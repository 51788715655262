import React, { useContext, useState } from 'react';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidatePosts, reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { removePostFromAllRoadmaps } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import connect from 'common/core/connect';
import MergePostSuggestion from 'common/post/MergePostSuggestion';
import findStringMatches from 'common/util/findStringMatches';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import unique from 'common/util/unique';

import GenericActionModal, { GenericActionError } from './GenericActionModal';

import type { CommonModalProps } from '.';
import type { Board } from 'common/api/resources/board';
import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

type OwnProps = CommonModalProps;

type ConnectProps = {
  reloadMergeData: (boards: Board[], mergeIntoPost: Post, mergePosts: Post[]) => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const getSuggestions = (selectedPosts: Post[], searchValue: string) => {
  if (!searchValue || !searchValue.length) {
    return selectedPosts;
  }

  const titleMatches = findStringMatches(selectedPosts, 'title', searchValue);
  const detailsMatches = findStringMatches(selectedPosts, 'details', searchValue);

  // might match both title and details, so we need to dedupe
  return unique([...titleMatches, ...detailsMatches]);
};

const MergePostsModal = ({ onClose, reloadMergeData, selectedPosts }: CommonModalProps & Props) => {
  const boards = useContext(BoardsContext);
  const [searchValue, setSearchValue] = useState<string>('');

  const boardsAreLoading = 'loading' in boards;

  const handleSubmit = async (mergeIntoPost: Post) => {
    if (boardsAreLoading) {
      return;
    }

    const response = await AJAX.post('/api/posts/bulkEdit', {
      mergeIntoPostID: mergeIntoPost._id,
      operationType: 'merge',
      postIDs: selectedPosts.map((post) => post._id),
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      throw new GenericActionError({ message: error.message, source: 'MergePosts' });
    }

    // MergeIntoPost is also a selected post, remove it before reloading to prevent it's removal from boards/roadmaps
    const mergePosts = selectedPosts.filter((post) => post._id !== mergeIntoPost._id);

    const selectedBoards = boards.filter((board) =>
      mergePosts.some((post) => post.boardID === board._id)
    );

    await reloadMergeData(selectedBoards, mergeIntoPost, mergePosts);
  };

  return (
    <GenericActionModal<Post>
      showSearch
      confirmationModalSettings={{
        header: 'Are you sure you want to merge these posts?',
        description: 'All votes and comments will be moved to the post you selected.',
        confirmCTA: 'Merge',
      }}
      cta="Merge"
      title="Merge posts"
      onClose={onClose}
      onSubmit={handleSubmit}
      searchValue={searchValue}
      onSearchValueChanged={(searchValue) => setSearchValue(searchValue)}
      successMessage={`Successfully merged ${selectedPosts.length} posts`}
      suggestions={getSuggestions(selectedPosts, searchValue)}
      SuggestionComponent={({ suggestion, onClick }) => (
        <MergePostSuggestion post={suggestion} onTap={onClick} />
      )}
      suggestionsLoading={boardsAreLoading}
    />
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  reloadMergeData: async (boards: Board[], mergeIntoPost: Post, mergePosts: Post[]) => {
    return Promise.all([
      dispatch(invalidateDashboardActivity()),
      dispatch(invalidatePostQueries()),
      dispatch(invalidatePosts(mergePosts)),
      dispatch(reloadPost(mergeIntoPost)),
      dispatch(reloadPostActivity(mergeIntoPost)),
      ...boards.map((board) => dispatch(reloadBoard(board.urlName))),
      ...mergePosts.map((post) => dispatch(removePostFromAllRoadmaps(post))),
    ]);
  },
}))(MergePostsModal) as unknown as React.FC<OwnProps>;

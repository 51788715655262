import React from 'react';

import classNames from 'classnames';

import Colors, { type ColorKeys, type ColorNames } from 'common/colors/constants';
import LazyLoadedImage from 'common/LazyLoadedImage';

import 'css/components/_Avatar.scss';

type Size = 16 | 20 | 24 | 28 | 32 | 40 | 48 | 56 | 64;
type InitialProps = {
  variant: 'initial';
  alt?: never;
};

type ImageProps = {
  variant: 'image';
  alt: string;
};
type DefaultProps = {
  size: Size;
  className?: string;
  color: ColorNames;
  value: string;
  shape: 'circle' | 'square';
};
export type Props = DefaultProps & (ImageProps | InitialProps);

const Avatar = ({ className, size, variant, value, color, shape, alt }: Props) => {
  return (
    <span
      className={classNames('avatarV2', className, {
        [`size-${size}`]: true,
        [`variant-${variant}`]: true,
        [`shape-${shape}`]: true,
      })}
      style={
        variant === 'image'
          ? undefined
          : {
              color: Colors[`${color}110` as ColorKeys],
              backgroundColor: Colors[`${color}40` as ColorKeys],
            }
      }>
      {variant === 'image' ? (
        <LazyLoadedImage src={value} className="avatarImage" alt={alt} />
      ) : (
        <span className="initialLetter">{value}</span>
      )}
    </span>
  );
};

export default Avatar;

import React from 'react';

import CheckboxV2, { type Props as CheckboxProps } from 'common/ui/CheckboxV2';
import Label, { type Props as LabelProps } from 'common/ui/common/Label';

type ChildrenProps =
  | {
      children: Omit<React.ReactNode, 'string'>;
      'aria-label': string;
    }
  | {
      children: string;
      'aria-label'?: string;
    };

type Props = Omit<CheckboxProps, 'aria-label'> & ChildrenProps & Pick<LabelProps, 'suffix'>;

const CheckboxWithLabel = ({
  'aria-label': ariaLabel,
  children,
  size,
  suffix,
  className,
  ...props
}: Props) => {
  return (
    <Label
      className={className}
      suffix={suffix}
      label={children}
      labelPlacement="right"
      size={size}>
      <CheckboxV2
        size={size}
        {...props}
        aria-label={ariaLabel || (typeof children === 'string' ? children : '')}
      />
    </Label>
  );
};

export default CheckboxWithLabel;

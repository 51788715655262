import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';

import CustomPostFieldModal from './CustomPostFieldModal';

import 'css/components/modals/_CustomPostFieldModal.scss';

export default class CreateCustomPostFieldModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
  };

  state = {
    error: null,
    saving: false,
  };

  onCreate = async ({ name, options, type }) => {
    const { onCreate } = this.props;

    if (!validateInput.customPostField.name(name)) {
      this.setState({ error: 'Please enter a valid post field name (1-20 characters)' });
      return;
    }

    this.setState({ error: null, saving: true });
    const response = await AJAX.post('/api/customPostFields/create', {
      customPostField: {
        name,
        options: options.length ? options : null,
        required: false,
        type: type.name,
      },
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': `You don't have the permissions to create a new post field`,
        'no options': 'You should add at least one option',
        'plan does not support': 'Your plan does not support custom post fields',
        'too many options': `You should not add more than ${type.optionsLimit} options`,
      },
    });

    if (error) {
      this.setState({
        error: error.message,
        saving: false,
      });
      return;
    }

    this.setState({ saving: false }, () => onCreate());
  };

  render() {
    const { error, saving } = this.state;
    const { onClose } = this.props;

    return (
      <CustomPostFieldModal
        error={error}
        header="New Post Field"
        isTypeEditable={true}
        onClose={onClose}
        onSubmit={this.onCreate}
        saving={saving}
        submitLabel="Create"
      />
    );
  }
}

import { AllMimeTypes, ImageMimeTypes } from 'common/constants/files';

import type { Company } from 'common/api/endpoints/companies';

const getAcceptedMimeTypes = (company: Company) => {
  const supportsAllTypes = company.features.fileAttachments;
  return supportsAllTypes ? AllMimeTypes : ImageMimeTypes;
};

export default getAcceptedMimeTypes;

import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { ProjectManagementIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
export const sections = [
  {
    integrations: ProjectManagementIntegrations,
  },
];

const AdminIntegrationSettingsProjectManagement = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Project Management"
      settingsDescription="Coordinate projects and track tasks effectively with project management integrations. Keep teams aligned and maintain project momentum with ease."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations['project-management']),
    '/admin/settings'
  )
)(AdminIntegrationSettingsProjectManagement) as unknown as React.FC;

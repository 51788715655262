import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import AJAX from 'common/AJAX';
import * as AuthRequests from 'common/auth/AuthRequests';
import AuthButton from 'common/AuthButton';
import CannyAttribution from 'common/CannyAttribution';
import { DisconnectGoogleContext } from 'common/containers/AuthContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_ChangelogLoginForm.scss';

import CannyLogoIcon from 'img/canny-icon.svg';

class ChangelogLoginForm extends Component {
  static propTypes = {
    company: PropTypes.object,
    changelog: PropTypes.object,
    disconnectGoogle: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
  };

  onOAuthFailure = (error) => {
    this.setState({
      error,
    });
  };

  onOAuthSuccess = (oauthData) => {
    const { viewer } = this.props;
    if (viewer.loggedOut) {
      this.login(oauthData);
    } else {
      this.addGoogleDomain(oauthData);
    }
  };

  login = async (oauthData) => {
    const { error, redirecting } = await AuthRequests.login(oauthData);

    if (redirecting) {
      return;
    }

    if (!error) {
      this.addGoogleDomain(oauthData);
      return;
    }

    if (error.type === 'slow down') {
      this.setState({
        error: error.message,
      });
      return;
    }

    this.signup(oauthData);
  };

  signup = async (oauthData) => {
    const { error, redirecting } = await AuthRequests.signup(oauthData);

    if (redirecting) {
      return;
    }

    if (!error) {
      this.addGoogleDomain(oauthData);
      return;
    }

    this.onError(error.message);
  };

  addGoogleDomain = (oauthData) => {
    AJAX.post(
      '/api/viewer/addGSuiteDomain',
      {
        ...oauthData, // googleUserID, googleIDToken
      },
      (response) => {
        if (response === 'success') {
          window.location.reload();
          return;
        }

        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (responseObject.error === 'invalid email') {
          const { disconnectGoogle } = this.props;
          disconnectGoogle();
          this.setState({
            error: (
              <div>
                Invalid email. Please try again and make sure to sign in with your company
                email&nbsp;account.
              </div>
            ),
          });
          return;
        }

        this.onError();
      }
    );
  };

  onCannyLogIn = () => {
    this.props.openModal(AccountModal, {
      formState: FormStates.login,
    });
    return;
  };

  onError = (message) => {
    this.setState({
      error: message ? (
        <div>{message}</div>
      ) : (
        <div>
          Something went wrong. Please&nbsp;
          <a href="mailto:support@canny.io">contact support</a>.
        </div>
      ),
    });
  };

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderCannyButton() {
    if (!this.props.viewer.loggedOut) {
      return null;
    }

    return (
      <Tappable onTap={this.onCannyLogIn}>
        <div className="authButton">
          <div className="imageContainer">
            <LazyLoadedImage alt="Canny logo" src={CannyLogoIcon} />
          </div>
          <div className="cta">Log in with Canny</div>
        </div>
      </Tappable>
    );
  }

  renderGSuiteButton() {
    return (
      <AuthButton
        authType="google"
        onFailure={this.onOAuthFailure}
        onSuccess={this.onOAuthSuccess}
        value="Log in with Google"
      />
    );
  }

  renderOIDCButton() {
    return (
      <AuthButton
        additionalData={{
          companyID: this.props.company._id,
        }}
        authType="oidc"
        onFailure={this.onOAuthFailure}
        onSuccess={this.onOAuthSuccess}
        value="Log in with OpenID Connect"
      />
    );
  }

  renderPrimarySignInOption() {
    const {
      company: { changelog, privateDomains },
      viewer,
    } = this.props;

    const usingOIDCAuthentication = privateDomains.some(
      (domainEntry) => domainEntry.verifiedBy.oidc
    );
    const isUsingDomainVerification = changelog.domains.length > 0;

    /**
     * When a company is a) NOT using domain verified auth b) using OIDC authentication on any board, we default to the OIDC login over GSuite domain verification.
     * If companies want to use both, we'll need to change this to render both.
     */
    if (!isUsingDomainVerification && usingOIDCAuthentication) {
      return (
        <div className="primaryLogIn">
          <div className="prompt">
            Log in with your admin account to access&nbsp;this&nbsp;page.
          </div>
          {this.renderOIDCButton()}
        </div>
      );
    }

    if (isUsingDomainVerification) {
      const { domains } = changelog;
      return (
        <div className="primaryLogIn">
          <div className="prompt">
            Log in with your Google account (
            {domains
              .map((domain) => {
                return '@' + domain;
              })
              .join(', ')}
            ) to access&nbsp;this&nbsp;page.
          </div>
          {this.renderGSuiteButton()}
        </div>
      );
    }

    if (!viewer.loggedOut) {
      return <div className="noAccess">You do not have access to this&nbsp;page</div>;
    }

    return (
      <div className="primaryLogIn">
        <div className="prompt">Log in with your admin account to access&nbsp;this&nbsp;page.</div>
        {this.renderCannyButton()}
      </div>
    );
  }

  renderSecondarySignInOption() {
    const {
      company: { changelog },
      viewer,
    } = this.props;
    if (changelog.domains.length === 0) {
      return null;
    }

    if (!viewer.loggedOut) {
      return null;
    }

    return (
      <div className="secondarySignIn">
        <p className="prompt">Alternate log in options</p>
        {this.renderCannyButton()}
      </div>
    );
  }

  render() {
    return (
      <div className="changelogLoginForm">
        <div className="topContainer">
          {this.renderPrimarySignInOption()}
          {this.renderErrorMessage()}
        </div>
        {this.renderSecondarySignInOption()}
        <CannyAttribution source="feedback_subdomain" />
      </div>
    );
  }
}

export default compose(
  withContexts({
    company: CompanyContext,
    disconnectGoogle: DisconnectGoogleContext,
    openModal: OpenModalContext,
    viewer: ViewerContext,
  })
)(ChangelogLoginForm);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import BingContainer from 'common/containers/BingContainer';
import CannylyticsContainer from 'common/containers/CannylyticsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import EventContainer from 'common/containers/EventContainer';
import HubSpotContainer from 'common/containers/HubSpotContainer';
import IntercomContainer from 'common/containers/IntercomContainer';
import LinkedInContainer from 'common/containers/LinkedInContainer';
import MixpanelContainer from 'common/containers/MixpanelContainer';
import ModalContainer from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import FocusNav from './FocusNav';

import 'css/components/_RegistrationWrapper.scss';

class RegistrationWrapper extends Component {
  static propTypes = {
    company: PropTypes.object,
    viewer: PropTypes.object,
  };

  componentDidMount() {
    const { company, viewer } = this.props;

    if (company.viewerIsMember) {
      return;
    }

    if (viewer.loggedOut) {
      const url = devURL('https://canny.io/login');
      window.location.assign(url);
    }
  }

  renderContents() {
    const { children, company } = this.props;

    if (company.viewerIsMember) {
      return children;
    }

    return (
      <ContentContainer outerClassName="notAuthorized">
        You aren't a member of this company.
      </ContentContainer>
    );
  }

  render() {
    return (
      <ModalContainer>
        <HubSpotContainer>
          <IntercomContainer>
            <LinkedInContainer>
              <BingContainer>
                <MixpanelContainer property="registration" trackPageViews={true}>
                  <CannylyticsContainer>
                    <EventContainer>
                      <div className="pageWrapper">
                        <FocusNav />
                        <div className="registrationWrapper">{this.renderContents()}</div>
                      </div>
                    </EventContainer>
                  </CannylyticsContainer>
                </MixpanelContainer>
              </BingContainer>
            </LinkedInContainer>
          </IntercomContainer>
        </HubSpotContainer>
      </ModalContainer>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  viewer: ViewerContext,
})(RegistrationWrapper);

import React, { useContext } from 'react';

import {
  type AddPostCategory as AddPostCategoryType,
  type Filter,
  Resource,
} from 'common/automations/constants';
import { BoardsContext } from 'common/containers/BoardsContainer';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import styles from 'css-module/components/subdomain/admin/AdminAutomationSettings/ActionRow/AddPostCategory.module.scss';

import type { Option } from 'common/ui/common/select/SelectCommon';

interface Props {
  filters: Filter[];
  properties: AddPostCategoryType['properties'];
  onChange: (properties?: AddPostCategoryType['properties']) => void;
}

const AddPostCategory = ({ filters, ...props }: Props) => {
  const boards = useContext(BoardsContext);

  const { properties, onChange } = props;

  if (!Array.isArray(boards)) {
    return null;
  }

  const boardFilter = filters.find((filter) => filter.resource === Resource.postBoardURLName);

  if (!boardFilter) {
    return null;
  }

  const selectedBoard = boards.find((board) => board.urlName === boardFilter.value);

  if (!selectedBoard) {
    return null;
  }

  const categoryOptions: Option[] = [];
  let selectedCategory: Option | undefined = undefined;

  selectedBoard.categories.forEach((category) => {
    const categoryOption: Option = {
      label: category.name,
      value: category._id,
      render: category.parentID ? (
        <div className={styles.leftMargin}>{category.name}</div>
      ) : (
        category.name
      ),
    };
    categoryOptions.push(categoryOption);
    if (properties.categoryID === category._id) {
      selectedCategory = categoryOption;
    }
  });

  return (
    <SingleSelectWithSearch
      className="grid2"
      placeholder="Select a category"
      onChange={(option?: Option) => onChange({ categoryID: (option as Option).value })}
      options={categoryOptions}
      value={selectedCategory}
      optionsMaxWidth="300px"
    />
  );
};

export default AddPostCategory;

import React from 'react';

import translateString from 'common/i18n/translateString';
import Markdown from 'common/markdown/Markdown';

import type { Comment } from 'common/api/resources/comments';

import 'css/components/comment/_CommentBody.scss';

type Props = {
  comment: Comment;
  hideTranslation?: boolean;
};

const CommentBody = ({ comment, hideTranslation }: Props) => {
  return (
    <div className="commentBody">
      <div className="value">
        <Markdown
          contents={translateString(comment, 'value', hideTranslation)}
          mentions={comment.mentionedUsers}
        />
      </div>
    </div>
  );
};

export default CommentBody;

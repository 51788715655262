import React, { useContext, useEffect, useState } from 'react';

import AJAX from 'common/AJAX';
import DateRangePicker from 'common/common/DateRangePicker';
import { CompanyContext } from 'common/containers/CompanyContainer';
import DataTable, { type Column, OptionalNumericCell, useLocalSort } from 'common/DataTable';
import UpsellModal from 'common/modals/UpsellModal';
import UserAvatar from 'common/user/UserAvatar';
import { RelativeDateOptions, RelativeDateRanges } from 'common/util/dateRanges';
import { isNotNil } from 'common/util/isNil';
import parseAPIResponse from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';

import type { Member } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardAdminAnalytics.scss';

type MemberAnalytics = {
  commentCount: number;
  mergeCount: number;
  postsCount: number;
  postsOnBehalfCount: number;
  voteCount: number;
  votesOnBehalfCount: number;
};

type Row = {
  id: string;
  admin: Member;
  comments?: number;
  merges?: number;
  posts?: number;
  votes?: number;
};

type SortableColumn<R extends Row, ID extends keyof R> = Column<R, ID> & {
  sort: (order: 'asc' | 'desc') => (a?: R[ID], b?: R[ID]) => number;
};

const optionalNumericSort =
  (order: 'asc' | 'desc') => (a: number | undefined, b: number | undefined) => {
    const aNumeric = a ?? 0;
    const bNumeric = b ?? 0;
    return order === 'asc' ? bNumeric - aNumeric : aNumeric - bNumeric;
  };

const valueOrZero = (value?: number | null): number => value || 0;

type Props = {
  members: Member[];
};

type DateRangeOption = {
  label: string | string[] | null;
  value: [string, string] | null;
};

const DefaultDateRange: DateRangeOption = {
  label: RelativeDateRanges.thisWeek,
  value: RelativeDateOptions[RelativeDateRanges.thisWeek].toRange() as [string, string],
};

const columns = [
  {
    id: 'admin',
    align: 'left',
    header: 'Admins',
    cell: (member) => (
      <div className="memberData" key={member._id}>
        <UserAvatar size="medium" user={member} />
        <div className="memberDetails">
          <div className="memberName">{member.name}</div>
          <div className="memberEmail">{member.email}</div>
        </div>
      </div>
    ),
    sort: (order: 'asc' | 'desc') => stringSort('name', order),
    sortable: true,
  } as SortableColumn<Row, 'admin'>,
  {
    id: 'votes',
    align: 'right',
    header: 'Votes',
    tooltip: 'Includes their votes and those made on behalf of other\u00a0users',
    cell: (content) => <OptionalNumericCell value={content} />,
    sort: optionalNumericSort,
    sortable: true,
  } as SortableColumn<Row, 'votes'>,
  {
    id: 'posts',
    align: 'right',
    header: 'Posts',
    tooltip: 'Includes their posts and those made on behalf of other\u00a0users',
    cell: (content) => <OptionalNumericCell value={content} />,
    sort: optionalNumericSort,
    sortable: true,
  } as SortableColumn<Row, 'posts'>,
  {
    id: 'comments',
    align: 'right',
    header: 'Comments',
    cell: (content) => <OptionalNumericCell value={content} />,
    sort: optionalNumericSort,
    sortable: true,
  } as SortableColumn<Row, 'comments'>,
  {
    id: 'merges',
    align: 'right',
    header: 'Merges',
    cell: (content) => <OptionalNumericCell value={content} />,
    sort: optionalNumericSort,
    sortable: true,
  } as SortableColumn<Row, 'merges'>,
];

export default ({ members }: Props) => {
  const company = useContext(CompanyContext);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [analyticsMap, setAnalyticsMap] = useState<Record<string, MemberAnalytics> | null>(null);
  const [dateRange, setDateRange] = useState<DateRangeOption>(DefaultDateRange);
  const [showUpsellModal, setUpsellModalVisibility] = useState<boolean>(false);

  useEffect(() => {
    const fetchAnalytics = async () => {
      setError(null);
      setLoading(true);

      const response = await AJAX.post('/api/adminDashboard/getMemberAnalytics', {
        ...(dateRange?.value && { dateRange: dateRange.value }),
      });

      const { error, parsedResponse } = parseAPIResponse<Record<string, MemberAnalytics>>(
        response,
        {
          isSuccessful: (parsedResponse) => parsedResponse && typeof parsedResponse === 'object',
        }
      );

      setLoading(false);

      if (error) {
        setError(error.message);
        return;
      } else if (!parsedResponse) {
        return;
      }

      setAnalyticsMap(parsedResponse);
    };

    fetchAnalytics();
  }, [dateRange]);

  const onDateRangeSelected = ({
    relativeDate,
    dateRange,
  }: {
    relativeDate: string | null;
    dateRange: [string | null, string | null];
  }) => {
    const [start, end] = dateRange ?? [];
    const isDateRangeValid = isNotNil(start) && isNotNil(end);
    if (!isDateRangeValid) {
      setDateRange({ label: relativeDate ?? null, value: null });
      return;
    }

    setDateRange({
      label: relativeDate ?? [start, end],
      value: [start, end],
    });
  };

  const analyticsHistory = company.limits?.analyticsHistory;
  const limitedOptions =
    analyticsHistory && analyticsHistory <= 30
      ? [
          RelativeDateRanges.allTime,
          RelativeDateRanges.lastHalf,
          RelativeDateRanges.lastQuarter,
          RelativeDateRanges.thisHalf,
          RelativeDateRanges.thisQuarter,
        ]
      : [];

  const rows = members.map((member) => {
    const analytics = analyticsMap?.[member._id];
    const posts = valueOrZero(analytics?.postsCount) + valueOrZero(analytics?.postsOnBehalfCount);
    const votes = valueOrZero(analytics?.voteCount) + valueOrZero(analytics?.votesOnBehalfCount);

    // default to undefined to show `-` instead of 0.
    return {
      id: member._id,
      admin: member,
      comments: analytics?.commentCount || undefined,
      merges: analytics?.mergeCount || undefined,
      posts: posts || undefined,
      votes: votes || undefined,
    };
  });

  const [sortedRows, onSort] = useLocalSort(rows, (rows, sort) => {
    if (!sort) {
      return rows;
    }

    const column = columns.find((column) => column.id === sort.columnID);
    if (column?.sort) {
      const sortedRows = [...rows].sort((a, b) => {
        return column.sort(sort.order)(
          a[column.id as keyof Row[typeof column.id]],
          b[column.id as keyof Row[typeof column.id]]
        );
      });

      return sortedRows;
    }

    return rows;
  });

  return (
    <div className="adminDashboardAdminAnalytics">
      <div className="dateContainer">
        <div className="label">Show activity for</div>
        <div className="dateDropdown">
          <DateRangePicker
            align="end"
            date={dateRange?.label}
            historyDayLimit={analyticsHistory}
            limitedOptions={limitedOptions}
            onLimitExceeded={() => setUpsellModalVisibility(true)}
            onSubmit={onDateRangeSelected}
          />
        </div>
      </div>
      <DataTable<Row>
        loading={loading}
        onSort={onSort}
        height={400}
        error={error}
        columns={columns}
        rows={sortedRows}
      />
      <UpsellModal
        cta="More than 30 days of analytics&nbsp;history"
        feature="limits.adminAnalyticsHistory"
        onClose={() => setUpsellModalVisibility(false)}
        onUpsell={() => setUpsellModalVisibility(false)}
        show={showUpsellModal}
      />
    </div>
  );
};

import React, { type ComponentType, type Context, forwardRef, useContext } from 'react';

type Contexts = {
  [key: string]: Context<any>;
};

type Options = {
  forwardRef?: boolean;
};

type ComponentProps = {
  [key: string]: any;
};

const withContexts = (contexts: Contexts, options: Options = {}) => {
  return <P extends object>(Component: ComponentType<P>) => {
    const WrappedComponent = forwardRef<any, P & ComponentProps>((props, ref) => {
      const contextProps: ComponentProps = {};
      for (const [contextName, context] of Object.entries(contexts)) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        contextProps[contextName] = useContext(context);
      }

      const childProps = {
        ...contextProps,
        ...props,
        ...(options?.forwardRef && ref && { ref }),
      };

      return <Component {...(childProps as P)} />;
    });

    const displayName = Component.displayName || Component.name || Component.displayName;
    WrappedComponent.displayName = `withContexts(${displayName})`;

    return WrappedComponent;
  };
};

export default withContexts;

import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { DataAndEnrichmentIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const sections = [
  {
    integrations: DataAndEnrichmentIntegrations,
  },
];

const AdminIntegrationSettingsDataAndEnrichment = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Data and Enrichment"
      settingsDescription="Enhance data quality and insights with enrichment tools. Add valuable context to customer data for more informed decision-making."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations['data-and-enrichment']),
    '/admin/settings'
  )
)(AdminIntegrationSettingsDataAndEnrichment) as unknown as React.FC;

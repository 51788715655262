const Strings = {
  // email
  invalidEmail: 'Please enter a valid email.',
  emailTaken:
    "There is already an account with this email, are you sure you don't have an account already?",
  emailAlreadySubscribed: 'This email address is already subscribed.',

  // login
  badLogin: 'The email and password do not match.',

  // misc (catchall)
  miscError: 'Uh-oh! Something went wrong, please try again later.',

  // name
  invalidName: 'Please enter a valid name. (2-50 characters)',

  // password
  invalidPassword: 'Please enter a password between 6 and 200 characters.',
  passwordConfirmMismatch: 'Uh-oh! Your password confirmation does not match.',

  // unsubscription
  selectUnsubscription: 'Please select something to unsubscribe from.',

  // URL
  invalidURL: 'Please enter a valid URL.',
};

export default Strings;

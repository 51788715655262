import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import KeyCodes from 'common/KeyCodes';

import Tappable from '../Tappable';

import 'css/components/inputs/_CheckboxInput.scss';

export default class CheckboxInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    isFocusable: PropTypes.bool,
    label: PropTypes.node,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
  };

  static defaultProps = {
    defaultChecked: false,
    disabled: false,
    isFocusable: false,
  };

  state = {
    checked: this.props.defaultChecked,
    focused: false,
  };

  constructor(props, context) {
    super(props, context);

    this.checkboxRef = React.createRef();
  }

  componentDidMount() {
    this.checkboxRef.current.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    this.checkboxRef.current.removeEventListener('keydown', this.onKeyDown);
  }

  getValue = () => {
    return this.state.checked;
  };

  setValue = (checked) => {
    if (this.state.checked === checked) {
      return;
    }

    this.setState({
      checked,
    });
  };

  onBlur = (e) => {
    if (!this.props.isFocusable) {
      return;
    }

    this.setState({ focused: false });
  };

  onFocus = (e) => {
    if (!this.props.isFocusable || this.props.disabled) {
      return;
    }

    this.setState({ focused: true });
  };

  onKeyDown = (e) => {
    if (this.state.focused && e.keyCode === KeyCodes.Enter) {
      e.preventDefault();
      this.onToggle(e);
      return;
    }
    this.props.onKeyDown?.(e);
  };

  onToggle = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        const { onChange } = this.props;
        onChange && onChange(this.state.checked);
      }
    );
  };

  renderLabel() {
    const { label } = this.props;
    if (!label) {
      return null;
    }

    return <div className="checkboxLabel">{label}</div>;
  }

  render() {
    const { className, disabled, isFocusable } = this.props;
    const { checked, focused } = this.state;

    return (
      <Tappable onTap={this.onToggle}>
        <div className={classnames('checkboxInput', className, { focused, disabled })}>
          <div
            className={classnames('checkbox', { checked, focusable: isFocusable })}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            ref={this.checkboxRef}
            tabIndex={0}>
            <div className="icon-check" />
          </div>
          {this.renderLabel()}
        </div>
      </Tappable>
    );
  }
}

import React, { useContext, useEffect, useState } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import {
  CustomDescriptionFieldTypes,
  DefaultDescriptionFieldTypes,
} from 'common/prioritization/DescriptionColumnTypes';
import ImpactFactorTypes from 'common/prioritization/ImpactFactorTypes';
import { type Option } from 'common/ui/common/select/SelectCommon';
import MultipleSelect from 'common/ui/MultipleSelect';
import SingleSelect from 'common/ui/SingleSelect';
import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';
import mapify from 'common/util/mapify';

import getDefaultFieldSettings, {
  boolFilterOperator,
  customFilterOperator,
} from './FilterConstants/SelectFilterUtils';
import useCustomQueryParam from './useCustomQueryParam';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { Board as PlainBoard } from 'common/api/resources/board';

type Props = {
  boards: Board[];
  distinctBoards: PlainBoard[];
  fieldID: string | null;
  isCustomDescriptionColumn: boolean;
  name: string;
  options?: string[];
  type: DefaultDescriptionFieldTypes | CustomDescriptionFieldTypes | keyof typeof ImpactFactorTypes;
};

const formatToArray = (value: Option | Option[]) => {
  return Array.isArray(value) ? value : [value];
};

const mapToCustomFieldSettings = (options: string[]) => ({
  options: options.map((option) => ({
    label: capitalizeFirstLetter(option),
    value: option,
  })),
  placeholder: 'All',
});

const isMulti = (type: Props['type']) =>
  type === DefaultDescriptionFieldTypes.multiSelect ||
  type === CustomDescriptionFieldTypes.multiselect;

const isBool = (type: Props['type']) => type === ImpactFactorTypes.checkbox.name;

const SelectFilter = ({
  boards,
  distinctBoards,
  fieldID,
  isCustomDescriptionColumn,
  name,
  options,
  type,
}: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const company = useContext<Company>(CompanyContext);

  const { getCustomQueryParam, setCustomQueryParams } = useCustomQueryParam(
    isCustomDescriptionColumn ? 'description' : 'factor'
  );

  const [values, setValues] = useState<Option[] | undefined>(
    type === DefaultDescriptionFieldTypes.multiSelect ? [] : undefined
  );
  const [loading, setLoading] = useState(true);

  const distinctBoardIDs = distinctBoards ? Object.keys(mapify(distinctBoards, '_id')) : [];

  const [fieldSettings] = useState(
    options && options.length
      ? mapToCustomFieldSettings(options)
      : getDefaultFieldSettings(name, boards, distinctBoardIDs, company)
  );

  const getMatchingOptions = (urlNames: string[], options: Option[]) =>
    urlNames
      .map((urlName) => options.find((option) => option.value === urlName))
      .filter((option): option is Option => Boolean(option));

  useEffect(() => {
    const selectedOption = fieldID ? getCustomQueryParam(fieldID)?.value : location.query[name];

    if (!selectedOption) {
      setValues(type === DefaultDescriptionFieldTypes.multiSelect ? [] : undefined);
    }

    let selectionOptionURLNames = selectedOption ?? [];

    if (!fieldID) {
      selectionOptionURLNames = selectedOption ? selectedOption.split('_') : [];
    }

    if (fieldSettings) {
      const { options } = fieldSettings;

      if (isMulti(type)) {
        const newValues = getMatchingOptions(selectionOptionURLNames, options);
        setValues(newValues && newValues.length ? newValues : []);
      } else {
        // With single select, we can only have 1 value so if they modify the URL manually only the first
        // value will be used
        const firstMatchingOption = getMatchingOptions([selectionOptionURLNames[0]], options)[0];
        if (firstMatchingOption) {
          setValues([firstMatchingOption]);
        }
      }
    }

    setLoading(false);
  }, [fieldID, getCustomQueryParam, location.query, name, fieldSettings, type]);

  const changeOption = (name: string, options?: Option[]) => {
    setLoading(true);
    const { query } = location;
    const selectedOptionSet: { [key: string]: boolean } = {};

    options &&
      options.forEach((option) => {
        selectedOptionSet[option.value] = true;
      });

    const newURLNames = Object.keys(selectedOptionSet);
    const newOptions = newURLNames.length > 0 ? newURLNames.join('_') : undefined;

    if (fieldID) {
      let operator = null;
      if (newURLNames.length) {
        operator = isBool(type) ? boolFilterOperator : customFilterOperator;
      }

      setCustomQueryParams(fieldID, operator, newURLNames);
    } else {
      const newQuery = Object.assign({}, query, {
        [name]: newOptions,
      });

      router.push({
        pathname: location.pathname,
        query: newQuery,
      });
    }
  };

  if (fieldSettings) {
    const { options, placeholder } = fieldSettings;

    if (options) {
      return isMulti(type) ? (
        <MultipleSelect
          allowClear={true}
          labelPlacement="top"
          loading={loading}
          onChange={(changedOptions?: Option[] | Option) =>
            changeOption(name, changedOptions && formatToArray(changedOptions))
          }
          options={options}
          optionsPlacement="bottom"
          placeholder={placeholder}
          value={values ?? []}
          withBorder={true}
        />
      ) : (
        <SingleSelect
          allowClear={true}
          labelPlacement="top"
          loading={loading}
          onChange={(changedOptions?: Option[] | Option) =>
            changeOption(name, changedOptions && formatToArray(changedOptions))
          }
          options={options}
          optionsPlacement="bottom"
          placeholder={placeholder}
          value={values && values[0]}
          withBorder={true}
        />
      );
    }
  }

  return null;
};

export default SelectFilter;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapNUX.scss';

import SendToRoadmap from 'img/roadmap-nux.jpg';

class AdminRoadmapNUX extends Component {
  static propTypes = {
    company: PropTypes.shape({
      nux: PropTypes.object,
    }),
    reloadCompany: PropTypes.func,
  };

  onDismiss = async () => {
    await AJAX.post('/api/nux/dismissNUX', { nuxType: 'prioritizationOverview' });
    this.props.reloadCompany();
  };

  render() {
    if (this.props.company?.nux?.prioritizationOverview) {
      return null;
    }

    return (
      <div className="adminRoadmapNUX">
        <div className="container">
          <div className="info">
            <p className="text topInfo">
              Welcome to the prioritization view of your roadmap! Our goal is to help your team
              score and rank&nbsp;posts.
            </p>
            <p className="text">
              Specify which posts you want to score by sending them to the roadmap. We’ve added a
              few of your top posts to get you&nbsp;started.
            </p>
          </div>
          <div className="imageWrapper">
            <LazyLoadedImage
              className="image"
              src={SendToRoadmap}
              alt="When viewing a post, you can send it to a roadmap"
            />
          </div>
          <Tappable onTap={this.onDismiss}>
            <div className="dismissButton">
              <div className="icon icon-x" />
            </div>
          </Tappable>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts({ company: CompanyContext }, { forwardRef: true })
)(AdminRoadmapNUX);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import areObjectsEqual from 'common/areObjectsEqual';
import RadioButton from 'common/inputs/RadioButton';

import 'css/components/inputs/_RadioButtonGroup.scss';

export default class RadioButtonGroup extends Component {
  static propTypes = {
    buttons: PropTypes.array,
    defaultSelectedName: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    defaultSelectedName: null,
    disabled: false,
  };

  state = {
    selectedIndex: 0,
  };

  componentWillMount() {
    this.resetIndex();
  }

  componentDidUpdate(prevProps) {
    const prevButtons = prevProps.buttons;
    const { buttons } = this.props;
    if (!areObjectsEqual(prevButtons, buttons)) {
      this.resetIndex();
    }
  }

  resetIndex = () => {
    const { buttons, defaultSelectedName } = this.props;
    if (!defaultSelectedName) {
      return;
    }

    const selectedIndex = buttons.findIndex((button) => {
      return button.name === defaultSelectedName;
    });

    if (!selectedIndex) {
      return;
    }

    this.setState({
      selectedIndex,
    });
  };

  getValue = () => {
    return this.props.buttons[this.state.selectedIndex];
  };

  setValue = (buttonName) => {
    const selectedIndex = this.props.buttons.findIndex((button) => {
      return button.name === buttonName;
    });
    if (selectedIndex === -1) {
      return;
    }

    this.setState({
      selectedIndex,
    });
  };

  onSelected = (index) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    this.setState(
      {
        selectedIndex: index,
      },
      () => {
        const { buttons, onChange } = this.props;
        onChange && onChange(buttons[index]);
      }
    );
  };

  render() {
    const { buttons, isRow } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div
        role="radiogroup"
        className={classnames({
          isRow: isRow,
          radioButtonGroup: true,
        })}>
        {buttons.map((button, i) => (
          <RadioButton
            key={button.render}
            label={button.render}
            selected={selectedIndex === i}
            onSelected={() => this.onSelected(i)}
          />
        ))}
      </div>
    );
  }
}

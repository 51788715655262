// according to https://moz.com/blog/how-long-should-your-meta-description-be-2018
const MaxLength = 160;

function lastIndexOf(string: string, char: string): number {
  const reverseFirstIndex = reverse(string).indexOf(char);
  if (reverseFirstIndex === -1) {
    return -1;
  }

  const lastIndex = string.length - reverseFirstIndex;
  return lastIndex;
}

function reverse(string: string): string {
  return string.split('').reduce((reverse, char) => {
    return char + reverse;
  }, '');
}

export default function generateMetaDescription(string: string): string {
  // turn newlines and dupe spaces into single spaces
  const processedString = string
    .replace(/[\n\r]+/g, ' ')
    .replace(/[\s]+/g, ' ')
    .trim();

  // short enough? return
  if (processedString.length <= MaxLength) {
    return processedString;
  }

  // too long? need to truncate smartly
  const substring = processedString.substring(0, MaxLength);

  // period within last 50 chars? truncate to that (include period)
  const lastPeriodIndex = lastIndexOf(substring, '.');
  if (lastPeriodIndex !== -1 && lastPeriodIndex > MaxLength - 50) {
    return substring.substring(0, lastPeriodIndex);
  }

  // space within last 50 chars? truncate to that (exclude space)
  const lastSpaceIndex = lastIndexOf(substring, ' ');
  if (lastSpaceIndex !== -1 && lastSpaceIndex > MaxLength - 50) {
    return substring.substring(0, lastSpaceIndex - 1);
  }

  // no periods/spaces? just return the raw string and let google deal with it
  return substring;
}

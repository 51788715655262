import * as React from 'react';

import classnames from 'classnames';

import cloneElementWithProps from 'common/core/cloneElementWithProps';
import generateRandomID from 'common/generateRandomID';
import UppercaseHeader from 'common/UppercaseHeader';

import 'css/components/_FormField.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  error?: React.ReactNode;
  label: React.ReactNode;
  secondaryLabel?: React.ReactNode;
};

export default ({ children, className, error, label, secondaryLabel }: Props) => {
  const id = generateRandomID();
  const errorID = generateRandomID();

  const errorProps = {
    'aria-invalid': error ? 'true' : 'false',
    'aria-errormessage': errorID,
  };

  return (
    <div className={classnames('formField', className)}>
      <UppercaseHeader>
        <label htmlFor={id}>{label}</label>
        {secondaryLabel && <div className="secondaryLabel">{secondaryLabel}</div>}
      </UppercaseHeader>
      {cloneElementWithProps(children, { id, name: id, ...errorProps })}
      {error ? (
        <span id={errorID} className="errorMessage">
          {error}
        </span>
      ) : null}
    </div>
  );
};

import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { AuthenticationIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const sections = [
  {
    integrations: AuthenticationIntegrations,
  },
];

const AdminIntegrationSettingsAuthentication = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Authentication"
      settingsDescription="Ensure secure access to your platform with reliable authentication tools. Manage user verification smoothly and keep data privacy intact."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.authentication),
    '/admin/settings'
  )
)(AdminIntegrationSettingsAuthentication) as unknown as React.FC;

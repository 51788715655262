import { GroupsError, GroupsLoaded, RequestGroups } from '../actions/gsuiteGroups';

export default function gsuiteGroups(state = null, action) {
  switch (action.type) {
    case RequestGroups: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case GroupsLoaded: {
      return Object.assign({}, state, {
        error: null,
        groups: action.groups,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case GroupsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    default:
      return state;
  }
}

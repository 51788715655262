import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import Dropdown from 'common/Dropdown';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';

import * as AzureDevopsPropTypes from './AzureDevopsPropTypes';

const AnyAllOptions = [
  {
    name: 'any',
    render: 'any',
  },
  {
    name: 'all',
    render: 'all',
  },
];
const NotifyOptions = [
  {
    name: 'notify',
    render: 'notify',
  },
  {
    name: 'do not notify',
    render: 'do not notify',
  },
];

const CreateRuleForm = ({ azureDevopsStates, loading, onClose, onSubmit }) => {
  // context
  const company = useContext(CompanyContext);

  // state
  const [allLinkedWorkItems, setAllLinkedWorkItems] = useState(false);
  const [stateName, setStateName] = useState(null);

  // refs
  const cannyStatusDropdownRef = useRef();
  const notifyVotersDropdownRef = useRef();

  // effects
  useEffect(() => {
    if (!azureDevopsStates || stateName) {
      return;
    }

    setStateName(azureDevopsStates[0].name);
  }, [azureDevopsStates, stateName]);

  // helpers
  const onAnyAllChanged = (option) => {
    setAllLinkedWorkItems(option === 'all');
  };

  const onStatusChange = (stateName) => {
    setStateName(stateName);
  };

  // Note: this helper is a wrapper for onSubmit prop
  const onSubmitForm = () => {
    const cannyStatus = cannyStatusDropdownRef.current.getValue();
    const shouldNotifyVoters = notifyVotersDropdownRef.current.getValue() === 'notify';

    onSubmit({
      allLinkedWorkItems,
      azureDevopsState: stateName,
      cannyStatus,
      shouldNotifyVoters,
    });
  };

  const render = () => {
    const isPlural = !!allLinkedWorkItems;
    const statusesOptions =
      azureDevopsStates.map((azureDevopsState) => ({
        name: azureDevopsState.name,
        render: <div className="azureDevopsStatusLabel">{azureDevopsState.name}</div>,
      })) || [];
    const cannyStatusOptions = company.statuses.map((status) => {
      return {
        name: status.name,
        render: <PostStatus showOpen={true} status={status.name} />,
      };
    });
    const selectedAllOption = allLinkedWorkItems ? 'all' : 'any';

    return (
      <div className="composerContainer">
        <div className="composer">
          When
          <ControlledDropdown
            className="anyAllDropdown"
            dropdownClassName="adminAzureDevopsSettingsDropdown"
            onChange={onAnyAllChanged}
            options={AnyAllOptions}
            selectedName={selectedAllOption}
          />
          linked Azure DevOps {isPlural ? 'work items are' : 'work item is'} changed to
          <ControlledDropdown
            className="azureDevopsStatusDropdown"
            dropdownClassName="adminAzureDevopsSettingsDropdown"
            options={statusesOptions}
            onChange={onStatusChange}
            selectedName={stateName}
          />
          , change all linked Canny posts to
          <Dropdown
            className="cannyStatusDropdown"
            defaultSelectedName="open"
            dropdownClassName="adminAzureDevopsSettingsDropdown"
            options={cannyStatusOptions}
            ref={cannyStatusDropdownRef}
          />
          , and
          <Dropdown
            className="notifyDropdown"
            defaultSelectedName="notify"
            dropdownClassName="adminAzureDevopsSettingsDropdown"
            options={NotifyOptions}
            ref={notifyVotersDropdownRef}
          />
          voters.
        </div>
        <div className="buttons">
          <Button buttonType="ghostButton" onTap={onClose} value="cancel" />
          <Button loading={loading} onTap={onSubmitForm} value="Create" />
        </div>
      </div>
    );
  };

  return render();
};

CreateRuleForm.propTypes = {
  azureDevopsStates: AzureDevopsPropTypes.states,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CreateRuleForm;

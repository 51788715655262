import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import { reloadCompany } from '../../actions/company';

import IntercomLogo from 'img/landing/intercom-logo.svg';
import SalesforceLogo from 'img/landing/salesforce-logo.svg';
import SlackLogo from 'img/landing/slack-logo.svg';
import ZendeskLogo from 'img/landing/zendesk-logo.svg';

import 'css/components/subdomain/admin/_AdminInstallIntegrationNUX.scss';

class AdminInstallIntegrationNUX extends Component {
  static propTypes = {
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    style: PropTypes.oneOf(['tall', 'wide']),
  };

  static defaultProps = {
    style: 'tall',
  };

  state = {
    isDismissed:
      this.props.company && this.props.company.nux
        ? this.props.company.nux.installIntegration
        : false,
  };

  onDismissNUX = () => {
    this.setState({ isDismissed: true });
    AJAX.post(
      '/api/nux/dismissNUX',
      {
        nuxType: 'installIntegration',
      },
      this.props.reload
    );
  };

  render() {
    if (this.state.isDismissed) {
      return null;
    }

    const {
      company: { intercom, salesforce, slack, zendesk },
      style,
    } = this.props;

    return (
      <div className={`installIntegrationNUX ${style}`}>
        <div className="message">
          <div className="words">
            <div className="prompt">Getting feedback elsewhere?</div>
            <div className="description">
              Enable customer-facing teammates to track feedback from existing tools.
            </div>
          </div>
          {style === 'tall' && (
            <div className="dismiss">
              <Tappable onTap={this.onDismissNUX}>
                <div className="exitButton icon icon-x" />
              </Tappable>
            </div>
          )}
        </div>
        <div className="integrations">
          {!slack && (
            <>
              <Link to="/admin/settings/slack">
                <LazyLoadedImage className="slack" src={SlackLogo} alt="Slack logo" />
              </Link>
              <div className="spacer" />
            </>
          )}
          {!intercom && (
            <>
              <Link to="/admin/settings/intercom">
                <LazyLoadedImage className="intercom" src={IntercomLogo} alt="Intercom logo" />
              </Link>
              <div className="spacer" />
            </>
          )}
          {!zendesk && (
            <>
              <Link to="/admin/settings/zendesk">
                <LazyLoadedImage className="zendesk" src={ZendeskLogo} alt="Zendesk logo" />
              </Link>
              <div className="spacer" />
            </>
          )}
          {!salesforce && (
            <>
              <Link to="/admin/settings/salesforce">
                <LazyLoadedImage
                  className="salesforce"
                  src={SalesforceLogo}
                  alt="Salesforce logo"
                />
              </Link>
              <div className="spacer" />
            </>
          )}
        </div>
        {style === 'wide' && (
          <div className="dismiss">
            <Tappable onTap={this.onDismissNUX}>
              <div className="exitButton icon icon-x" />
            </Tappable>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reload: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminInstallIntegrationNUX);

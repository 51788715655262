import devURL from 'common/util/devURL';

import Message from './message/Message';
import queryString from './util/queryString';

const FBAuthURL = 'https://www.facebook.com/v6.0/dialog/oauth';
const FBAppID = __DEV__ ? '1606035092942076' : '1606018679610384';
const RedirectURI = devURL('https://canny.io/auth');
const Origin = devURL('https://canny.io');

export default class OAuthFacebook {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab, redirectURL, separateWindow }) {
    this._onSuccess = onSuccess;
    this._onFailure = onFailure;
    this._onContinue = onContinue;
    this._shouldOpenNewTab = shouldOpenNewTab;
    this._redirectURL = redirectURL;
    this._separateWindow = separateWindow;
  }

  connect = () => {
    const url =
      FBAuthURL +
      queryString.stringify({
        client_id: FBAppID,
        redirect_uri: RedirectURI,
        scope: 'email',
        state: this.getState(),
      });

    if (!this._shouldOpenNewTab) {
      window.location = url;
      return;
    }

    // No, 'noopener' because we trust Facebook
    const authWindow = window.open(url, 'Canny Authentication');
    const unsubscribe = Message.subscribe(authWindow, Origin, 'authResult', (result) => {
      this._onContinue(result);
      unsubscribe();
    });
  };

  getState = () => {
    return JSON.stringify({
      authType: 'facebook',
      stage: 2,
      ...(this._shouldOpenNewTab && { close: true }),
      ...(this._redirectURL && { redirectURL: encodeURIComponent(this._redirectURL) }),
      ...(this._separateWindow && { separateWindow: encodeURIComponent(this._separateWindow) }),
    });
  };
}

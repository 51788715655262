import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import SingleSelect from 'common/ui/SingleSelect';

import type { CompanyPostStatus } from 'common/api/endpoints/companies';
import type { ApplyPostStatus as ApplyPostStatusType, Filter } from 'common/automations/constants';
import type { Option } from 'common/ui/common/select/SelectCommon';

interface Props {
  filters: Filter[];
  properties: ApplyPostStatusType['properties'];
  onChange: (properties?: ApplyPostStatusType['properties']) => void;
}

const ApplyPostStatus = ({ filters, ...props }: Props) => {
  const company = useContext(CompanyContext);
  const { properties, onChange } = props;

  if (company.statuses.length === 0) {
    return null;
  }

  const options: Option[] = company.statuses.map((status: CompanyPostStatus) => ({
    label: status.name,
    value: status._id,
  }));
  const selectedOption = options.find((option) => option.value === properties.statusID);

  if (!selectedOption) {
    onChange({ statusID: company.statuses[0]._id });
    return null;
  }

  return (
    <SingleSelect
      className="grid2"
      placeholder="Select a tag"
      onChange={(option) => option && onChange({ statusID: option.value })}
      options={options}
      value={selectedOption}
      optionsMaxWidth="300px"
    />
  );
};

export default ApplyPostStatus;

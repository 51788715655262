import {
  Invalidate,
  LoadingMore,
  QueryError,
  QueryLoaded,
  RequestQuery,
  getCompanyQueryKey,
} from '../actions/thirdPartyCompanyQueries';

export default function thirdPartyCompanyQueries(state = {}, action) {
  switch (action.type) {
    case RequestQuery: {
      const queryKey = getCompanyQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case QueryLoaded: {
      const companyIDs = [];
      action.result.companies.forEach((company) => {
        companyIDs.push(company._id);
      });
      const queryKey = getCompanyQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          companyIDs,
          hasNextPage: action.result.hasNextPage,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case QueryError: {
      const queryKey = getCompanyQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LoadingMore: {
      const queryKey = getCompanyQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case Invalidate: {
      return {};
    }

    default:
      return state;
  }
}

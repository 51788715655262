import { Component } from 'react';

import { compose } from 'redux';

import { loadRoadmapPosts } from 'common/actions/sharedRoadmapPosts';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { Board } from 'common/api/endpoints/boards';
import type { RoadmapPost, Status } from 'common/api/endpoints/roadmapPosts';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { Category } from 'common/api/resources/board';
import type { RoadmapsState } from 'common/reducers/sharedRoadmap';
import type { RoadmapPostsState } from 'common/reducers/sharedRoadmapPosts';

const EmptyRoadmapPosts: {
  posts: RoadmapPost[];
  distinctBoards?: Board[] | undefined;
  distinctCategories?: Category[] | undefined;
  distinctOwners?: StrippedUser[] | undefined;
  statuses?: Status[] | undefined;
} = { posts: [] };
const asyncFetch = {
  key: 'asyncSharedRoadmapPosts',
  promise: ({ store: { dispatch, getState }, params }: PromiseOptions) => {
    const state = getState();

    const { sharedRoadmap } = state ?? {};
    const roadmaps = sharedRoadmap?.roadmaps ?? {};
    const roadmap = roadmaps[params.roadmapViewID] ?? null;

    const company = state.company;

    if (!company || !roadmap) {
      return;
    }

    return dispatch(loadRoadmapPosts(roadmap, params.roadmapViewID));
  },
};

type Props = {
  sharedRoadmap: RoadmapsState;
  sharedRoadmapPosts: RoadmapPostsState;
  roadmapPostsLoading: boolean;
  params: { roadmapViewID: string };
  location: { pathname: string };
};

const getPostState = (
  sharedRoadmap: RoadmapsState,
  sharedRoadmapPosts: RoadmapPostsState,
  roadmapViewID: string
) => {
  const roadmapID = sharedRoadmap?.roadmaps?.[roadmapViewID]?._id;
  if (!roadmapID) {
    return EmptyRoadmapPosts;
  }

  const sharedPosts = sharedRoadmapPosts.roadmapPosts?.[roadmapID]?.[roadmapViewID];

  return sharedPosts ?? EmptyRoadmapPosts;
};

class SharedRoadmapPostsContainer extends Component<Props> {
  static asyncConnect = asyncFetch;

  render() {
    const { children, sharedRoadmap, sharedRoadmapPosts, params } = this.props;

    return cloneElementWithProps(children, {
      ...this.props,
      roadmapPosts: getPostState(sharedRoadmap, sharedRoadmapPosts, params.roadmapViewID),
    });
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({
    company: state.company,
    boards: state.boards,
    sharedRoadmap: state.sharedRoadmap,
    sharedRoadmapPosts: state.sharedRoadmapPosts,
    roadmapPostsLoading:
      state.reduxAsyncConnect.loadState?.asyncSharedRoadmapPosts?.loading ?? false,
  }))
)(SharedRoadmapPostsContainer);

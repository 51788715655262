export const isPublicView = (pathname: string) => {
  if (pathname === '/admin') {
    return false;
  } else if (pathname.startsWith('/admin/')) {
    return false;
  } else if (pathname.startsWith('/register/')) {
    return false;
  } else if (pathname === '/join') {
    return false;
  }

  return true;
};

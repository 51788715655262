import { Component } from 'react';

import PropTypes from 'prop-types';

import { loadPostActivity } from '../actions/postsActivity';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

const asyncFetch = {
  promise: ({ store: { dispatch, getState }, location: { pathname }, params }) => {
    if (!params.postURLName) {
      return;
    }

    const internalComments = pathname.startsWith('/admin/') ? 'allowed' : 'none';
    const { boardURLName, postURLName } = params;
    const { boards, posts, widget } = getState();
    const board = widget ? widget.board : boards.items[boardURLName];
    const post = board && posts[board._id] && posts[board._id][postURLName];
    return dispatch(
      loadPostActivity(post, {
        limit: 10,
        internalComments,
        sort: 'newest',
      })
    );
  },
};

class PostActivityContainer extends Component {
  static propTypes = {
    params: PropTypes.object,
  };

  render() {
    const newProps = { ...this.props };

    const { children, params } = this.props;
    if (params.postURLName) {
      const { board, posts, postsActivity } = this.props;
      const post = board && posts[board._id] && posts[board._id][params.postURLName];
      const postActivity = post && postsActivity && postsActivity[post._id];
      newProps.postActivity = postActivity;
    }

    return cloneElementWithProps(children, newProps);
  }
}

export default asyncConnect([asyncFetch], (state) => ({ postsActivity: state.postsActivity }))(
  PostActivityContainer
);

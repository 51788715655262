export enum AlphanumericConditions {
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  contains = 'contains',
  notContains = 'notContains',
}

export enum DropdownConditions {
  is = 'is',
}

export enum MultiselectConditions {
  isOneOf = 'isOneOf',
}

export enum NumericConditions {
  equals = 'equals',
  doesNotEqual = 'doesNotEqual',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan',
}

export type PostCustomFieldFilterConditions =
  | DropdownConditions
  | MultiselectConditions
  | AlphanumericConditions
  | NumericConditions;

export enum InputTypes {
  text = 'text',
  number = 'number',
  dropdown = 'dropdown',
}

const conditionInputMapping: Record<PostCustomFieldFilterConditions, InputTypes | null> = {
  [AlphanumericConditions.isEmpty]: null,
  [AlphanumericConditions.isNotEmpty]: null,
  [AlphanumericConditions.contains]: InputTypes.text,
  [AlphanumericConditions.notContains]: InputTypes.text,
  [NumericConditions.equals]: InputTypes.number,
  [NumericConditions.doesNotEqual]: InputTypes.number,
  [NumericConditions.greaterThan]: InputTypes.number,
  [NumericConditions.lessThan]: InputTypes.number,
  [DropdownConditions.is]: InputTypes.dropdown,
  [MultiselectConditions.isOneOf]: InputTypes.dropdown,
};

export const getConditionInputType = (condition: PostCustomFieldFilterConditions) => {
  return conditionInputMapping[condition] ?? null;
};

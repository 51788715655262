export default {
  changelog: true,
  'customer feedback': true,
  'feature requests': true,
  feedback: true,
  'give feedback': true,
  holla: true,
  roadmap: true,
  'user feedback': true,
};

export enum CustomPostFieldTypes {
  integer = 'integer',
  multilineText = 'multilineText',
  multiselect = 'multiselect',
  dropdown = 'dropdown',
  text = 'text',
}

export interface CustomPostField {
  _id: string;
  name: string;
  options?: string[];
  type: CustomPostFieldTypes;
  value: any;
}

export type BoardField = CustomPostField & {
  customPostFieldID: string;
  label: string;
  order: number;
  required: boolean;
  placeholder: string;
};

import { Component } from 'react';

import { loadRoadmap } from 'common/actions/roadmaps';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

const asyncFetch = {
  promise: async ({ store: { dispatch, getState }, params }) => {
    if (!params.roadmapURLName) {
      return;
    }

    const state = getState();
    const roadmap = state.roadmaps?.roadmaps?.find((roadmap) => {
      return roadmap.urlName === params.roadmapURLName;
    });
    if (roadmap) {
      return;
    }

    // User must have loaded a past urlName this roadmap used to have
    return dispatch(loadRoadmap(params.roadmapURLName));
  },
};

class RoadmapContainer extends Component {
  static asyncConnect = asyncFetch;

  render() {
    const { children, oldURLNames, params, roadmaps } = this.props;
    const urlName = oldURLNames?.[params.roadmapURLName] ?? params.roadmapURLName;

    const roadmap = roadmaps.find((roadmap) => {
      return roadmap.urlName === urlName && !roadmap.archived;
    });

    return cloneElementWithProps(children, {
      ...this.props,
      roadmap,
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  oldURLNames: state.roadmaps?.oldURLNames,
}))(RoadmapContainer);

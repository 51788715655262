import { getPostQueryKey } from 'common/util/filterPosts';

import {
  RemovePostFromAllRoadmaps,
  RequestRoadmapPosts,
  RoadmapPostsError,
  RoadmapPostsLoaded,
  RoadmapPostsTasksLoaded,
} from '../actions/roadmapPosts';

import type { RoadmapPost, RoadmapPostResponse, Status } from 'common/api/endpoints/roadmapPosts';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { User } from 'common/api/endpoints/users';
import type { Board, Category } from 'common/api/resources/board';
import type { Post } from 'common/api/resources/posts';

const removeUnnecessaryGroups = <T extends Category | Board | Status | User>(
  currentRoadmap: RoadmapPostState,
  currentPosts: RoadmapPost[],
  currentGroupValues: T[],
  deletedPostID: string,
  updatedPostList: RoadmapPost[],
  groupKey: keyof Post,
  groupName: Exclude<keyof RoadmapPostResponse, 'roadmapPosts'>
): T[] => {
  const deletedPost = currentPosts.find((post) => post.postID === deletedPostID);
  const deletedPostGroupValue = deletedPost?.post?.[groupKey];

  if (!updatedPostList.find((post) => post.post[groupKey] === deletedPostGroupValue)) {
    // Assert that the groupKey and groupName will have the same return type
    return currentRoadmap[groupName].filter((group) => group._id !== deletedPostGroupValue) as T[];
  }

  return currentGroupValues;
};

const getRoadmapPostGroupValues = (
  state: RoadmapPostsState,
  roadmapID: string,
  updatedPostList: RoadmapPost[],
  postID: string
) => {
  const currentPosts = state[roadmapID].posts;
  const currentRoadmap = state[roadmapID];

  const updatedCategoryList = removeUnnecessaryGroups(
    currentRoadmap,
    currentPosts,
    state[roadmapID].distinctCategories,
    postID,
    updatedPostList,
    'categoryID',
    'distinctCategories'
  );

  const updatedBoardList = removeUnnecessaryGroups(
    currentRoadmap,
    currentPosts,
    state[roadmapID].distinctBoards,
    postID,
    updatedPostList,
    'boardID',
    'distinctBoards'
  );

  const updatedOwnerList = removeUnnecessaryGroups(
    currentRoadmap,
    currentPosts,
    state[roadmapID].distinctOwners,
    postID,
    updatedPostList,
    'authorID',
    'distinctOwners'
  );

  const updatedStatuses = removeUnnecessaryGroups(
    currentRoadmap,
    currentPosts,
    state[roadmapID].statuses,
    postID,
    updatedPostList,
    'status',
    'statuses'
  );

  return { updatedCategoryList, updatedBoardList, updatedOwnerList, updatedStatuses };
};

// TODO: Import this once the actions are typed
type Action = any;

export interface RoadmapPostState extends Omit<RoadmapPostResponse, 'roadmapPosts'> {
  error: string | null;
  lastUpdated: number;
  loading: boolean;
  posts: RoadmapPost[];
  queryParams: { eta?: string; roadmap: Roadmap }; // TODO: extend this type and move it to a common location
}

export type RoadmapPostsState = Record<string, RoadmapPostState>;

export default function roadmapPosts(state: RoadmapPostsState = {}, action: Action) {
  switch (action.type) {
    case RemovePostFromAllRoadmaps: {
      const postsByRoadmap: RoadmapPostsState = {};
      const postID = action.post._id;

      Object.keys(state).forEach((roadmapID) => {
        const updatedPostList = state[roadmapID].posts.filter((post) => post.postID !== postID);

        const { updatedCategoryList, updatedBoardList, updatedOwnerList, updatedStatuses } =
          getRoadmapPostGroupValues(state, roadmapID, updatedPostList, postID);

        postsByRoadmap[roadmapID] = {
          ...state[roadmapID],
          distinctBoards: updatedBoardList,
          distinctCategories: updatedCategoryList,
          distinctOwners: updatedOwnerList,
          error: null,
          posts: updatedPostList,
          lastUpdated: action.timestamp,
          loading: false,
          statuses: updatedStatuses,
        };
      });

      return postsByRoadmap;
    }

    case RequestRoadmapPosts: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: {
          distinctBoards: [],
          distinctCategories: [],
          distinctOwners: [],
          error: null,
          posts: [],
          lastUpdated: action.timestamp,
          loading: true,
          statuses: [],
          queryParams: { eta: action.queryParams.eta, roadmap: action.queryParams.roadmap },
        },
      });
    }

    case RoadmapPostsLoaded: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          distinctBoards: action.boards,
          distinctCategories: action.categories,
          distinctOwners: action.owners,
          error: null,
          posts: action.roadmapPosts,
          lastUpdated: action.timestamp,
          loading: false,
          statuses: action.statuses,
          queryParams: { eta: action.queryParams.eta, roadmap: action.queryParams.roadmap },
        }),
      });
    }

    case RoadmapPostsTasksLoaded: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          posts: state[queryKey]?.posts.map((roadmapPost) => {
            const postTasks = action.postTasks[roadmapPost.post._id];
            if (postTasks) {
              return { ...roadmapPost, post: { ...roadmapPost.post, ...postTasks } };
            }
            return roadmapPost;
          }),
          lastUpdated: action.timestamp,
        }),
      });
    }

    case RoadmapPostsError: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          distinctBoards: [],
          distinctCategories: [],
          distinctOwners: [],
          error: action.error,
          posts: [],
          lastUpdated: action.timestamp,
          loading: false,
          statuses: [],
          queryParams: { eta: action.queryParams.eta, roadmap: action.queryParams.roadmap },
        }),
      });
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import Form from 'common/Form';
import withContexts from 'common/util/withContexts';

import Modal from './Modal';
import { reloadCompany } from '../actions/company';
import AJAX from '../AJAX';
import connect from '../core/connect';
import Button from '../inputs/Button';
import TextInput from '../inputs/TextInput';
import validateInput from '../validateInput';

import 'css/components/company/_ChangeInvoiceEmailModal.scss';

class ChangeInvoiceEmailModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    company: PropTypes.shape({
      billingData: PropTypes.shape({
        invoiceEmail: PropTypes.string,
      }),
    }),
  };

  state = {
    newEmail: this.props.company.billingData.invoiceEmail,
    saving: false,
  };

  onChange = (e) => {
    this.setState({
      newEmail: e.target.value,
    });
  };

  onSave = () => {
    this.setState({
      saving: true,
    });

    AJAX.post(
      '/api/billing/changeInvoiceEmail',
      {
        invoiceEmail: this.state.newEmail,
      },
      (response) => {
        this.props.reloadCompany().then(() => {
          this.setState({
            saving: false,
          });
          this.props.closeModal();
        });
      }
    );
  };

  render() {
    const { newEmail, saving } = this.state;
    const isEmailValid = validateInput.email(newEmail);
    return (
      <Modal className="changeInvoiceEmailModal">
        <Form
          addEventsToDocument={false}
          disableSubmit={!isEmailValid || saving}
          onSubmit={this.onSave}>
          <div className="heading">Where would you like invoices to be sent?</div>
          <div className="content">
            <TextInput defaultValue={this.state.newEmail} onChange={this.onChange} />
            <Button
              buttonType="cannyButton"
              disabled={!isEmailValid}
              formButton={true}
              loading={this.state.saving}
              tint={true}
              value="Save"
            />
          </div>
        </Form>
      </Modal>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withContexts(
    {
      closeModal: CloseModalContext,
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(ChangeInvoiceEmailModal);

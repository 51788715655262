import type { SavedView, Settings } from 'common/api/endpoints/roadmaps';

export type ViewDraft = {
  hiddenColumnIDs: string[];
  filterQuery: string;
};

export const mapViewsToOptions = (views: SavedView[]) =>
  views.map((view) => ({
    label: view.name,
    value: view._id,
  }));

export const findLastActiveFilter = (settings: Settings, views: SavedView[]) => {
  const selectedView = views.find(
    (savedView) => settings.lastActiveFilters?.viewID === savedView._id
  );

  return selectedView?.filterQuery || settings.lastActiveFilters?.filterQuery;
};

export const getCurrentSavedView = (initialView: SavedView | undefined) => {
  if (initialView) {
    const mappedView = mapViewsToOptions([initialView]);
    return mappedView[0];
  }

  return null;
};

import React from 'react';

import classNames from 'classnames';

import IconBadge from './IconBadge';
import { RadioContext } from './RadioGroupV2';

import type { LucideProps } from 'lucide-react';

import 'css/components/_RadioButtonV2.scss';

const useRadioContext = () => {
  const context = React.useContext(RadioContext);
  if (!context) {
    throw new Error('Radio button should be used inside radio group');
  }
  return context;
};

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  value: string;
  disabled?: boolean;
  badge?: React.ComponentType<LucideProps>;
}

const RadioButtonV2 = ({ children, className, value, disabled, badge, ...props }: Props) => {
  const {
    error,
    value: radioGroupValue,
    onChange,
    size = 'medium',
    name,
    disabled: radioGroupDisabled,
  } = useRadioContext();
  const checked = value === radioGroupValue;
  const isDisabled = radioGroupDisabled || disabled;
  return (
    <label
      className={classNames(className, 'radioLabel', {
        [size]: true,
        disabled: isDisabled,
        error,
      })}>
      <input
        name={name}
        {...props}
        value={value}
        className="radioButton"
        checked={checked}
        type="radio"
        disabled={isDisabled}
        aria-checked={checked}
        onChange={({ target }) => onChange?.(target.value)}
      />
      <span className="radioCircle">
        <span className="radioInnerCircle"></span>
      </span>
      {children}
      {badge && <IconBadge icon={badge} size={size} active={checked} disabled={isDisabled} />}
    </label>
  );
};

export default RadioButtonV2;

import React, { useContext, useEffect, useState } from 'react';

import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import AJAX from 'common/AJAX';
import StripeContext from 'common/contexts/StripeContext';
import ModalPortal from 'common/modals/ModalPortal';
import ModernModal from 'common/modals/ModernModal';
import SpinnerV2 from 'common/SpinnerV2';
import ButtonV2 from 'common/ui/ButtonV2';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import 'css/components/subdomain/admin/billing/_AdminCCModal.scss';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  secret: string;
};

const CreditCardButtons = (props: Props) => {
  const { secret, onClose, onSuccess } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async () => {
    setError(null);

    if (!stripe) {
      return;
    }

    if (!elements) {
      return;
    }

    setSubmitting(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setSubmitting(false);
      return;
    }

    const { error: confirmError, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret: secret,
      confirmParams: {
        return_url: 'https://canny.io/redirect?to=/admin/settings/billing',
      },
      redirect: 'if_required',
    });
    if (confirmError) {
      const message = confirmError.message || 'Something went wrong';
      setError(message);
      setSubmitting(false);
      return;
    }

    const paymentMethodID = setupIntent.payment_method;
    const response = await AJAX.post('/api/billing/updateCard', { paymentMethodID });
    const { error: updateError } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Failed to update card, please contact support.',
        'not authorized': "You do not have permission to update your team's credit card.",
      },
    });
    if (updateError) {
      setError(updateError.message);
      setSubmitting(false);
      return;
    }

    onSuccess();
  };

  return (
    <div>
      <div className="buttons">
        <ButtonV2 className="cancelButton" onClick={onClose} size="medium" variant="outlined">
          Cancel
        </ButtonV2>
        <ButtonV2 className="submitButton" loading={submitting} onClick={onSubmit} size="medium">
          Submit
        </ButtonV2>
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

type ModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const AdminCCModal = (props: ModalProps) => {
  const { onClose, onSuccess } = props;

  const stripe = useContext(StripeContext);

  const [error, setError] = useState<string | null>(null);
  const [secret, setSecret] = useState<string | null>(null);

  useEffect(() => {
    const fetchSecret = async () => {
      // Your code here
      const response = await AJAX.post('/api/billing/updateCardSetup');
      const { error: responseError, parsedResponse } = parseAPIResponse<{ token: string }>(
        response,
        {
          isSuccessful: (response) => 'token' in response,
          errors: {
            default: 'Failed to initialize card update, please contact support.',
            'not authorized': "You do not have permission to update your team's credit card.",
          },
        }
      );

      if (responseError) {
        setError(responseError.message);
        return;
      } else if (!parsedResponse) {
        setError('Failed to initialize card update, please contact support.');
        return;
      }

      setSecret(parsedResponse.token);
    };

    fetchSecret();
  }, [onClose]);

  if (!secret) {
    return (
      <ModalPortal className="adminCCSpinner" closeOnClickAway={false}>
        <SpinnerV2 size="xxxxlarge" />
      </ModalPortal>
    );
  }

  let contents;
  let footer;
  if (error) {
    contents = <div className="error">{error}</div>;
  } else if (secret) {
    contents = (
      <PaymentElement
        key="paymentElement"
        options={{ layout: 'tabs', wallets: { applePay: 'never', googlePay: 'never' } }}
      />
    );
    footer = <CreditCardButtons secret={secret} onClose={onClose} onSuccess={onSuccess} />;
  }

  return (
    <Elements stripe={stripe} options={{ clientSecret: secret }}>
      <ModernModal
        footer={footer}
        header="Add Payment Method"
        modalClassName="adminCCModal"
        onClose={onClose}
        sections={[contents]}
      />
    </Elements>
  );
};

export default AdminCCModal;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { removeItem } from 'common/actions/queueItemQueries';
import { invalidateRoadmap } from 'common/actions/roadmap';
import { removePostFromAllRoadmaps } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import AutopilotSourceLogo from 'common/common/AutopilotSourceLogo';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import ExternalLink from 'common/ExternalLink';
import TranslationToggle from 'common/i18n/TranslationToggle';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import ErrorModal from 'common/modals/ErrorModal';
import MarkSpamModal from 'common/modals/MarkSpamModal';
import Strings from 'common/Strings';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import { Span } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import getCannyOrigin from 'common/util/getCannyOrigin';
import hasPermission from 'common/util/hasPermission';
import isWidget from 'common/util/isWidget';
import withContexts from 'common/util/withContexts';
import { IsIntercomContext } from 'common/widget/WidgetContext';

import PostLink from './PostLink';

import 'css/components/post/_PostMenu.scss';

class PostMenu extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    getPostLink: PropTypes.func,
    isIntercom: PropTypes.bool,
    onEdit: PropTypes.func,
    onMarkedSpam: PropTypes.func,
    onPostDeleted: PropTypes.func,
    openModal: PropTypes.func,
    post: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      authorID: PropTypes.string,
      created: PropTypes.string,
    }).isRequired,
    router: PropTypes.object,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  state = {
    needsInvalidate: false,
  };

  componentWillUnmount() {
    const { needsInvalidate } = this.state;
    if (!needsInvalidate) {
      return;
    }

    const { post } = this.props;
    this.props.invalidatePost(post);
  }

  onDeleteTapped = () => {
    const { company, openModal, post, viewer } = this.props;
    const { viewerIsMember } = company;
    const viewerIsAuthor = post.authorID && post.authorID === viewer._id;
    const viewerIsBae = !!viewer.cannyBae;
    const authorCanDelete = post.commentCount === 0 && post.voteSettings.lowEngagement;

    const canSkipPermissionCheck =
      viewerIsBae || (viewerIsAuthor && authorCanDelete) || !viewerIsMember;

    if (!canSkipPermissionCheck) {
      if (!hasPermission('deletePosts', company, viewer)) {
        openModal(AccessModal, {
          requiredPermissions: ['deletePosts'],
        });
        return;
      }
    }

    openModal(ConfirmModal, {
      message: "Are you sure you'd like to delete this post?",
      onConfirm: this.onConfirmedDelete,
    });
  };

  onMarkedSpamTapped = () => {
    const { company, openModal, viewer } = this.props;
    const viewerIsBae = !!viewer.cannyBae;

    if (!viewerIsBae) {
      if (!hasPermission('deleteUsers', company, viewer)) {
        openModal(AccessModal, {
          requiredPermissions: ['deleteUsers'],
        });
        return;
      }
    }

    openModal(MarkSpamModal, {
      onConfirm: this.onConfirmedMarkedSpam,
    });
  };

  onConfirmedDelete = () => {
    const { board, invalidateData, onPostDeleted, openModal, post } = this.props;
    AJAX.post(
      '/api/posts/delete',
      {
        postID: post._id,
      },
      (response) => {
        if (response !== 'success') {
          openModal(ErrorModal, {
            message: Strings.miscError,
          });
          return;
        }

        invalidateData(board, post).then(() => {
          this.setState({
            needsInvalidate: true,
          });

          onPostDeleted();
        });
      }
    );
  };

  onConfirmedMarkedSpam = () => {
    const { board, invalidateData, onMarkedSpam, openModal, post } = this.props;
    AJAX.post(
      '/api/spam/mark',
      {
        postID: post._id,
      },
      (response) => {
        if (response !== 'success') {
          openModal(ErrorModal, {
            message: Strings.miscError,
          });
          return;
        }

        invalidateData(board, post).then(() => {
          this.setState({
            needsInvalidate: true,
          });

          onMarkedSpam();
        });
      }
    );
  };

  renderEditButton() {
    const {
      company: { viewerIsMember },
      onEdit,
      post,
      viewer,
    } = this.props;
    const viewerIsAuthor = post.authorID && post.authorID === viewer._id;
    const viewerIsBae = !!viewer.cannyBae;

    if (!viewerIsAuthor && !viewerIsMember && !viewerIsBae) {
      return null;
    } else if (!viewerIsMember && !viewerIsBae && post.voteSettings.highEngagement) {
      return null;
    }

    return (
      <Tappable key="edit" onTap={onEdit}>
        <div className="editLink">Edit&nbsp;Post</div>
      </Tappable>
    );
  }

  renderDeleteLink() {
    if (!this.props.onPostDeleted) {
      return null;
    }

    const {
      company: { viewerIsMember },
      post,
      viewer,
    } = this.props;
    const viewerIsAuthor = post.authorID && post.authorID === viewer._id;
    const viewerIsBae = !!viewer.cannyBae;

    if (!viewerIsAuthor && !viewerIsMember && !viewerIsBae) {
      return null;
    } else if (viewerIsAuthor && !viewerIsMember && !viewerIsBae) {
      const authorCanDelete = post.commentCount === 0 && post.voteSettings.lowEngagement;
      if (!authorCanDelete) {
        return null;
      }
    }

    return (
      <Tappable key="delete" onTap={this.onDeleteTapped}>
        <div className="deleteLink">Delete&nbsp;Post</div>
      </Tappable>
    );
  }

  renderMarkSpam() {
    if (!this.props.onMarkedSpam) {
      return null;
    }

    const {
      company: { viewerIsMember },
      viewer,
    } = this.props;
    const viewerIsBae = !!viewer.cannyBae;

    if (!viewerIsMember && !viewerIsBae) {
      return null;
    }

    return (
      <Tappable key="spam" onTap={this.onMarkedSpamTapped}>
        <div className="deleteLink">Mark&nbsp;Spam</div>
      </Tappable>
    );
  }

  renderPostCreator() {
    const { post } = this.props;

    if (!post?.by?.name) {
      return null;
    }

    return (
      <div key="createdBy" className="creatorName">
        Created by {post.by.name}
      </div>
    );
  }

  renderSource() {
    const {
      post: { sourceFeatureExtractionItem },
    } = this.props;

    if (!sourceFeatureExtractionItem) {
      return null;
    }

    const { link, integrationName, integrationItemSourceType } = sourceFeatureExtractionItem;

    if (!integrationName) {
      return null;
    }

    if (integrationName === 'api') {
      const linkText = integrationItemSourceType
        ? `${integrationItemSourceType.slice(0, 20)}${
            integrationItemSourceType.length > 20 ? '...' : ''
          }`
        : 'API';

      return (
        <div className="sourceLinkWrapper">
          <AutopilotSourceLogo className="sourceIcon" integrationName="api" size="small" />
          {link ? (
            <ExternalLink className="sourceLink" to={link}>
              {linkText}
            </ExternalLink>
          ) : (
            <Span className="sourceType">{linkText}</Span>
          )}
        </div>
      );
    }

    if (!link) {
      return null;
    }

    return (
      <div className="sourceLinkWrapper">
        <AutopilotSourceLogo
          className="sourceIcon"
          integrationName={integrationName}
          size="small"
        />
        <ExternalLink className="sourceLink" to={link}>
          View Source
        </ExternalLink>
      </div>
    );
  }

  renderTimestamp() {
    const { board, company, isIntercom, post } = this.props;
    if (!post.created) {
      return null;
    }

    if (isIntercom) {
      const postURL = devURL(getCannyOrigin(company) + '/' + board.urlName + '/p/' + post.urlName);
      return (
        <a className="timestampLink" href={postURL} rel="noopener" target="_blank">
          <Timestamp timestamp={post.created} />
        </a>
      );
    }

    return (
      <PostLink key="createdDate" className="timestampLink" post={post}>
        <Timestamp timestamp={post.created} />
      </PostLink>
    );
  }

  renderTranslationToggle() {
    const { post } = this.props;
    return (
      <TranslationToggle
        className="translationToggle"
        hideTranslation={this.props.hideTranslation}
        object={post}
        onToggle={this.props.onToggleTranslation}
        stringKeys={['details', 'status', 'title']}
        variant="text"
      />
    );
  }

  render() {
    return (
      <div className="postMenu">
        {this.renderPostCreator()}
        {this.renderTimestamp()}
        {this.renderSource()}
        {this.renderTranslationToggle()}
        {this.renderEditButton()}
        {this.renderMarkSpam()}
        {this.renderDeleteLink()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateData: (board, post) => {
      const promises = [];
      if (!isWidget()) {
        promises.push(dispatch(reloadBoard(board.urlName)));
        promises.push(dispatch(invalidateDashboardActivity()));
        promises.push(dispatch(invalidateRoadmap()));
        promises.push(dispatch(invalidateSuggestions()));
        promises.push(dispatch(removePostFromAllRoadmaps(post)));
        promises.push(dispatch(removeItem(post)));
      }

      promises.push(dispatch(invalidatePostQueries()));

      return Promise.all(promises);
    },
    invalidatePost: (post) => {
      return dispatch(reloadPost(post));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      getPostLink: GetPostLinkContext,
      isIntercom: IsIntercomContext,
      openModal: OpenModalContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostMenu);

export default function groupify<T extends object>(objects: T[], key: keyof T) {
  const map: Record<string, T[]> = {};

  objects.forEach((object) => {
    if (!object.hasOwnProperty(key)) {
      return;
    }

    const value = String(object[key]);
    if (!map[value]) {
      map[value] = [];
    }
    map[value].push(object);
  });

  return map;
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import withContexts from 'common/util/withContexts';

import Helmet from './Helmet';

class SubdomainPostVotersHelmet extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
    }),
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    post: PropTypes.shape({
      title: PropTypes.string,
    }),
  };

  render() {
    const { board, company, post } = this.props;
    if (!post || !post.title || !board || !board.name || !company || !company.name) {
      return null;
    }

    const title = post.title + ' | Voters | ' + company.name;
    return <Helmet title={title} />;
  }
}

export default withContexts({ company: CompanyContext })(SubdomainPostVotersHelmet);

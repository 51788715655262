import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import ChangelogLoginForm from 'common/subdomain/public/ChangelogLoginForm';
import withContexts from 'common/util/withContexts';

import { loadChangelog } from '../actions/changelog';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

const asyncFetch = {
  promise: ({ store: { dispatch, getState } }) => {
    return dispatch(loadChangelog());
  },
};

class ChangelogContainer extends Component {
  static asyncConnect = asyncFetch;

  static propTypes = {
    changelog: PropTypes.object,
    company: PropTypes.shape({
      changelog: PropTypes.object,
    }),
  };

  render() {
    const newProps = { ...this.props };
    const { children, changelog, company } = newProps;
    if (company.changelog && !company.changelog.viewerHasAccess) {
      return <ChangelogLoginForm />;
    }

    delete newProps.company;
    newProps.changelog = changelog && changelog.labels ? changelog : null;
    return cloneElementWithProps(children, newProps);
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({ changelog: state.changelog })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(ChangelogContainer);

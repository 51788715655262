type ObjectType<T extends string> = {
  [key in T]: string | Date | number;
};

type ReturnType<T extends string> = (a: ObjectType<T>, b: ObjectType<T>) => -1 | 0 | 1;

export default function stringSort<T extends string>(
  key: T,
  order = 'asc',
  caseInsensitive = false
): ReturnType<T> {
  return function (a, b) {
    let aValue = a[key];
    let bValue = b[key];
    if (caseInsensitive) {
      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
      }
      if (typeof bValue === 'string') {
        bValue = bValue.toLowerCase();
      }
    }

    if (aValue === bValue) {
      return 0;
    } else if (aValue < bValue) {
      return order === 'desc' ? 1 : -1;
    } else {
      return order === 'desc' ? -1 : 1;
    }
  };
}

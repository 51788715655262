import { Component } from 'react';

import { loadVoters } from '../actions/voters';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

class PostVoterContainer extends Component {
  render() {
    const { children, post, voters } = this.props;
    const postVoters = (post && voters[post._id] && voters[post._id].items) || null;
    return cloneElementWithProps(children, {
      ...this.props,
      voters: postVoters,
    });
  }
}

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState }, params }) => {
        const state = getState();
        const { boards, posts, widget } = state;
        const board = widget ? widget.board : boards.items[params.boardURLName];
        const post = board && posts[board._id] && posts[board._id][params.postURLName];
        return dispatch(loadVoters(post));
      },
    },
  ],
  (state) => ({ voters: state.voters })
)(PostVoterContainer);

import React, { useContext, useRef, useState } from 'react';

import classnames from 'classnames';

import { LocationContext } from 'common/containers/RouterContainer';
import generateRandomID from 'common/generateRandomID';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import numberWithCommas from 'common/util/numberWithCommas';

import type { Board, Category } from 'common/api/resources/board';

const getSelectedCategory = (board: Board) => {
  const params = new URLSearchParams(typeof window !== 'undefined' ? window?.location.search : '');
  const categoryURLName = params.get('category');

  if (categoryURLName && board) {
    const { categories } = board;
    return categories.find((category) => category.urlName === categoryURLName);
  }
  return undefined;
};

const AllCategoriesID = '__all__';

const optionID = (componentID: string, category?: Category) =>
  category ? `categoryDropdown-${componentID}-${category._id}` : AllCategoriesID;

type CategoryItemProps = {
  active: boolean;
  children: React.ReactNode;
  id: string;
  link: string;
  onSelect: () => void;
  subcategory: boolean;
};
const CategoryItem = ({ active, children, id, link, onSelect, subcategory }: CategoryItemProps) => {
  return (
    <Link to={link} onTap={onSelect} role="option" aria-selected={active} id={id}>
      <div className={classnames('option', { subcategory })}>{children}</div>
    </Link>
  );
};

type Props = { board: Board };
const CategoriesDropdown = ({ board }: Props) => {
  const [open, setOpen] = useState(false);
  const { pathname, query } = useContext(LocationContext);
  const componentID = useRef(generateRandomID());

  const dropdownRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  useBackgroundClick(() => {
    setOpen(false);
  }, [dropdownRef, contentRef]);

  const selectedCategory = getSelectedCategory(board);
  const selectedName = (selectedCategory ? selectedCategory.name : 'All Categories').replace(
    ' ',
    '\u00a0'
  );

  const getCategoryLink = (category: Category | null) => {
    const { category: existingCategory, ...newQuery } = query;
    if (category) {
      newQuery.category = category.urlName;
    }

    const queryParams = new URLSearchParams(newQuery);
    return `${pathname}?${queryParams.toString()}`;
  };

  return (
    <div className="dropdown">
      <button
        onClick={() => setOpen(!open)}
        className="selector"
        ref={dropdownRef}
        role="combobox"
        aria-activedescendant={optionID(componentID.current, selectedCategory)}
        aria-controls={componentID.current}
        aria-expanded={open}
        aria-haspopup="listbox">
        <div className="selectedName">{selectedName}</div>
        <div className="icon-chevron-down" aria-hidden="true" />
      </button>
      <div
        className={classnames('dropdownContent', { hidden: !open })}
        ref={contentRef}
        id={componentID.current}
        role="listbox">
        <div className="categories" role="none">
          <CategoryItem
            active={!selectedCategory}
            id={AllCategoriesID}
            link={getCategoryLink(null)}
            onSelect={() => setOpen(false)}
            subcategory={false}>
            All Categories
          </CategoryItem>
          {board.categories.map((category) => (
            <CategoryItem
              key={category._id}
              active={category._id === selectedCategory?._id}
              id={optionID(componentID.current, category)}
              link={getCategoryLink(category)}
              onSelect={() => setOpen(false)}
              subcategory={!!category.parentID}>
              {translateString(category, 'name')} ({numberWithCommas(category.postCount)})
            </CategoryItem>
          ))}
        </div>
      </div>
    </div>
  );
};
export default CategoriesDropdown;

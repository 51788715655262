import React, { Component } from 'react';

import classnames from 'classnames';
import { Settings } from 'lucide-react';
import PropTypes from 'prop-types';
import deepCompare from 'react-fast-compare';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import CategorySelector from 'common/categories/CategorySelector';
import DateRangePicker from 'common/common/DateRangePicker';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import CheckboxInput from 'common/inputs/CheckboxInput';
import RadioButtonGroup from 'common/inputs/RadioButtonGroup';
import Link from 'common/Link';
import AccessModal from 'common/modals/AccessModal';
import SavedSegmentsModal from 'common/modals/SavedSegmentsModal';
import UpsellModal from 'common/modals/UpsellModal';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import AdminCreateSavedFilterModal from 'common/subdomain/admin/AdminCreateSavedFilterModal';
import CompanyFilterSection from 'common/subdomain/admin/AdminFeedbackSidebar/CompanyFilterSection';
import AdminPageSidebar from 'common/subdomain/admin/AdminPageSidebar';
import AdminSidebarBoardSection from 'common/subdomain/admin/AdminSidebarBoardSection';
import AdminSidebarSectionItem from 'common/subdomain/admin/AdminSidebarSectionItem';
import Tag from 'common/tags/Tag';
import TagSelector from 'common/tags/TagSelector';
import Tappable from 'common/Tappable';
import capitalizeWords from 'common/util/capitalizeWords';
import { RelativeDateOptions, RelativeDateRanges } from 'common/util/dateRanges';
import escapeRegExp from 'common/util/escapeRegExp';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import AdminPicker from './AdminPicker';
import AdminSidebarSection from './AdminSidebarSection';

import 'css/components/subdomain/admin/_AdminFeedbackSidebar.scss';

const DateRangeTypes = [
  {
    default: true,
    name: 'Posts',
    filterName: 'postCreatedDateRange',
    urlName: 'post-created',
  },
  {
    name: 'Votes',
    filterName: 'voteCreatedDateRange',
    urlName: 'vote-created',
  },
];

const DefaultCheckedStatusTypes = {
  [PostStatusTypes.Initial]: true,
  [PostStatusTypes.Active]: true,
};

const DefaultSavedFilterName = 'Default';

const UpsellCTAMap = {
  categories: 'User-facing categories for organizing posts',
  postOwners: 'Assign posts to your teammates',
  userSegmentation: 'Filter feedback to user groups you care about',
  'limits.analyticsHistory': 'More than 30 days of analytics history',
};

function getFilterDateRanges(filter) {
  return DateRangeTypes.reduce((dateRanges, type) => {
    dateRanges[type.filterName] = filter[type.filterName] ?? {
      relativeRange: RelativeDateRanges.allTime,
    };
    return dateRanges;
  }, {});
}

class AdminFeedbackSidebar extends Component {
  static propTypes = {
    activeSavedFilter: PropTypes.string,
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.object,
    location: PropTypes.object,
    onUpdateActiveSavedFilter: PropTypes.func.isRequired,
    openModal: PropTypes.func,
    reloadCompany: PropTypes.func,
    router: PropTypes.object,
    showSidebar: PropTypes.bool,
    viewer: PropTypes.object,
  };

  state = {
    afterUpsell: null,
    dateRange: this.getDateRangeFromQuery(),
    filtersChanged: false,
    ownerSearchOpen: false,
    searchMode: !!this.props.location.query.search,
    segment: this.props.location.query.segment,
    selectedDateRangeType: this.getSelectedDateRangeTypeFromQuery(),
    selectedOwner: this.props.location.query.owner,
    showUpsellModal: null,
  };

  constructor(props, context) {
    super(props, context);

    this.sidebarRef = React.createRef();
    this.ownerActionRef = React.createRef();
    this.ownerRef = React.createRef();
    this.statusRefs = {};
    this.uncategorizedRef = React.createRef();
    this.untaggedRef = React.createRef();
  }

  componentDidMount() {
    this.createCheckboxesRefs();
    this.inferActiveSavedFilter();
  }

  componentDidUpdate(prevProps) {
    const {
      activeSavedFilter,
      company,
      boards,
      location: { query },
    } = this.props;
    const {
      location: { query: prevQuery },
    } = prevProps;
    const { search } = query;
    const { search: prevSearch } = prevQuery;
    const isSearching = search && !!search.trim();
    const wasSearching = prevSearch && !!prevSearch.trim();

    // no board means there's no feedback to show
    if (boards.length === 0) {
      return null;
    }

    if (activeSavedFilter !== prevProps.activeSavedFilter) {
      this.applySavedFilter();
      return;
    }

    const defaultStatuses = company.statuses.filter((status) =>
      [PostStatusTypes.Complete, PostStatusTypes.Closed].includes(status.type)
    );
    if (isSearching && !wasSearching) {
      if (!query.status) {
        defaultStatuses.forEach((status) => this.statusRefs[status._id].current.setValue(true));
      }
    } else if (!isSearching && wasSearching) {
      if (!query.status) {
        defaultStatuses.forEach((status) => this.statusRefs[status._id].current.setValue(false));
      }
    }

    const areDateRangesMatching = DateRangeTypes.every(
      (dateRange) => query[dateRange.urlName] === prevQuery[dateRange.urlName]
    );
    if (!areDateRangesMatching) {
      this.setState({
        dateRange: this.getDateRangeFromQuery(),
        selectedDateRangeType: this.getSelectedDateRangeTypeFromQuery(),
      });
    }

    if (query.owner !== prevQuery.owner || query.unassigned !== prevQuery.unassigned) {
      const { viewer } = this.props;
      if (query.unassigned) {
        this.ownerRef.current.setValue('none');
      } else if (!query.owner) {
        this.ownerRef.current.setValue('all');
      } else if (query.owner === viewer.urlName) {
        this.ownerRef.current.setValue('me');
      } else {
        this.setState(
          {
            selectedOwner: query.owner,
          },
          () => {
            this.ownerRef.current.setValue(query.owner);
          }
        );
      }
    }

    if (query.segment !== prevQuery.segment) {
      if (!query.segment) {
        this.setState({
          segment: null,
        });
      } else {
        this.setState({
          segment: query.segment,
        });
      }
    }

    if (query.status !== prevQuery.status) {
      const { company } = this.props;
      company.statuses.forEach((status) => {
        const statusRegExp = `(^|_)${escapeRegExp(status.urlName)}(_|$)`;
        const isCheckedByDefault = DefaultCheckedStatusTypes[status.type];
        const isStatusSelected =
          typeof query.status === 'string'
            ? query.status.match(statusRegExp)
            : typeof query.search === 'string' || isCheckedByDefault;
        const wasStatusSelected =
          typeof prevQuery.status === 'string'
            ? prevQuery.status.match(statusRegExp)
            : typeof prevQuery.search === 'string' || isCheckedByDefault;
        if (isStatusSelected === wasStatusSelected) {
          return;
        }
        this.statusRefs[status._id].current.setValue(isStatusSelected);
      });
    }

    if (query.untagged !== prevQuery.untagged) {
      this.untaggedRef.current.setValue(query.untagged === 'true');
    }

    if (query.uncategorized !== prevQuery.uncategorized) {
      this.uncategorizedRef.current.setValue(query.uncategorized === 'true');
    }

    // Wait for all setStates to finish
    setTimeout(() => {
      this.checkSavedFilterChange();
    }, 0);
  }

  createCheckboxesRefs = () => {
    const { company } = this.props;

    company.statuses.forEach((status) => {
      this.statusRefs[status._id] = React.createRef();
    });
  };

  applySavedFilter() {
    const { activeSavedFilter, boards, company, location, router, viewer } = this.props;
    const { search } = location.query;

    const filter = company.savedFilters.find((filter) => filter.name === activeSavedFilter);
    if (!filter) {
      // This is the default filter, so remove any active filters.
      router.replace({ pathname: location.pathname, query: { search } });
      return;
    }

    const { boardIDs, categoryIDs, tagIDs } = filter;

    // boards
    const selectedBoards = boards.filter((board) => boardIDs.includes(board._id));
    const allBoardsSelected = boards.length === selectedBoards.length;
    const boardsQuery = allBoardsSelected
      ? undefined
      : selectedBoards.map((board) => board.urlName).join('_');

    // categories
    const selectedCategories = selectedBoards
      .map((board) => {
        return board.categories.filter((category) => categoryIDs.includes(category._id));
      })
      .flat();
    const categoriesQuery = selectedCategories.map((category) => category.urlName).join('_');

    // companies
    const companyURLNames = filter?.companyURLNames?.join('_');
    const accountOwnerName = filter?.accountOwnerName;

    // created date ranges
    const dateRanges = DateRangeTypes.reduce((dateRanges, type) => {
      const { filterName, urlName } = type;
      const dateRange = filter[filterName];

      if (!dateRange) {
        return dateRanges;
      }

      if (dateRange.relativeRange && dateRange.relativeRange !== RelativeDateRanges.allTime) {
        dateRanges[urlName] = dateRange.relativeRange;
        return dateRanges;
      }

      if (dateRange.fixedRange) {
        dateRanges[urlName] = dateRange.fixedRange.join('_');
        return dateRanges;
      }

      return dateRanges;
    }, {});

    // owner
    let owner = null;
    if (filter.ownerID) {
      owner =
        company.members.find((member) => {
          return member._id === filter.ownerID;
        })?.urlName ?? null;
    } else if (filter.owner === 'me') {
      owner = viewer.urlName;
    }

    // search
    const isSearching = search && !!search.trim();

    // segment
    const segment = company.segments.find(({ _id }) => _id === filter.segmentID)?.urlName;

    // sort
    let sort = filter.sort;
    if (sort === 'newest') {
      sort = 'new';
    } else if (sort === 'oldest') {
      sort = 'old';
    } else if (sort === 'score') {
      sort = 'top';
    } else if (sort === 'trendingScore') {
      // Not required in the url because it's the default
      sort = undefined;
    }

    // statuses
    const statusURLNames = [];
    company.statuses.forEach((status) => {
      if (filter.statuses.includes(status.urlName)) {
        statusURLNames.push(status.urlName);
      }
    });

    const statusesFilter = statusURLNames.join('_');
    const statusesCheckedByDefault = company.statuses.filter(
      (status) => DefaultCheckedStatusTypes[status.type]
    );
    const areAllStatusesSelected = statusURLNames.length === company.statuses.length;
    const areDefaultStatusesSelected =
      statusesFilter === statusesCheckedByDefault.map((status) => status.urlName).join('_');
    const status = isSearching
      ? areAllStatusesSelected
        ? undefined
        : statusesFilter
      : areDefaultStatusesSelected
      ? undefined
      : statusesFilter;

    // tags
    const selectedTags = selectedBoards
      .map((board) => {
        return board.tags.filter((tag) => tagIDs.includes(tag._id));
      })
      .flat();
    const tagsQuery = selectedTags.map((tag) => tag.urlName).join('_');

    // unassigned
    const unassigned = filter.owner === 'none';

    // uncategorized
    const uncategorized = filter.onlyUncategorized;

    // untagged
    const untagged = filter.onlyUntagged;

    router.replace({
      pathname: location.pathname,
      query: {
        boards: boardsQuery,
        ...dateRanges,
        ...(!uncategorized && categoriesQuery && { categories: categoriesQuery }),
        ...(owner && { owner }),
        search,
        ...(segment && { segment }),
        sort,
        ...(status && { status }),
        ...(companyURLNames && { companies: companyURLNames }),
        ...(accountOwnerName && { accountOwner: accountOwnerName }),
        ...(!untagged && tagsQuery && { tags: tagsQuery }),
        ...(unassigned && { unassigned: true }),
        ...(uncategorized && { uncategorized: true }),
        ...(untagged && { untagged: true }),
      },
    });
  }

  changeSegment = (segment) => {
    this.setState({ segment }, this.updateQuery);
  };

  checkSavedFilterChange() {
    const { activeSavedFilter, company, location } = this.props;

    const activeFilter = company.savedFilters.find((filter) => filter.name === activeSavedFilter);
    if (!activeFilter) {
      const filterParams = { ...location.query };
      delete filterParams.search;
      // If there's no query params, then we know the default filter is active.
      const filtersIdentical = Object.keys(filterParams).length === 0;
      if (this.state.filtersChanged !== !filtersIdentical) {
        this.setState({ filtersChanged: !filtersIdentical });
      }
      return;
    }

    const activeConfig = {
      boardIDs: activeFilter.boardIDs,
      categoryIDs: activeFilter.categoryIDs,
      companyURLNames: activeFilter.companyURLNames,
      accountOwnerName: activeFilter.accountOwnerName,
      onlyUncategorized: activeFilter.onlyUncategorized,
      onlyUntagged: activeFilter.onlyUntagged,
      owner: activeFilter.owner,
      ownerID: activeFilter.ownerID,
      segmentID: activeFilter.segmentID,
      sort: activeFilter.sort,
      statuses: activeFilter.statuses,
      tagIDs: activeFilter.tagIDs,
      ...getFilterDateRanges(activeFilter),
    };

    const currentConfig = this.getCurrentSavedFilterStructure();
    const filtersIdentical = deepCompare(activeConfig, currentConfig);

    if (this.state.filtersChanged !== !filtersIdentical) {
      this.setState({ filtersChanged: !filtersIdentical });
    }
  }

  getDateRangeFromQuery() {
    const { query } = this.props.location;
    const rangeType = DateRangeTypes.find((type) => query[type.urlName]);
    const dateValue = query[rangeType?.urlName]?.split('_') ?? [];

    if (dateValue.length === 1) {
      const relativeDate = RelativeDateOptions[dateValue[0]];
      return relativeDate?.value;
    }

    if (dateValue.length === 2) {
      const filteredDateValue = dateValue.filter((date) =>
        validateInput.primitives.isoString(date)
      );
      const [from, to] = filteredDateValue;

      if (from === this.state?.dateRange[0] && to === this.state?.dateRange[1]) {
        // Values are the same, so return the same reference instead of a new array.
        return this.state.dateRange;
      }

      const areDatesValid = new Date(from) <= new Date(to);
      return areDatesValid ? [from, to] : RelativeDateRanges.allTime;
    }

    return RelativeDateRanges.allTime;
  }

  getSelectedDateRangeTypeFromQuery() {
    const { query } = this.props.location;
    const defaultDateRangeType = DateRangeTypes.find((type) => type.default);
    return (
      DateRangeTypes.find((type) => query[type.urlName]) ||
      this.state?.selectedDateRangeType ||
      defaultDateRangeType
    );
  }

  getSelectedBoardsFromQuery() {
    const { boards, location } = this.props;
    const { query } = location;

    const selectedBoardURLNames = query.boards?.split('_') ?? boards.map((board) => board.urlName);
    const selectedBoards = boards.filter((board) => selectedBoardURLNames.includes(board.urlName));

    return selectedBoards;
  }

  getCurrentSavedFilterStructure() {
    const { company, location } = this.props;

    // boards
    const selectedBoards = this.getSelectedBoardsFromQuery();

    // categories
    const selectedCategoryIDs = [];
    const categoryURLNames = location.query.categories?.split('_') ?? [];
    selectedBoards.forEach((board) => {
      board.categories.forEach((category) => {
        if (categoryURLNames.includes(category.urlName)) {
          selectedCategoryIDs.push(category._id);
        }
      });
    });

    // created date range
    const selectedRangeType = this.getSelectedDateRangeTypeFromQuery();
    const dateRange = this.getDateRangeFromQuery();
    const dateRanges = DateRangeTypes.reduce((dateRanges, type) => {
      if (type.name === selectedRangeType.name) {
        const rangeProp = typeof dateRange === 'string' ? 'relativeRange' : 'fixedRange';
        dateRanges[type.filterName] = { [rangeProp]: dateRange };
        return dateRanges;
      }

      dateRanges[type.filterName] = { relativeRange: RelativeDateRanges.allTime };
      return dateRanges;
    }, {});

    // owner
    const ownerValue = this.ownerRef.current && this.ownerRef.current.getValue().name;
    let ownerID = null;
    if (ownerValue && !['me', 'all', 'none'].includes(ownerValue)) {
      ownerID =
        company.members.find((member) => {
          return member.urlName === ownerValue;
        })?._id ?? null;
    }

    // segment
    const { segment } = this.state;
    const segmentObject = company.segments.find(({ urlName }) => urlName === segment);

    // sort
    let sort = location.query.sort ?? 'trending';
    if (sort === 'new') {
      sort = 'newest';
    } else if (sort === 'old') {
      sort = 'oldest';
    } else if (sort === 'top') {
      sort = 'score';
    } else if (sort === 'trending') {
      sort = 'trendingScore';
    }

    // statuses
    const statusURLNames = [];
    company.statuses.forEach((status) => {
      const ref = this.statusRefs[status._id].current;
      const isCheckedByDefault = DefaultCheckedStatusTypes[status.type];
      if (ref ? ref.getValue() : isCheckedByDefault) {
        statusURLNames.push(status.urlName);
      }
    });

    // tags
    const selectedTagIDs = [];
    const tagURLNames = location.query.tags?.split('_') ?? [];
    selectedBoards.forEach((board) => {
      board.tags.forEach((tag) => {
        if (tagURLNames.includes(tag.urlName)) {
          selectedTagIDs.push(tag._id);
        }
      });
    });

    // companies
    const selectedCompanies = location.query.companies?.split('_') ?? [];
    const selectedAccountOwner = location.query.accountOwner ?? '';

    return {
      boardIDs: selectedBoards.map((board) => board._id),
      categoryIDs: selectedCategoryIDs,
      ...dateRanges,
      onlyUncategorized: this.uncategorizedRef.current?.getValue() ?? false,
      onlyUntagged: this.untaggedRef.current?.getValue() ?? false,
      owner: ['me', 'all', 'none'].includes(ownerValue) ? ownerValue : null,
      ownerID,
      segmentID: segmentObject?._id ?? null,
      sort,
      statuses: statusURLNames,
      tagIDs: selectedTagIDs,
      companyURLNames: selectedCompanies,
      accountOwnerName: selectedAccountOwner,
    };
  }

  inferActiveSavedFilter() {
    const { company } = this.props;

    const currentConfig = this.getCurrentSavedFilterStructure();

    const matchingFilter = company.savedFilters.find((filter) => {
      const filterConfig = {
        boardIDs: filter.boardIDs,
        categoryIDs: filter.categoryIDs,
        companyURLNames: filter.companyURLNames,
        accountOwnerName: filter.accountOwnerName,
        onlyUncategorized: filter.onlyUncategorized,
        owner: filter.owner,
        ownerID: filter.ownerID,
        segmentID: filter.segmentID,
        sort: filter.sort,
        statuses: filter.statuses,
        tagIDs: filter.tagIDs,
        onlyUntagged: filter.onlyUntagged,
        ...getFilterDateRanges(filter),
      };
      const filtersIdentical = deepCompare(filterConfig, currentConfig);
      return filtersIdentical;
    });

    // If we found a matching filter, make it active.
    if (matchingFilter) {
      this.props.onUpdateActiveSavedFilter(matchingFilter);
      return;
    }

    // Try to restore their last active filters
    this.restoreLastActiveFilters();
  }

  manageSegments = () => {
    const { boards, company, openModal, viewer } = this.props;
    const viewerHasPermission = hasPermission('manageUserSegmentation', company, viewer);
    if (!viewerHasPermission) {
      openModal(AccessModal, {
        requiredPermissions: ['manageUserSegmentation'],
      });
      return;
    }

    openModal(SavedSegmentsModal, {
      boards,
      onUpdateQuery: this.updateQuery,
    });
  };

  restoreLastActiveFilters() {
    const { activeSavedFilter, company, location, router } = this.props;
    const filterParams = { ...location.query };
    delete filterParams.search;
    const noFiltersActive = Object.keys(filterParams).length === 0;
    const lastUsedFilters = company?.viewerPreferences?.lastActiveFilters?.filterQuery;

    // Only apply last active filters if there are none in the url right now.
    if (lastUsedFilters && noFiltersActive) {
      router.push(`${location.pathname}${lastUsedFilters}`);
    } else if (noFiltersActive) {
      this.props.onUpdateActiveSavedFilter({ name: activeSavedFilter });
      return;
    }

    // Indicate there's changes to this filter.
    this.setState({ filtersChanged: true });
  }

  updateQuery = () => {
    const {
      boards,
      company: { segments, statuses },
      location,
      router,
      viewer,
    } = this.props;

    // boards
    const selectedBoards = this.getSelectedBoardsFromQuery();
    const allBoardsSelected = boards.length === selectedBoards.length;
    const boardURLNames = allBoardsSelected
      ? undefined
      : selectedBoards.map((board) => board.urlName).join('_');

    // date range
    const dateRanges = DateRangeTypes.reduce((dateRanges, type) => {
      const rangeValue = location.query[type.urlName];
      if (rangeValue) {
        dateRanges[type.urlName] = rangeValue;
      }
      return dateRanges;
    }, {});

    // categories
    const { categories } = location.query;
    const boardCategoryURLNameSet = {};
    selectedBoards.forEach((board) => {
      board.categories.forEach((category) => {
        boardCategoryURLNameSet[category.urlName] = true;
      });
    });
    const categoryURLNames = categories ? categories.split('_') : [];
    const relevantCategoryURLNames = categoryURLNames.filter((categoryURLNames) => {
      return boardCategoryURLNameSet[categoryURLNames];
    });
    const categoriesQuery = relevantCategoryURLNames.join('_') || undefined;

    // owner
    const ownerValue = this.ownerRef.current && this.ownerRef.current.getValue().name;
    const owner =
      ownerValue === 'all' || ownerValue === 'none'
        ? null
        : ownerValue === 'me'
        ? viewer.urlName
        : ownerValue;

    // search
    const { search } = location.query;
    const isSearching = search && !!search.trim();

    // segment
    const { segment } = this.state;
    const segmentObject = segments.find(({ urlName }) => urlName === segment);

    // sort
    const { sort } = location.query;

    // statuses
    const statusURLNames = [];
    statuses.forEach((status) => {
      const ref = this.statusRefs[status._id].current;
      const isCheckedByDefault = DefaultCheckedStatusTypes[status.type];
      if (ref ? ref.getValue() : isCheckedByDefault) {
        statusURLNames.push(status.urlName);
      }
    });

    // companies
    const { companies: companiesQuery } = location.query;

    // accountOwner
    const { accountOwner: accountOwnerQuery } = location.query;

    const statusesFilter = statusURLNames.join('_');
    const statusesCheckedByDefault = statuses.filter(
      (status) => DefaultCheckedStatusTypes[status.type]
    );
    const areAllStatusesSelected = statusURLNames.length === statuses.length;
    const areDefaultStatusesSelected =
      statusesFilter === statusesCheckedByDefault.map((status) => status.urlName).join('_');
    const status = isSearching
      ? areAllStatusesSelected
        ? undefined
        : statusesFilter
      : areDefaultStatusesSelected
      ? undefined
      : statusesFilter;

    // tags
    const { tags } = location.query;
    const boardTagURLNameSet = {};
    selectedBoards.forEach((board) => {
      board.tags.forEach((tag) => {
        boardTagURLNameSet[tag.urlName] = true;
      });
    });
    const tagURLNames = tags ? tags.split('_') : [];
    const filteredTagURLNames = tagURLNames.filter((tagURLNames) => {
      return boardTagURLNameSet[tagURLNames];
    });
    const tagsQuery = filteredTagURLNames.join('_') || undefined;

    // unassigned
    const unassigned = ownerValue === 'none';

    // uncategorized
    const uncategorized = this.uncategorizedRef.current
      ? this.uncategorizedRef.current.getValue()
      : false;

    // untagged
    const untagged = this.untaggedRef.current ? this.untaggedRef.current.getValue() : false;

    router.push({
      pathname: location.pathname,
      query: {
        boards: boardURLNames,
        ...(!uncategorized && categoriesQuery && { categories: categoriesQuery }),
        ...dateRanges,
        ...(owner && { owner }),
        ...(isSearching && { search }),
        ...(segmentObject && { segment }),
        ...(sort && { sort }),
        ...(status && { status }),
        ...(!untagged && tagsQuery && { tags: tagsQuery }),
        ...(unassigned && { unassigned: true }),
        ...(uncategorized && { uncategorized: true }),
        ...(untagged && { untagged: true }),
        ...(companiesQuery && { companies: companiesQuery }),
        ...(accountOwnerQuery && { accountOwner: accountOwnerQuery }),
      },
    });
  };

  onBoardsChange = (boards) => {
    const { location, router } = this.props;
    const { query } = location;
    const selectedBoardURLNames = this.props.boards
      .filter((board) => boards[board.urlName])
      .map((board) => board.urlName);

    const newBoardURLNames =
      selectedBoardURLNames.length > 0 && selectedBoardURLNames.length !== this.props.boards.length
        ? selectedBoardURLNames.join('_')
        : undefined;

    const newQuery = Object.assign({}, query, {
      boards: newBoardURLNames,
    });

    router.push({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  selectCategory = (category) => {
    const { location, router } = this.props;
    const { query } = location;
    const { categories } = query;
    const categoryURLNames = categories ? categories.split('_') : [];
    const categoryURLNameSet = {};
    categoryURLNames.forEach((categoryURLName) => {
      categoryURLNameSet[categoryURLName] = true;
    });

    if (categoryURLNameSet[category.urlName]) {
      delete categoryURLNameSet[category.urlName];
    } else {
      categoryURLNameSet[category.urlName] = true;
      this.uncategorizedRef.current.setValue(false);
    }

    const newURLNames = Object.keys(categoryURLNameSet);
    const newCategories = newURLNames.length > 0 ? newURLNames.join('_') : undefined;

    const newQuery = Object.assign({}, query, {
      categories: newCategories,
    });
    delete newQuery.uncategorized;

    router.push({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  onCategorySelected = async (category) => {
    const { features } = this.props.company;
    const planSupports = features?.categories;
    if (!planSupports) {
      this.setState({
        afterUpsell: () => this.selectCategory(category),
        showUpsellModal: 'categories',
      });
      return;
    }

    this.selectCategory(category);
  };

  onDateRangePicked = ({ relativeDate, dateRange }) => {
    const { location, router } = this.props;
    const { selectedDateRangeType } = this.state;
    const newRange = relativeDate
      ? relativeDate !== RelativeDateRanges.allTime
        ? relativeDate
        : null
      : dateRange.join('_');
    const copiedQuery = { ...location.query, [selectedDateRangeType.urlName]: newRange };

    if (!newRange) {
      delete copiedQuery[selectedDateRangeType.urlName];
    }

    router.push({
      pathname: location.pathname,
      query: copiedQuery,
    });
  };

  onDateRangeTypePicked = (dateRangeType) => {
    const { location, router } = this.props;
    const { dateRange } = this.state;
    const currentDateRange = typeof dateRange === 'string' ? dateRange : dateRange.join('_');

    this.setState({ selectedDateRangeType: dateRangeType });

    if (currentDateRange !== RelativeDateRanges.allTime) {
      const query = { ...location.query };
      DateRangeTypes.forEach((type) => delete query[type.urlName]);
      query[dateRangeType.urlName] = currentDateRange;

      router.push({
        pathname: location.pathname,
        query,
      });
    }
  };

  onLimitExceeded = () => {
    this.setState({
      showUpsellModal: 'limits.analyticsHistory',
    });
  };

  onCreateNewSavedFilter = () => {
    const filterConfig = this.getCurrentSavedFilterStructure();
    this.props.openModal(AdminCreateSavedFilterModal, {
      filterConfig,
      onSuccess: (name) => {
        this.props.onUpdateActiveSavedFilter({ name });
      },
    });
  };

  onManageSegments = async () => {
    const { features } = this.props.company;
    const planSupports = features?.userSegmentation;
    if (!planSupports) {
      this.setState({
        afterUpsell: this.manageSegments,
        showUpsellModal: 'userSegmentation',
      });
      return;
    }

    this.manageSegments();
  };

  onOwnerChange = async () => {
    const { features } = this.props.company;
    const planSupports = features?.postOwners;
    if (!planSupports) {
      this.setState({
        afterDismiss: () => {
          this.ownerRef.current.setValue('all');
        },
        afterUpsell: this.updateQuery,
        showUpsellModal: 'postOwners',
      });
      return;
    }

    this.updateQuery();
  };

  onOwnerSearch = () => {
    this.setState({
      ownerSearchOpen: !this.state.ownerSearchOpen,
    });
  };

  onOwnerSelected = (owner) => {
    const { viewer } = this.props;
    const isOwnerViewer = owner._id === viewer._id;
    const { selectedOwner } = this.state;
    this.setState(
      {
        ownerSearchOpen: false,
        selectedOwner: isOwnerViewer ? selectedOwner : owner.urlName,
      },
      () => {
        this.ownerRef.current.onSelected(isOwnerViewer ? 2 : 3);
      }
    );
  };

  onResetStatuses = () => {
    const {
      company,
      location: {
        query: { search },
      },
    } = this.props;
    const isSearching = search && !!search.trim();
    company.statuses.forEach((status) => {
      const isCheckedByDefault = DefaultCheckedStatusTypes[status.type];
      this.statusRefs[status._id].current.setValue(isSearching || isCheckedByDefault);
    });
    setTimeout(() => {
      this.updateQuery();
    }, 0);
  };

  onSegmentChange = async (segment) => {
    const { features } = this.props.company;
    const planSupports = features?.userSegmentation;
    if (!planSupports) {
      this.setState({
        afterUpsell: () => this.changeSegment(segment),
        showUpsellModal: 'userSegmentation',
      });
      return;
    }

    this.changeSegment(segment);
  };

  onSelectStatus = (selectedStatus) => {
    const { company } = this.props;
    company.statuses.forEach((status) => {
      const isSelectedStatus = selectedStatus._id === status._id;
      this.statusRefs[status._id].current.setValue(isSelectedStatus);
    });
    setTimeout(() => {
      this.updateQuery();
    }, 0);
  };

  onStatusChange = () => {
    this.updateQuery();
  };

  onUncategorizedChange = async (e) => {
    const { features } = this.props.company;
    const planSupports = features?.categories;
    if (!planSupports) {
      this.setState({
        afterDismiss: () => {
          this.uncategorizedRef.current.setValue(false);
        },
        afterUpsell: this.updateQuery,
        showUpsellModal: 'categories',
      });
      return;
    }

    this.updateQuery();
  };

  onUntaggedChange = () => {
    this.updateQuery();
  };

  onUpdateSavedFilter = async () => {
    const { activeSavedFilter, company, reloadCompany } = this.props;
    const filterConfig = this.getCurrentSavedFilterStructure();
    const filter = company.savedFilters.find((filter) => filter.name === activeSavedFilter);
    let response;

    // If they haven't overwritten the default filter yet, then we need to create it for the first time.
    if (activeSavedFilter === DefaultSavedFilterName && !filter) {
      response = await AJAX.post('/api/savedFilters/create', {
        name: DefaultSavedFilterName,
        ...filterConfig,
        isDefault: true,
      });
    } else {
      response = await AJAX.post('/api/savedFilters/edit', {
        filterID: filter._id,
        name: filter.name,
        ...filterConfig,
      });
    }

    if (response === 'success') {
      await reloadCompany();
    }
  };

  onTagSelected = (tag) => {
    const { location, router } = this.props;
    const { query } = location;

    const { tags } = query;
    const tagURLNames = tags ? tags.split('_') : [];
    const tagURLNameSet = {};
    tagURLNames.forEach((tagURLName) => {
      tagURLNameSet[tagURLName] = true;
    });

    if (tagURLNameSet[tag.urlName]) {
      delete tagURLNameSet[tag.urlName];
    } else {
      tagURLNameSet[tag.urlName] = true;
      this.untaggedRef.current.setValue(false);
    }

    const newURLNames = Object.keys(tagURLNameSet);
    const newTags = newURLNames.length > 0 ? newURLNames.join('_') : undefined;

    const newQuery = Object.assign({}, query, {
      tags: newTags,
    });
    delete newQuery.untagged;

    router.push({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  onUpsell = () => {
    const { afterUpsell } = this.state;
    this.setState({
      afterDismiss: null,
      afterUpsell: null,
      showUpsellModal: null,
    });
    afterUpsell?.();
  };

  onUpsellDismiss = () => {
    const { afterDismiss } = this.state;
    this.setState({
      afterDismiss: null,
      afterUpsell: null,
      showUpsellModal: null,
    });
    afterDismiss?.();
  };

  renderCategorySection() {
    const {
      board,
      boards,
      location: {
        query: { boards: boardsQuery, categories, uncategorized },
      },
    } = this.props;

    if (boards.length === 0) {
      return null;
    }

    const settingsURL = board?.urlName
      ? `/admin/settings/boards/${board.urlName}/categories`
      : `/admin/settings/`;

    const categoryMap = {};
    boards.forEach((board) => {
      const isBoardSelected =
        !boardsQuery || boardsQuery.match(new RegExp(`(^|_)${board.urlName}(_|$)`));
      if (!isBoardSelected) {
        return;
      }

      board.categories.forEach((category) => {
        categoryMap[category.urlName] = {
          name: category.name,
          urlName: category.urlName,
        };
      });
    });

    const selectedCategories = categories
      ? categories
          .split('_')
          .filter((categoryURLName) => {
            return categoryMap[categoryURLName];
          })
          .map((categoryURLName) => {
            const category = categoryMap[categoryURLName];
            return (
              <Tag
                key={category.urlName}
                name={category.name}
                onTap={this.onCategorySelected.bind(this, category)}
                selected={true}
              />
            );
          })
      : [];

    return (
      <AdminSidebarSection
        action={
          <Link to={settingsURL}>
            <Settings size="16" className="icon-settings" />
          </Link>
        }
        className="categorySection"
        title="Categories">
        <div className="uncategorized">
          <CheckboxInput
            defaultChecked={!!uncategorized}
            label="Uncategorized only"
            onChange={this.onUncategorizedChange}
            ref={this.uncategorizedRef}
          />
        </div>
        <CategorySelector
          boards={boards.filter((board) => {
            const isBoardSelected =
              !boardsQuery || boardsQuery.match(new RegExp(`(^|_)${board.urlName}(_|$)`));
            return isBoardSelected;
          })}
          excludeCategories={
            categories
              ? categories
                  .split('_')
                  .filter((categoryURLName) => {
                    return categoryMap[categoryURLName];
                  })
                  .map((categoryURLName) => {
                    return categoryMap[categoryURLName];
                  })
              : []
          }
          onCategorySelected={this.onCategorySelected}
        />
        {selectedCategories.length > 0 ? (
          <div className="selectedCategories">{selectedCategories}</div>
        ) : null}
      </AdminSidebarSection>
    );
  }

  renderDateRangePicker() {
    const { company } = this.props;
    const { dateRange, selectedDateRangeType } = this.state;
    const options = DateRangeTypes.map((type) => {
      const className = classnames({
        dateRangeToggleOption: true,
        optionSelected: type?.urlName === selectedDateRangeType.urlName,
      });
      return (
        <Tappable key={type.name} onTap={this.onDateRangeTypePicked.bind(this, type)}>
          <div className={className}>{type.name}</div>
        </Tappable>
      );
    });

    let limitedOptions = [];
    const analyticsHistory = company.limits?.analyticsHistory;
    if (analyticsHistory && analyticsHistory <= 30) {
      limitedOptions = [
        RelativeDateRanges.lastHalf,
        RelativeDateRanges.lastQuarter,
        RelativeDateRanges.thisHalf,
        RelativeDateRanges.thisQuarter,
      ];
    }

    return (
      <AdminSidebarSection className="dateRangeSection" title="Date Range">
        <div className="dateRangeToggle">{options}</div>
        <DateRangePicker
          date={dateRange}
          historyDayLimit={analyticsHistory}
          limitedOptions={limitedOptions}
          onLimitExceeded={this.onLimitExceeded}
          onSubmit={this.onDateRangePicked}
        />
      </AdminSidebarSection>
    );
  }

  renderOwnerSection() {
    const {
      company: { members },
    } = this.props;
    const buttons = [
      {
        name: 'all',
        render: 'All',
      },
      {
        name: 'none',
        render: 'No owner',
      },
      {
        name: 'me',
        render: 'Me',
      },
    ];
    const {
      location: { query },
      viewer,
    } = this.props;
    const { selectedOwner } = this.state;
    const admin = members.find((member) => {
      return member.urlName === selectedOwner;
    });
    if (admin && admin._id !== viewer._id) {
      buttons.push({
        name: admin.urlName,
        render: admin.name,
      });
    }

    const defaultSelectedName =
      admin && admin._id === viewer._id
        ? 'me'
        : admin
        ? admin.urlName
        : query.unassigned
        ? 'none'
        : 'all';

    const { formHasContents } = this.state;
    return (
      <AdminSidebarSection
        action={
          <div className="ownerAction" ref={this.ownerActionRef}>
            <Tappable onTap={this.onOwnerSearch}>
              <div className="search">Search</div>
            </Tappable>
            {this.renderOwnerSuggestions()}
          </div>
        }
        className="ownerSection"
        title="Owner">
        <RadioButtonGroup
          buttons={buttons}
          defaultSelectedName={defaultSelectedName}
          disabled={formHasContents}
          onChange={this.onOwnerChange}
          ref={this.ownerRef}
        />
      </AdminSidebarSection>
    );
  }

  renderOwnerSuggestions() {
    const { ownerSearchOpen } = this.state;
    if (!ownerSearchOpen) {
      return null;
    }

    return <AdminPicker onAdminSelected={this.onOwnerSelected} />;
  }

  renderSavedFilterUpdater() {
    const { activeSavedFilter } = this.props;
    if (!this.state.filtersChanged) {
      return null;
    }

    return (
      <div className="savedFilterOptions">
        <Tappable onTap={this.onUpdateSavedFilter}>
          <div className="updateOption topOption">Update '{activeSavedFilter}'</div>
        </Tappable>
        <Tappable onTap={this.onCreateNewSavedFilter}>
          <div className="updateOption">Create new saved filter</div>
        </Tappable>
      </div>
    );
  }

  renderSegmentSection() {
    const {
      company: { segments },
    } = this.props;

    const segmentOptions = [
      { name: 'everyone', render: 'Everyone (default)' },
      ...segments.map(({ name, urlName }) => ({ name: urlName, render: name })),
    ];
    const segment = segments.find(({ urlName }) => urlName === this.state.segment);
    return (
      <AdminSidebarSection
        action={
          <Tappable onTap={this.onManageSegments}>
            <Settings size="16" className="icon-settings" />
          </Tappable>
        }
        className="segmentSection"
        title="User Segment">
        <ControlledDropdown
          className="segmentDropdown"
          dropdownClassName="adminBoardSidebarSegmentDropdown"
          onChange={this.onSegmentChange}
          options={segmentOptions}
          selectedName={(segment && segment.urlName) || 'everyone'}
        />
      </AdminSidebarSection>
    );
  }

  renderStatusSection() {
    const {
      company,
      location: {
        query: { search, status },
      },
    } = this.props;
    const selectedStatusURLNames = status?.split('_') || [];
    const statusesURLNameMap = mapify(company.statuses, 'urlName');
    const existingStatuses = selectedStatusURLNames.filter(
      (statusURLName) => statusesURLNameMap[statusURLName]
    );

    return (
      <AdminSidebarSection
        action={
          <Tappable onTap={this.onResetStatuses}>
            <div className="reset">Reset</div>
          </Tappable>
        }
        className="statusSection"
        title="Status">
        {Object.entries(statusesURLNameMap).map(([statusURLName, status]) => {
          const isCheckedByDefault = !!search || DefaultCheckedStatusTypes[status.type];
          const isChecked =
            existingStatuses.length > 0
              ? existingStatuses.includes(statusURLName)
              : isCheckedByDefault;

          return (
            <AdminSidebarSectionItem key={status.name}>
              <CheckboxInput
                defaultChecked={isChecked}
                key={status.name}
                label={capitalizeWords(status.name).replace(' ', '\u00a0')}
                onChange={this.onStatusChange}
                ref={this.statusRefs[status._id]}
              />
              <div className="hoverMenu">
                <Tappable onTap={() => this.onSelectStatus(status)}>
                  <div className="only">1</div>
                </Tappable>
              </div>
            </AdminSidebarSectionItem>
          );
        })}
      </AdminSidebarSection>
    );
  }

  renderTagsSection() {
    const {
      board,
      boards,
      location: {
        query: { boards: boardsQuery, tags, untagged },
      },
    } = this.props;

    if (boards.length === 0) {
      return null;
    }

    const settingsURL = board?.urlName
      ? `/admin/settings/boards/${board.urlName}/tags`
      : `/admin/settings/`;

    const tagMap = {};
    boards.forEach((board) => {
      const isBoardSelected =
        !boardsQuery || boardsQuery.match(new RegExp(`(^|_)${board.urlName}(_|$)`));
      if (!isBoardSelected) {
        return;
      }

      board.tags.forEach((tag) => {
        tagMap[tag.urlName] = {
          name: tag.name,
          urlName: tag.urlName,
          id: tag._id,
        };
      });
    });

    const selectedTags = tags
      ? tags
          .split('_')
          .filter((tagURLName) => {
            return tagMap[tagURLName];
          })
          .map((tagURLName) => {
            const tag = tagMap[tagURLName];
            return (
              <Tag
                key={tag.id}
                name={tag.name}
                onTap={this.onTagSelected.bind(this, tag)}
                selected={true}
              />
            );
          })
      : [];

    return (
      <AdminSidebarSection
        action={
          <Link to={settingsURL}>
            <Settings size="16" className="icon-settings" />
          </Link>
        }
        className="tagSection"
        title="Tags">
        <div className="untagged">
          <CheckboxInput
            defaultChecked={!!untagged}
            label="Posts without tags"
            onChange={this.onUntaggedChange}
            ref={this.untaggedRef}
          />
        </div>
        <TagSelector
          boards={boards.filter((board) => {
            const isBoardSelected =
              !boardsQuery || boardsQuery.match(new RegExp(`(^|_)${board.urlName}(_|$)`));
            return isBoardSelected;
          })}
          excludeTags={
            tags
              ? tags
                  .split('_')
                  .filter((tagURLName) => tagMap[tagURLName])
                  .map((tagURLName) => tagMap[tagURLName])
              : []
          }
          onTagSelected={this.onTagSelected}
        />
        {selectedTags.length > 0 ? <div className="selectedTags">{selectedTags}</div> : null}
      </AdminSidebarSection>
    );
  }

  render() {
    const classNames = classnames({
      adminFeedbackSidebar: true,
      collapsed: !this.props.showSidebar,
    });

    return (
      <div className={classNames}>
        <AdminPageSidebar
          adjustHeightForOptions
          footer={this.renderSavedFilterUpdater()}
          showSidebar={this.props.showSidebar}>
          {this.renderSegmentSection()}
          {this.renderDateRangePicker()}
          <AdminSidebarBoardSection
            boards={this.props.boards}
            onBoardsChange={this.onBoardsChange}
            selectedBoards={this.getSelectedBoardsFromQuery()}
            showPostCount={true}
          />
          {this.renderStatusSection()}
          <CompanyFilterSection />
          {this.renderTagsSection()}
          {this.renderCategorySection()}
          {this.renderOwnerSection()}
          <UpsellModal
            cta={UpsellCTAMap[this.state.showUpsellModal]}
            feature={this.state.showUpsellModal}
            onClose={this.onUpsellDismiss}
            onUpsell={this.onUpsell}
            show={!!this.state.showUpsellModal}
          />
        </AdminPageSidebar>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      boards: BoardsContext,
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminFeedbackSidebar);

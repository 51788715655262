import React, { useContext, useRef, useState } from 'react';

import classnames from 'classnames';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Portal from 'common/common/Portal';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Link from 'common/Link';
import UpsellModal from 'common/modals/UpsellModal';
import Tappable from 'common/Tappable';
import ButtonV2 from 'common/ui/ButtonV2';
import { dayjs } from 'common/util/dayjsUtils';
import getOnboardingTasks, {
  OnboardingLabels,
  OnboardingLinks,
} from 'common/util/getOnboardingTasks';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import 'css/components/subdomain/admin/_AdminOnboardingProgress.scss';

const OneDayMS = 24 * 60 * 60 * 1000;

const _AdminOnboardingChecklist = ({ checklist, company, onClose, reloadCompany, shown }) => {
  // context
  const location = useContext(LocationContext);
  const showToast = useContext(ShowToastContext);

  // state
  const [skipping, setSkipping] = useState(false);

  // helpers
  const onSkipOnboarding = async () => {
    console.log('fuck');
    setSkipping(true);

    const response = await AJAX.post('/api/billing/skipOnboarding');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast('Skip failed, please contact support.', ToastTypes.error);
      setSkipping(false);
      return;
    }

    await reloadCompany();
    setSkipping(false);
  };

  if (!shown) {
    return null;
  }

  const canSkip = company.billingData?.onboardingExtensions > 0;
  const onDashboard = location.pathname === '/admin';
  return (
    <div className="checklist">
      <div className="header">
        <div className="title">Canny Onboarding</div>
        <Tappable onTap={onClose}>
          <div className="exitButton icon icon-x" />
        </Tappable>
      </div>
      <div className="description">
        <div className="text">Complete these steps to get&nbsp;started</div>
        {canSkip && (
          <Tappable onTap={onSkipOnboarding}>
            <ButtonV2
              className="skipOnboardingButton"
              size="medium"
              variant="plain"
              loading={skipping}>
              {skipping ? 'Skipping' : 'Skip'}
            </ButtonV2>
          </Tappable>
        )}
      </div>

      <div className="todos">
        {checklist.map(({ completed, name }) => {
          const label = OnboardingLabels[name];
          const link = OnboardingLinks[name];
          return (
            <div
              key={name}
              className={classnames('todo', {
                completed,
              })}>
              <div className="checkbox">
                <div className="icon-check" />
              </div>
              <div className="item" href={link} rel="noopener" target="_blank">
                <div className="words">
                  <div className="label">{label}</div>
                </div>
                {completed && <div className="popper">🎉</div>}
                <div className="spacer" />
              </div>
            </div>
          );
        })}
      </div>
      {!onDashboard && (
        <Link className="help" onTap={onClose} to="/admin">
          <div className="link">Onboarding dashboard</div>
          <div className="arrow">→</div>
        </Link>
      )}
    </div>
  );
};

const AdminOnboardingChecklist = compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(_AdminOnboardingChecklist);

const AdminOnboardingDaysLeft = (props) => {
  const { daysLeft, planTrial } = props;
  if (planTrial) {
    return null;
  }

  const daysLeftFloor = Math.floor(daysLeft);
  if (daysLeftFloor < 1) {
    return '0 days left';
  } else if (daysLeftFloor === 1) {
    return '1 day left';
  }

  return `${daysLeftFloor} days left`;
};

const AdminOnboardingProgress = (props) => {
  // context
  const company = useContext(CompanyContext);
  const {
    billingData: { onboardingDue },
    planTrial,
    stats,
  } = company;

  // state
  const [showChecklist, setShowChecklist] = useState(false);
  const [showUpsell, setShowUpsell] = useState(false);

  // refs
  const progressBarRef = useRef();

  const { onboardedAt } = stats;
  if (onboardedAt) {
    const onboardedPastWeek = dayjs(onboardedAt) > dayjs().subtract(1, 'w');
    if (onboardedPastWeek) {
      return (
        <div className="adminOnboardingProgress done">
          <div className="content">
            <div className="left">
              <div className="message">Onboarding Complete! 🎉</div>
            </div>
            {!planTrial ? (
              <div className="right">
                <button className="tryGrowth" onClick={() => setShowUpsell(true)}>
                  Try&nbsp;Growth
                </button>
              </div>
            ) : null}
          </div>
          <div className="progressBar">
            <div className="progress" />
          </div>
          <UpsellModal
            feature="onboardingCompleted"
            onClose={() => setShowUpsell(false)}
            onUpsell={() => setShowUpsell(false)}
            show={showUpsell}
          />
        </div>
      );
    }

    return null;
  }

  const msLeft = new Date(onboardingDue).getTime() - Date.now();
  const daysLeft = msLeft / OneDayMS;

  const checklist = getOnboardingTasks(company);
  const completedItems = checklist.filter(({ completed }) => completed);
  const progress = (completedItems.length / 5) * 100;

  return (
    <button
      className={classnames('adminOnboardingProgress', {
        warning: daysLeft < 2,
        alert: daysLeft < 1,
      })}
      onClick={() => setShowChecklist(!showChecklist)}
      ref={progressBarRef}>
      <div className="content">
        <div className="left">
          <div className="message">Set up Canny</div>
          <div className="spacer" />
          <div className="daysLeft">
            <AdminOnboardingDaysLeft daysLeft={daysLeft} planTrial={planTrial} />
          </div>
        </div>
        <div className="break" />
        <div className="right">{completedItems.length}/5</div>
      </div>
      <div className="progressBar">
        <div className="progress" style={{ width: `${progress}%` }} />
      </div>
      <Portal
        align="end"
        className="onboardingChecklistPortal"
        onBlur={() => setShowChecklist(false)}
        relativeToRef={progressBarRef}>
        <AdminOnboardingChecklist
          checklist={checklist}
          company={company}
          onClose={() => setShowChecklist(false)}
          shown={showChecklist}
        />
      </Portal>
    </button>
  );
};

export default AdminOnboardingProgress;

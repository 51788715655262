import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadRoadmapPosts } from 'common/actions/roadmapPosts';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, ParamsContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import AccessModal from 'common/modals/AccessModal';
import AdminCreateLinearIssueModal from 'common/subdomain/admin/AdminCreateLinearIssueModal';
import Tappable from 'common/Tappable';
import { getQueryFilterParams } from 'common/util/filterPosts';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';
import LinearLogoIcon from 'img/landing/linear-logo.webp';

import AdminCreateAsanaTaskModal from '../../AdminCreateAsanaTaskModal';
import AdminCreateAzureDevopsWorkItemModal from '../../AdminCreateAzureDevopsWorkItemModal';
import AdminCreateClickupTaskModal from '../../AdminCreateClickupTaskModal';
import AdminCreateGithubIssueModal from '../../AdminCreateGithubIssueModal';
import AdminCreateJiraIssueModal from '../../AdminCreateJiraIssueModal';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapPostIntegrations.scss';

import AsanaLogoIcon from 'img/asana-logo.png';
import AzureDevopsLogoIcon from 'img/azure-devops-logo.png';
import ClickupLogoIcon from 'img/clickup-logo.png';
import GithubLogoIcon from 'img/github-logo-icon.png';
import JiraLogoIcon from 'img/jira-logo.svg';

const Modals = {
  Asana: 'Asana',
  AzureDevops: 'AzureDevops',
  Clickup: 'Clickup',
  Github: 'Github',
  Jira: 'Jira',
  Linear: 'Linear',
};

class AdminRoadmapPostIntegrations extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        urlName: PropTypes.string,
      })
    ),
    company: PropTypes.shape({
      clickup: PropTypes.shape({
        authorized: PropTypes.bool,
      }),
      jira: PropTypes.shape({
        connected: PropTypes.bool,
      }),
    }),
    post: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      urlName: PropTypes.string,
      status: PropTypes.string,
    }),
    roadmap: PropTypes.shape({
      _id: PropTypes.string,
    }),
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  state = {
    modal: null,
  };

  pushToAsana = () => {
    const { company, openModal, viewer } = this.props;

    if (!hasPermission('manageRoadmap', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageRoadmap'],
      });
      return;
    }

    this.setState({ modal: Modals.Asana });
  };

  pushToAzureDevops = () => {
    const { company, openModal, viewer } = this.props;

    if (!hasPermission('manageRoadmap', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageRoadmap'],
      });
      return;
    }

    this.setState({ modal: Modals.AzureDevops });
  };

  pushToClickup = () => {
    const { company, openModal, viewer } = this.props;

    if (!hasPermission('manageRoadmap', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageRoadmap'],
      });
      return;
    }

    this.setState({ modal: Modals.Clickup });
  };

  pushToGithub = () => {
    const {
      boards,
      company,
      location,
      openModal,
      params,
      post,
      reloadRoadmapPosts,
      roadmap,
      viewer,
    } = this.props;
    const board = boards.find((board) => post.boardID === board._id);

    if (!hasPermission('manageRoadmap', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageRoadmap'],
      });
      return;
    }

    const boardInQuery = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(boardInQuery, company, location, {}, roadmap);

    openModal(AdminCreateGithubIssueModal, {
      board,
      onIssueCreated: () => reloadRoadmapPosts(queryParams),
      post,
    });
  };

  pushToJira = () => {
    const {
      boards,
      company,
      location,
      openModal,
      params,
      post,
      reloadRoadmapPosts,
      roadmap,
      viewer,
    } = this.props;
    const board = boards.find((board) => post.boardID === board._id);

    if (!hasPermission('manageRoadmap', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageRoadmap'],
      });
      return;
    }

    const boardInQuery = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(boardInQuery, company, location, {}, roadmap);

    openModal(AdminCreateJiraIssueModal, {
      board,
      onIssueCreated: () => reloadRoadmapPosts(queryParams),
      post,
    });
  };

  pushToLinear = () => {
    const { company, openModal, viewer } = this.props;

    if (!hasPermission('manageRoadmap', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageRoadmap'],
      });
      return;
    }

    this.setState({ modal: Modals.Linear });
  };

  renderAsanaIntegration = () => {
    const { company, post } = this.props;
    if (!company?.activeIntegrations?.asana) {
      return null;
    }

    const isLinked = !!post.asanaTasks?.length;
    const icon = (
      <LazyLoadedImage
        className={classnames('logo', { linked: isLinked })}
        src={AsanaLogoIcon}
        alt="Asana Logo"
      />
    );

    if (isLinked) {
      const [firstTask] = post.asanaTasks;
      return (
        <div className="integrationIcon">
          <a href={firstTask.url} rel="noopener" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return (
      <div className="integrationIcon">
        <Tappable onTap={this.pushToAsana}>{icon}</Tappable>
      </div>
    );
  };

  renderAzureDevopsIntegration = () => {
    const { company, post } = this.props;
    if (!company?.activeIntegrations?.azureDevops) {
      return null;
    }

    const isLinked = !!post.azureDevopsWorkItems?.length;
    const icon = (
      <LazyLoadedImage
        className={classnames('logo', { linked: isLinked })}
        src={AzureDevopsLogoIcon}
        alt="Azure DevOps Logo"
      />
    );

    if (isLinked) {
      const [workItem] = post.azureDevopsWorkItems;
      return (
        <div className="integrationIcon">
          <a href={workItem.url} rel="noopener" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return (
      <div className="integrationIcon">
        <Tappable onTap={this.pushToAzureDevops}>{icon}</Tappable>
      </div>
    );
  };

  renderClickupIntegration = () => {
    const {
      company: { clickup },
      post,
    } = this.props;
    if (!clickup?.authorized) {
      return null;
    }

    const isLinked = !!post.clickupTasks?.length;
    const icon = (
      <LazyLoadedImage
        className={classnames('logo', { linked: isLinked })}
        src={ClickupLogoIcon}
        alt="ClickUp Logo"
      />
    );

    if (isLinked) {
      const [firstTask] = post.clickupTasks;
      return (
        <div className="integrationIcon">
          <a href={firstTask.url} rel="noopener" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return (
      <div className="integrationIcon">
        <Tappable onTap={this.pushToClickup}>{icon}</Tappable>
      </div>
    );
  };

  renderGithubIntegration = () => {
    const {
      company: { github },
      post,
    } = this.props;
    if (!github?.installationID) {
      return null;
    }

    const isLinked = !!post.githubIssues.length;
    const icon = (
      <LazyLoadedImage
        className={classnames('logo', { linked: isLinked })}
        src={GithubLogoIcon}
        alt="Github Logo"
      />
    );

    if (isLinked) {
      const [firstIssue] = post.githubIssues;
      return (
        <div className="integrationIcon">
          <a href={firstIssue.url} rel="noopener" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return (
      <div className="integrationIcon">
        <Tappable onTap={this.pushToGithub}>{icon}</Tappable>
      </div>
    );
  };

  renderJiraIntegration = () => {
    const {
      company: { jira },
      post,
    } = this.props;
    if (!jira?.connected) {
      return null;
    }

    const isLinked = !!post.jiraIssues.length;
    const icon = (
      <LazyLoadedImage
        className={classnames('logo', { linked: isLinked })}
        src={JiraLogoIcon}
        alt="Jira Logo"
      />
    );

    if (isLinked) {
      const [firstIssue] = post.jiraIssues;
      return (
        <div className="integrationIcon">
          <a href={firstIssue.url} rel="noopener" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return (
      <div className="integrationIcon">
        <Tappable onTap={this.pushToJira}>{icon}</Tappable>
      </div>
    );
  };

  renderLinearIntegration = () => {
    const { company, post } = this.props;
    if (!company?.activeIntegrations?.linear) {
      return null;
    }

    const isLinked = !!post.linearIssues?.length;
    const icon = (
      <LazyLoadedImage
        className={classnames('logo', { linked: isLinked })}
        src={LinearLogoIcon}
        alt="Linear Logo"
      />
    );

    if (isLinked) {
      const [firstIssue] = post.linearIssues;
      return (
        <div className="integrationIcon">
          <a href={firstIssue.url} rel="noopener" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return (
      <div className="integrationIcon">
        <Tappable onTap={this.pushToLinear}>{icon}</Tappable>
      </div>
    );
  };

  renderModals = () => {
    const { boards, company, location, params, post, reloadRoadmapPosts, roadmap } = this.props;
    const { modal } = this.state;
    const board = boards.find((board) => post.boardID === board._id);

    const boardInQuery = boards.find((board) => board.urlName === params.boardURLName);
    const queryParams = getQueryFilterParams(boardInQuery, company, location, {}, roadmap);

    const ModalComponent = {
      [Modals.Asana]: (
        <AdminCreateAsanaTaskModal
          onClose={() => this.setState({ modal: null })}
          onTaskCreated={() => reloadRoadmapPosts(queryParams)}
          post={{ ...post, board }}
        />
      ),
      [Modals.AzureDevops]: (
        <AdminCreateAzureDevopsWorkItemModal
          onClose={() => this.setState({ modal: null })}
          onWorkItemCreated={() => reloadRoadmapPosts(queryParams)}
          post={{ ...post, board }}
        />
      ),
      [Modals.Clickup]: (
        <AdminCreateClickupTaskModal
          onClose={() => this.setState({ modal: null })}
          onTaskCreated={() => reloadRoadmapPosts(queryParams)}
          post={{ ...post, board }}
        />
      ),
      [Modals.Linear]: (
        <AdminCreateLinearIssueModal
          onClose={() => this.setState({ modal: null })}
          onIssueCreated={() => reloadRoadmapPosts(queryParams)}
          post={{ ...post, board }}
        />
      ),
    }[modal];

    return ModalComponent ?? null;
  };

  render() {
    return (
      <div className="adminRoadmapPostIntegrations">
        {this.renderAsanaIntegration()}
        {this.renderAzureDevopsIntegration()}
        {this.renderClickupIntegration()}
        {this.renderJiraIntegration()}
        {this.renderGithubIntegration()}
        {this.renderLinearIntegration()}
        {this.renderModals()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadRoadmapPosts: (queryParams) => {
      return Promise.all([dispatch(reloadRoadmapPosts(queryParams))]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      params: ParamsContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminRoadmapPostIntegrations);

import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Spinner from 'common/Spinner';
import AdminCreateLinearIssueModal from 'common/subdomain/admin/AdminCreateLinearIssueModal';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AdminLinearSearchForm from './AdminLinearSearchForm';

import 'css/components/subdomain/admin/_AdminLinearPostSidebarSection.scss';

const AdminLinearPostSidebarSection = ({ post, reloadPost }) => {
  // context
  const company = useContext(CompanyContext);

  // state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchFormOpen, setIsSearchFormOpen] = useState(false);
  const [removingIssueID, setRemovingIssueID] = useState(null);

  // helpers
  const onCreateIssue = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onToggleSearchForm = () => {
    setIsSearchFormOpen((state) => !state);
  };

  const removeIssue = async (issue) => {
    if (removingIssueID === issue.id) {
      return;
    }

    setRemovingIssueID(issue.id);

    const response = await AJAX.post('/api/linear/unlinkIssue', { issueLinkID: issue.linkID });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (!error) {
      await reloadPost(post);
    }

    setRemovingIssueID(null);
  };

  // renders
  const renderCreateButton = () => {
    return (
      <Tappable onTap={onCreateIssue}>
        <div className="createButton">Create new issue</div>
      </Tappable>
    );
  };

  const renderLinearIssues = () => {
    const { linearIssues } = post;

    if (!linearIssues?.length) {
      return null;
    }

    const issues = linearIssues.map((issue) => (
      <div key={issue.id} className="issue">
        <a href={issue.url} rel="noopener" target="_blank">
          {issue.title}
        </a>
        {removingIssueID === issue.id ? (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        ) : (
          <Tappable onTap={() => removeIssue(issue)}>
            <div className="icon icon-x" />
          </Tappable>
        )}
      </div>
    ));

    return <div className="linearIssues">{issues}</div>;
  };

  const renderSearchForm = () => {
    if (!isSearchFormOpen) {
      return null;
    }

    return <AdminLinearSearchForm post={post} />;
  };

  const renderAction = () => {
    return (
      <Tappable onTap={onToggleSearchForm}>
        <div>Link issue</div>
      </Tappable>
    );
  };

  const render = () => {
    const doesPlanSupportIntegration = company?.integrations?.linear;
    const isIntegrationActive = !!company?.activeIntegrations?.linear;

    if (!doesPlanSupportIntegration || !isIntegrationActive) {
      return null;
    }

    return (
      <AdminSidebarSection
        action={renderAction()}
        className="adminLinearPostSidebarSection"
        title="Linear Issues">
        {renderSearchForm()}
        {renderLinearIssues()}
        {renderCreateButton()}
        {isModalOpen && <AdminCreateLinearIssueModal post={post} onClose={onCloseModal} />}
      </AdminSidebarSection>
    );
  };

  return render();
};

AdminLinearPostSidebarSection.propTypes = {
  company: PropTypes.shape({ _id: PropTypes.string }),
  post: PropTypes.object,
};

export default connect(null, (dispatch) => ({
  reloadPost: (post) => {
    return Promise.all([dispatch(invalidatePostQueries()), dispatch(reloadPost(post))]);
  },
}))(AdminLinearPostSidebarSection);

import {
  LinearSettingsError,
  LinearSettingsLoaded,
  RequestLinearSettings,
} from 'common/actions/linearSettings';

export default function linearSettings(state = null, action) {
  switch (action.type) {
    case LinearSettingsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case LinearSettingsLoaded: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        ...action.linearSettings,
      });
    }

    case RequestLinearSettings: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import { type Names, loadTPCForFilters } from 'common/actions/tpcForFilters';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { DefaultState, type TPCForFiltersState } from 'common/reducers/tpcForFilters';

import type { AsyncItem } from 'redux-connect';

const asyncFetch: AsyncItem = {
  key: 'asyncTPCFetch',
  promise: ({ store: { dispatch }, location }: PromiseOptions) => {
    const { query } = location;
    const currentCompaniesQuery =
      typeof query.companies === 'string' ? query.companies.split('_').filter(Boolean) : [];
    const currentAccountOwnerQuery =
      typeof query.accountOwner === 'string' ? query.accountOwner : '';
    return dispatch(loadTPCForFilters(currentCompaniesQuery, currentAccountOwnerQuery));
  },
};

type TPCForFiltersContextType = {
  companyNames?: Names;
  accountOwnerNames?: Names;
  loading?: boolean;
  error?: string;
};

export const TPCForFiltersContext = React.createContext<TPCForFiltersContextType>(DefaultState);

type Props = {
  children: React.ReactNode;
  tpcForFilters: TPCForFiltersState;
};

class TPCForFiltersContainer extends Component<Props> {
  static asyncConnect = asyncFetch;

  render() {
    const { children, tpcForFilters } = this.props;

    const clonedElement = cloneElementWithProps(children, {
      ...this.props,
      ...tpcForFilters,
    });

    return (
      <TPCForFiltersContext.Provider value={tpcForFilters}>
        {clonedElement}
      </TPCForFiltersContext.Provider>
    );
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  tpcForFilters: state.tpcForFilters,
}))(TPCForFiltersContainer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as AuthRequests from 'common/auth/AuthRequests';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import withContexts from 'common/util/withContexts';

import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Strings from './Strings';
import Tappable from './Tappable';
import validateInput from './validateInput';

import 'css/components/_EmailLoginForm.scss';

class EmailLoginForm extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    email: PropTypes.string,
    isWidget: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    submitting: false,
  };

  constructor(props, context) {
    super(props, context);

    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  close = () => {
    this.props.closeModal();
  };

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const email = this.emailRef.current.getValue().trim();
    const password = this.passwordRef.current.getValue();

    if (!validateInput.email(email)) {
      this.setState({
        error: Strings.invalidEmail,
      });
      return;
    } else if (!validateInput.password(password)) {
      this.setState({
        error: Strings.invalidPassword,
      });
      return;
    }

    this.setState({
      error: null,
      submitting: true,
    });

    const { error, parsedResponse, redirecting } = await AuthRequests.login({
      email: email,
      password: password,
      useTokenAuth: this.props.isWidget,
    });

    if (redirecting) {
      return;
    }

    if (!error) {
      this.setState(
        {
          submitting: false,
        },
        () => this.props.onSuccess(parsedResponse)
      );
      return;
    }

    this.setState({
      submitting: false,
      error: error.message,
    });
  };

  renderErrorMessage() {
    if (!this.state.error) {
      return;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    const { email } = this.props;
    return (
      <div className="emailLoginForm">
        <div className="topContainer">
          <h1>Log in to your account</h1>
          <form onSubmit={this.onSubmit}>
            <div className="inputs">
              <TextInput
                autoFocus={true}
                defaultValue={email ? email : ''}
                placeholder="Email"
                ref={this.emailRef}
              />
              <TextInput placeholder="Password" ref={this.passwordRef} type="password" />
            </div>
            {this.renderErrorMessage()}
            <div className="buttonContainer">
              <Tappable onTap={this.props.onForgotPassword}>
                <span className="greyLink">Forgot password?</span>
              </Tappable>
              <Button
                formButton={true}
                loading={this.state.submitting}
                tint={true}
                value="Log in"
              />
            </div>
          </form>
        </div>
        <Tappable onTap={this.props.onBack}>
          <div className="accountModalCTA">
            <span>Back</span>
          </div>
        </Tappable>
      </div>
    );
  }
}

export default withContexts(
  { closeModal: CloseModalContext, isWidget: IsWidgetContext },
  { forwardRef: true }
)(EmailLoginForm);

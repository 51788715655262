import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import CannyAttribution from 'common/CannyAttribution';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import Spinner from 'common/Spinner';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/public/_JoinBoardForm.scss';

class JoinBoardForm extends Component {
  static propTypes = {
    company: PropTypes.object,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    openModal: PropTypes.func,
    params: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    verified: false,
    error: null,
  };

  componentDidMount() {
    const { company, location, router, viewer } = this.props;
    const { query } = location;

    if (
      !validateInput.id(query.boardID) ||
      !validateInput.email(query.email) ||
      !validateInput.sessionID(query.inviteID)
    ) {
      router.replace({
        pathname: location.pathname,
        query: null,
      });
      return;
    }

    AJAX.post(
      '/api/boards/verifyInvite',
      {
        boardID: query.boardID,
        email: query.email,
        inviteID: query.inviteID,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error:
              'Your invitation is no longer available, please contact ' +
              company.name +
              ' to get access.',
          });
        } else if (viewer && !viewer.loggedOut) {
          this.onAcceptInvite();
        } else {
          this.setState({
            verified: true,
          });
        }
      }
    );
  }

  onAcceptInvite = () => {
    const { location, router } = this.props;
    const {
      params: { boardURLName },
    } = this.props;
    const { query } = location;

    AJAX.post(
      '/api/boards/acceptInvite',
      {
        boardID: query.boardID,
        email: query.email,
        inviteID: query.inviteID,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
          });
        } else {
          if (this._refreshCallback) {
            this._refreshCallback();
          } else {
            this.props.reloadBoard(boardURLName).then(() => {
              router.replace({
                pathname: location.pathname,
                query: null,
              });
            });
          }
        }
      }
    );
  };

  onOpenModal = () => {
    const {
      location: { query },
      openModal,
    } = this.props;
    openModal(AccountModal, {
      email: query.email,
      formState: FormStates.signup,
      onSuccess: (callback) => {
        this._refreshCallback = callback;
        this.onAcceptInvite();
      },
    });
  };

  renderCTA() {
    const { company, location } = this.props;
    const { authRedirectEnabled, authRedirectURL, name } = company;
    if (authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location);
      return (
        <>
          <div className="description">
            Log in with your {name} account to post and vote on&nbsp;feedback.
          </div>
          <a href={redirectURL} className="logInLink">
            <Button tint={true} value={'Log In To ' + name} />
          </a>
        </>
      );
    }

    return (
      <>
        <div className="description">Create an account to post and vote on&nbsp;feedback.</div>
        <Button onTap={this.onOpenModal} tint={true} value="Create an account" />
      </>
    );
  }

  renderContents() {
    const { error, verified } = this.state;
    if (error) {
      return <div className="error">{error}</div>;
    }
    if (!verified) {
      return <Spinner />;
    }

    const { company } = this.props;
    return (
      <div className="signup">
        <div className="title">
          {"You've been invited to join "}
          <span>{company.name + "'s"}</span>
          {' private\u00a0board'}
        </div>
        {this.renderCTA()}
      </div>
    );
  }

  render() {
    return (
      <div className="joinBoardForm">
        {this.renderContents()}
        <CannyAttribution source="feedback_subdomain" />
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      params: ParamsContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(JoinBoardForm);

import React, { useContext, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { OpenModalContext } from 'common/containers/ModalContainer';
import Draggable from 'common/Draggable';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import CheckboxInput from 'common/inputs/CheckboxInput';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminBoardSettingsCategoriesList.scss';

const ErrorMessages = {
  alreadyExists: 'This category name already exists.',
  defaultMessage: 'Something went wrong, please try again later.',
  invalidName: 'Please enter a valid category name. (1-30 characters)',
  planDoesNotSupport:
    'Your plan does not support adding a new category. Please upgrade your subscription to use categories.',
  notAuthorized:
    'You are not authorized to perform this operation. Please request additional permissions from a workspace owner/manager.',
};
const ListItemHeight = 50;

const AdminCategoryListItem = (props) => {
  const { board, category, onCreateSubCategoryClicked, reloadBoard, setError } = props;

  // context
  const openModal = useContext(OpenModalContext);

  // state
  const [deleting, setDeleting] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);
  const [dragStart, setDragStart] = useState(null);
  const [renaming, setRenaming] = useState(false);
  const [sendingRenameRequest, setSendingRenameRequest] = useState(false);

  // refs
  const renameInputRef = useRef();

  const onDragStart = (e) => {
    setDragStart(e.clientY);
    setDragging(true);
    props.onDragStart();
  };

  const onDragMove = (e) => {
    const dragOffset = e.clientY - dragStart;
    setDragOffset(dragOffset);
    props.onDragMove(dragOffset);
  };

  const onDragEnd = () => {
    setDragging(false);
    setDragOffset(0);
    setDragStart(null);
    props.onDragEnd();
  };

  const onCancelRenaming = () => {
    setRenaming(false);
  };

  const onRenameCategory = () => {
    setRenaming(true);
  };

  const onSaveRename = async () => {
    if (sendingRenameRequest) {
      return;
    }

    const categoryName = renameInputRef.current.getValue();
    const categoryExists =
      board.categories.find((category) => category.name === categoryName) &&
      categoryName !== category.name;
    if (!validateInput.category.name(categoryName)) {
      setError(ErrorMessages.invalidName);
      return;
    } else if (categoryExists) {
      setError(ErrorMessages.alreadyExists);
      return;
    }

    setSendingRenameRequest(true);
    setError(null);

    const response = await AJAX.post('/api/categories/rename', {
      categoryID: category._id,
      name: categoryName,
    });

    setSendingRenameRequest(false);
    setRenaming(false);

    if (response === 'success') {
      await reloadBoard(board.urlName);
    } else {
      setError(ErrorMessages.defaultMessage);
    }
  };

  const onDeleteCategory = () => {
    if (deleting) {
      return;
    }

    const suffix = category.postCount === 1 ? ' post' : ' posts';
    openModal(ConfirmModal, {
      message:
        "Are you sure you'd like to delete this category? It will be removed from " +
        category.postCount +
        suffix +
        '.',
      onConfirm: onDeleteConfirmed,
    });
  };

  const onDeleteConfirmed = async () => {
    setDeleting(true);

    const response = await AJAX.post('/api/categories/delete', {
      categoryID: category._id,
    });
    if (response === 'success') {
      await reloadBoard(board.urlName);
    } else {
      setError(ErrorMessages.defaultMessage);
    }

    setDeleting(false);
  };

  const onToggleSubscription = async () => {
    const endpointPrefix = '/api/categories/';
    const endpointSuffix = category.subscribed ? 'unsubscribe' : 'subscribe';
    const endpoint = endpointPrefix + endpointSuffix;
    const response = await AJAX.post(endpoint, {
      boardID: board._id,
      categoryID: category ? category._id : null,
    });

    if (response === 'success') {
      await reloadBoard(board.urlName);
    } else {
      setError(ErrorMessages.defaultMessage);
    }
  };

  const render = () => {
    const isCategoryParent = !category.parentID;
    const hasSubCategories = !!category.subCategories?.length;
    const containerClassNames = classnames({
      dragging,
      listItemContainer: true,
      subCategory: !!category.parentID,
    });
    const categoryClassNames = classnames({
      category: true,
      renaming,
    });
    const leftContainer = renaming ? (
      <>
        <TextInput autoFocus={true} defaultValue={category.name} ref={renameInputRef} />
        <div className="categorySettings">
          <Tappable onTap={onCancelRenaming}>
            <div>Cancel</div>
          </Tappable>
          <Tappable onTap={onSaveRename}>
            <div>Save</div>
          </Tappable>
        </div>
      </>
    ) : (
      <>
        <div className="categoryName">
          {category.name}
          {' ('}
          {category.postCount} {category.postCount === 1 ? 'post' : 'posts'}
          {')'}
        </div>
        <div className="categorySettings">
          {isCategoryParent && (
            <Tappable onTap={() => onCreateSubCategoryClicked(category)}>
              <div className="icon icon-sub-category" />
            </Tappable>
          )}
          <Tappable onTap={onRenameCategory}>
            <div className="icon icon-pencil" />
          </Tappable>
          {!hasSubCategories && (
            <Tappable onTap={onDeleteCategory}>
              <div className="icon icon-x" />
            </Tappable>
          )}
        </div>
      </>
    );

    return (
      <div className="adminCategoryListItem">
        <div className={containerClassNames} style={{ top: dragOffset }}>
          <Draggable
            onDragStart={onDragStart}
            onDragMove={onDragMove}
            onDragEnd={onDragEnd}
            pressTimeout={null}>
            <div className="dragContainer">
              <div className="icon icon-drag" />
            </div>
          </Draggable>
          <div className={categoryClassNames}>
            <div className="leftContainer">{leftContainer}</div>
            <Toggle onToggle={onToggleSubscription} value={category.subscribed} />
          </div>
        </div>
      </div>
    );
  };

  return render();
};

const AdminBoardSettingsCategoriesList = (props) => {
  const { board, categories = [], parentCategory, reloadBoard, setError, showCreateForm } = props;
  const openModal = useContext(OpenModalContext);

  // state
  const areSubCategories = !!parentCategory;
  const [creating, setCreating] = useState(false);
  const [dividerIndex, setDividerIndex] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [categoryToShowForm, setCategoryToShowForm] = useState(null);

  // refs
  const createInputRef = useRef();
  const subscribeAdminsInputRef = useRef();

  useEffect(() => {
    // reset values when board is reloaded
    setCategoryToShowForm(null);
    setDividerIndex(null);
  }, [board]);

  const onCreateInputChange = (e) => {
    const { value } = e.target;
    const isValueValid = value !== '';
    setError(null);
    setShowCreateButton(isValueValid);
  };

  const onCreateCategory = async () => {
    if (creating) {
      return;
    }

    const categoryName = createInputRef.current.getValue();
    const categoryExists = categories.find((category) => category.name === categoryName);
    if (!validateInput.category.name(categoryName)) {
      setError(ErrorMessages.invalidName);
      return;
    } else if (categoryExists) {
      setError(ErrorMessages.alreadyExists);
      return;
    }

    setCreating(true);
    setError(null);

    const subscribeAdmins = subscribeAdminsInputRef.current.getValue();
    const response = await AJAX.post('/api/categories/create', {
      boardID: board._id,
      name: categoryName,
      subscribeAdmins,
      ...(parentCategory && { parentID: parentCategory._id }),
    });

    setCreating(false);
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'name taken': ErrorMessages.alreadyExists,
        'plan does not support': ErrorMessages.planDoesNotSupport,
        default: ErrorMessages.defaultMessage,
        'not authorized': ErrorMessages.notAuthorized,
      },
    });

    if (error?.type === 'not authorized') {
      openModal(AccessModal, {
        requiredPermissions: ['manageCategories'],
      });
      return;
    }

    if (error) {
      setError(error.message);
      return;
    }

    await reloadBoard();
    createInputRef.current?.setValue?.('');
    setCategoryToShowForm(null);
    // trigger to re-order boards
    props.onCategoryCreated();
  };

  const onCreateSubCategoryClicked = (category) => {
    setCategoryToShowForm(category);
  };

  const onDragStart = () => {
    setDragging(true);
  };

  const calculateListItemHeights = (selectedCategoryIndex, offset) => {
    // calculate the total length of list items in the following direction of drop
    const isGoingDownwards = offset > 0;
    const itemsInDirection = isGoingDownwards
      ? categories.slice(selectedCategoryIndex + 1)
      : categories.slice(0, selectedCategoryIndex).reverse();
    const itemHeights = itemsInDirection.map((item) => {
      // calculate the total height of category with it's subCategories
      return ((item?.subCategories?.length ?? 0) + 1) * ListItemHeight;
    });

    // Need to subtract/sum itemLength based on direction
    if (!isGoingDownwards) {
      return itemHeights;
    }

    return itemHeights.map((itemLength) => itemLength * -1);
  };

  const calculateDividerIndex = (selectedCategoryIndex, offset, listItemHeights) => {
    const initialOffsetDirection = offset > 0 ? 'downwards' : 'upwards';
    const isGoingDownwards = initialOffsetDirection === 'downwards';
    const selectedCategory = categories[selectedCategoryIndex];

    // need to subtract subCategoryHeights from offset when going downwards
    const selectedCategoryHeight = isGoingDownwards
      ? (selectedCategory?.subCategories?.length ?? 0) * ListItemHeight
      : 0;
    let newOffset = offset - selectedCategoryHeight;
    let indexOffset = 0;

    // Need to loop through listItemHeights until offset becomes the opposite sign
    listItemHeights.forEach((itemHeight) => {
      const newOffsetDirection = newOffset > 0 ? 'downwards' : 'upwards';

      // if directions are not same, we find the index
      if (initialOffsetDirection !== newOffsetDirection) {
        return;
      }

      newOffset += itemHeight;
      indexOffset += 1;
    });

    const dividerIndex = isGoingDownwards
      ? selectedCategoryIndex + indexOffset + 1
      : selectedCategoryIndex - indexOffset;
    if (selectedCategoryIndex === dividerIndex || selectedCategoryIndex + 1 === dividerIndex) {
      setDividerIndex(null);
    } else {
      setDividerIndex(dividerIndex);
    }
  };

  const onDragMove = (categoryIndex, offset) => {
    const listItemHeights = calculateListItemHeights(categoryIndex, offset);
    calculateDividerIndex(categoryIndex, offset, listItemHeights);
  };

  const onDragEnd = (categoryIndex) => {
    setDragging(false);

    if (dividerIndex === null) {
      return;
    }

    const category = categories[categoryIndex];
    const shouldComeBefore = categories[dividerIndex];

    props.onDragCompleted(category, shouldComeBefore);
  };

  const onToggleUncategorizedSubscription = async (category) => {
    const endpointSuffix = board.uncategorized.subscribed ? 'unsubscribe' : 'subscribe';
    const endpoint = `/api/categories/${endpointSuffix}`;
    const response = await AJAX.post(endpoint, {
      boardID: board._id,
      categoryID: category ? category._id : null,
    });

    if (response === 'success') {
      reloadBoard();
      return;
    } else {
      setError(ErrorMessages.defaultMessage);
    }
  };

  const renderUncategorizedItem = () => {
    if (areSubCategories) {
      return null;
    }

    const uncategorizedPosts =
      board.postCount -
      categories.reduce((categorizedPosts, category) => {
        return categorizedPosts + category.postCount;
      }, 0);

    return (
      <div className="adminCategoryListItem">
        <div className="listItemContainer">
          <div key={'uncategorized'} className="category uncategorized">
            <div className="leftContainer">
              <div className="categoryName">
                {'Uncategorized ('}
                {uncategorizedPosts} {uncategorizedPosts === 1 ? 'post' : 'posts'}
                {')'}
              </div>
            </div>
            <Toggle
              onToggle={onToggleUncategorizedSubscription}
              value={board.uncategorized.subscribed}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCreateButton = () => {
    if (!showCreateButton) {
      return null;
    }

    return (
      <div className="buttonContainer">
        <CheckboxInput
          defaultChecked={true}
          label="Subscribe all admins"
          ref={subscribeAdminsInputRef}
        />
        <Button formButton={true} loading={creating} value="Create" />
      </div>
    );
  };

  const renderCreateCategoryForm = () => {
    if (!showCreateForm) {
      return null;
    }

    const placeholder = areSubCategories ? 'Create new sub-category...' : 'Create new category...';
    const classNames = classnames({
      subCategories: areSubCategories,
      createCategoryForm: true,
    });
    return (
      <Form
        className={classNames}
        addEventsToDocument={false}
        disableSubmit={creating}
        onSubmit={onCreateCategory}>
        <TextInput onChange={onCreateInputChange} placeholder={placeholder} ref={createInputRef} />
        {renderCreateButton()}
      </Form>
    );
  };

  const render = () => {
    const renderItems = categories.map((category, index) => {
      const showCreateForm = category._id === categoryToShowForm?._id;
      return (
        <div key={category._id}>
          <AdminCategoryListItem
            key={category._id}
            board={board}
            category={category}
            onDragStart={onDragStart}
            onDragMove={(offset) => onDragMove(index, offset)}
            onDragEnd={() => onDragEnd(index)}
            reloadBoard={reloadBoard}
            setError={setError}
            onCreateSubCategoryClicked={onCreateSubCategoryClicked}
          />
          <AdminBoardSettingsCategoriesList
            board={board}
            categories={category.subCategories}
            onCategoryCreated={props.onCategoryCreated}
            onDragCompleted={props.onDragCompleted}
            parentCategory={category}
            reloadBoard={reloadBoard}
            setError={setError}
            showCreateForm={showCreateForm}
          />
        </div>
      );
    });

    if (dividerIndex !== null) {
      renderItems.splice(dividerIndex, 0, <div className="divider" key="divider" />);
    }

    const classNames = classnames({
      adminBoardSettingsCategoriesList: true,
      dragging,
      subCategories: areSubCategories,
    });

    return (
      <div className={classNames}>
        <div className="categoriesContainer">
          {!areSubCategories && (
            <UppercaseHeader className="subscribedHeader">Subscribed</UppercaseHeader>
          )}
          <div className="categories">
            {renderUncategorizedItem()}
            {renderItems}
          </div>
          {renderCreateCategoryForm()}
        </div>
      </div>
    );
  };

  return render();
};

export default AdminBoardSettingsCategoriesList;

import Themes from 'common/util/theme';
import validateInput from 'common/validateInput';

import type { HexColor, RGBColor } from 'common/colors/utils';

const Black = 0;
const HexBase = 16;
const HexColorLenght = 2;
const White = 255;

const hexToRGB = (
  hex: HexColor | null,
  opacity = 1,
  theme: Themes = Themes.light
): RGBColor | null => {
  if (!hex || !validateInput.hexColor(hex) || opacity > 1 || opacity < 0) {
    return null;
  }

  let hexWithoutPound = hex.replace('#', '');
  if (hexWithoutPound.length === 3) {
    hexWithoutPound =
      hexWithoutPound[0] +
      hexWithoutPound[0] +
      hexWithoutPound[1] +
      hexWithoutPound[1] +
      hexWithoutPound[2] +
      hexWithoutPound[2];
  }
  const splitIdxs = [0, 2, 4];
  const hexColors = splitIdxs.map((index) => hexWithoutPound.substr(index, HexColorLenght));
  const rgbColors = hexColors
    .map((hexColor) => Number.parseInt(hexColor, HexBase))
    .map((color) =>
      Math.round(
        Math.max(
          Black,
          Math.min(White, opacity * color + (1 - opacity) * (theme === 'light' ? White : Black))
        )
      )
    );

  return [rgbColors[0], rgbColors[1], rgbColors[2]];
};

export default hexToRGB;

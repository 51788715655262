import { Component } from 'react';

import PropTypes from 'prop-types';

import { getThirdPartyCompanyPostsKey, loadQuery } from 'common/actions/thirdPartyCompanyPosts';

import { loadThirdPartyCompany } from '../actions/thirdPartyCompanies';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

function getQueryParams(urlName, location) {
  const { filter } = location.query;
  return {
    filter: filter || 'posts-votes-comments',
    urlName,
  };
}

const getPostList = (urlName, thirdPartyCompanyPosts, allPosts, location) => {
  const queryKey = getThirdPartyCompanyPostsKey(getQueryParams(urlName, location));
  const queryKeyResult = Object.assign({ loading: true }, thirdPartyCompanyPosts[queryKey]);
  const posts = [];

  if (queryKeyResult.postIDs && queryKeyResult.postIDs.length) {
    const postsByID = {};

    Object.keys(allPosts).forEach((boardID) => {
      Object.keys(allPosts[boardID]).forEach((postKey) => {
        const post = allPosts[boardID][postKey];
        postsByID[post._id] = post;
      });
    });

    queryKeyResult.postIDs.forEach((id) => {
      posts.push({
        ...postsByID[id],
      });
    });
  }

  return {
    ...queryKeyResult,
    posts,
  };
};

const asyncFetch = {
  promise: ({ store: { dispatch }, location, params }) => {
    return Promise.all([
      dispatch(loadThirdPartyCompany(params.companyURLName)),
      dispatch(loadQuery(getQueryParams(params.companyURLName, location))),
    ]);
  },
};

class ThirdPartyCompanyContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
    posts: PropTypes.object,
    thirdPartyCompanies: PropTypes.object,
    thirdPartyCompanyPosts: PropTypes.object,
  };

  render() {
    const {
      children,
      location,
      params: { companyURLName },
      posts,
      thirdPartyCompanies,
      thirdPartyCompanyPosts,
    } = this.props;

    const thirdPartyCompany = thirdPartyCompanies[companyURLName];
    const postList = getPostList(companyURLName, thirdPartyCompanyPosts, posts, location);

    return cloneElementWithProps(children, {
      ...this.props,
      thirdPartyCompany,
      postList,
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  posts: state.posts,
  thirdPartyCompanies: state.thirdPartyCompanies,
  thirdPartyCompanyPosts: state.thirdPartyCompanyPosts,
}))(ThirdPartyCompanyContainer);

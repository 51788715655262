import React, { useContext, useRef, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Pill from 'common/common/Pill';
import {
  CreditsPerIntegrationItemName,
  type IntegrationItemNames,
  type SyncIntegrationNames,
} from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import AdminAutopilotInfoBox from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotInfoBox';
import { InfoPoints } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import ButtonV2 from 'common/ui/ButtonV2';
import SwitchV2 from 'common/ui/SwitchV2';
import { H3, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/AdminQueue/AdminIntegrationAutopilotSettingsSection/_AdminIntegrationAutopilotSettingsSection.module.scss';

import type { Company } from 'common/api/endpoints/companies';

type ConnectProps = {
  reloadCompany: () => void;
};

type OwnProps = {
  integrationName: SyncIntegrationNames;
  integrationLabel: string;
  integrationType: IntegrationItemNames;
};

type Props = OwnProps & ConnectProps;

const getDefaultEnabledState: (
  company: Company,
  integrationName: SyncIntegrationNames
) => boolean = (company, integrationName) => {
  return !!company.installedSyncIntegrations.find((integration) => {
    return integration.integrationName === integrationName && !integration.disabled;
  });
};

const AdminIntegrationAutopilotSettingsSection = ({
  integrationName,
  integrationLabel,
  integrationType,
  reloadCompany,
}: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  // state
  const points = InfoPoints[integrationName] ?? InfoPoints.conversationDefault;
  const cost = CreditsPerIntegrationItemName[integrationType];

  const defaultEnabledState = useRef(getDefaultEnabledState(company, integrationName));
  const [enabled, setEnabled] = useState(defaultEnabledState.current);
  const [loading, setLoading] = useState(false);

  const canSave = defaultEnabledState.current !== enabled && !loading;

  // helpers
  const onSave = async () => {
    setLoading(true);

    const response = await AJAX.post(`/api/queue/toggleIntegrationSync`, {
      integrationName,
      enabled,
    });

    setLoading(false);

    const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
    if (error) {
      setEnabled(!enabled);
      showToast(error.message, ToastTypes.error);
      return;
    }

    defaultEnabledState.current = enabled;
    reloadCompany();
  };

  return (
    <section className={styles.container}>
      <div className={styles.heading}>
        <H3 variant="headingMd" className={styles.headingCopy}>
          Canny Autopilot
        </H3>
        <Pill className={styles.headingPill}>
          {cost} {cost > 1 ? 'credits' : 'credit'} / {integrationType}
        </Pill>
      </div>
      <div className={styles.subheading}>
        <P>Enable Autopilot to capture user feedback found in {integrationLabel} conversations.</P>
        <SwitchV2 size="medium" checked={enabled} onChange={setEnabled} />
      </div>
      <AdminAutopilotInfoBox points={points} className={styles.infoBox} />
      <ButtonV2
        size="medium"
        className={styles.saveButton}
        disabled={!canSave}
        loading={loading}
        onClick={onSave}>
        Save
      </ButtonV2>
    </section>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(AdminIntegrationAutopilotSettingsSection) as unknown as React.FC<OwnProps>;

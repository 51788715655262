import React, { Component } from 'react';

import PropTypes, { type InferProps } from 'prop-types';

import abbreviateNumber from '../util/abbreviateNumber';

import 'css/components/post/_PostCommentCount.scss';

export default class PostCommentCount extends Component<
  InferProps<typeof PostCommentCount.propTypes>
> {
  static propTypes = {
    post: PropTypes.shape({
      commentCount: PropTypes.number.isRequired,
    }).isRequired,
  };

  render() {
    const { post } = this.props;
    return (
      <div className="postCommentCount">
        <span className="icon icon-comment" />
        <span>{abbreviateNumber(post.commentCount)}</span>
      </div>
    );
  }
}

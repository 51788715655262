import React, { type MouseEventHandler, useState } from 'react';

import classnames from 'classnames';
import { ChevronUp } from 'lucide-react';

import abbreviateNumber from 'common/util/abbreviateNumber';
import { isNotNil } from 'common/util/isNil';

import type { HexColor } from 'common/colors/utils';

import 'css/components/post/_PostVotesV2.scss';

type Props = {
  highlight: HexColor | null;
  score: number | null;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const PostVotesUI = ({ highlight: highlightOrNull, onClick, score }: Props) => {
  const [hovering, setHovering] = useState<boolean>(false);

  const highlight = highlightOrNull ?? undefined;
  return (
    <button
      className={classnames('postVotesV2', { hovering, highlight })}
      onClick={onClick}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      style={highlight && { borderColor: highlight }}>
      <div className="background" style={highlight && { backgroundColor: highlight }} />
      <ChevronUp className="chevron" style={highlight && { color: highlight }} />
      {isNotNil(score) ? <span className="score">{abbreviateNumber(score)}</span> : null}
    </button>
  );
};

export default PostVotesUI;

import React from 'react';

import AdminOnboardingHeader, { AdminOnboardingSubHeader } from './AdminOnboardingHeader';
import AdminOnboardingMultiSelect from './AdminOnboardingMultiSelect';
import AdminOnboardingOtherInput from './AdminOnboardingOtherInput';
import { type UseCase } from './Types';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  onChange: (useCase: UseCase) => void;
  useCase: UseCase;
};

const AdminOnboardingSource = ({ onChange, useCase }: Props) => {
  // helpers
  const onChangeOther = (other: string) => {
    onChange({
      ...useCase,
      otherMarketingSource: other,
    });
  };

  const onChangeSelect = (marketingSources: string[]) => {
    onChange({
      ...useCase,
      marketingSources,
    });
  };

  const { marketingSources, otherMarketingSource } = useCase;
  const selectedOther = marketingSources.includes('other');
  return (
    <div className="onboardingSource">
      <AdminOnboardingHeader>How did you hear about us?</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>Select all that apply:</AdminOnboardingSubHeader>
      <AdminOnboardingMultiSelect
        onChange={onChangeSelect}
        options={[
          { name: 'search', label: 'Search engine' },
          { name: 'socialMedia', label: 'Social media' },
          { name: 'review', label: 'Review site (e.g. Capterra)' },
          { name: 'blog', label: 'Blog/other publications' },
          { name: 'usedCanny', label: 'Used Canny to collect feedback' },
          { name: 'seenCanny', label: 'Used Canny to give feedback' },
          { name: 'friendColleague', label: 'Friend/Colleague' },
          { name: 'podcast', label: 'Podcast' },
          { name: 'webinar', label: 'Webinar' },
          { name: 'other', label: 'Other' },
        ]}
        selected={marketingSources}
      />
      {selectedOther && (
        <AdminOnboardingOtherInput
          onChange={onChangeOther}
          placeholder="e.g. online community, industry event, etc."
          value={otherMarketingSource}
        />
      )}
    </div>
  );
};

export default AdminOnboardingSource;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ContentContainer from 'common/containers/ContentContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import SubdomainSidebarContainer from 'common/containers/SubdomainSidebarContainer';
import SubdomainPostHelmet from 'common/helmets/SubdomainPostHelmet';
import EditPostForm from 'common/post/EditPostForm';
import Post from 'common/post/Post';
import Spinner from 'common/Spinner';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_SubdomainPost.scss';

class SubdomainPost extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
      urlName: PropTypes.string,
    }),
    getPostLink: PropTypes.func,
    location: PropTypes.object,
    post: PropTypes.shape({
      urlName: PropTypes.string,
    }),
    postActivity: PropTypes.shape({
      activities: PropTypes.array,
      commentReplies: PropTypes.array,
      comments: PropTypes.object,
      mergedPosts: PropTypes.object,
      statusHistories: PropTypes.object,
    }),
    router: PropTypes.object,
  };

  state = {
    isEditing: false,
  };

  componentDidMount() {
    this.fixURL();
  }

  fixURL() {
    const { board, getPostLink, params, post, router, location } = this.props;
    const validBoard = board && (board._id || board.requestedBoardID);
    const validPost = post && post._id && post.board && post.board._id;

    // permissions are handled on board level
    if (board?.requestedBoardID && (post.notAuthorized || post.notFound)) {
      router.replace('/' + params.boardURLName);
    }

    if (!validBoard || !validPost) {
      return;
    }

    if (board._id === post.board._id && params.postURLName === post.urlName) {
      return;
    }

    router.replace({
      pathname: getPostLink(post),
      query: location.query,
    });
  }

  onEdit = () => {
    this.setState({ isEditing: true });
  };

  onPostDeleted = () => {
    const { board, router } = this.props;
    router.push({
      pathname: '/' + board.urlName,
      query: {
        postDeleted: true,
      },
    });
  };

  onPostEdited = (post) => {
    const { getPostLink, location, router } = this.props;
    router.replace({
      pathname: getPostLink(post),
      query: location.query,
    });
    this.setState({ isEditing: false });
  };

  renderContents() {
    const { board, post } = this.props;
    if (this.state.isEditing) {
      return <div className="editContainer">{this.renderEditMode()}</div>;
    }

    return (
      <SubdomainSidebarContainer board={board} post={post}>
        <div className="postContainer">{this.renderViewMode()}</div>
      </SubdomainSidebarContainer>
    );
  }

  renderViewMode() {
    const { board, post, postActivity } = this.props;

    if (post.error) {
      return <div className="notFound">Something went wrong, please try again later.</div>;
    } else if (post.loading) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    } else if (post.notFound) {
      return <div className="notFound">There is no such post.</div>;
    }

    if (board?.requestedBoardID) {
      return;
    }

    return (
      <Post
        board={board}
        onEdit={this.onEdit}
        onPostDeleted={this.onPostDeleted}
        post={post}
        postActivity={postActivity}
        showCustomPostFields={post.viewerIsAuthor}
        viewType="public"
      />
    );
  }

  renderEditMode() {
    const { board, post } = this.props;
    if (post.error) {
      return <div className="other card">Something went wrong, please try again later.</div>;
    } else if (post.loading) {
      return (
        <div className="other card">
          <Spinner />
        </div>
      );
    } else if (post.notFound) {
      return <div className="other card">There is no such post.</div>;
    }

    return (
      <div className="formContainer card">
        <h1>Edit Post</h1>
        <EditPostForm
          board={board}
          onPostEdited={this.onPostEdited}
          post={post}
          showCustomPostFields={post.viewerIsAuthor}
        />
      </div>
    );
  }

  render() {
    const { board, post } = this.props;
    const key = post ? post._id : 'no post';
    return (
      <ContentContainer key={key} outerClassName="subdomainPost">
        <SubdomainPostHelmet board={board} post={post} />
        {this.renderContents()}
      </ContentContainer>
    );
  }
}

export default withContexts({ getPostLink: GetPostLinkContext, location: LocationContext })(
  SubdomainPost
);

import promisify from 'common/util/promisify';

import Data from '../Data';

import type { AsanaSearchTask } from 'common/api/endpoints/asanaTasks';
import type { Dispatch, GetState, State } from 'redux-connect';

export const Invalidate = 'canny/asana_task_suggestions/invalidate';
export const SuggestionsError = 'canny/asana_task_suggestions/suggestions_error';
export const SuggestionsLoaded = 'canny/asana_task_suggestions/suggestions_loaded';

// Actions
function suggestionsLoaded(search: string, suggestions: AsanaSearchTask[]) {
  return {
    search,
    suggestions,
    timestamp: Date.now(),
    type: SuggestionsLoaded,
  };
}

function suggestionsError(search: string, error: string) {
  return {
    error,
    search,
    timestamp: Date.now(),
    type: SuggestionsError,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators
function fetchSuggestions(search: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          tasks: {
            input: { search },
            query: Data.queries.asanaTaskSuggestions,
          },
        },
        cookies
      );
      return dispatch(suggestionsLoaded(search, response.tasks));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(suggestionsError(search, message));
    }
  };
}

export function loadSuggestions(search: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetch(getState(), search)) {
      return dispatch(fetchSuggestions(search));
    }
  };
}

export function invalidateSuggestions() {
  return (dispatch: Dispatch) => {
    return dispatch(invalidate());
  };
}

// Helpers
function shouldFetch(state: State, search: string) {
  if (typeof search !== 'string' || search.length === 0) {
    return false;
  }

  const taskSuggestions = state.asanaTaskSuggestions;
  return !taskSuggestions[search];
}

import React, { type FC, useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminGeneralPreferencesSettings.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

const AdminGeneralPreferencesSettings: FC<Props> = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [enableGlobalPostSearch, setGlobalSearch] = useState<boolean>(
    company.enableGlobalPostSearch
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const savePreferences = async () => {
    setLoading(true);
    setError(null);

    const response = await AJAX.post('/api/company/changeSettings', {
      enableGlobalPostSearch,
    });

    setLoading(false);

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      setError(error.message);
      return;
    }

    reloadCompany();
  };

  return (
    <div className="adminGeneralPreferencesSettings">
      <Helmet title="Preferences | General settings | Canny" />
      <h2>Board search results</h2>
      <p className="description">
        By default, searching on a board or creating a new post will bring up posts across all
        boards. Turning this off will limit results to the current&nbsp;board.
      </p>
      <div className="toggleContainer">
        <p className="value">Show search results across all boards</p>
        <Toggle
          onToggle={() => setGlobalSearch((enableGlobalPostSearch) => !enableGlobalPostSearch)}
          value={enableGlobalPostSearch}
        />
      </div>
      <Button
        buttonType="cannyButton"
        className="saveButton"
        disabled={enableGlobalPostSearch === company.enableGlobalPostSearch}
        loading={loading}
        onTap={savePreferences}
        value="Save preferences"
      />
      {error && (
        <div className="error" role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.general.preferences),
    '/admin/settings'
  )
)(AdminGeneralPreferencesSettings) as unknown as React.FC<OwnProps>;

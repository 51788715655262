import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ControlledDropdown from 'common/ControlledDropdown';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Link from 'common/Link';
import ModalPortal from 'common/modals/ModalPortal';
import Tappable from 'common/Tappable';
import validateInput from 'common/validateInput';

import 'css/components/modals/_CreateBoardFieldModal.scss';

export default class CreateBoardFieldModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
  };

  state = {
    customPostField: null,
    error: null,
    label: null,
    placeholder: null,
  };

  onSubmit = ({ customPostField, label, placeholder }) => {
    const { onCreate } = this.props;

    if (!validateInput.customPostField.label(label)) {
      this.setState({
        error: 'Please enter a valid label (1-30 characters)',
      });
      return;
    } else if (!validateInput.customPostField.placeholder(placeholder)) {
      this.setState({
        error: 'Please enter a valid placeholder (1-35 characters)',
      });
      return;
    }

    return onCreate({ customPostField, label, placeholder });
  };

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { customPostFields, onClose } = this.props;
    const { customPostField, label, placeholder } = this.state;

    const isEnabled = label && customPostField && placeholder;
    return (
      <ModalPortal className="boardFieldPortal" closeOnClickAway onClose={onClose}>
        <div className="createBoardFieldModal">
          <div className="modalHeader">
            <h2>Add field</h2>
            <Tappable onTap={onClose}>
              <div className="closeIcon icon icon-x" />
            </Tappable>
          </div>
          <p className="info">
            Select the post field you want to add to the create post form. To create new fields head
            to your <Link to="/admin/settings/fields/post-fields">Post Fields</Link> settings.
          </p>
          <Form
            addEventsToDocument={false}
            disableSubmit={!isEnabled}
            onSubmit={() => this.onSubmit({ customPostField, label, placeholder })}>
            <div className="formContainer">
              <div className="fields">
                <ControlledDropdown
                  options={customPostFields.map((customPostField) => ({
                    name: customPostField._id,
                    render: customPostField.name,
                  }))}
                  onChange={(customPostFieldID) =>
                    this.setState({
                      customPostField: customPostFields.find(
                        (field) => field._id === customPostFieldID
                      ),
                    })
                  }
                  placeholder="Post Field"
                  selectedName={customPostField?._id}
                />
                <TextInput
                  autoFocus={true}
                  inset="Field label"
                  onChange={(e) => this.setState({ label: e.target.value })}
                />
                <TextInput
                  inset="Placeholder"
                  onChange={(e) => this.setState({ placeholder: e.target.value })}
                />
              </div>
              {this.renderError()}
              <div className="buttons">
                <Button
                  buttonType="cannyButton"
                  disabled={!isEnabled}
                  formButton={true}
                  value="Save"
                />
              </div>
            </div>
          </Form>
        </div>
      </ModalPortal>
    );
  }
}

import React from 'react';

import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import { RoutePermissions } from 'common/util/permissions';

import AdminSettingsSidebarLink from './AdminSettingsSidebarLink';

import 'css/components/subdomain/admin/_AdminFieldsSettingsContainer.scss';

type FieldsSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.postFields;
};

const SidebarLinks: FieldsSidebarLink[] = [
  {
    label: 'Post Fields',
    urlSuffix: 'post-fields',
  },
  {
    label: 'Company Fields',
    urlSuffix: 'company-fields',
  },
];

type Props = {
  children: React.ReactNode;
};

const AdminFieldsSettingsContainer = ({ children }: Props) => (
  <div className="adminFieldsSettingsContainer">
    <AdminSettingsHeader
      title="Fields"
      subheading="Configure fields used across your workspace."
      learnMoreLink="https://help.canny.io/en/articles/6329585-customizing-fields"
    />
    <div className="settingsContainer">
      <div className="settingsSidebar">
        {SidebarLinks.map(({ label, urlSuffix }) => (
          <AdminSettingsSidebarLink
            key={urlSuffix}
            requiredPermissions={RoutePermissions.adminSettings.postFields[urlSuffix]}
            to={`/admin/settings/fields/${urlSuffix}`}>
            {label}
          </AdminSettingsSidebarLink>
        ))}
      </div>
      <div className="settingsContent">{children}</div>
    </div>
  </div>
);

export default AdminFieldsSettingsContainer;

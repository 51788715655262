import React, { useContext, useState } from 'react';

import { X } from 'lucide-react';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Spinner from 'common/Spinner';
import AdminAsanaSearchForm from 'common/subdomain/admin/AdminAsanaPostSidebarSection/AdminAsanaSearchForm';
import AdminCreateAsanaTaskModal from 'common/subdomain/admin/AdminCreateAsanaTaskModal';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { AsanaTask } from 'common/api/endpoints/asanaTasks';
import type { Company } from 'common/api/endpoints/companies';
import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

import 'css/components/subdomain/admin/_AdminAsanaPostSidebarSection.scss';

type Props = {
  post: Post;
  reloadPost(post: Post): void;
};

const AdminAsanaPostSidebarSection = ({ post, reloadPost }: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);

  // state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSearchFormOpen, setIsSearchFormOpen] = useState<boolean>(false);
  const [removingTaskID, setRemovingTaskID] = useState<string | null>(null);

  // helpers
  const onCreateTask = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onToggleSearchForm = () => {
    setIsSearchFormOpen((state) => !state);
  };

  const removeTask = async (task: AsanaTask) => {
    if (removingTaskID === task.id) {
      return;
    }

    setRemovingTaskID(task.id);

    const response = await AJAX.post('/api/asana/unlinkTask', { taskLinkID: task.linkID });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (!error) {
      await reloadPost(post);
    }

    setRemovingTaskID(null);
  };

  // renders
  const renderAsanaTasks = () => {
    const { asanaTasks } = post;

    if (!asanaTasks?.length) {
      return null;
    }

    const tasks = asanaTasks.map((task) => (
      <div key={task.id} className="task">
        <a href={task.url} rel="noopener" target="_blank">
          {task.name}
        </a>
        {removingTaskID === task.id ? (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        ) : (
          <Tappable onTap={() => removeTask(task)}>
            <div className="iconXContainer">
              <X size={18} />
            </div>
          </Tappable>
        )}
      </div>
    ));

    return <div className="asanaTasks">{tasks}</div>;
  };

  const renderCreateButton = () => {
    return (
      <Tappable onTap={onCreateTask}>
        <div className="createButton">Create new task</div>
      </Tappable>
    );
  };

  const renderSearchForm = () => {
    if (!isSearchFormOpen) {
      return null;
    }

    return <AdminAsanaSearchForm post={post} />;
  };

  const renderAction = () => {
    return (
      <Tappable onTap={onToggleSearchForm}>
        <div>Link Task</div>
      </Tappable>
    );
  };

  const render = () => {
    const doesPlanSupportIntegration = company?.integrations?.asana;
    const isIntegrationActive = !!company?.activeIntegrations?.asana;

    if (!doesPlanSupportIntegration || !isIntegrationActive) {
      return null;
    }

    return (
      <AdminSidebarSection
        action={renderAction()}
        className="adminAsanaPostSidebarSection"
        title="Asana Tasks">
        {renderSearchForm()}
        {renderAsanaTasks()}
        {renderCreateButton()}
        {isModalOpen && <AdminCreateAsanaTaskModal post={post} onClose={onCloseModal} />}
      </AdminSidebarSection>
    );
  };

  return render();
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadPost: (post: Post) => {
    return Promise.all([dispatch(invalidatePostQueries()), dispatch(reloadPost(post))]);
  },
}))(AdminAsanaPostSidebarSection);

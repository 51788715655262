import React from 'react';

import Pill from 'common/common/Pill';
import { type VoteWeight, WeightMap } from 'common/post/PostVotes/Constants';
import isNil from 'common/util/isNil';

type Props = {
  weight?: VoteWeight;
};

const VoteWeightPill = ({ weight }: Props) => {
  if (isNil(weight) || !(weight in WeightMap)) {
    return null;
  }

  const weightToRender = WeightMap[weight];
  return (
    <div className="voteWeightPill">
      <Pill pillStyle={weightToRender.pillStyle}>{weightToRender.name}</Pill>
    </div>
  );
};

export default VoteWeightPill;

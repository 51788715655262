import createCustomError from 'common/util/createCustomError';
import unique from 'common/util/unique';

import {
  type ActionOptions,
  type ActionType,
  type EditableAutomation,
  type FilterOptions,
  type Resource,
  automationSchema,
} from './constants';

export const AutomationUtilError = createCustomError('validateAutomation');

export const getRequiredFilters = (automation: EditableAutomation): Resource[] => {
  const requiredFields = Object.entries(automationSchema[automation.trigger].actions)
    .filter(([actionType]) => automation.actions.some((action) => action.type === actionType))
    .flatMap(([_, options]) => options.requiredFields);

  return unique(requiredFields).sort();
};

export const getAllowedFilters = (automation: EditableAutomation): Resource[] => {
  const filtersObject = automationSchema[automation.trigger]?.filters;
  if (!filtersObject) {
    throw new AutomationUtilError({
      message: `Could not find schema details for trigger: ${automation.trigger}`,
    });
  }

  const resourceLimits = Object.entries(filtersObject) as [Resource, FilterOptions][];
  return resourceLimits
    .filter(([key, filterOptions]) => {
      const matchingFilters = automation.filters.filter((filter) => filter.resource === key);
      return matchingFilters.length < filterOptions.max;
    })
    .map(([key, _]) => key);
};

export const getAllowedActions = (automation: EditableAutomation): ActionType[] => {
  const actionsObject = automationSchema[automation.trigger]?.actions;
  if (!actionsObject) {
    throw new AutomationUtilError({
      message: `Could not find schema details for trigger: ${automation.trigger}`,
    });
  }

  const actionLimits = Object.entries(actionsObject) as [ActionType, ActionOptions][];
  return actionLimits
    .filter(([key, actionOptions]) => {
      const matchingActions = automation.actions.filter((action) => action.type === key);
      return matchingActions.length < actionOptions.max;
    })
    .map(([key, _]) => key);
};

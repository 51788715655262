import DefaultCompanyRoleNames, { hasRoleName } from './CompanyRoleNames';

import type { Company, Role } from 'common/api/endpoints/companies';

type Permissions = Role['permissions'];

export const NonPaidPermissions: Record<string, boolean> = {
  manageTeammates: true,
};

export const getEnabledPermissions = (permissions: Permissions) => {
  return Object.entries(permissions)
    .filter(([_, enabled]) => enabled)
    .map(([permission, _]) => permission);
};

export const getFreeTierPermissions = (company: Company) => {
  const contributorRole = company.roles.find(hasRoleName(DefaultCompanyRoleNames.contributor));

  if (!contributorRole) {
    // all companies should have a contributor role, however in the case that they don't we will assume all permissions are billable
    return [];
  }

  const freeTierPermissions = {
    ...contributorRole.permissions,
    ...NonPaidPermissions,
  };

  return getEnabledPermissions(freeTierPermissions);
};

// A utility for the frontend to determine if a role is billable. It works by fetching the contributor role from the company obj and comparing the permissions.
// For any backend code use roleHasBillablePermissions defined in server/services/BillingService.ts
export const companyRoleHasBillablePermissions = (company: Company, role: Omit<Role, '_id'>) => {
  const contributorRole = company.roles.find(hasRoleName(DefaultCompanyRoleNames.contributor));

  if (!contributorRole) {
    // all companies should have a contributor role, however in the case that they don't we will assume that the role is billable
    return true;
  }
  const freeTierPermissions = getEnabledPermissions({
    ...contributorRole.permissions,
    ...NonPaidPermissions,
  });
  const enabledPermissions = getEnabledPermissions(role.permissions);

  return enabledPermissions.some((permission) => !freeTierPermissions.includes(permission));
};

import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import getCannyOrigin from 'common/util/getCannyOrigin';

import Helmet from './Helmet';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

type Props = {
  roadmap: Roadmap;
};

const SharedRoadmapHelmet = ({ roadmap }: Props) => {
  const company = useContext(CompanyContext);
  if (!roadmap || !company) {
    return null;
  }

  const title = roadmap.name + ' | ' + company.name;
  const url = getCannyOrigin(company) + '/' + '/r/' + roadmap.urlName + '/voters';

  return <Helmet title={title} link={[{ href: url, rel: 'canonical' }]} />;
};

export default SharedRoadmapHelmet;

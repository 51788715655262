import type { Board } from 'common/api/endpoints/boards';
import type { StrippedUser } from 'common/api/endpoints/users';
import type {
  PostAction,
  PostDraftCreateAction,
  PostDraftDismissAction,
  PostDraftMergeAction,
} from 'common/api/resources/autopilotActions';
import type { PostDraft } from 'common/api/resources/postDrafts';
import type { Post } from 'common/api/resources/posts';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

// types related to the output of GetQueueItemsEndpoint and the types used in QueueSuggestedCell etc
export enum QueueItemType {
  post = 'post',
  draft = 'draft',
}

type WithAuthor<T> = T & { author: StrippedUser };

export type QueuePost = Post & {
  type: QueueItemType.post;
  source: 'canny';
};

export type QueuePostDraft = PostDraft & {
  type: QueueItemType.draft;
  source: SyncIntegrationNames;
  link: string | null;
};

export type QueueItem = WithAuthor<QueuePost | QueuePostDraft>;

// types related to the output of GetAutopilotActionItemsEndpoint and the types used in AuditLogSuggestionCell etc
// to optimise performance, we only return the fields needed for the UI - hence the StrippedPost and StrippedPostDraft types

export type ActionFields =
  | '_id'
  | 'actionType'
  | 'boardID'
  | 'createdAt'
  | 'createdPostID'
  | 'duplicatePostID'
  | 'sourceID'
  | 'sourceType'
  | 'actionType';

export type PostDraftFields =
  | '_id'
  | 'authorID'
  | 'createdAt'
  | 'details'
  | 'featureExtractionItemID'
  | 'workaround'
  | 'title';

export type PostFields =
  | '_id'
  | 'authorID'
  | 'boardID'
  | 'commentCount'
  | 'created'
  | 'details'
  | 'score'
  | 'title'
  | 'totalMRR'
  | 'urlName';

export type FeatureExtractionItemFields =
  | '_id'
  | 'integrationItemID'
  | 'integrationName'
  | 'integrationItemSourceURL';

type StrippedPost = Pick<Post, PostFields> & { board: Board }; // board is added by AutopilotActionItemListContainer and is not returned by the API
type StrippedPostDraft = Pick<PostDraft, PostDraftFields>;

export type PostActionItem = Pick<PostAction, ActionFields> & {
  board?: Board;
  duplicatePost: WithAuthor<StrippedPost>;
  source: 'canny';
  sourcePost: WithAuthor<StrippedPost>;
  urlName: string;
};

type PostDraftCommon = {
  board?: Board;
  source: SyncIntegrationNames;
  sourcePostDraft: WithAuthor<StrippedPostDraft>;
  link: string | null;
};

type PostDraftMergeActionItem = Pick<PostDraftMergeAction, ActionFields> &
  PostDraftCommon & {
    createdPost: null;
    duplicatePost: WithAuthor<StrippedPost>;
  };

type PostDraftCreateActionItem = Pick<PostDraftCreateAction, ActionFields> &
  PostDraftCommon & {
    createdPost: WithAuthor<StrippedPost>;
    duplicatePost: null;
  };

type PostDraftDismissActionItem = Pick<PostDraftDismissAction, ActionFields> &
  PostDraftCommon & {
    createdPost: null; // actions are dismissed before any posts are created
    duplicatePost: null | WithAuthor<StrippedPost>;
  };

export type PostDraftActionItem =
  | PostDraftMergeActionItem
  | PostDraftCreateActionItem
  | PostDraftDismissActionItem;

export type ActionItem =
  | PostActionItem
  | PostDraftMergeActionItem
  | PostDraftCreateActionItem
  | PostDraftDismissActionItem;

import { Component } from 'react';

import { loadDiscordSettings } from 'common/actions/discordSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

class DiscordSettingsContainer extends Component {
  render() {
    const { children, discordSettings } = this.props;
    return cloneElementWithProps(children, {
      ...this.props,
      discordSettings,
    });
  }
}

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => {
        return dispatch(loadDiscordSettings());
      },
    },
  ],
  (state) => ({ discordSettings: state.discordSettings })
)(DiscordSettingsContainer);

import React, { Component } from 'react';

import AdminPostSidebar from './AdminPostSidebar';

import 'css/components/subdomain/admin/_AdminFeedbackPostDetails.scss';

export default class AdminFeedbackPostDetails extends Component {
  renderContents() {
    const { board, boards, customPostFields, post, roadmaps } = this.props;
    if (!post || post.loading || post.notFound || !board || !board._id) {
      return null;
    }

    return (
      <AdminPostSidebar
        board={board}
        boards={boards}
        customPostFields={customPostFields}
        key={post._id}
        post={post}
        roadmaps={roadmaps}
      />
    );
  }

  render() {
    return <div className="adminFeedbackPostDetails">{this.renderContents()}</div>;
  }
}

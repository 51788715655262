import { SUGGESTIONS_ERROR, SUGGESTIONS_LOADED } from '../actions/mentionSuggestions';

export default function mentionSuggestions(state = {}, action) {
  switch (action.type) {
    case SUGGESTIONS_LOADED: {
      return Object.assign({}, state, {
        [action.key]: {
          error: null,
          lastUpdated: action.timestamp,
          loading: false,
          items: action.suggestions,
        },
      });
    }

    case SUGGESTIONS_ERROR: {
      return Object.assign({}, state, {
        [action.key]: {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          items: null,
        },
      });
    }

    default:
      return state;
  }
}

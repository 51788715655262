import Data from '../Data';

// Action Types

export const REQUEST_VOTERS = 'canny/voters/request_voters';
export const VOTERS_LOADED = 'canny/voters/voters_loaded';
export const VOTERS_ERROR = 'canny/voters/voters_error';
export const INVALIDATE_VOTERS = 'canny/voters/invalidate_voters';
export const UPDATE_VOTE_WEIGHT = 'canny/voters/update_vote_weight';

// Actions

function requestVoters(post) {
  return {
    post,
    timestamp: Date.now(),
    type: REQUEST_VOTERS,
  };
}

function votersLoaded(post, voters, totalMRR) {
  return {
    post,
    timestamp: Date.now(),
    totalMRR,
    type: VOTERS_LOADED,
    voters,
  };
}

function voteWeightUpdated(post, voter, weight) {
  return {
    post,
    voter,
    timestamp: Date.now(),
    weight,
    type: UPDATE_VOTE_WEIGHT,
  };
}

function votersError(post, error) {
  return {
    error,
    post,
    timestamp: Date.now(),
    type: VOTERS_ERROR,
  };
}

function invalidate(post) {
  return {
    post,
    timestamp: Date.now(),
    type: INVALIDATE_VOTERS,
  };
}

// Action Creators

function fetchVoters(post, segmentURL) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        voters: {
          input: {
            postID: post._id,
            segmentURLName: segmentURL,
          },
          query: Data.queries.voters,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(votersError(post, error));
        } else {
          const { voters, totalMRR } = data.voters;
          return dispatch(votersLoaded(post, voters, totalMRR));
        }
      }
    );
  };
}

export function loadVoters(post) {
  return (dispatch, getState) => {
    if (shouldFetchVoters(getState(), post)) {
      dispatch(requestVoters(post));
      return dispatch(fetchVoters(post));
    }
  };
}

export function updateVoteWeight(post, voter, weight) {
  return (dispatch, getState) => {
    return dispatch(voteWeightUpdated(post, voter, weight));
  };
}

export function reloadVoters(post, segmentURL) {
  return (dispatch, getState) => {
    return dispatch(fetchVoters(post, segmentURL));
  };
}

export function invalidateVoters(post) {
  return (dispatch) => {
    return dispatch(invalidate(post));
  };
}

// Helper Functions

function shouldFetchVoters(state, post) {
  if (!post || !post._id) {
    return false;
  }

  const voters = state.voters;
  return !voters[post._id];
}

import React, { Component } from 'react';

import { compose } from 'redux';

import InformationBox from 'common/common/InformationBox';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminZapierSettings.scss';

class AdminZapierSettings extends Component {
  renderSetup = () => {
    const {
      company,
      activeIntegrations: { activeIntegrations, integrationCount, integrationLimit },
    } = this.props;

    // if plan does not support zapier, render upsell
    if (!company?.integrations?.zapier) {
      return <AdminFeatureUpsell feature="zapier" cta="Connect Canny with +1000 other apps" />;
    }

    // If Zapier not already installed, they are exceeding their integration limit, render the upsell
    if (!activeIntegrations.zapier && integrationLimit && integrationCount >= integrationLimit) {
      return (
        <>
          <InformationBox icon="!" className="upsellNote">
            You are at your integration limit, upgrade to install more integrations.
          </InformationBox>
          <AdminFeatureUpsell feature="zapier" />
        </>
      );
    }

    return (
      <a
        href="https://zapier.com/apps/canny/integrations"
        rel="noopener"
        target="_blank"
        className="buttonContainer">
        <Button value="Get Started in Zapier" />
      </a>
    );
  };

  render() {
    return (
      <div className="adminZapierSettings">
        <Helmet title="Zapier Integration | Canny" />
        <div className="settingsHeading">Zapier Integration</div>
        <div className="content">
          <div className="text">Canny integrates with 1,000+ other apps on&nbsp;Zapier.</div>
          {this.renderSetup()}
        </div>
      </div>
    );
  }
}

export default compose(
  withAccessControl(testEveryPermission(RoutePermissions.integrations.zapier), '/admin/settings'),
  withContexts({
    company: CompanyContext,
    activeIntegrations: ActiveIntegrationContext,
  })
)(AdminZapierSettings);

import {
  COMPANY_ERROR,
  COMPANY_LOADED,
  COMPANY_NOT_FOUND,
  REQUEST_COMPANY,
} from '../actions/company';

export default function company(state = null, action) {
  switch (action.type) {
    case REQUEST_COMPANY:
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
        notFound: false,
      });

    case COMPANY_LOADED:
      return Object.assign(
        {},
        state,
        {
          lastUpdated: action.timestamp,
          loading: false,
        },
        action.company
      );

    case COMPANY_NOT_FOUND:
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        loading: false,
        notFound: true,
      });

    case COMPANY_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });

    default:
      return state;
  }
}

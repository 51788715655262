import promisify from 'common/util/promisify';

import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { Automation } from 'common/api/endpoints/automation';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: automationsRequested,
  loadedAction: automationsLoaded,
  errorAction: automationsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<Automation>('automations');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchAutomations() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          automations: {
            query: Data.queries.automations,
          },
        },
        cookies
      );
      return dispatch(automationsLoaded(response.automations));
    } catch (e) {
      if (typeof e === 'string') {
        return dispatch(automationsError(e));
      }
      return dispatch(automationsError('server error'));
    }
  };
}

export function loadAutomations() {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetch(getState())) {
      dispatch(automationsRequested());
      return dispatch(fetchAutomations());
    }
  };
}

export function reloadAutomations() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchAutomations());
  };
}

// Helpers

function shouldFetch(state: State) {
  return !state.automations.data;
}

import React from 'react';

import { AlertCircle, ChevronDown, X } from 'lucide-react';

import SpinnerV2 from 'common/SpinnerV2';
import { type CommonSelectProps } from 'common/ui/common/select/SelectCommon';
import IconButtonV2 from 'common/ui/IconButtonV2';

import 'css/components/_RightIcons.scss';

type Props = Pick<CommonSelectProps, 'disabled' | 'loading' | 'error'> & {
  onClear: () => void;
  'aria-label': string;
  showClear?: boolean;
};

const RightIcons = ({
  onClear,
  error,
  loading,
  'aria-label': ariaLabel,
  showClear,
  disabled,
}: Props) => {
  const renderError = !disabled && error && (
    <AlertCircle aria-label="alert circle icon" className="errorIcon" />
  );
  const renderLoading = loading && (
    <span className="dropdownSpinner">
      <SpinnerV2 size="medium" />
    </span>
  );
  const renderClear = showClear && (
    <IconButtonV2
      aria-label={ariaLabel}
      icon={X}
      size="small"
      variant="plain"
      disabled={disabled}
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        onClear();
      }}
    />
  );
  return (
    <span className="rightIcons">
      {renderError || renderLoading || renderClear}
      {(renderError || renderLoading || renderClear) && <span className="optionDivider" />}
      <ChevronDown aria-label="toggle icon" className="toggleIcon" />
    </span>
  );
};
export default RightIcons;

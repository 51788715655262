import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ViewerContext } from 'common/containers/ViewerContainer';
import * as FileUploader from 'common/FileUploader';
import withContexts from 'common/util/withContexts';

import FileInput from './FileInput';

import 'css/components/inputs/_UploadImageButton.scss';

export const uploadFile = async (file, { onError, viewer, onImageUploaded }) => {
  if (!file) {
    return;
  }

  if (!viewer || viewer.loggedOut) {
    onError && onError('Please log in to upload images.');
    return;
  }

  try {
    const fileURL = await FileUploader.upload(file, viewer);
    onImageUploaded(fileURL, file.name);
  } catch (error) {
    onError?.(error.data.message);
  }
};

class UploadImageButton extends Component {
  static propTypes = {
    onError: PropTypes.func,
    onImageStart: PropTypes.func,
    onImageUploaded: PropTypes.func.isRequired,
    defaultStyle: PropTypes.bool,
  };

  onFile = async (file) => uploadFile(file, this.props);

  render() {
    return (
      <div className="uploadImageButton">
        <FileInput
          accept="image/*"
          onFile={this.onFile}
          onFileStart={this.props.onImageStart}
          defaultStyle={this.props.defaultStyle}
          value={this.props.children ?? <span className="icon icon-image" />}
        />
      </div>
    );
  }
}

export default withContexts({ viewer: ViewerContext }, { forwardRef: true })(UploadImageButton);

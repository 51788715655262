import React, { Component } from 'react';

import PropTypes from 'prop-types';
import deepCompare from 'react-fast-compare';

import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext, OpenModalContext } from 'common/containers/ModalContainer';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Modal from 'common/modals/Modal';
import SavedSegmentsModal from 'common/modals/SavedSegmentsModal/SavedSegmentsModal';
import AttributeEditor, { AttributeType, FieldType } from 'common/segments/AttributeEditor';
import Tappable from 'common/Tappable';
import { P } from 'common/ui/Text';
import buildAttribute from 'common/util/buildAttribute';
import withContexts from 'common/util/withContexts';

import SegmentInUse from './SegmentInUse';

import 'css/components/modals/_EditSegmentModal.scss';

export const SegmentType = PropTypes.shape({
  attributes: PropTypes.arrayOf(AttributeType),
  name: PropTypes.string,
});

class EditSegmentModal extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(
      PropTypes.shape({
        settings: PropTypes.shape({
          segmentURLName: PropTypes.string,
        }),
      })
    ).isRequired,
    closeModal: PropTypes.func.isRequired,
    company: PropTypes.shape({
      company: PropTypes.arrayOf(FieldType),
      user: PropTypes.arrayOf(FieldType),
    }),
    onClose: PropTypes.func.isRequired,
    onUpdateQuery: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    reloadSegments: PropTypes.func.isRequired,
    segment: SegmentType,
  };

  state = {
    attributes: (this.props.segment && this.props.segment.attributes) || [],
    leaving: false,
    name: (this.props.segment && this.props.segment.name) || '',
    saving: false,
  };

  componentWillUnmount = () => {
    if (!this.state.leaving) {
      this.props.onClose();
    }
  };

  onAddAttribute = (attribute) => {
    const { attributes } = this.state;
    this.setState({
      attributes: [...attributes, attribute],
    });
  };

  onBack = () => {
    const { boards } = this.props;
    this.setState({ leaving: true }, () => {
      this.props.openModal(SavedSegmentsModal, {
        boards,
        needsInvalidate: this.state.saving,
        onUpdateQuery: this.props.onUpdateQuery,
      });
    });
  };

  onChangeAttribute = (i, attribute) => {
    const { attributes } = this.state;
    const newAttributes = [...attributes];
    newAttributes[i] = attribute;
    this.setState({ attributes: newAttributes });
  };

  onChangeName = (event) => {
    const { value } = event.target;
    this.setState({ name: value });
  };

  onClose = () => {
    this.props.closeModal();
  };

  onRemoveAttribute = (index) => {
    const { attributes } = this.state;
    this.setState({ attributes: attributes.filter((a, i) => i !== index) });
  };

  onSave = () => {
    const { segment } = this.props;
    const { attributes, name } = this.state;

    this.setState({ saving: true });
    AJAX.post('/api/segments/upsert', {
      attributes,
      name,
      ...(segment && { segmentID: segment._id }),
    })
      .then(this.props.reloadSegments)
      .then(this.onBack);
  };

  validateSegment = () => {
    const { company } = this.props;
    const { attributes, name } = this.state;

    if (!name || attributes.length === 0) {
      return false;
    }

    if (this.props.segment) {
      const isTheSame = deepCompare(
        {
          attributes: this.props.segment.attributes,
          name: this.props.segment.name,
        },
        {
          attributes,
          name,
        }
      );

      if (isTheSame) {
        return false;
      }
    }

    const customFieldMap = [...company.customFields.company, ...company.customFields.user].reduce(
      (prev, curr) => ({
        ...prev,
        [curr._id]: curr,
      }),
      {}
    );

    try {
      attributes.map((attribute) => buildAttribute(attribute, customFieldMap));
    } catch (error) {
      return false;
    }

    return true;
  };

  renderForm() {
    const validSegment = this.validateSegment();
    return (
      <Form
        addEventsToDocument={true}
        className="segmentForm"
        disableSubmit={!validSegment || this.state.saving}
        onSubmit={this.onSave}>
        {this.renderAttributeEditors()}
        {this.renderFooter()}
      </Form>
    );
  }

  renderAttributeEditors() {
    const { company } = this.props;
    const { attributes } = this.state;
    const customFields = [...company.customFields.company, ...company.customFields.user];

    return (
      <div className="attributeEditors">
        {attributes.map((attribute, i) => {
          return (
            <div key={i}>
              <AttributeEditor
                attribute={attribute}
                customFields={customFields}
                onChange={this.onChangeAttribute.bind(this, i)}
                onRemove={this.onRemoveAttribute.bind(this, i)}
              />
              <div className="separator">
                <div className="separatorLeft" />
                <P className="operator">AND</P>
                <div className="separatorRight" />
              </div>
            </div>
          );
        })}
        <AttributeEditor customFields={customFields} onChange={this.onAddAttribute} />
      </div>
    );
  }

  renderFooter() {
    const { boards, segment } = this.props;
    const boardsUsingSegment = boards.filter(
      (board) => board.settings.segmentURLName === segment?.urlName
    );

    const segmentInuse = boardsUsingSegment.length > 0;

    const textInput = (
      <TextInput
        className="segmentName"
        disabled={segmentInuse}
        onChange={this.onChangeName}
        placeholder="Segment name"
        value={this.state.name}
      />
    );

    const validSegment = this.validateSegment();
    return (
      <div className="footer">
        <Tappable onTap={this.onBack}>
          <div className="cancelButton">Cancel</div>
        </Tappable>
        <div className="filler" />
        {segmentInuse ? (
          <Tooltip value={<SegmentInUse boardsUsingSegment={boardsUsingSegment} />}>
            {textInput}
          </Tooltip>
        ) : (
          textInput
        )}
        <Button
          buttonType="cannyButton"
          disabled={!validSegment}
          formButton={true}
          loading={this.state.saving}
          value="Save Segment"
        />
      </div>
    );
  }

  render() {
    const title = this.props.segment ? 'edit segment' : 'create segment';
    return (
      <Modal className="editSegmentModal" style={{ padding: 0 }}>
        <div className="header">
          <div className="title">{title}</div>
          <Tappable onTap={this.onClose}>
            <div className="exitButton icon icon-x" />
          </Tappable>
        </div>
        {this.renderForm()}
      </Modal>
    );
  }
}

export default withContexts(
  {
    closeModal: CloseModalContext,
    company: CompanyContext,
    openModal: OpenModalContext,
  },
  { forwardRef: true }
)(EditSegmentModal);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { GetExperimentGroupsContext } from 'common/containers/ExperimentContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import loadScript from 'common/util/loadScript';
import withContexts from 'common/util/withContexts';

import cloneElementWithProps from '../core/cloneElementWithProps';

export const TrackMixpanelEventContext = React.createContext();

const MixpanelID = __DEV__
  ? 'c2641a425e9220f785d0be3c55d8ec2c'
  : 'ac70ebddf8b9618e6a8365f61234d2a4';

function loadMixpanelSDK() {
  if (window.mixpanel) {
    return;
  }

  (function (f, b) {
    if (!b.__SV) {
      var i, h;
      window.mixpanel = b;
      b._i = [];
      b.init = function (e, f, c) {
        function g(a, d) {
          var b = d.split('.');
          b.length === 2 && ((a = a[b[0]]), (d = b[1]));
          a[d] = function () {
            a.push([d].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var a = b;
        typeof c !== 'undefined' ? (a = b[c] = []) : (c = 'mixpanel');
        a.people = a.people || [];
        a.toString = function (a) {
          var d = 'mixpanel';
          c !== 'mixpanel' && (d += '.' + c);
          a || (d += ' (stub)');
          return d;
        };
        a.people.toString = function () {
          return a.toString(1) + '.people (stub)';
        };
        i =
          'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
            ' '
          );
        for (h = 0; h < i.length; h++) {
          g(a, i[h]);
        }
        var j = 'set set_once union unset remove delete'.split(' ');
        a.get_group = function () {
          function b(c) {
            d[c] = function () {
              const call2_args = arguments;
              const call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
              a.push([e, call2]);
            };
          }
          for (
            var d = {}, e = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)), c = 0;
            c < j.length;
            c++
          ) {
            b(j[c]);
          }
          return d;
        };
        b._i.push([e, f, c]);
      };
      b.__SV = 1.2;
      loadScript('https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js', 'mixpanel-script');
    }
  })(document, window.mixpanel || []);
  window.mixpanel.init(MixpanelID, {
    debug: false,
  });
}

class MixpanelContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    property: PropTypes.string,
    trackPageViews: PropTypes.bool,
    viewer: PropTypes.object,
  };

  static defaultProps = {
    trackPageViews: false,
  };

  componentDidMount() {
    loadMixpanelSDK();

    this.identifyUser();
    this.trackPageViews();
  }

  componentWillUnmount() {
    if (!this._unlisten) {
      return;
    }

    this._unlisten();
  }

  identifyUser = () => {
    const { getExperimentGroups, viewer } = this.props;
    const experimentGroups = getExperimentGroups();

    if (!viewer?._id) {
      return;
    }

    window.mixpanel.identify(viewer._id);
    window.mixpanel.people.set({
      ...experimentGroups,
      ...(viewer.avatarURL && {
        $avatar: viewer.avatarURL,
      }),
      $email: viewer.email,
      $name: viewer.name,
    });

    if (viewer.companies.length === 0) {
      return;
    }

    window.mixpanel.set_group(
      'company_id',
      viewer.companies.map((company) => company._id)
    );

    viewer.companies.forEach((company) => {
      window.mixpanel.get_group('company_id', company._id).set({
        $name: company.name,
        subdomain: company.subdomain,
      });
    });
  };

  trackPageViews = () => {
    if (!this.props.trackPageViews) {
      return;
    }

    this._unlisten = browserHistory.listen(this.onRouteChange);
    this.onRouteChange();
  };

  onRouteChange = () => {
    loadMixpanelSDK();

    window.mixpanel.track('pageview', {
      host: window.location.host,
      href: window.location.href,
      pathname: window.location.pathname,
      property: this.props.property,
      search: window.location.search,
      title: document.title,
    });
  };

  onTrackEvent = (eventName, eventData) => {
    loadMixpanelSDK();

    const { getExperimentGroups } = this.props;
    const experimentGroups = getExperimentGroups();

    window.mixpanel.track(eventName, {
      property: this.props.property,
      ...eventData,
      ...experimentGroups,
    });
  };

  render() {
    const childProps = Object.assign({}, this.props);
    delete childProps.company;
    delete childProps.getExperimentGroups;
    delete childProps.trackPageViews;
    delete childProps.viewer;
    return (
      <TrackMixpanelEventContext.Provider value={this.onTrackEvent}>
        {cloneElementWithProps(this.props.children, {
          ...childProps,
        })}
      </TrackMixpanelEventContext.Provider>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  getExperimentGroups: GetExperimentGroupsContext,
  viewer: ViewerContext,
})(MixpanelContainer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { TintColorContext } from 'common/containers/TintColorContainer';
import withContexts from 'common/util/withContexts';

import NotificationComponents from './NotificationComponents';
import NotificationLink from './NotificationLink';
import { reloadNotifications } from '../actions/notifications';
import AJAX from '../AJAX';
import connect from '../core/connect';
import Timestamp from '../Timestamp';

import 'css/components/_Notification.scss';

class Notification extends Component {
  static propTypes = {
    notification: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      read: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
    }),
    tint: PropTypes.bool,
    tintColor: PropTypes.string,
  };

  onLinkClicked = () => {
    const { notification } = this.props;
    if (notification.read) {
      return;
    }

    AJAX.post(
      '/api/notifications/markRead',
      {
        notificationID: notification._id,
      },
      (response) => {
        this.props.reloadNotifications();
      }
    );
  };

  render() {
    var notification = this.props.notification;
    var Component = NotificationComponents[notification.type];

    if (!Component) {
      if (__DEV__) {
        console.log('Invalid notification: ' + JSON.stringify(notification));
      }
      return null;
    }

    var className = 'notification';
    if (!this.props.notification.read) {
      className += ' unread';
    }

    var style = Object.assign(
      {},
      {
        ...(!this.props.notification.read &&
          this.props.tint && { borderLeftColor: this.props.tintColor }),
      }
    );

    return (
      <NotificationLink notification={notification} onTap={this.onLinkClicked}>
        <div className={className} style={style}>
          <Component notification={notification} />
          <div className="timestamp">
            <Timestamp timestamp={notification.updated} />
          </div>
        </div>
      </NotificationLink>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadNotifications: () => {
      return dispatch(reloadNotifications());
    },
  })),
  withContexts(
    {
      tintColor: TintColorContext,
    },
    {
      forwardRef: true,
    }
  )
)(Notification);

import expandRangeToLine from './expandRangeToLine';
import updateRangeThenSelectRange from './updateRangeThenSelectRange';

// "foo\nfoo" becomes "- foo\n- foo"
// "- foo\n- foo" becomes "foo\nfoo"
export default function addOrRemoveLinePrefix(autoResizeTextarea, linePrefix) {
  const range = autoResizeTextarea.getSelectionRange();
  const value = autoResizeTextarea.getValue();

  expandRangeToLine(range, value);

  const selection = value.substring(range.start, range.end);
  const lines = selection.split('\n');

  const shouldRemove = lines.reduce((shouldRemove, line, i) => {
    if (!shouldRemove) {
      return false;
    }
    return line.startsWith(linePrefix.replace('i', i + 1));
  }, true);

  if (shouldRemove) {
    let charsRemoved = 0;
    updateRangeThenSelectRange(
      autoResizeTextarea,
      range,
      lines
        .map((line, i) => {
          const toRemove = line.match(linePrefix.replace('i', i + 1))[0];
          charsRemoved += toRemove.length;
          return line.substring(toRemove.length, line.length);
        })
        .join('\n'),
      {
        start: range.end - charsRemoved,
        end: range.end - charsRemoved,
      }
    );
  } else {
    let charsAdded = 0;
    updateRangeThenSelectRange(
      autoResizeTextarea,
      range,
      lines
        .map((line, i) => {
          const toAdd = linePrefix.replace('i', i + 1);
          charsAdded += toAdd.length;
          return toAdd + line;
        })
        .join('\n'),
      {
        start: range.end + charsAdded,
        end: range.end + charsAdded,
      }
    );
  }
}

export default class OAuthGSuiteRedirect {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab }) {
    this._onSuccess = onSuccess;
    this._onFailure = onFailure;
  }

  connect = (result) => {
    if (!result.code) {
      this._onFailure();
      return;
    }

    this._onSuccess(result.code);
  };
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withContexts from 'common/util/withContexts';

import SentrySDK from '../3rd/SentrySDK';
import AccountModal, { FormStates } from '../AccountModal';
import Tappable from '../Tappable';

import 'css/components/auth/_LoginToAccess.scss';

import CannyLogoIcon from 'img/canny-icon.svg';

class LoginToAccess extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    viewer: PropTypes.object,
  };

  onCannyLogIn = () => {
    this.props.openModal(AccountModal, {
      formState: FormStates.login,
    });
    return;
  };

  componentDidMount() {
    if (!this.props.viewer.loggedOut) {
      const message = 'LoginToAccess component should not be rendered if user is logged in.';
      SentrySDK.captureException(new Error(message));
    }
  }

  renderCannyLogInButton() {
    return (
      <Tappable onTap={this.onCannyLogIn}>
        <div className="loginButton">
          <div className="imageContainer">
            <LazyLoadedImage alt="canny logo" src={CannyLogoIcon} />
          </div>
          <div className="cta">Log in with Canny</div>
        </div>
      </Tappable>
    );
  }

  render() {
    return (
      <div className="loginToAccess">
        <div className="topContainer">
          <div className="primaryLogIn">
            <div className="prompt">Log in to access&nbsp;this&nbsp;page.</div>
            {this.renderCannyLogInButton()}
          </div>
        </div>
      </div>
    );
  }
}

export default withContexts({
  openModal: OpenModalContext,
  viewer: ViewerContext,
})(LoginToAccess);

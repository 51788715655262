// Check if null or undefined
// https://github.com/lodash/lodash/blob/ddfd9b11a0126db2302cb70ec9973b66baec0975/lodash.js#L11953-L11975

type Nil = null | undefined;

export default function isNil(value: unknown): value is Nil {
  // eslint-disable-next-line eqeqeq
  return value == null;
}

export function isNotNil<T>(value: T): value is NonNullable<T> {
  return !isNil(value);
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import translateString from 'common/i18n/translateString';
import Button from 'common/inputs/Button';
import Markdown from 'common/markdown/Markdown';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import nbspLastSpace from 'common/util/nbspLastSpace';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_BoardDetails.scss';

class BoardDetails extends Component {
  static propTypes = {
    board: PropTypes.shape({
      postCount: PropTypes.number,
      settings: PropTypes.shape({
        showCreateForm: PropTypes.bool,
      }),
      strings: PropTypes.shape({
        createHeading: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
    company: PropTypes.object,
    disabled: PropTypes.bool,
    getPostLink: PropTypes.func,
    location: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
  };

  onPostCreated = (post) => {
    const { board, company, getPostLink, reloadBoard, reloadCompany, router } = this.props;
    reloadBoard(board.urlName);

    router.push(getPostLink(post));

    if (company.viewerIsMember && company.postCount < 3) {
      reloadCompany();
    }
  };

  renderHeading() {
    const { board } = this.props;

    if (!board.settings.showCreateForm) {
      return;
    }

    return <div className="heading">{translateString(board.strings, 'createHeading')}</div>;
  }

  renderDescription() {
    const { board } = this.props;
    if (!board.strings.description) {
      return null;
    }

    return (
      <div className="description">
        <Markdown contents={nbspLastSpace(translateString(board.strings, 'description'))} />
      </div>
    );
  }

  render() {
    const { company, location, viewer } = this.props;
    const { authRedirectEnabled, authRedirectURL } = company;
    if (viewer.loggedOut && authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location);
      return (
        <div className="boardDetails logInPrompt">
          <div className="heading">Log in to your {company.name} account to give&nbsp;feedback</div>
          <a href={redirectURL} className="logInLink">
            <Button tint={true} value="Log In" />
          </a>
        </div>
      );
    }

    return null;
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return dispatch(reloadBoard(boardURLName));
    },
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      getPostLink: GetPostLinkContext,
      location: LocationContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(BoardDetails);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import LoadMore from 'common/common/LoadMore';
import Spinner from 'common/Spinner';

import Notification from './Notification';

import 'css/components/_NotificationList.scss';

export default class NotificationList extends Component {
  static propTypes = {
    notifications: PropTypes.shape({
      hasNextPage: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.object),
      loadingMore: PropTypes.bool,
    }),
    onLoadMoreTapped: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.loadMoreRef = React.createRef();
  }

  onScroll = (e, scrollContainer) => {
    const loadMore = this.loadMoreRef.current;
    if (!loadMore) {
      return;
    }

    loadMore.onScroll(e, scrollContainer);
  };

  renderLoadMore() {
    const { hasNextPage: hasMore, loadingMore } = this.props.notifications;
    return (
      <div className="loadMoreContainer">
        <LoadMore
          hasMore={hasMore}
          loadingMore={loadingMore}
          onLoadMore={this.props.onLoadMoreTapped}
          ref={this.loadMoreRef}
        />
      </div>
    );
  }

  renderList() {
    const { items } = this.props.notifications;
    if (items.length === 0) {
      return (
        <div className="nullState card">
          <span className="noNotificationsMessage">You have no notifications.</span>
        </div>
      );
    }

    const notifications = [];
    items.forEach((notification) => {
      notifications.push(
        <Notification key={notification._id} notification={notification} tint={true} />
      );
    });

    return (
      <div className="notificationListBody card">
        <div className="notifications">{notifications}</div>
        {this.renderLoadMore()}
      </div>
    );
  }

  renderContents() {
    const { notifications } = this.props;
    if (!notifications || notifications.loading || notifications.reloading) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    }

    return this.renderList();
  }

  render() {
    return <div className="notificationList">{this.renderContents()}</div>;
  }
}

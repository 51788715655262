import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import Link from 'common/Link';
import { P } from 'common/ui/Text';
import areArraysSimilar from 'common/util/areArraysSimilar';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';

import AdminSalesforceSyncContacts from './AdminSalesforceSyncContacts';
import AdminSalesforceSyncRecord from './AdminSalesforceSyncRecord';

import 'css/components/subdomain/admin/AdminSalesforceSettings/_AdminSalesforceSync.scss';

const DefaultSalesforceSync = {
  syncAccounts: false,
  syncAccountFields: [],
  syncContacts: false,
  syncContactDisallowNameOverride: false,
  syncContactFields: [],
  syncContactOnlyExisting: false,
};

const RecordTypes = {
  Account: 'Account',
  Contact: 'Contact',
};

const LoadingErrorMessage =
  'Something went wrong loading fields from Salesforce. Please refresh the page to try again.';

class AdminSalesforceSync extends Component {
  static propTypes = {
    company: PropTypes.shape({
      salesforceSync: PropTypes.shape({
        syncAccounts: PropTypes.bool,
        syncAccountFields: PropTypes.arrayOf(PropTypes.string),
        syncContacts: PropTypes.bool,
        syncContactDisallowNameOverride: PropTypes.bool,
        syncContactFields: PropTypes.arrayOf(PropTypes.string),
        syncContactOnlyExisting: PropTypes.bool,
      }),
    }),
  };

  state = {
    accountFields: {
      fields: [],
      loading: false,
    },
    contactFields: {
      fields: [],
      loading: false,
    },
    error: '',
    errorLoading: null,
    saving: false,
    canSave: false,
    salesforceSync: this.props.company.salesforceSync || DefaultSalesforceSync,
  };

  componentDidMount() {
    if (this.state.salesforceSync.syncAccounts) {
      this.fetchAccounts();
    }
    if (this.state.salesforceSync.syncContacts) {
      this.fetchContacts();
    }
  }

  fetchAccounts = async () => {
    this.setState({
      accountFields: { loading: true, fields: [] },
    });

    const response = await AJAX.post('/api/salesforce/fields/get', {
      recordType: RecordTypes.Account,
    });

    try {
      const { fields } = JSON.parse(response);
      this.setState({
        accountFields: {
          loading: false,
          fields: fields.sort(stringSort('label')),
        },
      });
    } catch (e) {
      this.setState({ errorLoading: LoadingErrorMessage });
    }
  };

  fetchContacts = async () => {
    this.setState({
      contactFields: { loading: true, fields: [] },
    });

    const response = await AJAX.post('/api/salesforce/fields/get', {
      recordType: RecordTypes.Contact,
    });

    try {
      const { fields } = JSON.parse(response);
      this.setState({
        contactFields: {
          loading: false,
          fields: fields.sort(stringSort('label')),
        },
      });
    } catch (e) {
      this.setState({ errorLoading: LoadingErrorMessage });
    }
  };

  onSyncChange = async (update) => {
    const currentsalesforceSync = this.state.salesforceSync;

    // Delete any undefined keys in 'update'
    Object.keys(update).forEach((key) => (update[key] === undefined ? delete update[key] : {}));

    this.setState(
      {
        salesforceSync: {
          ...this.state.salesforceSync,
          ...update,
        },
      },
      () => {
        this.setState({ canSave: this.canSave() });
      }
    );

    // Don't await these methods, since they aren't dependant on each other
    if (!currentsalesforceSync.syncAccounts && update.syncAccounts) {
      this.fetchAccounts();
    }
    if (!currentsalesforceSync.syncContacts && update.syncContacts) {
      this.fetchContacts();
    }
  };

  onSave = async () => {
    const {
      syncAccounts,
      syncAccountFields,
      syncContacts,
      syncContactDisallowNameOverride,
      syncContactFields,
      syncContactOnlyExisting,
    } = this.state.salesforceSync;

    this.setState({
      error: '',
      saving: true,
    });

    const response = await AJAX.post('/api/salesforce/sync/update', {
      syncAccounts,
      syncAccountFields,
      syncContacts,
      syncContactDisallowNameOverride,
      syncContactFields,
      syncContactOnlyExisting,
    });

    if (response !== 'success') {
      this.setState({
        saving: false,
        error: 'Something went wrong.',
      });
      return;
    }

    await this.props.reloadCompany();
    this.setState({ saving: false });
  };

  canSave() {
    const savedSync = this.props.company.salesforceSync || DefaultSalesforceSync;
    const newSync = this.state.salesforceSync;

    const fieldsDiffer = (fieldName) => savedSync[fieldName] !== newSync[fieldName];
    if (
      fieldsDiffer('syncAccounts') ||
      fieldsDiffer('syncContacts') ||
      fieldsDiffer('syncContactDisallowNameOverride') ||
      fieldsDiffer('syncContactOnlyExisting')
    ) {
      return true;
    }

    if (
      !areArraysSimilar(savedSync.syncAccountFields, newSync.syncAccountFields) ||
      !areArraysSimilar(savedSync.syncContactFields, newSync.syncContactFields)
    ) {
      return true;
    }

    return false;
  }

  renderSaveButton() {
    return (
      <div className="buttonContainer">
        <Button
          buttonType="cannyButton"
          className="saveButton"
          disabled={!this.state.canSave}
          loading={this.state.saving}
          onTap={this.onSave}
          tint={true}
          value="Save"
        />
      </div>
    );
  }

  renderError(error) {
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { accountFields, contactFields, error, errorLoading, salesforceSync } = this.state;
    return (
      <div className="adminSalesforceSync">
        <div className="text">
          <P>Select which records and fields from Salesforce you want to sync to Canny.</P>
          <P>
            Synced fields can be mapped to{' '}
            <Link className="cannyLink" to="/admin/settings/fields/company-fields">
              Canny company fields
            </Link>{' '}
            to be used in powerful&nbsp;analytics.
          </P>
        </div>
        {!errorLoading ? (
          <>
            <AdminSalesforceSyncRecord
              fields={contactFields.fields}
              fetching={contactFields.loading}
              onChange={({ sync, syncFields }) =>
                this.onSyncChange({
                  syncContacts: sync,
                  syncContactFields: syncFields,
                })
              }
              recordType="Contact"
              selectedFields={salesforceSync.syncContactFields}
              sync={salesforceSync.syncContacts}
            />
            {salesforceSync.syncContacts && !contactFields.loading ? (
              <AdminSalesforceSyncContacts
                syncContactSettings={{
                  syncContactDisallowNameOverride: salesforceSync.syncContactDisallowNameOverride,
                  syncContactOnlyExisting: salesforceSync.syncContactOnlyExisting,
                }}
                onChange={({ syncContactDisallowNameOverride, syncContactOnlyExisting }) =>
                  this.onSyncChange({
                    syncContactDisallowNameOverride,
                    syncContactOnlyExisting,
                  })
                }
              />
            ) : null}
            <AdminSalesforceSyncRecord
              fields={accountFields.fields}
              fetching={accountFields.loading}
              onChange={({ sync, syncFields }) =>
                this.onSyncChange({
                  syncAccounts: sync,
                  syncAccountFields: syncFields,
                })
              }
              recordType="Account"
              selectedFields={salesforceSync.syncAccountFields}
              sync={salesforceSync.syncAccounts}
            />
            {this.renderSaveButton()}
            {this.renderError(error)}
          </>
        ) : (
          this.renderError(errorLoading)
        )}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminSalesforceSync);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Toggle from 'common/common/Toggle';
import ControlledDropdown from 'common/ControlledDropdown';
import Form from 'common/Form';
import FormField from 'common/FormField';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import TextInput from 'common/inputs/TextInput';
import ModalPortal from 'common/modals/ModalPortal';

import Button from '../inputs/Button';
import UppercaseHeader from '../UppercaseHeader';

import 'css/components/growth/_ChurnModal.scss';

export const ChurnReasons = [
  {
    label: 'Not using',
    name: 'notUsing',
  },
  {
    label: 'Product issues',
    name: 'productIssues',
    requireNotes: true,
  },
  {
    label: 'Pricing',
    name: 'pricing',
    subReasons: [
      {
        label: 'Too expensive',
        name: 'expensive',
      },
      {
        label: 'Missing features',
        name: 'features',
      },
      {
        label: 'Other',
        name: 'other',
        requireNotes: true,
      },
    ],
  },
  {
    label: 'Switching to competitor',
    name: 'lostToCompetitor',
    subReasons: [
      {
        label: 'Aha!',
        name: 'aha',
      },
      {
        label: 'Featurebase',
        name: 'featurebase',
      },
      {
        label: 'Frill',
        name: 'frill',
      },
      {
        label: 'Pendo',
        name: 'pendo',
      },
      {
        label: 'Productboard',
        name: 'productBoard',
      },
      {
        label: 'Upvoty',
        name: 'upvoty',
      },
      {
        label: 'UserVoice',
        name: 'userVoice',
      },
      {
        label: 'Other',
        name: 'other',
        requireNotes: true,
      },
    ],
  },
  {
    label: 'Company issue',
    name: 'companyIssue',
    subReasons: [
      {
        label: 'Shutting down',
        name: 'shuttingDown',
      },
      {
        label: 'Cost cutting',
        name: 'costCutting',
      },
      {
        label: 'Funding',
        name: 'funding',
      },
    ],
  },
  {
    label: 'Bringing in house',
    name: 'bringingInHouse',
    subReasons: [
      {
        label: 'Internal tool',
        name: 'internal',
      },
      {
        label: 'AirTable/Excel',
        name: 'excel',
      },
      {
        label: 'Notion',
        name: 'notion',
      },
      {
        label: 'Other',
        name: 'other',
        requireNotes: true,
      },
    ],
  },
  {
    label: 'Refuse to give reason',
    name: 'noReason',
    requireNotes: true,
  },
  {
    label: 'Other',
    name: 'other',
    requireNotes: true,
  },
];

export default class ChurnModal extends Component {
  static propTypes = {
    canEndNow: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    endNow: false,
    error: null,
    intercomLink: '',
    notes: '',
    reason: null,
    submitting: false,
    subreason: null,
  };

  constructor(props, context) {
    super(props, context);

    this.intercomLinkRef = React.createRef();
    this.notesRef = React.createRef();
  }

  canSubmit = () => {
    const { intercomLink, reason, subReason, notes } = this.state;

    if (!intercomLink || !reason) {
      return false;
    }

    const churnReason = ChurnReasons.find(({ name }) => name === reason);
    if (churnReason.requireNotes && !notes) {
      return false;
    }

    const needsSubReason = churnReason.subReasons?.length > 0;
    if (!needsSubReason) {
      return true;
    }

    if (!subReason) {
      return false;
    }

    const churnSubReason = churnReason.subReasons.find(({ name }) => name === subReason);
    if (churnSubReason.requireNotes && !notes) {
      return false;
    }

    return true;
  };

  onEndNowToggle = (endNow) => {
    this.setState({ endNow });
  };

  onChangeReason = (reason) => {
    this.setState({ reason });
  };

  onChangeSubReason = (subReason) => {
    this.setState({ subReason });
  };

  onLinkChange = (event) => {
    const intercomLink = event.target.value;
    this.setState({ intercomLink });
  };

  onNotesChange = (event) => {
    const notes = event.target.value;
    this.setState({ notes });
  };

  onSubmit = async () => {
    this.setState({
      error: null,
      submitting: true,
    });

    const { onClose, onSubmit } = this.props;
    const { endNow, intercomLink, notes, reason, subReason } = this.state;

    const error = await onSubmit({
      endNow,
      intercomLink,
      notes,
      reason,
      subReason,
    });

    if (error) {
      this.setState({
        error,
        submitting: false,
      });
      return;
    }

    this.setState({ submitting: false });
    onClose();
  };

  renderEndNowToggle = () => {
    const { canEndNow } = this.props;
    if (!canEndNow) {
      return;
    }

    return (
      <div className="toggleContainer">
        <Toggle onToggle={this.onEndNowToggle} value={this.state.endNow} />
        <UppercaseHeader className="label">End Subscription Immediately</UppercaseHeader>
      </div>
    );
  };

  renderReason = () => {
    const options = ChurnReasons.map(({ label, name }) => ({
      name,
      render: label,
    }));
    const { reason } = this.state;

    return (
      <FormField label="Churn Reason">
        <ControlledDropdown
          minWidth={165}
          onChange={this.onChangeReason}
          options={options}
          placeholder="Select One"
          selectedName={reason}
        />
      </FormField>
    );
  };

  renderSubReason = () => {
    const { reason, subReason } = this.state;
    const churnReason = ChurnReasons.find(({ name }) => name === reason);
    if (!churnReason?.subReasons?.length) {
      return;
    }

    const options = churnReason.subReasons.map(({ label, name }) => ({
      name,
      render: label,
    }));
    return (
      <FormField label="Churn Sub-Reason">
        <ControlledDropdown
          minWidth={165}
          onChange={this.onChangeSubReason}
          options={options}
          placeholder="Select One"
          selectedName={subReason}
        />
      </FormField>
    );
  };

  renderNotes = () => {
    return (
      <FormField label="Notes">
        <AutoResizeTextarea
          ref={this.detailsRef}
          defaultValue={''}
          maxRows={10}
          minRows={3}
          onChange={this.onNotesChange}
        />
      </FormField>
    );
  };

  renderButtons = () => {
    const { submitting } = this.state;
    const formComplete = this.canSubmit();
    return (
      <div className="buttons">
        <Button buttonType="ghostButton" onTap={this.props.onClose} value="Cancel" />
        <Button disabled={!formComplete} formButton={true} loading={submitting} value="Submit" />
      </div>
    );
  };

  renderError = () => {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  };

  renderContent = () => {
    return (
      <Form
        className="churnModal"
        addEventsToDocument={true}
        disableSubmit={false}
        onSubmit={this.onSubmit}>
        <FormField label="Intercom Link">
          <TextInput autoFocus ref={this.intercomLinkRef} onChange={this.onLinkChange} />
        </FormField>
        {this.renderReason()}
        {this.renderSubReason()}
        {this.renderNotes()}
        {this.renderEndNowToggle()}
        {this.renderButtons()}
        {this.renderError()}
      </Form>
    );
  };

  render() {
    return (
      <ModalPortal
        className="churnModalPortal"
        closeOnClickAway={true}
        onClose={this.props.onClose}>
        {this.renderContent()}
      </ModalPortal>
    );
  }
}

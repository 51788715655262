import React, { Component } from 'react';

import PropTypes from 'prop-types';
import deepCompare from 'react-fast-compare';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import Link from 'common/Link';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';

import AdminHubspotSyncRecord from './AdminHubspotSyncRecord';

import 'css/components/subdomain/admin/AdminHubspotSettings/_AdminHubspotSync.scss';

const DefaultHubspotSync = {
  syncCompanies: false,
  syncCompanyFields: [],
  syncContacts: false,
  syncContactFields: [],
};

class AdminHubspotSync extends Component {
  static propTypes = {
    company: PropTypes.shape({
      hubspotSync: PropTypes.shape({
        syncCompanies: PropTypes.bool,
        syncCompanyFields: PropTypes.arrayOf(PropTypes.string),
        syncContacts: PropTypes.bool,
        syncContactFields: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  };

  state = {
    companyFields: {
      fields: [],
      loading: false,
    },
    contactFields: {
      fields: [],
      loading: false,
    },
    canSave: false,
    error: null,
    hubspotSync: this.props.company.hubspotSync || DefaultHubspotSync,
    saving: false,
  };

  componentDidMount() {
    const { syncCompanies, syncContacts } = this.state.hubspotSync;
    if (syncCompanies) {
      this.fetchCompanies();
    }
    if (syncContacts) {
      this.fetchContacts();
    }
  }

  fetchCompanies = () => {
    return this.fetchFields('company', 'companyFields');
  };

  fetchContacts = () => {
    return this.fetchFields('contact', 'contactFields');
  };

  fetchFields = async (objectType, stateObjectKey) => {
    const { [stateObjectKey]: fields } = this.state;
    this.setState({ [stateObjectKey]: { ...fields, loading: true } });
    const response = await AJAX.post('/api/hubspot/fields/get', {
      objectType,
    });

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => Array.isArray(parsedResponse.fields),
    });

    if (error) {
      this.setState({
        error: error.message,
        [stateObjectKey]: { ...fields, loading: false },
      });
      return;
    }

    this.setState({
      [stateObjectKey]: {
        loading: false,
        fields: parsedResponse.fields.sort(stringSort('label')),
      },
    });
  };

  onSyncChange = (update) => {
    const currentHubspotSync = this.state.hubspotSync;

    // Delete any undefined keys in 'update'
    Object.keys(update).forEach((key) => (update[key] === undefined ? delete update[key] : {}));

    this.setState(
      {
        hubspotSync: {
          ...this.state.hubspotSync,
          ...update,
        },
      },
      () => {
        this.setState({ canSave: this.canSave() });
      }
    );

    // Don't await these methods, since they aren't dependant on each other
    if (!currentHubspotSync.syncCompanies && update.syncCompanies) {
      this.fetchCompanies();
    }
    if (!currentHubspotSync.syncContacts && update.syncContacts) {
      this.fetchContacts();
    }
  };

  onSave = async () => {
    const { syncCompanies, syncCompanyFields, syncContacts, syncContactFields } =
      this.state.hubspotSync;

    this.setState({
      error: '',
      saving: true,
    });

    const response = await AJAX.post('/api/hubspot/sync/update', {
      syncCompanies,
      syncCompanyFields,
      syncContacts,
      syncContactFields,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({ error: error.message, saving: false });
      return;
    }

    await this.props.reloadCompany();
    this.setState({ saving: false });
  };

  canSave() {
    const savedSync = this.props.company.hubspotSync || DefaultHubspotSync;
    const newSync = this.state.hubspotSync;
    const isSyncUpdated = !deepCompare(savedSync, newSync);
    return isSyncUpdated;
  }

  renderSaveButton() {
    return (
      <div className="buttonContainer">
        <Button
          buttonType="cannyButton"
          className="saveButton"
          disabled={!this.state.canSave}
          loading={this.state.saving}
          onTap={this.onSave}
          tint={true}
          value="Save"
        />
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { companyFields, contactFields, hubspotSync } = this.state;
    return (
      <div className="adminHubspotSync">
        <div className="text">
          <P>Select which records and fields from HubSpot you want to sync to Canny.</P>
          <P>
            Synced fields can be mapped to{' '}
            <Link className="cannyLink" to="/admin/settings/fields/company-fields">
              Canny company fields
            </Link>{' '}
            to be used in powerful&nbsp;analytics.
          </P>
        </div>
        <AdminHubspotSyncRecord
          fields={contactFields.fields}
          fetching={contactFields.loading}
          onChange={({ sync, syncFields }) =>
            this.onSyncChange({
              syncContactFields: syncFields,
              syncContacts: sync,
            })
          }
          objectType="contact"
          selectedFields={hubspotSync.syncContactFields}
          sync={hubspotSync.syncContacts}
        />
        <AdminHubspotSyncRecord
          fields={companyFields.fields}
          fetching={companyFields.loading}
          onChange={({ sync, syncFields }) =>
            this.onSyncChange({
              syncCompanies: sync,
              syncCompanyFields: syncFields,
            })
          }
          objectType="company"
          selectedFields={hubspotSync.syncCompanyFields}
          sync={hubspotSync.syncCompanies}
        />
        {this.renderSaveButton()}
        {this.renderError()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withContexts({ company: CompanyContext }, { forwardRef: true })
)(AdminHubspotSync);

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Calendar, CaseSensitive, CheckCircle, Hash, type LucideIcon } from 'lucide-react';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { RevenueTimeframes } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import DataTable, { type Column } from 'common/DataTable';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import SpinnerV2 from 'common/SpinnerV2';
import {
  HubspotAutomaticFields,
  type IntegrationName,
  IntegrationNameMap,
  SalesforceAutomaticFields,
  ThirdPartyCompanyDefaultField,
} from 'common/thirdPartyCompanies/constants';
import IconBadge from 'common/ui/IconBadge';
import SingleSelect from 'common/ui/SingleSelect';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import { P, Span } from 'common/ui/Text';
import getPrettyName from 'common/util/getPrettyName';
import nbspLastSpace from 'common/util/nbspLastSpace';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import useDelayer from 'common/util/useDelayer';
import styles from 'css-module/components/subdomain/admin/_AdminCompanyFieldsSettings.module.scss';

import ActionsCell from './ActionsCell';
import { type Field, type FieldMapping, fieldIsIntegration } from '../utils';

import HubspotLogo from 'img/hubspot-logo-small.png';
import SalesforceLogo from 'img/salesforce-logo-small.png';

import type { DefaultField, Row } from './types';
import type { Company } from 'common/api/endpoints/companies';
import type { Dispatch } from 'redux-connect';

type Props = {
  reloadCompany: () => Promise<void>;
};

enum Modal {
  acceptAutomaticOwnerFields = 'acceptAutomaticOwnerFields',
  acceptUnlinkAutomaticField = 'acceptUnlinkAutomaticField',
}

const IconByType: Record<DefaultField['type'], LucideIcon> = {
  date: Calendar,
  number: Hash,
  string: CaseSensitive,
  boolean: CheckCircle,
};

const IntegrationIcons: Record<string, string> = {
  hubspot: HubspotLogo,
  salesforce: SalesforceLogo,
};

const fieldToOption = (field: Field) => {
  const name = field.name;
  const prettyName = getPrettyName(name);

  return {
    label: `${prettyName} (${field.name})`,
    value: field.name,
    avatar: field.integrations?.length ? IntegrationIcons[field.integrations[0]] : null,
    render: (
      <div className={styles.syncSelectOption} key={field.name}>
        <P>{prettyName}</P>
        <P className={styles.syncSelectOptionName}>({field.name})</P>
      </div>
    ),
  };
};

const FieldMappingTable = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState<Field[]>([]);
  const [modal, setModal] = useState<Modal | null>(null);
  const [pendingFieldMapping, setPendingFieldMapping] = useState<FieldMapping>(
    company.thirdPartyCompanyFieldMapping ?? {}
  );
  const [fieldMapping, setFieldMapping] = useState<FieldMapping>(
    company.thirdPartyCompanyFieldMapping ?? {}
  );

  // disable the account owner fields if the account owner id field is linked to a salesforce or hubspot field
  let disabledRowIDs: string[] = [];
  if (
    fieldIsIntegration(
      [IntegrationNameMap.salesforce, IntegrationNameMap.hubspot],
      fieldMapping[ThirdPartyCompanyDefaultField.accountOwnerID],
      fields
    )
  ) {
    disabledRowIDs = [
      ThirdPartyCompanyDefaultField.accountOwnerEmail,
      ThirdPartyCompanyDefaultField.accountOwner,
    ];
  }

  useEffect(() => {
    const fetchFields = async () => {
      setLoading(true);

      const response = await AJAX.post('/api/thirdPartyCompanies/getMappableFields');
      const { error, parsedResponse } = parseAPIResponse<{ fields: Field[] }>(response, {
        isSuccessful: (parsedObject) => Array.isArray(parsedObject?.fields),
      });

      setLoading(false);

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      if (!parsedResponse?.fields) {
        return;
      }

      setFields(parsedResponse.fields);
    };
    fetchFields();
  }, [showToast]);

  const DefaultFields: DefaultField[] = [
    {
      name: ThirdPartyCompanyDefaultField.monthlySpend,
      label: company.revenueTimeframe.toUpperCase(),
      type: 'number',
      description: `Customer's current ${
        company.revenueTimeframe === RevenueTimeframes.arr ? 'annual' : 'monthly'
      } spend`,
    },
    {
      name: ThirdPartyCompanyDefaultField.renewalDate,
      label: 'Renewal Date',
      type: 'date',
      description: `Date of the customer's next renewal`,
    },
    {
      name: ThirdPartyCompanyDefaultField.renewalRisk,
      label: 'Renewal Risk',
      type: 'string',
      description: `Likelihood of the customer renewing`,
    },
    {
      name: ThirdPartyCompanyDefaultField.accountOwnerID,
      label: 'Account Owner ID',
      type: 'string',
      description: 'Unique identifier for the account owner',
    },
    {
      name: ThirdPartyCompanyDefaultField.accountOwner,
      label: 'Account Owner Name',
      type: 'string',
      description: nbspLastSpace(`Name of the person responsible for the account`),
    },
    {
      name: ThirdPartyCompanyDefaultField.accountOwnerEmail,
      label: 'Account Owner Email',
      type: 'string',
      description: nbspLastSpace(`Email of the person responsible for the account`),
    },
    {
      name: ThirdPartyCompanyDefaultField.customerStatus,
      label: 'Customer Status',
      type: 'string',
      description: `State of the customer's account`,
    },
  ];

  const onFieldMappingChange = useCallback(
    async (updatedFieldMapping: FieldMapping) => {
      setFieldMapping(updatedFieldMapping);

      const response = await AJAX.post('/api/company/changeSettings', {
        thirdPartyCompanyFieldMapping: updatedFieldMapping,
      });

      const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
      if (error) {
        setFieldMapping(company.thirdPartyCompanyFieldMapping);
        showToast(error.message, ToastTypes.error);
      }

      reloadCompany();
    },
    [reloadCompany, company.thirdPartyCompanyFieldMapping, showToast]
  );

  const fieldMappingDelayer = useDelayer(onFieldMappingChange, 500);

  const rows = DefaultFields.map((defaultField) => ({
    id: defaultField.name,
    className: disabledRowIDs.includes(defaultField.name) ? styles.disabledRow : '',
    defaultField,
    syncField: fieldMapping?.[defaultField.name],
  }));

  const columns = [
    {
      id: 'defaultField',
      align: 'left',
      header: 'Canny Field',
      cell: (defaultField) => (
        <div className={styles.defaultFieldColumn} key={defaultField.name}>
          <div className={styles.title}>
            <P>{defaultField.label}</P>
            <IconBadge size="small" icon={IconByType[defaultField.type]} />
          </div>
          <P className={styles.description} variant="bodySm">
            {defaultField.description}
          </P>
        </div>
      ),
      sortable: false,
    } as Column<Row, 'defaultField'>,
    {
      id: 'syncField',
      align: 'left',
      header: <div className={styles.syncFieldHeader}>Sync Field</div>,
      cell: (syncField, row) => {
        // remove duplicated fields
        const options = fields
          .filter((field) => field.type === row.defaultField.type)
          .map(fieldToOption);

        const selectedFieldName = fieldMapping[row.defaultField.name];
        let selectedField = options.find((option) => option.value === selectedFieldName);

        // if a field was deleted from the options, we still have to render it
        // to let admins know they are linking to a custom field and not using the default
        if (
          selectedFieldName &&
          !selectedField &&
          !(selectedFieldName in ThirdPartyCompanyDefaultField)
        ) {
          let integrations: IntegrationName[] = [];
          if (selectedFieldName in SalesforceAutomaticFields) {
            integrations = [IntegrationNameMap.salesforce];
          } else if (selectedFieldName in HubspotAutomaticFields) {
            integrations = [IntegrationNameMap.hubspot];
          }

          const field = {
            ...fieldToOption({
              name: selectedFieldName,
              type: 'string', // arbitrary value
              integrations,
            }),
            disabled: true,
          };
          selectedField = field;
          options.push(field);
        }

        if (loading) {
          return <SpinnerV2 className={styles.tableSpinner} size="small" />;
        }

        if (!options.length) {
          return <P className={styles.noFields}>No compatible fields&nbsp;available.</P>;
        }

        const Select = options.length < 10 ? SingleSelect : SingleSelectWithSearch;
        return (
          <Select
            key={syncField}
            disabled={disabledRowIDs.includes(row.id)}
            value={selectedField}
            optionsMaxWidth="400px"
            withBorder={false}
            className={styles.syncFieldSelect}
            options={options}
            onChange={(selectedOption) => {
              const updatedFieldMapping = {
                ...fieldMapping,
                [row.defaultField.name]: selectedOption?.value ?? null,
              };

              // automatically link the account owner fields if the user selects an integration field for the account owner id field
              // except if the account owner fields are already linked to the same integration
              const shouldProposeLinking = [
                IntegrationNameMap.salesforce,
                IntegrationNameMap.hubspot,
              ].some((integration) => {
                const ownerFieldsWereLinked =
                  fieldIsIntegration(
                    [integration],
                    fieldMapping[ThirdPartyCompanyDefaultField.accountOwnerID],
                    fields
                  ) && disabledRowIDs.length !== 0;

                const ownerFieldsAreLinked = fieldIsIntegration(
                  [integration],
                  selectedOption?.value || null,
                  fields
                );

                return !ownerFieldsWereLinked && ownerFieldsAreLinked;
              });

              const isOwnerIDField =
                row.defaultField.name === ThirdPartyCompanyDefaultField.accountOwnerID;

              if (isOwnerIDField && shouldProposeLinking) {
                setPendingFieldMapping(updatedFieldMapping);
                setModal(Modal.acceptAutomaticOwnerFields);
              } else {
                setFieldMapping(updatedFieldMapping);
                fieldMappingDelayer(updatedFieldMapping);
              }
            }}
            placeholder="Select a field"
          />
        );
      },
      sortable: false,
      fullWidth: true,
    } as Column<Row, 'syncField'>,
    {
      id: 'id',
      align: 'right',
      header: '',
      cell: (_, row) => (
        <ActionsCell
          fieldMapping={fieldMapping}
          row={row}
          disabled={disabledRowIDs.includes(row.id)}
          fields={fields}
          onUpdateFieldMapping={(updatedFieldMapping) => {
            setFieldMapping(updatedFieldMapping);
            fieldMappingDelayer(updatedFieldMapping);
          }}
        />
      ),
      sortable: false,
      fullWidth: true,
    } as Column<Row, 'id'>,
  ];

  return (
    <>
      <DataTable className={styles.fieldsTable} hoverable={false} columns={columns} rows={rows} />
      {modal === Modal.acceptAutomaticOwnerFields && (
        <ModernConfirmModal
          type="primary"
          header="Do you want to link the Account Owner fields?"
          confirmText="Link"
          onClose={() => {
            setModal(null);
            setPendingFieldMapping(fieldMapping);
          }}
          onConfirm={() => {
            const ownerIDFieldName =
              pendingFieldMapping[ThirdPartyCompanyDefaultField.accountOwnerID];

            // if the owner id field is a salesforce field, link the account owner and account owner email fields
            // if the owner id field is a hubspot field, link the account owner and account owner email fields
            let updatedMapping = pendingFieldMapping;
            if (fieldIsIntegration([IntegrationNameMap.salesforce], ownerIDFieldName, fields)) {
              updatedMapping = {
                ...pendingFieldMapping,
                [ThirdPartyCompanyDefaultField.accountOwner]:
                  SalesforceAutomaticFields.salesforce_owner_name.name,
                [ThirdPartyCompanyDefaultField.accountOwnerEmail]:
                  SalesforceAutomaticFields.salesforce_owner_email.name,
              };
            } else if (fieldIsIntegration([IntegrationNameMap.hubspot], ownerIDFieldName, fields)) {
              updatedMapping = {
                ...pendingFieldMapping,
                [ThirdPartyCompanyDefaultField.accountOwner]:
                  HubspotAutomaticFields.hubspot_owner_name.name,
                [ThirdPartyCompanyDefaultField.accountOwnerEmail]:
                  HubspotAutomaticFields.hubspot_owner_email.name,
              };
            }

            setFieldMapping(updatedMapping);
            fieldMappingDelayer(updatedMapping);
            setModal(null);
            setPendingFieldMapping(fieldMapping);
          }}>
          <>
            <P>
              The <Span fontWeight="semibold">Account Owner Name</Span> and{' '}
              <Span fontWeight="semibold">Account Owner Email</Span> fields will be automatically
              populated based on the Salesforce <Span fontWeight="semibold">Account Owner ID</Span>{' '}
              you provided.
            </P>
          </>
        </ModernConfirmModal>
      )}
    </>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(FieldMappingTable) as unknown as React.FC<unknown>;

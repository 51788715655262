import {
  LOADING_MORE,
  QUERY_ERROR,
  QUERY_LOADED,
  REQUEST_QUERY,
  getVoteQueryKey,
} from '../actions/voteQueries';

export default function voteQueries(state = {}, action) {
  switch (action.type) {
    case REQUEST_QUERY: {
      const queryKey = getVoteQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case QUERY_LOADED: {
      const posts = [];
      action.result.posts.forEach((post) => {
        posts.push({
          boardID: post.board._id,
          postURLName: post.urlName,
        });
      });
      const queryKey = getVoteQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          posts,
          hasNextPage: action.result.hasNextPage,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case QUERY_ERROR: {
      const queryKey = getVoteQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LOADING_MORE: {
      const queryKey = getVoteQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from 'common/common/ErrorBoundary';

import 'css/components/subdomain/admin/_AdminSidebarSection.scss';

export default class AdminSidebarSection extends Component {
  static propTypes = {
    action: PropTypes.element,
    className: PropTypes.string,
    title: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    const sidebarClassName = 'adminSidebarSection' + (className ? ' ' + className : '');

    return (
      <div className={sidebarClassName}>
        <div className="header">
          <div className="title">{this.props.title}</div>
          <div className="action">{this.props.action}</div>
        </div>
        <ErrorBoundary>{this.props.children}</ErrorBoundary>
      </div>
    );
  }
}

import React, { Component } from 'react';

import { LogOut } from 'lucide-react';

import { reloadViewer } from 'common/actions/viewer';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import Dropdown from 'common/Dropdown';
import IconButtonV2 from 'common/ui/IconButtonV2';

import 'css/components/chrome/_CompanySelector.scss';

class CompanySelector extends Component {
  state = {
    loading: false,
  };

  getOptions = () => {
    const { viewer } = this.props;
    return viewer.companies.map((company) => ({ name: company._id, render: company.name }));
  };

  onChange = (companyID) => {
    const { onCompanySelected, viewer } = this.props;
    const selectedCompany = viewer.companies.find((company) => company._id === companyID);
    onCompanySelected(selectedCompany);
  };

  onLogout = async () => {
    const { reloadViewer } = this.props;

    this.setState({ loading: true });
    try {
      await AJAX.post('/api/viewer/logout');
      await reloadViewer();
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { company, onCompanySelected, viewer } = this.props;
    const { loading } = this.state;
    if (!onCompanySelected || !viewer || !viewer.companies.length > 1) {
      return null;
    }

    return (
      <div className="companySelector">
        <Dropdown
          defaultSelectedName={company._id}
          dropdownClassName="companySelectorDropdown"
          options={this.getOptions()}
          onChange={this.onChange}
        />
        <IconButtonV2
          onClick={this.onLogout}
          aria-label="Log out"
          icon={LogOut}
          loading={loading}
          size="large"
        />
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  reloadViewer: () => dispatch(reloadViewer()),
}))(CompanySelector);

export default function expandRange(range, value, regex) {
  while (range.start > 0) {
    if (value[range.start - 1].match(regex)) {
      break;
    }
    range.start--;
  }
  while (range.end < value.length) {
    if (value[range.end].match(regex)) {
      break;
    }
    range.end++;
  }
}

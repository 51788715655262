import Data from 'common/Data';
import promisify from 'common/util/promisify';

import type { Board } from 'common/api/endpoints/boards';
import type { RoadmapPost, Status } from 'common/api/endpoints/roadmapPosts';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { Category } from 'common/api/resources/board';
import type { RoadmapPostsState } from 'common/reducers/sharedRoadmapPosts';
import type { Dispatch, GetState } from 'redux-connect';

export const Actions = {
  FetchSharedRoadmapPosts: 'canny/roadmaps/shared/posts/request',
  SharedRoadmapPostsLoaded: 'canny/roadmaps/shared/posts/loaded',
  SharedRoadmapPostsError: 'canny/roadmaps/shared/posts/error',
};

export type SharedRoadmapPostsAction = Action & {
  roadmapID: string;
  roadmapViewID: string;
  roadmapPosts: RoadmapPost[];
  timestamp: Date;
  error?: string;
  type: typeof Actions;
  boards?: Board[];
  categories?: Category[];
  owners?: StrippedUser[];
  statuses?: Status[];
};

// Actions
function requestRoadmapPosts() {
  return {
    timestamp: Date.now(),
    type: Actions.FetchSharedRoadmapPosts,
  };
}

function roadmapPostsLoaded(
  roadmapID: string,
  roadmapViewID: string,
  boards: Board[],
  categories: Category[],
  owners: StrippedUser[],
  roadmapPosts: RoadmapPost[],
  statuses: Status[]
) {
  return {
    roadmapID,
    roadmapViewID,
    roadmapPosts,
    boards,
    categories,
    owners,
    statuses,
    timestamp: Date.now(),
    type: Actions.SharedRoadmapPostsLoaded,
  };
}

function roadmapPostsError(error: string) {
  return {
    error,
    timestamp: Date.now(),
    type: Actions.SharedRoadmapPostsError,
  };
}

// Action Creators
function fetchSharedRoadmapPosts(roadmapID: string, roadmapViewID: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          result: {
            input: {
              roadmapID,
              roadmapViewID,
            },
            query: Data.queries.sharedRoadmapPosts,
          },
        },
        cookies
      );
      return dispatch(
        roadmapPostsLoaded(
          roadmapID,
          roadmapViewID,
          response.result.distinctBoards,
          response.result.distinctCategories,
          response.result.distinctOwners,
          response.result.roadmapPosts,
          response.result.statuses
        )
      );
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return dispatch(roadmapPostsError(error));
    }
  };
}

export function loadRoadmapPosts(roadmap: Roadmap, roadmapViewID: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchRoadmapPosts(getState(), roadmap._id, roadmapViewID)) {
      dispatch(requestRoadmapPosts());
      return dispatch(fetchSharedRoadmapPosts(roadmap._id, roadmapViewID));
    }
  };
}

// Helper Functions
function shouldFetchRoadmapPosts(
  state: RoadmapPostsState,
  roadmapID: string,
  roadmapViewID: string
) {
  const roadmapPosts = state.roadmapPosts;
  if (!roadmapPosts?.[roadmapID]?.[roadmapViewID]) {
    return true;
  }
  return false;
}

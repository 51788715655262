export default function truncateString(string: string, maxLength: number) {
  string = string.trim();

  const firstNewline = string.indexOf('\n');
  if (firstNewline !== -1) {
    if (firstNewline < maxLength) {
      return string.substr(0, firstNewline) + '...';
    }
  }

  const willTruncate = maxLength < string.length;
  if (!willTruncate) {
    return string;
  }

  const truncatedString = string.substr(0, maxLength);
  const lastSymbol = Math.max(
    truncatedString.lastIndexOf(' '),
    truncatedString.lastIndexOf('!'),
    truncatedString.lastIndexOf(')'),
    truncatedString.lastIndexOf("'"),
    truncatedString.lastIndexOf('"'),
    truncatedString.lastIndexOf('}'),
    truncatedString.lastIndexOf(']'),
    truncatedString.lastIndexOf(','),
    truncatedString.lastIndexOf('.'),
    truncatedString.lastIndexOf('-')
  );

  if (lastSymbol === -1 || lastSymbol + 20 < truncatedString.length) {
    return truncatedString + '...';
  }

  return truncatedString.substr(0, lastSymbol) + '…';
}

import React from 'react';

import classnames from 'classnames';

import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import { H3, P } from 'common/ui/Text';

import type { Board } from 'common/api/endpoints/boards';

import 'css/components/subdomain/public/_BoardList.scss';

type Props = {
  boards: Board[];
  activeBoard?: Board;
  className?: string;
};

const BoardList = ({ boards, activeBoard, className }: Props) => {
  return (
    <ul className={classnames('boardList', className)}>
      <H3 variant="headingXs" className="header">
        Boards
      </H3>
      {boards.map((board) => (
        <Link key={board._id} to={`/${board.urlName}`}>
          <li className={classnames('boardItem', { active: activeBoard?._id === board._id })}>
            <P className="boardName">{translateString(board, 'name')}</P>
          </li>
        </Link>
      ))}
    </ul>
  );
};

export default BoardList;

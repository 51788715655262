import React from 'react';

import { Check, Clock } from 'lucide-react';

import Colors from 'common/colors/constants';
import Pill from 'common/common/Pill';
import LazyLoadedImage from 'common/LazyLoadedImage';
import nbspLastSpace from 'common/util/nbspLastSpace';

const CompletedPillStyle = {
  color: Colors.green110,
  background: Colors.green30,
};

const IncompletePillStyle = {
  color: Colors.gray110,
  background: Colors.gray30,
};

type InstructionTemplateProps = {
  completed: boolean;
  ctas?: React.ReactNode;
  description: string;
  hero?: React.ReactNode;
  heroImg?: string;
  heroImgAlt?: string;
  label: string;
  time: number;
};

const InstructionTemplate = ({
  completed,
  ctas,
  description,
  label,
  hero,
  heroImg,
  heroImgAlt,
  time,
}: InstructionTemplateProps) => {
  return (
    <div className="onboardingInstructions">
      {heroImg ? (
        <div className="hero image">
          <LazyLoadedImage src={heroImg} alt={heroImgAlt} />
        </div>
      ) : (
        <div className="hero">{hero}</div>
      )}
      <div className="title">
        {label}
        {completed ? (
          <Pill className="pill" pillStyle={CompletedPillStyle}>
            <div className="pillContents">
              <Check size={14} />
              Completed
            </div>
          </Pill>
        ) : (
          <Pill className="pill" pillStyle={IncompletePillStyle}>
            <div className="pillContents">
              <Clock size={14} />
              {time} minutes
            </div>
          </Pill>
        )}
      </div>
      <div className="description">{nbspLastSpace(description)}</div>
      <div className="ctas">{ctas}</div>
    </div>
  );
};

export default InstructionTemplate;

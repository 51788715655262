import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import TextInput from 'common/inputs/TextInput';
import Tappable from 'common/Tappable';
import UserAvatar from 'common/user/UserAvatar';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminPicker.scss';

const MinAdminsForSearch = 6;

class AdminPicker extends Component {
  static propTypes = {
    company: PropTypes.object,
    onAdminSeleted: PropTypes.func,
  };

  state = {
    searchValue: '',
  };

  onAdminSelected = (admin) => {
    this.props.onAdminSelected(admin);
  };

  onSearchChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  renderAdminSuggestions() {
    const {
      company: { members },
    } = this.props;
    const sortedAdmins = [...members].sort((a, b) => {
      const aComesFirst = a.name.toLowerCase() < b.name.toLowerCase();
      const bComesFirst = a.name.toLowerCase() > b.name.toLowerCase();

      if (aComesFirst) {
        return -1;
      } else if (bComesFirst) {
        return 1;
      } else {
        return 0;
      }
    });

    const { searchValue } = this.state;
    if (searchValue === '') {
      return sortedAdmins;
    }

    const matches = [];
    const matchSet = {};

    // exact matches
    sortedAdmins
      .filter((admins) => {
        return admins.name.toLowerCase() === searchValue.toLowerCase();
      })
      .forEach((exactMatch) => {
        matches.push(exactMatch);
        matchSet[exactMatch._id] = true;
      });

    // prefix matches
    sortedAdmins
      .filter((admins) => {
        return admins.name.toLowerCase().startsWith(searchValue.toLowerCase());
      })
      .forEach((prefixMatch) => {
        if (matchSet[prefixMatch._id]) {
          return;
        }

        matches.push(prefixMatch);
        matchSet[prefixMatch._id] = true;
      });

    // other matches
    sortedAdmins
      .filter((admins) => {
        return admins.name.toLowerCase().includes(searchValue.toLowerCase());
      })
      .forEach((otherMatch) => {
        if (matchSet[otherMatch._id]) {
          return;
        }

        matches.push(otherMatch);
        matchSet[otherMatch._id] = true;
      });

    return matches;
  }

  renderSearchInput() {
    const {
      company: { members },
    } = this.props;
    if (members.length < MinAdminsForSearch) {
      return null;
    }

    return (
      <TextInput
        autoFocus={true}
        onChange={this.onSearchChange}
        placeholder="Search&hellip;"
        prefix={<div className="icon icon-search" />}
      />
    );
  }

  render() {
    const {
      company: { members },
    } = this.props;
    const adminSuggestions = this.renderAdminSuggestions();
    return (
      <div className="adminPicker">
        {this.renderSearchInput()}
        <div
          className={classnames('suggestions', {
            noSearch: members.length < MinAdminsForSearch,
          })}>
          {adminSuggestions.map((admin, i) => (
            <Tappable onTap={this.onAdminSelected.bind(this, admin)} key={admin._id}>
              <div className="suggestion">
                <UserAvatar user={admin} />
                <div className="name">{admin.name}</div>
              </div>
            </Tappable>
          ))}
        </div>
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(AdminPicker);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { invalidateSuggestions } from 'common/actions/issueSuggestions';
import { reloadPost } from 'common/actions/posts';
import { reloadRoadmapPostsForRoadmap } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import TextInput from 'common/inputs/TextInput';
import Tappable from 'common/Tappable';
import emphasizeMatches from 'common/util/emphasizeMatches';
import findStringMatches from 'common/util/findStringMatches';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import 'css/components/subdomain/admin/_AdminRoadmapsFormPostSidebarSection.scss';

class AdminRoadmapsFormPostSidebarSection extends Component {
  static propTypes = {
    post: PropTypes.shape({
      _id: PropTypes.string,
    }),
    reload: PropTypes.func.isRequired,
    roadmaps: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  };

  state = {
    error: null,
    focused: true,
    mouseDown: false,
    submitting: false,
    value: '',
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onMouseDown, false);
    document.addEventListener('mouseup', this.onMouseUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
  }

  onAddRoadmap = async (roadmap) => {
    const { post, reload } = this.props;

    this.setState({
      error: null,
      focused: false,
      mouseDown: false,
      submitting: true,
    });

    const response = await AJAX.post('/api/roadmaps/posts/add', {
      postID: post._id,
      roadmapID: roadmap._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({
        error: error.message,
        submitting: false,
      });
      return;
    }

    await reload(post, roadmap);
    this.setState({
      submitting: false,
      value: '',
    });
  };

  onMouseDown = () => {
    const { focused } = this.state;
    if (!focused) {
      return;
    }

    this.setState({
      mouseDown: true,
    });
  };

  onMouseUp = () => {
    this.setState({
      mouseDown: false,
    });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  onChange = (e) => {
    this.setState({
      error: null,
      value: e.target.value,
    });
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderSuggestions = () => {
    const { focused, mouseDown, submitting, value } = this.state;
    const { roadmaps } = this.props;
    if ((!focused && !mouseDown) || submitting) {
      return null;
    }

    const visibleRoadmaps = roadmaps.filter((roadmap) => !roadmap.archived);
    const matches = findStringMatches(visibleRoadmaps, 'name', value);
    if (matches.length === 0) {
      return (
        <div className="focusFields">
          <div className="suggestions">
            <div className="noMatches">There are no matching&nbsp;roadmaps</div>
          </div>
        </div>
      );
    }

    const suggestions = matches.map((roadmap) => {
      return (
        <Tappable key={roadmap._id} onTap={() => this.onAddRoadmap(roadmap)}>
          <div className="suggestion">{emphasizeMatches(roadmap.name, value)}</div>
        </Tappable>
      );
    });

    return (
      <div className="focusFields">
        <div className="suggestions">{suggestions}</div>
      </div>
    );
  };

  render() {
    const { value, submitting } = this.state;

    return (
      <div className="adminRoadmapsFormPostSidebarSection">
        {this.renderError()}
        <TextInput
          autoFocus={true}
          disabled={submitting}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          placeholder="Search for a roadmap..."
          value={value}
        />
        {this.renderSuggestions()}
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  reload: (post, roadmap) => {
    return Promise.all([
      dispatch(invalidateSuggestions()),
      dispatch(reloadPost(post)),
      dispatch(reloadRoadmapPostsForRoadmap(roadmap)),
    ]);
  },
}))(AdminRoadmapsFormPostSidebarSection);

import React from 'react';

import classnames from 'classnames';

import { ImageMimeTypes, type LocalFile } from 'common/constants/files';
import FormFiles from 'common/file/FormFiles';
import FormImages from 'common/image/FormImages';

import 'css/components/file/_FileRenderer.scss';

type Props = {
  allowRemove: boolean;
  className?: string;
  files: LocalFile[];
  onFileRemoved?: (file: LocalFile) => void;
};

const FileRenderer = ({ allowRemove, className, files, onFileRemoved }: Props) => {
  if (!files || files.length === 0) {
    return null;
  }

  const imageFiles = files.filter((file) =>
    file.contentType ? ImageMimeTypes.includes(file.contentType) : false
  );
  const nonImageFiles = files.filter((file) =>
    // Note: If the file has no content type, we assume it is not an image
    file.contentType ? !ImageMimeTypes.includes(file.contentType) : true
  );
  const hasImages = imageFiles.length > 0;
  const hasFiles = nonImageFiles.length > 0;

  return (
    <div className={classnames('fileRenderer', className)}>
      {hasImages && (
        <div className="imageRenderer">
          <FormImages
            allowRemove={allowRemove}
            images={imageFiles}
            onImageRemoved={onFileRemoved}
          />
        </div>
      )}
      {hasFiles && (
        <div className={classnames('nonImageRenderer', { separator: hasImages })}>
          <FormFiles
            allowRemove={allowRemove}
            files={nonImageFiles}
            onFileRemoved={onFileRemoved}
          />
        </div>
      )}
    </div>
  );
};

export default FileRenderer;

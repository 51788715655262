import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ControlledDropdown from 'common/ControlledDropdown';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Link from 'common/Link';
import ModalPortal from 'common/modals/ModalPortal';
import Tappable from 'common/Tappable';
import validateInput from 'common/validateInput';

import 'css/components/modals/_EditBoardFieldModal.scss';

export default class EditBoardFieldModal extends Component {
  static propTypes = {
    company: PropTypes.shape({
      featureAllowlist: PropTypes.arrayOf(PropTypes.string),
    }),
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    field: PropTypes.object.isRequired,
  };

  state = {
    error: null,
    label: null,
    placeholder: null,
  };

  onSubmit = ({ field, label, placeholder }) => {
    const { onEdit } = this.props;

    if (field.default && field.tempID === 'details') {
      if (!validateInput.board.strings.detailsField(label)) {
        this.setState({
          error: 'Please enter a valid label (1-20 characters)',
        });
        return;
      }
      if (!validateInput.board.strings.detailsPlaceholder(placeholder)) {
        this.setState({
          error: 'Please enter a valid placeholder (1-60 characters)',
        });
        return;
      }
    }
    if (field.default && field.tempID === 'title') {
      if (!validateInput.board.strings.titleField(label)) {
        this.setState({
          error: 'Please enter a valid label (1-20 characters)',
        });
        return;
      }
      if (!validateInput.board.strings.titlePlaceholder(placeholder)) {
        this.setState({
          error: 'Please enter a valid placeholder (1-60 characters)',
        });
        return;
      }
    } else {
      if (!validateInput.customPostField.label(label)) {
        this.setState({
          error: 'Please enter a valid label (1-30 characters)',
        });
        return;
      } else if (!validateInput.customPostField.placeholder(placeholder)) {
        this.setState({
          error: 'Please enter a valid placeholder (1-35 characters)',
        });
        return;
      }
    }

    return onEdit({ field, label, placeholder });
  };

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { field, onClose } = this.props;
    const { label, placeholder } = this.state;

    const hasLabel = label || label === null;
    const hasPlaceholder = placeholder || placeholder === null;
    const isEnabled = hasLabel && hasPlaceholder;
    return (
      <ModalPortal className="boardFieldPortal" closeOnClickAway onClose={onClose}>
        <div className="editBoardFieldModal">
          <div className="modalHeader">
            <h2>Edit field</h2>
            <Tappable onTap={onClose}>
              <div className="closeIcon icon icon-x" />
            </Tappable>
          </div>
          <p className="info">
            The field type can't be modified. To modify the type, delete this field and create a new
            one with the correct type in your{' '}
            <Link to="/admin/settings/fields/post-fields">Post Fields</Link> settings.
          </p>
          <Form
            addEventsToDocument={false}
            disableSubmit={!isEnabled}
            onSubmit={() =>
              this.onSubmit({
                field,
                label: label ?? field.label,
                placeholder: placeholder ?? field.placeholder,
              })
            }>
            <div className="formContainer">
              <div className="fields">
                <ControlledDropdown
                  disabled={true}
                  options={[
                    {
                      name: field.tempID,
                      render: field.name,
                    },
                  ]}
                  selectedName={field.tempID}
                />
                <TextInput
                  autoFocus={true}
                  defaultValue={field.label}
                  inset="Field label"
                  onChange={(e) => this.setState({ label: e.target.value })}
                />
                <TextInput
                  inset="Placeholder"
                  defaultValue={field.placeholder}
                  onChange={(e) => this.setState({ placeholder: e.target.value })}
                />
              </div>
              {this.renderError()}
              <div className="buttons">
                <Button
                  buttonType="cannyButton"
                  disabled={!isEnabled}
                  formButton={true}
                  value="Save"
                />
              </div>
            </div>
          </Form>
        </div>
      </ModalPortal>
    );
  }
}

import React, { useContext } from 'react';

import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Helmet from 'common/helmets/Helmet';
import AdminIntegrationPlanCard from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationPlanCard';
import { getEnabledAutopilotIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/utils';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';
import AdminSettingsSidebarTitle from 'common/subdomain/admin/AdminSettingsSidebarTitle';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationSettingsContainer.module.scss';

import type { Company } from 'common/api/endpoints/companies';

type Props = {
  children: React.ReactNode;
};

type NavHeading = {
  title: string;
  label?: never;
  urlSuffix?: never;
};

type NavLink = {
  label: string;
  urlSuffix: string;
  title?: never;
};

type NavItem = NavHeading | NavLink;

const getSidebarLinks: (hasEnabledIntegrations: boolean) => NavItem[] = (
  hasEnabledIntegrations = false
) => {
  return [
    {
      label: 'All Integrations',
      urlSuffix: 'all',
    },
    ...(hasEnabledIntegrations
      ? [
          {
            label: 'Active Integrations',
            urlSuffix: 'active',
          },
        ]
      : []),
    {
      title: 'Works with',
    },
    {
      label: 'Autopilot',
      urlSuffix: 'autopilot',
    },
    {
      label: 'Feedback Boards',
      urlSuffix: 'feedback-boards',
    },
    {
      title: 'Categories',
    },
    {
      label: 'All',
      urlSuffix: 'categories',
    },
    {
      label: 'Authentication',
      urlSuffix: 'authentication',
    },
    {
      label: 'Automation',
      urlSuffix: 'automation',
    },
    {
      label: 'Communication',
      urlSuffix: 'communication',
    },
    {
      label: 'Data and Enrichment',
      urlSuffix: 'data-and-enrichment',
    },
    {
      label: 'Project Management',
      urlSuffix: 'project-management',
    },
    {
      label: 'Sales',
      urlSuffix: 'sales',
    },
  ];
};

const AdminIntegrationSettingsContainer = ({ children }: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const { integrationCount } = useContext(ActiveIntegrationContext);

  const enabledAutopilotIntegrations = getEnabledAutopilotIntegrations(company);

  const hasEnabledIntegrations = integrationCount + enabledAutopilotIntegrations.length > 0;
  const sidebarLinks = getSidebarLinks(hasEnabledIntegrations);

  // render
  return (
    <main className="adminIntegrationSettingsContainer">
      <Helmet title="Integrations | Canny" />
      <AdminSettingsHeader
        title="Integrations"
        subheading="Connect Canny to your tools and&nbsp;services."
        learnMoreLink="https://help.canny.io/en/collections/325118-canny-integrations"
      />
      <div className={styles.innerContainer}>
        <div className={styles.sidebar}>
          <nav>
            <ul className={styles.navList}>
              {sidebarLinks.map(({ label, urlSuffix, title }) => {
                if (title) {
                  return (
                    <li key={title} className={styles.sidebarTitle}>
                      <AdminSettingsSidebarTitle title={title} />
                    </li>
                  );
                }
                return (
                  <li key={urlSuffix} className={styles.sidebarLink}>
                    <AdminSettingsSidebarLink to={`/admin/settings/integrations/${urlSuffix}`}>
                      {label}
                    </AdminSettingsSidebarLink>
                  </li>
                );
              })}
            </ul>
          </nav>
          <AdminIntegrationPlanCard />
        </div>
        <div className={styles.settings}>{children}</div>
      </div>
    </main>
  );
};

export default AdminIntegrationSettingsContainer;

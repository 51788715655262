import { DefaultColors } from 'common/postStatus/PostStatusColors';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';

export type DefaultStatus = {
  color: string;
  name: string;
  order: number;
  showInPublicRoadmap: boolean;
  type: PostStatusTypes;
};

const DefaultStatuses = [
  {
    color: DefaultColors.Open,
    name: 'open',
    order: 0,
    showInPublicRoadmap: false,
    type: PostStatusTypes.Initial,
  },
  {
    color: DefaultColors.UnderReview,
    name: 'under review',
    order: 1,
    showInPublicRoadmap: false,
    type: PostStatusTypes.Active,
  },
  {
    color: DefaultColors.Planned,
    name: 'planned',
    order: 2,
    showInPublicRoadmap: true,
    type: PostStatusTypes.Active,
  },
  {
    color: DefaultColors.InProgress,
    name: 'in progress',
    order: 3,
    showInPublicRoadmap: true,
    type: PostStatusTypes.Active,
  },
  {
    color: DefaultColors.Complete,
    name: 'complete',
    order: 4,
    showInPublicRoadmap: true,
    type: PostStatusTypes.Complete,
  },
  {
    color: DefaultColors.Closed,
    name: 'closed',
    order: 5,
    showInPublicRoadmap: false,
    type: PostStatusTypes.Closed,
  },
];

export const DefaultChangelogRoadmapStatuses = ['under review', 'planned', 'in progress'];
export const DefaultRoadmapStatuses = ['planned', 'in progress', 'complete'];

export default DefaultStatuses;

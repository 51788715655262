import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Tappable from 'common/Tappable';
import parseAPIResponse from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminBoardSettingsTags.scss';

class AdminBoardSettingsTags extends Component {
  static propTypes = {
    board: PropTypes.shape({
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          postCount: PropTypes.number,
          urlName: PropTypes.string,
        })
      ),
    }),
    company: PropTypes.shape({
      plan: PropTypes.object,
      subdomain: PropTypes.string,
    }),
    openModal: PropTypes.func,
    reloadBoard: PropTypes.func,
    router: PropTypes.object.isRequired,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    creating: false,
    deleting: false,
    renaming: false,
    renamingID: null,
  };

  constructor(props, context) {
    super(props, context);

    this.createInputRef = React.createRef();
    this.renameInputRef = React.createRef();
  }

  onCancelRenaming = () => {
    this.setState({
      renamingID: null,
    });
  };

  onCreateInputChange = (e) => {
    const { value } = e.target;
    this.setState({
      error: null,
      showCreateButton: value !== '',
    });
  };

  onCreateTag = () => {
    const { creating } = this.state;
    if (creating) {
      return;
    }

    const { board } = this.props;
    const tagName = this.createInputRef.current.getValue();
    if (!validateInput.tags.name(tagName)) {
      this.setState({
        error: 'Please enter a valid tag name. (1-30 characters)',
      });
      return;
    } else if (
      board.tags.find((tag) => {
        return tag.name === tagName;
      })
    ) {
      this.setState({
        error: 'This tag name already exists.',
      });
      return;
    }

    this.setState({
      creating: true,
      error: null,
    });

    AJAX.post(
      '/api/tags/create',
      {
        boardID: board._id,
        name: tagName,
      },
      (response) => {
        const { error } = parseAPIResponse(response, { isSuccessful: (tag) => tag._id });

        this.setState({
          creating: false,
        });

        if (!error) {
          const { reloadBoard } = this.props;
          reloadBoard(board.urlName);
          this.createInputRef.current.setValue('');
          return;
        }

        this.setState({
          error: error.message,
        });
      }
    );
  };

  onDeleteTag = (tag) => {
    const { deleting } = this.state;
    if (deleting) {
      return;
    }

    const suffix = tag.postCount === 1 ? ' post' : ' posts';
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message:
        "Are you sure you'd like to delete this tag? It will be removed from " +
        tag.postCount +
        suffix +
        '.',
      onConfirm: () => {
        this.setState({
          deleting: true,
        });

        AJAX.post(
          '/api/tags/delete',
          {
            tagID: tag._id,
          },
          (response) => {
            this.setState({
              deleting: false,
            });

            if (response === 'success') {
              const { board, reloadBoard } = this.props;
              reloadBoard(board.urlName);
              return;
            } else {
              this.setState({
                error: 'Something went wrong, please try again later.',
              });
            }
          }
        );
      },
    });
  };

  onRenameTag = (tag) => {
    this.setState({
      renamingID: tag._id,
    });
  };

  onSaveRename = (tag) => {
    const { renaming } = this.state;
    if (renaming) {
      return;
    }

    const { board } = this.props;
    const tagName = this.renameInputRef.current.getValue();
    if (!validateInput.tags.name(tagName)) {
      this.setState({
        error: 'Please enter a valid tag name. (1-30 characters)',
      });
      return;
    } else if (
      board.tags.find((tag) => {
        return tag.name === tagName;
      }) &&
      tagName !== tag.name
    ) {
      this.setState({
        error: 'This tag name already exists.',
      });
      return;
    }

    this.setState({
      renaming: true,
      error: null,
    });

    AJAX.post(
      '/api/tags/edit',
      {
        name: tagName,
        tagID: tag._id,
      },
      (response) => {
        this.setState({
          renaming: false,
          renamingID: null,
        });

        if (response === 'success') {
          const { reloadBoard } = this.props;
          reloadBoard(board.urlName);
          return;
        } else {
          this.setState({
            error: 'Something went wrong, please try again later.',
          });
        }
      }
    );
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderTags() {
    const {
      board: { tags },
    } = this.props;
    const items = [];
    tags.forEach((tag) => {
      const { renamingID } = this.state;
      if (tag._id === renamingID) {
        items.push(
          <div className="tagItem renaming" key={tag._id}>
            <TextInput autoFocus={true} defaultValue={tag.name} ref={this.renameInputRef} />
            <div className="rightContainer">
              <Tappable onTap={this.onCancelRenaming}>
                <div className="cancelLink">Cancel</div>
              </Tappable>
              <Tappable onTap={this.onSaveRename.bind(this, tag)}>
                <div className="saveLink">Save</div>
              </Tappable>
            </div>
          </div>
        );
      } else {
        const suffix = tag.postCount === 1 ? ' post' : ' posts';
        items.push(
          <div className="tagItem" key={tag._id}>
            <div className="tagName">{tag.name + ' (' + tag.postCount + suffix + ')'}</div>
            <div className="rightContainer">
              <Tappable onTap={this.onRenameTag.bind(this, tag)}>
                <div className="renameLink">Rename</div>
              </Tappable>
              <Tappable onTap={this.onDeleteTag.bind(this, tag)}>
                <div className="deleteLink">Delete</div>
              </Tappable>
            </div>
          </div>
        );
      }
    });

    return <div className="tags">{items}</div>;
  }

  renderCreateButton() {
    const { creating, showCreateButton } = this.state;
    if (!showCreateButton) {
      return null;
    }

    return <Button formButton={true} loading={creating} value="Create" />;
  }

  renderCreateTagForm() {
    return (
      <Form
        className="createTagForm"
        addEventsToDocument={false}
        disableSubmit={this.state.creating}
        onSubmit={this.onCreateTag}>
        <TextInput
          onChange={this.onCreateInputChange}
          placeholder="Create new tag…"
          ref={this.createInputRef}
        />
        {this.renderCreateButton()}
      </Form>
    );
  }

  render() {
    const { board } = this.props;
    return (
      <div className="adminBoardSettingsTags">
        <Helmet title={'Tag Settings | ' + board.name + ' | Canny'} />
        <div className="text">Note: Tags are currently only visible to admins.</div>
        {this.renderTags()}
        {this.renderCreateTagForm()}
        {this.renderError()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board.tags),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminBoardSettingsTags);

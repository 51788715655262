/**
 * customField: the calculation references a custom field; uses `fieldID`.
 * default: the calculation is done through a predefined field; uses `field`. e.g., `wonOpportunities`
 */
export enum CalculationFieldTypes {
  customField = 'customField',
  default = 'default',
}

export enum CalculationFunctions {
  average = 'average',
  count = 'count',
  sum = 'sum',
  max = 'max',
  min = 'min',
}

/**
 * These can only be root-level UserLookup fields
 */
export enum CalculationStaticFactors {
  monthlySpend = 'monthlySpend',
  annualSpend = 'annualSpend',
}

export enum CalculationOpportunityFactors {
  openOpportunities = 'openOpportunities',
  wonOpportunities = 'wonOpportunities',
  lostOpportunities = 'lostOpportunities',
}

export enum CalculationVoteFactors {
  companyVotes = 'companyVotes',
  userVotes = 'userVotes',
  niceToHaveUserVotes = 'niceToHaveUserVotes',
  importantUserVotes = 'importantUserVotes',
  mustHaveUserVotes = 'mustHaveUserVotes',
}

export enum PostFieldFormats {
  money = 'money',
  number = 'number',
}

import promisify from 'common/util/promisify';

import Data from '../Data';

import type { ActionItem } from 'common/api/endpoints/queue';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import type { State } from 'common/reducers/actionItemQueries';
import type { Dispatch, GetState } from 'redux-connect';

const key = 'action_item_queries';

// Action Types
export const RemoveItem = `canny/${key}/remove_item`;
export const RequestQuery = `canny/${key}/request_query`;
export const QueryLoaded = `canny/${key}/query_loaded`;
export const QueryError = `canny/${key}/query_error`;
export const InvalidateAndReload = `canny/${key}/invalidate_and_reload`;
export const LoadingMore = `canny/${key}/loading_more`;
export const UpdateCount = `canny/${key}/update_count`;

type FullReduxState = {
  actionItemQueries: State;
};

export type QueryParams = {
  source?: string;
};

type Result = {
  items: Record<string, ActionItem>;
  count: number;
  postActionsCount: number;
  draftActionsCount: number;
  draftActionsCountPerSource: Partial<Record<SyncIntegrationNames, number>>;
  hasMore: boolean;
};

export type AutopilotActionItemAction = Action & {
  queryParams: QueryParams;
  result: Result;
  item?: ActionItem;
};

// Actions

function requestQuery(queryParams: QueryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestQuery,
  };
}

function removeItemFromState(item: Pick<ActionItem, '_id'>) {
  return {
    item,
    type: RemoveItem,
  };
}

function queryLoaded(queryParams: QueryParams, result: Result, reload = false) {
  return {
    queryParams,
    reload,
    result,
    timestamp: Date.now(),
    type: QueryLoaded,
  };
}

function queryError(queryParams: QueryParams, error: string) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QueryError,
  };
}

function invalidatingAndReloading(queryParams: QueryParams) {
  return {
    timestamp: Date.now(),
    queryParams,
    type: InvalidateAndReload,
  };
}

function loadingMore(queryParams: QueryParams, pages: number) {
  return {
    pages,
    queryParams,
    timestamp: Date.now(),
    type: LoadingMore,
  };
}

// Action Creators
function fetchQuery(queryParams: QueryParams, reload = false, additionalInput = {}) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const data = await promisify(
        Data.fetch,
        {
          result: {
            input: { ...queryParams, ...additionalInput },
            query: Data.queries.actionItems,
          },
        },
        cookies
      );
      return gotResult(dispatch, queryParams, '', data.result, reload);
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return gotResult(dispatch, queryParams, error);
    }
  };
}

export function loadActionItems(queryParams: QueryParams) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams, false));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMoreActionItems(queryParams: QueryParams, pages: number) {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch(loadingMore(queryParams, pages));
    if (!isFetchingQuery(getState(), queryParams)) {
      return dispatch(fetchQuery(queryParams, false, { pages }));
    }
  };
}

export function invalidateQueriesAndReload(queryParams: QueryParams) {
  return (dispatch: Dispatch) => {
    dispatch(invalidatingAndReloading(queryParams));
    return dispatch(fetchQuery(queryParams, true));
  };
}

export function removeItem(item: Pick<ActionItem, '_id'>) {
  return (dispatch: Dispatch) => {
    return dispatch(removeItemFromState(item));
  };
}

// Helper Functions
export function getQueryKey(queryParams: QueryParams) {
  return JSON.stringify(queryParams);
}

function shouldFetchQuery(state: FullReduxState, queryParams: QueryParams) {
  const { actionItemQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !actionItemQueries.queries[queryKey];
}

function isFetchingQuery(state: FullReduxState, queryParams: QueryParams) {
  const { actionItemQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !!actionItemQueries.queries[queryKey].loading;
}

// Callback Queue
const resultCallbacks: Record<string, (() => void)[]> = {};

async function waitForResult(queryParams: QueryParams) {
  const queryKey = getQueryKey(queryParams);
  return new Promise((resolve) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => resolve(true));
  });
}

async function gotResult(
  dispatch: Dispatch,
  queryParams: QueryParams,
  error: string,
  result?: Result,
  reload = false
) {
  const copiedParams = { ...queryParams };
  const resultAction = result
    ? queryLoaded(copiedParams, result, reload)
    : queryError(copiedParams, error);

  await dispatch(resultAction);

  const queryKey = getQueryKey(copiedParams);
  const callbacks = resultCallbacks[queryKey];
  if (!callbacks) {
    return;
  }

  callbacks.forEach((cb) => cb());
  resultCallbacks[queryKey].length = 0;
}

import { dayjs } from 'common/util/dayjsUtils';

export enum RelativeDateRanges {
  allTime = 'all-time',
  lastHalf = 'last-half',
  lastMonth = 'last-month',
  lastQuarter = 'last-quarter',
  lastWeek = 'last-week',
  thisHalf = 'this-half',
  thisMonth = 'this-month',
  thisQuarter = 'this-quarter',
  thisWeek = 'this-week',
  thisWholeHalf = 'this-whole-half',
  thisWholeMonth = 'this-whole-month',
  thisWholeQuarter = 'this-whole-quarter',
  thisWholeWeek = 'this-whole-week',
  nextHalf = 'next-half',
  nextMonth = 'next-month',
  nextQuarter = 'next-quarter',
  nextWeek = 'next-week',
  today = 'today',
}

type DateRangeOption = {
  text: string;
  value: RelativeDateRanges;
  toRange: () => [string, string] | [null, null];
  getPreviousRange: () => [string, string] | [null, null];
};

export const RelativeDateOptions: Record<RelativeDateRanges, DateRangeOption> = {
  [RelativeDateRanges.today]: {
    text: 'Today',
    value: RelativeDateRanges.today,
    toRange() {
      const from = dayjs().startOf('day').format('YYYY-MM-DD');
      const to = dayjs().endOf('day').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      const from = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
      const to = dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD');
      return [from, to];
    },
  },
  [RelativeDateRanges.thisWeek]: {
    text: 'This week',
    value: RelativeDateRanges.thisWeek,
    toRange() {
      const from = dayjs().startOf('week').format('YYYY-MM-DD');
      const to = dayjs().endOf('day').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastWeek].toRange();
    },
  },
  [RelativeDateRanges.thisMonth]: {
    text: 'This month',
    value: RelativeDateRanges.thisMonth,
    toRange() {
      const from = dayjs().startOf('month').format('YYYY-MM-DD');
      const to = dayjs().endOf('day').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastMonth].toRange();
    },
  },
  [RelativeDateRanges.thisQuarter]: {
    text: 'This quarter',
    value: RelativeDateRanges.thisQuarter,
    toRange() {
      const from = dayjs().startOf('quarter').format('YYYY-MM-DD');
      const to = dayjs().endOf('day').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastQuarter].toRange();
    },
  },
  [RelativeDateRanges.thisHalf]: {
    text: 'This half',
    value: RelativeDateRanges.thisHalf,
    toRange() {
      const thisHalf =
        dayjs().quarter() <= 2
          ? dayjs().quarter(1) // start of the first half
          : dayjs().quarter(3); // start of the second half
      const from = thisHalf.startOf('quarter').format('YYYY-MM-DD');
      const to = dayjs().endOf('day').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastHalf].toRange();
    },
  },
  [RelativeDateRanges.thisWholeWeek]: {
    text: 'This week',
    value: RelativeDateRanges.thisWholeWeek,
    toRange() {
      const from = dayjs().startOf('week').format('YYYY-MM-DD');
      const to = dayjs().endOf('week').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastWeek].toRange();
    },
  },
  [RelativeDateRanges.thisWholeMonth]: {
    text: 'This month',
    value: RelativeDateRanges.thisWholeMonth,
    toRange() {
      const from = dayjs().startOf('month').format('YYYY-MM-DD');
      const to = dayjs().endOf('month').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastMonth].toRange();
    },
  },
  [RelativeDateRanges.thisWholeQuarter]: {
    text: 'This quarter',
    value: RelativeDateRanges.thisWholeQuarter,
    toRange() {
      const from = dayjs().startOf('quarter').format('YYYY-MM-DD');
      const to = dayjs().endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastQuarter].toRange();
    },
  },
  [RelativeDateRanges.thisWholeHalf]: {
    text: 'This half',
    value: RelativeDateRanges.thisWholeHalf,
    toRange() {
      const thisHalf =
        dayjs().quarter() <= 2
          ? dayjs().quarter(1) // start of the first half
          : dayjs().quarter(3); // start of the second half
      const nextHalf =
        dayjs().quarter() >= 3
          ? dayjs().add(1, 'year').quarter(1) // next year's first half
          : dayjs().quarter(3); // this year's second half
      const from = thisHalf.startOf('quarter').format('YYYY-MM-DD');
      const to = nextHalf.startOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.lastHalf].toRange();
    },
  },
  [RelativeDateRanges.allTime]: {
    text: 'All time',
    value: RelativeDateRanges.allTime,
    toRange() {
      return [null, null];
    },
    getPreviousRange() {
      return [null, null];
    },
  },
  [RelativeDateRanges.lastWeek]: {
    text: 'Last week',
    value: RelativeDateRanges.lastWeek,
    toRange() {
      const lastWeek = dayjs().subtract(1, 'week');
      const from = lastWeek.startOf('week').format('YYYY-MM-DD');
      const to = lastWeek.endOf('week').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      const previousWeek = dayjs().subtract(2, 'week');
      const from = previousWeek.startOf('week').format('YYYY-MM-DD');
      const to = previousWeek.endOf('week').format('YYYY-MM-DD');
      return [from, to];
    },
  },
  [RelativeDateRanges.lastMonth]: {
    text: 'Last month',
    value: RelativeDateRanges.lastMonth,
    toRange() {
      const lastMonth = dayjs().subtract(1, 'month');
      const from = lastMonth.startOf('month').format('YYYY-MM-DD');
      const to = lastMonth.endOf('month').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      const previousMonth = dayjs().subtract(2, 'month');
      const from = previousMonth.startOf('month').format('YYYY-MM-DD');
      const to = previousMonth.endOf('month').format('YYYY-MM-DD');
      return [from, to];
    },
  },
  [RelativeDateRanges.lastQuarter]: {
    text: 'Last quarter',
    value: RelativeDateRanges.lastQuarter,
    toRange() {
      const lastQuarter = dayjs().subtract(1, 'quarter');
      const from = lastQuarter.startOf('quarter').format('YYYY-MM-DD');
      const to = lastQuarter.endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      const previousQuarter = dayjs().subtract(2, 'quarter');
      const from = previousQuarter.startOf('quarter').format('YYYY-MM-DD');
      const to = previousQuarter.endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
  },
  [RelativeDateRanges.lastHalf]: {
    text: 'Last half',
    value: RelativeDateRanges.lastHalf,
    toRange() {
      const lastHalf =
        dayjs().quarter() <= 2
          ? dayjs().subtract(1, 'year').quarter(3) // last year's last half
          : dayjs().quarter(1); // this year's first half
      const from = lastHalf.startOf('quarter').format('YYYY-MM-DD');
      const to = lastHalf.add(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      const previousHalf =
        dayjs().quarter() <= 2
          ? dayjs().subtract(1, 'year').quarter(1) // last year's first half
          : dayjs().subtract(1, 'year').quarter(3); // last year's last half
      const from = previousHalf.startOf('quarter').format('YYYY-MM-DD');
      const to = previousHalf.add(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
  },
  [RelativeDateRanges.nextWeek]: {
    text: 'Next week',
    value: RelativeDateRanges.nextWeek,
    toRange() {
      const lastWeek = dayjs().add(1, 'week');
      const from = lastWeek.startOf('week').format('YYYY-MM-DD');
      const to = lastWeek.endOf('week').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.thisWholeWeek].toRange();
    },
  },
  [RelativeDateRanges.nextMonth]: {
    text: 'Next month',
    value: RelativeDateRanges.nextMonth,
    toRange() {
      const nextMonth = dayjs().add(1, 'month');
      const from = nextMonth.startOf('month').format('YYYY-MM-DD');
      const to = nextMonth.endOf('month').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.thisWholeMonth].toRange();
    },
  },
  [RelativeDateRanges.nextQuarter]: {
    text: 'Next quarter',
    value: RelativeDateRanges.nextQuarter,
    toRange() {
      const nextQuarter = dayjs().add(1, 'quarter');
      const from = nextQuarter.startOf('quarter').format('YYYY-MM-DD');
      const to = nextQuarter.endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.thisWholeQuarter].toRange();
    },
  },
  [RelativeDateRanges.nextHalf]: {
    text: 'Next half',
    value: RelativeDateRanges.nextHalf,
    toRange() {
      const nextHalf =
        dayjs().quarter() >= 3
          ? dayjs().add(1, 'year').quarter(1) // next year's first half
          : dayjs().quarter(3); // this year's second half
      const from = nextHalf.startOf('quarter').format('YYYY-MM-DD');
      const to = nextHalf.add(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
      return [from, to];
    },
    getPreviousRange() {
      return RelativeDateOptions[RelativeDateRanges.thisWholeHalf].toRange();
    },
  },
};

export const getRelativeDateOptionByValue = (value: keyof typeof RelativeDateRanges) =>
  Object.values(RelativeDateOptions).find((option) => option.value === value);

import promisify from 'common/util/promisify';

import Data from '../Data';

// Action Types
export const RequestRoadmap = 'canny/roadmaps/requestOne';
export const RequestRoadmaps = 'canny/roadmaps/request';
export const RoadmapLoaded = 'canny/roadmaps/loadedOne';
export const RoadmapsLoaded = 'canny/roadmaps/loaded';
export const RoadmapsError = 'canny/roadmaps/error';
export const InvalidateRoadmaps = 'canny/roadmaps/invalidate';
export const VisibilitySettingsUpdated = 'canny/roadmaps/visibilitySettingsUpdated';

// Actions
function requestRoadmap(roadmapURLName) {
  return {
    roadmapURLName,
    timestamp: Date.now(),
    type: RequestRoadmap,
  };
}

function requestRoadmaps() {
  return {
    timestamp: Date.now(),
    type: RequestRoadmaps,
  };
}

function roadmapLoaded(roadmap, oldURLName) {
  return {
    oldURLName,
    roadmap,
    timestamp: Date.now(),
    type: RoadmapLoaded,
  };
}

function roadmapsLoaded(roadmaps) {
  return {
    roadmaps,
    timestamp: Date.now(),
    type: RoadmapsLoaded,
  };
}

function roadmapsError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: RoadmapsError,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: InvalidateRoadmaps,
  };
}

function visibilitySettingsUpdated(roadmap, hiddenColumnIDs) {
  return {
    hiddenColumnIDs,
    roadmap,
    timestamp: Date.now(),
    type: VisibilitySettingsUpdated,
  };
}

// Action Creators
function fetchRoadmap(roadmapURLName) {
  return async (dispatch, getState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          roadmap: {
            input: {
              roadmapURLName,
            },
            query: Data.queries.roadmap,
          },
        },
        cookies
      );
      return dispatch(roadmapLoaded(response.roadmap, roadmapURLName));
    } catch (e) {
      return dispatch(roadmapsError(e));
    }
  };
}

function fetchRoadmaps() {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        roadmaps: { query: Data.queries.roadmaps },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(roadmapsError(error));
        } else {
          return dispatch(roadmapsLoaded(data.roadmaps));
        }
      }
    );
  };
}

export function loadRoadmap(roadmapURLName) {
  return (dispatch, getState) => {
    if (shouldFetchRoadmap(getState(), roadmapURLName)) {
      dispatch(requestRoadmap(roadmapURLName));
      return dispatch(fetchRoadmap(roadmapURLName));
    }
  };
}

export function loadRoadmaps() {
  return (dispatch, getState) => {
    if (shouldFetchRoadmaps(getState())) {
      dispatch(requestRoadmaps());
      return dispatch(fetchRoadmaps());
    }
  };
}

export function updateVisibilitySettings(roadmap, hiddenColumnIDs) {
  return (dispatch, getState) => {
    return dispatch(visibilitySettingsUpdated(roadmap, hiddenColumnIDs));
  };
}

export function reloadRoadmaps() {
  return (dispatch) => {
    return dispatch(fetchRoadmaps());
  };
}

export function reloadRoadmap(roadmapURLName) {
  return (dispatch) => {
    return dispatch(fetchRoadmap(roadmapURLName));
  };
}

export function invalidateRoadmaps() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions
function shouldFetchRoadmaps(state) {
  return !state.roadmaps;
}

function shouldFetchRoadmap(state, roadmapURLName) {
  if (!state.roadmaps?.roadmaps) {
    return true;
  }

  const roadmap = state.roadmaps.roadmaps.find((roadmap) => roadmap.urlName === roadmapURLName);
  if (!roadmap) {
    return true;
  }

  return false;
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import TextInput from 'common/inputs/TextInput';
import Tappable from 'common/Tappable';
import emphasizeMatches from 'common/util/emphasizeMatches';
import findStringMatches from 'common/util/findStringMatches';
import mapify from 'common/util/mapify';
import stringSort from 'common/util/stringSort';

import 'css/components/categories/_CategorySelector.scss';

const Uncategorized = {
  name: 'Uncategorized',
  subCategories: [],
  urlName: 'uncategorized',
};

export default class CategorySelector extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    boards: PropTypes.arrayOf(
      PropTypes.shape({
        categories: PropTypes.array,
      })
    ),
    excludeCategories: PropTypes.array,
    isFloating: PropTypes.bool,
    onCategorySelected: PropTypes.func,
  };

  static defaultProps = {
    autoFocus: false,
    isFloating: false,
  };

  state = {
    focused: false,
    mouseDown: false,
    searchValue: '',
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onMouseDown, false);
    document.addEventListener('mouseup', this.onMouseUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
  }

  onBlur = () => {
    this.setState({
      focused: false,
    });
  };

  onChange = (e) => {
    this.setState({
      searchValue: e.target.value.trim(),
    });
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onMouseDown = () => {
    const { focused } = this.state;
    if (!focused) {
      return;
    }

    this.setState({
      mouseDown: true,
    });
  };

  onMouseUp = () => {
    this.setState({
      mouseDown: false,
    });
  };

  onCategorySelected = (category) => {
    this.props.onCategorySelected(category);

    this.inputRef.current.blur();
    this.inputRef.current.setValue('');

    this.setState({
      focused: false,
      searchValue: '',
    });
  };

  renderInput() {
    const { autoFocus } = this.props;
    return (
      <div className="categoryInput">
        <TextInput
          autoFocus={autoFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          placeholder="Search..."
          ref={this.inputRef}
        />
        {this.renderSuggestions()}
      </div>
    );
  }

  renderSuggestions() {
    const { isFloating } = this.props;
    const { focused, mouseDown, searchValue } = this.state;

    if (!focused && !mouseDown) {
      return null;
    }

    const { boards, excludeCategories } = this.props;
    const categories = boards.reduce((accumulator, board) => {
      const boardCategories = board.categories.map((category) => {
        return {
          _id: category._id,
          boardID: board._id,
          index: category.index,
          name: category.name,
          parentID: category.parentID,
          subCategories: [],
          urlName: category.urlName,
        };
      });
      return [...accumulator, ...boardCategories];
    }, []);

    const excludedCategorySet = mapify(excludeCategories, 'urlName');
    const categoriesIndexed = categories.every((category) => category.index !== null);
    const sortFunction = categoriesIndexed ? (a, b) => a.index - b.index : stringSort('name');
    const sortedCategories = categories.sort(sortFunction).concat(isFloating ? Uncategorized : []);
    const filteredCategories = sortedCategories.filter(
      (category) => !excludedCategorySet[category.urlName]
    );

    const searchMatches = findStringMatches(filteredCategories, 'name', searchValue);
    const parentCategories = searchMatches.filter((category) => !category.parentID);

    searchMatches.forEach((category) => {
      if (category.name === Uncategorized.name) {
        return;
      }

      const isParent = !category.parentID;
      if (isParent) {
        // if search match is parent add all subcategories to search result
        category.subCategories = sortedCategories.filter(
          (sortedCategory) => sortedCategory.parentID === category._id
        );
        return;
      }

      const parentCategory = categories.find((parent) => parent._id === category.parentID);
      const isParentIncluded = !!parentCategories.find(
        (parent) => parent._id === parentCategory._id
      );
      const isSubIncluded = !!(parentCategory?.subCategories ?? []).find(
        (subCategory) => subCategory._id === category._id
      );

      if (isParentIncluded && isSubIncluded) {
        return;
      } else if (isParentIncluded && !isSubIncluded) {
        parentCategory.subCategories.push(category);
      } else if (!isParentIncluded) {
        parentCategory.subCategories = [category];
        parentCategories.push(parentCategory);
      }
    });

    const matches = [];

    parentCategories.forEach((parent) => {
      matches.push(parent, ...parent.subCategories);
    });

    if (matches.length === 0) {
      return (
        <div className="suggestions">
          <div className="noMatches">There are no matching&nbsp;categories</div>
        </div>
      );
    }

    const suggestions = matches.map((category) => {
      const classNames = classnames({
        subSuggestion: !!category.parentID,
        suggestion: true,
      });
      const categoryKey = `${category.boardID}_${category.parentID}_${category.urlName}`;
      return (
        <Tappable key={categoryKey} onTap={this.onCategorySelected.bind(this, category)}>
          <div className={classNames}>{emphasizeMatches(category.name, searchValue)}</div>
        </Tappable>
      );
    });

    return <div className="suggestions">{suggestions}</div>;
  }

  render() {
    const { isFloating } = this.props;
    return (
      <div className={classnames('categorySelector', { floatingMenu: isFloating })}>
        {this.renderInput()}
      </div>
    );
  }
}

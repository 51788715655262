import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import withContexts from 'common/util/withContexts';

import Link from '../Link';

class PostLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    getPostLink: PropTypes.func,
    linkToAdmin: PropTypes.bool,
    onTap: PropTypes.func,
    post: PropTypes.shape({
      board: PropTypes.shape({
        urlName: PropTypes.string.isRequired,
      }),
      title: PropTypes.string,
      urlName: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    linkToAdmin: false,
  };

  render() {
    var className = 'postLink';
    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    var children = this.props.post.title;
    if (this.props.children) {
      children = this.props.children;
    }

    const { getPostLink, post } = this.props;
    if (!getPostLink) {
      throw new Error('Missing getPostLink from context');
    }
    const href = getPostLink(post);

    return (
      <Link className={className} to={href} onTap={this.props.onTap} target="_blank">
        {children}
      </Link>
    );
  }
}

export default withContexts({ getPostLink: GetPostLinkContext })(PostLink);

import { type ExtensionTypes, MimeTypes } from 'common/constants/files';

import type { Entries } from 'common/types/util';

const getExtensionFromContentType = (contentType: string): ExtensionTypes | null => {
  const mimeType = contentType.toLowerCase();
  const mimeTypeEntries = Object.entries(MimeTypes) as Entries<typeof MimeTypes>;
  const result = mimeTypeEntries.find(([, mimeTypes]) => {
    return (mimeTypes as readonly string[]).includes(mimeType);
  });

  return result ? (result[0] as ExtensionTypes) : null;
};

export default getExtensionFromContentType;

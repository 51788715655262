import { useEffect, useRef } from 'react';

import delayer from 'common/util/delayer';

/**
 * `useDelayer` is a custom React hook designed to delay the execution of a specified method. It leverages
 * the `delayer` functionality, which acts as a wrapper around `setTimeout` with added cancel capabilities.
 *
 * This hook is especially beneficial when the method being delayed relies on external factors
 * that may be subject to change, such as query parameters. By using this hook, you can ensure that
 * method executions are current and in sync with those external changes.
 *
 * @param {Function} methodToDelay - The method intended for delayed execution. The type is set to
 * `unknown` to accommodate functions with various signatures.
 * @param {number} [delay=500] - The duration (in milliseconds) for which the method's execution will be delayed.
 * If not specified, the default delay is set to 500ms.
 *
 * @returns {Function} A function that, when invoked, cancels any ongoing delay and schedules the new
 * method execution after the specified delay.
 */
const useDelayer = (methodToDelay: unknown, delay = 500) => {
  const delayerRef = useRef(new delayer(methodToDelay, delay));

  useEffect(() => {
    return () => {
      if (delayerRef.current) {
        delayerRef.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (delayerRef.current) {
      delayerRef.current.cancel();
      delayerRef.current = new delayer(methodToDelay, delay);
    }

    return () => {
      if (delayerRef.current) {
        delayerRef.current.cancel();
      }
    };
  }, [methodToDelay, delay]);

  return (...args: unknown[]) => {
    delayerRef.current.cancel();
    delayerRef.current.callAfterDelay(...args);
    return;
  };
};

export default useDelayer;

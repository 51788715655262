import React, { useContext, useEffect, useRef, useState } from 'react';

import { Trash } from 'lucide-react';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import ModernModal from 'common/modals/ModernModal';
import UpsellModal from 'common/modals/UpsellModal';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import UserAvatar from 'common/user/UserAvatar';
import { GrowthPlanID } from 'common/util/isGrowth';
import isNil from 'common/util/isNil';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInviteEmails from 'common/util/validateInviteEmails';
import styles from 'css-module/components/subdomain/admin/AdminRoadmap/AdminRoadmapHeader/_AdminRoadmapInviteModal.module.scss';

import type { Invite, Roadmap } from 'common/api/endpoints/roadmaps';
import type { Option } from 'common/ui/common/select/SelectCommon';

type Props = {
  onClose: () => void;
  reloadRoadmaps: () => void;
  roadmap: Roadmap | null;
  viewID?: string | null;
  selectedView?: Option;
};

const AdminRoadmapInviteModal = ({ onClose, roadmap, reloadRoadmaps, viewID }: Props) => {
  const [loading, setLoading] = useState(false);
  const [newEmails, setNewEmails] = useState('');
  const [acceptedInvites, setAcceptedInvites] = useState<Invite[]>([]);
  const [pendingInvites, setPendingInvites] = useState<Invite[]>([]);
  const emailInput = useRef<AutoResizeTextarea | null>(null);
  const showToast = useContext(ShowToastContext);
  const company = useContext(CompanyContext);

  const handleAPIResponse = (response: string, callback: () => void) => {
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': 'You are not authorized to invite users to this roadmap',
        'invalid input': "This roadmap doesn't exist anymore. Refresh the page and try again.",
      },
    });

    if (error) {
      setLoading(false);
      showToast(error.message, ToastTypes.error);
      return;
    }

    return callback();
  };

  useEffect(() => {
    if (!roadmap || !viewID) {
      return;
    }
    const invites = roadmap.invites ?? [];
    const relevantInvites = invites.filter(
      ({ roadmapSavedSettingsViewID }) => roadmapSavedSettingsViewID === viewID
    );
    const pendingInvites = relevantInvites.filter(({ name }) => isNil(name));
    const acceptedInvites = relevantInvites.filter(({ name }) => !isNil(name));
    setPendingInvites(pendingInvites);
    setAcceptedInvites(acceptedInvites);
  }, [roadmap, viewID]);

  if (!company.features.shareRoadmaps) {
    return (
      <UpsellModal
        cta="Share a Roadmap View"
        feature="roadmaps.sharing"
        onClose={onClose}
        onUpsell={onClose}
        show
        planID={GrowthPlanID}></UpsellModal>
    );
  }

  const handleSubmit = async () => {
    if (!roadmap || !viewID || loading) {
      return;
    }

    setLoading(true);
    const { validEmails, invalidEmails } = validateInviteEmails(newEmails);

    if (invalidEmails.length > 0) {
      showToast('The following emails are invalid: ' + invalidEmails.join(', '), ToastTypes.error);
      setLoading(false);
      return;
    }

    const response = await AJAX.post('/api/roadmaps/sendInvites', {
      roadmapID: roadmap._id,
      roadmapSavedSettingsViewID: viewID,
      emails: validEmails,
    });

    handleAPIResponse(response, async () => {
      showToast('Invites sent', ToastTypes.success);
      await reloadRoadmaps();
      setLoading(false);
      setNewEmails('');
    });
  };

  const handleRevokeEmail = async (email: string) => {
    if (!roadmap || !viewID) {
      return;
    }

    setLoading(true);
    const response = await AJAX.post('/api/roadmaps/revokeAccess', {
      roadmapID: roadmap._id,
      roadmapSavedSettingsViewID: viewID,
      email: email,
    });

    handleAPIResponse(response, async () => {
      showToast('Invite removed', ToastTypes.success);
      await reloadRoadmaps();
      setLoading(false);
    });
  };

  return (
    <ModernModal
      modalClassName={styles.modalContent}
      overlayClassName={styles.adminRoadmapInviteModal}
      header="Share Roadmap View"
      sections={[
        <>
          <label htmlFor="emails" className={styles.label}>
            <P variant="bodyMd" fontWeight="medium">
              Email
            </P>
            <P variant="bodyMd" fontWeight="regular" className={styles.sublabel}>
              Share a read-only version of this roadmap view
            </P>
          </label>
          <AutoResizeTextarea
            id="emails"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNewEmails(e.target.value)}
            ref={emailInput}
            placeholder="jane@netflix.com, tim@youtube.com"
            value={newEmails}
          />
        </>,
        ...[
          pendingInvites.length > 0 ? (
            <>
              <P variant="bodyMd" fontWeight="medium" className={styles.label}>
                Pending Invitations
              </P>
              <ul className={styles.currentInviteContainer}>
                {pendingInvites.map((invite) => {
                  return (
                    <li key={invite._id} className={styles.currentInviteItem}>
                      <div className={styles.currentInviteLeftHandSide}>
                        {invite.avatarURL ? <UserAvatar user={invite} /> : null}
                        <P>
                          {invite.email} {invite.name ? `(${invite.name})` : null}
                        </P>
                      </div>
                      <IconButtonV2
                        icon={Trash}
                        aria-label={`Revoke access from ${invite.email}`}
                        size="small"
                        onClick={() => handleRevokeEmail(invite.email)}
                        className={styles.revokeButton}
                      />
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null,
        ],
        ...[
          acceptedInvites.length > 0 ? (
            <>
              <P variant="bodyMd" fontWeight="medium" className={styles.label}>
                Accepted Invitations
              </P>
              <ul className={styles.currentInviteContainer}>
                {acceptedInvites.map((invite) => {
                  return (
                    <li key={invite._id} className={styles.currentInviteItem}>
                      <div className={styles.currentInviteLeftHandSide}>
                        {invite.avatarURL ? <UserAvatar user={invite} /> : null}
                        <P>
                          {invite.email} {invite.name ? `(${invite.name})` : null}
                        </P>
                      </div>
                      <IconButtonV2
                        icon={Trash}
                        aria-label={`Revoke access from ${invite.email}`}
                        size="small"
                        onClick={() => handleRevokeEmail(invite.email)}
                        className={styles.revokeButton}
                      />
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null,
        ],
      ].filter((s) => s)}
      onClose={onClose}
      footer={
        <>
          <ButtonV2 onClick={onClose} variant="outlined" size="medium">
            Cancel
          </ButtonV2>
          <ButtonV2
            onClick={handleSubmit}
            size="medium"
            loading={loading}
            disabled={newEmails.length === 0}>
            Invite
          </ButtonV2>
        </>
      }
    />
  );
};

export default AdminRoadmapInviteModal;

import React, { Component } from 'react';

import { X } from 'lucide-react';
import PropTypes from 'prop-types';

import Tooltip from 'common/common/Tooltip';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import EditPostForm from 'common/post/EditPostForm';
import MergePostMenu from 'common/post/MergePostMenu';
import Post from 'common/post/Post';
import PostHeader from 'common/post/PostHeader';
import IconButtonV2 from 'common/ui/IconButtonV2';
import withContexts from 'common/util/withContexts';

import AdminFeedbackCommentComposer from './AdminFeedbackCommentComposer';
import AdminFeedbackPostDetails from './AdminFeedbackPostDetails';

import 'css/components/subdomain/admin/_AdminFeedbackPost.scss';

class AdminFeedbackPost extends Component {
  static propTypes = {
    board: PropTypes.object,
    location: PropTypes.object,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onEdited: PropTypes.func,
    post: PropTypes.object,
    postActivity: PropTypes.object,
    roadmaps: PropTypes.array,
    router: PropTypes.object,
    showToast: PropTypes.func,
    skipFixURL: PropTypes.bool,
  };

  static defaultProps = {
    onEdited: () => null,
    skipFixURL: false,
  };

  state = {
    isEditing: false,
    hideTranslation: false,
  };

  constructor(props, context) {
    super(props, context);

    this.postRef = React.createRef();
    this.scrollContainerRef = React.createRef();
  }

  componentDidMount() {
    this.scrollContainerRef.current.addEventListener('scroll', this.onScroll, false);
    this.onScroll();

    // setTimeout required to wait for AdminFeedbackSidebar to run applySavedFilter
    setTimeout(() => {
      this.fixURL();
    }, 0);
  }

  componentWillUnmount() {
    this.scrollContainerRef.current.removeEventListener('scroll', this.onScroll);
  }

  fixURL() {
    const { board, boards, params, post, router, skipFixURL, location } = this.props;
    if (skipFixURL || !post?._id || (!board?._id && !board?.requestedBoardID)) {
      return;
    }

    const actualPostBoard = boards.find((board) => {
      return board._id === post.boardID;
    });
    if (!actualPostBoard) {
      return;
    }

    const boardURLNeedsUpdate = actualPostBoard.urlName !== params.boardURLName;
    const postURLNeedsUpdate = post.urlName !== params.postURLName;

    if (!boardURLNeedsUpdate && !postURLNeedsUpdate) {
      return;
    }

    router.replace({
      pathname: `/admin/feedback/${actualPostBoard.urlName}/p/${post.urlName}`,
      query: location.query,
    });
  }

  onCancelEdit = () => {
    this.setState({ isEditing: false });
  };

  onEdit = () => {
    this.setState({ isEditing: true });
  };

  onMarkedSpam = () => {
    const { location, onDelete, router, showToast } = this.props;
    if (onDelete) {
      onDelete();
    } else {
      router.replace({
        pathname: '/admin/feedback',
        query: location.query,
      });
    }
    showToast('The post has been marked as spam');
  };

  onPostDeleted = () => {
    const { location, onDelete, router, showToast } = this.props;
    if (onDelete) {
      onDelete();
    } else {
      router.replace({
        pathname: '/admin/feedback',
        query: location.query,
      });
    }
    showToast('The post has been deleted');
  };

  onPostEdited = (post) => {
    const { onEdited } = this.props;
    this.setState({ isEditing: false }, () => onEdited(post));
  };

  onPostMerged = (intoPost, toBoard) => {
    const { location, router, showToast } = this.props;
    router.replace({
      pathname: `/admin/feedback/${toBoard.urlName}/p/${intoPost.urlName}`,
      query: location.query,
    });
    showToast(`Post merged — votes may take time to update`);
  };

  onScroll = (e) => {
    const post = this.postRef.current;
    if (!post) {
      return;
    }

    const scrollContainer = this.scrollContainerRef.current;
    this.postRef.current.onScroll(e, scrollContainer);
  };

  onToggleTranslation = (hideTranslation) => {
    this.setState({
      hideTranslation,
    });
  };

  renderContents() {
    const { board, post, postActivity } = this.props;
    if (!post || post.loading || post.error) {
      return null;
    } else if (post.notFound) {
      return <div className="notFound">There is no such post.</div>;
    }

    if (this.state.isEditing) {
      return (
        <div className="editPost">
          <div className="heading">Edit Post</div>
          <EditPostForm
            board={board}
            onCancel={this.onCancelEdit}
            onPostEdited={this.onPostEdited}
            key={post._id}
            post={post}
            showCustomPostFields={true}
          />
        </div>
      );
    }

    return (
      <Post
        allowUnmerge={true}
        board={board}
        key={post._id}
        onEdit={this.onEdit}
        onMarkedSpam={this.onMarkedSpam}
        onPostDeleted={this.onPostDeleted}
        onToggleTranslation={this.onToggleTranslation}
        post={post}
        postActivity={postActivity}
        ref={this.postRef}
        showCommentComposer={false}
        showCustomPostFields={true}
        showHeader={false}
        showStatus={false}
        showSummary={true}
        viewType="admin"
      />
    );
  }

  renderHeader() {
    const { board, boards, onClose, post } = this.props;
    if (!board || !post || post.error || post.notFound) {
      return null;
    }

    const { hideTranslation } = this.state;
    return (
      <div className="mainHeader">
        <PostHeader
          hideTranslation={hideTranslation}
          key={post._id}
          post={post}
          showStatus={false}
          v1
        />
        <div className="headerActions">
          <Tooltip position="left" value="Merge post">
            <MergePostMenu
              board={board}
              boards={boards}
              post={post}
              onPostMerged={this.onPostMerged}
            />
          </Tooltip>
          {onClose && (
            <IconButtonV2
              size="large"
              icon={X}
              variant="plain"
              aria-label="Close button"
              onClick={onClose}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    const { board, customPostFields, post, roadmaps } = this.props;

    return (
      <div className="adminFeedbackPost">
        <div className="adminFeedbackPostOuter">
          {this.renderHeader()}
          <div className="adminFeedbackPostBottom">
            <div className="adminFeedbackPostMain">
              <div className="adminFeedbackPostContainer" ref={this.scrollContainerRef}>
                {this.renderContents()}
              </div>
              <div className="commentComposerContainer">
                <AdminFeedbackCommentComposer board={board} post={post} />
              </div>
            </div>
            <AdminFeedbackPostDetails
              board={board}
              boards={this.props.boards}
              customPostFields={customPostFields}
              roadmaps={roadmaps}
              key={post ? post._id : 'no post'}
              post={post}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  params: ParamsContext,
  router: RouterContext,
  showToast: ShowToastContext,
})(AdminFeedbackPost);

import React, { Component } from 'react';

import classnames from 'classnames';
import { BarChart2, DollarSign, MessageSquare, Triangle } from 'lucide-react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Truncate from 'common/common/Truncate';
import { convertMRRToTimeframe } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import plaintext from 'common/markdown/plaintext';
import PostStatus from 'common/post/PostStatus';
import PostTitle from 'common/post/PostTitle';
import UppercaseHeader from 'common/UppercaseHeader';
import abbreviateNumber from 'common/util/abbreviateNumber';
import { isElementCompletelyOnScreen } from 'common/util/isElementOnScreen';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminFeedbackPostListItem.scss';

const PreventKeys = ['metaKey', 'shiftKey', 'ctrlKey'];

class AdminFeedbackPostListItem extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.shape({
      hasCollectedMonthlySpend: PropTypes.bool,
    }),
    capTitleLength: PropTypes.bool,
    highlighted: PropTypes.bool,
    linkToAdmin: PropTypes.bool,
    location: PropTypes.object,
    params: PropTypes.object,
    post: PropTypes.shape({
      board: PropTypes.object,
      details: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    redirectOnSelect: PropTypes.bool,
    router: PropTypes.object,
    scrollContainerRef: PropTypes.object,
    selectedPost: PropTypes.object,
    showBoard: PropTypes.bool.isRequired,
    showComments: PropTypes.bool.isRequired,
    showDetails: PropTypes.bool.isRequired,
    showSelection: PropTypes.bool.isRequired,
    showStatus: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    linkToAdmin: false,
    onPostSelected: () => null,
    redirectOnSelect: true,
    showBoard: false,
    showComments: true,
    showDetails: true,
    showSelection: true,
    showStatus: false,
  };

  state = {
    isSelected: false,
  };

  componentDidMount() {
    const { params, post } = this.props;
    const isSelected =
      params.postURLName &&
      params.postURLName === post.urlName &&
      params.boardURLName &&
      params.boardURLName === post.board.urlName;

    this.itemRef = React.createRef();
    this.setState({ isSelected });
  }

  componentDidUpdate(prevProps, prevState) {
    const { params, post, selectedPost } = this.props;

    if (prevProps.params !== params) {
      const isSelected =
        params.postURLName &&
        params.postURLName === post.urlName &&
        params.boardURLName &&
        params.boardURLName === post.board.urlName;

      this.setState({ isSelected });
    } else if (prevProps.selectedPost !== selectedPost) {
      const isSelected =
        selectedPost &&
        selectedPost.postURLName === post.urlName &&
        selectedPost.boardURLName === post.board.urlName;

      this.setState({ isSelected });
    } else if (!prevState.isSelected && this.state.isSelected) {
      this.scrollIntoView();
    }
  }

  onPostSelected = (e) => {
    const { location, router } = this.props;
    const { onPostSelected, post, redirectOnSelect } = this.props;

    if (redirectOnSelect) {
      router.push({
        pathname: `/admin/feedback/${post.board.urlName}/p/${post.urlName}`,
        query: location.query,
      });
    }

    onPostSelected(e, post);
  };

  scrollIntoView() {
    const parent = this.props.scrollContainerRef?.current;
    if (!parent) {
      return;
    }
    const element = findDOMNode(this.itemRef.current);

    const { isVisible, above } = isElementCompletelyOnScreen(element, parent);

    if (!isVisible) {
      element.scrollIntoView(above);
    }
  }

  renderBoard() {
    const { showBoard } = this.props;
    if (!showBoard) {
      return null;
    }

    const { board } = this.props.post;
    if (!board) {
      return null;
    }

    return <UppercaseHeader className="boardName">{board.name}</UppercaseHeader>;
  }

  renderCommentCount() {
    const { post, showComments } = this.props;
    if (!showComments) {
      return null;
    }

    return (
      <div className="commentCount" key="commentCount">
        <MessageSquare size={14} className="icon" />
        <div className="count">{post.commentCount + post.internalCommentCount}</div>
      </div>
    );
  }

  renderCounts() {
    const {
      location: {
        query: { sort },
      },
    } = this.props;

    const counts = [this.renderMoney(), this.renderVotes(), this.renderCommentCount()];

    if (sort !== 'mrr' && sort !== 'opportunity') {
      // Show MRR last if not sorting by MRR
      counts.push(counts.shift());
    }

    return <div className="counts">{counts}</div>;
  }

  renderDetails() {
    const { showDetails } = this.props;
    if (!showDetails) {
      return null;
    }

    const { post } = this.props;
    if (!post.details) {
      return null;
    }

    return (
      <div className="postDetails">
        <Truncate numberOfLines={2}>{plaintext(translateString(post, 'details'))}</Truncate>
      </div>
    );
  }

  renderMoney() {
    const {
      company,
      location: { query },
      post,
    } = this.props;

    if (query.sort === 'opportunity') {
      const moneyString = abbreviateNumber(post.totalOpportunityValue || 0);
      return (
        <div className="totalOpportunityValue" key="opportunity">
          <BarChart2 size={14} className="opportunityLines" />
          <DollarSign size={14} className="dollarSymbol" />
          <div className="count">{moneyString}</div>
        </div>
      );
    }

    // Don't show MRR if company has none
    if (!company.hasCollectedMonthlySpend) {
      return null;
    }

    // Don't count segment in query if segment does not exist
    const existingSegment = company.segments.find(({ urlName }) => urlName === query.segment);
    const areVotesFiltered = query['vote-created'] || existingSegment;
    const totalMRR = areVotesFiltered ? post.filteredTotalMRR : post.totalMRR;
    const totalSpend = abbreviateNumber(convertMRRToTimeframe(totalMRR, company.revenueTimeframe));

    return (
      <div className="mrrValue" key="mrr">
        <DollarSign size={14} className="dollarSymbol" />
        <div className="count">{totalSpend}</div>
      </div>
    );
  }

  renderStatus() {
    const {
      showStatus,
      post: { status },
    } = this.props;
    if (!showStatus) {
      return null;
    }

    return <PostStatus status={status} />;
  }

  renderVotes() {
    const {
      company,
      location: { query },
    } = this.props;
    const { post } = this.props;

    // Don't count segment in query if segment does not exist
    const existingSegment = company.segments.find(({ urlName }) => urlName === query.segment);
    const areVotesFiltered = query['vote-created'] || existingSegment;
    const score =
      areVotesFiltered && typeof post.filteredScore !== 'undefined'
        ? post.filteredScore
        : post.score;
    return (
      <div className="votes" key="votes">
        <Triangle size={14} className="upvote" />
        <div className="score">{abbreviateNumber(score)}</div>
      </div>
    );
  }

  render() {
    const { highlighted, location, post, showSelection, capTitleLength } = this.props;
    const { isSelected } = this.state;
    const selected = showSelection && isSelected;
    const className = classnames('adminFeedbackPostListItem', {
      selected,
      highlighted: typeof highlighted === 'boolean' ? highlighted : selected,
    });
    const pathname = `/admin/feedback/${post.board.urlName}/p/${post.urlName}`;
    const query = location.query;
    const to = pathname + queryString.stringify(query);
    return (
      <Link
        fakeLink={true}
        onTap={this.onPostSelected}
        preventDefaultEventKeys={PreventKeys}
        to={to}>
        <div className={className} ref={this.itemRef}>
          {capTitleLength ? (
            <Truncate numberOfLines={3}>
              <PostTitle post={post} />
            </Truncate>
          ) : (
            <PostTitle post={post} />
          )}
          {this.renderDetails()}
          <div className="postInfo">
            {this.renderCounts()}
            {this.renderStatus()}
          </div>
        </div>
      </Link>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  params: ParamsContext,
  router: RouterContext,
})(AdminFeedbackPostListItem);

import React, { useRef, useState } from 'react';

import classnames from 'classnames';

import Pill from 'common/common/Pill';
import Toggle from 'common/common/Toggle';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import ActionMenu from 'common/subdomain/admin/ActionMenu';
import Timestamp from 'common/Timestamp';
import UserLockup from 'common/user/UserLockup';

import type { Automation } from 'common/api/endpoints/automation';
import type Portal from 'common/common/Portal';

import 'css/components/subdomain/admin/AdminAutomationSettings/_AutomationRule.scss';

type Props = {
  appliedCount: number;
  className?: string;
  createdDate: Date;
  creator: Automation['createdBy'];
  enabled: boolean;
  onDelete: () => void;
  onEdit: () => void;
  onToggle: () => void;
  ruleName: string;
};

const AutomationRule = ({
  appliedCount,
  className,
  createdDate,
  creator,
  enabled,
  onDelete,
  onToggle,
  onEdit,
  ruleName,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);
  const menuPortalRef = useRef<Portal>(null);

  useBackgroundClick(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [menuRef, menuPortalRef]);

  const onEditClick = () => {
    setMenuOpen(false);
    onEdit();
  };

  const onDeleteClick = () => {
    setMenuOpen(false);
    onDelete();
  };

  return (
    <>
      <div className={classnames('automationRule', className)}>
        <div className="left">
          <div className="topRow">
            <span className="ruleName">{ruleName}</span>
            <Pill>
              {appliedCount} {appliedCount === 1 ? 'Post' : 'Posts'}
            </Pill>
          </div>
          <div className="bottomRow">
            Created{' '}
            <Timestamp
              textOptions={{ lowerCaseRelative: true }}
              className="timestamp"
              timestamp={createdDate}
            />{' '}
            by <UserLockup user={creator} />
          </div>
        </div>
        <div className="right">
          <Toggle small onToggle={onToggle} value={enabled} />

          <button
            className="unstyledButton menu"
            onClick={() => setMenuOpen(!menuOpen)}
            ref={menuRef}>
            <div className="icon-dots" />
          </button>
        </div>
      </div>
      {menuOpen && (
        <ActionMenu
          align="end"
          ref={menuPortalRef}
          relativeToRef={menuRef}
          options={[
            {
              action: onEditClick,
              label: 'Edit',
            },
            {
              action: onDeleteClick,
              destructive: true,
              label: 'Delete',
            },
          ]}
        />
      )}
    </>
  );
};

export default AutomationRule;

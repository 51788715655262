import React from 'react';

import classnames from 'classnames';

import { CustomFieldTypes } from 'common/customPostFields/Constants';
import translateString from 'common/i18n/translateString';
import MultipleSelect from 'common/ui/MultipleSelect';
import SingleSelect from 'common/ui/SingleSelect';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import { P } from 'common/ui/Text';

import type { FormErrors, PostForm } from './utils';
import type { Board } from 'common/api/endpoints/boards';
import type { BoardField } from 'common/api/resources/postFields';
import type { Option } from 'common/ui/common/select/SelectCommon';

type Props = {
  board: Board;
  onFormChange: (edits: Partial<PostForm>) => void;
  form: PostForm;
  errors: FormErrors;
};

const UncategorizedCategory = {
  label: 'Uncategorized',
  value: '',
  render: <div className={classnames('categoryOption')}>Uncategorized</div>,
};

const toOption = (value?: string | string[]): Option | undefined => {
  if (!value || typeof value !== 'string') {
    return undefined;
  }

  return {
    label: value,
    value,
  };
};

const toOptions = (values?: string | string[]): Array<Option> => {
  if (!Array.isArray(values)) {
    return [];
  }

  return values.map((value) => ({
    label: value,
    value,
  }));
};

const PostFieldDropdowns = ({ board, onFormChange, form, errors }: Props) => {
  const categoryOptions = board.categories
    .map((category) => ({
      label: category.name,
      value: category._id,
      render: (
        <div className={classnames('categoryOption', { subcategory: !!category.parentID })}>
          {translateString(category, 'name')}
        </div>
      ),
    }))
    .concat(UncategorizedCategory);
  const selectedCategory = categoryOptions.find((option) => option.value === form.categoryID);

  const boardFieldsWithOptions = board.boardFields
    .filter((boardField) => CustomFieldTypes[boardField.type]?.hasOptions)
    .sort((a, b) => a.order - b.order);

  const renderCategoriesSelect = () => {
    if (board.categories.length <= 5) {
      return (
        <SingleSelect
          className="dropdown createPostFormV2CategorySelect"
          optionsMaxWidth="200px"
          onChange={(option) => onFormChange({ categoryID: option?.value })}
          placeholder="Select a category"
          value={selectedCategory}
          options={categoryOptions}
        />
      );
    }

    return (
      <SingleSelectWithSearch
        className="dropdown createPostFormV2CategorySelect"
        optionsMaxWidth="200px"
        onChange={(option) => onFormChange({ categoryID: option?.value })}
        placeholder="Category"
        value={selectedCategory}
        options={categoryOptions}
      />
    );
  };

  const renderPostFieldDropdown = (boardField: BoardField) => {
    if (boardField.type !== CustomFieldTypes.dropdown.name || !boardField.options) {
      return null;
    }

    if (boardField.options.length <= 5) {
      return (
        <SingleSelect
          required={boardField.required}
          className="dropdown"
          optionsMaxWidth="200px"
          key={boardField._id}
          error={errors.customFieldValuesMap?.[boardField.customPostFieldID]}
          onChange={(option) =>
            option &&
            onFormChange({
              customFieldValuesMap: {
                ...form.customFieldValuesMap,
                [boardField.customPostFieldID]: option.value,
              },
            })
          }
          placeholder={boardField.placeholder}
          value={toOption(form.customFieldValuesMap[boardField.customPostFieldID])}
          options={toOptions(boardField.options)}
        />
      );
    }

    return (
      <SingleSelectWithSearch
        className="dropdown"
        required={boardField.required}
        optionsMaxWidth="200px"
        key={boardField._id}
        error={errors.customFieldValuesMap?.[boardField.customPostFieldID]}
        onChange={(option) =>
          option &&
          onFormChange({
            customFieldValuesMap: {
              ...form.customFieldValuesMap,
              [boardField.customPostFieldID]: option.value,
            },
          })
        }
        placeholder={boardField.placeholder}
        value={toOption(form.customFieldValuesMap[boardField.customPostFieldID])}
        options={toOptions(boardField.options)}
      />
    );
  };

  return (
    <div className="dropdowns">
      {board.categories.length > 0 ? (
        <div className="dropdownContainer">
          <P fontWeight="medium">Category</P>
          {renderCategoriesSelect()}
        </div>
      ) : null}
      {boardFieldsWithOptions.map((boardField) =>
        boardField.type === CustomFieldTypes.dropdown.name ? (
          <div
            key={boardField._id}
            className={classnames('dropdownContainer', 'postField', {
              required: boardField.required,
            })}>
            <P fontWeight="medium" className="label">
              {boardField.label}
            </P>
            {renderPostFieldDropdown(boardField)}
          </div>
        ) : (
          <div
            key={boardField._id}
            className={classnames('dropdownContainer', 'postField', {
              required: boardField.required,
            })}>
            <P fontWeight="medium" className="label">
              {boardField.label}
            </P>
            <MultipleSelect
              className="dropdown"
              optionsMaxWidth="200px"
              required={boardField.required}
              hideSelection
              error={errors.customFieldValuesMap?.[boardField.customPostFieldID]}
              onChange={(options) =>
                onFormChange({
                  customFieldValuesMap: {
                    ...form.customFieldValuesMap,
                    [boardField.customPostFieldID]: options.map((option) => option.value),
                  },
                })
              }
              value={toOptions(form.customFieldValuesMap[boardField.customPostFieldID])}
              placeholder={boardField.placeholder}
              options={toOptions(boardField.options)}
            />
          </div>
        )
      )}
    </div>
  );
};

export default PostFieldDropdowns;

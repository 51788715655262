import { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { ParamsContext } from 'common/containers/RouterContainer';
import withContexts from 'common/util/withContexts';

import { loadEntry } from '../actions/changelogEntries';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

const asyncFetch = {
  promise: ({ store: { dispatch }, params }) => {
    if (!params.entryURLName) {
      return;
    }
    return dispatch(loadEntry(params.entryURLName));
  },
};

class ChangelogEntryContainer extends Component {
  static propTypes = {
    params: PropTypes.object,
  };

  render() {
    const newProps = { ...this.props };
    const { children, changelogEntries, params } = newProps;
    const entryURLName = params.entryURLName;

    delete newProps.params;
    newProps.entry = changelogEntries[entryURLName];
    return cloneElementWithProps(children, newProps);
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({ changelogEntries: state.changelogEntries })),
  withContexts(
    {
      params: ParamsContext,
    },
    {
      forwardRef: true,
    }
  )
)(ChangelogEntryContainer);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tappable from 'common/Tappable';

import 'css/components/inputs/_UnderlinedSelector.scss';

export default class UnderlinedSelector extends Component {
  state = {
    menuOpen: false,
    selected:
      this.props.options.find((option) => {
        return option.name === this.props.defaultOptionName;
      }) ||
      this.props.options[0] ||
      {},
  };

  static defaultProps = {
    options: [],
    disabled: false,
  };

  static propTypes = {
    defaultOptionName: PropTypes.string,
    onToggleMenu: PropTypes.func,
    onChooseOption: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        render: PropTypes.node.isRequired, // renderable
      })
    ),
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  onDocumentClick = (e) => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return;
    }

    const container = this.containerRef.current;
    if (!container?.contains(e.target)) {
      // touchend happens outside of menu, close menu
      this.setState({ menuOpen: false });
      return;
    }
  };

  onToggleMenu = () => {
    const { onToggleMenu } = this.props;
    this.setState(
      (state) => ({ menuOpen: !state.menuOpen }),
      () => {
        onToggleMenu?.(this.state.menuOpen);
      }
    );
  };

  onChooseOption = (name) => {
    const { onChooseOption, options = [] } = this.props;
    const selectedOption = options.find((option) => option.name === name);

    this.setState({ selected: selectedOption, menuOpen: false }, () => {
      onChooseOption?.(selectedOption);
    });
  };

  renderDropdown = () => {
    const { menuOpen } = this.state;
    const { options } = this.props;
    const selected = this.getSelectedOption();

    if (!menuOpen) {
      return null;
    }

    const items = options.map((option) => {
      return (
        <Tappable
          key={option.name}
          onTap={() => this.onChooseOption(option.name)}
          stopPropagation={true}>
          <div
            className={classnames({
              option: true,
              selected: selected.name === option.name,
            })}>
            {option.render}
          </div>
        </Tappable>
      );
    });
    return (
      <div className="dropdown">
        <div className="items">{items}</div>
      </div>
    );
  };

  getSelectedOption = () => {
    const { selected: selectedProp } = this.props;
    const { selected: selectedState } = this.state;

    return selectedProp ?? selectedState;
  };

  render() {
    const { disabled, options } = this.props;
    const selected = this.getSelectedOption();

    if (disabled || options.length === 1) {
      return selected.render;
    }

    return (
      <div className="underlinedSelector" ref={this.containerRef}>
        <Tappable onTap={this.onToggleMenu}>
          <div className="selector">
            <div className="selectedName">{selected.render}</div>
            <div className="icon-chevron-down" />
            {this.renderDropdown()}
          </div>
        </Tappable>
      </div>
    );
  }
}

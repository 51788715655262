import { Component } from 'react';

import PropTypes from 'prop-types';

import cloneElementWithProps from 'common/core/cloneElementWithProps';

export default class Draggable extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    placeholder: PropTypes.node,
    value: PropTypes.string,
  };

  state = {
    dragging: false,
  };

  onDragStart = (e, value) => {
    e.dataTransfer.setData('text/plain', value);
    // add timeout for the "dragged" image to be rendered correctly.
    setTimeout(() => {
      this.setState({ dragging: true });
      this.props.onDragStart?.(value);
    }, 0);
  };

  onDragEnd = () => {
    this.setState({ dragging: false });
    this.props.onDragEnd?.();
  };

  render() {
    const { children, disabled, placeholder, value } = this.props;
    const { dragging } = this.state;

    if (dragging && placeholder) {
      return cloneElementWithProps(placeholder, { onDragEnd: this.onDragEnd });
    }

    return cloneElementWithProps(children, {
      draggable: !disabled,
      onDragEnd: this.onDragEnd,
      onDragStart: (e) => this.onDragStart(e, value),
    });
  }
}

import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadLinearSettings } from 'common/actions/linearSettings';
import AJAX from 'common/AJAX';
import InformationBox from 'common/common/InformationBox';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';

import Rules from './Rules';

import 'css/components/subdomain/admin/_AdminLinearSettings.scss';

// For more information, visit: https://developers.linear.app/docs/oauth/authentication#2.-redirect-user-access-requests-to-linear
const Linear = 'linear';
const LinearParams = {
  actor: 'application',
  client_id: publicConfig('linearClientID'),
  redirect_uri: devURL('https://canny.io/linear-redirect'),
  response_type: 'code',
  scope: 'read,write',
};
const LinearURL = 'https://linear.app/oauth/authorize';

const AdminLinearSettings = (props) => {
  // props
  const { linearSettings, reloadLinearSettings } = props;

  // context
  const activeIntegrations = useContext(ActiveIntegrationContext);
  const company = useContext(CompanyContext);

  // state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // effects
  useEffect(() => {
    const { code, integration } = props.location.query;
    if (!code) {
      return;
    }

    const { location, router } = props;
    router.replace({
      pathname: location.pathname,
    });

    if (integration === Linear) {
      install(code);
    }
    // This probably isn't correct, re-enable this later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // helpers
  const install = async (code) => {
    setError(null);
    setLoading(true);

    const response = await AJAX.post('/api/linear/install', { code });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setLoading(false);
      setError(error.message);
      return;
    }

    await reloadLinearSettings();
    setLoading(false);
  };

  const uninstall = async () => {
    setLoading(true);

    const response = await AJAX.post('/api/linear/uninstall');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setLoading(false);
      setError(error.message);
      return;
    }

    await reloadLinearSettings();
    setLoading(false);
  };

  // renderers
  const renderContents = () => {
    const installation = linearSettings?.installation;
    const hasInstalled = installation && (!!installation?.installed || installation?.lostAccess);
    const linearParams = {
      state: JSON.stringify({
        integration: Linear,
        subdomain: company.subdomain,
      }),
      ...LinearParams,
    };
    const linearURL = `${LinearURL}${queryString.stringify(linearParams)}`;

    if (!company?.integrations?.linear) {
      return <AdminFeatureUpsell feature="linear" />;
    } else if (loading) {
      return renderLoading();
    } else if (!hasInstalled) {
      return renderInstallButton(linearURL);
    }

    const { rules, states } = linearSettings;

    return (
      <div className="installation">
        {installation?.lostAccess ? (
          <>
            <InformationBox icon="!" className="withMarginBottom">
              Your Linear integration has lost access to Canny. Please reinstall or uninstall the
              integration.
            </InformationBox>
            <a href={linearURL} className="buttonContainer" rel="noreferrer noopener nofollow">
              <Button value="Reinstall Linear" />
            </a>
            <Tappable onTap={uninstall}>
              <div className="uninstall">Uninstall</div>
            </Tappable>
          </>
        ) : (
          <div className="status">
            <div className="text">Your Linear integration is installed.</div>
            <Tappable onTap={uninstall}>
              <div className="uninstall">Uninstall</div>
            </Tappable>
          </div>
        )}

        <Rules
          linearRules={rules}
          linearStatuses={states}
          onError={setError}
          onRuleCreated={reloadLinearSettings}
          onRuleDeleted={reloadLinearSettings}
        />
      </div>
    );
  };

  const renderInstallButton = (linearURL) => {
    const { integrationCount, integrationLimit } = activeIntegrations;
    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }
    return (
      <div>
        <a href={linearURL} className="buttonContainer" rel="noreferrer noopener nofollow">
          <Button value="Install Linear" />
        </a>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="spinnerContainer">
        <Spinner />
      </div>
    );
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  };

  return (
    <div className="adminLinearSettings">
      <Helmet title="Linear Integration | Canny" />
      <div className="settingsHeading">Linear Integration</div>
      <div className="content">
        <div className="text">
          Canny for Linear lets you link Canny posts with Linear issues and sync states from Linear
          to Canny. This helps your engineering and product management teams communicate priorities.
        </div>
        <div className="text">
          Check out&nbsp;
          <a
            className="articleLink"
            href="http://help.canny.io/en/articles/6300217-linear-integration"
            rel="noopener"
            target="_blank">
            this help article
          </a>
          &nbsp;to see how this integration works.
        </div>
        {renderContents()}
        {renderError()}
      </div>
    </div>
  );
};

AdminLinearSettings.propTypes = {
  company: PropTypes.shape({ _id: PropTypes.string }),
  linearSettings: PropTypes.shape({
    installation: PropTypes.object,
    rules: PropTypes.arrayOf(PropTypes.object),
    states: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default compose(
  connect(
    (state) => ({ linearSettings: state.linearSettings }),
    (dispatch) => ({
      reloadLinearSettings: () => dispatch(reloadLinearSettings()),
    })
  ),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.linear), '/admin/settings')
)(AdminLinearSettings);

export default function getExtension(urlString: string): string | null {
  if (!urlString || typeof urlString !== 'string') {
    return null;
  }

  // foobar
  const lastDot = urlString.lastIndexOf('.');
  if (lastDot === -1) {
    return null;
  }

  // foobar.jpg
  const questionMark = urlString.indexOf('?');
  if (questionMark === -1) {
    return urlString.substr(lastDot + 1).toLowerCase();
  }

  // foobar.jpg?foo=bar
  return urlString.substr(lastDot + 1, questionMark - lastDot - 1).toLowerCase();
}

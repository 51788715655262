import React, { useState } from 'react';

import { Search, Trash } from 'lucide-react';
import { compose } from 'redux';

import {
  type KnowledgeHubFile,
  Status as KnowledgeHubFileStatus,
} from 'common/api/endpoints/knowledgeHubFile';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import { isSupportedExtension } from 'common/constants/files';
import DataTable, { type Column, type Row as DataTableRow, useLocalSort } from 'common/DataTable';
import { ExtensionLabelMap, FileTypeLabelMap } from 'common/file/utils/fileLabels';
import TextInput from 'common/inputs/TextInput';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withAccessControl from 'common/routing/withAccessControl';
import EmptyState from 'common/subdomain/admin/reports/common/EmptyState';
import Timestamp from 'common/Timestamp';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import UserLockup from 'common/user/UserLockup';
import findStringMatches from 'common/util/findStringMatches';
import getExtension from 'common/util/getExtension';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import stringSort from 'common/util/stringSort';
import styles from 'css-module/components/subdomain/admin/settings/_AdminKnowledgeHubTable.module.scss';

type Props = {
  files: KnowledgeHubFile[];
  onDelete: (files: KnowledgeHubFile[]) => Promise<void>;
  loading: boolean;
};

interface Row extends DataTableRow {
  id: KnowledgeHubFile['_id'];
  name: KnowledgeHubFile['title'];
  uploader: KnowledgeHubFile['uploader'];
  createdAt: KnowledgeHubFile['createdAt'];
  status: KnowledgeHubFileStatus;
  file: KnowledgeHubFile;
}

type SortableColumn<R extends Row, ID extends keyof R> = Column<R, ID> & {
  sort: (order: 'asc' | 'desc') => (a?: R[ID], b?: R[ID]) => number;
};

const fileToRow = (file: KnowledgeHubFile) => {
  return {
    id: file._id,
    name: file.title,
    uploader: file.uploader,
    createdAt: file.createdAt,
    status: file.status,
    file,
  } as const;
};

const AdminKnowledgeHubTable = ({ files, onDelete, loading }: Props) => {
  const [search, setSearch] = useState('');
  const [isClearingFailedFiles, setIsClearingFailedFiles] = useState(false);
  const [isDeletingFile, setIsDeletingFile] = useState<Record<KnowledgeHubFile['_id'], boolean>>(
    {}
  );

  const failedFiles = files.filter((file) => file.status === KnowledgeHubFileStatus.failed);
  const clearFailedFiles = async () => {
    setIsClearingFailedFiles(true);
    await onDelete(failedFiles);
    setIsClearingFailedFiles(false);
  };

  const deleteFile = async (file: KnowledgeHubFile) => {
    setIsDeletingFile((prev) => ({ ...prev, [file._id]: true }));
    await onDelete([file]);
    setIsDeletingFile((prev) => ({ ...prev, [file._id]: false }));
  };

  const rows = files.map(fileToRow);
  const columns = [
    {
      id: 'name',
      align: 'left',
      header: 'Name',
      cell: (name) => {
        const extension = getExtension(name);
        const content =
          extension && isSupportedExtension(extension)
            ? ExtensionLabelMap[extension] ?? FileTypeLabelMap.attachment
            : FileTypeLabelMap.attachment;

        return (
          <Tooltip className={styles.nameTooltip} delay={500} value={name} position="top">
            <div className={styles.nameCell}>
              <LazyLoadedImage alt={name} src={content.icon} />
              <P fontWeight="medium" variant="bodyMd" className={styles.nameCellText}>
                {name || content.label}
              </P>
            </div>
          </Tooltip>
        );
      },
      sort: (order: 'asc' | 'desc') => {
        return (a: string, b: string) => {
          return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
        };
      },
      sortable: true,
    } as SortableColumn<Row, 'name'>,
    {
      id: 'uploader',
      align: 'left',
      header: 'Submitted by',
      cell: (uploader) => {
        if (!uploader) {
          return null;
        }

        return <UserLockup containerClassName="user" truncate={true} user={uploader} />;
      },
      sort: (order: 'asc' | 'desc') => stringSort('name', order),
      sortable: true,
    } as SortableColumn<Row, 'uploader'>,
    {
      id: 'createdAt',
      align: 'left',
      header: 'Uploaded',
      cell: (createdAt) => <Timestamp className={styles.createdCell} timestamp={createdAt} />,
      sort: (order: 'asc' | 'desc') => {
        return (a: string, b: string) => {
          return order === 'desc'
            ? new Date(a).getTime() - new Date(b).getTime()
            : new Date(b).getTime() - new Date(a).getTime();
        };
      },
      sortable: true,
    } as SortableColumn<Row, 'createdAt'>,
    {
      id: 'status',
      align: 'left',
      header: 'Status',
      cell: (status) => {
        const statusPillMap = {
          [KnowledgeHubFileStatus.pending]: (
            <Pill pillStyle={DefaultPillStyles.warning}>Processing</Pill>
          ),
          [KnowledgeHubFileStatus.processing]: (
            <Pill pillStyle={DefaultPillStyles.warning}>Processing</Pill>
          ),
          [KnowledgeHubFileStatus.processed]: null,
          [KnowledgeHubFileStatus.failed]: (
            <Pill pillStyle={DefaultPillStyles.destructive}>Failed</Pill>
          ),
        } as const;
        return statusPillMap[status];
      },
      sort: (order: 'asc' | 'desc') => {
        return (a: string, b: string) => {
          return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
        };
      },
      sortable: true,
    } as SortableColumn<Row, 'status'>,
    {
      id: 'file',
      align: 'left',
      header: 'Actions',
      cell: (file) => {
        return (
          <IconButtonV2
            aria-label="Delete file"
            icon={Trash}
            size="medium"
            variant="plain"
            onClick={() => deleteFile(file)}
            loading={isDeletingFile[file._id]}
          />
        );
      },
      sortable: false,
    } as Column<Row, 'file'>,
  ];

  // sort logic
  const [sortedRows, onSort] = useLocalSort(rows, (rows, sort) => {
    if (!sort) {
      return rows;
    }

    const column = columns.find((column) => column.id === sort.columnID);
    if (column && `sort` in column) {
      const sortedRows = [...rows].sort((a, b) => {
        return column.sort(sort.order)(
          a[column.id as keyof Row[typeof column.id]],
          b[column.id as keyof Row[typeof column.id]]
        );
      });

      return sortedRows;
    }

    return rows;
  });

  // do not render the attachment table if there are no files
  if (!files.length && !search && !loading) {
    return null;
  }

  const filteredRows = findStringMatches(sortedRows, 'name', search);
  return (
    <div className={styles.adminKnowledgeHubTable}>
      <div className={styles.fileTableHeader}>
        <P fontWeight="semibold">Attached files</P>
        {!loading && (
          <div className={styles.right}>
            <TextInput
              className={styles.search}
              inset={<Search size={14} className={styles.searchIcon} />}
              placeholder="e.g. file.pdf"
              value={search}
              inputMode="search"
              onChange={(e) => setSearch(e.target.value)}
            />
            {failedFiles.length > 0 && (
              <ButtonV2
                variant="outlined"
                size="medium"
                loading={isClearingFailedFiles}
                disabled={isClearingFailedFiles}
                onClick={clearFailedFiles}>
                Clear failed uploads
              </ButtonV2>
            )}
          </div>
        )}
      </div>
      <DataTable<Row>
        onSort={onSort}
        loading={loading}
        columns={columns}
        rows={filteredRows}
        emptyState={
          <tr>
            <td colSpan={columns.length}>
              <EmptyState message="Check the file name and try again." />
            </td>
          </tr>
        }
      />
    </div>
  );
};

export default compose(
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.inbox),
    '/admin/settings'
  )
)(AdminKnowledgeHubTable);

import type { Sliding } from 'common/constants/billing';

export default function getSlidingQuantity(
  count: number,
  limit: number | null,
  slidingPlan: Sliding
): number {
  if (!limit) {
    return 0;
  }
  const { increment } = slidingPlan;
  const slidingQuantity = count <= limit ? 0 : Math.ceil((count - limit) / increment);
  return slidingQuantity;
}

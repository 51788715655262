import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CustomFieldTypes } from 'common/customPostFields/Constants';
import { findTypeFromName } from 'common/customPostFields/Utils';
import Dropdown from 'common/Dropdown';
import FormField from 'common/FormField';
import generateRandomID from 'common/generateRandomID';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModernModal from 'common/modals/ModernModal';
import Tappable from 'common/Tappable';

import 'css/components/modals/_CustomPostFieldModal.scss';

const createOption = (value) => {
  return { tempID: generateRandomID(), value };
};

export default class CustomPostFieldModal extends Component {
  static propTypes = {
    defaultCustomPostField: PropTypes.object,
    error: PropTypes.string,
    header: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    saving: PropTypes.bool,
    submitLabel: PropTypes.string,
  };

  state = {
    name: this.props.defaultCustomPostField?.name,
    options: this.props.defaultCustomPostField?.options?.map(createOption) ?? null,
    type: findTypeFromName(this.props.defaultCustomPostField?.type ?? 'text'),
  };

  constructor(props) {
    super(props);
    this.newOptionInputRef = React.createRef();
  }

  onRemoveOption = (option) => {
    if (!option?.tempID) {
      return;
    }

    this.setState(
      (state) => {
        const updatedOptions = state.options.filter((o) => o.tempID !== option.tempID);
        return {
          options: updatedOptions.length ? updatedOptions : null,
        };
      },
      () => this.newOptionInputRef?.current.focus()
    );
  };

  onOptionUpdated = (option) => {
    if (!option?.tempID) {
      return;
    }

    this.setState((state) => {
      return {
        options: state.options.map((o) => {
          if (o.tempID === option.tempID) {
            return {
              ...o,
              value: option.value,
            };
          }

          return o;
        }),
      };
    });
  };

  onOptionAdded = (value) => {
    const newOption = createOption(value);

    this.setState(
      (state) => {
        return {
          options: [...(state.options ?? []), newOption],
          newOption: null,
        };
      },
      () => {
        if (this.state.options.length < this.state.type.optionsLimit) {
          this.newOptionInputRef?.current.setValue('');
          this.newOptionInputRef?.current.focus();
        }
      }
    );
  };

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  onTypeChange = (type) => {
    this.setState({
      type: findTypeFromName(type),
      options: null,
    });
  };

  renderError() {
    const { error } = this.props;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderOptions() {
    const { options, type } = this.state;

    const canCreateOption =
      this.state.newOption && (!options || options.length < type.optionsLimit);
    return (
      <section className="options">
        <h2>Options</h2>
        <ul className="optionsList">
          {options?.map((option, order) => (
            <li className="newOption" key={option.tempID}>
              <div className="order">{order + 1}</div>
              <TextInput
                aria-label="Dropdown option"
                defaultValue={option.value}
                placeholder="Option name"
                maxLength={30}
                onChange={(e) => this.onOptionUpdated({ ...option, value: e.currentTarget.value })}
                suffix={
                  <Tappable triggerOnEnter={true} onTap={() => this.onRemoveOption(option)}>
                    <button aria-label="Delete option" className="icon icon-x" type="button" />
                  </Tappable>
                }
              />
            </li>
          ))}
          {(!options || (options && options.length < type.optionsLimit)) && (
            <div className="newOption">
              <div className="order">{options ? options.length + 1 : 1}</div>
              <TextInput
                aria-label="Dropdown option"
                placeholder="Option name"
                maxLength={30}
                ref={this.newOptionInputRef}
                onChange={(e) => this.setState({ newOption: e.currentTarget.value })}
              />
            </div>
          )}
        </ul>
        <div className="addOptionSection">
          <Tappable
            triggerOnEnter={true}
            onTap={() => canCreateOption && this.onOptionAdded(this.state.newOption)}>
            <button disabled={!canCreateOption} type="button">
              + Add new option
            </button>
          </Tappable>
        </div>
      </section>
    );
  }

  render() {
    const {
      defaultCustomPostField,
      header,
      isTypeEditable,
      onClose,
      onSubmit,
      saving,
      submitLabel,
    } = this.props;
    const { name, options, newOption, type } = this.state;

    const optionValues = []
      .concat(options?.map((option) => option.value))
      .concat(newOption)
      .filter(Boolean);
    const isDisabled =
      !name ||
      !!(type.hasOptions && !options && !newOption) ||
      optionValues.some((value) => value.length > 30);

    return (
      <ModernModal
        overlayClassName="customPostFieldModalPortal"
        onClose={onClose}
        header={header}
        sections={[
          <div className="fields" key="fields">
            <FormField label="Field name">
              <TextInput
                autoFocus={true}
                defaultValue={defaultCustomPostField?.name}
                onChange={this.onNameChange}
              />
            </FormField>
            <div className="typeSelector">
              <FormField id="fieldSelector" label="Field type">
                <Dropdown
                  disabled={!isTypeEditable}
                  defaultSelectedName={type.name}
                  onChange={this.onTypeChange}
                  options={Object.values(CustomFieldTypes).map(({ label, name }) => ({
                    name,
                    render: label,
                  }))}
                />
              </FormField>
            </div>
            {type.hasOptions && this.renderOptions()}
          </div>,
        ]}
        footer={
          <>
            {this.renderError()}
            <div className="buttons">
              <Button
                buttonType="cannyButton"
                disabled={isDisabled}
                onTap={() => onSubmit({ name, options: optionValues, type })}
                loading={saving}
                value={submitLabel}
              />
            </div>
          </>
        }
      />
    );
  }
}

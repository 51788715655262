// inspired by https://gist.github.com/rproenca/64781c6a1329b48a455b645d361a9aa3
export default function copyText(text: string): Promise<void> {
  // 1. If navigator.clipboard.writeText is supported, use that
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text);
  }

  // 2. Otherwise, use textarea solution (wrapped in a promise)
  return new Promise((resolve) => {
    // 2a. Create an offscreen textarea with the value we'd like to be copied
    const textarea = document.createElement('textarea');
    textarea.style.position = 'absolute';
    textarea.style.top = '-9999px';
    textarea.readOnly = true;
    textarea.value = text;
    document.body.appendChild(textarea);

    // 2b. Select the range in the textarea
    const isIOS = navigator.userAgent.match(/ipad|iphone/i);
    if (isIOS) {
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
      textarea.setSelectionRange(0, text.length);
    } else {
      textarea.select();
    }

    // 2c. exec command "copy" to copy the selection, then remove the textarea
    document.execCommand('copy');
    document.body.removeChild(textarea);
    resolve();
  });
}

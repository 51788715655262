import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import connect from 'common/core/connect';
import ModalPortal from 'common/modals/ModalPortal';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminCCModal from 'common/subdomain/admin/billing/AdminCCModal';
import { GrowthAnnualPlanID, GrowthPlanID } from 'common/util/isGrowth';
import { StarterPlanID } from 'common/util/isStarter';

import 'css/components/modals/_UpsellModal.scss';

class UpsellModal extends Component {
  static propTypes = {
    cta: PropTypes.string,
    feature: PropTypes.string,
    onClose: PropTypes.func,
    onUpsell: PropTypes.func,
    planID: PropTypes.oneOf([GrowthPlanID, GrowthAnnualPlanID, StarterPlanID, StarterPlanID]),
    reloadCompany: PropTypes.func,
    show: PropTypes.bool,
  };

  state = {
    showCardModal: false,
  };

  onAddCard = () => {
    this.setState({ showCardModal: true });
  };

  onCardAdded = async () => {
    await this.props.reloadCompany();
    this.setState({ showCardModal: false });
  };

  onCardModalClosed = () => {
    this.setState({ showCardModal: false });
  };

  render() {
    const { cta, feature, onClose, onUpsell, planID, show } = this.props;
    if (!show) {
      return null;
    }

    if (this.state.showCardModal) {
      return <AdminCCModal onClose={this.onCardModalClosed} onSuccess={this.onCardAdded} />;
    }

    return (
      <ModalPortal className="upsellPortal" closeOnClickAway onClose={onClose}>
        <AdminFeatureUpsell
          cta={cta}
          feature={feature}
          onClose={onClose}
          onAddCard={this.onAddCard}
          onUpsell={onUpsell}
          planID={planID}
        />
      </ModalPortal>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(UpsellModal);

export default function nbspLastSpace(string: string): string {
  if (typeof string !== 'string') {
    return string;
  }

  const lastSpace = string.lastIndexOf(' ');
  if (lastSpace === -1) {
    return string;
  }

  return string.substr(0, lastSpace) + '\u00a0' + string.substr(lastSpace + 1);
}

import React from 'react';

import { X } from 'lucide-react';

import Card from 'common/common/Card';
import { LoadStatus, type LocalFile } from 'common/constants/files';
import { ExtensionLabelMap, FileTypeLabelMap } from 'common/file/utils/fileLabels';
import LazyLoadedImage from 'common/LazyLoadedImage';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';

import 'css/components/file/_FileCard.scss';

type Props = {
  allowRemove: boolean;
  file: LocalFile;
  onFileRemoved?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: LocalFile) => void;
};

const FileCard = ({ allowRemove, file, onFileRemoved }: Props) => {
  const content = file.extension
    ? ExtensionLabelMap[file.extension] ?? FileTypeLabelMap.attachment
    : FileTypeLabelMap.attachment;
  const loaded = file.uploadStatus === LoadStatus.loaded;

  const component = (
    <Card className="fileCard" borderStyle="solid">
      <div className="leftContainer">
        <LazyLoadedImage alt={file.name} className="fileIcon" src={content.icon} />
        <P className="fileName" variant="bodyMd">
          {file.name || content.label}
        </P>
      </div>
      {allowRemove && (
        <div className="rightContainer">
          <IconButtonV2
            size="small"
            loading={!loaded}
            onClick={(e) => onFileRemoved && onFileRemoved(e, file)}
            icon={X}
            variant="plain"
            aria-label="Remove file"
          />
        </div>
      )}
    </Card>
  );

  return loaded ? (
    <a href={file.url ?? ''} target="_blank" download={file.uniqueID} rel="noopener">
      {component}
    </a>
  ) : (
    component
  );
};

export default FileCard;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidateUserQueries } from 'common/actions/userQueries';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext, OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Modal from 'common/modals/Modal';
import { DeleteSegmentConfirmationModal, EditSegmentModal } from 'common/modals/SavedSegmentsModal';
import { SegmentType } from 'common/modals/SavedSegmentsModal/EditSegmentModal';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';
import SegmentationIcon from 'img/landing/features-segmentation.webp';

import SegmentInUse from './SegmentInUse';

import 'css/components/modals/_SavedSegmentsModal.scss';

class SavedSegmentsModal extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(
      PropTypes.shape({
        settings: PropTypes.shape({
          segmentURLName: PropTypes.string,
        }),
      })
    ).isRequired,
    closeModal: PropTypes.func.isRequired,
    company: PropTypes.shape({
      segments: PropTypes.arrayOf(SegmentType),
    }),
    invalidateQueries: PropTypes.func.isRequired,
    needsInvalidate: PropTypes.bool,
    onUpdateQuery: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    reloadSegments: PropTypes.func.isRequired,
  };

  static defaultProps = {
    needsInvalidate: false,
  };

  state = {
    editting: false,
  };

  componentWillUnmount = () => {
    if (!this.state.editting) {
      this.onClose();
    }
  };

  onCreateSegment = () => {
    const { boards, onUpdateQuery, openModal } = this.props;
    this.setState({ editting: true }, () => {
      openModal(EditSegmentModal, {
        boards,
        onClose: this.onClose,
        onUpdateQuery,
        reloadSegments: this.onReloadSegments,
      });
    });
  };

  onEditSegment = (segment) => {
    const { boards, onUpdateQuery, openModal } = this.props;
    this.setState({ editting: true }, () => {
      openModal(EditSegmentModal, {
        boards,
        onClose: this.onClose,
        onUpdateQuery,
        reloadSegments: this.onReloadSegments,
        segment,
      });
    });
  };

  onDeleteSegment = (segment) => {
    const { boards, onUpdateQuery, openModal } = this.props;
    this.setState({ editting: true }, () => {
      openModal(DeleteSegmentConfirmationModal, {
        boards,
        onUpdateQuery,
        reloadSegments: this.onReloadSegments,
        segment,
      });
    });
  };

  onExit = () => {
    this.props.closeModal();
  };

  onReloadSegments = () => {
    const { reloadSegments } = this.props;
    return reloadSegments();
  };

  onClose = () => {
    const { invalidateQueries, needsInvalidate, onUpdateQuery } = this.props;
    if (needsInvalidate) {
      invalidateQueries();
      onUpdateQuery();
    }
  };

  renderSegments() {
    const {
      boards,
      company: { segments },
    } = this.props;

    if (segments.length === 0) {
      return (
        <div className="segments nullState">
          <LazyLoadedImage alt="Segmentation icon" src={SegmentationIcon} />
          <div className="title">Welcome to User Segmentation!</div>
          <div className="details">
            Which user groups do you care about most? Create segments to filter feedback to
            those&nbsp;groups.
          </div>
          <div className="details">
            Want to add custom fields? Check out{' '}
            <a
              href="https://developers.canny.io/install/custom-fields"
              rel="noopener"
              target="_blank">
              the&nbsp;documentation
            </a>
            .
          </div>
        </div>
      );
    }

    const findBoardsUsingSegment = (segment) => {
      return boards.filter((board) => board.settings.segmentURLName === segment?.urlName);
    };

    return (
      <div className="segments">
        {segments.map((segment) => {
          const boardsUsingSegment = findBoardsUsingSegment(segment);
          return (
            <div className="segment" key={segment._id}>
              <div className="name">{segment.name}</div>
              <div className="actions">
                <Tappable onTap={() => this.onEditSegment(segment)}>
                  <div className="action edit">Edit</div>
                </Tappable>
                {boardsUsingSegment.length > 0 ? (
                  <Tooltip value={<SegmentInUse boardsUsingSegment={boardsUsingSegment} />}>
                    <div className="action delete disabled">Delete</div>
                  </Tooltip>
                ) : (
                  <>
                    <Tappable onTap={() => this.onDeleteSegment(segment)}>
                      <div className="action delete">Delete</div>
                    </Tappable>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="footer">
        <Button buttonType="cannyButton" onTap={this.onCreateSegment} value="Create New Segment" />
      </div>
    );
  }

  render() {
    return (
      <Modal className="savedSegmentsModal" style={{ padding: 0 }}>
        <div className="header">
          <div className="title">Saved segments</div>
          <Tappable onTap={this.onExit}>
            <div className="exitButton icon icon-x" />
          </Tappable>
        </div>
        {this.renderSegments()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateQueries: () => {
      return Promise.all([dispatch(invalidatePostQueries()), dispatch(invalidateUserQueries())]);
    },
    reloadSegments: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      closeModal: CloseModalContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(SavedSegmentsModal);

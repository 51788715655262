import React, { Component } from 'react';

import { loadRoadmaps } from 'common/actions/roadmaps';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { RoadmapsState } from 'common/reducers/roadmaps';

const asyncFetch = {
  promise: ({ store: { dispatch } }: PromiseOptions) => {
    return dispatch(loadRoadmaps());
  },
};

type RoadmapsContextType = {
  roadmaps: RoadmapsState['roadmaps'];
  roadmapsError?: string;
};

export const RoadmapsContext = React.createContext<RoadmapsContextType>({
  roadmaps: [],
  roadmapsError: undefined,
});

type Props = {
  roadmaps: RoadmapsState;
};

class RoadmapsContainer extends Component<Props> {
  static asyncConnect = asyncFetch;

  render() {
    const { children, roadmaps } = this.props;

    const loadedSuccessfully = roadmaps && !roadmaps.error && !roadmaps.loading;
    const value = {
      roadmaps: loadedSuccessfully ? roadmaps.roadmaps : [],
      roadmapsError: roadmaps.error,
    };
    const clonedElement = cloneElementWithProps(children, {
      ...this.props,
      ...value,
    });
    return <RoadmapsContext.Provider value={value}>{clonedElement}</RoadmapsContext.Provider>;
  }
}

export default asyncConnect([asyncFetch], (state) => ({ roadmaps: state.roadmaps }))(
  RoadmapsContainer
);

import {
  INVALIDATE_AND_RELOAD,
  LOADING_MORE,
  QUERY_ERROR,
  QUERY_LOADED,
  type QueueAction,
  REMOVE_ITEM,
  REQUEST_QUERY,
  UPDATE_COUNT,
  getQueryKey,
} from '../actions/queueItemQueries';

import type { QueueItem } from 'common/api/endpoints/queue';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

export type State = {
  count: number;
  postsCount: number;
  draftsCount: number;
  draftsCountPerSource: Partial<Record<SyncIntegrationNames, number>>;
  items: Record<string, QueueItem>;
  updatedAt: Date | null;
  queries: {
    [queryKey: string]: {
      error: string;
      itemIDs: string[];
      updatedAt: Date;
      loading: boolean;
      pages: number;
      hasMore: boolean;
      loadingMore: boolean;
      queryParams: string;
    };
  };
};

const DefaultState: State = {
  count: 0,
  postsCount: 0,
  draftsCount: 0,
  draftsCountPerSource: {},
  updatedAt: null,
  items: {},
  queries: {},
};

export default function queueItemQueries(state: State = DefaultState, action: QueueAction) {
  switch (action.type) {
    case REQUEST_QUERY: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postsCount: state.postsCount,
        draftsCount: state.draftsCount,
        draftsCountPerSource: state.draftsCountPerSource,
        items: state.items,
        updatedAt: state.updatedAt,
        queries: {
          ...state.queries,
          [queryKey]: {
            ...state.queries[queryKey],
            updatedAt: action.timestamp,
            loading: true,
            loadingMore: false,
            queryParams: action.queryParams,
          },
        },
      };

      return newState;
    }

    case REMOVE_ITEM: {
      const itemToRemove = action.item;
      if (!itemToRemove) {
        return state;
      } else if (!state.items[itemToRemove._id]) {
        return state;
      }

      const updatedState = {
        count: state.count - 1,
        draftsCount: state.draftsCount,
        postsCount: state.postsCount,
        draftsCountPerSource: state.draftsCountPerSource,
        updatedAt: state.updatedAt,
        items: {
          ...state.items,
          [itemToRemove._id]: undefined,
        },
        queries: state.queries,
      };

      if (itemToRemove.type === 'draft') {
        updatedState.draftsCount -= 1;

        const { source } = itemToRemove;

        if (source in updatedState.draftsCountPerSource) {
          // prevents count from going negative / handles undefined values
          const currentCount = updatedState.draftsCountPerSource[source] ?? 0;
          updatedState.draftsCountPerSource[source] = Math.max(currentCount - 1, 0);
        }
      } else if (itemToRemove.type === 'post') {
        updatedState.postsCount -= 1;
      }

      return updatedState;
    }

    case QUERY_LOADED: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: action.result.count,
        draftsCount: action.result.draftsCount,
        postsCount: action.result.postsCount,
        draftsCountPerSource: action.result.draftsCountPerSource,
        updatedAt: action.timestamp,
        items: { ...state.items, ...action.result.items },
        queries: {
          ...state.queries,
          [queryKey]: {
            ...state.queries[queryKey],
            itemIDs: Object.keys(action.result.items),
            updatedAt: action.timestamp,
            loading: false,
            loadingMore: false,
            hasMore: action.result.hasMore,
            queryParams: action.queryParams,
            reloading: false,
          },
        },
      };

      return newState;
    }

    case QUERY_ERROR: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postsCount: state.postsCount,
        draftsCount: state.draftsCount,
        draftsCountPerSource: state.draftsCountPerSource,
        updatedAt: state.updatedAt,
        items: state.items,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            error: action.error,
            updatedAt: action.timestamp,
            loading: false,
            loadingMore: false,
            queryParams: action.queryParams,
          },
        },
      };

      return newState;
    }

    case LOADING_MORE: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postsCount: state.postsCount,
        draftsCount: state.draftsCount,
        draftsCountPerSource: state.draftsCountPerSource,
        updatedAt: state.updatedAt,
        items: state.items,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            updatedAt: action.timestamp,
            pages: action.pages,
            loadingMore: true,
            queryParams: action.queryParams,
          },
        },
      };

      return newState;
    }

    case UPDATE_COUNT: {
      const newState = {
        count: action.result.count,
        draftsCount: action.result.draftsCount,
        postsCount: action.result.postsCount,
        draftsCountPerSource: action.result.draftsCountPerSource,
        updatedAt: action.timestamp,
        items: state.items,
        queries: state.queries,
      };

      return newState;
    }

    case INVALIDATE_AND_RELOAD: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postsCount: state.postsCount,
        draftsCount: state.draftsCount,
        draftsCountPerSource: state.draftsCountPerSource,
        items: state.items,
        updatedAt: state.updatedAt,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            reloading: true,
          },
        },
      };

      return newState;
    }

    default:
      return state;
  }
}

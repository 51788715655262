import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { SalesIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const sections = [
  {
    integrations: SalesIntegrations,
  },
];

const AdminIntegrationSettingsSales = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Sales"
      settingsDescription="Discover powerful tools to enhance your sales processes. Integrate these solutions to streamline customer management, track deals, and boost productivity effortlessly."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.sales),
    '/admin/settings'
  )
)(AdminIntegrationSettingsSales) as unknown as React.FC;

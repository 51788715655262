import Data from 'common/Data';
import { RelativeDateOptions, RelativeDateRanges } from 'common/util/dateRanges';
import { dayjs } from 'common/util/dayjsUtils';
import promisify from 'common/util/promisify';

// Action Types

export const Invalidate = 'canny/adminDashboard/invalidate_dashboard_activity';
export const RequestDashboardActivity = 'canny/adminDashboard/request_dashboard_activity';
export const DashboardActivityLoaded = 'canny/adminDashboard/dashboard_activity_loaded';
export const DashboardActivityError = 'canny/adminDashboard/dashboard_activity_error';

// Actions

function requestDashboardActivity() {
  return {
    timestamp: Date.now(),
    type: RequestDashboardActivity,
  };
}

function dashboardActivityLoaded(filters, dashboardActivity) {
  return {
    dashboardActivity,
    filtersCacheKey: getFiltersCacheKey(filters),
    timestamp: Date.now(),
    type: DashboardActivityLoaded,
  };
}

function dashboardActivityError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: DashboardActivityError,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchDashboardActivity(filters) {
  return async (dispatch, getState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          dashboardActivity: {
            input: getRequestData(filters),
            query: Data.queries.adminDashboardActivity,
          },
        },
        cookies
      );
      return dispatch(dashboardActivityLoaded(filters, response.dashboardActivity));
    } catch (e) {
      return dispatch(dashboardActivityError(e));
    }
  };
}

export function invalidateDashboardActivity() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

export function loadDashboardActivity(filters) {
  return async (dispatch, getState) => {
    if (shouldFetchDashboardActivity(getState(), filters)) {
      dispatch(requestDashboardActivity());
      return dispatch(fetchDashboardActivity(filters));
    }
  };
}

// Helpers

function getRequestData(filters) {
  const { categories, dateRange, tags } = filters;
  let dateRangeValue = dateRange;
  if (typeof dateRange === 'string' && RelativeDateOptions[dateRange]) {
    if (dateRange === RelativeDateRanges.allTime) {
      dateRangeValue = [];
    } else {
      dateRangeValue = RelativeDateOptions[dateRange].value;
    }
  } else {
    dateRangeValue =
      dateRangeValue.length === 2
        ? [
            dayjs(dateRangeValue[0]).startOf('day').utc().format(),
            dayjs(dateRangeValue[1]).endOf('day').utc().format(),
          ]
        : [];
  }

  return {
    categoryURLNames: categories,
    dateRange: dateRangeValue,
    tagURLNames: tags,
  };
}

function shouldFetchDashboardActivity(state, filters) {
  if (!state.dashboardActivity) {
    return true;
  }

  // We haven't loaded these filters yet.
  if (!state.dashboardActivity.stats[getFiltersCacheKey(filters)]) {
    return true;
  }

  return false;
}

export function getFiltersCacheKey(filters) {
  const dateRange = filters.dateRange.toString();
  const categories = filters.categories.sort().join(',');
  const tags = filters.tags.sort().join(',');
  return `${dateRange}-${categories}-${tags}`;
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import getCannyOrigin from 'common/util/getCannyOrigin';
import withContexts from 'common/util/withContexts';

import Helmet from './Helmet';

class SubdomainHomeHelmet extends Component {
  static propTypes = {
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  render() {
    const { company } = this.props;
    if (!company || !company.name) {
      return null;
    }

    const removeBranding = company?.features?.removeBranding;
    const title = company.name + ' Feedback';
    const description = `Give feedback to the ${
      company.name
    } team so we can make more informed product decisions.${
      removeBranding ? '' : ' Powered by Canny.'
    }`;
    const url = getCannyOrigin(company) + '/';

    return (
      <Helmet
        title={title}
        link={[{ href: url, rel: 'canonical' }]}
        meta={[
          { name: 'description', content: description },

          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:type', content: 'website' },
        ]}
      />
    );
  }
}

export default withContexts({
  company: CompanyContext,
})(SubdomainHomeHelmet);

import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import {
  AuthenticationIntegrations,
  AutomationIntegrations,
  CommunicationIntegrations,
  DataAndEnrichmentIntegrations,
  ProjectManagementIntegrations,
  SalesIntegrations,
} from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const getCategorySections = () => {
  return [
    {
      sectionTitle: 'Authentication',
      integrations: AuthenticationIntegrations,
    },
    {
      sectionTitle: 'Automation',
      integrations: AutomationIntegrations,
    },
    {
      sectionTitle: 'Communication',
      integrations: CommunicationIntegrations,
    },
    {
      sectionTitle: 'Data and Enrichment',
      integrations: DataAndEnrichmentIntegrations,
    },
    {
      sectionTitle: 'Project Management',
      integrations: ProjectManagementIntegrations,
    },
    {
      sectionTitle: 'Sales',
      integrations: SalesIntegrations,
    },
  ];
};

const AdminIntegrationSettingsCategories = () => {
  const sections = getCategorySections();

  return (
    <AdminIntegrationSettings
      settingsTitle="All"
      settingsDescription="Browse integrations across all categories"
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations['categories-all']),
    '/admin/settings'
  )
)(AdminIntegrationSettingsCategories) as unknown as React.FC;

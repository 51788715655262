import { loadReferralSettings } from 'common/actions/referralSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { ReferralSettings } from 'common/api/endpoints/referralSettings';
import type { State } from 'redux-connect';

type Props = {
  children: React.ReactNode;
  referralSettings: ReferralSettings;
};

const ReferralSettingsContainer = (props: Props) => {
  // props
  const { children, referralSettings } = props;

  return cloneElementWithProps(children, {
    ...props,
    referralSettings,
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadReferralSettings()),
    },
  ],
  (state: State) => ({ referralSettings: state.referralSettings?.data })
)(ReferralSettingsContainer);

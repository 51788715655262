import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

const {
  requestAction: requestRules,
  loadedAction: rulesLoaded,
  errorAction: rulesError,

  RequestType: RequestRules,
  LoadedType: RulesLoaded,
  ErrorType: RulesError,
} = requestActionFactory('jiraRules');

export { RequestRules, RulesLoaded, RulesError };

// Action Creators

function fetchRules() {
  return (dispatch, getState) => {
    const { cookies } = getState();
    return Data.fetch(
      {
        rules: {
          query: Data.queries.jiraRules,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(rulesError(error));
        } else {
          return dispatch(rulesLoaded(data.rules));
        }
      }
    );
  };
}

export function loadRules() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      if (shouldFetchRules(getState())) {
        dispatch(requestRules());
        return dispatch(fetchRules());
      }
    });
  };
}

export function reloadRules() {
  return (dispatch, getState) => {
    return dispatch(fetchRules());
  };
}

// Helpers

function shouldFetchRules(state) {
  const { company } = state;
  if (!company || !company.jira || !company.jira.connected) {
    return false;
  }

  return !state.jiraRules.data;
}

import React from 'react';

import classnames from 'classnames';
import { AlertCircle, Check, StickyNote } from 'lucide-react';

import { P } from 'common/ui/Text';
import styles from 'css-module/components/common/_Alert.module.scss';

export enum AlertTypes {
  Danger = 'Danger',
  Info = 'Info',
  Confirmation = 'Confirmation',
}

const AlertModes: Record<AlertTypes, { icon: React.ElementType; className: string }> = {
  [AlertTypes.Danger]: {
    icon: AlertCircle,
    className: styles.danger,
  },
  [AlertTypes.Info]: {
    icon: StickyNote,
    className: styles.info,
  },
  [AlertTypes.Confirmation]: {
    icon: Check,
    className: styles.confirmation,
  },
} as const;

type ElementProps = { children: React.ReactNode; className?: string };

const Heading: React.FC<ElementProps> = ({ className, children }) => (
  <P className={classnames(styles.heading, className)} variant="bodyMd" fontWeight="semibold">
    {children}
  </P>
);

const Content: React.FC<ElementProps> = ({ className, children }) => (
  <P className={classnames(styles.subText, className)}>{children}</P>
);

const CTA: React.FC<ElementProps> = ({ className, children }) => (
  <div className={classnames(styles.cta, className)}>{children}</div>
);

type Props = {
  headingText?: string;
  subText?: React.ReactNode;
  button?: React.ReactNode;
  type: AlertTypes;
  className?: string;
  children?: React.ReactNode;
};

const Alert = ({ headingText, subText, button, type, className, children }: Props) => {
  const { icon: Icon, className: modeClassName } = AlertModes[type];

  // If no children exist, use the props
  let heading = <Heading>{headingText}</Heading>;
  let content = <Content>{subText}</Content>;
  let cta = <CTA>{button}</CTA>;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Heading) {
        heading = child;
      }
      if (child.type === Content) {
        content = child;
      }
      if (child.type === CTA) {
        cta = child;
      }
    }
  });

  return (
    <div className={classnames(className, styles.alert, modeClassName)}>
      <Icon className={styles.icon} size={14} />
      {heading}
      {content}
      {cta}
    </div>
  );
};

// Add static components
Alert.Heading = Heading;
Alert.Content = Content;
Alert.CTA = CTA;
Alert.AlertTypes = AlertTypes;

export default Alert;

export const FallbackLanguageCode = 'en';

export const SupportedLanguages = {
  Dansk: 'da', // danish
  Deutsch: 'de', // german
  English: 'en',
  Español: 'es', // spanish
  Français: 'fr', // french
  Italiano: 'it', // italian
  Nederlands: 'nl', // dutch
  Polski: 'pl', // polish
  'Português (Brasil)': 'pt', // portuguese (brazil)
  'Português (Portugal)': 'pt-PT', // portuguese (portugal)
  Русский: 'ru', // russian
  Suomi: 'fi', // finnish
} as const;

type SupportedLanguageNames = keyof typeof SupportedLanguages;

const SupportedLanguageCodes = Object.entries(SupportedLanguages).reduce(
  (acc: Record<string, string>, [key, value]) => {
    acc[value.toLowerCase()] = key;
    return acc;
  },
  {}
) as Record<(typeof SupportedLanguages)[SupportedLanguageNames], SupportedLanguageNames>;

export function getSupportedLanguageCode(preferredLanguageCode: string | null): SupportedLanguage {
  const lowerLanguageCode = preferredLanguageCode?.toLowerCase();

  // handle null, empty string
  if (!lowerLanguageCode) {
    return FallbackLanguageCode;
  }

  // handle *
  if (lowerLanguageCode === '*') {
    return FallbackLanguageCode;
  }

  // handle exact match
  if (lowerLanguageCode in SupportedLanguageCodes) {
    return lowerLanguageCode as SupportedLanguage;
  }

  // handle subtags
  const languageCode = lowerLanguageCode.split('-')[0];
  if (languageCode in SupportedLanguageCodes) {
    return languageCode as SupportedLanguage;
  }

  return FallbackLanguageCode;
}

export type SupportedLanguage = (typeof SupportedLanguages)[keyof typeof SupportedLanguages];

import promisify from 'common/util/promisify';

import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { CustomPostFields } from 'common/api/endpoints/customPostFields';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: customPostFieldsRequest,
  loadedAction: customPostFieldsLoaded,
  errorAction: customPostFieldsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<CustomPostFields>('customPostFields');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchCustomPostFields() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          customPostFields: {
            query: Data.queries.customPostFields,
          },
        },
        cookies
      );
      return dispatch(customPostFieldsLoaded(response.customPostFields));
    } catch (e) {
      if (typeof e === 'string') {
        return dispatch(customPostFieldsError(e));
      }
      return dispatch(customPostFieldsError('server error'));
    }
  };
}

export function loadCustomPostFields() {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchCustomPostFields(getState())) {
      dispatch(customPostFieldsRequest());
      return dispatch(fetchCustomPostFields());
    }
  };
}

export function reloadCustomPostFields() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchCustomPostFields());
  };
}

// Helpers

function shouldFetchCustomPostFields(state: State) {
  return !state.customPostFields.data;
}

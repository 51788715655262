import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { getFiltersCacheKey, loadDashboardActivity } from 'common/actions/dashboardActivity';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import asyncConnect from 'common/core/asyncConnect';
import connect from 'common/core/connect';
import AdminDashboardHelmet from 'common/helmets/AdminDashboardHelmet';
import Button from 'common/inputs/Button';
import Link from 'common/Link';
import AccessModal from 'common/modals/AccessModal';
import RoadmapView from 'common/subdomain/RoadmapView';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';

import AdminDashboardActivity from './AdminDashboardActivity';
import AdminDashboardAdminAnalytics from './AdminDashboardAdminAnalytics';
import AdminDashboardEngagementBreakdown from './AdminDashboardEngagementBreakdown';
import AdminDashboardOnboarding from './AdminDashboardOnboarding';
import AdminDashboardPostList from './AdminDashboardPostList';
import AdminBoardList from '../AdminBoardList';
import ThirdPartyCompanyFeatureRequests from '../reports/ThirdPartyCompanyFeatureRequests';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboard.scss';

const DefaultDateRange = 'this-week';

const asyncFetch = {
  promise: ({ store: { dispatch } }) => {
    return dispatch(
      loadDashboardActivity({
        categories: [],
        dateRange: DefaultDateRange,
        tags: [],
      })
    );
  },
};

class AdminDashboard extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.object,
    dashboardActivity: PropTypes.object,
    openModal: PropTypes.func,
    refreshDashboardActivity: PropTypes.func.isRequired,
    roadmap: PropTypes.object,
    trackEvent: PropTypes.func,
    viewer: PropTypes.object,
  };

  state = {
    activityFilters: {
      categories: [],
      dateRange: DefaultDateRange,
      tags: [],
    },
  };

  componentDidMount() {
    this.props.trackEvent('Viewed Admin Dashboard');
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { dashboardActivity, refreshDashboardActivity } = nextProps;
    const { activityFilters } = nextState;

    const invalidated = this.props.dashboardActivity && !dashboardActivity;
    const doesNotHaveData = !dashboardActivity?.stats?.[getFiltersCacheKey(activityFilters)];
    if (invalidated || (activityFilters !== this.state.activityFilters && doesNotHaveData)) {
      refreshDashboardActivity(activityFilters);
      return false;
    }

    // Don't rerender if we're waiting for dashboard activity to refresh. This makes the charts
    // rerender smoothly when the new data is ready.
    if (!dashboardActivity || dashboardActivity.loading || doesNotHaveData) {
      return false;
    }

    return true;
  }

  onDateRangeSelected = ({ relativeDate, dateRange }) => {
    this.setState({
      activityFilters: {
        ...this.state.activityFilters,
        dateRange: relativeDate ?? dateRange,
      },
    });
  };

  onFilterUpdate = ({ categories, tags }) => {
    this.setState({
      activityFilters: {
        ...this.state.activityFilters,
        categories,
        tags,
      },
    });
  };

  renderNewAndStalePosts() {
    const { dashboardActivity } = this.props;
    return (
      <div className="postLists">
        <AdminDashboardPostList
          header="New posts"
          noPostsMessage="No posts have recently been created."
          posts={dashboardActivity?.newPosts ?? []}
          tooltip="Posts that have recently been created."
        />
        <AdminDashboardPostList
          showStaleInfo
          header="Stale posts"
          noPostsMessage="None of your posts are stale."
          posts={dashboardActivity?.stalePosts ?? []}
          tooltip="Posts that are planned or in progress but haven't been updated in over 4 weeks."
        />
      </div>
    );
  }

  renderV1Boards() {
    const { company, openModal, viewer } = this.props;
    const viewerHasPermission = hasPermission('manageBoards', company, viewer);
    return (
      <div>
        <div className="v1Header">
          <div className="boardsHeader">Boards</div>
          <Link
            className="cannyButton"
            disabled={!viewerHasPermission}
            onTap={() => {
              if (viewerHasPermission) {
                return;
              }
              openModal(AccessModal, {
                requiredPermissions: ['manageBoards'],
              });
            }}
            to="/admin/create-board">
            <Button className="cannyButton" value="Create Board" />
          </Link>
        </div>
        <AdminBoardList
          boards={this.props.boards}
          noBoardsMessage={
            <div>
              Your boards will show up here.{' '}
              <Link to="/admin/create-board">Click here to create one.</Link>
            </div>
          }
        />
      </div>
    );
  }

  renderAdminAnalytics() {
    const {
      company: { members },
    } = this.props;

    return <AdminDashboardAdminAnalytics members={members} />;
  }

  renderEngagementBreakdown() {
    return <AdminDashboardEngagementBreakdown />;
  }

  renderBoardsOrDashboardActivity() {
    const {
      company: { fullScreenAdminView },
    } = this.props;
    const { boards, dashboardActivity } = this.props;
    const { activityFilters } = this.state;

    if (fullScreenAdminView) {
      return (
        <AdminDashboardActivity
          boards={boards}
          dateRange={activityFilters.dateRange}
          onDateRangeSelected={this.onDateRangeSelected}
          onFilterUpdate={this.onFilterUpdate}
          stats={dashboardActivity?.stats?.[getFiltersCacheKey(activityFilters)]}
        />
      );
    } else {
      return this.renderV1Boards();
    }
  }

  renderOnboarding() {
    const {
      company: { stats },
    } = this.props;

    if (stats.onboardedAt) {
      return null;
    }

    return <AdminDashboardOnboarding />;
  }

  render() {
    const { company } = this.props;
    const hasVoteWeightsEnabled = company.features?.voteWeights;
    return (
      <div className="adminDashboard">
        <ContentContainer>
          <AdminDashboardHelmet />
          {this.renderOnboarding()}
          {this.renderBoardsOrDashboardActivity()}
          {hasVoteWeightsEnabled && (
            <ThirdPartyCompanyFeatureRequests className="thirdPartyCompanyFeatureRequests" />
          )}
          {this.renderNewAndStalePosts()}
          {this.renderAdminAnalytics()}
          {this.renderEngagementBreakdown()}
          <RoadmapView roadmap={this.props.roadmap} />
        </ContentContainer>
      </div>
    );
  }
}

export default compose(
  asyncConnect([asyncFetch], ({ dashboardActivity }) => ({ dashboardActivity })),
  connect(null, (dispatch) => ({
    async refreshDashboardActivity(filters) {
      await dispatch(loadDashboardActivity(filters));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      trackEvent: TrackEventContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminDashboard);

import convertTextToHTML from 'common/util/convertTextToHTML';
import { dayjs } from 'common/util/dayjsUtils';
import validateInput from 'common/validateInput';

export const ErrorTypes = {
  create: 'create',
  fields: 'fields',
  projects: 'projects',
};

export const ErrorMessages = {
  'not authorized': `You are not authorized to use this feature. Reach for one of you company's admins for support.`,
  'integration not installed': `Your company does not have the Azure DevOps integration installed. Please, install it before linking work items.`,
};

export const Fields = {
  boolean: {
    parser: (value) => value === '1',
    serializer: (value) => (value ? 'True' : 'False'),
    type: 'boolean',
    validator: validateInput.primitives.boolean,
  },
  dateTime: {
    parser: (value) => dayjs(value, 'MM/DD/YYYY').toDate(),
    serializer: (value) => value.toISOString(),
    type: 'dateTime',
    validator: validateInput.primitives.date,
  },
  double: {
    parser: (value) => +value,
    serializer: (value) => value,
    type: 'double',
    validator: validateInput.primitives.number,
  },
  integer: {
    parser: (value) => +value,
    serializer: (value) => value,
    type: 'integer',
    validator: validateInput.primitives.integer,
  },
  plainText: {
    parser: (value) => value,
    serializer: (value) => value,
    type: 'plainText',
    validator: validateInput.primitives.string,
  },
  string: {
    parser: (value) => value,
    serializer: (value) => value,
    type: 'string',
    validator: (value) => validateInput.primitives.string(value) && value.length < 256,
  },
  html: {
    parser: convertTextToHTML,
    serializer: (value) => value,
    type: 'html',
    validator: validateInput.primitives.string,
  },
};

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import SearchInput from 'common/inputs/SearchInput';
import delayer from 'common/util/delayer';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminUsersSearch.scss';

class AdminUsersSearch extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
    }),
    router: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this._searchDelayer = new delayer(this.onSearchChangeAfterDelay, 250);
  }

  componentWillUnmount() {
    this._searchDelayer.cancel();
  }

  onSearchChange = (searchValue) => {
    const trimmedValue = searchValue.trim();

    if (trimmedValue) {
      this._searchDelayer.callAfterDelay(searchValue);
      return;
    }

    this._searchDelayer.cancel();
    const {
      location: { pathname, query },
      router,
    } = this.props;
    const newQuery = Object.assign({}, query);
    if (query.search) {
      delete newQuery.search;
      delete newQuery['sort'];
    }

    router.replace({
      pathname,
      query: newQuery,
    });
  };

  onSearchChangeAfterDelay = (searchValue) => {
    const trimmedValue = searchValue.trim();

    const {
      location: { pathname, query },
      router,
    } = this.props;
    const newQuery = Object.assign({}, query);
    if (!query.search && trimmedValue) {
      newQuery.search = trimmedValue;
      delete newQuery['sort'];
    } else {
      newQuery.search = trimmedValue;
    }

    router.replace({
      pathname,
      query: newQuery,
    });
  };

  render() {
    return (
      <div className="adminUsersSearch">
        <SearchInput
          autoFocus={false}
          defaultValue={this.props.location.query.search}
          onChange={this.onSearchChange}
          placeholder="Search by name..."
        />
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  router: RouterContext,
})(AdminUsersSearch);

import { loadCompany } from './company';
import Data from '../Data';

// Action Types

export const RequestChangelog = 'canny/changelog/request_changelog';
export const ChangelogLoaded = 'canny/changelog/changelog_loaded';
export const ChangelogError = 'canny/changelog/changelog_error';

// Actions

function requestChangelog() {
  return {
    timestamp: Date.now(),
    type: RequestChangelog,
  };
}

function changelogLoaded(result) {
  return {
    result,
    timestamp: Date.now(),
    type: ChangelogLoaded,
  };
}

function changelogError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: ChangelogError,
  };
}

// Action Creators

function fetchChangelog(pages) {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      const company = getState().company;
      if (company.error || company.notFound) {
        return dispatch(changelogError('company load failed'));
      }

      if (company.changelog && !company.changelog.viewerHasAccess) {
        return dispatch(changelogError('cannot access changelog'));
      }

      const cookies = getState().cookies;
      return Data.fetch(
        {
          result: {
            query: Data.queries.changelog,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, error, data);
        }
      );
    });
  };
}

export function loadChangelog() {
  return (dispatch, getState) => {
    if (shouldFetchChangelog(getState())) {
      dispatch(requestChangelog());
      return dispatch(fetchChangelog());
    } else if (isFetchingChangelog(getState())) {
      return waitForResult();
    }
  };
}

export function reloadChangelog() {
  return (dispatch) => {
    return Promise.all([dispatch(fetchChangelog())]);
  };
}

// Helper Functions

function shouldFetchChangelog(state) {
  const changelog = state.changelog;
  return !changelog;
}

function isFetchingChangelog(state) {
  const changelog = state.changelog;
  return !!changelog.loading;
}

// Callback Queue

const resultCallbacks = [];

function waitForResult() {
  return new Promise((resolve, reject) => {
    resultCallbacks.push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, error, data) {
  const resultAction = error ? changelogError(error) : changelogLoaded(data.result);
  return Promise.all([dispatch(resultAction)]).then(() => {
    resultCallbacks.forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks.length = 0;
  });
}

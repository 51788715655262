import React, { Component, Fragment } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import ExternalLink from './ExternalLink';

import 'css/components/_CannyAttribution.scss';

class CannyAttribution extends Component {
  static propTypes = {
    company: PropTypes.shape({
      features: PropTypes.shape({
        removeBranding: PropTypes.bool,
      }),
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          href: PropTypes.string,
        })
      ),
      subdomain: PropTypes.string,
    }),
    desktopOnly: PropTypes.bool,
    externalLink: PropTypes.bool,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
      })
    ),
    mobileOnly: PropTypes.bool,
    showCompanyLinks: PropTypes.bool,
    source: PropTypes.oneOf([
      'changelog_subdomain',
      'changelog_widget',
      'feedback_subdomain',
      'feedback_widget',
      'roadmap_subdomain',
    ]).isRequired,
  };

  static defaultProps = {
    externalLink: false,
    showCompanyLinks: true,
  };

  renderBranding() {
    const { company } = this.props;
    const removeBranding = company?.features?.removeBranding;
    if (removeBranding) {
      return null;
    }

    const {
      company: { name, subdomain },
      source,
    } = this.props;
    const url = devURL(
      'https://canny.io/powered-by-canny?' +
        'utm_source=' +
        source +
        '&' +
        'utm_medium=powered&' +
        'utm_campaign=' +
        encodeURIComponent(subdomain) +
        '&' +
        'company=' +
        encodeURIComponent(name)
    );

    const { externalLink } = this.props;
    if (externalLink) {
      return (
        <ExternalLink className="attribution" to={url}>
          Powered&nbsp;by&nbsp;Canny
        </ExternalLink>
      );
    }

    return (
      <a href={url} rel="noopener" target="_blank" className="attribution">
        Powered&nbsp;by&nbsp;Canny
      </a>
    );
  }

  renderLinks() {
    const { company, links } = this.props;
    const removeBranding = company?.features?.removeBranding;
    if (!links?.length) {
      return null;
    }

    return (
      <ul className="links">
        {!removeBranding && <div className="separator">&middot;</div>}
        {links.map((link, i) => (
          <Fragment key={link.href}>
            <a className="link" href={link.href} target="_blank" rel="noopener nofollow noreferrer">
              {link.label}
            </a>
            <div className="separator">&middot;</div>
          </Fragment>
        ))}
      </ul>
    );
  }

  renderCompanyLinks() {
    const {
      company: { links },
      showCompanyLinks,
    } = this.props;
    if (!showCompanyLinks) {
      return null;
    }

    if (!links?.length) {
      return null;
    }

    return (
      <div className="companyLinks">
        {links.map((link, i) => (
          <Fragment key={link.href}>
            <a className="link" href={link.href} target="_blank" rel="noopener nofollow noreferrer">
              {link.label}
            </a>
            <div className="separator">&middot;</div>
          </Fragment>
        ))}
      </div>
    );
  }

  render() {
    const { desktopOnly, mobileOnly } = this.props;
    const className = classnames({
      cannyAttribution: true,
      desktopOnly,
      mobileOnly,
    });
    return (
      <div className={className}>
        <div className="firstLine">
          {this.renderBranding()}
          {this.renderLinks()}
        </div>
        {this.renderCompanyLinks()}
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(CannyAttribution);

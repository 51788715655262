import { entryLoaded } from './changelogEntries';
import { loadCompany } from './company';
import Data from '../Data';

// Action Types

export const RequestQuery = 'canny/changelog_entry_queries/request_query';
export const QueryLoaded = 'canny/changelog_entry_queries/query_loaded';
export const QueryError = 'canny/changelog_entry_queries/query_error';
export const LoadingMore = 'canny/changelog_entry_queries/loading_more';
export const Invalidate = 'canny/changelog_entry_queries/invalidate';

// Actions

function requestQuery(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestQuery,
  };
}

function queryLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: QueryLoaded,
  };
}

function queryError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QueryError,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LoadingMore,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      const company = getState().company;
      if (company.error || company.notFound) {
        return dispatch(queryError('company load failed'));
      }

      if (company.changelog && !company.changelog.viewerHasAccess) {
        return dispatch(queryError('cannot access changelog'));
      }

      if (!queryParams) {
        return dispatch(queryError('Missing queryParams'));
      }

      const cookies = getState().cookies;
      return Data.fetch(
        {
          result: {
            input: {
              ...getRequestData(queryParams),
            },
            query: Data.queries.changelogEntries,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, queryParams, error, data);
        }
      );
    });
  };
}

/**
 * queryParams of type: {
 *   labels:
 *   status:
 *   type:
 * }
 */
export function loadQuery(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(changelogEntryList) {
  return (dispatch, getState) => {
    const { queryParams } = changelogEntryList;
    dispatch(loadingMore(queryParams));

    queryParams.pages = queryParams.pages ? queryParams.pages + 1 : 2;
    return dispatch(fetchQuery(queryParams));
  };
}

export function invalidateEntryQueries() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions

export function getEntryQueryKey(queryParams) {
  const queryObject = {};
  if (typeof queryParams.labels !== 'undefined') {
    queryObject.labels = queryParams.labels;
  }
  if (typeof queryParams.status !== 'undefined') {
    queryObject.status = queryParams.status;
  }
  if (typeof queryParams.type !== 'undefined') {
    queryObject.type = queryParams.type;
  }
  if (typeof queryParams.search !== 'undefined') {
    queryObject.search = queryParams.search;
  }
  return JSON.stringify(queryObject);
}

function getRequestData(queryParams) {
  const requestData = {
    pages: queryParams.pages ? queryParams.pages : 1,
  };
  if (typeof queryParams.labels !== 'undefined') {
    requestData.labels = queryParams.labels.split('_');
  }
  if (typeof queryParams.status !== 'undefined') {
    requestData.status = queryParams.status.split('_').filter((status) => {
      return status !== '';
    });
  }
  if (typeof queryParams.type !== 'undefined') {
    requestData.type = queryParams.type;
  }
  if (typeof queryParams.search !== 'undefined') {
    requestData.searchText = queryParams.search;
  }
  return requestData;
}

function shouldFetchQuery(state, queryParams) {
  const { changelogEntryQueries } = state;
  const queryKey = getEntryQueryKey(queryParams);
  return !changelogEntryQueries[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const { changelogEntryQueries } = state;
  const queryKey = getEntryQueryKey(queryParams);
  return !!changelogEntryQueries[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getEntryQueryKey(queryParams);
  return new Promise((resolve, reject) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, queryParams, error, data) {
  var resultAction;
  const promises = [];
  if (error) {
    resultAction = queryError(queryParams, error);
  } else {
    resultAction = queryLoaded(queryParams, data.result);
    data.result.entries.forEach((entry) => {
      promises.push(dispatch(entryLoaded(entry.urlName, entry)));
    });
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      const queryKey = getEntryQueryKey(queryParams);
      if (!resultCallbacks[queryKey]) {
        return;
      }

      resultCallbacks[queryKey].forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks[queryKey].length = 0;
    });
  });
}

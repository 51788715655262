import React, { useState } from 'react';

import AJAX from 'common/AJAX';
import Dropdown from 'common/Dropdown';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Message from 'common/message/Message';
import ConfirmModal from 'common/modals/ConfirmModal';
import DefaultCompanyRoleNames from 'common/roles/CompanyRoleNames';
import { companyRoleHasBillablePermissions } from 'common/roles/utils';
import Alert, { AlertTypes } from 'common/ui/Alert';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Company } from 'common/api/endpoints/companies';
import type { OpenModalContextType } from 'common/containers/ModalContainer';

import 'css/components/subdomain/admin/AdminOIDCSettings/_Form.scss';

type Props = {
  company: Company;
  openModal: OpenModalContextType;
  reloadCompany: () => void;
  setError: (e: string | null) => void;
  setTestPassed: (b: boolean) => void;
};

const knownErrors = [
  'Failed to fetch discovery document from specified URL.',
  'Either the client ID or authorization endpoint is invalid.',
  'Discovery document is missing one of the required endpoints.',
];

type Fields = 'clientID' | 'clientSecret' | 'defaultAccessLevel' | 'discoveryDocURL';

const SecretPlaceholder = '••••••••••••••••••••••••••••••••••••••••';
const MessageOrigin = devURL('https://canny.io');

const OIDCSettingsForm = ({
  company,
  openModal,
  reloadCompany,
  setError,
  setTestPassed,
}: Props) => {
  const { roles } = company;

  const roleOptions = [
    { name: 'user', render: 'User' },
    ...roles.map((role) => ({
      name: role.name.toLowerCase(),
      render: role.name,
    })),
  ];

  const preferredDefaultRole = company.features.adminRoles
    ? DefaultCompanyRoleNames.contributor.toLowerCase()
    : DefaultCompanyRoleNames.owner.toLowerCase();

  const defaultRole =
    roles.find((role) => role.name.toLowerCase() === preferredDefaultRole.toLowerCase()) ??
    roles[0];

  const [isInstalled, setIsInstalled] = useState(!!company.oidc);

  const [fields, setFields] = useState<Record<Fields, string>>({
    clientID: company.oidc?.clientID ?? '',
    clientSecret: '',
    defaultAccessLevel: company.oidc?.defaultAccessLevel ?? defaultRole.name.toLowerCase(),
    discoveryDocURL: company.oidc?.discoveryDocURL ?? '',
  });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingTest, setLoadingTest] = useState(false);
  const [loadingDisconnect, setLoadingDisconnect] = useState(false);
  const [hasChangedAuthSettings, setHasChangedAuthSettings] = useState(false);
  const [hasChangedRoleSettings, setHasChangedRoleSettings] = useState(false);

  const selectedRole = roles.find((role) => role.name.toLowerCase() === fields.defaultAccessLevel);

  const onFieldChange = (fieldName: Fields) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasChangedAuthSettings(true);
    setError(null);
    const { value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const onRoleChange = (value: string) => {
    setHasChangedRoleSettings(true);
    setError(null);
    setFields((prevFields) => ({
      ...prevFields,
      defaultAccessLevel: value,
    }));
  };

  const onSave = async () => {
    if (loadingSave) {
      return;
    }
    setTestPassed(false);
    setError(null);
    setLoadingSave(true);

    const response = await AJAX.post('/api/oidc/register', fields);

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: knownErrors.reduce((acc, error) => ({ ...acc, [error]: error }), {}),
    });

    setLoadingSave(false);

    if (error) {
      setError(error.message);
      return;
    }

    setHasChangedAuthSettings(false);
    setHasChangedRoleSettings(false);
    setIsInstalled(true);
  };

  const onDisconnect = () => {
    if (loadingDisconnect) {
      return;
    }
    setTestPassed(false);
    setError(null);

    openModal(ConfirmModal, {
      message: `Are you sure you'd like to disconnect your OpenID Connect integration?`,
      onConfirm: async () => {
        setLoadingDisconnect(true);

        const response = await AJAX.post('/api/oidc/disconnect', {});
        const { error } = parseAPIResponse(response, {
          isSuccessful: isDefaultSuccessResponse,
        });

        setLoadingDisconnect(false);
        if (error) {
          return setError(error.message);
        }

        setFields({
          clientID: '',
          clientSecret: '',
          defaultAccessLevel: defaultRole.name.toLowerCase(),
          discoveryDocURL: '',
        });
        setIsInstalled(false);
        reloadCompany();
      },
    });
  };

  const onTest = () => {
    const initiateUrl = devURL(
      `https://canny.io/api/oidc/initiate?companyID=${company._id}&validateConfig=true`
    );
    // No, 'noopener' because we trust business customer admins to not try to phish their owner users
    const oidcWindow = window.open(initiateUrl, '_blank');
    setLoadingTest(true);
    setTestPassed(false);
    setError(null);

    const unsubscribe = Message.subscribe(
      oidcWindow,
      MessageOrigin,
      'oidcValidation',
      (result: { validation?: string | null }) => {
        unsubscribe();
        setLoadingTest(false);
        if (result.validation !== 'success') {
          return setError('Something went wrong during the test, check your config and try again.');
        }
        setTestPassed(true);
      }
    );
  };

  const canSubmit =
    fields.discoveryDocURL &&
    fields.clientID &&
    fields.clientSecret &&
    !loadingDisconnect &&
    !loadingSave &&
    (hasChangedAuthSettings || hasChangedRoleSettings);

  return (
    <Form
      className="adminOIDCSettingsForm"
      addEventsToDocument={false}
      disableSubmit={!canSubmit || loadingSave}
      onSubmit={onSave}>
      <div className="inputs">
        <TextInput
          inset="Discovery document URL (well-known endpoint)"
          value={fields.discoveryDocURL}
          onChange={onFieldChange('discoveryDocURL')}
        />
        <TextInput
          inset="App Client ID"
          value={fields.clientID}
          onChange={onFieldChange('clientID')}
        />
        <TextInput
          className="clientSecretInput"
          inset="App Client Secret"
          value={fields.clientSecret}
          onChange={onFieldChange('clientSecret')}
          placeholder={isInstalled && !hasChangedAuthSettings ? SecretPlaceholder : ''}
        />
        <p className="text">
          Select which role you would like users to be assigned after successfully authenticating
          via&nbsp;OpenID Connect:
        </p>
        <Dropdown
          className="roleDropdown"
          defaultSelectedName={fields.defaultAccessLevel}
          onChange={onRoleChange}
          options={roleOptions}
        />
      </div>
      {selectedRole &&
        company.sliding?.admins &&
        companyRoleHasBillablePermissions(company, selectedRole) && (
          <Alert
            className="billingAlert"
            type={AlertTypes.Danger}
            headingText="Billing alert"
            subText={`The role "${selectedRole.name}" is billed per-admin and will incur additional charges.`}
          />
        )}
      <div className="buttons">
        <Button
          disabled={!canSubmit}
          formButton={true}
          loading={loadingSave}
          value={isInstalled ? 'Update' : 'Save'}
        />
        {isInstalled && <Button onTap={onTest} loading={loadingTest} value="Test" />}
        {isInstalled && (
          <Button
            buttonType="redButton"
            onTap={onDisconnect}
            loading={loadingDisconnect}
            value="Disconnect"
          />
        )}
      </div>
    </Form>
  );
};

export default OIDCSettingsForm;

import React from 'react';

import classnames from 'classnames';

import 'css/components/common/_InformationBox.scss';

type Props = {
  className?: string | null;
  icon: string;
  children: React.ReactNode;
};

const InformationBox = ({ className = null, icon, children }: Props) => {
  return (
    <div className={classnames('informationBox', className)}>
      <div className="infoboxIcon">{icon}</div>
      <div className="infoboxText">{children}</div>
    </div>
  );
};

export default InformationBox;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries, loadQuery } from 'common/actions/postQueries';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Dropdown from 'common/ControlledDropdown';
import asyncConnect from 'common/core/asyncConnect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import FileInput from 'common/inputs/FileInput';
import withAccessControl from 'common/routing/withAccessControl';
import { getPostQueryKey } from 'common/util/filterPosts';
import mapify from 'common/util/mapify';
import parseAPIResponse from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminBoardSettingsDataImport.scss';

const LongpollDuration = 5000;
const DetailsField = 'DetailsField';
const StatusField = 'StatusField';
const TagsField = 'TagsField';
const CategoryField = 'CategoryField';
const TitleField = 'TitleField';
const SkipField = 'SkipField';

const Fields = {
  [DetailsField]: {
    id: DetailsField,
    label: 'Details',
    name: 'details',
    validate: () => null,
  },
  [CategoryField]: {
    id: CategoryField,
    label: 'Category',
    name: 'category',
  },
  [SkipField]: {
    id: SkipField,
    label: 'Skip',
    name: 'skip',
    validate: () => null,
  },
  [StatusField]: {
    id: StatusField,
    label: 'Status',
    name: 'status',
  },
  [TagsField]: {
    id: TagsField,
    label: 'Tags',
    name: 'tags',
    validate: () => null,
  },
  [TitleField]: {
    id: TitleField,
    label: 'Title',
    name: 'title',
  },
};

const asyncFetch = {
  promise: ({ store: { dispatch, getState }, params }) => {
    const state = getState();
    const board = state.widget ? state.widget.board : state.boards.items[params.boardURLName];
    if (!board) {
      return;
    }

    const company = state.widget ? state.widget.company : state.company;
    if (!company) {
      return;
    }

    return dispatch(loadQuery({ board, query: 'postTitles' }));
  },
};

class AdminBoardSettingsDataImport extends Component {
  static propTypes = {
    company: PropTypes.object,
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    cellFields: [TitleField, DetailsField, StatusField, CategoryField, TagsField, SkipField],
    createdTask: null,
    csv: null,
    error: null,
    fieldCellMap: {
      [CategoryField]: 4,
      [DetailsField]: 1,
      [StatusField]: 2,
      [TagsField]: 3,
      [TitleField]: 0,
    },
    successfullyImported: null,
    isFirstRowHeader: false,
    uploading: false,
  };

  componentDidMount = () => {
    this._poll = null;
    this.pollBoard();
  };

  componentWillUnmount = () => {
    if (this._poll) {
      clearTimeout(this._poll);
      this._poll = null;
    }
  };

  pollBoard = () => {
    const { board, reloadBoard, reloadPostTitles } = this.props;
    const { createdTask } = this.state;
    const { tasks } = board;
    if (tasks.importPosts.length) {
      const currentTask = tasks.importPosts[0];

      if (createdTask && createdTask._id !== currentTask._id) {
        this.setState({ createdTask: null });
      }

      this._poll = setTimeout(() => {
        reloadBoard(board.urlName).then(this.pollBoard);
      }, LongpollDuration);
    } else if (createdTask) {
      reloadPostTitles(board);
      this.setState({
        createdTask: null,
        successfullyImported: createdTask.postCount,
      });
    }
  };

  onFieldSelect = (nextField, cellIndex) => {
    const { fieldCellMap } = this.state;
    const nextFieldCellMap = {
      ...fieldCellMap,
      [nextField]: cellIndex,
    };

    const currentField = Object.keys(fieldCellMap).find(
      (field) => fieldCellMap[field] === cellIndex
    );

    if (currentField) {
      nextFieldCellMap[currentField] = null;
    }

    this.setState({ fieldCellMap: nextFieldCellMap });
  };

  onFile = (file) => {
    if (!file) {
      return;
    }

    if (!validateInput.file(file)) {
      this.setState({
        error: 'Invalid file (5MB max, csv)',
        uploading: false,
      });
      return;
    }

    this.setState({
      error: null,
      successfullyImported: null,
      uploading: true,
    });

    AJAX.postFile('/api/util/parseCSV', { file }, {}, (responseString) => {
      const response = JSON.parse(responseString);
      if (response.error) {
        this.setState({
          error: response.error,
          uploading: false,
        });
        return;
      }

      const { csv } = response;
      this.setState({
        csv,
        uploading: false,
      });
    });
  };

  onImport = () => {
    const { board, invalidatePostQueries, reloadBoard } = this.props;
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;

    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    const fieldCount = rows[0].length;
    const cellFields = Array(fieldCount).fill(SkipField);
    Object.keys(fieldCellMap).forEach((field) => {
      const cell = fieldCellMap[field];
      if (cell < fieldCount) {
        cellFields[cell] = field;
      }
    });

    const posts = rows.map((row) =>
      cellFields.reduce((prev, field, i) => {
        if (field === SkipField) {
          return prev;
        }
        const { name } = Fields[field];
        return {
          ...prev,
          [name]: row[i],
        };
      }, {})
    );

    AJAX.post(
      '/api/posts/import',
      {
        boardID: board._id,
        posts,
      },
      (responseString) => {
        const { parsedResponse, error } = parseAPIResponse(responseString, {
          errors: {
            'not authorized':
              'You are not authorized to perform this operation. Please request additional permissions from a workspace owner/manager.',
            'invalid titles':
              'Some posts are missing titles. Please check the titles of the posts you are trying to upload.',
            'import in progress': 'There is already an import in progress. Please try again later.',
          },
          isSuccessful: (parsedResponse) => parsedResponse,
        });

        if (error) {
          this.setState({
            error: error.message,
            csv: null,
          });
          return;
        }

        this.setState({
          createdTask: {
            _id: parsedResponse.result.taskID,
            postCount: posts.length,
          },
          csv: null,
        });
        Promise.all([invalidatePostQueries(), reloadBoard(board.urlName)]).then(this.pollBoard);
      }
    );
  };

  countEmptyTitles() {
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;
    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    return rows.filter((row) => !row[fieldCellMap[TitleField]]).length;
  }

  countDuplicatedTitlesInFile() {
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;

    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    const firstRow = rows[0];

    if (firstRow[fieldCellMap[TitleField]] === undefined) {
      return 0;
    }
    const titles = rows.map((row) => row[fieldCellMap[TitleField]].toLowerCase());
    const titleSet = new Set(titles);

    return titles.length - titleSet.size;
  }

  countInvalidStatuses() {
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;

    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    const firstRow = rows[0];

    if (firstRow[fieldCellMap[StatusField]] === undefined) {
      return 0;
    }
    const statuses = rows.map((row) => row[fieldCellMap[StatusField]].toLowerCase());

    return statuses.filter((status) => !validateInput.companyPostStatus.name(status)).length;
  }

  getNewStatuses() {
    const { company } = this.props;
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;

    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    const firstRow = rows[0];

    if (firstRow[fieldCellMap[StatusField]] === undefined) {
      return [];
    }

    const statuses = rows.map((row) => row[fieldCellMap[StatusField]].toLowerCase().trim());
    const statusesMap = mapify(company.statuses, 'name');
    const validStatuses = statuses.filter((status) => validateInput.companyPostStatus.name(status));
    return validStatuses.filter((status) => !statusesMap[status]);
  }

  countTitleNameDuplicates() {
    const { board, postQueries } = this.props;
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;

    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    const firstRow = rows[0];

    if (firstRow[fieldCellMap[TitleField]] === undefined) {
      return 0;
    }

    const postQueryKey = getPostQueryKey({ board, query: 'postTitles' });
    const existingTitles = postQueries[postQueryKey].titles;
    const titleSet = new Set(existingTitles.map((title) => title.toLowerCase()));

    const newTitles = rows.map((row) => row[fieldCellMap[TitleField]].toLowerCase());

    return newTitles.filter((title) => titleSet.has(title)).length;
  }

  renderUploadButton() {
    const { csv, uploading } = this.state;
    const label = csv ? 'Reupload .CSV file' : 'Upload a .CSV file';
    return (
      <FileInput
        accept=".csv"
        disabled={uploading}
        onFile={this.onFile}
        value={<span className="uploadFileButtonn">{label}</span>}
      />
    );
  }

  renderErrors() {
    const { company } = this.props;
    const { csv, error } = this.state;
    let emptyTitleError,
      duplicateTitleWarning,
      duplicatedTitlesInFileWarning,
      invalidStatusWarning,
      newStatusWarning,
      generalError;

    if (csv) {
      const emptyTitleCount = this.countEmptyTitles();
      if (emptyTitleCount > 0) {
        emptyTitleError = (
          <div className="emptyTitleError">
            <div className="count">{emptyTitleCount}</div>
            <div className="warning">
              {emptyTitleCount === 1 ? 'post' : 'posts'} are missing titles
            </div>
            <Tooltip value="Post titles are a required field">
              <span className="info">?</span>
            </Tooltip>
          </div>
        );
      }

      const duplicateCount = this.countTitleNameDuplicates();
      if (duplicateCount > 0) {
        duplicateTitleWarning = (
          <div className="duplicateTitleWarning">
            <div className="count">{duplicateCount}</div>
            <div className="warning">
              {duplicateCount === 1 ? 'post' : 'posts'} will be modified
            </div>
            <Tooltip value="An existing post has the same title. The details, status, and tags will be replaced with any new values.">
              <span className="info">?</span>
            </Tooltip>
          </div>
        );
      }

      const duplicatedTitlesInFile = this.countDuplicatedTitlesInFile();
      if (duplicatedTitlesInFile > 0) {
        duplicatedTitlesInFileWarning = (
          <div className="duplicateTitleWarning">
            <div className="count">{duplicatedTitlesInFile}</div>
            <div className="warning">
              {duplicatedTitlesInFile === 1 ? 'post' : 'posts'} with non-unique titles in the import
              file.
            </div>
            <Tooltip value="Posts should have unique titles. Consolidate them to avoid data loss.">
              <span className="info">?</span>
            </Tooltip>
          </div>
        );
      }

      const invalidStatusCount = this.countInvalidStatuses();
      if (invalidStatusCount > 0) {
        invalidStatusWarning = (
          <div className="invalidStatusWarning">
            <div className="count">{invalidStatusCount}</div>
            <div className="warning">
              {invalidStatusCount === 1 ? 'post' : 'posts'} will be switched to the “open” status
            </div>
            <Tooltip value="There are invalid status names. Everything in red will default back to “open”.">
              <span className="info">?</span>
            </Tooltip>
          </div>
        );
      }

      const newStatuses = this.getNewStatuses();
      if (newStatuses.length > 0) {
        const isGated = !company.features.customStatuses;
        const message = isGated
          ? `${
              newStatuses.length === 1 ? 'status' : 'statuses'
            } will be transformed to “open”: ${newStatuses.join(', ')}`
          : `${
              newStatuses.length === 1 ? 'status' : 'statuses'
            } will be created: ${newStatuses.join(', ')}`;
        newStatusWarning = (
          <div className="invalidStatusWarning">
            <div className="count">{newStatuses.length}</div>
            <div className="warning">{message}</div>
            <Tooltip value="There are new statuses in the CSV.">
              <span className="info">?</span>
            </Tooltip>
          </div>
        );
      }
    }

    if (error) {
      generalError = <div className="error">{error}</div>;
    }

    return (
      <div className="errors">
        {emptyTitleError}
        {duplicateTitleWarning}
        {duplicatedTitlesInFileWarning}
        {invalidStatusWarning}
        {newStatusWarning}
        {generalError}
      </div>
    );
  }

  renderContents() {
    const { board } = this.props;
    const { csv, isFirstRowHeader, successfullyImported } = this.state;

    const { tasks } = board;
    if (tasks.importPosts.length) {
      const { progress } = tasks.importPosts[0];
      const percentage = Math.floor(progress * 100);
      return (
        <div className="importProgress">
          <div className="progressBar">
            <div className="bar" style={{ width: `${percentage}%` }} />
          </div>
          <div className="details">
            <div>Importing Posts...</div>
            <div>{percentage}%</div>
          </div>
        </div>
      );
    }

    if (!csv) {
      const successMessage = `${successfullyImported} ${
        successfullyImported === 1 ? 'post' : 'posts'
      } successfully imported`;
      return (
        <div className="buttonContainer">
          {successfullyImported && <div className="success">{successMessage}</div>}
          {this.renderUploadButton()}
          {this.renderErrors()}
        </div>
      );
    }

    const postCount = isFirstRowHeader ? csv.length - 1 : csv.length;
    const importButtonText = postCount === 1 ? 'Import 1 Post' : `Import ${postCount} Posts`;
    return (
      <div className="csv">
        <div className="toggleContainer">
          <Toggle
            onToggle={(isFirstRowHeader) => this.setState({ isFirstRowHeader })}
            value={isFirstRowHeader}
          />
          <div className="label">First row is the header</div>
        </div>
        {this.renderTable()}
        <div className="footer">
          {this.renderErrors()}
          <div className="buttons">
            {this.renderUploadButton()}
            <Button
              buttonType="cannyButton"
              className="submitButton"
              onTap={this.onImport}
              value={importButtonText}
            />
          </div>
        </div>
      </div>
    );
  }

  renderTable() {
    const { csv, fieldCellMap, isFirstRowHeader } = this.state;
    const fieldCount = csv[0].length;

    const cellFields = Array(fieldCount).fill(SkipField);
    Object.keys(fieldCellMap).forEach((field) => {
      const cell = fieldCellMap[field];
      if (cell < fieldCount) {
        cellFields[cell] = field;
      }
    });

    const rows = isFirstRowHeader ? csv.slice(-csv.length + 1) : csv;
    const firstTwentyRows = rows.slice(0, 20);
    const fieldOptions = [
      TitleField,
      DetailsField,
      StatusField,
      TagsField,
      CategoryField,
      SkipField,
    ].map((fieldKey) => {
      const { id, label } = Fields[fieldKey];
      return {
        name: id,
        render: label,
      };
    });

    return (
      <div className="tableContainer">
        <table className="table" style={{ width: fieldCount * 200 }}>
          <thead className="header">
            <tr>
              {cellFields.map((fieldKey, i) => (
                <th className="cell" key={i}>
                  <Dropdown
                    onChange={(field) => this.onFieldSelect(field, i)}
                    placeholder={'Select attribute'}
                    options={fieldOptions}
                    selectedName={fieldKey}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="rows">
            {firstTwentyRows.map((row, iRow) => (
              <tr className="row" key={iRow}>
                {row.map((cell, iCell) => {
                  return (
                    <td className="cell" key={iCell}>
                      {cell}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { board } = this.props;
    return (
      <div className="adminBoardSettingsDataImport">
        <Helmet title={'Import Data | ' + board.name + ' | Canny'} />
        <div className="message">
          Upload a .CSV file to populate your board with posts. You can optionally include the post
          status, category, and&nbsp;tags.
        </div>
        {this.renderContents()}
      </div>
    );
  }
}

export default compose(
  asyncConnect(
    [asyncFetch],
    (state) => ({
      postQueries: state.postQueries,
    }),
    (dispatch) => ({
      invalidatePostQueries: () =>
        Promise.all([dispatch(invalidateDashboardActivity()), dispatch(invalidatePostQueries())]),
      reloadBoard: (boardURLName) => dispatch(reloadBoard(boardURLName)),
      reloadPostTitles: (board) => dispatch(loadQuery({ board, query: 'postTitles' })),
    })
  ),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board['data-import']),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBoardSettingsDataImport);

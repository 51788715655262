import React, { Component } from 'react';

import classnames from 'classnames';
import { X } from 'lucide-react';
import PropTypes from 'prop-types';

import { CloseModalContext } from 'common/containers/ModalContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ButtonV2 from 'common/ui/ButtonV2';
import withContexts from 'common/util/withContexts';

import 'css/components/_PhotoViewer.scss';

class PhotoViewer extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    defaultIndex: PropTypes.number,
    imageURLs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };

  static defaultProps = {
    defaultIndex: 0,
  };

  state = {
    currentIndex: this.props.defaultIndex,
  };

  constructor(props, context) {
    super(props, context);

    this.viewerRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.viewerRef.current) {
        this.viewerRef.current.focus();
      }
    }, 0);
  }

  onKeyDown = (e) => {
    var keyCode = e.nativeEvent.keyCode;
    if (keyCode === 37) {
      // left
      this.previousImage();
    } else if (keyCode === 39) {
      // right
      this.nextImage();
    } else if (keyCode === 27) {
      // escape
      this.props.closeModal();
    }
  };

  previousImage = () => {
    var currentIndex = this.state.currentIndex;
    var newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex += this.props.imageURLs.length;
    }
    this.setState({
      currentIndex: newIndex,
    });
  };

  nextImage = (e) => {
    e && e.stopPropagation();
    var currentIndex = this.state.currentIndex;
    var newIndex = (currentIndex + 1) % this.props.imageURLs.length;
    this.setState({
      currentIndex: newIndex,
    });
  };

  renderImages() {
    const { imageURLs } = this.props;
    const { currentIndex } = this.state;

    return imageURLs.map((url, i) => (
      <button
        className="imageWrapper"
        key={i}
        onClick={this.nextImage}
        onKeyDown={(e) => e.preventDefault()}>
        <LazyLoadedImage
          className={classnames({ currentImage: i === currentIndex })}
          src={this.props.imageURLs[i]}
        />
      </button>
    ));
  }

  render() {
    return (
      <div className="photoViewer" ref={this.viewerRef} onKeyDown={this.onKeyDown} tabIndex="0">
        <ButtonV2
          className="closeButton"
          variant="outlined"
          color="primary"
          startIcon={X}
          onClick={() => {
            this.props.closeModal();
          }}>
          Close
        </ButtonV2>
        {this.renderImages()}
      </div>
    );
  }
}

export default withContexts({ closeModal: CloseModalContext }, { forwardRef: true })(PhotoViewer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import ContentContainer from 'common/containers/ContentContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import connect from 'common/core/connect';
import SubdomainCreatePostHelmet from 'common/helmets/SubdomainCreatePostHelmet';
import translateString from 'common/i18n/translateString';
import CreatePostForm from 'common/post/CreatePostForm';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_SubdomainCreatePost.scss';

class SubdomainCreatePost extends Component {
  static propTypes = {
    board: PropTypes.object,
    getPostLink: PropTypes.func,
    postList: PropTypes.object,
    router: PropTypes.object,
  };

  onPostCreated = (post) => {
    const { board, getPostLink, reloadBoard, router } = this.props;
    reloadBoard(board.urlName);
    router.push(getPostLink(post));
  };

  render() {
    const { board, postList } = this.props;
    return (
      <ContentContainer
        innerClassName="subdomainCreatePostContents"
        outerClassName="subdomainCreatePost">
        <SubdomainCreatePostHelmet board={board} />
        <div className="heading">{translateString(board.strings, 'createHeading')}</div>
        <CreatePostForm
          board={this.props.board}
          onPostCreated={this.onPostCreated}
          showSuggestedPosts={true}
          suggestedPosts={postList}
        />
      </ContentContainer>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return dispatch(reloadBoard(boardURLName));
    },
  })),
  withContexts(
    {
      getPostLink: GetPostLinkContext,
    },
    {
      forwardRef: true,
    }
  )
)(SubdomainCreatePost);

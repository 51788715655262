import React, { Component } from 'react';

import classnames from 'classnames';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import { invalidateAllQueries as invalidateAllTPCFeatureRequestQueries } from 'common/actions/thirdPartyCompanyFeatureRequestsQueries';
import { reloadVoters, updateVoteWeight } from 'common/actions/voters';
import AJAX from 'common/AJAX';
import Colors from 'common/colors/constants';
import Pill from 'common/common/Pill';
import { RevenueTimeframes, convertMRRToTimeframe } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import UnderlinedSelector from 'common/inputs/UnderlinedSelector';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ModalPortal from 'common/modals/ModalPortal';
import UpsellModal from 'common/modals/UpsellModal';
import { VoteWeight } from 'common/post/PostVotes/Constants';
import VoteWeightPill from 'common/post/VoteWeightPill';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import SingleSelect from 'common/ui/SingleSelect';
import UppercaseHeader from 'common/UppercaseHeader';
import UserLockup from 'common/user/UserLockup';
import calculateTotalMRR from 'common/util/calculateTotalMRR';
import getHostname from 'common/util/getHostname';
import { isNotNil } from 'common/util/isNil';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import HelpscoutLogo from 'img/helpscout-logo-small.png';
import HubspotLogo from 'img/hubspot-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import SalesforceLogo from 'img/salesforce-logo-small.png';
import ZendeskLogo from 'img/zendesk-logo-small.png';

import 'css/components/subdomain/admin/_AdminFeedbackVotersModal.scss';

const DefaultSegment = { name: 'all', render: 'all' };
const TypeOptions = [
  { name: 'voters', render: 'voters' },
  { name: 'opportunities', render: 'opportunities' },
];
const MonochromePillStyle = {
  color: Colors.gray110,
  background: Colors.gray30,
};

class AdminFeedbackVotersModal extends Component {
  static propTypes = {
    company: PropTypes.object,
    defaultOption: PropTypes.oneOf(['opportunities', 'voters']),
    loadAllVoters: PropTypes.func,
    loadVotersWithSegment: PropTypes.func,
    updateVoteWeight: PropTypes.func,
    onClose: PropTypes.func,
    post: PropTypes.object,
    refreshPost: PropTypes.func,
    voters: PropTypes.object,
  };

  state = {
    error: null,
    exporting: false,
    loading: true,
    loadingVoterID: null,
    removing: null,
    selectedOption: this.props.defaultOption,
    selectedSegment: DefaultSegment,
    showUpsellModal: false,
    voters: [],
    votersMRR: 0,
  };

  async componentDidMount() {
    const { loadAllVoters, post } = this.props;
    await loadAllVoters(post);
  }

  componentDidUpdate(prevProps) {
    const { voters } = this.props;
    if (voters !== prevProps.voters) {
      this.collectPostVoters();
      return;
    }
  }

  collectPostVoters() {
    const { post, voters } = this.props;
    const { loading, loadingVoterID } = this.state;
    const postVoters = voters?.[post._id]?.items || [];
    const votersMRR = voters?.[post._id]?.totalMRR || 0;

    if (loading || loadingVoterID) {
      this.setState({
        loading: false,
        loadingVoterID: null,
        voters: postVoters,
        votersMRR,
      });
    }
  }

  onPriorityChange = async (voter, weight) => {
    const { company, post, updateVoteWeight, showToast } = this.props;

    if (!company?.features?.voteWeights) {
      this.setState({ showUpsellModal: true });
      return;
    }

    const response = await AJAX.post('/api/votes/updateWeight', {
      voteID: voter.voteID,
      weight,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'vote not found': 'The vote does not exist. Refresh the page and try again',
        'not authorized': "You don't have permission to prioritize this vote",
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    this.setState({ loadingVoterID: voter._id });
    updateVoteWeight(post, voter, weight);
  };

  onExport = async () => {
    const { post, showToast } = this.props;
    const { selectedSegment } = this.state;

    this.setState({
      exporting: true,
      error: null,
    });

    const shouldFilterBySegment = selectedSegment.name !== DefaultSegment.name;
    const response = await AJAX.post('/api/posts/exportVoters', {
      postID: post._id,
      ...(shouldFilterBySegment ? { segmentURLName: selectedSegment.urlName } : null),
    });

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse.csv,
      errors: {
        'not authorized': "You don't have the permissions to export voters",
        default: 'Something went wrong. Please contact support',
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    const currentDateString = new Date().toISOString().slice(0, 10);
    const csvName = `Canny Voters Export - ${post.title} - ${currentDateString}.csv`;

    saveAs(new Blob([parsedResponse.csv]), csvName);

    this.setState({ exporting: false });
  };

  onOptionSelected = async (option) => {
    const { selectedOption } = this.state;
    if (selectedOption === option.name) {
      return;
    }

    this.setState(
      {
        selectedOption: option.name,
      },
      () => {
        if (option.name === 'voters') {
          this.onSegmentSelected(DefaultSegment);
        }
      }
    );
  };

  onRemoveOpportunity = async (removedOpportunity) => {
    const { post, refreshPost } = this.props;
    const { removing } = this.state;
    if (removing) {
      return;
    }

    this.setState({ removing: removedOpportunity._id });

    const response = await AJAX.post('/api/opportunities/removeLink', {
      opportunityID: removedOpportunity._id,
      postID: post._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (!error) {
      await refreshPost(post);
    }

    this.setState({ removing: null });
  };

  onRemoveVoter = async (removedVoter) => {
    const { post, refreshPost } = this.props;
    const { removing, voters } = this.state;
    if (removing) {
      return;
    }

    this.setState({ removing: removedVoter._id });

    const response = await AJAX.post('/api/posts/voteAs', {
      postID: post._id,
      score: 0,
      userID: removedVoter._id,
    });

    if (response === 'success') {
      refreshPost(post);
    }

    this.setState({
      voters: voters.filter((voter) => voter._id !== removedVoter._id),
      removing: null,
    });
  };

  onSegmentSelected = async (segment) => {
    const { post, loadAllVoters, loadVotersWithSegment } = this.props;
    const { selectedSegment } = this.state;

    if (selectedSegment.name === segment.name) {
      return;
    }

    this.setState({
      loading: true,
      selectedSegment: segment,
    });

    if (segment.name === DefaultSegment.name) {
      await loadAllVoters(post);
    } else {
      await loadVotersWithSegment(post, segment.urlName);
    }
  };

  renderTypeSelector = () => {
    const {
      post: { opportunities = [] },
    } = this.props;
    const { selectedOption } = this.state;
    const options = TypeOptions;

    return (
      <UnderlinedSelector
        defaultOptionName={selectedOption}
        disabled={!opportunities.length && selectedOption === 'voters'}
        onChooseOption={this.onOptionSelected}
        options={options}
      />
    );
  };

  renderSegmentSelector = () => {
    const { company } = this.props;
    const { selectedOption } = this.state;

    if (selectedOption === 'opportunities') {
      return 'all';
    }

    const segments =
      company?.segments.map((segment) => {
        return { ...segment, render: segment.name.toLowerCase() };
      }) ?? [];
    const options = [DefaultSegment, ...segments];

    return (
      <UnderlinedSelector
        defaultOptionName="all"
        onChooseOption={this.onSegmentSelected}
        options={options}
      />
    );
  };

  renderFilters = () => {
    const { company } = this.props;

    const planSupports = company?.features?.userSegmentation;
    if (!planSupports) {
      return null;
    }

    const segments = company?.segments ?? [];
    if (segments.length === 0) {
      return null;
    }

    return (
      <div className="voterFilters">
        <div className="menu">
          Showing {this.renderSegmentSelector()} {this.renderTypeSelector()}
        </div>
      </div>
    );
  };

  renderFooter() {
    const {
      company: { revenueTimeframe },
      post,
    } = this.props;
    const { error, selectedOption, votersMRR } = this.state;
    const totalSpend = convertMRRToTimeframe(votersMRR, revenueTimeframe);
    const usingARR = revenueTimeframe === RevenueTimeframes.arr;

    let value = `$${numberWithCommas(totalSpend)}/${usingARR ? 'yr' : 'mo'}`;

    // Opportunity spend doesn't have a timeframe unit (eg, 'yr' or 'mo'). It's just a fixed dollar value
    if (selectedOption === 'opportunities') {
      const opportunities = post.opportunities || [];
      const opportunityTotal = opportunities.reduce((prev, opportunity) => {
        if (!opportunity.value) {
          return prev;
        }
        return prev + opportunity.value;
      }, 0);
      value = `$${numberWithCommas(opportunityTotal)}`;
    }

    return (
      <div className="footer">
        <div className="voterNameColumn">
          {selectedOption === 'voters' && (
            <>
              <UppercaseHeader>
                <Tappable onTap={this.onExport}>
                  <span className="exportButton">Export List</span>
                </Tappable>
              </UppercaseHeader>
              <span className="exportError">{error}</span>
            </>
          )}
        </div>
        <div className="voterPriorityColumn">
          <UppercaseHeader>&nbsp;</UppercaseHeader>
        </div>
        <div className="voterSpendColumn">
          <UppercaseHeader>{value}</UppercaseHeader>
        </div>
        <div className="voterLinksColumn">
          <UppercaseHeader>&nbsp;</UppercaseHeader>
        </div>
        <div className="voterDateColumn">
          <UppercaseHeader>&nbsp;</UppercaseHeader>
        </div>
      </div>
    );
  }

  renderHeader() {
    const {
      company: { revenueTimeframe },
      post,
    } = this.props;
    const { selectedOption, voters } = this.state;
    const numVoters = Object.keys(voters).length;

    if (selectedOption === 'opportunities') {
      return (
        <div className="header">
          <div className="opportunityNameColumn">
            <UppercaseHeader>Accounts ({post.opportunities.length})</UppercaseHeader>
          </div>
          <div className="opportunityValueColumn">
            <UppercaseHeader>Amount</UppercaseHeader>
          </div>
          <div className="opportunityStatusColumn">
            <UppercaseHeader>Status</UppercaseHeader>
          </div>
          <div className="opportunityLinkColumn">
            <UppercaseHeader>Link</UppercaseHeader>
          </div>
        </div>
      );
    }

    return (
      <div className="header">
        <div className="voterNameColumn">
          <UppercaseHeader>Voters ({numVoters})</UppercaseHeader>
        </div>
        <div className="voterPriorityColumn">
          <UppercaseHeader>Priority</UppercaseHeader>
        </div>
        <div className="voterSpendColumn">
          <UppercaseHeader>
            {revenueTimeframe === RevenueTimeframes.arr ? 'Annual Spend' : 'Monthly Spend'}
          </UppercaseHeader>
        </div>
        <div className="voterLinksColumn">
          <UppercaseHeader>Link</UppercaseHeader>
        </div>
        <div className="voterDateColumn">
          <UppercaseHeader>Voted</UppercaseHeader>
        </div>
      </div>
    );
  }

  renderIntercomLink(voter) {
    const { company } = this.props;
    if (!company || !company.intercom || !company.intercom.appID) {
      return null;
    }

    if (!voter || !voter.intercomConversationID) {
      return null;
    }

    const conversationURL = `https://app.intercom.io/a/apps/${company.intercom.appID}/conversations/${voter.intercomConversationID}`;
    return (
      <a
        href={conversationURL}
        target="_blank"
        className="intercom"
        rel="noopener noreferrer nofollow">
        <LazyLoadedImage className="logo" src={IntercomLogo} />
      </a>
    );
  }

  renderOpportunityRow(opportunity) {
    const { link, name, status, value } = opportunity;
    const icons = {
      hubspot: HubspotLogo,
      salesforce: SalesforceLogo,
    };

    return (
      <div className="opportunityRow">
        <div className="opportunityNameColumn">
          <div className="opportunityName">{name}</div>
        </div>
        <div className="opportunityValueColumn">{`$${numberWithCommas(value)}`}</div>
        <div className="opportunityStatusColumn">
          <div className={`opportunityStatus ${status}`}>{status}</div>
        </div>
        <div className="opportunityLinkColumn">
          <a className="integration" href={link} rel="noopener noreferrer nofollow" target="_blank">
            <LazyLoadedImage className="logo" src={icons[opportunity.type]} />
          </a>
        </div>
        {this.renderRemoveOpportunityButton(opportunity)}
      </div>
    );
  }

  renderExternalLink(voter) {
    if (!voter?.externalLink) {
      return null;
    }

    const hostname = getHostname(voter.externalLink);
    const knownHosts = [
      { host: 'helpscout.net', icon: <LazyLoadedImage className="logo" src={HelpscoutLogo} /> },
      { host: 'hubspot.com', icon: <LazyLoadedImage className="logo" src={HubspotLogo} /> },
      { host: 'mail.google.com', icon: <div className="icon-email" /> },
    ];
    const host = knownHosts.find(({ host }) => hostname.endsWith(host));

    return (
      <a
        href={voter.externalLink}
        target="_blank"
        className="externalLink"
        rel="noopener noreferrer nofollow">
        {host?.icon ?? <div className="icon-open" />}
      </a>
    );
  }

  renderSalesforceLink(voter) {
    const { company } = this.props;
    if (!company || !company.salesforce || !company.salesforce.instanceURL) {
      return null;
    }
    if (!voter || !voter.salesforceUserID || !voter.salesforceUserType) {
      return null;
    }

    const {
      salesforce: { instanceURL },
    } = company;
    const { salesforceCaseID, salesforceUserID, salesforceUserType } = voter;
    const recordPath = salesforceCaseID
      ? `/lightning/r/Case/${salesforceCaseID}/view`
      : `/lightning/r/${salesforceUserType}/${salesforceUserID}/view`;

    return (
      <a
        className="salesforce"
        href={instanceURL + recordPath}
        rel="noopener noreferrer nofollow"
        target="_blank">
        <LazyLoadedImage className="logo" src={SalesforceLogo} />
      </a>
    );
  }

  renderHubspotLink(voter) {
    const { company } = this.props;

    const isHubspotAvailable = company?.hubspot?.authorized;
    const isVoterFromHubspot = voter?.hubspotUserID;
    if (!isHubspotAvailable || !isVoterFromHubspot) {
      return null;
    }

    const hubspotContactURL = `https://app.hubspot.com/contacts/${company.hubspot.hubspotID}/contact/${voter.hubspotUserID}/`;
    return (
      <a
        className="hubspot"
        href={hubspotContactURL}
        rel="noopener noreferrer nofollow"
        target="_blank">
        <LazyLoadedImage className="logo" src={HubspotLogo} />
      </a>
    );
  }

  renderTableBody() {
    const { post } = this.props;
    const { loading, selectedOption, voters } = this.state;

    if (selectedOption === 'opportunities') {
      return (
        <div className="table">
          {post.opportunities.map((opportunity) => (
            <div key={opportunity.salesforceOpportunityID || opportunity.hubspotDealID}>
              {this.renderOpportunityRow(opportunity)}
            </div>
          ))}
        </div>
      );
    }

    if (loading) {
      return (
        <div className="table loading">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="table">
        {voters.map((voter) => (
          <div key={voter._id}>{this.renderVoterRow(voter)}</div>
        ))}
      </div>
    );
  }

  renderRemoveOpportunityButton(opportunity) {
    const { removing } = this.state;

    if (removing === opportunity._id) {
      return (
        <div className="opportunityRemoveButton visible">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="opportunityRemoveButton">
        <Tappable onTap={() => this.onRemoveOpportunity(opportunity)}>
          <div className="icon icon-x" />
        </Tappable>
      </div>
    );
  }

  renderRemoveVoterButton(voter) {
    const { viewer } = this.props;
    const { removing } = this.state;

    if (removing === voter._id) {
      return (
        <div className="voterRemoveButton visible">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="voterRemoveButton">
        {voter.by && voter._id !== viewer._id ? (
          <Tappable onTap={() => this.onRemoveVoter(voter)}>
            <div className="icon icon-x" />
          </Tappable>
        ) : null}
      </div>
    );
  }

  renderVoterRow(voter) {
    const { loadingVoterID } = this.state;
    const {
      company: { revenueTimeframe },
    } = this.props;
    const totalSpend = numberWithCommas(
      convertMRRToTimeframe(calculateTotalMRR([voter]), revenueTimeframe)
    );

    return (
      <div className="voterRow">
        <div className="voterNameColumn">
          <UserLockup showCompanyNames={true} user={voter} />
        </div>
        <div className="voterPriorityColumn">
          <SingleSelect
            withBorder={false}
            loading={voter._id === loadingVoterID}
            placeholder={<Pill pillStyle={MonochromePillStyle}>No priority</Pill>}
            onChange={(selectedOption) => this.onPriorityChange(voter, selectedOption.value)}
            options={[
              {
                label: <VoteWeightPill weight={VoteWeight.niceToHave} />,
                value: VoteWeight.niceToHave,
              },
              {
                label: <VoteWeightPill weight={VoteWeight.important} />,
                value: VoteWeight.important,
              },
              {
                label: <VoteWeightPill weight={VoteWeight.mustHave} />,
                value: VoteWeight.mustHave,
              },
              {
                label: <Pill pillStyle={MonochromePillStyle}>No priority</Pill>,
                value: null,
              },
            ]}
            value={
              isNotNil(voter.weight)
                ? {
                    label: <VoteWeightPill weight={voter.weight} />,
                    value: voter.weight,
                  }
                : undefined
            }
          />
        </div>
        <div className="voterSpendColumn">${totalSpend}</div>
        <div className="voterLinksColumn">
          {this.renderHubspotLink(voter)}
          {this.renderIntercomLink(voter)}
          {this.renderSalesforceLink(voter)}
          {this.renderExternalLink(voter)}
          {this.renderZendeskLink(voter)}
        </div>
        <div className="voterDateColumn">
          <Timestamp timestamp={voter.voteCreated} />
        </div>
        {this.renderRemoveVoterButton(voter)}
      </div>
    );
  }

  renderZendeskLink(voter) {
    const { company } = this.props;
    if (!company || !company.zendesk || !company.zendesk.subdomain) {
      return null;
    }

    if (!voter || !voter.zendeskTicketID) {
      return null;
    }

    const ticketURL = `https://${company.zendesk.subdomain}.zendesk.com/agent/tickets/${voter.zendeskTicketID}`;
    return (
      <a href={ticketURL} target="_blank" className="zendesk" rel="noopener noreferrer nofollow">
        <LazyLoadedImage className="logo" src={ZendeskLogo} />
      </a>
    );
  }

  render() {
    const { onClose } = this.props;
    const { loading, showUpsellModal } = this.state;
    return (
      <>
        <ModalPortal className="adminFeedbackVotersModalPortal" onClose={onClose}>
          <div className="adminFeedbackVotersModal">
            {this.renderFilters()}
            <div className="list">
              <div
                className={classnames({
                  stretchToMaxContentWidth: true,
                  loading,
                })}>
                {this.renderHeader()}
                {this.renderTableBody()}
                {this.renderFooter()}
              </div>
            </div>
          </div>
        </ModalPortal>
        <UpsellModal
          feature="features.voteWeights"
          onClose={() => this.setState({ showUpsellModal: false })}
          onUpsell={() => this.setState({ showUpsellModal: false })}
          show={showUpsellModal}
        />
      </>
    );
  }
}

export default compose(
  connect(
    (state) => ({ voters: state.voters }),
    (dispatch) => ({
      async updateVoteWeight(post, voter, weight) {
        await Promise.all([
          dispatch(updateVoteWeight(post, voter, weight)),
          dispatch(reloadRoadmapPostsForRoadmapsWithPost(post)),
          dispatch(invalidateAllTPCFeatureRequestQueries()),
        ]);
      },
      async refreshPost(post) {
        await Promise.all([
          dispatch(invalidateDashboardActivity()),
          dispatch(invalidatePostQueries()),
          dispatch(reloadPost(post)),
          dispatch(reloadVoters(post)),
        ]);
      },
      async loadAllVoters(post) {
        await dispatch(reloadVoters(post));
      },
      async loadVotersWithSegment(post, segmentURL) {
        await dispatch(reloadVoters(post, segmentURL));
      },
    })
  ),
  withContexts(
    {
      company: CompanyContext,
      viewer: ViewerContext,
      showToast: ShowToastContext,
    },
    { forwardRef: true }
  )
)(AdminFeedbackVotersModal);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import Tappable from 'common/Tappable';
import emphasizeMatches from 'common/util/emphasizeMatches';
import findStringMatches from 'common/util/findStringMatches';
import mapify from 'common/util/mapify';
import parseAPIResponse from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';
import validateInput from 'common/validateInput';

import 'css/components/tags/_TagSelector.scss';

export default class TagSelector extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(
      PropTypes.shape({
        tags: PropTypes.array,
      })
    ),
    excludeTags: PropTypes.array,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onTagSelected: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'Search...',
  };

  state = {
    error: null,
    focused: false,
    mouseDown: false,
    searchValue: '',
  };

  constructor(props, context) {
    super(props, context);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { allowTagCreation } = this.props;

    document.addEventListener('mousedown', this.onMouseDown, false);
    document.addEventListener('mouseup', this.onMouseUp, false);

    if (allowTagCreation && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
  }

  blur = () => {
    this.inputRef.current.blur();
    this.setState({
      focused: false,
    });
  };

  onBlur = () => {
    this.setState({
      focused: false,
    });
  };

  onChange = (e) => {
    this.setState({
      error: null,
      searchValue: e.target.value.trim(),
    });
  };

  onFocus = () => {
    this.props.onFocus && this.props.onFocus();

    this.setState({
      focused: true,
    });
  };

  onKeyDown = (e) => {
    const { allowTagCreation, excludeTags } = this.props;
    const { searchValue, isNewTagIsBeingCreated } = this.state;

    if (!allowTagCreation || !searchValue) {
      return;
    }

    if (e.keyCode === KeyCodes.Enter) {
      const allTags = Object.values(this.getTagsFromBoards());
      const excludedTagSet = mapify(excludeTags, 'urlName');
      const availableTags = allTags.filter((tag) => !excludedTagSet[tag.urlName]);

      const tagAlreadyApplied = excludeTags.some((tag) => tag.name === searchValue);
      if (tagAlreadyApplied) {
        return;
      }

      const exactTagMatch = availableTags.find((tag) => tag.name === searchValue);
      if (exactTagMatch) {
        this.onTagSelected(exactTagMatch);
        return;
      }
      if (!isNewTagIsBeingCreated) {
        this.createNewTag();
      }
    }
  };

  onMouseDown = () => {
    const { focused } = this.state;
    if (!focused) {
      return;
    }

    this.setState({
      mouseDown: true,
    });
  };

  onMouseUp = () => {
    this.setState({
      mouseDown: false,
    });
  };

  onTagSelected = (tag) => {
    this.props.onTagSelected(tag);

    this.inputRef.current.blur();
    this.inputRef.current.setValue('');

    this.setState({
      error: null,
      focused: false,
      searchValue: '',
    });
  };

  getTagsFromBoards = () => {
    const { boards } = this.props;

    const tagMap = {};
    boards.forEach((board) => {
      board.tags.forEach((tag) => {
        tagMap[tag.urlName] = {
          _id: tag._id,
          name: tag.name,
          urlName: tag.urlName,
        };
      });
    });
    return tagMap;
  };

  createNewTag = () => {
    const { boards, onTagCreated } = this.props;
    const tagName = this.inputRef.current.getValue();
    const board = boards[0];

    const setError = (error) => {
      this.setState({
        error,
        searchValue: '',
      });
    };

    if (!validateInput.tags.name(tagName)) {
      setError('Please enter a valid tag name. (1-30 characters)');
      return;
    } else if (board.tags.find(({ name }) => name === tagName)) {
      setError('This tag name already exists');
      return;
    }

    this.setState({ error: null, isNewTagIsBeingCreated: true });

    AJAX.post(
      '/api/tags/create',
      {
        boardID: board._id,
        name: tagName,
      },
      (response) => {
        const { error, parsedResponse } = parseAPIResponse(response, {
          isSuccessful: (tag) => tag._id,
        });
        this.setState({ isNewTagIsBeingCreated: false });

        if (error) {
          setError(error.message);
          return;
        }

        this.setState(
          {
            error: null,
            searchValue: '',
          },
          () => {
            onTagCreated(parsedResponse);
            this.inputRef.current.setValue('');
            this.blur();
          }
        );
      }
    );
  };

  renderError = () => {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return (
      <div className="createForm">
        <div className="error">{error}</div>
      </div>
    );
  };

  renderInput() {
    return (
      <div className="tagInput">
        <TextInput
          disabled={this.props.disabled ?? false}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          placeholder={this.props.placeholder}
          ref={this.inputRef}
          onKeyDown={this.onKeyDown}
        />
        {this.renderSuggestions()}
      </div>
    );
  }

  renderSuggestions() {
    const { focused, mouseDown, searchValue } = this.state;
    const { excludeTags, allowTagCreation } = this.props;

    if (!focused && !mouseDown) {
      return null;
    }

    const tagMap = this.getTagsFromBoards();

    const excludedTagSet = mapify(excludeTags, 'urlName');
    const filteredTags = Object.values(tagMap)
      .filter((tag) => {
        return !excludedTagSet[tag.urlName];
      })
      .sort(stringSort('name'));
    const matches = findStringMatches(filteredTags, 'name', searchValue);

    const suggestions = matches.map((tag) => {
      return (
        <Tappable key={tag.urlName} onTap={this.onTagSelected.bind(this, tag)}>
          <div className="suggestion">{emphasizeMatches(tag.name, searchValue)}</div>
        </Tappable>
      );
    });

    if (allowTagCreation) {
      const excludedTags = Object.values(tagMap);
      const hasSameName = (tag) => tag.name === searchValue;
      const tagExists = excludedTags.some(hasSameName);
      if (suggestions.length === 0 && tagExists) {
        return (
          <div className="suggestions">
            <div className="noMatches">This post is already tagged with&nbsp;"{searchValue}"</div>
          </div>
        );
      }

      if (!tagExists && searchValue !== '') {
        suggestions.push(
          <Tappable key="create" onTap={this.createNewTag}>
            <div className="suggestion createNew">
              <div className="icon icon-plus" />
              Create new tag
            </div>
          </Tappable>
        );
      }
    }

    if (suggestions.length === 0) {
      return (
        <div className="suggestions">
          <div className="noMatches">There are no matching&nbsp;tags</div>
        </div>
      );
    }

    return <div className="suggestions">{suggestions}</div>;
  }

  render() {
    return (
      <div className="tagSelector">
        {this.renderInput()}
        {this.renderError()}
      </div>
    );
  }
}

import { useEffect } from 'react';

import Portal from 'common/common/Portal';

type OnClick = () => void;
type IgnoredElements = React.RefObject<HTMLElement | Portal>[];

const useBackgroundClick = (onClick: OnClick, ignoredElement: IgnoredElements = []) => {
  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      const ignoreByClass =
        e.target instanceof HTMLElement && e.target.closest('.ignoreModalClickAway');

      const shouldIgnore =
        ignoreByClass ||
        ignoredElement.some(
          (ref) =>
            (ref.current instanceof Portal && ref?.current?.containsNode(e.target)) || // If a portal is clicked
            (ref.current instanceof HTMLElement &&
              e.target instanceof HTMLElement &&
              ref?.current?.contains(e.target))
        ); // If an element is clicked

      if (shouldIgnore) {
        return;
      }
      onClick();
    };

    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [onClick, ignoredElement]);
};

export default useBackgroundClick;

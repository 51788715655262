import {
  InvalidateAllPostActivities,
  InvalidatePostActivities,
  PostActivityError,
  PostActivityLoaded,
  PostActivityNotFound,
  RequestPostActivity,
} from '../actions/postsActivity';

export default function posts(state = {}, action) {
  switch (action.type) {
    case InvalidateAllPostActivities: {
      return {};
    }

    case InvalidatePostActivities: {
      const { posts } = action;
      const newState = Object.assign({}, state);
      posts.forEach((post) => delete newState[post._id]);
      return newState;
    }

    case RequestPostActivity: {
      return Object.assign({}, state, {
        [action.post._id]: Object.assign({}, state[action.post._id], {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
          notFound: false,
          options: action.options,
        }),
      });
    }

    case PostActivityLoaded: {
      return Object.assign({}, state, {
        [action.post._id]: Object.assign(
          {},
          {
            error: null,
            lastUpdated: action.timestamp,
            loading: false,
            notFound: false,
            options: action.options,
          },
          action.postActivity
        ),
      });
    }

    case PostActivityNotFound: {
      return Object.assign({}, state, {
        [action.post._id]: Object.assign({}, state[action.post._id], {
          lastUpdated: action.timestamp,
          loading: false,
          notFound: true,
        }),
      });
    }

    case PostActivityError: {
      return Object.assign({}, state, {
        [action.post._id]: Object.assign({}, state[action.post._id], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        }),
      });
    }

    default:
      return state;
  }
}

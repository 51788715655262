import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import { invalidateReactions, loadReactions } from 'common/actions/userReactions';
import AJAX from 'common/AJAX';
import Colors from 'common/colors/constants';
import Overlay from 'common/common/Overlay';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { SSOTokenContext } from 'common/containers/SSOTokenContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import IsAdminViewContext from 'common/contexts/IsAdminViewContext';
import connect from 'common/core/connect';
import Message from 'common/message/Message';
import ReactorsModal from 'common/modals/ReactorsModal';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import { P } from 'common/ui/Text';
import UserLockup from 'common/user/UserLockup';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import withContexts from 'common/util/withContexts';
import { ConfigContext } from 'common/widget/WidgetContext';

import 'css/components/reaction/_ReactionsMenu.scss';

const AllowedObjectTypes = ['changelog', 'comment'];

class ReactionsMenu extends Component {
  _submittingReactions = {};

  static propTypes = {
    allowedReactions: PropTypes.shape({
      like: PropTypes.func,
    }).isRequired,
    authRedirectEnabled: PropTypes.bool,
    className: PropTypes.string,
    object: PropTypes.shape({
      reactions: PropTypes.object,
      viewerReactions: PropTypes.object,
    }).isRequired,
    objectType: PropTypes.oneOf(AllowedObjectTypes),
    company: PropTypes.shape({
      subdomain: PropTypes.string,
      viewerIsMember: PropTypes.bool,
    }),
    config: PropTypes.object,
    isAdminView: PropTypes.bool,
    isWidget: PropTypes.bool,
    location: PropTypes.object,
    openModal: PropTypes.func,
    reloadObjectData: PropTypes.func.isRequired,
    showReactorNames: PropTypes.bool,
    ssoToken: PropTypes.string,
    tintColor: PropTypes.string,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      admin: PropTypes.bool,
    }),
  };

  static defaultProps = {
    authRedirectEnabled: true,
  };

  state = {
    reactions: this.props.object.reactions ?? {},
    reactionsHovered: {},
    viewerReactions: this.props.object.viewerReactions ?? {},
  };

  componentDidUpdate(prevProps) {
    const { object } = this.props;
    const { reactions, viewerReactions } = object;

    if (reactions && reactions !== prevProps.object.reactions) {
      this.setState({ reactions });
    }

    if (viewerReactions !== prevProps.object.viewerReactions) {
      this.setState({ viewerReactions: viewerReactions ?? {} });
    }
  }

  authenticateUserAndSubmitReaction = async (reactionName) => {
    const { company, config, isWidget, location, openModal, ssoToken, viewer } = this.props;
    const { authRedirectEnabled, authRedirectURL } = company;

    if (viewer?._id || ssoToken) {
      await this.submitReaction(reactionName);
      return;
    }

    // if viewer._id is not present, authenticate viewer
    if (!authRedirectEnabled || !authRedirectURL) {
      openModal(AccountModal, {
        formState: FormStates.signup,
        onSuccess: async (refreshCallback) => {
          await this.submitReaction(reactionName, refreshCallback);
        },
      });

      return;
    }

    const redirectURL = getAuthRedirectURL(company, location, config);
    if (isWidget) {
      Message.postMessage(window.parent, '*', 'redirect', redirectURL);
    } else {
      window.location.assign(redirectURL);
    }
  };

  isReactionsDisabled = () => {
    const { authRedirectEnabled, ssoToken, viewer } = this.props;
    return !ssoToken && !viewer?._id && !authRedirectEnabled;
  };

  submitReaction = async (reactionName, refreshCallback) => {
    const { company, invalidateReactions, object, objectType, reloadObjectData } = this.props;
    const { viewerReactions } = this.state;
    const viewerDidReact = viewerReactions[reactionName];
    let url;

    if (!viewerDidReact) {
      url = '/api/userReactions/create';
      this.setState((state) => ({
        reactions: { ...state.reactions, [reactionName]: (state.reactions[reactionName] ?? 0) + 1 },
        reactionsHovered: { ...state.reactionsHovered, [reactionName]: false },
        viewerReactions: { ...state.viewerReactions, [reactionName]: true },
      }));
    } else {
      url = '/api/userReactions/delete';
      this.setState((state) => ({
        reactions: { ...state.reactions, [reactionName]: state.reactions[reactionName] - 1 },
        reactionsHovered: { ...state.reactionsHovered, [reactionName]: false },
        viewerReactions: { ...state.viewerReactions, [reactionName]: false },
      }));
    }

    this._submittingReactions[reactionName] = true;
    invalidateReactions(object._id, reactionName);

    await AJAX.post(url, {
      company,
      objectID: object._id,
      objectType,
      reactionName,
    });

    if (refreshCallback) {
      refreshCallback();
      return;
    }

    await reloadObjectData();
    this._submittingReactions[reactionName] = false;
  };

  onReactionClicked = async (reactionName) => {
    const { viewer } = this.props;
    const disabled = this.isReactionsDisabled();

    if (disabled || this._submittingReactions[reactionName] || viewer?.loading) {
      return;
    }

    await this.authenticateUserAndSubmitReaction(reactionName);
  };

  onReactionHovered = (reactionName) => {
    const { isAdminView, loadReactions, object, objectType, showReactorNames } = this.props;

    this.setState((state) => ({
      reactionsHovered: { ...state.reactionsHovered, [reactionName]: true },
    }));

    if (isAdminView || showReactorNames) {
      loadReactions(object._id, objectType, reactionName);
    }
  };

  onReactionLeft = (reactionName) => {
    this.setState((state) => ({
      reactionsHovered: { ...state.reactionsHovered, [reactionName]: false },
    }));
  };

  onShowMoreReactorsClicked = (reactionName) => {
    const { object, userReactions } = this.props;
    const reactors = userReactions[object._id][reactionName].items.map(
      (userReaction) => userReaction.user
    );
    this.props.openModal(ReactorsModal, { reactors });
  };

  renderReactorsBody(reactionName) {
    const { object, userReactions } = this.props;
    const reactions = userReactions[object._id]?.[reactionName];

    if (reactions && !reactions.loading && reactions.items?.length > 0) {
      const firstFive = reactions.items.slice(0, 5);
      const restCount = reactions.items.length - 5;

      return (
        <div className="reactors">
          {firstFive.map((item) => {
            return (
              <div className="reactor" key={item.user._id}>
                <UserLockup showBadge={true} showProfile={true} user={item.user} />
              </div>
            );
          })}
          {restCount > 0 && (
            <Tappable onTap={() => this.onShowMoreReactorsClicked(reactionName)}>
              <div className="more">
                <button>and {restCount} more...</button>
              </div>
            </Tappable>
          )}
        </div>
      );
    }

    if (reactions.loading) {
      return (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      );
    }
    return null;
  }

  renderReactors(reactionName) {
    const { isAdminView, showReactorNames } = this.props;
    const { reactions, reactionsHovered } = this.state;

    const reactionCount = reactions[reactionName] ?? 0;
    const reactionHovered = reactionsHovered[reactionName];
    const showDropdown = isAdminView || showReactorNames;
    const showReactors = reactionHovered && showDropdown && reactionCount > 0;

    if (!showReactors) {
      return null;
    }

    return (
      <Overlay open={reactionHovered} top={10}>
        {this.renderReactorsBody(reactionName)}
      </Overlay>
    );
  }

  renderReactions() {
    const { allowedReactions, tintColor } = this.props;
    const { reactions, reactionsHovered, viewerReactions } = this.state;
    const disabled = this.isReactionsDisabled();

    return (
      <>
        {Object.entries(allowedReactions).map(([reactionName, renderFunc]) => {
          const reactionCount = reactions[reactionName] ?? 0;
          const viewerDidReact = !!viewerReactions[reactionName];
          const reactionHovered = reactionsHovered[reactionName];
          const pluralReactionName = reactionCount > 1 ? `${reactionName}s` : reactionName;
          const containerClassName = classnames('reactionComponentContainer', { disabled });
          let color;

          if (!disabled && viewerDidReact) {
            color = tintColor;
          } else if (!disabled && reactionHovered) {
            color = Colors.gray90;
          }

          if (disabled && reactionCount === 0) {
            return null;
          }

          return (
            <div
              className="reaction"
              key={reactionName}
              onMouseEnter={() => this.onReactionHovered(reactionName)}
              onMouseLeave={() => this.onReactionLeft(reactionName)}>
              <button
                className="reactionButton"
                onClick={() => this.onReactionClicked(reactionName)}>
                <div className={containerClassName}>
                  {renderFunc({ disabled, fill: color, stroke: color })}
                  {reactionCount > 0 && (
                    <P className="reactionCount" variant="bodyMd">
                      {reactionCount} {pluralReactionName}
                    </P>
                  )}
                </div>
              </button>
              {this.renderReactors(reactionName)}
            </div>
          );
        })}
      </>
    );
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="reactionsMenu">{this.renderReactions()}</div>
      </div>
    );
  }
}

export default compose(
  connect(
    ({ userReactions }) => ({ userReactions }),
    (dispatch) => ({
      invalidateReactions: (objectID, reactionName) =>
        dispatch(invalidateReactions(objectID, reactionName)),
      loadReactions: (objectID, objectType, reactionName) =>
        dispatch(loadReactions(objectID, objectType, reactionName)),
    })
  ),
  withContexts(
    {
      company: CompanyContext,
      config: ConfigContext,
      isAdminView: IsAdminViewContext,
      isWidget: IsWidgetContext,
      location: LocationContext,
      openModal: OpenModalContext,
      ssoToken: SSOTokenContext, // Required for ChangelogWidget
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(ReactionsMenu);

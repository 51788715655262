import diacritics from './diacritics';

const Dashes = /[-]/gi;
const Delimiters = /[/,+:;_]/gi;
const MaxLength = 80;
const Spaces = /[ ]/gi;
const UnallowedCharacters = /[^a-z0-9- ]/gi;

export default function urlNamify(input: string): string {
  let urlName = input.toLowerCase();
  urlName = diacritics.remove(urlName);
  urlName = urlName.replace(Delimiters, ' ');
  urlName = urlName.replace(UnallowedCharacters, '');
  urlName = urlName.replace(Dashes, ' ');
  urlName = urlName.toLowerCase().trim();
  urlName = urlName.replace(Spaces, '-');
  urlName = urlName.replace(/-+/g, '-');
  urlName = urlName.substr(0, MaxLength);
  urlName = urlName.replace(/-+$/g, '');
  return urlName;
}

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadZoomSettings } from 'common/actions/zoomSettings';
import AJAX from 'common/AJAX';
import { IntegrationItemNames, SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ZoomSettingsContext } from 'common/containers/ZoomSettingsContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import SpinnerV2 from 'common/SpinnerV2';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationAutopilotSettingsSection from 'common/subdomain/admin/AdminQueue/AdminIntegrationAutopilotSettingsSection/AdminIntegrationAutopilotSettingsSection';
import { getZoomOAuthURL } from 'common/subdomain/admin/AdminQueue/GettingStarted/utils';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/_AdminZoomSettings.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { ZoomSettings } from 'common/api/endpoints/zoom';

type OwnProps = {
  zoomSettings: ZoomSettings;
};

type ConnectProps = {
  reloadZoomSettings(): Promise<void>;
};

type Props = OwnProps & ConnectProps;

// For more information, visit: https://developers.zoom.us/docs/integrations/oauth/
const Zoom = 'zoom';

const AdminZoomSettings = (props: Props) => {
  // props
  const { reloadZoomSettings } = props;

  // context
  const company = useContext<Company>(CompanyContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const showToast = useContext(ShowToastContext);
  const zoomSettings = useContext(ZoomSettingsContext);

  // state
  const [loading, setLoading] = useState<boolean>(false);

  // helpers
  const sendRequest = useCallback(
    async (url: string, params: Record<string, unknown> = {}) => {
      setLoading(true);

      const response = await AJAX.post(url, params);
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        setLoading(false);
        showToast(error.message, ToastTypes.error);
        return;
      }

      await reloadZoomSettings();
      setLoading(false);
    },
    [reloadZoomSettings, showToast]
  );

  const install = useCallback(
    async (code: string) => sendRequest('/api/zoom/install', { code }),
    [sendRequest]
  );

  const uninstall = useCallback(async () => sendRequest('/api/zoom/uninstall', {}), [sendRequest]);

  // effects
  useEffect(() => {
    const { code, integration } = location.query;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    if (integration === Zoom) {
      install(code);
    }
  }, [install, location.query, location.pathname, router]);

  // renderers
  const Contents = () => {
    const installation = zoomSettings?.data?.installation;
    const hasInstalled = installation && !!installation?.installed && !installation?.lostAccess;

    if (!company?.integrations?.zoom) {
      return <AdminFeatureUpsell feature="zoom" />;
    } else if (loading) {
      return <SpinnerV2 size="xxxxlarge" />;
    } else if (!hasInstalled) {
      return <InstallButton />;
    }

    return (
      <>
        <Alert
          type={AlertTypes.Confirmation}
          className={styles.alert}
          headingText="Zoom connected"
          subText={`${installation.userEmail} is linked to your workspace. New transcripts will be analyzed for feedback within 24 hours.`}
          button={
            <ButtonV2 size="medium" onClick={uninstall} color="error" variant="outlined">
              Uninstall
            </ButtonV2>
          }
        />
        <AdminIntegrationAutopilotSettingsSection
          integrationLabel="Zoom"
          integrationName={SyncIntegrationNames.zoom}
          integrationType={IntegrationItemNames.call}
        />
      </>
    );
  };

  const InstallButton = () => {
    const hasLostAccess = !!zoomSettings?.data?.installation?.lostAccess;
    const ctaValue = hasLostAccess ? 'Reinstall Zoom' : 'Install Zoom';
    const zoomURL = getZoomOAuthURL(company.subdomain, false);

    return (
      <div>
        {hasLostAccess && (
          <Alert
            className={styles.alert}
            type={AlertTypes.Danger}
            headingText="Your Zoom account is disconnected."
            subText="The connection between Canny and Zoom has been lost. Please reinstall the integration."
          />
        )}
        <div className={styles.installButtons}>
          <a href={zoomURL} className={styles.buttonContainer} rel="noreferrer noopener nofollow">
            <ButtonV2 size="medium">{ctaValue}</ButtonV2>
          </a>
          {hasLostAccess && (
            <ButtonV2 variant="ghost" size="medium" onClick={uninstall} color="error">
              Uninstall
            </ButtonV2>
          )}
        </div>
      </div>
    );
  };

  return (
    <main className={styles.adminZoomSettings}>
      <Helmet title="Zoom Integration | Canny" />
      <AdminSettingsHeader
        title="Zoom Integration"
        subheading="Automatically detect and gather feedback from Zoom transcripts."
        learnMoreLink="https://help.canny.io/en/articles/9972065-zoom-integration"
      />
      <div className={styles.content}>
        <Contents />
      </div>
    </main>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadZoomSettings: () => dispatch(reloadZoomSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.zoom),
    '/admin/settings'
  )
)(AdminZoomSettings) as unknown as React.FC<OwnProps>;

import React, { Component } from 'react';

import 'css/components/modals/_Modal.scss';

export default class Modal extends Component {
  render() {
    const className = this.props.className ? 'modal ' + this.props.className : 'modal';
    return (
      <div className={className} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

import React, { useContext, useEffect, useState } from 'react';

import { compose } from 'redux';

import AJAX from 'common/AJAX';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { CookieNames } from 'common/cookies/cookies';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import { SupportedLanguages } from 'common/i18n/supportedLanguages';
import Button from 'common/inputs/Button';
import SingleSelect from 'common/ui/SingleSelect';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import 'css/components/subdomain/admin/_AdminLanguageSettings.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  preferredLanguage?: string;
};

type Props = OwnProps & ConnectProps;

const AdminLanguageSettings = ({ preferredLanguage }: Props) => {
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const showToast = useContext(ShowToastContext);

  const [editing, setEditing] = useState(false);
  const [hasEdits, setHasEdits] = useState(false);
  const [locale, setLocale] = useState(preferredLanguage || 'browser');

  useEffect(() => {
    if (location.query?.success) {
      router.replace({
        pathname: location.pathname,
        query: null,
      });
      showToast('Language preferences updated', ToastTypes.success);
    }
  }, [location.pathname, location.query, router, showToast]);

  const onLocaleChange = (selectedOption?: { value: string }) => {
    if (!selectedOption) {
      return;
    }

    setHasEdits(true);
    setLocale(selectedOption.value);
  };

  const onSubmit = async () => {
    if (!hasEdits) {
      return;
    }

    setEditing(true);
    setHasEdits(false);

    const response = await AJAX.post('/api/viewer/setPreferredLanguage', {
      language: locale === 'browser' ? null : locale,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    setEditing(false);

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('success', 'true');
    url.search = params.toString();
    window.location.replace(url.toString());
  };

  const localeOptions = [
    {
      label: 'Use browser language',
      value: 'browser',
    },
    ...Object.entries(SupportedLanguages).map(([languageName, localeCode]) => ({
      label: languageName,
      value: localeCode,
    })),
  ];
  const selectedOption = localeOptions.find((localeOption) => {
    return locale === localeOption.value;
  });
  return (
    <div className="adminLanguageSettings">
      <Helmet title="Language Preferences | Canny" />
      <H2 className="heading" variant="bodyMd" fontWeight="bold">
        Language preferences
      </H2>
      <P>
        Set what language you would like to see content in.
        <a
          className="learnMore"
          href="https://help.canny.io/en/articles/9091829-content-translations"
          target="_blank"
          rel="noreferrer">
          Learn more
        </a>
      </P>
      <div className="content">
        <P>
          Note: currently only applies to text in user-generated content (posts, comments, etc).
        </P>
        <div className="selectContainer">
          <SingleSelect
            className="languageSelect"
            onChange={onLocaleChange}
            options={localeOptions}
            value={selectedOption}
          />
        </div>
        <Button
          disabled={!hasEdits || locale === preferredLanguage}
          loading={editing}
          onTap={onSubmit}
          value="Save"
        />
      </div>
    </div>
  );
};

export default compose(
  connect(({ cookies }: { cookies: Record<string, string> }) => ({
    preferredLanguage: cookies?.[CookieNames.locale],
  }))
)(AdminLanguageSettings) as unknown as React.FC<OwnProps>;

import validateInput from 'common/validateInput';

const validateInviteEmails = (emailString: string) => {
  if (emailString === '') {
    return { validEmails: [], invalidEmails: [] };
  }
  const emails = emailString.trim().split(/[,\s]+/);

  const invalidEmails: Set<string> = new Set();
  const validEmails: Set<string> = new Set();

  emails.forEach((email) => {
    const trimmedEmail = email.trim().toLowerCase();
    if (!validateInput.email(trimmedEmail)) {
      invalidEmails.add(trimmedEmail);
    } else {
      validEmails.add(trimmedEmail);
    }
  });

  return { validEmails: Array.from(validEmails), invalidEmails: Array.from(invalidEmails) };
};

export default validateInviteEmails;

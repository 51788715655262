import React, { useContext, useRef, useState } from 'react';

import { ChevronDown, List } from 'lucide-react';

import Portal from 'common/common/Portal';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Dropdown from 'common/Dropdown';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import ButtonV2 from 'common/ui/ButtonV2';
import { Span } from 'common/ui/Text';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/post/PostActivity/_FiltersDropdown.scss';

export type SortOptions = 'oldest' | 'newest';

type OnChangeOptions = {
  segment: string;
  sort: SortOptions;
  onlyInternalComments: boolean;
};

interface Props {
  loading: boolean;
  onlyInternalComments: boolean;
  onChange: (options: OnChangeOptions) => void;
  segment: string;
  sort: SortOptions;
}

export const getFilterCount = ({
  onlyInternalComments,
  segment,
}: Omit<OnChangeOptions, 'sort'>) => {
  let filterCount = 0;
  if (onlyInternalComments) {
    filterCount++;
  }
  if (segment !== segmentDefaultValue) {
    filterCount++;
  }
  return filterCount;
};

export const segmentDefaultValue = 'everyone';

const FiltersDropdown = ({ loading, onChange, onlyInternalComments, segment, sort }: Props) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const portalRef = useRef<Portal>(null);
  const company = useContext<Company>(CompanyContext);

  // Close the dropdown if any other dom element is interacted with
  useBackgroundClick(() => {
    if (open) {
      setOpen(false);
    }
  }, [buttonRef, portalRef]);

  let filterCount = 0;
  if (onlyInternalComments) {
    filterCount++;
  }
  if (segment !== segmentDefaultValue) {
    filterCount++;
  }

  const userSegmentationEnabled = company.features.userSegmentation;
  const segmentOptions = [
    { name: segmentDefaultValue, render: 'Everyone (default)' },
    ...company.segments.map(({ name, urlName }) => ({ name: urlName, render: name })),
  ];

  const existingOptions = {
    segment,
    sort,
    onlyInternalComments,
  };

  return (
    <>
      <ButtonV2
        aria-type="dialog"
        className="filterButton"
        ref={buttonRef}
        onClick={() => setOpen(!open)}
        size="medium"
        startIcon={List}
        endIcon={ChevronDown}
        loading={loading}
        variant="outlined">
        View
        <Span variant="bodyMd" className="filterCount">
          {filterCount > 0 ? `(${filterCount})` : null}
        </Span>
      </ButtonV2>
      {open ? (
        <Portal
          align="end"
          className="filtersDropdownPortal"
          ref={portalRef}
          relativeToRef={buttonRef}>
          <div className="row header">Display</div>
          <div className="row">
            <span className="label">Sort by</span>
            <Dropdown
              defaultSelectedName={sort}
              onChange={(newSort: SortOptions) => onChange({ ...existingOptions, sort: newSort })}
              options={[
                {
                  name: 'newest',
                  render: 'Newest first',
                },
                {
                  name: 'oldest',
                  render: 'Oldest first',
                },
              ]}
            />
          </div>
          <div className="row">
            <span className="label">Show internal comments only</span>
            <Toggle
              defaultValue={onlyInternalComments}
              onToggle={(value: boolean) =>
                onChange({ ...existingOptions, onlyInternalComments: value })
              }
              small
            />
          </div>
          {userSegmentationEnabled ? (
            <>
              <div className="row header separator">Filters</div>
              <div className="row">
                <span className="label">Segment</span>
                <Dropdown
                  defaultSelectedName={segment}
                  onChange={(newSegment: string) =>
                    onChange({ ...existingOptions, segment: newSegment })
                  }
                  options={segmentOptions}
                  truncate
                />
              </div>
            </>
          ) : null}
        </Portal>
      ) : null}
    </>
  );
};

export default FiltersDropdown;

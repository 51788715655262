import React from 'react';

import CheckboxInput from 'common/inputs/CheckboxInput';

import 'css/components/subdomain/admin/AdminSalesforceSettings/_AdminSalesforceSyncContacts.scss';

type SyncContactSettings = {
  syncContactDisallowNameOverride: boolean;
  syncContactOnlyExisting: boolean;
};

type Props = {
  syncContactSettings: SyncContactSettings;
  onChange: (settings: SyncContactSettings) => void;
};

const AdminSalesforceSyncContacts = ({ syncContactSettings, onChange }: Props) => {
  return (
    <div className="adminSalesforceSyncContacts">
      <CheckboxInput
        className="contactsCheckbox"
        defaultChecked={syncContactSettings.syncContactOnlyExisting}
        label="Only sync existing contacts"
        onChange={(syncContactOnlyExisting: boolean) =>
          onChange({
            ...syncContactSettings,
            syncContactOnlyExisting,
          })
        }
      />
      <CheckboxInput
        className="contactsCheckbox"
        defaultChecked={syncContactSettings.syncContactDisallowNameOverride}
        label="Don't override user's name field for existing contacts"
        onChange={(syncContactDisallowNameOverride: boolean) =>
          onChange({
            ...syncContactSettings,
            syncContactDisallowNameOverride,
          })
        }
      />
    </div>
  );
};

export default AdminSalesforceSyncContacts;

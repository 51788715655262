import React from 'react';

import SentrySDK from 'common/3rd/SentrySDK';
import { H3 } from 'common/ui/Text';
import createCustomError from 'common/util/createCustomError';
import styles from 'css-module/components/post/_Summary.module.scss';

type SummaryContentProps = {
  summary: string;
};

type Summary = {
  categories: Array<{
    title: string;
    details: string[];
  }>;
};

const SummaryParsingError = createCustomError('SummaryParsingError');

const SummaryContent = ({ summary }: SummaryContentProps) => {
  try {
    const { categories } = JSON.parse(summary) as Summary;
    return (
      <>
        {categories.map((category) => {
          return (
            <div key={category.title} className={styles.category}>
              <H3 variant="headingSm" className={styles.categoryTitle}>
                {category.title}
              </H3>
              <ul className={styles.detailsList}>
                {category.details.map((detail) => {
                  return (
                    <li className={styles.detail} key={detail}>
                      {detail}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </>
    );
  } catch (e) {
    SentrySDK.captureException(
      new SummaryParsingError({
        summary,
        error: e,
        message: 'Failed to parse summary',
      })
    );
    return null;
  }
};

export default SummaryContent;

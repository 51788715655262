import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { Check, Copy } from 'lucide-react';

import IconButtonV2 from 'common/ui/IconButtonV2';
import copyText from 'common/util/copyText';

type Props = {
  value: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

const CopyButtonV2 = ({ className, value, size }: Props) => {
  const [copied, setCopied] = useState(false);
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const defaultSize = size ?? 'small';

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const onCopy = () => {
    if (copied) {
      return;
    }

    copyText(value).then(() => {
      setCopied(true);
      timerRef.current = setTimeout(() => setCopied(false), 3000);
    });
  };

  return (
    <div className={classNames('copyButtonV2', className)}>
      {copied ? (
        <IconButtonV2 aria-label="check icon" size={defaultSize} icon={Check} variant="outlined" />
      ) : (
        <IconButtonV2
          aria-label="copy icon"
          onClick={onCopy}
          size={defaultSize}
          icon={Copy}
          variant="outlined"
        />
      )}
    </div>
  );
};
export default CopyButtonV2;

import { Component } from 'react';

import { loadNotifications } from '../actions/notifications';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

const asyncFetch = {
  promise: ({ store: { dispatch } }) => {
    return dispatch(loadNotifications());
  },
};

class CompanyNotificationsContainer extends Component {
  static asyncConnect = asyncFetch;

  render() {
    const { children, notifications } = this.props;
    return cloneElementWithProps(children, {
      ...this.props,
      notifications,
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({ notifications: state.notifications }))(
  CompanyNotificationsContainer
);

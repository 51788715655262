import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { invalidateSuggestions, loadSuggestions } from 'common/actions/issueSuggestions';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import AdminIntegrationSearchForm from 'common/subdomain/admin/AdminIntegrationSearchForm';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

class AdminJiraIssueForm extends Component {
  static propTypes = {
    post: PropTypes.object,
    issueSuggestions: PropTypes.object,
  };

  state = {
    error: null,
  };

  onAddIssue = async (issue) => {
    const { post, reloadPost } = this.props;

    this.setState({ error: null });

    const response = await AJAX.post('/api/jira/linkIssue', {
      issueID: issue.id,
      postID: post._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    await reloadPost(post);
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderSuggestion(suggestion) {
    return `[${suggestion?.key}] ${suggestion?.summary}`;
  }

  render() {
    const { issueSuggestions, loadSuggestions } = this.props;

    return (
      <div className="adminJiraIssueForm">
        <AdminIntegrationSearchForm
          loadSuggestions={loadSuggestions}
          onSuggestionSelected={this.onAddIssue}
          placeholder={'Search for an issue...'}
          renderSuggestion={this.renderSuggestion}
          suggestions={issueSuggestions}
        />
        {this.renderError()}
      </div>
    );
  }
}

export default connect(
  (state) => ({ issueSuggestions: state.issueSuggestions }),
  (dispatch) => ({
    loadSuggestions: (issueSearch) => {
      return Promise.all([dispatch(loadSuggestions(issueSearch))]);
    },
    reloadPost: (post) => {
      return Promise.all([dispatch(invalidateSuggestions()), dispatch(reloadPost(post))]);
    },
  })
)(AdminJiraIssueForm);

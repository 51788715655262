enum NotificationActionTypes {
  commentCreated = 'commentCreated',
  postCreated = 'postCreated',
  postMerged = 'postMerged',
  postOwnerChanged = 'postOwnerChanged',
  postOwnerCleared = 'postOwnerCleared',
  postStatusChanged = 'postStatusChanged',
  userMentioned = 'userMentioned',
  voteMilestone = 'voteMilestone',
}

type NotificationAction = {
  name: NotificationActionTypes;
  render: string;
};

const NotificationActions: Record<NotificationActionTypes, NotificationAction> = {
  commentCreated: {
    name: NotificationActionTypes.commentCreated,
    render: 'New\u00a0Comments',
  },
  postCreated: {
    name: NotificationActionTypes.postCreated,
    render: 'New\u00a0Posts',
  },
  postMerged: {
    name: NotificationActionTypes.postMerged,
    render: 'Post\u00a0Merge',
  },
  postOwnerChanged: {
    name: NotificationActionTypes.postOwnerChanged,
    render: 'Post\u00a0Ownership',
  },
  postOwnerCleared: {
    name: NotificationActionTypes.postOwnerCleared,
    render: 'Post\u00a0Owner Cleared',
  },
  postStatusChanged: {
    name: NotificationActionTypes.postStatusChanged,
    render: 'Status\u00a0Updates',
  },
  userMentioned: {
    name: NotificationActionTypes.userMentioned,
    render: 'Mentions',
  },
  voteMilestone: {
    name: NotificationActionTypes.voteMilestone,
    render: 'Vote\u00a0Milestones',
  },
};

export { NotificationActionTypes };
export default NotificationActions;

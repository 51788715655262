export const FreeV1PlanID = 'free-1';
export const FreeV2PlanID = 'free-2';
export const FreeV3PlanID = 'free-3';
export const FreePlanID = FreeV3PlanID;
export const FreePlanIDs = [FreeV1PlanID, FreeV2PlanID, FreeV3PlanID];

export default function isFree(planID: string | null) {
  if (!planID) {
    return false;
  }

  return FreePlanIDs.includes(planID);
}

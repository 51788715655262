import React from 'react';

import styles from 'css-module/components/subdomain/admin/AdminQueue/_Suggestion.module.scss';

import AdminQueueComment from './AdminQueueComment';
import AdminQueuePost from './AdminQueuePost';

import type { InboxItem } from 'common/api/resources/inbox';

type Props = {
  item: InboxItem;
};

const SpamDetailsCell = ({ item }: Props) => {
  if (item.itemType === 'post') {
    return (
      <div className={styles.queueItemWrapper}>
        <AdminQueuePost
          className={styles.queueSuggestionItem}
          title={item.title}
          details={item.details}
          author={item.author}
          created={item.created}
          board={item.board}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.queueItemWrapper}>
        <AdminQueueComment
          className={styles.queueSuggestionItem}
          title={item.post.title}
          author={item.author}
          created={item.created}
          board={item.post.board}
          mentionedUsers={item.mentionedUsers}
          value={item.value}
        />
      </div>
    );
  }
};

export default SpamDetailsCell;

import AJAX from 'common/AJAX';

export default class OAuthAzureRedirect {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab }) {
    this._onSuccess = onSuccess;
    this._onFailure = onFailure;
  }

  connect = (result) => {
    // 1. Verify access token
    if (!result.code) {
      this._onFailure();
      return;
    }

    AJAX.post(
      '/api/oauth/getAzureAuthTokens',
      {
        code: result.code,
      },
      (response) => {
        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          this._onFailure('invalid code');
          return;
        }

        if (!responseObject.azureAccessToken || !responseObject.azureUserID) {
          this._onFailure('invalid code');
          return;
        }

        this._onSuccess({
          avatarURL: responseObject.avatarURL,
          azureAccessToken: responseObject.azureAccessToken,
          azureUserID: responseObject.azureUserID,
          email: responseObject.email,
          name: responseObject.name,
        });
      }
    );
  };
}

import validateAction from './validateAction';
import validateFilter from './validateFilter';
import { getRequiredFilters } from '../automationUtils';
import { type EditableAutomation, automationSchema } from '../constants';

export type ErrorTypes = 'title' | 'trigger' | 'filter' | 'action';

type ValidationResult =
  | {
      ok: false;
      error: {
        type: ErrorTypes;
        message: string;
      };
    }
  | { ok: true };

export const validateAutomation = (automation: EditableAutomation): ValidationResult => {
  if (!automation.title) {
    return {
      ok: false,
      error: { type: 'title', message: 'A title is required' },
    };
  }

  if (!automation.trigger) {
    return {
      ok: false,
      error: { type: 'trigger', message: 'A trigger is required' },
    };
  }

  // Ensure required fields exist in the automation
  const requiredFilters = getRequiredFilters(automation);
  const existingFilterTypes = automation.filters.map((filter) => filter.resource);
  const missingRequiredFilter = requiredFilters.find(
    (field) => existingFilterTypes.includes(field) === undefined
  );
  if (missingRequiredFilter) {
    return {
      ok: false,
      error: { type: 'trigger', message: `Missing required filter (${missingRequiredFilter})` },
    };
  }

  const filterCountIsValid = validateFilterCounts(automation);
  if (!filterCountIsValid) {
    return {
      ok: false,
      error: { type: 'filter', message: 'Unexpected field(s)' },
    };
  }

  // Validate each field
  const invalidFilter = automation.filters.find((filter) => validateFilter(filter) === false);
  if (invalidFilter) {
    return {
      ok: false,
      error: { type: 'filter', message: `Invalid value (${invalidFilter.resource})` },
    };
  }

  if (automation.actions.length === 0) {
    return {
      ok: false,
      error: { type: 'action', message: 'At least one outcome is required' },
    };
  }

  const actionCountIsValid = validateActionCounts(automation);
  if (!actionCountIsValid) {
    return {
      ok: false,
      error: { type: 'action', message: 'Unexpected action(s)' },
    };
  }

  // Validate each action
  const invalidAction = automation.actions.find((action) => validateAction(action) === false);
  if (invalidAction) {
    return {
      ok: false,
      error: { type: 'action', message: `Invalid value (${invalidAction.type})` },
    };
  }
  return { ok: true };
};

const validateFilterCounts = (automation: EditableAutomation): boolean => {
  const resourceLimits = Object.entries(automationSchema[automation.trigger].filters);
  return resourceLimits.every(([key, filterOptions]) => {
    const filterCount = automation.filters.filter((filter) => filter.resource === key);
    return filterCount.length <= filterOptions.max;
  });
};

const validateActionCounts = (automation: EditableAutomation): boolean => {
  const actionLimits = Object.entries(automationSchema[automation.trigger].actions);
  return actionLimits.every(([key, actionOptions]) => {
    const filterCount = automation.actions.filter((action) => action.type === key);
    return filterCount.length <= actionOptions.max;
  });
};

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import AJAX from 'common/AJAX';
import * as AuthRequests from 'common/auth/AuthRequests';
import AuthButton from 'common/AuthButton';
import CannyAttribution from 'common/CannyAttribution';
import { DisconnectAzureContext, DisconnectGoogleContext } from 'common/containers/AuthContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Button from 'common/inputs/Button';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Tappable from 'common/Tappable';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import withContexts from 'common/util/withContexts';

import {
  AzureLogIn,
  CannyLogIn,
  CannySignUp,
  CompanyLogIn,
  EmailVerification,
  GSuiteLogIn,
  NoLogIn,
  OIDCLogIn,
  OktaLogIn,
  getPrimaryLogIn,
  getSecondaryLogIn,
  getTertiaryLogIn,
} from './BoardLogin';
import EmailVerificationForm from './EmailVerificationForm';

import CannyLogoIcon from 'img/canny-icon.svg';

import 'css/components/subdomain/public/_BoardLoginForm.scss';

class BoardLoginForm extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.object,
    disconnectAzure: PropTypes.func,
    disconnectGoogle: PropTypes.func,
    location: PropTypes.object,
    openModal: PropTypes.func.isRequired,
    viewer: PropTypes.object,
  };

  state = {
    allowListedDomain: this.props.board.allowedDomain,
    thirdPartySignIn: this.props.board.allowIdentifiedUsers,
    allowInvited: this.props.board.allowInvitedUsers,
    allowSegment: this.props.board.allowSegment,
    error: null,
    verifiedBy: this.props.board.allowVerifiedBy,
  };

  onOAuthFailure = (error) => {
    this.setState({
      error,
    });
  };

  onOAuthSuccess = (oauthData) => {
    const { viewer } = this.props;
    if (viewer.loggedOut) {
      this.login(oauthData);
    } else if (oauthData.googleUserID) {
      this.addGSuiteDomain(oauthData);
    } else if (oauthData.azureUserID) {
      this.addAzureDomain(oauthData);
    } else {
      throw new Error('Unsupported OAuth method used');
    }
  };

  login = async (oauthData) => {
    const { error, redirecting } = await AuthRequests.login(oauthData);

    if (redirecting) {
      return;
    }

    if (!error) {
      if (oauthData.googleUserID) {
        this.addGSuiteDomain(oauthData);
      } else if (oauthData.azureUserID) {
        this.addAzureDomain(oauthData);
      } else {
        throw new Error('Unsupported OAuth method used');
      }
    } else if (error.type === 'slow down') {
      this.setState({
        error: error.message,
      });
    } else {
      this.signup(oauthData);
    }
  };

  signup = async (oauthData) => {
    const { error, redirecting } = await AuthRequests.signup(oauthData);

    if (redirecting) {
      return;
    }

    if (!error) {
      if (oauthData.googleUserID) {
        this.addGSuiteDomain(oauthData);
      } else if (oauthData.azureUserID) {
        this.addAzureDomain(oauthData);
      } else {
        throw new Error('Unsupported OAuth method used');
      }
      return;
    }

    this.onError(error.message);
  };

  addAzureDomain = (oauthData) => {
    AJAX.post(
      '/api/viewer/addAzureDomain',
      {
        ...oauthData, // azureAccessToken, azureUserID
      },
      (response) => {
        if (response === 'success') {
          window.location.reload();
          return;
        }

        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (responseObject.error === 'invalid email') {
          const { disconnectAzure } = this.props;
          disconnectAzure();
          this.setState({
            error: (
              <div>
                Invalid email. Please try again and make sure to sign in with your company
                email&nbsp;account.
              </div>
            ),
          });
          return;
        }

        this.onError();
      }
    );
  };

  addGSuiteDomain = (oauthData) => {
    AJAX.post(
      '/api/viewer/addGSuiteDomain',
      {
        ...oauthData, // googleUserID, googleIDToken
      },
      (response) => {
        if (response === 'success') {
          window.location.reload();
          return;
        }

        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (responseObject.error === 'invalid email') {
          const { disconnectGoogle } = this.props;
          disconnectGoogle();
          this.setState({
            error: (
              <div>
                Invalid email. Please try again and make sure to sign in with your company
                email&nbsp;account.
              </div>
            ),
          });
          return;
        }

        this.onError();
      }
    );
  };

  onCannyLogIn = () => {
    this.props.openModal(AccountModal, {
      formState: FormStates.login,
    });
  };

  onCannySignUp = () => {
    this.props.openModal(AccountModal, {
      formState: FormStates.signup,
    });
  };

  onError = (message) => {
    this.setState({
      error: message ? (
        <div>{message}</div>
      ) : (
        <div>
          Something went wrong. Please&nbsp;
          <a href="mailto:support@canny.io">contact support</a>.
        </div>
      ),
    });
  };

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderAzureButton() {
    return (
      <AuthButton
        authType="azure"
        onFailure={this.onOAuthFailure}
        onSuccess={this.onOAuthSuccess}
        value="Log in with Azure AD"
      />
    );
  }

  renderCannyLogInButton() {
    return (
      <Tappable onTap={this.onCannyLogIn}>
        <div className="authButton">
          <div className="imageContainer">
            <LazyLoadedImage alt="Canny logo" src={CannyLogoIcon} />
          </div>
          <div className="cta">Log in with Canny</div>
        </div>
      </Tappable>
    );
  }

  renderCannySignUpButton() {
    return (
      <Tappable onTap={this.onCannySignUp}>
        <div className="authButton">
          <div className="imageContainer">
            <LazyLoadedImage alt="Canny logo" src={CannyLogoIcon} />
          </div>
          <div className="cta">Sign up with Canny</div>
        </div>
      </Tappable>
    );
  }

  renderGSuiteButton() {
    return (
      <AuthButton
        authType="google"
        onFailure={this.onOAuthFailure}
        onSuccess={this.onOAuthSuccess}
        value="Log in with Google"
      />
    );
  }

  renderOIDCButton() {
    const { company } = this.props;
    return (
      <AuthButton
        additionalData={{
          companyID: company._id,
        }}
        authType="oidc"
        onFailure={this.onOAuthFailure}
        onSuccess={this.onOAuthSuccess}
        value="Log in with OpenID Connect"
      />
    );
  }

  renderOktaButton() {
    const { company } = this.props;
    return (
      <AuthButton
        additionalData={{
          okta: company.okta,
        }}
        authType="okta"
        onFailure={this.onOAuthFailure}
        onSuccess={this.onOAuthSuccess}
        value="Log in with Okta"
      />
    );
  }

  renderRedirectButton() {
    const { company, location } = this.props;
    const { authRedirectEnabled, authRedirectURL } = company;
    if (!authRedirectEnabled || !authRedirectURL) {
      return null;
    }

    const redirectURL = getAuthRedirectURL(company, location);
    return (
      <a href={redirectURL}>
        <Button tint={true} value={'Log In To ' + company.name} />
      </a>
    );
  }

  renderPrimaryLogIn() {
    const { viewer } = this.props;
    const { allowListedDomain, thirdPartySignIn, allowInvited, allowSegment, verifiedBy } =
      this.state;

    const viewerLoggedIn = !viewer.loggedOut;
    const allowAzure = verifiedBy === 'azure';
    const allowIdentified = thirdPartySignIn;
    const allowGSuite = !!allowListedDomain && verifiedBy === 'gsuite';
    const allowOIDC = verifiedBy === 'oidc';
    const allowOkta = verifiedBy === 'okta';
    const allowVerifiedEmail = !!allowListedDomain && verifiedBy === 'email';

    const primaryLogIn = getPrimaryLogIn({
      viewerLoggedIn,
      allowAzure,
      allowIdentified,
      allowInvited,
      allowGSuite,
      allowOIDC,
      allowOkta,
      allowSegment,
      allowVerifiedEmail,
    });

    switch (primaryLogIn) {
      case AzureLogIn:
        return this.renderAzureLogIn();
      case CannyLogIn:
        return this.renderCannyLogIn();
      case CannySignUp:
        return this.renderCannySignUp();
      case CompanyLogIn:
        return this.renderCompanyLogIn();
      case GSuiteLogIn:
        return this.renderGSuiteLogIn();
      case EmailVerification:
        return this.renderEmailVerification();
      case NoLogIn:
        return this.renderNoLogIn();
      case OIDCLogIn:
        return this.renderOIDCButton();
      case OktaLogIn:
        return this.renderOktaLogIn();
      default:
        return null;
    }
  }

  renderAlternateLogIns() {
    const { viewer } = this.props;
    const { allowListedDomain, thirdPartySignIn, allowInvited, verifiedBy } = this.state;

    const viewerLoggedIn = !viewer.loggedOut;
    const allowAzure = verifiedBy === 'azure';
    const allowIdentified = thirdPartySignIn;
    const allowGSuite = !!allowListedDomain && verifiedBy === 'gsuite';
    const allowOkta = verifiedBy === 'okta';
    const allowOIDC = verifiedBy === 'oidc';
    const allowVerifiedEmail = !!allowListedDomain && verifiedBy === 'email';

    const secondaryLogIn = getSecondaryLogIn({
      viewerLoggedIn,
      allowAzure,
      allowIdentified,
      allowInvited,
      allowGSuite,
      allowOkta,
      allowOIDC,
      allowVerifiedEmail,
    });

    const tertiaryLogIn = getTertiaryLogIn({
      viewerLoggedIn,
      allowAzure,
      allowIdentified,
      allowInvited,
      allowGSuite,
      allowOkta,
      allowOIDC,
      allowVerifiedEmail,
    });

    const alternativeLogIns = [secondaryLogIn, tertiaryLogIn].filter((l) => l);

    if (alternativeLogIns.length === 0) {
      return null;
    }

    return (
      <div>
        <p className="alternativeNote">Alternate log in options</p>
        <div className="alternativeSignIn">
          {alternativeLogIns.map((login) => (
            <div key={login}>
              {login === AzureLogIn && this.renderAzureButton()}
              {login === CannyLogIn && this.renderCannyLogInButton()}
              {login === CannySignUp && this.renderCannySignUpButton()}
              {login === GSuiteLogIn && this.renderGSuiteButton()}
              {login === OIDCLogIn && this.renderOIDCButton()}
              {login === OktaLogIn && this.renderOktaButton()}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderNoLogIn() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">You do not have permission to access&nbsp;this&nbsp;page.</div>
      </div>
    );
  }

  renderAzureLogIn() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">
          Log in with your Azure AD account to access&nbsp;this&nbsp;page.
        </div>
        {this.renderAzureButton()}
      </div>
    );
  }

  renderCannyLogIn() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">Log in to access&nbsp;this&nbsp;page.</div>
        {this.renderCannyLogInButton()}
      </div>
    );
  }

  renderCannySignUp() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">Sign up for a Canny account to access&nbsp;this&nbsp;page.</div>
        {this.renderCannySignUpButton()}
      </div>
    );
  }

  renderGSuiteLogIn() {
    const { allowListedDomain } = this.state;
    const domains = allowListedDomain
      .split?.(/[,\s@]+/gi)
      .map((domain) => {
        return '@' + domain;
      })
      .join(', ');

    return (
      <div className="primaryLogIn">
        <div className="prompt">
          Log in with your Google Workspace account {domains ? `(${domains})` : null} to
          access&nbsp;this&nbsp;page.
        </div>
        {this.renderGSuiteButton()}
      </div>
    );
  }

  renderOIDCLogIn() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">
          Log in with your OpenID Connect account to access&nbsp;this&nbsp;page.
        </div>
        {this.renderOIDCButton()}
      </div>
    );
  }

  renderOktaLogIn() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">Log in with your Okta account to access&nbsp;this&nbsp;page.</div>
        {this.renderOktaButton()}
      </div>
    );
  }

  renderCompanyLogIn() {
    return (
      <div className="primaryLogIn">
        <div className="prompt">
          Log in with your {this.props.company.name} account to access&nbsp;this&nbsp;page.
        </div>
        {this.renderRedirectButton()}
      </div>
    );
  }

  renderEmailVerification() {
    return (
      <EmailVerificationForm
        boardURLName={this.props.boardURLName}
        allowListedDomain={this.state.allowListedDomain}
      />
    );
  }

  render() {
    return (
      <div className="boardLoginForm">
        <div className="topContainer">
          {this.renderPrimaryLogIn()}
          {this.renderErrorMessage()}
        </div>
        {this.renderAlternateLogIns()}
        <CannyAttribution source="feedback_subdomain" />
      </div>
    );
  }
}

export default compose(
  withContexts({
    company: CompanyContext,
    disconnectAzure: DisconnectAzureContext,
    disconnectGoogle: DisconnectGoogleContext,
    location: LocationContext,
    openModal: OpenModalContext,
    viewer: ViewerContext,
  })
)(BoardLoginForm);

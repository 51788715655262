import React, { useEffect, useRef, useState } from 'react';

import { BarChart2, FacebookIcon, Link, TwitterIcon } from 'lucide-react';

import { reloadEntry } from 'common/actions/changelogEntries';
import Tooltip from 'common/common/Tooltip';
import ChangelogHeart from 'common/reaction/ChangelogHeart';
import ReactionsMenu from 'common/reaction/ReactionsMenu';
import IconBadge from 'common/ui/IconBadge';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import copyText from 'common/util/copyText';
import getCannyOrigin from 'common/util/getCannyOrigin';
import numberWithCommas from 'common/util/numberWithCommas';

import type { Company } from 'common/api/endpoints/companies';
import type { ChangelogEntry } from 'common/api/resources/changelog';

const FBAppID = __DEV__ ? '1606035092942076' : '1606018679610384';

type Props = {
  company: Company;
  entry: ChangelogEntry;
};

const ChangelogFooter = ({ company, entry }: Props) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (linkCopied) {
      const threeSeconds = 3000;
      timerRef.current = setTimeout(() => {
        setLinkCopied(false);
      }, threeSeconds);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [linkCopied]);

  const onShareLinkTapped = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const entryURL = getCannyOrigin(company) + '/changelog/' + entry.urlName;
    copyText(entryURL).then(() => {
      setLinkCopied(true);
    });
  };

  // Facebook integration expects us to pass a href with Canny subdomains
  // Use canny subdomain instead of custom primary domain
  const entryURL = `https://${company.subdomain}.canny.io/changelog/${entry.urlName}`;
  const facebookURL =
    'https://www.facebook.com/dialog/share' +
    '?app_id=' +
    FBAppID +
    '&display=page' +
    '&href=' +
    encodeURIComponent(entryURL) +
    '&redirect_uri=' +
    encodeURIComponent(entryURL);
  const twitterURL =
    'https://twitter.com/intent/tweet' +
    '?text=' +
    encodeURIComponent(entry.title + ' by ' + company.name) +
    '&url=' +
    encodeURIComponent(entryURL) +
    '&via=cannyHQ';

  return (
    <footer className="footer">
      <div className="stats">
        <ReactionsMenu
          allowedReactions={{
            like: ({
              disabled,
              fill,
              stroke,
            }: {
              disabled: boolean;
              fill: string;
              stroke: string;
            }) => <ChangelogHeart disabled={disabled} fill={fill} stroke={stroke} />,
          }}
          object={entry}
          objectType="changelog"
          reloadObjectData={reloadEntry}
          showReactorNames={company.viewerIsMember}
        />
      </div>
      <div className="share">
        {company.viewerIsMember && (
          <div className="views">
            <IconBadge size="large" icon={BarChart2} />
            <P>
              {numberWithCommas(entry.views)} {entry.views === 1 ? 'View' : 'Views'}
            </P>
          </div>
        )}
        <Tooltip position="top" value={linkCopied ? 'Link copied!' : 'Click to copy'}>
          <IconButtonV2
            size="medium"
            icon={Link}
            variant="outlined"
            aria-label="Share link"
            onClick={onShareLinkTapped}
          />
        </Tooltip>
        <a href={facebookURL} rel="noopener" target="_blank">
          <IconButtonV2
            size="medium"
            icon={FacebookIcon}
            variant="outlined"
            aria-label="Share on Facebook"
          />
        </a>
        <a href={twitterURL} rel="noopener" target="_blank">
          <IconButtonV2
            size="medium"
            icon={TwitterIcon}
            variant="outlined"
            aria-label="Share on X"
          />
        </a>
      </div>
    </footer>
  );
};

export default ChangelogFooter;

import React, { Component } from 'react';

import classnames from 'classnames';
import { Eye, LogOut, Settings } from 'lucide-react';
import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import Portal from 'common/common/Portal';
import UserAvatarV2 from 'common/common/UserAvatarV2';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import Tappable from 'common/Tappable';
import { P } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminNavAccountMenu.scss';

import CannyIcon from 'img/canny-icon.svg';

class PublicNavAccountMenu extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.shape({
      error: PropTypes.string,
      notFound: PropTypes.bool,
      viewerIsMember: PropTypes.bool,
    }),
    post: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    menuOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  onDropdownBlur = (e) => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return;
    }

    this.setState({ menuOpen: false });
  };

  onLogoutTapped = async () => {
    const response = await AJAX.post('/api/viewer/logout', {});
    if (response !== 'success') {
      return;
    }

    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    document.location.reload();
  };

  onToggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  renderMobileBackLink() {
    const {
      board,
      company,
      post,
      location: { pathname },
    } = this.props;
    const isMember = company.viewerIsMember;
    if (!isMember) {
      return null;
    }

    var link = '/admin';
    if (board) {
      if (post) {
        link = '/admin/board/' + board.urlName + '/p/' + post.urlName;
      } else {
        link = '/admin/board/' + board.urlName;
      }
    } else if (pathname.startsWith('/changelog')) {
      link = '/admin/changelog';
    }

    return (
      <div className="mobileBackLink section">
        <Link to={link} className="menuItem">
          <Eye size={16} className="lucide-icon" />
          <P className="itemName">Admin View</P>
        </Link>
      </div>
    );
  }

  renderCompanies() {
    const items = [];
    const {
      viewer: { companies },
    } = this.props;
    if (companies.length <= 1) {
      return null;
    }

    companies.forEach((company) => {
      const companyLogo = company.logoURL;
      const companyURL =
        company.integrationAuthRedirectionURL ??
        devURL('https://' + company.subdomain + '.canny.io/admin');
      items.push(
        <a key={company._id} className="company menuItem" href={companyURL}>
          <LazyLoadedImage
            className="companyLogo"
            src={companyLogo ?? CannyIcon}
            alt=""></LazyLoadedImage>
          <P className="itemName">{company.name}</P>
        </a>
      );
    });

    return (
      <div className="companies section">
        <div className="sectionTitleWrapper">
          <p className="sectionTitle">Workspaces</p>
        </div>
        {items}
      </div>
    );
  }

  renderAccountMenu() {
    const {
      company: { viewerIsMember },
      viewer: { companies },
    } = this.props;
    const settingsLink = viewerIsMember ? '/admin/settings/account' : '/settings';
    return (
      <div
        className={classnames('menuItems publicMenu', {
          shrink: companies.length <= 1,
        })}>
        {this.renderMobileBackLink()}
        {this.renderCompanies()}
        <div className="section spacedOut">
          <Link className="menuItem" onTap={this.onToggleMenu} to={settingsLink}>
            <Settings size={16} className="lucide-icon" />
            <P className="itemName">Settings</P>
          </Link>
          <Tappable onTap={this.onLogoutTapped}>
            <div className="menuItem">
              <LogOut size={16} className="lucide-icon" />
              <P className="itemName">Logout</P>
            </div>
          </Tappable>
        </div>
      </div>
    );
  }

  renderPortal() {
    if (!this.state.menuOpen) {
      return null;
    }

    return (
      <Portal
        align="end"
        className={classnames('dropdownPortal', 'adminNavAccountMenuPortal')}
        onBlur={this.onDropdownBlur}
        position="bottom"
        relativeToRef={this.containerRef}>
        {this.renderAccountMenu()}
      </Portal>
    );
  }

  render() {
    const { viewer } = this.props;
    return (
      <div className="adminNavAccountMenu" ref={this.containerRef}>
        {viewer?._id && (
          <button className="avatarContainer" onClick={this.onToggleMenu}>
            <UserAvatarV2 size={32} user={viewer} shape="circle" />
          </button>
        )}
        {this.renderPortal()}
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  viewer: ViewerContext,
})(PublicNavAccountMenu);

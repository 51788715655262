import React, { useState } from 'react';

import classnames from 'classnames';

import TextInput from 'common/inputs/TextInput';
import ModernModal from 'common/modals/ModernModal';
import ButtonV2 from 'common/ui/ButtonV2';
import Label from 'common/ui/common/Label';
import RadioButtonV2 from 'common/ui/RadioButtonV2';
import RadioGroupV2 from 'common/ui/RadioGroupV2';
import styles from 'css-module/components/subdomain/admin/AdminRoadmap/AdminRoadmapHeader/_DuplicateRoadmapModal.module.scss';

export type FormValues = {
  title: string;
  scoring: 'none' | 'all';
  posts: 'none' | 'incomplete' | 'all';
};

type Props = {
  onClose: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
  originalRoadmapTitle?: string;
  allRoadmapTitles: string[];
};

const calculateTitle = (originalTitle: string | undefined, allTitles: string[]) => () => {
  const roadmapName = originalTitle || 'Untitled';
  let counter = 1;
  let title = `${roadmapName} (${counter})`;
  while (allTitles.includes(title)) {
    title = `${roadmapName} (${++counter})`;
  }
  return title;
};

const DuplicateRoadmapModal = ({
  originalRoadmapTitle,
  allRoadmapTitles,
  onClose,
  onSubmit,
}: Props) => {
  const [title, setTitle] = useState(calculateTitle(originalRoadmapTitle, allRoadmapTitles));
  const [scoring, setScoring] = useState<FormValues['scoring']>('all');
  const [posts, setPosts] = useState<FormValues['posts']>('all');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit({ title, scoring, posts });
    setLoading(false);
  };

  return (
    <ModernModal
      overlayClassName={styles.duplicateRoadmapPortal}
      modalClassName={styles.modalContent}
      onClose={onClose}
      header="Duplicate Roadmap"
      sections={[
        <>
          <Label label="Title" labelPlacement="top" className={classnames(styles.label)}>
            <TextInput
              className={styles.textInput}
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </Label>
          <Label
            label="Keep Impact Factors"
            labelPlacement="top"
            className={classnames(styles.label, styles.smallLabel)}
            size="small">
            <RadioGroupV2
              size="medium"
              name="scoring"
              value={scoring}
              onChange={(newScoring) => setScoring(newScoring as FormValues['scoring'])}>
              <RadioButtonV2 value="none">None</RadioButtonV2>
              <RadioButtonV2 value="all">All impact factors</RadioButtonV2>
            </RadioGroupV2>
          </Label>
          <Label
            label="Keep Posts"
            labelPlacement="top"
            className={classnames(styles.label, styles.smallLabel)}
            size="small">
            <RadioGroupV2
              size="medium"
              name="posts"
              value={posts}
              onChange={(newPosts) => setPosts(newPosts as FormValues['posts'])}>
              <RadioButtonV2 value="none">None</RadioButtonV2>
              <RadioButtonV2 value="incomplete">Incomplete posts only</RadioButtonV2>
              <RadioButtonV2 value="all">All posts</RadioButtonV2>
            </RadioGroupV2>
          </Label>
        </>,
      ]}
      footer={
        <>
          <ButtonV2 onClick={onClose} variant="outlined" size="medium">
            Cancel
          </ButtonV2>
          <ButtonV2 onClick={handleSubmit} size="medium" loading={loading}>
            Duplicate
          </ButtonV2>
        </>
      }
    />
  );
};

export default DuplicateRoadmapModal;

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { removeItem } from 'common/actions/queueItemQueries';
import { removePostFromAllRoadmaps } from 'common/actions/roadmapPosts';

import type { Board } from 'common/api/endpoints/boards';
import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

// Action Creators

export const reloadOnMerge = (
  mergePost: Post,
  intoPost: Post,
  fromBoard: Board,
  toBoard: Board
) => {
  return async (dispatch: Dispatch) => {
    await Promise.all([
      dispatch(invalidateDashboardActivity()),
      dispatch(invalidatePostQueries()),
      dispatch(reloadBoard(fromBoard.urlName)),
      dispatch(reloadBoard(toBoard.urlName)),
      dispatch(reloadPost(mergePost)),
      dispatch(removeItem(mergePost)),
      dispatch(reloadPost(intoPost)),
      dispatch(reloadPostActivity(intoPost)),
      dispatch(removePostFromAllRoadmaps(mergePost)),
    ]);
  };
};

import React, { useContext, useRef, useState } from 'react';

import { Filter } from 'lucide-react';

import Accordion from 'common/common/Accordion';
import Popover from 'common/common/Popover';
import Portal from 'common/common/Portal';
import { type BoardContextType, BoardsContext } from 'common/containers/BoardsContainer';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import translateString from 'common/i18n/translateString';
import ButtonV2 from 'common/ui/ButtonV2';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import SwitchWithLabel from 'common/ui/SwitchWithLabel';
import difference from 'common/util/difference';
import unique from 'common/util/unique';

import type { Category } from 'common/api/resources/board';
import type { BoardStateItem } from 'common/reducers/boards';

import 'css/components/subdomain/RoadmapView/_RoadmapFilter.scss';

const getDefaultSelectedItems = (
  boards: BoardContextType,
  allowedBoardURLNames: string[]
): Filters => {
  if (!Array.isArray(boards)) {
    return {
      boardURLNames: [],
      categoryURLNames: [],
      includeUncategorized: true,
    };
  }
  const filteredBoards = boards.filter((board) => allowedBoardURLNames.includes(board.urlName));
  return {
    boardURLNames: filteredBoards.map((board) => board.urlName),
    categoryURLNames: filteredBoards.flatMap((board) =>
      board.categories.map((category) => category.urlName)
    ),
    includeUncategorized: true,
  };
};

const getChildCategories = (categories: Category[], category: Category) =>
  categories.filter((childCategory) => childCategory.parentID === category._id);

type Filters = {
  boardURLNames: string[];
  categoryURLNames: string[];
  includeUncategorized: boolean;
};

type Props = {
  allowedBoardURLNames: string[];
  filterValues: Filters;
  onFilterChange: (filters: Filters) => void;
};

const RoadmapFilter = ({ allowedBoardURLNames, onFilterChange, filterValues }: Props) => {
  const allBoards = useContext(BoardsContext);
  const [open, setOpen] = useState(false);
  const portalRef = useRef<Portal>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useBackgroundClick(() => {
    setOpen(false);
  }, [portalRef, buttonRef]);

  // Boards are loading, no need to render
  if (!Array.isArray(allBoards)) {
    return null;
  }

  const boards = allBoards.filter((board) => allowedBoardURLNames.includes(board.urlName));
  const values = filterValues ?? getDefaultSelectedItems(boards, allowedBoardURLNames);

  const onBoardClick = (board: BoardStateItem, value: boolean) => {
    const boardCategoryURLNames = board.categories.map((category) => category.urlName);
    if (value) {
      onFilterChange({
        ...values,
        boardURLNames: [...values.boardURLNames, board.urlName],
        categoryURLNames: unique([...values.categoryURLNames, ...boardCategoryURLNames]),
      });
    } else {
      onFilterChange({
        ...values,
        boardURLNames: values.boardURLNames.filter(
          (selectedBoardURLName) => selectedBoardURLName !== board.urlName
        ),
        categoryURLNames: difference(values.categoryURLNames, boardCategoryURLNames),
      });
    }
  };

  // And unselect all children
  const onCategoryClick = (category: Category, value: boolean) => {
    if (value) {
      const board = boards.find((board) => {
        return board.categories.find((boardCategory) => boardCategory._id === category._id);
      });

      const boardURLNames = values.boardURLNames;
      if (board?.urlName && !boardURLNames.includes(board.urlName)) {
        boardURLNames.push(board.urlName);
      }

      onFilterChange({
        ...values,
        boardURLNames,
        categoryURLNames: unique([...values.categoryURLNames, category.urlName]),
      });
    } else {
      onFilterChange({
        ...values,
        categoryURLNames: difference(values.categoryURLNames, [category.urlName]),
      });
    }
  };

  const defaultSelection = getDefaultSelectedItems(boards, allowedBoardURLNames);
  const allSelected =
    defaultSelection.boardURLNames.length === values.boardURLNames.length &&
    defaultSelection.categoryURLNames.length === values.categoryURLNames.length;

  const onSelectAll = () => {
    if (allSelected) {
      onFilterChange({
        ...values,
        boardURLNames: [],
        categoryURLNames: [],
      });
    } else {
      onFilterChange(defaultSelection);
    }
  };

  return (
    <>
      <ButtonV2
        ref={buttonRef}
        startIcon={Filter}
        onClick={() => setOpen(!open)}
        size="medium"
        variant="outlined">
        Filters
      </ButtonV2>
      {open && (
        <Portal
          className="roadmapFilterPortal"
          align="start"
          position="left"
          ref={portalRef}
          relativeToRef={buttonRef}>
          <Popover
            className="roadmapFilterPopover"
            cta={
              <ButtonV2 size="small" variant="plain" onClick={onSelectAll}>
                {allSelected ? 'Select none' : 'Select all'}
              </ButtonV2>
            }
            title="Filters"
            onClose={() => setOpen(false)}>
            <div className="filterContent">
              <div className="accordions">
                {boards.map((board) => {
                  const boardCheckbox = (
                    <CheckboxWithLabel
                      onChange={(value) => onBoardClick(board, value)}
                      checked={values.boardURLNames.includes(board.urlName)}
                      size="medium">
                      {translateString(board, 'name')}
                    </CheckboxWithLabel>
                  );

                  if (board.categories.length === 0) {
                    return (
                      <div key={board.urlName} className="filterItem">
                        {boardCheckbox}
                      </div>
                    );
                  }

                  return (
                    <Accordion key={board.urlName} className="filterItem" title={boardCheckbox}>
                      <CategoriesAccordion
                        categories={board.categories}
                        onCategoryChange={onCategoryClick}
                        selectedCategories={values.categoryURLNames}
                      />
                    </Accordion>
                  );
                })}
              </div>
              <div className="footer">
                <SwitchWithLabel
                  onChange={(includeUncategorized) =>
                    onFilterChange({ ...values, includeUncategorized })
                  }
                  className="uncategorizedSwitch"
                  size="medium"
                  checked={values.includeUncategorized}>
                  Show uncategorized posts
                </SwitchWithLabel>
              </div>
            </div>
          </Popover>
        </Portal>
      )}
    </>
  );
};

type CategoriesAccordionProps = {
  categories: Category[];
  onCategoryChange: (category: Category, value: boolean) => void;
  selectedCategories: string[];
};

const CategoriesAccordion = ({
  categories,
  selectedCategories,
  onCategoryChange,
}: CategoriesAccordionProps) => {
  const parentCategories = categories.filter((category) => !category.parentID);
  return (
    <div className="categoriesAccordion">
      {parentCategories.map((category) => {
        const childCategories = getChildCategories(categories, category);
        const categoryCheckbox = (
          <CheckboxWithLabel
            onChange={(value) => onCategoryChange(category, value)}
            checked={selectedCategories.includes(category.urlName)}
            size="medium">
            {translateString(category, 'name')}
          </CheckboxWithLabel>
        );

        if (childCategories.length === 0) {
          return (
            <div key={category._id} className="filterItem">
              {categoryCheckbox}
            </div>
          );
        }

        return (
          <Accordion key={category._id} className="filterItem" title={categoryCheckbox}>
            {childCategories.map((childCategory) => {
              return (
                <div key={childCategory._id} className="categoriesAccordion withSpacer">
                  <CheckboxWithLabel
                    onChange={(value) => onCategoryChange(childCategory, value)}
                    checked={selectedCategories.includes(childCategory.urlName)}
                    size="medium">
                    {translateString(childCategory, 'name')}
                  </CheckboxWithLabel>
                </div>
              );
            })}
          </Accordion>
        );
      })}
    </div>
  );
};

export default RoadmapFilter;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import DateRangePicker from 'common/common/DateRangePicker';
import { CompanyContext } from 'common/containers/CompanyContainer';
import UpsellModal from 'common/modals/UpsellModal';
import Tile from 'common/subdomain/admin/AdminAnalytics/Tile';
import AdminDashboardActivityFilterPicker from 'common/subdomain/admin/AdminDashboard/AdminDashboardActivityFilterPicker';
import { RelativeDateRanges } from 'common/util/dateRanges';
import { dayjs } from 'common/util/dayjsUtils';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardActivity.scss';

class AdminDashboardActivity extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.shape({
      created: PropTypes.string,
    }),
    dateRange: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onDateRangeSelected: PropTypes.func.isRequired,
    onFilterUpdate: PropTypes.func.isRequired,
    stats: PropTypes.object,
  };

  state = {
    showUpsellModal: false,
  };

  checkIfCompanyHasEnoughData() {
    const { company, stats } = this.props;

    const creationDate = dayjs(company.created);
    const moreThanFiveDaysOld = creationDate.isBefore(dayjs().subtract(5, 'days'));
    if (moreThanFiveDaysOld) {
      return true;
    }

    const dataTotal =
      stats?.comments.total + stats?.posts.total + stats?.statusChanges.total + stats?.votes.total;
    if (dataTotal > 5) {
      return true;
    }

    return false;
  }

  onUpsell() {
    this.setState({ showUpsellModal: true });
  }

  onUpsellDismiss = () => {
    this.setState({ showUpsellModal: false });
  };

  renderCharts() {
    if (!this.checkIfCompanyHasEnoughData()) {
      return this.renderWelcomeMessage();
    }

    return (
      <>
        {this.renderDateRangePicker()}
        {this.renderFilterPicker()}
        <div className="charts">
          {this.renderPostsChart()}
          {this.renderVotesChart()}
          {this.renderCommentsChart()}
          {this.renderStatusChangesChart()}
        </div>
      </>
    );
  }

  renderCommentsChart() {
    const { stats } = this.props;
    return (
      <Tile
        title="Comments"
        totalBefore={stats?.comments.totalBefore}
        data={stats?.comments.data ?? []}
        total={stats?.comments.total ?? 0}
      />
    );
  }

  renderDateRangePicker() {
    const { company } = this.props;
    const { dateRange, onDateRangeSelected } = this.props;

    let limitedOptions = [];
    const analyticsHistory = company.limits?.analyticsHistory;
    if (analyticsHistory && analyticsHistory <= 30) {
      limitedOptions = [
        RelativeDateRanges.allTime,
        RelativeDateRanges.lastHalf,
        RelativeDateRanges.lastQuarter,
        RelativeDateRanges.thisHalf,
        RelativeDateRanges.thisQuarter,
      ];
    }

    return (
      <div className="dateDropdown">
        <DateRangePicker
          align="end"
          date={dateRange}
          hideOptions={['today']}
          historyDayLimit={analyticsHistory}
          limitedOptions={limitedOptions}
          onLimitExceeded={() => this.onUpsell()}
          onSubmit={onDateRangeSelected}
        />
      </div>
    );
  }

  renderFilterPicker() {
    const { boards, company, onFilterUpdate } = this.props;
    if (!company.features?.advancedAnalytics) {
      return null;
    }

    return (
      <div className="filterDropdown">
        <AdminDashboardActivityFilterPicker
          align="start"
          boards={boards}
          onFilterUpdate={onFilterUpdate}
        />
      </div>
    );
  }

  renderWelcomeMessage() {
    return (
      <div className="welcomeMessage">
        <div className="welcomeHeader">Welcome to Canny!</div>
        <div>You'll see charts of your activity here when you have some feedback.</div>
      </div>
    );
  }

  renderPostsChart() {
    const { stats } = this.props;
    return (
      <Tile
        title="Posts"
        totalBefore={stats?.posts.totalBefore}
        data={stats?.posts.data ?? []}
        total={stats?.posts.total ?? 0}
      />
    );
  }

  renderStatusChangesChart() {
    const { stats } = this.props;
    return (
      <Tile
        title="Status changes"
        totalBefore={stats?.statusChanges.totalBefore}
        data={stats?.statusChanges.data ?? []}
        total={stats?.statusChanges.total ?? 0}
      />
    );
  }

  renderVotesChart() {
    const { stats } = this.props;
    return (
      <Tile
        title="Votes"
        totalBefore={stats?.votes.totalBefore}
        data={stats?.votes.data ?? []}
        total={stats?.votes.total ?? 0}
      />
    );
  }

  render() {
    return (
      <div className="adminDashboardActivity">
        <div className="containerHeader">Activity Overview</div>
        {this.renderCharts()}
        <UpsellModal
          cta="More than 30 days of analytics&nbsp;history"
          feature="limits.analyticsHistory"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsellDismiss}
          show={this.state.showUpsellModal}
        />
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
})(AdminDashboardActivity);

import dayjsLib from 'dayjs';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjsQuarterYearPlugin from 'dayjs/plugin/quarterOfYear';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import dayjsUTCPlugin from 'dayjs/plugin/utc';

dayjsLib.extend(dayjsUTCPlugin);
dayjsLib.extend(dayjsQuarterYearPlugin);
dayjsLib.extend(dayjsCustomParseFormat);
dayjsLib.extend(dayjsRelativeTimePlugin);
dayjsLib.extend(localizedFormat);

export const dayjs = dayjsLib;

import { validateAttribute } from 'common/util/buildAttribute';

const DayInMilliseconds = 1000 * 60 * 60 * 24;
const dateNDaysAgo = (daysAgo) => new Date(new Date() - daysAgo * DayInMilliseconds);
export const OpSelectorCreatorMap = {
  after: (field, value) => ({ [field]: { $gt: new Date(value) } }),
  afterNDaysAgo: (field, value) => ({ [field]: { $gt: dateNDaysAgo(value) } }),
  before: (field, value) => ({ [field]: { $lt: new Date(value) } }),
  beforeNDaysAgo: (field, value) => ({ [field]: { $lt: dateNDaysAgo(value) } }),
  contains: (field, value) => ({ [field]: { $regex: new RegExp(value) } }),
  doesNotContain: (field, value) => ({ [field]: { $not: new RegExp(value) } }),
  greaterThan: (field, value) => ({ [field]: { $gt: value } }),
  is: (field, value) => ({ [field]: { $eq: value } }),
  isFalse: (field) => ({ [field]: { $eq: false } }),
  isTrue: (field) => ({ [field]: { $eq: true } }),
  isNot: (field, value) => ({ [field]: { $ne: value } }),
  isNotNull: (field) => ({ [field]: { $exists: true, $nin: ['', null] } }),
  isNull: (field) => ({ $or: [{ [field]: { $eq: null } }, { [field]: { $exists: false } }] }),
  lessThan: (field, value) => ({ [field]: { $lt: value } }),
  on: (field, value) => ({ [field]: { $eq: new Date(value) } }),
  onNDaysAgo: (field, value) => ({
    [field]: {
      $gt: dateNDaysAgo(value),
      $lt: dateNDaysAgo(value - 1),
    },
  }),
};

const getMongoFilter = (attributes) => {
  const queries = attributes
    .filter((attribute) => {
      try {
        validateAttribute(attribute);
        return true;
      } catch {
        return false;
      }
    })
    .map(({ field, op, value }) => OpSelectorCreatorMap[op](field, value));
  return queries.length
    ? {
        $and: queries,
      }
    : {};
};

export default getMongoFilter;

import React from 'react';

import { Group } from 'lucide-react';

import { type CustomPostField, CustomPostFieldTypes } from 'common/api/resources/postFields';
import SingleSelect from 'common/ui/SingleSelect';

import type { Option } from 'common/ui/common/select/SelectCommon';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapGroupBy.scss';

const DefaultGroupByOptions = [
  { label: 'Board', value: 'Board' },
  { label: 'Category', value: 'Categories' },
  { label: 'Owner', value: 'Owner' },
  { label: 'Status', value: 'Status' },
];

export enum GroupByTypes {
  default = 'default',
  customPostField = 'customPostField',
}

/**
 * @todo: This function only takes into consideration custom post fields of the type "dropdown"
 * */
export const getGroupableCustomPostFields = (customPostFields: CustomPostField[]) => {
  return customPostFields.filter(
    (customPostField) => customPostField.type === CustomPostFieldTypes.dropdown
  );
};

export const getGroupByOptions = (customPostFields: CustomPostField[]) => {
  return DefaultGroupByOptions.concat(
    getGroupableCustomPostFields(customPostFields).map((customPostField) => ({
      label: customPostField.name,
      value: customPostField._id,
    }))
  );
};

type Props = {
  groupBy?: Option;
  customPostFields: CustomPostField[];
  onGroupByChange: (option: Option | undefined) => void;
};

const AdminRoadmapGroupBy = ({ groupBy, onGroupByChange, customPostFields }: Props) => {
  const options = getGroupByOptions(customPostFields);

  const selectedOption: Option | undefined = groupBy
    ? {
        ...groupBy,
        prefixIcon: Group,
        flair: null,
        avatar: null,
      }
    : undefined;

  return (
    <div className="adminRoadmapGroupBy">
      <SingleSelect
        allowClear
        placeholder="Group By"
        value={selectedOption}
        options={options}
        onChange={onGroupByChange}
        labelPlacement="left"
        optionsPlacement="bottom"
      />
    </div>
  );
};

export default AdminRoadmapGroupBy;

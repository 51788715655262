import React from 'react';

import Helmet from 'common/helmets/Helmet';

import AdminChangelogList from './AdminChangelogList';
import AdminChangelogSidebar from './AdminChangelogSidebar';

import type { Changelog, ChangelogEntry } from 'common/api/resources/changelog';

import 'css/components/subdomain/admin/_AdminChangelog.scss';

type Props = {
  changelog: Changelog | null;
  changelogEntryList: Array<ChangelogEntry>;
};

const AdminChangelog = (props: Props) => {
  const { changelog, changelogEntryList } = props;
  if (!changelog) {
    return null;
  }

  return (
    <div className="adminChangelog">
      <Helmet title="Changelog | Canny" />
      <AdminChangelogSidebar changelog={changelog} />
      <AdminChangelogList changelog={changelog} entryList={changelogEntryList} />
    </div>
  );
};

export default AdminChangelog;

import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ModernModal from 'common/modals/ModernModal';
import AdminAutopilotInfoBox from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotInfoBox';
import {
  InfoPoints,
  type Integration,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import ButtonV2 from 'common/ui/ButtonV2';
import { H2, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotIntegrationModal.module.scss';

import CannyLogo from 'img/canny-icon.svg';

import type { Company } from 'common/api/endpoints/companies';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

export type Props = {
  onClose: () => void;
  integration: Integration;
  enableIntegrationSync: (integration: Integration) => void;
  getOAuthURL: () => string;
  connectDisabled?: boolean;
  inputSection?: React.ReactNode;
};

const AdminAutopilotIntegrationModal = ({
  onClose,
  integration,
  enableIntegrationSync,
  getOAuthURL,
  connectDisabled = false,
  inputSection,
}: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);

  const infoPoints = InfoPoints[integration.name] ?? InfoPoints.conversationDefault;

  // state
  const integrationState = company.installedSyncIntegrations.find(
    ({ integrationName }: { integrationName: SyncIntegrationNames }) =>
      integrationName === integration.name
  );

  // helpers
  const onEnableClick = () => {
    enableIntegrationSync(integration);
    onClose();
  };

  // render
  const getConnectButton = () => {
    // Integration hasn't been installed
    if (!integrationState) {
      return (
        <a href={getOAuthURL()} className={styles.link}>
          <ButtonV2 size="medium" disabled={connectDisabled} className={styles.connectButton}>
            Connect
          </ButtonV2>
        </a>
      );
    }

    // Integration is installed, but the sync is disabled
    if (integrationState && integrationState.disabled) {
      return (
        <ButtonV2 size="medium" onClick={onEnableClick} className={styles.connectButton}>
          Connect
        </ButtonV2>
      );
    }

    // integration is installed and enabled - base button should be disabled
    return null;
  };

  const header = (
    <div className={styles.modalHeader}>
      <div className={styles.cannyLogoContainer}>
        <LazyLoadedImage src={integration.icon} className={styles.integrationLogo} alt="" />
      </div>
      <div className={styles.lineContainer}>
        <span className={styles.line} />
        <span className={styles.line} />
      </div>
      <div className={styles.cannyLogoContainer}>
        <LazyLoadedImage src={CannyLogo} className={styles.cannyLogo} alt="" />
      </div>
    </div>
  );

  const footer = (
    <>
      <ButtonV2 variant="outlined" size="medium" onClick={onClose}>
        Cancel
      </ButtonV2>
      {getConnectButton()}
    </>
  );

  return (
    <ModernModal
      modalClassName={styles.modal}
      header={header}
      onClose={onClose}
      sections={[
        <>
          <H2 variant="headingXl" className={styles.heading}>
            Connect {integration.label} to Autopilot
          </H2>
          <P className={styles.subheading}>
            Enable Autopilot to capture user feedback found in {integration.label} conversations.
          </P>
          {inputSection}
          <AdminAutopilotInfoBox points={infoPoints} />
        </>,
      ]}
      footer={footer}></ModernModal>
  );
};

export default AdminAutopilotIntegrationModal;

import { Component } from 'react';

import PropTypes from 'prop-types';

export default class AdminPostV1Redirect extends Component {
  static propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
  };

  componentDidMount() {
    const { params, router } = this.props;
    router.replace(
      `/admin/feedback/${params.boardURLName}/p/${params.postURLName}?boards=${params.boardURLName}`
    );
  }

  render() {
    return null;
  }
}

import React, { useContext } from 'react';

import { MaxPendingDrafts } from 'common/constants/autopilotActions';
import { CompanyContext } from 'common/containers/CompanyContainer';

import AdminQueueAutomationUpsell from './AdminQueueAlerts/AdminQueueAutomationUpsell';
import AdminQueueCreditLimit from './AdminQueueAlerts/AdminQueueCreditLimit';
import AdminQueueCreditWarning from './AdminQueueAlerts/AdminQueueCreditWarning';
import AdminQueueDraftLimit from './AdminQueueAlerts/AdminQueueDraftLimit';
import AdminQueuePaused from './AdminQueueAlerts/AdminQueuePaused';

import type { Counts } from './types';
import type { Company } from 'common/api/endpoints/companies';

type Props = {
  counts: Counts;
};

const AdminQueueBanner = (props: Props) => {
  const company = useContext<Company>(CompanyContext);

  const { counts } = props;
  const { autopilot, installedSyncIntegrations, queueAutomation } = company;
  const { creditLimit, usedCredits } = autopilot;
  const automationDisabled = !queueAutomation?.enabled;

  // no credits
  if (usedCredits >= creditLimit) {
    return <AdminQueueCreditLimit />;
  }

  // draft limit
  const hitDraftLimit = counts.totalSourceCount >= MaxPendingDrafts;
  if (automationDisabled && hitDraftLimit) {
    return <AdminQueueDraftLimit company={company} />;
  }

  // ap paused
  const enabledSources = installedSyncIntegrations.filter((integration) => !integration.disabled);
  if (enabledSources.length === 0) {
    return <AdminQueuePaused />;
  }

  // credit warning
  if (usedCredits >= creditLimit * 0.75) {
    return <AdminQueueCreditWarning />;
  }

  // automation disabled
  if (!queueAutomation?.enabled) {
    return <AdminQueueAutomationUpsell company={company} />;
  }

  return null;
};

export default AdminQueueBanner;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadChangelog } from 'common/actions/changelog';
import { entryLoaded, reloadEntry } from 'common/actions/changelogEntries';
import { invalidateEntryQueries } from 'common/actions/changelogEntryQueries';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Error from 'common/common/Error';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import ErrorModal from 'common/modals/ErrorModal';
import Spinner from 'common/Spinner';
import delayer from 'common/util/delayer';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';

import AdminChangelogCreateComposer from './AdminChangelogCreateComposer';
import AdminChangelogCreatePreview from './AdminChangelogCreatePreview';
import AdminChangelogCreateSidebar, { Types } from './AdminChangelogCreateSidebar';

import 'css/components/subdomain/admin/_AdminChangelogCreate.scss';

const SaveDraftDelay = 1000;

class AdminChangelogCreate extends Component {
  static propTypes = {
    changelog: PropTypes.object,
    company: PropTypes.object,
    entry: PropTypes.object,
    entryLoaded: PropTypes.func,
    invalidateEntry: PropTypes.func,
    openModal: PropTypes.func,
    reloadChangelog: PropTypes.func,
    route: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    deleted: false,
    detailsValue: this.props.entry?.details ?? '',
    entrySaved: false,
    linkedPosts: this.props.entry?.posts ?? [],
    needsPublish: false,
    needsSave: false,
    notify: this.props.entry?.notifySubscribers ?? true,
    publishing: false,
    reloadLabels: false,
    savingEntry: false,
    scheduling: false,
    selectedLabels: this.props.entry?.labelIDs ?? [],
    selectedTypes: this.props.entry?.types ?? [Types.new.name],
    titleValue: this.props.entry?.title ?? '',
    unsavedWork: false,
  };

  componentDidMount() {
    const { company, entry, openModal, route, router, viewer } = this.props;
    if (!hasPermission('manageChangelog', company, viewer)) {
      router.replace('/admin/changelog');
      openModal(
        AccessModal,
        {
          requiredPermissions: ['manageChangelog'],
        },
        {
          allowRouteChange: true,
        }
      );
      return;
    }

    this._draftDelayer = new delayer(this.onSaveEntry, SaveDraftDelay);
    this._isMounted = true;

    window.addEventListener('beforeunload', this.onBeforeUnload, false);
    router.setRouteLeaveHook(route, this.onRouteLeave);

    if (!entry || !entry._id) {
      return;
    }

    router.replace({ pathname: `/admin/changelog/${entry.urlName}/edit` });
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this._draftSavedTimer) {
      clearTimeout(this._draftSavedTimer);
    }

    window.removeEventListener('beforeunload', this.onBeforeUnload, false);
  }

  onBeforeUnload = (e) => {
    const prompt = this.onRouteLeave();
    if (!prompt) {
      return;
    }

    (e || window.event).returnValue = prompt;
    return prompt;
  };

  onDatePicked = (timestamp) => {
    const now = new Date();
    if (timestamp > now) {
      this.onScheduleEntry(timestamp);
    } else {
      this.onPublishEntry(timestamp);
    }
  };

  onDeleteEntry = () => {
    const { entry, openModal } = this.props;

    openModal(ConfirmModal, {
      message: `Are you sure you'd like to delete this entry? This cannot be\u00a0undone.`,
      onConfirm: () => {
        AJAX.post(
          '/api/changelog/deleteEntry',
          {
            entryID: entry._id,
          },
          (response) => {
            if (response !== 'success') {
              openModal(ErrorModal, {
                message: 'Something went wrong, please try again later.',
              });
              return;
            }

            this.setState({ deleted: true });

            const { invalidateEntry } = this.props;
            invalidateEntry(entry);
          }
        );
      },
    });
  };

  onDetailsChange = (detailsValue) => {
    this.setState({ detailsValue });
    this.onShouldSaveDraft();
  };

  onLabelSelected = (label) => {
    const { selectedLabels } = this.state;

    if (selectedLabels.includes(label._id)) {
      this.setState({
        selectedLabels: selectedLabels.filter((labelID) => labelID !== label._id),
        reloadLabels: true,
      });
    } else {
      this.setState({
        selectedLabels: selectedLabels.concat(label._id),
        reloadLabels: true,
      });
    }

    this.onShouldSaveDraft();
  };

  onLinkPost = (post) => {
    const { linkedPosts } = this.state;

    this.setState({
      linkedPosts: linkedPosts.concat(post),
    });

    this.onShouldSaveDraft();
  };

  onPublishEntry = (timestamp) => {
    const { entry, openModal, router, invalidateEntry } = this.props;
    const { publishing, titleValue } = this.state;
    if (publishing) {
      return;
    }

    if (!titleValue) {
      openModal(ErrorModal, {
        message: 'Please add a title to your entry.',
      });
      return;
    }

    this.setState({
      ...(timestamp
        ? {
            scheduling: true,
          }
        : {
            publishing: true,
          }),
    });

    AJAX.post(
      '/api/changelog/publishEntry',
      {
        entryID: entry._id,
        saveID: entry.saveID,
        timestamp,
      },
      async (response) => {
        if (response === 'success') {
          await invalidateEntry(entry);
          router.push(`/changelog/${entry.urlName}`);
          return;
        }

        openModal(ErrorModal, {
          message: 'Something went wrong, please contact our team for support.',
        });
      }
    );
  };

  onPublishTapped = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to publish this entry now?",
      onConfirm: () => {
        const { needsSave, savingEntry } = this.state;
        if (needsSave || savingEntry) {
          this.setState({ needsPublish: true });
          return;
        }
        this.onPublishEntry();
      },
    });
  };

  onNotifyChange = (notify) => {
    this.setState({ notify });
    this.onShouldSaveDraft();
  };

  onRouteLeave = () => {
    if (this._updatingRoute) {
      return;
    }

    const { entry } = this.props;
    const { needsSave, savingEntry, unsavedWork } = this.state;

    if (!entry || entry.status === 'draft') {
      if (needsSave || savingEntry) {
        return `Your draft hasn't finished saving yet. Are you sure you want to leave?`;
      }
    } else if (unsavedWork) {
      return 'Your entry has unsaved changes. Are you sure you want to leave?';
    }
  };

  onSaveEntry = () => {
    const { savingEntry, selectedTypes, titleValue, notify } = this.state;
    if (savingEntry || !titleValue || selectedTypes.length === 0) {
      return;
    }

    this.setState({
      entrySaved: false,
      savingEntry: true,
    });

    const { entry } = this.props;
    const { detailsValue, linkedPosts, selectedLabels } = this.state;
    AJAX.post(
      '/api/changelog/saveEntry',
      {
        details: detailsValue,
        entryID: entry ? entry._id : null,
        labelIDs: selectedLabels,
        postIDs: linkedPosts.map((post) => post._id),
        notify: entry?.firstPublishedAt ? false : notify,
        saveID: entry ? entry.saveID : null,
        title: titleValue,
        types: selectedTypes,
      },
      (response) => {
        let result = null;
        try {
          result = JSON.parse(response);
        } catch (e) {
          result = { error: 'server error' };
        }

        if (result.entry) {
          this.onSaveSuccess(result);
          return;
        }
        this.setState({
          entrySaved: false,
          savingEntry: false,
        });

        if (result.error === 'out of date') {
          this.showOutOfDateErrorModal();
          return;
        }

        if (result.error === 'post taken') {
          this.showPostTakenErrorModal();
          return;
        }

        this.showUnknownErrorModal();
      }
    );
  };

  async onSaveSuccess(result) {
    const { linkedPosts, needsPublish, reloadLabels } = this.state;
    const { entryLoaded, reloadChangelog, router } = this.props;

    result.entry.posts = linkedPosts;

    await entryLoaded(result.entry);
    if (!this._isMounted) {
      return;
    }

    this._updatingRoute = true;
    router.replace(`/admin/changelog/${result.entry.urlName}/edit`);
    this._updatingRoute = false;

    if (reloadLabels) {
      reloadChangelog();
    }

    this.setState({
      entrySaved: true,
      needsSave: false,
      reloadLabels: false,
      savingEntry: false,
      unsavedWork: false,
    });

    this._draftSavedTimer = setTimeout(() => {
      this._draftSavedTimer = null;
      this.setState({ entrySaved: false });
    }, 3000);

    if (needsPublish) {
      this.onPublishEntry();
    }
  }

  onScheduleEntry = (timestamp) => {
    const { scheduling } = this.state;
    const { entry, invalidateEntry, openModal } = this.props;

    if (scheduling) {
      return;
    }

    this.setState({ scheduling: true });

    AJAX.post(
      '/api/changelog/scheduleEntry',
      {
        entryID: entry._id,
        timestamp,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({ scheduling: false });
          openModal(ErrorModal, {
            message: 'Something went wrong, please refresh and try again.',
          });
          return;
        }

        invalidateEntry(entry).then(() => {
          this.setState({ scheduling: false });
        });
      }
    );
  };

  onShouldSaveDraft = () => {
    const { entry } = this.props;
    if (entry && entry.status === 'published') {
      this.setState({ unsavedWork: true });
      return;
    }

    this.setState({ needsSave: true });

    const { titleValue } = this.state;
    if (!titleValue || !titleValue.trim()) {
      return;
    }

    this._draftDelayer.callAfterDelay();
  };

  onTitleChange = (e) => {
    const titleValue = e.target.value;
    this.setState({ titleValue }, () => {
      this.onShouldSaveDraft();
    });
  };

  onTypeChange = (type, checked) => {
    const { selectedTypes } = this.state;

    if (checked) {
      this.setState({
        selectedTypes: selectedTypes.concat(type.name),
      });
    } else {
      this.setState({
        selectedTypes: selectedTypes.filter((t) => t !== type.name),
      });
    }

    this.onShouldSaveDraft();
  };

  onUnlinkPost = (postToUnlink) => {
    const { linkedPosts } = this.state;

    this.setState({
      linkedPosts: linkedPosts.filter((post) => {
        return post !== postToUnlink;
      }),
    });

    this.onShouldSaveDraft();
  };

  onUnpublishEntry = () => {
    const { unpublishing } = this.state;
    const { entry, invalidateEntry, openModal } = this.props;

    if (unpublishing) {
      return;
    }

    this.setState({ unpublishing: true });

    AJAX.post(
      '/api/changelog/unpublishEntry',
      {
        entryID: entry._id,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({ unpublishing: false });
          openModal(ErrorModal, {
            message: 'Something went wrong, please refresh and try again.',
          });
          return;
        }
        invalidateEntry(entry).then(() => {
          this.setState({ unpublishing: false });
        });
      }
    );
  };

  onUnscheduleEntry = () => {
    const { unscheduling } = this.state;
    const { entry, invalidateEntry, openModal } = this.props;

    if (unscheduling) {
      return;
    }

    this.setState({ unscheduling: true });

    AJAX.post(
      '/api/changelog/unscheduleEntry',
      {
        entryID: entry._id,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({ unscheduling: false });
          openModal(ErrorModal, {
            message: 'Something went wrong, please refresh and try again.',
          });
          return;
        }

        invalidateEntry(entry).then(() => {
          this.setState({ unscheduling: false });
        });
      }
    );
  };

  showOutOfDateErrorModal() {
    this.props.openModal(ErrorModal, {
      message: (
        <div>
          <div className="part">
            This draft has been changed since you loaded it. Please refresh the page to make
            changes.
          </div>
          <div className="part">
            You will lose any unsaved work, so be sure to copy your work before refreshing.
          </div>
        </div>
      ),
    });
  }

  showPostTakenErrorModal() {
    this.props.openModal(ErrorModal, {
      message: (
        <div>
          <div className="part">
            Posts can only be linked to one entry. One of your linked posts has already been linked
            to another entry.
          </div>
          <div className="part">
            You will lose any unsaved work, so be sure to copy your work before refreshing.
          </div>
        </div>
      ),
    });
  }

  showUnknownErrorModal() {
    this.props.openModal(ErrorModal, {
      message: (
        <div>
          <div className="part">
            Something went wrong while trying to save your draft. Please contact our team for
            support.
          </div>
          <div className="part">
            You may lose any unsaved work, so be sure to copy your work before leaving the page.
          </div>
        </div>
      ),
    });
  }

  renderContent() {
    const { changelog, entry, company } = this.props;
    const { deleted } = this.state;

    if (entry?.error) {
      return (
        <div className="entryErrorWrapper">
          <Error />
        </div>
      );
    }

    if (deleted) {
      return <div className="entryDeleted">This entry has been successfully deleted.</div>;
    }

    if (entry && entry.loading) {
      return (
        <div className="entryLoading">
          <Spinner />
        </div>
      );
    }

    if (entry && entry.notFound) {
      return (
        <div className="entryErrorWrapper">
          <Error
            heading="Oops, we can't find this entry!"
            subHeading="If you think it should exist, refresh the page and try again"
          />
        </div>
      );
    }

    return (
      <>
        <AdminChangelogCreateSidebar
          changelog={changelog}
          disabled={this.state.needsPublish || this.state.publishing}
          entry={entry}
          linkedPosts={this.state.linkedPosts}
          onDeleteEntry={this.onDeleteEntry}
          onLabelSelected={this.onLabelSelected}
          onLinkPost={this.onLinkPost}
          onShouldSaveDraft={this.onShouldSaveDraft}
          onTypeChange={this.onTypeChange}
          onUnlinkPost={this.onUnlinkPost}
          selectedLabels={this.state.selectedLabels}
          selectedTypes={this.state.selectedTypes}
        />
        <AdminChangelogCreatePreview
          changelog={changelog}
          details={this.state.detailsValue}
          entry={entry}
          selectedLabels={this.state.selectedLabels}
          selectedTypes={this.state.selectedTypes}
          title={this.state.titleValue}
          unsavedWork={this.state.unsavedWork}
        />
        <AdminChangelogCreateComposer
          detailsValue={this.state.detailsValue}
          entry={entry}
          entrySaved={this.state.entrySaved}
          needsPublish={this.state.needsPublish}
          notifyEnabled={
            company.features.changelogEmailSubscriptions &&
            changelog.enableEmailSubscriptions &&
            !entry?.publishedAt
          }
          onDatePicked={this.onDatePicked}
          onDetailsChange={this.onDetailsChange}
          onPublishTapped={this.onPublishTapped}
          onNotifyChange={this.onNotifyChange}
          onSaveEntry={this.onSaveEntry}
          onTitleChange={this.onTitleChange}
          onUnpublishEntry={this.onUnpublishEntry}
          onUnscheduleEntry={this.onUnscheduleEntry}
          publishing={this.state.publishing}
          notify={this.state.notify}
          savingEntry={this.state.savingEntry}
          scheduling={this.state.scheduling}
          selectedTypes={this.state.selectedTypes}
          unpublishing={this.state.unpublishing}
          unsavedWork={this.state.unsavedWork}
          unscheduling={this.state.unscheduling}
        />
      </>
    );
  }

  render() {
    const { entry } = this.props;
    const title = (entry && entry.title ? entry.title : 'New Entry') + ' | Changelog | Canny';

    return (
      <div className="adminChangelogCreate">
        <Helmet title={title} />
        {this.renderContent()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    entryLoaded: (entry) => {
      return Promise.all([
        dispatch(entryLoaded(entry.urlName, entry)),
        dispatch(invalidateEntryQueries()),
        dispatch(reloadCompany()),
      ]);
    },
    invalidateEntry: (entry) => {
      return Promise.all([
        dispatch(reloadEntry(entry)),
        dispatch(invalidateEntryQueries()),
        dispatch(reloadCompany()),
      ]);
    },
    reloadChangelog: () => {
      return Promise.all([dispatch(reloadChangelog())]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminChangelogCreate);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import seedrandom from 'seedrandom';

import cloneElementWithProps from '../core/cloneElementWithProps';
import connect from '../core/connect';
import Experiments from '../experiments/Experiments';
import generateRandomID from '../generateRandomID';

export const GetExperimentGroupsContext = React.createContext();
export const GetExperimentVariationContext = React.createContext();

const ExperimentCookieName = '__canny__experimentID';

class ExperimentContainer extends Component {
  static propTypes = {
    cookies: PropTypes.object,
  };

  getExperimentID = () => {
    const { cookies } = this.props;
    if (!cookies[ExperimentCookieName]) {
      console.error('[ExperimentContainer]: No experimentID cookie set');
    }

    const experimentID = cookies[ExperimentCookieName] || generateRandomID();
    return experimentID;
  };

  getGroups = () => {
    const groups = {};
    Object.keys(Experiments).forEach((experimentName) => {
      const experiment = Experiments[experimentName];
      const variation = this.getVariation(experiment);
      groups['E_' + experiment.name] = 'V_' + variation.name;
    });
    return groups;
  };

  getVariation = (experiment) => {
    if (!experiment) {
      console.error('[ExperimentContainer]: Called getVariation with null experiment.');
      return null;
    }

    // generate consistent unique seed for experiment + user
    const experimentID = this.getExperimentID();
    const experimentName = experiment.name;
    const seed = experimentName + '/' + experimentID;
    const rng = seedrandom(seed);
    const random = rng();

    // figure out which variation they're in
    const { variations } = experiment;
    const resultName = Object.keys(variations).find((variationName) => {
      const variation = variations[variationName];
      return random >= variation.minWeight && random <= variation.maxWeight;
    });
    return variations[resultName];
  };

  render() {
    return (
      <GetExperimentGroupsContext.Provider value={this.getGroups}>
        <GetExperimentVariationContext.Provider value={this.getVariation}>
          {cloneElementWithProps(this.props.children, {
            ...this.props,
          })}
        </GetExperimentVariationContext.Provider>
      </GetExperimentGroupsContext.Provider>
    );
  }
}

export default connect((state) => ({ cookies: state.cookies }))(ExperimentContainer);

import Data from '../Data';

// Action Types

export const SUGGESTIONS_LOADED = 'canny/mention_suggestions/suggestions_loaded';
export const SUGGESTIONS_ERROR = 'canny/mention_suggestions/suggestions_error';

// Actions

function suggestionsLoaded(boardID, prefix, membersOnly, suggestions) {
  return {
    boardID,
    membersOnly,
    key: getMentionQueryKey(boardID, prefix, membersOnly),
    prefix,
    suggestions,
    timestamp: Date.now(),
    type: SUGGESTIONS_LOADED,
  };
}

function suggestionsError(boardID, prefix, membersOnly, error) {
  return {
    boardID,
    error,
    key: getMentionQueryKey(boardID, prefix, membersOnly),
    membersOnly,
    prefix,
    timestamp: Date.now(),
    type: SUGGESTIONS_ERROR,
  };
}

// Action Creators

function fetchSuggestions(boardID, prefix, membersOnly) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        suggestions: {
          input: {
            boardID,
            membersOnly,
            prefix,
          },
          query: Data.queries.mentionSuggestions,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(suggestionsError(boardID, prefix, membersOnly, error));
        } else {
          return dispatch(suggestionsLoaded(boardID, prefix, membersOnly, data.suggestions));
        }
      }
    );
  };
}

export function loadSuggestions(boardID, prefix, membersOnly = false) {
  return (dispatch, getState) => {
    if (shouldFetchSuggestions(getState(), boardID, prefix, membersOnly)) {
      return dispatch(fetchSuggestions(boardID, prefix, membersOnly));
    }
  };
}

// Helpers

export function getMentionQueryKey(boardID, prefix, membersOnly) {
  const queryObject = {
    boardID,
    membersOnly,
    prefix,
  };
  return JSON.stringify(queryObject);
}

function getMentions(state, boardID, prefix, membersOnly) {
  const queryKey = getMentionQueryKey(boardID, prefix, membersOnly);
  return state.mentionSuggestions[queryKey];
}

function shouldFetchSuggestions(state, boardID, prefix, membersOnly) {
  if (typeof prefix !== 'string' || prefix.length === 0) {
    return false;
  }

  return !getMentions(state, boardID, prefix, membersOnly);
}

import Data from 'common/Data';

import { postsLoaded } from './posts';

// Action Types

export const RequestThirdPartyCompanyPosts =
  'canny/thirdPartyCompanyPosts/request_third_party_company_posts';
export const ThirdPartyCompanyPostsLoaded =
  'canny/thirdPartyCompanyPosts/third_party_company_posts_loaded';
export const ThirdPartyCompanyPostsError =
  'canny/thirdPartyCompanyPosts/third_party_company_posts_error';
export const LoadingMore = 'canny/thirdPartyCompanyPosts/loading_more';

// Actions

function requestThirdPartyCompanyPosts(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestThirdPartyCompanyPosts,
  };
}

export function thirdPartyCompanyPostsLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: ThirdPartyCompanyPostsLoaded,
  };
}

function thirdPartyCompanyPostsError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: thirdPartyCompanyPostsError,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LoadingMore,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return async (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: getRequestData(queryParams),
          query: Data.queries.thirdPartyCompanyPosts,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, queryParams, error, data);
      }
    );
  };
}

export function loadQuery(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestThirdPartyCompanyPosts(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(postList) {
  return (dispatch) => {
    const queryParams = postList.queryParams;
    dispatch(loadingMore(queryParams));
    queryParams.limit = queryParams.limit ? queryParams.limit + 10 : 20;
    return dispatch(fetchQuery(queryParams));
  };
}

// Helper Functions

export function getThirdPartyCompanyPostsKey(queryParams) {
  return JSON.stringify({
    filter: queryParams.filter,
    ...(queryParams.urlName && { urlName: queryParams.urlName }),
  });
}

function getRequestData(queryParams) {
  return {
    limit: queryParams.limit || 10,
    urlName: queryParams.urlName,
    filter: queryParams.filter.split('-'),
  };
}

function shouldFetchQuery(state, queryParams) {
  const thirdPartyCompanyPosts = state.thirdPartyCompanyPosts;
  const queryKey = getThirdPartyCompanyPostsKey(queryParams);
  return !thirdPartyCompanyPosts[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const thirdPartyCompanyPosts = state.thirdPartyCompanyPosts;
  const queryKey = getThirdPartyCompanyPostsKey(queryParams);
  return !!thirdPartyCompanyPosts[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getThirdPartyCompanyPostsKey(queryParams);
  return new Promise((resolve) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(resolve);
  });
}

async function gotResult(dispatch, queryParams, error, data) {
  let resultAction;
  const promises = [];
  if (error) {
    resultAction = thirdPartyCompanyPostsError(queryParams, error);
  } else {
    resultAction = thirdPartyCompanyPostsLoaded(queryParams, data.result);
    promises.push(dispatch(postsLoaded(data.result.postList.posts)));
  }

  await Promise.all(promises);
  await dispatch(resultAction);

  if (!resultCallbacks[queryParams]) {
    return;
  }

  resultCallbacks[queryParams].forEach((resultCallback) => {
    resultCallback();
  });
  resultCallbacks[queryParams].length = 0;
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Helmet from 'common/helmets/Helmet';
import devURL from 'common/util/devURL';
import loadScript from 'common/util/loadScript';
import withContexts from 'common/util/withContexts';

export const MoveIntercomContext = React.createContext();
export const ShowIntercomContext = React.createContext();
export const TrackIntercomEventContext = React.createContext();

const AppID = 'dk4rtzsf';
const DisabledCompanyIDs = [
  '5e70396a09cda666a94f978d', // Drift
];
const DisallowedPages = [/\/install-teams/, /\/asana-redirect/];

function loadIntercomSDK() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('reattach_activator');
    window.Intercom('update', window.intercomSettings);
  } else {
    window.Intercom = function () {
      window.Intercom.c(arguments);
    };
    window.Intercom.q = [];
    window.Intercom.c = function (args) {
      window.Intercom.q.push(args);
    };
    loadScript(`https://widget.intercom.io/widget/${AppID}`, 'intercom-script');
  }
}

function dateStringToUnixTimestamp(dateString) {
  return Math.round(new Date(dateString).getTime() / 1000);
}

class IntercomContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      subdomain: PropTypes.string,
      viewerIsMember: PropTypes.bool,
    }),
    location: PropTypes.object,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      companies: PropTypes.array,
      created: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  state = {
    hidden: false,
  };

  componentDidMount() {
    this._booted = false;
    if (__DEV__) {
      return;
    }

    const {
      company,
      location: { pathname },
      viewer,
    } = this.props;

    const { host } = window.location;
    const isDisallowedPage = DisallowedPages.some((page) => page.test(pathname));

    if (isDisallowedPage) {
      // Don't boot Intercom on the disallowed pages.
      return;
    }

    const isDevelopersPage = host === devURL('developers.canny.io');
    const isLandingPage = !company;
    const isCannySubdomain = company?.subdomain === 'feedback'; // company is feedback.canny.io
    const viewerIsNotMember = !company?.viewerIsMember;
    const isCompanyDisabled = DisabledCompanyIDs.includes(company?._id);

    if (!isLandingPage && !isDevelopersPage) {
      if (isCompanyDisabled) {
        return;
      }
      if (viewerIsNotMember && !isCannySubdomain) {
        return;
      }
    }

    const viewerHasCompany = viewer?.companies?.length;
    loadIntercomSDK();

    this._booted = true;
    if (viewer.error || viewer.loggedOut || !viewerHasCompany) {
      window.Intercom('boot', {
        app_id: AppID,
      });
      this.checkVisibility();
      return;
    }

    const companies = [];
    viewer.companies.forEach((company) => {
      companies.push({
        boardCount: company.boardCount,
        company_id: company._id,
        created_at: dateStringToUnixTimestamp(company.created),
        name: company.name,
        subdomain: company.subdomain,
      });
    });
    window.intercomSettings = {
      app_id: AppID,
      companies,
      created_at: dateStringToUnixTimestamp(viewer.created),
      email: viewer.email,
      name: viewer.name,
      signed_up_at: dateStringToUnixTimestamp(viewer.created),
      user_hash: viewer.intercomHash,
      user_id: viewer._id,
      ...(viewer.avatarURL && {
        avatar: {
          image_url: viewer.avatarURL,
        },
      }),
    };
    window.Intercom('boot', window.intercomSettings);

    this.checkVisibility();
  }

  componentDidUpdate() {
    if (__DEV__ || !this._booted) {
      return;
    }

    this.checkVisibility();
  }

  checkVisibility() {
    const {
      location: { pathname },
    } = this.props;

    const hideIntercom =
      pathname.startsWith('/admin/changelog/create') ||
      /\/admin\/changelog\/.*\/edit/.test(pathname);

    if (this.state.hidden !== hideIntercom) {
      this.setState(
        {
          hidden: hideIntercom,
        },
        () => {
          if (window.Intercom) {
            window.Intercom('update', { hide_default_launcher: hideIntercom });
          }
        }
      );
    }
  }

  onMoveIntercom = async (horizontalPadding) => {
    if (__DEV__) {
      return;
    }

    if (!this._booted) {
      setTimeout(() => {
        this.onMoveIntercom(horizontalPadding);
      }, 0);
      return;
    }

    window.Intercom('update', { horizontal_padding: horizontalPadding });
  };

  onShowIntercom = () => {
    if (__DEV__ || !this._booted) {
      return;
    }

    window.Intercom('showNewMessage');
  };

  onTrackEvent = (eventName, eventData) => {
    if (__DEV__ || !this._booted) {
      return;
    }

    window.Intercom('trackEvent', eventName, eventData);
  };

  render() {
    return (
      <MoveIntercomContext.Provider value={this.onMoveIntercom}>
        <ShowIntercomContext.Provider value={this.onShowIntercom}>
          <TrackIntercomEventContext.Provider value={this.onTrackEvent}>
            <Helmet
              link={[
                {
                  href: 'https://widget.intercom.io',
                  rel: 'preconnect',
                },
                {
                  href: 'https://js.intercomcdn.com',
                  rel: 'preconnect',
                },
                {
                  href: 'https://api-iam.intercom.io',
                  rel: 'preconnect',
                },
              ]}
            />
            {this.props.children}
          </TrackIntercomEventContext.Provider>
        </ShowIntercomContext.Provider>
      </MoveIntercomContext.Provider>
    );
  }
}

export default compose(
  withContexts({
    company: CompanyContext,
    location: LocationContext,
    viewer: ViewerContext,
  })
)(IntercomContainer);

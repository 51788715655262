import Colors from 'common/colors/constants';

export const Types = {
  all: {
    color: 'gray80',
    name: 'all',
    render: 'All entries',
    pillStyle: {
      color: Colors.gray110,
      background: Colors.gray30,
    },
  },
  new: {
    color: 'mint90',
    name: 'new',
    render: 'New',
    pillStyle: {
      color: Colors.mint110,
      background: Colors.mint30,
    },
  },
  improved: {
    color: 'blue90',
    name: 'improved',
    render: 'Improved',
    pillStyle: {
      color: Colors.blue110,
      background: Colors.blue30,
    },
  },
  fixed: {
    color: 'purple90',
    name: 'fixed',
    render: 'Fixed',
    pillStyle: {
      color: Colors.purple110,
      background: Colors.purple30,
    },
  },
} as const;

export const isTypeKey = (maybeType: unknown): maybeType is keyof typeof Types => {
  if (maybeType && typeof maybeType === 'string' && maybeType in Types) {
    return true;
  }

  return false;
};

import { type QueryParams } from 'common/actions/queueItemQueries';

import type { Board } from 'common/api/endpoints/boards';
import type { ActionItem, QueuePost, QueuePostDraft } from 'common/api/endpoints/queue';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { InboxItem as SpamInboxItem } from 'common/api/resources/inbox';
import type { Post } from 'common/api/resources/posts';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

export type InboxDraftItem = QueuePostDraft & {
  author: StrippedUser;
  board: Board;
};

export type InboxCannyPostItem = QueuePost & {
  author: StrippedUser;
  board: Board;
};

export type QueueInboxItem = InboxDraftItem | InboxCannyPostItem;

export type QueueRow = {
  id: string;
  source: string;
  item: QueueInboxItem;
  actions: {
    approve: () => Promise<void>;
    delete: () => Promise<void>;
    merge: (post: Post) => Promise<void>;
  };
};

export type ActionRow = {
  id: string;
  source: string;
  item: ActionItem;
  actions: {
    create: () => Promise<void>;
    delete: () => Promise<void>;
    merge: (post: Post) => Promise<void>;
  };
};

export enum InboxType {
  comment = 'comment',
  post = 'post',
}

export type SpamRow = {
  id: string;
  loading: boolean;
  score: number;
  type: InboxType;
  item: SpamInboxItem;
  actions: {
    approve: () => Promise<void>;
    reject: () => Promise<void>;
    delete: () => Promise<void>;
  };
};

export type PostModalDraft = {
  title: string;
  details: string | null;
  internalComment: string | null;
  author: StrippedUser;
};

export type QueueItemsState = {
  hasMore: boolean;
  items: QueueInboxItem[];
  loading: boolean;
  error?: string;
  pages?: number;
  loadingMore: boolean;
  queryParams: QueryParams;
};

export type ActionItemsState = {
  hasMore: boolean;
  items: ActionItem[];
  loading: boolean;
  error?: string;
  pages?: number;
  loadingMore: boolean;
  queryParams: QueryParams;
};

export type Counts = {
  totalCount: number;
  cannyCount: number;
  totalSourceCount: number;
  perSourceCounts: Partial<Record<SyncIntegrationNames, number>>;
};

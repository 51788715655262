import React, { Component } from 'react';

import { Unlink } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import devURL from 'common/util/devURL';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import AdminSalesforceSync from './AdminSalesforceSync';

import 'css/components/subdomain/admin/AdminSalesforceSettings/_AdminSalesforceSettings.scss';

const AuthorizeURI = 'https://login.salesforce.com/services/oauth2/authorize';
const ConsumerKey = publicConfig('salesforceConsumerKey');
const RedirectURI = devURL('https://canny.io/salesforce');

class AdminSalesforceSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        code: PropTypes.string,
        error: PropTypes.string,
        errorDescription: PropTypes.string,
      }),
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.shape({
      email: PropTypes.string,
    }),
  };

  state = {
    authError: this.props.location.query.error,
    authErrorDescription: this.props.location.query.errorDescription,
    code: this.props.location.query.code,
    error: null,
    reauth: this.props.location.query.reauth,
  };

  componentDidMount() {
    const { location, router } = this.props;
    router.replace({
      pathname: location.pathname,
    });

    const { code, reauth } = this.state;
    if (!code) {
      return;
    }

    const url = reauth ? '/api/salesforce/reauth' : '/api/salesforce/install';
    AJAX.post(
      url,
      {
        code,
        redirectURI: RedirectURI,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later.',
          });
        } else {
          this.props.reloadCompany();
        }
      }
    );
  }

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Salesforce organization?",
      onConfirm: () => {
        AJAX.post('/api/salesforce/disconnect', {}, (response) => {
          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please contact our team for support.',
            });
          } else {
            this.props.reloadCompany();
          }
        });
      },
    });
  };

  renderAuthError() {
    const { authError, authErrorDescription } = this.state;
    if (!authError) {
      return null;
    }

    return (
      <p className="salesforceError">
        Something went wrong.
        <br />
        Error: {authError}
        <br />
        Description: {authErrorDescription}
      </p>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <p className="salesforceError">{error}</p>;
  }

  renderConnection() {
    const {
      company: { salesforce, subdomain },
      viewer,
    } = this.props;
    if (!salesforce) {
      const authParams = {
        client_id: ConsumerKey,
        login_hint: viewer.email,
        redirect_uri: RedirectURI,
        response_type: 'code',
        state: JSON.stringify({ subdomain }),
      };
      const authURI = AuthorizeURI + queryString.stringify(authParams);
      return (
        <div className="connect">
          <p className="text">Give Canny API access to your Salesforce Instance</p>
          {this.renderAuthError()}
          {this.renderError()}
          <a className="link" href={authURI} rel="noopener" target="_blank">
            <Button disabled={salesforce} buttonType="cannyButton" value="Authenticate" />
          </a>
        </div>
      );
    }

    if (!salesforce.lostAccess) {
      return (
        <div className="connection">
          <p className="text">Your Salesforce organization is connected.</p>
          <p className="text">Organization ID: {salesforce.organizationID}</p>
          <button className="disconnect" onClick={this.onDisconnect}>
            <Unlink class="unlink" size={14} />
            Disconnect
          </button>
        </div>
      );
    }

    const authParams = {
      client_id: ConsumerKey,
      login_hint: viewer.email,
      redirect_uri: RedirectURI,
      response_type: 'code',
      state: JSON.stringify({ subdomain, reauth: true }),
    };
    const authURI = AuthorizeURI + queryString.stringify(authParams);
    return (
      <div className="reauth">
        <p className="text">
          Canny lost access to Salesforce. Please re-authenticate to allow&nbsp;access or disconnect
          to setup a new integration.
        </p>
        <a className="link" href={authURI} rel="noopener" target="_blank">
          <Button buttonType="cannyButton" className="reauthButton" value="Reauthenticate" />
        </a>
        <button className="disconnect" onClick={this.onDisconnect}>
          <Unlink class="unlink" size={14} />
          Disconnect
        </button>
      </div>
    );
  }

  renderAPIConnect() {
    return (
      <div className="integration">
        <h2 className="heading">Salesforce API connect</h2>
        {this.renderConnection()}
      </div>
    );
  }

  renderDataSync() {
    const {
      company: { salesforce },
    } = this.props;
    if (!salesforce || salesforce.lostAccess) {
      return;
    }

    return (
      <div className="integration">
        <h2 className="heading">Salesforce Data Sync</h2>
        <AdminSalesforceSync />
      </div>
    );
  }

  renderInstallation() {
    const {
      company: { salesforce },
    } = this.props;
    if (!salesforce.installed) {
      return (
        <div className="install">
          <p className="text">
            Install Canny's Salesforce Widget into your Salesforce instance (Only available for
            Salesforce Enterprise Editions Customers).
          </p>
          <a className="link" href={salesforce.installURL} rel="noopener" target="_blank">
            <Button buttonType="cannyButton" value="Install Package" />
          </a>
        </div>
      );
    }

    return (
      <div className="installation">
        <p className="text">Your Salesforce widget is installed.</p>
        {salesforce.canUpgrade ? (
          <a className="link" href={salesforce.installURL} rel="noopener" target="_blank">
            <Button
              buttonType="ghostButton"
              className="upgrade"
              value={`Upgrade integration to v${salesforce.packageVersion}`}
            />
          </a>
        ) : null}
      </div>
    );
  }

  renderWidgetIntegration() {
    const {
      company: { salesforce },
    } = this.props;
    if (!salesforce || salesforce.lostAccess) {
      return;
    }

    return (
      <div className="integration">
        <h2 className="heading">Salesforce Widget</h2>
        {this.renderInstallation()}
      </div>
    );
  }

  renderContent() {
    const { company } = this.props;
    if (!company?.integrations?.salesforce) {
      if (company?.salesforce) {
        return <div className="integrations">{this.renderAPIConnect()}</div>;
      }

      return (
        <AdminFeatureBlock
          benefit="Available on the Canny Business plan—contact us for details."
          feature="Salesforce integration"
          showBillingLink={false}
        />
      );
    }

    return (
      <div className="integrations">
        {this.renderAPIConnect()}
        {this.renderDataSync()}
        {this.renderWidgetIntegration()}
      </div>
    );
  }

  render() {
    return (
      <div className="adminSalesforceSettings">
        <Helmet title="Salesforce Integration | Canny" />
        <h1 className="settingsHeading">Salesforce Integration</h1>
        <div className="content">
          <div className="description">
            <p className="text">
              Canny's Salesforce integration lets you keep track of feedback from sales
              contacts-without leaving&nbsp;Salesforce.
            </p>
            <a
              className="link"
              href="https://canny.io/integrations/salesforce"
              rel="noopener"
              target="_blank">
              Learn more
            </a>
            <a
              className="link"
              href="https://help.canny.io/en/articles/3808707-salesforce-integration"
              rel="noopener"
              target="_blank">
              Setting up the integration
            </a>
          </div>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.integrations.salesforce),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminSalesforceSettings);

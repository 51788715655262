import { ErrorType, LoadedType, RequestType } from 'common/actions/tldvSettings';

import requestReducerFactory, { defaultState as _defaultState } from './requestReducerFactory';

import type { TLDVInstallation } from 'common/api/endpoints/tldv';

const reducer = requestReducerFactory<{ installation: TLDVInstallation }>(
  RequestType,
  LoadedType,
  ErrorType
);

export type TLDVSettingsState = ReturnType<typeof reducer>;
export const defaultState = _defaultState;
export default reducer;

import Data from '../Data';

// Action Types

export const SuggestionsLoaded = 'canny/user_suggestions/suggestions_loaded';
export const SuggestionsError = 'canny/user_suggestions/suggestions_error';
export const Invalidate = 'canny/user_suggestions/invalidate';

// Actions

function suggestionsLoaded(search, suggestions) {
  return {
    search,
    suggestions,
    timestamp: Date.now(),
    type: SuggestionsLoaded,
  };
}

function suggestionsError(search, error) {
  return {
    error,
    search,
    timestamp: Date.now(),
    type: SuggestionsError,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchSuggestions(search) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: {
            search,
            limit: 10,
          },
          query: Data.queries.users,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(suggestionsError(search, error));
        } else {
          return dispatch(suggestionsLoaded(search, data.result.users));
        }
      }
    );
  };
}

export function loadSuggestions(search) {
  return (dispatch, getState) => {
    if (shouldFetchSuggestions(getState(), search)) {
      return dispatch(fetchSuggestions(search));
    }
  };
}

export function invalidateSuggestions() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helpers

function shouldFetchSuggestions(state, search) {
  if (typeof search !== 'string' || search.length === 0) {
    return false;
  }

  const { userSuggestions } = state;
  return !userSuggestions[search];
}

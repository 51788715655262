import React from 'react';

import classnames from 'classnames';
import { ArrowDownRight, ArrowUpRight } from 'lucide-react';

import SimpleLineChart from 'common/charts/SimpleLineChart';
import { isNotNil } from 'common/util/isNil';
import nbspLastSpace from 'common/util/nbspLastSpace';

import 'css/components/subdomain/admin/AdminAnalytics/_Tile.scss';

type DiffIndicatorProps = {
  diff: number;
};

const DiffIndicator = ({ diff }: DiffIndicatorProps) => {
  if (diff === 0 || diff === Infinity) {
    return null;
  }

  return (
    <div
      className={classnames('diffIndicator', {
        green: diff > 0,
        red: diff < 0,
      })}>
      <div className="diffIcon">
        {diff > 0 ? (
          <ArrowUpRight className="increaseArrow" size={12} />
        ) : (
          <ArrowDownRight className="decreaseArrow" size={12} />
        )}
      </div>
      <div className="diffValue">{Math.abs(diff)}%</div>
    </div>
  );
};

type TileProps = {
  title: string;
  total: number;
  totalBefore?: number;
  data: number[];
};

const Tile = ({ title, total, totalBefore, data }: TileProps) => {
  // calculate increase/decrease
  const diff = isNotNil(totalBefore) ? Math.round(((total - totalBefore) / totalBefore) * 100) : 0;

  return (
    <div className="tile">
      <div className="tileDetails">
        <div className="tileTitle">{nbspLastSpace(title)}</div>
        <div className="tileValue">
          <div className="tileTotal">{total}</div>
          {diff ? <DiffIndicator diff={diff} /> : null}
        </div>
      </div>
      <div className="tileCanvas">
        <SimpleLineChart data={data} />
      </div>
    </div>
  );
};

export default Tile;

import loadScript from 'common/util/loadScript';

const GAdsEventMap = {
  'Registered Company': 'AW-852677599/17GZCNvf27MYEN-ny5YD',
  'Registered Qualified Company': 'AW-852677599/HPLWCN7f27MYEN-ny5YD',
  'Registered VIP Company': 'AW-852677599/4MHFCOHf27MYEN-ny5YD',
  'Demo Request': 'AW-852677599/UepfCOTf27MYEN-ny5YD',
  'Qualified Demo Request': 'AW-852677599/4X1ACOff27MYEN-ny5YD',
  'VIP Demo Request': 'AW-852677599/F9ffCOrf27MYEN-ny5YD',
};

class GoogleAnalytics {
  adsEnabled = false;

  properties = {};

  trackers = {};

  disableProperty(config) {
    window[`ga-disable-${config.measurementID}`] = true;
  }

  enableProperty(config) {
    window[`ga-disable-${config.measurementID}`] = false;
  }

  initProperty(config) {
    // make sure we aren't already tracking property
    if (this.properties[config.measurementID]) {
      return;
    }

    this.properties[config.measurementID] = config;
    this.trackers[config.trackerName] = config;

    config.enableAdsTracking =
      typeof config.enableAdsTracking === 'undefined' ? false : config.enableAdsTracking;

    if (config.enableAdsTracking) {
      this.adsEnabled = true;
    }

    // load required script
    this.loadGTM(config.measurementID);

    window.gtag('config', config.measurementID, {
      allow_google_signals: config.enableAdsTracking,
      groups: config.trackerName,
    });

    if (!config.history) {
      return;
    }

    config.history.listen((location) => {
      const isPathExcluded = (config.excludePathnames || []).some((excludePathname) => {
        return location.pathname.match(excludePathname);
      });
      if (isPathExcluded) {
        // disable property on excluded paths to avoid logging events if
        // enhanced measurement is enabled.
        this.disableProperty(config);
        return false;
      }

      this.enableProperty(config);

      // send new page view when path changes
      window.gtag('event', 'page_view', {
        page_location: `${location.pathname}${location.search}`,
        page_path: location.pathname,
        send_to: config.trackerName,
      });
    });
  }

  loadGTM(measurementID) {
    // Loads required Google Analytics V4 Property script
    const gaURL = `https://www.googletagmanager.com/gtag/js?id=${measurementID}`;
    const alreadyLoaded = !loadScript(gaURL, `gtag-global`);

    if (alreadyLoaded) {
      return;
    }

    // configure
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
  }

  track(config) {
    // only run on client
    if (typeof window === 'undefined') {
      return;
    }

    // do not run in SSR test mode
    if (__SSR_TEST_RUNNER__) {
      return;
    }

    // do not run in chrome extension
    if (__BROWSER_EXTENSION__) {
      return;
    }

    // invalid config
    if (typeof config !== 'object' || !config.trackerName) {
      throw new Error('Invalid Google Analytics config: ' + JSON.stringify(config));
    }

    this.initProperty(config);
  }

  trackEvent(trackerName, event, params) {
    if (!window.gtag) {
      return;
    }

    window.gtag('event', event, {
      event_category: params.category,
      event_label: params.label,
      send_to: trackerName,
      transport_type: 'beacon',
    });

    if (this.adsEnabled && GAdsEventMap.hasOwnProperty(params.label)) {
      window.gtag('event', 'conversion', {
        send_to: GAdsEventMap[params.label],
      });
    }
  }
}

export default new GoogleAnalytics();

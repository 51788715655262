import React, { useContext } from 'react';

import { Edit, Plus, Trash, UserCog } from 'lucide-react';

import Card from 'common/common/Card';
import EmptyStatePlaceholder from 'common/common/EmptyStatePlaceholder';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H2, Span } from 'common/ui/Text';

import AdminFeatureBlock from '../../AdminFeatureBlock';

import type { Company, Role } from 'common/api/endpoints/companies';

type Props = {
  roles: (Role & { memberCount: number })[];
  onCreateClick(): void;
  onEditClick(role: Role): void;
  onDeleteClick(role: Role): void;
};

const CustomRoles = ({ roles, onCreateClick, onEditClick, onDeleteClick }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const hasRequiredFeature = !!company.features.customRoles;

  if (!hasRequiredFeature) {
    return (
      <section className="roles">
        <header className="row">
          <H2 variant="headingSm">Custom</H2>
        </header>
        <AdminFeatureBlock
          feature="custom roles"
          benefit="Create custom roles with granular permissions."
          showBillingLink={true}
        />
      </section>
    );
  }

  return (
    <section className="roles">
      <header className="row">
        <H2 variant="headingSm">Custom</H2>
        <ButtonV2 color="primary" onClick={onCreateClick} size="medium" startIcon={Plus}>
          Create new role
        </ButtonV2>
      </header>
      {roles.length === 0 ? (
        <EmptyStatePlaceholder
          title="No custom roles yet"
          description="Create a custom role with specific permissions for your organization."
          icon={UserCog}>
          <ButtonV2
            color="primary"
            onClick={onCreateClick}
            size="small"
            startIcon={Plus}
            variant="ghost">
            Create new role
          </ButtonV2>
        </EmptyStatePlaceholder>
      ) : (
        <ul className="roleList">
          {roles.map((role) => (
            <li key={role._id}>
              <Card borderStyle="solid" className="roleWrapperCard">
                <Span>{role.name}</Span>{' '}
                <Span className="roleMemberCount">{`(${role.memberCount} member${
                  role.memberCount === 1 ? '' : 's'
                })`}</Span>
                <IconButtonV2
                  className="editButton"
                  variant="plain"
                  aria-label="edit role"
                  onClick={() => onEditClick(role)}
                  size="small"
                  icon={Edit}
                />
                <div className="verticalDivider" />
                <Tooltip
                  disabled={role.memberCount === 0}
                  position="left"
                  value="Only roles with no members assigned can be deleted">
                  <IconButtonV2
                    disabled={role.memberCount > 0}
                    color="error"
                    variant="plain"
                    aria-label="delete role"
                    onClick={() => onDeleteClick(role)}
                    size="small"
                    icon={Trash}
                  />
                </Tooltip>
              </Card>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default CustomRoles;

import { Component } from 'react';

import PropTypes from 'prop-types';

import decodeQueryTextParam from 'common/util/decodeQueryTextParam';

import { getEntryQueryKey, loadQuery } from '../actions/changelogEntryQueries';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

function getQueryParams(location) {
  const { pathname, query } = location;
  const isAdminView = pathname.startsWith('/admin/');

  return {
    ...(typeof query.labels === 'string' && {
      labels: query.labels,
    }),
    ...(typeof query.status === 'string' && {
      status: query.status,
    }),
    ...(typeof query.type === 'string' && {
      type: query.type,
    }),
    ...(typeof query.search === 'string' && {
      search: decodeQueryTextParam(query.search)?.trim(),
    }),
    ...(!isAdminView && {
      status: 'published',
    }),
  };
}

const asyncFetch = {
  promise: ({ store: { dispatch }, location }) => {
    return dispatch(loadQuery(getQueryParams(location)));
  },
};

class ChangelogEntryListContainer extends Component {
  static asyncConnect = asyncFetch;

  static propTypes = {
    changelogEntryQueries: PropTypes.object,
    changelogEntries: PropTypes.object,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
  };

  render() {
    const { children, changelogEntries, changelogEntryQueries, location } = this.props;
    const entryQueryKey = getEntryQueryKey(getQueryParams(location));
    const entryQueryResult = Object.assign({ loading: true }, changelogEntryQueries[entryQueryKey]);
    if (entryQueryResult.entries) {
      entryQueryResult.entries = entryQueryResult.entries.map((entryKey) => {
        return changelogEntries[entryKey.entryURLName];
      });
    }

    return cloneElementWithProps(children, {
      ...this.props,
      changelogEntryList: entryQueryResult,
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  changelogEntryQueries: state.changelogEntryQueries,
  changelogEntries: state.changelogEntries,
}))(ChangelogEntryListContainer);

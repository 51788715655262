import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { AutomationIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const sections = [
  {
    integrations: AutomationIntegrations,
  },
];

const AdminIntegrationSettingsAutomation = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Automation"
      settingsDescription="Streamline repetitive tasks with automation tools that handle workflows efficiently, allowing your team to focus on strategic work."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.automation),
    '/admin/settings'
  )
)(AdminIntegrationSettingsAutomation) as unknown as React.FC;

import React from 'react';

import classNames from 'classnames';

import LazyLoadedImage from 'common/LazyLoadedImage';
import { type Integration } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import SwitchV2 from 'common/ui/SwitchV2';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationCard.module.scss';

type Props = {
  integration: Integration;
  enabled: boolean;
  openIntegrationModal: () => void;
  loading: boolean;
};

const AdminIntegrationToggleCard = ({
  integration,
  enabled,
  openIntegrationModal,
  loading,
}: Props) => {
  const toggleHint = `${integration.label} is currently ${
    enabled ? 'enabled' : 'disabled'
  } as an Autopilot data source. Open a modal to adjust settings.`;

  return (
    <>
      <button
        disabled={loading}
        className={classNames(styles.toggle, styles.card)}
        onClick={openIntegrationModal}
        aria-label={toggleHint}>
        <div className={styles.label}>
          <LazyLoadedImage className={styles.icon} src={integration.icon} alt="" />
          <div className={styles.labelPillContainer}>
            <P className={styles.labelCopy}>{integration.label}</P>
          </div>
        </div>
        <SwitchV2 tabIndex={-1} checked={enabled} size="small" className={styles.switch} />
      </button>
    </>
  );
};

export default AdminIntegrationToggleCard;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadMore } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import KeyCodes from 'common/KeyCodes';
import AccessModal from 'common/modals/AccessModal';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import { P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import isElementOnScreen from 'common/util/isElementOnScreen';
import numberWithCommas from 'common/util/numberWithCommas';
import withContexts from 'common/util/withContexts';

import AdminUserCount from './AdminUserCount';
import AdminUsersCompanyListItem from './AdminUsersCompanyListItem';
import AdminUsersListItem from './AdminUsersListItem';
import AdminUsersSearch from './AdminUsersSearch';

import 'css/components/subdomain/admin/_AdminUsersAndCompaniesList.scss';

const Modals = {
  deleteUser: 'deleteUser',
};

class AdminUsersAndCompaniesList extends Component {
  static propTypes = {
    company: PropTypes.shape({
      contributorCount: PropTypes.number,
      userCount: PropTypes.number,
    }),
    companyList: PropTypes.object,
    loadMore: PropTypes.func,
    location: PropTypes.object,
    openModal: PropTypes.func,
    params: PropTypes.object,
    router: PropTypes.object,
    userList: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    deletedUsers: [],
    deleting: false,
    errorDeletingUsers: null,
    selected: {},
    modal: null,
  };

  constructor(props, context) {
    super(props, context);

    this.loadMoreRef = React.createRef();
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.scrollRef.current.addEventListener('scroll', this.onScroll, false);
    document.addEventListener('keydown', this.onKeyDown, false);
    this.onScroll();
  }

  componentWillUnmount() {
    this.scrollRef.current.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (document.activeElement !== document.body) {
      return;
    }

    if (e.keyCode === KeyCodes.UpArrow) {
      e.preventDefault();
      this.selectPreviousUser();
    } else if (e.keyCode === KeyCodes.DownArrow) {
      e.preventDefault();
      this.selectNextUser();
    }
  };

  onLoadMore = () => {
    const { loadMore, userList } = this.props;
    if (userList.loadingMore) {
      return;
    }
    loadMore(userList);
  };

  onScroll = () => {
    const { userList } = this.props;
    const loadMore = this.loadMoreRef.current;
    if (userList.loadingMore || !loadMore) {
      return;
    }

    if (!isElementOnScreen(loadMore, this.scrollRef.current)) {
      return;
    }

    this.onLoadMore();
  };

  onCheckboxChange = (user, checked) => {
    const { selected } = this.state;
    const newSelected = Object.assign({}, selected);
    if (checked) {
      newSelected[user._id] = true;
    } else {
      delete newSelected[user._id];
    }
    this.setState({
      selected: newSelected,
    });
  };

  deleteUser = (selectedUserIDs, numberSelected, users) => {
    const { deletedUsers, deleting } = this.state;
    if (deleting) {
      return;
    }

    this.setState({ deleting: true });

    AJAX.post(
      '/api/users/delete',
      {
        userIDs: selectedUserIDs,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            errorDeletingUsers: `Failed to delete ${users}`,
            deleting: false,
          });
          return;
        }

        this.setState({
          deletedUsers: [...deletedUsers, ...selectedUserIDs],
          deleting: false,
          selected: {},
          modal: null,
        });
      }
    );
  };

  renderDeleteModal = () => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('deleteUsers', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['deleteUsers'],
      });
      return;
    }

    const { deleting, selected } = this.state;
    if (deleting) {
      return;
    }

    const selectedUserIDs = Object.keys(selected);
    const numberSelected = selectedUserIDs.length;
    const users = numberSelected === 1 ? 'user' : 'users';
    const confirmationMsg = `Delete ${numberSelected} ${users}`;

    return (
      <ModernConfirmModal
        onClose={() => this.setState({ modal: null })}
        header={`Are you sure you want to delete the selected ${users}?`}
        onConfirm={() => this.deleteUser(selectedUserIDs, numberSelected, users)}
        type="destructive"
        confirmText={confirmationMsg}>
        <>
          <P>Deleting the users will permanently remove:</P>
          <ul>
            <li>All of their votes and comments </li>
            <li>All of their posts without votes or comments</li>
          </ul>
          <P>Posts with votes or comments will remain, but the users will no longer be credited.</P>
          <P>There might be a delay before the data is removed. This action cannot be undone.</P>
        </>
      </ModernConfirmModal>
    );
  };

  selectNextUser() {
    const {
      location: { query },
      params,
      userList,
      router,
    } = this.props;
    const isUserSelected = params.userURLName;
    if (!isUserSelected) {
      return;
    }

    const selectedUserIndex = userList?.users?.findIndex((user) => {
      return user.urlName === params.userURLName;
    });
    if (selectedUserIndex === -1) {
      return;
    }

    if (userList.users.length <= selectedUserIndex + 1) {
      return;
    }

    if (selectedUserIndex + 1 === userList.users.length - 1) {
      this.onLoadMore();
    }

    const nextUser = userList.users[selectedUserIndex + 1];
    router.replace({
      pathname: `/admin/users/${nextUser.urlName}`,
      query,
    });
  }

  selectPreviousUser() {
    const {
      location: { query },
      params,
      userList,
      router,
    } = this.props;
    const isUserSelected = params.userURLName;
    if (!isUserSelected) {
      return;
    }

    const selectedUserIndex = userList?.users?.findIndex((user) => {
      return user.urlName === params.userURLName;
    });
    if (!selectedUserIndex || selectedUserIndex === -1) {
      return;
    }

    const previousUser = userList.users[selectedUserIndex - 1];
    router.replace({
      pathname: `/admin/users/${previousUser.urlName}`,
      query,
    });
  }

  renderCompanyList() {
    const { companyList } = this.props;
    const companies = companyList.companies || [];

    if (!companies.length) {
      return null;
    }

    return (
      <>
        <div className="sectionHeader">Companies</div>
        <div>
          {companies.map((company) => (
            <AdminUsersCompanyListItem key={company._id} thirdPartyCompany={company} />
          ))}
        </div>
      </>
    );
  }

  renderUserCount() {
    const { userList } = this.props;
    const { selected } = this.state;

    // render selected users if any
    const numberSelected = Object.keys(selected).length;
    if (numberSelected > 0) {
      const users = numberSelected === 1 ? 'User' : 'Users';
      const formattedCount = numberWithCommas(numberSelected);
      return (
        <div className="userCountContainer">
          <div>
            <span className="userCount">
              {formattedCount} {users} selected
            </span>
          </div>
          {this.renderDeleteUserAction()}
        </div>
      );
    }

    return (
      <div className="userCountContainer">
        <AdminUserCount userList={userList} />
      </div>
    );
  }

  renderDeleteUserAction() {
    const { deleting, errorDeletingUsers, selected } = this.state;
    if (errorDeletingUsers) {
      return <div className="deleteUserError">{errorDeletingUsers}</div>;
    }

    if (deleting) {
      return (
        <div className="deleteUserAction">
          <Spinner />
        </div>
      );
    }
    const selectedUserIDs = Object.keys(selected).length;
    if (selectedUserIDs < 1) {
      return null;
    }

    const users = selectedUserIDs === 1 ? 'User' : 'Users';

    return (
      <Tappable onTap={() => this.setState({ modal: Modals.deleteUser })}>
        <div className="deleteUser">Delete {users}</div>
      </Tappable>
    );
  }

  renderUsersList() {
    const { deletedUsers, deleting, selected } = this.state;
    const { companyList, userList } = this.props;
    const users = userList.users
      ? userList.users.filter((user) => user && !deletedUsers.includes(user._id))
      : [];
    const companies = companyList.companies || [];
    const sectionHeader = companies.length > 0 ? <div className="sectionHeader">Users</div> : null;

    if (!users.length) {
      return (
        <>
          {sectionHeader}
          <div className="noResults">No users found for search query</div>
        </>
      );
    }

    return (
      <>
        {sectionHeader}
        <div>
          {users.map((user) => (
            <AdminUsersListItem
              key={user._id}
              deleting={deleting}
              onCheckboxChange={this.onCheckboxChange}
              user={user}
              scrollContainerRef={this.scrollRef}
              selected={!!selected[user._id]}
            />
          ))}
        </div>
      </>
    );
  }

  renderPagination() {
    const { userList } = this.props;
    if (!userList || !userList.users || !userList.hasNextPage) {
      return null;
    }

    if (userList.loadingMore) {
      return (
        <div className="pagination">
          <Spinner />
        </div>
      );
    }

    return (
      <Tappable onTap={this.onLoadMore}>
        <div className="pagination" ref={this.loadMoreRef}>
          <div className="paginationLoadMore">Load More</div>
        </div>
      </Tappable>
    );
  }

  render() {
    return (
      <div className="adminUsersAndCompaniesList" ref={this.scrollRef}>
        {this.renderUserCount()}
        <AdminUsersSearch />
        {this.renderCompanyList()}
        {this.renderUsersList()}
        {this.renderPagination()}
        {this.state.modal === Modals.deleteUser && this.renderDeleteModal()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadMore: (userList) => dispatch(loadMore(userList)),
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      params: ParamsContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminUsersAndCompaniesList);

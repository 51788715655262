import mapify from './mapify';

import type { Company, Member, RolePermissionName } from 'common/api/endpoints/companies';

const getMembersWithPermission = (
  permissionKey: RolePermissionName,
  company: Company
): Member[] => {
  const { members, roles } = company;

  const roleMap = mapify(roles, '_id');
  return members.filter((member) => {
    const role = roleMap[member.roleID];
    return role.permissions[permissionKey];
  });
};

export default getMembersWithPermission;

import React from 'react';

import classnames from 'classnames';

import { H2 } from 'common/ui/Text';

import 'css/components/common/_Popover.scss';

type Props = {
  className?: string;
  cta?: React.ReactNode;
  onClose?: () => void;
  title?: string;
  children: React.ReactNode;
};

const Popover = ({ className, cta, title, children }: Props) => {
  return (
    <section className={classnames('popover', className)}>
      {title ? (
        <header className="header">
          <H2 variant="headingSm">{title}</H2>
          {cta}
        </header>
      ) : null}
      {children}
    </section>
  );
};

export default Popover;

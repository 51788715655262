import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CheckboxInput from 'common/inputs/CheckboxInput';
import CheckboxList from 'common/inputs/CheckboxList';
import Spinner from 'common/Spinner';

import 'css/components/subdomain/admin/AdminHubspotSettings/_AdminHubspotSyncRecord.scss';

export default class AdminHubspotSyncRecord extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    objectType: PropTypes.oneOf(['company', 'contact']).isRequired,
    onChange: PropTypes.func.isRequired,
    selectedFields: PropTypes.arrayOf(PropTypes.string),
    sync: PropTypes.bool,
  };

  static defaultProps = {
    selectedFields: [],
    sync: false,
  };

  onCheckboxChange = (newFields) => {
    const { sync, onChange } = this.props;
    onChange({
      sync,
      syncFields: newFields,
    });
  };

  onToggleSync = (sync) => {
    const { onChange, selectedFields } = this.props;
    onChange({
      sync,
      syncFields: selectedFields,
    });
  };

  renderFields() {
    const { fetching, fields, sync, selectedFields } = this.props;
    if (!sync) {
      return;
    }

    if (fetching) {
      return (
        <div className="fetching">
          <Spinner />
        </div>
      );
    }

    return (
      <CheckboxList
        className="fieldList"
        fields={fields}
        onChange={this.onCheckboxChange}
        selectedFields={selectedFields}
      />
    );
  }

  render() {
    const { objectType, sync } = this.props;
    const plural = {
      company: 'companies',
      contact: 'contacts',
    };

    return (
      <div className="adminHubspotSyncRecord">
        <CheckboxInput
          defaultChecked={sync}
          label={`Sync ${plural[objectType]}`}
          onChange={(sync) => this.onToggleSync(sync)}
        />
        {this.renderFields()}
      </div>
    );
  }
}

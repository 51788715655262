import React, { Component } from 'react';

import PropTypes from 'prop-types';

import 'css/components/_ContentContainer.scss';

export default class ContentContainer extends Component {
  static propTypes = {
    innerClassName: PropTypes.string,
    outerClassName: PropTypes.string,
  };

  renderInnerContainer() {
    if (this.props.innerClassName) {
      return <div className={this.props.innerClassName}>{this.props.children}</div>;
    } else {
      return this.props.children;
    }
  }

  renderContainers() {
    return (
      <div className="contentContainer">
        <div className="contentInnerContainer">{this.renderInnerContainer()}</div>
      </div>
    );
  }

  render() {
    if (this.props.outerClassName) {
      return <div className={this.props.outerClassName}>{this.renderContainers()}</div>;
    } else {
      return this.renderContainers();
    }
  }
}

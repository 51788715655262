import {
  DashboardActivityError,
  DashboardActivityLoaded,
  Invalidate,
  RequestDashboardActivity,
} from 'common/actions/dashboardActivity';

export default function dashboardActivity(state = null, action) {
  switch (action.type) {
    case RequestDashboardActivity: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case DashboardActivityLoaded: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        newPosts: action.dashboardActivity.newPosts,
        stalePosts: action.dashboardActivity.stalePosts,
        stats: {
          ...state.stats,
          [action.filtersCacheKey]: {
            ...action.dashboardActivity.stats,
          },
        },
      });
    }

    case DashboardActivityError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case Invalidate: {
      return null;
    }

    default:
      return state;
  }
}

import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { ArrowUpCircle, LayoutGrid, Lightbulb, MessageSquare, Tag } from 'lucide-react';

import DoughnutChart from 'common/charts/DoughnutChart';
import DateRangePicker from 'common/common/DateRangePicker';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import UpsellModal from 'common/modals/UpsellModal';
import Spinner from 'common/Spinner';
import EmptyState from 'common/subdomain/admin/reports/common/EmptyState';
import { RelativeDateRanges } from 'common/util/dateRanges';
import { isNotNil } from 'common/util/isNil';
import nbspLastSpace from 'common/util/nbspLastSpace';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';

export enum Activity {
  comments = 'comments',
  posts = 'posts',
  votes = 'votes',
}

export enum Group {
  boards = 'boards',
  tags = 'tags',
  categories = 'categories',
}

import 'css/components/subdomain/admin/AdminAnalytics/_EngagementBreakdown.scss';

type Props = {
  boards: Board[];
  data: Record<string, number>;
  loading: boolean;
  error: string | null;
  filters: {
    activity: Activity;
    dateRange: null | [string, string];
    group: Group;
    segmentID: null | string;
    boardID: null | string;
  };
  onSelectArea: (key: string, event: PointerEvent) => void;
  updateFilters: (update: Partial<Props['filters']>) => void;
};

const GroupOptions = [
  {
    name: 'categories',
    render: (
      <div className="activityOption">
        <LayoutGrid className="icon" size={16} />
        <div className="label">Categories</div>
      </div>
    ),
  },
  {
    name: 'tags',
    render: (
      <div className="activityOption">
        <Tag className="icon" size={16} /> Tags
      </div>
    ),
  },
];

const ActivityOptions = [
  {
    name: 'votes',
    render: (
      <div className="activityOption">
        <ArrowUpCircle className="icon" size={16} /> Votes Overview
      </div>
    ),
  },
  {
    name: 'posts',
    render: (
      <div className="activityOption">
        <Lightbulb className="icon" size={16} /> Posts Overview
      </div>
    ),
  },
  {
    name: 'comments',
    render: (
      <div className="activityOption">
        <MessageSquare className="icon" size={16} /> Comments Overview
      </div>
    ),
  },
];

const EngagementBreakdown = ({
  loading,
  error,
  boards,
  data,
  filters,
  onSelectArea,
  updateFilters,
}: Props) => {
  const company = useContext<Company>(CompanyContext);

  const [showUpsellModal, setUpsellModalVisibility] = useState<boolean>(false);

  const segmentOptions = [
    {
      name: 'all',
      render: 'Everyone',
    },
  ].concat(
    company.segments.map((segment) => ({
      name: segment._id,
      render: nbspLastSpace(segment.name),
    }))
  );

  const boardOptions = [
    {
      name: 'all',
      render: 'All boards',
    },
  ].concat(
    boards.map((board) => ({
      name: board._id,
      render: nbspLastSpace(board.name),
    }))
  );

  // generate chart if data is available, or show an according message.
  let chart = null;
  const totalSum = Object.values(data).reduce((sum, value) => sum + value, 0);
  if (loading) {
    chart = <Spinner />;
  } else if (error) {
    chart = (
      <div className="error" role="alert">
        {error}
      </div>
    );
  } else if (totalSum === 0) {
    chart = <EmptyState message="Results will be displayed when there is more&nbsp;activity." />;
  } else {
    chart = <DoughnutChart title={filters.activity} data={data} onSelectArea={onSelectArea} />;
  }

  let limitedOptions: string[] = [];
  const analyticsHistory = company.limits?.analyticsHistory;
  if (analyticsHistory && analyticsHistory <= 30) {
    limitedOptions = [
      RelativeDateRanges.allTime,
      RelativeDateRanges.lastHalf,
      RelativeDateRanges.lastQuarter,
      RelativeDateRanges.thisHalf,
      RelativeDateRanges.thisQuarter,
    ];
  }

  const onDateRangeSelected = ({
    dateRange,
  }: {
    relativeDate: string | null;
    dateRange: [string | null, string | null];
  }) => {
    const [start, end] = dateRange ?? [];
    const isDateRangeValid = isNotNil(start) && isNotNil(end);
    if (!isDateRangeValid) {
      updateFilters({ dateRange: null });
      return;
    }

    updateFilters({ dateRange: [start, end] });
  };

  return (
    <div className="engagementBreakdown">
      <div className="firstRow">
        <div className="header">
          <ControlledDropdown
            dropdownClassName="activityDropdownMenu"
            className="activityDropdown"
            selectedName={filters.activity}
            options={ActivityOptions}
            onChange={(activity: Props['filters']['activity']) => updateFilters({ activity })}
          />
          <div className="subtitle">
            View distribution of all {filters.activity} across your&nbsp;boards.
          </div>
        </div>
        <div className="filters">
          <ControlledDropdown
            className="filterDropdown"
            selectedName={filters.boardID ?? 'all'}
            options={boardOptions}
            disabled={!boards.length}
            onChange={(boardID: Props['filters']['boardID']) =>
              updateFilters({ boardID: boardID === 'all' ? null : boardID })
            }
          />
          <ControlledDropdown
            dropdownClassName="segmentDropdownMenu"
            className={classnames('filterDropdown', 'segmentDropdown')}
            selectedName={filters.segmentID ?? 'all'}
            options={segmentOptions}
            disabled={segmentOptions.length < 2}
            onChange={(segmentID: Props['filters']['segmentID']) =>
              updateFilters({ segmentID: segmentID === 'all' ? null : segmentID })
            }
          />
          <ControlledDropdown
            dropdownClassName="categoryDropdownMenu"
            className={classnames('filterDropdown', 'categoryDropdown')}
            selectedName={filters.group !== 'boards' ? filters.group : 'categories'}
            disabled={filters.group === 'boards'}
            options={GroupOptions}
            onChange={(group: Props['filters']['group']) => updateFilters({ group })}
          />
          <div className="separator" />
          <DateRangePicker
            align="start"
            className="dateRangeFilter"
            date={RelativeDateRanges.thisWeek}
            historyDayLimit={analyticsHistory}
            limitedOptions={limitedOptions}
            onLimitExceeded={() => setUpsellModalVisibility(true)}
            onSubmit={onDateRangeSelected}
          />
        </div>
      </div>
      <div className="engagementBreakdownChart">{chart}</div>
      <UpsellModal
        cta="More than 30 days of analytics&nbsp;history"
        feature="limits.analyticsHistory"
        onClose={() => setUpsellModalVisibility(false)}
        onUpsell={() => setUpsellModalVisibility(false)}
        show={showUpsellModal}
      />
    </div>
  );
};

export default EngagementBreakdown;

import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { FeedbackBoardsIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const sections = [
  {
    integrations: FeedbackBoardsIntegrations,
  },
];

const AdminIntegrationSettingsFeedbackBoards = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Feedback Boards"
      settingsDescription="Collect and centralize customer feedback. Gain a clear view of requests, prioritize ideas, and understand user needs across channels."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.feedback),
    '/admin/settings'
  )
)(AdminIntegrationSettingsFeedbackBoards) as unknown as React.FC;

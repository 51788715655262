import getFirstName from 'common/util/getFirstName';
import mapify from 'common/util/mapify';

import type { StrippedUserWithAlias } from 'common/api/endpoints/users';

const BaseMentionRegex = '@\\{[a-z0-9]{24}\\|(alias|first_name|full_name)\\}';
export const MentionRegex = new RegExp(BaseMentionRegex, 'gi');
const RenderModeRegex = /^(alias|first_name|full_name)$/;

export enum RenderValues {
  alias = 'alias',
  first_name = 'first_name',
  full_name = 'full_name',
}
export enum RenderTypes {
  aliasID = 'aliasID',
  userID = 'userID',
}

const isRenderValue = (value: string): value is RenderValues => {
  return Object.values(RenderValues).includes(value as RenderValues);
};

/**
 *
 * If mentions are anonymized and the user is not an admin, we want to show the alias instead of the full name.
 */
export const getMentionDisplayValue = (
  user: { alias: string; name: string } | null,
  renderMode: RenderValues
) => {
  const renderer = {
    [RenderValues.alias]: () => user?.alias ?? null,
    [RenderValues.first_name]: () => (user?.name ? getFirstName(user.name) : null),
    [RenderValues.full_name]: () => user?.name ?? null,
  }[renderMode];

  if (!renderer || !user) {
    return null;
  }

  return renderer();
};

export const getIDFromMentionTag = (mentionTag: string) => {
  if (!mentionTag.match(MentionRegex)) {
    return null;
  }

  const match = mentionTag.match(/[0-9a-z]{24}/);
  const entityID = match ? match[0] : null;

  const mode = getModeFromMentionTag(mentionTag);

  if (!mode || !entityID) {
    return null;
  }

  return {
    entityID,
    type: mode === RenderValues.alias ? RenderTypes.aliasID : RenderTypes.userID,
  };
};

/**
 * Replaces mentions with the appropriate display value.
 */
export const replaceMentionTagsWithDisplayValues = (
  string: string,
  users: Array<{
    _id: string | null;
    aliasID: string | null;
    name: string;
    alias: string;
  }>
) => {
  const userIDMap = mapify(users, '_id');
  const userAliasMap = mapify(users, 'aliasID');

  const mentions = getMentions(string);

  return mentions.reduce((string, mentionTag) => {
    const mentionData = getIDFromMentionTag(mentionTag);
    const renderMode = getModeFromMentionTag(mentionTag);
    if (!mentionData || !renderMode) {
      return string;
    }

    const { entityID, type } = mentionData;
    const userMap = type === RenderTypes.aliasID ? userAliasMap : userIDMap;
    const mentionedUser = userMap[entityID];
    const displayValue = getMentionDisplayValue(mentionedUser, renderMode);
    if (!displayValue) {
      return string;
    }

    return string.replaceAll(mentionTag, displayValue);
  }, string);
};

export const getModeFromMentionTag = (mentionTag: string): RenderValues | null => {
  if (!mentionTag.match(MentionRegex)) {
    return null;
  }

  const [, secondPart] = mentionTag.split('|');
  const match = secondPart.replace('}', '').match(RenderModeRegex);
  const mode = match ? match[0] : null;

  if (!mode || !isRenderValue(mode)) {
    return null;
  }

  return mode;
};

export const getMentionTag = (
  user: StrippedUserWithAlias,
  renderMode: RenderValues = RenderValues.full_name
) => {
  const entityID = renderMode === RenderValues.alias ? user.aliasID : user._id;
  return `@{${entityID}|${renderMode}}`;
};

// Input: "@{1abdb132b4|full_name} @{2abdb132b4|first_name}"
// Output: ['@{1abdb132b4|full_name}', '@{2abdb132b4|first_name}']
export function getMentions(string: string) {
  const matches = string.match(MentionRegex);
  if (!matches) {
    return [];
  }

  const mentionSet: Record<string, boolean> = {};
  const mentions: string[] = [];

  matches.forEach((match) => {
    const mention = match.slice(match.indexOf('@'), match.indexOf('}') + 1).toLowerCase();
    if (mentionSet[mention]) {
      return;
    }

    mentionSet[mention] = true;
    mentions.push(mention);
  });

  return mentions;
}

export function removeMentions(string: string) {
  return string
    .replace(new RegExp(`[\\t ]*${BaseMentionRegex}\\.`, 'g'), '.')
    .replace(new RegExp(`[\\t ]*${BaseMentionRegex}\\!`, 'g'), '!')
    .replace(new RegExp(`[\\t ]*${BaseMentionRegex}\\?`, 'g'), '?')
    .replace(new RegExp(`([\\t ]*${BaseMentionRegex}[:,\\.\\t ]*)+`, 'g'), ' ')
    .trim();
}

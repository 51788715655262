import React, { useContext, useEffect, useState } from 'react';

import classnames from 'classnames';
import { Filter, Plus, Sigma } from 'lucide-react';

import { reloadRoadmaps } from 'common/actions/roadmaps';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import SearchInput from 'common/inputs/SearchInput';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import ModalPortal from 'common/modals/ModalPortal';
import UpsellModal from 'common/modals/UpsellModal';
import AdminRoadmapEditFormulaModal from 'common/subdomain/admin/AdminRoadmapEditFormulaModal';
import ButtonV2 from 'common/ui/ButtonV2';
import { Span } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AdminRoadmapGroupBy from './AdminRoadmapGroupBy';
import AdminRoadmapHeaderName from './AdminRoadmapHeaderName';
import AdminRoadmapHeaderOptionsMenu from './AdminRoadmapHeaderOptionsMenu';
import DuplicateRoadmapModal, { type FormValues } from './DuplicateRoadmapModal';

import 'css/components/subdomain/admin/AdminRoadmap/AdminRoadmapHeader/_AdminRoadmapHeader.scss';

const DefaultName = 'New Roadmap';

import type { Board } from 'common/api/endpoints/boards';
import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { CustomPostField } from 'common/api/resources/postFields';
import type { Option } from 'common/ui/common/select/SelectCommon';
import type { Dispatch } from 'redux-connect';

type OwnProps = {
  boards: Board[];
  customPostFields: CustomPostField[];
  groupBy?: Option;
  loading: boolean;
  onCreatePost: () => void;
  onToggleViewSettings: () => void;
  modalOpen: string | null;
  onGroupBy: (option: Option | undefined) => void;
  onSearch: (searchValue: string) => void;
  roadmap: Roadmap | null;
  roadmaps: Roadmap[];
  searchValue: string;
  updateServerSettings: (
    columns: string[],
    updatedGroupBy: string | undefined,
    selectedView: string | null,
    columnWidths: Record<string, number>
  ) => void;
};

type ConnectProps = {
  reloadRoadmaps: () => void;
};

type Props = OwnProps & ConnectProps;

const calculateActiveFilters = (query: Record<string, string>) => {
  let count = Object.keys(query).length;
  if (query.description) {
    const customQueries = JSON.parse(decodeURIComponent(query.description));
    count += Object.keys(customQueries).length - 1;
  }

  if (query.factor) {
    const customQueries = JSON.parse(decodeURIComponent(query.factor));
    count += Object.keys(customQueries).length - 1;
  }

  return count;
};

const AdminRoadmapHeader = ({
  boards,
  customPostFields,
  groupBy,
  loading,
  modalOpen,
  onCreatePost,
  onGroupBy,
  onSearch,
  onToggleViewSettings,
  reloadRoadmaps,
  roadmap,
  roadmaps,
  updateServerSettings,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext(ViewerContext);
  const openModal = useContext(OpenModalContext);
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const showToast = useContext(ShowToastContext);

  const [editing, setEditing] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [roadmapName, setRoadmapName] = useState(roadmap?.name ?? DefaultName);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formulaModalOpen, setFormulaModalOpen] = useState(false);
  const [numberOfActiveFilters, setNumberOfActiveFilters] = useState(
    calculateActiveFilters(location.query)
  );

  // If the roadmap changes, (eg, changing pages or content refresh), reset these properties
  useEffect(() => {
    if (roadmap) {
      setEditing(isNew);
      setIsNew(false);
      setRoadmapName(roadmap.name);
    }
  }, [roadmap, isNew]);

  useEffect(() => {
    setNumberOfActiveFilters(calculateActiveFilters(location.query));
  }, [location.query]);

  const onArchiveRoadmap = async () => {
    setIsLoading(true);
    setShowOptionsMenu(false);
    if (!roadmap) {
      return;
    }

    const response = await AJAX.post('/api/roadmaps/update', {
      archived: true,
      columns: roadmap.columns,
      name: roadmap.name,
      roadmapID: roadmap._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': 'You are not authorized to archive a roadmap',
        'cannot archive last roadmap': 'Last roadmap can not be archived',
        default: 'Failed to archive roadmap',
      },
    });

    if (error) {
      setIsLoading(false);
      if (error.type === 'roadmap limit' || error.type === 'plan does not support') {
        setShowUpsellModal(true);
        return;
      }
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadRoadmaps();
    setIsLoading(false);
    router.replace('/admin/roadmap');
  };

  const onCreateRoadmap = async () => {
    const permission: RolePermissionName = 'manageRoadmap';
    if (!hasPermission(permission, company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: [permission],
      });
      return;
    }

    const response = await AJAX.post('/api/roadmaps/create', {});
    const { error, parsedResponse } = parseAPIResponse<{ urlName: string }>(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse.urlName,
      errors: {
        'not authorized': 'You are not authorized to create a roadmap',
        'name taken': 'This name has been taken. Please try another name',
        default: 'Failed to create roadmap',
      },
    });

    if (error) {
      if (error.type === 'roadmap limit' || error.type === 'plan does not support') {
        setShowUpsellModal(true);
        return;
      }
      showToast(error.message, ToastTypes.error);
      return;
    }

    if (!parsedResponse) {
      return;
    }

    const { urlName } = parsedResponse;
    await reloadRoadmaps();
    router.replace(`/admin/roadmap/${urlName}`);
    setIsNew(true);
  };

  const onDeleteRoadmap = async () => {
    setShowOptionsMenu(false);

    openModal(ConfirmModal, {
      message: (
        <div>Are you sure you want to delete {roadmap?.name}? This cannot be&nbsp;undone.</div>
      ),
      onConfirm: async () => {
        const deletedRoadmap = roadmap;
        setIsLoading(true);
        setShowOptionsMenu(false);
        const response = await AJAX.post('/api/roadmaps/delete', {
          roadmapID: deletedRoadmap?._id,
        });
        const { error } = parseAPIResponse(response, {
          isSuccessful: isDefaultSuccessResponse,
          errors: {
            'not authorized': 'You are not authorized to delete a roadmap',
            'cannot delete last roadmap': 'Last roadmap can not be deleted',
            default: 'Failed to delete roadmap',
          },
        });
        if (error) {
          showToast(error.message, ToastTypes.error);
          setIsLoading(false);
          return;
        }

        await reloadRoadmaps();
        setIsLoading(false);
        router.replace(`/admin/roadmap`);
      },
    });
  };

  const onDuplicateRoadmap = async () => {
    setShowOptionsMenu(false);
    setShowDuplicateModal(true);
  };

  const onSubmitDuplicateRoadmap = async ({ title, scoring, posts }: FormValues) => {
    const permission: RolePermissionName = 'manageRoadmap';
    if (!hasPermission(permission, company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: [permission],
      });
      return;
    }

    const response = await AJAX.post('/api/roadmaps/create', {
      name: title,
      copy: {
        roadmapID: roadmap?._id,
        posts,
        scoring,
      },
    });

    const { error, parsedResponse } = parseAPIResponse<{ urlName: string }>(response, {
      isSuccessful: (response) => !!response.urlName,
      errors: {
        'not authorized': 'You are not authorized to create a roadmap',
        'name taken': 'That name is already in use',
        'roadmap name': 'Roadmap name must be between 0 and 40 characters',
        default: 'Roadmap could not be created, please refresh the page and try again',
      },
    });

    if (error) {
      if (error.type === 'roadmap limit' || error.type === 'plan does not support') {
        setShowUpsellModal(true);
        return;
      }
      showToast(error?.message, ToastTypes.error);
      return;
    }

    if (!parsedResponse) {
      return;
    }

    const { urlName } = parsedResponse;
    await reloadRoadmaps();
    router.replace(`/admin/roadmap/${urlName}`);
    setIsNew(true);
    setShowDuplicateModal(false);
  };

  const onDropdownBlur = () => {
    if (!showOptionsMenu) {
      return;
    }
    setShowOptionsMenu(false);
  };

  const onRenameRoadmap = () => {
    setEditing(true);
    roadmap && setRoadmapName(roadmap.name);
    setShowOptionsMenu(false);
  };

  const onSaveRoadmapName = async () => {
    setEditing(false);
    if (!roadmap || (roadmap && roadmapName === roadmap.name)) {
      return;
    }

    setIsLoading(true);

    const response = await AJAX.post('/api/roadmaps/update', {
      archived: roadmap.archived,
      columns: roadmap.columns,
      name: roadmapName,
      roadmapID: roadmap._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'roadmap name': 'Roadmap name must be between 0 and 40 characters',
        'not authorized': 'You are not authorized to rename a roadmap',
        default: 'Failed to save roadmap name',
      },
    });

    if (error) {
      setIsLoading(false);
      if (error.type === 'roadmap limit' || error.type === 'plan does not support') {
        setShowUpsellModal(true);
        return;
      }
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadRoadmaps();
    setIsLoading(false);
    router.replace(`/admin/roadmap/${roadmap.urlName}`);
  };

  const onToggleOptionsMenu = () => {
    setShowOptionsMenu(!showOptionsMenu);
  };

  const onUpsell = () => {
    setShowUpsellModal(false);
    onCreateRoadmap();
  };

  const updateGroupBy = async (selectedGroupBy: Option | undefined) => {
    onGroupBy(selectedGroupBy);
    if (roadmap) {
      await updateServerSettings(
        roadmap.settings.hiddenColumnIDs,
        selectedGroupBy?.value,
        roadmap.settings.lastActiveFilters?.viewID ?? null,
        roadmap.settings.columnWidths ?? {}
      );
    }
  };

  return (
    <div className="adminRoadmapHeader">
      <div className="nameContainer">
        <AdminRoadmapHeaderName
          editing={editing}
          onCreateRoadmap={onCreateRoadmap}
          onNameUpdate={(name: string) => setRoadmapName(name)}
          onSaveRoadmapName={onSaveRoadmapName}
          roadmap={roadmap}
          roadmapName={roadmapName}
          roadmaps={roadmaps}
        />
        {!editing && hasPermission('manageRoadmap', company, viewer) && (
          <AdminRoadmapHeaderOptionsMenu
            onArchiveRoadmap={onArchiveRoadmap}
            onDeleteRoadmap={onDeleteRoadmap}
            onDropdownBlur={onDropdownBlur}
            onDuplicateRoadmap={onDuplicateRoadmap}
            onToggleOptionsMenu={onToggleOptionsMenu}
            onRenameRoadmap={onRenameRoadmap}
            roadmaps={roadmaps}
            showOptionsMenu={showOptionsMenu}
            loading={isLoading || loading}
          />
        )}
        <div className="verticalDivider" />
        <SearchInput
          autoFocus={false}
          onChange={(searchValue: string) => onSearch(searchValue.trim())}
          placeholder="Search..."
        />
      </div>
      <div className="buttons">
        {hasPermission('manageRoadmap', company, viewer) && (
          <ButtonV2
            className={classnames({ active: formulaModalOpen })}
            onClick={() => setFormulaModalOpen(true)}
            size="medium"
            startIcon={Sigma}
            variant="outlined">
            Score
          </ButtonV2>
        )}
        <ButtonV2
          className={classnames('ignoreModalClickAway', {
            active: modalOpen === 'view',
          })}
          onClick={onToggleViewSettings}
          size="medium"
          startIcon={Filter}
          variant="outlined">
          Filters&nbsp;
          {numberOfActiveFilters > 0 && (
            <Span variant="bodyMd" className="filterCount">
              ({numberOfActiveFilters})
            </Span>
          )}
        </ButtonV2>
        <AdminRoadmapGroupBy
          groupBy={groupBy}
          customPostFields={customPostFields}
          onGroupByChange={updateGroupBy}
        />
        <div className="verticalDivider" />
        {boards?.length && hasPermission('manageRoadmap', company, viewer) ? (
          <ButtonV2 onClick={onCreatePost} size="medium" startIcon={Plus}>
            Create new post
          </ButtonV2>
        ) : null}
      </div>
      <UpsellModal
        cta="Unlimited roadmaps enable more advanced&nbsp;planning"
        feature="limits.prioritizationRoadmaps"
        onClose={() => setShowUpsellModal(false)}
        onUpsell={onUpsell}
        show={showUpsellModal}
      />
      {formulaModalOpen && (
        <ModalPortal
          onClose={() => setFormulaModalOpen(false)}
          className="adminRoadmapTableEditFormulaModalPortal">
          <AdminRoadmapEditFormulaModal
            onClose={() => setFormulaModalOpen(false)}
            roadmap={roadmap}
          />
        </ModalPortal>
      )}
      {showDuplicateModal ? (
        <DuplicateRoadmapModal
          originalRoadmapTitle={roadmap?.name}
          allRoadmapTitles={roadmaps.map((roadmap) => roadmap.name)}
          onClose={() => setShowDuplicateModal(false)}
          onSubmit={onSubmitDuplicateRoadmap}
        />
      ) : null}
    </div>
  );
};

// TODO: remove cast after `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  reloadRoadmaps: () => dispatch(reloadRoadmaps()),
}))(AdminRoadmapHeader) as unknown as React.FC<OwnProps>;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import Button from 'common/inputs/Button';
import Link from 'common/Link';
import UpsellModal from 'common/modals/UpsellModal';
import Tappable from 'common/Tappable';
import { GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterPlanID } from 'common/util/isStarter';
import { RoutePermissions } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import AdminBoardSettingsReorderModal from './AdminBoardSettingsReorderModal';
import AdminSettingsSidebarLink from './AdminSettingsSidebarLink';

import 'css/components/subdomain/admin/_AdminBoardSettingsContainer.scss';

class AdminBoardSettingsContainer extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.object,
    location: PropTypes.object,
    params: PropTypes.object,
    router: PropTypes.object,
    openModal: PropTypes.func,
  };

  state = {
    showUpsellModal: null,
  };

  componentDidMount() {
    this.selectBoard();
  }

  componentDidUpdate(prevProps) {
    const { boards, params } = this.props;

    if (prevProps.boards !== boards || prevProps.params !== params) {
      this.selectBoard();
    }
  }

  selectBoard = () => {
    const { boards, params } = this.props;
    const isBoardSelected = params.boardURLName
      ? !!boards.find((board) => {
          return board.urlName === params.boardURLName;
        })
      : false;
    if (isBoardSelected) {
      return;
    }

    if (boards.length === 0) {
      return;
    }

    const firstBoard = boards[0];
    const { router } = this.props;
    router.replace(`/admin/settings/boards/${firstBoard.urlName}/`);
  };

  onBoardSelected = (boardURLName) => {
    const {
      location: { pathname },
      router,
    } = this.props;
    const currentRoute = pathname
      .split('/')
      .filter((parts) => {
        return !!parts;
      })
      .pop();
    router.push(`/admin/settings/boards/${boardURLName}/${currentRoute}`);
  };

  onOpenReorderModal = () => {
    const { openModal } = this.props;
    openModal(AdminBoardSettingsReorderModal);
  };

  onUpsell = (planID) => {
    this.setState({ showUpsellModal: planID });
  };

  onUpsellDismiss = () => {
    this.setState({ showUpsellModal: null });
  };

  renderBoardDropdown() {
    const { boards, params } = this.props;
    if (boards.length === 0) {
      return null;
    }

    const selectedBoard = params.boardURLName
      ? boards.find((board) => {
          return board.urlName === params.boardURLName;
        }) || boards[0]
      : boards[0];
    return (
      <ControlledDropdown
        selectedName={selectedBoard.urlName}
        key={boards.length}
        onChange={this.onBoardSelected}
        options={boards.map((board) => ({
          name: board.urlName,
          render: board.name.replace(/ /g, '\u00a0'),
        }))}
      />
    );
  }

  renderCreateBoard() {
    const boardCount = this.props.company.boardCount;
    const boardLimit = this.props.company.limits.boards;
    if (boardLimit && boardLimit <= boardCount) {
      const planID = boardLimit === 1 ? StarterPlanID : GrowthPlanID;
      return <Button onTap={() => this.onUpsell(planID)} value="Create Board" />;
    }

    return (
      <Link to="/admin/create-board">
        <Button value="Create Board" />
      </Link>
    );
  }

  renderHeader() {
    return (
      <div className="settingsHeading boardSettingsHeader">
        <div className="left">
          <div className="top">
            <div className="boardSettingsHeading">Board&nbsp;Settings</div>
            {this.renderBoardDropdown()}
          </div>
          <div className="bottom">
            <div className="subheading">
              Manage and organize your feedback boards.
              <a
                className="learnMore"
                href="https://help.canny.io/en/articles/4968514-board-settings"
                target="_blank"
                rel="noreferrer">
                Learn&nbsp;more
              </a>
            </div>
          </div>
        </div>
        <div className="right">
          {this.renderReorderButton()}
          {this.renderCreateBoard()}
        </div>
      </div>
    );
  }

  renderReorderButton() {
    const { boards } = this.props;
    if (boards.length < 2) {
      return null;
    }

    return (
      <Tappable onTap={this.onOpenReorderModal}>
        <div className="reorder">Reorder&nbsp;boards</div>
      </Tappable>
    );
  }

  renderSidebar() {
    const { boards, params } = this.props;
    const selectedBoard = params.boardURLName
      ? boards.find((board) => {
          return board.urlName === params.boardURLName;
        }) || boards[0]
      : boards[0];
    if (!selectedBoard) {
      return null;
    }

    return (
      <div className="settingsSidebar">
        {SidebarLinks.map((linkData, i) => this.renderSidebarLink(selectedBoard, linkData, i))}
      </div>
    );
  }

  renderSidebarLink(selectedBoard, linkData, index) {
    const { label, urlSuffix } = linkData;
    const { urlName } = selectedBoard;

    return (
      <AdminSettingsSidebarLink
        key={urlSuffix}
        requiredPermissions={RoutePermissions.adminSettings.board[urlSuffix]}
        to={`/admin/settings/boards/${urlName}/${urlSuffix}`}>
        {label}
      </AdminSettingsSidebarLink>
    );
  }

  render() {
    const { boards, params } = this.props;
    const board = boards.find((board) => {
      return board.urlName === params.boardURLName;
    });

    const cta = isStarter(this.state.showUpsellModal) ? 'Get more boards' : 'Get unlimited boards';
    return (
      <div className="adminBoardSettingsContainer">
        {this.renderHeader()}
        <div className="settingsContainer">
          {this.renderSidebar()}
          <div className="settingsContent" key={board ? board._id : 'no board'}>
            {this.props.children}
          </div>
          <UpsellModal
            cta={cta}
            feature="limits.boards"
            onClose={() => this.onUpsellDismiss()}
            onUpsell={() => this.onUpsellDismiss()}
            planID={this.state.showUpsellModal}
            show={!!this.state.showUpsellModal}
          />
        </div>
      </div>
    );
  }
}

const SidebarLinks = [
  {
    label: 'General',
    urlSuffix: 'general',
  },
  {
    label: 'Create Post Form',
    urlSuffix: 'create-form',
  },
  {
    label: 'Privacy',
    urlSuffix: 'privacy',
  },
  {
    label: 'Tags',
    urlSuffix: 'tags',
  },
  {
    label: 'Widget',
    urlSuffix: 'widget',
  },
  {
    label: 'Categories',
    urlSuffix: 'categories',
  },
  {
    label: 'Smart Replies',
    urlSuffix: 'smart-replies',
  },
  {
    label: 'Import Data',
    urlSuffix: 'data-import',
  },
  {
    label: 'Export Data',
    urlSuffix: 'data-export',
  },
  {
    label: 'Delete Board',
    urlSuffix: 'delete',
  },
];

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  params: ParamsContext,
  router: RouterContext,
  openModal: OpenModalContext,
})(AdminBoardSettingsContainer);

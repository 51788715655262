import React from 'react';

import TextInput from 'common/inputs/TextInput';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotIntegrationModal.module.scss';

type Props = {
  zendeskSubdomain: string;
  setZendeskSubdomain: (value: string) => void;
};

const ZendeskSubdomainInput = ({ zendeskSubdomain, setZendeskSubdomain }: Props) => {
  return (
    <div className={styles.zendeskSubdomainInput}>
      <TextInput
        inset="Subdomain"
        value={zendeskSubdomain}
        onChange={(e) => setZendeskSubdomain(e.target.value)}
        prefix="https://"
        suffix=".zendesk.com"
      />
    </div>
  );
};

export default ZendeskSubdomainInput;

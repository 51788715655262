import React, { Component } from 'react';

import Helmet from './Helmet';

export default class AdminCreateBoardHelmet extends Component {
  render() {
    return (
      <Helmet
        title="Create Board | Canny"
        meta={[
          {
            name: 'description',
            content: 'Create boards that allow your users to vote on anything.',
          },
        ]}
      />
    );
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Colors from 'common/colors/constants';
import { colorPaletteHash } from 'common/colors/utils';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withContexts from 'common/util/withContexts';

import Image from '../Image';

import StarImage from 'img/white-star.png';

import 'css/components/user/_UserAvatar.scss';

class UserAvatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    company: PropTypes.shape({
      members: PropTypes.array,
    }),
    loading: PropTypes.oneOf(['eager', 'lazy']),
    showBadge: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    tintColor: PropTypes.string,
    user: PropTypes.shape({
      _id: PropTypes.string,
      aliasID: PropTypes.string,
      avatarURL: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    loading: 'lazy',
    showBadge: false,
  };

  renderAvatar() {
    const { _id, aliasID, avatarURL, name } = this.props.user;
    const userID = _id ?? aliasID;

    if (avatarURL) {
      return (
        <div className="avatarContainer">
          <Image alt={name} loader={<div className="loading" />} src={avatarURL} />
        </div>
      );
    } else {
      const firstLetter = name.substr(0, 1);
      const color = colorPaletteHash(userID);
      return (
        <div
          className="missingAvatar"
          style={{
            color: Colors[`${color}110`],
            backgroundColor: Colors[`${color}40`],
          }}>
          {firstLetter}
        </div>
      );
    }
  }

  renderBadge() {
    const { company, loading, showBadge, tintColor, user } = this.props;
    if (!showBadge || !company || !company.members) {
      return null;
    }

    var isMember = false;
    company.members.forEach((member) => {
      if (member._id === user._id) {
        isMember = true;
      }
    });

    if (!isMember) {
      return null;
    }

    const badgeStyle = {
      ...(tintColor && { backgroundColor: tintColor }),
    };
    return (
      <div className="badge">
        <LazyLoadedImage
          alt="member badge"
          className="star"
          loading={loading}
          src={StarImage}
          style={badgeStyle}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={classnames('userAvatar', this.props.className, this.props.size)}>
        {this.renderAvatar()}
        {this.renderBadge()}
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  tintColor: TintColorContext,
})(UserAvatar);

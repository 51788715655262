import React from 'react';

import 'css/components/common/_Truncate.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  numberOfLines?: number;
  children?: React.ReactNode;
};

// https://css-tricks.com/line-clampin/#weird-webkit-flexbox-way
const Truncate = ({ children, numberOfLines = 1, ...props }: Props) => {
  return (
    <div {...props} className="truncate" style={{ WebkitLineClamp: numberOfLines }}>
      {children}
    </div>
  );
};

export default Truncate;

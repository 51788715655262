import React, { useContext } from 'react';

import { AlertCircle } from 'lucide-react';

import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import Button from 'common/inputs/Button';

import 'css/components/common/_Error.scss';

type Props = {
  heading?: React.ReactNode;
  subHeading?: React.ReactNode;
  icon?: React.ReactNode;
};

const Error = ({ subHeading, heading = 'Oops, looks like something went wrong!', icon }: Props) => {
  const showIntercom = useContext(ShowIntercomContext);

  return (
    <div className="errorWrapper">
      <div className="errorIcon">{icon || <AlertCircle className="alertIcon" size={120} />}</div>
      <p className="text">{heading}</p>
      <p className="subText">
        {subHeading || (
          <>
            <span>You may refresh the page or try again later. Please </span>
            <Button buttonType="textButton" value="contact us" onTap={showIntercom} />
            <span> if the problem persists.</span>
          </>
        )}
      </p>
    </div>
  );
};
export default Error;

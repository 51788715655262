import React, { Component } from 'react';

import PropTypes from 'prop-types';

import cloneElementWithProps from 'common/core/cloneElementWithProps';
import loadScript from 'common/util/loadScript';

export const TrackBingEventContext = React.createContext();

function loadBingSDK() {
  window.uetq = window.uetq || [];

  loadScript('https://bat.bing.com/bat.js', 'bing-script', {
    callback: () => {
      if (typeof window.UET === 'undefined') {
        return;
      }
      window.uetq = new window.UET({
        ti: '26062189',
        q: window.uetq,
      });
      window.uetq.push('pageLoad');
    },
  });
}

export default class BingContainer extends Component {
  static propTypes = {
    shouldLoadSDK: PropTypes.bool,
  };

  static defaultProps = {
    shouldLoadSDK: false,
  };

  componentDidMount() {
    const { shouldLoadSDK } = this.props;
    if (!shouldLoadSDK) {
      return;
    }

    loadBingSDK();
  }

  onTrackEvent = (eventName, eventData) => {
    const { shouldLoadSDK } = this.props;
    if (!shouldLoadSDK) {
      return;
    }

    if (typeof window.uetq === 'undefined') {
      loadBingSDK();
    }

    window.uetq.push('event', eventName, {
      event_category: 'Canny Events',
      event_label: eventName,
    });
  };

  render() {
    const childProps = Object.assign({}, this.props);
    delete childProps.shouldLoadSDK;
    return (
      <TrackBingEventContext.Provider value={this.onTrackEvent}>
        {cloneElementWithProps(this.props.children, {
          ...childProps,
        })}
      </TrackBingEventContext.Provider>
    );
  }
}

import React, { useEffect, useState } from 'react';

import { type Stripe, loadStripe } from '@stripe/stripe-js';

import StripeContext from 'common/contexts/StripeContext';

const StripeKey = __DEV__ ? 'pk_test_QdkCWFRIz1Ew9fjCPhlYspkj' : 'pk_live_vYNYos5vTI9MrxewiKBDXT6C';

type Props = {
  children: React.ReactNode;
};

const StripeContainer = ({ children }: Props) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    setStripePromise(loadStripe(StripeKey));
  }, []);

  return <StripeContext.Provider value={stripePromise}>{children}</StripeContext.Provider>;
};
export default StripeContainer;

import React from 'react';

import { IntegrationItemNames, SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import TextInput from 'common/inputs/TextInput';
import SpinnerV2 from 'common/SpinnerV2';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationAutopilotSettingsSection from 'common/subdomain/admin/AdminQueue/AdminIntegrationAutopilotSettingsSection/AdminIntegrationAutopilotSettingsSection';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import Label from 'common/ui/common/Label';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/_AdminFreshdeskSettings.module.scss';

import type { FreshdeskSettings } from 'common/api/endpoints/freshdesk';

type Props = {
  installation: FreshdeskSettings['installation'];
  shouldUpsell: boolean;
  loading: boolean;
  onInstall: () => void;
  onUninstall: () => void;
  setAPIKey: (apiKey: string) => void;
  setDomain: (domain: string) => void;
  apiKey: string;
  domain: string;
};

const AdminFreshdeskInstallFlow = ({
  installation,
  shouldUpsell,
  loading,
  onInstall,
  onUninstall,
  setAPIKey,
  setDomain,
  apiKey,
  domain,
}: Props) => {
  const hasInstalled = installation && !!installation?.installed && !installation?.lostAccess;
  const hasLostAccess = !!installation?.lostAccess;

  if (shouldUpsell) {
    return <AdminFeatureUpsell feature="freshdesk" />;
  } else if (loading) {
    return <SpinnerV2 size="xxxxlarge" />;
  } else if (!hasInstalled) {
    return (
      <>
        {hasLostAccess && (
          <Alert
            className={styles.alert}
            type={AlertTypes.Danger}
            headingText="Your Freshdesk account is disconnected."
            subText="The connection between Canny and Freshdesk has been lost. Please reinstall the integration."
          />
        )}
        <P>
          Enter your Freshdesk API key and domain below to connect your Freshdesk account. If you
          need help finding your credentials, refer to&nbsp;
          <a
            className={styles.articleLink}
            href="https://help.canny.io/en/articles/9930902-freshdesk-integration"
            rel="noopener"
            target="_blank">
            this help article
          </a>
          .
        </P>
        <div className={styles.installContainer}>
          <div className={styles.installFields}>
            <Label label={<P fontWeight="medium">Domain</P>} labelPlacement="top">
              <TextInput
                className={styles.input}
                onChange={(e) => setDomain(e.currentTarget.value)}
                placeholder="company.freshdesk.com"
                value={domain}
              />
            </Label>
            <Label label={<P fontWeight="medium">API Key</P>} labelPlacement="top">
              <TextInput
                className={styles.input}
                onChange={(e) => setAPIKey(e.currentTarget.value)}
                placeholder="e.g. rZmPxkVTqWoLjdCNis"
                value={apiKey}
              />
            </Label>
          </div>
          <div className={styles.installButtons}>
            <ButtonV2
              size="medium"
              onClick={onInstall}
              loading={loading}
              disabled={!domain || !apiKey}>
              {hasLostAccess ? 'Reinstall Freshdesk' : 'Install Freshdesk'}
            </ButtonV2>
            {hasLostAccess && (
              <ButtonV2 variant="ghost" size="medium" onClick={onUninstall} color="error">
                Uninstall
              </ButtonV2>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Alert
        type={AlertTypes.Confirmation}
        className={styles.alert}
        headingText="Freshdesk connected"
        subText={`${installation.accountName} (${installation.domain}) is linked to your workspace`}
        button={
          <ButtonV2 size="medium" onClick={onUninstall} color="error" variant="outlined">
            Uninstall
          </ButtonV2>
        }
      />
      <AdminIntegrationAutopilotSettingsSection
        integrationLabel="Freshdesk"
        integrationName={SyncIntegrationNames.freshdesk}
        integrationType={IntegrationItemNames.ticket}
      />
    </>
  );
};

export default AdminFreshdeskInstallFlow;

import React from 'react';

import classnames from 'classnames';

import { CountryCodes } from 'common/constants/countries';
import ReviewIntegrationSearchSuggestion from 'common/subdomain/admin/AdminAutopilotSettings/ReviewIntegrationSearch/ReviewIntegrationSearchSuggestion';
import { type Option } from 'common/ui/common/select/SelectCommon';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import { P } from 'common/ui/Text';
import mapify from 'common/util/mapify';
import styles from 'css-module/components/subdomain/admin/settings/_ReviewIntegrationSearch.module.scss';

import type { IntegrationItem } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';

type OwnProps = {
  searchLabel?: string;
  searchSublabel?: string;
  searchLoading?: boolean;
  loading?: boolean;
  className?: string;
  stackedFields?: boolean;
  searchParams: SearchParams;
  setSearchParams: (search: SearchParams) => void;
  disallowEditing?: boolean;
  searchSuggestions?: SearchResult[];
  onSourceSelected: (source: SearchResult) => void;
};

type Props = Pick<IntegrationItem, 'needsCountry'> & OwnProps;
export type SearchResult = { canSelect: boolean; name: string; link: string; score: number };
export type SearchParams = { search?: string; countryCode: CountryCodes | null };

export const RequestErrors = {
  'failed to search': 'Something went wrong, please try again later.',
  'invalid country code': 'Please select a country.',
  default: 'Something went wrong, please try again later.',
};

const CountryOptions = Object.entries(CountryCodes).map(([countryName, countryCode]) => ({
  label: countryName,
  value: countryCode,
}));

const getCountryOption = (countryCode: CountryCodes | null) => {
  return CountryOptions.find((option) => option.value === countryCode);
};

const ReviewIntegrationSearch = ({
  needsCountry,
  searchLabel = 'Search',
  searchSublabel,
  searchLoading = false,
  loading = false,
  className,
  stackedFields = false,
  searchParams,
  setSearchParams,
  disallowEditing = false,
  searchSuggestions = [],
  onSourceSelected,
}: Props) => {
  // state
  const canEditFields = loading ? false : !disallowEditing;

  const searchSuggestionsMap: Record<string, SearchResult> = mapify(searchSuggestions, 'link');

  const searchOptions: Option[] = Object.values(searchSuggestionsMap).map((suggestion) => {
    return {
      label: suggestion.name,
      value: suggestion.link,
      disabled: !suggestion.canSelect,
      render: <ReviewIntegrationSearchSuggestion suggestion={suggestion} />,
    };
  });

  // helpers
  const onSelectCountry = (option: Option | undefined) => {
    if (!option) {
      return;
    }

    setSearchParams({ countryCode: option.value as CountryCodes, search: searchParams.search });
  };

  const onSearch = (search?: string) => {
    setSearchParams({ search, countryCode: searchParams.countryCode });
  };

  const onSourceChange = (option?: Option) => {
    if (!option) {
      return;
    }

    onSourceSelected(searchSuggestionsMap[option.value]);
  };

  const getSelectedValue = () => {
    const copy = searchParams.search ?? '';
    return {
      label: copy,
      value: copy,
    };
  };

  // render
  return (
    <div
      className={classnames([
        styles.bottomContainer,
        className,
        { [styles.stacked]: stackedFields },
      ])}>
      {needsCountry && (
        <div className={classnames([styles.formSection, styles.countryDropdown])}>
          <P variant="bodyMd" className={styles.label} fontWeight="medium">
            Country<span className={styles.requiredField}>&#42;</span>
          </P>
          <div className={styles.inputContainer}>
            <SingleSelectWithSearch
              headClassName={styles.countryDropdownHead}
              value={getCountryOption(searchParams.countryCode)}
              disabled={!canEditFields}
              onChange={onSelectCountry}
              options={CountryOptions}
              placeholder={searchParams.countryCode ? undefined : 'Select country'}
            />
          </div>
        </div>
      )}
      <div className={styles.formSection}>
        <P className={styles.label} fontWeight="medium">
          {searchLabel}
          <span className={styles.requiredField}>&#42;</span>
        </P>
        {searchSublabel && <P className={styles.sublabel}>{searchSublabel}</P>}
        <div className={styles.inputContainer}>
          <SingleSelectWithSearch
            className={styles.searchInputContainer}
            disabled={!canEditFields}
            placeholder="Search for your product..."
            onSearchChange={onSearch}
            onChange={onSourceChange}
            value={getSelectedValue()}
            options={searchOptions}
            loading={searchLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewIntegrationSearch;

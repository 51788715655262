import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadRules, reloadRules } from 'common/actions/clickupRules';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import asyncConnect from 'common/core/asyncConnect';
import Dropdown from 'common/Dropdown';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminClickupSettings.scss';

const AnyAllOptions = [
  {
    name: 'any',
    render: 'any',
  },
  {
    name: 'all',
    render: 'all',
  },
];
const AuthURL = '/api/clickup/authorize';
const NotifyOptions = [
  {
    name: 'notify',
    render: 'notify',
  },
  {
    name: 'do not notify',
    render: 'do not notify',
  },
];
const OneSecond = 1000;

class AdminClickupSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      clickup: PropTypes.shape({
        _id: PropTypes.string,
      }),
      subdomain: PropTypes.string,
    }),
  };

  state = {
    clickupStatuses: [],
    creatingRule: false,
    deletingRule: null,
    error: null,
    loading: null,
    savingRule: false,
    selectedAllOption: 'all',
    selectedStatusName: null,
  };

  #authWindowInterval = null;

  constructor(props, context) {
    super(props, context);

    this.cannyStatusDropdownRef = React.createRef();
    this.notifyVotersDropdownRef = React.createRef();
  }

  async componentDidMount() {
    const { company } = this.props;
    if (!company?.clickup?.authorized) {
      return null;
    }

    // load data from clickup
    this.setState({ loading: 'CLICKUP' });

    const clickupStatuses = await this.fetchClickupStatuses();

    this.setState({
      clickupStatuses,
      loading: null,
      selectedStatusName: clickupStatuses[0],
    });
  }

  componentWillUnmount() {
    clearInterval(this.#authWindowInterval);
  }

  onAuthorize = async () => {
    this.setState({ loading: 'AUTHORIZING' });
    const authWindow = window.open(AuthURL, '_blank', 'noopener');

    // Track whether the authorization window was closed or not.
    this.#authWindowInterval = setInterval(async () => {
      // Close "auth window" when authenticated successfully
      const isBackToSettings = authWindow?.location.href === window.location.href;
      if (isBackToSettings) {
        authWindow.close();
        clearInterval(this.#authWindowInterval);
        this.setState({ loading: 'CLICKUP' });
        const clickupStatuses = await this.fetchClickupStatuses();
        this.setState({
          clickupStatuses,
          selectedStatusName: clickupStatuses[0],
        });
      }

      // Reload data if the "auth window was" closed.
      const isAuthWindowClosed = !authWindow || authWindow.closed;
      if (isAuthWindowClosed) {
        clearInterval(this.#authWindowInterval);
        await Promise.all([this.props.reloadCompany(), this.props.reloadRules()]);
        this.setState({ loading: null });
        return;
      }
    }, OneSecond);
  };

  onDeleteRule = async (rule) => {
    const { reloadRules } = this.props;

    this.setState({ deletingRule: rule._id });
    const response = await AJAX.post('/api/clickup/removeRule', {
      ruleID: rule._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({ deletingRule: null, error: error.message });
      return;
    }

    await reloadRules();
    this.setState({ deletingRule: null, error: null });
  };

  onInstall = async () => {
    const { reloadCompany } = this.props;
    this.setState({
      error: null,
      loading: 'INSTALLING',
    });
    const response = await AJAX.post('/api/clickup/install');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({
        error: error.message,
        loading: null,
      });
      return;
    }

    await reloadCompany();
    this.onAuthorize();
  };

  onUninstall = async () => {
    this.setState({
      error: null,
      loading: 'UNINSTALLING',
    });
    const response = await AJAX.post('/api/clickup/uninstall');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({
        error: error.message,
        loading: null,
      });
      return;
    }

    await this.props.reloadCompany();
    this.setState({ loading: null });
  };

  onOpenCreateForm = () => {
    this.setState({ creatingRule: true });
  };

  onCloseCreateForm = () => {
    this.setState({ creatingRule: false });
  };

  onSaveRule = async () => {
    const { reloadRules } = this.props;

    const cannyStatus = this.cannyStatusDropdownRef.current.getValue();
    const shouldNotifyVoters = this.notifyVotersDropdownRef.current.getValue() === 'notify';

    this.setState({ error: null, savingRule: true });
    const response = await AJAX.post('/api/clickup/createRule', {
      all: this.state.selectedAllOption === 'all',
      cannyStatus,
      clickupStatus: this.state.selectedStatusName,
      shouldNotifyVoters,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'similar rule exists': `There's already a rule assigned to this ClickUp status for this list. Please, select another one and try again.`,
      },
    });

    if (error) {
      this.setState({ error: error.message, savingRule: false });
      return;
    }

    await reloadRules();
    this.setState({
      creatingRule: false,
      error: null,
      savingRule: false,
    });
  };

  fetchClickupStatuses = async () => {
    const response = await AJAX.post('/api/clickup/getTeam');

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.result,
      errors: {
        'plan does not support': 'Your plan does not support this integration.',
        'invalid oneLoginDomain or clientID': 'Either the OneLogin Domain or Client ID is invalid.',
        'invalid oneLoginDomain': 'Please enter a valid OneLogin Domain.',
        default: 'Something went wrong, please contact our team for support.',
      },
    });

    if (error) {
      this.setState({
        error: error.message,
        loading: null,
      });
      return;
    }

    const statuses = [];
    for (const team of Object.values(parsedResponse.result.teams)) {
      for (const space of Object.values(parsedResponse.result.teams[team.id].spaces)) {
        for (const folder of Object.values(
          parsedResponse.result.teams[team.id].spaces[space.id].folders
        )) {
          for (const list of Object.values(
            parsedResponse.result.teams[team.id].spaces[space.id].folders[folder.id].lists
          )) {
            statuses.push(...list.statuses.map(({ status }) => status));
          }
        }
      }
    }

    return Array.from(new Set(statuses)).sort();
  };

  renderAuthorizeButton() {
    return <Button onTap={this.onAuthorize} value="Authorize ClickUp" />;
  }

  renderContents() {
    const { company } = this.props;
    const { loading } = this.state;
    const { installed, authorized } = company?.clickup ?? {};

    if (!company?.integrations?.clickup) {
      return <AdminFeatureUpsell feature="clickup" />;
    } else if (loading) {
      return this.renderLoading(loading);
    } else if (!installed) {
      return this.renderInstallButton();
    } else if (!authorized) {
      return this.renderAuthorizeButton();
    }

    return (
      <div className="installation">
        <div className="status">
          <div className="text">Your ClickUp integration is installed.</div>
          <Tappable onTap={this.onUninstall}>
            <div className="disconnect">Uninstall</div>
          </Tappable>
        </div>
        {this.renderRules()}
      </div>
    );
  }

  renderInstallButton() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
    } = this.props;
    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    return <Button onTap={this.onInstall} value="Install ClickUp" />;
  }

  renderLoading(loadingState) {
    const message =
      {
        AUTHORIZING: 'Authorizing ClickUp integration',
        CLICKUP: 'Loading data from ClickUp',
        INSTALLING: 'Installing ClickUp integration',
        UNINSTALLING: 'Uninstalling ClickUp integration',
      }[loadingState] ?? 'Loading ClickUp integration';

    return (
      <div className="spinnerContainer">
        <div>
          <Spinner />
        </div>
        <div className="installing">{message}</div>
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderCreateRuleForm() {
    const { company } = this.props;
    const { clickupStatuses, selectedAllOption, selectedStatusName } = this.state;

    const isPlural = selectedAllOption === 'all';
    const statusesOptions =
      clickupStatuses.map((status) => ({
        name: status,
        render: <div className="clickupStatusLabel">{status}</div>,
      })) || [];

    if (statusesOptions.length === 0) {
      return (
        <div className="composerContainer">
          <div className="error">You need to create statuses in ClickUp before creating rules.</div>
        </div>
      );
    }

    const cannyStatusOptions = company.statuses.map((status) => {
      return {
        name: status.name,
        render: <PostStatus showOpen={true} status={status.name} />,
      };
    });

    return (
      <div className="composerContainer">
        <div className="composer">
          When
          <ControlledDropdown
            className="anyAllDropdown"
            dropdownClassName="adminClickupSettingsDropdown"
            onChange={(option) => this.setState({ selectedAllOption: option })}
            options={AnyAllOptions}
            selectedName={selectedAllOption}
          />
          linked ClickUp {isPlural ? 'tasks are' : 'task is'} changed to
          <ControlledDropdown
            className="clickupStatusDropdown"
            dropdownClassName="adminClickupSettingsDropdown"
            options={statusesOptions}
            onChange={(status) => this.setState({ selectedStatusName: status })}
            selectedName={selectedStatusName}
          />
          , change all linked Canny posts to
          <Dropdown
            className="cannyStatusDropdown"
            defaultSelectedName="open"
            dropdownClassName="adminClickupSettingsDropdown"
            options={cannyStatusOptions}
            ref={this.cannyStatusDropdownRef}
          />
          , and
          <Dropdown
            className="notifyDropdown"
            defaultSelectedName="notify"
            dropdownClassName="adminClickupSettingsDropdown"
            options={NotifyOptions}
            ref={this.notifyVotersDropdownRef}
          />
          voters.
        </div>
        <div className="buttons">
          <Button buttonType="ghostButton" onTap={this.onCloseCreateForm} value="cancel" />
          <Button loading={this.state.savingRule} onTap={this.onSaveRule} value="Create" />
        </div>
      </div>
    );
  }

  renderRule = (rule) => {
    const isPlural = rule.all;
    return (
      <div className="rule" key={rule._id}>
        <div className="description">
          When <p className="underlined">{rule.all ? 'all' : 'any'}</p> linked ClickUp{' '}
          {isPlural ? 'tasks are' : 'task is'} changed to{' '}
          <div className="clickupStatusLabel underlined">{rule.clickupStatus}</div>, change all
          linked Canny posts to <PostStatus showOpen={true} status={rule.cannyStatus} />, and{' '}
          {rule.shouldNotifyVoters ? '' : 'do not'} notify voters.
        </div>
        {this.state.deletingRule === rule._id ? (
          <Spinner />
        ) : (
          <Tappable onTap={() => this.onDeleteRule(rule)}>
            <div className="icon icon-x" />
          </Tappable>
        )}
      </div>
    );
  };

  renderRules() {
    const { creatingRule } = this.state;
    const { rules } = this.props;
    if (!rules) {
      return null;
    }

    return (
      <div className="rules">
        <p className="text">
          Set up rules so that when a task's status changes, linked Canny posts are updated as well.
        </p>
        <UppercaseHeader>Rules</UppercaseHeader>
        <hr />
        {rules.length > 0 && <div className="ruleList">{rules.map(this.renderRule)}</div>}
        {creatingRule ? (
          this.renderCreateRuleForm()
        ) : (
          <Button
            className="createRuleButton"
            onTap={this.onOpenCreateForm}
            value="Create New Rule"
          />
        )}
      </div>
    );
  }

  render() {
    const { company } = this.props;

    return (
      <div className="adminClickupSettings">
        <Helmet title="ClickUp Integration | Canny" />
        <div className="settingsHeading">ClickUp Integration</div>
        <div className="content">
          <div className="text">
            Canny for ClickUp lets you link Canny posts with ClickUp tasks and sync statuses from
            ClickUp to Canny. This helps your engineering and product management teams communicate
            priorities.
          </div>
          {!company?.clickup?.authorized ? (
            <div className="text">
              TIP: We recommend creating a "Canny" ClickUp user to authenticate the integration.
              This way, you can specify which Spaces, Folders, and Lists the integration has access
              to.
            </div>
          ) : null}
          {this.renderContents()}
          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default compose(
  asyncConnect(
    [
      {
        promise: ({ store: { dispatch, getState } }) => dispatch(loadRules()),
      },
    ],
    (state) => ({ rules: state.clickupRules?.rules }),
    (dispatch) => ({
      reloadCompany: () => dispatch(reloadCompany()),
      reloadRules: (post) => dispatch(reloadRules()),
    })
  ),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.clickup), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
    },
    { forwardRef: true }
  )
)(AdminClickupSettings);

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { SignJWT } from 'jose';
import { type Dispatch, compose } from 'redux';

import { reloadAsanaSettings } from 'common/actions/asanaSettings';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Dropdown from 'common/Dropdown';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withAccessControl from 'common/routing/withAccessControl';
import setCookie from 'common/setCookie';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import Alert from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';
import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';
import { dayjs } from 'common/util/dayjsUtils';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';

import Rules from './Rules';

import type { AsanaSettings } from 'common/api/endpoints/asanaSettings';

import 'css/components/subdomain/admin/_AdminAsanaSettings.scss';

type OwnProps = {
  asanaSettings: AsanaSettings;
};

type ConnectProps = {
  reloadAsanaSettings(): Promise<void>;
};

type Props = OwnProps & ConnectProps;

// For more information, visit: https://developers.asana.com/docs/oauth
const Asana = 'asana';
const AsanaParams = {
  client_id: publicConfig('asanaClientID'),
  redirect_uri: devURL('https://canny.io/asana-redirect'),
  response_type: 'code',
};
const AsanaURL = 'https://app.asana.com/-/oauth_authorize';

const AdminAsanaSettings = (props: Props) => {
  // props
  const { asanaSettings, reloadAsanaSettings } = props;

  // context
  const activeIntegrations = useContext(ActiveIntegrationContext);
  const company = useContext(CompanyContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const viewer = useContext(ViewerContext);

  // state
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [workspaceID, setWorkspaceID] = useState<string | null>(null);
  const [updatingWorkspace, setUpdatingWorkspace] = useState<boolean>(false);
  const [appAuthorization] = useState<boolean>(location?.query?.appAuthorization === 'true');
  const [asanaState, setAsanaState] = useState<string | null>(null);

  // helpers
  const install = useCallback(
    async (code: string) => {
      setError(null);
      setLoading(true);

      const response = await AJAX.post('/api/asana/install', { code });
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        setLoading(false);
        setError(error.message);
        return;
      }

      await reloadAsanaSettings();
      setLoading(false);
    },
    [reloadAsanaSettings]
  );

  const setSelectedWorkspace = (workspaceID: string) => {
    setWorkspaceID(workspaceID);
  };

  const uninstall = async () => {
    setLoading(true);
    setError(null);

    const response = await AJAX.post('/api/asana/uninstall', {});
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setLoading(false);
      setError(error.message);
      return;
    }

    await reloadAsanaSettings();
    setLoading(false);
  };

  const updateWorkspace = async () => {
    setUpdatingWorkspace(true);

    const response = await AJAX.post('/api/asana/setWorkspace', { workspaceID });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setUpdatingWorkspace(false);
      setError(error.message);
      return;
    }

    await reloadAsanaSettings();
    setUpdatingWorkspace(false);
  };

  // effects
  useEffect(() => {
    if (asanaState) {
      return;
    }

    const generateJWT = async () => {
      const stateBody = {
        integration: Asana,
        subdomain: company.subdomain,
        ...(appAuthorization && { appAuthorization }),
        hash: viewer.cannyHash,
      };
      const jwt = await new SignJWT(stateBody)
        .setProtectedHeader({ alg: 'HS256' })
        .setExpirationTime('2h')
        .sign(new TextEncoder().encode(viewer.cannyHash));
      const state = encodeURIComponent(jwt);

      setAsanaState(state);
      setCookie('asana_state_value', state);
    };
    generateJWT();
  }, [appAuthorization, asanaState, company.subdomain, viewer.cannyHash]);

  useEffect(() => {
    const { code } = location.query;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    install(code);
  }, [install, location.query, location.pathname, router]);

  useEffect(() => {
    if (!asanaSettings || !appAuthorization) {
      return;
    }

    const { installation, workspaces } = asanaSettings;
    const hasInstalled = !!installation?.installed && !installation?.lostAccess;

    if (!hasInstalled) {
      return;
    }

    const workspace = workspaces.find((workspace) => workspace.id === installation.workspaceID);

    if (!workspace) {
      return;
    }

    const redirectURL = devURL(`https://canny.io/asana-redirect?appAuthorizationCompleted=true`);
    window.location.replace(redirectURL);
  }, [asanaSettings, appAuthorization]);

  // renderers
  const renderContents = () => {
    if (!asanaSettings) {
      return null;
    }

    const { customFields, installation, rules, workspaces } = asanaSettings;
    const hasInstalled = !!installation?.installed && !installation?.lostAccess;

    if (!company?.integrations?.asana) {
      return <AdminFeatureUpsell feature="asana" />;
    } else if (loading) {
      return renderLoading();
    } else if (!hasInstalled) {
      return renderInstallButton();
    }

    const workspace = workspaces.find((workspace) => workspace.id === installation.workspaceID);
    const workspaceOptions = workspaces.map((workspace) => ({
      name: workspace.id,
      render: workspace.name,
    }));
    const syncStatus = capitalizeFirstLetter(installation.syncStatus);
    const syncExecutedAt = installation.syncExecutedAt
      ? dayjs(installation.syncExecutedAt).format('D/MM/YYYY HH:mm')
      : 'None';

    return (
      <div className="installation fullWidth">
        <div className="status">
          {workspace ? (
            <Alert type={Alert.AlertTypes.Confirmation} className="fullWidth standardMargin">
              <Alert.Heading>Asana connected</Alert.Heading>
              <Alert.Content>
                Your Asana integration is installed in your workspace
                <Tooltip
                  className="adminAsanaSettingsTooltip"
                  containerClassName="helpIcon"
                  position="bottom"
                  value={
                    <>
                      <P>Workspace:&nbsp;{workspace.name}</P>
                      <P>Webhook sync status: {syncStatus}</P>
                      <P>Last sync: {syncExecutedAt}</P>
                    </>
                  }>
                  <span className="icon-help" />
                </Tooltip>
              </Alert.Content>
              <Alert.CTA>
                <ButtonV2 variant="outlined" color="primary" size="medium" onClick={uninstall}>
                  Uninstall Asana
                </ButtonV2>
              </Alert.CTA>
            </Alert>
          ) : null}
        </div>
        {!workspace && (
          <>
            <div className="text">Select a workspace to complete installation:</div>
            <div className="workspace">
              <Dropdown
                className="workspaceDropdown"
                onChange={setSelectedWorkspace}
                options={workspaceOptions}
                placeholder={'Workspace...'}
              />
              <Button
                value="Save"
                disabled={!workspaceID}
                loading={updatingWorkspace}
                onTap={updateWorkspace}
              />
            </div>
          </>
        )}
        {workspace && (
          <Rules
            asanaCustomFields={customFields}
            asanaRules={rules}
            onError={setError}
            onRuleCreated={reloadAsanaSettings}
            onRuleDeleted={reloadAsanaSettings}
          />
        )}
      </div>
    );
  };

  const renderInstallButton = () => {
    const { integrationCount, integrationLimit } = activeIntegrations;
    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    const hasLostAccess = !!asanaSettings?.installation?.lostAccess;
    const isInstalled = asanaSettings?.installation?.installed;
    const asanaURL = `${AsanaURL}${queryString.stringify({
      ...AsanaParams,
      state: asanaState,
    })}`;

    return (
      <>
        {hasLostAccess && (
          <Alert type={Alert.AlertTypes.Danger} className="fullWidth standardMargin">
            <Alert.Heading>Lost access</Alert.Heading>
            <Alert.Content>
              Your Asana integration has lost access to Canny. Please reinstall the integration.
            </Alert.Content>
            <Alert.CTA>
              <ButtonV2 asChild size="medium">
                <a href={asanaURL} className="buttonContainer" rel="noreferrer noopener nofollow">
                  Reinstall Asana
                </a>
              </ButtonV2>
            </Alert.CTA>
          </Alert>
        )}
        {isInstalled ? (
          <ButtonV2
            variant="outlined"
            color="primary"
            size="medium"
            onClick={uninstall}
            className="standardMargin">
            Uninstall Asana
          </ButtonV2>
        ) : (
          <ButtonV2 asChild size="medium">
            <a href={asanaURL} className="buttonContainer" rel="noreferrer noopener nofollow">
              Install Asana
            </a>
          </ButtonV2>
        )}
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className="spinnerContainer">
        <Spinner />
      </div>
    );
  };

  const renderError = () => {
    if (!error && asanaSettings) {
      return null;
    }

    const errorMessage = error ?? 'Could not load Asana settings. Please try again later';
    return <div className="error">{errorMessage}</div>;
  };

  return (
    <div className="adminAsanaSettings">
      <Helmet title="Asana Integration | Canny" />
      <AdminSettingsHeader
        title="Asana Integration"
        subheading="Link Canny posts to Asana tasks and sync fields from Asana to Canny."
        learnMoreLink="http://help.canny.io/en/articles/7065590-asana-integration"
      />
      <div className="content">
        {renderContents()}
        {renderError()}
      </div>
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadAsanaSettings: () => dispatch(reloadAsanaSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.asana),
    '/admin/settings'
  )
)(AdminAsanaSettings) as unknown as React.FC<OwnProps>;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import ChangelogEntry from 'common/changelog/ChangelogEntry';

import 'css/components/subdomain/admin/_AdminChangelogCreatePreview.scss';

export default class AdminChangelogCreatePreview extends Component {
  static propTypes = {
    changelog: PropTypes.object,
    details: PropTypes.string,
    entry: PropTypes.object,
    selectedLabels: PropTypes.array,
    selectedTypes: PropTypes.array,
    title: PropTypes.string,
    unsavedWork: PropTypes.bool,
  };

  renderPreview() {
    const { changelog, details, entry, selectedLabels, selectedTypes, title, unsavedWork } =
      this.props;

    const liveEntry = {
      ...entry,
      status: unsavedWork || !entry ? 'draft' : entry.status,
      details,
      title: title || 'Entry title',
      labelIDs: selectedLabels,
      types: selectedTypes,
    };

    return (
      <ChangelogEntry
        disableTranslation={true}
        entry={liveEntry}
        changelog={changelog}
        showPublishTime
        showFooter
      />
    );
  }

  render() {
    const className = classnames('adminChangelogCreatePreview', {
      noTitle: this.props.title === '',
    });

    return <div className={className}>{this.renderPreview()}</div>;
  }
}

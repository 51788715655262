import React, { useContext, useState } from 'react';

import saveAs from 'file-saver';

import AJAX from 'common/AJAX';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import { P } from 'common/ui/Text';
import parseAPIResponse from 'common/util/parseAPIResponse';

import { ModalToastYOffset } from './constants';
import { type CommonModalProps } from './index';

const ExportPostsConfirmationModal = ({
  onClose,
  selectedPosts,
  currentRoadmap,
}: CommonModalProps) => {
  const showToast = useContext(ShowToastContext);
  const [loading, setLoading] = useState(false);

  const postPlural = selectedPosts.length > 1 ? 'posts' : 'post';

  const performExport = async () => {
    setLoading(true);

    const postIDs = selectedPosts.map((post) => post._id);

    const response = await AJAX.post('/api/roadmaps/posts/export', {
      postIDs,
      roadmapID: currentRoadmap._id,
    });

    const { error, parsedResponse } = parseAPIResponse<{ csv: string }>(response, {
      isSuccessful: (response) => response && !!response.csv,
    });

    if (error || !parsedResponse) {
      setLoading(false);
      showToast('Something went wrong, please try again.', ToastTypes.error, {
        yOffset: ModalToastYOffset,
      });
      return;
    }

    saveAs(new Blob([parsedResponse.csv]), `Canny_Posts_${new Date().toISOString()}.csv`);

    setLoading(false);
    showToast(`Successfully exported ${selectedPosts.length} ${postPlural}`, ToastTypes.success, {
      yOffset: ModalToastYOffset,
    });
    onClose();
  };

  return (
    <ModernConfirmModal
      onClose={onClose}
      onConfirm={performExport}
      confirmText={`Export ${postPlural}`}
      type="primary"
      header={`Exporting ${selectedPosts.length} ${postPlural}`}
      loading={loading}>
      <P>
        You have selected {selectedPosts.length} {postPlural} to export. Please note this might take
        some time.
      </P>
    </ModernConfirmModal>
  );
};

export default ExportPostsConfirmationModal;

import React from 'react';

import { H1 } from 'common/ui/Text';

import BoardGridItem from './BoardGridItem';

import type { Board } from 'common/api/endpoints/boards';

import 'css/components/common/_BoardGrid.scss';

type Props = {
  boards: Board[];
};

const BoardGrid = ({ boards }: Props) => {
  if (!boards.length) {
    return null;
  }

  return (
    <div className="boardGridContainer">
      <H1 className="header" variant="headingMd">
        Boards
      </H1>
      <div className="boardGrid">
        {boards.map((board) => (
          <BoardGridItem key={board._id} board={board} />
        ))}
      </div>
    </div>
  );
};
export default BoardGrid;

import { Component } from 'react';

import PropTypes from 'prop-types';

import { getCompanyQueryKey, loadQuery } from 'common/actions/thirdPartyCompanyQueries';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

function getQueryParams(location) {
  const { query } = location;

  const search = query.search ? query.search.trim() : null;
  const segmentURLName = query.segment ? query.segment.trim() : null;

  return {
    ...(search && { search }),
    ...(segmentURLName && { segmentURLName }),
    limit: 5,
  };
}

const asyncFetch = {
  promise: ({ store: { dispatch }, location }) => {
    const params = getQueryParams(location);
    // Don't load third party companies unless we're actually searching
    if (!params.search) {
      return;
    }
    return dispatch(loadQuery(getQueryParams(location)));
  },
};

class ThirdPartyCompanyListContainerV2 extends Component {
  static asyncConnect = asyncFetch;

  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.object,
      state: PropTypes.object,
    }),
    thirdPartyCompanies: PropTypes.object,
    thirdPartyCompanyQueries: PropTypes.object,
  };

  render() {
    const { children, location, thirdPartyCompanies, thirdPartyCompanyQueries } = this.props;
    const companyQueryKey = getCompanyQueryKey(getQueryParams(location));
    const companyQueryResult = Object.assign(
      { loading: true },
      thirdPartyCompanyQueries[companyQueryKey]
    );
    if (companyQueryResult.companyIDs) {
      companyQueryResult.companies = companyQueryResult.companyIDs.map((companyID) => {
        return thirdPartyCompanies[companyID];
      });
    }

    return cloneElementWithProps(children, {
      ...this.props,
      companyList: companyQueryResult,
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  thirdPartyCompanies: state.thirdPartyCompanies,
  thirdPartyCompanyQueries: state.thirdPartyCompanyQueries,
}))(ThirdPartyCompanyListContainerV2);

import React from 'react';

import SearchInput from 'common/inputs/SearchInput';
import AdminRoadmapGroupBy from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapHeader/AdminRoadmapGroupBy';
import { H2 } from 'common/ui/Text';

import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { CustomPostField } from 'common/api/resources/postFields';
import type { Option } from 'common/ui/common/select/SelectCommon';

import 'css/components/subdomain/public/_SharedRoadmapHeader.scss';

type Props = {
  customPostFields: CustomPostField[];
  groupBy?: Option;
  onSearch: (newSearchValue: string) => void;
  roadmap: Roadmap;
  setGroupBy: (option: Option | undefined) => void;
};

const SharedRoadmapHeader = ({
  customPostFields,
  groupBy,
  onSearch,
  roadmap,
  setGroupBy,
}: Props) => {
  const updateGroupBy = (selectedGroupBy: Option | undefined) => {
    setGroupBy(selectedGroupBy);
  };

  const renderControls = () => {
    return (
      <div className="sharedRoadmapHeader__controls">
        <AdminRoadmapGroupBy
          groupBy={groupBy}
          customPostFields={customPostFields}
          onGroupByChange={updateGroupBy}
        />
      </div>
    );
  };

  return (
    <header className="sharedRoadmapHeader">
      <div className="sharedRoadmapHeader__left-hand">
        <H2 className="sharedRoadmapHeader__title" variant="headingMd">
          {roadmap.name}
        </H2>
        <SearchInput
          autoFocus={false}
          onChange={(searchValue: string) => onSearch(searchValue.trim())}
          placeholder="Search..."
        />
      </div>
      {renderControls()}
    </header>
  );
};

export default SharedRoadmapHeader;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Draggable from 'common/Draggable';
import Link from 'common/Link';
import numberWithCommas from 'common/util/numberWithCommas';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminBoardListItem.scss';

class AdminBoardListItem extends Component {
  static propTypes = {
    alwaysShowDrag: PropTypes.bool,
    board: PropTypes.shape({
      name: PropTypes.string,
      settings: PropTypes.shape({
        private: PropTypes.bool,
      }),
      urlName: PropTypes.string,
    }),
    company: PropTypes.object,
    linkToBoard: PropTypes.bool,
    onDragEnd: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragStart: PropTypes.func,
    showLinks: PropTypes.bool,
    showPostCount: PropTypes.bool,
  };

  static defaultProps = {
    alwaysShowDrag: false,
    linkToBoard: true,
    showLinks: true,
    showPostCount: true,
  };

  state = {
    dragOffset: 0,
    dragging: false,
  };

  onDragStart = (e) => {
    this._dragStart = e.clientY;
    this.setState({
      dragging: true,
    });
    this.props.onDragStart();
  };

  onDragMove = (e) => {
    const dragOffset = e.clientY - this._dragStart;
    this.setState({
      dragOffset,
    });
    this.props.onDragMove(dragOffset);
  };

  onDragEnd = (e) => {
    this._dragStart = null;
    this.setState({
      dragOffset: 0,
      dragging: false,
    });
    this.props.onDragEnd();
  };

  renderBoard() {
    const {
      board: { name, urlName },
      company,
      linkToBoard,
    } = this.props;
    const adminURL = company.fullScreenAdminView
      ? `/admin/feedback?boards=${urlName}`
      : `/admin/board/${urlName}`;
    if (linkToBoard) {
      return (
        <Link className="linkContainer" to={adminURL}>
          <div className="name">{name}</div>
          {this.renderPostCount()}
        </Link>
      );
    } else {
      return (
        <div className="boardContainer">
          <div className="name">{name}</div>
          {this.renderPostCount()}
        </div>
      );
    }
  }

  renderLinks() {
    const {
      board: { urlName },
      showLinks,
    } = this.props;
    if (!showLinks) {
      return null;
    }

    const boardURL = `/${urlName}`;
    const settingsURL = `/admin/settings/boards/${urlName}/general`;
    return (
      <div className="icons">
        <a href={boardURL} target="_blank" rel="noopener">
          <div className="icon icon-link" />
        </a>
        {this.renderPrivacyIcon()}
        <Link to={settingsURL}>
          <div className="icon icon-gear" />
        </Link>
      </div>
    );
  }

  renderPostCount() {
    const {
      board: { activePostCount },
      showPostCount,
    } = this.props;
    if (!showPostCount) {
      return false;
    }

    return <div className="count">{numberWithCommas(activePostCount)}</div>;
  }

  renderPrivacyIcon() {
    const {
      board: { urlName, settings },
    } = this.props;
    const privacyURL = '/admin/settings/boards/' + urlName + '/privacy';

    if (settings.private) {
      return (
        <Link to={privacyURL}>
          <Tooltip position="top" value="Private board">
            <div className="icon icon-lock" />
          </Tooltip>
        </Link>
      );
    }

    return (
      <Link to={privacyURL}>
        <Tooltip position="top" value="Public board">
          <div className="icon icon-eye" />
        </Tooltip>
      </Link>
    );
  }

  render() {
    const { alwaysShowDrag } = this.props;
    const { dragging, dragOffset } = this.state;
    const classNames = classnames({
      dragging,
      listItemContainer: true,
    });
    return (
      <div className="adminBoardListItem">
        <div
          className={classNames}
          style={{
            top: dragOffset,
          }}>
          <Draggable
            onDragStart={this.onDragStart}
            onDragMove={this.onDragMove}
            onDragEnd={this.onDragEnd}
            pressTimeout={null}>
            <div
              className={classnames('icon icon-drag', {
                alwaysShow: alwaysShowDrag,
              })}
            />
          </Draggable>
          {this.renderBoard()}
          {this.renderLinks()}
        </div>
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(AdminBoardListItem);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { reloadViewer } from 'common/actions/viewer';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withContexts from 'common/util/withContexts';

import Pill from './Pill';
import VideoPlayButton from './VideoPlayButton';
import connect from '../core/connect';
import VideoModal from '../modals/VideoModal';
import Tappable from '../Tappable';

import 'css/components/common/_VideoNUX.scss';

class VideoNUX extends Component {
  static propTypes = {
    company: PropTypes.shape({
      nux: PropTypes.object,
    }),
    name: PropTypes.string.isRequired,
    openModal: PropTypes.func,
    pillText: PropTypes.string,
    text: PropTypes.string,
    videoThumbnail: PropTypes.string,
    videoURL: PropTypes.string,
  };

  static defaultProps = {
    pillText: 'Tip',
  };

  state = {
    isDismissed:
      this.props.company && this.props.company.nux
        ? this.props.company.nux[this.props.name]
        : false,
  };

  onDismiss = () => {
    const { name } = this.props;
    this.setState({ isDismissed: true });
    AJAX.post(
      '/api/nux/dismissNUX',
      {
        nuxType: name,
      },
      this.props.reload
    );
  };

  onOpenNUXVideo = () => {
    const { openModal, videoURL } = this.props;
    openModal(VideoModal, {
      source: videoURL,
    });
  };

  render() {
    const { isDismissed } = this.state;
    if (isDismissed) {
      return null;
    }

    const { pillText, text, videoThumbnail } = this.props;
    return (
      <div className="videoNUX">
        <Tappable onTap={this.onOpenNUXVideo}>
          <div className="videoNUXContent">
            <div className="thumbnail">
              <VideoPlayButton />
              <LazyLoadedImage src={videoThumbnail} />
            </div>
            <div className="text">
              <Pill className="pill">{pillText}</Pill>
              <div className="title">{text}</div>
            </div>
          </div>
        </Tappable>
        <Tappable onTap={this.onDismiss}>
          <div className="icon-x" />
        </Tappable>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reload: () =>
      Promise.all([dispatch(reloadCompany()), dispatch(reloadViewer({ includeDocsData: true }))]),
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(VideoNUX);

import { PostDraftDeleted, PostDraftSaved } from 'common/actions/postDraft';

export default function postDraft(state = {}, action) {
  switch (action.type) {
    case PostDraftDeleted: {
      return { lastUpdated: action.timestamp };
    }

    case PostDraftSaved: {
      return Object.assign(
        {},
        state,
        {
          lastUpdated: action.timestamp,
        },
        action.postDraft
      );
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import { ChevronUp } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { invalidateUserQueries } from 'common/actions/userQueries';
import { invalidateVoters } from 'common/actions/voters';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Message from 'common/message/Message';
import Tappable from 'common/Tappable';
import abbreviateNumber from 'common/util/abbreviateNumber';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import isWidget from 'common/util/isWidget';
import withContexts from 'common/util/withContexts';
import { ConfigContext } from 'common/widget/WidgetContext';

import 'css/components/post/_PostVotes.scss';

class PostVotes extends Component {
  static propTypes = {
    company: PropTypes.object,
    config: PropTypes.object,
    isWidget: PropTypes.bool,
    location: PropTypes.object,
    openModal: PropTypes.func.isRequired,
    post: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      score: PropTypes.number,
      viewerVote: PropTypes.number.isRequired,
      voteSettings: PropTypes.shape({
        votesHidden: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    tintColor: PropTypes.string.isRequired,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
  };

  state = {
    viewerDidVote: this.props.post.viewerVote,
  };

  constructor(props, context) {
    super(props, context);
    this._refreshCallback = null;
    this._voting = false;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.post && newProps.post.viewerVote !== this.props.post.viewerVote) {
      this.setState({
        viewerDidVote: newProps.post.viewerVote,
      });
    }
  }

  vote = (e) => {
    e.stopPropagation();

    // already sent a vote request
    if (this._voting) {
      return;
    }

    // not loaded
    const { viewer } = this.props;
    if (viewer.loading) {
      return;
    }

    var vote = () => {
      var score = 0;
      if (this.state.viewerDidVote !== 1) {
        score = 1;
      }

      this.setState({
        viewerDidVote: score,
      });

      this._voting = true;
      AJAX.post(
        '/api/posts/vote',
        {
          postID: this.props.post._id,
          score: score,
        },
        this.onVote
      );
    };

    // not logged in
    if (!viewer._id) {
      const { company, config, isWidget, location, openModal } = this.props;
      const { authRedirectEnabled, authRedirectURL } = company;
      if (authRedirectEnabled && authRedirectURL) {
        const redirectURL = getAuthRedirectURL(company, location, config);
        if (isWidget) {
          Message.postMessage(window.parent, '*', 'redirect', redirectURL);
        } else {
          window.location.assign(redirectURL);
        }
        return;
      }

      openModal(AccountModal, {
        formState: FormStates.signup,
        onSuccess: (callback) => {
          this._refreshCallback = callback;
          vote();
        },
      });
      return;
    }

    vote();
  };

  onVote = () => {
    if (this._refreshCallback) {
      this._refreshCallback();
      return;
    }

    this._voting = false;

    const { post, reloadPost } = this.props;
    reloadPost(post);
  };

  renderArrow() {
    const { tintColor } = this.props;
    const { viewerDidVote } = this.state;
    return (
      <ChevronUp
        size={24}
        className={classnames('icon', { voted: viewerDidVote === 1 })}
        style={{ ...(viewerDidVote === 1 && { color: tintColor }) }}
      />
    );
  }

  renderScore() {
    const {
      location: { query },
      post,
    } = this.props;
    const { viewerDidVote } = this.state;
    const areVotesFiltered = query['vote-created'] || query.segment;

    // Note: PostVotes is used in AdminDashboardActivity, which we don't return vote settings in it.
    if (post.voteSettings?.votesHidden) {
      return null;
    }

    let score;
    if (areVotesFiltered && typeof post.filteredScore !== 'undefined') {
      score = post.filteredScore;
      if (post.viewerInFilteredVoters) {
        score += viewerDidVote - post.viewerVote;
      }
    } else {
      score = post.score + viewerDidVote - post.viewerVote;
    }

    return <span>{abbreviateNumber(score)}</span>;
  }

  render() {
    //Note: PostVotes is used in AdminDashboardActivity, which we don't return vote settings in it.
    const votesHidden = this.props.post?.voteSettings?.votesHidden;

    return (
      <Tappable onTap={this.vote}>
        <div className={classnames('postVotes', { votesHidden })}>
          {this.renderArrow()}
          {this.renderScore()}
        </div>
      </Tappable>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => {
      return Promise.all([
        dispatch(invalidateDashboardActivity()),
        dispatch(invalidateVoters(post)),
        dispatch(reloadPost(post)),
        isWidget() ? null : dispatch(invalidatePostQueries()),
        isWidget() ? null : dispatch(invalidateUserQueries()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      config: ConfigContext,
      isWidget: IsWidgetContext,
      location: LocationContext,
      openModal: OpenModalContext,
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostVotes);

import {
  Integrations as AutopilotSources,
  GongSource,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import { GrowthPlanID } from 'common/util/isGrowth';
import ClickUpLogo from 'img/landing/clickup-logo.webp';
import GoogleLogo from 'img/landing/google-logo.webp';
import HubSpotLogo from 'img/landing/hubspot-logo.webp';
import LinearLogo from 'img/landing/linear-logo.webp';
import MsTeamsLogo from 'img/landing/microsoft-teams-logo.webp';
import OktaLogo from 'img/landing/okta-logo.webp';
import OneLoginLogo from 'img/landing/onelogin-logo.webp';
import SegmentLogo from 'img/landing/segment-logo.webp';

import AsanaLogo from 'img/asana-logo.png';
import AzureDevopsLogo from 'img/azure-devops-logo.png';
import AzureLogo from 'img/azure-logo-icon.png';
import ChromeLogo from 'img/chrome-logo.svg';
import DiscordLogo from 'img/discord-logo.png';
import FreshdeskLogo from 'img/freshdesk-logo-small.svg';
import GoogleAnalyticsLogo from 'img/ga-logo.png';
import GongLogo from 'img/gong-logo.png';
import HelpscoutLogo from 'img/helpscout-logo.png';
import GitHubLogo from 'img/landing/github-logo.svg';
import IntercomLogo from 'img/landing/intercom-logo.svg';
import JiraLogo from 'img/landing/jira-logo.svg';
import SalesforceLogo from 'img/landing/salesforce-logo.svg';
import SlackLogo from 'img/landing/slack-logo.svg';
import ZapierLogo from 'img/landing/zapier-logo.svg';
import ZendeskLogo from 'img/landing/zendesk-logo.svg';
import OIDCLogo from 'img/open-id-connect-logo.png';
import TLDVLogo from 'img/tldv-logo-small.svg';
import ZoomLogo from 'img/zoom-logo-small.png';

import type { Company } from 'common/api/endpoints/companies';
import type { Integrations as IntegrationNames } from 'common/constants/billing';

export enum Tag {
  business = 'Business',
  beta = 'Beta',
}

export type IntegrationLinkCard = {
  url: string;
  label: string;
  icon: string | React.ReactNode;
  credits?: number | { min: number; max: number };
  external?: boolean;
  zapier?: boolean;
  type?: never;
  tag?: Tag;
  upsell?: (company: Company) => null | { feature: string; cta: string; planID: string };
  onClick?: () => void;
};

type IntegrationCardMapKey = Exclude<
  keyof IntegrationNames | 'github' | 'googleAnalytics',
  'api' | 'webhooks'
>;

type IntegrationCardMap = Record<IntegrationCardMapKey, IntegrationLinkCard>;
export const IntegrationCards: IntegrationCardMap = {
  asana: {
    url: '/admin/settings/asana',
    label: 'Asana',
    icon: AsanaLogo,
  },
  azureAD: {
    url: '/admin/settings/azure',
    label: 'Azure',
    icon: AzureLogo,
    tag: Tag.business,
  },
  azureDevops: {
    url: '/admin/settings/azure-devops',
    label: 'Azure DevOps',
    icon: AzureDevopsLogo,
    tag: Tag.business,
  },
  clickup: {
    url: '/admin/settings/clickup',
    label: 'ClickUp',
    icon: ClickUpLogo,
  },
  discord: {
    url: '/admin/settings/discord',
    label: 'Discord',
    icon: DiscordLogo,
  },
  freshdesk: {
    url: '/admin/settings/freshdesk',
    label: 'Freshdesk',
    icon: FreshdeskLogo,
  },
  github: {
    url: '/admin/settings/github',
    label: 'GitHub',
    icon: GitHubLogo,
  },
  gong: {
    url: '/admin/settings/gong',
    label: 'Gong',
    icon: GongLogo,
  },
  gsuite: {
    url: '/admin/settings/google-workspace',
    label: 'Google Workspace',
    icon: GoogleLogo,
  },
  helpscout: {
    url: '/admin/settings/helpscout',
    label: 'Help Scout',
    icon: HelpscoutLogo,
  },
  hubspot: {
    url: '/admin/settings/hubspot',
    label: 'Hubspot',
    icon: HubSpotLogo,
    tag: Tag.business,
  },
  intercom: {
    url: '/admin/settings/intercom',
    label: 'Intercom',
    icon: IntercomLogo,
  },
  jira: {
    url: '/admin/settings/jira',
    label: 'Jira',
    icon: JiraLogo,
  },
  linear: {
    url: '/admin/settings/linear',
    label: 'Linear',
    icon: LinearLogo,
  },
  microsoftTeams: {
    url: '/admin/settings/microsoft-teams',
    label: 'Microsoft Teams',
    icon: MsTeamsLogo,
  },
  oidc: {
    url: '/admin/settings/oidc',
    label: 'OpenID Connect',
    icon: OIDCLogo,
    tag: Tag.business,
  },
  okta: {
    url: '/admin/settings/okta',
    label: 'Okta',
    icon: OktaLogo,
    tag: Tag.business,
  },
  oneLogin: {
    url: '/admin/settings/onelogin',
    label: 'OneLogin',
    icon: OneLoginLogo,
    tag: Tag.business,
  },
  salesforce: {
    url: '/admin/settings/salesforce',
    label: 'Salesforce',
    icon: SalesforceLogo,
    tag: Tag.business,
  },
  segment: {
    url: '/admin/settings/segment',
    label: 'Segment',
    icon: SegmentLogo,
  },
  slack: {
    url: '/admin/settings/slack',
    label: 'Slack',
    icon: SlackLogo,
  },
  tldv: {
    url: '/admin/settings/tldv',
    label: 'TLDV',
    icon: TLDVLogo,
  },
  zapier: {
    url: '/admin/settings/zapier',
    label: 'Zapier',
    icon: ZapierLogo,
  },
  zendesk: {
    url: '/admin/settings/zendesk',
    label: 'Zendesk',
    icon: ZendeskLogo,
  },
  zoom: {
    url: '/admin/settings/zoom',
    label: 'Zoom',
    icon: ZoomLogo,
  },
  browserExtension: {
    url: 'https://chrome.google.com/webstore/detail/canny/ppljpoflocngelbkbmebgkpdbbjaejhi',
    label: 'Chrome Extension',
    icon: ChromeLogo,
    external: true,
    upsell: (company: Company) => {
      if (company.integrations?.browserExtension) {
        return null;
      }
      return {
        feature: 'integrations.browserExtension',
        cta: 'Track feedback from any webpage via Chrome',
        planID: GrowthPlanID,
      };
    },
  },
  googleAnalytics: {
    url: '/admin/settings/google-analytics',
    label: 'Google Analytics',
    icon: GoogleAnalyticsLogo,
  },
};

// "Works with" subsection
export const AutopilotIntegrations = [...AutopilotSources, GongSource];

export const FeedbackBoardsIntegrations = [
  IntegrationCards.asana,
  IntegrationCards.azureDevops,
  IntegrationCards.clickup,
  IntegrationCards.discord,
  IntegrationCards.github,
  IntegrationCards.hubspot,
  IntegrationCards.intercom,
  IntegrationCards.jira,
  IntegrationCards.linear,
  IntegrationCards.microsoftTeams,
  IntegrationCards.salesforce,
  IntegrationCards.segment,
  IntegrationCards.slack,
  IntegrationCards.zapier,
  IntegrationCards.zendesk,
];

// "Categories" subsection
export const AuthenticationIntegrations = [
  IntegrationCards.azureAD,
  IntegrationCards.gsuite,
  IntegrationCards.oidc,
  IntegrationCards.okta,
  IntegrationCards.oneLogin,
];

export const AutomationIntegrations = [IntegrationCards.zapier];

export const CommunicationIntegrations = [
  IntegrationCards.intercom,
  IntegrationCards.zendesk,
  IntegrationCards.slack,
  IntegrationCards.microsoftTeams,
  IntegrationCards.discord,
  IntegrationCards.helpscout,
  IntegrationCards.freshdesk,
];

export const DataAndEnrichmentIntegrations = [
  IntegrationCards.googleAnalytics,
  IntegrationCards.segment,
  IntegrationCards.browserExtension,
];

export const ProjectManagementIntegrations = [
  IntegrationCards.asana,
  IntegrationCards.azureDevops,
  IntegrationCards.jira,
  IntegrationCards.github,
  IntegrationCards.clickup,
  IntegrationCards.linear,
];

export const SalesIntegrations = [
  IntegrationCards.hubspot,
  IntegrationCards.salesforce,
  IntegrationCards.gong,
  IntegrationCards.tldv,
  IntegrationCards.zoom,
];

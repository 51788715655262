import React, { Component } from 'react';

import PropTypes from 'prop-types';

import 'css/components/_UppercaseHeader.scss';

export default class UppercaseHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  render() {
    var className = 'uppercaseHeader';
    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    return (
      <div {...this.props} className={className}>
        {this.props.children}
      </div>
    );
  }
}

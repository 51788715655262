import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { getMentionQueryKey, loadSuggestions } from 'common/actions/mentionSuggestions';
import Portal from 'common/common/Portal';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import connect from 'common/core/connect';
import KeyCodes from 'common/KeyCodes';
import mod from 'common/mod';
import Tappable from 'common/Tappable';
import UserLockup from 'common/user/UserLockup';
import delayer from 'common/util/delayer';
import hexToRGB from 'common/util/hexToRGB';
import mapify from 'common/util/mapify';
import {
  RenderTypes,
  RenderValues,
  getIDFromMentionTag,
  getMentionDisplayValue,
  getMentionTag,
  getModeFromMentionTag,
} from 'common/util/mentions';
import withContexts from 'common/util/withContexts';

import AutoResizeTextarea from './AutoResizeTextarea';

import 'css/components/inputs/_MentionsTextarea.scss';

const Delay = 300;
const MaxLookback = 25;

class MentionsTextarea extends Component {
  static propTypes = {
    board: PropTypes.object.isRequired,
    company: PropTypes.shape({
      members: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
        })
      ),
    }),
    initialMentionedUsers: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        aliasID: PropTypes.string,
        name: PropTypes.string,
        urlName: PropTypes.string,
      })
    ),
    loadSuggestions: PropTypes.func,
    membersOnly: PropTypes.bool,
    mentionSuggestions: PropTypes.object,
    portalPosition: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    tintColor: PropTypes.string,
  };

  static defaultProps = {
    initialMentionedUsers: [],
    membersOnly: false,
    portalPosition: 'bottom',
  };

  state = {
    cursorPosition: 0,
    mentionedUsers: [],
    mentionPrefix: null,
    mentions: [],
    selectedSuggestionIndex: 0,
    selection: {
      direction: 'forward',
      end: 0,
      start: 0,
    },
    textareaWidth: 0,
    value: '',
  };

  constructor(props) {
    super(props);

    this.backdropRef = React.createRef();
    this.containerRef = React.createRef();
    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    const { initialMentionedUsers } = this.props;
    this._onChangeDelayer = new delayer(this.onChangeAfterDelay, Delay);
    document.addEventListener('resize', this.onResize);

    const { mentions, value } = this.getDisplayValue(this.props.value, initialMentionedUsers);
    this.setState({
      mentionedUsers: initialMentionedUsers,
      mentions,
      selection: {
        end: value.length,
        direction: 'forward',
        start: value.length,
      },
      textareaWidth: this.containerRef.current.clientWidth,
      value,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const { mentions, value } = this.getDisplayValue(this.props.value);
      this.setState({ mentions, value }, () => {
        const { selection } = this.state;
        // set textarea selection manually to avoid caret moving to the end when editing
        // only do it when the textarea is focused to avoid safari issue.
        const textarea = this.getTextarea();
        if (document.activeElement === textarea) {
          this.textareaRef.current.setSelectionRange(
            selection.start,
            selection.end,
            selection.direction
          );
        }
      });
    }

    if (prevProps.membersOnly !== this.props.membersOnly) {
      const { mentions, value } = this.getDisplayValue(this.props.value);
      this.props.onChange?.(this.getRawValue(value, mentions));
    }
  }

  componentWillUnmount() {
    this._onChangeDelayer.cancel();
    document.removeEventListener('resize', this.onResize);
  }

  focus = () => {
    return this.textareaRef.current.focus();
  };

  setSelectionRange = (start, end, direction = 'none') => {
    this.setState({
      selection: {
        direction,
        end,
        start,
      },
    });
  };

  getTextarea = () => {
    return this.textareaRef.current.getTextarea();
  };

  getValue = () => {
    return this.textareaRef.current.getValue();
  };

  setValue = (value) => {
    this.props.onChange(value);
  };

  splitStringWithMentions(value, mentions) {
    // sort mentions in order of appearence.
    const sortedMentions = [...mentions].sort((mentionA, mentionB) => {
      const mentionAComesFirst = mentionA.start < mentionB.start;
      const mentionBComesFirst = mentionB.start < mentionA.start;
      if (mentionAComesFirst) {
        return -1;
      } else if (mentionBComesFirst) {
        return 1;
      } else {
        return 0;
      }
    });

    // create split positions
    const splitMarkers = [];
    sortedMentions.forEach((mention) => {
      splitMarkers.push({ index: mention.start }, { index: mention.end, mention });
    });
    splitMarkers.push({ index: value.length });

    // split the value into parts
    const parts = splitMarkers.map((splitMarker, splitIdx) => {
      const previousMarker = splitMarkers[splitIdx - 1] ?? { index: 0 };
      const substring = value.substring(previousMarker.index, splitMarker.index);
      return {
        content: substring,
        end: splitMarker.index,
        mention: splitMarker.mention,
        start: previousMarker.index,
      };
    });

    return parts;
  }

  deleteSubstring = ({ mentions, value }, { end, start }) => {
    if (start === end) {
      return { mentions, value };
    }

    const nextMentions = [];
    const nextValue = value.slice(0, start) + value.slice(end);
    mentions.forEach((mention) => {
      const isMentionEdited = mention.start < end && mention.end > start;
      if (isMentionEdited) {
        return;
      } else if (!isMentionEdited && mention.end > end) {
        nextMentions.push({
          ...mention,
          end: mention.end - (end - start),
          start: mention.start - (end - start),
        });
      } else {
        nextMentions.push({ ...mention });
      }
    });
    return { mentions: nextMentions, value: nextValue };
  };

  insertSubstring = ({ mentions, value }, string, index) => {
    if (!string) {
      return { mentions, value };
    }

    const nextMentions = [];
    const nextValue = value.slice(0, index) + string + value.slice(index);
    mentions.forEach((mention) => {
      const isMentionEdited = mention.start < index && mention.end > index;
      if (isMentionEdited) {
        return;
      } else if (mention.start >= index) {
        nextMentions.push({
          ...mention,
          end: mention.end + string.length,
          start: mention.start + string.length,
        });
      } else {
        nextMentions.push({ ...mention });
      }
    });
    return { mentions: nextMentions, value: nextValue };
  };

  getSelectionDirection = (oldSelection, newSelection) => {
    if (newSelection.start < oldSelection.start) {
      return 'backward';
    } else if (newSelection.end > oldSelection.end) {
      return 'forward';
    } else {
      return oldSelection.direction;
    }
  };

  getRawValue = (value, mentions) => {
    const { company, membersOnly } = this.props;
    const parts = this.splitStringWithMentions(value, mentions);
    const companyMembersMap = mapify(company.members, '_id');
    const nextValue = parts.reduce((nextValue, part) => {
      const isCompanyMember = companyMembersMap[part.mention?.user._id];
      if (membersOnly && !isCompanyMember) {
        return nextValue + part.content;
      }

      if (part.mention) {
        return nextValue + getMentionTag(part.mention.user, part.mention.mode);
      }
      return nextValue + part.content;
    }, '');
    return nextValue;
  };

  getDisplayValue = (value, mentionedUsers = this.state.mentionedUsers) => {
    const mentionRegex = /@{[a-z0-9]{24}\|(alias|first_name|full_name)}/gi;
    const mentions = [];

    // In anonymized boards mode, non member _id's are hidden, so we need to compare aliasIDs
    const mentionedUsersWithIDs = mentionedUsers.filter((user) => user._id);
    const userIDMap = mapify(mentionedUsersWithIDs, '_id');
    const userAliasMap = mapify(mentionedUsers, 'aliasID');

    let match = null;
    let body = value;
    // iterate over every mention
    while ((match = mentionRegex.exec(body)) !== null) {
      const [mentionTag] = match;
      const { entityID, type } = getIDFromMentionTag(mentionTag);
      const renderMode = getModeFromMentionTag(mentionTag);
      const userMap = type === RenderTypes.aliasID ? userAliasMap : userIDMap;
      const mentionedUser = userMap[entityID];

      if (!mentionedUser) {
        continue;
      }

      // replace mention tag with user's name
      const mentionText = getMentionDisplayValue(mentionedUser, renderMode) || mentionTag;
      const firstPart = body.slice(0, match.index);
      const secondPart = body.slice(match.index + mentionTag.length);
      body = firstPart + mentionText + secondPart;

      // fix regex index to find all possible mentions
      mentionRegex.lastIndex = firstPart.length + mentionText.length;

      // store mention position
      mentions.push({
        end: match.index + mentionText.length,
        mode: renderMode,
        start: match.index,
        user: mentionedUser,
      });
    }

    return { value: body, mentions };
  };

  handleChange = (
    { newValue, oldValue },
    { newSelection, oldSelection },
    { inputType, newChar, oldClipboard },
    mentions
  ) => {
    if (['insertCompositionText', 'insertReplacementText'].includes(inputType)) {
      // this case handles autocomplete on mobile devices.
      // we have to "move" the mentions when a new string was inserted by
      // chosing one of the predective text suggestions.
      const diff = newSelection.end - oldSelection.end;
      const updatedMentions = mentions
        .map((mention) => {
          const isMentionEdited =
            mention.start < oldSelection.start && mention.end > oldSelection.end;
          if (isMentionEdited) {
            return null;
          }

          if (mention.start < oldSelection.start) {
            return mention;
          }

          return {
            ...mention,
            end: mention.end + diff,
            start: mention.start + diff,
          };
        })
        .filter(Boolean);

      return { mentions: updatedMentions, value: newValue };
    }

    if (['insertText', 'insertFromComposition', 'insertLineBreak'].includes(inputType)) {
      const removedSelectionValue =
        oldSelection.start !== oldSelection.end
          ? this.deleteSubstring(
              { mentions, value: oldValue },
              { end: oldSelection.end, start: oldSelection.start }
            )
          : { mentions, value: oldValue };
      return this.insertSubstring(removedSelectionValue, newChar ?? '\n', oldSelection.start);
    }

    if (inputType === 'deleteContentBackward' && oldSelection.start === oldSelection.end) {
      const caret = oldSelection.start;
      const mentionToEdit = mentions.find((mention) => mention.end === caret);
      if (!mentionToEdit) {
        // remove previous character
        return this.deleteSubstring(
          { mentions, value: oldValue },
          { end: oldSelection.end, start: newSelection.start }
        );
      }

      const hasMultipleNames = mentionToEdit.user.name.split(' ').length > 1;
      const valueWithoutMention = this.deleteSubstring(
        { mentions, value: oldValue },
        { end: mentionToEdit.end, start: mentionToEdit.start }
      );
      if (mentionToEdit.mode === 'full_name' && hasMultipleNames) {
        // reduce mention to first name
        const mentionDisplay = mentionToEdit.user.name.split(' ')[0];
        const { mentions: partialMentions, value: nextValue } = this.insertSubstring(
          valueWithoutMention,
          mentionDisplay,
          mentionToEdit.start
        );

        const adaptedMention = {
          ...mentionToEdit,
          end: mentionToEdit.start + mentionDisplay.length,
          mode: 'first_name',
          start: mentionToEdit.start,
        };

        return {
          mentions: partialMentions.concat(adaptedMention),
          value: nextValue,
          selection: {
            direction: 'forward',
            end: mentionToEdit.start + mentionDisplay.length,
            start: mentionToEdit.start + mentionDisplay.length,
          },
        };
      }

      // remove mention completely
      return {
        ...valueWithoutMention,
        selection: {
          direction: 'forward',
          end: mentionToEdit.start,
          start: mentionToEdit.start,
        },
      };
    }

    if (
      [
        'deleteContentBackward',
        'deleteWordBackward',
        'deleteContent',
        'deleteCompositionText',
      ].includes(inputType)
    ) {
      return this.deleteSubstring(
        { mentions, value: oldValue },
        { end: oldSelection.end, start: newSelection.start }
      );
    }

    if (inputType === 'deleteContentForward') {
      return this.deleteSubstring(
        { mentions, value: oldValue },
        { end: oldSelection.start + 1, start: oldSelection.start }
      );
    }

    if (inputType === 'deleteSoftLineBackward') {
      return this.deleteSubstring(
        { mentions, value: oldValue },
        { end: oldSelection.end, start: newSelection.start }
      );
    }

    if (inputType === 'deleteByCut') {
      const mentionsWithinCut = mentions.filter((mention) => {
        return mention.start >= oldSelection.start && mention.end <= oldSelection.end;
      });
      const cutContent = oldValue.slice(oldSelection.start, oldSelection.end);
      const clipboard = {
        content: cutContent,
        mentions: mentionsWithinCut.map((mention) => ({
          ...mention,
          end: mention.end - oldSelection.start,
          start: mention.start - oldSelection.start,
        })),
      };
      return {
        ...this.deleteSubstring(
          { mentions, value: oldValue },
          { end: oldSelection.end, start: newSelection.start }
        ),
        clipboard,
      };
    }

    if (inputType === 'insertFromPaste') {
      const removedSelectionValue = this.deleteSubstring(
        { mentions, value: oldValue },
        oldSelection
      );
      const copiedText = newValue.slice(oldSelection.start, newSelection.end);
      const { mentions: newMentions, value: updatedValue } = this.insertSubstring(
        removedSelectionValue,
        copiedText,
        oldSelection.start
      );

      const shouldRestoreMentionsFromClipboard =
        oldClipboard?.content === copiedText && oldClipboard?.mentions?.length > 0;
      if (shouldRestoreMentionsFromClipboard) {
        const clipboardMentions = oldClipboard.mentions.map((mention) => ({
          ...mention,
          end: mention.end + oldSelection.start,
          start: mention.start + oldSelection.start,
        }));
        newMentions.push(...clipboardMentions);
      }

      return { mentions: newMentions, value: updatedValue };
    }

    if (inputType === 'historyUndo' && mentions.length) {
      // don't allow undoing content so it doesn't break mentions
      return { mentions, value: oldValue };
    }

    return { mentions, value: newValue };
  };

  onCopy = (e) => {
    const { mentions, selection } = this.state;
    const value = this.getValue();
    const mentionsFullyIncluded = mentions.filter((mention) => {
      return mention.start >= selection.start && mention.end <= selection.end;
    });
    // The reason why we don't process mentions in the clipboard is to
    // allow users to copy the text into other places while keeping the
    // display name.
    const clipboard = {
      content: value.slice(selection.start, selection.end),
      mentions: mentionsFullyIncluded.map((mention) => ({
        ...mention,
        end: mention.end - selection.start,
        start: mention.start - selection.start,
      })),
    };
    this.setState({ clipboard });
  };

  onChange = (e) => {
    const {
      clipboard: oldClipboard,
      mentions,
      selection: oldSelection,
      value: oldValue,
    } = this.state;
    const {
      data: newChar,
      inputType,
      target: { selectionEnd, selectionStart, value: newValue },
    } = e.nativeEvent;

    const newSelection = {
      direction: this.getSelectionDirection(oldSelection, {
        end: selectionEnd,
        start: selectionStart,
      }),
      end: selectionEnd,
      start: selectionStart,
    };
    const {
      clipboard: nextClipboard,
      mentions: nextMentions,
      selection: nextSelection,
      value: nextValue,
    } = this.handleChange(
      { newValue, oldValue },
      { newSelection, oldSelection },
      { inputType, newChar, oldClipboard },
      mentions
    );

    this._onChangeDelayer.cancel();
    this.props.onChange && this.props.onChange(this.getRawValue(nextValue, nextMentions));

    if (selectionStart !== selectionEnd || !oldValue) {
      this.setState({
        ...(nextClipboard && { clipboard: nextClipboard }),
        mentionPrefix: null,
        mentions: nextMentions,
        selectedSuggestionIndex: 0,
        selection: nextSelection ?? newSelection,
        value: nextValue,
      });
      return;
    }

    this.setState({
      ...(nextClipboard && { clipboard: nextClipboard }),
      cursorPosition: selectionStart,
      mentions: nextMentions,
      selection: nextSelection ?? newSelection,
      value: nextValue,
    });

    this._onChangeDelayer.callAfterDelay(e);
  };

  onChangeAfterDelay = async (e) => {
    const { cursorPosition, value } = this.state;
    const { board, loadSuggestions } = this.props;

    for (var i = 0; i < MaxLookback; i++) {
      var position = cursorPosition - 1 - i;
      if (position < 0 || value[position] === '@') {
        break;
      }
    }

    if (position < 0 || value[position] !== '@') {
      this.setState({
        mentionPrefix: null,
        selectedSuggestionIndex: 0,
      });
      return;
    }

    const positionBefore = position - 1;
    if (positionBefore >= 0 && value[positionBefore].match(/[a-z0-9]/i)) {
      this.setState({
        mentionPrefix: null,
        selectedSuggestionIndex: 0,
      });
      return;
    }

    const mentionStart = position + 1;
    const mentionPrefix = value.substr(mentionStart, cursorPosition - mentionStart);
    await loadSuggestions(board._id, mentionPrefix, this.props.membersOnly);

    this.setState({
      mentionPrefix,
      selectedSuggestionIndex: 0,
    });
  };

  onResize = (e) => {
    this.setState({ textareaWidth: this.containerRef.current.clientWidth });
  };

  onSelection = (e) => {
    const {
      selection: { end, start },
    } = this.state;
    const textarea = this.getTextarea();
    const focusedElement = document.activeElement;
    const isSelectionChanged = textarea.selectionStart !== start || textarea.selectionEnd !== end;

    if (focusedElement.isSameNode(textarea) && isSelectionChanged) {
      const selection = {
        end: textarea.selectionEnd,
        start: textarea.selectionStart,
      };
      this.setState((state) => {
        return {
          selection: {
            ...selection,
            direction: this.getSelectionDirection(state.selection, selection),
          },
        };
      });
    }
  };

  onKeyDown = (e) => {
    this.props.onKeyDown && this.props.onKeyDown(e);

    const { mentionPrefix, selectedSuggestionIndex } = this.state;
    const { board, membersOnly, mentionSuggestions } = this.props;
    const mentionQueryKey = getMentionQueryKey(board._id, mentionPrefix, membersOnly);
    const suggestions = mentionSuggestions[mentionQueryKey];
    if (!suggestions || !suggestions.items || !suggestions.items.length) {
      return;
    }

    const { DownArrow, UpArrow, Enter, Tab } = KeyCodes;
    if (
      e.keyCode !== DownArrow &&
      e.keyCode !== UpArrow &&
      e.keyCode !== Enter &&
      e.keyCode !== Tab
    ) {
      return;
    }

    e.preventDefault();

    if (e.keyCode === DownArrow) {
      this.setState({
        selectedSuggestionIndex: mod(selectedSuggestionIndex + 1, suggestions.items.length),
      });
    } else if (e.keyCode === UpArrow) {
      this.setState({
        selectedSuggestionIndex: mod(selectedSuggestionIndex - 1, suggestions.items.length),
      });
    } else if (e.keyCode === Enter || e.keyCode === Tab) {
      this.onSuggestionSelected(suggestions.items[selectedSuggestionIndex]);
    }
  };

  onSuggestionSelected = (suggestion) => {
    const { board, company } = this.props;
    const { cursorPosition, mentionPrefix, mentions } = this.state;
    const { viewerIsMember } = company;
    const mentionString = suggestion.name;

    const replaceEnd = cursorPosition;
    const replaceStart = replaceEnd - (mentionPrefix.length + 1);

    const textarea = this.textareaRef.current.getTextarea();
    const value = textarea.value;
    const nextValue = value.substr(0, replaceStart) + mentionString + value.substr(replaceEnd);
    const delta = mentionString.length - (replaceEnd - replaceStart);
    const isSuggestionAdmin = company.members.some((member) => member._id === suggestion._id);
    const mentionMode = board.settings.privateAuthors
      ? isSuggestionAdmin || viewerIsMember
        ? RenderValues.full_name
        : RenderValues.alias
      : RenderValues.full_name;
    const newMention = {
      end: replaceStart + mentionString.length,
      mode: mentionMode,
      start: replaceStart,
      user: suggestion,
    };

    const nextMentions = mentions
      .map((mention) => {
        if (mention.start > replaceStart) {
          return {
            ...mention,
            end: mention.end + delta,
            start: mention.start + delta,
          };
        }
        return mention;
      })
      .concat(newMention);

    this.setState(
      (state) => ({
        mentionedUsers: state.mentionedUsers.concat(suggestion),
        mentionPrefix: null,
        mentions: nextMentions,
        selectedSuggestionIndex: 0,
        selection: {
          direction: 'forward',
          end: replaceStart + mentionString.length,
          start: replaceStart + mentionString.length,
        },
        value: nextValue,
      }),
      () => {
        this.setValue(this.getRawValue(nextValue, nextMentions));
        this.textareaRef.current.focus();
      }
    );
  };

  renderSuggestions() {
    const { board, company } = this.props;
    const { mentionPrefix, selectedSuggestionIndex, textareaWidth } = this.state;

    const userMentionsDisabled =
      company.featureAllowlist.includes('disable-mentions') && !company.viewerIsMember;
    if (!mentionPrefix || userMentionsDisabled) {
      return null;
    }

    const { membersOnly, mentionSuggestions, portalPosition } = this.props;
    const mentionQueryKey = getMentionQueryKey(board._id, mentionPrefix, membersOnly);
    const suggestions = mentionSuggestions[mentionQueryKey];
    if (!suggestions || !suggestions.items || !suggestions.items.length) {
      return null;
    }

    const items = suggestions.items.map((suggestion, i) => {
      return (
        <Tappable
          key={suggestion.aliasID}
          onTap={() => this.onSuggestionSelected(suggestion)}
          stopPropagation={true}>
          <div className={classnames('suggestion', { selected: i === selectedSuggestionIndex })}>
            <UserLockup showProfile={false} user={suggestion} />
          </div>
        </Tappable>
      );
    });

    return (
      <Portal
        align="start"
        className="dropdownPortal mentionsTextarea"
        position={portalPosition}
        relativeToRef={this.containerRef}>
        <div className="suggestions" style={{ width: textareaWidth }}>
          {items}
        </div>
      </Portal>
    );
  }

  renderHighlights(value) {
    const { mentions } = this.state;
    const { tintColor } = this.props;
    const parts = this.splitStringWithMentions(value, mentions);

    return parts.map((part, idx) => {
      if (part.mention) {
        const mentionRGBColor = hexToRGB(tintColor);
        const backgroundStyle = mentionRGBColor && {
          backgroundColor: `rgba(${mentionRGBColor.join(',')}, 0.15)`,
        };
        return (
          <mark
            className="highlight"
            key={`${part.mention.user.aliasID}-${idx}`}
            style={backgroundStyle}>
            {part.content}
          </mark>
        );
      }
      return <mark key={idx}>{part.content}</mark>;
    });
  }

  render() {
    const { value } = this.state;
    const textareaProps = { ...this.props };
    Object.keys(MentionsTextarea.propTypes).forEach((propType) => {
      delete textareaProps[propType];
    });

    return (
      <div className="mentionsTextarea">
        <div className="textareaWithHighlights" ref={this.containerRef}>
          <div className="backdrop" ref={this.backdropRef}>
            <div className="highlights">{this.renderHighlights(value)}</div>
          </div>
          <AutoResizeTextarea
            {...textareaProps}
            ref={this.textareaRef}
            onChange={this.onChange}
            onCopy={this.onCopy}
            onKeyDown={this.onKeyDown}
            onSelect={this.onSelection}
            value={value}
          />
        </div>
        {this.renderSuggestions()}
      </div>
    );
  }
}

export default compose(
  connect(
    (state, props) => ({ mentionSuggestions: state.mentionSuggestions }),
    (dispatch) => ({
      loadSuggestions: (boardID, mentionPrefix, membersOnly) => {
        return Promise.all([dispatch(loadSuggestions(boardID, mentionPrefix, membersOnly))]);
      },
    }),
    { withRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      tintColor: TintColorContext,
    },
    { forwardRef: true }
  )
)(MentionsTextarea);

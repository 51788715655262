export default function abbreviateNumber(number: number, toFixed: number = 1): string {
  if (number < 1000) {
    return String(Math.round(number));
  }

  const fraction = number / 1000;
  if (Math.round(fraction) < 1000) {
    return fraction.toFixed(toFixed) + 'k';
  }

  return (number / 1000000).toFixed(toFixed) + 'm';
}

import React, { type ComponentType, forwardRef, useContext, useRef } from 'react';

import { AccessContext, type AccessStatus } from 'common/containers/AccessContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';

import type { Company } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

type ComponentProps = {
  [key: string]: any;
};

type Condition = (company: Company, viewer: Viewer) => AccessStatus;

type Options = {
  forwardRef?: boolean;
};

/*
 * This HoC is intended to wrap components for routes that require a certain
 * condition to be met before rendering. If the condition is not met, the user
 * is redirected to the fallback route.
 *
 * The condition is a function that must return an AccessStatus object. As we gate
 * more routes with this HoC we should extend the AccessStatus object to include more
 * reasons for failure and corresponding data types.
 */
const withAccessControl = <Props extends object>(
  condition: Condition,
  fallback: string,
  options?: Options
) => {
  return (Component: ComponentType<Props>) => {
    const WrappedComponent = forwardRef<any, Props & ComponentProps>((props, ref) => {
      const { setAccessStatus } = useContext(AccessContext);
      const company = useContext<Company>(CompanyContext);
      const viewer = useContext<Viewer>(ViewerContext);
      const router = useContext(RouterContext);

      const wasAccessDeniedRef = useRef(false);

      if (!company || !viewer) {
        return null;
      }

      if (wasAccessDeniedRef.current) {
        // TODO redirect to a generic error page
        return null;
      }

      const status = condition(company, viewer);

      if (status.result === 'failure') {
        // update the ref in case fallback matches the current route and we loop
        wasAccessDeniedRef.current = true;
        setAccessStatus(status);
        router.replace(fallback);
        return null;
      }

      const childProps = {
        ...props,
        ...(options?.forwardRef &&
          ref && {
            ref,
          }),
      };

      return <Component {...(childProps as Props)} />;
    });

    const displayName = Component.displayName || Component.name || Component.displayName;
    WrappedComponent.displayName = `withAccessControl(${displayName})`;

    return WrappedComponent;
  };
};

export default withAccessControl;

import validateInput from 'common/validateInput';

export const CustomFieldTypes = {
  integer: {
    hasOptions: false,
    label: 'Number',
    name: 'integer',
    optionsLimit: 0,
    validator: validateInput.primitives.integer,
    postTransform: String,
  },
  multilineText: {
    hasOptions: false,
    label: 'Multiline text',
    name: 'multilineText',
    optionsLimit: 0,
    validator: validateInput.string,
    postTransform: null,
  },
  multiselect: {
    hasOptions: true,
    label: 'Multiselect Dropdown',
    name: 'multiselect',
    optionsLimit: 25,
    validator: validateInput.arrayOf(validateInput.customPostField.option),
    postTransform: null,
  },
  dropdown: {
    hasOptions: true,
    label: 'Dropdown',
    name: 'dropdown',
    optionsLimit: 25,
    validator: validateInput.customPostField.option,
    postTransform: null,
  },
  text: {
    hasOptions: false,
    label: 'Single-line text',
    name: 'text',
    optionsLimit: 0,
    validator: validateInput.string,
    postTransform: null,
  },
};

export const CustomFieldTypesList = Object.values(CustomFieldTypes).map((type) => type.name);

export const Errors = {
  extraFields: 'too many fields',
  incompatibleTypes: 'invalid field types',
  missingFields: 'missing required fields',
};

export enum CustomFieldFilterOperators {
  contains = 'contains',
  doesNotEqual = 'doesNotEqual',
  equals = 'equals',
  equalsBool = 'equalsBool',
  equalsOneOf = 'equalsOneOf',
  greaterThan = 'greaterThan',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  lessThan = 'lessThan',
  notContains = 'notContains',
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import NoteBox from 'common/common/NoteBox';
import Toggle from 'common/common/Toggle';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminGitHubSettings.scss';

class AdminGitHubSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      github: PropTypes.shape({
        installationID: PropTypes.string,
      }),
      githubRule: PropTypes.shape({
        active: PropTypes.bool,
        allLinkedIssues: PropTypes.bool,
        cannyStatus: PropTypes.string,
        shouldNotifyVoters: PropTypes.bool,
      }),
      subdomain: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
    router: PropTypes.object,
  };

  state = {
    error: null,
    installationID: this.props.location.query.installationID,
    githubRule: this.props.company.githubRule || {
      active: false,
      allLinkedIssues: false,
      cannyStatus: 'open',
      shouldNotifyVoters: true,
    },
  };

  componentDidMount() {
    const { installationID } = this.state;
    if (!installationID) {
      return;
    }

    const { location, router } = this.props;
    router.replace({
      pathname: location.pathname,
    });

    AJAX.post(
      '/api/github/install',
      {
        installationID,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            installationID: null,
            error: 'Something went wrong, please try again later',
          });
        } else {
          this.props.reloadCompany().then(() => {
            this.setState({ installationID: null });
          });
        }
      }
    );
  }

  onChangeRule(key, value) {
    this.setState({
      githubRule: {
        ...this.state.githubRule,
        [key]: value,
      },
    });
  }

  onSave = () => {
    this.setState({
      saving: true,
    });

    const { active, allLinkedIssues, cannyStatus, shouldNotifyVoters } = this.state.githubRule;
    AJAX.post(
      '/api/github/upsertRule',
      {
        active,
        allLinkedIssues,
        cannyStatus,
        shouldNotifyVoters,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            saving: false,
          });
        } else {
          this.props.reloadCompany().then(() => {
            this.setState({
              error: null,
              saving: false,
              showComposer: false,
            });
          });
        }
      }
    );
  };

  renderInstallButton() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
    } = this.props;
    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    return (
      <a
        href="https://github.com/apps/canny"
        rel="noopener"
        target="_blank"
        className="buttonContainer">
        <Button value="Install GitHub" />
      </a>
    );
  }

  renderContents() {
    const { company } = this.props;
    const { installationID } = this.state;
    const installed = company.github && company.github.installationID;

    if (installationID) {
      return this.renderInstalling();
    }

    if (!installed) {
      return this.renderInstallButton();
    }

    return this.renderGitHubRule();
  }

  renderGitHubRule() {
    const {
      company: { statuses },
    } = this.props;
    const { active, allLinkedIssues, cannyStatus, shouldNotifyVoters } = this.state.githubRule;

    const anyAllSelectedName = allLinkedIssues ? 'all' : 'any';
    const shouldNotifyVotersSelectedName = shouldNotifyVoters ? 'notify' : 'do not notify';
    const cannyStatusOptions = statuses.map((status) => {
      return {
        name: status.name,
        render: <PostStatus showOpen={true} status={status.name} />,
      };
    });

    return (
      <div className="githubRule">
        <div className="rule">
          <Toggle onToggle={this.onChangeRule.bind(this, 'active')} value={active} />
          <div className="composerContainer">
            <div className="composer">
              When
              <ControlledDropdown
                className="anyAllDropdown"
                dropdownClassName="adminGithubSettingsDropdown"
                selectedName={anyAllSelectedName}
                onChange={(name) => this.onChangeRule('allLinkedIssues', name === 'all')}
                options={[
                  {
                    name: 'any',
                    render: 'any',
                  },
                  {
                    name: 'all',
                    render: 'all',
                  },
                ]}
              />
              {allLinkedIssues
                ? 'linked GitHub issues are closed'
                : 'linked GitHub issue is closed'}
              , change all linked Canny posts to
              <ControlledDropdown
                className="cannyStatusDropdown"
                dropdownClassName="adminGithubSettingsDropdown"
                selectedName={cannyStatus}
                onChange={this.onChangeRule.bind(this, 'cannyStatus')}
                options={cannyStatusOptions}
              />
              , and
              <ControlledDropdown
                className="notifyDropdown"
                selectedName={shouldNotifyVotersSelectedName}
                onChange={(name) => this.onChangeRule('shouldNotifyVoters', name === 'notify')}
                options={[
                  {
                    name: 'notify',
                    render: 'notify',
                  },
                  {
                    name: 'do not notify',
                    render: 'do not notify',
                  },
                ]}
              />
              voters.
            </div>
          </div>
        </div>
        <div className="footer">{this.renderSaveButton()}</div>

        <NoteBox title="Note" colorScheme="note" className="withMarginTop">
          Disconnecting Github from Canny must be completed from your Github
          organization&nbsp;settings.
          <br />
          <a href="https://help.canny.io/en/articles/3481076-github-integration">
            View our help article
          </a>{' '}
          for more&nbsp;details.
        </NoteBox>
      </div>
    );
  }

  renderInstalling() {
    return (
      <div className="spinnerContainer">
        <div>
          <Spinner />
        </div>
        <div className="installing">Installing GitHub</div>
      </div>
    );
  }

  renderSaveButton() {
    const { githubRule } = this.props.company;
    const { githubRule: editedGitHubRule } = this.state;
    const canSave = JSON.stringify(githubRule) !== JSON.stringify(editedGitHubRule);
    return (
      <Button
        buttonType="cannyButton"
        className="saveButton"
        disabled={!canSave}
        loading={this.state.saving}
        onTap={this.onSave}
        tint={true}
        value="Save"
      />
    );
  }

  render() {
    return (
      <div className="adminGitHubSettings">
        <Helmet title="GitHub Integration | Canny" />
        <div className="settingsHeading">GitHub Integration</div>
        <div className="content">
          <div className="text">
            Canny for GitHub lets you link Canny posts with GitHub issues and keep statuses in sync.
            This helps your engineering and product management teams communicate priorities.
          </div>
          {this.renderContents()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.github), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminGitHubSettings);

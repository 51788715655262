import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { LoadStatus } from 'common/constants/files';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import FileRenderer from 'common/file/FileRenderer';
import { createFiles, createImageFiles } from 'common/file/utils/createFiles';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import getValidFileURLs from 'common/file/utils/getValidFileURLs';
import uploadFile from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import FormField from 'common/FormField';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import UploadFileButton from 'common/inputs/UploadFileButton';
import CustomPostFieldInput, { getErrorMessages } from 'common/post/CustomPostFieldInput';
import Strings from 'common/Strings';
import mapify from 'common/util/mapify';
import nbspLastSpace from 'common/util/nbspLastSpace';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

class EditPostForm extends Component {
  static propTypes = {
    board: PropTypes.shape({
      strings: PropTypes.shape({
        detailsField: PropTypes.string,
        detailsPlaceholder: PropTypes.string,
        titleField: PropTypes.string,
        titlePlaceholder: PropTypes.string,
      }),
    }).isRequired,
    post: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      details: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }))
        .isRequired,
      imageURLs: PropTypes.arrayOf(PropTypes.string).isRequired,
      title: PropTypes.string.isRequired,
    }),
    showCustomPostFields: PropTypes.bool,
    tags: PropTypes.array,
  };

  state = {
    customFieldValuesMap: {},
    error: null,
    erroredFields: [],
    files: [...createFiles(this.props.post.files), ...createImageFiles(this.props.post.imageURLs)],
    submitting: false,
  };

  constructor(props, context) {
    super(props, context);

    this.detailsRef = React.createRef();
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    const { company, post, showCustomPostFields } = this.props;
    if (!showCustomPostFields || !post || !company) {
      return;
    }

    const doesPlanSupport = company.features?.customPostFields ?? false;
    if (!doesPlanSupport) {
      return;
    }

    // load default custom post fields
    this.setState({
      customFieldValuesMap: post.customPostFields.reduce((map, field) => {
        if (!field.value) {
          return map;
        }

        return { ...map, [field._id]: field.value };
      }, {}),
    });
  }

  onCustomFieldChange = (value, boardField) => {
    this.setState((state) => ({
      customFieldValuesMap: {
        ...state.customFieldValuesMap,
        [boardField.customPostFieldID]: value,
      },
    }));
  };

  onEdit = () => {
    const { board, showCustomPostFields } = this.props;
    const { customFieldValuesMap } = this.state;
    const title = this.titleRef.current.getValue().trim();
    const details = this.detailsRef.current.getValue().trim();
    const validFileURLs = getValidFileURLs(this.state.files);
    const imageURLs = JSON.stringify(validFileURLs.imageURLs);
    const fileURLs = JSON.stringify(validFileURLs.nonImageFileURLs);

    if (!validateInput.postTitle(title)) {
      this.setState({
        error: 'Please enter a title between 0 and 400 characters.',
      });
      return;
    } else if (!validateInput.postDetails(details)) {
      this.setState({
        error: 'Please enter details between 0 and 5000 characters.',
      });
      return;
    } else if (!validateInput.postImageURLs(imageURLs)) {
      this.setState({
        error: Strings.miscError,
      });
      return;
    } else if (!validateInput.publicNonImageFileURLs(fileURLs)) {
      this.setState({
        error: 'Files were not uploaded correctly.',
      });
      return;
    }

    const hasCustomFields = board.boardFields.length;
    if (showCustomPostFields && hasCustomFields) {
      const { erroredFields, errorMessages } = getErrorMessages(
        customFieldValuesMap,
        board.boardFields
      );
      if (errorMessages.length) {
        const error = (
          <>
            <p>There are some issues with your form:</p>
            <ul>
              {errorMessages.map((message) => (
                <li key={message}>{nbspLastSpace(message)}</li>
              ))}
            </ul>
          </>
        );

        this.setState({ error, erroredFields });
        return;
      }
    }

    this.setState({
      error: null,
      erroredFields: [],
      submitting: true,
    });

    AJAX.post(
      '/api/posts/edit',
      {
        customFieldValuesMap,
        details,
        fileURLs,
        imageURLs,
        postID: this.props.post._id,
        title,
      },
      (response) => {
        this.setState({
          submitting: false,
        });

        var responseData;
        try {
          responseData = JSON.parse(response);
        } catch (e) {
          responseData = { error: 'server error' };
        }

        if (!responseData.post) {
          this.setState({
            error:
              responseData.error === 'spam'
                ? `Our system identifies parts of this post as spam. Please, try with different content.`
                : Strings.miscError,
          });
          return;
        }

        // Post successfully edited.
        // 1. Reload post
        this.props.reloadPost(this.props.post);

        // 2. Callback
        this.props.onPostEdited(responseData.post);
      }
    );
  };

  onFile = async (file) => {
    const { viewer } = this.props;

    await uploadFile({
      file,
      viewer,
      onFileError: this.onFileError,
      onFileUploading: this.onFileUploading,
      onFileUploaded: this.onFileUploaded,
    });
  };

  onFileError = (file, error) => {
    this.setState((state) => ({
      error,
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFileUploading = (file) => {
    this.setState((state) => ({
      files: [...state.files, file],
    }));
  };

  onFileUploaded = (file) => {
    this.setState((state) => ({
      files: state.files.map((f) => (f.uniqueID === file.uniqueID ? file : f)),
    }));
  };

  onFileRemoved = (file) => {
    this.setState((state) => ({
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  focusTitle = () => {
    this.titleRef.current && this.titleRef.current.focus();
  };

  renderCancelButton() {
    const { onCancel } = this.props;
    if (!onCancel) {
      return null;
    }

    return <Button buttonType="ghostButton" onTap={onCancel} value="Cancel" />;
  }

  renderDetailsInput() {
    const {
      board: { strings },
      post,
    } = this.props;
    return (
      <FormField label={strings.detailsField}>
        <AutoResizeTextarea
          ref={this.detailsRef}
          defaultValue={post.details}
          minRows={3}
          onChange={this.props.onDetailsChange}
          placeholder={strings.detailsPlaceholder}
        />
      </FormField>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderCustomFields() {
    const { board, post, showCustomPostFields } = this.props;
    const { erroredFields } = this.state;
    if (!showCustomPostFields) {
      return null;
    }

    const postFieldMap = mapify(post.customPostFields, '_id');
    const erroredFieldsMap = mapify(erroredFields, '_id');

    return board.boardFields.map((boardField) => {
      const label = boardField.required ? `${boardField.label} *` : boardField.label;
      const value = postFieldMap[boardField.customPostFieldID]?.value;
      return (
        <FormField label={label} key={boardField._id}>
          <CustomPostFieldInput
            errored={!!erroredFieldsMap[boardField._id]}
            postField={{ ...boardField, value }}
            onChange={this.onCustomFieldChange}
          />
        </FormField>
      );
    });
  }

  renderTitleInput() {
    const {
      board: { strings },
      post,
    } = this.props;
    return (
      <FormField label={strings.titleField}>
        <TextInput
          ref={this.titleRef}
          defaultValue={post.title}
          placeholder={strings.titlePlaceholder}
        />
      </FormField>
    );
  }

  render() {
    const { files } = this.state;
    const className = classnames('createPostForm', this.props.className);
    const filesUploading = files.some((file) => file.uploadStatus !== LoadStatus.loaded);
    const acceptedMimeTypes = getAcceptedMimeTypes(this.props.company);

    return (
      <Form
        acceptedFileTypes={acceptedMimeTypes}
        addEventsToDocument={false}
        allowFileUpload={true}
        className={className}
        disableSubmit={this.state.submitting || filesUploading}
        onFile={this.onFile}
        onSubmit={this.onEdit}>
        {this.renderTitleInput()}
        {this.renderDetailsInput()}
        {this.renderCustomFields()}
        <FileRenderer
          className="postFormFileRenderer"
          allowRemove={true}
          files={this.state.files}
          onFileRemoved={this.onFileRemoved}
        />
        <div className="formButtons">
          <UploadFileButton
            acceptedMimeTypes={acceptedMimeTypes}
            defaultStyle={false}
            onFileError={this.onFileError}
            onFileUploading={this.onFileUploading}
            onFileUploaded={this.onFileUploaded}
          />
          <div className="rightButtons">
            {this.renderCancelButton()}
            <Button
              formButton={true}
              disabled={filesUploading}
              loading={this.state.submitting}
              tint={true}
              value="Save"
            />
          </div>
        </div>
        {this.renderError()}
      </Form>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => dispatch(reloadPost(post)),
  })),
  withContexts({ company: CompanyContext, viewer: ViewerContext }, { forwardRef: true })
)(EditPostForm);

import React from 'react';

import classNames from 'classnames';

import Label from 'common/ui/common/Label';
import SwitchV2, { type Props as SwitchProps } from 'common/ui/SwitchV2';

interface Props extends Omit<SwitchProps, 'aria-label'> {
  children: string;
}

const SwitchWithLabel = ({ children, size, className, ...props }: Props) => {
  return (
    <Label
      className={classNames('switchWithLabel', className)}
      label={children}
      labelPlacement="left"
      size={size}>
      <SwitchV2 size={size} {...props} aria-label={children} />
    </Label>
  );
};

export default SwitchWithLabel;

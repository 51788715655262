import React, { useContext } from 'react';

import classnames from 'classnames';
import { Save } from 'lucide-react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ModalPortal from 'common/modals/ModalPortal';
import Button from 'common/ui/ButtonV2';
import areArraysEqual from 'common/util/areArraysEqual';
import hasPermission from 'common/util/hasPermission';
import queryString from 'common/util/queryString';

import AdminRoadmapViewSettingsSection from './AdminRoadmapViewSettingsSection';
import { type ViewDraft, mapViewsToOptions } from './SavedViewUtils';
import SettingsModalCreateViewHeader from './SettingsModalCreateViewHeader';
import SettingsModalDefaultHeader from './SettingsModalDefaultHeader';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { DescriptionColumn, Factor, Roadmap } from 'common/api/endpoints/roadmaps';
import type { Board as PlainBoard } from 'common/api/resources/board';
import type { Option } from 'common/ui/common/select/SelectCommon';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapViewSettingsModal.scss';

type Props = {
  boards: Board[];
  creatingView: boolean;
  createNewView: (viewName: string) => Promise<void>;
  distinctBoards: PlainBoard[];
  groupBy: string | undefined;
  hiddenColumnIDs: string[];
  isVisible: boolean;
  onClose: () => void;
  roadmap: Roadmap;
  selectedView?: Option;
  setSelectedView: (view: Option | undefined) => void;
  setCreatingView: (creating: boolean) => void;
  setUpdatingView: (updating: boolean) => void;
  setViewDraft: (draft: ViewDraft) => void;
  setViewName: (name: string) => void;
  showShareModal: () => void;
  updateServerSettings: (
    hiddenColumnIDs: string[],
    updatedGroupBy: string | undefined,
    viewID: string | null,
    columnWidths: Record<string, number>
  ) => void;
  updateViewDetails: () => void;
  updatingView: boolean;
  viewDraft: ViewDraft | null;
  viewName: string;
};

export type Column = {
  columnID: Factor['_id'];
  hidden: boolean;
};

const defaultFieldOrder = ['eta', 'segment', 'owner', 'status', 'category', 'tags'];

const AdminRoadmapViewSettingsModal = ({
  boards,
  creatingView,
  createNewView,
  distinctBoards,
  groupBy,
  hiddenColumnIDs,
  isVisible,
  onClose,
  roadmap,
  selectedView,
  setSelectedView,
  setCreatingView,
  setUpdatingView,
  setViewDraft,
  setViewName,
  showShareModal,
  updateServerSettings,
  updateViewDetails,
  updatingView,
  viewDraft,
  viewName,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const router = useContext(RouterContext);
  const viewer = useContext(ViewerContext);
  const location = useContext(LocationContext);

  const viewOptions = mapViewsToOptions(roadmap.savedViews ?? []);

  const updateColumns = async (columns: Column[]) => {
    // update visibility settings locally first
    const hiddenColumnIDsSet = new Set(
      hiddenColumnIDs
        // remove "unhidden" columns from the hiddenColumnIDs list
        .filter((hiddenColumnID) => {
          const column = columns.find((column) => column.columnID === hiddenColumnID);
          if (!column) {
            return true;
          }

          return column.hidden;
        })
        // add new hidden columns to the hiddenColumnIDs list
        .concat(columns.filter((column) => column.hidden).map((column) => column.columnID))
    );

    if ((creatingView || selectedView) && viewDraft) {
      setViewDraft({
        ...viewDraft,
        hiddenColumnIDs: Array.from(hiddenColumnIDsSet),
      });
      return;
    }

    const currentViewID = roadmap.settings?.lastActiveFilters?.viewID ?? null;

    updateServerSettings(
      Array.from(hiddenColumnIDsSet),
      groupBy,
      selectedView ? currentViewID : null,
      roadmap?.settings?.columnWidths ?? {}
    );
  };

  const sortDefaultFields = (fields: DescriptionColumn[]) => {
    return [...fields].sort((a, b) => {
      if (
        defaultFieldOrder.indexOf(a.name.toLowerCase()) <
        defaultFieldOrder.indexOf(b.name.toLowerCase())
      ) {
        return -1;
      }
      if (
        defaultFieldOrder.indexOf(a.name.toLowerCase()) >
        defaultFieldOrder.indexOf(b.name.toLowerCase())
      ) {
        return 1;
      }

      return 0;
    });
  };

  const saveViewInformation = async () => {
    if (creatingView && !viewName) {
      showToast('A view title is required.', ToastTypes.error);
      return;
    }

    creatingView ? await createNewView(viewName) : await updateViewDetails();
  };

  const updateView = async (selectedView: Option | undefined) => {
    setSelectedView(selectedView);
    await updateServerSettings(
      roadmap?.settings?.hiddenColumnIDs,
      groupBy,
      selectedView?.value ?? null,
      roadmap?.settings?.columnWidths ?? {}
    );

    const savedView = roadmap.savedViews.find((view) => view._id === selectedView?.value);

    setViewDraft({
      filterQuery: savedView?.filterQuery || '',
      hiddenColumnIDs: savedView?.hiddenColumnIDs || hiddenColumnIDs,
    });

    const savedFilterQuery = savedView?.filterQuery ? queryString.parse(savedView.filterQuery) : {};
    const currentFilterQuery = !selectedView ? location.query : {};

    router.replace({
      pathname: `/admin/roadmap/${roadmap.urlName}`,
      query: {
        ...currentFilterQuery,
        ...savedFilterQuery,
      },
    });
  };

  const hiddenFactors = hiddenColumnIDs.filter((columnID) => {
    return roadmap.factors.some((factor) => factor._id === columnID);
  });

  const hiddenDescriptionColumns = hiddenColumnIDs.filter((columnID) => {
    return roadmap.descriptionColumns.some(
      (descriptionColumn) => descriptionColumn._id === columnID
    );
  });

  const defaultFields = sortDefaultFields(
    roadmap.descriptionColumns.filter((column) => column.fieldType === 'default')
  );

  const customFields = roadmap.descriptionColumns.filter(
    (column) => column.fieldType === 'customField'
  );

  const combinedCustomFieldsAndFactors = [...customFields, ...roadmap.factors].sort(
    (a, b) => new Date(a.created).valueOf() - new Date(b.created).valueOf()
  );

  if (!roadmap) {
    return null;
  }

  const hasViewChanged = () => {
    if (!selectedView) {
      return false;
    }

    const currentSelectedView = roadmap?.savedViews.find(
      (savedView) => selectedView?.value === savedView._id
    );

    const haveChanged =
      !areArraysEqual(
        viewDraft?.hiddenColumnIDs || [],
        currentSelectedView?.hiddenColumnIDs || []
      ) || viewDraft?.filterQuery !== currentSelectedView?.filterQuery;

    if (!haveChanged) {
      setUpdatingView(false);
    }

    return haveChanged;
  };

  return (
    <ModalPortal
      allowBodyScroll={true}
      className={classnames('adminRoadmapPortal', 'adminRoadmapViewSettingsModal', {
        modalVisible: isVisible,
        nuxVisible: !company?.nux?.prioritizationOverview,
      })}
      onClose={onClose}>
      <aside className="modalContents">
        <header className="modalHeader">
          {!creatingView ? (
            <SettingsModalDefaultHeader
              onClose={onClose}
              renderShareButton={hasPermission('manageRoadmap', company, viewer)}
              selectedView={selectedView}
              setCreatingView={setCreatingView}
              setSelectedView={updateView}
              showShareModal={showShareModal}
              viewOptions={viewOptions}
            />
          ) : (
            <SettingsModalCreateViewHeader
              onClose={onClose}
              setCreatingView={setCreatingView}
              updateViewName={setViewName}
              viewName={viewName}
            />
          )}
        </header>
        <div
          className={classnames('sectionContainer', {
            sectionContainerCreatingView: creatingView,
          })}>
          <AdminRoadmapViewSettingsSection
            sectionHeader="Default fields"
            boards={boards}
            columns={defaultFields}
            distinctBoards={distinctBoards}
            hiddenDescriptionColumns={hiddenDescriptionColumns}
            hiddenFactors={hiddenFactors}
            updateColumns={updateColumns}
          />
          {combinedCustomFieldsAndFactors.length ? (
            <AdminRoadmapViewSettingsSection
              sectionHeader="Custom fields"
              boards={boards}
              columns={combinedCustomFieldsAndFactors}
              distinctBoards={distinctBoards}
              hiddenDescriptionColumns={hiddenDescriptionColumns}
              hiddenFactors={hiddenFactors}
              updateColumns={updateColumns}
            />
          ) : null}
        </div>
        {creatingView || hasViewChanged() ? (
          <div className="modalFooter">
            <Button
              loading={updatingView}
              size="medium"
              color="primary"
              startIcon={Save}
              variant="plain"
              onClick={saveViewInformation}>
              {creatingView ? 'Save view' : 'Update view'}
            </Button>
          </div>
        ) : null}
      </aside>
    </ModalPortal>
  );
};

export default AdminRoadmapViewSettingsModal;

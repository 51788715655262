import React, { Component } from 'react';

import PropTypes from 'prop-types';

export const GetPostLinkContext = React.createContext();

export default class PostLinkContainer extends Component {
  static propTypes = {
    getPostLink: PropTypes.func.isRequired,
  };

  render() {
    return (
      <GetPostLinkContext.Provider value={this.props.getPostLink}>
        {this.props.children}
      </GetPostLinkContext.Provider>
    );
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Portal from 'common/common/Portal';
import { KeyNames } from 'common/KeyCodes';

import Link from './Link';
import Tappable from './Tappable';

import 'css/components/_Dropdown.scss';

export default class ControlledDropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownClassName: PropTypes.string,
    minWidth: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        render: PropTypes.node.isRequired, // renderable
      })
    ).isRequired,
    placeholder: PropTypes.string,
    selectionStyle: PropTypes.object,
    selectedName: PropTypes.string,
    splitStyle: PropTypes.bool,
    withKeyboardNavigation: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    minWidth: 0,
    placeholder: null,
    selectedName: null,
    selectionStyle: {},
    splitStyle: false,
    withKeyboardNavigation: false,
  };

  state = {
    focusedName: this.props.selectedName ?? null,
    open: false,
  };

  constructor(props, context) {
    super(props, context);
    this.focusedOptionRef = React.createRef();
    this.selectionRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    const { focusedName, open } = this.state;
    const { options, withKeyboardNavigation } = this.props;

    if (!withKeyboardNavigation) {
      return;
    }

    if (open && options?.length && !prevState.open) {
      // focus first option once the dropdown list is open
      this.setState({ focusedName: options[0].name });
    } else if (open && focusedName !== prevState.focusedName) {
      // focus DOM element when changing focused option
      this.focusedOptionRef?.current?.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onChoose = (option) => {
    const { onChange } = this.props;
    if (option === null) {
      onChange && onChange(null);
    } else {
      onChange && onChange(option.name);
    }

    this.setState({ open: false });
  };

  onDropdownBlur = () => {
    if (!this.state.open) {
      return;
    }
    this.setState({ open: false });
  };

  onToggleDropdown = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    this.setState({
      open: !this.state.open,
    });
  };

  getNextOption = (key, focusedOptionIdx) => {
    const { options } = this.props;

    if (key === KeyNames.DownArrow) {
      const nextOption =
        options[focusedOptionIdx === options.length - 1 ? 0 : focusedOptionIdx + 1];
      return nextOption;
    } else if (key === KeyNames.UpArrow) {
      const nextOption =
        options[focusedOptionIdx === 0 ? options.length - 1 : focusedOptionIdx - 1];
      return nextOption;
    } else if (key === KeyNames.End) {
      const nextOption = options[options.length - 1];
      return nextOption;
    } else if (key === KeyNames.Home) {
      const nextOption = options[0];
      return nextOption;
    }

    return null;
  };

  onKeyDown = (e) => {
    const { options, withKeyboardNavigation } = this.props;
    const { focusedName, open } = this.state;
    if (!withKeyboardNavigation || !open) {
      return;
    }

    if (e.key === KeyNames.Escape) {
      this.onDropdownBlur();
      return;
    }

    const focusedOptionIdx = options.findIndex((option) => option.name === focusedName);
    const nextOption = this.getNextOption(e.key, focusedOptionIdx);
    if (nextOption) {
      e.preventDefault();
      this.setState({ focusedName: nextOption.name });
      return;
    }
  };

  renderDropdown() {
    const { open, focusedName } = this.state;
    const { dropdownClassName, minWidth, options, selectedName } = this.props;
    if (!open || options.length === 0) {
      return null;
    }

    const mapOptionToItem = (option) => {
      if (option.link) {
        return (
          <Link
            className={classnames('option', {
              focused: focusedName === option.name,
              selected: selectedName === option.name,
            })}
            forwardRef={focusedName === option.name ? this.focusedOptionRef : null}
            key={option.name}
            onTap={() => this.onChoose(option)}
            to={option.link}>
            {option.render}
          </Link>
        );
      } else {
        return (
          <Tappable key={option.name} onTap={() => this.onChoose(option)} stopPropagation={true}>
            <div
              ref={focusedName === option.name ? this.focusedOptionRef : null}
              className={classnames('option', {
                focused: focusedName === option.name,
                selected: selectedName === option.name,
              })}>
              {option.render}
            </div>
          </Tappable>
        );
      }
    };

    const items = options.filter((option) => !option.sticky).map(mapOptionToItem);
    const stickyItems = options.filter((option) => option.sticky).map(mapOptionToItem);

    const selectionWidth = `${Math.max(this.selectionRef.current.offsetWidth, minWidth)}px`;
    return (
      <Portal
        align="start"
        className={classnames('dropdownPortal', dropdownClassName)}
        onBlur={this.onDropdownBlur}
        position="bottom"
        relativeToRef={this.selectionRef}>
        <div className="controlled dropdown" style={{ minWidth: selectionWidth }}>
          <div className="dropdownList">{items}</div>
          <div>{stickyItems}</div>
        </div>
      </Portal>
    );
  }

  renderSelection() {
    const { options, placeholder, selectedName, selectionStyle, splitStyle } = this.props;

    let className = 'selection';
    let selection = options.find(({ name }) => name === selectedName);
    if (!selection) {
      selection = { render: placeholder || '' };
      className += ' placeholder';
    }

    if (splitStyle) {
      className += ' split';

      return (
        <div className={className} ref={this.selectionRef}>
          <Link className="option" style={selectionStyle} to={selection.link}>
            {selection.render}
          </Link>
          <Tappable onTap={this.onToggleDropdown}>
            <div className="icon-chevron-down" />
          </Tappable>
        </div>
      );
    }

    return (
      <Tappable onTap={this.onToggleDropdown}>
        <div className={className} ref={this.selectionRef}>
          <div className="left" style={selectionStyle}>
            {selection.render}
          </div>
          <div className="icon-chevron-down" />
        </div>
      </Tappable>
    );
  }

  render() {
    const className = classnames('dropdownContainer', 'controlled', this.props.className, {
      disabled: this.props.disabled,
      open: this.state.open,
    });

    return (
      <div className={className}>
        {this.renderSelection()}
        {this.renderDropdown()}
      </div>
    );
  }
}

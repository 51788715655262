import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminCreateSavedFilterModal.scss';

class AdminCreateSavedFilterModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    company: PropTypes.shape({
      savedFilters: PropTypes.array,
    }),
    filterConfig: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    reloadCompany: PropTypes.func,
  };

  state = {
    error: null,
    name: '',
    submitting: false,
  };

  constructor(props, context) {
    super(props, context);
    this.nameInputRef = React.createRef();
  }

  onCreate = async () => {
    const { company, closeModal, filterConfig, onSuccess, reloadCompany } = this.props;

    const name = this.nameInputRef.current.getValue().trim();
    if (!name) {
      this.setState({
        error: 'You must input a valid filter name.',
      });
      return;
    }

    const existingName = company.savedFilters.find((filter) => filter.name === name);
    if (existingName) {
      this.setState({
        error: 'This filter name already exists.',
      });
      return;
    }

    this.setState({
      error: null,
      submitting: true,
    });

    const response = await AJAX.post('/api/savedFilters/create', {
      isDefault: false,
      name,
      ...filterConfig,
    });

    if (response === 'success') {
      await reloadCompany();
      closeModal();
      onSuccess(name);
    } else {
      this.setState({
        error: 'Something went wrong, please try again later.',
        submitting: false,
      });
    }
  };

  render() {
    const { error, submitting } = this.state;

    return (
      <div className="adminCreateSavedFilterModal">
        <Form
          className="filterForm"
          addEventsToDocument={false}
          disableSubmit={submitting}
          onSubmit={this.onCreate}>
          <div className="title">Create new saved filter</div>
          <TextInput
            autoFocus={true}
            inset="Filter Name"
            onChange={this.onNameChange}
            placeholder="Short name that describes the filter"
            ref={this.nameInputRef}
          />
          <div className="bottomRow">
            <div className="error">{error}</div>
            <Button
              buttonType="cannyButton"
              formButton={true}
              loading={submitting}
              value="Create"
            />
          </div>
        </Form>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      closeModal: CloseModalContext,
      company: CompanyContext,
    },
    { forwardRef: true }
  )
)(AdminCreateSavedFilterModal);

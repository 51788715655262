import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { TintColorContext } from 'common/containers/TintColorContainer';
import withContexts from 'common/util/withContexts';

import Tappable from '../Tappable';

const buttonTypes = [
  'blackButton',
  'cannyButton',
  'darkGhostButton',
  'destructive',
  'emailButton',
  'fbButton',
  'ghostButton',
  'githubButton',
  'googleButton',
  'greenButton',
  'greyButton',
  'redButton',
  'textButton',
  'whiteButton',
];

class Button extends Component {
  static propTypes = {
    buttonStyle: PropTypes.object.isRequired,
    buttonType: PropTypes.oneOf(buttonTypes).isRequired,
    className: PropTypes.string,
    component: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    formButton: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onTap: PropTypes.func,
    tint: PropTypes.bool,
    tintColor: PropTypes.string,
    value: PropTypes.node.isRequired,
  };

  static defaultProps = {
    buttonStyle: {},
    buttonType: 'cannyButton',
    component: 'button',
    disabled: false,
    formButton: false,
    loading: false,
    tint: false,
  };

  onTap = (e) => {
    if (this.props.loading) {
      return;
    }

    if (this.props.onClick && !this.props.onTap) {
      this.props.onClick(e);
      return;
    }

    this.props.onTap(e);
  };

  render() {
    var className = classnames(
      'button',
      'buttonStyle',
      this.props.buttonType,
      this.props.className,
      {
        loading: this.props.loading,
      }
    );

    var props = {};
    if (this.props.disabled) {
      props.disabled = 'disabled';
    }

    if (this.props.formButton) {
      props.type = 'submit';
    } else {
      props.type = 'button';
    }

    if (this.props.onClick && !this.props.onTap) {
      props.onClick = this.onTap;
    }

    var value;
    if (typeof this.props.value === 'string') {
      value = this.props.value.replace(/ /g, '\u00a0');
    } else {
      value = this.props.value;
    }

    const style = Object.assign({}, this.props.buttonStyle, {
      ...(this.props.tint && { background: this.props.tintColor }),
    });

    const Component = this.props.component;

    var button = (
      <Component {...props} className={className} style={style}>
        <span className="label">{value}</span>
        <span className="loader"> </span>
      </Component>
    );

    if (this.props.onTap) {
      return <Tappable onTap={this.onTap}>{button}</Tappable>;
    } else {
      return button;
    }
  }
}

export default withContexts({ tintColor: TintColorContext })(Button);

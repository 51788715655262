import PostStatusTypes from 'common/postStatus/PostStatusTypes';

import { loadCompany } from './company';
import Data from '../Data';

// Action Types

export const SuggestionsLoaded = 'canny/post_suggestions/suggestions_loaded';
export const SuggestionsError = 'canny/post_suggestions/suggestions_error';
export const Invalidate = 'canny/post_suggestions/invalidate';

// Actions

export const DefaultStatusTypes = [
  PostStatusTypes.Initial,
  PostStatusTypes.Active,
  PostStatusTypes.Complete,
];

function suggestionsLoaded(entry, search, suggestions, statusTypes) {
  return {
    entry,
    search,
    statusTypes,
    suggestions,
    timestamp: Date.now(),
    type: SuggestionsLoaded,
  };
}

function suggestionsError(entry, search, error, statusTypes) {
  return {
    entry,
    error,
    search,
    statusTypes,
    timestamp: Date.now(),
    type: SuggestionsError,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchSuggestions(entry, search, statusTypes) {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      const { company, cookies } = getState();
      if (company.error || company.notFound) {
        return dispatch(suggestionsError(search, 'failed to load company'));
      }

      return Data.fetch(
        {
          suggestions: {
            input: {
              companyID: company._id,
              entryID: entry ? entry._id : null,
              search,
              includeStatusTypes: statusTypes,
            },
            query: Data.queries.postSuggestions,
          },
        },
        cookies,
        (error, data) => {
          if (error) {
            return dispatch(suggestionsError(entry, search, error, statusTypes));
          } else {
            return dispatch(suggestionsLoaded(entry, search, data.suggestions, statusTypes));
          }
        }
      );
    });
  };
}

export function loadSuggestions(entry, search, statusTypes = DefaultStatusTypes) {
  return (dispatch, getState) => {
    if (shouldFetchSuggestions(getState(), entry, search, statusTypes)) {
      return dispatch(fetchSuggestions(entry, search, statusTypes));
    }
  };
}

export function invalidateSuggestions() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helpers

export function getEntryKey(entry) {
  return entry ? entry._id : 'none';
}

function shouldFetchSuggestions(state, entry, search, statusTypes) {
  if (typeof search !== 'string') {
    return false;
  }

  const postSuggestions = state.postSuggestions;
  const entryPostSuggestions = postSuggestions[getEntryKey(entry)];
  return !entryPostSuggestions || !entryPostSuggestions[`${search}/${statusTypes?.join(',')}`];
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

export default class DropArea extends Component {
  static propTypes = {
    className: PropTypes.string,
    onDrop: PropTypes.func,
    style: PropTypes.object,
  };

  state = {
    draggingOver: false,
  };

  constructor(props, context) {
    super(props, context);

    this.dropAreaRef = React.createRef();
  }

  onDrop = (e) => {
    const { onDrop } = this.props;
    e.preventDefault();

    const data = e.dataTransfer.getData('text/plain');
    this.setState({ draggingOver: false });
    if (onDrop) {
      onDrop(data);
    }
  };

  onDragOver = (e) => {
    const { draggingOver } = this.state;
    e.preventDefault();

    if (!draggingOver && this.dropAreaRef.current.isSameNode(e.target)) {
      this.setState({ draggingOver: true });
    }
  };

  onDragLeave = (e) => {
    const { draggingOver } = this.state;
    if (draggingOver && this.dropAreaRef.current.isSameNode(e.target)) {
      this.setState({ draggingOver: false });
    }
  };

  render() {
    const { draggingOver } = this.state;
    const { children, className, style } = this.props;
    return (
      <div
        className={classnames(className, { draggingOver })}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        ref={this.dropAreaRef}
        style={style}>
        {children}
      </div>
    );
  }
}

import {
  INVALIDATE,
  REQUEST_USER,
  USER_ERROR,
  USER_LOADED,
  USER_NOT_FOUND,
} from '../actions/users';

export default function users(state = {}, action) {
  switch (action.type) {
    case REQUEST_USER: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign({}, state[action.urlName], {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
          notFound: false,
        }),
      });
    }

    case USER_LOADED: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign(
          {},
          state[action.urlName],
          {
            lastUpdated: action.timestamp,
            loading: false,
          },
          action.user
        ),
      });
    }

    case USER_NOT_FOUND: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign({}, state[action.urlName], {
          lastUpdated: action.timestamp,
          loading: false,
          notFound: true,
        }),
      });
    }

    case USER_ERROR: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign({}, state[action.urlName], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        }),
      });
    }

    case INVALIDATE: {
      return {};
    }

    default:
      return state;
  }
}

import { CustomFieldTypes, Errors } from 'common/customPostFields/Constants';

export const findTypeFromName = (name) =>
  Object.values(CustomFieldTypes).find((type) => type.name === name);

export const getFieldsErrors = (customFieldValuesMap, fields) => {
  const errors = fields
    .map((field) => {
      const value = customFieldValuesMap?.[field.customPostFieldID ?? field._id];
      const type = findTypeFromName(field.type);

      const hasValue = !!(Array.isArray(value) ? value.length : value);
      if (field.required && !hasValue) {
        return {
          field,
          type: Errors.missingFields,
        };
      } else if (value && !type.validator(value)) {
        return {
          field,
          type: Errors.incompatibleTypes,
        };
      }
    })
    .filter(Boolean);

  return errors;
};

export const transformCustomFieldValue = (type, value) => {
  const customFieldType = CustomFieldTypes[type];

  const transformFunction = customFieldType?.postTransform;

  return transformFunction ? transformFunction(value) : value;
};

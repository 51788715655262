import OAuthAzure from './OAuthAzure';
import OAuthAzureRedirect from './OAuthAzureRedirect';
import OAuthFacebook from './OAuthFacebook';
import OAuthFacebookRedirect from './OAuthFacebookRedirect';
import OAuthGitHub from './OAuthGitHub';
import OAuthGitHubRedirect from './OAuthGitHubRedirect';
import OAuthGoogle from './OAuthGoogle';
import OAuthGoogleRedirect from './OAuthGoogleRedirect';
import OAuthGSuite from './OAuthGSuite';
import OAuthGSuiteRedirect from './OAuthGSuiteRedirect';
import OAuthOIDC from './OAuthOIDC';
import OAuthOkta from './OAuthOkta';

type OAuthProvider = 'azure' | 'facebook' | 'github' | 'google' | 'gsuite' | 'okta' | 'oidc';
// TODO change all usages of getFlow to use the enum for better readability
// 1 is initial, 2 is redirect
export enum OAuthStage {
  initial = 1,
  redirect = 2,
}

type OAuthDefintion = {
  flows: object[]; // TODO what is the type of a class?
  name: string;
};

const ProviderDetails: Record<OAuthProvider, OAuthDefintion> = {
  azure: {
    flows: [OAuthAzure, OAuthAzureRedirect],
    name: 'Azure AD',
  },
  facebook: {
    flows: [OAuthFacebook, OAuthFacebookRedirect],
    name: 'Facebook',
  },
  github: {
    flows: [OAuthGitHub, OAuthGitHubRedirect],
    name: 'GitHub',
  },
  google: {
    flows: [OAuthGoogle, OAuthGoogleRedirect],
    name: 'Google',
  },
  gsuite: {
    flows: [OAuthGSuite, OAuthGSuiteRedirect],
    name: 'GSuite',
  },
  okta: {
    flows: [OAuthOkta],
    name: 'Okta',
  },
  oidc: {
    flows: [OAuthOIDC],
    name: 'OpenID Connect', // TODO should this be something else, where is this used?
  },
};

const OAuthFlows = {
  getFlow(oauthProvider: OAuthProvider, stage?: OAuthStage) {
    const provider = OAuthFlows.getProviderDetails(oauthProvider);

    if (!stage) {
      stage = OAuthStage.initial;
    }

    const index = stage - 1;
    return provider.flows[index];
  },

  getName(oauthProvider: OAuthProvider) {
    const provider = OAuthFlows.getProviderDetails(oauthProvider);

    return provider.name;
  },

  getProviderDetails(oauthProvider: OAuthProvider) {
    return ProviderDetails[oauthProvider];
  },
};

export default OAuthFlows;

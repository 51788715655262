import Data from '../Data';

// Action Types

export const RequestCustomFields = 'canny/customFields/request_custom_fields';
export const CustomFieldsLoaded = 'canny/customFields/custom_fields_loaded';
export const CustomFieldsError = 'canny/customFields/custom_fields_error';

// Actions

function requestCustomFields() {
  return {
    timestamp: Date.now(),
    type: RequestCustomFields,
  };
}

function customFieldsLoaded(customFields) {
  return {
    customFields,
    timestamp: Date.now(),
    type: CustomFieldsLoaded,
  };
}

function customFieldsError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: CustomFieldsError,
  };
}

// Action Creators

function fetchCustomFields() {
  return (dispatch, getState) => {
    const { cookies } = getState();
    return Data.fetch(
      {
        customFields: {
          query: Data.queries.customFields,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(customFieldsError(error));
        } else {
          return dispatch(customFieldsLoaded(data.customFields));
        }
      }
    );
  };
}

export function loadCustomFields() {
  return (dispatch, getState) => {
    if (shouldFetchCustomFields(getState())) {
      dispatch(requestCustomFields());
      return dispatch(fetchCustomFields());
    }
  };
}

export function reloadCustomFields() {
  return (dispatch, getState) => {
    return dispatch(fetchCustomFields());
  };
}

// Helpers

function shouldFetchCustomFields(state) {
  return !state.customFields;
}

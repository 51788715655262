import { Component } from 'react';

import PropTypes from 'prop-types';

export default class AdminBoardV1Redirect extends Component {
  static propTypes = {
    board: PropTypes.object,
    router: PropTypes.object,
  };

  componentDidMount() {
    const { board, router } = this.props;
    router.replace(`/admin/feedback?boards=${board.urlName}`);
  }

  render() {
    return null;
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import AJAX from 'common/AJAX';
import Comment from 'common/comment/Comment';
import CommentBody from 'common/comment/CommentBody';
import CommentComposer from 'common/comment/CommentComposer';
import CommentMenu from 'common/comment/CommentMenu';
import PinnedLabel from 'common/comment/PinnedLabel';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import FileRenderer from 'common/file/FileRenderer';
import { createFiles, createImageFiles } from 'common/file/utils/createFiles';
import ConfirmModal from 'common/modals/ConfirmModal';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import UserLockup from 'common/user/UserLockup';
import withContexts from 'common/util/withContexts';

import PostStatus from './PostStatus';

import 'css/components/post/_PostStatusChange.scss';

class PostStatusChange extends Component {
  static propTypes = {
    board: PropTypes.object,
    comment: PropTypes.object,
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    openModal: PropTypes.func.isRequired,
    pinned: PropTypes.bool,
    post: PropTypes.object,
    statusChange: PropTypes.shape({
      changerID: PropTypes.string,
      created: PropTypes.string.isRequired,
      isLastStatusChange: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
    }),
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
  };

  state = {
    editMode: false,
    hideTranslation: false,
  };

  componentDidMount() {
    this._deleting = false;
  }

  onCancelEdit = () => {
    this.setState({
      editMode: false,
    });
  };

  onCommentEdited = () => {
    this.setState({
      editMode: false,
    });
  };

  onDeleteStatusHistory = () => {
    this.props.openModal(ConfirmModal, {
      message: "Are you sure you'd like to delete this status change?",
      onConfirm: () => {
        if (this._deleting) {
          return;
        }
        this._deleting = true;

        const { statusChange, invalidateData, post, viewer } = this.props;

        AJAX.post(
          '/api/posts/deleteStatus',
          {
            statusChangeID: statusChange._id,
            viewer,
          },
          (response) => {
            invalidateData(post);
          }
        );
      },
    });
  };

  onEditComment = () => {
    this.setState({
      editMode: true,
    });
  };

  onPinToggle = () => {
    const { invalidateData, post } = this.props;
    invalidateData(post);
  };

  onToggleTranslation = () => {
    this.setState({
      hideTranslation: !this.state.hideTranslation,
    });
  };

  renderDeleteStatus() {
    const {
      company: { viewerIsMember },
      pinned,
      statusChange,
    } = this.props;

    if (!viewerIsMember || pinned) {
      return null;
    }

    if (statusChange.isLastStatusChange) {
      return null;
    }

    return (
      <Tappable onTap={this.onDeleteStatusHistory}>
        <span className="icon icon-x" />
      </Tappable>
    );
  }

  renderLockup() {
    const { statusChange } = this.props;
    const { hideTranslation } = this.state;
    if (!statusChange.changer) {
      return (
        <div className="update noUser">
          <span>This post was marked as </span>
          <PostStatus
            hideTranslation={hideTranslation}
            isBadge={true}
            status={statusChange.status}
            showOpen={true}
          />
        </div>
      );
    }

    return (
      <div className="update">
        <UserLockup user={statusChange.changer} />
        <span className="text">
          <span> marked this post as </span>
          <PostStatus
            hideTranslation={hideTranslation}
            isBadge={true}
            status={statusChange.status}
            showOpen={true}
          />
        </span>
      </div>
    );
  }

  renderStatusComment() {
    if (!this.props.comment) {
      return (
        <div className="bottomContainer">
          <Timestamp timestamp={this.props.statusChange.created} />
        </div>
      );
    }

    const { editMode, hideTranslation } = this.state;
    const { board, comment, pinned, post } = this.props;
    if (editMode) {
      return (
        <div className="bottomContainer">
          <CommentComposer
            board={board}
            comment={comment}
            onCancelEdit={this.onCancelEdit}
            onCommentEdited={this.onCommentEdited}
            post={post}
          />
        </div>
      );
    }

    const body = comment.value.trim() ? (
      <CommentBody comment={comment} hideTranslation={hideTranslation} />
    ) : null;
    const files = [
      ...createImageFiles(comment.imageURLs || []),
      ...createFiles(comment.files || []),
    ];

    return (
      <div className="bottomContainer">
        {body}
        <FileRenderer className="postStatusFileRenderer" allowRemove={false} files={files} />
        <CommentMenu
          board={board}
          comment={comment}
          hideTranslation={hideTranslation}
          onEditComment={this.onEditComment}
          onPinToggle={this.onPinToggle}
          onToggleTranslation={this.onToggleTranslation}
          pinned={pinned}
          post={post}
        />
      </div>
    );
  }

  renderReplies() {
    const { board, comment, invalidateData, post, replies } = this.props;
    if (!replies || replies.length === 0) {
      return null;
    }

    const list = [];
    replies.forEach((reply) => {
      list.push(
        <Comment
          key={reply._id}
          board={board}
          comment={reply}
          invalidateData={invalidateData}
          parent={comment}
          post={post}
          replies={[]}
        />
      );
    });

    return <div className="replies">{list}</div>;
  }

  render() {
    const { pinned } = this.props;
    return (
      <div
        className={classnames({
          postStatusChange: true,
          pinned,
        })}>
        <div className="topContainer">
          <div className="left">{this.renderLockup()}</div>
          <div className="right">
            {pinned && <PinnedLabel />}
            {this.renderDeleteStatus()}
          </div>
        </div>
        {this.renderStatusComment()}
        {this.renderReplies()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateData: (post) => {
      return Promise.all([dispatch(reloadPostActivity(post)), dispatch(reloadPost(post))]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostStatusChange);

import React, { Component } from 'react';

import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import deepCompare from 'react-fast-compare';

import 'css/components/charts/_ChartRenderer.scss';

Chart.register(...registerables, ChartDataLabels);

// hide chart data labels by default
Chart.defaults.set('plugins.datalabels', {
  display: false,
});

class ChartRenderer extends Component {
  static propTypes = {
    chartConfig: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    type: PropTypes.oneOf(['line', 'doughnut']).isRequired,
  };

  state = {
    canvasCtx: null,
  };

  constructor(props, context) {
    super(props, context);
    this.canvasRef = React.createRef();
  }

  async componentDidMount() {
    const { chartConfig, onUpdate, type } = this.props;
    const canvasCtx = this.canvasRef.current.getContext('2d');
    this.setState({ canvasCtx });
    this.chart = new Chart(canvasCtx, {
      type,
      ...chartConfig,
    });
    onUpdate?.(this.chart);
  }

  componentDidUpdate(prevProps) {
    const { canvasCtx } = this.state;
    if (canvasCtx === null) {
      return;
    }
    if (deepCompare(prevProps, this.props)) {
      // Do not update if props didn't change
      return;
    }

    this.updateChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  updateChart() {
    const { chartConfig, onUpdate } = this.props;
    this.chart.data = chartConfig.data;
    this.chart.options = chartConfig.options;
    this.chart.update();
    onUpdate?.(this.chart);
  }

  render() {
    return (
      <div className="chartRenderer">
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}

export default ChartRenderer;

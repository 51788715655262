import React, { useContext, useEffect, useState } from 'react';

import { Mail, MailX } from 'lucide-react';

import AccountModal, { FormStates } from 'common/AccountModal';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import { P } from 'common/ui/Text';
import isNil from 'common/util/isNil';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Company } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

import 'css/components/subdomain/public/_SubdomainChangelog.scss';

type EmailSubscriptions = Record<
  string,
  Array<{
    _id: string;
    createdAt: string; // ISO 8601 date
    type: 'changelog';
  }>
>;

const SubdomainChangelogSubscriptionButton = () => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const openModal = useContext(OpenModalContext);
  const viewer = useContext<Viewer>(ViewerContext);

  const [isSubscribed, setSubscribed] = useState<boolean | null>(null);

  const isViewerLoggedIn = viewer && !viewer.loggedOut;
  useEffect(() => {
    const fetchEmailSubscriptions = async () => {
      if (!isViewerLoggedIn) {
        setSubscribed(false);
        return;
      }

      const response = await AJAX.post('/api/viewer/getEmailSubscriptions', {});

      const { error, parsedResponse } = parseAPIResponse<{ subscriptions: EmailSubscriptions }>(
        response,
        { isSuccessful: (parsedResponse) => !!parsedResponse?.subscriptions }
      );

      if (error || !parsedResponse) {
        setSubscribed(false);
        return;
      }

      const isSubscribed = parsedResponse.subscriptions[company._id]?.some(
        (subscription) => subscription.type === 'changelog'
      );
      setSubscribed(!!isSubscribed);
    };

    fetchEmailSubscriptions();
  }, [isViewerLoggedIn, company._id]);

  const subscribe = async () => {
    const response = await AJAX.post('/api/changelog/subscribe', {});

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'plan does not support': `You are not allowed to subscribe to the changelog. Please refresh the page.`,
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    setSubscribed(true);
  };

  const unsubscribe = async () => {
    const response = await AJAX.post('/api/changelog/unsubscribe', {});

    const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    setSubscribed(false);
  };

  const openAccountModal = () => {
    openModal(AccountModal, {
      formState: FormStates.signup,
      onSuccess: async (callback: () => void) => {
        await subscribe();
        callback?.();
      },
    });
  };

  if (isNil(isSubscribed)) {
    return null;
  }

  if (isSubscribed) {
    return (
      <button className="subscriptionButton" onClick={unsubscribe}>
        <P className="subscriptionLabel">
          <MailX size={16} /> Unsubscribe
        </P>
      </button>
    );
  }

  return (
    <button
      className="subscriptionButton"
      onClick={isViewerLoggedIn ? subscribe : openAccountModal}>
      <P className="subscriptionLabel">
        <Mail size={16} /> Subscribe
      </P>
    </button>
  );
};

export default SubdomainChangelogSubscriptionButton;

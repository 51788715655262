import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { invalidateSuggestions, loadSuggestions } from 'common/actions/clickupTaskSuggestions';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import AdminIntegrationSearchForm from 'common/subdomain/admin/AdminIntegrationSearchForm';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

class AdminClickupSearchForm extends Component {
  static propTypes = {
    post: PropTypes.object,
    taskSuggestions: PropTypes.object,
  };

  state = {
    error: null,
    searchValue: null,
  };

  onLinkTask = async (task) => {
    const { post, reloadPost } = this.props;

    this.setState({ error: null });

    const response = await AJAX.post('/api/clickup/linkTask', {
      postID: post._id,
      taskID: task.id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': `You are not authorized to use this feature. Reach for one of you company's admins for support.`,
        'integration not installed': `Your company does not have the ClickUp integration installed. Please, install it before linking tasks.`,
      },
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    await reloadPost(post);
  };

  onSearchValueChanged = (value) => {
    this.setState({ searchValue: value });
  };

  renderError() {
    const { taskSuggestions } = this.props;
    const { error, searchValue } = this.state;

    const suggestions = taskSuggestions?.[searchValue];
    if (suggestions?.error === 'rate limit') {
      return (
        <div className="error">
          The ClickUp API reached its limit. Please, wait a few minutes before trying again.
        </div>
      );
    }

    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderSuggestion(suggestion) {
    return `[${suggestion?.customID ?? suggestion?.listName}] ${suggestion?.name}`;
  }

  render() {
    const { loadSuggestions, taskSuggestions } = this.props;

    return (
      <div className="adminClickupSearchForm">
        <AdminIntegrationSearchForm
          loadSuggestions={loadSuggestions}
          onSearchValueChanged={this.onSearchValueChanged}
          onSuggestionSelected={this.onLinkTask}
          placeholder={'ClickUp Task ID'}
          renderSuggestion={this.renderSuggestion}
          suggestions={taskSuggestions}
        />
        {this.renderError()}
      </div>
    );
  }
}

export default connect(
  (state) => ({ taskSuggestions: state.clickupTaskSuggestions }),
  (dispatch) => ({
    loadSuggestions: (searchQuery) => dispatch(loadSuggestions(searchQuery)),
    reloadPost: (post) => {
      return Promise.all([dispatch(invalidateSuggestions()), dispatch(reloadPost(post))]);
    },
  })
)(AdminClickupSearchForm);

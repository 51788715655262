const ImpactFactorTypes = {
  calculation: {
    defaultValue: 0,
    name: 'calculation',
    // TODO: define if this functions make sense for calculation roadmap factors
    normalize: () => 0,
    validate: () => null,
  },
  checkbox: {
    defaultValue: false,
    name: 'checkbox',
    options: ['true', 'false'],
    normalize: (value: number) => {
      return value ? 100 : 0;
    },
    validate: (value: boolean) => {
      if (typeof value !== 'boolean') {
        return 'Please enter a valid value';
      }

      return undefined;
    },
  },
  fibonacci: {
    defaultValue: 0,
    name: 'fibonacci',
    normalize: (value: number) => {
      return Math.max(1, value / 2.33);
    },
    validate: (value: number) => {
      if (typeof value !== 'number') {
        return 'Please enter a number';
      } else if (Number.isNaN(value)) {
        return 'Please enter a number';
      } else if (value < 0) {
        return 'Please enter a positive number';
      } else if (value > 233) {
        return 'Please enter a number up to 233';
      } else if (!Number.isInteger(value)) {
        return 'Please enter an integer';
      }

      return undefined;
    },
  },
  numberToOneHundred: {
    defaultValue: 0,
    name: 'numberToOneHundred',
    normalize: (value: number) => value,
    validate: (value: number) => {
      if (typeof value !== 'number') {
        return 'Please enter a number';
      } else if (Number.isNaN(value)) {
        return 'Please enter a number';
      } else if (value < 0) {
        return 'Please enter a positive number';
      } else if (value > 100) {
        return 'Please enter a number up to 100';
      } else if (!Number.isInteger(value * 10)) {
        return 'Please enter increments of 0.1';
      }

      return undefined;
    },
  },
  numberToTen: {
    defaultValue: 0,
    name: 'numberToTen',
    normalize: (value: number) => {
      return value * value;
    },
    validate: (value: number) => {
      if (typeof value !== 'number') {
        return 'Please enter a number';
      } else if (Number.isNaN(value)) {
        return 'Please enter a number';
      } else if (value < 0) {
        return 'Please enter a positive number';
      } else if (value > 10) {
        return 'Please enter a number up to 10';
      } else if (!Number.isInteger(value)) {
        return 'Please enter an integer';
      }

      return undefined;
    },
  },
  percentage: {
    defaultValue: 0,
    name: 'percentage',
    normalize: (value: number) => value,
    skipWeight: true,
    validate: (value: number) => {
      if (typeof value !== 'number') {
        return 'Please enter a number';
      } else if (Number.isNaN(value)) {
        return 'Please enter a number';
      } else if (value < 0) {
        return 'Please enter a positive number';
      } else if (value > 100) {
        return 'Please enter a number up to 100';
      } else if (!Number.isInteger(value)) {
        return 'Please enter an integer';
      }

      return undefined;
    },
  },
  stars: {
    defaultValue: 0,
    name: 'stars',
    normalize: (value: number) => {
      return Math.pow(2 * value, 2);
    },
    validate: (value: number) => {
      if (typeof value !== 'number') {
        return 'Please enter a number';
      } else if (Number.isNaN(value)) {
        return 'Please enter a number';
      } else if (value < 0) {
        return 'Please enter a positive amount of stars';
      } else if (value > 5) {
        return 'Please enter up to five stars';
      } else if (!Number.isInteger(value)) {
        return 'Please enter an integer';
      }

      return undefined;
    },
  },
};

export default ImpactFactorTypes;

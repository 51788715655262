import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';
import Tappable from '../Tappable';

import 'css/components/tags/_Tag.scss';

export default class Tag extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    name: PropTypes.string,
    onTap: PropTypes.func,
    selected: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  onTap = () => {
    const { disabled, onTap } = this.props;
    if (disabled || !onTap) {
      return;
    }

    onTap();
  };

  renderSpinner() {
    if (!this.props.loading) {
      return null;
    }
    return <Spinner />;
  }

  render() {
    const { loading, name, onTap, selected } = this.props;
    const className = classnames('tag', {
      loading: loading,
      selected: selected,
      tappable: !!onTap,
    });
    return (
      <Tappable onTap={this.onTap}>
        <div className={className}>
          <div className="name">{name}</div>
          {this.renderSpinner()}
        </div>
      </Tappable>
    );
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminBoardSettingsWidget.scss';

function processURL(url) {
  if (!url) {
    return null;
  }
  if (url.indexOf('?') !== -1) {
    url = url.slice(0, url.indexOf('?'));
  }
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  return url;
}

class AdminBoardSettingsWidget extends Component {
  static propTypes = {
    board: PropTypes.shape({
      settings: PropTypes.shape({
        widgetURL: PropTypes.string,
      }),
    }),
    company: PropTypes.object,
    openModal: PropTypes.func,
    reloadBoard: PropTypes.func,
    router: PropTypes.object.isRequired,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    saving: false,
  };

  constructor(props, context) {
    super(props, context);

    this.widgetURLRef = React.createRef();
  }

  onSave = () => {
    const { saving } = this.state;
    if (saving) {
      return;
    }

    this.setState({
      error: null,
    });

    const widgetURL = processURL(this.widgetURLRef.current.getValue());
    if (widgetURL && !validateInput.primitives.url(widgetURL)) {
      this.setState({
        error: 'URL is invalid.',
      });
      return;
    }

    this.setState({
      saving: true,
    });

    const { board } = this.props;
    AJAX.post(
      '/api/boards/editWidgetURL',
      {
        boardID: board._id,
        widgetURL: widgetURL ? widgetURL : null,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later.',
            saving: false,
          });
          return;
        }

        const { reloadBoard } = this.props;
        reloadBoard(board.urlName).then(() => {
          this.setState({
            saving: false,
          });
        });
      }
    );
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    const { board, company } = this.props;
    const { saving } = this.state;
    const subdomain = company.subdomain + '.canny.io';
    const docsURL = 'https://developers.canny.io/install/widget/web';
    return (
      <div className="adminBoardSettingsWidget">
        <Helmet title={'Widget Settings | ' + board.name + ' | Canny'} />
        <Form
          className="widgetForm"
          addEventsToDocument={false}
          disableSubmit={saving}
          onSubmit={this.onSave}>
          <div className="text">
            If you're using our widget, enter its URL here. When we send user emails we'll link to
            your widget rather than {subdomain}.
          </div>
          <div className="text">
            Please be sure to check the URL works before setting this. Also make sure you have{' '}
            <a href={docsURL} rel="noopener" target="_blank">
              URL forwarding
            </a>{' '}
            set up.
          </div>
          <TextInput
            defaultValue={board.settings.widgetURL}
            inset="Widget URL"
            placeholder="https://yoursite.com/url/to/widget"
            ref={this.widgetURLRef}
          />
          {this.renderError()}
          <Button formButton={true} loading={saving} value="Save" />
        </Form>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board.widget),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBoardSettingsWidget);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CloseModalContext } from 'common/containers/ModalContainer';
import Form from 'common/Form';
import withContexts from 'common/util/withContexts';

import Modal from './Modal';
import Button from '../inputs/Button';

import 'css/components/modals/_ErrorModal.scss';

class ErrorModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    message: PropTypes.node.isRequired,
  };

  render() {
    return (
      <Modal>
        <Form
          className="errorModal"
          addEventsToDocument={true}
          disableSubmit={false}
          onSubmit={this.props.closeModal}>
          <div className="message">{this.props.message}</div>
          <div className="buttons">
            <Button buttonType="cannyButton" formButton={true} value="Okay" />
          </div>
        </Form>
      </Modal>
    );
  }
}

export default withContexts({ closeModal: CloseModalContext }, { forwardRef: true })(ErrorModal);

import React, { type ReactNode, useContext } from 'react';

import {
  ArrowLeftRight,
  Fingerprint,
  GitMerge,
  TerminalSquare,
  UserPlus,
  Webhook,
} from 'lucide-react';

import Colors from 'common/colors/constants';
import { RevenueTimeframes, convertMRRToTimeframe } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import CopyButton from 'common/inputs/CopyButton';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { ThirdPartyCompanyDefaultField } from 'common/thirdPartyCompanies/constants';
import Timestamp from 'common/Timestamp';
import { isNotNil } from 'common/util/isNil';
import mapify from 'common/util/mapify';
import numberWithCommas from 'common/util/numberWithCommas';
import validateInput from 'common/validateInput';

import AdminUsersDetailsSidebarUserHeader from './AdminUsersDetailsSidebarUserHeader';

import FreshdeskLogo from 'img/freshdesk-logo-small.svg';
import GongLogo from 'img/gong-logo-small.png';
import GoogleLogo from 'img/google-icon.svg';
import HelpscoutLogo from 'img/helpscout-logo-small.png';
import HubspotLogo from 'img/hubspot-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import SalesforceLogo from 'img/salesforce-logo-small.png';
import SegmentLogo from 'img/segment-logo-small.png';
import SlackLogo from 'img/slack-logo-small.png';
import TLDVLogo from 'img/tldv-logo-small.svg';
import ZendeskLogo from 'img/zendesk-logo-small.png';
import ZoomLogo from 'img/zoom-logo-small.png';

import type { Company } from 'common/api/endpoints/companies';
import type { CustomField } from 'common/api/endpoints/customFields';
import type { ThirdPartyCompany } from 'common/api/endpoints/thirdPartyCompanies';
import type { CompanyUser } from 'common/api/endpoints/users';
import type { ReduxStateMetadata } from 'common/subdomain/admin/AdminUsers';

import 'css/components/subdomain/admin/_AdminUsersDetailsSidebar.scss';

type Field = {
  className?: string;
  label: React.ReactNode;
  suffix?: React.ReactNode;
  value: React.ReactNode;
};

type Props = {
  user?: CompanyUser & ReduxStateMetadata;
  thirdPartyCompany?: ThirdPartyCompany & ReduxStateMetadata;
  customFields: CustomField[];
};

type SourceRenderer = { name: string; render: JSX.Element };

const sources = {
  api: {
    name: 'API',
    render: <TerminalSquare width="16" className="blackIcon" />,
  },
  freshdesk: {
    name: 'Freshdesk',
    render: <LazyLoadedImage alt="Freshdesk logo" src={FreshdeskLogo} width="18" />,
  },
  gong: {
    name: 'Gong',
    render: <LazyLoadedImage alt="Gong logo" src={GongLogo} width="18" />,
  },
  gsuite: {
    name: 'G Suite',
    render: <LazyLoadedImage alt="Google logo" src={GoogleLogo} width="18" />,
  },
  helpscout: {
    name: 'Help Scout',
    render: <LazyLoadedImage alt="HelpScout logo" src={HelpscoutLogo} width="18" />,
  },
  hubspot: {
    name: 'Hubspot',
    render: <LazyLoadedImage alt="HubSpot logo" src={HubspotLogo} width="16" />,
  },
  identify: {
    name: 'Identify',
    render: <Fingerprint width="16" className="blackIcon" />,
  },
  intercom: {
    name: 'Intercom',
    render: <LazyLoadedImage alt="Intercom logo" src={IntercomLogo} width="16" />,
  },
  manual: {
    name: 'Manually created',
    render: <UserPlus width="16" className="blackIcon" />,
  },
  merge: {
    name: 'Merged',
    render: <GitMerge width="16" className="blackIcon" />,
  },
  salesforce: {
    name: 'Salesforce',
    render: <LazyLoadedImage alt="Salesforce logo" src={SalesforceLogo} width="18" />,
  },
  segment: {
    name: 'Segment',
    render: <LazyLoadedImage alt="Segment logo" src={SegmentLogo} width="16" />,
  },
  slack: {
    name: 'Slack',
    render: <LazyLoadedImage alt="Slack logo" src={SlackLogo} width="16" />,
  },
  tldv: {
    name: 'TLDV',
    render: <LazyLoadedImage alt="TLDV logo" src={TLDVLogo} width="16" />,
  },
  webhook: {
    name: 'Webhook',
    render: <Webhook width="16" className="blackIcon" />,
  },
  zendesk: {
    name: 'Zendesk',
    render: <LazyLoadedImage alt="Zendesk logo" src={ZendeskLogo} width="16" />,
  },
  zoom: {
    name: 'Zoom',
    render: <LazyLoadedImage alt="Zoom logo" src={ZoomLogo} width="16" />,
  },
};

// util components
const Copyable = ({ children, value }: { children: ReactNode; value?: string }) => {
  return (
    <div className="copyable">
      <div className="content">{children}</div>
      <CopyButton value={value || children} />
    </div>
  );
};

const ID = ({ id, fallback = 'Unknown' }: { id: string; fallback?: string }) => {
  if (!id) {
    return <div>{fallback}</div>;
  }

  return <Copyable>{id}</Copyable>;
};

const TimestampWithFallback = ({
  timestamp,
  fallback = 'Unknown',
}: {
  timestamp: Date | null;
  fallback?: string;
}) => {
  if (!timestamp) {
    return <div>{fallback}</div>;
  }

  return <Timestamp showRelative={false} timestamp={timestamp} />;
};

const CustomFieldValue = ({
  customField,
  fieldValue,
}: {
  customField: CustomField;
  fieldValue?: string | number | boolean | Date | null;
}) => {
  if (fieldValue === null || fieldValue === undefined) {
    return <div>None</div>;
  }

  if (customField.fieldType === 'date') {
    return <TimestampWithFallback timestamp={new Date(String(fieldValue))} />;
  }

  if (customField.fieldType === 'boolean') {
    return <div>{fieldValue ? 'true' : 'false'}</div>;
  }

  if (customField.fieldType === 'string') {
    if (validateInput.primitives.url(fieldValue)) {
      return (
        <a
          className="link"
          href={String(fieldValue)}
          target="_blank"
          rel="noopener noreferrer nofollow">
          {fieldValue}
        </a>
      );
    }
  }

  return <div>{fieldValue}</div>;
};

const SectionHeader = ({ sectionName }: { sectionName: string }) => (
  <div className="sectionHeader">
    <div className="sectionTitle">{sectionName}</div>
  </div>
);

const FieldsTable = ({ fields }: { fields: Field[] }) => (
  <table className="fieldsTable">
    <tbody>
      {fields.map((field) => (
        <tr key={String(field.label)} className={field.className}>
          <td>
            <div className="label">{field.label}</div>
            <div className="suffix">{field.suffix}</div>
          </td>
          <td>{field.value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const CompanyInfo = ({
  thirdPartyCompany,
  company,
  customFields,
}: {
  thirdPartyCompany: ThirdPartyCompany;
  company: Company;
  customFields: CustomField[];
}) => {
  const fields = getCompanyFields(thirdPartyCompany, company, customFields);
  return (
    <div className="companyInfo" key={thirdPartyCompany._id}>
      <div className="companyName">{thirdPartyCompany.name}</div>
      <FieldsTable fields={fields} />
    </div>
  );
};

const UserCompanies = ({
  user,
  company,
  customFields,
}: {
  user: CompanyUser;
  company: Company;
  customFields: CustomField[];
}) => {
  if (!user.companies?.length) {
    return null;
  }

  return (
    <div className="fieldSection">
      <SectionHeader sectionName="Companies" />
      {user.companies.map((thirdPartyCompany) => (
        <CompanyInfo
          key={thirdPartyCompany._id}
          thirdPartyCompany={thirdPartyCompany}
          company={company}
          customFields={customFields}
        />
      ))}
    </div>
  );
};

// Validate the source, use `as` to test if the source exists
const getSourceSuffix = (source: string | null) => {
  return source && source in sources ? sources[source as keyof typeof sources] : null;
};

// field getters
const getUserCustomFields = (user: CompanyUser, customFields: CustomField[]) => {
  if (!user || !user.customFieldsArray || !customFields) {
    return [];
  }

  const customFieldsFromType = customFields.filter(
    (customField) => customField.objectType === 'user'
  );
  const customFieldMap = mapify(customFieldsFromType, 'name');

  return Object.entries(customFieldMap).map(([fieldName, customField]) => {
    const field = user.customFieldsArray.find((field) => field.key === fieldName);

    return {
      label: customField.prettyName,
      suffix: getSourceSuffix(field?.source ?? null)?.render ?? null,
      value: <CustomFieldValue customField={customField} fieldValue={field?.value} />,
    };
  });
};

const getCompanyCustomFields = (
  tpc: ThirdPartyCompany,
  company: Company,
  customFields: CustomField[]
) => {
  if (!tpc || !tpc.customFields || !customFields) {
    return [];
  }

  const fieldsToSkip = Object.entries(company.thirdPartyCompanyFieldMapping).flat().filter(Boolean);

  const customFieldsFromType = customFields.filter(
    (customField) =>
      customField.objectType === 'company' && !fieldsToSkip.includes(customField.name)
  );
  const customFieldMap = mapify(customFieldsFromType, 'name');

  return Object.entries(customFieldMap).map(([fieldName, customField]) => {
    const field = tpc.fieldsArray.find((field) => field.key === fieldName);
    return {
      label: customField.prettyName,
      suffix: getSourceSuffix(field?.source ?? null)?.render ?? (
        <ArrowLeftRight size={14} color={Colors.indigo90} />
      ),
      value: (
        <CustomFieldValue customField={customField} fieldValue={tpc.customFields[fieldName]} />
      ),
    };
  });
};

const getCompanyDefaultFields = (
  thirdPartyCompany: ThirdPartyCompany,
  company: Company,
  customFields: CustomField[]
) => {
  const toLabel = (label: string, fieldName: string | null) =>
    `${label} ${fieldName ? `(${fieldName})` : ''}`;

  const DefaultFieldLabels = {
    [ThirdPartyCompanyDefaultField.customerStatus]: 'Customer Status',
    [ThirdPartyCompanyDefaultField.monthlySpend]:
      company.revenueTimeframe === RevenueTimeframes.arr ? 'Annual Spend' : 'Monthly Spend',
    [ThirdPartyCompanyDefaultField.accountOwner]: 'Account Owner',
    [ThirdPartyCompanyDefaultField.accountOwnerEmail]: 'Account Owner Email',
    [ThirdPartyCompanyDefaultField.accountOwnerID]: 'Account Owner ID',
    [ThirdPartyCompanyDefaultField.renewalDate]: 'Renewal Date',
    [ThirdPartyCompanyDefaultField.renewalRisk]: 'Renewal Risk',
  } as const;

  const getMonthlySpendValue = (value?: unknown) => {
    if (isNotNil(value) && typeof value === 'number') {
      return `$${numberWithCommas(
        Math.round(convertMRRToTimeframe(value, company.revenueTimeframe))
      )}`;
    }
    return 'Unknown';
  };

  return Object.entries(company.thirdPartyCompanyFieldMapping)
    .map(([defaultFieldName, customFieldName]) => {
      const customField = customFields.find((field) => field.name === customFieldName);
      const fieldValue = thirdPartyCompany.customFields[customFieldName];
      const field = thirdPartyCompany.fieldsArray.find((field) => field.key === customFieldName);

      if (!customField) {
        return null;
      }

      return {
        label: toLabel(
          DefaultFieldLabels[defaultFieldName as ThirdPartyCompanyDefaultField],
          defaultFieldName !== customFieldName ? customFieldName : null
        ),
        suffix: getSourceSuffix(field?.source ?? null)?.render,
        value:
          defaultFieldName === ThirdPartyCompanyDefaultField.monthlySpend ? (
            getMonthlySpendValue(thirdPartyCompany.monthlySpend)
          ) : (
            <CustomFieldValue customField={customField} fieldValue={fieldValue} />
          ),
      };
    })
    .filter(Boolean);
};

const getCompanyFields = (
  thirdPartyCompany: ThirdPartyCompany,
  company: Company,
  customFields: CustomField[]
) => {
  // Get all data sources, deduped and validated.
  const dataSources = Array.from(
    new Set(thirdPartyCompany.fieldsArray.map((field) => field.source))
  )
    .filter(
      (item, index, self): item is keyof typeof sources =>
        self.indexOf(item) === index && Boolean(item) && !!getSourceSuffix(item)
    )
    .map((source) => getSourceSuffix(source) as SourceRenderer);

  const fields = [
    {
      label: 'Data source(s)',
      className: 'source',
      value:
        dataSources.length > 0
          ? dataSources.map((source, index) => {
              return (
                <span key={source.name || index} className="sourceItem">
                  {source.render} {source.name}
                  {index === dataSources.length - 1 ? '' : ', '}
                </span>
              );
            })
          : 'Unknown',
    },
    {
      label: 'Created',
      value: <TimestampWithFallback timestamp={thirdPartyCompany.companyCreated} />,
    },
    {
      label: 'Company ID',
      value: <ID id={thirdPartyCompany.thirdPartyCompanyID} />,
    },
    {
      label: 'User Count',
      value: thirdPartyCompany.userCount,
    },
    ...getCompanyDefaultFields(thirdPartyCompany, company, customFields),
    ...getCompanyCustomFields(thirdPartyCompany, company, customFields),
  ].filter(isNotNil);

  return fields;
};

const getUserFields = (user: CompanyUser, customFields: CustomField[]) => {
  // Get all data sources, deduped and validated.
  const dataSources = [
    user?.nameSource,
    user?.emailSource,
    user?.avatarURLSource,
    ...(user?.customFieldsArray.map((field) => field.source) ?? []),
    ...(user?.sources ?? []),
  ]
    .filter(
      (item, index, self): item is keyof typeof sources =>
        self.indexOf(item) === index && Boolean(item) && !!getSourceSuffix(item)
    )
    .map((source) => getSourceSuffix(source) as SourceRenderer);

  const fields = [
    {
      label: 'Data source(s)',
      className: 'source',
      value:
        dataSources.length > 0
          ? dataSources.map((source, index) => {
              return (
                <span key={source.name || index} className="sourceItem">
                  {source.render} {source.name}
                  {index === dataSources.length - 1 ? '' : ', '}
                </span>
              );
            })
          : 'Unknown',
    },
    {
      label: 'Account Created',
      value: <TimestampWithFallback timestamp={user.userCreated} />,
    },
    {
      label: 'Email',
      suffix: getSourceSuffix(user?.emailSource)?.render ?? null,
      value: user.email ? (
        <Copyable value={user.email}>
          <a href={`mailto:${user.email}`} className="email">
            {user.email}
          </a>
        </Copyable>
      ) : (
        'Unknown'
      ),
    },
    {
      label: 'Name',
      suffix: getSourceSuffix(user?.nameSource)?.render ?? null,
      value: user?.name,
    },
    {
      label: 'User ID',
      value: <ID id={user.thirdPartyUserID} />,
    },
    {
      label: 'Last Activity',
      value: <TimestampWithFallback timestamp={user.lastActivity} fallback="Never" />,
    },
    {
      label: 'Browser',
      value: user?.platform?.name || 'Unknown',
    },
    {
      label: 'OS',
      value: user?.platform?.os || 'Unknown',
    },
    ...getUserCustomFields(user, customFields),
  ].filter(Boolean);

  return fields;
};

// main component
const AdminUsersDetailsSidebar = ({ customFields, thirdPartyCompany, user }: Props) => {
  const company = useContext<Company>(CompanyContext);

  const userIsLoaded = user && !user.loading && !user.error && !user.notFound;
  const tpcIsLoaded =
    thirdPartyCompany &&
    !thirdPartyCompany.loading &&
    !thirdPartyCompany.error &&
    !thirdPartyCompany.notFound;

  return (
    <div className="adminUsersDetailsSidebar">
      {userIsLoaded && (
        <>
          <AdminUsersDetailsSidebarUserHeader user={user} />
          <div className="fieldSection">
            <SectionHeader sectionName="User details" />
            <FieldsTable fields={getUserFields(user, customFields)} />
          </div>
          <UserCompanies user={user} company={company} customFields={customFields} />
        </>
      )}
      {tpcIsLoaded && (
        <div className="fieldSection">
          <CompanyInfo
            thirdPartyCompany={thirdPartyCompany}
            company={company}
            customFields={customFields}
          />
        </div>
      )}
    </div>
  );
};

export default AdminUsersDetailsSidebar;

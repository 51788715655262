import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import CannyAttribution from 'common/CannyAttribution';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import Spinner from 'common/Spinner';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/public/_JoinBoardForm.scss';

class VerifyEmail extends Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    router: PropTypes.object,
  };

  state = {
    error: null,
  };

  componentDidMount() {
    const { location, router } = this.props;
    const { query } = location;
    const { verificationID } = query;

    if (!validateInput.sessionID(verificationID)) {
      router.replace({
        pathname: location.pathname,
        query: null,
      });
      return;
    }

    AJAX.post('/api/viewer/verifyEmail', { verificationID }, (response) => {
      // Reload page without query params to avoid infinite loop.
      window.location = window.location.pathname;
    });
  }

  renderAttribution() {
    const { boardURLName } = this.props;
    if (!boardURLName) {
      return null;
    }

    return <CannyAttribution source="feedback_subdomain" />;
  }

  renderContents() {
    const { error } = this.state;
    if (error) {
      return <div className="error">{error}</div>;
    }

    return <Spinner />;
  }

  render() {
    return (
      <div className="joinBoardForm">
        {this.renderContents()}
        {this.renderAttribution()}
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  router: RouterContext,
})(VerifyEmail);

import Colors from 'common/colors/constants';

import type { PillStyle } from 'common/common/Pill';

export enum VoteWeight {
  niceToHave = 0,
  important = 10,
  mustHave = 20,
}

type Pill = {
  name: string;
  pillStyle: PillStyle;
};

export const WeightMap: Record<VoteWeight, Pill> = {
  0: {
    name: 'Nice to have',
    pillStyle: {
      color: Colors.blue90,
      background: Colors.blue30,
    },
  },
  10: {
    name: 'Important',
    pillStyle: {
      background: Colors.purple30,
      color: Colors.purple90,
    },
  },
  20: {
    name: 'Must have',
    pillStyle: {
      background: Colors.orange30,
      color: Colors.orange90,
    },
  },
};

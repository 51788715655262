import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { HelpscoutSettings } from 'common/api/endpoints/helpscout';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: helpscoutSettingsRequested,
  loadedAction: helpscoutSettingsLoaded,
  errorAction: helpscoutSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<HelpscoutSettings>('helpscoutSettings');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchHelpscoutSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          helpscoutSettings: {
            query: Data.queries.helpscoutSettings,
          },
        },
        cookies
      );
      return dispatch(helpscoutSettingsLoaded(response.helpscoutSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(helpscoutSettingsError(message));
    }
  };
}

export function loadHelpscoutSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(helpscoutSettingsRequested());
    return dispatch(fetchHelpscoutSettings());
  };
}

export function reloadHelpscoutSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchHelpscoutSettings());
  };
}

function shouldFetch(state: State) {
  return !state.helpscoutSettings.data;
}

import React from 'react';

import Colors from 'common/colors/constants';

const HeartPath = `M10 1C8.35 1 7 2.42751 7
  4.17224C7 2.42751 5.65 1 4
  1C2.35 1 1 2.42751 1
  4.17224C1 4.80669 1.1875 5.44114 1.4875
  5.91698C2.0125 6.74969 7 11.6667 7
  11.6667C7 11.6667 11.95 6.78934 12.475
  5.95663C12.8125 5.44114 13 4.84635 13
  4.17224C13 2.42751 11.65 1 10 1Z`;

type Props = {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
};

const Heart = ({ fill, stroke = Colors.gray90, width = '14', height = '13' }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d={HeartPath}
        fill={fill}
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Heart;

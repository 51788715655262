import { loadCompany } from './company';
import Data from '../Data';

// Action Types

export const EntryError = 'canny/changelog_entries/entry_error';
export const EntryLoaded = 'canny/changelog_entries/entry_loadde';
export const EntryNotFound = 'canny/changelog_entries/entry_not_found';
export const InvalidateAll = 'canny/changelog_entries/invalidate_all';
export const RequestEntry = 'canny/changelog_entries/request_entry';

// Actions

function requestEntry(entryURLName) {
  return {
    entryURLName,
    timestamp: Date.now(),
    type: RequestEntry,
  };
}

export function entryLoaded(entryURLName, entry) {
  return {
    entry: entry,
    entryURLName,
    timestamp: Date.now(),
    type: EntryLoaded,
  };
}

function entryNotFound(entryURLName) {
  return {
    entryURLName,
    timestamp: Date.now(),
    type: EntryNotFound,
  };
}

function entryError(entryURLName, error) {
  return {
    error,
    entryURLName,
    timestamp: Date.now(),
    type: EntryError,
  };
}

function invalidateAll() {
  return {
    timestamp: Date.now,
    type: InvalidateAll,
  };
}

// Action Creators

function fetchEntry(entryURLName) {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      const company = getState().company;
      if (company.error || company.notFound) {
        return dispatch(entryError('company load failed'));
      }

      if (company.changelog && !company.changelog.viewerHasAccess) {
        return dispatch(entryError('cannot access changelog'));
      }

      const cookies = getState().cookies;
      return Data.fetch(
        {
          entry: {
            input: {
              entryURLName,
            },
            query: Data.queries.changelogEntry,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, getState(), entryURLName, error, data);
        }
      );
    });
  };
}

export function loadEntry(entryURLName) {
  return (dispatch, getState) => {
    if (shouldFetchEntry(getState(), entryURLName)) {
      dispatch(requestEntry(entryURLName));
      return dispatch(fetchEntry(entryURLName));
    } else if (isFetchingEntry(getState(), entryURLName)) {
      return waitForResult(entryURLName);
    }
  };
}

export function reloadEntry(entry) {
  return (dispatch) => {
    return dispatch(fetchEntry(entry.urlName));
  };
}

export function reloadEntryByURLName(entryURLName) {
  return (dispatch) => {
    return dispatch(fetchEntry(entryURLName));
  };
}

export function invalidateEntries() {
  return (dispatch) => {
    return dispatch(invalidateAll());
  };
}

// Helper Functions

function shouldFetchEntry(state, entryURLName) {
  if (!entryURLName) {
    return false;
  }

  const { changelogEntries } = state;
  const entry = changelogEntries[entryURLName];
  return !entry || !entry.posts;
}

function isFetchingEntry(state, entryURLName) {
  if (!entryURLName) {
    return false;
  }

  return !!state.changelogEntries[entryURLName].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(entryURLName) {
  return new Promise((resolve, reject) => {
    resultCallbacks[entryURLName] = resultCallbacks[entryURLName] || [];
    resultCallbacks[entryURLName].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, state, entryURLName, error, data) {
  var resultAction;
  if (error === 'not found') {
    resultAction = entryNotFound(entryURLName);
  } else if (error) {
    resultAction = entryError(entryURLName, error);
  } else {
    resultAction = entryLoaded(entryURLName, data.entry);
  }

  const promises = [dispatch(resultAction)];

  return Promise.all(promises).then(() => {
    if (!resultCallbacks[entryURLName]) {
      return;
    }

    resultCallbacks[entryURLName].forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks[entryURLName].length = 0;
  });
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminGoogleAnalyticsSettings.scss';

class AdminGoogleAnalyticsSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      gaPropertyID: PropTypes.string,
    }),
    openModal: PropTypes.func,
  };

  state = {
    deleting: false,
    error: null,
    propertyID: this.props.company.gaPropertyID || '',
    saving: false,
  };

  constructor(props, context) {
    super(props, context);

    this.propertyIDRef = React.createRef();
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      error: null,
      propertyID: value.trim(),
    });
  };

  onRemove = () => {
    const { deleting } = this.state;
    if (deleting) {
      return;
    }

    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to remove your tracking ID?",
      onConfirm: () => {
        this.setState({
          deleting: true,
        });

        AJAX.post('/api/googleAnalytics/delete', {}, (response) => {
          if (response === 'success') {
            const { reloadCompany } = this.props;
            reloadCompany().then(() => {
              this.setState({
                deleting: false,
                error: null,
                propertyID: '',
              });
              this.propertyIDRef.current.setValue('');
            });
            return;
          }

          this.setState({
            deleting: false,
            error: 'Something went wrong, please try again later.',
          });
        });
      },
    });
  };

  onSave = () => {
    const { propertyID, saving } = this.state;
    if (saving) {
      return;
    }

    this.setState({
      saving: true,
    });

    AJAX.post(
      '/api/googleAnalytics/upsert',
      {
        propertyID,
      },
      (response) => {
        if (response === 'success') {
          this.props.reloadCompany().then(() => {
            this.setState({
              saving: false,
            });
          });
          return;
        }

        this.setState({
          error: 'Something went wrong, please try again later.',
          saving: false,
        });
      }
    );
  };

  renderForm() {
    const { company } = this.props;
    const { deleting, propertyID, saving } = this.state;
    const sameAsExisting = propertyID === company.gaPropertyID;
    const validPropertyID = validateInput.googleAnalytics.propertyID(propertyID);
    const isButtonDisabled = deleting || sameAsExisting || !validPropertyID;
    return (
      <Form
        className="trackingForm"
        addEventsToDocument={false}
        disableSubmit={isButtonDisabled || saving}
        onSubmit={this.onSave}>
        <div className="text">
          Add your Google Analytics tracking code to track traffic on your Canny subdomain (
          {company.subdomain}.canny.io).
        </div>
        <div className="horizontalContainer">
          <TextInput
            defaultValue={company.gaPropertyID || ''}
            disabled={deleting || saving}
            onChange={this.onChange}
            placeholder="G-123456789A"
            ref={this.propertyIDRef}
          />
          {this.renderRemoveButton()}
        </div>
        {this.renderError()}
        <Button disabled={isButtonDisabled} formButton={true} loading={saving} value="Save" />
      </Form>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderRemoveButton() {
    const { company } = this.props;
    if (!company || !company.gaPropertyID) {
      return null;
    }

    const { deleting } = this.state;
    if (deleting) {
      return <Spinner />;
    }

    return (
      <Tappable onTap={this.onRemove}>
        <div className="removeButton">Remove</div>
      </Tappable>
    );
  }

  render() {
    return (
      <div className="adminGoogleAnalyticsSettings">
        <Helmet title="Google Analytics Integration | Canny" />
        <div className="settingsHeading">Google Analytics Integration</div>
        {this.renderForm()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.integrations.googleAnalytics),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    { forwardRef: true }
  )
)(AdminGoogleAnalyticsSettings);

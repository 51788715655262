import React from 'react';

import AdminOnboardingHeader, { AdminOnboardingSubHeader } from './AdminOnboardingHeader';
import AdminOnboardingMultiSelect from './AdminOnboardingMultiSelect';
import { type UseCase } from './Types';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  onChange: (useCase: UseCase) => void;
  useCase: UseCase;
};

const AdminOnboardingHow = ({ onChange, useCase }: Props) => {
  // helpers
  const onChangeSelect = (motivations: string[]) => {
    const wantsPrivate =
      motivations.includes('autopilotFeedback') || motivations.includes('behalfFeedback');
    onChange({
      ...useCase,
      motivations,
      private: wantsPrivate,
    });
  };

  const { motivations } = useCase;
  return (
    <div className="onboardingHow">
      <AdminOnboardingHeader>How do you want to collect feedback?</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>Select all that apply:</AdminOnboardingSubHeader>
      <AdminOnboardingMultiSelect
        onChange={onChangeSelect}
        options={[
          {
            name: 'autopilotFeedback',
            label: 'Automatically detect and capture from user interactions',
          },
          { name: 'behalfFeedback', label: "Manually enter on our user's behalf" },
          { name: 'directFeedback', label: 'Give users a place to post ideas' },
        ]}
        selected={motivations}
      />
    </div>
  );
};

export default AdminOnboardingHow;

import React, { useEffect, useState } from 'react';

import connect from 'common/core/connect';
import setCookie from 'common/setCookie';
import Themes, { type Theme, calculateTheme, getThemeFromBrowserSettings } from 'common/util/theme';

export const ThemeContext = React.createContext<{
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
} | null>(null);

type OwnProps = {
  children: React.ReactNode;
  configTheme?: Theme;
};

type ConnectProps = {
  cookies?: Record<string, string>;
};

type Props = OwnProps & ConnectProps;

const ThemeContainer = ({ children, configTheme, cookies }: Props) => {
  const [browserTheme, setBrowserTheme] = useState(getThemeFromBrowserSettings(cookies));
  const [theme, setTheme] = useState(() => calculateTheme({ configTheme, browserTheme }));

  useEffect(() => {
    // dont listen if matchmedia is not supported
    if (!window.matchMedia) {
      return;
    }

    const onBrowserThemeChange = (event: MediaQueryListEvent) =>
      setBrowserTheme(event.matches ? Themes.dark : Themes.light);
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // https://www.designcise.com/web/tutorial/how-to-fix-the-javascript-typeerror-matchmedia-addeventlistener-is-not-a-function
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', onBrowserThemeChange);
    } else {
      mediaQuery.addListener(onBrowserThemeChange);
    }

    return () => {
      if (mediaQuery?.removeEventListener) {
        mediaQuery.removeEventListener('change', onBrowserThemeChange);
      } else {
        mediaQuery.removeListener(onBrowserThemeChange);
      }
    };
  }, []);

  useEffect(() => {
    setTheme(calculateTheme({ configTheme, browserTheme }));
  }, [browserTheme, configTheme]);

  useEffect(() => {
    setCookie('__canny__browserTheme', browserTheme);
  }, [browserTheme]);

  useEffect(() => {
    const html = document.querySelector('html');
    html?.classList.remove('darkTheme', 'lightTheme');
    html?.classList.add(theme === 'dark' ? 'darkTheme' : 'lightTheme');
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

// TODO: remove cast once `connect` is typed
export default connect((state: any) => ({ cookies: state.cookies }))(
  ThemeContainer
) as unknown as React.FC<OwnProps>;

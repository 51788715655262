import React, { useContext, useState } from 'react';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import IntercomTeamInboxSourceFilter from 'common/subdomain/admin/AdminQueue/SourceFilters/IntercomTeamInboxSourceFilter';
import ButtonV2 from 'common/ui/ButtonV2';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/AdminIntercomSettings/_AdminIntercomSettingsTeamInboxes.module.scss';

export default function AdminIntercomSettingsTeamInboxes() {
  const showToast = useContext(ShowToastContext);
  const company = useContext(CompanyContext);
  const { intercom } = company;

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedInboxIDs, setSelectedInboxIDs] = useState<string[] | null>(
    intercom?.autopilotTeamInboxIDs ?? null
  );

  if (!intercom || intercom.lostAccess) {
    return null;
  }

  const intercomSourceEnabled = !!company.installedSyncIntegrations.find((integration) => {
    return integration.integrationName === 'intercom' && !integration.disabled;
  });

  if (!intercomSourceEnabled) {
    return null;
  }

  const handleChange = (teamInboxIDs: string[] | null) => {
    setHasChanges(true);
    setSelectedInboxIDs(teamInboxIDs);
  };

  const handleSave = async () => {
    setSaving(true);
    setSaveError(false);

    const response = await AJAX.post('/api/intercom/setTeamInboxes', {
      teamIDs: selectedInboxIDs,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    setSaving(false);

    if (error) {
      setSaveError(true);
    } else {
      showToast('Team inboxes saved.');
      setHasChanges(false);
    }
  };

  return (
    <div className={styles.adminIntercomSettingsTeamInboxes}>
      <H2 variant="headingMd">Autopilot settings</H2>
      <P className={styles.description} variant="bodyMd">
        Use filters to specify which conversations are processed by Autopilot.
      </P>
      <div className={styles.filterContainer}>
        <IntercomTeamInboxSourceFilter
          handleChange={handleChange}
          selectedInboxIDs={selectedInboxIDs}
        />
        {saveError && (
          <P className={styles.saveError} variant="bodySm">
            Something went wrong, please try again later.
          </P>
        )}
        <ButtonV2
          className={styles.saveButton}
          size="medium"
          disabled={!hasChanges}
          loading={saving}
          onClick={handleSave}>
          Save
        </ButtonV2>
      </div>
    </div>
  );
}

import React from 'react';

import { P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/_ReviewIntegrationSearch.module.scss';

import type { SearchResult } from './ReviewIntegrationSearch';

type Props = {
  suggestion: SearchResult;
};

const ReviewIntegrationSearchSuggestion = ({ suggestion }: Props) => {
  return (
    <div className={styles.searchSuggestion} key={suggestion.name}>
      <P variant="bodyMd">{suggestion.name}</P>
      {suggestion.link && (
        <>
          :&nbsp;
          <a
            className={styles.sourceLink}
            href={suggestion.link}
            rel="noopener"
            target="_blank"
            onClick={(e) => e.stopPropagation()}>
            See Source
          </a>
        </>
      )}
    </div>
  );
};

export default ReviewIntegrationSearchSuggestion;

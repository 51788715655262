import React, { useContext } from 'react';

import InformationBox from 'common/common/InformationBox';
import { CompanyContext } from 'common/containers/CompanyContainer';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import { GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterPlanID } from 'common/util/isStarter';

const AdminIntegrationLimitUpsell = () => {
  const company = useContext(CompanyContext);
  const integrationLimit = company.limits.integrations;
  const planID = integrationLimit === 1 ? StarterPlanID : GrowthPlanID;
  const amount = isStarter(planID) ? 'More' : 'Unlimited';

  return (
    <>
      <InformationBox icon="!" className="upsellNote">
        You are at your integration limit, upgrade to install more integrations.
      </InformationBox>
      <AdminFeatureUpsell
        cta={`${amount} integrations to suit your workflow`}
        feature="limits.integrations"
        planID={planID}
      />
    </>
  );
};

export default AdminIntegrationLimitUpsell;

import {
  DiscordSettingsError,
  DiscordSettingsLoaded,
  RequestDiscordSettings,
} from 'common/actions/discordSettings';

export default function discordSettings(state = null, action) {
  switch (action.type) {
    case DiscordSettingsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case DiscordSettingsLoaded: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        ...action.discordSettings,
      });
    }

    case RequestDiscordSettings: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    default:
      return state;
  }
}

import React from 'react';

import Helmet from './Helmet';

const SubdomainAccountSettingsHelmet = () => {
  const title = 'Account Settings | Canny';
  return <Helmet title={title} />;
};

export default SubdomainAccountSettingsHelmet;

import { RequestViewer, ViewerError, ViewerLoaded, ViewerLoggedOut } from '../actions/viewer';

export default function viewer(state = null, action) {
  switch (action.type) {
    case RequestViewer:
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
        loggedOut: false,
      });

    case ViewerLoaded:
      return Object.assign(
        {},
        state,
        {
          lastUpdated: action.timestamp,
          loading: false,
          loggedOut: false,
        },
        action.viewer
      );

    case ViewerLoggedOut:
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        loading: false,
        loggedOut: true,
      });

    case ViewerError:
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });

    default:
      return state;
  }
}

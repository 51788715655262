import { Component } from 'react';

import PropTypes from 'prop-types';

import { loadPost } from 'common/actions/posts';
import { loadPostActivity } from 'common/actions/postsActivity';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import connect from 'common/core/connect';

class RoadmapPostContainer extends Component {
  static propTypes = {
    boards: PropTypes.array,
    loadPost: PropTypes.func,
    post: PropTypes.shape({
      boardID: PropTypes.string,
      urlName: PropTypes.string,
    }),
    posts: PropTypes.object,
    postsActivity: PropTypes.object,
  };

  state = {
    board: null,
  };

  async componentDidMount() {
    this.loadPostData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.post?._id !== this.props.post?._id) {
      this.loadPostData();
    }
  }

  async loadPostData() {
    const { boards, loadPost, post } = this.props;
    if (!post) {
      return;
    }
    const board = boards.find((board) => board._id === post.boardID);
    await loadPost(board, post);
    this.setState({ board });
  }

  render() {
    const { boards, children, posts, postsActivity } = this.props;
    const { board } = this.state;
    const post = posts[board?._id]?.[this.props.post?.urlName];
    const postActivity = postsActivity[post?._id];
    if (!postActivity) {
      return null;
    }

    return cloneElementWithProps(children, {
      board: board ?? null,
      boards,
      post: post ?? null,
      postActivity,
    });
  }
}

export default connect(
  (state) => ({
    posts: state.posts,
    postsActivity: state.postsActivity,
  }),
  (dispatch) => ({
    loadPost: (board, post) => {
      return Promise.all([
        dispatch(loadPost(board, post.urlName)),
        dispatch(loadPostActivity(post)),
      ]);
    },
  })
)(RoadmapPostContainer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { reloadCompany } from 'common/actions/company';
import { reloadNotifications } from 'common/actions/notifications';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import AdminPageSidebar from 'common/subdomain/admin/AdminPageSidebar';
import AdminSidebarBoardSection from 'common/subdomain/admin/AdminSidebarBoardSection';
import AdminSidebarNotificationActionSection from 'common/subdomain/admin/AdminSidebarNotificationActionSection';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import 'css/components/subdomain/admin/_AdminNotificationsSidebar.scss';

class AdminNotificationsSidebar extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(PropTypes.object),
    company: PropTypes.object,
    reloadCompany: PropTypes.func,
    reloadNotifications: PropTypes.func,
  };

  state = {
    error: null,
    selectedBoards: [],
    selectedNotificationActionNames: [],
  };

  componentDidMount() {
    this.handleDefaultNotificationSettings();
  }

  handleDefaultNotificationSettings = () => {
    const { viewerNotificationSettings } = this.props.company;

    if (!viewerNotificationSettings) {
      this.setState({ selectedBoards: this.props.boards });
      return;
    }

    const { boardIDs } = viewerNotificationSettings;
    const selectedBoards = this.props.boards.filter((board) => boardIDs.includes(board._id));

    this.setState({ selectedBoards });
  };

  updateNotificationSettings = async () => {
    const { company } = this.props;
    const { selectedBoards, selectedNotificationActionNames: notificationActions } = this.state;
    const boardIDs = selectedBoards.map((board) => board._id);

    const response = await AJAX.post('/api/notifications/settings/update', {
      boardIDs,
      companyID: company._id,
      notificationActions,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    this.setState({ error: null });

    this.props.reloadCompany();
    this.props.reloadNotifications();
  };

  onBoardsChange = (boardMap) => {
    const selectedBoards = this.props.boards.filter((board) => boardMap[board.urlName]);
    this.setState({ selectedBoards }, this.updateNotificationSettings);
  };

  onNotificationActionsChange = (selectedNotificationActionNames, areSettingsDefault = false) => {
    this.setState(
      { selectedNotificationActionNames },
      areSettingsDefault ? null : this.updateNotificationSettings
    );
  };

  renderErrorSection() {
    const { error } = this.state;

    if (!error) {
      return;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    return (
      <div className="adminNotificationsSidebar">
        <AdminPageSidebar showSidebar={true}>
          <AdminSidebarBoardSection
            boards={this.props.boards}
            onBoardsChange={this.onBoardsChange}
            selectedBoards={this.state.selectedBoards}
            showPostCount={false}
          />
          <AdminSidebarNotificationActionSection
            onNotificationActionsChange={this.onNotificationActionsChange}
          />
          {this.renderErrorSection()}
        </AdminPageSidebar>
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  reloadCompany: () => dispatch(reloadCompany()),
  reloadNotifications: () => dispatch(reloadNotifications()),
}))(AdminNotificationsSidebar);

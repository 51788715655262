import React, { useContext, useState } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import UserPicker from 'common/user/UserPicker';
import findStringMatches from 'common/util/findStringMatches';

import type { Company, Member } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminPicker.scss';

type Props = Omit<React.ComponentProps<typeof UserPicker<Member>>, 'onSearch' | 'users'>;

const getMemberSuggestions = (searchValue: string, members: Member[]) => {
  const sortedAdmins = [...members].sort((a, b) => {
    const aComesFirst = a.name.toLowerCase() < b.name.toLowerCase();
    const bComesFirst = a.name.toLowerCase() > b.name.toLowerCase();

    if (aComesFirst) {
      return -1;
    } else if (bComesFirst) {
      return 1;
    } else {
      return 0;
    }
  });

  if (searchValue === '') {
    return sortedAdmins;
  }

  return findStringMatches(sortedAdmins, 'name', searchValue);
};

const AdminDropdown = (props: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [searchValue, setSearchValue] = useState<string>('');

  const memberSuggestions = getMemberSuggestions(searchValue, company.members);
  return (
    <UserPicker<Member>
      {...props}
      onSearch={setSearchValue}
      onUserSelected={props.onUserSelected}
      showBadge={false}
      showSearch={company.members.length > 5}
      relativeElementRef={props.relativeElementRef}
      users={memberSuggestions}
    />
  );
};

export default AdminDropdown;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminUsersCompanyListItem.scss';

class AdminUsersCompanyListItem extends Component {
  static propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
    router: PropTypes.object,
    thirdPartyCompany: PropTypes.object.isRequired,
  };

  onCompanySelected = () => {
    const { location, router, thirdPartyCompany } = this.props;
    router.replace({
      pathname: `/admin/users/company/${thirdPartyCompany.urlName}`,
      query: location.query,
    });
  };

  render() {
    const { params, thirdPartyCompany } = this.props;
    const selected = params.companyURLName === thirdPartyCompany.urlName;
    const className = classnames('adminUsersCompanyListItem', { selected });

    return (
      <Tappable onTap={this.onCompanySelected}>
        <div className={className}>
          <div className="name">{thirdPartyCompany.name}</div>
        </div>
      </Tappable>
    );
  }
}

export default withContexts({
  location: LocationContext,
  params: ParamsContext,
  router: RouterContext,
})(AdminUsersCompanyListItem);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import translateString from 'common/i18n/translateString';
import LazyLoadedImage from 'common/LazyLoadedImage';
import plaintext from 'common/markdown/plaintext';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import withContexts from 'common/util/withContexts';

import PostCommentCount from './PostCommentCount';
import PostLink from './PostLink';
import PostStatus from './PostStatus';
import PostTitle from './PostTitle';
import PostVotesV2 from './PostVotes/PostVotesV2';
import Truncate from '../common/Truncate';
import UppercaseHeader from '../UppercaseHeader';

import ClockIcon from 'img/icons/clock.svg';

import 'css/components/post/_PostListItem.scss';

class PostListItem extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.shape({
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    }),
    linkToAdmin: PropTypes.bool.isRequired,
    post: PropTypes.shape({
      board: PropTypes.object,
      details: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    showBoard: PropTypes.bool.isRequired,
    showComments: PropTypes.bool.isRequired,
    showDetails: PropTypes.bool.isRequired,
    showStatus: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    showBoard: false,
    showComments: true,
    showDetails: true,
    showStatus: false,
  };

  shouldDisplayBoard() {
    const {
      showBoard,
      post: { board },
    } = this.props;
    return showBoard && board;
  }

  renderBoard() {
    const { board } = this.props.post;
    if (!this.shouldDisplayBoard()) {
      return null;
    }

    return (
      <UppercaseHeader className="boardName">{translateString(board, 'name')}</UppercaseHeader>
    );
  }

  renderCommentCount() {
    const { post, showComments } = this.props;
    if (!showComments || post.commentCount === null || Number.isNaN(post.commentCount)) {
      return null;
    }

    return <PostCommentCount post={post} />;
  }

  renderDetails() {
    const {
      post: { details },
      showDetails,
    } = this.props;
    if (!showDetails || !details) {
      return null;
    }

    const includeMargin = this.shouldDisplayBoard();

    return (
      <div className={classnames('postDetails', { includeMargin })}>
        <Truncate numberOfLines={2}>{plaintext(details)}</Truncate>
      </div>
    );
  }

  renderStale() {
    const { post, showStale } = this.props;
    const { statusChanged } = post;
    if (!showStale) {
      return null;
    }

    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const timeAgo = new Date() - new Date(statusChanged);
    const weeksAgo = Math.floor(timeAgo / oneWeek);

    return (
      <div className="stale">
        <LazyLoadedImage src={ClockIcon} />
        <UppercaseHeader className="weeksStale">{weeksAgo} weeks</UppercaseHeader>
      </div>
    );
  }

  renderStatus() {
    const {
      showStatus,
      post: { status },
    } = this.props;
    if (!showStatus) {
      return null;
    }

    return (
      <div className="statusStale">
        <PostStatus status={status} />
        {this.renderStale()}
      </div>
    );
  }

  render() {
    const { company, linkToAdmin, post } = this.props;

    const status = company.statuses.find((status) => status.name === post.status);
    const hasStatus = status?.type === PostStatusTypes.Initial;
    const hasDetails = !!post.details;

    return (
      <div className="postListItem">
        <PostVotesV2 post={post} />
        <PostLink
          className={classnames({ noDetails: !(hasDetails || hasStatus) })}
          linkToAdmin={linkToAdmin}
          post={post}>
          <div className="body">
            <PostTitle post={post} />
            {this.renderStatus()}
            {this.renderDetails()}
            {this.renderBoard()}
          </div>
          {this.renderCommentCount()}
        </PostLink>
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(PostListItem);

import React from 'react';

import escapeRegExp from 'common/util/escapeRegExp';

export default function emphasizeMatches(string: string, search: string) {
  if (!search || !search.trim()) {
    return string;
  }

  const parts = [];
  const regex = new RegExp(escapeRegExp(search), 'ig');
  let key = 1;
  let match = regex.exec(string);
  let position = 0;

  while (match) {
    if (match.index > position) {
      parts.push(
        <div className="text" key={key++}>
          {string.substring(position, match.index)}
        </div>
      );
    }
    parts.push(
      <div className="emphasize" key={key++}>
        {match[0]}
      </div>
    );
    position = match.index + match[0].length;
    match = regex.exec(string);
  }

  if (position < string.length) {
    parts.push(
      <div className="text" key={key++}>
        {string.substring(position, string.length)}
      </div>
    );
  }

  return parts;
}

import PropTypes from 'prop-types';

// TODO: define a better shape for installation
export const installation = PropTypes.arrayOf(PropTypes.object);
export const rules = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string,
    allLinkedWorkItems: PropTypes.bool,
    cannyStatus: PropTypes.string,
    azureDevopsState: PropTypes.string,
  })
);
export const states = PropTypes.arrayOf(
  PropTypes.shape({
    category: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
  })
);

import { Component } from 'react';

import PropTypes from 'prop-types';

import { ViewerContext } from 'common/containers/ViewerContainer';
import withContexts from 'common/util/withContexts';

const AppID = '58a6799d8d61b9968dad91a2';

class CannyContainer extends Component {
  static propTypes = {
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      avatarURL: PropTypes.string,
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          monthlySpend: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  componentDidMount() {
    if (__DEV__) {
      return;
    }

    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return;
    }

    Canny('identify', {
      appID: AppID,
      hash: viewer.cannyHash,
      user: {
        ...(viewer.avatarURL && {
          avatarURL: viewer.avatarURL,
        }),
        companies: viewer.companies.map((company) => ({
          created: new Date(company.created).toISOString(),
          id: company._id,
          monthlySpend: company.monthlySpend / 100,
          name: company.name,
        })),
        created: new Date(viewer.created).toISOString(),
        email: viewer.email,
        id: viewer._id,
        name: viewer.name,
      },
    });
  }

  render() {
    return this.props.children;
  }
}

export default withContexts({ viewer: ViewerContext })(CannyContainer);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { invalidateUserQueries } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import Tooltip from 'common/common/Tooltip';
import { LoadStatus } from 'common/constants/files';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import FileRenderer from 'common/file/FileRenderer';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import getValidFileURLs from 'common/file/utils/getValidFileURLs';
import uploadFile from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import MentionsTextarea from 'common/inputs/MentionsTextarea';
import UploadFileButton from 'common/inputs/UploadFileButton';
import UpsellModal from 'common/modals/UpsellModal';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import UserAvatar from 'common/user/UserAvatar';
import hasPermission from 'common/util/hasPermission';
import { StarterPlanID } from 'common/util/isStarter';
import { getMentionTag } from 'common/util/mentions';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminFeedbackCommentComposer.scss';

class AdminFeedbackCommentComposer extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    board: PropTypes.object.isRequired,
    company: PropTypes.shape({
      billingData: PropTypes.object,
    }).isRequired,
    onCommentCreated: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    post: PropTypes.shape({
      _id: PropTypes.string,
    }),
    replyTo: PropTypes.object,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    onCommentCreated: () => {},
  };

  state = {
    files: [],
    focused: this.props.autoFocus,
    initialMentionedUsers: [
      this.props.replyTo?.author,
      ...(this.props.comment?.mentionedUsers ?? []),
    ].filter(Boolean),
    isInternal: this.isCommentInternal(),
    showUpsellModal: false,
    submitting: false,
    value: '',
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    const { replyTo, viewer } = this.props;
    document.addEventListener('mousedown', this.onDocumentClick, false);

    if (!replyTo) {
      return;
    }

    const { author } = replyTo;
    if (!author) {
      this.setState({ value: '' });
      return;
    }

    const authorIsViewer = author._id === viewer._id;
    const authorMention = `${getMentionTag(author)}: `;
    const nextValue = !authorIsViewer ? authorMention : '';
    this.setState({ value: nextValue }, () => {
      const textarea = this.textareaRef.current?.getWrappedInstance();
      if (textarea) {
        const cursor = nextValue.length;
        textarea.setSelectionRange?.(cursor, cursor, 'forward');
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { post: prevPost } = prevProps;
    const { post } = this.props;

    if (prevPost?._id === post?._id) {
      return;
    }

    this.setState({ isInternal: this.isCommentInternal() });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClick, false);
  }

  areFilesUploading = () => {
    return this.state.files.some((file) => file.uploadStatus !== LoadStatus.loaded);
  };

  isCommentInternal() {
    const { comment, company, replyTo, viewer } = this.props;
    const planSupports = company.features?.internalComments;

    const canCreatePublicComments = hasPermission('createPublicComment', company, viewer);

    if (!canCreatePublicComments) {
      return true;
    }

    if (comment) {
      return comment.internal;
    }

    if (replyTo) {
      return replyTo.internal;
    }

    return planSupports;
  }

  onDocumentClick = (e) => {
    const { focused, showUpsellModal } = this.state;
    if (!focused || showUpsellModal) {
      return;
    }

    const container = this.containerRef.current;
    if (!container?.contains(e.target)) {
      // touchstart happens outside of composer, collapse composer
      this.setState({ focused: false, error: null });
      return;
    }
  };

  onChange = (value) => {
    this.setState({ value });
  };

  onFile = async (file) => {
    const { viewer } = this.props;

    await uploadFile({
      file,
      viewer,
      onFileError: this.onFileError,
      onFileUploading: this.onFileUploading,
      onFileUploaded: this.onFileUploaded,
    });
  };

  onFileError = (file, error) => {
    this.setState((state) => ({
      error,
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFileUploading = (file) => {
    this.setState((state) => ({
      files: [...state.files, file],
    }));
  };

  onFileUploaded = (file) => {
    this.setState((state) => ({
      files: state.files.map((f) => (f.uniqueID === file.uniqueID ? file : f)),
    }));
  };

  onFileRemoved = (file) => {
    this.setState((state) => ({
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onSubmit = () => {
    const { post, replyTo, viewer } = this.props;
    const { files, isInternal, value } = this.state;
    const { imageURLs, nonImageFileURLs } = getValidFileURLs(files);

    if (!value && imageURLs.length === 0 && nonImageFileURLs.length === 0) {
      this.setState({ error: 'Oops! You forgot to enter a comment.' });
      return;
    }

    const commentData = {
      fileURLs: JSON.stringify(nonImageFileURLs),
      imageURLs: JSON.stringify(imageURLs),
      internal: isInternal,
      postID: post._id,
      value: value,
    };

    if (replyTo) {
      commentData.parentID = replyTo.parentID ?? replyTo._id;
    }

    if (viewer._id) {
      this.onSaveComment(commentData);
      return;
    }
  };

  onSaveComment = async (commentData) => {
    this.setState({
      error: null,
      submitting: true,
    });

    const endpointURL = '/api/comments/create';
    const response = await AJAX.post(endpointURL, commentData);
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'character limit': 'Please make your comment between 1 and 2500 characters.',
        'slow down': `You are trying to create comments too fast. Please wait a few minutes before trying again.`,
        spam: `Our system identifies parts of this comment as spam. Please, try with different comment.`,
        'author does not have permission':
          "You don't have permission to create public comments. Speak to a teammate to get access.",
      },
    });

    if (error) {
      this.setState({
        error: error.message,
        submitting: false,
      });
      return;
    }

    await this.props.invalidateData(this.props.post);
    this.setState({
      files: [],
      submitting: false,
      focused: false,
      value: '',
    });

    this.props.onCommentCreated();
  };

  onTogglePrivacy = () => {
    const { isInternal } = this.state;
    if (isInternal) {
      this.togglePrivacy();
      return;
    }

    const { features } = this.props.company;
    const planSupports = features?.internalComments;
    if (!planSupports) {
      this.setState({ showUpsellModal: true });
      return;
    }

    this.togglePrivacy();
  };

  onUpsell = () => {
    this.setState({ showUpsellModal: false });
    this.togglePrivacy();
  };

  onUpsellDismiss = () => {
    this.setState({ showUpsellModal: false });
  };

  hasContent() {
    const { files, value } = this.state;
    return value || files.length > 0;
  }

  togglePrivacy = () => {
    const { replyTo } = this.props;
    this.setState(
      ({ isInternal, value }) => ({
        isInternal: !isInternal,
        value: replyTo ? this.processCommentWithReplyMention() : value,
      }),
      () => {
        const { value } = this.state;
        const cursor = value.length;
        const textarea = this.textareaRef.current?.getWrappedInstance();

        if (textarea) {
          // re-focus textarea and move cursor to the end
          textarea.focus();
          textarea.setSelectionRange?.(cursor, cursor, 'forward');
        }
      }
    );
  };

  processCommentWithReplyMention = () => {
    const { isInternal, value } = this.state;
    const { company, replyTo, viewer } = this.props;

    if (!replyTo.author) {
      return value;
    }

    const authorIsViewer = replyTo.author._id === viewer._id;
    const authorIsMember = company.members.some((m) => {
      return m._id === replyTo.author._id;
    });

    if (authorIsMember || authorIsViewer) {
      return value;
    }

    const authorMention = `${getMentionTag(replyTo.author)}: `;
    if (!isInternal && !replyTo.internal && value.startsWith(authorMention)) {
      // Remove author mention when switching from public to internal on a reply
      return value.replace(authorMention, '');
    } else if (isInternal && !value.startsWith(authorMention)) {
      // Re-add author mention when switching from internal to public on a reply
      return `${authorMention}${value}`;
    }

    return value;
  };

  renderTeamNotifyMessage() {
    const { replyTo } = this.props;
    const { isInternal } = this.state;
    if (replyTo) {
      return null;
    }

    return isInternal ? (
      <div className="teamNotifyMessage">Mentioned admins will be notified.</div>
    ) : (
      <div className="teamNotifyMessage">The post author and voters will get an&nbsp;email.</div>
    );
  }

  renderFileInput() {
    const { focused } = this.state;
    if (!focused && !this.hasContent()) {
      return null;
    }

    return (
      <div className="fileInputContainer">
        <UploadFileButton
          acceptedMimeTypes={getAcceptedMimeTypes(this.props.company)}
          defaultStyle={false}
          onFileError={this.onFileError}
          onFileUploading={this.onFileUploading}
          onFileUploaded={this.onFileUploaded}
        />
      </div>
    );
  }

  renderPrivacyToggle() {
    const { isInternal } = this.state;
    const { replyTo, company, viewer } = this.props;

    if (replyTo?.internal) {
      return <div />;
    }

    const canCreatePublicComments = hasPermission('createPublicComment', company, viewer);

    return (
      <Tooltip
        disabled={canCreatePublicComments}
        value="You do not have access to this feature. Please ask one of your teammates to give you permission">
        <div className="toggleContainer">
          <Toggle
            disabled={!canCreatePublicComments}
            onToggle={this.onTogglePrivacy}
            value={!isInternal}
          />
          <div className="value">Public</div>
        </div>
      </Tooltip>
    );
  }

  renderButtonBar() {
    const { focused } = this.state;
    const hasContent = this.hasContent();

    if (!focused && !hasContent) {
      return null;
    }

    const disabled = !hasContent || this.areFilesUploading();

    return (
      <div className="buttonBar">
        {this.renderPrivacyToggle()}
        <div className="rightContainer">
          {this.renderTeamNotifyMessage()}
          <Button
            buttonType="blackButton"
            disabled={disabled}
            formButton={true}
            loading={this.state.submitting}
            tint={true}
            value="Submit"
          />
        </div>
      </div>
    );
  }

  renderErrorMessage() {
    const { error } = this.state;
    if (error) {
      return <div className="error">{error}</div>;
    }
  }

  renderFiles() {
    const { files } = this.state;
    if (files.length === 0) {
      return null;
    }

    return (
      <div className="fileRendererContainer">
        <FileRenderer
          allowRemove={true}
          files={this.state.files}
          onFileRemoved={this.onFileRemoved}
        />
      </div>
    );
  }

  render() {
    const { autoFocus, board, company, post, viewer } = this.props;
    const { focused, initialMentionedUsers, isInternal, submitting, value } = this.state;

    if (!post || post.error || post.notFound) {
      return null;
    }

    const planIsExpired = !company.billingData?.plan;
    if (planIsExpired) {
      return (
        <div className="commentComposer">
          <AdminFeatureBlock
            feature="comments"
            benefit="Interact with your users to dig into details and give updates."
          />
        </div>
      );
    }

    const placeholder = isInternal ? 'Leave an internal comment' : 'Leave a comment';
    return (
      <div
        className={classnames({
          adminFeedbackCommentComposer: true,
          internal: isInternal,
        })}
        ref={this.containerRef}>
        <Form
          acceptedFileTypes={getAcceptedMimeTypes(company)}
          addEventsToDocument={false}
          allowFileUpload={true}
          className={classnames({
            composerForm: true,
            focused: focused || this.hasContent(),
          })}
          disableSubmit={!this.hasContent() || submitting || this.areFilesUploading()}
          onFile={this.onFile}
          onSubmit={this.onSubmit}>
          <UserAvatar user={viewer} />
          <div className="composerEditable">
            <div className="composerContent">
              <MentionsTextarea
                ref={this.textareaRef}
                autoComplete="off"
                autoFocus={autoFocus}
                board={board}
                initialMentionedUsers={initialMentionedUsers}
                membersOnly={isInternal}
                onChange={this.onChange}
                onFocus={this.onFocus}
                placeholder={placeholder}
                portalPosition="top"
                value={value}
              />
              {this.renderFileInput()}
            </div>
            {this.renderFiles()}
            {this.renderButtonBar()}
            {this.renderErrorMessage()}
          </div>
        </Form>
        <UpsellModal
          cta="Leave comments just for your&nbsp;team"
          feature="internalComments"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsell}
          planID={StarterPlanID}
          show={this.state.showUpsellModal}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateData: (post) => {
      return Promise.all([
        dispatch(invalidateDashboardActivity()),
        dispatch(invalidatePostQueries()),
        dispatch(reloadPostActivity(post)),
        dispatch(invalidateUserQueries()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminFeedbackCommentComposer);

import React, { useContext, useEffect, useRef } from 'react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { getEncodedQueryParams } from 'common/containers/QueueItemListContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import AccessModal from 'common/modals/AccessModal';
import { CannyAnnouncement } from 'common/modals/CannyAnnouncementModal';
import { H1, P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';

import AdminQueueBanner from './AdminQueueBanner';
import AdminQueueSidebar from './AdminQueueSidebar';

import AutopilotAnnouncement from 'img/autopilot.svg';

import type { Counts } from './types';
import type { Board } from 'common/api/endpoints/boards';
import type {
  Company,
  RolePermissionName,
  ViewerPreferences,
} from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminQueue.scss';

type Props = {
  actionItemCounts: Counts;
  boards: Board[];
  children: React.ReactNode;
  queueItemCounts: Counts;
  inboxTotalItemCount: number;
  reloadCompany: () => void;
};

const AdminQueue = (props: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext(ViewerContext);
  const openModal = useContext(OpenModalContext);
  const isAllowed = hasPermission('deleteComments', company, viewer);

  const initialPathname = useRef<string>(location.pathname);
  const initialViewerPreferences = useRef<ViewerPreferences>(company?.viewerPreferences);

  const { queueItemCounts, inboxTotalItemCount, actionItemCounts, reloadCompany } = props;

  const counts = {
    spam: inboxTotalItemCount,
    ...(company.queueAutomation?.enabled ? actionItemCounts : queueItemCounts),
  };

  const hasInitialQueueItems = useRef<boolean>(counts.spam > 0 || counts.totalCount > 0);

  const { features } = company;
  const hasAutopilot = features.deduplication || features.supportExtraction;

  // order of these useEffects is intentional as the redirects will override previous ones
  // we want permissions block -> getting-started -> apply filter in order of precedence

  // load pre-saved filters. We perform do this here to prevent clashes between Spam and Posts when switching between them
  useEffect(() => {
    const lastUsedFilters = initialViewerPreferences.current?.queueFilters;
    if (!lastUsedFilters) {
      return;
    }

    // don't load pre-saved filters if they are invalid.
    // this occurs when a user trials a paid plan and downgrades, losing access to spam moderation
    if (lastUsedFilters.source === 'spam' && !company.features.moderationTools) {
      // since the user's preferences are currently invalid we forcibly reset them
      const resetInvalidFilters = async () => {
        await AJAX.post('/api/viewer/updatePreferences', {
          preferences: {
            queueFilters: {},
          },
        });
        reloadCompany();
      };
      resetInvalidFilters();

      return;
    }

    router.replace({
      pathname: initialPathname.current,
      query: getEncodedQueryParams(lastUsedFilters),
    });
  }, [router, company.features.moderationTools, reloadCompany]);

  // Redirect to Getting Started
  useEffect(() => {
    // if they don't have autopilot, skip
    if (!hasAutopilot) {
      return;
    }

    // if they have a any spam or draft items, skip
    if (hasInitialQueueItems.current) {
      return;
    }

    // if they've enabled deduplication, skip
    if (company.deduplication?.enabled) {
      return;
    }

    const enabledNonAPISources = company.installedSyncIntegrations.filter(
      ({ integrationName, disabled }) => integrationName !== SyncIntegrationNames.api && !disabled
    );

    // if they have an enabled non-api source, skip
    if (enabledNonAPISources.length > 0) {
      return;
    }

    router.replace('/admin/autopilot/getting-started');
  }, [company, router, hasInitialQueueItems, hasAutopilot]);

  // redirect users who don't have access
  useEffect(() => {
    if (!isAllowed) {
      const permission: RolePermissionName = 'deletePosts';
      router.replace('/admin');
      openModal(
        AccessModal,
        {
          requiredPermissions: [permission],
        },
        {
          allowRouteChange: true,
        }
      );
    }
  }, [router, isAllowed, openModal]);

  return (
    <div className="adminQueue">
      <Helmet title="Autopilot | Canny" />
      <header className="queueHeader">
        <div className="queueTitle">
          <H1 variant="headingLg">Autopilot</H1>
        </div>
        <P className="queueSubtitle">
          Review incoming feedback from all your sources.{' '}
          <a
            href="https://help.canny.io/en/articles/8202451-beta-inbox"
            target="_blank"
            className="externalLink"
            rel="noopener">
            Learn&nbsp;more.
          </a>
        </P>
      </header>
      {hasAutopilot ? (
        <>
          <AdminQueueBanner counts={counts} />
          <main className="queueContentLayout">
            <AdminQueueSidebar company={company} counts={counts} />
            {isAllowed && cloneElementWithProps(props.children, props)}
          </main>
        </>
      ) : (
        <Card borderStyle="solid" className="autopilotPlanUpsell">
          <CannyAnnouncement
            body={`Never let valuable feedback slip through the cracks again with Canny Autopilot. Automatically detect and consolidate feedback from all your sources in one place.\nMove to our new Free plan to get started.`}
            cta="Get Autopilot"
            image={AutopilotAnnouncement}
            learnMore="https://help.canny.io/en/articles/8202451-beta-inbox"
            onSuccess={() => router.replace('/admin/settings/billing/subscription')}
            title="Feedback Discovery on Autopilot"
          />
        </Card>
      )}
    </div>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadCompany: () => dispatch(reloadCompany()),
}))(AdminQueue);

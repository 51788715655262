import React from 'react';

import LazyLoadedImage from 'common/LazyLoadedImage';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/reports/_EmptyState.module.scss';

import NoDataIcon from 'img/icons/no-data.svg';

type Props = {
  message: string;
  learnMore?: string;
};

const EmptyState = ({ message, learnMore }: Props) => {
  return (
    <div className={styles.emptyState}>
      <LazyLoadedImage className={styles.emptyStateIcon} src={NoDataIcon} />
      <P fontWeight="semibold">No results found</P>
      <div>
        <P>{message}</P>
        {learnMore ? (
          <P>
            <a href={learnMore} className={styles.learnMore}>
              Learn more
            </a>
          </P>
        ) : null}
      </div>
    </div>
  );
};

export default EmptyState;

import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { Minus, Pencil, Plus } from 'lucide-react';

import { Types, isTypeKey } from 'common/changelog/constants';
import Colors from 'common/colors/constants';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import useViewTracking from 'common/hooks/useViewTracking';
import translateString from 'common/i18n/translateString';
import TranslationToggle from 'common/i18n/TranslationToggle';
import Link from 'common/Link';
import Markdown from 'common/markdown/Markdown';
import MarkdownStages, { MarkdownStagesNameMap } from 'common/markdown/MarkdownStages';
import AccessModal from 'common/modals/AccessModal';
import PhotoViewer from 'common/PhotoViewer';
import Timestamp from 'common/Timestamp';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H1, P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';

import ChangelogFooter from './ChangelogFooter';

import type { RolePermissionName } from 'common/api/endpoints/companies';
import type {
  Changelog,
  ChangelogEntry as ChangelogEntryType,
} from 'common/api/resources/changelog';

import 'css/components/changelog/_ChangelogEntry.scss';

const DefaultPillStyle = {
  color: Colors.gray110,
  background: Colors.gray30,
};

type Props = {
  changelog: Changelog;
  disableTranslation?: boolean;
  entry: ChangelogEntryType;
  collapsed?: boolean;
  onToggleCollapse?: (collapsed: boolean) => void;
  showPublishTime?: boolean;
  showFooter?: boolean;
  showEditLink?: boolean;
  isCollapsible?: boolean;
  logsView?: boolean;
};

const ChangelogEntry = ({
  changelog,
  disableTranslation = false,
  entry,
  collapsed = false,
  onToggleCollapse,
  showPublishTime = false,
  showFooter = false,
  showEditLink = false,
  isCollapsible = false,
  logsView = false,
}: Props) => {
  const viewer = useContext(ViewerContext);
  const openModal = useContext(OpenModalContext);
  const company = useContext(CompanyContext);
  const [hideTranslation, setHideTranslation] = useState(disableTranslation);
  const viewRef = useViewTracking(viewer, 'changelog', entry._id, company, logsView);

  const hasPermissionToEdit = hasPermission('manageChangelog', company, viewer);

  if (isCollapsible && !onToggleCollapse && __DEV__) {
    throw new Error('No collapsing handler');
  }

  const onImageTapped = (imageURL: string) => {
    openModal(PhotoViewer, {
      imageURLs: [imageURL],
      defaultIndex: 0,
    });
  };

  const onEdit = () => {
    if (!hasPermissionToEdit) {
      openModal(AccessModal, {
        requiredPermissions: ['manageChangelog'] as Array<RolePermissionName>,
      });
    }
  };

  const onToggleTranslation = () => {
    setHideTranslation(!hideTranslation);
  };

  // when the entry is collapsed, we show a small excerpt of its details.
  // if the entry details start with an image, or a video, we have to remove them first
  // to be able to extract the portion of the text we want to render.
  const getProcessedDetails = (details: string) => {
    if (!collapsed) {
      return details;
    }

    const stagesToRemove = [
      MarkdownStagesNameMap.multiLineCodeBlock,
      MarkdownStagesNameMap.unorderedList,
      MarkdownStagesNameMap.orderedList,
      MarkdownStagesNameMap.loom,
      MarkdownStagesNameMap.vimeo,
      MarkdownStagesNameMap.wistia,
      MarkdownStagesNameMap.youtube,
      MarkdownStagesNameMap.image,
    ];

    let processedDetails = details;
    stagesToRemove.forEach((stageName) => {
      const stage = MarkdownStages.find((stage) => stage.name === stageName);
      if (!stage) {
        return;
      }

      processedDetails = processedDetails.replace(stage.regex, '');
    });

    return processedDetails;
  };

  const labelIDMap = mapify(changelog?.labels || {}, '_id');

  const showEdit = company.viewerIsMember && showEditLink;
  const shouldRenderFooter =
    !company.changelog.private && entry.status === 'published' && !collapsed && showFooter;
  return (
    <div className="changelogEntry" ref={viewRef}>
      <header className="changelogEntryHeader">
        <div className="titleContainer">
          {showPublishTime &&
            (entry.status === 'published' ? (
              <Timestamp className="timestamp" timestamp={entry.publishedAt} />
            ) : (
              <P className="timestamp">Unpublished</P>
            ))}
          <div className="categorization">
            {entry.status !== 'published' && (
              <Pill pillStyle={DefaultPillStyles.warning}>{entry.status}</Pill>
            )}
            {entry.types.map((type) => (
              <Pill
                key={type}
                pillStyle={isTypeKey(type) ? Types[type].pillStyle : DefaultPillStyle}>
                {type}
              </Pill>
            ))}
            {entry.labelIDs.map(
              (id) =>
                labelIDMap[id] && (
                  <Pill key={id} pillStyle={DefaultPillStyle}>
                    {translateString(labelIDMap[id], 'name', hideTranslation)}
                  </Pill>
                )
            )}
          </div>
          <Link to={entry.urlName ? `/changelog/${entry.urlName}` : null}>
            <H1 className="title" variant="headingLg">
              {translateString(entry, 'title', hideTranslation)}
            </H1>
          </Link>
        </div>
        <div className="buttons">
          {showEdit && (
            <Link
              className="editLink"
              disabled={!hasPermissionToEdit}
              to={`/admin/changelog/${entry.urlName}/edit`}>
              <IconButtonV2
                size="medium"
                icon={Pencil}
                onClick={onEdit}
                variant="outlined"
                aria-label="Edit changelog entry"
              />
            </Link>
          )}
          {isCollapsible && onToggleCollapse && (
            <IconButtonV2
              size="medium"
              icon={collapsed ? Plus : Minus}
              variant="outlined"
              onClick={() => onToggleCollapse(!collapsed)}
              aria-label="Collapse changelog entry"
            />
          )}
        </div>
      </header>
      <div className={classnames('changelogEntryBody', { truncated: collapsed })}>
        <Markdown
          contents={getProcessedDetails(translateString(entry, 'details', hideTranslation))}
          onImageTapped={onImageTapped}
        />
        {disableTranslation ? null : (
          <TranslationToggle
            className="translationToggle"
            hideTranslation={hideTranslation}
            object={entry}
            onToggle={onToggleTranslation}
            stringKeys={['title', 'details']}
          />
        )}
      </div>
      {shouldRenderFooter && <ChangelogFooter company={company} entry={entry} />}
    </div>
  );
};

export default ChangelogEntry;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Data from 'common/Data';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModalPortal from 'common/modals/ModalPortal';
import Spinner from 'common/Spinner';
import LocalStorage from 'common/storage/LocalStorage';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import promisify from 'common/util/promisify';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminCreateClickupTaskModal.scss';

class AdminCreateClickupTaskModal extends Component {
  static propTypes = {
    company: PropTypes.object,
    onClose: PropTypes.func,
    onTaskCreated: PropTypes.func,
    post: PropTypes.object,
  };

  state = {
    clickupData: null,
    creating: false,
    error: null,
    selectedFolder: null,
    selectedList: null,
    selectedSpace: null,
    selectedTeam: null,
  };

  constructor(props, context) {
    super(props, context);

    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
  }

  async componentDidMount() {
    const clickupData = await this.fetchClickupTeam();

    this.setState({
      clickupData,
      ...this.getDefaults(clickupData),
    });
  }

  getDefaults = (clickupData, path = []) => {
    const { company } = this.props;
    const [teamID, spaceID, folderID, listID] = path;

    // try to load selected options from local storage when getting all the defaults
    let savedOptions = {};
    const storageKey = `${company._id}/clickup/new`;
    const savedOptionsString = LocalStorage.get(storageKey);
    if (!path.length && savedOptionsString) {
      savedOptions = JSON.parse(savedOptionsString);
    }

    const teamIDs = Object.keys(clickupData?.teams ?? {});
    const selectedTeam =
      teamID ??
      (teamIDs.includes(savedOptions.selectedTeam) ? savedOptions.selectedTeam : teamIDs[0]);

    const spaceIDs = Object.keys(clickupData?.teams?.[selectedTeam]?.spaces ?? {});
    const selectedSpace =
      spaceID ??
      (spaceIDs.includes(savedOptions.selectedSpace) ? savedOptions.selectedSpace : spaceIDs[0]);

    const folderIDs = Object.keys(
      clickupData?.teams?.[selectedTeam]?.spaces?.[selectedSpace]?.folders ?? {}
    );
    const selectedFolder =
      folderID ??
      (folderIDs.includes(savedOptions.selectedFolder)
        ? savedOptions.selectedFolder
        : folderIDs[0]);

    const listIDs = Object.keys(
      clickupData?.teams?.[selectedTeam]?.spaces?.[selectedSpace]?.folders?.[selectedFolder]
        ?.lists ?? {}
    );
    const selectedList =
      listID ??
      (listIDs.includes(savedOptions.selectedList) ? savedOptions.selectedList : listIDs[0]);

    const selectedOptions = {
      selectedFolder,
      selectedList,
      selectedSpace,
      selectedTeam,
    };

    // persist selected options on local storage
    LocalStorage.set(storageKey, JSON.stringify(selectedOptions));

    return selectedOptions;
  };

  fetchClickupTeam = async () => {
    const { clickupData } = await promisify(Data.fetch, {
      clickupData: { query: Data.queries.clickupTeam },
    });

    return clickupData;
  };

  mapToOptions = (entityMap) => {
    return Object.values(entityMap ?? {}).map((entity) => ({
      name: entity.id,
      render: entity.name,
    }));
  };

  onCreateTask = async () => {
    const { onClose, onTaskCreated, post, reloadPost } = this.props;

    this.setState({ creating: true });

    const response = await AJAX.post('/api/clickup/createAndLink', {
      description: this.descriptionRef.current.getValue(),
      listID: this.state.selectedList,
      name: this.titleRef.current.getValue(),
      postID: post._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({
        creating: false,
        error: error.message,
      });
      return;
    }

    await reloadPost(post);

    onTaskCreated?.();
    onClose?.();
  };

  onChangeField = (path) => {
    const { clickupData } = this.state;
    this.setState({
      ...this.getDefaults(clickupData, path),
    });
  };

  renderContents() {
    const { post } = this.props;
    const { clickupData, error, selectedFolder, selectedList, selectedSpace, selectedTeam } =
      this.state;

    if (error) {
      return <div className="error">{error}</div>;
    } else if (!clickupData) {
      return <Spinner />;
    }

    // generate dropdown options
    const teamOptions = this.mapToOptions(clickupData.teams);
    const spaceOptions = this.mapToOptions(clickupData.teams?.[selectedTeam]?.spaces);
    const folderOptions = this.mapToOptions(
      clickupData.teams?.[selectedTeam]?.spaces?.[selectedSpace]?.folders
    );
    const listOptions = this.mapToOptions(
      clickupData.teams?.[selectedTeam]?.spaces?.[selectedSpace]?.folders?.[selectedFolder]?.lists
    );

    if (!teamOptions.length) {
      return (
        <div className="error">
          It looks like there are no available ClickUp Workspaces. Please add a Workspace on ClickUp
          before creating a&nbsp;task.
        </div>
      );
    }
    const detailValue = post.details;
    const imageURLsMarkdown = post.imageURLs.reduce((acc, imageURL) => {
      return `${acc}\n![image](${imageURL})\n`;
    }, '');

    const descriptionWithImages = post.imageURLs.length
      ? `${detailValue}\n${imageURLsMarkdown}`
      : detailValue;

    return (
      <div className="createTaskForm">
        <div className="dropdownsContainer">
          <div className="dropdownOuterContainer">
            <UppercaseHeader>Workspace</UppercaseHeader>
            <ControlledDropdown
              options={teamOptions}
              onChange={(teamID) => this.onChangeField([teamID])}
              selectedName={selectedTeam}
            />
          </div>
          {spaceOptions.length ? (
            <div className="dropdownOuterContainer">
              <UppercaseHeader>Space</UppercaseHeader>
              <ControlledDropdown
                options={spaceOptions}
                onChange={(spaceID) => this.onChangeField([selectedTeam, spaceID])}
                selectedName={selectedSpace}
              />
            </div>
          ) : null}
        </div>
        <div className="dropdownsContainer">
          {folderOptions.length ? (
            <div className="dropdownOuterContainer">
              <UppercaseHeader>Folder</UppercaseHeader>
              <ControlledDropdown
                options={folderOptions}
                onChange={(folderID) => this.onChangeField([selectedTeam, selectedSpace, folderID])}
                selectedName={selectedFolder}
              />
            </div>
          ) : null}
          {listOptions.length ? (
            <div className="dropdownOuterContainer">
              <UppercaseHeader>List</UppercaseHeader>
              <ControlledDropdown
                options={listOptions}
                onChange={(listID) =>
                  this.onChangeField([selectedTeam, selectedSpace, selectedFolder, listID])
                }
                selectedName={selectedList}
              />
            </div>
          ) : null}
        </div>
        {listOptions.length ? (
          <>
            <TextInput defaultValue={post.title} inset="Title" ref={this.titleRef} />
            <AutoResizeTextarea
              defaultValue={descriptionWithImages}
              inset="Description"
              maxRows={8}
              minRows={3}
              ref={this.descriptionRef}
            />
            <Button
              buttonType="cannyButton"
              loading={this.state.creating}
              onTap={this.onCreateTask}
              value="Create & Link Task"
            />
          </>
        ) : (
          <div className="error">
            It looks like there are no available ClickUp lists. Please add a list to this space in
            ClickUp. Alternatively, select another workspace, space, or&nbsp;folder.
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <ModalPortal
        className="adminCreateClickupTaskModal"
        closeOnClickAway={true}
        onClose={this.props.onClose}>
        <div className="container">
          <div className="heading">Create a new ClickUp task</div>
          {this.renderContents()}
        </div>
      </ModalPortal>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => dispatch(reloadPost(post)),
  })),
  withContexts({ company: CompanyContext }, { forwardRef: true })
)(AdminCreateClickupTaskModal);

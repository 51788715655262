import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { TintColorContext } from 'common/containers/TintColorContainer';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import isElementOnScreen from 'common/util/isElementOnScreen';
import withContexts from 'common/util/withContexts';

import 'css/components/common/_LoadMore.scss';

class LoadMore extends Component {
  static propTypes = {
    className: PropTypes.string,
    loadingMore: PropTypes.bool,
    hasMore: PropTypes.bool,
    onLoadMore: PropTypes.func,
    tintColor: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, false);
    this.onScroll();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (e, scrollContainer) => {
    const container = this.containerRef.current;
    if (!container) {
      return;
    }

    // avoid calling load more if it's already loading more
    if (this.props.loadingMore) {
      return;
    }

    if (!isElementOnScreen(container, scrollContainer)) {
      return;
    }

    this.props.onLoadMore();
  };

  render() {
    const { className, hasMore, loadingMore, onLoadMore } = this.props;
    if (!hasMore) {
      return null;
    }

    if (loadingMore) {
      return (
        <div className="loadMore loadingMore" ref={this.containerRef}>
          <Spinner />
        </div>
      );
    }

    const { tintColor } = this.props;
    const loadMoreStyle = {
      ...(tintColor && { color: tintColor }),
    };
    return (
      <Tappable onTap={onLoadMore}>
        <div
          className={classnames('loadMore', className)}
          ref={this.containerRef}
          style={loadMoreStyle}>
          Load More
          <div className="arrow">&rarr;</div>
        </div>
      </Tappable>
    );
  }
}

export default withContexts({ tintColor: TintColorContext }, { forwardRef: true })(LoadMore);

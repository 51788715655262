import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CloseModalContext } from 'common/containers/ModalContainer';
import Form from 'common/Form';
import ModalPortal from 'common/modals/ModalPortal';
import withContexts from 'common/util/withContexts';

import Modal from './Modal';
import Button from '../inputs/Button';

import 'css/components/modals/_ConfirmModal.scss';

class ConfirmModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    message: PropTypes.node.isRequired,
    onConfirm: PropTypes.func.isRequired,
    portalContainerID: PropTypes.string,
    submitButtonType: PropTypes.string,
    submitButtonValue: PropTypes.string,
    tint: PropTypes.bool,
    useModalPortal: PropTypes.bool,
  };

  static defaultProps = {
    submitButtonType: 'cannyButton',
    submitButtonValue: 'Yes',
    tint: true,
    useModalPortal: false,
  };

  onConfirm = () => {
    this.props.closeModal();
    this.props.onConfirm();
  };

  render() {
    const {
      closeModal,
      message,
      portalContainerID,
      submitButtonType,
      submitButtonValue,
      tint,
      useModalPortal,
    } = this.props;
    const content = (
      <Form
        className="confirmModal"
        addEventsToDocument={true}
        disableSubmit={false}
        onSubmit={this.onConfirm}>
        <div className="message">{message}</div>
        <div className="buttons">
          <Button buttonType="ghostButton" onTap={closeModal} value="Cancel" />
          <Button
            buttonType={submitButtonType}
            formButton={true}
            tint={tint}
            value={submitButtonValue}
          />
        </div>
      </Form>
    );

    return useModalPortal ? (
      <ModalPortal
        className="confirmModalPortal"
        closeOnClickAway={true}
        onClose={closeModal}
        portalContainerID={portalContainerID}>
        {content}
      </ModalPortal>
    ) : (
      <Modal>{content}</Modal>
    );
  }
}

export default withContexts({ closeModal: CloseModalContext }, { forwardRef: true })(ConfirmModal);

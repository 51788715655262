import React from 'react';

import classnames from 'classnames';
import { Check, X } from 'lucide-react';

import Colors from 'common/colors/constants';
import { type HexColor, contrastRatio, luminance, testWCAG } from 'common/colors/utils';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import PostVotesV3 from 'common/post/PostVotes/PostVotesUI';
import { P } from 'common/ui/Text';
import hexToRGB from 'common/util/hexToRGB';
import roundCents from 'common/util/roundCents';

import 'css/components/_ColorAccessibilityChecker.scss';

type Props = {
  className: string;
  foreground: HexColor;
  backgrounds: Array<HexColor>;
};

const DefaultPillStyle = {
  color: Colors.gray110,
  background: Colors.gray30,
};

const ColorAccessibilityChecker = ({ className, foreground, backgrounds }: Props) => {
  return (
    <div className={classnames('colorAccessibilityChecker', className)}>
      {backgrounds.map((background) => {
        const rgbForeground = hexToRGB(foreground);
        const rgbBackground = hexToRGB(background);

        if (!rgbBackground || !rgbForeground) {
          return null;
        }

        const contrast = contrastRatio(rgbForeground, rgbBackground);
        const contrastChecks = testWCAG(rgbForeground, rgbBackground);
        const brightness = luminance(rgbBackground);
        return (
          <div
            key={background}
            style={{ background }}
            className={classnames('colorAccessibilityCheck', { darkTheme: brightness < 0.3 })}>
            <div className="colorAccessibilityRender">
              <PostVotesV3 highlight={foreground} onClick={() => null} score={1200} />
              <div className="colorAccessibilityText">
                <P variant="bodyMd" style={{ color: foreground }}>
                  This is some sample 14pt text
                </P>
                <P variant="bodyXl" style={{ color: foreground }}>
                  This is some sample 18pt text
                </P>
              </div>
            </div>
            <div className="colorAccessibilityResults">
              <Pill pillStyle={DefaultPillStyle}>{roundCents(1 / contrast)}</Pill>
              <Pill
                pillStyle={
                  contrastChecks.AA.smallText
                    ? DefaultPillStyles.success
                    : DefaultPillStyles.destructive
                }>
                <P variant="bodySm" fontWeight="medium" className="pillWCAG">
                  Small text {contrastChecks.AA.smallText ? <Check size={14} /> : <X size={14} />}
                </P>
              </Pill>
              <Pill
                pillStyle={
                  contrastChecks.AA.largeText
                    ? DefaultPillStyles.success
                    : DefaultPillStyles.destructive
                }>
                <P variant="bodySm" fontWeight="medium" className="pillWCAG">
                  Large text {contrastChecks.AA.largeText ? <Check size={14} /> : <X size={14} />}
                </P>
              </Pill>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ColorAccessibilityChecker;

import React from 'react';

import { loadChangelog } from 'common/actions/changelog';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import { RoutePermissions } from 'common/util/permissions';

import 'css/components/subdomain/admin/_AdminChangelogSettingsContainer.scss';
import AdminSettingsSidebarLink from './AdminSettingsSidebarLink';

import type { Changelog } from 'common/api/resources/changelog';

type ChangelogSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.changelog;
};

const SidebarLinks: ChangelogSidebarLink[] = [
  {
    label: 'Labels',
    urlSuffix: 'labels',
  },
  {
    label: 'Privacy',
    urlSuffix: 'privacy',
  },
];

type Props = {
  changelog: Changelog;
  children: React.ReactNode;
};

const AdminChangelogSettingsContainer = ({ changelog, children }: Props) => (
  <div className="adminChangelogSettingsContainer">
    <AdminSettingsHeader
      title="Changelog"
      subheading="Manage your&nbsp;changelog."
      learnMoreLink="https://help.canny.io/en/articles/3006399-changelog"
    />
    <div className="settingsContainer">
      <div className="settingsSidebar">
        {SidebarLinks.map(({ label, urlSuffix }) => (
          <AdminSettingsSidebarLink
            key={urlSuffix}
            requiredPermissions={RoutePermissions.adminSettings.changelog[urlSuffix]}
            to={`/admin/settings/changelog/${urlSuffix}`}>
            {label}
          </AdminSettingsSidebarLink>
        ))}
      </div>
      <div className="settingsContent">{cloneElementWithProps(children, { changelog })}</div>
    </div>
  </div>
);

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => {
        return dispatch(loadChangelog());
      },
    },
  ],
  (state) => ({ changelog: state.changelog })
)(AdminChangelogSettingsContainer);

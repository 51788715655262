import {
  LoadingMore,
  RequestThirdPartyCompanyPosts,
  ThirdPartyCompanyPostsError,
  ThirdPartyCompanyPostsLoaded,
  getThirdPartyCompanyPostsKey,
} from 'common/actions/thirdPartyCompanyPosts';

export default function thirdPartyCompanyPosts(state = {}, action) {
  switch (action.type) {
    case RequestThirdPartyCompanyPosts: {
      const queryKey = getThirdPartyCompanyPostsKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case ThirdPartyCompanyPostsLoaded: {
      const { postList } = action.result;
      const queryKey = getThirdPartyCompanyPostsKey(action.queryParams);
      const postIDs = postList.posts.map((post) => post._id);

      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          postIDs,
          hasNextPage: postList.hasNextPage,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case ThirdPartyCompanyPostsError: {
      const queryKey = getThirdPartyCompanyPostsKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LoadingMore: {
      const queryKey = getThirdPartyCompanyPostsKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    default:
      return state;
  }
}

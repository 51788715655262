import React from 'react';

import 'css/components/_Overlay.scss';

type Props = {
  open?: boolean;
  children: React.ReactNode;
};

const Overlay = ({ children, open = false }: Props) => {
  if (!open) {
    return null;
  }

  return (
    <div className="overlayWrapper">
      <div className="overlayContainer">
        <div className="overlay">{children}</div>
      </div>
    </div>
  );
};

export default Overlay;

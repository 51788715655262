import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { loadRules, reloadRules } from 'common/actions/jiraRules';
import AJAX from 'common/AJAX';
import NoteBox from 'common/common/NoteBox';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import asyncConnect from 'common/core/asyncConnect';
import Data from 'common/Data';
import Dropdown from 'common/Dropdown';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import { extractBaseURL } from 'common/jira/utils';
import LazyLoadedImage from 'common/LazyLoadedImage';
import PostStatus from 'common/post/PostStatus';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import ButtonV2 from 'common/ui/ButtonV2';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';

import JiraConfigurePage from 'img/jira-configure.png';

import 'css/components/subdomain/admin/_AdminJiraSettings.scss';

class AdminJiraSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      subdomain: PropTypes.string,
    }),
  };

  state = {
    any: true,
    domain: null,
    error: null,
    saving: false,
    showComposer: false,
    statuses: null,
    uninstalling: false,
  };

  constructor(props, context) {
    super(props, context);

    this.anyAllDropdownRef = React.createRef();
    this.cannyStatusDropdownRef = React.createRef();
    this.jiraStatusDropdownRef = React.createRef();
    this.notifyVotersDropdownRef = React.createRef();
  }

  componentDidMount() {
    this.fetchJiraStatuses();
  }

  fetchJiraStatuses = () => {
    Data.fetch(
      {
        statuses: {
          query: Data.queries.jiraStatuses,
        },
      },
      (error, data) => {
        if (error) {
          this.setState({
            error: error,
          });
          return;
        }

        this.setState({
          statuses: data.statuses.sort(stringSort('name')),
        });
      }
    );
  };

  onAnyAllChange = (anyAll) => {
    this.setState({
      any: anyAll === 'any',
    });
  };

  onCloseComposer = () => {
    this.setState({
      showComposer: false,
    });
  };

  onCreateRequest = async () => {
    const { domain } = this.state;
    const baseURL = extractBaseURL(domain);
    const invalidDomainError = 'Please provide a valid Atlassian.net or Jira.com domain.';

    if (!baseURL) {
      this.setState({ error: invalidDomainError });
      return;
    }

    this.setState({ error: null, saving: true });

    const response = await AJAX.post('/api/jira/createInstallationRequest', {
      baseURL,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': 'You are not authorized to take this action',
        'plan does not support': 'Your plan does not support Jira',
        'integration limit':
          "You've reached your integration limit, please uninstall other integration to proceed",
        'invalid baseURL': invalidDomainError,
        'pending request exists':
          'There is an existing initialization, please cancel the current initialization to proceed',
        'jira already installed': 'Jira is already installed',
      },
    });

    if (error) {
      this.setState({ error: error.message, saving: false });
    }

    await this.props.reloadCompany();
    this.setState({ saving: false });
  };

  onUninstall = async () => {
    this.setState({ error: null, uninstalling: true });

    const response = await AJAX.post('/api/jira/uninstall', {});
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': 'You are not authorized to take this action',
        'must uninstall in Jira workspace':
          'Please uninstall the integration in Jira Workspace settings',
      },
    });

    if (error) {
      this.setState({ error: error.message, uninstalling: false });
    }

    await this.props.reloadCompany();
    this.setState({ uninstalling: false });
  };

  onCreateRule = () => {
    this.setState({
      showComposer: true,
    });
  };

  onDeleteRequest = async () => {
    this.setState({ error: null, saving: true });

    const response = await AJAX.post('/api/jira/deleteInstallationRequest', {});

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': 'You are not authorized to take this action',
        'no pending request': 'No pending request to cancel',
      },
    });

    if (error) {
      this.setState({ error: error.message, saving: false });
    }

    await this.props.reloadCompany();
    this.setState({ saving: false });
  };

  onDeleteRule = (rule) => {
    AJAX.post(
      '/api/jira/deleteRule',
      {
        ruleID: rule._id,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
          });
        } else {
          const { reloadRules } = this.props;
          reloadRules().then(() => {
            this.setState({
              error: null,
            });
          });
        }
      }
    );
  };

  onDomainChange = (e) => {
    this.setState({
      error: null,
      domain: e.target.value,
    });
  };

  onSaveRule = () => {
    const allLinkedIssues = this.anyAllDropdownRef.current.getValue() === 'all';
    const cannyStatus = this.cannyStatusDropdownRef.current.getValue();
    const jiraStatus = this.jiraStatusDropdownRef.current.getValue();
    const shouldNotifyVoters = this.notifyVotersDropdownRef.current.getValue() === 'notify';

    this.setState({
      saving: true,
    });

    AJAX.post(
      '/api/jira/createRule',
      {
        allLinkedIssues,
        cannyStatus,
        jiraStatus,
        shouldNotifyVoters,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            saving: false,
          });
        } else {
          const { reloadRules } = this.props;
          reloadRules().then(() => {
            this.setState({
              error: null,
              saving: false,
              showComposer: false,
            });
          });
        }
      }
    );
  };

  renderComposer() {
    const { showComposer } = this.state;
    if (!showComposer) {
      return null;
    }

    const { company } = this.props;
    const { any, saving, statuses } = this.state;
    const areStatusesLoaded = statuses && statuses.length;
    const cannyStatusOptions = company.statuses.map((status) => {
      return {
        name: status.name,
        render: <PostStatus showOpen={true} status={status.name} />,
      };
    });

    return (
      <div className="composerContainer">
        <div className="composer">
          When
          <Dropdown
            className="anyAllDropdown"
            defaultSelectedName={'any'}
            dropdownClassName="adminJiraSettingsDropdown"
            onChange={this.onAnyAllChange}
            options={[
              {
                name: 'any',
                render: 'any',
              },
              {
                name: 'all',
                render: 'all',
              },
            ]}
            ref={this.anyAllDropdownRef}
          />
          {any ? 'linked Jira issue is changed to' : 'linked Jira issues are changed to'}
          {areStatusesLoaded ? (
            <Dropdown
              className="jiraStatusDropdown"
              defaultSelectedName={statuses[0].name}
              dropdownClassName="adminJiraSettingsDropdown"
              options={statuses.map((status) => ({
                name: status.name,
                render: status.name,
              }))}
              ref={this.jiraStatusDropdownRef}
            />
          ) : (
            <Spinner />
          )}
          , change all linked Canny posts to
          <Dropdown
            className="cannyStatusDropdown"
            defaultSelectedName="open"
            dropdownClassName="adminJiraSettingsDropdown"
            options={cannyStatusOptions}
            ref={this.cannyStatusDropdownRef}
          />
          , and
          <Dropdown
            className="notifyDropdown"
            defaultSelectedName="notify"
            dropdownClassName="adminJiraSettingsDropdown"
            options={[
              {
                name: 'notify',
                render: 'notify',
              },
              {
                name: 'do not notify',
                render: 'do not notify',
              },
            ]}
            ref={this.notifyVotersDropdownRef}
          />
          voters.
        </div>
        <div className="buttons">
          <Button buttonType="ghostButton" onTap={this.onCloseComposer} value="cancel" />
          <Button loading={saving} onTap={this.onSaveRule} value="Create" />
        </div>
      </div>
    );
  }

  renderCreateRuleButton() {
    const { showComposer } = this.state;
    if (showComposer) {
      return null;
    }

    return (
      <Button className="createRuleButton" onTap={this.onCreateRule} value="Create New Rule" />
    );
  }

  renderRules() {
    const { rules } = this.props;
    const items = rules.map((rule, i) => (
      <div className="rule" key={rule._id}>
        <div className="description">
          {'When '}
          {rule.allLinkedIssues ? 'all ' : 'any '}
          {rule.allLinkedIssues
            ? 'linked Jira issues are changed to '
            : 'linked Jira issue is changed to '}
          {'"' + rule.jiraStatus + '"'}
          {', change all linked Canny posts to '}
          <PostStatus showOpen={true} status={rule.cannyStatus} />
          {', and '}
          {rule.shouldNotifyVoters ? 'notify\u00a0voters' : 'do not notify\u00a0voters'}
          {'.'}
        </div>
        <Tappable onTap={this.onDeleteRule.bind(this, rule)}>
          <div className="icon icon-x" />
        </Tappable>
      </div>
    ));
    return (
      <div className="rules">
        {rules.length === 0 ? null : <UppercaseHeader>Rules</UppercaseHeader>}
        {items}
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderConnected() {
    const content = this.state.uninstalling ? (
      <div className="spinnerContainer">
        <Spinner />
      </div>
    ) : (
      <>
        {this.renderDisconnect()}
        {this.renderRules()}
        {this.renderComposer()}
        {this.renderError()}
        {this.renderCreateRuleButton()}
        {this.renderNote()}
      </>
    );

    return (
      <div className="connected">
        <div className="text">
          This page lets you configure status syncing with Jira. Set up rules so that when an
          issue's status changes, linked Canny posts are updated as well.
        </div>
        {content}
      </div>
    );
  }

  renderDisconnect() {
    const { company } = this.props;

    if (!company.jira || !company.jira.multipleInstancesConnected) {
      return null;
    }

    return (
      <div className="disconnectContainer">
        <div className="text">Your Jira integration is installed.</div>
        <button className="uninstallButton" onClick={this.onUninstall}>
          <div className="uninstall">Uninstall</div>
        </button>
      </div>
    );
  }

  renderInitialize() {
    const { jiraRequest } = this.props.company;
    const { saving } = this.state;

    const button = jiraRequest ? (
      <ButtonV2
        className="cancelButton"
        loading={saving}
        size="medium"
        onClick={this.onDeleteRequest}
        color="error"
        variant="outlined">
        Cancel
      </ButtonV2>
    ) : (
      <ButtonV2
        className="initializeButton"
        size="medium"
        loading={saving}
        onClick={this.onCreateRequest}>
        Initialize
      </ButtonV2>
    );

    return (
      <div className="initialize">
        <div className="text">
          Enter your Atlassian/Jira domain to initialize the installation. Visit&nbsp;
          <a href="https://help.canny.io/en/articles/1283233-jira-integration">our help article</a>
          &nbsp;for instructions on how to set up Canny's Jira&nbsp;integration.
        </div>
        <TextInput
          className="domainInput"
          inset="Atlassian/Jira Domain"
          disabled={jiraRequest}
          defaultValue={jiraRequest?.baseURL}
          onChange={this.onDomainChange}
          placeholder="https://your-workspace.atlassian.net"
        />
        {jiraRequest && (
          <>
            <div className="text">First step complete. Next:</div>
            <ol className="numberedList">
              <li>
                <div className="text">
                  Head to the&nbsp;
                  <a
                    href="https://marketplace.atlassian.com/apps/1217889/canny-for-jira"
                    target="_blank"
                    rel="noopener">
                    Jira Marketplace
                  </a>
                  &nbsp;and install Canny.
                </div>
              </li>
              <li>
                <div className="text">
                  From your Jira dashboard go to Apps&nbsp;&#8594;&nbsp;Manage your
                  apps&nbsp;&#8594;&nbsp;Canny&nbsp;&#8594;&nbsp;Configure.
                </div>
                <LazyLoadedImage src={JiraConfigurePage} className="configImage" />
              </li>
              <li className="lastNumberedList">
                <div className="text">
                  Select the Canny account you&apos;d like to sync with Jira, then click "Configure
                  Plugin".
                </div>
                <div className="text">
                  Check out our&nbsp;
                  <a href="https://help.canny.io/en/articles/1283233-jira-integration">
                    help article
                  </a>
                  &nbsp;with more details.
                </div>
              </li>
            </ol>
          </>
        )}
        {button}
      </div>
    );
  }

  renderDisconnected() {
    const {
      company,
      activeIntegrations: { integrationCount, integrationLimit },
    } = this.props;
    let upsell;

    if (integrationLimit && integrationCount >= integrationLimit) {
      upsell = <AdminIntegrationLimitUpsell />;
    }

    if (!company?.integrations?.jira) {
      upsell = <AdminFeatureUpsell feature="jira" />;
    }

    return (
      <div className="disconnected">
        <div className="text">
          The Jira integration lets you configure status syncing with Jira once you've set up
          the&nbsp;integration.
        </div>
        {upsell ? upsell : this.renderInitialize()}
        {this.renderError()}
      </div>
    );
  }

  renderContents() {
    const { company } = this.props;

    const disconnected = !company.jira || !company.jira.connected;
    if (disconnected) {
      return this.renderDisconnected();
    }

    return this.renderConnected();
  }

  renderNote() {
    const { company } = this.props;

    if (!company.jira || company.jira?.multipleInstancesConnected) {
      return null;
    }

    return (
      <NoteBox title="Note" colorScheme="note" className="text withMarginTop">
        Disconnecting Jira from Canny must be completed from your Jira workspace settings.
        <br />
        <a href="https://help.canny.io/en/articles/1283233-jira-integration">
          View our help article
        </a>{' '}
        for more&nbsp;details.
      </NoteBox>
    );
  }

  render() {
    return (
      <div className="adminJiraSettings">
        <Helmet title="Jira Integration | Canny" />
        <div className="settingsHeading">Jira Integration</div>
        <div className="content">{this.renderContents()}</div>
      </div>
    );
  }
}

export default compose(
  asyncConnect(
    [
      {
        promise: ({ store: { dispatch, getState } }) => {
          return dispatch(loadRules());
        },
      },
    ],
    (state) => ({
      rules: state.jiraRules && state.jiraRules.data,
    }),
    (dispatch) => ({
      reloadCompany: (post) => {
        return Promise.all([dispatch(reloadCompany())]);
      },
      reloadRules: (post) => {
        return Promise.all([dispatch(reloadRules())]);
      },
    })
  ),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.jira), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminJiraSettings);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import withContexts from 'common/util/withContexts';

import Helmet from './Helmet';

class AdminDashboardHelmet extends Component {
  static propTypes = {
    company: PropTypes.shape({
      loading: PropTypes.bool,
    }),
  };

  render() {
    const { company } = this.props;
    if (company.loading) {
      return null;
    }

    return <Helmet title={company.name + ' | Canny'} />;
  }
}

export default withContexts({ company: CompanyContext })(AdminDashboardHelmet);

import React, { useContext } from 'react';

import classnames from 'classnames';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { deletePostDraft, savePostDraft } from 'common/actions/postDraft';
import { reloadOnMerge } from 'common/actions/postMerge';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import {
  type QueryParams,
  invalidateQueriesAndReload,
  loadMoreQueueItems,
  removeItem,
} from 'common/actions/queueItemQueries';
import { invalidateRoadmap } from 'common/actions/roadmap';
import {
  reloadRoadmapPostsForRoadmapsWithPost,
  removePostFromAllRoadmaps,
} from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { type QueueItem } from 'common/api/endpoints/queue';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import RoadmapPostContainer from 'common/containers/RoadmapPostContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import { KeyIdentifiers } from 'common/KeyCodes';
import ModalPortal from 'common/modals/ModalPortal';
import SelectableDataTable from 'common/SelectableDataTable';
import AdminCreatePostModal from 'common/subdomain/admin/AdminCreatePostModal';
import AdminFeedbackPost from 'common/subdomain/admin/AdminFeedbackPost';
import { isNotNil } from 'common/util/isNil';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AutopilotNUX from './AutopilotNUX';
import { GenericSourceCell, QueueActionsCell, QueueSuggestionCell } from './DataTableCells';
import { isCannyPost, isPostDraft } from './utils';

import 'css/components/subdomain/admin/_AdminQueuePage.scss';

import type {
  Counts,
  InboxCannyPostItem,
  InboxDraftItem,
  PostModalDraft,
  QueueInboxItem,
  QueueItemsState,
  QueueRow as Row,
} from './types';
import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { CustomPostField } from 'common/api/resources/postFields';
import type { Post } from 'common/api/resources/posts';
import type { Column, Sort } from 'common/DataTable';
import type { Dispatch } from 'redux-connect';

type SortableColumn<R extends Row, ID extends keyof R> = Column<R, ID> & {
  sort: (order: 'asc' | 'desc') => void;
};

type ConnectProps = {
  boards: Board[];
  customPostFields: CustomPostField[];
  deletePostDraft: () => void;
  invalidateQueriesAndReload: (queryParams: QueryParams) => void;
  loadMoreItems: (queryParams: QueryParams, pages: number) => void;
  queueItemCounts: Counts;
  queueItemList: QueueItemsState;
  removeItem: (item: QueueItem) => void;
  removePost: (post: Post, board: Board) => void;
  reloadOnMerge: (mergePost: Post, intoPost: Post, fromBoard: Board, toBoard: Board) => void;
  reloadPost: (post: Post, queryParams?: QueryParams) => void;
  reloadPostActivity: (post: Post) => void;
  roadmaps: Roadmap[];
  savePostDraft: (draft: PostModalDraft) => void;
};

type OwnProps = Record<string, never>;

type Props = ConnectProps & OwnProps;

const AdminQueuePosts = ({
  boards,
  customPostFields,
  deletePostDraft,
  loadMoreItems,
  queueItemCounts,
  queueItemList,
  reloadOnMerge,
  reloadPost,
  reloadPostActivity,
  roadmaps,
  savePostDraft,
  removeItem,
  removePost,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const openModal = useContext(OpenModalContext);
  const showToast = useContext(ShowToastContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);

  // expand post logic
  const getSelectedPost = () => {
    if (!location.query.postURLName) {
      return;
    }

    const potentialPosts = queueItemList.items
      .map((item) => {
        if (item.source === 'canny' && item.duplicatePost) {
          return [item, item.duplicatePost];
        }

        if (item.source === 'canny') {
          return item;
        }

        if (item.duplicatePost) {
          return item.duplicatePost;
        }

        return null;
      })
      .flat()
      .filter(isNotNil);

    return potentialPosts.find((post) => post.urlName === location.query.postURLName);
  };

  const selectedPost = getSelectedPost();
  const closePost = () =>
    router.replace({
      pathname: location.pathname,
      query: { ...location.query, postURLName: undefined },
    });

  // render the source column when we're viewing all sources / no source in particular
  const sourceColumnRequired = !location.query.source;

  // table setup
  const columns = [
    sourceColumnRequired
      ? ({
          id: 'source',
          align: 'left',
          sortable: true,
          header: 'Source',
          sort: (order) => {
            router.replace({
              pathname: location.pathname,
              query: { ...location.query, sort: 'source', sortDirection: order },
            });
          },
          cell: (_, { item }) => {
            if (item.type === 'draft') {
              return (
                <GenericSourceCell
                  hasDuplicatePost={!!item.duplicatePost}
                  link={item.link}
                  source={item.source}
                  sourceType="draft"
                />
              );
            }

            return (
              <GenericSourceCell
                hasDuplicatePost={!!item.duplicatePost}
                sourceType="post"
                urlName={item.urlName}
              />
            );
          },
        } as SortableColumn<Row, 'source'>)
      : null,
    {
      id: 'item',
      align: 'left',
      header: 'Suggestion',
      sortable: true,
      fullWidth: true,
      sort: (order) => {
        router.replace({
          pathname: location.pathname,
          query: { ...location.query, sort: 'created', sortDirection: order },
        });
      },
      cell: (item) => <QueueSuggestionCell boards={boards} company={company} item={item} />,
    } as SortableColumn<Row, 'item'>,
    {
      id: 'actions',
      align: 'left',
      header: 'Actions',
      sortable: false,
      cell: (_, row) => {
        return <QueueActionsCell row={row} boards={boards} />;
      },
    } as Column<Row, 'actions'>,
  ].filter(isNotNil);

  const onApproveDraft = async (
    item: QueueInboxItem,
    post: Post,
    { addVote }: { addVote: boolean }
  ) => {
    const response = await AJAX.post('/api/queue/drafts/approve', {
      postDraftID: item._id,
      postID: post._id,
      addVote,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'There was a problem approving this item. Try again.',
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    removeItem(item);
    reloadPostActivity(post);
  };

  const onDeleteDraft = async (item: QueueInboxItem) => {
    const response = await AJAX.post('/api/queue/drafts/reject', {
      postDraftID: item._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'There was a problem rejecting this item. Try again.',
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    removeItem(item);
  };

  const itemToRow = (inboxItem: QueueInboxItem) => {
    // actions
    const approveDraft = async (item: InboxDraftItem) => {
      deletePostDraft();
      savePostDraft({
        title: item.title,
        details: item.details,
        internalComment: item.details,
        author: item.author,
      });

      openModal(AdminCreatePostModal, {
        boards,
        onUnmount: () => deletePostDraft(),
        onPostCreated: async (post: Post) => {
          await onApproveDraft(item, post, { addVote: false });
          showToast('Post created', ToastTypes.success);
        },
        onVoteAdded: async (post: Post) => {
          await onApproveDraft(item, post, { addVote: true });
          showToast('Vote added', ToastTypes.success);
        },
      });
    };

    const deleteDraft = async (item: InboxDraftItem) => {
      await onDeleteDraft(item);
      showToast('Dismissed', ToastTypes.success);
    };

    const onMergeDraft = async (item: InboxDraftItem, post: Post) => {
      await onApproveDraft(item, post, { addVote: true });
      showToast('Merged as vote', ToastTypes.success);
    };

    const approvePost = async (item: InboxCannyPostItem) => {
      await onApprovePost(item);
      showToast('Post approved', ToastTypes.success);
    };

    const rejectPost = async (item: InboxCannyPostItem) => {
      await onRejectPost(item);
      showToast('Merge suggestion rejected', ToastTypes.success);
    };

    const deletePost = async (item: InboxCannyPostItem) => {
      await onDeletePost(item);
      showToast('Post deleted', ToastTypes.success);
    };

    const onRejectPost = async (item: InboxCannyPostItem) => {
      const response = await AJAX.post('/api/queue/posts/reject', {
        postID: item._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          default: 'There was a problem rejecting the suggestion. Try again.',
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      removeItem(item);
    };

    const onApprovePost = async (item: InboxCannyPostItem, mergeIntoPost?: Post) => {
      const response = await AJAX.post('/api/queue/posts/approve', {
        postID: item._id,
        mergeIntoPostID: mergeIntoPost?._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          default: 'There was a problem approving the suggestion. Try again.',
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      removeItem(item);
    };

    const onDeletePost = async (item: InboxCannyPostItem) => {
      const response = await AJAX.post('/api/posts/delete', {
        postID: item._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          default: 'There was a problem deleting the post. Try again.',
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      removeItem(item);
      removePost(item, item.board);
    };

    const onMergePost = async (item: InboxCannyPostItem, targetPost: Post) => {
      const targetBoard = boards.find((board) => board._id === targetPost.boardID);
      if (!targetBoard) {
        showToast('Failed to find the target board', ToastTypes.error);
        return;
      }

      await onApprovePost(item, targetPost);

      const response = await AJAX.post('/api/posts/merge', {
        mergePostID: item._id,
        mergePostIntoID: targetPost._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          'board deleted': 'The board for one of the posts does not exist anymore',
          'invalid company': "You can't merge posts from another company",
          'not authorized': "You don't have permission to merge posts",
          'post missing': 'One of the posts you are trying to merge does not exist anymore',
          'same posts': 'It is not possible to merge one post with itself',
        },
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      }

      reloadOnMerge(item, targetPost, item.board, targetBoard);
      showToast('Posts merged', ToastTypes.success);
    };

    const commonParams = {
      id: inboxItem._id,
      source: inboxItem.source,
      type: inboxItem.type,
      item: inboxItem,
    };

    if (isPostDraft(inboxItem)) {
      return {
        ...commonParams,
        actions: {
          approve: () => approveDraft(inboxItem),
          delete: () => deleteDraft(inboxItem),
          merge: (post: Post) => onMergeDraft(inboxItem, post),
        },
      };
    }

    if (isCannyPost(inboxItem) && !inboxItem.duplicatePost) {
      return {
        ...commonParams,
        actions: {
          approve: () => approvePost(inboxItem),
          delete: () => deletePost(inboxItem),
          merge: (post: Post) => onMergePost(inboxItem, post),
        },
      };
    }

    if (isCannyPost(inboxItem) && inboxItem.duplicatePost) {
      return {
        ...commonParams,
        actions: {
          approve: () => approvePost(inboxItem),
          delete: () => rejectPost(inboxItem),
          merge: (post: Post) => onMergePost(inboxItem, post),
        },
      };
    }

    return null;
  };

  const isReady = !queueItemList.loading && !queueItemList.error;
  const rows = isReady ? queueItemList.items.map<Row | null>(itemToRow).filter(isNotNil) : [];

  if (isReady && !rows.length) {
    return (
      <div className="adminQueuePage empty">
        <AutopilotNUX company={company} counts={queueItemCounts} />
      </div>
    );
  }

  // map sort fields to columnIDs
  const isSortDefined = location.query.sortDirection && location.query.sort;
  const sortColumnMap: Record<string, string> = {
    source: 'source',
    created: 'item',
  };

  const onSort = (sort: Sort | null) => {
    if (!sort) {
      router.replace({
        pathname: location.pathname,
        query: { ...location.query, sort: undefined, sortDirection: undefined },
      });
      return;
    }

    const { columnID, order } = sort;

    const column = columns.find((column) => columnID === column.id);
    if (column && 'sort' in column) {
      column.sort(order);
    }
  };

  return (
    <div className="adminQueuePage">
      <SelectableDataTable<Row>
        fullHeight
        className="adminQueueTable"
        cellAlignment="middle"
        loading={queueItemList.loading}
        error={queueItemList.error}
        padding="small"
        rounded={false}
        onSort={onSort}
        defaultSort={
          isSortDefined
            ? {
                columnID: sortColumnMap[location.query.sort],
                order: location.query.sortDirection,
              }
            : undefined
        }
        pagination={{
          hasMore: queueItemList.hasMore,
          loading: queueItemList.loadingMore,
          onLoadMore: () =>
            loadMoreItems(
              queueItemList.queryParams,
              queueItemList.pages ? queueItemList.pages + 1 : 2
            ),
        }}
        hotkeys={{
          [KeyIdentifiers.Space]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null = element?.querySelector('.sourceLink') ?? null;
            button?.click();
          },
          [KeyIdentifiers.N]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null =
              element?.querySelector('.queueActions > .approve') ?? null;
            button?.click();
          },
          [KeyIdentifiers.V]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null =
              element?.querySelector('.queueActions > .merge') ?? null;
            button?.click();
          },
          [KeyIdentifiers.X]: (_, element: HTMLElement | null) => {
            const button: HTMLElement | null =
              element?.querySelector('.queueActions > .delete') ?? null;
            button?.click();
          },
          [KeyIdentifiers.M]: (row: Row) => {
            if (!row.item.duplicatePost) {
              return;
            }

            row.actions.merge(row.item.duplicatePost);
          },
        }}
        columns={columns}
        rows={rows}
      />
      {selectedPost && (
        <ModalPortal
          allowBodyScroll
          closeOnClickAway={false}
          allowEscape
          className={classnames('adminQueueDrawer', 'postModal', 'modalVisible')}
          onClose={closePost}>
          <div className="modalContents">
            <RoadmapPostContainer boards={boards} post={selectedPost}>
              <AdminFeedbackPost
                key={selectedPost?._id}
                customPostFields={customPostFields}
                onClose={closePost}
                onDelete={() => {
                  if (!selectedPost || !selectedPost.board) {
                    return;
                  }

                  removePost(selectedPost, selectedPost.board);
                  closePost();
                }}
                onEdited={() => {
                  if (!selectedPost) {
                    return;
                  }

                  reloadPost(selectedPost, queueItemList.queryParams);
                  closePost();
                }}
                roadmaps={roadmaps}
                skipFixURL={true}
              />
            </RoadmapPostContainer>
          </div>
        </ModalPortal>
      )}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  deletePostDraft: () => {
    return dispatch(deletePostDraft());
  },
  loadMoreItems: (queryParams: QueryParams, pages: number) => {
    return dispatch(loadMoreQueueItems(queryParams, pages));
  },
  savePostDraft: (postDraft: PostModalDraft) => {
    return dispatch(savePostDraft(postDraft));
  },
  reloadOnMerge: (mergePost: Post, intoPost: Post, fromBoard: Board, toBoard: Board) => {
    return dispatch(reloadOnMerge(mergePost, intoPost, fromBoard, toBoard));
  },
  reloadPostActivity: (post: Post) => {
    return Promise.all([dispatch(reloadPostActivity(post)), dispatch(reloadPost(post))]);
  },
  reloadPost: (post: Post, queryParams?: QueryParams) => {
    return Promise.all([
      dispatch(invalidatePostQueries()),
      dispatch(reloadPost(post)),
      queryParams && dispatch(invalidateQueriesAndReload(queryParams)),
      dispatch(reloadRoadmapPostsForRoadmapsWithPost(post)),
    ]);
  },
  removePost: (post: Post, board: Board) => {
    return Promise.all([
      dispatch(reloadBoard(board.urlName)),
      dispatch(invalidateDashboardActivity()),
      dispatch(invalidateRoadmap()),
      dispatch(invalidateSuggestions()),
      dispatch(removePostFromAllRoadmaps(post)),
      dispatch(invalidatePostQueries()),
    ]);
  },
  removeItem: (item: QueueItem) => {
    return dispatch(removeItem(item));
  },
}))(AdminQueuePosts) as unknown as React.FC<OwnProps>;

import React from 'react';

import classNames from 'classnames';

import { P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/_AdminSettingsSidebarTitle.module.scss';

type Props = {
  title: string;
  className?: string;
};

const AdminSettingsSidebarTitle = ({ title, className }: Props) => {
  return <P className={classNames(styles.title, className)}>{title}</P>;
};

export default AdminSettingsSidebarTitle;

import {
  type AutopilotActionItemAction,
  InvalidateAndReload,
  LoadingMore,
  QueryError,
  QueryLoaded,
  RemoveItem,
  RequestQuery,
  getQueryKey,
} from '../actions/actionItemQueries';

import type { ActionItem } from 'common/api/endpoints/queue';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

export type State = {
  count: number;
  postActionsCount: number;
  draftActionsCount: number;
  draftActionsCountPerSource: Partial<Record<SyncIntegrationNames, number>>;
  items: Record<string, ActionItem>;
  updatedAt: Date | null;
  queries: {
    [queryKey: string]: {
      error: string;
      itemIDs: string[];
      updatedAt: Date;
      loading: boolean;
      pages: number;
      hasMore: boolean;
      loadingMore: boolean;
      queryParams: string;
    };
  };
};

const DefaultState: State = {
  count: 0,
  postActionsCount: 0,
  draftActionsCount: 0,
  draftActionsCountPerSource: {},
  updatedAt: null,
  items: {},
  queries: {},
};

export default function actionItemQueries(
  state: State = DefaultState,
  action: AutopilotActionItemAction
) {
  switch (action.type) {
    case RequestQuery: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postActionsCount: state.postActionsCount,
        draftActionsCount: state.draftActionsCount,
        draftActionsCountPerSource: state.draftActionsCountPerSource,
        items: state.items,
        updatedAt: state.updatedAt,
        queries: {
          ...state.queries,
          [queryKey]: {
            ...state.queries[queryKey],
            updatedAt: action.timestamp,
            loading: true,
            loadingMore: false,
            queryParams: action.queryParams,
          },
        },
      };

      return newState;
    }

    case RemoveItem: {
      const itemToRemove = action.item;
      if (!itemToRemove) {
        return state;
      } else if (!state.items[itemToRemove._id]) {
        return state;
      }

      const updatedState = {
        count: state.count - 1,
        draftActionsCount: state.draftActionsCount,
        postActionsCount: state.postActionsCount,
        draftActionsCountPerSource: state.draftActionsCountPerSource,
        updatedAt: state.updatedAt,
        items: {
          ...state.items,
          [itemToRemove._id]: undefined,
        },
        queries: state.queries,
      };

      if (itemToRemove.sourceType === 'draft') {
        updatedState.draftActionsCount -= 1;

        const { source } = itemToRemove;

        if (source in updatedState.draftActionsCountPerSource) {
          // prevents count from going negative / handles undefined values
          const currentCount = updatedState.draftActionsCountPerSource[source] ?? 0;
          updatedState.draftActionsCountPerSource[source] = Math.max(currentCount - 1, 0);
        }
      } else if (itemToRemove.sourceType === 'post') {
        updatedState.postActionsCount -= 1;
      }

      return updatedState;
    }

    case QueryLoaded: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: action.result.count,
        draftActionsCount: action.result.draftActionsCount,
        postActionsCount: action.result.postActionsCount,
        draftActionsCountPerSource: action.result.draftActionsCountPerSource,
        updatedAt: action.timestamp,
        items: { ...state.items, ...action.result.items },
        queries: {
          ...state.queries,
          [queryKey]: {
            ...state.queries[queryKey],
            itemIDs: Object.keys(action.result.items),
            updatedAt: action.timestamp,
            loading: false,
            loadingMore: false,
            hasMore: action.result.hasMore,
            queryParams: action.queryParams,
            reloading: false,
          },
        },
      };

      return newState;
    }

    case QueryError: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postActionsCount: state.postActionsCount,
        draftActionsCount: state.draftActionsCount,
        draftActionsCountPerSource: state.draftActionsCountPerSource,
        updatedAt: state.updatedAt,
        items: state.items,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            error: action.error,
            updatedAt: action.timestamp,
            loading: false,
            loadingMore: false,
            queryParams: action.queryParams,
          },
        },
      };

      return newState;
    }

    case LoadingMore: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postActionsCount: state.postActionsCount,
        draftActionsCount: state.draftActionsCount,
        draftActionsCountPerSource: state.draftActionsCountPerSource,
        updatedAt: state.updatedAt,
        items: state.items,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            updatedAt: action.timestamp,
            pages: action.pages,
            loadingMore: true,
            queryParams: action.queryParams,
          },
        },
      };

      return newState;
    }

    case InvalidateAndReload: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        count: state.count,
        postActionsCount: state.postActionsCount,
        draftActionsCount: state.draftActionsCount,
        draftActionsCountPerSource: state.draftActionsCountPerSource,
        items: state.items,
        updatedAt: state.updatedAt,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            reloading: true,
          },
        },
      };

      return newState;
    }

    default:
      return state;
  }
}

import React from 'react';

import { loadTLDVSettings } from 'common/actions/tldvSettings';
import asyncConnect from 'common/core/asyncConnect';
import { type TLDVSettingsState, defaultState } from 'common/reducers/tldvSettings';

import type { State } from 'redux-connect';

type Props = {
  tldvSettings: TLDVSettingsState;
  children: React.ReactNode;
};

export const TLDVSettingsContext = React.createContext<TLDVSettingsState>(defaultState);

const TLDVSettingsContainer = ({ children, tldvSettings }: Props) => (
  <TLDVSettingsContext.Provider value={tldvSettings}>{children}</TLDVSettingsContext.Provider>
);

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadTLDVSettings()),
    },
  ],
  (state: State) => ({ tldvSettings: state.tldvSettings })
)(TLDVSettingsContainer);

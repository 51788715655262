import React from 'react';

import PropTypes from 'prop-types';

import SentrySDK from 'common/3rd/SentrySDK';

import 'css/components/common/_ErrorBoundary.scss';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    message: PropTypes.string,
  };

  static defaultProps = {
    message: 'Something went wrong and our team has been notified. Please try again later.',
  };

  state = {
    error: false,
  };

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    SentrySDK.captureException(error);
  }

  render() {
    const { message, renderError } = this.props;
    const { error } = this.state;
    if (!error) {
      return this.props.children;
    }

    if (renderError) {
      return renderError();
    }

    return (
      <div className="errorBoundary">
        <p className="error">{message}</p>
      </div>
    );
  }
}

import devURL from 'common/util/devURL';
import queryString from 'common/util/queryString';

export default class OAuthOkta {
  constructor({
    okta,
    onSuccess,
    onFailure,
    onContinue,
    shouldOpenNewTab,
    redirectURL,
    separateWindow,
  }) {
    this._okta = okta;
    this._onFailure = onFailure;
    this._onContinue = onContinue;
    this._redirectURL = redirectURL;
    this._separateWindow = separateWindow;
    this._shouldOpenNewTab = shouldOpenNewTab;
  }

  connect = () => {
    const query = queryString.stringify({ iss: this._okta.oktaDomain });
    const url = devURL(`https://canny.io/api/oauth/okta/initiate${query}`);

    window.location = url;
  };
}

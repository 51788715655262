import React, { useRef, useState } from 'react';

import { Settings2 } from 'lucide-react';

import Popover from 'common/common/Popover';
import Portal from 'common/common/Portal';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import { VoteWeight } from 'common/post/PostVotes/Constants';
import VoteWeightPill from 'common/post/VoteWeightPill';
import DateRangeFilter from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapViewSettingsModal/DateRangeFilter';
import TextFilter from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapViewSettingsModal/TextFilter';
import { ThirdPartyCompanyDefaultField } from 'common/thirdPartyCompanies/constants';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import { P, Span } from 'common/ui/Text';
import { RelativeDateRanges } from 'common/util/dateRanges';
import isNil from 'common/util/isNil';
import styles from 'css-module/components/subdomain/admin/reports/_FiltersPopover.module.scss';

import type { Filter, Filters } from 'common/api/endpoints/thirdPartyCompanyFeatureRequests';
import type { Nullable } from 'common/types/util';

type Props = {
  filters: Filters;
  onChange: (filters: Filters) => void;
};

const EmptyFilter = {
  operator: null,
  value: null,
};

const VoteWeightOptions = [
  {
    render: <VoteWeightPill weight={VoteWeight.niceToHave} />,
    label: 'Nice to have',
    value: String(VoteWeight.niceToHave),
  },
  {
    render: <VoteWeightPill weight={VoteWeight.important} />,
    label: 'Important',
    value: String(VoteWeight.important),
  },
  {
    render: <VoteWeightPill weight={VoteWeight.mustHave} />,
    label: 'Must have',
    value: String(VoteWeight.mustHave),
  },
];

const deleteFilter = (filters: Filters, filter: keyof Filters) => {
  const updatedfilters = { ...filters };
  delete updatedfilters[filter];
  return updatedfilters;
};

const FiltersPopover = ({ filters, onChange }: Props) => {
  const [open, setOpen] = useState(false);
  const portalRef = useRef(null);
  const buttonRef = useRef(null);

  const filterList = Object.values(filters);

  useBackgroundClick(() => setOpen(false), [portalRef, buttonRef]);

  const updateFilter = (filter: Nullable<Filter>, field: keyof Filters) => {
    if (!filter.operator) {
      return onChange(deleteFilter(filters, field));
    }

    onChange({
      ...filters,
      [field]: filter,
    });
  };

  return (
    <>
      <ButtonV2
        size="medium"
        startIcon={Settings2}
        onClick={() => setOpen(!open)}
        ref={buttonRef}
        variant="outlined">
        Filters&nbsp;
        {filterList.length > 0 && <Span variant="bodyMd">({filterList.length})</Span>}
      </ButtonV2>
      {open && (
        <Portal
          align="end"
          ref={portalRef}
          className={styles.filtersPopoverContainer}
          zIndex={2998} // adjust to render right behind ModalPortal
          relativeToRef={buttonRef}>
          <Popover
            cta={
              <ButtonV2
                size="medium"
                variant="plain"
                aria-label="Reset filters"
                onClick={() => onChange({})}>
                Reset filters
              </ButtonV2>
            }
            title="Filters">
            <div className={styles.filtersBody}>
              <div className={styles.filter}>
                <P fontWeight="semibold">Company Spend</P>
                <TextFilter
                  filter={filters?.monthlySpend ?? EmptyFilter}
                  inputType="customFieldNumber"
                  onChange={(filter) =>
                    updateFilter(filter, ThirdPartyCompanyDefaultField.monthlySpend)
                  }
                />
              </div>
              <div className={styles.filter}>
                <P fontWeight="semibold">Renewal Risk</P>
                <TextFilter
                  filter={filters?.renewalRisk ?? EmptyFilter}
                  inputType="customFieldText"
                  onChange={(filter) =>
                    updateFilter(filter, ThirdPartyCompanyDefaultField.renewalRisk)
                  }
                />
              </div>
              <div className={styles.filter}>
                <P fontWeight="semibold">Account Owner</P>
                <TextFilter
                  filter={filters?.accountOwner ?? EmptyFilter}
                  inputType="customFieldText"
                  onChange={(filter) =>
                    updateFilter(filter, ThirdPartyCompanyDefaultField.accountOwner)
                  }
                />
              </div>
              <div className={styles.filter}>
                <P fontWeight="semibold">Renewal Date</P>
                <DateRangeFilter
                  dateRange={filters?.renewalDate ?? RelativeDateRanges.allTime}
                  firstColumn={[
                    RelativeDateRanges.thisWholeMonth,
                    RelativeDateRanges.thisWholeQuarter,
                    RelativeDateRanges.thisWholeHalf,
                  ]}
                  secondColumn={[
                    RelativeDateRanges.nextMonth,
                    RelativeDateRanges.nextQuarter,
                    RelativeDateRanges.nextHalf,
                  ]}
                  onChange={({ dateRange, relativeDate }) => {
                    const isEmpty = Array.isArray(dateRange) && dateRange.some(isNil);
                    if (isEmpty) {
                      return onChange(
                        deleteFilter(filters, ThirdPartyCompanyDefaultField.renewalDate)
                      );
                    }

                    onChange({
                      ...filters,
                      [ThirdPartyCompanyDefaultField.renewalDate]:
                        typeof relativeDate === 'string'
                          ? (relativeDate as RelativeDateRanges)
                          : (dateRange as [string, string]),
                    });
                  }}
                />
              </div>
              <div className={styles.filter}>
                <P fontWeight="semibold">Priority</P>
                <SingleSelect
                  allowClear
                  placeholder="All priorities"
                  options={VoteWeightOptions}
                  value={VoteWeightOptions.find(
                    (option) => option.value === String(filters.voteWeight)
                  )}
                  onChange={(option) => {
                    if (!option) {
                      return onChange(deleteFilter(filters, 'voteWeight'));
                    }

                    onChange({
                      ...filters,
                      voteWeight: Number(option.value),
                    });
                  }}
                />
              </div>
              <div className={styles.filter}>
                <P fontWeight="semibold">Total Opportunity</P>
                <TextFilter
                  onChange={(filter) => updateFilter(filter, 'opportunityValue')}
                  filter={filters?.opportunityValue ?? EmptyFilter}
                  inputType="customFieldNumber"
                />
              </div>
            </div>
          </Popover>
        </Portal>
      )}
    </>
  );
};

export default FiltersPopover;

import React from 'react';

import { AlertCircle, Unplug } from 'lucide-react';

import Tooltip from 'common/common/Tooltip';
import {
  IntegrationNameMap,
  SalesforceAutomaticFields,
  ThirdPartyCompanyDefaultField,
} from 'common/thirdPartyCompanies/constants';
import IconButtonV2 from 'common/ui/IconButtonV2';
import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/subdomain/admin/_AdminCompanyFieldsSettings.module.scss';

import { type Field, type FieldMapping, fieldIsIntegration } from '../utils';

import type { Row } from './types';

type Props = {
  fieldMapping: FieldMapping;
  disabled?: boolean;
  row: Row;
  fields: Field[];
  onUpdateFieldMapping: (updatedFieldMapping: FieldMapping) => void;
};

const ActionsCell = ({ fieldMapping, row, fields, onUpdateFieldMapping, disabled }: Props) => {
  const selectedFieldName = fieldMapping[row.defaultField.name];
  const isAutomaticSalesforceField = Object.values(SalesforceAutomaticFields).find(
    (field) => field.linked === row.defaultField.name
  );

  // if the user has selected a field that is not a default field, we need to render the unlink button
  const shouldRenderUnlinkButton =
    !disabled && selectedFieldName && !(selectedFieldName in ThirdPartyCompanyDefaultField);

  // we need to show the alert if the user is trying to link a salesforce field to an automatic salesforce field
  const shouldRenderAlert =
    isAutomaticSalesforceField &&
    fieldIsIntegration(
      [IntegrationNameMap.salesforce, IntegrationNameMap.hubspot],
      selectedFieldName,
      fields
    );

  let alert: string | null = null;
  if (row.defaultField.name === ThirdPartyCompanyDefaultField.accountOwner) {
    alert = `Please ensure the "${selectedFieldName}" field contains the account owner's name. Consider using the "Account Owner ID" field to automatically sync the name.`;
  } else if (row.defaultField.name === ThirdPartyCompanyDefaultField.accountOwnerEmail) {
    alert = `Please ensure the "${selectedFieldName}" field contains the account owner's email. Consider using the "Account Owner ID" field to automatically sync the email.`;
  }

  return (
    <div className={styles.actionButtons}>
      {shouldRenderAlert && alert && (
        <Tooltip position="top" value={nbspLastSpace(alert)} className={styles.tooltip}>
          <AlertCircle size={16} className={styles.alertIcon} />
        </Tooltip>
      )}
      {shouldRenderUnlinkButton && (
        <Tooltip position="top" value="Unlink field">
          <IconButtonV2
            size="medium"
            icon={Unplug}
            onClick={() => {
              const updatedFieldMapping = {
                ...fieldMapping,
                [row.defaultField.name]: null,
              };
              onUpdateFieldMapping(updatedFieldMapping);
            }}
            variant="plain"
            aria-label="Unlink field"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ActionsCell;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Portal from 'common/common/Portal';

import 'css/components/common/_Tooltip.scss';

export default class Tooltip extends Component {
  static propTypes = {
    className: PropTypes.string,
    delay: PropTypes.number,
    containerClassName: PropTypes.string,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    value: PropTypes.node.isRequired,
  };

  static defaultProps = {
    delay: 0,
    disabled: false,
    position: 'right',
  };

  timeoutID = null;

  state = {
    tooltipOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.childrenRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('touchend', this.onTouchEnd, false);
  }

  componentWillUnmount() {
    document.removeEventListener('touchend', this.onTouchEnd, false);
  }

  onMouseLeave = () => {
    // cancel pending "open" operations
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      this.timeoutID = null;
    }

    this.setState({ tooltipOpen: false });
    return;
  };

  onMouseOver = () => {
    const { delay } = this.props;

    if (!delay) {
      this.setState({ tooltipOpen: true });
      return;
    }

    // wait before opening the tooltip
    if (!this.timeoutID) {
      this.timeoutID = setTimeout(() => this.setState({ tooltipOpen: true }), delay);
    }
  };

  onTouchEnd = (e) => {
    if (!findDOMNode(this).contains(e.target)) {
      // touchend happens outside of tooltip, close tooltip
      this.setState({ tooltipOpen: false });
    } else {
      // touchend happens on tooltip, toggle
      this.setState({ tooltipOpen: !this.state.tooltipOpen });
    }
  };

  renderTooltip() {
    if (!this.state.tooltipOpen || this.props.disabled) {
      return null;
    }

    const { position, value } = this.props;
    const className = classnames('tooltip', position, this.props.className);

    return (
      <Portal
        align="center"
        className="tooltipContainer"
        position={position}
        relativeToRef={this.childrenRef}>
        <div className={className}>
          <div className="arrow" />
          <div className="padding" />
          {value}
        </div>
      </Portal>
    );
  }

  render() {
    const className = classnames('tooltipContainer', this.props.containerClassName);
    return (
      <div className={className} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
        {this.renderTooltip()}
        <span ref={this.childrenRef}>{this.props.children}</span>
      </div>
    );
  }
}

import Data from '../Data';

// Action Types

export const REQUEST_ALL_BOARDS = 'canny/boards/request_all_boards';
export const ALL_BOARDS_LOADED = 'canny/boards/all_boards_loaded';
export const ALL_BOARDS_ERROR = 'canny/boards/all_boards_error';

export const REQUEST_BOARD = 'canny/boards/request_board';
export const BOARD_LOADED = 'canny/boards/board_loaded';
export const BOARD_NOT_FOUND = 'canny/boards/board_not_found';
export const BOARD_NOT_AUTHORIZED = 'canny/boards/board_not_authorized';
export const BOARD_ERROR = 'canny/boards/board_error';

// Actions

function requestAllBoards() {
  return {
    timestamp: Date.now(),
    type: REQUEST_ALL_BOARDS,
  };
}

function allBoardsLoaded(boards) {
  return {
    boards: boards,
    timestamp: Date.now(),
    type: ALL_BOARDS_LOADED,
  };
}

function allBoardsError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: ALL_BOARDS_ERROR,
  };
}

function requestBoard(boardURLName) {
  return {
    boardURLName,
    timestamp: Date.now(),
    type: REQUEST_BOARD,
  };
}

function boardLoaded(boardURLName, board) {
  return {
    boardURLName,
    board: board,
    timestamp: Date.now(),
    type: BOARD_LOADED,
  };
}

function boardNotFound(boardURLName, requestedBoardID) {
  return {
    boardURLName,
    requestedBoardID,
    timestamp: Date.now(),
    type: BOARD_NOT_FOUND,
  };
}

function boardNotAuthorized(boardURLName, errorReason, requestedBoardID) {
  return {
    boardURLName,
    errorReason,
    requestedBoardID,
    timestamp: Date.now(),
    type: BOARD_NOT_AUTHORIZED,
  };
}

function boardError(boardURLName, error) {
  return {
    boardURLName,
    error: error,
    timestamp: Date.now(),
    type: BOARD_ERROR,
  };
}

// Action Creators

function fetchAllBoards() {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        boards: {
          query: Data.queries.boards,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(allBoardsError(error));
        } else {
          return dispatch(allBoardsLoaded(data.boards));
        }
      }
    );
  };
}

export function loadAllBoards() {
  return (dispatch, getState) => {
    if (shouldFetchAllBoards(getState())) {
      dispatch(requestAllBoards());
      return dispatch(fetchAllBoards());
    }
  };
}

export function reloadAllBoards() {
  return (dispatch) => {
    return dispatch(fetchAllBoards());
  };
}

function fetchBoard(boardURLName) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        board: {
          input: {
            boardURLName: boardURLName,
          },
          query: Data.queries.board,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, boardURLName, error, data);
      }
    );
  };
}

export function loadBoard(boardURLName) {
  return (dispatch, getState) => {
    if (shouldFetchBoard(getState(), boardURLName)) {
      dispatch(requestBoard(boardURLName));
      return dispatch(fetchBoard(boardURLName));
    } else if (isFetchingBoard(getState(), boardURLName)) {
      return waitForResult(boardURLName);
    }
  };
}

export function reloadBoard(boardURLName) {
  return (dispatch) => {
    return dispatch(fetchBoard(boardURLName));
  };
}

// Helper Functions

function shouldFetchBoard(state, boardURLName) {
  if (!boardURLName) {
    return false;
  }

  const boards = state.boards;
  return !boards.items.hasOwnProperty(boardURLName);
}

function isFetchingBoard(state, boardURLName) {
  if (!boardURLName) {
    return false;
  }

  const boards = state.boards;
  return !!boards.items[boardURLName].loading;
}

function shouldFetchAllBoards(state) {
  const boards = state.boards;
  return !boards.allLoaded && !boards.allLoading && !boards.error;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(boardURLName) {
  return new Promise((resolve, reject) => {
    resultCallbacks[boardURLName] = resultCallbacks[boardURLName] || [];
    resultCallbacks[boardURLName].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, boardURLName, error, data) {
  let resultAction;
  if (error && error.message === 'not found') {
    resultAction = boardNotFound(boardURLName, error.requestedBoardID);
  } else if (error && error.message && error.message === 'not authorized') {
    resultAction = boardNotAuthorized(boardURLName, error.reason, error.requestedBoardID);
  } else if (error) {
    resultAction = boardError(boardURLName, error);
  } else {
    resultAction = boardLoaded(boardURLName, data.board);
  }

  return Promise.all([dispatch(resultAction)]).then(() => {
    if (!resultCallbacks[boardURLName]) {
      return;
    }

    resultCallbacks[boardURLName].forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks[boardURLName].length = 0;
  });
}

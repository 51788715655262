import React from 'react';

import plaintext from 'common/markdown/plaintext';
import Mentionify from 'common/Mentionify';

import AdminQueuePost from './AdminQueuePost';

import type { Board } from 'common/api/endpoints/boards';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { Comment } from 'common/api/resources/comments';

import 'css/components/subdomain/admin/_AdminQueuePost.scss';

type Props = {
  className?: string;
  title: string;
  author?: StrippedUser;
  created?: string;
  board?: Board;
  value: string;
  meta?: {
    score: number;
    commentCount: number;
    monthlySpend?: number;
  };
  workaround?: string | null;
  mentionedUsers?: Comment['mentionedUsers'];
  truncate?: boolean;
};

const AdminQueueComment = ({
  title,
  className,
  author,
  created,
  board,
  mentionedUsers,
  value,
}: Props) => {
  const details = <Mentionify mentions={mentionedUsers}>{plaintext(value)}</Mentionify>;
  return (
    <AdminQueuePost
      className={className}
      title={title}
      details={details}
      author={author}
      created={created}
      board={board}
    />
  );
};

export default AdminQueueComment;

import React from 'react';

import ErrorBoundary from 'common/common/ErrorBoundary';

import AdminSettingsSidebar from './AdminSettingsSidebar';

import 'css/components/subdomain/admin/AdminSettings/_AdminSettings.scss';

type Props = {
  children: React.ReactNode;
};

const AdminSettings = ({ children }: Props) => {
  return (
    <div className="adminSettings" id="adminSettings">
      <AdminSettingsSidebar />
      <div className="mainContainer">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </div>
  );
};

export default AdminSettings;

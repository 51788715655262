import type { Voter } from 'common/api/endpoints/voters';

export default function calculateTotalMRR(voters: Voter[]): number {
  const companyMap: Record<string, number> = {};

  voters.forEach((voter) => {
    if (!voter.companies) {
      return;
    }
    voter.companies.forEach((company) => {
      companyMap[company._id] = Math.round(company.monthlySpend || 0);
    });
  });

  const totalMRR = Object.values(companyMap).reduce((sum, monthlySpend) => {
    return sum + monthlySpend;
  }, 0);

  return totalMRR;
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Portal from 'common/common/Portal';
import TextInput from 'common/inputs/TextInput';
import Tag from 'common/tags/Tag';
import Tappable from 'common/Tappable';
import findStringMatches from 'common/util/findStringMatches';
import stringSort from 'common/util/stringSort';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardActivityFilterPicker.scss';

export default class AdminDashboardActivityFilterPicker extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['center', 'end', 'start']),
    boards: PropTypes.array,
    onFilterUpdate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    align: 'start',
  };

  state = {
    pickerOpen: false,
    selectedCategories: [],
    selectedTags: [],
    searchValue: '',
  };

  constructor(props, context) {
    super(props, context);
    this.dropdownButtonRef = React.createRef();
    this.portalRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onCloseDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onCloseDropdown);
  }

  onCloseDropdown = () => {
    if (!this.state.pickerOpen) {
      return;
    }
    this.setState({
      pickerOpen: false,
      searchValue: '',
    });
  };

  onSelectCategory = (category) => {
    const { selectedCategories } = this.state;
    const alreadySelected = selectedCategories.includes(category.urlName);

    const updatedCategories = alreadySelected
      ? selectedCategories.filter((urlName) => urlName !== category.urlName)
      : [...selectedCategories, category.urlName];

    this.setState({ selectedCategories: updatedCategories }, () => {
      this.props.onFilterUpdate({
        categories: this.state.selectedCategories,
        tags: this.state.selectedTags,
      });
      // The number of active filters changed, so we need to resync the position of the dropdown.
      this.portalRef?.current?.updateRelativePosition();
    });
  };

  onSelectTag = (tag) => {
    const { selectedTags } = this.state;
    const alreadySelected = selectedTags.includes(tag.urlName);

    const updatedTags = alreadySelected
      ? selectedTags.filter((urlName) => urlName !== tag.urlName)
      : [...selectedTags, tag.urlName];

    this.setState({ selectedTags: updatedTags }, () => {
      this.props.onFilterUpdate({
        categories: this.state.selectedCategories,
        tags: this.state.selectedTags,
      });
      // The number of active filters changed, so we need to resync the position of the dropdown.
      this.portalRef?.current?.updateRelativePosition();
    });
  };

  onSearchChange = (e) => {
    this.setState({ searchValue: e.target.value?.trim() });
  };

  onToggleDropdown = () => {
    this.setState((state) => ({
      pickerOpen: !state.pickerOpen,
      searchValue: '',
    }));
  };

  getFilteredItems(itemMap, selectedItems) {
    const { searchValue } = this.state;
    const items = Object.values(itemMap);
    const matches = searchValue
      ? findStringMatches(items, 'name', searchValue)
      : items.filter((item) => {
          return selectedItems.includes(item.urlName);
        });
    matches.sort(stringSort('name'));
    return matches;
  }

  renderDropdownButton() {
    const { selectedCategories, selectedTags } = this.state;
    const appliedFilters = selectedCategories.length + selectedTags.length;
    return (
      <div ref={this.dropdownButtonRef}>
        <Tappable onTap={this.onToggleDropdown}>
          <div className="dropdownButton">
            <div className="icon-filter" />
            {appliedFilters > 0 && <div className="appliedFilters">{appliedFilters}</div>}
          </div>
        </Tappable>
      </div>
    );
  }

  renderFilters() {
    const tagOptions = this.renderTagOptions();
    const categoryOptions = this.renderCategoryOptions();
    if (!tagOptions && !categoryOptions) {
      if (!this.state.searchValue) {
        return null;
      }

      return <div className="section noResults">No results match this search</div>;
    }

    return (
      <div className="filters">
        {tagOptions}
        {categoryOptions}
      </div>
    );
  }

  renderPicker() {
    const { align } = this.props;
    if (!this.state.pickerOpen) {
      return null;
    }

    return (
      <Portal
        align={align}
        className="adminDashboardActivityFilterPickerPortal"
        onBlur={this.onCloseDropdown}
        ref={this.portalRef}
        relativeToRef={this.dropdownButtonRef}>
        <div className="pickerContainer">
          {this.renderSearch()}
          {this.renderFilters()}
        </div>
      </Portal>
    );
  }

  renderCategoryOptions() {
    const categoryMap = {};
    this.props.boards.forEach((board) => {
      board.categories.forEach((category) => {
        categoryMap[category.urlName] = {
          name: category.name,
          urlName: category.urlName,
        };
      });
    });

    const filtered = this.getFilteredItems(categoryMap, this.state.selectedCategories);
    if (filtered.length === 0) {
      return null;
    }

    const categories = filtered.map((category) => {
      return (
        <Tag
          key={category.urlName}
          name={category.name}
          onTap={() => this.onSelectCategory(category)}
          selected={this.state.selectedCategories.includes(category.urlName)}
        />
      );
    });

    return (
      <div className="section">
        <div className="sectionTitle">Categories</div>
        <div className="tagList">{categories}</div>
      </div>
    );
  }

  renderSearch() {
    return (
      <div className="search">
        <div className="icon icon-search" />
        <TextInput
          className="searchInput"
          onChange={this.onSearchChange}
          placeholder="Search for tags/categories..."
        />
      </div>
    );
  }

  renderTagOptions() {
    const tagMap = {};
    this.props.boards.forEach((board) => {
      board.tags.forEach((tag) => {
        tagMap[tag.urlName] = {
          name: tag.name,
          urlName: tag.urlName,
        };
      });
    });

    const filtered = this.getFilteredItems(tagMap, this.state.selectedTags);
    if (filtered.length === 0) {
      return null;
    }

    const tags = filtered.map((tag) => {
      return (
        <Tag
          key={tag.urlName}
          name={tag.name}
          onTap={() => this.onSelectTag(tag)}
          selected={this.state.selectedTags.includes(tag.urlName)}
        />
      );
    });

    return (
      <div className="section">
        <div className="sectionTitle">Tags</div>
        <div className="tagList">{tags}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="adminDashboardActivityFilterPicker">
        {this.renderDropdownButton()}
        {this.renderPicker()}
      </div>
    );
  }
}

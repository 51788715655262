import diacritics from './diacritics';

const Dashes = /[-]/gi;
const MaxLength = 30;
const Spaces = /[ ]/gi;
const UnallowedCharacters = /[^a-z0-9- ]/gi;

export default function subdomainify(input: string): string {
  let subdomain = input.toLowerCase();
  subdomain = diacritics.remove(subdomain);
  subdomain = subdomain.replace(UnallowedCharacters, '');
  subdomain = subdomain.replace(Dashes, ' ');
  subdomain = subdomain.toLowerCase().trim();
  subdomain = subdomain.replace(Spaces, '-');
  subdomain = subdomain.substr(0, MaxLength);
  return subdomain;
}

const requestActionFactory = <D extends object>(key: string) => {
  const RequestType = `canny/${key}/request`;
  const LoadedType = `canny/${key}/loaded`;
  const ErrorType = `canny/${key}/error`;

  function requestAction() {
    return {
      timestamp: Date.now(),
      type: RequestType,
    };
  }

  function loadedAction(data: D) {
    return {
      data,
      timestamp: Date.now(),
      type: LoadedType,
    };
  }

  function errorAction(error: string) {
    return {
      error,
      timestamp: Date.now(),
      type: ErrorType,
    };
  }

  return {
    requestAction,
    loadedAction,
    errorAction,

    RequestType,
    LoadedType,
    ErrorType,
  };
};

export default requestActionFactory;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Facepile from 'common/user/Facepile';

import 'css/components/post/_VotersFacepile.scss';

const MaxFacesToShow = 5;

export default class VotersFacepile extends Component {
  static propTypes = {
    post: PropTypes.object,
    voters: PropTypes.arrayOf(
      PropTypes.shape({
        avatarURL: PropTypes.string,
      })
    ),
  };

  renderExtraString() {
    const { post, voters } = this.props;

    if (!post.voteSettings.highEngagement) {
      return null;
    }

    const extraString = post.voteSettings.votesHidden
      ? 'more'
      : post.score - Math.min(voters.length, MaxFacesToShow);

    return <div className="more">{`+ ${extraString}`}</div>;
  }

  render() {
    const { voters } = this.props;
    if (!voters || !voters.length) {
      return null;
    }

    return (
      <div className="votersFacepile">
        <Facepile maxFaces={MaxFacesToShow} users={voters} />
        {this.renderExtraString()}
      </div>
    );
  }
}

import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import AutopilotUsageCard from 'common/subdomain/admin/AdminQueue/AdminQueueSidebar/AutopilotUsageCard';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';
import styles from 'css-module/components/subdomain/admin/settings/_AdminAutopilotSettings.module.scss';

const SidebarLinks = [
  {
    label: 'Feedback Discovery',
    urlSuffix: 'feedback-discovery',
  },
  {
    label: 'Knowledge Hub',
    urlSuffix: 'knowledge-hub',
    allowlistFlag: 'rag-feature-request', // TODO: Remove this when out of beta
  },
];

const AdminAutopilotSettings = ({ children }: { children: React.ReactNode }) => {
  const company = useContext(CompanyContext);

  return (
    <div className={styles.container}>
      <AdminSettingsHeader
        title="Autopilot"
        subheading="Configure AI-powered features that replace manual work."
        learnMoreLink="https://help.canny.io/en/articles/8202451-beta-inbox"
      />
      <div className={styles.content}>
        <div className={styles.sidebar}>
          {SidebarLinks.filter(
            ({ allowlistFlag }) =>
              !allowlistFlag || company.featureAllowlist.includes(allowlistFlag)
          ).map(({ label, urlSuffix }) => (
            <AdminSettingsSidebarLink key={urlSuffix} to={`/admin/settings/autopilot/${urlSuffix}`}>
              {label}
            </AdminSettingsSidebarLink>
          ))}
          <div className={styles.usageContainer}>
            <AutopilotUsageCard showPercentage={false} company={company} />
          </div>
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
};

export default AdminAutopilotSettings;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import 'css/components/subdomain/admin/_AdminPageSidebar.scss';

export default class AdminPageSidebar extends Component {
  static propTypes = {
    adjustHeightForOptions: PropTypes.bool, // Leaves a space for saved filter options in feedback sidebar
    footer: PropTypes.element,
    showSidebar: PropTypes.bool,
  };

  static defaultProps = {
    adjustHeightForOptions: false,
  };

  constructor(props) {
    super(props);

    this.sidebarRef = React.createRef();
  }

  render() {
    const sidebarClassNames = classnames({
      adminPageSidebar: true,
      collapsed: !this.props.showSidebar,
    });

    const sidebarContentsClassNames = classnames({
      sidebarContents: true,
      collapsed: !this.props.showSidebar,
    });

    const sidebarStyle = this.props.adjustHeightForOptions
      ? {
          height: `calc(100% - ${this.sidebarRef.current?.offsetTop ?? 0}px)`,
        }
      : {};

    return (
      <div className={sidebarClassNames} style={sidebarStyle} ref={this.sidebarRef}>
        <div className="fixedColumn">
          <div className={sidebarContentsClassNames}>
            <div className="filterSections">{this.props.children}</div>
          </div>
          {this.props.footer}
        </div>
      </div>
    );
  }
}

import { Component } from 'react';

import PropTypes from 'prop-types';

import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import { getUserQueryKey, loadQuery } from '../actions/userQueries';

function getQueryParams(location) {
  const { query } = location;

  const search = query.search ? query.search.trim() : null;

  const sort = query.sort || null;
  const segmentURLName = query.segment || null;
  const dateRange = query.dateRange || null;

  return {
    ...(search && { search }),
    ...(sort && { sort }),
    ...(segmentURLName && { segmentURLName }),
    ...(dateRange && { dateRange }),
  };
}

const asyncFetch = {
  promise: ({ store: { dispatch }, location }) => {
    return dispatch(loadQuery(getQueryParams(location)));
  },
};

class UserListContainer extends Component {
  static asyncConnect = asyncFetch;

  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.object,
      state: PropTypes.object,
    }),
    userQueries: PropTypes.object,
    users: PropTypes.object,
  };

  getUserList() {
    const { location, users, userQueries } = this.props;
    const userQueryKey = getUserQueryKey(getQueryParams(location));
    const userList = Object.assign({ loading: true }, userQueries[userQueryKey]);
    if (!userList.userIDs || !userList.userIDs.length) {
      return userList;
    }

    const usersByID = {};
    Object.keys(users).forEach((urlName) => {
      const user = users[urlName];
      usersByID[user._id] = user;
    });

    userList.users = userList.userIDs.map((id) => usersByID[id]);

    return userList;
  }

  render() {
    const { children } = this.props;
    return cloneElementWithProps(children, {
      ...this.props,
      userList: this.getUserList(),
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  userQueries: state.userQueries,
  users: state.users,
}))(UserListContainer);

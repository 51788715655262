import React from 'react';

import Helmet from 'common/helmets/Helmet';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import { RoutePermissions } from 'common/util/permissions';

import 'css/components/subdomain/admin/_AdminRoadmapSettingsContainer.scss';
import AdminSettingsSidebarLink from './AdminSettingsSidebarLink';

type RoadmapSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.roadmap;
};

const SidebarLinks: RoadmapSidebarLink[] = [
  {
    label: 'Public Roadmap',
    urlSuffix: 'public-view',
  },
  {
    label: 'Statuses',
    urlSuffix: 'statuses',
  },
  {
    label: 'Archive',
    urlSuffix: 'archive',
  },
];

const AdminRoadmapSettingsContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="adminRoadmapSettingsContainer">
    <Helmet title="Roadmap & Statuses Settings | Canny" />
    <AdminSettingsHeader
      title="Roadmaps and Statuses"
      subheading="Manage your roadmaps and status&nbsp;labels."
      learnMoreLink="https://help.canny.io/en/articles/673583-post-statuses"
    />
    <div className="settingsContainer">
      <div className="settingsSidebar">
        {SidebarLinks.map(({ label, urlSuffix }) => (
          <AdminSettingsSidebarLink
            key={urlSuffix}
            requiredPermissions={RoutePermissions.adminSettings.roadmap[urlSuffix]}
            to={`/admin/settings/roadmap/${urlSuffix}`}>
            {label}
          </AdminSettingsSidebarLink>
        ))}
      </div>
      <div className="settingsContent">{children}</div>
    </div>
  </div>
);

export default AdminRoadmapSettingsContainer;

import { postsLoaded } from './posts';
import Data from '../Data';

// Action Types

export const RequestUserPosts = 'canny/userPosts/request_user_posts';
export const UserPostsLoaded = 'canny/userPosts/user_posts_loaded';
export const UserPostsError = 'canny/userPosts/user_posts_error';
export const LoadingMore = 'canny/userPosts/loading_more';
export const Invalidate = 'canny/userPosts/invalidate';

// Actions

function requestUserPosts(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestUserPosts,
  };
}

export function userPostsLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: UserPostsLoaded,
  };
}

function userPostsError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: UserPostsError,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LoadingMore,
  };
}

function invalidate(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: getRequestData(queryParams),
          query: Data.queries.userPosts,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, queryParams, error, data);
      }
    );
  };
}

export function loadQuery(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestUserPosts(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(postList) {
  return (dispatch) => {
    const queryParams = postList.queryParams;
    dispatch(loadingMore(queryParams));
    queryParams.limit = queryParams.limit ? queryParams.limit + 10 : 20;
    return dispatch(fetchQuery(queryParams));
  };
}

export function invalidateUserPosts(queryParams) {
  return (dispatch) => {
    dispatch(invalidate(queryParams));
  };
}

// Helper Functions

export function getUserPostsKey(queryParams) {
  return JSON.stringify({
    filter: queryParams.filter,
    ...(queryParams.user && { userID: queryParams.user._id }),
  });
}

function getRequestData(queryParams) {
  return {
    limit: queryParams.limit || 10,
    userID: queryParams.user._id,
    filter: queryParams.filter.split('-'),
  };
}

function shouldFetchQuery(state, queryParams) {
  const userPosts = state.userPosts;
  const queryKey = getUserPostsKey(queryParams);
  return !userPosts[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const userPosts = state.userPosts;
  const queryKey = getUserPostsKey(queryParams);
  return !!userPosts[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getUserPostsKey(queryParams);
  return new Promise((resolve) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(resolve);
  });
}

function gotResult(dispatch, queryParams, error, data) {
  let resultAction;
  const promises = [];
  if (error) {
    resultAction = userPostsError(queryParams, error);
  } else {
    resultAction = userPostsLoaded(queryParams, data.result);
    promises.push(dispatch(postsLoaded(data.result.postList.posts)));
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      if (!resultCallbacks[queryParams]) {
        return;
      }

      resultCallbacks[queryParams].forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks[queryParams].length = 0;
    });
  });
}

import React, { Component } from 'react';

import classnames from 'classnames';

import 'css/components/_Spinner.scss';

export default class Spinner extends Component {
  render() {
    return <div className={classnames(this.props.className, 'spinner')} />;
  }
}

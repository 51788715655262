import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withContexts from 'common/util/withContexts';

import Link from './Link';

import WhiteIcon from 'img/canny-icon-white.svg';
import PurpleIcon from 'img/canny-icon.svg';
import WhiteLogo from 'img/canny-wordmark-white.svg';
import PurpleLogo from 'img/canny-wordmark.svg';

import 'css/components/_NavLogo.scss';

class NavLogo extends Component {
  static propTypes = {
    linkTo: PropTypes.string,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    style: PropTypes.string,
  };

  static defaultProps = {
    linkTo: '/',
  };

  onLogoTapped = () => {
    const { linkTo, location, router } = this.props;
    if (location.pathname === linkTo) {
      router.replace(linkTo);
      return;
    }

    router.push(linkTo);
  };

  render() {
    const { linkTo } = this.props;
    const desktopLogo = this.props.style === 'light' ? WhiteLogo : PurpleLogo;
    const mobileLogo = this.props.style === 'light' ? WhiteIcon : PurpleIcon;
    return (
      <div className="navLogoContainer">
        <Link className="navLogo" fakeLink={true} onTap={this.onLogoTapped} to={linkTo}>
          <LazyLoadedImage alt="Canny Home" src={desktopLogo} height="83" width="300" />
        </Link>
        <Link className="navLogo mobile" fakeLink={true} onTap={this.onLogoTapped} to={linkTo}>
          <LazyLoadedImage alt="Canny Home" src={mobileLogo} height="150" width="117" />
        </Link>
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  router: RouterContext,
})(NavLogo);

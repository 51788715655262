import { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadRoadmap } from '../actions/roadmap';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

import type { Post } from 'common/api/resources/posts';
import type { RoadmapState } from 'common/reducers/roadmap';
import type { AsyncItem } from 'redux-connect';

export type ContainerRoadmap = {
  hasNextPage: boolean;
  loadingMore: boolean;
  pages: number;
  posts: Post[];
} | null;

type Props = {
  roadmap: RoadmapState;
  posts: Record<string, Record<string, Post>>;
};

const asyncFetch: AsyncItem = {
  promise: ({ store: { dispatch } }) => {
    return dispatch(loadRoadmap());
  },
};

class PublicRoadmapContainer extends Component<Props> {
  static asyncConnect = asyncFetch;

  static propTypes = {
    roadmap: PropTypes.object,
    posts: PropTypes.object,
  };

  render() {
    const { children, posts, roadmap } = this.props;
    return cloneElementWithProps(children, {
      ...this.props,
      roadmap:
        roadmap && roadmap.posts
          ? {
              filters: roadmap.filters,
              hasNextPage: roadmap.hasNextPage,
              loadingMore: roadmap.loadingMore,
              boardURLNames: roadmap.boardURLNames,
              pages: roadmap.pages,
              posts: roadmap.posts.map((postKey) => {
                return posts[postKey.boardID][postKey.postURLName];
              }),
            }
          : null,
    });
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({ posts: state.posts, roadmap: state.roadmap }))
)(PublicRoadmapContainer);

import { loadAsanaSettings } from 'common/actions/asanaSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { AsanaSettings } from 'common/api/endpoints/asanaSettings';
import type { State } from 'redux-connect';

type Props = {
  asanaSettings: AsanaSettings;
  children: React.ReactNode;
};

const AsanaSettingsContainer = (props: Props) => {
  // props
  const { asanaSettings, children } = props;

  return cloneElementWithProps(children, {
    ...props,
    asanaSettings,
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadAsanaSettings()),
    },
  ],
  (state: State) => ({ asanaSettings: state.asanaSettings?.data })
)(AsanaSettingsContainer);

export enum CompanyReferralStatus {
  free = 'Free subscription',
  paid = 'Paid subscription',
  cancelled = 'Cancelled',
}

export type ReferralAnswer = {
  questionID: string;
  answer: string;
};

export type Referrer = { name: string };

export type ReferralSettings = {
  joinedReferralProgram: boolean;
  referralCode: string | null;
  referredCompanies: Array<{
    name: string;
    status: CompanyReferralStatus;
  }>;
};

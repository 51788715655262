import React, { Component } from 'react';

import { X } from 'lucide-react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { browserHistory } from 'react-router';

import Error from 'common/common/Error';
import ErrorBoundary from 'common/common/ErrorBoundary';
import Tappable from 'common/Tappable';
import IconButtonV2 from 'common/ui/IconButtonV2';

type Options = {
  allowRouteChange: boolean;
};

export type OpenModalContextType = <T extends Record<string, unknown>>(
  ChildComponent: React.ComponentType<T>,
  childProps: T,
  options?: Options
) => void;

type CloseModalContextType = () => void;

export const OpenModalContext = React.createContext<OpenModalContextType>(() => undefined);
export const CloseModalContext = React.createContext<CloseModalContextType>(() => undefined);

type State = {
  allowRouteChange: boolean;
  ChildComponent: React.ComponentType<any> | null;
  childProps: Record<string, unknown>;
  isOpen: boolean;
};

type Props = {
  parentWindowViewport?: {
    clientHeight: number;
    scrollTop: number;
  };
};

export default class ModalContainer extends Component<Props, State> {
  static propTypes = {
    parentWindowViewport: PropTypes.object,
  };

  state: State = {
    allowRouteChange: false,
    ChildComponent: null,
    childProps: {},
    isOpen: false,
  };

  private overlayRef: React.RefObject<HTMLDivElement> = React.createRef();
  private _unlisten: () => void = () => undefined;
  private _skipClose = false;

  componentDidMount() {
    this._unlisten = browserHistory.listen(this.onRouteChange);
  }

  componentWillUnmount() {
    this._unlisten();
  }

  onRouteChange = () => {
    const { allowRouteChange } = this.state;
    if (allowRouteChange) {
      return;
    }

    this.closeModal();
  };

  openModal: OpenModalContextType = (ChildComponent, childProps, options) => {
    if (!ChildComponent) {
      return;
    }

    document.body.classList.add('modalOpen');
    this.setState({
      allowRouteChange: !!options?.allowRouteChange,
      ChildComponent,
      childProps,
      isOpen: true,
    });
  };

  closeModal = () => {
    document.body?.classList.remove('modalOpen');
    this.setState({
      allowRouteChange: false,
      ChildComponent: null,
      childProps: {},
      isOpen: false,
    });
  };

  onOverlayTap = (e: React.MouseEvent) => {
    if (this._skipClose) {
      this._skipClose = false;
      return;
    }
    this._skipClose = false;

    if (e.target instanceof Node && !findDOMNode(this.overlayRef.current)?.isSameNode(e.target)) {
      return;
    }

    this.closeModal();
  };

  onOverlayTapStart = (e: React.MouseEvent) => {
    if (e.target instanceof Node && !findDOMNode(this.overlayRef.current)?.isSameNode(e.target)) {
      this._skipClose = true;
    }
  };

  renderModal() {
    if (!this.state.isOpen) {
      return null;
    }

    const { parentWindowViewport } = this.props;
    const styles = parentWindowViewport
      ? {
          height: parentWindowViewport.clientHeight,
          minHeight: parentWindowViewport.clientHeight,
          maxHeight: parentWindowViewport.clientHeight,
          top: parentWindowViewport.scrollTop,
        }
      : {};
    const { ChildComponent, childProps } = this.state;
    const child = ChildComponent ? (
      <ErrorBoundary
        renderError={() => (
          <div className="modal">
            <IconButtonV2
              size="medium"
              onClick={this.closeModal}
              icon={X}
              variant="plain"
              className="closeModalButton"
              aria-label="Close modal"
            />
            <Error />
          </div>
        )}>
        <ChildComponent {...childProps} />
      </ErrorBoundary>
    ) : null;
    return (
      <Tappable onTap={this.onOverlayTap} onTapStart={this.onOverlayTapStart}>
        <div className="modalOverlay" ref={this.overlayRef} style={styles}>
          {child}
        </div>
      </Tappable>
    );
  }

  render() {
    return (
      <OpenModalContext.Provider value={this.openModal}>
        <CloseModalContext.Provider value={this.closeModal}>
          <div className="modalContainer">
            {this.props.children}
            {this.renderModal()}
          </div>
        </CloseModalContext.Provider>
      </OpenModalContext.Provider>
    );
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { compose } from 'redux';

import { loadMore } from 'common/actions/postQueries';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import KeyCodes from 'common/KeyCodes';
import LazyLoadedImage from 'common/LazyLoadedImage';
import PostList from 'common/post/PostList';
import mapify from 'common/util/mapify';
import withContexts from 'common/util/withContexts';
import DollarIcon from 'img/landing/use-cases-5.webp';

import AdminFeedbackPostListItem from './AdminFeedbackPostListItem';
import AdminFeedbackPostListMenu from './AdminFeedbackPostListMenu';
import AdminInstallIntegrationNUX from './AdminInstallIntegrationNUX';

import 'css/components/subdomain/admin/_AdminFeedbackPostList.scss';

class AdminFeedbackPostList extends Component {
  static propTypes = {
    activeSavedFilter: PropTypes.string,
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.shape({
      hasCollectedMonthlySpend: PropTypes.bool,
    }),
    loadMore: PropTypes.func,
    location: PropTypes.object,
    onPostSelected: PropTypes.func,
    onPostsSelected: PropTypes.func,
    onUpdateActiveSavedFilter: PropTypes.func.isRequired,
    postList: PropTypes.object,
    router: PropTypes.object,
    selectedPostsMap: PropTypes.object,
    sidebarCollapsed: PropTypes.bool,
  };

  state = {
    lastStartPost: this.props.postList?.posts?.[this.getSelectedPostIndex()],
    selectedPostGroups: [],
  };

  constructor(props) {
    super(props);

    this.postListRef = React.createRef();
    this.scrollContainerRef = React.createRef();
  }

  componentDidMount() {
    this.scrollContainerRef.current.addEventListener('scroll', this.onScroll, false);
    document.addEventListener('keydown', this.onKeyDown, false);
    this.onScroll();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.search !== prevLocation.search) {
      this.setState({ selectedPostGroups: [] });
      this.props.onPostsSelected({});
    }
  }

  componentWillUnmount() {
    this.scrollContainerRef.current.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('keydown', this.onKeyDown);
  }

  getPostIndex(targetPost) {
    const {
      postList: { posts },
    } = this.props;
    const postIndex = posts.findIndex((post) => post._id === targetPost?._id);
    return postIndex >= 0 ? postIndex : 0;
  }

  getSelectedPostIndex() {
    const { params, postList } = this.props;
    const postIndex = postList?.posts?.findIndex(
      (post) => post.board.urlName === params.boardURLName && post.urlName === params.postURLName
    );
    return postIndex >= 0 ? postIndex : 0;
  }

  getSelectedPostsMap(selectedPostGroups) {
    return selectedPostGroups.reduce((postsMap, postGroup) => {
      postsMap = { ...postsMap, ...postGroup.posts };
      return postsMap;
    }, {});
  }

  onPostSelected = (e, selectedPost) => {
    const isMetaPressed = e.metaKey || e.ctrlKey;
    if (!e.shiftKey && !isMetaPressed) {
      this.setState({
        selectedPostGroups: [
          {
            posts: mapify([selectedPost], '_id'),
            active: true,
          },
        ],
        lastStartPost: selectedPost,
      });
      this.props.onPostsSelected({});
      this.props.onPostSelected(selectedPost);
      return;
    }

    const {
      postList: { posts },
    } = this.props;
    const { lastStartPost, selectedPostGroups } = this.state;
    const newPostGroups = [...selectedPostGroups];
    const lastPostGroup = newPostGroups[newPostGroups.length - 1];

    if (e.shiftKey) {
      const postIndexes = [this.getPostIndex(lastStartPost), this.getPostIndex(selectedPost)];
      const [startIndex, endIndex] = [Math.min(...postIndexes), Math.max(...postIndexes)];
      const selectedPosts = posts.slice(startIndex, endIndex + 1);
      const selectedPostGroup = {
        posts: mapify(selectedPosts, '_id'),
        active: true,
      };

      if (lastPostGroup?.active) {
        newPostGroups.pop();
      }

      newPostGroups.push(selectedPostGroup);
    } else if (isMetaPressed) {
      if (lastPostGroup?.active) {
        lastPostGroup.active = false;
      }

      let isPostDeleted = false;
      newPostGroups.forEach((postGroup) => {
        const { posts } = postGroup;
        if (posts[selectedPost._id]) {
          delete posts[selectedPost._id];
          isPostDeleted = true;
        }
      });

      if (!isPostDeleted) {
        newPostGroups.push({
          posts: mapify([selectedPost], '_id'),
          active: true,
        });
      }

      this.setState({ lastStartPost: selectedPost });
    }

    const selectedPostsMap = this.getSelectedPostsMap(newPostGroups);
    this.setState({ selectedPostGroups: newPostGroups });
    this.props.onPostsSelected(selectedPostsMap);
    this.props.onPostSelected(selectedPost);
  };

  onScroll = (e) => {
    // Might be showing MRR Notice
    if (!this.postListRef.current) {
      return;
    }
    const scrollContainer = this.scrollContainerRef.current;
    this.postListRef.current.onScroll(e, scrollContainer);
  };

  onKeyDown = (e) => {
    const targetInsideContainer = findDOMNode(this.scrollContainerRef.current).contains(
      document.activeElement
    );
    if (document.activeElement !== document.body && !targetInsideContainer) {
      return;
    }

    if (e.keyCode === KeyCodes.UpArrow) {
      e.preventDefault();
      this.selectPreviousPost(e);
    } else if (e.keyCode === KeyCodes.DownArrow) {
      e.preventDefault();
      this.selectNextPost(e);
    }
  };

  selectNextPost(e) {
    const {
      loadMore,
      location: { query },
      params,
      postList,
      router,
    } = this.props;
    const isPostSelected = params.boardURLName && params.postURLName;
    if (!isPostSelected) {
      return;
    }

    const selectedPostIndex = this.getSelectedPostIndex();
    if (selectedPostIndex === -1) {
      return;
    }

    if (postList.posts.length <= selectedPostIndex + 1) {
      return;
    }

    if (selectedPostIndex + 1 === postList.posts.length - 1) {
      loadMore(postList);
    }

    const selectedPost = postList.posts[selectedPostIndex];
    const nextPost = postList.posts[selectedPostIndex + 1];

    this.onPostSelected(e, selectedPost);
    this.onPostSelected(e, nextPost);

    router.push({
      pathname: `/admin/feedback/${nextPost.board.urlName}/p/${nextPost.urlName}`,
      query,
    });
  }

  selectPreviousPost(e) {
    const {
      location: { query },
      params,
      postList,
      router,
    } = this.props;
    const isPostSelected = params.boardURLName && params.postURLName;
    if (!isPostSelected) {
      return;
    }

    const selectedPostIndex = this.getSelectedPostIndex();
    if (!selectedPostIndex || selectedPostIndex === -1) {
      return;
    }

    const selectedPost = postList.posts[selectedPostIndex];
    const previousPost = postList.posts[selectedPostIndex - 1];

    this.onPostSelected(e, selectedPost);
    this.onPostSelected(e, previousPost);

    router.push({
      pathname: `/admin/feedback/${previousPost.board.urlName}/p/${previousPost.urlName}`,
      query,
    });
  }

  renderNoPostsMessage() {
    const {
      location: { query },
    } = this.props;
    const noBoardsSelected = query.boards === '';
    if (noBoardsSelected) {
      return 'Please select a board';
    }

    return 'No posts match these filters';
  }

  renderPosts() {
    const {
      company,
      boards,
      location: {
        query: { sort },
      },
      postList,
      selectedPostsMap,
    } = this.props;
    const { loading } = postList;

    // Prevent crash when there are no boards.
    if (boards.length === 0) {
      return null;
    }

    if (sort !== 'mrr' || loading || company.hasCollectedMonthlySpend) {
      return (
        <PostList
          ItemComponent={AdminFeedbackPostListItem}
          postList={postList}
          noPostsMessage={this.renderNoPostsMessage()}
          onPostSelected={this.onPostSelected}
          ref={this.postListRef}
          scrollContainerRef={this.scrollContainerRef}
          selectedPostsMap={selectedPostsMap}
          showComments={true}
          showMenu={false}
          showPrivateComments={true}
        />
      );
    }

    // Shown when sorting by MRR and top post has no value
    return (
      <div className="noMRRDataNotice">
        <LazyLoadedImage className="dollarIcon" src={DollarIcon} alt="dollar graphic" />
        <div className="header">Sort by MRR</div>
        <div className="text">
          Pass MRR data into Canny to view your feedback sorted by MRR impact.
        </div>
        <a
          className="docsButton"
          href="https://developers.canny.io/install/companies"
          rel="noopener"
          target="_blank">
          <Button value="See The Docs" />
        </a>
      </div>
    );
  }

  render() {
    const className = classnames({
      adminFeedbackPostList: true,
      sidebarCollapsed: this.props.sidebarCollapsed,
    });

    return (
      <div className={className}>
        <AdminFeedbackPostListMenu
          activeSavedFilter={this.props.activeSavedFilter}
          board={this.props.board}
          boards={this.props.boards}
          company={this.props.company}
          onUpdateActiveSavedFilter={this.props.onUpdateActiveSavedFilter}
        />
        <div className="scrollContainer" ref={this.scrollContainerRef}>
          {this.renderPosts()}
        </div>
        <AdminInstallIntegrationNUX style="tall" />
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadMore: (postList) => {
      return dispatch(loadMore(postList));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      params: ParamsContext,
      router: RouterContext,
    },
    { forwardRef: true }
  )
)(AdminFeedbackPostList);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost, reloadPostByURLName } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import ConfirmModal from 'common/modals/ConfirmModal';
import parseAPIResponse from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';
import withContexts from 'common/util/withContexts';

import 'css/components/post/_MovePostDropdown.scss';

class MovePostDropdown extends Component {
  static propTypes = {
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.object,
    location: PropTypes.object,
    onPostMoved: PropTypes.func,
    openModal: PropTypes.func,
    post: PropTypes.object,
    router: PropTypes.object,
    showToast: PropTypes.func,
  };

  state = {
    error: null,
    board: this.props.board,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.board !== this.props.board) {
      this.setState({ board: this.props.board });
    }
  }

  onConfirmMovePost = (intoBoard) => {
    this.setState({
      error: null,
    });

    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: 'Are you sure you\'d like to move this post to board "' + intoBoard.name + '"?',
      onConfirm: this.onMovePost.bind(this, intoBoard),
    });
  };

  replaceBoardInURL = (board) => {
    const { router } = this.props;
    const {
      location: { pathname, search },
    } = router;

    /**
     * TODO:
     * - Add Roadmap case, add other cases (e.g Autopilot)
     */
    const splitPathname = pathname.split('/');
    const [, , subRouteSegment] = splitPathname;
    if (subRouteSegment === 'feedback') {
      const [, , , , ...rest] = splitPathname;
      const restOfPath = rest.join('/');
      const newPath = `/admin/feedback/${board.urlName}/${restOfPath}${search}`;
      router.replace(newPath);
    } else if (subRouteSegment === 'roadmap') {
      // Admin Roadmap page
    } else {
      // Check other cases (e.g Autopilot)
    }
  };

  onMovePost = async (intoBoardID) => {
    const { post } = this.props;

    if (post.boardID === intoBoardID) {
      return;
    }

    const response = await AJAX.post('/api/posts/move', {
      boardID: intoBoardID,
      postID: post._id,
    });

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.urlName && parsedResponse.success,
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    } else if (!parsedResponse) {
      return;
    }

    const { board, boards, onPostMoved, showToast } = this.props;
    const fromBoard = board;
    const intoBoard = boards.find((board) => board._id === intoBoardID);

    await onPostMoved(post, fromBoard, intoBoard, parsedResponse.urlName);

    this.replaceBoardInURL(intoBoard);
    this.setState({ board: intoBoard });

    showToast(`This post has been moved to "${intoBoard.name}"`);
  };

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderDropdown() {
    const { boards } = this.props;
    const { board: currentBoard } = this.state;

    const options = [...boards].sort(stringSort('name')).map((board) => {
      return {
        name: board._id,
        render: board.name,
      };
    });
    return (
      <ControlledDropdown
        dropdownClassName="movePostDropdownPortal"
        minWidth={165}
        onChange={this.onMovePost}
        options={options}
        selectedName={currentBoard._id}
      />
    );
  }

  render() {
    return (
      <div className="movePostDropdown">
        <div className="searchContainer">{this.renderDropdown()}</div>
        {this.renderError()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    onPostMoved: (post, fromBoard, intoBoard, intoURLName) => {
      return Promise.all([
        dispatch(invalidatePostQueries()),
        dispatch(reloadBoard(fromBoard.urlName)),
        dispatch(reloadBoard(intoBoard.urlName)),
        dispatch(reloadPost(post)),
        dispatch(reloadPostActivity(post)),
        dispatch(reloadPostByURLName(intoBoard, intoURLName)),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      router: RouterContext,
      showToast: ShowToastContext,
    },
    {
      forwardRef: true,
    }
  )
)(MovePostDropdown);

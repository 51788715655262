import React, { useContext, useEffect, useState } from 'react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import SwitchV2 from 'common/ui/SwitchV2';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Slack, SlackSettings } from 'common/api/endpoints/slack';
import type { Dispatch } from 'redux';

import 'css/components/subdomain/admin/_AdminMessagesSettings.scss';

const optionCopy = {
  disableSpamButtonOnDirectMessages: {
    tooltip: 'Control if the "mark as spam" button is visible on direct messages',
    label: 'Disable spam button on direct messages',
  },
  privateAuthors: {
    tooltip: 'This setting depends on the "use user aliases" board setting also being enabled.',
    label: 'Show aliases instead of names, when the respective board has aliases enabled.',
  },
} as const;

type Props = {
  settings: Slack['settings'];
  reloadCompany: () => void;
  optionsToDisplay: (keyof SlackSettings)[];
};

const AdminMessagesSettings = ({ settings, reloadCompany, optionsToDisplay }: Props) => {
  const [options, setOptions] = useState(settings);
  const [loading, setLoading] = useState(false);

  const showToast = useContext(ShowToastContext);

  useEffect(() => {
    setOptions({
      privateAuthors: settings.privateAuthors,
      disableSpamButtonOnDirectMessages: settings.disableSpamButtonOnDirectMessages,
    });
  }, [settings.privateAuthors, settings.disableSpamButtonOnDirectMessages]);

  const updateSlack = async (settings: SlackSettings) => {
    setLoading(true);
    const response = await AJAX.post('/api/slack/updateSettings', {
      settings,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    setLoading(false);

    if (error) {
      showToast(error.message, ToastTypes.error);
    } else {
      reloadCompany();
    }
  };
  return (
    <div className="adminMessagesSettings">
      <UppercaseHeader>Settings</UppercaseHeader>
      <div className="settings">
        <div className="settingList">
          {optionsToDisplay.map((optionToDisplay) => (
            <Tooltip
              disabled={!optionCopy[optionToDisplay].tooltip}
              key={optionToDisplay}
              value={optionCopy[optionToDisplay].tooltip}>
              <label className="settingLabel">
                <div className="settingName">{optionCopy[optionToDisplay].label}</div>
                <SwitchV2
                  size="medium"
                  onChange={() =>
                    setOptions({
                      ...options,
                      [optionToDisplay]: !options[optionToDisplay],
                    })
                  }
                  checked={options[optionToDisplay]}
                />
              </label>
            </Tooltip>
          ))}
        </div>

        <Button
          buttonType="cannyButton"
          disabled={optionsToDisplay.every((option) => options[option] === settings[option])}
          loading={loading}
          onTap={() => {
            updateSlack(options);
          }}
          value="Save"
        />
      </div>
    </div>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    dispatch(reloadCompany());
  },
}))(AdminMessagesSettings);

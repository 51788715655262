import React, { useContext, useState } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';

import AdminAutopilotIntegrationModal, {
  type Props as ModalProps,
} from './AdminAutopilotIntegrationModal';
import ZendeskSubdomainInput from './ZendeskSubdomainInput';
import { getZendeskOAuthURL } from '../utils';

import type { Company } from 'common/api/endpoints/companies';

type Props = Omit<ModalProps, 'getOAuthURL' | 'connectDisabled' | 'inputSection'>;

const zendeskSubdomainRegex = /^[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?$/i;

const ZendeskIntegrationModal = (baseProps: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const installedZendeskInstance = company.zendesk;

  // state
  const [zendeskSubdomain, setZendeskSubdomain] = useState(
    installedZendeskInstance?.subdomain ?? ''
  );

  const connectDisabled =
    !installedZendeskInstance &&
    (!zendeskSubdomain || !zendeskSubdomainRegex.test(zendeskSubdomain));

  return (
    <AdminAutopilotIntegrationModal
      {...baseProps}
      connectDisabled={connectDisabled}
      getOAuthURL={() => getZendeskOAuthURL(zendeskSubdomain, company.subdomain)}
      inputSection={
        !installedZendeskInstance ? (
          <ZendeskSubdomainInput
            zendeskSubdomain={zendeskSubdomain}
            setZendeskSubdomain={setZendeskSubdomain}
          />
        ) : null
      }
    />
  );
};

export default ZendeskIntegrationModal;

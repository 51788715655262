import React, { useCallback, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';

import ChartRenderer from 'common/charts/ChartRenderer';
import { StaticColors } from 'common/colors/constants';
import abbreviateNumber from 'common/util/abbreviateNumber';
import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';
import 'css/components/charts/_DoughnutChart.scss';

import type { ActiveElement, Chart, ChartEvent, CoreChartOptions, InteractionItem } from 'chart.js';

const DefaultAspectRatio = 1;

interface TooltipData {
  entity: {
    name: string;
    color: string;
    type: string;
  };
  count: {
    total: number;
    partial: number;
  };
  position: {
    x: number;
    y: number;
  };
}

type DoughnutChartProps = {
  aspectRatio?: number;
  data: Record<string, number>;
  title: string;
  onSelectArea: (key: string, event: PointerEvent) => void;
};

type Legend = {
  color: string;
  value: string;
};

type LegendOverflowProps = {
  legends: Legend[];
};

interface ConfigOptions extends Partial<CoreChartOptions<'doughnut'>> {
  onMouseOut: () => void;
  onSelectArea: (key: string, event: PointerEvent) => void;
}

function getConfig(
  title: string,
  data: Record<string, number>,
  { aspectRatio, onSelectArea, onHover, onMouseOut }: ConfigOptions
) {
  const totalSum = Object.values(data).reduce((total, val) => total + val, 0);
  return {
    data: {
      labels: Object.keys(data),
      datasets: [
        {
          hoverBorderColor: StaticColors.white,
          data: Object.values(data),
        },
      ],
    },
    plugins: [
      {
        // adds a custom plugin to render text in the center of the doughnut
        id: 'centerText',
        beforeDraw: (chart: Chart) => {
          const { ctx } = chart;
          const { width, height } = chart.chartArea;

          // set "total" text style
          ctx.font = `600 28px Inter, sans-serif`;
          ctx.fillStyle = StaticColors.darkBlue;
          ctx.textBaseline = 'middle';

          // draw "total" text
          const totalText = abbreviateNumber(totalSum).toString();
          const totalTextX = Math.round((width - ctx.measureText(totalText).width) / 2);
          const totalTextY = height / 2 - 5;
          ctx.fillText(totalText, totalTextX, totalTextY);

          // set "title" text style
          ctx.font = `400 12px Inter, sans-serif`;
          ctx.fillStyle = StaticColors.middleBlue;
          ctx.textBaseline = 'middle';

          // draw "title" text
          const titleText = capitalizeFirstLetter(title);
          const titleTextX = Math.round((width - ctx.measureText(titleText).width) / 2);
          const titleTextY = height / 2 + 19;
          ctx.fillText(titleText, titleTextX, titleTextY);

          ctx.save();
          ctx.restore();
        },
      },
      {
        // adds a custom plugin to call "onMouseOut" when the mouse goes beyond the chart area.
        id: 'onMouseOut',
        beforeEvent(_: Chart, args: { inChartArea: boolean }) {
          if (!args.inChartArea) {
            onMouseOut();
          }
        },
      },
    ],
    options: {
      aspectRatio,
      onClick: (chartEvent: ChartEvent, clickedElements: ActiveElement[]) => {
        const clickedElement = clickedElements.find((element) => typeof element.index === 'number');
        if (!clickedElement || chartEvent.type !== 'click' || !chartEvent.native) {
          return;
        }

        const event = chartEvent.native as PointerEvent;
        const key = Object.keys(data)[clickedElement.index];

        onSelectArea(key, event);
      },
      onHover,
      responsive: true,
      // to resize the canvas when the window is resized, set this value to false.
      // however, the canvas size will lose the aspect ratio, which will push the
      // content on the top and the bottom.
      maintainAspectRatio: true,
      layout: {
        autoPadding: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: 'white',
          font: {
            weight: 'bold',
            size: 14,
          },
          // do not render labels on areas smaller than 3% of the chart.
          // do not render big labels on areas smaller than 5% of the chart.
          formatter: function (value: number) {
            const ratio = value / totalSum;
            if (ratio < 0.03) {
              return '';
            } else if (value >= 1000 && ratio < 0.05) {
              return '';
            }

            return value;
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      elements: {
        point: {
          pointStyle: false,
        },
      },
    },
  };
}

/** Renders a tooltip with extra information for the DoughnutChart */
const ChartTooltip = ({ entity, count, position }: TooltipData) => {
  const percentage = Math.round((count.partial / count.total) * 1000) / 10;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const calculatePosition = (position: TooltipData['position']) => {
    const padding = 15; //px

    // default to left-oriented
    if (!tooltipRef.current) {
      return {
        left: position.x + padding,
        top: position.y + padding,
      };
    }

    const pageWidth = document.body.clientWidth;
    const tooltipWidth = tooltipRef.current.clientWidth;
    const tooltipMaxWidth = 250; //px

    if (tooltipMaxWidth + position.x > pageWidth) {
      return {
        left: position.x - tooltipWidth - padding,
        top: position.y + padding,
      };
    } else {
      return {
        left: position.x + padding,
        top: position.y + padding,
      };
    }
  };

  // transform type to singular.
  const type = count.partial > 1 ? entity.type : entity.type.replace(/s$/, '');
  return (
    <div className="tooltip" ref={tooltipRef} style={calculatePosition(position)}>
      <div className="entity">
        <div className="color" style={{ backgroundColor: entity.color }} />
        <div className="name">{entity.name}</div>
      </div>
      <hr className="divider" />
      <div className="lines">
        <div className="line">
          <div className="number">{abbreviateNumber(count.partial)}</div> {type} out of{' '}
          <div className="number">{abbreviateNumber(count.total)}</div>
        </div>
        <div className="line">
          <div className="number">{percentage}%</div> of total
        </div>
      </div>
    </div>
  );
};

/** Renders a legend with color and a label for a chart */
const Legend = ({ value, color }: Legend) => {
  return (
    <div className="legend">
      <div className="color" style={{ backgroundColor: color }} />
      <div className="value">{value}</div>
    </div>
  );
};

/** Renders a legend overflow */
const LegendOverflow = ({ legends }: LegendOverflowProps) => {
  return (
    <div className="legendOverflow">
      <div className="value">{`+${legends.length} more`}</div>
      <div className="hoverArea" />
      <div className="legendOverflowMenu">
        {legends.map((legend) => (
          <Legend key={`${legend.value}|overflow`} color={legend.color} value={legend.value} />
        ))}
      </div>
    </div>
  );
};

/** Renders a doughnut chart */
const DoughnutChart = ({
  aspectRatio = DefaultAspectRatio,
  data,
  title,
  onSelectArea,
}: DoughnutChartProps) => {
  const [tooltip, setTooltip] = useState<TooltipData | null>(null);
  const [legends, setLegends] = useState<Legend[]>([]);
  const onMouseOut = useCallback(() => setTooltip(null), []);

  const calculateTooltip = useCallback(
    (event: ChartEvent, items: InteractionItem[], chart: Chart) => {
      if (event.type === 'mouseout') {
        setTooltip(null);
      } else if (event.type !== 'mousemove' || !event.native) {
        return;
      } else if (!items.filter(Boolean).length) {
        setTooltip(null);
        return;
      }

      const elementIndex = items[0]?.index;
      if (typeof elementIndex !== 'number') {
        return;
      }

      const value = (chart.data.datasets[0].data[elementIndex] as number | null) ?? 0; // it is a `data` value
      const name = (chart.data.labels?.[elementIndex] as string | undefined) ?? 'Unknown'; // it is a `data` key
      const nativeEvent = event.native as MouseEvent; // non-mousemove events were filtered out already

      setTooltip({
        entity: {
          name,
          color: items[0].element.options.backgroundColor,
          type: title,
        },
        position: {
          x: nativeEvent.clientX,
          y: nativeEvent.clientY,
        },
        count: {
          partial: value,
          total: Object.values(data).reduce((total, val) => total + val, 0),
        },
      });
    },
    [title, data]
  );

  const calculateLegends = useCallback(
    (chart: Chart) => {
      const legends =
        chart.legend?.legendItems?.map((legendItem) => {
          return {
            color: legendItem.fillStyle?.toString() || 'red', // fillStyle should always be defined
            value: legendItem.text ?? 'Unknown',
          };
        }) ?? [];

      // more relevant legends come first
      setLegends(legends.sort((a, b) => data[b.value] - data[a.value]));
    },
    [data]
  );

  // we need to memoize to avoid re-rendering the chart every time it's hovered
  const chartConfig = useMemo(
    () =>
      getConfig(title, data, { onSelectArea, aspectRatio, onHover: calculateTooltip, onMouseOut }),
    [title, data, aspectRatio, calculateTooltip, onSelectArea, onMouseOut]
  );

  return (
    <div className="doughnutChart">
      <div className="doughnutChartCanvas">
        <ChartRenderer type="doughnut" chartConfig={chartConfig} onUpdate={calculateLegends} />
      </div>
      <div className={classnames('legends', { collapsible: legends.length > 2 })}>
        {legends
          .map((legend) => <Legend key={legend.value} value={legend.value} color={legend.color} />)
          // remove exceeding entities and render an indicator
          .slice(0, legends.length <= 6 ? legends.length : 5)
          .concat(
            legends.length > 6 ? <LegendOverflow legends={legends.slice(5, Infinity)} /> : []
          )}
      </div>
      {tooltip && (
        <ChartTooltip position={tooltip.position} entity={tooltip.entity} count={tooltip.count} />
      )}
    </div>
  );
};

export default React.memo(DoughnutChart);

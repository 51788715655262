import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as AuthRequests from 'common/auth/AuthRequests';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import OAuthFlows from 'common/OAuthFlows';
import { H1 } from 'common/ui/Text';
import withContexts from 'common/util/withContexts';

import AuthButton from './AuthButton';
import Button from './inputs/Button';
import Tappable from './Tappable';

import GoogleIcon from 'img/google-icon.svg';

import 'css/components/_SignupForm.scss';

class SignupForm extends Component {
  static propTypes = {
    hasSuccessAction: PropTypes.bool.isRequired,
    isWidget: PropTypes.bool.isRequired,
    oauthProvider: PropTypes.string,
    onEmail: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    onOAuthSignup: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    loading: null,
  };

  onOAuthFailure = (error) => {
    this.setState({
      error: 'Failed to connect, please try again!',
      loading: null,
    });
  };

  onOAuthContinue = (result) => {
    var OAuthFlow = OAuthFlows.getFlow(this.state.loading, 2);
    var flow = new OAuthFlow({
      onContinue: this.onOAuthContinue,
      onFailure: this.onOAuthFailure,
      onSuccess: this.onOAuthSuccess,
      redirectURL: null,
      separateWindow: null,
      shouldOpenNewTab: this.props.hasSuccessAction,
    });
    flow.connect(result);
  };

  onOAuthSuccess = (oauthData) => {
    this.login({
      ...oauthData,
      useTokenAuth: this.props.isWidget,
    });
  };

  login = async (requestData) => {
    const { error, parsedResponse, redirecting } = await AuthRequests.login(requestData);

    // Note: We'd like to show register form if we receive an error from login endpoint except 'slow down'
    if (error && error.type === 'slow down') {
      this.setState({
        error: error.message,
      });
      return;
    }

    if (redirecting) {
      return;
    }

    if (parsedResponse?.user) {
      this.props.onSuccess(parsedResponse);
      return;
    }

    if (!requestData.email || !requestData.name) {
      this.props.onOAuthSignup(requestData);
      return;
    }

    this.signup(requestData);
  };

  signup = async (requestData) => {
    const { error, parsedResponse, redirecting } = await AuthRequests.signup(requestData);

    if (redirecting) {
      return;
    }

    if (!error) {
      this.props.onSuccess(parsedResponse);
      return;
    }

    this.setState({ error: error.message });
  };

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    return (
      <div className="signupForm">
        <div className="topContainer">
          <H1 variant="headingMd">Sign up for Canny with:</H1>
          <div className="buttons">
            <AuthButton
              authType="facebook"
              onFailure={this.onOAuthFailure}
              onSuccess={this.onOAuthSuccess}
              value={<div className="icon icon-facebook" />}
            />
            <AuthButton
              authType="google"
              onFailure={this.onOAuthFailure}
              onSuccess={this.onOAuthSuccess}
              value={<LazyLoadedImage alt="google logo" src={GoogleIcon} className="google" />}
            />
            <AuthButton
              authType="github"
              onFailure={this.onOAuthFailure}
              onSuccess={this.onOAuthSuccess}
              value={<div className="icon icon-github" />}
            />
            <div className="centeredDivider">OR</div>
            <Button
              buttonType="emailButton"
              onTap={this.props.onEmail}
              value={<div className="icon icon-email"></div>}
            />
          </div>
          <div className="note">
            We use Canny to collect feedback from users like you. Sign up to post and vote.
          </div>
          {this.renderErrorMessage()}
        </div>
        <Tappable onTap={this.props.onLogin}>
          <div className="accountModalCTA">
            <span>Already have an account? Log in</span>
          </div>
        </Tappable>
      </div>
    );
  }
}

export default withContexts({ isWidget: IsWidgetContext }, { forwardRef: true })(SignupForm);

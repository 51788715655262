import { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { ParamsContext } from 'common/containers/RouterContainer';
import withContexts from 'common/util/withContexts';

import { loadPost } from '../actions/posts';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

export const asyncFetch = {
  promise: ({ store: { dispatch, getState }, params }) => {
    if (!params.postURLName) {
      return;
    }

    const state = getState();
    const { boards, widget } = state;
    const board = widget ? widget.board : boards.items[params.boardURLName];
    return dispatch(loadPost(board, params.postURLName));
  },
};

class PostContainer extends Component {
  static propTypes = {
    params: PropTypes.object,
  };

  render() {
    const newProps = { ...this.props };
    const { board, children, params, posts } = newProps;

    if (params.postURLName) {
      newProps.post = board && posts[board._id] && posts[board._id][params.postURLName];
    }

    return cloneElementWithProps(children, newProps);
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({ posts: state.posts })),
  withContexts(
    {
      params: ParamsContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostContainer);

import React from 'react';

import { StaticColors } from 'common/colors/constants';

import type { HexColor } from 'common/colors/utils';

export const TintColorContext = React.createContext<HexColor | null>(null);
export const DefaultTintColor = StaticColors.canny;

type Props = {
  tintColor: HexColor;
  children: React.ReactNode;
};

const TintColorContainer = ({ children, tintColor }: Props) => {
  return <TintColorContext.Provider value={tintColor}>{children}</TintColorContext.Provider>;
};
export default TintColorContainer;

import { useScroll } from 'react-router-scroll';

// Not a hook
// eslint-disable-next-line react-hooks/rules-of-hooks
export default useScroll(function (prevRouterProps, nextRouterProps) {
  if (prevRouterProps && prevRouterProps.location.pathname === nextRouterProps.location.pathname) {
    return false;
  }

  return true;
});

import React, { Component, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

let store = null;
const hasPromise = (item) => item?.promise instanceof Function;
export const setStore = (s) => {
  store = s;
};

function connectClientComponent(Child) {
  class ConnectedComponent extends Component {
    static propTypes = {
      connectOnMount: PropTypes.func.isRequired,
    };

    componentDidMount() {
      this.props.connectOnMount();
    }

    render() {
      const { forwardedRef, ...props } = this.props;

      return <Child ref={forwardedRef} {...props} />;
    }
  }

  return forwardRef((props, ref) => <ConnectedComponent {...props} forwardedRef={ref} />);
}

/*
 * This function replaces the `loadOnServer` logic used by redux-connect for SSR, for a
 * client-only approach.
 *
 * It works by replacing the @asyncConnect decorator with a new one, that runs
 * the async promises on mount. It simulates the logic run by redux-connect in:
 * https://github.com/makeomatic/redux-connect/blob/6e4d865fa5fbb8db2a505cb177583f34fb9edd95/modules/helpers/utils.js
 */
export default function clientAsyncConnect(asyncItems, mapStateToProps, mapDispatchToProps) {
  const asyncMapDispatchToProps = (dispatch) => {
    return {
      connectOnMount: () => {
        return Promise.all(
          asyncItems.filter(hasPromise).map((asyncItem) => {
            return asyncItem.promise({ store, location: window.location });
          })
        );
      },
    };
  };

  return (Child) =>
    connect(mapStateToProps, asyncMapDispatchToProps, null, { withRef: true })(
      connectClientComponent(Child)
    );
}

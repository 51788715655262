import devURL from 'common/util/devURL';
import loadScript from 'common/util/loadScript';

const CannySDK = {
  init: () => {
    if (typeof window === 'undefined') {
      return;
    }

    if (typeof window.Canny === 'function') {
      return;
    }

    window.Canny = function () {
      window.Canny.q.push(arguments);
    };
    window.Canny.q = [];
    loadScript(devURL('https://canny.io/sdk.js'), 'canny-sdk');
  },
};

export default CannySDK;

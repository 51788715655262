import React, { Component } from 'react';

import PropTypes from 'prop-types';

import SentrySDK from 'common/3rd/SentrySDK';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { CloseModalContext } from 'common/containers/ModalContainer';
import Data from 'common/Data';
import Dropdown from 'common/Dropdown';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import { CustomFieldSchemas } from 'common/integrations/jira/Constants';
import Spinner from 'common/Spinner';
import UppercaseHeader from 'common/UppercaseHeader';
import oxfordJoin from 'common/util/oxfordJoin';
import parseAPIResponse from 'common/util/parseAPIResponse';
import promisify from 'common/util/promisify';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminCreateJiraIssueModal.scss';

const StaticFields = {
  description: true,
  issuetype: true,
  project: true,
  summary: true,
};
const SupportedFieldTypes = {
  array: true,
  issuetype: true,
  option: true,
  project: true,
  string: true,
  url: true,
};

class AdminCreateJiraIssueModal extends Component {
  static propTypes = {
    board: PropTypes.object,
    closeModal: PropTypes.func,
    company: PropTypes.object,
    onIssueCreated: PropTypes.func,
    post: PropTypes.object,
  };

  state = {
    creating: false,
    customValues: {},
    error: null,
    projects: null,
    requiredFields: null,
    selectedIssueType: null,
    selectedProject: null,
  };

  constructor(props, context) {
    super(props, context);

    this.descriptionRef = React.createRef();
    this.issueTypeDropdownRef = React.createRef();
    this.projectDropdownRef = React.createRef();
    this.summaryRef = React.createRef();
  }

  componentDidMount() {
    this.fetchJiraProjects();
  }

  fetchJiraProjects = () => {
    Data.fetch(
      {
        projects: {
          query: Data.queries.jiraProjects,
        },
      },
      (error, data) => {
        if (error) {
          this.setState({
            error,
          });
          return;
        }

        const selectedProject = data.projects[0];
        this.fetchJiraRequiredFields(selectedProject);
        this.setState({
          projects: data.projects,
          selectedIssueType: selectedProject ? selectedProject.issueTypes[0] : null,
          selectedProject,
        });
      }
    );
  };

  fetchJiraRequiredFields = async (project) => {
    if (!project) {
      return;
    }

    this.setState({ requiredFields: null });

    let data = null;
    try {
      data = await promisify(Data.fetch, {
        requiredFields: {
          query: Data.queries.jiraRequiredFields,
          input: {
            projectID: project.id,
          },
        },
      });
    } catch (error) {
      if (error) {
        this.setState({ error });
        return;
      }
    }

    this.setState({
      requiredFields: data.requiredFields,
    });
  };

  onCreateIssue = async () => {
    const { creating } = this.state;
    if (creating) {
      return;
    }

    this.setState({
      creating: true,
    });

    const { closeModal, post } = this.props;

    const { requiredFields, selectedIssueType, selectedProject } = this.state;
    const requiredFieldsForContext =
      requiredFields?.[selectedProject.id]?.[selectedIssueType.id]?.requiredFields;
    const customValues = {};
    if (requiredFieldsForContext && requiredFieldsForContext.length > 0) {
      requiredFieldsForContext.forEach((field) => {
        // skip static (non-custom) fields
        if (StaticFields[field.schema.system]) {
          return null;
        }

        if (this.state.customValues[field.key]) {
          customValues[field.key] = this.state.customValues[field.key];
        } else if (field.schema.type === 'option') {
          customValues[field.key] = {
            id: field.allowedValues[0].id,
          };
        } else if (field.schema.type === 'string' || field.schema.type === 'url') {
          customValues[field.key] = '';
        } else if (field.schema.type === 'array') {
          customValues[field.key] = [
            {
              id: field.allowedValues[0].id,
            },
          ];
        }
      });
    }

    const response = await AJAX.post('/api/jira/createAndLinkIssue', {
      customValues,
      jiraIssueTypeID: this.issueTypeDropdownRef.current.getValue(),
      jiraProjectID: this.projectDropdownRef.current.getValue(),
      issueDescription: this.descriptionRef.current.getValue(),
      issueSummary: this.summaryRef.current.getValue(),
      postID: post._id,
    });

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse?.issue,
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    // open new issue in a new tab
    // if popups are blocked by the browser, `issueWindow` will be `null`
    const { issue } = parsedResponse;
    const issueWindow = window.open(issue.url, '_blank', 'noopener');
    issueWindow?.focus();

    const { onIssueCreated } = this.props;
    closeModal();
    onIssueCreated();
  };

  onCustomFieldDropdownChange = (fieldKey, optionID) => {
    this.setState({
      customValues: {
        ...this.state.customValues,
        [fieldKey]: {
          id: optionID,
        },
      },
    });
  };

  onCustomFieldMultiselectDropdownChange = (fieldKey, optionID) => {
    this.setState({
      customValues: {
        ...this.state.customValues,
        [fieldKey]: [{ id: optionID }],
      },
    });
  };

  onCustomFieldChange = (fieldKey, e) => {
    this.setState({
      customValues: {
        ...this.state.customValues,
        [fieldKey]: e.target.value,
      },
    });
  };

  onIssueTypeChange = (issueTypeID) => {
    const { selectedProject } = this.state;
    this.setState({
      selectedIssueType: selectedProject.issueTypes.find((issueType) => {
        return issueType.id === issueTypeID;
      }),
    });
  };

  onProjectChange = (projectID) => {
    const { projects } = this.state;
    const selectedProject = projects.find((project) => {
      return project.id === projectID;
    });

    this.fetchJiraRequiredFields(selectedProject);
    this.setState({
      selectedProject,
      selectedIssueType: selectedProject.issueTypes[0],
    });
  };

  renderContents() {
    const { error, projects, requiredFields } = this.state;
    if (error) {
      return (
        <div className="error">Something went wrong, please contact our team for&nbsp;support.</div>
      );
    } else if (!projects || !requiredFields) {
      return <Spinner />;
    } else if (projects.length === 0) {
      return (
        <div className="error">
          It appears you don't have any Jira projects. If this isn't accurate, please contact our
          team for&nbsp;support.
        </div>
      );
    }

    const {
      board,
      company: { subdomain },
      post,
    } = this.props;
    const { selectedIssueType, selectedProject } = this.state;
    const requiredFieldsForContext =
      requiredFields?.[selectedProject.id]?.[selectedIssueType.id]?.requiredFields;
    const unsupportedFields = { invalid: [], type: [] };
    if (requiredFieldsForContext && requiredFieldsForContext.length > 0) {
      requiredFieldsForContext.forEach((requiredField) => {
        if (!SupportedFieldTypes[requiredField.schema.type]) {
          unsupportedFields.type.push(requiredField);
        }
        if (requiredField.schema.type === 'array' && !requiredField.allowedValues?.length) {
          unsupportedFields.invalid.push(requiredField);
        }
      });
    }
    const areFieldsSupported = unsupportedFields.type.length === 0;
    const areAllCustomRequiredFieldsValid = unsupportedFields.invalid.length === 0;

    const postURL =
      'https://' + subdomain + '.canny.io/admin/board/' + board.urlName + '/p/' + post.urlName;
    const description = post.details ? post.details + '\n\n' + postURL : postURL;

    const errorFieldsString = oxfordJoin(
      unsupportedFields.invalid.map((fieldName) => `"${fieldName.name}"`),
      'and'
    );

    return (
      <div className="createIssueForm">
        <div className="dropdownsContainer">
          <div className="dropdownOuterContainer">
            <UppercaseHeader>Project</UppercaseHeader>
            <Dropdown
              defaultSelectedName={selectedProject.id}
              options={projects
                .sort((a, b) => {
                  if (a.key < b.key) {
                    return -1;
                  } else if (a.key > b.key) {
                    return 1;
                  }
                  return 0;
                })
                .map((project) => ({
                  name: project.id,
                  render: (
                    <div className="jiraProject">{'[' + project.key + '] ' + project.name}</div>
                  ),
                }))}
              onChange={this.onProjectChange}
              ref={this.projectDropdownRef}
            />
          </div>
          <div className="dropdownOuterContainer">
            <UppercaseHeader>Issue Type</UppercaseHeader>
            <Dropdown
              defaultSelectedName={selectedIssueType.id}
              key={selectedProject.id}
              options={selectedProject.issueTypes
                .filter((issueType) => {
                  return issueType.name !== 'Sub-task';
                })
                .map((issueType) => ({
                  name: issueType.id,
                  render: <div className="jiraIssueType">{issueType.name}</div>,
                }))}
              onChange={this.onIssueTypeChange}
              ref={this.issueTypeDropdownRef}
            />
          </div>
        </div>
        <TextInput defaultValue={post.title} inset="Summary" ref={this.summaryRef} />
        <AutoResizeTextarea
          defaultValue={description}
          inset="Description"
          maxRows={8}
          minRows={3}
          ref={this.descriptionRef}
        />
        {areAllCustomRequiredFieldsValid ? (
          this.renderCustomRequiredFields()
        ) : (
          <div className="error">
            <div className="error">
              You can not create a Jira issue because the following Jira fields are incompatible
              with Canny: {errorFieldsString}. Please mark the fields as optional in your Jira
              settings to continue.
            </div>
          </div>
        )}
        {this.renderError()}
        {areFieldsSupported ? null : (
          <div className="error">
            A required custom field, "{unsupportedFields.type[0].name}", has a type that we do not
            support, "{unsupportedFields.type[0].schema.type}". Please contact support for
            assistance.
          </div>
        )}
        <Button
          buttonType="cannyButton"
          disabled={
            !selectedProject ||
            !selectedIssueType ||
            !areFieldsSupported ||
            !areAllCustomRequiredFieldsValid
          }
          loading={this.state.creating}
          onTap={this.onCreateIssue}
          value="Create & Link Issue"
        />
      </div>
    );
  }

  renderCustomRequiredFields() {
    const { requiredFields, selectedIssueType, selectedProject } = this.state;
    const requiredFieldsForContext =
      requiredFields?.[selectedProject.id]?.[selectedIssueType.id]?.requiredFields;
    if (!requiredFieldsForContext || requiredFieldsForContext.length === 0) {
      return null;
    }

    const customFieldInputs = requiredFieldsForContext
      .map((field) => {
        if (StaticFields[field.schema.system]) {
          return null;
        } else if (
          field.schema.type === 'string' &&
          field.schema.custom === CustomFieldSchemas.textarea
        ) {
          return (
            <AutoResizeTextarea
              defaultValue={this.state.customValues[field.key]?.value}
              inset={field.name}
              key={field.key}
              onChange={(e) => this.onCustomFieldChange(field.key, e)}
            />
          );
        } else if (field.schema.type === 'string' || field.schema.type === 'url') {
          return (
            <TextInput
              defaultValue={
                this.state.customValues[field.key] ? this.state.customValues[field.key].value : ''
              }
              inset={field.name}
              key={field.key}
              onChange={this.onCustomFieldChange.bind(this, field.key)}
            />
          );
        } else if (field.schema.type === 'option') {
          return (
            <div className="customDropdown" key={field.key}>
              <UppercaseHeader>{field.name}</UppercaseHeader>
              <Dropdown
                defaultSelectedName={
                  this.state.customValues[field.key]
                    ? this.state.customValues[field.key].id
                    : field.allowedValues[0].id
                }
                options={field.allowedValues.map((allowedValue) => ({
                  name: allowedValue.id,
                  render: allowedValue.value,
                }))}
                onChange={this.onCustomFieldDropdownChange.bind(this, field.key)}
              />
            </div>
          );
        } else if (field.schema.type === 'array') {
          return (
            <div className="customDropdown" key={field.key}>
              <UppercaseHeader>{field.name}</UppercaseHeader>
              <Dropdown
                defaultSelectedName={
                  this.state.customValues[field.key]
                    ? this.state.customValues[field.key][0].id
                    : field.allowedValues[0].id
                }
                options={field.allowedValues.map((allowedValue) => ({
                  name: allowedValue.id,
                  render: allowedValue.value || allowedValue.name,
                }))}
                onChange={(options) =>
                  this.onCustomFieldMultiselectDropdownChange(field.key, options)
                }
              />
            </div>
          );
        } else {
          const error = new Error(`Unsupported required field type: ${field.schema.type}`);
          SentrySDK.captureException(error);
          return null;
        }
      })
      .filter((result) => !!result);
    return <div className="customRequiredFields">{customFieldInputs}</div>;
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    return (
      <div className="adminCreateJiraIssueModal">
        <div className="heading">Create a new Jira issue</div>
        {this.renderContents()}
      </div>
    );
  }
}

export default withContexts(
  {
    closeModal: CloseModalContext,
    company: CompanyContext,
  },
  { forwardRef: true }
)(AdminCreateJiraIssueModal);

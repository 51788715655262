import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import translateString from 'common/i18n/translateString';
import companyOGImage from 'common/util/companyOGImage';
import getCannyOrigin from 'common/util/getCannyOrigin';
import removeMarkdownLinks from 'common/util/removeMarkdownLinks';
import withContexts from 'common/util/withContexts';

import Helmet from './Helmet';

class BoardHomeHelmet extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
      strings: PropTypes.shape({
        description: PropTypes.string,
      }),
    }),
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  getDescription(company, board) {
    const description = translateString(board.strings, 'description');
    if (description) {
      return removeMarkdownLinks(description);
    }

    const removeBranding = company?.features?.removeBranding;
    return `Give feedback to the ${
      company.name
    } team so we can make more informed product decisions.${
      removeBranding ? '' : ' Powered by Canny.'
    }`;
  }

  render() {
    const { board, company } = this.props;
    if (!board || !board.name || !company.name) {
      return null;
    }

    const title = `${board.name} | ${company.name}`;
    const url = getCannyOrigin(company) + '/' + board.urlName;

    const description = this.getDescription(company, board);
    return (
      <Helmet
        title={title}
        link={[{ href: url, rel: 'canonical' }]}
        meta={[
          {
            name: 'description',
            content: description,
          },

          // og
          { property: 'og:image', content: companyOGImage(company) },
          {
            property: 'og:description',
            content: description,
          },
          { property: 'og:title', content: title },

          // twitter
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@cannyHQ' },
          { name: 'twitter:title', content: title },
          {
            name: 'twitter:description',
            content: description,
          },
          { name: 'twitter:image', content: companyOGImage(company) },
        ]}
      />
    );
  }
}

export default withContexts({ company: CompanyContext })(BoardHomeHelmet);

import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import Dropdown from 'common/Dropdown';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';

const AnyAllOptions = [
  {
    name: 'any',
    render: 'any',
  },
  {
    name: 'all',
    render: 'all',
  },
];
const NotifyOptions = [
  {
    name: 'notify',
    render: 'notify',
  },
  {
    name: 'do not notify',
    render: 'do not notify',
  },
];

const CreateRuleForm = ({ linearStatuses, loading, onClose, onSubmit }) => {
  // context
  const company = useContext(CompanyContext);

  // state
  const [allLinkedIssues, setAllLinkedIssues] = useState(false);
  const [statusName, setStatusName] = useState(null);

  // refs
  const cannyStatusDropdownRef = useRef();
  const notifyVotersDropdownRef = useRef();

  // effects
  useEffect(() => {
    if (!linearStatuses || statusName) {
      return;
    }

    setStatusName(linearStatuses[0]);
  }, [linearStatuses, statusName]);

  // helpers
  const onAnyAllChanged = (option) => {
    setAllLinkedIssues(option === 'all');
  };

  const onStatusChange = (statusName) => {
    setStatusName(statusName);
  };

  // Note: this helper is a wrapper for onSubmit prop
  const onSubmitForm = () => {
    const cannyStatus = cannyStatusDropdownRef.current.getValue();
    const shouldNotifyVoters = notifyVotersDropdownRef.current.getValue() === 'notify';

    onSubmit({
      allLinkedIssues,
      cannyStatus,
      linearStatus: statusName,
      shouldNotifyVoters,
    });
  };

  const render = () => {
    const isPlural = !!allLinkedIssues;
    const statusesOptions =
      linearStatuses.sort().map((status) => ({
        name: status,
        render: <div className="linearStatusLabel">{status}</div>,
      })) || [];
    const cannyStatusOptions = company.statuses.map((status) => {
      return {
        name: status.name,
        render: <PostStatus showOpen={true} status={status.name} />,
      };
    });
    const selectedAllOption = allLinkedIssues ? 'all' : 'any';

    return (
      <div className="composerContainer">
        <div className="composer">
          When
          <ControlledDropdown
            className="anyAllDropdown"
            dropdownClassName="adminLinearSettingsDropdown"
            onChange={onAnyAllChanged}
            options={AnyAllOptions}
            selectedName={selectedAllOption}
          />
          linked Linear {isPlural ? 'issues are' : 'issue is'} changed to
          <ControlledDropdown
            className="linearStatusDropdown"
            dropdownClassName="adminLinearSettingsDropdown"
            options={statusesOptions}
            onChange={onStatusChange}
            selectedName={statusName}
          />
          , change all linked Canny posts to
          <Dropdown
            className="cannyStatusDropdown"
            defaultSelectedName="open"
            dropdownClassName="adminLinearSettingsDropdown"
            options={cannyStatusOptions}
            ref={cannyStatusDropdownRef}
          />
          , and
          <Dropdown
            className="notifyDropdown"
            defaultSelectedName="notify"
            dropdownClassName="adminLinearSettingsDropdown"
            options={NotifyOptions}
            ref={notifyVotersDropdownRef}
          />
          voters.
        </div>
        <div className="buttons">
          <Button buttonType="ghostButton" onTap={onClose} value="cancel" />
          <Button loading={loading} onTap={onSubmitForm} value="Create" />
        </div>
      </div>
    );
  };

  return render();
};

CreateRuleForm.propTypes = {
  linearStatuses: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CreateRuleForm;

import React, { forwardRef } from 'react';

interface LazyLoadedImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  loading?: 'eager' | 'lazy';
  fetchpriority?: 'high' | 'auto';
}

const LazyLoadedImage = forwardRef<HTMLImageElement, LazyLoadedImageProps>(
  ({ loading = 'lazy', ...props }, ref) => {
    const isPriority = loading === 'eager';
    const fetchPriority = isPriority ? 'high' : 'auto';
    // eslint-disable-next-line
    return <img ref={ref} fetchpriority={fetchPriority} {...props} />;
  }
);

export default LazyLoadedImage;

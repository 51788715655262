import { getPostQueryKey, mapQueryFilterParamsToRequestData } from 'common/util/filterPosts';

import Data from '../Data';

// Action Types
export const RemovePostFromAllRoadmaps = 'canny/roadmap_posts/remove_post';
export const RequestRoadmapPosts = 'canny/roadmap_posts/request';
export const RoadmapPostsLoaded = 'canny/roadmap_posts/loaded';
export const RoadmapPostsError = 'canny/roadmap_posts/error';
export const RoadmapPostsTasksLoaded = 'canny/roadmap_posts/tasks_loaded';

// Actions
function removePost(post) {
  return {
    post,
    timestamp: Date.now(),
    type: RemovePostFromAllRoadmaps,
  };
}

function requestRoadmapPosts(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestRoadmapPosts,
  };
}

function roadmapPostsLoaded(
  boards,
  categories,
  owners,
  roadmapPosts,
  statuses,
  roadmap,
  queryParams
) {
  return {
    roadmapPosts,
    boards,
    categories,
    owners,
    statuses,
    timestamp: Date.now(),
    type: RoadmapPostsLoaded,
    queryParams,
  };
}

function roadmapPostsTasksLoaded(postTasks, queryParams) {
  return {
    postTasks,
    queryParams,
    timestamp: Date.now(),
    type: RoadmapPostsTasksLoaded,
  };
}

function roadmapPostsError(error, queryParams) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: RoadmapPostsError,
  };
}

// Action Creators
function fetchRoadmapPosts(queryParams, supplementalOwners) {
  return (dispatch, getState) => {
    const state = getState();
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: mapQueryFilterParamsToRequestData(queryParams, state.company, {
            supplementalOwners,
          }),
          query: Data.queries.roadmapPosts,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(roadmapPostsError(error, queryParams));
        } else {
          return dispatch(
            roadmapPostsLoaded(
              data.result.distinctBoards,
              data.result.distinctCategories,
              data.result.distinctOwners,
              data.result.roadmapPosts,
              data.result.statuses,
              queryParams.roadmap,
              queryParams
            )
          );
        }
      }
    );
  };
}

function fetchRoadmapPostTasks(postIDs, queryParams) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: { postIDs },
          query: Data.queries.postTasks,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          // Ignore errors
        } else {
          return dispatch(roadmapPostsTasksLoaded(data.result, queryParams));
        }
      }
    );
  };
}

export function loadRoadmapPosts(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchRoadmapPosts(getState(), queryParams)) {
      dispatch(requestRoadmapPosts(queryParams));
      const roadmapPostsPromise = dispatch(fetchRoadmapPosts(queryParams));

      roadmapPostsPromise.then((data) => {
        const roadmapPosts = getState().roadmapPosts[getPostQueryKey(queryParams)];
        if (!roadmapPosts?.posts || roadmapPosts.posts.length === 0) {
          return;
        }
        const postIDs = roadmapPosts.posts.map((roadmapPost) => roadmapPost.postID);
        dispatch(fetchRoadmapPostTasks(postIDs, queryParams));
      });
      return roadmapPostsPromise;
    }
  };
}

export function reloadRoadmapPosts(queryParams, supplementalOwners) {
  return (dispatch) => {
    return dispatch(fetchRoadmapPosts(queryParams, supplementalOwners));
  };
}

export function reloadRoadmapPostsForRoadmapsWithPost(post) {
  return (dispatch, getState) => {
    const state = getState();

    const { roadmapPosts = [] } = state;

    Object.keys(roadmapPosts).forEach((roadmapPostKey) => {
      const { posts = [] } = roadmapPosts[roadmapPostKey];
      const includesPost = !!posts.find((roadmapPost) => roadmapPost.postID === post._id);
      if (includesPost) {
        dispatch(reloadRoadmapPosts(roadmapPosts[roadmapPostKey].queryParams));
      }
    });
  };
}

// Update all instances of the roadmap in our state (filtered and unfiltered)
export function reloadRoadmapPostsForRoadmap(roadmap) {
  return (dispatch, getState) => {
    const state = getState();

    const { roadmapPosts = [] } = state;

    Object.keys(roadmapPosts).forEach((roadmapPostKey) => {
      if (roadmapPostKey.includes(roadmap._id)) {
        dispatch(reloadRoadmapPosts(roadmapPosts[roadmapPostKey].queryParams));
      }
    });
  };
}

export function removePostFromAllRoadmaps(post) {
  return (dispatch) => {
    return dispatch(removePost(post));
  };
}

// Helper Functions
function shouldFetchRoadmapPosts(state, queryParams) {
  if (!queryParams.roadmap || !queryParams.roadmap._id) {
    return false;
  }

  const queryKey = getPostQueryKey(queryParams);

  return !state.roadmapPosts[queryKey];
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { reloadPost } from 'common/actions/posts';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import VideoNUX from 'common/common/VideoNUX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import { CustomFieldTypes } from 'common/customPostFields/Constants';
import CopyButtonV2 from 'common/inputs/CopyButtonV2';
import Link from 'common/Link';
import AccessModal from 'common/modals/AccessModal';
import EditableCustomPostField from 'common/post/EditableCustomPostField';
import MovePostDropdown from 'common/post/MovePostDropdown';
import PostCategoryDropdown from 'common/post/PostCategoryDropdown';
import PostCategoryMenu from 'common/post/PostCategoryMenu';
import PostETAInput from 'common/post/PostETAInput';
import PostOwnerInput from 'common/post/PostOwnerInput';
import PostStatusDropdown from 'common/post/PostStatusDropdown';
import PostVoteAsForm from 'common/post/PostVoteAsForm';
import VotersFacepile from 'common/post/VotersFacepile';
import AdminAsanaPostSidebarSection from 'common/subdomain/admin/AdminAsanaPostSidebarSection';
import AdminAzureDevopsPostSidebarSection from 'common/subdomain/admin/AdminAzureDevopsPostSidebarSection';
import AdminLinearPostSidebarSection from 'common/subdomain/admin/AdminLinearPostSidebarSection';
import Tag from 'common/tags/Tag';
import TagSelector from 'common/tags/TagSelector';
import Tappable from 'common/Tappable';
import getCannyOrigin from 'common/util/getCannyOrigin';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';
import numberWithCommas from 'common/util/numberWithCommas';
import withContexts from 'common/util/withContexts';

import AdminClickupPostSidebarSection from './AdminClickupPostSidebarSection';
import AdminFeedbackVotersModal from './AdminFeedbackVotersModal';
import AdminGitHubPostSidebarSection from './AdminGitHubPostSidebarSection';
import AdminJiraPostSidebarSection from './AdminJiraPostSidebarSection';
import AdminRoadmapsPostSidebarSection from './AdminRoadmapsPostSidebarSection';
import AdminSidebarSection from './AdminSidebarSection';

import VideoThumbnailVote from 'img/video-thumbnail-vote.jpg';

import 'css/components/subdomain/admin/_AdminPostSidebar.scss';

const Modals = {
  opportunities: 'OpportunitiesModal',
  voters: 'VotersModal',
};

class AdminPostSidebar extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
      urlName: PropTypes.string,
    }),
    boards: PropTypes.array,
    company: PropTypes.shape({
      fullScreenAdminView: PropTypes.bool,
      subdomain: PropTypes.string,
    }),
    customPostFields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    openModal: PropTypes.func,
    post: PropTypes.shape({
      status: PropTypes.string,
      voters: PropTypes.array,
    }),
    roadmaps: PropTypes.array,
    viewer: PropTypes.object,
  };

  state = {
    modal: null,
    tagsFormOpen: false,
    tagsLoading: [],
    voterFormOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.tagSelectorRef = React.createRef();
  }

  isAllowedRoadmapAccess() {
    const { company, viewer } = this.props;
    if (company.postCount === 0) {
      return false;
    } else if (!hasPermission('manageRoadmap', company, viewer)) {
      return false;
    }

    return company.features.prioritizationRoadmap;
  }

  onCategorySelected = async (category) => {
    const { post, reloadPost } = this.props;
    await AJAX.post('/api/posts/changeCategory', {
      categoryID: category ? category._id : null,
      postID: post._id,
    });
    reloadPost(post);
  };

  onShowOpportunitiesModal = () => {
    this.setState({ modal: Modals.opportunities });
  };

  onShowVotersModal = () => {
    this.setState({ modal: Modals.voters });
  };

  onTagSelectorFocus = () => {
    const { company, openModal, viewer } = this.props;
    if (hasPermission('changePostTags', company, viewer)) {
      return;
    }

    openModal(AccessModal, {
      requiredPermissions: ['changePostTags'],
    });
    setTimeout(() => {
      this.tagSelectorRef.current.blur();
    }, 0);
  };

  onToggleTag = (tag) => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('changePostTags', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['changePostTags'],
      });
      return;
    }

    const { tagsLoading } = this.state;
    if (tagsLoading.includes(tag._id)) {
      return;
    }

    this.setState({
      tagsLoading: tagsLoading.concat([tag._id]),
    });

    const { post, reloadPost } = this.props;
    const postTags = this.props.post.tags;
    if (
      postTags.some((postTag) => {
        return postTag._id === tag._id;
      })
    ) {
      AJAX.post(
        '/api/posts/removeTag',
        {
          postID: post._id,
          tagID: tag._id,
        },
        (response) => {
          reloadPost(post).then(() => {
            this.setState({
              tagsLoading: this.state.tagsLoading.filter((t) => t !== tag._id),
            });
          });
        }
      );
    } else {
      AJAX.post(
        '/api/posts/addTag',
        {
          postID: post._id,
          tagID: tag._id,
        },
        (response) => {
          reloadPost(post).then(() => {
            this.setState({
              tagsLoading: this.state.tagsLoading.filter((t) => t !== tag._id),
            });
          });
        }
      );
    }
  };

  onUpdatePostField = async (value, customPostField) => {
    const { post, reloadPost } = this.props;

    await AJAX.post('/api/posts/edit', {
      customFieldValuesMap: {
        [customPostField._id]: value,
      },
      details: post.details,
      fileURLs: JSON.stringify(post.fileURLs || []),
      imageURLs: JSON.stringify(post.imageURLs || []),
      postID: post._id,
      title: post.title,
    });

    await reloadPost(post);
  };

  onToggleTagsForm = () => {
    this.setState(({ tagsFormOpen }) => ({
      tagsFormOpen: !tagsFormOpen,
    }));
  };

  onToggleVotersForm = () => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('voteOnBehalf', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['voteOnBehalf'],
      });
      return;
    }

    this.setState({
      voterFormOpen: !this.state.voterFormOpen,
    });
  };

  renderBoard() {
    const {
      company: { boardCount },
    } = this.props;
    if (boardCount <= 1) {
      return null;
    }

    return (
      <div className="postDetail">
        <div className="detailLabel">Board</div>
        {this.renderMovePostDropdown()}
      </div>
    );
  }

  renderUnlinkedPostFields() {
    const {
      board: { boardFields },
      company,
      customPostFields,
      post,
    } = this.props;
    const boardFieldMap = mapify(boardFields, 'customPostFieldID');
    const postFieldMap = mapify(post.customPostFields, '_id');

    const doesPlanSupport = company.features?.customPostFields ?? false;
    const hasFields = !!customPostFields?.length;
    if (!hasFields) {
      return null;
    }

    const mapFieldToDetail = (customPostField) => {
      // skip fields that are in the "create post" form
      const isInBoardForm = boardFieldMap[customPostField._id];
      if (isInBoardForm) {
        return null;
      }

      // skip empty fields if plan does not support custom fields
      const value = postFieldMap[customPostField._id]?.value;
      if (!doesPlanSupport && !value) {
        return null;
      }

      const fullType = CustomFieldTypes[customPostField.type];
      return (
        <div className="postDetail customField" key={customPostField._id}>
          <Tooltip
            position="top"
            delay={1000}
            containerClassName="detailLabel"
            value={customPostField.name}>
            <label htmlFor={customPostField._id}>{customPostField.name}</label>
          </Tooltip>
          <div className="detailValue">
            <EditableCustomPostField
              key={customPostField._id}
              customPostField={{
                ...customPostField,
                placeholder: fullType.hasOptions ? '—' : null,
                value,
              }}
              onSubmit={this.onUpdatePostField}
              showUpsell={!doesPlanSupport}
            />
          </div>
        </div>
      );
    };

    return customPostFields.map(mapFieldToDetail);
  }

  renderCategoryOption() {
    const { board, post } = this.props;
    const settingsURL = `/admin/settings/boards/${board.urlName}/categories`;

    if (!board?.categories || board.categories.length === 0) {
      return (
        <div className="postDetail">
          <div className="detailLabel">Category</div>
          <Link to={settingsURL}>
            <div className="detailValue toggleButton">Add</div>
          </Link>
        </div>
      );
    }

    if (board.categories.length < 3) {
      return (
        <div className="postDetail">
          <div className="detailLabel">Category</div>
          <div className="detailValue">
            <PostCategoryDropdown post={post} board={board} />
          </div>
        </div>
      );
    }

    const categoryName = post?.category?.name;
    return (
      <div className="postDetail">
        <div className="detailLabel">Category</div>
        <div className="detailValue">
          <PostCategoryMenu board={board} onCategorySelected={this.onCategorySelected}>
            <div className="categorySelectorTrigger">
              <div className={classnames('categoryName', { selected: !!categoryName })}>
                {categoryName || 'Uncategorized'}
              </div>
              <div className="icon-chevron-down" />
            </div>
          </PostCategoryMenu>
        </div>
      </div>
    );
  }

  renderETADetail() {
    const { post, reloadPost } = this.props;
    return (
      <div className="postDetail">
        <PostETAInput post={post} reloadPost={reloadPost} />
      </div>
    );
  }

  renderMovePostDropdown() {
    const { boards, post, board: currentBoard } = this.props;
    return (
      <div className="movePost detailValue">
        <MovePostDropdown board={currentBoard} boards={boards} post={post} />
      </div>
    );
  }

  renderOpportunitiesSection() {
    const { company, post } = this.props;
    if (!company.fullScreenAdminView || !post.opportunities?.length) {
      return null;
    }

    const { opportunities } = post;
    const header =
      opportunities.length > 1 ? `${opportunities.length} Opportunities` : `1 Opportunity`;

    const value = opportunities.reduce((prev, opportunity) => {
      if (!opportunity.value) {
        return prev;
      }
      return prev + opportunity.value;
    }, 0);

    return (
      <AdminSidebarSection className="opportunities" title={header}>
        <Tappable onTap={this.onShowOpportunitiesModal}>
          <div className="opportunityValue">${numberWithCommas(value)} total value</div>
        </Tappable>
      </AdminSidebarSection>
    );
  }

  renderOwner() {
    const { post, reloadPost } = this.props;
    return (
      <div className="postDetail">
        <PostOwnerInput post={post} reloadPost={reloadPost} />
      </div>
    );
  }

  renderPostDetails() {
    const { board, company, post } = this.props;
    return (
      <AdminSidebarSection className="postDetails" title="Details">
        {this.renderPublicLink()}
        {this.renderBoard()}
        <div className="postDetail">
          <PostStatusDropdown
            board={board}
            // if a company downgrades, they might still have custom statuses in the DB
            // hide them so posts can't be assigned to custom default statuses
            showCustomStatuses={company.features.customStatuses}
            post={post}
          />
        </div>
        {this.renderOwner()}
        {this.renderETADetail()}
        {this.renderCategoryOption()}
        {this.renderUnlinkedPostFields()}
      </AdminSidebarSection>
    );
  }

  renderPublicLink() {
    const { board, company, post } = this.props;
    const toURL = `/${board.urlName}/p/${post.urlName}`;
    const widgetURL = board?.settings?.widgetURL;
    const boardURL = widgetURL || `${getCannyOrigin(company)}/${board.urlName}`;
    const publicURL = `${boardURL}/p/${post.urlName}`;

    return (
      <div className="postDetail">
        <div className="detailLabel">Public link</div>
        <Link className="publicLink detailValue" to={toURL}>
          {publicURL}
        </Link>
        <CopyButtonV2 className="customPostFieldIcon" value={publicURL} />
      </div>
    );
  }

  renderPostTags() {
    const boardTags = this.props.board.tags;
    const postTags = this.props.post.tags;

    if (this.props.post.error) {
      return null;
    }

    const postTagsSet = {};
    postTags.forEach((postTag) => {
      postTagsSet[postTag._id] = true;
    });

    const { tagsLoading } = this.state;
    const tagButtons = [];
    boardTags
      .filter((boardTag) => {
        return postTagsSet[boardTag._id] || tagsLoading.includes(boardTag._id);
      })
      .forEach((boardTag) => {
        tagButtons.push(
          <Tag
            key={boardTag._id}
            loading={this.state.tagsLoading.includes(boardTag._id)}
            name={boardTag.name}
            onTap={this.onToggleTag.bind(this, boardTag)}
            selected={true}
          />
        );
      });

    if (tagButtons.length === 0) {
      return null;
    }

    return <div className="tagButtons">{tagButtons}</div>;
  }

  renderTagsAction = () => {
    return (
      <Tappable onTap={this.onToggleTagsForm}>
        <div>Add tags</div>
      </Tappable>
    );
  };

  renderTagsForm() {
    const { tagsFormOpen, tagsLoading } = this.state;
    const { board, company, post, reloadBoard, viewer } = this.props;

    if (post.error) {
      return null;
    }

    const excludeTags = post.tags.concat(tagsLoading);

    if (!tagsFormOpen) {
      return null;
    }

    return (
      <TagSelector
        boards={[board]}
        excludeTags={excludeTags}
        allowTagCreation={hasPermission('manageTags', company, viewer)}
        onFocus={this.onTagSelectorFocus}
        onTagSelected={this.onToggleTag}
        onTagCreated={async (tag) => {
          await reloadBoard(board.urlName);
          this.onToggleTag(tag);
        }}
        placeholder="Add tag"
        ref={this.tagSelectorRef}
      />
    );
  }

  renderTags() {
    return (
      <AdminSidebarSection action={this.renderTagsAction()} className="postTags" title="Tags">
        {this.renderTagsForm()}
        {this.renderPostTags()}
      </AdminSidebarSection>
    );
  }

  renderVotersAction() {
    return (
      <Tappable onTap={this.onToggleVotersForm}>
        <div>Add voter</div>
      </Tappable>
    );
  }

  renderVotersForm() {
    if (!this.state.voterFormOpen) {
      return null;
    }

    const { post } = this.props;
    return <PostVoteAsForm post={post} />;
  }

  renderVoters() {
    const { post } = this.props;
    return (
      <Tappable onTap={this.onShowVotersModal}>
        <div>
          <VotersFacepile post={post} voters={post.voters} />
        </div>
      </Tappable>
    );
  }

  renderVotersSection() {
    const { post } = this.props;
    const votersHeader = `${post.score} Voter${post.score === 1 ? '' : 's'}`;

    return (
      <AdminSidebarSection
        action={this.renderVotersAction()}
        className="voters"
        title={votersHeader}>
        {this.renderVotersForm()}
        <VideoNUX
          name="voteBehalf"
          text="Vote on behalf of your&nbsp;users"
          videoThumbnail={VideoThumbnailVote}
          videoURL="https://www.loom.com/embed/4248837682a94aacb22d455b8d1c4e09?autoplay=1"
        />
        {this.renderVoters()}
      </AdminSidebarSection>
    );
  }

  renderAsanaSection() {
    const { post } = this.props;
    return <AdminAsanaPostSidebarSection post={post} />;
  }

  renderAzureDevopsSection() {
    const { post } = this.props;
    return <AdminAzureDevopsPostSidebarSection post={post} />;
  }

  renderClickupSection() {
    const { post } = this.props;
    return <AdminClickupPostSidebarSection post={post} />;
  }

  renderGitHubSection() {
    const { board, post } = this.props;

    return <AdminGitHubPostSidebarSection board={board} post={post} />;
  }

  renderJiraSection() {
    const { board, post } = this.props;
    return <AdminJiraPostSidebarSection board={board} post={post} />;
  }

  renderLinearSection() {
    const { post } = this.props;
    return <AdminLinearPostSidebarSection post={post} />;
  }

  renderRoadmapsSection() {
    const { post, roadmaps } = this.props;
    if (!this.isAllowedRoadmapAccess()) {
      return null;
    }

    return <AdminRoadmapsPostSidebarSection post={post} roadmaps={roadmaps} />;
  }

  renderModal() {
    const { post } = this.props;
    const { modal } = this.state;

    if (modal === Modals.voters) {
      return (
        <AdminFeedbackVotersModal
          defaultOption="voters"
          onClose={() => this.setState({ modal: null })}
          post={post}
        />
      );
    } else if (modal === Modals.opportunities) {
      return (
        <AdminFeedbackVotersModal
          defaultOption="opportunities"
          onClose={() => this.setState({ modal: null })}
          post={post}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div className="adminPostSidebar">
        {this.renderPostDetails()}
        {this.renderTags()}
        {this.renderVotersSection()}
        {this.renderOpportunitiesSection()}
        {this.renderRoadmapsSection()}
        {this.renderAsanaSection()}
        {this.renderClickupSection()}
        {this.renderGitHubSection()}
        {this.renderJiraSection()}
        {this.renderLinearSection()}
        {this.renderAzureDevopsSection()}
        {this.renderModal()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => {
      return Promise.all([
        dispatch(reloadRoadmapPostsForRoadmapsWithPost(post)),
        dispatch(reloadPost(post)),
      ]);
    },
    reloadBoard: (boardURLName) => {
      return dispatch(reloadBoard(boardURLName));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminPostSidebar);

import React from 'react';

import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import SentrySDK from 'common/3rd/SentrySDK';
import { CookieNames } from 'common/cookies/cookies';
import GoogleAnalytics from 'common/ga/GoogleAnalytics';
import getCookies from 'common/util/getCookies';

import configureStore from './configureStore';

import 'common/Polyfills';
import 'common/storage/sessionStorageWrapper';

export default function createEntry(config) {
  const {
    app: App,
    enableAdsTracking = false,
    ga4MeasurementID,
    history: customHistory,
    reducers,
    sentryDSN,
  } = config;
  if (!App || !sentryDSN || !reducers) {
    throw new Error('Invalid app config: ' + config);
  }

  SentrySDK.init(sentryDSN);

  const cookies = {};
  if (__DEV__ && !(__SSR_LOCAL_SERVER__ || __SSR_TEST_RUNNER__)) {
    const cookieNames = Object.values(CookieNames);
    const cookieValues = getCookies(cookieNames);
    cookieNames.forEach((cookieName, i) => {
      if (cookieValues[i]) {
        cookies[cookieName] = cookieValues[i];
      }
    });
  }

  const store = configureStore(reducers, { ...cookies });
  const history = syncHistoryWithStore(customHistory || browserHistory, store);

  if (ga4MeasurementID) {
    GoogleAnalytics.track({
      enableAdsTracking,
      excludePathnames: [],
      history,
      measurementID: ga4MeasurementID,
      trackerName: 'cannyTracker',
    });
  }

  (__DEV__ || __BROWSER_EXTENSION__ ? render : hydrate)(
    <Provider store={store}>
      <HelmetProvider>
        <App history={history} />
      </HelmetProvider>
    </Provider>,
    document.getElementById('content')
  );
}

import React from 'react';

import 'css/components/common/_VideoPlayButton.scss';

const VideoPlayButton = () => {
  return (
    <div className="videoPlayButton">
      <div className="triangle" />
    </div>
  );
};

export default VideoPlayButton;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import Message from 'common/message/Message';
import LocalStorage from 'common/storage/LocalStorage';
import devURL from 'common/util/devURL';
import parseJSON from 'common/util/parseJSON';
import withContexts from 'common/util/withContexts';

const HaveSetProductSeen = 'have-set-product-seen';
const HaveTrackedProductSeen = 'have-tracked-product-seen';
const ProductSeenOrigin = devURL('https://product-seen.canny.io');

class ProductSeenContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    trackEvent: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.iframeRef = React.createRef();
  }

  handleLanding = () => {
    const iframe = this.iframeRef.current;
    if (!iframe?.contentWindow) {
      return;
    }

    const haveTrackedProductSeen = LocalStorage.get(HaveTrackedProductSeen);
    if (haveTrackedProductSeen) {
      return;
    }

    Message.subscribe(
      iframe.contentWindow,
      ProductSeenOrigin,
      'return-product-seen',
      (resultJSON) => {
        parseJSON(resultJSON, (error, result) => {
          if (error) {
            return;
          }

          const { trackEvent } = this.props;
          const subdomain = result?.subdomain;
          if (!subdomain) {
            return;
          }

          trackEvent('Seen Product', { subdomain });
          LocalStorage.set(HaveTrackedProductSeen, true);
        });
      }
    );
    Message.postMessage(iframe.contentWindow, ProductSeenOrigin, 'get-product-seen');
  };

  handleSubdomain = () => {
    const iframe = this.iframeRef.current;
    if (!iframe?.contentWindow) {
      return;
    }

    const { company } = this.props;
    if (company.viewerIsMember) {
      return;
    }

    const haveSetProductSeen = LocalStorage.get(HaveSetProductSeen);
    if (haveSetProductSeen) {
      return;
    }

    Message.postMessage(iframe.contentWindow, ProductSeenOrigin, 'set-product-seen', {
      subdomain: company.subdomain,
    });
    LocalStorage.set(HaveSetProductSeen, true);
  };

  onFrameLoaded = () => {
    const { company } = this.props;
    if (company) {
      // subdomain, set-product-seen, if haven't already
      this.handleSubdomain();
    } else {
      // landing, get-product-seen, trackEvent accordingly
      this.handleLanding();
    }
  };

  render() {
    return (
      <>
        {this.props.children}
        <iframe
          ref={this.iframeRef}
          width="0"
          height="0"
          id="canny-product-seen-frame"
          onLoad={this.onFrameLoaded}
          sandbox="allow-same-origin allow-scripts"
          scrolling="no"
          src={ProductSeenOrigin + '/product-seen.html'}
          style={{
            border: 0,
            display: 'none',
          }}
        />
      </>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  trackEvent: TrackEventContext,
})(ProductSeenContainer);

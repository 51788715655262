enum Via {
  api = 'api',
  browserExtension = 'browser_extension',
  postMerged = 'postMerged',
  postUnmerged = 'postUnmerged',
  user = 'user',
  slack = 'slack',
}

export default Via;

import { useCallback, useContext } from 'react';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';

function useCustomQueryParam(name: string) {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  // Utility function to parse the "custom" query param
  const getCustomValue = useCallback(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const customValue = currentSearchParams.get(name);
    try {
      return customValue ? JSON.parse(decodeURIComponent(customValue)) : {};
    } catch {
      return {};
    }
  }, [location.search, name]);

  const setCustomQueryParams = (id: string, operator: string | null, value: string[]) => {
    const currentCustomValue = getCustomValue();
    let newCustomValue = { ...currentCustomValue };
    if (!operator) {
      // Remove the entry if there is no operator selected
      newCustomValue[id] = undefined;

      // If we are removing the last key that exists in custom,
      // remove all of custom
      if (Object.keys(currentCustomValue).length === 1) {
        newCustomValue = null;
      }
    } else {
      // Update (or create) the entry for the given id
      newCustomValue[id] = {
        operator,
        value,
      };
    }

    const encodedCustomValue = newCustomValue
      ? encodeURIComponent(JSON.stringify(newCustomValue))
      : undefined;

    if (Object.keys(getCustomValue()).length > 1) {
      router.replace({
        pathname: location.pathname,
        query: {
          ...location.query,
          [name]: encodedCustomValue,
        },
      });
    } else {
      router.push({
        pathname: location.pathname,
        query: {
          ...location.query,
          [name]: encodedCustomValue,
        },
      });
    }
  };

  const getCustomQueryParam = useCallback(
    (id: string) => {
      const currentCustomValue = getCustomValue();
      return currentCustomValue[id] || null;
    },
    [getCustomValue]
  );

  return {
    setCustomQueryParams,
    getCustomQueryParam,
  };
}

export default useCustomQueryParam;

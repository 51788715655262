import React, { useContext } from 'react';

import { Paperclip } from 'lucide-react';

import {
  type FailedFile,
  type MimeTypeValues,
  type UploadedFile,
  type UploadingFile,
} from 'common/constants/files';
import { ViewerContext } from 'common/containers/ViewerContainer';
import uploadFile from 'common/file/utils/uploadFile';
import FileInput from 'common/inputs/FileInput';
import styles from 'css-module/components/common/_UploadFileButton.module.scss';

type OnFileErrorFunc = (file: FailedFile, error: string) => void;
type OnFileOpenFunc = () => void;
type OnFileStartFunc = () => boolean;
type OnFileUploadingFunc = (file: UploadingFile) => void;
type OnFileUploadedFunc = (file: UploadedFile) => void;

type Props = {
  acceptedMimeTypes: MimeTypeValues[];
  defaultStyle: boolean;
  onFileError: OnFileErrorFunc;
  onFileOpen?: OnFileOpenFunc;
  onFileStart?: OnFileStartFunc;
  onFileUploaded: OnFileUploadedFunc;
  onFileUploading?: OnFileUploadingFunc;
};

const UploadFileButton = ({
  acceptedMimeTypes,
  defaultStyle,
  onFileError,
  onFileOpen,
  onFileStart,
  onFileUploaded,
  onFileUploading = () => {},
}: Props) => {
  const viewer = useContext(ViewerContext);
  const accept = acceptedMimeTypes.join(',');

  const onFile = async (file: File) => {
    await uploadFile({
      file,
      viewer,
      onFileError,
      onFileUploading,
      onFileUploaded,
    });
  };

  return (
    <FileInput
      accept={accept}
      onFile={onFile}
      onOpen={onFileOpen}
      onFileStart={onFileStart}
      defaultStyle={defaultStyle}
      value={
        <div className={styles.fileIcon}>
          <Paperclip strokeWidth={2} size={16} />
        </div>
      }
    />
  );
};

export default UploadFileButton;

import React, { useContext, useEffect, useState } from 'react';

import classnames from 'classnames';
import deepCompare from 'react-fast-compare';

import {
  type QueryParams,
  type ThirdPartyCompanyFeatureWithRequestsState,
  getQueryKey,
  getQueryParams,
  invalidateAllQueries,
  loadThirdPartyCompaniesWithRequests,
} from 'common/actions/thirdPartyCompanyFeatureRequestsQueries';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import SearchInput from 'common/inputs/SearchInput';
import { ThirdPartyCompanyDefaultField } from 'common/thirdPartyCompanies/constants';
import { H2, P } from 'common/ui/Text';
import { RelativeDateRanges } from 'common/util/dateRanges';
import findStringMatches from 'common/util/findStringMatches';
import styles from 'css-module/components/subdomain/admin/reports/_ThirdPartyCompanyFeatureRequests.module.scss';

import FiltersPopover from './FiltersPopover';
import Pagination from './Pagination';
import PostDrawer from './PostDrawer';
import ThirdPartyCompanyFeatureRequestsTable from './ThirdPartyCompanyFeatureRequestsTable';

import type {
  Filters,
  PostWithVoteWeights,
  Sort,
} from 'common/api/endpoints/thirdPartyCompanyFeatureRequests';
import type { Dispatch, State } from 'redux-connect';

type ConnectProps = {
  loadThirdPartyCompaniesWithRequests: (queryParams: QueryParams) => void;
  reloadThirdPartyCompaniesWithRequests: () => void;
  thirdPartyCompaniesFeatureRequestsQueriesState: ThirdPartyCompanyFeatureWithRequestsState;
  queryState: Record<string, string | undefined>;
};

type OwnProps = {
  className?: string;
};

// set a narrow filter by default to reduce the number of lookups on the first search
const ParamName = 'tpc-feature-requests';
const DefaultFilter = {
  [ThirdPartyCompanyDefaultField.renewalDate]: RelativeDateRanges.thisWholeMonth,
};

const CompaniesPerPage = 5;

const ThirdPartyCompanyFeatureRequests = ({
  loadThirdPartyCompaniesWithRequests,
  reloadThirdPartyCompaniesWithRequests,
  thirdPartyCompaniesFeatureRequestsQueriesState,
  queryState,
  className,
}: OwnProps & ConnectProps) => {
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const boards = useContext(BoardsContext);

  // set local state
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');

  // load filters and sort from query params
  const currentQuery = queryState ?? location.query;
  const param = currentQuery[ParamName] ? String(currentQuery[ParamName]) : null;
  const state = param ? thirdPartyCompaniesFeatureRequestsQueriesState.queries[param] : null;
  const { items: companies, loading } = { items: [], ...state };
  const { sort, filter } = (param && getQueryParams(param)) ?? {
    filter: DefaultFilter,
    sort: null,
  };

  // reload data when sort and filters change
  useEffect(() => {
    loadThirdPartyCompaniesWithRequests({ sort, filter });
  }, [sort, filter, loadThirdPartyCompaniesWithRequests]);

  // filter companies locally by search term
  const filteredCompanies = search
    ? findStringMatches(
        companies,
        (companyWithFeatures) => companyWithFeatures.company.companyName,
        search
      )
    : companies;

  // paginate companies locally
  const paginatedCompanies = filteredCompanies.slice(
    (page - 1) * CompaniesPerPage,
    page * CompaniesPerPage
  );

  // mutations
  const updateFilter = (updatedFilter: Filters) => {
    if (deepCompare(updatedFilter, filter)) {
      return;
    }

    router.replace({
      pathname: location.pathname,
      query: { ...currentQuery, [ParamName]: getQueryKey({ filter: updatedFilter, sort }) },
    });

    setPage(1);
  };

  const updateSort = (updatedSort: Sort | null) => {
    if (deepCompare(updatedSort, sort)) {
      return;
    }

    router.replace({
      pathname: location.pathname,
      query: { ...currentQuery, [ParamName]: getQueryKey({ sort: updatedSort, filter }) },
    });

    setPage(1);
  };

  const selectPost = (post: PostWithVoteWeights) => {
    const board = Array.isArray(boards) ? boards.find((board) => board._id === post.boardID) : null;

    if (!board) {
      return;
    }

    router.replace({
      pathname: location.pathname,
      query: {
        ...currentQuery,
        postURLName: post.urlName,
        boardURLName: board.urlName,
      },
    });
  };

  const unselectPost = () => {
    const query = { ...currentQuery };
    delete query.postURLName;
    delete query.boardURLName;

    router.replace({
      pathname: location.pathname,
      query,
    });
  };

  const getSelectedPost = () => {
    if (!currentQuery.postURLName || !currentQuery.boardURLName) {
      return null;
    }

    const board = Array.isArray(boards)
      ? boards.find((board) => board.urlName === currentQuery.boardURLName)
      : null;

    if (!board) {
      return null;
    }

    // find the selected post among the filtered companies
    for (const company of paginatedCompanies) {
      for (const post of company.posts) {
        const matchPost = post.urlName === currentQuery.postURLName;
        const matchBoard = post.boardID === board._id;
        if (matchPost && matchBoard) {
          return post;
        }
      }
    }

    return null;
  };

  const selectedPost = getSelectedPost();
  return (
    <div className={classnames(styles.thirdPartyCompanyFeatureRequests, className)}>
      {selectedPost && (
        <PostDrawer
          post={selectedPost}
          onClose={unselectPost}
          onEdited={reloadThirdPartyCompaniesWithRequests}
        />
      )}
      <header className={styles.header}>
        <H2 variant="headingMd">Customer Requests</H2>
        <P className={styles.subtitle}>Review company requests by priority</P>
      </header>
      <div className={styles.options}>
        <SearchInput
          className={styles.searchInput}
          styling="v2"
          placeholder="Search company..."
          onChange={(value: string) => {
            setSearch(value);
            setPage(1);
          }}
        />
        <FiltersPopover filters={filter} onChange={updateFilter} />
      </div>
      <div className="tableContainer">
        <ThirdPartyCompanyFeatureRequestsTable
          companies={paginatedCompanies}
          onSelectPost={selectPost}
          onSort={updateSort}
          loading={loading}
        />
      </div>
      <div className={styles.pagination}>
        {filteredCompanies.length > 0 && (
          <P>
            Showing {Math.min(filteredCompanies.length, CompaniesPerPage)} of{' '}
            {filteredCompanies.length} companies
          </P>
        )}
        {filteredCompanies.length > CompaniesPerPage && (
          <Pagination
            onPageChange={setPage}
            page={page}
            count={Math.ceil(filteredCompanies.length / CompaniesPerPage)}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  (state: State) => ({
    // Fetch the query as it currently exists, not from `location`. `location` waits until the route is ready,
    // but we're using the query string as state, so we need it to update immediately.
    queryState: state.routing.locationBeforeTransitions.query,
    thirdPartyCompaniesFeatureRequestsQueriesState: state.thirdPartyCompanyFeatureRequestsQueries,
  }),
  (dispatch: Dispatch) => ({
    loadThirdPartyCompaniesWithRequests: (queryParams: QueryParams) => {
      return dispatch(loadThirdPartyCompaniesWithRequests(queryParams));
    },
    reloadThirdPartyCompaniesWithRequests: () => {
      return dispatch(invalidateAllQueries());
    },
  })
)(ThirdPartyCompanyFeatureRequests);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as AuthRequests from 'common/auth/AuthRequests';
import ReCAPTCHAWidget from 'common/common/ReCAPTCHAWidget';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import withContexts from 'common/util/withContexts';

import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Strings from './Strings';
import Tappable from './Tappable';
import validateInput from './validateInput';

import 'css/components/_EmailSignupForm.scss';

class EmailSignupForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    isWidget: PropTypes.bool.isRequired,
    onBackClicked: PropTypes.func.isRequired,
    onForgotPasswordClicked: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.captchaWidgetRef = React.createRef();
    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
    this.passwordRef = React.createRef();
    this.confirmRef = React.createRef();
  }

  onSubmit = async (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const email = this.emailRef.current.getValue().trim();
    const name = this.nameRef.current.getValue().trim();
    const password = this.passwordRef.current.getValue();
    const confirmPassword = this.confirmRef.current.getValue();

    if (!validateInput.userName(name)) {
      this.setState({
        error: Strings.invalidName,
      });
      return;
    }
    if (!validateInput.email(email)) {
      this.setState({
        error: Strings.invalidEmail,
      });
      return;
    }
    if (!validateInput.password(password)) {
      this.setState({
        error: Strings.invalidPassword,
      });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({
        error: Strings.passwordConfirmMismatch,
      });
      return;
    }

    const captcha = await this.captchaWidgetRef?.current.getCAPTCHA();
    const useTokenAuth = this.props.isWidget;

    this.setState({
      error: null,
      submitting: true,
    });

    const { error, parsedResponse, redirecting } = await AuthRequests.signup({
      captcha,
      email,
      name,
      password,
      useTokenAuth,
    });

    if (redirecting) {
      return;
    }

    if (!error) {
      this.setState(
        {
          submitting: false,
        },
        () => this.props.onSuccess(parsedResponse)
      );
      return;
    }

    if (error.type === 'invalid captcha') {
      this.captchaWidgetRef?.current.refresh();
    }

    this.setState({
      submitting: false,
      error: error.message,
    });
  };

  renderErrorMessage() {
    if (!this.state.error) {
      return;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    const { email } = this.props;
    return (
      <div className="emailSignupForm">
        <div className="topContainer">
          <h1>Sign up with Email</h1>
          <form onSubmit={this.onSubmit}>
            <TextInput autoFocus={true} placeholder="Full name" ref={this.nameRef} />
            <TextInput defaultValue={email ? email : ''} placeholder="Email" ref={this.emailRef} />
            <TextInput placeholder="Password" ref={this.passwordRef} type="password" />
            <TextInput placeholder="Confirm password" ref={this.confirmRef} type="password" />
            {this.renderErrorMessage()}
            <ReCAPTCHAWidget ref={this.captchaWidgetRef} />
            <div className="buttonContainer">
              <Tappable onTap={this.props.onBackClicked}>
                <span className="greyLink">Cancel</span>
              </Tappable>
              <Button
                formButton={true}
                loading={this.state.submitting}
                tint={true}
                value="Sign up"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withContexts({ isWidget: IsWidgetContext }, { forwardRef: true })(EmailSignupForm);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tappable from '../Tappable';

import 'css/components/common/_Toggle.scss';

export default class Toggle extends Component {
  static propTypes = {
    defaultValue: PropTypes.bool,
    disabled: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    small: PropTypes.bool,
    value: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: false,
    disabled: false,
    small: false,
  };

  state = {
    value: typeof this.props.value === 'undefined' ? this.props.defaultValue : this.props.value,
  };

  componentDidUpdate(prevProps) {
    if (typeof this.props.value === 'undefined') {
      return;
    } else if (this.props.value === prevProps.value) {
      return;
    } else if (this.state.value === this.props.value) {
      return;
    }

    this.setState({
      value: this.props.value,
    });
  }

  getValue = () => {
    return this.state.value;
  };

  onToggle = () => {
    const { disabled, onToggle, value } = this.props;
    if (disabled) {
      return;
    }

    onToggle(!this.state.value);

    if (typeof value !== 'undefined') {
      return;
    }

    this.setState({
      value: !this.state.value,
    });
  };

  render() {
    const { value } = this.state;
    const { disabled, small } = this.props;
    return (
      <Tappable onTap={this.onToggle}>
        <div
          className={classnames('toggle', {
            on: value,
            off: !value,
            disabled: disabled,
            small: small,
          })}>
          <div className="toggleCircle" />
        </div>
      </Tappable>
    );
  }
}

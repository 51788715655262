import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import AJAX from 'common/AJAX';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import UserPicker from 'common/user/UserPicker';
import delayer from 'common/util/delayer';
import parseAPIResponse from 'common/util/parseAPIResponse';

import type { CompanyUser } from 'common/api/endpoints/users';

type Props = Omit<
  React.ComponentProps<typeof UserPicker>,
  'onSearch' | 'users' | 'onUserSelected'
> & {
  onUserSelected: (user: CompanyUser) => void;
  userFilter?: (user: CompanyUser) => boolean;
};

const UserDropdown = ({ userFilter = () => true, ...props }: Props) => {
  const showToast = useContext(ShowToastContext);

  const [users, setUsers] = useState<CompanyUser[]>([]);

  const onSearchAfterDelay = useCallback(
    async (value: string) => {
      const response = await AJAX.post('/api/users/get', {
        limit: 10,
        search: value,
      });

      const { error, parsedResponse } = parseAPIResponse<{ result: { users: CompanyUser[] } }>(
        response,
        {
          isSuccessful: (parsedResponse) => Array.isArray(parsedResponse.result?.users),
        }
      );

      if (error) {
        showToast(error.message, ToastTypes.error);
        return;
      } else if (!parsedResponse) {
        return;
      }

      setUsers(parsedResponse.result.users);
    },
    [showToast]
  );

  useEffect(() => {
    // preload users
    onSearchAfterDelay('');

    const delayer = searchDelayer.current;
    return () => {
      delayer.cancel();
    };
  }, [onSearchAfterDelay]);

  const onSearch = (value: string) => {
    searchDelayer.current.callAfterDelay(value);
  };

  const searchDelayer = useRef(new delayer(onSearchAfterDelay, 500));

  const filteredUsers = users.filter(userFilter);

  return <UserPicker {...props} onSearch={onSearch} users={filteredUsers} />;
};

export default UserDropdown;

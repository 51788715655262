import React from 'react';

import classnames from 'classnames';

import Colors from 'common/colors/constants';
import Heart from 'common/reaction/Heart';

import 'css/components/reaction/_ChangelogHeart.scss';

const DefaultColor = Colors.gray70;

export type Props = {
  disabled?: boolean;
  fill?: string;
  stroke?: string;
};

export default function ChangelogHeart({ disabled, fill, stroke }: Props) {
  const className = classnames('likeContainer', { disabled });
  const fillColor = fill ?? DefaultColor;
  const strokeColor = stroke ?? DefaultColor;

  return (
    <div className={className}>
      <Heart fill={fillColor} height={'15'} width={'17'} stroke={strokeColor} />
    </div>
  );
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost, reloadPostByURLName } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { removeItem } from 'common/actions/queueItemQueries';
import { invalidateRoadmap } from 'common/actions/roadmap';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import { invalidateAllQueries as invalidateAllTPCFeatureRequestQueries } from 'common/actions/thirdPartyCompanyFeatureRequestsQueries';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ParamsContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Dropdown from 'common/Dropdown';
import FileRenderer from 'common/file/FileRenderer';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import getValidFileURLs from 'common/file/utils/getValidFileURLs';
import uploadFile from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import MentionsTextarea from 'common/inputs/MentionsTextarea';
import UploadFileButton from 'common/inputs/UploadFileButton';
import AccessModal from 'common/modals/AccessModal';
import DefaultStatuses from 'common/postStatus/DefaultPostStatuses';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import PostStatus from './PostStatus';

import 'css/components/post/_PostStatusDropdown.scss';

const DefaultStatusesNameMap = mapify(DefaultStatuses, 'name');

class PostStatusDropdown extends Component {
  static propTypes = {
    board: PropTypes.object.isRequired,
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    openModal: PropTypes.func,
    params: PropTypes.shape({
      postURLName: PropTypes.string,
    }),
    post: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
    showCustomStatuses: PropTypes.bool,
    viewer: PropTypes.object,
  };

  static defaultProps = {
    showCustomStatuses: true,
  };

  state = {
    commentValue: '',
    error: null,
    files: [],
    loading: false,
    status: this.props.post.status,
    isNotifyVotersChecked: true,
  };

  constructor(props, context) {
    super(props, context);

    this.textAreaRef = React.createRef();
  }

  onCommentChange = (commentValue) => {
    this.setState({
      commentValue,
      error: null,
    });
  };

  onFile = async (file) => {
    const { viewer } = this.props;

    await uploadFile({
      file,
      viewer,
      onFileError: this.onFileError,
      onFileUploading: this.onFileUploading,
      onFileUploaded: this.onFileUploaded,
    });
  };

  onFileError = (file, error) => {
    this.setState((state) => ({
      ...state,
      error,
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFileUploading = (file) => {
    this.setState((state) => ({
      ...state,
      files: [...state.files, file],
    }));
  };

  onFileUploaded = (file) => {
    this.setState((state) => ({
      ...state,
      files: state.files.map((f) => (f.uniqueID === file.uniqueID ? file : f)),
    }));
  };

  onFileRemoved = (file) => {
    this.setState((state) => ({
      ...state,
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onStatusChange = (status) => {
    this.setState({
      error: null,
      status: status,
    });
  };

  onStatusChangeTapped = () => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('changePostStatus', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['changePostStatus'],
      });
      return;
    }
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });

    const { imageURLs, nonImageFileURLs } = getValidFileURLs(this.state.files);
    const response = await AJAX.post('/api/posts/updateStatus', {
      comment: JSON.stringify({
        fileURLs: nonImageFileURLs,
        imageURLs: imageURLs,
        value: this.state.commentValue,
      }),
      notifyVoters: this.state.isNotifyVotersChecked,
      postID: this.props.post._id,
      status: this.state.status,
    });

    const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
    const {
      reloadPost,
      params: { postURLName },
      post,
    } = this.props;

    if (error) {
      this.setState({
        error: error.message,
        loading: false,
      });
      return;
    }

    await reloadPost(post, postURLName ?? post.urlName);

    this.setState({
      commentValue: '',
      files: [],
      loading: false,
    });
  };

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderStatusCommentComposer() {
    const { commentValue, status } = this.state;
    const { board, company, post } = this.props;
    if (status === post.status) {
      return null;
    }

    const acceptedMimeTypes = getAcceptedMimeTypes(company);

    return (
      <Form
        acceptedFileTypes={acceptedMimeTypes}
        addEventsToDocument={false}
        allowFileUpload={true}
        disableSubmit={this.state.loading}
        onFile={this.onFile}
        onSubmit={this.onSubmit}>
        <MentionsTextarea
          autoFocus={true}
          board={board}
          ref={this.textAreaRef}
          onChange={this.onCommentChange}
          placeholder="Add an update comment (optional)"
          value={commentValue}
        />
        {!!this.state.files.length && (
          <div className="fileRendererContainer">
            <FileRenderer
              allowRemove={true}
              files={this.state.files}
              onFileRemoved={this.onFileRemoved}
            />
          </div>
        )}
        <div className="formButtons">
          {this.renderErrorMessage()}
          <div className="buttonsGroup">
            <UploadFileButton
              acceptedMimeTypes={acceptedMimeTypes}
              defaultStyle={false}
              onFileError={this.onFileError}
              onFileUploading={this.onFileUploading}
              onFileUploaded={this.onFileUploaded}
            />
            <Button
              buttonType="cannyButton"
              loading={this.state.loading}
              formButton={true}
              tint={true}
              value="Save"
            />
          </div>
        </div>
      </Form>
    );
  }

  renderBottomContainer() {
    const { isNotifyVotersChecked, status } = this.state;
    const { post } = this.props;
    if (status === post.status) {
      return null;
    }

    return (
      <div className="bottomContainer">
        <CheckboxWithLabel
          aria-label={`Checkbox to toggle notify voters`}
          checked={isNotifyVotersChecked}
          onChange={() =>
            this.setState({ isNotifyVotersChecked: !this.state.isNotifyVotersChecked })
          }
          size="medium">
          Notify all voters
        </CheckboxWithLabel>
      </div>
    );
  }

  render() {
    const { company, post, showCustomStatuses, viewer } = this.props;
    const viewerHasPermission = hasPermission('changePostStatus', company, viewer);
    const options = company.statuses
      .filter((status) => {
        const isDefault = status.name in DefaultStatusesNameMap;
        return showCustomStatuses || isDefault;
      })
      .map((status) => {
        return {
          name: status.name,
          render: <PostStatus showOpen={true} status={status.name} />,
        };
      });

    return (
      <div className="postStatusDropdown">
        <div className="topContainer">
          <div className="detailLabel">Status</div>
          <div className="detailValue">
            <Tooltip
              disabled={viewerHasPermission}
              position="left"
              value={
                'You do not have access to this feature. Please ask one of your teammates to give you permission'
              }>
              <Dropdown
                defaultSelectedName={post.status}
                disabled={!viewerHasPermission}
                dropdownClassName="postStatusDropdownSelector"
                onChange={this.onStatusChange}
                onTap={this.onStatusChangeTapped}
                options={options}
                placeholder={post.status}
              />
            </Tooltip>
          </div>
        </div>
        {this.renderStatusCommentComposer()}
        {this.renderBottomContainer()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post, postURLName) => {
      return Promise.all([
        dispatch(reloadPost(post)),
        dispatch(removeItem(post)),
        dispatch(reloadPostActivity(post)),
        dispatch(reloadPostByURLName(post.board, postURLName)),
        dispatch(reloadRoadmapPostsForRoadmapsWithPost(post)),
        dispatch(invalidatePostQueries()),
        dispatch(invalidateRoadmap()),
        dispatch(invalidateDashboardActivity()),
        dispatch(invalidateAllTPCFeatureRequestQueries()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      params: ParamsContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostStatusDropdown);

import React, { useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import ConfirmModal from 'common/modals/ConfirmModal';
import CustomRoleModal from 'common/modals/CustomRoleModal';
import DefaultCompanyRoleNames from 'common/roles/CompanyRoleNames';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import { H2, P, Span } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import CustomRoles from './CustomRoles';

import type { Company, Role, RolePermissionName } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/AdminMemberSettings/_AdminMemberRoleSettings.scss';

const defaultRoleNames: string[] = Object.values(DefaultCompanyRoleNames);

const upsertRole = async (
  name: string,
  enabledPermissions: RolePermissionName[],
  roleID?: string
) => {
  const isUpdating = !!roleID;
  const url = isUpdating ? `/api/roles/update` : '/api/roles/create';
  const response = await AJAX.post(url, {
    roleID,
    name,
    enabledPermissions,
  });

  const { error } = parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors: {
      'name is empty': 'A valid role name is required',
      'duplicate role name':
        'A role with this name already exists. Please note that role names are case insensitive.',
    },
  });

  return error;
};

const deleteRole = async (roleBeingDeleted: Role) => {
  const response = await AJAX.post('/api/roles/delete', {
    roleID: roleBeingDeleted._id,
  });
  const { error } = parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors: {
      'role has members':
        "Can't delete a role that is still assigned to members. Please remove all members first.",
    },
  });

  return error;
};

const addMemberCount = (company: Company) => (role: Role) => {
  const memberCount = company.members.filter(({ roleID }) => roleID === role._id).length;
  return { ...role, memberCount };
};

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

type DeleteModalState = {
  error: string | null;
  open: boolean;
  role: Role;
  type: 'delete';
};

type EditModalState = {
  error: string | null;
  open: boolean;
  role: Role | null;
  type: 'edit';
};

type ModalState = DeleteModalState | EditModalState;

const RoleSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({
    error: null,
    open: false,
    role: null,
    type: 'edit',
  });

  const { features, roles } = company;

  const defaultRoles = roles
    .filter(({ name }) => defaultRoleNames.includes(name))
    .map(addMemberCount(company))
    .sort((a: Role, b: Role) => b.name.localeCompare(a.name)); // sort it such that owner; manager; contributor

  const customRoles = roles
    .filter(({ name }) => !defaultRoleNames.includes(name))
    .map(addMemberCount(company));

  const beginCreatingRole = () => {
    setModalState({ open: true, role: null, type: 'edit', error: null });
  };

  const beginEditingRole = (role: Role) => {
    setModalState({ open: true, role, type: 'edit', error: null });
  };

  const beginDeletingRole = (role: Role) => {
    setModalState({ open: true, role, type: 'delete', error: null });
  };

  const closeModal = () => {
    setModalState((state) => ({ ...state, open: false, error: null }));
  };

  const onSave = async (
    name: string | undefined,
    enabledPermissions: RolePermissionName[],
    roleID?: string
  ) => {
    if (!name) {
      setModalState((state) => ({ ...state, error: 'A valid role name is required' }));
      return;
    }

    setLoading(true);
    setModalState((state) => ({ ...state, error: null }));
    const error = await upsertRole(name, enabledPermissions, roleID);
    if (error) {
      setModalState((state) => ({ ...state, error: error.message }));
      setLoading(false);
    } else {
      await reloadCompany();
      setLoading(false);
      closeModal();
    }
  };

  const onDeleteConfirm = async () => {
    const { role } = modalState;

    if (!role) {
      return;
    }

    setModalState((state) => ({ ...state, error: null }));
    setLoading(true);
    const error = await deleteRole(role);
    if (error) {
      setModalState((state) => ({ ...state, error: error.message }));
      setLoading(false);
    } else {
      await reloadCompany();
      setLoading(false);
      closeModal();
    }
  };

  if (!features.adminRoles) {
    return (
      <div className="adminMemberRoleSettings">
        <AdminFeatureUpsell cta="Manage teammate permissions" feature="adminRoles" />
      </div>
    );
  }

  return (
    <div className="adminMemberRoleSettings">
      <header className="column">
        <H2 variant="headingMd">Roles</H2>
        <Span className="subheading">
          Create and manage roles with dedicated permissions for your team.
        </Span>
      </header>
      <section className="roles">
        <H2 variant="headingSm">Default</H2>
        <ul className="roleList">
          {defaultRoles.map((role) => (
            <li key={role._id}>
              <Card borderStyle="solid" className="roleWrapperCard">
                <Span>{role.name}</Span>{' '}
                <Span className="roleMemberCount">{`(${role.memberCount} member${
                  role.memberCount === 1 ? '' : 's'
                })`}</Span>
              </Card>
            </li>
          ))}
        </ul>
      </section>
      <CustomRoles
        roles={customRoles}
        onCreateClick={beginCreatingRole}
        onEditClick={beginEditingRole}
        onDeleteClick={beginDeletingRole}
      />
      {modalState.open && modalState.type === 'edit' && (
        <CustomRoleModal
          error={modalState.error}
          onClose={closeModal}
          onSave={onSave}
          role={modalState.role}
          saveLoading={loading}
        />
      )}
      {modalState.open && modalState.type === 'delete' && (
        <ConfirmModal
          closeModal={closeModal}
          message={
            <P>
              Are you sure you want to delete the "{modalState.role.name}" role? This can't be
              undone.
            </P>
          }
          onConfirm={onDeleteConfirm}
          submitButtonType="redButton"
          submitButtonValue="Delete role"
          tint={false}
          useModalPortal
        />
      )}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.team.roles),
    '/admin/settings'
  )
)(RoleSettings) as unknown as React.FC<OwnProps>;

import React from 'react';

import Link from 'common/Link';

import type { Board } from 'common/api/resources/board';

type Props = {
  boardsUsingSegment: Board[];
};

const SegmentInUse = ({ boardsUsingSegment }: Props) => {
  const plural = boardsUsingSegment.length > 1;
  return (
    <>
      In use for privacy for {plural ? 'boards' : 'board'}{' '}
      {boardsUsingSegment
        .map<React.ReactNode>((board) => (
          <>
            "
            <Link to={`/admin/settings/boards/${board.urlName}/privacy`} className="tooltipLink">
              {board.name}
            </Link>
            "
          </>
        ))
        // join to JSX nodes with a comma
        .reduce((prev, curr) => [prev, ', ', curr])}
    </>
  );
};

export default SegmentInUse;

import React, { useCallback, useContext, useEffect, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationChannelSettings from 'common/subdomain/admin/AdminIntegrationChannelSettings';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import { H2, P, Span } from 'common/ui/Text';
import isNil from 'common/util/isNil';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/AdminMicrosoftTeamsSettings/_AdminMicrosoftTeamsSettings.module.scss';

import ChannelConnectionModal from './ChannelConnectionModal';

import type { Board } from 'common/api/endpoints/boards';
import type { Company, MicrosoftTeamsChannel } from 'common/api/endpoints/companies';
import type { MicrosoftTeamsWorkflow } from 'common/api/endpoints/microsoftTeamsWorkflows';

const HelpGuideLink = 'https://help.canny.io/en/articles/4343053-microsoft-teams-integration';

const Info = () => (
  <div>
    <H2 variant="headingMd">Send notifications to channels in Microsoft Teams</H2>
    <P>
      Add a workflow to your Teams channel to get notified on Canny events. Check out our{' '}
      <a
        className={styles.link}
        href={HelpGuideLink}
        target="_blank"
        rel="nofollow noreferrer noopener">
        setup guide
      </a>{' '}
      for more&nbsp;info.
    </P>
  </div>
);

enum Modal {
  channelConnection = 'channelConnection',
}

type OwnProps = { boards: Board[] };
type ConnectProps = { reloadCompany: () => void };

const AdminMicrosoftTeamsSettings = ({ boards, reloadCompany }: OwnProps & ConnectProps) => {
  const { integrationLimit, integrationCount } = useContext(ActiveIntegrationContext);
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  const [modal, setModal] = useState<Modal | null>(null);
  const [workflows, setWorkflows] = useState<MicrosoftTeamsWorkflow[]>([]);

  const fetchWorkflows = useCallback(async () => {
    const response = await AJAX.post('/api/microsoftTeams/workflows/list');
    const { parsedResponse, error } = parseAPIResponse<MicrosoftTeamsWorkflow[]>(response, {
      isSuccessful: (parsedResponse) => Array.isArray(parsedResponse),
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    } else if (!parsedResponse) {
      showToast('No workflows found', ToastTypes.error);
      return;
    }

    setWorkflows(parsedResponse);
  }, [showToast]);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const createWorkflow = async (workflow: Pick<MicrosoftTeamsWorkflow, 'name' | 'webhookURL'>) => {
    const response = await AJAX.post('/api/microsoftTeams/workflows/create', {
      name: workflow.name,
      webhookURL: workflow.webhookURL,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    fetchWorkflows();
  };

  // upsell integration
  const isInstalled = company.microsoftTeamsChannels.length > 0 || workflows.length > 0;
  const isWithinIntegrationLimit = isNil(integrationLimit) || integrationCount < integrationLimit;
  const hasFeature = !!company.integrations?.microsoftTeams;
  if (!isInstalled && (!isWithinIntegrationLimit || !hasFeature)) {
    return (
      <div>
        <Helmet title="Microsoft Teams Integration | Canny" />
        <h1 className="settingsHeading">Microsoft Teams Integration</h1>
        <div className={styles.content}>
          <Info />
          <div className={styles.upsell}>
            {!isWithinIntegrationLimit ? (
              <AdminIntegrationLimitUpsell />
            ) : (
              <AdminFeatureUpsell
                cta="Get notified on Canny&nbsp;events"
                feature="microsoftTeams"
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet title="Microsoft Teams Integration | Canny" />
      <h1 className="settingsHeading">Microsoft Teams Integration</h1>
      <div className={styles.content}>
        <Info />
        {company.microsoftTeamsChannels.length ? (
          <Alert
            className={styles.alert}
            type={AlertTypes.Danger}
            headingText="Some of your connections need to be reinstalled"
            subText={
              <Span>
                Microsoft is dropping support for our Microsoft Teams integration framework. Some of
                your channels need to be reinstalled. Please follow{' '}
                <a
                  className={styles.link}
                  href={HelpGuideLink}
                  target="_blank"
                  rel="nofollow noreferrer noopener">
                  our guide
                </a>{' '}
                to reinstall your&nbsp;channels.
              </Span>
            }
          />
        ) : null}
        <ButtonV2
          className={styles.createButton}
          onClick={() => setModal(Modal.channelConnection)}
          size="medium"
          color="primary"
          variant="contained">
          Connect a new channel
        </ButtonV2>
        {modal === Modal.channelConnection ? (
          <ChannelConnectionModal
            onSave={(workflow: Pick<MicrosoftTeamsWorkflow, 'name' | 'webhookURL'>) => {
              createWorkflow(workflow);
              setModal(null);
            }}
            onCancel={() => setModal(null)}
          />
        ) : null}
        <div className={styles.connectionList}>
          {workflows.map((workflow) => (
            <AdminIntegrationChannelSettings
              boards={boards}
              channel={workflow}
              integration={{
                deleteURL: '/api/microsoftTeams/workflows/delete',
                editURL: '/api/microsoftTeams/workflows/update',
                getChannelHeading: (workflow: MicrosoftTeamsWorkflow) => workflow.name,
                reload: fetchWorkflows,
              }}
              key={workflow._id}
            />
          ))}
          {company.microsoftTeamsChannels.map((channel) => (
            <AdminIntegrationChannelSettings
              boards={boards}
              channel={channel}
              integration={{
                deleteURL: '/api/microsoftTeams/deleteChannel',
                editURL: '/api/microsoftTeams/updateChannel',
                getChannelHeading: (channel: MicrosoftTeamsChannel) => {
                  const name =
                    channel.teamName && channel.channelName
                      ? `${channel.teamName} : ${channel.channelName}`
                      : `Microsoft Teams connected channel`;

                  return (
                    <div className={styles.oldChannelHeading}>
                      <Span fontWeight="semibold">{name}</Span>
                      <Pill pillStyle={DefaultPillStyles.destructive}>Needs to be reinstalled</Pill>
                    </div>
                  );
                },
                reload: reloadCompany,
              }}
              key={channel._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.integrations.microsoftTeams),
    '/admin/settings'
  )
)(AdminMicrosoftTeamsSettings) as unknown as React.FC<OwnProps>;

import React from 'react';

import { compose } from 'redux';

import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import { CommunicationIntegrations } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

export const sections = [
  {
    integrations: CommunicationIntegrations,
  },
];

const AdminIntegrationSettingsCommunication = () => {
  return (
    <AdminIntegrationSettings
      settingsTitle="Communication"
      settingsDescription="Manage all customer conversations in one place with communication tools. These integrations help maintain clear, consistent interactions across channels."
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.communication),
    '/admin/settings'
  )
)(AdminIntegrationSettingsCommunication) as unknown as React.FC;

import React from 'react';

import classnames from 'classnames';
import { AlertCircle } from 'lucide-react';

import { type PostSummary, PostSummaryStatus } from 'common/api/resources/posts';
import MenuButton from 'common/post/Summary/MenuButton';
import SummaryContent from 'common/post/Summary/SummaryContent';
import SpinnerV2 from 'common/SpinnerV2';
import Timestamp from 'common/Timestamp';
import { H2, P } from 'common/ui/Text';
import styles from 'css-module/components/post/_Summary.module.scss';

type SummaryProps = {
  className?: string;
  summary: PostSummary;
  resummarize?: () => Promise<void> | null;
  resummarizing?: boolean;
  deleteSummary?: () => Promise<void> | null;
  deleting?: boolean;
};

const SummaryUI = ({
  className,
  summary,
  resummarize,
  resummarizing = false,
  deleteSummary,
  deleting = false,
}: SummaryProps) => {
  // loading state
  if (summary.status === PostSummaryStatus.pending) {
    return (
      <div className={classnames(styles.summary, styles.loading, className)}>
        <SpinnerV2 size="large" />
        <P>Your summary is being generated! Check back in a few&nbsp;minutes.</P>
      </div>
    );
  }

  // error state
  if (summary.status === PostSummaryStatus.failed) {
    return (
      <div className={classnames(styles.summary, styles.error, className)}>
        <AlertCircle className={styles.icon} size={18} />
        <section className={styles.errorContent}>
          <P className={styles.content}>
            An issue occurred while rendering the comment summary. Please try
            re-summarizing&nbsp;again.
          </P>
          <footer className={styles.footer}>
            {(resummarize || resummarizing) && (
              <MenuButton
                value="Re-summarize"
                onClick={resummarize}
                disabled={deleting || resummarizing}
                loading={resummarizing}
              />
            )}
            {(deleteSummary || deleting) && (
              <>
                <div className={styles.middot}>&middot;</div>
                <MenuButton
                  value="Delete"
                  onClick={deleteSummary}
                  disabled={deleting || resummarizing}
                  loading={deleting}
                />
              </>
            )}
          </footer>
        </section>
      </div>
    );
  }

  return (
    <div className={classnames(styles.summary, className)}>
      <header>
        <H2 variant="headingMd">Comment summary</H2>
      </header>
      <div className={styles.content}>
        <SummaryContent summary={summary.summary} />
      </div>
      <footer className={styles.footer}>
        <Timestamp className={styles.timestamp} timestamp={summary.updatedAt} />
        {(resummarize || resummarizing) && (
          <>
            <div className={styles.middot}>&middot;</div>
            <MenuButton
              value="Re-summarize"
              onClick={resummarize}
              disabled={deleting || resummarizing}
              loading={resummarizing}
            />
          </>
        )}
        {(deleteSummary || deleting) && (
          <>
            <div className={styles.middot}>&middot;</div>
            <MenuButton
              value="Delete"
              onClick={deleteSummary}
              disabled={deleting || resummarizing}
              loading={deleting}
            />
          </>
        )}
      </footer>
    </div>
  );
};

export default SummaryUI;

import type { StrippedUser } from 'common/api/endpoints/users';
import type { Board } from 'common/api/resources/board';
import type { Comment } from 'common/api/resources/comments';
import type { Post } from 'common/api/resources/posts';

type InboxComment = Comment & {
  author: StrippedUser<Record<string, unknown>>;
  board?: Board;
  itemType: 'comment';
  post: InboxPost & { board?: Board };
  spamScore: number;
};

type InboxPost = Post & {
  author?: StrippedUser<Record<string, unknown>>;
  board?: Board;
  itemType: 'post';
  spamScore: number;
};

type InboxItem = InboxComment | InboxPost;

enum ItemType {
  all = 'all',
  comment = 'comment',
  post = 'post',
}

enum Confidence {
  lowConfidence = 'lowConfidence',
  highConfidence = 'highConfidence',
}

enum SortOptions {
  newest = 'newest',
  oldest = 'oldest',
}

export type { InboxComment, InboxItem, InboxPost };
export { Confidence, ItemType, SortOptions };

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadRoadmapPostsForRoadmap } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import Link from 'common/Link';
import Spinner from 'common/Spinner';
import { findLastActiveFilter } from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapViewSettingsModal/SavedViewUtils';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AdminRoadmapsFormPostSidebarSection from './AdminRoadmapsFormPostSidebarSection';
import AdminSidebarSection from './AdminSidebarSection';

import 'css/components/subdomain/admin/_AdminRoadmapsPostSidebarSection.scss';

class AdminRoadmapsPostSidebarSection extends Component {
  static propTypes = {
    post: PropTypes.shape({
      roadmapPosts: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          roadmapID: PropTypes.string,
        })
      ),
    }),
    roadmaps: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        urlName: PropTypes.string,
      })
    ),
  };

  state = {
    error: null,
    formOpen: false,
    removingID: false,
  };

  onRemoveRoadmapPost = async (roadmapPost, roadmap) => {
    const { post, reload } = this.props;
    const { removingID } = this.state;
    if (removingID) {
      return;
    }

    this.setState({ removingID: roadmapPost._id });

    const response = await AJAX.post('/api/roadmaps/posts/remove', {
      roadmapPostID: roadmapPost._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({
        error: error.message,
        removingID: null,
      });
      return;
    }

    await reload(post, roadmap);
    this.setState({
      error: null,
      removingID: null,
    });
  };

  onToggleTypeahead = () => {
    this.setState((state) => ({
      formOpen: !state.formOpen,
    }));
  };

  renderAction = () => {
    return (
      <Tappable onTap={this.onToggleTypeahead}>
        <div>Add to roadmap</div>
      </Tappable>
    );
  };

  renderForm() {
    const { post, roadmaps } = this.props;
    const { formOpen } = this.state;
    if (!formOpen) {
      return null;
    }

    return <AdminRoadmapsFormPostSidebarSection post={post} roadmaps={roadmaps} />;
  }

  renderRoadmaps = () => {
    const { post, roadmaps } = this.props;
    const { removingID } = this.state;

    return post.roadmapPosts.map((roadmapPost) => {
      const isLoading = roadmapPost._id === removingID;
      const roadmap = roadmaps.find(
        (roadmap) => roadmapPost.roadmapID === roadmap._id && !roadmap.archived
      );

      if (!roadmap) {
        return null;
      }

      const lastViewedFilter = findLastActiveFilter(roadmap.settings, roadmap.savedViews);
      const link = `/admin/roadmap/${roadmap.urlName}${lastViewedFilter ?? ''}`;
      const remove = (
        <Tappable onTap={() => this.onRemoveRoadmapPost(roadmapPost, roadmap)}>
          <div className="icon icon-x" />
        </Tappable>
      );
      return (
        <div key={roadmap._id} className="roadmapItem">
          <Link to={link}>{roadmap.name}</Link>
          {isLoading ? <Spinner /> : remove}
        </div>
      );
    });
  };

  renderError = () => {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  };

  render() {
    const { post, roadmaps } = this.props;

    if (!roadmaps?.length || !post || post.error) {
      return null;
    }

    return (
      <AdminSidebarSection
        action={this.renderAction()}
        className="adminRoadmapsPostSidebarSection"
        title="Roadmaps">
        {this.renderForm()}
        <div className="roadmapList">{this.renderRoadmaps()}</div>
        {this.renderError()}
      </AdminSidebarSection>
    );
  }
}

export default connect(null, (dispatch) => ({
  reload: (post, roadmap) => {
    return Promise.all([
      dispatch(invalidatePostQueries()),
      dispatch(reloadPost(post)),
      dispatch(reloadRoadmapPostsForRoadmap(roadmap)),
    ]);
  },
}))(AdminRoadmapsPostSidebarSection);

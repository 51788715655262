import React, { Component } from 'react';

import PropTypes from 'prop-types';

import isLeftClickEvent from './isLeftClickEvent';
import isModifiedEvent from './isModifiedEvent';
import Tappable from './Tappable';

export default class ExternalLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  onTap = (e) => {
    if (isModifiedEvent(e) || !isLeftClickEvent(e)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    window.open(this.props.to, '_system', 'noopener');
  };

  onClick = (e) => {
    if (isModifiedEvent(e) || !isLeftClickEvent(e)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { children, className, to } = this.props;
    return (
      <Tappable onTap={this.onTap}>
        <a className={className} href={to} onClick={this.onClick}>
          {children}
        </a>
      </Tappable>
    );
  }
}

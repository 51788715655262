import AdminOnboarding from './AdminOnboarding';

export { default as AdminOnboardingWhat } from './AdminOnboardingWhat';
export { default as AdminOnboardingHow } from './AdminOnboardingHow';
export { default as AdminOnboardingWhere } from './AdminOnboardingWhere';
export { default as AdminOnboardingSource } from './AdminOnboardingSource';
export { default as AdminOnboardingCreateBoard } from './AdminOnboardingCreateBoard';
export { default as AdminOnboardingInviteTeam } from './AdminOnboardingInviteTeam';
export { default as AdminOnboardingRedirect } from './AdminOnboardingRedirect';

export default AdminOnboarding;

import React, { useContext, useState } from 'react';

import { AlertCircle, ArrowRight } from 'lucide-react';

import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Helmet from 'common/helmets/Helmet';
import TextInput from 'common/inputs/TextInput';
import Link from 'common/Link';
import withAccessControl from 'common/routing/withAccessControl';
import ButtonV2 from 'common/ui/ButtonV2';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import { H1, H2, P, Span } from 'common/ui/Text';
import isFree from 'common/util/isFree';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/AdminGeneralSettings/_AdminGeneralSettingsDelete.module.scss';

import type { Company } from 'common/api/endpoints/companies';

const ConfirmationCheckboxCopy =
  'I acknowledge that all of the workspace data will be deleted and want to proceed.';

const AdminGeneralSettingsDeletion = () => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const showIntercom = useContext(ShowIntercomContext);
  const viewer = useContext(ViewerContext);
  const [loading, setLoading] = useState<boolean>(false);

  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');

  const hasPlanData = company.billingData?.plan;
  const companyHasActiveSubscription =
    hasPlanData && !isFree(company.billingData?.plan.planID ?? null);

  const onDeleteWorkspace = async () => {
    if (!acknowledged || companyName !== company.name) {
      showToast(
        'Please acknowledge the deletion and enter the correct company name',
        ToastTypes.error
      );
    }
    setLoading(true);

    try {
      const response = await AJAX.post('/api/company/delete', {
        companyID: company._id,
        name: companyName,
      });
      const { error } = parseAPIResponse(response, {
        errors: {
          'not authorized': 'You are not authorized to delete this workspace',
          'company is paying':
            'You cannot delete your workspace with an active subscription. Please cancel your subscription first.',
        },
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
      } else {
        showToast(
          'Workspace deleted. Redirecting you to the Canny homepage in 3 seconds...',
          ToastTypes.success
        );
        setTimeout(() => {
          window.location.href = 'https://canny.io';
        }, 3000);
      }
    } catch (error) {
      showToast(
        'Failed to delete workspace. Reach out to support for assistance.',
        ToastTypes.error
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.adminGeneralSettingsDelete}>
      <Helmet title="Delete | Company Settings | Canny" />
      <H1 variant="headingMd">Workspace Deletion</H1>
      <P>
        You are about to delete your Canny Workspace{' '}
        <Span fontWeight="medium">{company.name}.</Span> This will permanently delete all your data,
        including:
        <br />
        <br />
        <ul className={styles.deletionList}>
          <li>{company.postCount} posts</li>
          <li>{company.stats.voteCount} votes</li>
          <li>{company.userCount} users</li>
          <li>{company.stats.commentCount} comments</li>
        </ul>
      </P>
      <P>
        If you only want to delete your current account ({viewer.email}), please{' '}
        <Link className={styles.link} fakeLink={true} onTap={() => showIntercom()} to="/">
          contact support
        </Link>{' '}
        or ask another owner to remove you from this workspace.
      </P>
      <P>
        To delete your workspace, type the workspace name below and check the box to confirm. This
        action is irreversible.
      </P>
      <TextInput
        id="companyName"
        aria-label="Enter this workspace's name to confirm"
        value={companyName}
        placeholder="Enter workspace name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.currentTarget.value)}
      />
      <CheckboxWithLabel
        aria-label={ConfirmationCheckboxCopy}
        size="medium"
        onChange={(checked) => setAcknowledged(checked)}>
        <Span fontWeight="medium">{ConfirmationCheckboxCopy}</Span>
      </CheckboxWithLabel>
      <Tooltip
        disabled={!companyHasActiveSubscription}
        value="You can't delete your company whilst you have an active subscription. Please reach out to support.">
        <ButtonV2
          color="error"
          disabled={!acknowledged || companyName !== company.name || companyHasActiveSubscription}
          loading={loading}
          onClick={onDeleteWorkspace}
          size="medium">
          Delete workspace
        </ButtonV2>
      </Tooltip>
      {companyHasActiveSubscription ? (
        <div className={styles.warningCard}>
          <AlertCircle className={styles.warningIcon} />
          <div className={styles.warningCardContent}>
            <H2 variant="headingSm" className={styles.warningHeader}>
              Active subscription
            </H2>
            <P className={styles.warningBody}>
              You have an active subscription. Please contact support to cancel your subscription
              before deleting your workspace.
            </P>
            <ButtonV2
              endIcon={ArrowRight}
              onClick={() => showIntercom()}
              size="medium"
              variant="plain">
              Contact support
            </ButtonV2>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withAccessControl(
  testEveryPermission(RoutePermissions.adminSettings.general.delete),
  '/admin/settings'
)(AdminGeneralSettingsDeletion);

import Data from '../Data';

// Action Types

export const INVALIDATE = 'canny/users/invalidate';
export const REQUEST_USER = 'canny/users/request_user';
export const USER_LOADED = 'canny/users/user_loaded';
export const USER_NOT_FOUND = 'canny/users/user_not_found';
export const USER_ERROR = 'canny/users/user_error';

// Actions

function requestUser(urlName) {
  return {
    timestamp: Date.now(),
    type: REQUEST_USER,
    urlName,
  };
}

export function userLoaded(urlName, user) {
  return {
    timestamp: Date.now(),
    type: USER_LOADED,
    urlName,
    user,
  };
}

function userNotFound(urlName) {
  return {
    timestamp: Date.now(),
    type: USER_NOT_FOUND,
    urlName,
  };
}

function userError(urlName, error) {
  return {
    error,
    timestamp: Date.now(),
    type: USER_ERROR,
    urlName,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: INVALIDATE,
  };
}

// Action Creators

function fetchUser(urlName) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        user: {
          input: {
            urlName,
          },
          query: Data.queries.user,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, getState(), urlName, error, data);
      }
    );
  };
}

export function loadUser(urlName) {
  return (dispatch, getState) => {
    if (shouldFetchUser(getState(), urlName)) {
      dispatch(requestUser(urlName));
      return dispatch(fetchUser(urlName));
    } else if (isFetchingUser(getState(), urlName)) {
      return waitForResult(urlName);
    }
  };
}

export function reloadUser(urlName) {
  return (dispatch) => {
    return dispatch(fetchUser(urlName));
  };
}

export function invalidateUsers() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions

function shouldFetchUser(state, urlName) {
  const users = state.users;
  return !users.hasOwnProperty(urlName);
}

function isFetchingUser(state, urlName) {
  if (!urlName) {
    return false;
  }

  return !!state.users[urlName].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(urlName) {
  return new Promise((resolve, reject) => {
    resultCallbacks[urlName] = resultCallbacks[urlName] || [];
    resultCallbacks[urlName].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, state, urlName, error, data) {
  var resultAction;
  if (error === 'not found') {
    resultAction = userNotFound(urlName);
  } else if (error) {
    resultAction = userError(urlName, error);
  } else {
    resultAction = userLoaded(urlName, data.user);
  }

  return Promise.all([dispatch(resultAction)]).then(() => {
    if (!resultCallbacks[urlName]) {
      return;
    }

    resultCallbacks[urlName].forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks[urlName].length = 0;
  });
}

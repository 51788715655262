import React, { useCallback, useContext, useState } from 'react';

import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import TextToggle from 'common/common/TextToggle';
import { RevenueTimeframes } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import useDelayer from 'common/util/useDelayer';
import styles from 'css-module/components/subdomain/admin/_AdminCompanyFieldsSettings.module.scss';

import type { Company } from 'common/api/endpoints/companies';

type Props = {
  reloadCompany: () => Promise<void>;
};

enum Modal {
  'confirmSyncRevenue' = 'confirmSyncRevenue',
}

const flipTimeframe = (timeframe: RevenueTimeframes) => {
  return {
    [RevenueTimeframes.arr]: RevenueTimeframes.mrr,
    [RevenueTimeframes.mrr]: RevenueTimeframes.arr,
  }[timeframe];
};

const RevenuePreferencesSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  const [modal, setModal] = useState<Modal | null>(null);
  const [revenueRenderTimeframe, setRevenueRenderTimeframe] = useState<RevenueTimeframes>(
    company.revenueTimeframe
  );

  const [revenueSyncTimeframe, setRevenueSyncTimeframe] = useState<RevenueTimeframes>(
    company.revenueSyncTimeframe
  );

  const onRevenueRenderTimeframeChange = useCallback(
    async (updatedRevenueRenderTimeframe: RevenueTimeframes) => {
      setRevenueRenderTimeframe(updatedRevenueRenderTimeframe);

      const response = await AJAX.post('/api/company/changeSettings', {
        revenueTimeframe: updatedRevenueRenderTimeframe,
      });

      const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
      if (error) {
        setRevenueRenderTimeframe(company.revenueTimeframe);
        showToast(error.message, ToastTypes.error);
      }

      reloadCompany();
    },
    [reloadCompany, company.revenueTimeframe, showToast]
  );

  const onRevenueSyncTimeframeChange = useCallback(
    async (updatedRevenueSyncTimeframe: RevenueTimeframes) => {
      setRevenueSyncTimeframe(updatedRevenueSyncTimeframe);

      const response = await AJAX.post('/api/company/changeSettings', {
        revenueSyncTimeframe: updatedRevenueSyncTimeframe,
      });

      const { error } = parseAPIResponse(response, { isSuccessful: isDefaultSuccessResponse });
      if (error) {
        setRevenueSyncTimeframe(company.revenueSyncTimeframe);
        showToast(error.message, ToastTypes.error);
      }

      reloadCompany();
    },
    [reloadCompany, company.revenueSyncTimeframe, showToast]
  );

  const revenueRenderTimeframeDelayer = useDelayer(onRevenueRenderTimeframeChange, 500);
  const revenueSyncTimeframeDelayer = useDelayer(onRevenueSyncTimeframeChange, 500);

  return (
    <section className={styles.revenuePreferencesContainer}>
      <P variant="headingSm">Additional Settings</P>
      <div className={styles.revenuePreferenceItem}>
        <P>
          Are you collecting MRR (monthly recurring revenue) or ARR (annual recurring
          revenue)&nbsp;data?
        </P>
        <TextToggle
          onChange={(updatedRevenueSyncTimeframe) => {
            if (updatedRevenueSyncTimeframe === revenueSyncTimeframe) {
              return;
            }

            setModal(Modal.confirmSyncRevenue);
          }}
          options={[
            { value: RevenueTimeframes.mrr, label: 'MRR' },
            { value: RevenueTimeframes.arr, label: 'ARR' },
          ]}
          selected={revenueSyncTimeframe}
        />
      </div>
      <div className={styles.revenuePreferenceItem}>
        <P>Should Canny render the data as MRR or&nbsp;ARR?</P>
        <TextToggle
          onChange={(updatedRevenueRenderTimeframe) => {
            if (updatedRevenueRenderTimeframe === revenueRenderTimeframe) {
              return;
            }

            setRevenueRenderTimeframe(updatedRevenueRenderTimeframe);
            revenueRenderTimeframeDelayer(updatedRevenueRenderTimeframe);
          }}
          options={[
            { value: RevenueTimeframes.mrr, label: 'MRR' },
            { value: RevenueTimeframes.arr, label: 'ARR' },
          ]}
          selected={revenueRenderTimeframe}
        />
      </div>
      {modal === Modal.confirmSyncRevenue && (
        <ModernConfirmModal
          onClose={() => setModal(null)}
          onConfirm={() => {
            setRevenueSyncTimeframe(flipTimeframe(revenueSyncTimeframe));
            setModal(null);
            revenueSyncTimeframeDelayer(flipTimeframe(revenueSyncTimeframe));
          }}
          confirmText={`Switch to ${flipTimeframe(revenueSyncTimeframe).toUpperCase()}`}
          header={`Are you sure you want to switch to ${flipTimeframe(
            revenueSyncTimeframe
          ).toUpperCase()}?`}>
          <P>
            This change will not be visible instantly. Values will be updated as the fields
            are&nbsp;re-synced.
          </P>
        </ModernConfirmModal>
      )}
    </section>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  }))
)(RevenuePreferencesSettings) as unknown as React.FC<unknown>;

import React, { useContext } from 'react';

import classnames from 'classnames';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { invalidateRoadmap } from 'common/actions/roadmap';
import {
  reloadRoadmapPostsForRoadmapsWithPost,
  removePostFromAllRoadmaps,
} from 'common/actions/roadmapPosts';
import { BoardsContext } from 'common/containers/BoardsContainer';
import RoadmapPostContainer from 'common/containers/RoadmapPostContainer';
import { RoadmapsContext } from 'common/containers/RoadmapsContainer';
import connect from 'common/core/connect';
import ModalPortal from 'common/modals/ModalPortal';
import AdminFeedbackPost from 'common/subdomain/admin/AdminFeedbackPost';

import type { Board } from 'common/api/endpoints/boards';
import type { PostWithVoteWeights } from 'common/api/endpoints/thirdPartyCompanyFeatureRequests';

type OwnProps = {
  post: PostWithVoteWeights;
  onClose: () => void;
  onEdited: (post: PostWithVoteWeights) => void;
};

type ConnectProps = {
  removePost: (post: PostWithVoteWeights, board: Board) => void;
  reloadPost: (post: PostWithVoteWeights & { board: Board }) => void;
};

const PostDrawer = ({
  post,
  onClose,
  onEdited,
  removePost,
  reloadPost,
}: OwnProps & Partial<ConnectProps>) => {
  const boards = useContext(BoardsContext);
  const { roadmaps } = useContext(RoadmapsContext);

  if (!Array.isArray(boards)) {
    return null;
  }

  const board = boards.find((board) => post.boardID === board._id);
  return (
    <ModalPortal
      allowBodyScroll
      allowEscape
      className={classnames('adminQueueDrawer', 'postModal', 'overlapNav', {
        modalVisible: !!post,
      })}
      onClose={onClose}>
      <div className="modalContents">
        <RoadmapPostContainer boards={boards} post={post}>
          <AdminFeedbackPost
            key={post.urlName}
            customPostFields={[]}
            onClose={onClose}
            onDelete={() => {
              if (!board) {
                return;
              }

              removePost?.(post, board);
              onEdited(post);
              onClose();
            }}
            onEdited={() => {
              if (!board) {
                return;
              }

              reloadPost?.({ ...post, board });
              onEdited(post);
              onClose();
            }}
            roadmaps={roadmaps}
            skipFixURL={true}
          />
        </RoadmapPostContainer>
      </div>
    </ModalPortal>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadPost: (post: PostWithVoteWeights & { board: Board }) => {
    return Promise.all([
      dispatch(invalidatePostQueries()),
      dispatch(reloadPost(post)),
      dispatch(reloadRoadmapPostsForRoadmapsWithPost(post)),
    ]);
  },
  removePost: (post: PostWithVoteWeights, board: Board) => {
    return Promise.all([
      dispatch(reloadBoard(board.urlName)),
      dispatch(invalidateDashboardActivity()),
      dispatch(invalidateRoadmap()),
      dispatch(invalidateSuggestions()),
      dispatch(removePostFromAllRoadmaps(post)),
      dispatch(invalidatePostQueries()),
    ]);
  },
}))(PostDrawer);

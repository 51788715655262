import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Pill from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import translateString from 'common/i18n/translateString';
import { DefaultColors as DefaultPostStatusColors } from 'common/postStatus/PostStatusColors';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import withContexts from 'common/util/withContexts';

import UppercaseHeader from '../UppercaseHeader';

import 'css/components/post/_PostStatusV2.scss';

const isStatusObject = (status) => {
  return !!status?.name;
};

class PostStatusV2 extends Component {
  static propTypes = {
    className: PropTypes.string,
    company: PropTypes.shape({
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          color: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    isBadge: PropTypes.bool,
    showOpen: PropTypes.bool,
    status: PropTypes.oneOfType([
      PropTypes.string, // either the status name
      PropTypes.shape({
        // or the full status object
        _id: PropTypes.string,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    ]).isRequired,
  };

  static defaultProps = {
    showOpen: false,
  };

  render() {
    const { className, company, isBadge, showOpen } = this.props;

    const status = isStatusObject(this.props.status)
      ? this.props.status
      : company.statuses.find((status) => status.name === this.props.status);
    if (!status) {
      const styling = isBadge
        ? { background: DefaultPostStatusColors.Open }
        : { color: DefaultPostStatusColors.Open };
      return (
        <UppercaseHeader className={classnames('postStatus', className)} style={styling}>
          {this.props.status.replace(' ', '\u00a0')}
        </UppercaseHeader>
      );
    }

    if (status.type === PostStatusTypes.Initial && !showOpen) {
      return null;
    }

    if (isBadge) {
      const pillStyle = {
        color: status.color,
        background: `${status.color}20`,
      };

      return (
        <Pill className="postStatusV2" pillStyle={pillStyle}>
          {translateString(status, 'name').replace(' ', '\u00a0')}
        </Pill>
      );
    }

    return (
      <div className="postStatusV2" style={{ color: status.color }}>
        {translateString(status, 'name').replace(' ', '\u00a0')}
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(PostStatusV2);

import React, { Component } from 'react';

import classnames from 'classnames';
import * as linkify from 'linkifyjs';
import { ExternalLink } from 'lucide-react';
import PropTypes from 'prop-types';
import deepCompare from 'react-fast-compare';

import Tooltip from 'common/common/Tooltip';
import { CustomFieldTypes, CustomFieldTypesList } from 'common/customPostFields/Constants';
import CopyButtonV2 from 'common/inputs/CopyButtonV2';
import Markdown from 'common/markdown/Markdown';
import { MarkdownStagesNameMap } from 'common/markdown/MarkdownStages';
import UpsellModal from 'common/modals/UpsellModal';
import CustomPostFieldInput, { getErrorMessages } from 'common/post/CustomPostFieldInput';
import Tappable from 'common/Tappable';
import IconButtonV2 from 'common/ui/IconButtonV2';

import 'css/components/post/_EditableCustomPostField.scss';

const Upsell = {
  editPostFieldValue: 'editPostFieldValue',
};

export default class EditableCustomPostField extends Component {
  static propTypes = {
    customPostField: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.oneOf(CustomFieldTypesList),
    }).isRequired,
    className: PropTypes.string,
    errored: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    showUpsell: PropTypes.bool,
  };

  static defaultProps = {
    showUpsell: false,
  };

  state = {
    editing: false,
    error: null,
    upsell: null,
    value: this.props.customPostField.value,
  };

  onSubmit = async () => {
    const { customPostField, onSubmit } = this.props;
    const { error, value } = this.state;
    if (error) {
      return;
    }

    this.setState({ editing: false });

    await onSubmit(value, customPostField);
  };

  onChange = (value, customPostField) => {
    const { errorMessages } = getErrorMessages({ [customPostField._id]: value }, [customPostField]);
    if (errorMessages.length) {
      this.setState({ error: errorMessages[0] });
      return;
    }

    this.setState({ error: null, value }, async () => {
      // dropdown fields are resubmitted on every change
      if (customPostField.type === CustomFieldTypes.dropdown.name) {
        await this.onSubmit();
      }
    });
  };

  onEdit = () => {
    const { showUpsell } = this.props;
    if (showUpsell) {
      this.setState({ upsell: Upsell.editPostFieldValue });
      return;
    }

    this.setState({ editing: true });
  };

  onBlur = (customPostField) => {
    if (!deepCompare(customPostField.value, this.state.value)) {
      this.onSubmit();
    }
  };

  getValue = () => {
    const {
      customPostField: { placeholder, value },
    } = this.props;

    const renderValue = this.state.value || value || placeholder || '—';
    if (Array.isArray(renderValue)) {
      return renderValue.join(', ');
    }

    return renderValue;
  };

  renderUpsell = () => {
    const { upsell } = this.state;
    if (!upsell) {
      return null;
    }

    return (
      <UpsellModal
        feature={upsell}
        onClose={() => this.setState({ upsell: null })}
        onUpsell={() => this.setState({ upsell: null })}
        show={true}
      />
    );
  };

  render() {
    const { customPostField, showUpsell } = this.props;
    const { editing, error } = this.state;
    if (!customPostField) {
      return null;
    }

    const { value } = customPostField;
    const fullType = CustomFieldTypes[customPostField.type];
    const renderValue = this.getValue();
    // by default, we render a button to enter in editing mode
    if (!editing && !fullType.hasOptions) {
      const description = `Click on the button to edit the field. The field contains: ${
        value || 'Empty'
      }`;
      const [link] = linkify.find(renderValue);
      const valueIsLink = link && link.value === renderValue.trim();

      return (
        <div
          className={classnames('editableCustomPostField', customPostField.type, {
            empty: !value,
          })}>
          <Tooltip position="top" delay={1000} value={renderValue}>
            <Tappable triggerOnEnter={true} onTap={this.onEdit}>
              <button
                aria-description={description} // This attribute throws a warning on dev mode. It was fixed in React v18: https://github.com/facebook/react/pull/22142
                aria-label={customPostField.name}
                className="editingButton">
                <Markdown
                  contents={renderValue}
                  stages={[MarkdownStagesNameMap.line, MarkdownStagesNameMap.url]}
                />
              </button>
            </Tappable>
          </Tooltip>
          {this.renderUpsell()}
          {value && <CopyButtonV2 className="customPostFieldIcon" value={renderValue} />}
          {valueIsLink && (
            <a
              href={link.href}
              rel="nofollow noreferrer noopener"
              target="_blank"
              className="customPostFieldIcon">
              <IconButtonV2
                icon={ExternalLink}
                size="small"
                aria-label="link icon"
                variant="outlined"
              />
            </a>
          )}
        </div>
      );
    }

    return (
      <div
        className={classnames('editableCustomPostField', customPostField.type, { editing })}
        onBlur={() => this.onBlur(customPostField)}>
        <Tooltip disabled={!fullType.hasOptions} position="top" delay={1000} value={renderValue}>
          <CustomPostFieldInput
            autoFocus={true}
            disabled={showUpsell}
            errored={!!error}
            onChange={this.onChange}
            postField={customPostField}
            id={customPostField._id}
            truncate={true}
          />
        </Tooltip>
        {this.renderUpsell()}
      </div>
    );
  }
}

import Message from 'common/message/Message';
import devURL from 'common/util/devURL';
import queryString from 'common/util/queryString';

const GSuiteAuthURL = 'https://accounts.google.com/o/oauth2/v2/auth';
const GSuiteClientID = '1043030166953-d1bqcah3ql6ag15fiq0lmjhcgredee5k.apps.googleusercontent.com';
const RedirectURI = 'https://canny.io/auth';
const Origin = devURL('https://canny.io');

export default class OAuthGSuite {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab, redirectURL, separateWindow }) {
    this._onFailure = onFailure;
    this._onContinue = onContinue;
    this._redirectURL = redirectURL;
    this._separateWindow = separateWindow;
    this._shouldOpenNewTab = shouldOpenNewTab;
  }

  connect = () => {
    const url =
      GSuiteAuthURL +
      queryString.stringify({
        access_type: 'offline',
        client_id: GSuiteClientID,
        prompt: 'consent',
        redirect_uri: RedirectURI,
        response_type: 'code',
        scope: [
          'https://www.googleapis.com/auth/admin.directory.customer.readonly',
          'https://www.googleapis.com/auth/admin.directory.domain.readonly',
          'https://www.googleapis.com/auth/admin.directory.group.readonly',
          'https://www.googleapis.com/auth/admin.directory.user.readonly',
        ].join(' '),
        state: this.getState(),
      });

    if (!this._shouldOpenNewTab) {
      window.location = url;
      return;
    }

    // No, 'noopener' because we trust Google
    const authWindow = window.open(url, 'Canny + GSuite Integration');
    const unsubscribe = Message.subscribe(authWindow, Origin, 'authResult', (result) => {
      this._onContinue(result);
      unsubscribe();
    });
  };

  getState = () => {
    const params = {
      authType: 'gsuite',
      stage: 2,
      ...(this._shouldOpenNewTab && { close: true }),
      ...(this._redirectURL && { redirectURL: encodeURIComponent(this._redirectURL) }),
      ...(this._separateWindow && { separateWindow: encodeURIComponent(this._separateWindow) }),
    };
    return JSON.stringify(params);
  };
}

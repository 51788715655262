import React, { Component } from 'react';

import PropTypes from 'prop-types';

export const IsWidgetContext = React.createContext();

export default class IsWidgetContainer extends Component {
  static propTypes = {
    isWidget: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <IsWidgetContext.Provider value={this.props.isWidget}>
        {this.props.children}
      </IsWidgetContext.Provider>
    );
  }
}

import React from 'react';

import { PanelRightClose, Share } from 'lucide-react';

import Tooltip from 'common/common/Tooltip';
import Button from 'common/ui/ButtonV2';
import IconButton from 'common/ui/IconButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import { H2 } from 'common/ui/Text';

import type { Option } from 'common/ui/common/select/SelectCommon';

type Props = {
  onClose: () => void;
  renderShareButton: boolean;
  selectedView: Option | undefined;
  setCreatingView: (creating: boolean) => void;
  setSelectedView: (option: Option | undefined) => void;
  showShareModal: () => void;
  viewOptions: Option[];
};

const SettingsModalDefaultHeader = ({
  onClose,
  renderShareButton,
  selectedView,
  setCreatingView,
  setSelectedView,
  showShareModal,
  viewOptions,
}: Props) => {
  return (
    <>
      <div className="modalHeaderTitle">
        <H2 variant="headingMd">Filters</H2>
        <IconButton
          onClick={onClose}
          icon={PanelRightClose}
          aria-label="Close filter panel"
          variant="plain"
          size="medium"
        />
      </div>
      <div className="modalHeaderView">
        <SingleSelect
          allowClear
          label="View"
          placeholder="Select a view"
          value={selectedView}
          options={viewOptions}
          onChange={setSelectedView}
          labelPlacement="left"
          optionsPlacement="bottom"
        />
        <Button
          size="medium"
          color="primary"
          variant="plain"
          className="createView"
          onClick={() => {
            setSelectedView(undefined);
            setCreatingView(true);
          }}>
          Create view
        </Button>
        {renderShareButton ? (
          <Tooltip position="left" value="Select a saved view to share" disabled={selectedView}>
            <Button
              className="shareView"
              onClick={showShareModal}
              size="medium"
              startIcon={Share}
              disabled={!selectedView}
              variant="outlined">
              Share
            </Button>
          </Tooltip>
        ) : null}
      </div>
    </>
  );
};

export default SettingsModalDefaultHeader;

import React, { useContext, useState } from 'react';

import { reloadBoard } from 'common/actions/boards';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidatePosts, reloadPost } from 'common/actions/posts';
import { invalidatePostActivities } from 'common/actions/postsActivity';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import connect from 'common/core/connect';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import GenericActionModal, { GenericActionError } from './GenericActionModal';
import GenericSuggestion from './GenericSuggestion';
import { type CommonModalProps } from './index';
import { findStringMatchesByName } from './utils';

import type { Post } from 'common/api/resources/posts';
import type { BoardStateItem } from 'common/reducers/boards';
import type { Dispatch } from 'redux-connect';

type OwnProps = CommonModalProps;

type ConnectProps = {
  invalidatePostData: (posts: Post[]) => Promise<void>;
  reloadBoard: (board: BoardStateItem) => Promise<void>;
  reloadPost: (post: Post) => Promise<void>;
  reloadRoadmapPostsForRoadmapsWithPosts: (posts: Post[]) => Promise<void>;
};

type Props = OwnProps & ConnectProps;

type GenericTag = {
  _id: string;
  name: string;
};

const AddTagsToPostsModal = ({
  invalidatePostData,
  onClose,
  reloadBoard,
  reloadPost,
  reloadRoadmapPostsForRoadmapsWithPosts,
  selectedPost,
  selectedPosts,
}: Props) => {
  const boards = useContext(BoardsContext);

  const [searchValue, setSearchValue] = useState<string>('');
  const boardsAreLoading = 'loading' in boards;

  const selectedBoard =
    !boardsAreLoading && boards.find((board) => board._id === selectedPosts[0].board._id);

  const getTags = () => {
    if (!selectedBoard) {
      return [];
    }

    const { tags } = selectedBoard;
    return findStringMatchesByName(tags, searchValue);
  };

  const handleSubmit = async (selectedTag: GenericTag) => {
    if (!selectedBoard) {
      // not a GenericActionError because we'd want this to be logged to Sentry
      throw Error('No board selected');
    }

    const responseJSON = await AJAX.post('/api/posts/bulkEdit', {
      operationType: 'addTag',
      postIDs: selectedPosts.map((post) => post._id),
      tagID: selectedTag._id,
    });
    const { error } = parseAPIResponse(responseJSON, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      throw new GenericActionError({ message: error.message, source: 'AddTagsToPosts' });
    } else {
      const postsToInvalidate = selectedPosts.filter((post) => post._id !== selectedPost?._id); // only invalidate posts that aren't the selected post
      await Promise.all([
        invalidatePostData(postsToInvalidate),
        selectedPost && reloadPost(selectedPost),
        reloadBoard(selectedBoard), // reload board to get updated tags
        reloadRoadmapPostsForRoadmapsWithPosts(selectedPosts),
      ]);
    }
  };

  const tags = getTags();

  return (
    <GenericActionModal<GenericTag>
      showSearch
      cta="Add"
      onClose={onClose}
      onSearchValueChanged={(searchValue) => setSearchValue(searchValue)}
      onSubmit={handleSubmit}
      searchValue={searchValue}
      successMessage="Tags successfully added"
      suggestions={tags}
      SuggestionComponent={GenericSuggestion}
      suggestionsLoading={boardsAreLoading}
      title="Add tags"
    />
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  invalidatePostData: (posts: Post[]) => {
    return Promise.all([
      dispatch(invalidatePostActivities(posts)),
      dispatch(invalidatePostQueries()),
      dispatch(invalidatePosts(posts)),
    ]);
  },
  reloadPost: (post: Post) => {
    return dispatch(reloadPost(post));
  },
  reloadBoard: (board: BoardStateItem) => {
    return dispatch(reloadBoard(board));
  },
  reloadRoadmapPostsForRoadmapsWithPosts: (posts: Post[]) => {
    return Promise.all(posts.map((post) => dispatch(reloadRoadmapPostsForRoadmapsWithPost(post))));
  },
}))(AddTagsToPostsModal) as unknown as React.FC<OwnProps>;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import withContexts from 'common/util/withContexts';

import AdminCreateJiraIssueModal from './AdminCreateJiraIssueModal';
import AdminJiraIssueForm from './AdminJiraIssueForm';
import AdminJiraIssues from './AdminJiraIssues';
import AdminSidebarSection from './AdminSidebarSection';
import { reloadPost } from '../../actions/posts';
import connect from '../../core/connect';
import Tappable from '../../Tappable';

import 'css/components/subdomain/admin/_AdminJiraPostSidebarSection.scss';

class AdminJiraPostSidebarSection extends Component {
  static propTypes = {
    company: PropTypes.shape({
      jira: PropTypes.shape({
        connected: PropTypes.bool,
      }),
    }),
    openModal: PropTypes.func,
    post: PropTypes.object,
  };

  state = {
    formOpen: false,
  };

  onCreateIssue = () => {
    const { board, openModal, post } = this.props;
    openModal(AdminCreateJiraIssueModal, {
      board,
      onIssueCreated: () => {
        const { reloadPost } = this.props;
        reloadPost(post);
      },
      post,
    });
  };

  onToggleTypeahead = () => {
    const { formOpen } = this.state;
    this.setState({
      formOpen: !formOpen,
    });
  };

  renderAction() {
    return (
      <Tappable onTap={this.onToggleTypeahead}>
        <div>Link issue</div>
      </Tappable>
    );
  }

  renderCreateButton() {
    return (
      <Tappable onTap={this.onCreateIssue}>
        <div className="createButton">Create new issue</div>
      </Tappable>
    );
  }

  renderIssueForm() {
    const { formOpen } = this.state;
    if (!formOpen) {
      return null;
    }

    const { post } = this.props;
    return <AdminJiraIssueForm post={post} />;
  }

  render() {
    const {
      company: { jira },
    } = this.props;
    if (!jira || !jira.connected) {
      return null;
    }

    const { post } = this.props;
    return (
      <AdminSidebarSection
        action={this.renderAction()}
        className="adminJiraPostSidebarSection"
        title="Jira Issues">
        {this.renderIssueForm()}
        <AdminJiraIssues post={post} />
        {this.renderCreateButton()}
      </AdminSidebarSection>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => dispatch(reloadPost(post)),
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminJiraPostSidebarSection);

import {
  EntryError,
  EntryLoaded,
  EntryNotFound,
  InvalidateAll,
  RequestEntry,
} from '../actions/changelogEntries';

export default function changelogEntries(state = {}, action) {
  switch (action.type) {
    case RequestEntry: {
      return Object.assign({}, state, {
        [action.entryURLName]: Object.assign({}, state[action.entryURLName], {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
          notFound: false,
        }),
      });
    }

    case EntryLoaded: {
      const entry = Object.assign(
        {},
        {
          error: null,
          lastUpdated: action.timestamp,
          loading: false,
          notFound: false,
        },
        action.entry
      );
      return Object.assign({}, state, {
        [action.entryURLName]: entry,
        [entry.urlName]: entry,
      });
    }

    case EntryNotFound: {
      return Object.assign({}, state, {
        [action.entryURLName]: Object.assign({}, state[action.postURLName], {
          lastUpdated: action.timestamp,
          loading: false,
          notFound: true,
        }),
      });
    }

    case EntryError: {
      return Object.assign({}, state, {
        [action.entryURLName]: Object.assign({}, state[action.entryURLName], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        }),
      });
    }

    case InvalidateAll: {
      return {};
    }

    default:
      return state;
  }
}

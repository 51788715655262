import { postsLoaded } from './posts';
import Data from '../Data';

// Action Types

export const Invalidate = 'canny/roadmap/invalidate';
export const LoadingMoreRoadmap = 'canny/roadmap/loading_more';
export const RequestRoadmap = 'canny/roadmap/request_roadmap';
export const RoadmapLoaded = 'canny/roadmap/roadmap_loaded';
export const RoadmapError = 'canny/roadmap/roadmap_error';

// Actions

function requestRoadmap(filters) {
  return {
    filters,
    timestamp: Date.now(),
    type: RequestRoadmap,
  };
}

function roadmapLoaded(result, filters) {
  return {
    filters,
    result,
    timestamp: Date.now(),
    type: RoadmapLoaded,
  };
}

function loadingMoreRoadmap() {
  return {
    timestamp: Date.now(),
    type: LoadingMoreRoadmap,
  };
}

function roadmapError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: RoadmapError,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchRoadmap(pages, filters = undefined) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: {
            boardURLNames: filters?.boardURLNames,
            categoryURLNames: filters?.categoryURLNames,
            allowUncategorized: filters?.includeUncategorized,
            pages,
          },
          query: Data.queries.publicRoadmap,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, getState, error, data, filters);
      }
    );
  };
}

export function loadFilteredRoadmap(filters) {
  return (dispatch, getState) => {
    dispatch(requestRoadmap(filters));
    return dispatch(fetchRoadmap(1, filters));
  };
}

export function loadRoadmap() {
  return (dispatch, getState) => {
    if (shouldFetchRoadmap(getState())) {
      dispatch(requestRoadmap());
      return dispatch(fetchRoadmap(1));
    } else if (isFetchingRoadmap(getState())) {
      return waitForResult();
    }
  };
}

export function loadMore() {
  return (dispatch, getState) => {
    const {
      filters,
      roadmap: { hasNextPage, pages },
    } = getState();
    if (!hasNextPage) {
      return;
    }

    dispatch(loadingMoreRoadmap());
    return dispatch(fetchRoadmap(pages + 1, filters));
  };
}

export function reloadRoadmap() {
  return (dispatch) => {
    return dispatch(fetchRoadmap(1));
  };
}

export function invalidateRoadmap() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions

function shouldFetchRoadmap(state) {
  const roadmap = state.roadmap;
  return !roadmap;
}

function isFetchingRoadmap(state) {
  const roadmap = state.roadmap;
  return !!roadmap.loading;
}

// Callback Queue

const resultCallbacks = [];

function waitForResult() {
  return new Promise((resolve, reject) => {
    resultCallbacks.push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, getState, error, data, filters) {
  if (getState().roadmap?.filters !== filters) {
    return; // Discard outdated fetches
  }

  let resultAction;
  const promises = [];
  if (error) {
    resultAction = roadmapError(error);
  } else {
    resultAction = roadmapLoaded(data.result, filters);
    promises.push(dispatch(postsLoaded(data.result.posts)));
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      resultCallbacks.forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks.length = 0;
    });
  });
}

import React, { type ChangeEvent } from 'react';

import TextInput from 'common/inputs/TextInput';

type Props = {
  onChange: (other: string) => void;
  placeholder?: string;
  value: string | null;
};

const AdminOnboardingOtherInput = ({ onChange, placeholder, value }: Props) => {
  return (
    <TextInput
      className="otherInput"
      inset="Other"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      placeholder={placeholder}
      value={value || undefined}
    />
  );
};

export default AdminOnboardingOtherInput;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Portal from 'common/common/Portal';
import PostStatusColors, { statusColorToHex } from 'common/postStatus/PostStatusColors';
import Tappable from 'common/Tappable';

import 'css/components/post/_PostStatusColorPicker.scss';

export default class PostStatusColorPicker extends Component {
  static propTypes = {
    align: PropTypes.string,
    onColorSelected: PropTypes.func,
    selectedColor: PropTypes.string.isRequired,
  };

  static defaultProps = {
    align: 'start',
  };

  state = {
    isOpen: false,
  };

  constructor(props, context) {
    super(props, context);
    this.portalRef = React.createRef();
    this.colorRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onClick);
    window.addEventListener('resize', this.onClosePicker);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
    window.removeEventListener('resize', this.onClosePicker);
  }

  onClick = (e) => {
    const { isOpen } = this.state;
    const isClickWithinContainer =
      findDOMNode(this).contains(e.target) || this.portalRef?.current?.containsNode(e.target);
    if (!isOpen || isClickWithinContainer) {
      return;
    }

    this.onClosePicker();
  };

  onColorSelected = (colorKey) => {
    const { onColorSelected } = this.props;
    onColorSelected?.(colorKey);
    this.onClosePicker();
  };

  onClosePicker = () => {
    const { isOpen } = this.state;
    if (!isOpen) {
      return;
    }

    this.setState({ isOpen: false });
  };

  onTogglePicker = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  renderColors = () => {
    const { selectedColor } = this.props;
    const colors = ['Red', 'Purple', 'Blue', 'Green'];

    const colorLines = colors.map((color) => {
      const colorElements = Object.entries(PostStatusColors[color])
        .reverse()
        .map(([light, hex]) => {
          const colorKey = `${color}.${light}`;
          return (
            <Tappable key={colorKey} onTap={() => this.onColorSelected(hex)}>
              <div
                className={classnames('color', { selected: selectedColor === hex })}
                style={{ background: hex }}
              />
            </Tappable>
          );
        });

      return (
        <div key={color} className="colorLine">
          {colorElements}
        </div>
      );
    });

    return colorLines;
  };

  renderPicker = () => {
    const { align } = this.props;
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }

    return (
      <Portal
        align={align}
        className="postStatusColorPickerPortal"
        ref={this.portalRef}
        relativeToRef={this.colorRef}>
        <div className="postStatusColorPicker picker">{this.renderColors()}</div>
      </Portal>
    );
  };

  render() {
    const { selectedColor } = this.props;
    const isSelectedColorHex = selectedColor.startsWith('#');

    return (
      <div className="postStatusColorPicker">
        <Tappable onTap={this.onTogglePicker}>
          <div
            ref={this.colorRef}
            className="statusColor"
            style={{
              background: isSelectedColorHex ? selectedColor : statusColorToHex(selectedColor),
            }}
          />
        </Tappable>
        {this.renderPicker()}
      </div>
    );
  }
}

import { loadHelpscoutSettings } from 'common/actions/helpscoutSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { HelpscoutSettings } from 'common/api/endpoints/helpscout';
import type { State } from 'redux-connect';

type Props = {
  helpscoutSettings: HelpscoutSettings;
  children: React.ReactNode;
};

const HelpscoutSettingsContainer = (props: Props) => {
  // props
  const { children, helpscoutSettings } = props;

  return cloneElementWithProps(children, {
    ...props,
    helpscoutSettings,
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadHelpscoutSettings()),
    },
  ],
  (state: State) => ({ helpscoutSettings: state.helpscoutSettings?.data })
)(HelpscoutSettingsContainer);

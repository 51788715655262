import React, { useContext, useState } from 'react';

import { loadAutomations, reloadAutomations } from 'common/actions/automations';
import AJAX from 'common/AJAX';
import validateAutomation from 'common/automations/validateAutomation';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import asyncConnect from 'common/core/asyncConnect';
import Button from 'common/inputs/Button';
import ConfirmModal from 'common/modals/ConfirmModal';
import Spinner from 'common/Spinner';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AutomationModal, { type FormAutomation, type FormError } from './AutomationModal';
import AutomationRule from './AutomationRule';
import AdminFeatureUpsell from '../AdminFeatureUpsell';

import type { Automation } from 'common/api/endpoints/automation';

import 'css/components/subdomain/admin/AdminAutomationSettings/_AdminAutomationSettings.scss';

type Props = {
  automations: Automation[];
  reloadAutomations: () => void;
};

const AdminAutomationSettings = ({ automations, reloadAutomations }: Props) => {
  const company = useContext(CompanyContext);
  const openGlobalModal = useContext(OpenModalContext);

  const [editingAutomationID, setEditingAutomationID] = useState<string | null>(null);
  const [pageError, setPageError] = useState<string | null>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const createNewRule = async (formValues: FormAutomation): Promise<FormError | void> => {
    const validationResult = validateAutomation(formValues);
    if (!validationResult.ok) {
      return {
        type: validationResult.error.type,
        message: validationResult.error.message,
      };
    }

    setPageLoading(true);

    const { error } = parseAPIResponse(await AJAX.post('/api/automation/create', formValues), {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      setPageLoading(false);
      return { type: 'all', message: error.message };
    }

    setModalOpen(false);

    await reloadAutomations();
    setPageLoading(false);
  };

  const deleteAutomation = async (automationID: string) => {
    setPageLoading(true);
    setPageError(null);

    const { error } = parseAPIResponse(
      await AJAX.post('/api/automation/delete', { automationID }),
      { isSuccessful: isDefaultSuccessResponse }
    );
    if (error) {
      setPageLoading(false);
      setPageError(error.message);
      return;
    }

    await reloadAutomations();
    setPageLoading(false);
  };

  const openCreateModal = () => {
    setEditingAutomationID(null);
    setModalOpen(true);
  };

  const openDeleteModal = (automationID: string, automationTitle: string) => {
    openGlobalModal(ConfirmModal, {
      message: (
        <>
          <h1 className="adminAutomationConfirm header">
            Are you sure you want to delete "{automationTitle}"?
          </h1>
          <p className="adminAutomationConfirm body">
            Deleted rules will no longer apply to new posts. This action is irreversible.
          </p>
        </>
      ),
      onConfirm: () => deleteAutomation(automationID),
      submitButtonType: 'redButton',
      submitButtonValue: 'Delete rule',
      tint: false,
    });
  };

  const openEditModal = (automationID: string) => {
    setEditingAutomationID(automationID);
    setModalOpen(true);
  };

  const saveRule = async (
    automationID: string,
    formValues: FormAutomation
  ): Promise<FormError | void> => {
    const validationResult = validateAutomation(formValues);
    if (!validationResult.ok) {
      return {
        type: validationResult.error.type,
        message: validationResult.error.message,
      };
    }

    setModalOpen(false);
    setPageLoading(true);

    const { error } = parseAPIResponse(
      await AJAX.post('/api/automation/edit', {
        automationID,
        ...formValues,
      }),
      { isSuccessful: isDefaultSuccessResponse }
    );

    if (error) {
      setPageLoading(false);
      return { type: 'all', message: error.message };
    }

    await reloadAutomations();
    setPageLoading(false);
  };

  const toggleRule = async (automationID: string, enabled: boolean) => {
    setPageLoading(true);
    setPageError(null);
    const { error } = parseAPIResponse(
      await AJAX.post('/api/automation/toggle', { automationID, enabled }),
      { isSuccessful: isDefaultSuccessResponse }
    );
    if (error) {
      setPageLoading(false);
      setPageError(error.message);
      return;
    }
    await reloadAutomations();
    setPageLoading(false);
  };

  return (
    <>
      <AdminSettingsHeader
        title="Automations"
        subheading={
          <p>
            Simplify your workflow with rule-based automations. Rules will only apply to
            new&nbsp;posts.
          </p>
        }
        learnMoreLink="https://help.canny.io/en/articles/6752812-automations"
      />
      {!company?.features?.postAutomation ? (
        <div className="adminAutomationSettings">
          <AdminFeatureUpsell
            cta="Simplify your workflow with rule-based&nbsp;automations"
            feature="postAutomation"
          />
        </div>
      ) : (
        <>
          <div className="adminAutomationSettings">
            <section className="header">
              <h1 className="pageTitle">Rules</h1>
              <Button onTap={() => openCreateModal()} value="+ Create rule" />
            </section>
            {automations.length > 0 ? (
              <section className="rules">
                {automations.map((automation) => {
                  return (
                    <AutomationRule
                      appliedCount={automation.appliedCount}
                      createdDate={automation.created}
                      creator={automation.createdBy}
                      className="rule"
                      enabled={automation.enabled}
                      key={automation._id}
                      onDelete={() => openDeleteModal(automation._id, automation.title)}
                      onEdit={() => openEditModal(automation._id)}
                      onToggle={() => toggleRule(automation._id, !automation.enabled)}
                      ruleName={automation.title}
                    />
                  );
                })}
              </section>
            ) : null}

            {pageLoading ? <Spinner /> : null}
            {!pageLoading && automations.length === 0 ? (
              <>You haven't created any rules, yet...</>
            ) : null}
            {pageError ? <div className="pageError">{pageError}</div> : null}
          </div>

          {modalOpen ? (
            <AutomationModal
              isEditing={!!editingAutomationID}
              key={editingAutomationID ?? 'no-key'}
              onClose={() => setModalOpen(false)}
              onSave={(formValues) =>
                editingAutomationID
                  ? saveRule(editingAutomationID, formValues)
                  : createNewRule(formValues)
              }
              defaultValues={automations.find(
                (automation) => automation._id === editingAutomationID
              )}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => {
        return dispatch(loadAutomations());
      },
    },
  ],
  (state) => ({
    automations: (state.automations && state.automations.data) ?? [],
  }),
  (dispatch) => ({
    reloadAutomations: () => {
      return dispatch(reloadAutomations());
    },
  })
)(AdminAutomationSettings);

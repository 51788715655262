import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import DatePicker from 'common/common/DatePicker';

import TextInput from './TextInput';

import 'css/components/inputs/_DateInput.scss';

export default class DateInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.instanceOf(Date),
  };

  state = {
    focused: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.onClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick, false);
  }

  onClick = (e) => {
    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    this.setState({ focused: false });
  };

  onDateSelect = (date) => {
    this.setState({ focused: false });
    this.props.onChange(date);
  };

  onInputChange = (e) => {
    this.props.onChange(e);
  };

  onInputFocus = (e) => {
    this.setState({
      focused: true,
    });
  };

  render() {
    const { value } = this.props;
    const dateString = value && value.toLocaleDateString('en-US');
    return (
      <div className="dateInput">
        <TextInput onFocus={this.onInputFocus} value={dateString} />
        <div>
          {this.state.focused && (
            <DatePicker
              className="datePicker"
              onDateSelect={this.onDateSelect}
              type="date"
              value={this.props.value}
            />
          )}
        </div>
      </div>
    );
  }
}

import {
  InvalidateReactions,
  ReactionsError,
  ReactionsLoaded,
  RequestReactions,
} from 'common/actions/userReactions';

export default function userReactions(state = {}, action) {
  switch (action.type) {
    case RequestReactions: {
      const existingReactions = state[action.objectID] ?? {};
      const newReactions = Object.assign(existingReactions, {
        [action.reactionName]: {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
        },
      });
      return Object.assign({}, state, { [action.objectID]: newReactions });
    }

    case ReactionsLoaded: {
      const existingReactions = state[action.objectID] ?? {};
      const newReactions = Object.assign(existingReactions, {
        [action.reactionName]: {
          error: null,
          items: action.reactions,
          lastUpdated: action.timestamp,
          loading: false,
        },
      });
      return Object.assign({}, state, { [action.objectID]: newReactions });
    }

    case ReactionsError: {
      const existingReactions = state[action.objectID] ?? {};
      const newReactions = Object.assign(existingReactions, {
        [action.reactionName]: {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        },
      });
      return Object.assign({}, state, { [action.objectID]: newReactions });
    }

    case InvalidateReactions: {
      const existingReactions = state[action.objectID] ?? {};
      const newReactions = Object.assign(existingReactions, {
        [action.reactionName]: null,
      });

      return Object.assign({}, state, { [action.objectID]: newReactions });
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadDiscordSettings } from 'common/actions/discordSettings';
import AJAX from 'common/AJAX';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationChannelSettings from 'common/subdomain/admin/AdminIntegrationChannelSettings';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import delayer from 'common/util/delayer';
import devURL from 'common/util/devURL';
import mapify from 'common/util/mapify';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminDiscordSettings.scss';

const DiscordParams = {
  client_id: publicConfig('discordClientID'),
  // The permission bitmask, as defined here: https://discord.com/developers/docs/topics/permissions
  // Includes the permissions: READ_MESSAGES/VIEW_CHANNELS, SEND_MESSAGES, EMBED_LINKS, ATTACH_FILES
  permissions: '52224',
  redirect_uri: devURL('https://canny.io/discord'),
  response_type: 'code',
  scope: 'applications.commands bot guilds',
};
const DiscordURL = 'https://discord.com/api/oauth2/authorize';
const UnauthorizedChannelError = 'unauthorized channel';
const ErrorMessages = {
  default: 'Something went wrong, please try again later or contact our team for support.',
  'discord error': 'Could not communicate with Discord right now, please try again later.',
  'invalid permissions': 'Please grant all the permissions for discord bot.',
  [UnauthorizedChannelError]: UnauthorizedChannelError,
};

class AdminDiscordSettings extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.shape({
      _id: PropTypes.string,
      logoURL: PropTypes.string,
      name: PropTypes.string,
      subdomain: PropTypes.string,
      tintColor: PropTypes.string,
    }),
    discordSettings: PropTypes.object,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        guildId: PropTypes.string,
        integration: PropTypes.string,
      }),
    }),
    router: PropTypes.object,
  };

  state = {
    channelError: null,
    guildID: this.props.location.query.guildID,
    integration: this.props.location.query.integration,
    installError: null,
    registeringChannel: false,
    registeringDiscord: false,
    suggestions: null,
  };

  constructor(props) {
    super(props);

    this._searchDelayer = new delayer(this.onSearchValueChangedAfterDelay, 400);
    this._searchValue = null;
    this._searchRef = React.createRef();
  }

  componentDidMount() {
    const { guildID, integration } = this.state;
    if (!guildID) {
      return;
    }

    const { location, router } = this.props;
    router.replace({
      pathname: location.pathname,
    });

    if (integration === 'discord') {
      this.registerDiscord();
    }
  }

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Discord integration?",
      onConfirm: this.onDisconnectConfirmed,
    });
  };

  onDisconnectConfirmed = async () => {
    const response = await AJAX.post('/api/discord/disconnect');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({ installError: ErrorMessages.default });
      return;
    }

    this.props.reloadSettings();
  };

  onSearchValueChanged = (e) => {
    const { value } = e.target;
    this.setState({ suggestions: null });
    this._searchValue = value;
    this._searchDelayer.callAfterDelay();
  };

  onSearchValueChangedAfterDelay = async () => {
    if (!this._searchValue) {
      return;
    }

    const response = await AJAX.post('/api/discord/searchGuildChannels', {
      channelName: this._searchValue,
    });
    const { error, parsedResponse } = parseAPIResponse(response, {
      errors: ErrorMessages,
      isSuccessful: (parsedResponse) => parsedResponse?.channels,
    });

    if (error) {
      this.setState({ channelError: error.message });
      return;
    }

    this.setState({ suggestions: parsedResponse.channels });
  };

  onSuggestionSelected = async (suggestion) => {
    this.setState({ channelError: null, registeringChannel: true, suggestions: null });
    this._searchRef.current.setValue(suggestion.name);

    const response = await AJAX.post('/api/discord/registerChannel', {
      channelID: suggestion.id,
    });
    const { error } = parseAPIResponse(response, {
      errors: ErrorMessages,
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({ channelError: error.message, registeringChannel: false });
      this._searchRef.current.setValue('');
      return;
    }

    await this.props.reloadSettings();
    this._searchRef.current.setValue('');
    this.setState({ registeringChannel: false });
  };

  registerDiscord = async () => {
    this.setState({ registeringDiscord: true });

    const response = await AJAX.post('/api/discord/register', {
      guildID: this.state.guildID,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: ErrorMessages,
    });

    if (error) {
      this.setState({ installError: error.message, registeringDiscord: false });
      return;
    }

    await this.props.reloadSettings();
    this.setState({ registeringDiscord: false });
  };

  renderChannelsSection() {
    const { company, discordSettings } = this.props;
    const hasAccess = company?.integrations?.discord;
    const hasInstalled = !!discordSettings?.guildID;

    if (!hasAccess || !hasInstalled) {
      return null;
    }

    return (
      <div className="channelSection">
        <div className="subheading">Channels</div>
        {this.renderChannelSearch()}
        {this.renderError(this.state.channelError)}
        {this.renderChannels()}
      </div>
    );
  }

  renderChannelSearch() {
    return (
      <div>
        <div className="description">
          Search for text channels where you want to receive notifications.
        </div>
        <div className="channelPicker">
          {this.renderSearchInput()}
          {this.renderChannelSuggestions()}
        </div>
      </div>
    );
  }

  renderChannels() {
    const { boards, discordSettings, reloadSettings } = this.props;
    if (discordSettings?.channels?.length < 1) {
      return null;
    }

    const integration = {
      deleteURL: '/api/discord/deleteChannel',
      editURL: '/api/discord/editChannel',
      getChannelHeading: (channel) => `${channel.guildName} : ${channel.name}`,
      reload: reloadSettings,
    };
    return (
      <div className="channels">
        {discordSettings.channels.map((channel) => (
          <AdminIntegrationChannelSettings
            boards={boards}
            channel={channel}
            integration={integration}
            key={channel._id}
          />
        ))}
      </div>
    );
  }

  renderChannelSuggestions() {
    const { discordSettings } = this.props;
    const { suggestions } = this.state;

    if (!suggestions) {
      return null;
    }

    const channelMap = mapify(discordSettings.channels, 'channelID');
    const filteredSuggestions = suggestions.filter((suggestion) => !channelMap[suggestion.id]);

    if (filteredSuggestions.length === 0) {
      return (
        <div className="channelSuggestions">
          <div className="noMatches">There are no matching&nbsp;channels</div>
        </div>
      );
    }

    return (
      <div className="channelSuggestions">
        {filteredSuggestions.map((suggestion) => (
          <Tappable onTap={() => this.onSuggestionSelected(suggestion)} key={suggestion.id}>
            <div className="suggestion">
              <div className="name">{suggestion.name}</div>
            </div>
          </Tappable>
        ))}
      </div>
    );
  }

  renderDisconnect() {
    const { discordSettings } = this.props;

    return (
      <div className="connected">
        <div>Your Discord Guild ({discordSettings.guildName}) is connected.</div>
        <div>
          Check out&nbsp;
          <a
            className="articleLink"
            href="http://help.canny.io/en/articles/5807464-discord-integration"
            rel="noopener"
            target="_blank">
            this help article
          </a>
          &nbsp;to see how this integration works.
        </div>
        <Tappable onTap={this.onDisconnect}>
          <div className="disconnect">Disconnect</div>
        </Tappable>
      </div>
    );
  }

  renderInstall() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
      company,
      discordSettings,
    } = this.props;
    const hasAccess = company?.integrations?.discord;
    const hasInstalled = !!discordSettings.guildID;

    if (!hasAccess) {
      return (
        <>
          {hasInstalled && this.renderDisconnect()}
          <AdminFeatureUpsell feature="discord" />
        </>
      );
    }

    if (discordSettings.error) {
      const errorMessage = ErrorMessages[discordSettings.error] ?? ErrorMessages.default;
      return <div className="error">{errorMessage}</div>;
    }

    if (hasInstalled) {
      return this.renderDisconnect();
    }

    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    const discordParams = {
      state: JSON.stringify({
        integration: 'discord',
        subdomain: company.subdomain,
      }),
      ...DiscordParams,
    };
    const discordURL = `${DiscordURL}${queryString.stringify(discordParams)}`;
    const { registeringDiscord } = this.state;

    return (
      <div>
        <div className="description">
          Click “Install Discord" to configure notifications in channels.
        </div>
        {registeringDiscord ? (
          <Spinner />
        ) : (
          <a href={discordURL} className="buttonContainer" rel="noreferrer noopener nofollow">
            <Button value="Install Discord" />
          </a>
        )}
      </div>
    );
  }

  renderError(error) {
    if (!error) {
      return null;
    }

    if (error === UnauthorizedChannelError) {
      return (
        <div className="discordError">
          The Canny Discord bot doesn't have permission to access this channel. You'll need to
          adjust your channel permissions in order for Canny to send notifications here. Learn more
          about how to set this up in&nbsp;
          <a
            className="articleLink"
            href="http://help.canny.io/en/articles/5807464-discord-integration"
            rel="noopener"
            target="_blank">
            this article
          </a>
          .
        </div>
      );
    }

    return <div className="discordError">{error}</div>;
  }

  renderNotifications() {
    return (
      <div className="notifications">
        <div className="subheading">Notifications</div>
        <div className="description">
          Get instant notifications on select events in Canny such as new posts, comments, or votes.
        </div>
        {this.renderInstall()}
        {this.renderError(this.state.installError)}
      </div>
    );
  }

  renderSearchInput() {
    const { registeringChannel } = this.state;
    return (
      <div className="channelInput">
        <TextInput
          disabled={registeringChannel}
          onChange={this.onSearchValueChanged}
          placeholder="Search Channels"
          ref={this._searchRef}
        />
        {registeringChannel && (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="adminDiscordSettings">
        <Helmet title="Discord Integration | Canny" />
        <div className="settingsHeading">Discord Integration</div>
        <div className="content">
          {this.renderNotifications()}
          {this.renderChannelsSection()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadSettings: () => {
      return dispatch(reloadDiscordSettings());
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.discord), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminDiscordSettings);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledCheckboxInput from 'common/inputs/ControlledCheckboxInput';
import NotificationActions from 'common/notifications/NotificationActions';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import AdminSidebarSectionItem from 'common/subdomain/admin/AdminSidebarSectionItem';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

const { postOwnerChanged: PostOwnerChanged, postOwnerCleared: PostOwnerCleared } =
  NotificationActions;
const FilteredNotificationActionNames = Object.keys(NotificationActions).filter(
  (notificationActionName) => notificationActionName !== PostOwnerCleared.name
);

class AdminSidebarNotificationActionSection extends Component {
  static propTypes = {
    company: PropTypes.object,
    onNotificationActionsChange: PropTypes.func,
  };

  state = {
    selectedNotificationActionNames: [],
  };

  componentDidMount() {
    const defaultNotificationActionNames = this.getDefaultSelectedNotificationActionNames();
    this.setState({ selectedNotificationActionNames: defaultNotificationActionNames });
    this.props.onNotificationActionsChange(defaultNotificationActionNames, true);
  }

  getDefaultSelectedNotificationActionNames() {
    const { viewerNotificationSettings } = this.props.company;
    if (!viewerNotificationSettings) {
      return Object.keys(NotificationActions);
    }
    return viewerNotificationSettings.notificationActions;
  }

  onNotificationActionChange = (notificationActionName) => {
    const isAdded = this.state.selectedNotificationActionNames.includes(notificationActionName);
    const selectedNotificationActionNames =
      this.filterSelectedNotificationsByActionName(notificationActionName);

    if (notificationActionName === PostOwnerChanged.name && !isAdded) {
      selectedNotificationActionNames.push(PostOwnerCleared.name);
    }

    if (!isAdded) {
      selectedNotificationActionNames.push(notificationActionName);
    }

    this.setState({ selectedNotificationActionNames });
    this.props.onNotificationActionsChange(selectedNotificationActionNames);
  };

  onSelectAllNotificationActions = () => {
    const selectedNotificationActionNames = Object.keys(NotificationActions);

    this.setState({ selectedNotificationActionNames });
    this.props.onNotificationActionsChange(selectedNotificationActionNames);
  };

  onSelectNotificationAction = (notificationActionNameToSelect) => {
    const selectedNotificationActionNames = [notificationActionNameToSelect];

    if (notificationActionNameToSelect === PostOwnerChanged.name) {
      selectedNotificationActionNames.push(PostOwnerCleared.name);
    }

    this.setState({ selectedNotificationActionNames });
    this.props.onNotificationActionsChange(selectedNotificationActionNames);
  };

  filterSelectedNotificationsByActionName = (notificationActionName) => {
    return this.state.selectedNotificationActionNames.filter((selectedActionName) => {
      if (notificationActionName === PostOwnerChanged.name) {
        return (
          selectedActionName !== PostOwnerChanged.name &&
          selectedActionName !== PostOwnerCleared.name
        );
      }
      return selectedActionName !== notificationActionName;
    });
  };

  renderSelectAllNotifications() {
    return (
      <Tappable onTap={this.onSelectAllNotificationActions}>
        <div className="all">Select All</div>
      </Tappable>
    );
  }

  render() {
    const { selectedNotificationActionNames } = this.state;
    const selectedTypeMap = selectedNotificationActionNames.reduce((acc, type) => {
      acc[type] = true;
      return acc;
    }, {});

    return (
      <AdminSidebarSection
        action={this.renderSelectAllNotifications()}
        className="adminSidebarNotificationActionSection"
        title="Activity">
        {FilteredNotificationActionNames.map((notificationActionName) => {
          const notificationAction = NotificationActions[notificationActionName];
          const checked = !!selectedTypeMap[notificationActionName];
          return (
            <AdminSidebarSectionItem key={notificationActionName}>
              <ControlledCheckboxInput
                checked={checked}
                label={notificationAction.render}
                onChange={this.onNotificationActionChange.bind(this, notificationActionName)}
              />
              <div className="hoverMenu">
                <Tappable
                  onTap={this.onSelectNotificationAction.bind(this, notificationActionName)}>
                  <div className="only">1</div>
                </Tappable>
              </div>
            </AdminSidebarSectionItem>
          );
        })}
      </AdminSidebarSection>
    );
  }
}

export default withContexts({
  company: CompanyContext,
})(AdminSidebarNotificationActionSection);

import React, { useContext, useEffect, useState } from 'react';

import Error from 'common/common/Error';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import SharedRoadmapHelmet from 'common/helmets/SharedRoadmapHelmet';
import AdminRoadmapTable from 'common/subdomain/admin/AdminRoadmap/AdminRoadmapTable';
import findStringMatches from 'common/util/findStringMatches';

import SharedRoadmapHeader from './SharedRoadmap/SharedRoadmapHeader';

import type { Board } from 'common/api/endpoints/boards';
import type { RoadmapPost } from 'common/api/endpoints/roadmapPosts';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { CustomPostField } from 'common/api/resources/postFields';
import type { RoadmapPostState } from 'common/reducers/roadmapPosts';
import type { Option } from 'common/ui/common/select/SelectCommon';

import 'css/components/subdomain/public/_SharedRoadmap.scss';

export type ViewableRoadmapPostsState = Omit<
  RoadmapPostState,
  'loading' | 'error' | 'lastUpdated' | 'queryParams'
>;

type Props = {
  boards: Board[];
  customPostFields: CustomPostField[];
  roadmap: Roadmap;
  roadmapPosts: RoadmapPostState;
};

const SharedRoadmap = ({ boards, roadmapPosts, roadmap, customPostFields }: Props) => {
  const [viewableRoadmapPostsState, setViewableRoadmapPostsState] =
    useState<ViewableRoadmapPostsState>({
      distinctBoards: [],
      distinctCategories: [],
      distinctOwners: [],
      posts: [],
      statuses: [],
    });

  const company = useContext(CompanyContext);

  const [groupBy, setGroupBy] = useState<Option | undefined>(undefined);

  // If the list of roadmap posts changes, show everything
  useEffect(() => {
    setViewableRoadmapPostsState(roadmapPosts);
    setGroupBy(undefined);
  }, [roadmapPosts]);

  const filterRoadmapPosts = (searchValue: string, roadmapPosts: RoadmapPost[]) => {
    if (!searchValue) {
      return roadmapPosts;
    }

    const getTitle = (roadmapPost: RoadmapPost) => roadmapPost.post.title;
    return findStringMatches(roadmapPosts, getTitle, searchValue);
  };

  const searchRoadmapPosts = (searchValue: string) => {
    const filteredPosts = filterRoadmapPosts(searchValue, roadmapPosts.posts);
    setViewableRoadmapPostsState({ ...viewableRoadmapPostsState, posts: filteredPosts });
  };

  if (!roadmap) {
    return (
      <div className="sharedRoadmapError">
        <Error
          heading="You do not have access to this roadmap"
          subHeading={`Contact ${company.name} to get an invite`}
        />
      </div>
    );
  }

  const noFunc = () => {};

  const posts = viewableRoadmapPostsState?.posts ?? [];

  return (
    <>
      <SharedRoadmapHelmet roadmap={roadmap} />
      <ContentContainer outerClassName="sharedRoadmap">
        <SharedRoadmapHeader
          roadmap={roadmap}
          setGroupBy={setGroupBy}
          groupBy={groupBy}
          customPostFields={customPostFields}
          onSearch={searchRoadmapPosts}
        />
        <div className="sharedRoadmap__table-container">
          <AdminRoadmapTable
            {...viewableRoadmapPostsState}
            boards={boards}
            customPostFields={customPostFields}
            roadmapPosts={posts}
            hiddenColumnIDs={roadmap?.settings?.hiddenColumnIDs ?? []}
            createRoadmapPost={noFunc}
            deleteRoadmapPost={noFunc}
            error={''}
            groupBy={groupBy?.value}
            loading={false}
            onOpenPost={noFunc}
            roadmap={roadmap}
            selectedPost={undefined}
            onClosePost={noFunc}
            updateServerSettings={noFunc}
          />
        </div>
      </ContentContainer>
    </>
  );
};

export default SharedRoadmap;

import OAuthFlows from 'common/OAuthFlows';

export default class AuthRequest {
  constructor(authType, additionalData, callback) {
    this.additionalData = additionalData;
    this.authType = authType;
    this.callback = callback;
  }

  start = () => {
    const OAuthFlow = OAuthFlows.getFlow(this.authType);
    const oauthFlow = new OAuthFlow({
      ...this.additionalData,
      onContinue: this.onContinue,
      onFailure: this.onFailure,
      onSuccess: this.onSuccess,
      redirectURL: null,
      separateWindow: null,
      shouldOpenNewTab: true,
    });
    oauthFlow.connect();
  };

  onContinue = (result) => {
    const OAuthFlow = OAuthFlows.getFlow(this.authType, 2);
    const oauthFlow = new OAuthFlow({
      ...this.additionalData,
      onContinue: this.onContinue,
      onFailure: this.onFailure,
      onSuccess: this.onSuccess,
      redirectURL: null,
      separateWindow: null,
      shouldOpenNewTab: true,
    });
    oauthFlow.connect(result);
  };

  onSuccess = (oauthData) => {
    this.callback(null, oauthData);
  };

  onFailure = (error) => {
    this.callback(error);
  };
}

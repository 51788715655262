import React, { useCallback, useContext, useEffect, useState } from 'react';

import { FileUp } from 'lucide-react';
import { compose } from 'redux';

import AJAX from 'common/AJAX';
import { type KnowledgeHubFile } from 'common/api/endpoints/knowledgeHubFile';
import { SupportedMimeTypeList as AcceptedMimeTypes } from 'common/constants/knowledgeHubFiles';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import FileInput from 'common/inputs/FileInput';
import withAccessControl from 'common/routing/withAccessControl';
import SpinnerV2 from 'common/SpinnerV2';
import AdminKnowledgeHubTable from 'common/subdomain/admin/AdminAutopilotSettings/AdminKnowledgeHubSettings/AdminKnowledgeHubTable';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import ButtonV2 from 'common/ui/ButtonV2';
import { H1, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/settings/_AdminKnowledgeHubSettings.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { Task } from 'common/api/resources/task';

type Props = Record<string, never>;

const AdminKnowledgeBaseSettings = () => {
  const company = useContext<Company>(CompanyContext);
  const router = useContext(RouterContext);
  const showToast = useContext(ShowToastContext);

  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [files, setFiles] = useState<KnowledgeHubFile[]>([]);
  const [uploadingFile, setUploadingFile] = useState<Record<string, boolean>>({});

  const { features } = company;
  const hasRAGEnabled = company.featureAllowlist.includes('rag-feature-request');
  const hasAutopilot = features.deduplication || features.supportExtraction;
  const isUploadingFile = Object.values(uploadingFile).some(Boolean);
  const setLoading = (file: File, loading: boolean) =>
    setUploadingFile((prev) => ({ ...prev, [file.name]: loading }));

  const loadFiles = useCallback(async () => {
    setIsLoadingFiles(true);
    const response = await AJAX.post('/api/knowledgeHub/listFiles');
    const { error, parsedResponse } = parseAPIResponse<{ files: KnowledgeHubFile[] }>(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse?.files,
    });

    setIsLoadingFiles(false);
    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    } else if (!parsedResponse) {
      showToast('Something went wrong. Please try again.', ToastTypes.error);
      return;
    }

    setFiles(parsedResponse.files);
  }, [showToast]);

  // load files on mount
  useEffect(() => {
    loadFiles();
  }, [loadFiles]);

  // redirect to home if RAG is not enabled for this company
  useEffect(() => {
    if (!hasRAGEnabled) {
      router.replace('/admin');
    }
  }, [hasRAGEnabled, router]);

  const deleteFiles = async (files: KnowledgeHubFile[]) => {
    for (const file of files) {
      const response = await AJAX.post('/api/knowledgeHub/deleteFile', {
        fileID: file._id,
      });

      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        showToast(error.message, ToastTypes.error);
        continue;
      }
    }

    await loadFiles();
  };

  const uploadFile = async (file: File) => {
    setLoading(file, true);

    const response = await AJAX.postFile(
      '/api/knowledgeHub/uploadFile',
      { file },
      { fileType: file.type }
    );

    const { error, parsedResponse } = parseAPIResponse<{ task: Task }>(response, {
      isSuccessful: (parsedResponse) => !!parsedResponse.task,
      errors: {
        'invalid file': 'The file is too large (8MB max).',
        'invalid file type': 'The file type is not supported.',
      },
    });

    setLoading(file, false);

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    } else if (!parsedResponse) {
      showToast('Something went wrong. Please try again.', ToastTypes.error);
      return;
    }

    loadFiles();
  };

  if (!hasRAGEnabled) {
    return null;
  } else if (!hasAutopilot) {
    return (
      <div className={styles.adminKnowledgeBaseSettings}>
        <Helmet title={`Knowledge Base | Autopilot Settings | Canny`} />
        <AdminFeatureUpsell
          cta="Automatically gather feedback from all your sources in one place"
          feature="knowledgeHub"
        />
      </div>
    );
  }

  return (
    <section className={styles.adminKnowledgeBaseSettings}>
      <Helmet title={`Knowledge Hub | Autopilot Settings | Canny`} />
      <header className={styles.header}>
        <H1 variant="headingMd">Knowledge Hub</H1>
        <P className={styles.subheading}>
          Upload documents to help Autopilot detect existing features, enrich feedback, and enhance
          smart&nbsp;replies.{' '}
          <a
            className={styles.link}
            href="https://help.canny.io/en/articles/9893990-the-autopilot-knowledge-hub-beta"
            target="_blank"
            rel="noopener">
            Learn more
          </a>
        </P>
      </header>
      <Form
        className={styles.form}
        allowFileUpload={!isUploadingFile}
        acceptedFileTypes={AcceptedMimeTypes}
        onFile={uploadFile}>
        <div className={styles.fileUploadSection}>
          {isUploadingFile ? (
            <SpinnerV2 size="xxxxlarge" className={styles.fileSpinner} />
          ) : (
            <>
              <div className={styles.fileUploadTop}>
                <FileInput
                  accept={AcceptedMimeTypes.join(',')}
                  onFile={uploadFile}
                  defaultStyle={false}
                  value={
                    <ButtonV2 startIcon={FileUp} size="medium">
                      Upload
                    </ButtonV2>
                  }
                />
                <P>
                  or drop .txt, .md, .pdf, or .docx files here to enhance Autopilot’s
                  knowledge&nbsp;hub
                </P>
              </div>
              <P className={styles.fileUploadBottom}>Maximum file size: 8 MB</P>
            </>
          )}
        </div>
      </Form>
      <AdminKnowledgeHubTable files={files} onDelete={deleteFiles} loading={isLoadingFiles} />
    </section>
  );
};

export default compose(
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.inbox),
    '/admin/settings'
  )
)(AdminKnowledgeBaseSettings);

import React from 'react';

import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';
import { RoutePermissions } from 'common/util/permissions';

import 'css/components/subdomain/admin/_AdminNotificationsSettingsContainer.scss';

type NotificationsSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.notifications;
};

const SidebarLinks: NotificationsSidebarLink[] = [
  {
    label: 'Email types',
    urlSuffix: 'types',
  },
  {
    label: 'Custom address',
    urlSuffix: 'domain',
  },
];

const AdminNotificationsSettingsContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="adminNotificationsSettingsContainer">
    <AdminSettingsHeader
      title="Notifications"
      subheading="Manage communications to your end&nbsp;users."
      learnMoreLink="https://help.canny.io/en/collections/325110-canny-emails"
    />
    <div className="settingsContainer">
      <div className="settingsSidebar">
        {SidebarLinks.map(({ label, urlSuffix }) => (
          <AdminSettingsSidebarLink
            key={urlSuffix}
            to={`/admin/settings/user-emails/${urlSuffix}`}
            requiredPermissions={RoutePermissions.adminSettings.notifications[urlSuffix]}>
            {label}
          </AdminSettingsSidebarLink>
        ))}
      </div>
      <div className="settingsContent">{children}</div>
    </div>
  </div>
);

export default AdminNotificationsSettingsContainer;

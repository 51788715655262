import React from 'react';

import { Minus } from 'lucide-react';

import {
  isFilterBoardURLName,
  isFilterCustomField,
  isFilterPostCategory,
  isFilterPostContent,
} from 'common/automations/filterUtils';
import IconButtonV2 from 'common/ui/IconButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import stringSort from 'common/util/stringSort';
import unique from 'common/util/unique';

import BoardURLName from './BoardURLName';
import PostCategory from './PostCategory';
import PostContent from './PostContent';
import PostCustomField from './PostCustomField';
import { filterLabels } from '../util';

import type { Filter, Resource } from 'common/automations/constants';

export interface FormFilter extends Filter {
  id: string;
}

type FilterRowProps = {
  allowedFilters: Resource[];
  filter: FormFilter;
  filters: Filter[];
  onResourceChange: (id: string, resource: Resource) => void;
  onRemove: (id: string) => void;
  onFilterChange: (condition: FormFilter['condition'], value: FormFilter['value']) => void;
  required: boolean;
};

const FilterRow = ({
  allowedFilters,
  filter,
  filters,
  onResourceChange,
  onFilterChange,
  onRemove,
  required,
}: FilterRowProps) => {
  const resourceDropdownOptions = unique([...allowedFilters, filter.resource])
    .map((filter) => ({
      label: filterLabels[filter],
      value: filter,
    }))
    .sort(stringSort('label', 'asc', true));

  return (
    <div className="filterRow">
      {required ? (
        <div className="textCircle filterName">{filterLabels[filter.resource]}</div>
      ) : (
        <SingleSelect
          className="filterName"
          options={resourceDropdownOptions}
          // Assert these types, since the select is not clearable
          onChange={(option) => option && onResourceChange(filter.id, option.value as Resource)}
          placeholder="Select a condition"
          value={{ label: filterLabels[filter.resource], value: filter.resource }}
          optionsMaxWidth="300px"
        />
      )}
      <ConditionField
        key={filter.id}
        resource={filter.resource}
        condition={filter.condition}
        filters={filters}
        value={filter.value}
        onChange={(condition, value) => onFilterChange(condition, value)}
        required={required}
      />
      {required ? null : (
        <IconButtonV2
          className="deleteButton"
          size="medium"
          variant="plain"
          icon={Minus}
          aria-label="Remove condition"
          onClick={() => onRemove(filter.id)}
        />
      )}
    </div>
  );
};

interface ConditionFieldProps<F extends Filter> {
  resource: F['resource'];
  condition: F['condition'];
  filters: Filter[];
  value: F['value'];
  onChange: <OcF extends Filter = F>(condition?: OcF['condition'], value?: OcF['value']) => void;
  required: boolean;
}

const ConditionField = ({ filters, required, ...props }: ConditionFieldProps<Filter>) => {
  if (isFilterBoardURLName(props)) {
    const { value, onChange } = props;
    return <BoardURLName value={value} onChange={onChange} required={required} />;
  } else if (isFilterPostCategory(props)) {
    const { value, onChange } = props;
    return <PostCategory value={value} onChange={onChange} required={required} filters={filters} />;
  } else if (isFilterPostContent(props)) {
    const { condition, value, onChange } = props;
    return (
      <PostContent condition={condition} value={value} onChange={onChange} required={required} />
    );
  } else if (isFilterCustomField(props)) {
    const { condition, value, onChange } = props;
    return (
      <PostCustomField
        condition={condition}
        value={value}
        onChange={onChange}
        required={required}
      />
    );
  } else {
    return null;
  }
};

export default FilterRow;

import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { IntegrationCards } from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { Integrations as AutopilotIntegrations } from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';

import type { Company } from 'common/api/endpoints/companies';
import type { Integrations } from 'common/constants/billing';

export const getEnabledIntegrationCards = (activeIntegrations: Integrations) => {
  return Object.entries(IntegrationCards)
    .filter(([key]) => !!activeIntegrations[key as keyof Integrations])
    .map(([_key, value]) => value);
};

export const getEnabledSyncIntegrationMap: (company: Company) => Record<string, boolean> = (
  company
) => {
  return company.installedSyncIntegrations.reduce((map, { disabled, integrationName }) => {
    return {
      ...map,
      [integrationName]: !disabled,
    };
  }, {});
};

export const getEnabledAutopilotIntegrations = (company: Company) => {
  const companyIntegrations = getEnabledSyncIntegrationMap(company);
  return AutopilotIntegrations.filter(({ name }) => companyIntegrations[name]);
};

export const getEnabledSyncIntegrations = (company: Company) => {
  const enabledSyncIntegrations: SyncIntegrationNames[] = company.installedSyncIntegrations
    .filter(
      ({ disabled, integrationName }) => !disabled && integrationName !== SyncIntegrationNames.api
    )
    .map(({ integrationName }: { integrationName: SyncIntegrationNames }) => integrationName);

  return enabledSyncIntegrations;
};

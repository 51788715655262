import React, { useContext, useRef, useState } from 'react';

import {
  Filter,
  GalleryVerticalEnd,
  PlusIcon,
  SettingsIcon,
  ShieldAlert,
  TerminalSquare,
} from 'lucide-react';

import { reloadCompany } from 'common/actions/company';
import Portal from 'common/common/Portal';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H1, Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueSidebar.module.scss';

import AdminQueueFiltersModal from './AdminQueueFiltersModal';
import AdminQueueSourceFilterItem, {
  type StaticSourceItemProps,
} from './AdminQueueSourceFilterItem';
import AutopilotUsageCard from './AutopilotUsageCard';
import useFilterControls from '../useFilterControls';

import AppStoreLogo from 'img/app-store-logo-small.svg';
import CannyLogo from 'img/canny-icon.svg';
import CapterraLogo from 'img/capterra-logo-small.svg';
import FreshdeskLogo from 'img/freshdesk-logo-small.svg';
import G2Logo from 'img/g2-logo-small.svg';
import GongLogo from 'img/gong-logo.png';
import GooglePlayLogo from 'img/google-play-logo-small.svg';
import HelpscoutLogo from 'img/helpscout-logo-small.png';
import IntercomLogo from 'img/intercom-logo-small.png';
import ProductHuntLogo from 'img/product-hunt-logo-small.svg';
import SalesforceLogo from 'img/salesforce-text-logo-small.svg';
import ShopifyLogo from 'img/shopify-logo-small.svg';
import TLDVLogo from 'img/tldv-logo-small.svg';
import TrustpilotLogo from 'img/trustpilot-logo-small.svg';
import TrustRadiusLogo from 'img/trustradius-logo-small.svg';
import WordpressLogo from 'img/wordpress-logo-small.svg';
import ZapierLogo from 'img/zapier-logo-square-medium.png';
import ZendeskLogo from 'img/zendesk-logo-small.png';
import ZoomLogo from 'img/zoom-logo-small.png';

import type { Company } from 'common/api/endpoints/companies';
import type { Dispatch, State } from 'redux-connect';

type OwnProps = {
  company: Company;
  counts: {
    totalCount: number;
    cannyCount: number;
    totalSourceCount: number;
    perSourceCounts: Partial<Record<SyncIntegrationNames, number>>;
    spam: number;
  };
};

type ConnectProps = {
  queryState: Record<string, string> | null;
  reloadCompany: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const StaticSourcePropsMap: Record<SyncIntegrationNames, StaticSourceItemProps> = {
  [SyncIntegrationNames.api]: {
    label: 'API',
    icon: TerminalSquare,
  },
  [SyncIntegrationNames.appStoreReviews]: { label: 'App Store', imgSrc: AppStoreLogo },
  [SyncIntegrationNames.capterraReviews]: { label: 'Capterra', imgSrc: CapterraLogo },
  [SyncIntegrationNames.freshdesk]: { label: 'Freshdesk', imgSrc: FreshdeskLogo },
  [SyncIntegrationNames.gong]: {
    label: 'Gong',
    imgSrc: GongLogo,
  },
  [SyncIntegrationNames.g2Reviews]: { label: 'G2', imgSrc: G2Logo },
  [SyncIntegrationNames.helpscout]: { label: 'Help Scout', imgSrc: HelpscoutLogo },
  [SyncIntegrationNames.intercom]: { label: 'Intercom', imgSrc: IntercomLogo },
  [SyncIntegrationNames.playStoreReviews]: { label: 'Google Play', imgSrc: GooglePlayLogo },
  [SyncIntegrationNames.productHuntReviews]: { label: 'Product Hunt', imgSrc: ProductHuntLogo },
  [SyncIntegrationNames.salesforceReviews]: { label: 'Salesforce', imgSrc: SalesforceLogo },
  [SyncIntegrationNames.shopifyReviews]: { label: 'Shopify', imgSrc: ShopifyLogo },
  [SyncIntegrationNames.tldv]: { label: 'TLDV', imgSrc: TLDVLogo },
  [SyncIntegrationNames.trustpilotReviews]: { label: 'Trustpilot', imgSrc: TrustpilotLogo },
  [SyncIntegrationNames.trustradiusReviews]: { label: 'TrustRadius', imgSrc: TrustRadiusLogo },
  [SyncIntegrationNames.wordpressReviews]: { label: 'WordPress', imgSrc: WordpressLogo },
  [SyncIntegrationNames.zendesk]: { label: 'Zendesk', imgSrc: ZendeskLogo },
  [SyncIntegrationNames.zapier]: {
    label: 'Zapier',
    imgSrc: ZapierLogo,
  },
  [SyncIntegrationNames.zoom]: { label: 'Zoom', imgSrc: ZoomLogo },
};

enum Modal {
  filters = 'filters',
  sources = 'sources',
}

const AdminQueueSidebar = ({ company, counts, queryState, reloadCompany }: Props) => {
  const router = useContext(RouterContext);

  const [modalState, setModalState] = useState<Modal | null>(null);

  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const sidebarPortalRef = useRef<Portal>(null);

  const { queryParams, updateFilters } = useFilterControls({
    queryState,
    reloadCompany,
  });

  useBackgroundClick(() => {
    if (modalState) {
      setModalState(null);
    }
  }, [filtersButtonRef, sidebarPortalRef]);

  const resetSourceFilters = () => {
    updateFilters({ source: undefined });
  };

  const setSourceFilter = (source: string) => {
    // navigation breaks useBackgroundClick hook, so we need to manually close the modal
    setModalState(null);

    // reset sort and sortDirection when switching sources:
    // - prevents compatibility issues between spam and AI sources
    // - prevents sort for source being applied when viewing just one source
    updateFilters({ source, sort: undefined, sortDirection: undefined });
  };

  const isSelected = (source: string) => queryParams.source === source;

  return (
    <aside className={styles.adminQueueSidebar}>
      <header className={styles.adminQueueSidebarHeader}>
        <H1 variant="headingSm">Sources</H1>
        <div className={styles.settingsButtonLayout}>
          <IconButtonV2
            aria-label="Source settings"
            onClick={() => router.push('/admin/settings/autopilot')}
            icon={SettingsIcon}
            size="medium"
            variant="outlined"
          />
          <IconButtonV2
            ref={filtersButtonRef}
            aria-label="Autopilot filters"
            icon={Filter}
            onClick={() => setModalState(modalState === Modal.filters ? null : Modal.filters)}
            size="medium"
          />
        </div>
      </header>
      <ul className={styles.adminQueueSidebarSourceList}>
        <AdminQueueSourceFilterItem
          label="All sources"
          icon={GalleryVerticalEnd}
          count={counts.totalCount}
          onClick={resetSourceFilters}
          selected={!queryParams.source}
        />
        <AdminQueueSourceFilterItem
          label="Canny"
          imgSrc={CannyLogo}
          count={counts.cannyCount}
          onClick={() => setSourceFilter('canny')}
          paused={!company.deduplication?.enabled}
          selected={isSelected('canny')}
        />
        {company.installedSyncIntegrations.map(({ integrationName, disabled }) => (
          <AdminQueueSourceFilterItem
            key={integrationName}
            {...StaticSourcePropsMap[integrationName]}
            count={counts.perSourceCounts[integrationName] ?? 0}
            onClick={() => setSourceFilter(integrationName)}
            paused={disabled}
            selected={isSelected(integrationName)}
          />
        ))}
        {company.features.moderationTools ? (
          <AdminQueueSourceFilterItem
            label="Spam"
            icon={ShieldAlert}
            count={counts.spam}
            onClick={() => setSourceFilter('spam')}
            selected={isSelected('spam')}
          />
        ) : null}
      </ul>
      <button
        className={styles.addSourceButton}
        onClick={() => router.push('/admin/settings/autopilot/feedback-discovery')}>
        <PlusIcon className={styles.sourceIcon} />
        <Span className={styles.label}>Add source</Span>
      </button>
      <div className={styles.autopilotUsageContainer}>
        <AutopilotUsageCard company={company} />
      </div>
      {modalState && (
        <Portal
          className="adminQueueSidebarModalPortal"
          align="start"
          position="bottom"
          ref={sidebarPortalRef}
          relativeToRef={filtersButtonRef}>
          {modalState === Modal.filters ? (
            <AdminQueueFiltersModal queryState={queryState} reloadCompany={reloadCompany} />
          ) : null}
        </Portal>
      )}
    </aside>
  );
};

// TODO: remove cast after `connect` is typed
export default connect(
  (state: State) => ({
    queryState: state.routing.locationBeforeTransitions.query,
  }),
  (dispatch: Dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })
)(AdminQueueSidebar) as unknown as React.FC<OwnProps>;

import React from 'react';

import classNames from 'classnames';
import { useSelect } from 'downshift';

import Label from 'common/ui/common/Label';
import LabelWrapper from 'common/ui/common/select/LabelWrapper';
import OptionPrefix from 'common/ui/common/select/OptionPrefix';
import RightIcons from 'common/ui/common/select/RightIcons';
import { type CommonSelectProps, type Option } from 'common/ui/common/select/SelectCommon';
import SelectOptions from 'common/ui/common/select/SelectOptions';
import SupportingTexts from 'common/ui/common/select/SupportingTexts';
import { sortByGroupBy } from 'common/ui/common/select/util';

import 'css/components/_SelectCommon.scss';

export interface Props extends CommonSelectProps {
  onChange: (option?: Option) => void;
  value?: Option;
}

const SingleSelect = ({
  allowClear,
  className,
  cta,
  disabled,
  error,
  loading,
  label,
  labelPlacement = 'top',
  onChange,
  options,
  optionsMaxWidth,
  optionsPlacement = 'bottom',
  placeholder,
  prefixIcon: PrefixIcon,
  required = false,
  supportingText,
  value,
  withBorder = true,
}: Props) => {
  const sortedOptions = sortByGroupBy(options);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: cta ? [...sortedOptions, cta.option] : sortedOptions,
    selectedItem: value || null,
    itemToString: (item) => (item ? String(item.label) : ''),
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (cta && newSelectedItem?.value === cta.option.value) {
        cta.onAction();
        return;
      }
      onChange(newSelectedItem ?? undefined);
    },
  });

  return (
    <div className={classNames('dropdownV2Wrapper', className)}>
      <LabelWrapper labelPlacement={labelPlacement}>
        {label && <Label {...getLabelProps()} label={label} />}
        <div
          className={classNames('dropdownV2 singleSelect', {
            disabledV2: disabled,
            errorV2: error,
            withBorder,
          })}>
          <div className="dropdownWrapperV2">
            <div
              className="dropdownHeadWrapper"
              aria-required={required}
              aria-label="toggle menu"
              {...getToggleButtonProps({ disabled })}>
              <div className="leftWrapper">
                {PrefixIcon && <PrefixIcon className="prefixIcon" size={14} />}
                {value ? (
                  <>
                    <OptionPrefix
                      flair={value.flair}
                      avatar={value.avatar}
                      prefixIcon={value.prefixIcon}
                      disabled={disabled}
                    />
                    <span className="selectedItem">{value.label}</span>
                  </>
                ) : (
                  <span className="dropdownPlaceholder">{placeholder}</span>
                )}
              </div>
              <RightIcons
                aria-label="remove selection"
                disabled={disabled}
                loading={loading}
                onClear={onChange}
                showClear={allowClear && !!value}
                error={error}
              />
            </div>
            <SelectOptions
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
              ctaOption={cta?.option}
              optionsMaxWidth={optionsMaxWidth}
              options={sortedOptions}
              selectedItems={value && [value]}
              isOpen={isOpen}
              optionsPlacement={optionsPlacement}
              disabled={disabled}
              getMenuProps={getMenuProps}
            />
          </div>
          <SupportingTexts supportingText={supportingText} error={!disabled ? error : undefined} />
        </div>
      </LabelWrapper>
    </div>
  );
};

export default SingleSelect;

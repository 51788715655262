import expandRangeToWord from './expandRangeToWord';
import updateRangeThenSelectRange from './updateRangeThenSelectRange';

// '**foo**' and '**' results in 'foo'
// 'foo' and '**' results in '**foo**'
export default function addOrRemoveSurroundingCharacters(autoResizeTextarea, before, after) {
  const selectionRange = autoResizeTextarea.getSelectionRange();
  const range = Object.assign({}, selectionRange);
  const value = autoResizeTextarea.getValue();

  expandRangeToWord(range, value);

  const selection = value.substring(range.start, range.end);
  const beforeSelection = value.substring(0, range.start);
  const afterSelection = value.substring(range.end, value.length);

  const hasCharactersInside = selection.startsWith(before) && selection.endsWith(after);
  if (hasCharactersInside) {
    const updatedValue = selection.substring(before.length, selection.length - after.length);
    updateRangeThenSelectRange(autoResizeTextarea, range, updatedValue, {
      start: range.start,
      end: range.end - before.length - after.length,
    });
    return;
  }

  const hasCharactersOutside = beforeSelection.endsWith(before) && afterSelection.startsWith(after);
  if (hasCharactersOutside) {
    const updatedValue = selection;
    updateRangeThenSelectRange(
      autoResizeTextarea,
      {
        start: range.start - before.length,
        end: range.end + after.length,
      },
      updatedValue,
      {
        start: range.start - before.length,
        end: range.end - before.length,
      }
    );
    return;
  }

  const updatedValue = before + selection + after;
  updateRangeThenSelectRange(
    autoResizeTextarea,
    {
      start: range.start,
      end: range.end,
    },
    updatedValue,
    {
      start: range.start + before.length,
      end: range.end + before.length,
    }
  );
}

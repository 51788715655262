import React, { useContext } from 'react';

import { Sparkles } from 'lucide-react';

import { permissionNames } from 'common/bae/constants';
import Accordion from 'common/common/Accordion';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { getFreeTierPermissions } from 'common/roles/utils';
import CheckboxWithLabel from 'common/ui/CheckboxWithLabel';
import IconBadge from 'common/ui/IconBadge';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';

type Props = {
  section: string;
  permissions: Partial<Record<RolePermissionName, boolean>>;
  toggleSection: (isChecked: boolean) => void;
  togglePermission: (permission: string, isChecked: boolean) => void;
};

// if all permissions are checked, the top level checkbox should be checked
const isTopLevelChecked = (permissions: Partial<Record<RolePermissionName, boolean>>) =>
  Object.values(permissions).every((value) => value);

const PermissionsAccordion = ({ section, permissions, togglePermission, toggleSection }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const freeTierPermissions = getFreeTierPermissions(company);
  const topLevelChecked = isTopLevelChecked(permissions);

  return (
    <Accordion
      showDivider
      title={
        <CheckboxWithLabel
          checked={topLevelChecked}
          className="headerCheckbox roleCheckbox"
          size="medium"
          aria-label={`Checkbox to toggle all ${section} permissions`}
          onChange={toggleSection}>
          {section}
        </CheckboxWithLabel>
      }>
      <div className="checkboxGrid">
        {Object.entries(permissions).map(([permission, checked]) => (
          <div className="permissionRow" key={permission}>
            <CheckboxWithLabel
              checked={checked}
              className="roleCheckbox"
              size="medium"
              aria-label={`Checkbox to allow users to ${permission}`}
              onChange={(checked) => togglePermission(permission, checked)}>
              {permissionNames[permission as RolePermissionName]}
            </CheckboxWithLabel>
            {freeTierPermissions.includes(permission) && (
              <Tooltip value="Free-tier permission">
                <IconBadge active={checked} icon={Sparkles} size="small" />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </Accordion>
  );
};

export default PermissionsAccordion;

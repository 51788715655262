type User = {
  _id: string;
  avatarURL: string;
  email: string | null;
};

export enum Trigger {
  postCreation = 'postCreation',
  postStatusChange = 'postStatusChange',
}

export enum Resource {
  postBoardURLName = 'postBoardURLName',
  postCategory = 'postCategory',
  postContent = 'postContent',
  postCustomField = 'postCustomField',
}

export enum ActionType {
  addPostTags = 'addPostTags',
  addPostCategory = 'addPostCategory',
  addToRoadmap = 'addToRoadmap',
  applyPostStatus = 'applyPostStatus',
}

export interface Action {
  type: ActionType;
  properties: unknown;
}

export interface Filter {
  resource: Resource;
  condition: unknown;
  value: unknown;
}

export enum FiltersOperator {
  all = 'all',
  any = 'any',
}

export type Automation = {
  _id: string;
  actions: Action[];
  appliedCount: number;
  created: Date;
  createdBy: User | null;
  enabled: boolean;
  filters: Filter[];
  filtersOperator: FiltersOperator;
  title: string;
  trigger: Trigger;
};

export type AutomationListParameters = Record<string, never>;

export type AutomationListResponse = {
  result: Automation[];
};

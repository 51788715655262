import React, { useContext, useEffect, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import OIDCSettingsForm from './Form';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/AdminOIDCSettings/_AdminOIDCSettings.scss';

const HelpGuideLink = 'https://help.canny.io/en/articles/8047570-openid-connect-sso-integration';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

const Info = () => (
  <div className="infoSection">
    <h2 className="subheading">Authenticate your teammates into Canny</h2>
    <p>Grant your teammates access to Canny by signing in via your OpenID Connect provider.</p>
    <p>
      Enter your OpenID Connect details to install the integration. Check out our{' '}
      <a className="link" href={HelpGuideLink} target="_blank" rel="nofollow noreferrer noopener">
        setup guide
      </a>{' '}
      for more info.
    </p>
  </div>
);

const Upsell = ({ planSupportsOIDC }: { planSupportsOIDC: boolean }): React.ReactElement | null => {
  if (planSupportsOIDC) {
    return null;
  }

  return (
    <AdminFeatureBlock
      feature="OpenID Connect Integration"
      benefit="Authenticate your teammates as Canny Admins."
      showBillingLink={false}
    />
  );
};

const AdminOIDCSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const openModal = useContext(OpenModalContext);
  const showToast = useContext(ShowToastContext);

  const [error, setError] = useState<string | null>(null);
  const [testPassed, setTestPassed] = useState<boolean>(false);

  const planSupportsOIDC = !!company.integrations?.oidc;

  useEffect(() => {
    if (testPassed) {
      showToast('Test passed! Your OpenID Connect integration is working.', ToastTypes.success);
      setTestPassed(false);
    }
  }, [showToast, testPassed]);

  return (
    <div className="adminOIDCSettings">
      <Helmet title="OpenID Connect Integration | Canny" />
      <h1 className="settingsHeading">OpenID Connect Integration</h1>
      <div className="content">
        {planSupportsOIDC && (
          <>
            <Info />
            <OIDCSettingsForm
              company={company}
              openModal={openModal}
              reloadCompany={reloadCompany}
              setError={setError}
              setTestPassed={setTestPassed}
            />
          </>
        )}
        {error ? <p className="error text">{error}</p> : null}
        <Upsell planSupportsOIDC={planSupportsOIDC} />
      </div>
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.oidc),
    '/admin/settings'
  )
)(AdminOIDCSettings) as unknown as React.FC<OwnProps>;

import React from 'react';

import classnames from 'classnames';

import { CustomFieldTypes } from 'common/customPostFields/Constants';
import CustomPostFieldInput from 'common/post/CustomPostFieldInput';
import { P } from 'common/ui/Text';

import FieldError from './FieldError';

import type { FormErrors, PostForm } from './utils';
import type { Board } from 'common/api/endpoints/boards';

type Props = {
  board: Board;
  onFormChange: (edits: Partial<PostForm>) => void;
  form: PostForm;
  errors: FormErrors;
};

const PostFieldInputs = ({ board, onFormChange, form, errors }: Props) => {
  const boardFieldsWithoutOptions = board.boardFields
    .filter((boardField) => !CustomFieldTypes[boardField.type]?.hasOptions)
    .sort((a, b) => a.order - b.order);

  if (!boardFieldsWithoutOptions.length) {
    return null;
  }

  return (
    <div className="createPostFormSection postForm second">
      {boardFieldsWithoutOptions.map((boardField) => (
        <div
          key={boardField._id}
          className={classnames('postField', {
            required: boardField.required,
            errored: errors.customFieldValuesMap?.[boardField.customPostFieldID],
          })}>
          <label className="label">
            <P variant="bodyMd" fontWeight="medium">
              {boardField.label}
            </P>
          </label>
          <CustomPostFieldInput
            className="postFieldInput"
            postField={{
              ...boardField,
              value: form.customFieldValuesMap[boardField.customPostFieldID],
            }}
            onChange={(value: string) =>
              onFormChange({
                customFieldValuesMap: {
                  ...form.customFieldValuesMap,
                  [boardField.customPostFieldID]: value,
                },
              })
            }
          />
          <FieldError error={errors.customFieldValuesMap?.[boardField.customPostFieldID]} />
        </div>
      ))}
    </div>
  );
};

export default PostFieldInputs;

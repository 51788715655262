import { getPostQueryKey, mapQueryFilterParamsToRequestData } from 'common/util/filterPosts';
import isServer from 'common/util/isServer';

import { loadCompany } from './company';
import { postsLoaded } from './posts';
import { loadWidgetData } from './widget';
import Data from '../Data';

// Action Types

export const RequestQuery = 'canny/post_queries/request_query';
export const QueryLoaded = 'canny/post_queries/query_loaded';
export const QueryError = 'canny/post_queries/query_error';
export const LoadingMore = 'canny/post_queries/loading_more';
export const Invalidate = 'canny/post_queries/invalidate';

export const MaxPages = 50;

// Actions

function requestQuery(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestQuery,
  };
}

function queryLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: QueryLoaded,
  };
}

function queryError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QueryError,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LoadingMore,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return (dispatch, getState) => {
    const state = getState();
    const promises = [];
    if (state.widget) {
      promises.push(dispatch(loadWidgetData()));
    } else {
      promises.push(dispatch(loadCompany()));
    }

    return Promise.all(promises).then(() => {
      const state = getState();
      const company = state.widget ? state.widget.company : state.company;
      if (company.error || company.notFound) {
        return dispatch(queryError(queryParams, 'company load failed'));
      }

      let query = Data.queries.posts;
      if (queryParams.query) {
        query = Data.queries[queryParams.query];
      }

      const requestData = mapQueryFilterParamsToRequestData(queryParams, company);
      if (requestData.boardURLNames && requestData.boardURLNames.length === 0) {
        return gotResult(dispatch, queryParams, null, {
          result: {
            hasNextPage: false,
            posts: [],
          },
        });
      }

      const cookies = getState().cookies;
      return Data.fetch(
        {
          result: {
            input: requestData,
            query,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, queryParams, error, data);
        }
      );
    });
  };
}

export function loadQuery(queryParams, options = { waitForResult: true }) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      if (options.waitForResult) {
        dispatch(requestQuery(queryParams));
        return dispatch(fetchQuery(queryParams));
      } else {
        const result = dispatch(requestQuery(queryParams));
        if (!isServer()) {
          dispatch(fetchQuery(queryParams));
        }
        return result;
      }
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(postList) {
  return (dispatch, getState) => {
    const queryParams = postList.queryParams;
    dispatch(loadingMore(queryParams));

    queryParams.pages = Math.min(queryParams.pages ? queryParams.pages + 1 : 2, MaxPages);
    return dispatch(fetchQuery(queryParams));
  };
}

export function invalidatePostQueries() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

function shouldFetchQuery(state, queryParams) {
  const postQueries = state.postQueries;
  if (postQueries.needsPurge) {
    return true;
  }
  const queryKey = getPostQueryKey(queryParams);
  return !postQueries[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const postQueries = state.postQueries;
  const queryKey = getPostQueryKey(queryParams);
  return !!postQueries[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getPostQueryKey(queryParams);
  return new Promise((resolve, reject) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, queryParams, error, data) {
  var resultAction;
  const promises = [];
  if (error) {
    resultAction = queryError(queryParams, error);
  } else {
    resultAction = queryLoaded(queryParams, data.result);
    if (data.result.posts) {
      promises.push(dispatch(postsLoaded(data.result.posts)));
    }
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      const queryKey = getPostQueryKey(queryParams);
      if (!resultCallbacks[queryKey]) {
        return;
      }

      resultCallbacks[queryKey].forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks[queryKey].length = 0;
    });
  });
}

import {
  InvalidateAll,
  InvalidateAndReload,
  QueryError,
  QueryLoaded,
  RequestQuery,
  type ThirdPartyCompanyFeatureRequestsAction,
  getQueryKey,
} from '../actions/thirdPartyCompanyFeatureRequestsQueries';

import type { CompanyWithFeatureRequests } from 'common/api/endpoints/thirdPartyCompanyFeatureRequests';

export type State = {
  updatedAt: Date | null;
  queries: {
    [queryKey: string]: {
      error: string;
      items: CompanyWithFeatureRequests[];
      updatedAt: Date;
      loading: boolean;
    };
  };
};

const DefaultState: State = {
  updatedAt: null,
  queries: {},
};

export default function thirdPartyCompanyFeatureRequestQueries(
  state: State = DefaultState,
  action: ThirdPartyCompanyFeatureRequestsAction
) {
  switch (action.type) {
    case RequestQuery: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        updatedAt: state.updatedAt,
        queries: {
          ...state.queries,
          [queryKey]: {
            ...state.queries[queryKey],
            updatedAt: action.timestamp,
            error: null,
            loading: true,
          },
        },
      };

      return newState;
    }

    case QueryLoaded: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        updatedAt: action.timestamp,
        queries: {
          ...state.queries,
          [queryKey]: {
            ...state.queries[queryKey],
            items: action.result,
            updatedAt: action.timestamp,
            loading: false,
          },
        },
      };

      return newState;
    }

    case QueryError: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        updatedAt: state.updatedAt,
        queries: {
          [queryKey]: {
            ...state.queries[queryKey],
            error: action.error,
            updatedAt: action.timestamp,
            loading: false,
          },
        },
      };

      return newState;
    }

    case InvalidateAndReload: {
      const queryKey = getQueryKey(action.queryParams);
      const newState = {
        updatedAt: state.updatedAt,
        queries: {
          [queryKey]: null,
        },
      };

      return newState;
    }

    case InvalidateAll: {
      const newState = {
        updatedAt: state.updatedAt,
        queries: {},
      };

      return newState;
    }

    default:
      return state;
  }
}

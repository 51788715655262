import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import Strings from 'common/Strings';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import hasPermission from 'common/util/hasPermission';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminUserEmailSettingsDomain.scss';

const getStatus = (verified, created) => {
  const createdAgo = new Date().getTime() - new Date(created).getTime();
  if (verified) {
    return 'authenticated';
  } else if (createdAgo > 259_200_000) {
    // 72 hours
    return 'expired';
  }
  return 'unauthenticated';
};

class AdminUserEmailSettingsDomain extends Component {
  static propTypes = {
    company: PropTypes.shape({
      emailDomain: PropTypes.object,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    creating: false,
    deleting: false,
    enabled: this.props.company.emailDomain?.enabled,
    enabling: false,
    verifying: false,
  };

  constructor(props, context) {
    super(props, context);

    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
  }

  componentDidMount() {
    const { company, openModal, router, viewer } = this.props;
    if (hasPermission('manageCustomDomains', company, viewer)) {
      return;
    }

    router.replace('/admin/settings/user-emails/types');
    openModal(
      AccessModal,
      {
        requiredPermissions: ['manageCustomDomains'],
      },
      {
        allowRouteChange: false,
      }
    );
  }

  onDelete = async () => {
    this.setState({ deleting: true });

    const response = await AJAX.post('/api/emails/delete');

    if (response !== 'success') {
      this.setState({
        deleting: false,
        error: Strings.miscError,
      });
      return;
    }

    await this.props.reloadCompany();

    this.setState({ deleting: false, error: null });
  };

  onEnable = async (enabled) => {
    this.setState({ enabling: true });

    const response = await AJAX.post('/api/emails/enable', {
      enabled,
    });

    if (response !== 'success') {
      this.setState({ error: Strings.miscError });
    } else {
      this.setState({ error: null });
    }

    await this.props.reloadCompany();
    const { emailDomain } = this.props.company;

    // flush toggle state
    this.setState({ enabled: !emailDomain.enabled });
    this.setState({ enabled: emailDomain.enabled, enabling: false });
  };

  onSubmit = async () => {
    const name = this.nameRef.current.getValue().trim();
    const email = this.emailRef.current.getValue().trim();

    let error;
    if (!name) {
      error = 'Please enter a valid name';
    } else if (!email || !validateInput.email(email)) {
      error = 'Please enter a valid email';
    }

    if (error) {
      this.setState({ error });
      return;
    }

    this.setState({ creating: true });

    const response = await AJAX.post('/api/emails/create', {
      email,
      name,
    });

    if (response !== 'success') {
      this.setState({
        creating: false,
        error: Strings.miscError,
      });
      return;
    }

    await this.props.reloadCompany();

    this.setState({ creating: false, error: null });
  };

  onVerifyRecords = async () => {
    this.setState({ verifying: true });

    const responseJSON = await AJAX.post('/api/emails/verify');

    let response;
    try {
      response = JSON.parse(responseJSON);
    } catch (error) {
      this.setState({ error: Strings.miscError, verifying: false });
      return;
    }

    if (response.error) {
      this.setState({ error: Strings.miscError, verifying: false });
      return;
    }

    await this.props.reloadCompany();

    if (!response.verified) {
      const error =
        'Verifying domain records may take a few minutes, check back in a few minutes. If still unverified, please reach out to support.';
      this.setState({ error, verifying: false });
      return;
    }

    this.setState({ error: null, verifying: false });
  };

  renderDelete() {
    const { deleting } = this.state;
    if (deleting) {
      return <Spinner />;
    }

    return (
      <Tappable onTap={this.onDelete}>
        <span className="icon icon-x" />
      </Tappable>
    );
  }

  renderDomain() {
    const { emailDomain } = this.props.company;
    const { creating } = this.state;

    if (emailDomain) {
      const { created, name, email, verified } = emailDomain;
      const status = getStatus(verified, created);
      return (
        <div className="domain">
          <div className="text">
            {name} ({email})
          </div>
          {this.renderStatus(status)}
          <div className="spacer" />
          <div className="deleteDomain">{this.renderDelete()}</div>
        </div>
      );
    }

    return (
      <Form className="form" disableSubmit={creating} onSubmit={this.onSubmit.bind(this)}>
        <div className="inputs">
          <TextInput
            className="nameInput"
            inset="Name"
            placeholder="Acme Updates"
            ref={this.nameRef}
          />
          <TextInput
            className="emailInput"
            inset="Email"
            placeholder="updates@acme.com"
            ref={this.emailRef}
          />
        </div>
        {this.renderError()}
        <Button buttonType="cannyButton" formButton loading={creating} value="Continue" />
      </Form>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderRecords() {
    const { emailDomain } = this.props.company;
    if (!emailDomain) {
      return;
    }

    const { created, dkimTokens, domain, verificationToken, verified } = emailDomain;
    const records = [
      {
        type: 'TXT',
        name: `_amazonses.${domain}`,
        value: verificationToken,
      },
      ...dkimTokens.map((dkimToken) => ({
        type: 'CNAME',
        name: `${dkimToken}._domainkey.${domain}`,
        value: `${dkimToken}.dkim.amazonses.com`,
      })),
    ];
    return (
      <div className="records">
        <div className="description">
          Great! Now, give Canny permission to send emails on your behalf by authenticating this
          domain. You will need admin access to log into your domain provider (AWS, Cloudflare,
          etc.) and add the following four records in your DNS&nbsp;settings:
        </div>
        <div className="description smallPadding">
          You have 72 hours to complete the verification, otherwise your token will expire and you
          will have to start over.
        </div>
        {records.map((record) => {
          const { name, type, value } = record;
          return (
            <div className="record" key={name}>
              <div className="row type">Type: {type}</div>
              <div className="row name">Name: {name}</div>
              <div className="row value">Value: {value}</div>
              <div className="row recordStatus">
                Status: {this.renderStatus(getStatus(verified, created))}
              </div>
            </div>
          );
        })}
        {this.renderVerifyRecords()}
      </div>
    );
  }

  renderVerifyRecords() {
    const { emailDomain } = this.props.company;
    if (emailDomain?.verified) {
      return;
    }

    const { verifying } = this.state;
    return (
      <div className="verifyRecords">
        {this.renderError()}
        <Button
          buttonType="cannyButton"
          loading={verifying}
          onTap={this.onVerifyRecords.bind(this)}
          value="Verify Records"
        />
      </div>
    );
  }

  renderStatus(status) {
    return <UppercaseHeader className={`status ${status}`}>{status}</UppercaseHeader>;
  }

  renderToggle() {
    const { emailDomain } = this.props.company;
    if (!emailDomain?.verified) {
      return;
    }

    const { enabled, enabling } = this.state;
    return (
      <div className="enableDomain">
        {this.renderError()}
        <div className="toggleRow">
          <Toggle disabled={enabling} onToggle={this.onEnable} value={enabled} />
          {enabling && <Spinner />}
          <div className="text">Send emails from {emailDomain.email}</div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { company } = this.props;
    if (!company?.features?.customEmailDomain) {
      return (
        <AdminFeatureBlock
          benefit="Available on the Canny Business plan—contact us for details."
          feature="Custom email address"
          showBillingLink={false}
        />
      );
    }

    return (
      <>
        <div className="description">
          By default, user emails are sent from “notifications@canny.io”. In order for Canny to send
          emails from your domain, you will need to authenticate your&nbsp;domain.
        </div>
        <div className="description">
          First, specify a name and email address you’d like the emails to come&nbsp;from.
        </div>
        {this.renderDomain()}
        {this.renderRecords()}
        {this.renderToggle()}
      </>
    );
  }

  render() {
    return (
      <div className="adminUserEmailSettingsDomain">
        <Helmet title="Custom Address | User Email Settings | Canny" />
        <div className="content">{this.renderContent()}</div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.notifications.domain),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminUserEmailSettingsDomain);

import AJAX from 'common/AJAX';

export default class OAuthGitHubRedirect {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab }) {
    this._onSuccess = onSuccess;
    this._onFailure = onFailure;
  }

  connect = (result) => {
    if (!result.code || !result.state) {
      this._onFailure();
      return;
    }

    var code = result.code;
    var state = result.state;

    AJAX.post(
      '/api/oauth/getGitHubAccessToken',
      {
        code: code,
        state: state,
      },
      this.onAccessTokenResponse
    );
  };

  onAccessTokenResponse = (response) => {
    try {
      var responseObject = JSON.parse(response);
    } catch (e) {
      this._onFailure('Server Error: Something went wrong, try again in a few minutes.');
      return;
    }

    if (responseObject.error) {
      this._onFailure('Response Error: ' + responseObject.error);
      return;
    }

    if (!responseObject.ppToken || !responseObject.githubUserID) {
      this._onFailure('Missing data: ' + responseObject);
      return;
    }

    this._onSuccess(responseObject);
  };
}

import React, { useRef, useState } from 'react';

import { type Dispatch } from 'redux';

import { type CompanyInfo, type Names, reloadTPCForFilters } from 'common/actions/tpcForFilters';
import connect from 'common/core/connect';
import TextInput from 'common/inputs/TextInput';
import SpinnerV2 from 'common/SpinnerV2';
import CountBadge from 'common/ui/CountBadge';
import emphasizeMatches from 'common/util/emphasizeMatches';
import findStringMatches from 'common/util/findStringMatches';
import isNil from 'common/util/isNil';
import stringSort from 'common/util/stringSort';
import useDelayer from 'common/util/useDelayer';
import styles from 'css-module/components/subdomain/admin/AdminSideBar/_CompanyFilterSection.module.scss';

type ConnectProps = {
  reloadCompanies?: (search?: string) => Promise<void>;
};

type OwnProps = {
  companyNames?: Names;
  loading: boolean;
  onCompanySelected: (company: CompanyInfo) => void;
  selections: string[];
};

type Props = OwnProps & ConnectProps;

const CompanyFilterInput = ({
  companyNames,
  loading,
  onCompanySelected,
  reloadCompanies,
  selections,
}: Props) => {
  const inputRef = useRef<TextInput>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const selectFilter = (filter?: CompanyInfo) => {
    if (filter) {
      inputRef.current?.blur();
      inputRef.current?.setValue('');
      setSearchTerm('');
      onCompanySelected(filter);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value.trim();
    setSearchTerm(search);
    if (search.length > 0) {
      searchDelayer(search);
    }
  };

  const searchDelayer = useDelayer(reloadCompanies, 300);

  const filteredSuggestions = Object.values(companyNames ?? {})
    .filter(({ urlName }) => {
      return !selections.includes(urlName);
    })
    .sort(stringSort('name'));

  // Our current TPC search doesn't do name parts and only prefix matches
  // purposefully match that search functionality by only returning prefix matches here
  const searchMatches = findStringMatches(filteredSuggestions, 'name', searchTerm, {
    prefixOnly: true,
  });

  return (
    <div className={styles.companyFilterSectionInputWrapper}>
      <TextInput
        onChange={onChange}
        placeholder="Search..."
        ref={inputRef}
        className={styles.companyFilterSectionInput}
      />
      <ul className={styles.recommendationsList}>
        {searchMatches.length === 0 ? (
          <li className={styles.recommendationsItem}>
            {loading ? (
              <div className={styles.companyFilterSectionSpinner}>
                <SpinnerV2 size="medium" />
              </div>
            ) : (
              <div className={styles.noRecommendations}>There are no matching&nbsp;companies</div>
            )}
          </li>
        ) : null}
        {searchMatches.map((company) => {
          const { name, urlName, memberCount } = company;
          return (
            <li className={styles.recommendationsItem} key={`${urlName}_${name}`}>
              <button
                onClick={() => selectFilter(company)}
                className={styles.recommendationsButton}>
                <div>{emphasizeMatches(name, searchTerm)}</div>
                {!isNil(memberCount) && <CountBadge size="small" count={memberCount} />}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompanies: (search: string) => dispatch(reloadTPCForFilters(search)),
}))(CompanyFilterInput);

const ValidHostnames = ['atlassian.net', 'jira.com'];

export const extractBaseURL = (url: string) => {
  if (!url) {
    return false;
  }

  let urlObj;

  try {
    urlObj = new URL(url);
  } catch (e) {
    return null;
  }

  const { hostname } = urlObj;
  const isHostnameValid = ValidHostnames.some((validHostname) => hostname.endsWith(validHostname));

  if (!isHostnameValid) {
    return null;
  }

  return `https://${hostname}`;
};

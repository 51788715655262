import React, { useState } from 'react';

import { invalidateSuggestions, loadSuggestions } from 'common/actions/asanaTaskSuggestions';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { Errors } from 'common/asana/Constants';
import connect from 'common/core/connect';
import AdminIntegrationSearchForm from 'common/subdomain/admin/AdminIntegrationSearchForm';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { AsanaSearchTask } from 'common/api/endpoints/asanaTasks';
import type { Post } from 'common/api/resources/posts';
import type { Dispatch, State } from 'redux-connect';

type OwnProps = {
  post: Post;
};

type ConnectProps = {
  loadSuggestions(searchQuery: string): void;
  reloadPost(post: Post): void;
  taskSuggestions: Array<{
    error: string | null;
    items: AsanaSearchTask[] | null;
    lastUpdated: number;
    loading: boolean;
  }>;
};

type Props = OwnProps & ConnectProps;

const PlanDoesNotSupportError = 'asana plan does not support search';

const AdminAsanaSearchForm = ({ loadSuggestions, post, reloadPost, taskSuggestions }: Props) => {
  // state
  const [error, setError] = useState<string | null>(null);

  // helpers
  const linkTask = async (task: AsanaSearchTask) => {
    setError(null);

    const response = await AJAX.post('/api/asana/linkTask', {
      postID: post._id,
      taskID: task.id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: Errors,
    });

    if (error) {
      setError(error.message);
      return;
    }

    await reloadPost(post);
  };

  // renders
  const renderError = () => (error ? <div className="error">{error}</div> : null);

  const renderSuggestionError = (error: string) =>
    error === PlanDoesNotSupportError ? (
      <>
        Your Asana plan only supports searching by task ID.
        <a
          className="articleLink"
          href="http://help.canny.io/en/articles/7065590-asana-integration"
          rel="noopener"
          target="_blank">
          Learn&nbsp;more
        </a>
        .
      </>
    ) : (
      error
    );

  const renderSuggestion = (suggestion: AsanaSearchTask) => suggestion?.name;

  return (
    <div className="adminLinearSearchForm">
      <AdminIntegrationSearchForm
        loadSuggestions={loadSuggestions}
        onSuggestionSelected={linkTask}
        placeholder={'Asana Task Name/ID'}
        renderError={renderSuggestionError}
        renderSuggestion={renderSuggestion}
        suggestions={taskSuggestions}
      />
      {renderError()}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default connect(
  (state: State) => ({ taskSuggestions: state.asanaTaskSuggestions }),
  (dispatch: Dispatch) => ({
    loadSuggestions: (searchQuery: string) => dispatch(loadSuggestions(searchQuery)),
    reloadPost: (post: Post) => {
      return Promise.all([dispatch(invalidateSuggestions()), dispatch(reloadPost(post))]);
    },
  })
)(AdminAsanaSearchForm) as unknown as React.FC<OwnProps>;

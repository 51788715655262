export const StarterV1PlanID = 'starter-1';
export const StarterV2PlanID = 'starter-2';
export const StarterV1AnnualPlanID = 'starter-1-annual';
export const StarterV2AnnualPlanID = 'starter-2-annual';
export const StarterPlanID = StarterV2PlanID;
export const StarterAnnualPlanID = StarterV2AnnualPlanID;

export const StarterPlanIDs = [
  StarterV1PlanID,
  StarterV2PlanID,
  StarterV1AnnualPlanID,
  StarterV2AnnualPlanID,
];

export default function isStarter(planID?: string | null) {
  if (!planID) {
    return false;
  }

  return StarterPlanIDs.includes(planID);
}

import React from 'react';

import { type HexColor } from 'common/colors/utils';
import ColorInput from 'common/inputs/ColorInput';
import { H3, P } from 'common/ui/Text';

import 'css/components/_BrandColorPicker.scss';

type Props = {
  color: HexColor;
  onColorPicked: (color: HexColor) => void;
};

const BrandColorPicker = ({ color, onColorPicked }: Props) => {
  return (
    <div className="brandColorPicker">
      <div className="left">
        <div className="introduction">
          <H3 resetStyles variant="headingSm">
            Brand color
          </H3>
          <P className="subtitle">
            Assign a brand color to your public feedback portal while ensuring accessibility with
            our contrast&nbsp;checker.{' '}
            <a
              className="learnMore"
              target="_blank"
              href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
              rel="noopener noreferrer">
              Learn more about&nbsp;contrast.
            </a>
          </P>
        </div>
      </div>
      <div className="colorPicker">
        <ColorInput defaultValue={color} onChange={onColorPicked} />
      </div>
    </div>
  );
};

export default BrandColorPicker;

import createEntry from 'common/core/createEntry';

import SubdomainApp from './SubdomainApp';
import SubdomainReducers from './SubdomainReducers';

const GA4MeasurementID = 'G-4YBGZZJ0F7';
const SentryDSN = '5a14965313ce49a2a55fe149e9e2e23e';

createEntry({
  app: SubdomainApp,
  ga4MeasurementID: GA4MeasurementID,
  reducers: SubdomainReducers,
  sentryDSN: SentryDSN,
});

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { LocationContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import Link from 'common/Link';
import UppercaseHeader from 'common/UppercaseHeader';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_PublicNavBoardDropdown.scss';

class PublicNavBoardDropdown extends Component {
  static propTypes = {
    board: PropTypes.object,
    boards: PropTypes.array,
    className: PropTypes.string,
    linkToAdmin: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    selectionStyle: PropTypes.object,
    tintColor: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    linkToAdmin: false,
    selectionStyle: {},
  };

  renderContents() {
    const options = [];
    const {
      board,
      boards,
      className,
      linkToAdmin,
      location: { pathname },
      selectionStyle,
      tintColor,
    } = this.props;
    const isChangelog = pathname === '/changelog';
    const isRoadmap = pathname === '/';
    const isBoard =
      !isChangelog && !isRoadmap && board && board._id && pathname.startsWith('/' + board.urlName);

    boards.forEach((board) => {
      options.push({
        link: (linkToAdmin ? '/admin/board/' : '/') + board.urlName,
        name: board._id,
        render: (
          <div className="optionContent">
            <div>{board.name}</div>
            <UppercaseHeader className="count">{board.activePostCount}</UppercaseHeader>
          </div>
        ),
      });
    });

    if (boards.length === 1) {
      const boardLink = (linkToAdmin ? '/admin/board/' : '/') + boards[0].urlName;
      return (
        <Link
          to={boardLink}
          className="left onlyOneBoard"
          activeClassName="active"
          style={{
            ...(isBoard && {
              ...(tintColor && { color: tintColor }),
            }),
          }}>
          {boards[0].name}
        </Link>
      );
    } else if (!board || board.notAuthorized || board.notFound) {
      return (
        <ControlledDropdown
          dropdownClassName={classnames('publicNavBoardDropdownSelector', className)}
          key="no board selected"
          options={options}
          placeholder="Feedback"
          selectionStyle={selectionStyle}
        />
      );
    }

    return (
      <ControlledDropdown
        dropdownClassName={classnames('publicNavBoardDropdownSelector', className)}
        selectedName={board._id}
        key="board selected"
        options={options}
        selectionStyle={selectionStyle}
        splitStyle={true}
      />
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div
        className={classnames({
          publicNavBoardDropdown: true,
          ...(className && { [className]: true }),
        })}>
        {this.renderContents()}
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  tintColor: TintColorContext,
})(PublicNavBoardDropdown);

import React, { useContext, useEffect, useRef } from 'react';

import Error from 'common/common/Error';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Helmet from 'common/helmets/Helmet';
import LazyLoadedImage from 'common/LazyLoadedImage';
import AccessModal from 'common/modals/AccessModal';
import hasPermission from 'common/util/hasPermission';

import AdminUsersAndCompaniesList from './AdminUsersAndCompaniesList';
import AdminUsersDetailsSidebar from './AdminUsersDetailsSidebar';
import AdminUsersPostList from './AdminUsersPostList';
import AdminUsersSidebar from './AdminUsersSidebar';

import UserNotFoundImage from 'img/user-not-found.svg';

import 'css/components/subdomain/admin/_AdminUsers.scss';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { CustomField } from 'common/api/endpoints/customFields';
import type { ThirdPartyCompany } from 'common/api/endpoints/thirdPartyCompanies';
import type { CompanyUser } from 'common/api/endpoints/users';

export type ReduxStateMetadata = {
  loading: boolean;
  error: boolean;
  notFound: boolean;
};

type RouteParams = { companyURLName: string } | { userURLName: string } | Record<string, never>;

// TODO populate postList with correct, full types
interface Props {
  companyList: ThirdPartyCompany[];
  customFields: CustomField[];
  postList: Record<string, unknown>;
  thirdPartyCompany: ThirdPartyCompany & ReduxStateMetadata;
  user: CompanyUser & ReduxStateMetadata;
  userList: {
    users?: CompanyUser[];
    queryParams: Record<string, unknown>;
    userIDs?: string[];
  };
  params: RouteParams;
}

const AdminUsers = ({
  thirdPartyCompany,
  user,
  userList,
  companyList,
  postList,
  customFields,
  params,
}: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  const company = useContext<Company>(CompanyContext);
  const viewer = useContext(ViewerContext);

  const openModal = useContext(OpenModalContext);

  const isViewingUserOrCompany = params && ('userURLName' in params || 'companyURLName' in params);
  const firstUser = userList?.users?.[0];
  const initialLocation = useRef(location);

  const necessaryPermission: RolePermissionName = 'viewUserDetails';
  const viewerHasPermission = hasPermission(necessaryPermission, company, viewer);

  useEffect(() => {
    if (!viewerHasPermission) {
      openModal(AccessModal, {
        requiredPermissions: [necessaryPermission],
      });
      return;
    }

    // either we're already at a specific URL and don't need a redirect
    // or we're waiting for the user list to load, so can't form a redirect
    if (isViewingUserOrCompany || !firstUser?.urlName) {
      return;
    }

    router.replace({
      pathname: `/admin/users/${firstUser.urlName}`,
      query: initialLocation.current.query,
    });
  }, [firstUser?.urlName, isViewingUserOrCompany, openModal, router, viewerHasPermission]);

  const hasErrorOccured = user?.error || user?.notFound;

  return (
    <div className="adminUsers">
      <Helmet title="Users | Canny" />
      {!viewerHasPermission ? (
        <div className="center">
          <Error
            heading="You do not have permission to access this page."
            subHeading="Please contact an admin on your team or navigate to another&nbsp;page."
          />
        </div>
      ) : (
        <>
          <AdminUsersSidebar />
          <AdminUsersAndCompaniesList userList={userList} companyList={companyList} />
          {!hasErrorOccured ? (
            <>
              <AdminUsersPostList postList={postList} isCompanySelected={!!thirdPartyCompany} />
              <AdminUsersDetailsSidebar
                user={user}
                customFields={customFields}
                thirdPartyCompany={thirdPartyCompany}
              />
            </>
          ) : (
            <div className="center">
              {user?.error && <Error />}
              {user?.notFound && (
                <Error
                  icon={<LazyLoadedImage alt="User not found" src={UserNotFoundImage} />}
                  heading="We can't seem to find that record."
                  subHeading="It seems like the user you're trying to view does not exist."
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminUsers;

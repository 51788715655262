import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';

export default function getPrettyName(uglyName: string): string {
  // Split the camelCase string into words
  const words = uglyName
    .replace(/[_-]/g, ' ')
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .split(' ');

  // Capitalize the first letter of each word
  const prettifiedWords = words.map((word) => capitalizeFirstLetter(word));

  // Join the words back together with spaces
  return prettifiedWords.join(' ');
}

import React, { Component } from 'react';

import classnames from 'classnames';
import { Bell } from 'lucide-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { compose } from 'redux';

import { reloadNotifications } from 'common/actions/notifications';
import AJAX from 'common/AJAX';
import Portal from 'common/common/Portal';
import Tooltip from 'common/common/Tooltip';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Link from 'common/Link';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import IconButtonV2 from 'common/ui/IconButtonV2';
import withContexts from 'common/util/withContexts';

import Notification from './Notification';

import 'css/components/_NotificationsMenu.scss';

const ThirtySeconds = 30 * 1000;

class NotificationsMenu extends Component {
  static propTypes = {
    board: PropTypes.shape({
      _id: PropTypes.string,
    }),
    linkToAdmin: PropTypes.bool,
    notifications: PropTypes.shape({
      items: PropTypes.array,
      unreadCount: PropTypes.number,
    }),
    showTooltip: PropTypes.bool,
    iconStyle: PropTypes.oneOf(['light', 'dark']),
    tint: PropTypes.bool,
    tintColor: PropTypes.string,
    viewer: PropTypes.shape({
      loggedOut: PropTypes.bool,
    }),
    v2: PropTypes.bool,
  };

  static defaultProps = {
    iconStyle: 'dark',
    linkToAdmin: false,
    showTooltip: true,
    tint: false,
  };

  state = {
    menuOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this._lastFetch = new Date();
    this._unlisten = browserHistory.listen(this.onRouteChange);
  }

  componentWillUnmount() {
    this._unlisten();
  }

  onDropdownBlur = () => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return;
    }
    this.setState({ menuOpen: false });
  };

  onRouteChange = (location) => {
    this.setState({
      menuOpen: false,
    });

    const now = new Date();
    const timeSinceLastFetch = now - this._lastFetch;
    if (timeSinceLastFetch < ThirtySeconds) {
      return;
    }

    this._lastFetch = now;
    this.props.reloadNotifications();
  };

  onToggleNotifications = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
    this.markAllAsSeen();
  };

  markAllAsSeen = () => {
    const { board, notifications } = this.props;
    if (!notifications || notifications.unreadCount === 0 || this._markingAllSeen) {
      return;
    }

    this._markingAllSeen = true;
    AJAX.post(
      '/api/notifications/markAllSeen',
      {
        ...(board && { boardID: board._id }),
      },
      () => {
        this._markingAllSeen = false;
        this._lastFetch = new Date();
        this.props.reloadNotifications();
      }
    );
  };

  markAllAsRead = () => {
    const { board } = this.props;
    if (this._markingAllRead) {
      return;
    }

    this._markingAllRead = true;
    AJAX.post(
      '/api/notifications/markAllRead',
      {
        ...(board && { boardID: board._id }),
      },
      () => {
        this._markingAllRead = false;
        this._lastFetch = new Date();
        this.props.reloadNotifications();
      }
    );
  };

  shouldShowTooltip = () => {
    return this.props.showTooltip && !this.state.menuOpen;
  };

  renderButton() {
    const {
      notifications: { unreadCount },
    } = this.props;

    if (this.props.v2) {
      return (
        <div className="notificationsV2Button">
          <IconButtonV2
            onClick={this.onToggleNotifications}
            size="medium"
            icon={Bell}
            variant="outlined"
            aria-label="Notifications"
          />
          {unreadCount > 0 ? <span className="badge">{Math.min(unreadCount, 99)}</span> : null}
        </div>
      );
    }

    return <Tappable onTap={this.onToggleNotifications}>{this.renderNotificationMenu()}</Tappable>;
  }

  renderNotificationsLink() {
    const { linkToAdmin } = this.props;
    if (linkToAdmin) {
      return '/admin/notifications';
    }

    return '/notifications';
  }

  renderNotificationMenu() {
    const { iconStyle } = this.props;
    const { unreadCount } = this.props.notifications;

    var className = 'menu menuIconButton';
    if (unreadCount > 0) {
      className += ' hasUnread';
    }

    const badgeStyle = {
      ...(this.props.tint && { background: this.props.tintColor }),
    };
    const badge = unreadCount ? (
      <span className="badge" style={badgeStyle}>
        {Math.min(unreadCount, 99)}
      </span>
    ) : null;

    return (
      <div className={className}>
        <div className="navIconContainer">
          <Bell
            className={classnames('notificationsIcon', { light: iconStyle === 'light' })}
            size="20"
          />
        </div>
        {badge}
      </div>
    );
  }

  renderNotifications() {
    const { items, reloading } = this.props.notifications;

    if (reloading) {
      return (
        <div className="notificationsDropdownContainer">
          <div className="notificationsDropdown">
            <div className="spinnerContainer">
              <Spinner />
            </div>
          </div>
        </div>
      );
    }

    if (!items.length) {
      return (
        <div className="notificationsDropdownContainer">
          <div className="notificationsDropdown">
            <div className="nullState">
              <span>You have no notifications.</span>
            </div>
          </div>
        </div>
      );
    }

    var notifications = [];
    items.forEach((notification) => {
      notifications.push(<Notification key={notification._id} notification={notification} />);
    });

    const markAllAsReadStyle = {
      ...(this.props.tint && { color: this.props.tintColor }),
    };
    return (
      <div className="notificationsDropdownContainer">
        <div className="notificationsDropdown">
          <div className="notificationsDropdownHeader">
            <h3>Notifications</h3>
            <Tappable onTap={this.markAllAsRead}>
              <span style={markAllAsReadStyle}>Mark all as read</span>
            </Tappable>
          </div>
          <div className="notifications">{notifications}</div>
          <div className="notificationsDropdownFooter">
            <Link to={this.renderNotificationsLink()}>See all</Link>
          </div>
        </div>
      </div>
    );
  }

  renderPortal() {
    if (!this.state.menuOpen) {
      return null;
    }

    return (
      <Portal
        align="end"
        className={classnames('dropdownPortal', 'notificationsMenuPortal')}
        onBlur={this.onDropdownBlur}
        position="bottom"
        relativeToRef={this.containerRef}>
        {this.renderNotifications()}
      </Portal>
    );
  }

  render() {
    const { notifications, viewer } = this.props;
    if (!viewer || viewer.loggedOut) {
      return null;
    }

    if (!notifications || notifications.loading || notifications.error) {
      return null;
    }

    return (
      <div className="notificationsMenu" ref={this.containerRef}>
        <Tooltip
          className="adminNavTooltipIcon"
          disabled={!this.shouldShowTooltip()}
          position="bottom"
          value="Notifications">
          {this.renderButton()}
        </Tooltip>
        {this.renderPortal()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadNotifications: () => {
      return dispatch(reloadNotifications());
    },
  })),
  withContexts(
    {
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(NotificationsMenu);

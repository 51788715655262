import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import SubdomainPostVotersHelmet from 'common/helmets/SubdomainPostVotersHelmet';
import Link from 'common/Link';
import VotersFacepile from 'common/post/VotersFacepile';
import UserLockup from 'common/user/UserLockup';
import abbreviateNumber from 'common/util/abbreviateNumber';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_SubdomainPostVoters.scss';

class SubdomainPostVoters extends Component {
  static propTypes = {
    board: PropTypes.object,
    getPostLink: PropTypes.func,
    isWidget: PropTypes.bool,
    linkMore: PropTypes.bool,
    post: PropTypes.object,
    tintColor: PropTypes.string,
    voters: PropTypes.arrayOf(
      PropTypes.shape({
        avatarURL: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  };

  static defaultProps = {
    linkMore: true,
  };

  renderAndMore() {
    const { board, getPostLink, isWidget, linkMore, post, tintColor, voters } = this.props;

    if (post.score <= voters.length) {
      return null;
    }

    const link = getPostLink(post) + '/voters';
    const moreCount = post.score - voters.length;
    const showVoterNames = board && board.settings.showVoterNames;
    const linkStyle = {
      ...(tintColor && { color: tintColor }),
    };
    if (isWidget || !showVoterNames || !linkMore) {
      return <div className="more noHover">{'and ' + abbreviateNumber(moreCount) + ' more'}</div>;
    } else {
      return (
        <Link className="more" to={link} style={linkStyle}>
          {'and ' + abbreviateNumber(moreCount) + ' more...'}
        </Link>
      );
    }
  }

  renderVoters() {
    const { voters } = this.props;
    const voterList = [];

    voters.forEach((voter) => {
      voterList.push(
        <div key={voter.aliasID} className="voter">
          <div className="left">
            <UserLockup showCompanyNames={true} user={voter} />
          </div>
        </div>
      );
    });

    return voterList;
  }

  render() {
    const { board, post, voters } = this.props;
    if (!voters || !voters.length) {
      return null;
    }

    if (!board.settings.showVoterNames || board.settings.privateVotes) {
      return <VotersFacepile post={post} voters={post.voters} />;
    }

    return (
      <div className="subdomainPostVoters">
        <SubdomainPostVotersHelmet board={board} post={post} />
        <div className="users">{this.renderVoters()}</div>
        {this.renderAndMore()}
      </div>
    );
  }
}

export default withContexts({
  getPostLink: GetPostLinkContext,
  isWidget: IsWidgetContext,
  tintColor: TintColorContext,
})(SubdomainPostVoters);

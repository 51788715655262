import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { compose } from 'redux';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Helmet from 'common/helmets/Helmet';
import loadScript from 'common/util/loadScript';
import withContexts from 'common/util/withContexts';

export const TrackHubSpotEventContext = React.createContext();

const HubSpotScriptID = 'hs-script-loader';

function loadHubSpotScript() {
  window._hsq = window._hsq || [];
  loadScript('https://js.hs-scripts.com/5705808.js', HubSpotScriptID);
}

class HubSpotContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      subdomain: PropTypes.string,
      viewerIsMember: PropTypes.bool,
    }),
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      companies: PropTypes.array,
      created: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  componentDidMount() {
    if (__DEV__) {
      return;
    }

    loadHubSpotScript();

    this.trackPageViews();

    const { viewer } = this.props;
    if (!viewer?._id) {
      return;
    }

    const viewerIsAdmin = viewer.companies?.length;
    if (!viewerIsAdmin) {
      return;
    }

    window._hsq.push([
      'identify',
      {
        email: viewer.email,
        id: viewer._id,
      },
    ]);
  }

  componentWillUnmount() {
    if (__DEV__) {
      return;
    }

    this._unlisten?.();
  }

  onRouteChange = () => {
    window._hsq.push(['setPath', document.location.pathname]);
    window._hsq.push(['trackPageView']);
  };

  onTrackEvent = (eventName, eventData) => {
    if (__DEV__) {
      return;
    }

    loadHubSpotScript();

    window._hsq.push([
      'trackEvent',
      {
        id: eventName,
      },
    ]);
  };

  trackPageViews = () => {
    this._unlisten = browserHistory.listen(this.onRouteChange);
  };

  render() {
    return (
      <TrackHubSpotEventContext.Provider value={this.onTrackEvent}>
        <Helmet
          link={[
            {
              href: 'https://js.hs-scripts.com',
              rel: 'preconnect',
            },
          ]}
        />
        {this.props.children}
      </TrackHubSpotEventContext.Provider>
    );
  }
}

export default compose(
  withContexts({
    company: CompanyContext,
    viewer: ViewerContext,
  })
)(HubSpotContainer);

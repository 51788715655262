import React, { useCallback, useContext, useEffect, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadGongSettings } from 'common/actions/gongSettings';
import AJAX from 'common/AJAX';
import { IntegrationItemNames, SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationAutopilotSettingsSection from 'common/subdomain/admin/AdminQueue/AdminIntegrationAutopilotSettingsSection/AdminIntegrationAutopilotSettingsSection';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';

import type { Company } from 'common/api/endpoints/companies';
import type { GongSettings } from 'common/api/endpoints/gong';

import 'css/components/subdomain/admin/_AdminGongSettings.scss';

type OwnProps = {
  gongSettings: GongSettings;
};

type ConnectProps = {
  reloadGongSettings(): Promise<void>;
};

type Props = OwnProps & ConnectProps;

// For more information, visit: https://help.gong.io/hc/en-us/articles/13944551222157-Create-an-app-for-Gong
const Gong = 'gong';
const GongParams = {
  client_id: publicConfig('gongClientID'),
  redirect_uri: devURL('https://canny.io/gong-redirect'),
  response_type: 'code',
  scope:
    'api:calls:read:transcript api:calls:create api:workspaces:read api:calls:read:extensive api:calls:read:basic api:calls:read:media-url api:users:read',
};
const GongURL = 'https://app.gong.io/oauth2/authorize';

const AdminGongSettings = (props: Props) => {
  // props
  const { gongSettings, reloadGongSettings } = props;

  // context
  const company = useContext<Company>(CompanyContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);

  // state
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // helpers
  const sendRequest = useCallback(
    async (url: string, params: Record<string, unknown> = {}) => {
      setError(null);
      setLoading(true);

      const response = await AJAX.post(url, params);
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (error) {
        setLoading(false);
        setError(error.message);
        return;
      }

      await reloadGongSettings();
      setLoading(false);
    },
    [reloadGongSettings]
  );

  const install = useCallback(
    async (code: string) => sendRequest('/api/gong/install', { code }),
    [sendRequest]
  );

  const uninstall = useCallback(async () => sendRequest('/api/gong/uninstall', {}), [sendRequest]);

  // effects
  useEffect(() => {
    const { code, integration } = location.query;
    if (!code) {
      return;
    }

    router.replace({
      pathname: location.pathname,
    });

    if (integration === Gong) {
      install(code);
    }
  }, [install, location.query, location.pathname, router]);

  // renderers
  const Contents = () => {
    const installation = gongSettings?.installation;
    const hasInstalled = installation && !!installation?.installed && !installation?.lostAccess;

    if (!company?.integrations?.gong) {
      return <AdminFeatureUpsell feature="gong" />;
    } else if (loading) {
      return (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      );
    } else if (!hasInstalled) {
      return <InstallButton />;
    }

    return (
      <>
        <Alert
          type={AlertTypes.Confirmation}
          className="installationAlert"
          headingText="Gong connected"
          subText="Your Gong integration is installed. New transcripts will be analyzed for feedback within 24 hours."
          button={
            <ButtonV2 size="medium" onClick={uninstall} color="error" variant="outlined">
              Uninstall
            </ButtonV2>
          }
        />
        <AdminIntegrationAutopilotSettingsSection
          integrationLabel="Gong"
          integrationName={SyncIntegrationNames.gong}
          integrationType={IntegrationItemNames.call}
        />
      </>
    );
  };

  const InstallButton = () => {
    const hasLostAccess = !!gongSettings?.installation?.lostAccess;
    const ctaValue = hasLostAccess ? 'Reinstall Gong' : 'Install Gong';

    const gongParams = {
      ...GongParams,
      state: JSON.stringify({
        integration: Gong,
        subdomain: company.subdomain,
      }),
    };
    const gongURL = `${GongURL}${queryString.stringify(gongParams)}`;

    return (
      <div>
        {hasLostAccess && (
          <P className="text">
            Your Gong integration has lost access to Canny. Please reinstall the integration.
          </P>
        )}
        <a href={gongURL} className="buttonContainer" rel="noreferrer noopener nofollow">
          <ButtonV2 size="medium">{ctaValue}</ButtonV2>
        </a>
      </div>
    );
  };

  return (
    <div className="adminGongSettings">
      <Helmet title="Gong Integration | Canny" />
      <div className="settingsHeading">Gong Integration</div>
      <div className="content">
        <P className="text">
          Canny will automatically detect and pull in features requests from Gong&nbsp;transcripts.
        </P>
        <P className="text">
          Check out&nbsp;
          <a
            className="articleLink"
            href="http://help.canny.io/en/articles/8415446-gong-integration"
            rel="noopener"
            target="_blank">
            this help article
          </a>
          &nbsp;to see how this integration works.
        </P>
        <Contents />
        {error ? <P className="error">{error}</P> : null}
      </div>
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadGongSettings: () => dispatch(reloadGongSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.gong),
    '/admin/settings'
  )
)(AdminGongSettings) as unknown as React.FC<OwnProps>;

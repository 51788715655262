import React, { useContext } from 'react';

import classnames from 'classnames';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import Link from 'common/Link';
import UppercaseHeader from 'common/UppercaseHeader';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/AdminSettings/_AdminSettingsSidebar.scss';

type CompanyLink = {
  label: string;
  urlSuffix: string;
  featureFlag?: string;
};

type UserLink = {
  label: string;
  urlSuffix: string;
};

const AdminSettingsSidebar = () => {
  const location = useContext(LocationContext);
  const company = useContext<Company>(CompanyContext);

  const filteredCompanyLinks = CompanyLinks.filter(({ featureFlag }) => {
    if (!featureFlag) {
      return true;
    }

    return company.featureAllowlist.includes(featureFlag);
  });

  return (
    <div className="adminSettingsSidebar">
      <div className="section">
        <UppercaseHeader className="sectionHeader">Personal</UppercaseHeader>
        <div className="links">
          {UserLinks.map(({ label, urlSuffix }) => {
            const url = '/admin/settings' + urlSuffix;
            const isActive = location.pathname.startsWith(url);

            return (
              <div className={classnames('link', { active: isActive })} key={label}>
                <Link disabled={isActive} to={url}>
                  {label}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="section">
        <UppercaseHeader className="sectionHeader">{company.name}</UppercaseHeader>
        <div className="links">
          {filteredCompanyLinks.map(({ label, urlSuffix }) => {
            const url = '/admin/settings' + urlSuffix;
            const isActive = location.pathname.startsWith(url);

            return (
              <div className={classnames('link', { active: isActive })} key={label}>
                <Link disabled={isActive} to={url}>
                  {label}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const UserLinks: UserLink[] = [
  {
    label: 'Profile',
    urlSuffix: '/account',
  },
  {
    label: 'Preferences',
    urlSuffix: '/preferences',
  },
  {
    label: 'Saved Filters',
    urlSuffix: '/saved-filters',
  },
  {
    label: 'Referrals',
    urlSuffix: '/referrals',
  },
];

const CompanyLinks: CompanyLink[] = [
  {
    label: 'General',
    urlSuffix: '/company',
  },
  {
    label: 'Notifications',
    urlSuffix: '/user-emails',
  },
  {
    label: 'Admins',
    urlSuffix: '/team',
  },
  {
    label: 'Automations',
    urlSuffix: '/automation',
  },
  {
    label: 'Boards',
    urlSuffix: '/boards',
  },
  {
    label: 'Autopilot',
    urlSuffix: '/autopilot',
  },
  {
    label: 'Roadmaps and Statuses',
    urlSuffix: '/roadmap',
  },
  {
    label: 'Fields',
    urlSuffix: '/fields',
  },
  {
    label: 'Changelog',
    urlSuffix: '/changelog',
  },
  {
    label: 'Custom Domains',
    urlSuffix: '/custom-domain',
  },
  {
    label: 'Security',
    urlSuffix: '/security',
  },
  {
    label: 'API',
    urlSuffix: '/api',
  },
  {
    label: 'Integrations',
    urlSuffix: '/integrations',
  },
  {
    label: 'Billing',
    urlSuffix: '/billing',
  },
];

export default AdminSettingsSidebar;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { compose } from 'redux';

import { DefaultStatusTypes, getEntryKey, loadSuggestions } from 'common/actions/postSuggestions';
import Portal from 'common/common/Portal';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import mod from 'common/mod';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import delayer from 'common/util/delayer';
import mapify from 'common/util/mapify';
import withContexts from 'common/util/withContexts';

import PostStatus from './PostStatus';

import 'css/components/post/_PostSearchDropdown.scss';

const ChangeDelay = 250;

class PostSearchDropdown extends Component {
  static propTypes = {
    allowCreateNewPost: PropTypes.bool,
    company: PropTypes.shape({
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    }),
    dropdownClassName: PropTypes.string,
    dropdownPosition: PropTypes.oneOf(['bottom', 'top']),
    entry: PropTypes.object,
    excludePosts: PropTypes.array,
    onClickAway: PropTypes.func,
    onCreatePost: PropTypes.func,
    onLinkPost: PropTypes.func,
    postSuggestions: PropTypes.object,
    showRecentlyCompleted: PropTypes.bool,
    statusTypes: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    allowCreateNewPost: false,
    dropdownPosition: 'bottom',
    showRecentlyCompleted: true,
    statusTypes: DefaultStatusTypes,
  };

  state = {
    focused: false,
    mouseOverSuggestions: false,
    search: '',
    selectedSuggestionIndex: 0,
    submitting: false,
    value: '',
  };

  constructor(props, context) {
    super(props, context);
    this._onChangeDelayer = new delayer(this.onChangeAfterDelay, ChangeDelay);
    this.dropdownRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { entry, loadSuggestions, statusTypes } = this.props;
    loadSuggestions(entry, '', statusTypes);
    if (this.props.onClickAway) {
      document.addEventListener('click', this.onClickAway);
    }
  }

  componentWillUnmount() {
    this._onChangeDelayer.cancel();
    if (this.props.onClickAway) {
      document.removeEventListener('click', this.onClickAway);
    }
  }

  getSuggestions() {
    const { search } = this.state;
    const { entry, postSuggestions, statusTypes } = this.props;
    const entryPostSuggestions = postSuggestions[getEntryKey(entry)];

    const queryKey = `${search}/${statusTypes.join(',')}`;
    const suggestions = entryPostSuggestions?.[queryKey];
    if (!Array.isArray(suggestions?.items)) {
      return [];
    }

    const { excludePosts } = this.props;
    const excludePostSet = {};
    excludePosts.forEach((excludePost) => {
      excludePostSet[excludePost._id] = true;
    });
    return suggestions.items.filter((suggestion) => {
      return !excludePostSet[suggestion._id];
    });
  }

  onBlur = (e) => {
    if (this.state.mouseOverSuggestions) {
      return;
    }
    this.setState({
      focused: false,
    });
    this.props.onClickAway?.(e, this.state.value);
  };

  onChange = (e) => {
    const value = e.nativeEvent.target.value.trim();
    if (!value) {
      this.setState({
        search: '',
        selectedSuggestionIndex: 0,
        value: '',
      });
      return;
    }

    this.setState({
      value,
    });

    this._onChangeDelayer.callAfterDelay(e);
  };

  onChangeAfterDelay = () => {
    const { entry, loadSuggestions, statusTypes } = this.props;
    const value = this.state.value.trim();
    loadSuggestions(entry, value, statusTypes).then(() => {
      this.setState({
        search: value,
        selectedSuggestionIndex: 0,
      });
    });
  };

  onClickAway = (e) => {
    if (findDOMNode(this).contains(e.target)) {
      return;
    }
    this.props.onClickAway?.(e, this.state.value);
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onKeyDown = (e) => {
    const { allowCreateNewPost } = this.props;
    const { selectedSuggestionIndex } = this.state;
    const { DownArrow, UpArrow, Enter, Tab } = KeyCodes;
    if (
      e.keyCode !== DownArrow &&
      e.keyCode !== UpArrow &&
      e.keyCode !== Enter &&
      e.keyCode !== Tab
    ) {
      return;
    }
    e.preventDefault();

    const suggestions = this.getSuggestions();
    if (suggestions.length === 0 && !allowCreateNewPost) {
      return;
    }

    const numberOfOptions = suggestions.length + (allowCreateNewPost ? 1 : 0);
    if (e.keyCode === DownArrow) {
      this.setState({
        selectedSuggestionIndex: mod(selectedSuggestionIndex + 1, numberOfOptions),
      });
    } else if (e.keyCode === UpArrow) {
      this.setState({
        selectedSuggestionIndex: mod(selectedSuggestionIndex - 1, numberOfOptions),
      });
    } else if (e.keyCode === Enter || e.keyCode === Tab) {
      if (allowCreateNewPost && selectedSuggestionIndex === 0) {
        this.props.onCreatePost(this.state.value);
      } else {
        const index = selectedSuggestionIndex - (allowCreateNewPost ? 1 : 0);
        this.onLinkPost(suggestions[index]);
      }
    }
  };

  onLinkPost = (post) => {
    this.props.onLinkPost(post);

    this.inputRef.current.blur();
    this.inputRef.current.setValue('');

    this.setState({
      focused: false,
      mouseOverSuggestions: false,
      search: '',
      selectedSuggestionIndex: 0,
      value: '',
    });
  };

  onMouseOverSuggestions = () => {
    this.setState({
      mouseOverSuggestions: true,
    });
  };

  onMouseLeaveSuggestions = () => {
    this.setState({
      mouseOverSuggestions: false,
    });
  };

  renderPostSuggestions() {
    const items = [];
    const { company } = this.props;
    const { focused, search, selectedSuggestionIndex, submitting, value } = this.state;
    if (!focused || submitting) {
      return null;
    }

    if (search === '' && !this.props.showRecentlyCompleted) {
      return null;
    }

    const suggestions = this.getSuggestions();
    if (!suggestions.length && !this.props.allowCreateNewPost) {
      return null;
    }

    if (this.props.allowCreateNewPost) {
      const className = classnames('createNewPost', 'suggestion', {
        selected: selectedSuggestionIndex === 0,
      });
      items.push(
        <Tappable key="createNewPost" onTap={() => this.props.onCreatePost(value)}>
          <div className={className}>Create new post</div>
        </Tappable>
      );
    }

    if (search === '') {
      items.push(
        <div className="recently" key="recently">
          Recently completed posts
        </div>
      );
    }

    const statusesMap = mapify(company.statuses, 'name');
    suggestions.forEach((suggestion, i) => {
      const status = statusesMap[suggestion.status];
      const showStatus = status?.type !== PostStatusTypes.Initial;
      const index = this.props.allowCreateNewPost ? i + 1 : i;
      const className = classnames('suggestion', { selected: index === selectedSuggestionIndex });
      items.push(
        <Tappable key={suggestion._id} onTap={this.onLinkPost.bind(this, suggestion)}>
          <div className={className}>
            {suggestion.title}
            <div className="meta">
              <span className="vote" />
              <UppercaseHeader>{suggestion.score}</UppercaseHeader>
              <span className="dot">&middot;</span>
              <UppercaseHeader>{suggestion.board.name.replace(' ', '\u00a0')}</UppercaseHeader>
              {showStatus ? <span className="dot">&middot;</span> : null}
              {showStatus ? <PostStatus status={suggestion.status} /> : null}
            </div>
          </div>
        </Tappable>
      );
    });

    const portalClasses = classnames('postSearchDropdownPortal', this.props.dropdownClassName);

    return (
      <Portal
        className={portalClasses}
        position={this.props.dropdownPosition}
        relativeToRef={this.dropdownRef}>
        <div
          className="postSuggestions"
          onMouseOver={this.onMouseOverSuggestions}
          onMouseLeave={this.onMouseLeaveSuggestions}>
          {items}
        </div>
      </Portal>
    );
  }

  render() {
    return (
      <div className="postSearchDropdown" ref={this.dropdownRef}>
        <div className="formFields">
          <TextInput
            autoFocus={true}
            disabled={this.state.submitting}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onKeyDown={this.onKeyDown}
            placeholder={'Add post by title\u2026'}
            ref={this.inputRef}
          />
          {this.renderPostSuggestions()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({ postSuggestions: state.postSuggestions }),
    (dispatch) => ({
      loadSuggestions: (entry, search, status) => {
        return Promise.all([dispatch(loadSuggestions(entry, search, status))]);
      },
    })
  ),
  withContexts({ company: CompanyContext }, { forwardRef: true })
)(PostSearchDropdown);

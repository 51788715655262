import findStringMatches from 'common/util/findStringMatches';
import mapify from 'common/util/mapify';

import type { Board } from 'common/api/endpoints/boards';
import type { Post } from 'common/api/resources/posts';
import type { PostQueriesState } from 'common/reducers/postQueries';
import type { PostsState } from 'common/reducers/posts';

export const getBoardsQueryKey = (boards: Board[]) =>
  boards.map((board) => board.urlName).join('_');

export const getFilteredPosts = ({
  queryResultPosts,
  selectedPosts,
  posts,
}: {
  queryResultPosts: PostQueriesState[string]['posts'];
  selectedPosts: Post[];
  posts: PostsState;
}) => {
  const selectedPostsMap = mapify(selectedPosts, '_id');

  const matchingPosts = (queryResultPosts ?? []).map(
    (post) => posts[post.boardID][post.postURLName]
  );

  // check for truthiness because we might have deleted/invalidated posts
  return matchingPosts.filter((post) => post && selectedPostsMap[post._id]);
};

export const findStringMatchesByName = <T extends { name: string }>(
  entities: T[] = [],
  name: string | undefined
) => {
  if (!name || !name.length) {
    return entities;
  }

  return findStringMatches(entities, 'name', name);
};

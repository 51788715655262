import React, { useContext, useEffect, useRef } from 'react';

import classnames from 'classnames';
import { X } from 'lucide-react';

import Truncate from 'common/common/Truncate';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import IsAdminViewContext from 'common/contexts/IsAdminViewContext';
import CheckboxV2 from 'common/ui/CheckboxV2';
import { H6, P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';

import type { Company } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapPostTitle.scss';

type Props = {
  index: number;
  isGroupMember: boolean;
  isOpen: boolean;
  isSelected: boolean;
  onDeletePost: () => void;
  onKeyDown: () => void;
  onOpenPost: () => void;
  onSelected: () => void;
  title: string;
};

const AdminRoadmapPostTitle = (props: Props) => {
  const {
    index,
    isGroupMember,
    isOpen,
    isSelected,
    onDeletePost,
    onKeyDown,
    onOpenPost,
    onSelected,
    title,
  } = props;

  const titleRef = useRef<HTMLButtonElement | null>(null);
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext<Viewer>(ViewerContext);
  const isAdminView = useContext<boolean>(IsAdminViewContext);

  const viewerCanManageRoadmaps = hasPermission('manageRoadmap', company, viewer);

  useEffect(() => {
    if (isOpen) {
      titleRef.current?.focus();
    }
  }, [isOpen]);

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeletePost();
  };

  return (
    <div className={classnames('adminRoadmapPostTitleWrapper', { isGroupMember })}>
      {isAdminView && (
        <div className={classnames('adminRoadmapPostCheckboxWrapper', { visible: isSelected })}>
          <CheckboxV2
            aria-label="select post"
            checked={isSelected}
            onChange={onSelected}
            size="medium"
          />
        </div>
      )}
      <button
        className="adminRoadmapPostTitleButton"
        onClick={onOpenPost}
        onKeyDown={onKeyDown}
        ref={titleRef}>
        <div className="adminRoadmapPostTitle">
          <P className="index" variant="bodyMd">
            {index + 1}
          </P>
          <Truncate numberOfLines={2}>
            <H6 className="title">{title}</H6>
          </Truncate>
        </div>
      </button>
      {viewerCanManageRoadmaps && (
        <button onClick={onDeleteClick} aria-label="delete post" className="deletePostButton">
          <X size={20} strokeWidth={2} />
        </button>
      )}
    </div>
  );
};

export default AdminRoadmapPostTitle;

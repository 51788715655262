export type RequestState<D> = {
  error: string | null;
  lastUpdated: Date | null;
  loading: boolean;
  data: D | null;
};

export const defaultState = {
  error: null,
  lastUpdated: null,
  loading: true,
  data: null,
};

const requestReducerFactory = <D,>(requestType: string, loadedType: string, errorType: string) => {
  return function reducer(state = defaultState, action: Action): RequestState<D> {
    switch (action.type) {
      case requestType: {
        return Object.assign({}, state, {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
        });
      }

      case loadedType: {
        return Object.assign({}, state, {
          error: null,
          data: action.data,
          lastUpdated: action.timestamp,
          loading: false,
        });
      }

      case errorType: {
        return Object.assign({}, state, {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        });
      }

      default:
        return state;
    }
  };
};

export default requestReducerFactory;

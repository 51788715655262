import React from 'react';

import classNames from 'classnames';

import { P } from 'common/ui/Text';

import 'css/components/_CountBadge.scss';

type Variant = 'alert' | 'default';

export type Props = {
  className?: string;
  disabled?: boolean;
  size: Size;
  count: number;
  max?: number;
  variant?: Variant;
};

type Size = 'small' | 'medium' | 'large';

const CountBadge = ({
  size = 'medium',
  className,
  count,
  max,
  variant = 'default',
  disabled,
}: Props) => {
  return (
    <div
      className={classNames('countBadge', className, variant, {
        disabled,
        [size]: true,
      })}>
      <P variant={size === 'large' ? 'bodyMd' : 'bodySm'} fontWeight="medium" resetStyles>
        {max && count > max ? `${max}+` : count}
      </P>
    </div>
  );
};

export default CountBadge;

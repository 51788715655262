import React from 'react';

import { X } from 'lucide-react';

import OptionPrefix from 'common/ui/common/select/OptionPrefix';
import { type CommonSelectProps, type Option } from 'common/ui/common/select/SelectCommon';

import type { UseMultipleSelectionPropGetters } from 'downshift';

import 'css/components/_SelectedItems.scss';

type Props = Pick<CommonSelectProps, 'disabled'> & {
  value?: Option[];
  onRemove: (removedOption: Option) => void;
  getSelectedItemProps: UseMultipleSelectionPropGetters<Option>['getSelectedItemProps'];
  children?: React.ReactNode;
};

const SelectedItems = ({ value, onRemove, getSelectedItemProps, disabled, children }: Props) => {
  return (
    <span className="selectedItems">
      {value?.map((selectedItem, index) => (
        <span
          className="selectedItem multi"
          key={selectedItem.value}
          {...getSelectedItemProps({ selectedItem, index })}>
          <OptionPrefix
            flair={selectedItem.flair}
            avatar={selectedItem.avatar}
            prefixIcon={selectedItem.prefixIcon}
            disabled={disabled}
            multi
          />
          <span className="selectedItemLabel">{selectedItem.label}</span>
          <button
            aria-label={`remove ${selectedItem.label}`}
            aria-disabled={disabled}
            disabled={disabled}
            className="selectedItemRemoveButton"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(selectedItem);
            }}>
            <X className="selectedItemRemoveIcon" />
          </button>
        </span>
      ))}
      {children}
    </span>
  );
};
export default SelectedItems;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import cloneElementWithProps from '../core/cloneElementWithProps';

export const LocationContext = React.createContext();
export const ParamsContext = React.createContext();
export const RouterContext = React.createContext();

export default class RouterContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  render() {
    return (
      <LocationContext.Provider value={this.props.location}>
        <ParamsContext.Provider value={this.props.params}>
          <RouterContext.Provider value={this.props.router}>
            {cloneElementWithProps(this.props.children, {
              ...this.props,
            })}
          </RouterContext.Provider>
        </ParamsContext.Provider>
      </LocationContext.Provider>
    );
  }
}

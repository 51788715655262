import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModalPortal from 'common/modals/ModalPortal';
import Spinner from 'common/Spinner';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import 'css/components/subdomain/admin/_AdminCreateLinearIssueModal.scss';

const Errors = {
  'not authorized': `You are not authorized to use this feature. Reach for one of you company's admins for support.`,
  'linear not installed': `Your company does not have the Linear integration installed. Please, install it before linking tasks.`,
};

const mapToOptions = (entities) => {
  return entities.map((entity) => ({
    name: entity.id,
    render: entity.name,
  }));
};

const AdminCreateLinearIssueModal = ({ onClose, onIssueCreated, post, reloadPost }) => {
  // state
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const [issue, _setIssue] = useState({
    description: post.details,
    stateID: null,
    teamID: null,
    title: post.title,
  });
  const [teams, setTeams] = useState(null);

  // state utils
  const setIssue = (data) => _setIssue((state) => ({ ...state, ...data }));
  const getTeam = (teamID) => teams.find((team) => team.id === teamID);
  const getState = (stateID, teamID) => {
    const team = getTeam(teamID);
    return team.states.find((state) => state.id === stateID);
  };

  // effects
  useEffect(() => {
    const fetchAndSetTeams = async () => {
      const response = await AJAX.post('/api/linear/getTeams');
      const { error, parsedResponse } = parseAPIResponse(response, {
        errors: Errors,
        isSuccessful: (parsedResponse) => !!parsedResponse.teams,
      });

      if (error) {
        setError(error.message);
        return;
      }

      const { teams } = parsedResponse;
      const defaultTeam = teams[0];
      const defaultState = defaultTeam?.states?.[0];

      setIssue({ stateID: defaultState?.id, teamID: defaultTeam?.id });
      setTeams(teams);
    };

    fetchAndSetTeams();
  }, []);

  const createIssue = async () => {
    const { description, stateID, teamID, title } = issue;

    setCreating(true);

    const response = await AJAX.post('/api/linear/createAndLinkIssue', {
      description,
      postID: post._id,
      stateID,
      teamID,
      title,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setCreating(false);
      setError(error.message);
      return;
    }

    await reloadPost(post);

    onIssueCreated?.();
    onClose?.();
  };

  const onTeamChange = (teamID) => {
    const team = getTeam(teamID);
    const stateID = team.states?.[0]?.id;

    setIssue({ stateID, teamID });
  };

  // renders
  const renderContents = () => {
    if (error) {
      return <div className="error">{error}</div>;
    } else if (!teams) {
      return <Spinner />;
    }

    const selectedTeam = getTeam(issue.teamID);
    const selectedState = getState(issue.stateID, issue.teamID);
    const teamOptions = mapToOptions(teams);
    const stateOptions = mapToOptions(selectedTeam.states);

    if (!teamOptions.length) {
      return (
        <div className="error">
          It looks like there are no available Linear Teams. Please add a Team on Linear before
          creating a&nbsp;issue.
        </div>
      );
    }

    return (
      <div className="createIssueForm">
        <div className="dropdownsContainer">
          <div className="dropdownOuterContainer">
            <UppercaseHeader>Team</UppercaseHeader>
            <ControlledDropdown
              options={teamOptions}
              onChange={(teamID) => onTeamChange(teamID)}
              selectedName={selectedTeam.id}
            />
          </div>
          <div className="dropdownOuterContainer">
            <UppercaseHeader>Status</UppercaseHeader>
            <ControlledDropdown
              options={stateOptions}
              onChange={(stateID) => setIssue({ stateID })}
              selectedName={selectedState.id}
            />
          </div>
        </div>
        <div>
          <TextInput
            defaultValue={post.title}
            inset="Title"
            onChange={(e) => setIssue({ title: e.target.value })}
          />
          <AutoResizeTextarea
            defaultValue={post.details}
            inset="Description"
            maxRows={8}
            minRows={3}
            onChange={(e) => setIssue({ description: e.target.value })}
          />
          <Button
            buttonType="cannyButton"
            loading={creating}
            onTap={createIssue}
            value="Create & Link Issue"
          />
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <ModalPortal
        className="adminCreateLinearIssueModal"
        closeOnClickAway={true}
        onClose={onClose}>
        <div className="container">
          <div className="heading">Create a new Linear issue</div>
          {renderContents()}
        </div>
      </ModalPortal>
    );
  };

  return render();
};

AdminCreateLinearIssueModal.propTypes = {
  onClose: PropTypes.func,
  onIssueCreated: PropTypes.func,
  post: PropTypes.object,
};

export default connect(null, (dispatch) => ({
  reloadPost: (post) => dispatch(reloadPost(post)),
}))(AdminCreateLinearIssueModal);

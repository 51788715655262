import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import getCannyOrigin from 'common/util/getCannyOrigin';
import withContexts from 'common/util/withContexts';

import Helmet from './Helmet';

class SubdomainPostVotersListHelmet extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
    }),
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    post: PropTypes.shape({
      title: PropTypes.string,
    }),
  };

  render() {
    const { board, company, post } = this.props;
    if (!post || !post.title || !board || !board.name || !company || !company.name) {
      return null;
    }

    const title = 'Voters' + ' | ' + post.title + ' | ' + board.name + ' | ' + company.name;
    const url = getCannyOrigin(company) + '/' + board.urlName + '/p/' + post.urlName + '/voters';
    return <Helmet title={title} link={[{ href: url, rel: 'canonical' }]} />;
  }
}

export default withContexts({ company: CompanyContext })(SubdomainPostVotersListHelmet);

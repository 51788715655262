import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { loadGroups, reloadGroups } from 'common/actions/gsuiteGroups';
import AJAX from 'common/AJAX';
import AuthButton from 'common/AuthButton';
import InformationBox from 'common/common/InformationBox';
import Toggle from 'common/common/Toggle';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import asyncConnect from 'common/core/asyncConnect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import CheckboxInput from 'common/inputs/CheckboxInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import Timestamp from 'common/Timestamp';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminGSuiteSettings.scss';

class AdminGSuiteSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      gsuite: PropTypes.shape({
        domain: PropTypes.string,
        syncing: PropTypes.bool,
      }),
    }),
    openModal: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.revokeAdminRef = React.createRef();
    this.revokeBoardRef = React.createRef();

    const { groups } = props;
    if (!groups) {
      return;
    }

    this.checkboxRefs = [];
    groups.forEach((group) => {
      this.checkboxRefs[group.id] = React.createRef();
    });
  }

  state = {
    error: null,
    groupsChanged: false,
    offboardChanged: false,
    savingGroups: false,
    savingOffboard: false,
  };

  isConnected = () => {
    const {
      company: { gsuite },
    } = this.props;
    return gsuite && !gsuite?.lostAccess;
  };

  onAuthFailure = (error) => {
    this.setState({
      error: 'Something went wrong, please contact',
    });
  };

  onAuthSuccess = (code) => {
    AJAX.post(
      '/api/gsuite/connect',
      {
        code,
      },
      (response) => {
        const { reloadCompany, reloadGroups } = this.props;
        reloadCompany().then(() => {
          reloadGroups().then(() => {
            this.setState({
              error: null,
            });
          });
        });
      }
    );
  };

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Google Workspace account?",
      onConfirm: () => {
        AJAX.post('/api/gsuite/disconnect', {}, (response) => {
          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please try again later',
            });
          } else {
            const { reloadCompany } = this.props;
            reloadCompany().then(() => {
              this.setState({
                error: null,
              });
            });
          }
        });
      },
    });
  };

  onGroupChange = () => {
    this.setState({
      groupsChanged: true,
    });
  };

  onImport = () => {
    const { savingGroups } = this.state;
    if (savingGroups) {
      return;
    }

    this.setState({
      savingGroups: true,
    });

    AJAX.post('/api/gsuite/import', {}, (response) => {
      if (response !== 'success') {
        this.setState({
          error: 'Something went wrong, please try again later',
          savingGroups: false,
        });
      } else {
        const { reloadCompany } = this.props;
        reloadCompany().then(() => {
          this.setState({
            error: null,
            groupsChanged: false,
            savingGroups: false,
          });
        });
      }
    });
  };

  onSaveGroups = () => {
    const { savingGroups } = this.state;
    if (savingGroups) {
      return;
    }

    const { groups } = this.props;
    const groupIDs = [];
    groups.forEach((group) => {
      const checkbox = this.checkboxRefs[group.id].current;
      const checked = checkbox.getValue();
      if (checked) {
        groupIDs.push(group.id);
      }
    });

    this.setState({
      savingGroups: true,
    });

    AJAX.post(
      '/api/gsuite/updateGroups',
      {
        groupIDs,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            savingGroups: false,
          });
        } else {
          const { reloadCompany } = this.props;
          reloadCompany().then(() => {
            this.setState({
              error: null,
              groupsChanged: false,
              savingGroups: false,
            });
          });
        }
      }
    );
  };

  onSaveOffboard = () => {
    const { savingOffboard } = this.state;
    if (savingOffboard) {
      return;
    }

    const revokeAdminAccess = this.revokeAdminRef.current.getValue();
    const revokeBoardAccess = this.revokeBoardRef.current.getValue();

    this.setState({
      error: null,
      savingOffboard: true,
    });

    AJAX.post(
      '/api/gsuite/updateOffboardSettings',
      {
        revokeAdminAccess,
        revokeBoardAccess,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later',
            savingOffboard: false,
          });
        } else {
          const { reloadCompany } = this.props;
          reloadCompany().then(() => {
            this.setState({
              error: null,
              offboardChanged: false,
              savingOffboard: false,
            });
          });
        }
      }
    );
  };

  renderDisconnectButton(className) {
    return (
      <Tappable onTap={this.onDisconnect}>
        <div className={classnames('disconnect', className)}>Disconnect</div>
      </Tappable>
    );
  }

  renderIntegrateButton() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
      company,
    } = this.props;
    const { lostAccess, domain } = company?.gsuite ?? {};
    if (!company?.integrations?.gsuite) {
      return <AdminFeatureUpsell feature="gsuite" />;
    }

    if (this.isConnected()) {
      return null;
    }

    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    return (
      <div>
        {lostAccess ? (
          <InformationBox icon="!" className="withMarginBottom">
            Canny has lost access to your Google Workspace account ({domain}). Please reinstall the
            integration to continue using it. Alternatively, disconnect the integration completely.
          </InformationBox>
        ) : null}
        <div>
          <AuthButton
            authType="gsuite"
            onFailure={this.onAuthFailure}
            onSuccess={this.onAuthSuccess}
            className="authButton"
            value={lostAccess ? 'Reconnect with Google Workspace' : 'Connect with Google Workspace'}
          />
        </div>
        {lostAccess ? this.renderDisconnectButton('reinstall') : null}
      </div>
    );
  }

  renderConnected() {
    const {
      company: { gsuite },
    } = this.props;
    if (!this.isConnected()) {
      return null;
    }

    return (
      <div className="connected">
        <div className="text">Your Google Workspace account ({gsuite.domain}) is connected.</div>
        {this.renderDisconnectButton()}
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderGroups() {
    const {
      company: {
        gsuite: { groupIDs },
      },
      groups,
    } = this.props;
    if (!groups) {
      return null;
    }

    const items = groups.map((group, i) => (
      <CheckboxInput
        defaultChecked={groupIDs.includes(group.id)}
        key={group.id}
        label={group.name}
        onChange={this.onGroupChange}
        ref={this.checkboxRefs[group.id]}
      />
    ));
    return <div className="groups">{items}</div>;
  }

  renderImportSection() {
    return (
      <div className="import">
        <div className="explanation">
          <div className="subheading">Import groups of Google Workspace users</div>
          <div className="text">
            This feature lets you import groups of users from Google Workspace, so you can track
            feedback on behalf of members of your&nbsp;organization.
          </div>
          <div className="text">
            After connecting your Google Workspace account, and choosing some groups, we'll
            immediately begin importing users. We'll also re-import every week, when new members
            join your&nbsp;team.
          </div>
        </div>
        {this.renderImportSettings()}
      </div>
    );
  }

  renderImportSettings() {
    const {
      company: { gsuite },
    } = this.props;
    if (!this.isConnected()) {
      return null;
    }

    const { groupsChanged, savingGroups } = this.state;
    return (
      <div className="importSettings">
        <div className="text">
          Last import:&nbsp;
          {gsuite.lastSync ? <Timestamp timestamp={gsuite.lastSync} /> : 'Never'}
        </div>
        <div className="text">
          Next import:&nbsp;
          {gsuite.nextSync ? (
            <Timestamp showRelative={false} timestamp={gsuite.nextSync} />
          ) : (
            'Never'
          )}
        </div>
        {this.renderGroups()}
        <Button
          disabled={gsuite.syncing}
          loading={gsuite.syncing || savingGroups}
          onTap={groupsChanged ? this.onSaveGroups : this.onImport}
          value={groupsChanged ? 'Save Groups & Run Import' : 'Run Import Now'}
        />
      </div>
    );
  }

  renderOffboardSection() {
    return (
      <div className="offboard">
        <div className="explanation">
          <div className="subheading">Revoke access from offboarded employees</div>
          <div className="text">
            This feature lets you revoke access from people once they leave your company. The way it
            works is Google Workspace notifies us when a Google Workspace user account is deleted,
            and then we revoke permissions&nbsp;accordingly.
          </div>
        </div>
        {this.renderOffboardSettings()}
      </div>
    );
  }

  renderOffboardSettings() {
    const {
      company: { gsuite },
    } = this.props;
    if (!this.isConnected()) {
      return null;
    }

    const { offboardChanged, savingOffboard } = this.state;
    return (
      <div className="offboardSettings">
        <div className="toggleContainer">
          <Toggle
            defaultValue={gsuite.revokeAdminAccess}
            onToggle={() => {
              this.setState({ offboardChanged: true });
            }}
            ref={this.revokeAdminRef}
          />
          <div className="label">Revoke admin account</div>
        </div>
        <div className="toggleContainer">
          <Toggle
            defaultValue={gsuite.revokeBoardAccess}
            onToggle={() => {
              this.setState({ offboardChanged: true });
            }}
            ref={this.revokeBoardRef}
          />
          <div className="label">Revoke private board access</div>
        </div>
        <Button
          disabled={!offboardChanged || savingOffboard}
          loading={savingOffboard}
          onTap={this.onSaveOffboard}
          value={'Save Offboard Settings'}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="adminGSuiteSettings">
        <Helmet title="Google Workspace Integration | Canny" />
        <div className="settingsHeading">Google Workspace Integration</div>
        <div className="content">
          {this.renderConnected()}
          {this.renderImportSection()}
          {this.renderOffboardSection()}
          {this.renderIntegrateButton()}
          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default compose(
  asyncConnect(
    [
      {
        promise: ({ store: { dispatch, getState } }) => {
          return dispatch(loadGroups());
        },
      },
    ],
    (state) => ({
      groups: state.gsuiteGroups && state.gsuiteGroups.groups,
    }),
    (dispatch) => ({
      reloadCompany: (post) => {
        return dispatch(reloadCompany());
      },
      reloadGroups: () => {
        return dispatch(reloadGroups());
      },
    })
  ),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.gSuite), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    { forwardRef: true }
  )
)(AdminGSuiteSettings);

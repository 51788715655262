import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import 'css/components/_RadioGroupV2.scss';

export const RadioContext = React.createContext<Omit<Props, 'children'> | null>(null);

interface Props {
  children: React.ReactNode;
  className?: string;
  error?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  size: Size;
  disabled?: boolean;
  name: string;
}

type Size = 'small' | 'medium' | 'large';

const RadioGroupV2 = ({
  children,
  className,
  error = false,
  value,
  size = 'medium',
  name,
  onChange,
  disabled,
}: Props) => {
  const [radioGroupValue, setRadioGroupValue] = useState(value);

  const handleOnChange = (value: string) => {
    setRadioGroupValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    setRadioGroupValue(value);
  }, [value]);

  return (
    <RadioContext.Provider
      value={{ value: radioGroupValue, onChange: handleOnChange, size, name, disabled, error }}>
      <div className={classnames(className, 'radioGroup')} role="radiogroup">
        {children}
      </div>
    </RadioContext.Provider>
  );
};

export default RadioGroupV2;

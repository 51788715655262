import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { invalidateSuggestions, loadSuggestions } from 'common/actions/linearIssueSuggestions';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import AdminIntegrationSearchForm from 'common/subdomain/admin/AdminIntegrationSearchForm';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

const Errors = {
  'not authorized': `You are not authorized to use this feature. Reach for one of you company's admins for support.`,
  'linear not installed': `Your company does not have the Linear integration installed. Please, install it before linking tasks.`,
};

const AdminLinearSearchForm = ({ issueSuggestions, loadSuggestions, post, reloadPost }) => {
  // state
  const [error, setError] = useState(null);

  // helpers
  const linkIssue = async (issue) => {
    setError(null);

    const response = await AJAX.post('/api/linear/linkIssue', {
      issueID: issue.id,
      postID: post._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: Errors,
    });

    if (error) {
      setError(error.message);
      return;
    }

    await reloadPost(post);
  };

  // renders
  const renderError = () => (error ? <div className="error">{error}</div> : null);
  const renderSuggestion = (suggestion) => suggestion?.title;

  return (
    <div className="adminLinearSearchForm">
      <AdminIntegrationSearchForm
        loadSuggestions={loadSuggestions}
        onSuggestionSelected={linkIssue}
        placeholder={'Linear Issue Title'}
        renderSuggestion={renderSuggestion}
        suggestions={issueSuggestions}
      />
      {renderError()}
    </div>
  );
};

AdminLinearSearchForm.propTypes = {
  issueSuggestions: PropTypes.object,
  post: PropTypes.object,
};

export default connect(
  (state) => ({ issueSuggestions: state.linearIssueSuggestions }),
  (dispatch) => ({
    loadSuggestions: (searchQuery) => dispatch(loadSuggestions(searchQuery)),
    reloadPost: (post) => {
      return Promise.all([dispatch(invalidateSuggestions()), dispatch(reloadPost(post))]);
    },
  })
)(AdminLinearSearchForm);

import React, { useContext } from 'react';

import { RouterContext } from 'common/containers/RouterContainer';
import AdminQueueAlert, {
  AlertTypes,
} from 'common/subdomain/admin/AdminQueue/AdminQueueAlerts/AdminQueueAlert';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';

const AdminQueuePaused = () => {
  const router = useContext(RouterContext);

  return (
    <AdminQueueAlert
      type={AlertTypes.paused}
      text={
        <P>
          Feedback discovery is currently paused. Enable a source to continue receiving insights
          and&nbsp;feedback.
        </P>
      }
      actions={
        <ButtonV2
          onClick={() => router.push('/admin/settings/autopilot/feedback-discovery')}
          size="medium">
          Re-enable Autopilot
        </ButtonV2>
      }
    />
  );
};

export default AdminQueuePaused;

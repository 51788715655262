import React, { Component } from 'react';

import PropTypes from 'prop-types';

import * as AuthRequests from 'common/auth/AuthRequests';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withContexts from 'common/util/withContexts';

import SentrySDK from './3rd/SentrySDK';
import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Tappable from './Tappable';
import validateInput from './validateInput';

import UserPlaceholderImage from 'img/user-placeholder.svg';

import 'css/components/_OAuthSignupForm.scss';

/*
 * this.props.oauthData = {
 *   githubUserID,
 *   facebookUserID,
 *   email,
 *   avatarURL,
 *   name,
 * }
 *
 * All fields are optional, it's whatever we get from the oauth. We should get at least one token.
 */

class OAuthSignupForm extends Component {
  static propTypes = {
    isWidget: PropTypes.bool.isRequired,
    oauthData: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    failure: null,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
  }

  componentDidMount() {
    const { oauthData } = this.props;
    let error = null;

    if (!oauthData) {
      error = new Error('OAuthSignupForm: oauthData is null');
    } else if (!oauthData.githubUserID && !oauthData.fbUserID && !oauthData.googleUserID) {
      error = new Error('OAuthSignupForm: missing google/github/facebook userID');
    } else if (oauthData.fbUserID && !oauthData.fbAccessToken) {
      error = new Error('OAuthSignupForm: missing fbAccessToken for fb signup');
    } else if (oauthData.githubUserID && !oauthData.ppToken) {
      error = new Error('OAuthSignupForm: missing ppToken for github signup');
    } else if (oauthData.googleUserID && !oauthData.googleIDToken) {
      error = new Error('OAuthSignupForm: missing googleIDToken for google signup');
    }

    if (error) {
      this.setState({
        failure:
          "We're sorry. Our team has been notified and we'll figure this out soon. " +
          'Please try again later.',
      });
      SentrySDK.captureException(error);
      return;
    }
  }

  _getAuthData = () => {
    const { oauthData } = this.props;
    if (oauthData.githubUserID) {
      return {
        githubUserID: oauthData.githubUserID,
        ppToken: oauthData.ppToken,
      };
    } else if (oauthData.fbUserID) {
      return {
        fbAccessToken: oauthData.fbAccessToken,
        fbUserID: oauthData.fbUserID,
      };
    } else if (oauthData.googleUserID) {
      return {
        googleIDToken: oauthData.googleIDToken,
        googleUserID: oauthData.googleUserID,
      };
    }
  };

  onSubmit = async (e) => {
    e && e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const name = this.nameRef.current.getValue().trim();
    const email = this.emailRef.current.getValue().trim();

    if (!name || !validateInput.userName(name)) {
      this.setState({
        error: 'Please enter a valid name',
      });
      return;
    } else if (!email || !validateInput.email(email)) {
      this.setState({
        error: 'Please enter a valid email',
      });
      return;
    }

    const data = this._getAuthData();

    data.name = name;
    data.email = email;
    data.useTokenAuth = this.props.isWidget;

    if (this.props.oauthData.avatarURL) {
      data.avatarURL = this.props.oauthData.avatarURL;
    }

    this.setState({
      error: null,
      submitting: true,
    });

    const { error, parsedResponse, redirecting } = await AuthRequests.signup(data);

    if (redirecting) {
      return;
    }

    if (!error) {
      this.setState(
        {
          submitting: false,
        },
        () => this.props.onSuccess(parsedResponse)
      );
      return;
    }

    this.setState({
      submitting: false,
      error: error.message,
    });
  };

  renderAvatar() {
    const { oauthData } = this.props;
    const avatarURL = oauthData.avatarURL || UserPlaceholderImage;

    return (
      <div className="avatarContainer">
        <LazyLoadedImage alt="user avatar" src={avatarURL} />
      </div>
    );
  }

  renderConnectedMessage() {
    const { oauthData } = this.props;
    let connectedTo = null;
    if (oauthData.githubUserID) {
      connectedTo = 'GitHub';
    } else if (oauthData.fbUserID) {
      connectedTo = 'Facebook';
    } else if (oauthData.googleUserID) {
      connectedTo = 'Google';
    }

    const message = 'Connected to ' + connectedTo + '!';

    return <span className="connectedTo">{message}</span>;
  }

  renderEmailForm() {
    const props = {};
    if (this.props.oauthData.email) {
      props.defaultValue = this.props.oauthData.email;
    }

    return (
      <TextInput
        {...props}
        ref={this.emailRef}
        autoFocus={!!this.props.oauthData.name && !this.props.oauthData.email}
        className="half"
        inset="Email"
        placeholder="you@example.com"
      />
    );
  }

  renderNameInput() {
    const props = {};
    if (this.props.oauthData.name) {
      props.defaultValue = this.props.oauthData.name;
    }

    return (
      <TextInput
        {...props}
        ref={this.nameRef}
        autoFocus={!this.props.oauthData.name}
        className="half"
        inset="Name"
        placeholder="Jane Doe"
      />
    );
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    if (this.state.failure) {
      return (
        <div className="oauthSignupForm">
          <h1>Something has gone wrong</h1>
          <div className="failure">{this.state.failure}</div>
        </div>
      );
    }

    return (
      <div className="oauthSignupForm">
        {this.renderAvatar()}
        <form onSubmit={this.onSubmit}>
          {this.renderConnectedMessage()}
          <div className="inputs">
            {this.renderNameInput()}
            {this.renderEmailForm()}
          </div>
          {this.renderErrorMessage()}
          <div className="confirm">
            <Tappable onTap={this.props.onCancel}>
              <span className="greyLink">Cancel</span>
            </Tappable>
            <Button
              formButton={true}
              loading={this.state.submitting}
              tint={true}
              value="Create Account"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default withContexts({ isWidget: IsWidgetContext }, { forwardRef: true })(OAuthSignupForm);

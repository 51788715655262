import React, { Component } from 'react';

import classnames from 'classnames';

import loadScript from 'common/util/loadScript';

import 'css/components/common/_ReCAPTCHAWidget.scss';

export const ReCAPTCHAPublicKeys = {
  v2: '6Ley_EofAAAAAJVfLglMetw5fBPfXvItYYueYTta',
  v3: '6Lc6NwobAAAAADaEvT-fs1KIi88N97fraLBa3efX',
};

class ReCAPTCHAWidget extends Component {
  state = {
    version: 'v3',
    widgetID: null,
  };

  constructor(props, context) {
    super(props, context);

    this.widgetContainerRef = React.createRef();
  }

  componentDidMount() {
    loadScript(
      `https://www.recaptcha.net/recaptcha/api.js?render=${ReCAPTCHAPublicKeys.v3}`,
      'recaptcha',
      { callback: this.onLoad }
    );
  }

  componentWillUnmount() {
    this.hideReCAPTCHABadge();
  }

  onLoad = () => {
    window.grecaptcha.ready(this.onReady);
  };

  onReady = () => {
    this.showReCAPTCHABadge();
  };

  findBadgeElement = () => {
    return window.document.querySelector('.grecaptcha-badge');
  };

  hideReCAPTCHABadge = () => {
    const badge = this.findBadgeElement();
    if (badge) {
      badge.classList.remove('showBadge');
    }
  };

  showReCAPTCHABadge = () => {
    const badge = this.findBadgeElement();
    if (badge) {
      badge.classList.add('showBadge');
    }
  };

  getCAPTCHA = async () => {
    if (!window.grecaptcha) {
      return null;
    }

    const { version, widgetID } = this.state;
    if (version === 'v3') {
      return {
        value: await window.grecaptcha.execute(ReCAPTCHAPublicKeys.v3, { action: 'submit' }),
        version: 'v3',
      };
    }

    return {
      value: await window.grecaptcha.getResponse(widgetID),
      version: 'v2',
    };
  };

  refresh = () => {
    const { version, widgetID } = this.state;
    if (!this.widgetContainerRef?.current) {
      return;
    }

    if (version === 'v3') {
      const widgetID = window.grecaptcha.render(this.widgetContainerRef.current, {
        sitekey: ReCAPTCHAPublicKeys.v2,
        theme: 'light',
      });
      this.setState({ version: 'v2', widgetID });
      return;
    }

    const newWidgetID = window.grecaptcha.reset(widgetID);
    this.setState({ widgetID: newWidgetID });
  };

  render() {
    const { version } = this.state;

    return (
      <div
        className={classnames({ reCAPTCHAWidget: version === 'v2' })}
        ref={this.widgetContainerRef}
      />
    );
  }
}

export default ReCAPTCHAWidget;

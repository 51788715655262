import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { ChevronDown, ChevronUp } from 'lucide-react';

import Tooltip from 'common/common/Tooltip';
import { RevenueTimeframes, convertMRRToTimeframe } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import DataTable, { type Column, type Row as DataTableRow } from 'common/DataTable';
import PostStatusV2 from 'common/post/PostStatusV2';
import VoteWeightPill from 'common/post/VoteWeightPill';
import EmptyState from 'common/subdomain/admin/reports/common/EmptyState';
import { ThirdPartyCompanyDefaultField } from 'common/thirdPartyCompanies/constants';
import Timestamp from 'common/Timestamp';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import isNil from 'common/util/isNil';
import numberWithCommas from 'common/util/numberWithCommas';
import roundCents from 'common/util/roundCents';
import styles from 'css-module/components/subdomain/admin/reports/_ThirdPartyCompanyFeatureRequestsTable.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type {
  CompanyWithFeatureRequests,
  PostWithVoteWeights,
  Sort,
  ThirdPartyCompany,
} from 'common/api/endpoints/thirdPartyCompanyFeatureRequests';
import type { VoteWeight } from 'common/post/PostVotes/Constants';

type Row = DataTableRow & { type: 'company' | 'post'; hidden?: boolean } & Partial<{
    title: string;
    monthlySpend: number;
    renewalRisk: string;
    renewalDate: string;
    accountOwner: string;
    company: ThirdPartyCompany;
  }> &
  Partial<{
    title: string;
    postStatus: string;
    post: PostWithVoteWeights;
    parentCompanyID: string;
    voteWeight: VoteWeight;
    totalOpportunityValue: number;
  }>;

type Props = {
  companies: CompanyWithFeatureRequests[];
  loading?: boolean;
  onSort: (sort: Sort | null) => void;
  onSelectPost: (post: PostWithVoteWeights) => void;
};

const SortFieldMap = {
  title: ThirdPartyCompanyDefaultField.monthlySpend,
  renewalDate: ThirdPartyCompanyDefaultField.renewalDate,
} as const;

const toField = (field: unknown, cast = String) => {
  return field ? cast(field) : undefined;
};

const ThirdPartyCompanyFeatureRequestsTable = ({
  companies,
  onSort,
  loading,
  onSelectPost,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const [hiddenColumnIDs, setHiddenColumnIDs] = useState<string[]>([]);

  const columns = [
    {
      id: 'title',
      align: 'left',
      header: 'Company Spend',
      sortable: company.hasCollectedMonthlySpend,
      sticky: true,
      onClick: (row) => {
        if (row.type !== 'post' || !row.post) {
          return;
        }

        onSelectPost(row.post);
      },
      getClassName: (_, { type }) =>
        type === 'company' ? styles.companyNameContainerCell : styles.postTitleContainerCell,
      cell: (title, { type, monthlySpend, post }) => {
        if (type === 'post' && post) {
          return (
            <div className={styles.postCell}>
              <button className={styles.postTitleLink}>
                <P className={styles.postTitle}>{title}</P>
              </button>
            </div>
          );
        }

        const timeframe = company.revenueTimeframe === RevenueTimeframes.mrr ? 'mo' : 'yr';
        const spend =
          monthlySpend &&
          numberWithCommas(
            roundCents(convertMRRToTimeframe(monthlySpend, company.revenueTimeframe))
          );

        return (
          <div className={styles.companyCell}>
            <P fontWeight="semibold" className={styles.companyTitle}>
              {title}
            </P>
            {monthlySpend ? (
              <P>
                (${spend}/{timeframe})
              </P>
            ) : null}
          </div>
        );
      },
    } as Column<Row, 'title'>,
    {
      id: 'renewalRisk',
      align: 'left',
      header: 'Renewal Risk',
      sortable: false,
      cell: (renewalRisk) => {
        if (!renewalRisk) {
          return <div className={styles.renewalRiskCell} />;
        }

        return (
          <Tooltip value={renewalRisk} delay={300} position="top">
            <P className={styles.renewalRiskCell}>{renewalRisk}</P>
          </Tooltip>
        );
      },
    } as Column<Row, 'renewalRisk'>,
    {
      id: 'accountOwner',
      align: 'left',
      header: 'Account Owner',
      sortable: false,
      cell: (accountOwner) => {
        if (!accountOwner) {
          return <div className={styles.accountOwnerCell} />;
        }

        return (
          <Tooltip value={accountOwner} delay={300} position="top">
            <P className={styles.accountOwnerCell}>{accountOwner}</P>
          </Tooltip>
        );
      },
    } as Column<Row, 'accountOwner'>,
    {
      id: 'renewalDate',
      align: 'left',
      header: 'Renewal Date',
      sortable: true,
      cell: (date) => {
        if (!date) {
          return <div className={styles.renewalDateCell} />;
        }

        return (
          <Timestamp
            className={styles.renewalDateCell}
            showRelative={false}
            format="MMM D, YYYY"
            timestamp={date}
          />
        );
      },
    } as Column<Row, 'renewalDate'>,
    {
      id: 'postStatus',
      align: 'left',
      header: 'Post Status',
      sortable: false,
      cell: (status) => {
        if (!status) {
          return <div className={styles.postStatusCell} />;
        }

        return (
          <div className={styles.postStatusCell}>
            <PostStatusV2 showOpen={true} isBadge={true} status={status} />
          </div>
        );
      },
    } as Column<Row, 'postStatus'>,
    {
      id: 'voteWeight',
      align: 'left',
      header: 'Priority',
      sortable: false,
      cell: (weight) => {
        if (isNil(weight)) {
          return <div className={styles.voteWeightCell} />;
        }

        return (
          <div className={styles.voteWeightCell}>
            <VoteWeightPill weight={weight} />
          </div>
        );
      },
    } as Column<Row, 'voteWeight'>,
    {
      id: 'totalOpportunityValue',
      align: 'left',
      header: 'Total Opportunity',
      sortable: false,
      cell: (totalOpportunityValue, { type, hidden }) => {
        if (type === 'company') {
          return (
            <div className={classnames(styles.toggleRowVisibilityCell, styles.opportunityCell)}>
              <IconButtonV2
                size="medium"
                icon={hidden ? ChevronUp : ChevronDown}
                variant="plain"
                aria-label="Hide company"
              />
            </div>
          );
        }

        if (isNil(totalOpportunityValue)) {
          return <div className={styles.opportunityCell} />;
        }

        return (
          <div className={styles.opportunityCell}>
            <P className={styles.opportunityCellValue}>
              ${numberWithCommas(roundCents(totalOpportunityValue))}
            </P>
          </div>
        );
      },
    } as Column<Row, 'totalOpportunityValue'>,
  ];

  const toggleCompanyRow = (row: DataTableRow) => {
    if (hiddenColumnIDs.includes(row.id)) {
      setHiddenColumnIDs(hiddenColumnIDs.filter((id) => id !== row.id));
    } else {
      setHiddenColumnIDs(hiddenColumnIDs.concat(row.id));
    }
  };

  const getRowsFromCompanies = () => {
    const rows: Row[] = [];

    const fieldMapping = company.thirdPartyCompanyFieldMapping;
    const accountOwnerField = fieldMapping[ThirdPartyCompanyDefaultField.accountOwner];
    const renewalRiskField = fieldMapping[ThirdPartyCompanyDefaultField.renewalRisk];
    const renewalDateField = fieldMapping[ThirdPartyCompanyDefaultField.renewalDate];

    companies.forEach(({ company, posts }) => {
      // add company
      rows.push({
        id: company._id,
        title: company.companyName,
        monthlySpend: company.monthlySpend ?? undefined,
        hidden: hiddenColumnIDs.includes(company._id),
        className: styles.companyRow,
        accountOwner: toField(company.companyCustomFields[accountOwnerField]),
        renewalDate: toField(company.companyCustomFields[renewalDateField]),
        renewalRisk: toField(company.companyCustomFields[renewalRiskField]),
        company,
        type: 'company',
        onClick: toggleCompanyRow,
      });

      // add posts
      posts.forEach((post) => {
        rows.push({
          id: `${company._id}_${post._id}`,
          title: post.title,
          postStatus: post.status,
          parentCompanyID: company._id,
          voteWeight: post.voteWeight,
          totalOpportunityValue: post.totalOpportunityValue,
          className: styles.postRow,
          post,
          type: 'post',
        });
      });
    });

    return rows;
  };

  const rows: Row[] = getRowsFromCompanies().filter(
    (row) => !row.parentCompanyID || !hiddenColumnIDs.includes(row.parentCompanyID)
  );

  return (
    <DataTable
      columns={columns}
      rows={rows}
      loading={loading}
      showLoadingWithHeaders={true}
      hoverable={false}
      emptyState={
        <tr>
          <td colSpan={columns.length}>
            <EmptyState
              message="Ensure you have sufficient data or try different filters."
              learnMore="https://help.canny.io/en/articles/8737133-canny-reporting#h_0cd00d5622"
            />
          </td>
        </tr>
      }
      onSort={(sort) => {
        if (!sort) {
          return onSort(null);
        }

        const { columnID, order } = sort;
        const field = SortFieldMap[columnID as keyof typeof SortFieldMap];
        if (field) {
          return onSort({ field, order });
        }
      }}
    />
  );
};

export default ThirdPartyCompanyFeatureRequestsTable;

export enum ThirdPartyCompanyDefaultField {
  'accountOwner' = 'accountOwner',
  'accountOwnerEmail' = 'accountOwnerEmail',
  'accountOwnerID' = 'accountOwnerID',
  'customerStatus' = 'customerStatus',
  'monthlySpend' = 'monthlySpend',
  'renewalDate' = 'renewalDate',
  'renewalRisk' = 'renewalRisk',
}

enum FieldType {
  'boolean' = 'boolean',
  'number' = 'number',
  'date' = 'date',
  'string' = 'string',
}

export enum IntegrationsKeyMap {
  hubspot = 'hubspotCompanyID',
  intercom = 'intercomCompanyID',
  salesforce = 'salesforceAccountID',
  segment = 'segmentGroupID',
  zendesk = 'zendeskOrganizationID',
}

export type IntegrationName = keyof typeof IntegrationsKeyMap;
export const IntegrationNameMap = Object.keys(IntegrationsKeyMap).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {} as Record<IntegrationName, IntegrationName>
);

type DefaultField = {
  name: ThirdPartyCompanyDefaultField;
  type: FieldType;
};

const DefaultFieldMap: Record<ThirdPartyCompanyDefaultField, DefaultField> = {
  [ThirdPartyCompanyDefaultField.monthlySpend]: {
    name: ThirdPartyCompanyDefaultField.monthlySpend,
    type: FieldType.number,
  },
  [ThirdPartyCompanyDefaultField.renewalDate]: {
    name: ThirdPartyCompanyDefaultField.renewalDate,
    type: FieldType.date,
  },
  [ThirdPartyCompanyDefaultField.renewalRisk]: {
    name: ThirdPartyCompanyDefaultField.renewalRisk,
    type: FieldType.string,
  },
  [ThirdPartyCompanyDefaultField.accountOwner]: {
    name: ThirdPartyCompanyDefaultField.accountOwner,
    type: FieldType.string,
  },
  [ThirdPartyCompanyDefaultField.accountOwnerEmail]: {
    name: ThirdPartyCompanyDefaultField.accountOwnerEmail,
    type: FieldType.string,
  },
  [ThirdPartyCompanyDefaultField.accountOwnerID]: {
    name: ThirdPartyCompanyDefaultField.accountOwnerID,
    type: FieldType.string,
  },
  [ThirdPartyCompanyDefaultField.customerStatus]: {
    name: ThirdPartyCompanyDefaultField.customerStatus,
    type: FieldType.string,
  },
};

export const SalesforceAutomaticFields = {
  salesforce_owner_name: {
    name: 'salesforce_owner_name',
    linked: ThirdPartyCompanyDefaultField.accountOwner,
  },
  salesforce_owner_email: {
    name: 'salesforce_owner_email',
    linked: ThirdPartyCompanyDefaultField.accountOwnerEmail,
  },
};

export const HubspotAutomaticFields = {
  hubspot_owner_name: {
    name: 'hubspot_owner_name',
    linked: ThirdPartyCompanyDefaultField.accountOwner,
  },
  hubspot_owner_email: {
    name: 'hubspot_owner_email',
    linked: ThirdPartyCompanyDefaultField.accountOwnerEmail,
  },
};

export const DefaultFields = Object.values(DefaultFieldMap);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Modal from 'common/modals/Modal';

import 'css/components/modals/_VideoModal.scss';

export default class VideoModal extends Component {
  static propTypes = {
    source: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    this.iframeRef.current.setAttribute('allow', 'autoplay');
  }

  render() {
    return (
      <Modal className="videoModal">
        <iframe
          allow="autoplay"
          allowFullScreen
          frameBorder="0"
          ref={this.iframeRef}
          src={this.props.source}
        />
      </Modal>
    );
  }
}

import React, { useRef } from 'react';

import { MoreHorizontal } from 'lucide-react';

import Portal from 'common/common/Portal';
import Tappable from 'common/Tappable';
import IconButtonV2 from 'common/ui/IconButtonV2';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

type Props = {
  onArchiveRoadmap: () => void;
  onDeleteRoadmap: () => void;
  onDropdownBlur: () => void;
  onDuplicateRoadmap: () => void;
  onRenameRoadmap: () => void;
  onToggleOptionsMenu: () => void;
  roadmaps: Roadmap[];
  showOptionsMenu: boolean;
  loading: boolean;
};

const AdminRoadmapHeaderOptionsMenu = ({
  onArchiveRoadmap,
  onDeleteRoadmap,
  onDuplicateRoadmap,
  onDropdownBlur,
  onRenameRoadmap,
  onToggleOptionsMenu,
  roadmaps,
  showOptionsMenu,
  loading,
}: Props) => {
  const canDeleteOrArchive = roadmaps?.length > 1;
  const dropdownButtonRef = useRef(null);

  return (
    <div ref={dropdownButtonRef}>
      <IconButtonV2
        onClick={onToggleOptionsMenu}
        disabled={loading}
        icon={MoreHorizontal}
        aria-label="Roadmap options"
        variant="plain"
        size="medium"
        loading={loading}
      />
      {showOptionsMenu && (
        <Portal
          className="adminRoadmapHeaderPortal"
          onBlur={onDropdownBlur}
          relativeToRef={dropdownButtonRef}>
          <div className="dropdownMenu">
            {canDeleteOrArchive && (
              <Tappable onTap={onArchiveRoadmap}>
                <div className="dropdownMenuOption">Archive</div>
              </Tappable>
            )}
            <Tappable onTap={onDuplicateRoadmap}>
              <div className="dropdownMenuOption">Duplicate</div>
            </Tappable>
            <Tappable onTap={onRenameRoadmap}>
              <div className="dropdownMenuOption">Rename</div>
            </Tappable>
            {canDeleteOrArchive && (
              <Tappable onTap={onDeleteRoadmap}>
                <div className="dropdownMenuOption">Delete</div>
              </Tappable>
            )}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default AdminRoadmapHeaderOptionsMenu;

import {
  INVALIDATE,
  LOADING_MORE,
  QUERY_ERROR,
  QUERY_LOADED,
  REQUEST_QUERY,
  getUserQueryKey,
} from '../actions/userQueries';

export default function userQueries(state = {}, action) {
  switch (action.type) {
    case REQUEST_QUERY: {
      const queryKey = getUserQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case QUERY_LOADED: {
      const userIDs = [];
      action.result.users.forEach((user) => {
        userIDs.push(user._id);
      });
      const queryKey = getUserQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          hasNextPage: action.result.hasNextPage,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
          userIDs,
        }),
      });
    }

    case QUERY_ERROR: {
      const queryKey = getUserQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LOADING_MORE: {
      const queryKey = getUserQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case INVALIDATE: {
      return {};
    }

    default:
      return state;
  }
}

import publicConfig from 'common/core/publicConfig';

// localhost → localhost
// canny.io → .canny.io
// subdomain.canny.io → .canny.io
// customdomain.com → customdomain.com
// subdomain.customdomain.com → subdomain.customdomain.com
export default function getCookieDomain(host: string): string {
  const hostWithoutPort = host.split(':')[0];
  const parts = hostWithoutPort.split('.');

  const override = publicConfig('hostnameOverride') || 'canny.io';

  const isLocalhost = hostWithoutPort === 'localhost';
  const isLocalhostSubdomain = hostWithoutPort.match(/\.localhost$/i);
  const isCannyRegex = new RegExp(`(^|\.)${override}$`, 'i');
  const isCanny = hostWithoutPort.match(isCannyRegex);

  if (isLocalhost) {
    return 'localhost';
  } else if (isLocalhostSubdomain) {
    return '.' + parts[parts.length - 2] + '.' + parts[parts.length - 1];
  } else if (isCanny) {
    return `.${override}`;
  }

  return hostWithoutPort;
}

import {
  Invalidate,
  LoadingMoreRoadmap,
  RequestRoadmap,
  RoadmapError,
  RoadmapLoaded,
} from '../actions/roadmap';

import type { Board } from 'common/api/resources/board';
import type { Post } from 'common/api/resources/posts';

// TODO: Import this once the actions are typed
type Action = any;

type PostDetails = {
  boardID: string;
  postURLName: string;
};

type Filters = {
  boardURLNames: string[];
  categoryURLNames: string[];
  includeUncategorized: boolean;
};

export type RoadmapState = {
  boardURLNames?: string[];
  error?: string;
  filters?: Filters;
  hasNextPage?: boolean;
  lastUpdated: Date;
  loading?: boolean;
  loadingMore: boolean;
  pages?: number;
  posts?: PostDetails[];
} | null;

export default function roadmap(state = null, action: Action): RoadmapState {
  switch (action.type) {
    case RequestRoadmap: {
      return Object.assign({}, state, {
        filters: action.filters,
        hasNextPage: false,
        lastUpdated: action.timestamp,
        loading: true,
        loadingMore: false,
      });
    }

    case RoadmapLoaded: {
      const posts: PostDetails[] = [];
      action.result.posts.forEach((post: Post) => {
        posts.push({
          boardID: post.board._id,
          postURLName: post.urlName,
        });
      });
      return Object.assign({}, state, {
        boardURLNames: action.result.boards.map((board: Board) => board.urlName),
        filters: action.filters,
        hasNextPage: action.result.hasNextPage,
        pages: action.result.pages,
        posts,
        lastUpdated: action.timestamp,
        loading: false,
        loadingMore: false,
      });
    }

    case LoadingMoreRoadmap: {
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        loadingMore: true,
      });
    }

    case RoadmapError: {
      return Object.assign({}, state, {
        error: action.error,
        hasNextPage: false,
        lastUpdated: action.timestamp,
        loading: false,
        loadingMore: false,
      });
    }

    case Invalidate: {
      return null;
    }

    default:
      return state;
  }
}

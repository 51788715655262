export const postFieldAlphanumericOptions = [
  { label: 'Is empty', value: 'isEmpty' },
  { label: 'Is not empty', value: 'isNotEmpty' },
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'notContains' },
];

export const postFieldNumericOptions = [
  { label: 'Equals', value: 'equals' },
  { label: 'Does Not Equal', value: 'doesNotEqual' },
  { label: 'Greater Than', value: 'greaterThan' },
  { label: 'Less Than', value: 'lessThan' },
];

export const customFieldAlphanumericOptions = [
  { label: 'Is empty', value: 'isNull' },
  { label: 'Is not empty', value: 'isNotNull' },
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'doesNotContain' },
];

export const customFieldNumericOptions = [
  { label: 'Equals', value: 'is' },
  { label: 'Does Not Equal', value: 'isNot' },
  { label: 'Greater Than', value: 'greaterThan' },
  { label: 'Less Than', value: 'lessThan' },
];

export const textFilterOptionsSettings: { [key: string]: { requiresAdditionalValue: boolean } } = {
  contains: { requiresAdditionalValue: true },
  doesNotContain: { requiresAdditionalValue: true },
  notContains: { requiresAdditionalValue: true },
  isNull: { requiresAdditionalValue: false },
  isNotNull: { requiresAdditionalValue: false },
  isEmpty: { requiresAdditionalValue: false },
  isNotEmpty: { requiresAdditionalValue: false },
  is: { requiresAdditionalValue: true },
  isNot: { requiresAdditionalValue: true },
  equals: { requiresAdditionalValue: true },
  doesNotEqual: { requiresAdditionalValue: true },
  greaterThan: { requiresAdditionalValue: true },
  lessThan: { requiresAdditionalValue: true },
};

export type Filter = {
  operator: string;
  value: string;
};

import React, { useCallback, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
// import { browserHistory } from 'react-router';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';

export const TrackCannylyticsEventContext = React.createContext();

const CannylyticsContainer = ({ children, trackPageViews }) => {
  // context
  const company = useContext(CompanyContext);
  const viewer = useContext(ViewerContext);

  // helpers
  const trackEvent = useCallback(
    async (eventName, data) => {
      if (__DEV__) {
        return;
      }

      if (!company?._id || !viewer?._id) {
        return;
      }

      AJAX.post('/api/cannylytics/track', { data, event: eventName });
    },
    [company?._id, viewer?._id]
  );

  // effects
  useEffect(() => {
    if (__DEV__) {
      return;
    }

    // const onRouteChange = () => {
    //   trackEvent('page_view', {
    //     path: location.pathname,
    //     query: location.search,
    //   });
    // };

    trackEvent('app_load');

    // const unlisten = browserHistory.listen(onRouteChange);
    // return unlisten;
  }, [trackEvent]);

  return (
    <TrackCannylyticsEventContext.Provider value={trackEvent}>
      {children}
    </TrackCannylyticsEventContext.Provider>
  );
};

CannylyticsContainer.propTypes = {
  trackPageViews: PropTypes.bool,
};

CannylyticsContainer.defaultProps = {
  trackPageViews: false,
};

export default CannylyticsContainer;

import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import DataTable, { type Props as DataTableProps, type Row } from 'common/DataTable';

interface Props<R extends Row> extends DataTableProps<R> {
  hotkeys: Record<string, (row: R, element: HTMLElement | null) => void>;
}

export default function SelectableDataTable<R extends Row>(props: Props<R>) {
  const { rows, hotkeys } = props;
  const [selectedRow, setSelectedRow] = useState<R | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getRowElement = (row: R) => {
      const element: HTMLElement | null =
        tableRef.current?.querySelector(`tr[data-row="${row.id}"]`) ?? null;

      return element;
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (!selectedRow) {
        return;
      }

      if (e.altKey && hotkeys[e.code]) {
        const shortcutHandler = hotkeys[e.code];
        shortcutHandler(selectedRow, getRowElement(selectedRow));
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [rows, selectedRow, hotkeys]);

  const selectedRows = rows.map<R>((row: R) => ({
    ...row,
    selected: row.id === selectedRow?.id,
    className: classnames({ selected: row.id === selectedRow?.id }),
    onHover: () => setSelectedRow(row),
  }));

  return <DataTable<R> {...props} rows={selectedRows} ref={tableRef} />;
}

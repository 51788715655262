import {
  INVALIDATE_VOTERS,
  REQUEST_VOTERS,
  UPDATE_VOTE_WEIGHT,
  VOTERS_ERROR,
  VOTERS_LOADED,
} from '../actions/voters';

export default function voters(state = {}, action) {
  switch (action.type) {
    case REQUEST_VOTERS: {
      return Object.assign({}, state, {
        [action.post._id]: {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
        },
      });
    }

    case UPDATE_VOTE_WEIGHT: {
      const updatedPost = state[action.post._id];
      const voterToUpdate = updatedPost?.items?.find((voter) => voter._id === action.voter._id);

      // update state only if voter exists
      if (!voterToUpdate) {
        return state;
      }

      const updatedState = {
        ...state,
        [action.post._id]: {
          ...updatedPost,
          items: updatedPost.items.map((voter) =>
            voterToUpdate._id === voter._id
              ? {
                  ...voter,
                  weight: action.weight,
                }
              : voter
          ),
        },
      };

      return updatedState;
    }

    case VOTERS_LOADED: {
      return Object.assign({}, state, {
        [action.post._id]: Object.assign({}, state[action.post._id], {
          error: null,
          items: action.voters,
          lastUpdated: action.timestamp,
          loading: false,
          totalMRR: action.totalMRR,
        }),
      });
    }

    case VOTERS_ERROR: {
      return Object.assign({}, state, {
        [action.post._id]: Object.assign({}, state[action.post._id], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        }),
      });
    }

    case INVALIDATE_VOTERS: {
      return Object.assign({}, state, {
        [action.post._id]: null,
      });
    }

    default:
      return state;
  }
}

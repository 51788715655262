import React, { type SyntheticEvent } from 'react';

import classnames from 'classnames';

import TextInput from 'common/inputs/TextInput';
import SingleSelect from 'common/ui/SingleSelect';

import { findActiveOption } from '../util';

import type { PostContainsFilter } from 'common/automations/constants';

interface Props {
  condition: PostContainsFilter['condition'];
  value: PostContainsFilter['value'];
  onChange: (
    condition?: PostContainsFilter['condition'],
    value?: PostContainsFilter['value']
  ) => void;
  required: boolean;
}

const PostContent = ({ required, ...props }: Props) => {
  const { condition, value, onChange } = props;

  const conditionOptions = [
    {
      label: 'Contains all of',
      value: 'containsAll',
    },
    {
      label: 'Contains any of',
      value: 'containsAny',
    },
  ];
  const activeOption = findActiveOption(conditionOptions, condition);

  return (
    <>
      <SingleSelect
        className="filterField1of2"
        placeholder="Contains"
        onChange={(option) =>
          option && onChange(option.value as PostContainsFilter['condition'], value)
        }
        options={conditionOptions}
        value={activeOption}
        optionsMaxWidth="300px"
      />
      <TextInput
        className={classnames(
          'textInputOverrides',
          required ? 'filterField2of2-required' : 'filterField2of2'
        )}
        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
          onChange(condition, e.currentTarget.value.split(','))
        }
        placeholder="Keywords"
        value={value.join(',')}
      />
    </>
  );
};
export default PostContent;

import { StaticColors as Colors } from 'common/colors/constants';

const PostStatusColors = {
  Blue: {
    100: Colors.postStatusBlue100,
    200: Colors.postStatusBlue200,
    300: Colors.postStatusBlue300,
    400: Colors.postStatusBlue400,
    500: Colors.postStatusBlue500,
    600: Colors.postStatusBlue600,
    700: Colors.postStatusBlue700,
    800: Colors.postStatusBlue800,
  },
  Green: {
    100: Colors.postStatusGreen100,
    200: Colors.postStatusGreen200,
    300: Colors.postStatusGreen300,
    400: Colors.postStatusGreen400,
    500: Colors.postStatusGreen500,
    600: Colors.postStatusGreen600,
    700: Colors.postStatusGreen700,
    800: Colors.postStatusGreen800,
  },
  Purple: {
    100: Colors.postStatusPurple100,
    200: Colors.postStatusPurple200,
    300: Colors.postStatusPurple300,
    400: Colors.postStatusPurple400,
    500: Colors.postStatusPurple500,
    600: Colors.postStatusPurple600,
    700: Colors.postStatusPurple700,
    800: Colors.postStatusPurple800,
  },
  Red: {
    100: Colors.postStatusRed100,
    200: Colors.postStatusRed200,
    300: Colors.postStatusRed300,
    400: Colors.postStatusRed400,
    500: Colors.postStatusRed500,
    600: Colors.postStatusRed600,
    700: Colors.postStatusRed700,
    800: Colors.postStatusRed800,
  },
};

export const DefaultColors = {
  Complete: Colors.postStatusGreen200,
  InProgress: Colors.postStatusInProgress,
  Planned: Colors.postStatusPlanned,
  UnderReview: Colors.postStatusUnderReview,
  Open: Colors.postStatusOpen,
  Closed: Colors.postStatusClosed,
};

export const hexToStatusColor = (hex) => {
  const existingColor = Object.entries(DefaultColors).find(([code, color]) => color === hex);
  if (existingColor) {
    return existingColor[0];
  }

  for (const name in PostStatusColors) {
    for (const lighten in PostStatusColors[name]) {
      if (hex === PostStatusColors[name][lighten]) {
        return `${name}.${lighten}`;
      }
    }
  }
};

export const statusColorToHex = (color) => {
  if (color in DefaultColors) {
    return DefaultColors[color];
  }

  const [colorName, lighten] = color.split('.');
  return PostStatusColors[colorName][lighten];
};

export default PostStatusColors;

import React, { Component } from 'react';

import { Router, applyRouterMiddleware } from 'react-router';
import { ReduxAsyncConnect } from 'redux-connect';

import scrollMiddleware from '../core/scrollMiddleware';

function areRoutesEqual(r1, r2) {
  if (r1.props.path !== r2.props.path) {
    return false;
  } else if (typeof r1.props.component !== typeof r2.props.component) {
    return false;
  } else if (r1.props.component) {
    if (r1.props.component.name !== r2.props.component.name) {
      return false;
    }
  } else if (typeof r1.props.children !== typeof r2.props.children) {
    return false;
  } else if (r1.props.children) {
    if (r1.props.children.length !== r2.props.children.length) {
      return false;
    }

    for (let i = 0; i < r1.props.children.length; i++) {
      if (!areRoutesEqual(r1.props.children[i], r2.props.children[i])) {
        return false;
      }
    }
  }

  return true;
}

export default class CannyRouter extends Component {
  constructor(props) {
    super(props);
    this._key = 1;
    this._routes = this.props.routes;
  }

  componentDidUpdate() {
    if (areRoutesEqual(this._routes, this.props.routes)) {
      return;
    }

    this._key++;
    this._routes = this.props.routes;
  }

  render() {
    const { history, noSSR } = this.props;
    const routes = this._routes || this.props.routes;

    if (noSSR) {
      return <Router history={history} key={this._key} routes={routes} />;
    }

    return (
      <Router
        history={history}
        key={this._key}
        render={(props) => (
          <ReduxAsyncConnect {...props} render={applyRouterMiddleware(scrollMiddleware)} />
        )}
        routes={routes}
      />
    );
  }
}

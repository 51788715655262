import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminSelectUseCase.scss';

import CustomerFeedbackIcon from 'img/customer-customer-feedback-icon.png';
import CustomerTeamFeedbackIcon from 'img/customer-team-feedback-icon.png';
import TeammatesIcon from 'img/teammates-icon.png';

class AdminSelectUseCase extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
    }),
    router: PropTypes.object,
  };

  onSelected = (useCase) => {
    AJAX.post(
      '/api/company/updateUseCaseOld',
      {
        completed: true,
        customerFeedback: useCase === 'public' || useCase === 'private',
        publicFeedback: useCase === 'public',
        teammateFeedback: useCase === 'internal',
      },
      (response) => {
        const { router } = this.props;
        this.props.reloadCompany().then(() => router.push('/register/create-board'));
      }
    );
  };

  renderPrompt() {
    const options = [
      {
        description: 'A place for your customers to go to give\u00a0feedback',
        icon: CustomerFeedbackIcon,
        label: 'Public Feedback',
        value: 'public',
      },
      {
        description: 'A place for your support and sales teams to track customer\u00a0feedback',
        icon: CustomerTeamFeedbackIcon,
        label: 'Private Feedback',
        value: 'private',
      },
      {
        description: 'A place for your team\u00a0to\u00a0go to give feedback\u00a0internally',
        icon: TeammatesIcon,
        label: 'Employee Feedback',
        value: 'internal',
      },
    ];
    return (
      <div className="whoPrompt">
        <div className="prompt">
          <div className="question">What will you use Canny&nbsp;for?</div>
          <div className="motivation">This helps us tailor your&nbsp;onboarding.</div>
        </div>
        <div className="options">
          {options.map(({ description, icon, label, value }, i) => (
            <Tappable key={i} onTap={() => this.onSelected(value)}>
              <div className="option">
                <LazyLoadedImage className="icon" src={icon} />
                <div className="label">{label}</div>
                <div className="description">{description}</div>
              </div>
            </Tappable>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { company } = this.props;
    if (company.notFound) {
      return (
        <ContentContainer outerClassName="notFound">
          There is no such company. Did you enter the right URL?
        </ContentContainer>
      );
    }

    return <div className="adminSelectUseCase">{this.renderPrompt()}</div>;
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminSelectUseCase);

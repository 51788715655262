import { Component } from 'react';

import PropTypes from 'prop-types';

import { loadCustomFields } from 'common/actions/customFields';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

const asyncFetch = {
  promise: ({ store: { dispatch } }) => {
    return dispatch(loadCustomFields());
  },
};

class CustomFieldsContainer extends Component {
  static asyncConnect = asyncFetch;

  static propTypes = {
    customFields: PropTypes.object,
  };

  render() {
    const { children, customFields } = this.props;
    return cloneElementWithProps(children, {
      ...this.props,
      customFields: customFields && customFields.customFields ? customFields.customFields : [],
    });
  }
}

export default asyncConnect([asyncFetch], (state) => ({ customFields: state.customFields }))(
  CustomFieldsContainer
);

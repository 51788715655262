import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminBoardSettingsCategoriesList from 'common/subdomain/admin/AdminBoardSettingsCategoriesList';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import groupify from 'common/util/groupify';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminBoardSettingsCategories.scss';

class AdminBoardSettingsCategories extends Component {
  static propTypes = {
    board: PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          postCount: PropTypes.number,
          subscribed: PropTypes.bool,
          urlName: PropTypes.string,
        })
      ),
      uncategorized: PropTypes.shape({
        subscribed: PropTypes.bool,
      }),
    }),
    company: PropTypes.object,
    openModal: PropTypes.func,
    reloadBoard: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    categories: this.props.board?.categories ?? [],
    error: null,
    reordering: false,
  };

  componentDidUpdate(prevProps) {
    const { categories } = this.props.board;
    if (categories !== prevProps.board.categories) {
      this.setState({ categories });
    }
  }

  onCategoryCreated = async () => {
    const { board } = this.props;
    const { categories } = this.state;
    const parentCategories = categories.filter((category) => !category.parentID);
    const subCategories = categories.filter((category) => category.parentID);
    const subCategoriesByParentID = groupify(subCategories, 'parentID');
    const categoryIDs = [];

    parentCategories.forEach((parent) => {
      const subIDs = (subCategoriesByParentID[parent._id] ?? []).map((category) => category._id);
      categoryIDs.push(parent._id, ...subIDs);
    });

    await AJAX.post('/api/categories/reorder', {
      boardID: board._id,
      categoryIDs,
    });

    await this.reloadBoard();
  };

  onDragCompleted = async (targetCategory, shouldComeBeforeCategory = null) => {
    const { board } = this.props;
    const { categories } = this.state;

    const categoriesWithoutTarget = categories.filter(
      (category) => category._id !== targetCategory._id
    );
    const newCategories = [];

    categoriesWithoutTarget.forEach((category) => {
      if (category._id === shouldComeBeforeCategory?._id) {
        newCategories.push(targetCategory);
      }
      newCategories.push(category);
    });

    if (!shouldComeBeforeCategory) {
      newCategories.push(targetCategory);
    }

    const newCategoryIDs = newCategories.map((category) => category._id);
    this.setState({ categories: newCategories });

    const response = await AJAX.post('/api/categories/reorder', {
      boardID: board._id,
      categoryIDs: newCategoryIDs,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setError(error.message);
    } else {
      await this.reloadBoard();
    }
  };

  reloadBoard = () => {
    const { board, reloadBoard } = this.props;
    return reloadBoard(board.urlName);
  };

  setError = (error) => {
    this.setState({ error });
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderCategories() {
    const { board } = this.props;
    const { categories, reordering } = this.state;

    if (reordering) {
      return (
        <div className="reordering">
          <Spinner />
        </div>
      );
    }

    const parentCategories = categories.filter((category) => !category.parentID);
    const parentCategoriesMap = {};

    parentCategories.forEach((parentCategory) => {
      parentCategory.subCategories = [];
      parentCategoriesMap[parentCategory._id] = parentCategory;
    });

    categories.forEach((category) => {
      if (!category.parentID) {
        return;
      }
      parentCategoriesMap[category.parentID].subCategories.push(category);
    });

    return (
      <AdminBoardSettingsCategoriesList
        board={board}
        categories={parentCategories}
        onCategoryCreated={this.onCategoryCreated}
        onDragCompleted={this.onDragCompleted}
        reloadBoard={this.reloadBoard}
        setError={this.setError}
        showCreateForm={true}
      />
    );
  }

  render() {
    const {
      board,
      company: { features },
    } = this.props;
    if (!features?.categories) {
      return (
        <div className="optionDetails">
          <AdminFeatureUpsell feature="categories" />
        </div>
      );
    }

    return (
      <div className="adminBoardSettingsCategories">
        <Helmet title={'Category Settings | ' + board.name + ' | Canny'} />
        <div className="text">
          Categories are an additional level of organization within boards. Typically you'll want to
          make one category per team. This way people can subscribe to just the feedback that's
          relevant to&nbsp;them.
        </div>
        {this.renderCategories()}
        {this.renderError()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => {
      return Promise.all([dispatch(reloadBoard(boardURLName))]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.board.categories),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBoardSettingsCategories);

import React from 'react';

import ModernModal from 'common/modals/ModernModal';
import ButtonV2 from 'common/ui/ButtonV2';
import { H2, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationUninstallConfirmationModal.module.scss';

type Props = {
  integrationLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const AdminIntegrationUninstallConfirmationModal = ({
  integrationLabel,
  onConfirm,
  onCancel,
}: Props) => {
  return (
    <ModernModal
      header={null}
      hideHeader
      footer={null}
      hideFooter
      onClose={onCancel}
      modalClassName={styles.uninstallModal}
      sections={[
        <>
          <H2 variant="headingSm" className={styles.header}>
            Remove {integrationLabel} from Autopilot?
          </H2>
          <P className={styles.subheader}>
            New feedback from {integrationLabel} won't be captured until you re-enable it.
          </P>
          <div className={styles.buttons}>
            <ButtonV2 variant="outlined" onClick={onCancel} size="medium">
              Cancel
            </ButtonV2>
            <ButtonV2 onClick={onConfirm} size="medium" color="error">
              Uninstall source
            </ButtonV2>
          </div>
        </>,
      ]}
    />
  );
};

export default AdminIntegrationUninstallConfirmationModal;

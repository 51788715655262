import React, { useContext, useEffect, useState } from 'react';

import { Mail } from 'lucide-react';

import AccountModal, { FormStates } from 'common/AccountModal';
import AJAX from 'common/AJAX';
import CannyAttribution from 'common/CannyAttribution';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import SpinnerV2 from 'common/SpinnerV2';
import ButtonV2 from 'common/ui/ButtonV2';
import { H2, P } from 'common/ui/Text';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import nbspLastSpace from 'common/util/nbspLastSpace';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/public/_JoinRoadmapForm.scss';

const JoinRoadmapForm = () => {
  const location = useContext(LocationContext);
  const viewer = useContext(ViewerContext);
  const company = useContext(CompanyContext);
  const router = useContext(RouterContext);
  const params = useContext(ParamsContext);
  const openModal = useContext(OpenModalContext);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const [invitee, setInvitee] = useState<string>('');
  const [roadmapName, setRoadmapName] = useState<string>('');

  useEffect(() => {
    const { query } = location;

    // make sure we have valid query props
    if (
      !validateInput.urlName(params.roadmapURLName) ||
      !validateInput.email(query.email) ||
      !validateInput.sessionID(query.inviteID) ||
      !validateInput.id(params.roadmapViewID)
    ) {
      router.push({
        pathname: '',
      });
      return;
    }

    verifyInvite();
  });

  const verifyInvite = async () => {
    const { inviteID, email } = location.query;
    const { roadmapURLName } = params;
    const response = await AJAX.post('/api/roadmaps/verifyInvite', {
      roadmapURLName,
      roadmapSavedSettingsViewID: params.roadmapViewID,
      inviteID,
      email,
    });
    const { error, parsedResponse } = parseAPIResponse<{
      inviteeName: string | undefined;
      roadmapName: string;
    }>(response, {
      isSuccessful: (parsedResponse) =>
        !!parsedResponse.inviteeName && !!parsedResponse.roadmapName,
      errors: {
        'invalid invite': `Your invitation is no longer available, please contact ${company.name} to get access.`,
        'invalid input': 'Something went wrong. Please try again.',
      },
    });

    if (error) {
      setError(error.message);
      return;
    }

    // if we're logged in - accept the invite
    if (viewer && !viewer.loggedOut) {
      acceptInvite();
    } else {
      // else, set state that shows login
      setVerified(true);
      setInvitee(parsedResponse?.inviteeName ?? '');
      setRoadmapName(parsedResponse?.roadmapName ?? '');
    }
  };
  // https://oof.canny.localhost:8007/roadmap/long-roadmap/661692fd07765aa4155d2529?email=miko@mikosramek.ca&inviteID=0beb4bca-1078-3573-0dff-25ee862ca49c
  const acceptInvite = async () => {
    const { inviteID, email } = location.query;
    const { roadmapURLName } = params;

    const response = await AJAX.post('/api/roadmaps/acceptInvite', {
      roadmapURLName,
      roadmapSavedSettingsViewID: params.roadmapViewID,
      inviteID,
      email,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'invalid invite': `Your invitation is no longer available, please contact ${company.name} to get access.`,
        'invalid input': 'Something went wrong. Please try again.',
      },
    });

    if (error) {
      setError(error.message);
      return;
    }

    // reload the page
    const url = new URL(window.location.href);
    const refreshParams = new URLSearchParams();
    refreshParams.set('success', 'true');
    url.search = refreshParams.toString();
    window.location.replace(url.toString());
  };

  const openAccountModal = () => {
    const { email } = location.query;

    openModal(AccountModal, {
      email,
      formState: FormStates.signup,
      onSuccess: () => {
        acceptInvite();
      },
    });
  };

  const openLoginModal = () => {
    const { email } = location.query;

    openModal(AccountModal, {
      email,
      formState: FormStates.login,
      onSuccess: () => {
        acceptInvite();
      },
    });
  };

  const renderCTA = () => {
    const { authRedirectEnabled, authRedirectURL } = company;
    if (authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location);
      return (
        <>
          <P className="description" variant="bodyLg">
            Log in with your Canny account to view this&nbsp;roadmap.
          </P>
          <a href={redirectURL} className="authRedirect">
            <ButtonV2 size="medium">Log In</ButtonV2>
          </a>
        </>
      );
    }

    return (
      <>
        <P className="description" variant="bodyLg">
          To accept the invite, please log in or create an account
        </P>
        <div className="accountActions">
          <ButtonV2 onClick={openLoginModal} size="medium" variant="outlined">
            Log in
          </ButtonV2>
          <ButtonV2 onClick={openAccountModal} size="medium">
            Sign up
          </ButtonV2>
        </div>
      </>
    );
  };

  const renderContents = () => {
    if (error) {
      return <P>{error}</P>;
    }
    if (!verified) {
      return <SpinnerV2 size="medium" />;
    }

    const headingCopy =
      invitee && roadmapName ? (
        nbspLastSpace(`${invitee} has invited you to ${roadmapName}`)
      ) : (
        <>You've been invited to view this&nbsp;roadmap</>
      );

    return (
      <div className="signup">
        <div className="innerCard">
          <div className="cardSection cardTop">
            <Mail className="cardIcon" size={32} />
            <H2 variant="headingXl" className="title">
              {headingCopy}
            </H2>
          </div>
          <div className="cardSection cardBottom">{renderCTA()}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="joinRoadmapForm">
      {renderContents()}
      <CannyAttribution source="feedback_subdomain" />
    </div>
  );
};

export default JoinRoadmapForm;

import { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import withContexts from 'common/util/withContexts';

class HiringContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      billingData: PropTypes.shape({
        plan: PropTypes.shape({
          features: PropTypes.shape({
            removeBranding: PropTypes.bool,
          }),
        }),
      }),
    }),
  };

  componentDidMount() {
    if (__DEV__) {
      return;
    }

    const { company } = this.props;
    if (company?.features?.removeBranding) {
      return;
    }

    console.log(`
     _______  _______  __    _  __    _  __   __
    |       ||   _   ||  |  | ||  |  | ||  | |  |
    |       ||  |_|  ||   |_| ||   |_| ||  |_|  |
    |       ||       ||       ||       ||       |
    |      _||       ||  _    ||  _    ||_     _|
    |     |_ |   _   || | |   || | |   |  |   |
    |_______||__| |__||_|  |__||_|  |__|  |___|
    `);

    console.log(
      'Fancy meeting you here. I take it you like to build amazing products, wanna help us build ours?'
    );
    console.log(
      "We don't have any open positions currently, but we are always open to meeting exceptional candidates. If you think that's you, send your resume to hello@canny.io!"
    );
  }

  render() {
    return cloneElementWithProps(this.props.children, { ...this.props });
  }
}

export default withContexts({ company: CompanyContext })(HiringContainer);

import React from 'react';

import { H2, H4, P } from 'common/ui/Text';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  children: React.ReactNode;
};

const AdminOnboardingHeader = ({ children }: Props) => {
  return <H2 className="header">{children}</H2>;
};

export const AdminOnboardingHeaderTwo = ({ children }: Props) => {
  return <H4 className="headerTwo">{children}</H4>;
};

export const AdminOnboardingSubHeader = ({ children }: Props) => {
  return (
    <P className="subheader" variant="bodyLg">
      {children}
    </P>
  );
};

export default AdminOnboardingHeader;

import React, { Component } from 'react';

import { loadRoadmap } from 'common/actions/sharedRoadmap';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { RoadmapsState } from 'common/reducers/sharedRoadmap';
import type { AsyncItem } from 'redux-connect';

const asyncFetch: AsyncItem = {
  promise: ({ store: { dispatch }, params }: PromiseOptions) => {
    if (!params.roadmapViewID || !params.roadmapURLName) {
      return;
    }

    return dispatch(loadRoadmap(params.roadmapViewID, params.roadmapURLName));
  },
};

type RoadmapsContextType = {
  roadmap?: Roadmap;
  roadmapError?: string;
};

export const RoadmapsContext = React.createContext<RoadmapsContextType>({
  roadmap: undefined,
  roadmapError: undefined,
});

type Props = {
  sharedRoadmap: RoadmapsState;
  params: { roadmapViewID: string };
};

class SharedRoadmapContainer extends Component<Props> {
  static asyncConnect = asyncFetch;

  render() {
    const { children, sharedRoadmap, params } = this.props;

    const loadedSuccessfully = sharedRoadmap && !sharedRoadmap.error && !sharedRoadmap.loading;

    const roadmaps = sharedRoadmap.roadmaps ?? {};

    const value = {
      roadmap: loadedSuccessfully ? roadmaps[params.roadmapViewID] : undefined,
      roadmapsError: sharedRoadmap.error,
    };
    const clonedElement = cloneElementWithProps(children, {
      ...this.props,
      ...value,
    });
    return <RoadmapsContext.Provider value={value}>{clonedElement}</RoadmapsContext.Provider>;
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  sharedRoadmap: state.sharedRoadmap,
}))(SharedRoadmapContainer);

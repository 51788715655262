import React, { useContext, useEffect } from 'react';

import { type InBoardFilter } from 'common/automations/constants';
import { BoardsContext } from 'common/containers/BoardsContainer';
import SingleSelect from 'common/ui/SingleSelect';

interface Props {
  value: InBoardFilter['value'];
  onChange: (condition?: InBoardFilter['condition'], value?: InBoardFilter['value']) => void;
  required: boolean;
}

const BoardURLName = ({ required, ...props }: Props) => {
  const boards = useContext(BoardsContext);

  const { value, onChange } = props;
  const selectedBoard = Array.isArray(boards)
    ? boards.find((board) => board.urlName === value)
    : null;
  const boardsLoaded = Array.isArray(boards) && boards.length > 0;

  // On init, select the first board
  useEffect(() => {
    if (!selectedBoard && boardsLoaded) {
      onChange('equals', boards[0].urlName);
    }
  }, [boardsLoaded, selectedBoard, boards, onChange]);

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!boardsLoaded) {
    return <div className="textCircle filterField1of1">No boards exist</div>;
  }

  return (
    <>
      <div className="textCircle filterField1of2">is</div>
      <SingleSelect
        className={required ? 'filterField2of2-required' : 'filterField2of2'}
        placeholder=""
        onChange={(option) => onChange('equals', option?.value)}
        options={boards.map((board) => ({ label: board.name, value: board.urlName }))}
        value={
          selectedBoard ? { label: selectedBoard.name, value: selectedBoard.urlName } : undefined
        }
      />
    </>
  );
};
export default BoardURLName;

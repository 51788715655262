import React, { useContext, useState } from 'react';

import {
  ClipboardPaste,
  Download,
  LayoutGrid,
  type LucideProps,
  Map,
  Merge,
  Tag,
  Trash,
  User,
  X,
} from 'lucide-react';

import Tooltip from 'common/common/Tooltip';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { RoadmapsContext } from 'common/containers/RoadmapsContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ModalPortal from 'common/modals/ModalPortal';
import UpsellModal from 'common/modals/UpsellModal';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { Span } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';

import {
  AddTagsToPostsModal,
  AddToRoadmapModal,
  ChangePostsCategoryModal,
  ChangePostsOwnerModal,
  type CommonModalProps,
  DeletePostsConfirmationModal,
  ExportPostsConfirmationModal,
  MergePostsModal,
  MovePostsModal,
} from './AdminRoadmapBulkActionModals';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { Viewer } from 'common/api/endpoints/viewer';
import type { Post } from 'common/api/resources/posts';
import type { Feature } from 'common/constants/billing';
import type { BoardStateItem } from 'common/reducers/boards';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapBulkActionBar.scss';

type OperationType =
  | 'merge'
  | 'move'
  | 'addToRoadmap'
  | 'updateOwner'
  | 'addTag'
  | 'changeCategory'
  | 'delete'
  | 'export';

type OperationWithUpsell = {
  hasUpsell: true;
  upsellFeature: Feature;
  upsellCTA: string;
};

type OperationWithoutUpsell = {
  hasUpsell: false;
};

type OperationUpsellDetails = OperationWithUpsell | OperationWithoutUpsell;

type StaticOperation = {
  ariaLabel: string;
  color?: 'error';
  icon: React.ComponentType<LucideProps>;
  modal: React.ComponentType<CommonModalProps>;
  permissionKey?: RolePermissionName;
  singleBoardOnly?: boolean;
  type: OperationType;
} & OperationUpsellDetails;

type Operation = StaticOperation & {
  tooltip: string;
  tooltipDelay: number;
  disabled?: boolean;
  viewerHasPermission: boolean;
};

const STATIC_OPERATIONS: StaticOperation[] = [
  {
    ariaLabel: 'Merge posts',
    hasUpsell: false,
    icon: Merge,
    modal: MergePostsModal,
    permissionKey: 'mergePosts',
    type: 'merge',
  },
  {
    ariaLabel: 'Add to roadmap',
    hasUpsell: true,
    icon: Map,
    modal: AddToRoadmapModal,
    permissionKey: 'manageRoadmap',
    type: 'addToRoadmap',
    upsellFeature: 'prioritizationRoadmap',
    upsellCTA: 'Create roadmaps',
  },
  {
    ariaLabel: 'Move to board',
    hasUpsell: false,
    icon: ClipboardPaste,
    modal: MovePostsModal,
    type: 'move',
  },
  {
    ariaLabel: 'Change owner',
    hasUpsell: true,
    icon: User,
    modal: ChangePostsOwnerModal,
    permissionKey: 'changePostOwner',
    type: 'updateOwner',
    upsellFeature: 'postOwners',
    upsellCTA: 'Assign post owners',
  },
  {
    ariaLabel: 'Change category',
    hasUpsell: true,
    icon: LayoutGrid,
    modal: ChangePostsCategoryModal,
    singleBoardOnly: true,
    type: 'changeCategory',
    upsellFeature: 'categories',
    upsellCTA: 'Change post categories',
  },
  {
    ariaLabel: 'Add tags',
    hasUpsell: false,
    icon: Tag,
    modal: AddTagsToPostsModal,
    permissionKey: 'changePostTags',
    singleBoardOnly: true,
    type: 'addTag',
  },
  {
    ariaLabel: 'Export posts',
    hasUpsell: false,
    icon: Download,
    modal: ExportPostsConfirmationModal,
    permissionKey: 'exportData',
    type: 'export',
  },
  {
    ariaLabel: 'Delete posts',
    color: 'error',
    hasUpsell: false,
    icon: Trash,
    modal: DeletePostsConfirmationModal,
    permissionKey: 'deletePosts',
    type: 'delete',
  },
];

type Props = {
  currentRoadmap: Roadmap;
  selectedPost: Post;
  selectedPosts: Post[];
  onClose: () => void;
};

const countDistinctBoards = (selectedPosts: Post[]) => {
  const boardIDs = selectedPosts.map((post) => post.boardID);
  const uniqueBoardIDs = new Set(boardIDs);
  return uniqueBoardIDs.size;
};

const initializeOperation = ({
  operation,
  boards,
  company,
  roadmaps,
  selectedPosts,
  viewer,
}: {
  operation: StaticOperation;
  boards: BoardStateItem[];
  company: Company;
  roadmaps: Roadmap[] | undefined;
  selectedPosts: Post[];
  viewer: Viewer;
}): Operation => {
  const distinctBoardCount = countDistinctBoards(selectedPosts);

  const viewerHasPermission = operation.permissionKey
    ? hasPermission(operation.permissionKey, company, viewer)
    : true;

  if (!viewerHasPermission) {
    return {
      ...operation,
      viewerHasPermission,
      disabled: true,
      tooltip: 'You do not have permission to perform this action.',
      tooltipDelay: 0,
    };
  }

  // after deleting posts, they are removed from roadmapPosts meaning selectedPosts is temporarily []
  if (!selectedPosts.length) {
    return {
      ...operation,
      viewerHasPermission,
      disabled: false,
      tooltip: operation.ariaLabel,
      tooltipDelay: 1000,
    };
  }

  if (operation.singleBoardOnly && distinctBoardCount > 1) {
    return {
      ...operation,
      viewerHasPermission,
      disabled: true,
      tooltip: 'You can only perform this action with posts from one board selected.',
      tooltipDelay: 0,
    };
  }

  const selectedBoard = boards.find((board) => board._id === selectedPosts[0].boardID);

  if (operation.type === 'changeCategory') {
    if (!selectedBoard || selectedBoard.categories.length === 0) {
      return {
        ...operation,
        viewerHasPermission,
        disabled: true,
        tooltip: `You need to add categories to perform this action.`,
        tooltipDelay: 0,
      };
    }
  }

  if (operation.type === 'addTag') {
    if (!selectedBoard || selectedBoard.tags.length === 0) {
      return {
        ...operation,
        viewerHasPermission,
        disabled: true,
        tooltip: `You need to add tags to perform this action.`,
        tooltipDelay: 0,
      };
    }
  }

  if (operation.type === 'merge' && selectedPosts.length < 2) {
    return {
      ...operation,
      viewerHasPermission,
      disabled: true,
      tooltip: 'You must select at least two posts to merge.',
      tooltipDelay: 0,
    };
  }

  if (operation.type === 'addToRoadmap' && !roadmaps?.length) {
    return {
      ...operation,
      viewerHasPermission,
      disabled: true,
      tooltip: 'You need at least two roadmaps to perform this action.',
      tooltipDelay: 0,
    };
  }

  if (operation.type === 'move' && company.boardCount === 1) {
    return {
      ...operation,
      viewerHasPermission,
      disabled: true,
      tooltip: 'You need at least two boards to move posts.',
      tooltipDelay: 0,
    };
  }

  return {
    ...operation,
    viewerHasPermission,
    disabled: false,
    tooltip: operation.ariaLabel,
    tooltipDelay: 1000,
  };
};

const AdminRoadmapBulkActionBar = ({
  currentRoadmap,
  selectedPost,
  selectedPosts,
  onClose,
}: Props) => {
  const viewer = useContext<Viewer>(ViewerContext);
  const company = useContext<Company>(CompanyContext);
  const { roadmaps } = useContext(RoadmapsContext);
  const boards = useContext(BoardsContext);

  const [upsell, setUpsell] = useState<{ feature: Feature; cta: string } | null>(null);
  const [currentOperation, setCurrentOperation] = useState<Operation | null>(null);

  const isPlural = selectedPosts.length > 1;
  const copy = isPlural ? 'posts' : 'post';

  if ('loading' in boards) {
    return null;
  }

  const operations: Operation[] = STATIC_OPERATIONS.map((operation) => {
    return initializeOperation({ operation, boards, company, viewer, roadmaps, selectedPosts });
  });

  const onOperationSelect = (operation: Operation) => () => {
    if (operation.disabled) {
      return;
    }

    if (!operation.viewerHasPermission) {
      return;
    }

    if (operation.hasUpsell && !company.features[operation.upsellFeature]) {
      setUpsell({
        feature: operation.upsellFeature,
        cta: operation.upsellCTA,
      });
      return;
    }

    setCurrentOperation(operation);
  };

  const onOperationCompleted = (operation: Operation) => {
    switch (operation.type) {
      // these operations are destructive and so should close the BulkActionBar
      case 'merge':
      case 'delete':
        onClose();
        break;
      default:
        return;
    }
  };

  const OperationModal = currentOperation?.modal;

  return (
    <ModalPortal allowBodyScroll={true} className="adminRoadmapBulkActionBarPortal">
      <div className="adminRoadmapBulkActionBar">
        <Span className="postSelectedCount" variant="bodyMd">
          {selectedPosts.length} {copy} selected
        </Span>
        <div className="actionButtonsContainer">
          {operations.map((operation, idx) => (
            <Tooltip
              key={idx}
              delay={operation.tooltipDelay}
              position="top"
              value={operation.tooltip}>
              <IconButtonV2
                disabled={!operation.viewerHasPermission || operation.disabled}
                aria-label={operation.ariaLabel}
                icon={operation.icon}
                size="medium"
                onClick={onOperationSelect(operation)}
                color={operation.color}
              />
            </Tooltip>
          ))}
        </div>
        <Tooltip delay={1000} position="top" value="Close action bar">
          <IconButtonV2
            aria-label="close"
            icon={X}
            size="medium"
            variant="plain"
            onClick={onClose}
          />
        </Tooltip>
        {currentOperation && OperationModal && (
          <OperationModal
            currentRoadmap={currentRoadmap}
            onClose={(completed) => {
              if (completed === true) {
                onOperationCompleted(currentOperation);
              }
              setCurrentOperation(null);
            }}
            selectedPost={selectedPost}
            selectedPosts={selectedPosts}
          />
        )}
        {upsell && (
          <UpsellModal
            cta={upsell.cta}
            feature={upsell.feature}
            onClose={() => setUpsell(null)}
            onUpsell={() => setUpsell(null)}
            show
          />
        )}
      </div>
    </ModalPortal>
  );
};

export default AdminRoadmapBulkActionBar;

import React, { useEffect } from 'react';

import classnames from 'classnames';

import ModalPortal from 'common/modals/ModalPortal';
import ButtonV2 from 'common/ui/ButtonV2';
import { H1 } from 'common/ui/Text';

import 'css/components/modals/_ModernConfirmModal.scss';

type Props = {
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  confirmText: string;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type?: 'primary' | 'destructive';
};

const ModernConfirmModal = ({
  children,
  className,
  confirmText,
  header,
  loading,
  onClose,
  onConfirm,
  type = 'primary',
}: Props) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        onConfirm();
      } else if (event.code === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, onConfirm]);

  return (
    <ModalPortal
      className={classnames('modernConfirmModal', className)}
      onClose={onClose}
      closeOnClickAway
      allowEscape>
      <header className="header">
        <H1 variant="headingSm">{header}</H1>
      </header>
      <div className="body">{children}</div>
      <footer className="footer">
        <ButtonV2 size="medium" onClick={onClose} color="primary" variant="outlined">
          Cancel
        </ButtonV2>
        <ButtonV2
          size="medium"
          color={type === 'destructive' ? 'error' : 'primary'}
          loading={loading}
          onClick={onConfirm}
          variant="contained">
          {confirmText}
        </ButtonV2>
      </footer>
    </ModalPortal>
  );
};

export default ModernConfirmModal;

import { loadFreshdeskSettings } from 'common/actions/freshdeskSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { FreshdeskSettings } from 'common/api/endpoints/freshdesk';
import type { State } from 'redux-connect';

type Props = {
  freshdeskSettings: FreshdeskSettings;
  children: React.ReactNode;
};

const FreshdeskSettingsContainer = (props: Props) => {
  // props
  const { children, freshdeskSettings } = props;

  return cloneElementWithProps(children, {
    ...props,
    freshdeskSettings,
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadFreshdeskSettings()),
    },
  ],
  (state: State) => ({ freshdeskSettings: state.freshdeskSettings?.data })
)(FreshdeskSettingsContainer);

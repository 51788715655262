import EffortTypes from './EffortTypes';
import ImpactFactorTypes from './ImpactFactorTypes';

import type { RoadmapPost } from 'common/api/endpoints/roadmapPosts';
import type { Factor } from 'common/api/endpoints/roadmaps';

/**
 * Read {@link https://help.canny.io/en/articles/5046937-how-do-prioritization-factors-and-scoring-work| the docs} to understand how the formula works.
 */

export type StrippedRoadmapPost = Pick<RoadmapPost, '_id' | 'factorValues'>;
export type StrippedRoadmapFactor = Pick<Factor, '_id' | 'weight' | 'effort' | 'type'>;

const calculateScores = (
  roadmapPosts: StrippedRoadmapPost[],
  factors: StrippedRoadmapFactor[] = []
) => {
  const scoreMap: Record<string, number> = {};

  const impactFactors = factors.filter(({ effort }) => !effort);
  const effortFactors = factors.filter(({ effort }) => effort);

  const effortWeightSum = effortFactors.reduce((effortWeightSum, factor) => {
    return effortWeightSum + factor.weight;
  }, 0);

  const impactWeightSum = impactFactors.reduce((impactWeightSum, factor) => {
    if (factor.type === 'percentage') {
      return impactWeightSum;
    }
    return impactWeightSum + factor.weight;
  }, 0);

  const impactFactorsIDsSet = new Set(impactFactors.map(({ _id }) => _id));

  // create a map with the max value for each factor across roadmap posts.
  const maxFactorValueMap = roadmapPosts.reduce<Record<string, number>>(
    (maxCalculationFactorValueMap, roadmapPost) => {
      const factorValues = Object.entries(roadmapPost.factorValues).filter(([id]) =>
        impactFactorsIDsSet.has(id)
      );

      const maxValues = Object.fromEntries(
        factorValues.map(([factorID, factorValue]) => {
          if (typeof factorValue !== 'number') {
            return [factorID, 0];
          }
          if (!(factorID in maxCalculationFactorValueMap)) {
            return [factorID, factorValue];
          }

          return [factorID, Math.max(factorValue, maxCalculationFactorValueMap[factorID])];
        })
      );

      return {
        ...maxCalculationFactorValueMap,
        ...maxValues,
      };
    },
    {}
  );

  roadmapPosts.forEach((roadmapPost) => {
    // score is 0 if there are no efforts/impacts
    if (impactWeightSum === 0) {
      scoreMap[roadmapPost._id] = 0;
      return;
    }

    const percentageMultiplier = impactFactors.reduce((percentageMultiplier, factor) => {
      const factorValue = roadmapPost.factorValues[factor._id];
      if (factor.type !== 'percentage' || typeof factorValue !== 'number') {
        return percentageMultiplier;
      }
      return percentageMultiplier * (factorValue / 100);
    }, 1);

    const totalImpact =
      impactFactors.reduce((totalImpact, factor) => {
        const factorID = factor._id;
        const factorValue = roadmapPost.factorValues[factorID];
        if (factor.type === 'percentage' || factorValue === null) {
          return totalImpact;
        }

        // normalize calculation factors to [0-100]
        if (factor.type === ImpactFactorTypes.calculation.name) {
          const maxValue = maxFactorValueMap[factorID];
          if (typeof factorValue !== 'number') {
            return totalImpact;
          }

          const impact = factorValue / maxValue;

          if (Number.isNaN(impact)) {
            return totalImpact;
          }

          return totalImpact + factor.weight * (impact * 100);
        }
        const factorType = ImpactFactorTypes[factor.type];

        if (typeof factorValue === 'boolean') {
          return totalImpact + factor.weight * factorType.normalize(factorValue ? 1 : 0);
        }

        return totalImpact + factor.weight * factorType.normalize(factorValue);
      }, 0) / impactWeightSum;

    // if an effort factor's value hasn't been set, don't include it in the calculation
    let applicableEffortWeight = 0;
    const totalEffort = effortFactors.reduce<number>((totalEffort, factor) => {
      if (
        factor.type === 'calculation' ||
        factor.type === 'checkbox' ||
        factor.type === 'percentage'
      ) {
        return totalEffort;
      }
      const factorType = EffortTypes[factor.type];
      const effortValue = roadmapPost.factorValues[factor._id];
      // if the post has a value for this factor, add the factor's weight
      if (effortValue) {
        applicableEffortWeight += factor.weight;
        return totalEffort + factor.weight * factorType.normalize(effortValue);
      } else {
        // otherwise ignore that effort value + weight
        return totalEffort;
      }
    }, 0);

    if ((totalEffort === 0 || applicableEffortWeight === 0) && effortWeightSum !== 0) {
      scoreMap[roadmapPost._id] = 0;
      return;
    }

    const modifiedEffort = !effortWeightSum ? 1 : totalEffort / applicableEffortWeight;

    // 1000 * [0-1] * [0-100] / [1-100] = [0-100k]
    scoreMap[roadmapPost._id] = (1000 * percentageMultiplier * totalImpact) / modifiedEffort;
  });
  return scoreMap;
};

export default calculateScores;

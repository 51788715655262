import {
  Invalidate,
  SuggestionsError,
  SuggestionsLoaded,
  getEntryKey,
} from '../actions/postSuggestions';

export default function postSuggestions(state = {}, action) {
  switch (action.type) {
    case SuggestionsLoaded: {
      const entryKey = getEntryKey(action.entry);
      return Object.assign({}, state, {
        [entryKey]: Object.assign({}, state[entryKey], {
          [`${action.search}/${action.statusTypes?.join(',')}`]: {
            error: null,
            lastUpdated: action.timestamp,
            loading: false,
            items: action.suggestions,
          },
        }),
      });
    }

    case SuggestionsError: {
      const entryKey = getEntryKey(action.entry);
      return Object.assign({}, state, {
        [entryKey]: Object.assign({}, state[entryKey], {
          [`${action.search}/${action.statusTypes?.join(',')}`]: {
            error: action.error,
            lastUpdated: action.timestamp,
            loading: false,
            items: null,
          },
        }),
      });
    }

    case Invalidate: {
      return {};
    }

    default:
      return state;
  }
}

import React, { createContext, useContext, useState } from 'react';

import classnames from 'classnames';
import { ArrowRight } from 'lucide-react';

import AJAX from 'common/AJAX';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import Helmet from 'common/helmets/Helmet';
import Link from 'common/Link';
import AdminAutopilotGettingStartedHeading from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotGettingStartedHeading';
import AdminAutopilotGettingStartedIntegrations from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotGettingStartedIntegrations';
import ButtonV2 from 'common/ui/ButtonV2';
import { H1, H2, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotGettingStarted.module.scss';

import type { Company } from 'common/api/endpoints/companies';

enum GettingStartedTrackingEvents {
  learnMore = 'autopilot_learn_more',
  addSource = 'autopilot_add_source',
  getHelp = 'autopilot_get_help',
}

export const GettingStartedContext = createContext<{
  loading: boolean;
  erroredIntegrationName?: string;
  setErroredIntegrationName: (error?: string) => void;
  setLoading: (loading: boolean) => void;
} | null>(null);

export const useGettingStartedContext = () => {
  const context = React.useContext(GettingStartedContext);
  if (!context) {
    throw new Error('useGettingStartedContext should be used inside AdminAutopilotGettingStarted');
  }
  return context;
};

const AdminAutopilotGettingStarted = () => {
  // context
  const company = useContext<Company>(CompanyContext);
  const showIntercom = useContext(ShowIntercomContext);

  // state
  const [erroredIntegrationName, setErroredIntegrationName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const enabledSyncIntegrations: SyncIntegrationNames[] = company.installedSyncIntegrations
    .filter(
      ({ disabled, integrationName }) => !disabled && integrationName !== SyncIntegrationNames.api
    )
    .map(({ integrationName }: { integrationName: SyncIntegrationNames }) => integrationName);

  const hasEnabledASource = enabledSyncIntegrations.length > 0;

  // helpers
  const logEvent = (key: string) => {
    AJAX.post('/api/analytics/log', { key, data: {} });
  };

  const openIntercom = () => {
    logEvent(GettingStartedTrackingEvents.getHelp);
    showIntercom();
  };

  // render
  return (
    <main className={styles.container}>
      <div className={styles.innerWrapper}>
        <Helmet title="Get Started with Autopilot | Canny" />
        <GettingStartedContext.Provider
          value={{ loading, erroredIntegrationName, setErroredIntegrationName, setLoading }}>
          <H1 className={styles.heading}>Get Started with Autopilot 🎉</H1>
          <P variant="bodyLg">
            Quickly set up Canny by connecting as many data sources as you want to automatically
            gather feedback from your favorite tools.
          </P>
          <P variant="bodyLg" className={styles.learnMore}>
            <a
              href="https://help.canny.io/en/articles/8202451-autopilot"
              onClick={() => logEvent(GettingStartedTrackingEvents.learnMore)}
              target="_blank"
              rel="noopener">
              Learn more
            </a>
          </P>
          <H2
            variant="headingLg"
            className={classnames([
              styles.subHeading,
              { [styles.error]: erroredIntegrationName, [styles.active]: hasEnabledASource },
            ])}>
            <AdminAutopilotGettingStartedHeading
              enabledSyncIntegrations={enabledSyncIntegrations}
            />
          </H2>
          <AdminAutopilotGettingStartedIntegrations
            enabledSyncIntegrations={enabledSyncIntegrations}
          />
          <P fontWeight="medium" className={styles.helpTitle}>
            Don't see an integration?
          </P>
          <P fontWeight="medium" className={styles.helpLinkCopy}>
            <a
              href="https://feedback.canny.io/sources"
              onClick={() => logEvent(GettingStartedTrackingEvents.addSource)}
              target="_blank"
              rel="noopener">
              Tell us what you need, and we'll work on adding it!
              <ArrowRight size={16} strokeWidth={2} className={styles.helpArrow} />
            </a>
          </P>
          <P fontWeight="medium" className={styles.helpTitle}>
            Need help with getting set up?
          </P>
          <button onClick={openIntercom} className={styles.helpButton}>
            <P fontWeight="medium" className={styles.helpLinkCopy}>
              Talk to our onboarding specialist
              <ArrowRight size={16} strokeWidth={2} className={styles.helpArrow} />
            </P>
          </button>
          <Link
            to="/admin/autopilot"
            disabled={!hasEnabledASource || loading}
            className={styles.continueButton}>
            <ButtonV2
              endIcon={ArrowRight}
              size="large"
              disabled={!hasEnabledASource}
              loading={loading}>
              Continue
            </ButtonV2>
          </Link>
        </GettingStartedContext.Provider>
      </div>
    </main>
  );
};

export default AdminAutopilotGettingStarted;

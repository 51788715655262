import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadMoreNotifications, reloadNotifications } from 'common/actions/notifications';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import NotificationList from 'common/notifications/NotificationList';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_SubdomainNotifications.scss';

class SubdomainNotifications extends Component {
  static propTypes = {
    company: PropTypes.object,
    notifications: PropTypes.shape({
      hasNextPage: PropTypes.bool,
      items: PropTypes.array,
    }),
    tintColor: PropTypes.string,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  componentDidMount() {
    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return;
    }

    AJAX.post('/api/notifications/markAllSeen', {}, () => {});
  }

  onLoadMoreTapped = () => {
    this.props.loadMoreNotifications();
  };

  markAllAsRead = () => {
    AJAX.post('/api/notifications/markAllRead', {}, this.onMarkReadResponse);
  };

  onMarkReadResponse = (response) => {
    if (response === 'success') {
      this.props.reloadNotifications();
    }
  };

  renderMarkAllLink() {
    return (
      <Tappable onTap={this.markAllAsRead}>
        <div className="markAll">Mark all as read</div>
      </Tappable>
    );
  }

  renderContents() {
    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return <div className="loggedOut">You are not logged in.</div>;
    }

    return (
      <>
        <div className="notificationHeader">
          <div className="heading">Notifications</div>
          {this.renderMarkAllLink()}
        </div>
        <NotificationList
          notifications={this.props.notifications}
          onLoadMoreTapped={this.onLoadMoreTapped}
          tintColor={this.props.tintColor}
        />
      </>
    );
  }

  render() {
    return (
      <div className="subdomainNotifications">
        <ContentContainer>
          <Helmet title={`Notifications | ${this.props.company.name}`} />
          {this.renderContents()}
        </ContentContainer>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadMoreNotifications: () => {
      return dispatch(loadMoreNotifications());
    },
    reloadNotifications: () => {
      return dispatch(reloadNotifications());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(SubdomainNotifications);

import React, { useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadFreshdeskSettings } from 'common/actions/freshdeskSettings';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/settings/_AdminFreshdeskSettings.module.scss';

import AdminFreshdeskInstallFlow from './AdminFreshdeskInstallFlow';

import type { Company } from 'common/api/endpoints/companies';
import type { FreshdeskSettings } from 'common/api/endpoints/freshdesk';

type OwnProps = {
  freshdeskSettings: FreshdeskSettings;
};

type ConnectProps = {
  reloadFreshdeskSettings: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const AdminFreshdeskSettings = (props: Props) => {
  // props
  const { freshdeskSettings, reloadFreshdeskSettings } = props;

  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [apiKey, setAPIKey] = useState<string>('');
  const [domain, setDomain] = useState<string>('');

  // helpers
  const sendRequest = async (url: string, params: Record<string, unknown> = {}) => {
    setLoading(true);

    const response = await AJAX.post(url, params);
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'invalid domain': 'Please enter a valid Freshdesk domain',
        'could not connect to freshdesk':
          'Failed to connect to Freshdesk. Make sure your domain and API Keys are correct.',
      },
    });

    if (error) {
      setLoading(false);
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadFreshdeskSettings();
    setLoading(false);
  };

  const install = async () => sendRequest('/api/freshdesk/install', { apiKey, domain });
  const uninstall = async () => sendRequest('/api/freshdesk/uninstall', {});

  return (
    <main>
      <Helmet title="Freshdesk Integration | Canny" />
      <AdminSettingsHeader
        title="Freshdesk Integration"
        subheading="Automatically detect and gather feedback from Freshdesk tickets."
        learnMoreLink="https://help.canny.io/en/articles/9930902-freshdesk-integration"
      />
      <div className={styles.content}>
        <AdminFreshdeskInstallFlow
          shouldUpsell={!company?.integrations?.freshdesk}
          installation={freshdeskSettings?.installation}
          loading={loading}
          onInstall={install}
          onUninstall={uninstall}
          setAPIKey={setAPIKey}
          setDomain={setDomain}
          apiKey={apiKey}
          domain={domain}
        />
      </div>
    </main>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadFreshdeskSettings: () => dispatch(reloadFreshdeskSettings()),
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.integrations.freshdesk),
    '/admin/settings'
  )
)(AdminFreshdeskSettings) as unknown as React.FC<OwnProps>;

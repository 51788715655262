import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ControlledDropdown from 'common/ControlledDropdown';
import DateInput from 'common/inputs/DateInput';
import TextInput from 'common/inputs/TextInput';
import Tappable from 'common/Tappable';
import buildAttribute, { StandardFields, TypeOpValidation } from 'common/util/buildAttribute';
import { OpSelectorCreatorMap } from 'common/util/getMongoFilter';

import 'css/components/segments/_AttributeEditor.scss';

export const AttributeType = PropTypes.shape({
  customFieldID: PropTypes.string,
  op: PropTypes.oneOf(Object.keys(OpSelectorCreatorMap)),
  standardFieldID: PropTypes.oneOf(Object.keys(StandardFields)),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number, PropTypes.string]),
});

export const FieldType = PropTypes.shape({
  fieldType: PropTypes.oneOf(Object.keys(TypeOpValidation)),
  name: PropTypes.string,
  objectType: PropTypes.oneOf(['company', 'user']),
  prettyName: PropTypes.string,
});

export default class AttributeEditor extends Component {
  static propTypes = {
    attribute: AttributeType,
    customFields: PropTypes.arrayOf(FieldType).isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
  };

  static defaultProps = {
    attribute: {},
  };

  state = {
    customFieldMap: this.props.customFields.reduce(
      (prev, curr) => ({
        ...prev,
        [curr._id]: curr,
      }),
      {}
    ),
  };

  findStandardField(fieldID) {
    return StandardFields[fieldID];
  }

  findCustomField(fieldID) {
    const { customFields } = this.props;
    return customFields.find(({ _id }) => _id === fieldID) || {};
  }

  getError() {
    const { customFieldMap } = this.state;
    const { attribute } = this.props;
    if (!attribute.value) {
      return;
    }

    const field = this.getField();
    if (!field) {
      return;
    }

    try {
      buildAttribute(attribute, customFieldMap);
    } catch (e) {
      const { fieldType } = field;
      const typeErrorMap = {
        date: 'Value should be date',
        number: 'Value should be number',
        string: 'Value should be string',
      };
      return typeErrorMap[fieldType];
    }
  }

  getField() {
    const { attribute } = this.props;
    const { customFieldID, standardFieldID } = attribute;

    if (standardFieldID) {
      return {
        _id: standardFieldID,
        ...StandardFields[standardFieldID],
      };
    }

    const { customFields } = this.props;
    const customField = customFields.find(({ _id }) => _id === customFieldID);

    if (customField) {
      return {
        _id: customFieldID,
        ...customField,
      };
    }
  }

  getFields() {
    const { customFields } = this.props;
    return [
      ...Object.keys(StandardFields).map((standardFieldID) => ({
        _id: standardFieldID,
        ...StandardFields[standardFieldID],
      })),
      ...customFields,
    ];
  }

  getFieldOps() {
    const { fieldType } = this.getField();
    return Object.keys(TypeOpValidation[fieldType]).map((op) => ({
      ...TypeOpValidation[fieldType][op],
      id: op,
    }));
  }

  getOp() {
    const { attribute } = this.props;
    const { op } = attribute;
    const { fieldType } = this.getField();
    return TypeOpValidation[fieldType][op];
  }

  onChange = (attribute) => {
    this.props.onChange(attribute);
  };

  onChangeValue = (value) => {
    const nextAttribute = { ...this.props.attribute };
    if (value === '') {
      delete nextAttribute.value;
    } else {
      const { inputType } = this.getOp();
      if (inputType === 'date') {
        nextAttribute.value = value.toString();
      } else if (inputType === 'number') {
        nextAttribute.value = Number(value);
      } else {
        nextAttribute.value = value;
      }
    }

    this.props.onChange(nextAttribute);
  };

  onFieldSelect = (fieldID) => {
    const attribute = {};
    const standardField = this.findStandardField(fieldID);
    const customField = this.findCustomField(fieldID);

    let fieldType;
    if (standardField) {
      attribute.standardFieldID = fieldID;
      fieldType = standardField.fieldType;
    } else if (customField) {
      attribute.customFieldID = fieldID;
      fieldType = customField.fieldType;
    }

    attribute.op = Object.keys(TypeOpValidation[fieldType])[0];
    this.onChange(attribute);
  };

  doOpsHaveDifferentValidators = (op1, op2) => {
    const { fieldType } = this.getField();
    const validator1 = TypeOpValidation[fieldType][op1].validator;
    const validator2 = TypeOpValidation[fieldType][op2].validator;
    return validator1 !== validator2;
  };

  onOpSelect = (op) => {
    const { attribute } = this.props;
    const newAttribute = {
      ...attribute,
      op,
    };

    if (this.doOpsHaveDifferentValidators(op, attribute.op)) {
      delete newAttribute.value;
    }

    this.props.onChange(newAttribute);
  };

  onRemove = () => {
    this.props.onRemove();
  };

  renderFieldSelect() {
    const field = this.getField();
    const fields = this.getFields();
    return (
      <div className="fieldSelect">
        <ControlledDropdown
          onChange={this.onFieldSelect}
          placeholder={'Select attribute'}
          options={fields.map(({ _id, prettyName }) => ({
            name: _id,
            render: prettyName,
          }))}
          selectedName={field && field._id}
        />
      </div>
    );
  }

  renderOpSelect() {
    const { attribute } = this.props;
    const field = this.getField();

    if (!field) {
      return;
    }

    const fieldOps = this.getFieldOps();
    return (
      <div className="opSelect">
        <ControlledDropdown
          onChange={this.onOpSelect}
          options={fieldOps.map(({ id, label }) => ({
            name: id,
            render: label,
          }))}
          selectedName={attribute.op}
        />
      </div>
    );
  }

  renderRemoveButton() {
    const field = this.getField();

    if (!field) {
      return;
    }

    return (
      <Tappable onTap={this.onRemove}>
        <div className="removeButton icon icon-x" />
      </Tappable>
    );
  }

  renderValueInput() {
    const { attribute } = this.props;
    const error = this.getError();
    const field = this.getField();

    if (!field) {
      return;
    }

    const op = this.getOp();
    if (!op || !op.inputType) {
      return;
    }

    const { value } = attribute;
    const { inputType } = op;
    let inputValue;
    if (value !== undefined) {
      if (inputType === 'date') {
        inputValue = new Date(value);
      } else if (inputType === 'number') {
        inputValue = value.toString();
      } else {
        inputValue = value;
      }
    }

    return (
      <div className="valueInput">
        {op.inputType === 'date' ? (
          <DateInput onChange={this.onChangeValue} placeholder="enter date..." value={inputValue} />
        ) : (
          <TextInput
            className={error && 'error'}
            onChange={(e) => this.onChangeValue(e.target.value)}
            placeholder="enter value..."
            type={op.inputType}
            value={inputValue}
          />
        )}

        {error && <div className="error">{error}</div>}
      </div>
    );
  }

  render() {
    return (
      <div className="attributeEditor">
        <div className="attribute">
          {this.renderFieldSelect()}
          {this.renderOpSelect()}
          {this.renderValueInput()}
        </div>
        {this.renderRemoveButton()}
      </div>
    );
  }
}

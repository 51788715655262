import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { ZoomInstallation } from 'common/api/endpoints/zoom';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: zoomSettingsRequested,
  loadedAction: zoomSettingsLoaded,
  errorAction: zoomSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<ZoomInstallation>('zoomSettings');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchZoomSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          zoomSettings: {
            query: Data.queries.zoomSettings,
          },
        },
        cookies
      );
      return dispatch(zoomSettingsLoaded(response.zoomSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(zoomSettingsError(message));
    }
  };
}

export function loadZoomSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(zoomSettingsRequested());
    return dispatch(fetchZoomSettings());
  };
}

export function reloadZoomSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchZoomSettings());
  };
}

function shouldFetch(state: State) {
  return !state.zoomSettings.data;
}

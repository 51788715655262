import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import AccessModal from 'common/modals/AccessModal';
import withAccessControl from 'common/routing/withAccessControl';
import hasPermission from 'common/util/hasPermission';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminUserEmailSettingsTypes.scss';

const TwoSeconds = 2000;

class AdminUserEmailSettingsTypes extends Component {
  static propTypes = {
    company: PropTypes.shape({
      sendUserReportEmails: PropTypes.bool,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    saved: false,
    saving: false,
  };

  componentDidMount() {
    const { company, openModal, router, viewer } = this.props;
    if (hasPermission('manageEmailSettings', company, viewer)) {
      return;
    }

    router.replace('/admin');
    openModal(
      AccessModal,
      {
        requiredPermissions: ['manageEmailSettings'],
      },
      {
        allowRouteChange: true,
      }
    );
  }

  onToggle = (sendUserReportEmails) => {
    const { saving } = this.state;
    if (saving) {
      return;
    }

    this.setState({
      error: null,
      saved: false,
      saving: true,
    });

    AJAX.post(
      '/api/company/changeSettings',
      {
        sendUserReportEmails,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            error: 'Something went wrong, please try again later.',
            saved: false,
            saving: false,
          });
          return;
        }

        const { reloadCompany } = this.props;
        reloadCompany().then(() => {
          this.setState({
            saved: true,
            saving: false,
          });
          setTimeout(() => {
            this.setState({
              saved: false,
            });
          }, TwoSeconds);
        });
      }
    );
  };

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderStatus() {
    const { saving, saved } = this.state;
    if (!saving && !saved) {
      return null;
    }

    return <div className="status">{saved ? 'Saved!' : 'Saving...'}</div>;
  }

  render() {
    const {
      company: { sendUserReportEmails },
    } = this.props;
    return (
      <div className="adminUserEmailSettingsTypes">
        <Helmet title="Email Types | User Email Settings | Canny" />
        <div className="content">
          <div className="emailType">New posts (weekly)</div>
          <div className="description">
            An email with the new posts from the previous week sent to all tracked users. They give
            new posts exposure and a chance to&nbsp;trend.
          </div>
          {this.renderError()}
          <div className="toggleContainer">
            <Toggle onToggle={this.onToggle} value={sendUserReportEmails} />
            <div className="value">Send new posts email</div>
          </div>
          {this.renderStatus()}
          <div className="tip">
            <div className="important">!</div>
            <div className="text">
              Take a look at the full list of the emails we send&nbsp;
              <a
                href="https://help.canny.io/en/collections/325110-canny-emails"
                rel="noopener"
                target="_blank">
                here
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.notifications.types),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminUserEmailSettingsTypes);

import MarkdownStages from './MarkdownStages';
import processStage from './processStage';

function plaintextItems(items, { preserveLineBreaks = false } = {}) {
  return items
    .map((item) => {
      if (item.componentName === 'img') {
        return null;
      }

      if (typeof item === 'string') {
        const lines = item.split(/[\n\r]/g);
        return lines.join(' ');
      }

      return plaintextItems(item.items);
    })
    .filter((item) => {
      if (!item || !item.trim()) {
        return false;
      }
      return true;
    })
    .map((item) => {
      return item.trim();
    })
    .join(preserveLineBreaks ? '\n' : ' ');
}

export default function plaintext(
  contents,
  { stagesToOmit = [], preserveLineBreaks = false } = {}
) {
  const items = MarkdownStages.reduce(
    (items, stage) => {
      if (stagesToOmit.includes(stage.name)) {
        return items;
      }

      return processStage(items, stage);
    },
    [contents]
  );
  return plaintextItems(items, { preserveLineBreaks });
}

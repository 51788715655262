import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tappable from 'common/Tappable';

import 'css/components/_AutoResizeTextarea.scss';

const HeightPerRow = 22;
const InsetHeight = 23;

export default class AutoResizeTextarea extends Component {
  static propTypes = {
    className: PropTypes.string,
    definedHeight: PropTypes.string,
    inset: PropTypes.node,
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    maxRows: null,
    minRows: 1,
  };

  state = {
    focused: false,
    needsResize: false,
    rows: this.props.minRows,
  };

  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkResize);

    this.checkResize();

    if (!this.props.autoFocus || !this.props.defaultValue) {
      return;
    }

    var length = this.props.defaultValue.length;
    var textarea = this.textareaRef.current;
    textarea.setSelectionRange(length, length);
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value: newValue } = this.props;
    if (newValue !== prevValue) {
      this.checkResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkResize);
  }

  focus = () => {
    const textarea = this.textareaRef.current;
    textarea.focus();
  };

  getSelectionRange = () => {
    const textarea = this.textareaRef.current;
    return {
      start: textarea.selectionStart,
      end: textarea.selectionEnd,
    };
  };

  getTextarea = () => {
    return this.textareaRef.current;
  };

  getValue = () => {
    const textarea = this.getTextarea();
    return textarea.value;
  };

  insertText = (text) => {
    if (document.execCommand('insertText', false, text)) {
      return;
    }

    const selectionRange = this.getSelectionRange();
    const value = this.getValue();
    const before = value.substring(0, selectionRange.start);
    const after = value.substring(selectionRange.end);
    this.setValue(before + text + after);
  };

  setCursorPosition = (position) => {
    const textarea = this.textareaRef.current;
    textarea.setSelectionRange(position, position);
  };

  setSelectionRange = (start, end, direction = 'none') => {
    const textarea = this.textareaRef.current;
    textarea.setSelectionRange(start, end, direction);
  };

  setValue = (value, viaTextEvent) => {
    const textarea = this.getTextarea();
    textarea.value = value;

    this.checkResize();
  };

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e);
    this.checkResize();
  };

  onBlur = (e) => {
    this.props.onBlur && this.props.onBlur(e);
    this.setState({
      focused: false,
    });
  };

  onFocus = (e) => {
    this.props.onFocus && this.props.onFocus(e);
    this.setState({
      focused: true,
    });
  };

  getRowCount = () => {
    const { maxRows, minRows } = this.props;
    const textarea = this.getTextarea();

    if (!textarea) {
      return minRows;
    }

    let rows = Math.max(textarea.scrollHeight / HeightPerRow, minRows);
    if (maxRows) {
      rows = Math.min(rows, maxRows);
    }

    return Math.round(rows);
  };

  checkResize = () => {
    this.setState(
      {
        needsResize: true,
        rows: 1,
      },
      () => {
        // In case checkResize is called twice
        if (this.state.needsResize) {
          this.setState({
            needsResize: false,
            rows: this.getRowCount(),
          });
        }
      }
    );
  };

  getTextareaStyle = () => {
    const { definedHeight, inset } = this.props;

    if (definedHeight) {
      return { height: definedHeight };
    }

    // take into account inset height
    const insetFactor = inset ? InsetHeight : 0;

    // On resize, textarea height (row count) is temporarily set to 1
    // In order to prevent height changes set container height bigger than textarea
    const padding = 8 * 2;
    const height = HeightPerRow * this.getRowCount() + insetFactor + padding;
    return { height: `${height}px` };
  };

  renderInset() {
    const { inset } = this.props;
    if (!inset) {
      return null;
    }

    return <div className="inset">{inset}</div>;
  }

  render() {
    const className = classnames('autoResizeTextarea', this.props.className, {
      focused: this.state.focused,
    });

    const props = {};
    for (var key in this.props) {
      if (key === 'className' || key === 'inset' || key === 'key') {
        continue;
      }

      props[key] = this.props[key];
    }
    if (this.props.autoFocus) {
      props.autoFocus = 'true';
    }

    const textareaProps = { ...this.props };
    Object.keys(AutoResizeTextarea.propTypes).forEach((propType) => {
      delete textareaProps[propType];
    });

    return (
      <Tappable onTap={this.focus} stopPropagation={true}>
        <div className={className} style={this.getTextareaStyle()}>
          {this.renderInset()}
          <span className="inputContainer">
            <textarea
              {...textareaProps}
              key="textarea"
              ref={this.textareaRef}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
              rows={this.state.rows}
            />
          </span>
        </div>
      </Tappable>
    );
  }
}

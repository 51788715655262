import React from 'react';

import LazyLoadedImage from 'common/LazyLoadedImage';
import Button from 'common/ui/ButtonV2';

import type { LucideProps } from 'lucide-react';

type Option = {
  label: string;
  name: string;
  startIcon?: React.ComponentType<LucideProps>;
  startImg?: string;
};

type Props = {
  onChange: (selected: string[]) => void;
  options: Option[];
  selected: string[];
};

const AdminOnboardingMultiSelect = ({ onChange, options, selected }: Props) => {
  const onToggle = (option: string, isSelected: boolean) => {
    if (isSelected) {
      onChange([...selected, option]);
      return;
    }

    onChange(selected.filter((selectedOption) => selectedOption !== option));
  };

  return (
    <div className="options">
      {options.map((option) => {
        const isSelected = selected.includes(option.name);
        const variant = isSelected ? 'toggleOn' : 'toggleOff';
        return (
          <Button
            key={option.name}
            onClick={() => onToggle(option.name, !isSelected)}
            size="large"
            startIcon={option.startIcon}
            variant={variant}>
            {option.startImg && <LazyLoadedImage src={option.startImg} width="18" />}
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default AdminOnboardingMultiSelect;

import React, {
  type ComponentPropsWithoutRef,
  type RefForwardingComponent,
  forwardRef,
} from 'react';

import classnames from 'classnames';

import 'css/components/inputs/_DropdownButton.scss';

interface Props extends ComponentPropsWithoutRef<'button'> {
  ariaType: 'dialog' | 'listbox';
  className?: string;
  open: boolean;
  label: React.ReactNode;
  placeholderActive?: boolean;
}

// A component for the visual styles of a dropdown button
const DropdownButton: RefForwardingComponent<HTMLButtonElement, Props> = (
  { ariaType, className, open, label, placeholderActive = false, ...props },
  ref
) => {
  return (
    <button
      aria-expanded={open}
      aria-haspopup={ariaType}
      className={classnames(
        'standaloneDropdownButton',
        { placeholder: placeholderActive },
        className
      )}
      ref={ref}
      role={ariaType}
      type="button"
      {...props}>
      <div className="left">{label}</div>
      <div className="icon-chevron-down" />
    </button>
  );
};

export default forwardRef(DropdownButton);

/* Example shape of an experiment object:

ExperimentName: {
  variations: {
    VariationName1: {
      control: true,
      weight: 50,
    },
    VariationName2: {
      weight: 50,
    },
  },
},
*/

const Experiments = {};

// add experiment.name to experiments
// add variation.name to variations
// normalize variation.weight (sum to 1.0)
// add variation.minWeight and variation.maxWeight
Object.keys(Experiments).forEach((experimentName) => {
  const experiment = Experiments[experimentName];
  experiment.name = experimentName;

  const { variations } = experiment;
  const totalWeight = Object.keys(variations).reduce((sum, variationName) => {
    const variation = variations[variationName];
    return sum + variation.weight;
  }, 0);
  Object.keys(variations)
    .sort((a, b) => {
      // important to sort variations by name for consistent ordering
      // (Object.keys ordering is not guaranteed)
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    })
    .reduce((minWeight, variationName) => {
      const variation = variations[variationName];
      variation.name = variationName;
      variation.weight /= totalWeight;
      variation.minWeight = minWeight;
      variation.maxWeight = minWeight + variation.weight;
      return variation.maxWeight;
    }, 0);
});

export default Experiments;

import React from 'react';

import { Download, Search, Sparkles } from 'lucide-react';

import TextInput from 'common/inputs/TextInput';
import Spinner from 'common/Spinner';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';

import FiltersDropdown, { type SortOptions } from './FiltersDropdown';

import 'css/components/post/PostActivity/_AdminHeader.scss';

type Filters = {
  onlyInternalComments: boolean;
  search: string;
  segment: string;
  sort: SortOptions;
};

type Props = {
  canExportComments: boolean;
  canSearch: boolean;
  canSummarize: boolean;
  filterLoading: boolean;
  filters: Filters;
  onExportComments: () => void;
  onFiltersChange: (filters: Filters) => void;
  onSummarize: () => void;
  summarizeLoading: boolean;
};

const AdminHeader = ({
  canExportComments,
  canSearch,
  canSummarize,
  filterLoading,
  filters: { onlyInternalComments, search, segment, sort },
  onExportComments,
  onFiltersChange,
  summarizeLoading,
  onSummarize,
}: Props) => {
  return (
    <div className="adminHeader">
      {canSearch ? (
        <TextInput
          className="searchTextInput"
          onChange={(e) =>
            onFiltersChange({
              onlyInternalComments,
              search: e.currentTarget.value,
              segment,
              sort,
            })
          }
          placeholder="Search activity..."
          prefix={
            filterLoading ? (
              <div className="textInputSpinner">
                <Spinner />
              </div>
            ) : (
              <Search size={16} />
            )
          }
          value={search}
        />
      ) : (
        <div className="left">Activity Feed</div>
      )}

      <div className="right">
        {canSummarize ? (
          <ButtonV2
            aria-type="dialog"
            onClick={onSummarize}
            size="medium"
            startIcon={Sparkles}
            loading={summarizeLoading}
            variant="outlined">
            Summarize
          </ButtonV2>
        ) : null}
        <FiltersDropdown
          loading={filterLoading}
          onChange={(filters: Omit<Filters, 'search'>) => onFiltersChange({ ...filters, search })}
          onlyInternalComments={onlyInternalComments}
          segment={segment}
          sort={sort}
        />
        {canExportComments ? (
          <IconButtonV2
            size="medium"
            icon={Download}
            variant="outlined"
            onClick={onExportComments}
            aria-label="export comments"
          />
        ) : null}
      </div>
    </div>
  );
};

export default AdminHeader;

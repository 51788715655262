import { getPostQueryKey } from 'common/util/filterPosts';

import {
  Invalidate,
  LoadingMore,
  MaxPages,
  QueryError,
  QueryLoaded,
  RequestQuery,
} from '../actions/postQueries';

export default function postQueries(state = {}, action) {
  switch (action.type) {
    case RequestQuery: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case QueryLoaded: {
      const queryKey = getPostQueryKey(action.queryParams);
      // limit pages to 50
      const hasNextPage =
        !action.queryParams.pages || action.queryParams.pages <= MaxPages
          ? action.result.hasNextPage
          : false;

      const nextState = Object.assign({}, state[queryKey], {
        hasNextPage,
        lastUpdated: action.timestamp,
        loading: false,
        loadingMore: false,
        queryParams: action.queryParams,
      });

      if (action.result.posts) {
        nextState.posts = action.result.posts.map((post) => ({
          boardID: post.boardID,
          postURLName: post.urlName,
          filteredScore: post.filteredScore,
          viewerInFilteredVoters: post.viewerInFilteredVoters,
        }));
      }

      if (action.result.titles) {
        nextState.titles = action.result.titles;
      }

      // If just invalidated, clear all other query keys.
      if (state.needsPurge) {
        return {
          [queryKey]: nextState,
          needsPurge: false,
        };
      }

      return Object.assign({}, state, { [queryKey]: nextState });
    }

    case QueryError: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          queryParams: action.queryParams,
        }),
        needsPurge: false,
      });
    }

    case LoadingMore: {
      const queryKey = getPostQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
        needsPurge: false,
      });
    }

    case Invalidate: {
      return Object.assign({}, state, {
        needsPurge: true,
      });
    }

    default:
      return state;
  }
}

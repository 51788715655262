import React, { Component } from 'react';

import { Settings } from 'lucide-react';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import CheckboxInput from 'common/inputs/CheckboxInput';
import RadioButtonGroup from 'common/inputs/RadioButtonGroup';
import SearchInput from 'common/inputs/SearchInput';
import Link from 'common/Link';
import AccessModal from 'common/modals/AccessModal';
import AdminPageSidebar from 'common/subdomain/admin/AdminPageSidebar';
import AdminSidebarSectionItem from 'common/subdomain/admin/AdminSidebarSectionItem';
import Tag from 'common/tags/Tag';
import Tappable from 'common/Tappable';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import delayer from 'common/util/delayer';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';

import AdminSidebarSection from './AdminSidebarSection';

import 'css/components/subdomain/admin/_AdminChangelogSidebar.scss';

const Statuses = {
  draft: {
    name: 'draft',
    render: 'Draft',
  },
  scheduled: {
    name: 'scheduled',
    render: 'Scheduled',
  },
  published: {
    name: 'published',
    render: 'Published',
  },
};

const Types = {
  all: {
    name: 'all',
    render: 'All',
  },
  new: {
    name: 'new',
    render: 'New',
  },
  improved: {
    name: 'improved',
    render: 'Improved',
  },
  fixed: {
    name: 'fixed',
    render: 'Fixed',
  },
};

class AdminChangelogSidebar extends Component {
  static propTypes = {
    changelog: PropTypes.object,
    company: PropTypes.object,
    location: PropTypes.object,
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.statusRefs = {};
    Object.values(Statuses).forEach((status) => {
      this.statusRefs[status.name] = React.createRef();
    });
    this.searchInputRef = React.createRef();
    this._searchDelayer = new delayer(this.onSearchInputChangeAfterDelay, 250);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { query },
    } = this.props;
    const {
      location: { query: prevQuery },
    } = prevProps;
    if (!query.search && prevQuery.search) {
      this.searchInputRef.current.setValue('');
    } else if (query.search && !prevQuery.search) {
      this.searchInputRef.current.setValue(decodeQueryTextParam(query.search));
    }
  }

  componentWillUnmount() {
    this._searchDelayer.cancel();
  }

  onLabelSelected = (label) => {
    const { location, router } = this.props;
    const { query } = location;

    const { labels } = query;
    const labelURLNames = labels ? labels.split('_') : [];
    const labelURLNameSet = {};
    labelURLNames.forEach((labelURLName) => {
      labelURLNameSet[labelURLName] = true;
    });

    if (labelURLNameSet[label.urlName]) {
      delete labelURLNameSet[label.urlName];
    } else {
      labelURLNameSet[label.urlName] = true;
    }

    const newURLNames = Object.keys(labelURLNameSet);
    const newLabels = newURLNames.length > 0 ? newURLNames.join('_') : undefined;

    const newQuery = Object.assign({}, query, {
      labels: newLabels,
    });

    router.replace({
      ...location,
      query: newQuery,
    });
  };

  onStatusChange = () => {
    const { location, router } = this.props;
    const { query } = location;

    const checked = [];
    Object.values(Statuses).forEach((status) => {
      const checkboxInput = this.statusRefs[status.name].current;
      if (checkboxInput.getValue()) {
        checked.push(status.name);
      }
    });

    const newStatus = checked.length === 3 ? undefined : checked.join('_');
    const newQuery = Object.assign({}, query, {
      status: newStatus,
    });

    router.replace({
      ...location,
      query: newQuery,
    });
  };

  onTypeChange = (type) => {
    const { location, router } = this.props;
    const { query } = location;

    const newType = type === Types.all ? undefined : type.name;
    const newQuery = Object.assign({}, query, {
      type: newType,
    });

    router.replace({
      ...location,
      query: newQuery,
    });
  };

  onSearchInputChange = (searchQuery) => {
    this._searchDelayer.callAfterDelay(searchQuery);
  };

  onSearchInputChangeAfterDelay = (search) => {
    const { location, router } = this.props;
    const { query } = location;

    const newQuery = { ...query, search: search ? encodeURIComponent(search) : undefined };

    router.replace({
      ...location,
      query: newQuery,
    });
  };

  renderCreateLink() {
    const { company, openModal, viewer } = this.props;
    const viewerHasPermission = hasPermission('manageChangelog', company, viewer);
    return (
      <Link
        className="createLink"
        disabled={!viewerHasPermission}
        onTap={() => {
          if (viewerHasPermission) {
            return;
          }
          openModal(AccessModal, {
            requiredPermissions: ['manageChangelog'],
          });
        }}
        to="/admin/changelog/create">
        <div className="createNewEntry">
          <div className="label">Create a new entry</div>
          <div className="icon icon-plus" />
        </div>
      </Link>
    );
  }

  renderLabelFilter() {
    const { changelog } = this.props;
    if (!changelog || !changelog.labels || !changelog.labels.length) {
      return null;
    }

    const { labels } = this.props.location.query;

    const labelURLNames = labels ? labels.split('_') : [];
    const labelURLNameSet = {};
    labelURLNames.forEach((labelURLName) => {
      labelURLNameSet[labelURLName] = true;
    });

    const labelInputs = [];
    changelog.labels.forEach((label) => {
      labelInputs.push(
        <Tag
          key={label._id}
          name={label.name}
          onTap={this.onLabelSelected.bind(this, label)}
          selected={!!labelURLNameSet[label.urlName]}
        />
      );
    });

    return (
      <AdminSidebarSection
        action={
          <Link to="/admin/settings/changelog/labels">
            <Settings size="16" className="icon-settings" />
          </Link>
        }
        className="labelSection"
        title="Labels">
        <div className="labelInputs">{labelInputs}</div>
      </AdminSidebarSection>
    );
  }

  renderStatusFilter() {
    const { status } = this.props.location.query;
    const statuses = typeof status === 'string' ? status.split('_') : null;

    const statusInputs = [];
    Object.values(Statuses).forEach((statusEntry) => {
      const isChecked = statuses ? statuses.includes(statusEntry.name) : true;
      statusInputs.push(
        <AdminSidebarSectionItem key={statusEntry.name}>
          <Tappable>
            <CheckboxInput
              defaultChecked={!!isChecked}
              label={statusEntry.render}
              onChange={this.onStatusChange.bind(this, statusEntry)}
              ref={this.statusRefs[statusEntry.name]}
            />
          </Tappable>
        </AdminSidebarSectionItem>
      );
    });

    return (
      <AdminSidebarSection className="statusSection" title="Status">
        {statusInputs}
      </AdminSidebarSection>
    );
  }

  renderTypeFilter() {
    const buttons = Object.values(Types);
    return (
      <AdminSidebarSection className="typeSection" title="Type">
        <RadioButtonGroup
          buttons={buttons}
          defaultSelectedName={this.props.location.query.type}
          onChange={this.onTypeChange}
        />
      </AdminSidebarSection>
    );
  }

  render() {
    const {
      company: {
        changelog: { hasEntries },
      },
    } = this.props;
    return (
      <div className="adminChangelogSidebar">
        {this.renderCreateLink()}
        <AdminPageSidebar showSidebar={true}>
          {hasEntries ? (
            <>
              <div className="searchWrapper">
                <SearchInput
                  stopPropagation={false}
                  placeholder="Search Entries..."
                  onChange={this.onSearchInputChange}
                  defaultValue={decodeQueryTextParam(this.props.location.query.search)}
                  showClearIcon={false}
                  autoFocus={false}
                  ref={this.searchInputRef}
                  styling="v2"
                />
              </div>
              <p className="filters">Filters</p>
              {this.renderStatusFilter()}
              {this.renderTypeFilter()}
              {this.renderLabelFilter()}
            </>
          ) : null}
        </AdminPageSidebar>
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  openModal: OpenModalContext,
  router: RouterContext,
  viewer: ViewerContext,
})(AdminChangelogSidebar);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import copyText from 'common/util/copyText';

import Tappable from '../Tappable';

import 'css/components/inputs/_CopyButton.scss';

export default class CopyButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    label: 'Copy',
  };

  state = {
    copied: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onCopy = () => {
    const { copied } = this.state;
    if (copied) {
      return;
    }

    const { value } = this.props;
    copyText(value).then(() => {
      this.setState({
        copied: true,
      });

      if (this._copyTimeout) {
        clearTimeout(this._copyTimeout);
      }

      this._copyTimeout = setTimeout(() => {
        if (!this._isMounted) {
          return;
        }

        this._copyTimeout = null;
        this.setState({
          copied: false,
        });
      }, 3000);
    });
  };

  render() {
    const { label } = this.props;
    const { copied } = this.state;

    return (
      <Tappable onTap={this.onCopy}>
        <div className={classnames('copyButton', { active: !copied })}>
          {copied ? 'Copied!' : label}
        </div>
      </Tappable>
    );
  }
}

export const roundScore = (score: number, value: number = score) => {
  if (score < 10) {
    const num = value.toFixed(1);
    return Number.isInteger(score * 10) ? score : num;
  } else if (score < 1000) {
    return value.toFixed(0);
  } else if (score < 10000) {
    return value.toFixed(1);
  } else {
    return value.toFixed(0);
  }
};

const getScoreForDisplay = (score: number) => {
  if (score < 10) {
    return roundScore(score);
  } else if (score < 1000) {
    const value = roundScore(score);
    return value === '1000' ? '1k' : value;
  } else if (score < 10000) {
    const value = roundScore(score, score / 1000);
    return `${Number.isInteger(score / 100) ? score / 1000 : value}k`;
  } else {
    const value = roundScore(score, score / 1000);
    return `${value}k`;
  }
};

export default getScoreForDisplay;

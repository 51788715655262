import React, { Component } from 'react';

import { Edit, Plus, Trash } from 'lucide-react';
import PropTypes from 'prop-types';

import Toggle from 'common/common/Toggle';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Draggable from 'common/draganddrop/Draggable';
import DropArea from 'common/draganddrop/DropArea';
import Link from 'common/Link';
import Tappable from 'common/Tappable';
import { H2, P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import isNil from 'common/util/isNil';
import mapify from 'common/util/mapify';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminBoardSettingsCreateFormFields.scss';

class AdminBoardSettingsCreateForm extends Component {
  static propTypes = {
    company: PropTypes.object,
    onDeleteField: PropTypes.func.isRequired,
    onEditField: PropTypes.func.isRequired,
    onReorderField: PropTypes.func.isRequired,
    onUpdateField: PropTypes.func.isRequired,
    viewer: PropTypes.object,
  };

  renderLockedField = (field) => {
    const { onEditField } = this.props;

    return (
      <div className="fieldContainer locked" key={field.tempID}>
        <div className="column">
          <div className="fieldName">{field.name}</div>
        </div>
        <div className="column">
          <Tooltip position="top" value="This option can't be&nbsp;modified">
            <Toggle disabled={true} onToggle={() => null} small value={field.required} />
          </Tooltip>
        </div>
        <div className="column actions">
          <button className="fieldAction" onClick={() => onEditField(field)}>
            <Edit size={16} />
          </button>
        </div>
      </div>
    );
  };

  renderField = (field) => {
    const { company, onDeleteField, onEditField, onReorderField, onUpdateField, viewer } =
      this.props;
    const viewerHasPermission = hasPermission('manageCustomPostFields', company, viewer);

    if (field.delete) {
      return null;
    }

    return (
      <Draggable disabled={!viewerHasPermission} key={field.tempID} value={field.tempID}>
        <div className="fieldContainer">
          <DropArea
            className="dropArea"
            onDrop={(fieldTempID) => onReorderField(fieldTempID, field.tempID, { order: 'before' })}
          />
          <div className="column">
            <div className="fieldDrag icon icon-drag" />
            <div className="fieldName">{field.name}</div>
          </div>
          <div className="column">
            <Toggle
              disabled={!viewerHasPermission}
              onToggle={(required) => onUpdateField({ ...field, required })}
              small
              value={field.required}
            />
          </div>
          <div className="column actions">
            <button className="fieldAction" onClick={() => onEditField(field)}>
              <Edit size={16} />
            </button>
            <button className="fieldAction" onClick={() => onDeleteField(field)}>
              <Trash size={16} />
            </button>
          </div>
        </div>
      </Draggable>
    );
  };

  renderFieldHeaders = () => {
    return (
      <div className="fieldHeaders fieldContainer locked">
        <div className="column">
          <H2 variant="bodyMd" fontWeight="semibold" className="formSectionTitle">
            Fields
          </H2>
        </div>
        <div className="column">
          <H2 variant="bodyMd" fontWeight="semibold" className="formSectionTitle">
            Required?
          </H2>
        </div>
        <div className="column"></div>
      </div>
    );
  };

  renderNewField = () => {
    const { company, customPostFields, fields, onCreateField, onReorderField } = this.props;

    const lastField = fields[fields.length - 1];
    const fieldMap = mapify(fields, 'customPostFieldID');
    const nonSelectedFields = customPostFields.filter((customPostField) => {
      const field = fieldMap[customPostField._id];
      return !field || field.delete;
    });
    const nonDeletedBoardFields = fields.filter((field) => !field.delete && !field.default);
    const boardFieldLimit = company.limits?.boardFields;
    const hasUnlimitedPostFields = company.featureAllowlist.includes(
      'custom-post-fields-unlimited'
    );
    const hasLimit = !(hasUnlimitedPostFields || isNil(boardFieldLimit));
    if (hasLimit && nonDeletedBoardFields.length >= company.limits.boardFields) {
      return (
        <div className="newFieldContainer">
          <DropArea
            className="dropArea"
            onDrop={(fieldTempID) =>
              onReorderField(fieldTempID, lastField.tempID, { order: 'after' })
            }
          />
          <div className="noFieldsMessage">You can add up to {boardFieldLimit} custom fields</div>
        </div>
      );
    } else if (!nonSelectedFields.length) {
      return (
        <div className="newFieldContainer">
          <DropArea
            className="dropArea"
            onDrop={(fieldTempID) =>
              onReorderField(fieldTempID, lastField.tempID, { order: 'after' })
            }
          />
          <div className="noFieldsMessage">
            Create new <Link to="/admin/settings/fields/post-fields">Post Fields</Link> to add them
            to a board
          </div>
        </div>
      );
    }

    return (
      <div className="newFieldContainer">
        <DropArea
          className="dropArea"
          onDrop={(fieldTempID) =>
            onReorderField(fieldTempID, lastField.tempID, { order: 'after' })
          }
        />
        <Tappable onTap={onCreateField}>
          <button className="newFieldButton" type="button">
            <Plus size={16} strokeWidth={1} />
            <P>Add new field</P>
          </button>
        </Tappable>
      </div>
    );
  };

  render() {
    const { fields } = this.props;

    const lockedFields = fields.filter((field) => field.default);
    const unlockedFields = fields.filter((field) => !field.default);

    return (
      <section className="section adminBoardSettingsCreateFormFields">
        {this.renderFieldHeaders()}
        {lockedFields.map((field) => this.renderLockedField(field))}
        {unlockedFields.map((field) => this.renderField(field))}
        {this.renderNewField()}
      </section>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  viewer: ViewerContext,
})(AdminBoardSettingsCreateForm);

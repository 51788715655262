import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import Tappable from 'common/Tappable';
import hasPermission from 'common/util/hasPermission';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminAPISettings.scss';

class AdminAPISettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      apiKey: PropTypes.string,
      webhooks: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          webhookURL: PropTypes.string,
        })
      ),
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    adding: false,
    error: null,
    webhookInput: '',
  };

  constructor(props, context) {
    super(props, context);

    this.inputRef = React.createRef();
  }

  onAddWebhook = () => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('manageWebhooks', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageWebhooks'],
      });
      return;
    }

    const { adding, webhookInput } = this.state;
    if (adding) {
      return;
    }

    if (!validateInput.primitives.url(webhookInput)) {
      this.setState({
        error: 'Please enter a valid URL.',
      });
      return;
    }

    this.setState({
      adding: true,
      error: null,
    });

    AJAX.post(
      '/api/company/createWebhook',
      {
        webhookURL: webhookInput,
      },
      (response) => {
        if (response === 'success') {
          this.props.reloadCompany().then(() => {
            this.inputRef.current.setValue('');
            this.setState({
              adding: false,
            });
          });
          return;
        }

        this.setState({
          adding: false,
          error: 'Something went wrong, please try again later.',
        });
      }
    );
  };

  onRemoveWebhook = (webhook) => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('manageWebhooks', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['manageWebhooks'],
      });
      return;
    }

    openModal(ConfirmModal, {
      message: "Are you sure you'd like to remove this webhook?",
      onConfirm: () => {
        AJAX.post(
          '/api/company/deleteWebhook',
          {
            webhookID: webhook._id,
          },
          (response) => {
            if (response === 'success') {
              this.props.reloadCompany();
              return;
            }
          }
        );
      },
    });
  };

  onWebhookInputChange = (e) => {
    this.setState({
      error: null,
      webhookInput: e.target.value,
    });
  };

  renderWebhooks() {
    const { webhooks } = this.props.company;
    const items = [];
    webhooks.forEach((webhook, i) => {
      items.push(
        <div className="webhook" key={i}>
          <div className="url">{webhook.webhookURL}</div>
          <Tappable onTap={this.onRemoveWebhook.bind(this, webhook)}>
            <div className="icon icon-x" />
          </Tappable>
        </div>
      );
    });
    return <div className="webhooks">{items}</div>;
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderKey() {
    const {
      company: { apiKey },
    } = this.props;
    if (!apiKey) {
      return null;
    }

    return (
      <div className="text">
        Your Secret API Key is:&nbsp;
        <span className="emphasize">{apiKey}</span>
      </div>
    );
  }

  renderWebhookForm() {
    const { adding, webhookInput } = this.state;
    const isWebhookInvalid = !validateInput.primitives.url(webhookInput);
    return (
      <Form
        className="webhookForm"
        addEventsToDocument={false}
        disableSubmit={adding || isWebhookInvalid}
        onSubmit={this.onAddWebhook}>
        <TextInput
          onChange={this.onWebhookInputChange}
          placeholder="Add a new webhook URL"
          ref={this.inputRef}
        />
        <Button disabled={isWebhookInvalid} formButton={true} loading={adding} value="Add" />
      </Form>
    );
  }

  render() {
    return (
      <div className="adminAPISettings">
        <Helmet title="API & Webhooks | Canny" />
        <AdminSettingsHeader
          title="API"
          subheading="Push and pull data to and from&nbsp;Canny."
          learnMoreLink="https://help.canny.io/en/articles/4195400-the-canny-api"
        />
        <div className="content">
          <div className="text">
            You can use our API and webhooks to seamlessly integrate Canny with other services. See
            our&nbsp;
            <a href="https://developers.canny.io/api-reference" rel="noopener" target="_blank">
              documentation
            </a>
            .
          </div>
          {this.renderKey()}
          <div className="text">
            Remember, this key is secret. It should only be used from the server side of
            your&nbsp;app.
          </div>
          <div className="webhookHeading">Webhooks</div>
          <div className="text">
            After adding a webhook URL, we'll send requests to that URL when specific Canny events
            happen (like a new post being&nbsp;created).
          </div>
          {this.renderWebhooks()}
          {this.renderError()}
          {this.renderWebhookForm()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.adminSettings.api), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminAPISettings);

import { PermissionSections, type Section, Sections } from './constants';

import type { Role, RolePermissionName } from 'common/api/endpoints/companies';

type EnabledPermissionsMap = Record<Section, Partial<Record<RolePermissionName, boolean>>>;

const createEmptyPermissionsMap = (): EnabledPermissionsMap => {
  const emptyMap: EnabledPermissionsMap = {
    Boards: {},
    Users: {},
    Posts: {},
    Votes: {},
    Comments: {},
    Roadmap: {},
    Changelog: {},
    Developer: {},
    Settings: {},
  };

  for (const section of Sections) {
    emptyMap[section] = {};
    for (const permission of PermissionSections[section]) {
      emptyMap[section][permission] = false;
    }
  }
  return emptyMap;
};

const populatePermissionsFromRole = (
  map: EnabledPermissionsMap,
  role: Role
): EnabledPermissionsMap => {
  const currentlyEnabledPermissions = new Set(
    Object.entries(role.permissions)
      .filter(([_, isEnabled]) => isEnabled)
      .map(([permission]) => permission as RolePermissionName)
  );

  for (const section of Sections) {
    for (const permission of PermissionSections[section]) {
      if (currentlyEnabledPermissions.has(permission)) {
        map[section][permission] = true;
      }
    }
  }
  return map;
};

export const generateInitialState = (role: Role | null) => {
  let state = createEmptyPermissionsMap();
  if (role) {
    state = populatePermissionsFromRole(state, role);
  }
  return state;
};

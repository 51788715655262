import React, { useContext, useState } from 'react';

import { reloadBoard } from 'common/actions/boards';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidatePosts, reloadPost } from 'common/actions/posts';
import { invalidatePostActivities } from 'common/actions/postsActivity';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import connect from 'common/core/connect';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import GenericActionModal, { GenericActionError } from './GenericActionModal';
import GenericSuggestion from './GenericSuggestion';
import { type CommonModalProps } from './index';
import { findStringMatchesByName } from './utils';

import type { Post } from 'common/api/resources/posts';
import type { BoardStateItem } from 'common/reducers/boards';
import type { Dispatch } from 'redux-connect';

type OwnProps = CommonModalProps;

type ConnectProps = {
  invalidatePostData: (posts: Post[]) => Promise<void>;
  reloadBoards: (boards: BoardStateItem[]) => Promise<void>;
  reloadPost: (post: Post) => Promise<void>;
  reloadRoadmapPostsForRoadmapWithPosts: (posts: Post[]) => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const MovePostsModal = ({
  invalidatePostData,
  onClose,
  reloadBoards,
  reloadPost,
  reloadRoadmapPostsForRoadmapWithPosts,
  selectedPost,
  selectedPosts,
}: Props) => {
  const boards = useContext(BoardsContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const boardsAreLoading = 'loading' in boards;

  const reloadAndInvalidateCache = async (boardIDs: string[]) => {
    const boardsToReload = (boards as BoardStateItem[]).filter((board) =>
      boardIDs.includes(board._id)
    );
    const postsToInvalidate = selectedPosts.filter((post) => post._id !== selectedPost?._id); // only invalidate posts that aren't the selected post
    await Promise.all([
      reloadRoadmapPostsForRoadmapWithPosts(selectedPosts),
      reloadBoards(boardsToReload),
      invalidatePostData(postsToInvalidate),
      selectedPost && reloadPost(selectedPost),
    ]);
  };

  const handleSubmit = async (selectedBoard: BoardStateItem) => {
    const previousBoardIDs = selectedPosts.map((post) => post.boardID);

    const responseJSON = await AJAX.post('/api/posts/bulkEdit', {
      boardID: selectedBoard._id,
      operationType: 'move',
      postIDs: selectedPosts.map((post) => post._id),
    });
    const { error } = parseAPIResponse(responseJSON, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      throw new GenericActionError({ message: error.message, source: 'MovePosts' });
    } else {
      await reloadAndInvalidateCache([...previousBoardIDs, selectedBoard._id]);
    }
  };

  const filteredBoards = boardsAreLoading ? [] : findStringMatchesByName(boards, searchValue);

  const postPlural = selectedPosts.length > 1 ? 'posts' : 'post';

  return (
    <GenericActionModal<BoardStateItem>
      cta={`Move ${postPlural}`}
      title={`Move ${postPlural}`}
      onClose={onClose}
      onSubmit={handleSubmit}
      searchValue={searchValue}
      showSearch={boardsAreLoading ? false : boards.length > 10}
      onSearchValueChanged={(searchValue) => setSearchValue(searchValue)}
      successMessage={`Successfully moved ${selectedPosts.length} ${postPlural}`}
      suggestions={filteredBoards}
      SuggestionComponent={GenericSuggestion}
      suggestionsLoading={boardsAreLoading}
    />
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  invalidatePostData: (posts: Post[]) => {
    return Promise.all([
      dispatch(invalidatePostActivities(posts)),
      dispatch(invalidatePostQueries()),
      dispatch(invalidatePosts(posts)),
    ]);
  },
  reloadPost: (post: Post) => {
    return dispatch(reloadPost(post));
  },
  reloadBoards: (boards: BoardStateItem[]) => {
    return Promise.all(boards.map((board) => dispatch(reloadBoard(board.urlName))));
  },
  reloadRoadmapPostsForRoadmapWithPosts: (posts: Post[]) => {
    return Promise.all(posts.map((post) => dispatch(reloadRoadmapPostsForRoadmapsWithPost(post))));
  },
}))(MovePostsModal) as unknown as React.FC<OwnProps>;

import { loadCompany } from './company';
import { loadViewer } from './viewer';
import { loadWidgetData } from './widget';
import Data from '../Data';

// Action Types

export const RequestNotifications = 'canny/notifications/request_notifications';
export const LoadingMoreNotifications = 'canny/notifications/loading_more_notifications';
export const NoNotifications = 'canny/notifications/no_notifications';
export const NotificationsLoaded = 'canny/notifications/notifications_loaded';
export const NotificationsError = 'canny/notifications/notifications_error';
export const ReloadingNotifications = 'canny/notifications/reloading_notifications';

// Actions

function requestNotifications() {
  return {
    timestamp: Date.now(),
    type: RequestNotifications,
  };
}

function notificationsLoaded(notifications) {
  return {
    notifications,
    timestamp: Date.now(),
    type: NotificationsLoaded,
  };
}

function loadingMoreNotifications() {
  return {
    timestamp: Date.now(),
    type: LoadingMoreNotifications,
  };
}

function noNotifications() {
  return {
    timestamp: Date.now(),
    type: NoNotifications,
  };
}

function notificationsError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: NotificationsError,
  };
}

function reloadingNotifications() {
  return {
    timestamp: Date.now(),
    type: ReloadingNotifications,
  };
}

// Action Creators

function fetchNotifications(pages, boardID) {
  return (dispatch, getState) => {
    const promises = [];

    const state = getState();
    if (state.widget) {
      promises.push(dispatch(loadWidgetData()));
    } else {
      promises.push(dispatch(loadViewer()));
      promises.push(dispatch(loadCompany()));
    }

    return Promise.all(promises).then(() => {
      const state = getState();
      const company = state.widget ? state.widget.company : state.company;
      const viewer = state.widget ? state.widget.viewer : state.viewer;
      if (!viewer || !viewer._id || !company || !company._id) {
        return dispatch(noNotifications());
      }

      const { cookies } = state;
      return Data.fetch(
        {
          notifications: {
            input: {
              boardID,
              pages,
            },
            query: Data.queries.notifications,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, error, data);
        }
      );
    });
  };
}

export function loadNotifications(boardID) {
  return (dispatch, getState) => {
    if (shouldFetchNotifications(getState())) {
      dispatch(requestNotifications());
      return dispatch(fetchNotifications(1, boardID));
    } else if (isFetchingNotifications(getState())) {
      return waitForResult();
    }
  };
}

export function loadMoreNotifications() {
  return (dispatch, getState) => {
    const {
      notifications: { boardID, hasNextPage, pages },
    } = getState();
    if (!hasNextPage) {
      return;
    }

    dispatch(loadingMoreNotifications());
    return dispatch(fetchNotifications(pages + 1, boardID));
  };
}

export function reloadNotifications() {
  return (dispatch, getState) => {
    const {
      notifications: { boardID, pages },
    } = getState();
    dispatch(reloadingNotifications());
    return dispatch(fetchNotifications(pages, boardID));
  };
}

// Helper Functions

function shouldFetchNotifications(state) {
  return !state.notifications;
}

function isFetchingNotifications(state) {
  return state.notifications.loading;
}

// Callback Queue

const resultCallbacks = [];

function waitForResult() {
  return new Promise((resolve, reject) => {
    resultCallbacks.push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, error, data) {
  var resultAction;
  if (error) {
    resultAction = dispatch(notificationsError(error));
  } else {
    resultAction = dispatch(notificationsLoaded(data.notifications));
  }

  return Promise.all([dispatch(resultAction)]).then(() => {
    if (resultCallbacks.length === 0) {
      return;
    }

    resultCallbacks.forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks.length = 0;
  });
}

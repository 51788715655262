import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Form from 'common/Form';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModalPortal from 'common/modals/ModalPortal';
import Tappable from 'common/Tappable';
import delayer from 'common/util/delayer';

import 'css/components/subdomain/admin/_AdminFeedbackBulkEditModal.scss';

export default class AdminFeedbackBulkEditModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    portalContainerID: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    operation: PropTypes.shape({
      cta: PropTypes.string,
      disabled: PropTypes.bool,
      getSuggestions: PropTypes.func,
      getRequestData: PropTypes.func,
      icon: PropTypes.object,
      name: PropTypes.string,
      onCompleted: PropTypes.func,
      renderSuggestion: PropTypes.func,
      showSearch: PropTypes.bool,
      type: PropTypes.string,
    }).isRequired,
    selectedPostsMap: PropTypes.object.isRequired,
  };

  state = {
    error: null,
    searchValue: null,
    selectedSuggestion: null,
    submitting: false,
  };

  constructor(props) {
    super(props);

    this._searchDelayer = new delayer(this.onSearchValueChangedAfterDelay, 400);
    this._searchValue = null;
  }

  editPosts = async () => {
    const { searchValue, selectedSuggestion } = this.state;
    let errorMessage;
    this.setState({ error: null, submitting: true });

    try {
      await this.props.onSubmit({ searchValue, selectedSuggestion });
    } catch (error) {
      errorMessage = error.message || 'Something went wrong, please try again later.';
    }

    if (errorMessage) {
      this.setState({ error: errorMessage });
    }
  };

  onSearchValueChanged = (e) => {
    const { value } = e.target;
    this._searchValue = value;
    this._searchDelayer.callAfterDelay();
  };

  onSearchValueChangedAfterDelay = () => {
    const { operation } = this.props;
    operation?.onSearchValueChanged?.(this._searchValue);
    this.setState({ searchValue: this._searchValue });
  };

  onSuggestionSelected = (selectedSuggestion) => this.setState({ selectedSuggestion });

  renderHeader() {
    const { operation } = this.props;

    return (
      <div className="modalHeader">
        <div className="title">{operation.name}</div>
        <div className="exitButton">
          <Tappable onTap={this.props.closeModal}>
            <div className="icon icon-x" />
          </Tappable>
        </div>
      </div>
    );
  }

  renderSearch() {
    const { operation } = this.props;

    if (!operation.showSearch) {
      return null;
    }

    return (
      <div className="modalSearch">
        <TextInput
          autoFocus={true}
          disabled={this.state.submitting}
          onChange={this.onSearchValueChanged}
          placeholder={'Search...'}
        />
      </div>
    );
  }

  renderSuggestions() {
    const { operation } = this.props;
    const { searchValue, selectedSuggestion } = this.state;
    const suggestions = operation.getSuggestions(searchValue);

    return (
      <div className="modalSuggestions">
        {suggestions.map((suggestion) => {
          const selected = suggestion._id === selectedSuggestion?._id;
          const classNames = classnames('modalSuggestion', { selected });
          return (
            <Tappable onTap={() => this.onSuggestionSelected(suggestion)} key={suggestion._id}>
              <div className={classNames}>{operation.renderSuggestion(suggestion)}</div>
            </Tappable>
          );
        })}
      </div>
    );
  }

  renderFooter() {
    const { operation } = this.props;
    const { error, selectedSuggestion, submitting } = this.state;

    return (
      <div className="modalFooter">
        <div className="error">{error}</div>
        <Button
          buttonType="cannyButton"
          disabled={!selectedSuggestion}
          formButton={true}
          loading={submitting}
          value={operation.cta}
        />
      </div>
    );
  }

  render() {
    return (
      <ModalPortal
        className="adminFeedbackBulkEditModalPortal"
        onClose={this.props.onClose}
        portalContainerID={this.props.portalContainerID}>
        <Form
          addEventsToDocument={true}
          className="adminFeedbackBulkEditModal"
          disableSubmit={this.state.submitting}
          onSubmit={this.editPosts}>
          {this.renderHeader()}
          <div className="modalContents">
            {this.renderSearch()}
            {this.renderSuggestions()}
          </div>
          {this.renderFooter()}
        </Form>
      </ModalPortal>
    );
  }
}

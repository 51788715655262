import React, { useContext } from 'react';

import { RouterContext } from 'common/containers/RouterContainer';
import AdminQueueAlert, {
  AlertTypes,
} from 'common/subdomain/admin/AdminQueue/AdminQueueAlerts/AdminQueueAlert';
import ButtonV2 from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';

const AdminQueueCreditWarning = () => {
  const router = useContext(RouterContext);

  return (
    <AdminQueueAlert
      type={AlertTypes.warning}
      text={
        <P>
          Your Autopilot credits are running low. Purchase more now to avoid interruptions in
          your&nbsp;service.
        </P>
      }
      actions={
        <ButtonV2 onClick={() => router.push('/admin/settings/billing/autopilot')} size="medium">
          Purchase credits
        </ButtonV2>
      }
    />
  );
};

export default AdminQueueCreditWarning;

import { Actions, type SharedRoadmapPostsAction } from '../actions/sharedRoadmapPosts';

import type { Board } from 'common/api/endpoints/boards';
import type { RoadmapPost, Status } from 'common/api/endpoints/roadmapPosts';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { Category } from 'common/api/resources/board';

export type RoadmapPostsState = {
  error?: string;
  lastUpdated: Date;
  loading?: boolean;
  roadmapPosts: Record<
    string,
    Record<
      string,
      {
        posts: RoadmapPost[];
        distinctBoards?: Board[];
        distinctCategories?: Category[];
        distinctOwners?: StrippedUser[];
        statuses?: Status[];
      }
    >
  >;
};

export default function roadmapPosts(
  state: RoadmapPostsState | null = null,
  action: SharedRoadmapPostsAction
): RoadmapPostsState | null {
  switch (action.type) {
    case Actions.FetchSharedRoadmapPosts: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
        roadmapPosts: {},
      });
    }

    case Actions.SharedRoadmapPostsLoaded: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        roadmapPosts: {
          ...(state?.roadmapPosts ?? {}),
          [action.roadmapID]: {
            [action.roadmapViewID]: {
              posts: action.roadmapPosts,
              distinctCategories: action.categories,
              distinctOwners: action.owners,
              statuses: action.statuses,
              distinctBoards: action.boards,
            },
          },
        },
      });
    }

    case Actions.SharedRoadmapPostsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    default:
      return state;
  }
}

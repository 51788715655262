import React, { useContext, useState } from 'react';

import AJAX from 'common/AJAX';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import ConfirmModal from 'common/modals/ConfirmModal';
import SavedSegmentsModal from 'common/modals/SavedSegmentsModal/SavedSegmentsModal';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Segment } from 'common/api/endpoints/companies';
import type { Board } from 'common/api/resources/board';

type Props = {
  boards: Board[];
  onUpdateQuery: () => void;
  segment: Segment;
  reloadSegments: () => void;
};

const DeleteSegmentConfirmationModal = ({
  boards,
  onUpdateQuery,
  segment,
  reloadSegments,
}: Props) => {
  const [deleting, setDeleting] = useState(false);
  const openModal = useContext(OpenModalContext);
  const showToast = useContext(ShowToastContext);

  const onBack = () => {
    openModal(SavedSegmentsModal, {
      boards,
      needsInvalidate: deleting,
      onUpdateQuery,
    });
  };

  const deleteSegment = async () => {
    if (deleting) {
      return;
    }

    setDeleting(true);

    const { _id } = segment;
    const response = await AJAX.post('/api/segments/delete', {
      segmentID: _id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'not authorized': 'You are not authorized to delete a segment',
        'invalid input': "This segment doesn't exist anymore. Refresh the page and try again.",
      },
    });

    if (error) {
      setDeleting(false);
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadSegments();
    onBack();
  };

  return (
    <ConfirmModal
      onConfirm={deleteSegment}
      closeModal={onBack}
      message={`Are you sure you want to delete the "${segment.name}" segment?`}
      submitButtonValue="Delete segment"
      submitButtonType="redButton"
      tint={false}
    />
  );
};

export default DeleteSegmentConfirmationModal;

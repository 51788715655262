import { Actions, type SharedRoadmapAction } from '../actions/sharedRoadmap';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

export type RoadmapsState = {
  error?: string;
  lastUpdated: Date;
  loading?: boolean;
  roadmaps?: Record<string, Roadmap>;
};

export default function roadmaps(
  state: RoadmapsState | null = null,
  action: SharedRoadmapAction
): RoadmapsState | null {
  switch (action.type) {
    case Actions.FetchSharedRoadmap: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case Actions.SharedRoadmapLoaded: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        roadmaps: {
          ...state?.roadmaps,
          [action.roadmapViewID]: action.roadmap,
        },
      });
    }

    case Actions.SharedRoadmapError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    default:
      return state;
  }
}

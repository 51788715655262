import React, { Component } from 'react';

import Colors from 'common/colors/constants';

import TextInput from './TextInput';

import 'css/components/inputs/_ColorInput.scss';

export default class ColorInput extends Component {
  state = {
    value: this.props.defaultValue ? this.props.defaultValue.toLowerCase() : '',
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  getValue = () => {
    return this.inputRef.current.getValue();
  };

  onTextChange = (e) => {
    var text = this.inputRef.current.getValue().toLowerCase();
    if (text === '' || text === '#') {
      this.setState({
        value: text,
      });
      this.props.onChange && this.props.onChange(text);
      return;
    }

    const nonHex = /[^0-9a-f]/gi;
    text = text.replace(nonHex, '');
    text = '#' + text.substr(0, 6);

    this.setState({
      value: text,
    });
    this.props.onChange && this.props.onChange(text);
  };

  renderPreview() {
    const isColor = /^#([0-9a-f]{6}|[0-9a-f]{3})$/;
    const { value } = this.state;
    const color = value.match(isColor) ? value : Colors.white;

    return <div className="color" style={{ backgroundColor: color }} />;
  }

  render() {
    return (
      <div className="colorInput">
        <TextInput
          {...this.props}
          onChange={this.onTextChange}
          ref={this.inputRef}
          value={this.state.value}
        />
        {this.renderPreview()}
      </div>
    );
  }
}

import React, { useContext, useState } from 'react';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidatePosts, reloadPost } from 'common/actions/posts';
import { invalidatePostActivities } from 'common/actions/postsActivity';
import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import connect from 'common/core/connect';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import GenericActionModal, { GenericActionError } from './GenericActionModal';
import GenericSuggestion from './GenericSuggestion';
import { type CommonModalProps } from './index';
import { findStringMatchesByName } from './utils';

import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

type OwnProps = CommonModalProps;

type ConnectProps = {
  invalidatePostData: (posts: Post[]) => Promise<void>;
  reloadPost: (post: Post) => Promise<void>;
  reloadRoadmapPostsForRoadmapsWithPosts: (posts: Post[]) => Promise<void>;
};

type Props = OwnProps & ConnectProps;

type GenericCategory = {
  _id: string;
  name: string;
};

const UncategorizedCategoryID = '_uncategorized';

const ChangePostsCategoryModal = ({
  invalidatePostData,
  onClose,
  reloadRoadmapPostsForRoadmapsWithPosts,
  selectedPost,
  selectedPosts,
}: Props) => {
  const boards = useContext(BoardsContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const boardsAreLoading = 'loading' in boards;

  // since we assert change category can only be done when all posts are from the same board
  // we can choose any selectedPost to find the boardID
  const selectedBoard =
    !boardsAreLoading && boards.find((board) => board._id === selectedPosts[0].board._id);

  const categories = selectedBoard
    ? [
        ...selectedBoard.categories,
        {
          _id: UncategorizedCategoryID,
          name: 'Uncategorized',
        },
      ]
    : [];

  const handleSubmit = async (selectedCategory: GenericCategory) => {
    const categoryID =
      selectedCategory._id === UncategorizedCategoryID ? null : selectedCategory._id;

    const responseJSON = await AJAX.post('/api/posts/bulkEdit', {
      categoryID,
      operationType: 'changeCategory',
      postIDs: selectedPosts.map((post) => post._id),
    });
    const { error } = parseAPIResponse(responseJSON, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      throw new GenericActionError({ message: error.message, source: 'ChangePostsCategory' });
    } else {
      const postsToInvalidate = selectedPosts.filter((post) => post._id !== selectedPost?._id); // only invalidate posts that aren't the selected post
      await Promise.all([
        invalidatePostData(postsToInvalidate),
        selectedPost && reloadPost(selectedPost),
        reloadRoadmapPostsForRoadmapsWithPosts(selectedPosts),
      ]);
    }
  };

  return (
    <GenericActionModal<GenericCategory>
      cta="Change"
      onClose={onClose}
      onSearchValueChanged={(searchValue) => setSearchValue(searchValue)}
      onSubmit={handleSubmit}
      searchValue={searchValue}
      showSearch={categories.length > 10}
      successMessage="Successfully changed category"
      suggestions={findStringMatchesByName(categories, searchValue)}
      SuggestionComponent={GenericSuggestion}
      suggestionsLoading={boardsAreLoading}
      title="Change category"
    />
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  invalidatePostData: (posts: Post[]) => {
    return Promise.all([
      dispatch(invalidatePostActivities(posts)),
      dispatch(invalidatePostQueries()),
      dispatch(invalidatePosts(posts)),
    ]);
  },
  reloadPost: (post: Post) => {
    return dispatch(reloadPost(post));
  },
  reloadRoadmapPostsForRoadmapsWithPosts: (posts: Post[]) => {
    return Promise.all(posts.map((post) => dispatch(reloadRoadmapPostsForRoadmapsWithPost(post))));
  },
}))(ChangePostsCategoryModal) as unknown as React.FC<OwnProps>;

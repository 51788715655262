export { CustomPostFieldTypes as CustomDescriptionFieldTypes } from 'common/api/resources/postFields';

export enum DescriptionColumnTypes {
  customField = 'customField',
  default = 'default',
}

export enum DefaultDescriptionColumnFields {
  board = 'board',
  category = 'category',
  eta = 'eta',
  owner = 'owner',
  status = 'status',
  tags = 'tags',
}

export enum DefaultDescriptionFieldTypes {
  multiSelect = 'multiSelect',
  singleSelect = 'singleSelect',
  dateRange = 'dateRange',
}

import React, { useContext } from 'react';

import classnames from 'classnames';

import {
  type QueryParams,
  loadMoreActionItems,
  removeItem,
} from 'common/actions/actionItemQueries';
import { invalidateSuggestions } from 'common/actions/asanaTaskSuggestions';
import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { deletePostDraft, savePostDraft } from 'common/actions/postDraft';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { invalidateRoadmap } from 'common/actions/roadmap';
import { removePostFromAllRoadmaps } from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import { AutopilotActionSourceType } from 'common/constants/autopilotActions';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import RoadmapPostContainer from 'common/containers/RoadmapPostContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import ModalPortal from 'common/modals/ModalPortal';
import SelectableDataTable from 'common/SelectableDataTable';
import AdminCreatePostModal from 'common/subdomain/admin/AdminCreatePostModal';
import AdminFeedbackPost from 'common/subdomain/admin/AdminFeedbackPost';
import { isNotNil } from 'common/util/isNil';
import mapify from 'common/util/mapify';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AutopilotNUX from './AutopilotNUX';
import { AuditLogActionsCell, AuditLogSuggestionCell, GenericSourceCell } from './DataTableCells';

import type { ActionItemsState, Counts, PostModalDraft, ActionRow as Row } from './types';
import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { ActionItem, PostActionItem, PostDraftActionItem } from 'common/api/endpoints/queue';
import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { CustomPostField } from 'common/api/resources/postFields';
import type { Post } from 'common/api/resources/posts';
import type { Column } from 'common/DataTable';

import 'css/components/subdomain/admin/_AdminQueuePage.scss';

type ReloadPostActivityPost = Pick<Post, '_id' | 'board' | 'urlName'>;

type ConnectProps = {
  actionItemCounts: Counts;
  actionItemList: ActionItemsState;
  boards: Board[];
  customPostFields: CustomPostField[];
  deletePostDraft: () => void;
  loadMoreItems: (queryParams: QueryParams, pages: number) => Promise<void>;
  reloadPostActivity: (post: ReloadPostActivityPost) => Promise<void>;
  removeItem: (item: ActionItem) => void;
  removePost: (postID: string, board: Board) => Promise<void>;
  roadmaps: Roadmap[];
  savePostDraft: (postDraft: PostModalDraft) => void;
};

type OwnProps = Record<string, never>;

type Props = ConnectProps & OwnProps;

const revertAutopilotAction = async (item: ActionItem) => {
  const response = await AJAX.post('/api/queue/actions/revert', {
    actionID: item._id,
  });

  return parseAPIResponse(response, {
    errors: {
      'not authorized': 'You are not authorized to revert this action',
      default: 'There was a problem reverting this action. Try again.',
    },
    isSuccessful: isDefaultSuccessResponse,
  });
};

const deletePost = async (postID: string) => {
  const response = await AJAX.post('/api/posts/delete', {
    postID,
  });

  return parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
  });
};

const mergePosts = async (item: PostActionItem, targetPost: Post) => {
  const response = await AJAX.post('/api/posts/merge', {
    mergePostID: item.sourceID,
    mergePostIntoID: targetPost._id,
  });

  return parseAPIResponse(response, {
    isSuccessful: isDefaultSuccessResponse,
    errors: {
      'board deleted': 'The board for one of the posts does not exist anymore',
      'invalid company': "You can't merge posts from another company",
      'not authorized': "You don't have permission to merge posts",
      'post missing': 'One of the posts you are trying to merge does not exist anymore',
      'same posts': 'It is not possible to merge one post with itself',
    },
  });
};

const AdminQueueAuditLog = ({
  actionItemCounts,
  actionItemList,
  boards,
  customPostFields,
  deletePostDraft,
  loadMoreItems,
  removeItem,
  reloadPostActivity,
  removePost,
  roadmaps,
  savePostDraft,
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);
  const openModal = useContext(OpenModalContext);

  const columns = [
    {
      id: 'source',
      align: 'left',
      header: 'Source',
      sortable: false,
      cell: (_, { item }) => {
        if (item.sourceType === 'draft') {
          return (
            <GenericSourceCell
              source={item.source}
              sourceType="draft"
              link={item.link}
              hasDuplicatePost={!!item.duplicatePost}
            />
          );
        }
        return (
          <GenericSourceCell
            hasDuplicatePost
            sourceType="post"
            urlName={item.duplicatePost.urlName}
          />
        );
      },
    } as Column<Row, 'source'>,
    {
      id: 'item',
      align: 'left',
      header: 'Suggestion',
      fullWidth: true,
      sortable: false,
      cell: (item) => <AuditLogSuggestionCell company={company} item={item} />,
    } as Column<Row, 'item'>,
    {
      id: 'actions',
      align: 'left',
      header: 'Actions',
      sortable: false,
      cell: (_, row) => <AuditLogActionsCell boards={boards} row={row} />,
    } as Column<Row, 'actions'>,
  ].filter(isNotNil);

  const revertActionAndReload = async (item: ActionItem) => {
    const { error } = await revertAutopilotAction(item);

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    removeItem(item);
    item.createdPostID && item.board && removePost(item.createdPostID, item.board);
    item.duplicatePost && reloadPostActivity(item.duplicatePost);
  };

  const deleteSourcePost = async (item: PostActionItem) => {
    const { error } = await deletePost(item.sourceID);
    if (error) {
      showToast(
        'Failed to delete source post. You may have to delete this post manually.',
        ToastTypes.error
      );
      return;
    }
  };

  const onPostMerge = async (item: PostActionItem, targetPost: Post) => {
    const boardsMap = mapify(boards, '_id');
    const targetBoard = boardsMap[targetPost.boardID];
    if (!targetBoard) {
      showToast('Failed to find the target board. Try again', ToastTypes.error);
      return;
    }

    const { error } = await mergePosts(item, targetPost);
    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadPostActivity({ ...targetPost, board: targetBoard }); // board is not included in the targetPost object
    removeItem(item);
  };

  const onApproveDraft = async (
    item: PostDraftActionItem,
    post: Post,
    { addVote }: { addVote: boolean }
  ) => {
    const response = await AJAX.post('/api/queue/drafts/approve', {
      postDraftID: item.sourceID,
      postID: post._id,
      addVote,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: `There was a problem ${
          addVote ? 'adding the vote' : 'creating the post'
        }. Try again.`,
      },
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    await revertActionAndReload(item);
    await reloadPostActivity(post);

    if (addVote) {
      showToast('Vote added', ToastTypes.success);
    } else {
      showToast('Post created', ToastTypes.success);
    }
  };

  const itemToRow = (item: ActionItem) => {
    const rowCommon = {
      id: item._id,
      source: item.source,
      item,
    };

    if (item.sourceType === AutopilotActionSourceType.draft) {
      return {
        ...rowCommon,
        actions: {
          create: async () => {
            deletePostDraft();
            savePostDraft({
              title: item.sourcePostDraft.title,
              details: item.sourcePostDraft.details,
              internalComment: item.sourcePostDraft.details,
              author: item.sourcePostDraft.author,
            });
            openModal(AdminCreatePostModal, {
              boards,
              onUnmount: () => deletePostDraft(),
              onPostCreated: async (post: Post) => onApproveDraft(item, post, { addVote: false }),
              onVoteAdded: async (post: Post) => onApproveDraft(item, post, { addVote: true }),
            });
          },
          merge: async (post: Post) => onApproveDraft(item, post, { addVote: true }),
          delete: async () => {
            await revertActionAndReload(item);
            if (item.duplicatePost) {
              showToast('Vote deleted', ToastTypes.success);
            } else {
              showToast('Post deleted', ToastTypes.success);
            }
          },
        },
      };
    }

    return {
      ...rowCommon,
      actions: {
        create: async () => {
          await revertActionAndReload(item);
          showToast('Post created', ToastTypes.success);
        },
        merge: async (post: Post) => {
          await revertActionAndReload(item);
          await onPostMerge(item, post);
          showToast('Posts merged', ToastTypes.success);
        },
        delete: async () => {
          await revertActionAndReload(item);
          await deleteSourcePost(item);
          showToast('Post deleted', ToastTypes.success);
        },
      },
    };
  };

  const isReady = !actionItemList.loading && !actionItemList.error;
  const rows = isReady ? actionItemList.items.map<Row>(itemToRow).filter(isNotNil) : [];

  const getSelectedPost = () => {
    if (!location.query.postURLName) {
      return;
    }

    const potentialPosts = actionItemList.items
      .map((item) => {
        if (item.source === 'canny' && item.duplicatePost) {
          return [item.sourcePost, item.duplicatePost];
        }

        if (item.duplicatePost) {
          return item.duplicatePost;
        }

        if (item.createdPost) {
          return item.createdPost;
        }

        return null;
      })
      .flat()
      .filter(isNotNil);

    return potentialPosts.find((post) => post.urlName === location.query.postURLName);
  };

  const selectedPost = getSelectedPost();
  const closePost = () =>
    router.replace({
      pathname: location.pathname,
      query: { ...location.query, postURLName: undefined },
    });

  if (isReady && !rows.length) {
    return (
      <div className="adminQueuePage empty">
        <AutopilotNUX company={company} counts={actionItemCounts} />
      </div>
    );
  }

  return (
    <div className="adminQueuePage">
      <SelectableDataTable<Row>
        fullHeight
        className="adminQueueTable"
        cellAlignment="middle"
        loading={actionItemList.loading}
        error={actionItemList.error}
        padding="small"
        rounded={false}
        pagination={{
          hasMore: actionItemList.hasMore,
          loading: actionItemList.loadingMore,
          onLoadMore: () =>
            loadMoreItems(
              actionItemList.queryParams,
              actionItemList.pages ? actionItemList.pages + 1 : 2
            ),
        }}
        // TODO: implement keyboard shortcuts
        hotkeys={{}}
        columns={columns}
        rows={rows}
      />
      {selectedPost && (
        <ModalPortal
          allowBodyScroll
          closeOnClickAway={false}
          allowEscape
          className={classnames('adminQueueDrawer', 'postModal', 'modalVisible')}
          onClose={closePost}>
          <div className="modalContents">
            <RoadmapPostContainer boards={boards} post={selectedPost}>
              <AdminFeedbackPost
                key={selectedPost?._id}
                customPostFields={customPostFields}
                onClose={closePost}
                onDelete={() => {
                  if (!selectedPost || !selectedPost.board) {
                    return;
                  }

                  removePost(selectedPost._id, selectedPost.board);
                  closePost();
                }}
                onEdited={() => {
                  if (!selectedPost) {
                    return;
                  }

                  reloadPostActivity(selectedPost);
                  closePost();
                }}
                roadmaps={roadmaps}
                skipFixURL={true}
              />
            </RoadmapPostContainer>
          </div>
        </ModalPortal>
      )}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  deletePostDraft: () => {
    return dispatch(deletePostDraft());
  },
  loadMoreItems: (queryParams: QueryParams, pages: number) => {
    return dispatch(loadMoreActionItems(queryParams, pages));
  },
  savePostDraft: (postDraft: PostModalDraft) => {
    return dispatch(savePostDraft(postDraft));
  },
  reloadPostActivity: (post: ReloadPostActivityPost) => {
    return Promise.all([dispatch(reloadPostActivity(post)), dispatch(reloadPost(post))]);
  },
  removeItem: (item: ActionItem) => {
    return dispatch(removeItem(item));
  },
  removePost: (postID: string, board: Board) => {
    return Promise.all([
      dispatch(reloadBoard(board.urlName)),
      dispatch(invalidateDashboardActivity()),
      dispatch(invalidateRoadmap()),
      dispatch(invalidateSuggestions()),
      dispatch(removePostFromAllRoadmaps({ _id: postID })),
      dispatch(invalidatePostQueries()),
    ]);
  },
}))(AdminQueueAuditLog) as unknown as React.FC<OwnProps>;

import { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import loadScript from 'common/util/loadScript';
import withContexts from 'common/util/withContexts';

import cloneElementWithProps from '../core/cloneElementWithProps';

const OrgID = '64D8H';

function loadFullStorySDK() {
  window['_fs_debug'] = false;
  window['_fs_host'] = 'fullstory.com';
  window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
  window['_fs_org'] = OrgID;
  window['_fs_namespace'] = 'FS';
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    g = m[e] = function (a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    loadScript('https://edge.fullstory.com/s/fs.js', 'fullstory-script', {
      attributes: {
        crossorigin: 'anonymous',
      },
    });
    g.identify = function (i, v, s) {
      g(l, { uid: i }, s);
      if (v) {
        g(l, v, s);
      }
    };
    g.setUserVars = function (v, s) {
      g(l, v, s);
    };
    g.event = function (i, v, s) {
      g('event', { n: i, p: v }, s);
    };
    g.anonymize = function () {
      g.identify(!!0);
    };
    g.shutdown = function () {
      g('rec', !1);
    };
    g.restart = function () {
      g('rec', !0);
    };
    g.log = function (a, b) {
      g('log', [a, b]);
    };
    g.consent = function (a) {
      g('consent', !arguments.length || a);
    };
    g.identifyAccount = function (i, v) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function () {};
    g.setVars = function (n, p) {
      g('setVars', [n, p]);
    };
    g._w = {};
    y = 'XMLHttpRequest';
    g._w[y] = m[y];
    y = 'fetch';
    g._w[y] = m[y];
    if (m[y]) {
      m[y] = function () {
        return g._w[y].apply(this, arguments);
      };
    }
    g._v = '1.3.0';
  })(window, document, window['_fs_namespace'], 'script', 'user');
}

class FullStoryContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    viewer: PropTypes.shape({
      _id: PropTypes.string,
      companies: PropTypes.array,
      created: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  componentDidMount() {
    if (__DEV__) {
      return;
    }

    const { company, viewer } = this.props;
    if (
      !company ||
      !company._id ||
      !company.isQ ||
      !company.viewerIsMember ||
      !viewer ||
      !viewer._id
    ) {
      return;
    }

    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const didJustSignUp = new Date(company.created) > oneWeekAgo;
    if (!didJustSignUp) {
      return;
    }

    // only record on pages with FullStoryContainer
    loadFullStorySDK();

    window.FS.identify(viewer._id, {
      displayName: viewer.name,
      email: viewer.email,
    });
  }

  render() {
    const newProps = { ...this.props };
    delete newProps.company;
    delete newProps.viewer;

    return cloneElementWithProps(this.props.children, newProps);
  }
}

export default withContexts({
  company: CompanyContext,
  viewer: ViewerContext,
})(FullStoryContainer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Tooltip from 'common/common/Tooltip';
import UserAvatar from 'common/user/UserAvatar';

export default class AdminRoadmapOwnerCell extends Component {
  static propTypes = {
    user: PropTypes.shape({
      avatarURL: PropTypes.string,
      name: PropTypes.string,
      urlName: PropTypes.string,
    }),
  };

  render() {
    const { user } = this.props;
    if (!user) {
      return null;
    }

    return (
      <Tooltip position="top" value={user.name}>
        <UserAvatar user={user} />
      </Tooltip>
    );
  }
}

import React from 'react';

import classNames from 'classnames';
import { Check } from 'lucide-react';

import 'css/components/_CheckboxV2.scss';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
  size: 'small' | 'medium' | 'large';
  onChange?: (checked: boolean) => void;
  'aria-label': string;
}

const CheckboxV2 = ({
  'aria-label': ariaLabel,
  children,
  checked,
  onChange,
  className,
  size = 'medium',
  disabled,
  ...props
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <span className={classNames('checkboxV2', className, { [size]: true })}>
      <input
        {...props}
        className="checkboxV2Input"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        aria-label={ariaLabel}
      />
      <span className="checkboxBackground"></span>
      <Check className="checkedIcon" />
    </span>
  );
};

export default CheckboxV2;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import LazyLoadedImage from 'common/LazyLoadedImage';
import AdminCCModal from 'common/subdomain/admin/billing/AdminCCModal';
import Tappable from 'common/Tappable';
import Facepile from 'common/user/Facepile';
import getCannyOrigin from 'common/util/getCannyOrigin';
import getFirstName from 'common/util/getFirstName';
import getMembersWithPermission from 'common/util/getMembersWithPermission';
import hasPermission from 'common/util/hasPermission';
import isFree, { FreePlanID } from 'common/util/isFree';
import isGrowth, { GrowthAnnualPlanID, GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterAnnualPlanID, StarterPlanID } from 'common/util/isStarter';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import GoodIdea from 'img/good-idea.png';

import 'css/components/subdomain/admin/_AdminFeatureUpsell.scss';

const GrowthFeatures = [
  <>
    <span className="bold">Unlimited</span> roadmaps
  </>,
  'Custom access',
  'Custom statuses',
  <>
    <span className="bold">Unlimited</span> integrations
  </>,
  'Internal comments',
  'Zapier integration',
  'Private comments',
  'Block new posts',
  'Chrome extension',
  'Roadmap sharing',
  'User segmentation',
  'SOC 2 report',
];

const StarterFeatures = [
  <>
    <span className="bold">Unlimited</span> active posts
  </>,
  <>
    <span className="bold">Unlimited</span> admins
  </>,
  <>
    <span className="bold">More</span> integrations
  </>,
  <>
    <span className="bold">More</span> boards
  </>,
  'Custom domain',
  'Internal comments',
  'Prioritization roadmaps',
  'Content translations',
  'Chat support',
];

const FreeFeatures = [
  <>
    <span className="bold">Private</span> boards
  </>,
  <>
    <span className="bold">Autopilot</span> feedback extraction
  </>,
  'Autopilot deduplication',
  'Prioritized support',
];

const GrowthUpsellOpenKey = 'growth_upsell_open';
const GrowthUpsellSuccessKey = 'growth_upsell_success';
const StarterUpsellOpenKey = 'starter_upsell_open';
const StarterUpsellSuccessKey = 'starter_upsell_success';

class AdminFeatureUpsell extends Component {
  static propTypes = {
    company: PropTypes.object,
    cta: PropTypes.string,
    feature: PropTypes.string,
    onClose: PropTypes.func,
    onUpsell: PropTypes.func,
    planID: PropTypes.oneOf([
      FreePlanID,
      GrowthPlanID,
      GrowthAnnualPlanID,
      StarterPlanID,
      StarterAnnualPlanID,
    ]),
    reloadCompany: PropTypes.func,
    router: PropTypes.object,

    viewer: PropTypes.shape({
      email: PropTypes.string,
    }),
  };

  static defaultProps = {
    planID: GrowthPlanID,
  };

  state = {
    error: '',
    loading: false,
    showCCModal: false,
    showUpgradeModal: false,
  };

  componentDidMount() {
    const planUpsellOpenKey = isStarter(this.props.planID)
      ? StarterUpsellOpenKey
      : GrowthUpsellOpenKey;
    this.onLog(planUpsellOpenKey);
  }

  shouldOfferTrial = () => {
    const { company, planID } = this.props;
    const { billingData, planTrial, trialingPlan } = company;
    const planExpired = !billingData?.plan?.planID;
    const onFree = isFree(billingData?.plan?.planID);
    const onStarter = isStarter(billingData?.plan?.planID);

    if (isFree(planID)) {
      return false;
    }

    if (isStarter(planID)) {
      const onGrowthTrial = isGrowth(trialingPlan?.planID);
      const didStarterTrial = isStarter(planTrial?.planID);
      return !onGrowthTrial && !didStarterTrial && (planExpired || onFree);
    }

    const didGrowthTrial = isGrowth(planTrial?.planID);
    return !didGrowthTrial && (planExpired || onFree || onStarter);
  };

  onCloseCCModal = async () => {
    this.setState({ showCCModal: false });
  };

  onSuccessCCModal = async () => {
    await this.props.reloadCompany();
    this.setState({ showCCModal: false });
  };

  onLog = (key) => {
    const { feature } = this.props;
    return AJAX.post('/api/analytics/log', {
      key,
      data: {
        feature,
      },
    });
  };

  onUpgrade = async () => {
    const { company, onAddCard, planID, router } = this.props;
    const { billingData } = company;
    // only admins with billing permission can upgrade, so we can assume billingData.hasCard exists
    const { hasCard } = billingData;

    this.setState({
      error: null,
      upgrading: true,
    });

    const toFree = isFree(planID);
    if (!toFree && !hasCard) {
      if (onAddCard) {
        onAddCard();
      } else {
        this.setState({ showCCModal: true });
      }
      return;
    }

    const response = await AJAX.post('/api/billing/changePlan', { planID });
    if (toFree && response !== 'success') {
      router.push('/admin/settings/billing');
      return;
    }

    if (response !== 'success') {
      this.setState({
        error: 'Something went wrong, please contact our team for support.',
        upgrading: false,
      });
      return;
    }
    await this.props.reloadCompany();
    this.setState({
      upgrading: false,
    });
  };

  onUpsell = async () => {
    const { planID } = this.props;

    this.setState({
      error: '',
      loading: true,
    });

    const planUpsellSuccessKey = isStarter(planID)
      ? StarterUpsellSuccessKey
      : GrowthUpsellSuccessKey;
    await this.onLog(planUpsellSuccessKey);
    const response = await AJAX.post('/api/billing/startPlanTrial', { planID });
    if (response !== 'success') {
      this.setState({
        error: 'Something went wrong, please contact our team for support.',
        loading: false,
      });
      return;
    }

    await this.props.reloadCompany();

    this.setState({ loading: false });

    await this.props.onUpsell?.();
  };

  renderHeader() {
    const { planID } = this.props;

    const cta = this.props.cta || 'Take your user feedback to the next level';

    let pill;
    if (isGrowth(planID)) {
      pill = 'Canny Growth';
    } else if (isStarter(planID)) {
      pill = 'Canny Starter';
    } else if (isFree(planID)) {
      pill = 'Canny Free w/ Autopilot';
    }

    return (
      <div className="header">
        <LazyLoadedImage className="goodIdea" src={GoodIdea} />
        <div className="words">
          <Pill style={DefaultPillStyles.purpleBackground}>{pill}</Pill>
          <div className="title">{cta}</div>
        </div>
        {this.props.onClose && (
          <Tappable onTap={this.props.onClose}>
            <div className="icon-x" />
          </Tappable>
        )}
      </div>
    );
  }

  renderBody() {
    const { planID } = this.props;

    let features;
    if (isGrowth(planID)) {
      features = GrowthFeatures;
    } else if (isStarter(planID)) {
      features = StarterFeatures;
    } else if (isFree(planID)) {
      features = FreeFeatures;
    }

    return (
      <div className="body">
        <div className="words">Plus, get deeper insights...</div>
        <div className="features">
          {features.map((feature, index) => (
            // Index is okay for the key because the feature list is static
            <div className="feature" key={index}>
              <span className="icon-check" />
              <span className="label">{feature}</span>
            </div>
          ))}
        </div>
        <a href="https://canny.io/pricing">See pricing page</a>
      </div>
    );
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderFooter() {
    const { company, planID, viewer } = this.props;
    const showAdmins = !hasPermission('manageBilling', company, viewer);
    const planName = isStarter(planID) ? 'Starter' : 'Growth';

    if (showAdmins) {
      const admins = getMembersWithPermission('manageBilling', company);
      const adminEmails = admins.map((admin) => admin.email).join(',');
      const link =
        'mailto:' +
        adminEmails +
        queryString.stringify({
          subject: 'Canny – Upgrading our plan',
          body: `
Hi Canny administrators,

I'd like to request that we upgrade our Canny account to the ${planName} plan. You can visit the following link to start a 14-day trial of the Canny ${planName} plan here:
${getCannyOrigin(company)}/admin/settings/billing

Thanks,
${getFirstName(viewer.name)}`.trim(),
        });
      return (
        <div className="footer noAccess">
          <Facepile className="footerFaces" users={admins} />
          <div className="helpText">Ask an Owner of your team to start a {planName} trial</div>
          <a href={link} rel="noopener" target="_blank">
            <Button
              buttonType="cannyButton"
              className="growthButton"
              component="div"
              tint={true}
              value="Email owners"
            />
          </a>
        </div>
      );
    }

    return (
      <div className="footer">
        <div className="cta">
          <div className="icon icon-x" />
          <div className="words">
            {this.shouldOfferTrial() && (
              <div className="title">Try {planName} Free for 14 days</div>
            )}
          </div>
          {this.renderButton()}
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }

  renderButton() {
    const { planID } = this.props;

    if (this.shouldOfferTrial()) {
      return (
        <Button
          buttonType="cannyButton"
          className="growthButton"
          loading={this.state.loading}
          onTap={this.onUpsell}
          tint={true}
          value="Start Trial"
        />
      );
    }

    let cta;
    if (isGrowth(planID)) {
      cta = 'Upgrade to Growth';
    } else if (isStarter(planID)) {
      cta = 'Upgrade to Starter';
    } else if (isFree(planID)) {
      cta = 'Get Autopilot';
    }

    return (
      <Button
        buttonType="cannyButton"
        loading={this.state.loading}
        onTap={this.onUpgrade}
        tint={true}
        value={cta}
      />
    );
  }

  renderCreditCardModal() {
    if (!this.state.showCCModal) {
      return;
    }

    return <AdminCCModal onClose={this.onCloseCCModal} onSuccess={this.onSuccessCCModal} />;
  }

  render() {
    return (
      <div className="adminFeatureUpsell">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
        {this.renderCreditCardModal()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      router: RouterContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminFeatureUpsell);

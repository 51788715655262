import React from 'react';

import classNames from 'classnames';

import { type CommonSelectProps } from 'common/ui/common/select/SelectCommon';
import 'css/components/_LabelWrapper.scss';

type Props = Pick<CommonSelectProps, 'labelPlacement'> & {
  children: React.ReactNode;
};

const LabelWrapper = ({ children, labelPlacement }: Props) => {
  return (
    <div
      className={classNames('labelWrapper', {
        verticalPlacement: labelPlacement === 'top',
      })}>
      {children}
    </div>
  );
};
export default LabelWrapper;

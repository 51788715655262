import React, { Component } from 'react';

import classnames from 'classnames';
import { Building, RefreshCw, User } from 'lucide-react';
import PropTypes from 'prop-types';

import Portal from 'common/common/Portal';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import TextInput from 'common/inputs/TextInput';
import LazyLoadedImage from 'common/LazyLoadedImage';
import {
  CalculationFieldTypes,
  CalculationFunctions,
  CalculationOpportunityFactors,
  CalculationStaticFactors,
  CalculationVoteFactors,
} from 'common/prioritization/CalculationFactorTypes';
import ImpactFactorTypes from 'common/prioritization/ImpactFactorTypes';
import Tappable from 'common/Tappable';
import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';
import withContexts from 'common/util/withContexts';

import HubspotLogo from 'img/hubspot-logo-small.png';
import SalesforceLogo from 'img/salesforce-logo-small.png';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapFactorRow.scss';

const TypeOptions = [
  {
    name: 'numberToTen',
    render: (effort) => (effort ? '1-10' : '0-10'),
  },
  {
    name: 'numberToOneHundred',
    render: (effort) => (effort ? '1-100' : '0-100'),
  },
  {
    name: 'fibonacci',
    render: () => 'Fibonacci',
  },
  {
    name: 'stars',
    render: () => 'Stars',
  },
  {
    name: 'checkbox',
    render: () => 'Checkbox',
  },
  {
    name: 'percentage',
    render: () => 'Percentage',
  },
];

class AdminRoadmapFactorRow extends Component {
  static propTypes = {
    company: PropTypes.shape({
      activeIntegrations: PropTypes.shape({
        hubspot: PropTypes.bool,
        salesforce: PropTypes.bool,
      }),
    }).isRequired,
    deletable: PropTypes.bool,
    deleteFactor: PropTypes.func,
    effortType: PropTypes.bool,
    factor: PropTypes.shape({
      _id: PropTypes.string,
      calculation: PropTypes.shape({
        field: PropTypes.string,
        function: PropTypes.oneOf(Object.values(CalculationFunctions)),
        fieldType: PropTypes.oneOf(Object.values(CalculationFieldTypes)),
      }),
      deleted: PropTypes.bool,
      name: PropTypes.string,
      type: PropTypes.string,
      weight: PropTypes.number,
    }),
    onFocus: PropTypes.func,
    updateFactor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    effortType: false,
  };

  state = {
    focusedField: null,
  };

  constructor(props, context) {
    super(props, context);

    this.weightRef = React.createRef();
    this.nameRef = React.createRef();
  }

  deleteFactor = () => {
    const { deleteFactor, factor } = this.props;
    return deleteFactor(factor);
  };

  updateFactor = (factorFields) => {
    const { factor, updateFactor } = this.props;
    return updateFactor(factor, factorFields);
  };

  onBlur = () => {
    this.setState({ focusedField: null });
  };

  onFocus = (field) => {
    const { factor, onFocus } = this.props;

    this.setState({ focusedField: field });

    if (onFocus && field === 'name') {
      onFocus(factor);
    }
  };

  renderFactorIcon() {
    const { company, factor } = this.props;
    if (factor.type !== ImpactFactorTypes.calculation.name) {
      return null;
    }

    const isUserField =
      factor.calculation.fieldType === CalculationFieldTypes.customField &&
      company.customFields.user.some(
        (customField) => customField._id === factor.calculation.fieldID
      );
    const isCompanyField =
      factor.calculation.fieldType === CalculationFieldTypes.customField &&
      company.customFields.company.some(
        (customField) => customField._id === factor.calculation.fieldID
      );
    if (isUserField) {
      return <User className="icon" />;
    } else if (isCompanyField) {
      return <Building className="icon" />;
    } else if (factor.calculation.field in CalculationOpportunityFactors) {
      if (company.activeIntegrations.hubspot && company.activeIntegrations.salesforce) {
        return (
          <div className="severalIcons">
            <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />
            <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />
          </div>
        );
      } else if (company.activeIntegrations.hubspot) {
        return <LazyLoadedImage alt="HubSpot logo" className="icon" src={HubspotLogo} />;
      } else if (company.activeIntegrations.salesforce) {
        return <LazyLoadedImage alt="Salesforce logo" className="icon" src={SalesforceLogo} />;
      }

      return <RefreshCw className="icon" />;
    } else if (factor.calculation.field in CalculationStaticFactors) {
      return <Building className="icon" />;
    }

    return null;
  }

  renderCalcSelector = (functionName) => {
    const { deletable, factor } = this.props;
    if (factor.type !== ImpactFactorTypes.calculation.name) {
      return null;
    }

    const options =
      factor.calculation.field in CalculationVoteFactors
        ? [
            {
              name: CalculationFunctions.count,
              render: capitalizeFirstLetter(CalculationFunctions.count),
            },
          ]
        : Object.values(CalculationFunctions).map((functionName) => ({
            name: functionName,
            render: capitalizeFirstLetter(functionName),
          }));

    return (
      <ControlledDropdown
        disabled={options.length === 1 || !deletable}
        dropdownClassName="adminRoadmapFactorRow dropdown"
        options={options}
        onChange={(option) => this.updateFactor({ calculation: { function: option } })}
        selectedName={functionName}
      />
    );
  };

  renderSegmentSelector = (segmentID) => {
    const { company, deletable, factor } = this.props;
    const isSegmentable =
      factor.type === ImpactFactorTypes.calculation.name &&
      !(factor.calculation?.field in CalculationOpportunityFactors);
    if (!isSegmentable) {
      return null;
    }

    const options = Object.values(company.segments)
      .map((segment) => ({
        name: segment._id,
        render: segment.name,
      }))
      .concat({
        name: 'everyone',
        render: 'Everyone',
      });

    return (
      <ControlledDropdown
        disabled={!deletable}
        dropdownClassName="adminRoadmapFactorRow dropdown"
        options={options}
        onChange={(option) => {
          this.updateFactor({ calculation: { segmentID: option === 'everyone' ? null : option } });
        }}
        selectedName={segmentID}
      />
    );
  };

  renderTypeSelector = (type) => {
    const { deletable, effortType, factor } = this.props;
    if (type === ImpactFactorTypes.calculation.name) {
      return <div>Number</div>;
    }

    let options = TypeOptions.map((option) => {
      return {
        name: option.name,
        render: option.render(factor.effort),
      };
    });

    if (effortType) {
      const invalidTypes = ['checkbox', 'percentage'];
      options = options.filter((option) => !invalidTypes.includes(option.name));
    }

    return (
      <ControlledDropdown
        disabled={!!factor._id || !deletable}
        dropdownClassName="adminRoadmapFactorRow dropdown"
        options={options}
        onChange={(option) => this.updateFactor({ type: option })}
        selectedName={type}
      />
    );
  };

  renderError = () => {
    const { focusedField } = this.state;
    const { factor } = this.props;

    if (focusedField === 'weight' && factor.errors?.weight) {
      return (
        <Portal
          align="center"
          className="adminRoadmapFactorRow"
          position="bottom"
          relativeToRef={this.weightRef}>
          <div className="cellErrorMessage">Weight should be between 0 and 100</div>
        </Portal>
      );
    }

    if (focusedField === 'name' && factor.errors?.name) {
      return (
        <Portal
          align="center"
          className="adminRoadmapFactorRow"
          position="bottom"
          relativeToRef={this.nameRef}>
          <div className="cellErrorMessage">Name should be unique and between 1 and 30 chars</div>
        </Portal>
      );
    }
  };

  render() {
    const { deletable, effortType, factor } = this.props;
    if (factor.deleted) {
      return null;
    }

    const isWeightDisabled = !deletable || factor.type === 'percentage';
    const weightValue = Number.isInteger(factor.weight) ? String(factor.weight) : undefined;

    return (
      <div className="adminRoadmapFactorRow row container">
        <div
          className={classnames('cell name', { cellError: factor.errors?.name })}
          ref={this.nameRef}>
          <TextInput
            className="nameInput"
            maxLength={29}
            onBlur={this.onBlur}
            onChange={(e) => this.updateFactor({ name: e.target.value })}
            onFocus={() => this.onFocus('name')}
            placeholder={factor._id ? '' : 'Add new...'}
            value={factor.name}
            suffix={this.renderFactorIcon()}
          />
        </div>
        <div className="cell type">{this.renderTypeSelector(factor.type)}</div>
        {!effortType && (
          <>
            <div
              className={classnames('cell segment', {
                disabled:
                  factor.type !== ImpactFactorTypes.calculation.name ||
                  factor.calculation?.field in CalculationOpportunityFactors,
              })}>
              {this.renderSegmentSelector(factor.calculation?.segmentID ?? 'everyone')}
            </div>
            <div
              className={classnames('cell calc', {
                disabled: factor.type !== ImpactFactorTypes.calculation.name,
              })}>
              {this.renderCalcSelector(factor.calculation?.function)}
            </div>
            <div
              className={classnames('cell weight', { cellError: factor.errors?.weight })}
              ref={this.weightRef}>
              {factor.type === 'percentage' ? (
                <Tooltip position="top" value="Percentage type has no weight">
                  {weightValue}
                </Tooltip>
              ) : (
                <TextInput
                  disabled={isWeightDisabled}
                  maxLength={3}
                  onBlur={this.onBlur}
                  onChange={(e) =>
                    this.updateFactor({
                      weight: e.target.value.trim() ? Number(e.target.value) : undefined,
                    })
                  }
                  onFocus={() => this.onFocus('weight')}
                  value={weightValue}
                />
              )}
            </div>
          </>
        )}
        {effortType && (
          <div
            className={classnames('cell weight', { cellError: factor.errors?.weight })}
            ref={this.weightRef}>
            <TextInput
              disabled={isWeightDisabled}
              maxLength={3}
              onBlur={this.onBlur}
              onChange={(e) =>
                this.updateFactor({
                  weight: e.target.value.trim() ? Number(e.target.value) : undefined,
                })
              }
              onFocus={() => this.onFocus('weight')}
              value={weightValue}
            />
          </div>
        )}
        {deletable && (
          <span className="delete">
            <Tappable onTap={this.deleteFactor}>
              <div className="icon icon-x" />
            </Tappable>
          </span>
        )}
        {this.renderError()}
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(AdminRoadmapFactorRow);

import { Component } from 'react';

import { compose } from 'redux';

import { loadRoadmapPosts } from 'common/actions/roadmapPosts';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ParamsContext } from 'common/containers/RouterContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { getPostQueryKey, getQueryFilterParams } from 'common/util/filterPosts';
import withContexts from 'common/util/withContexts';

const EmptyRoadmapPosts = { posts: [] };
const asyncFetch = {
  key: 'asyncRoadmapPosts',
  promise: ({ store: { dispatch, getState }, location, params }) => {
    const state = getState();
    const { roadmaps } = state;
    const roadmap = roadmaps?.roadmaps?.find(
      (roadmap) => roadmap.urlName === params.roadmapURLName
    );

    const board = state.boards ? state.boards.items[params.boardURLName] : null;

    const company = state.company;

    if (!company) {
      return;
    }

    return dispatch(loadRoadmapPosts(getQueryFilterParams(board, company, location, {}, roadmap)));
  },
};

class RoadmapPostsContainer extends Component {
  static asyncConnect = asyncFetch;

  render() {
    const {
      company,
      children,
      location,
      roadmap,
      roadmapPosts,
      roadmapPostsLoading,
      boards,
      params,
    } = this.props;
    const board = boards.items[params.boardURLName];

    const postQueryKey = getPostQueryKey(
      getQueryFilterParams(board, company, location, {}, roadmap)
    );
    return cloneElementWithProps(children, {
      ...this.props,
      roadmapPosts: roadmapPosts[postQueryKey] ?? EmptyRoadmapPosts,
      roadmapPostsLoading,
    });
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({
    boards: state.boards,
    roadmapPosts: state.roadmapPosts,
    roadmapPostsLoading: state.reduxAsyncConnect.loadState?.asyncRoadmapPosts?.loading ?? false,
  })),
  withContexts(
    {
      company: CompanyContext,
      params: ParamsContext,
    },
    {
      forwardRef: true,
    }
  )
)(RoadmapPostsContainer);

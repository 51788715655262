function convertTextToHTML(text) {
  const lines = text.split('\n');
  let html = '';
  let inList = false;
  let listType = '';
  let inCodeBlock = false;

  lines.forEach((line) => {
    const trimmedLine = line.trim();

    // Check for headers (e.g., # Header, ## Subheader)
    if (/^#{1,3}\s/.test(trimmedLine)) {
      const headerLevel = trimmedLine.match(/^#+/)[0].length;
      html += `<h${headerLevel}>${convertTextToHTML(
        trimmedLine.slice(headerLevel + 1).trim()
      )}</h${headerLevel}>`;
    }
    // Check for bullet list items
    else if (trimmedLine.startsWith('- ') || trimmedLine.startsWith('* ')) {
      if (listType !== 'ul') {
        if (inList) {
          html += `</${listType}>`;
        }
        html += '<ul>';
        listType = 'ul';
        inList = true;
      }
      html += `<li>${convertTextToHTML(trimmedLine.slice(2).trim())}</li>`;
    } else if (trimmedLine.startsWith('```')) {
      if (inCodeBlock) {
        // Close the code block
        html += '</code></pre>';
        inCodeBlock = false;
      } else {
        // Open a new code block
        html += '<pre><code>';
        inCodeBlock = true;
      }
    }
    // Check for numbered list items
    else if (/^\d+\.\s/.test(trimmedLine)) {
      if (listType !== 'ol') {
        if (inList) {
          html += `</${listType}>`;
        }
        html += '<ol>';
        listType = 'ol';
        inList = true;
      }
      html += `<li>${convertTextToHTML(trimmedLine.replace(/^\d+\.\s/, '').trim())}</li>`;
    }
    // Check for line quote
    else if (trimmedLine.startsWith('>')) {
      html += `<blockquote>${trimmedLine.slice(1).trim()}</blockquote>`;
    }
    // Check for horizontal lines (--- or ***)
    else if (/^(-{3,}|(\* ){3,})$/.test(trimmedLine)) {
      html += '<hr>';
    }
    // Convert **bold**, *italic*, [link](url), and ![alt](image_url)
    else {
      if (inList) {
        html += `</${listType}>`;
        inList = false;
        listType = '';
      }

      let formattedLine = trimmedLine
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
        .replace(/\*(.*?)\*/g, '<em>$1</em>') // Italic
        .replace(/!\[(.*?)\]\((.*?)\)/g, '<img alt="$1" src="$2">') // Images
        .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>'); // Links

      // Detect inline code (single backticks)
      formattedLine = formattedLine.replace(/`(.*?)`/g, '<code>$1</code>');

      if (inCodeBlock) {
        html += `${trimmedLine}\n`;
      } else {
        if (trimmedLine.length) {
          html += `<p>${formattedLine}</p>`;
        } else {
          html += `<br/>`;
        }
      }
    }
  });

  if (inList) {
    html += `</${listType}>`;
  }

  if (inCodeBlock) {
    // Close the code block
    html += '</code></pre>';
  }

  return html;
}

export default convertTextToHTML;

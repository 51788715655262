import React from 'react';

import classNames from 'classnames';

import 'css/components/_Label.scss';

type LabelPlacement = 'right' | 'left' | 'top' | 'bottom';

type Size = 'small' | 'medium' | 'large';
export interface Props extends Omit<React.HtmlHTMLAttributes<HTMLLabelElement>, 'size'> {
  children: React.ReactNode;
  size?: Size;
  label: React.ReactNode;
  labelPlacement?: LabelPlacement;
  suffix?: React.ReactNode;
}

const Label = React.forwardRef<HTMLLabelElement, Props>(
  (
    {
      children,
      className,
      label,
      labelPlacement = 'left',
      size = 'medium',
      suffix,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <label
        className={classNames('labelV2', className, {
          [size]: true,
          verticalPlacement: labelPlacement === 'top' || labelPlacement === 'bottom',
          swap: labelPlacement === 'right' || labelPlacement === 'bottom',
        })}
        ref={ref}
        {...props}>
        <span className="labelText">
          {label}
          {suffix}
        </span>
        <span className="labelElement">{children}</span>
      </label>
    );
  }
);

export default Label;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CannyAttribution from 'common/CannyAttribution';

import SubdomainChangelogEntryHelmet from './SubdomainChangelogEntryHelmet';
import ChangelogEntry from '../../changelog/ChangelogEntry';
import ContentContainer from '../../containers/ContentContainer';
import Link from '../../Link';
import Spinner from '../../Spinner';

import 'css/components/subdomain/public/_SubdomainChangelogEntry.scss';

export default class SubdomainChangelogEntry extends Component {
  static propTypes = {
    changelog: PropTypes.object,
    entry: PropTypes.object,
  };

  renderContents() {
    const { changelog, entry } = this.props;

    if (entry.error === 'not published') {
      return <div className="notFound">This entry no longer exists</div>;
    } else if (entry.error) {
      return <div className="error">Something went wrong, please try again later.</div>;
    } else if (entry.loading) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    } else if (entry.notFound) {
      return <div className="notFound">There is no such entry.</div>;
    }

    return (
      <ChangelogEntry changelog={changelog} entry={entry} key="EntryView" showFooter logsView />
    );
  }

  renderSidebar() {
    return (
      <div className="sidebar">
        <Link className="backLink" to="/changelog">
          <div className="arrow">&larr;</div>
          Back to changelog
        </Link>
        <CannyAttribution desktopOnly={true} source="changelog_subdomain" />
      </div>
    );
  }

  render() {
    const { entry } = this.props;
    return (
      <ContentContainer innerClassName="subdomainChangelogEntry">
        <SubdomainChangelogEntryHelmet entry={entry} />
        {this.renderSidebar()}
        {this.renderContents()}
      </ContentContainer>
    );
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { CustomPostFieldsContext } from 'common/containers/CustomPostFieldsContainer';
import Link from 'common/Link';
import Markdown from 'common/markdown/Markdown';
import { MarkdownStagesNameMap } from 'common/markdown/MarkdownStages';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import mapify from 'common/util/mapify';
import withContexts from 'common/util/withContexts';

import 'css/components/post/_CustomPostFields.scss';

class CustomPostFields extends Component {
  static propTypes = {
    board: PropTypes.object,
    className: PropTypes.string,
    company: PropTypes.object,
    customPostFields: PropTypes.object,
    includeAllFields: PropTypes.bool,
    post: PropTypes.object,
    showCustomPostConfiguration: PropTypes.bool,
  };

  static defaultProps = {
    includeAllFields: false,
  };

  state = {
    collapsed: false,
  };

  onToggleCollapse = () => {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  getRenderablePostFields = () => {
    // we should only render the fields that are in the "create post" form.
    const {
      board: { boardFields = [] },
      customPostFields: { data: allCustomPostFields },
      includeAllFields,
      post: { customPostFields = [] },
    } = this.props;

    const boardFieldMap = mapify(boardFields, 'customPostFieldID');
    const allCustomPostFieldsMap = mapify(allCustomPostFields ?? [], '_id');

    return customPostFields
      .map((field) => {
        const fieldID = field.customPostFieldID ?? field._id;
        const boardField = boardFieldMap[fieldID];
        const postField = allCustomPostFieldsMap[fieldID];

        const isNotInBoard = !includeAllFields && !boardField;
        if (isNotInBoard || !postField) {
          return null;
        }

        return {
          ...field,
          label: boardField?.label ?? postField?.name ?? '',
          value: field.value || '—',
        };
      })
      .filter(Boolean);
  };

  renderFields(customPostFields) {
    const { collapsed } = this.state;
    if (collapsed) {
      return null;
    }

    return (
      <div className="postFieldList">
        {customPostFields.map((customPostField) => {
          const value = Array.isArray(customPostField.value)
            ? customPostField.value.join(', ')
            : `${customPostField.value}`;
          return (
            <div className="postField" key={customPostField._id}>
              <div className="label">{customPostField.label}</div>
              <div className="content">
                <Markdown
                  contents={value}
                  stages={[MarkdownStagesNameMap.line, MarkdownStagesNameMap.url]}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { board, className, company, showCustomPostConfiguration } = this.props;
    const { collapsed } = this.state;

    const customPostFields = this.getRenderablePostFields();
    const doesPlanSupport = company.features?.customPostFields;
    const hasFields = !!customPostFields?.length;
    if (!doesPlanSupport || !hasFields) {
      return null;
    }

    return (
      <div className={classnames('customPostFields', className)}>
        <div className="header">
          <Tappable onTap={this.onToggleCollapse}>
            <button className="collapsibleSection">
              <UppercaseHeader className="left">Post Fields</UppercaseHeader>{' '}
              {collapsed ? (
                <span className="icon icon-chevron-down" />
              ) : (
                <span className="icon icon-chevron-up" />
              )}
            </button>
          </Tappable>
          {showCustomPostConfiguration ? (
            <UppercaseHeader className="left">
              <Link
                className="configureLink"
                to={`/admin/settings/boards/${board.urlName}/create-form`}>
                Configure
              </Link>
            </UppercaseHeader>
          ) : null}
        </div>
        {this.renderFields(customPostFields)}
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext, customPostFields: CustomPostFieldsContext })(
  CustomPostFields
);

// taken from http://stackoverflow.com/questions/1068834/object-comparison-in-javascript

export default function areObjectsEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
    return false;
  }
  if (obj1.constructor !== obj2.constructor) {
    return false;
  }
  for (const key in obj1) {
    if (!obj1.hasOwnProperty(key)) {
      continue;
    }
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (obj1[key] === obj2[key]) {
      continue;
    }
    if (typeof obj1[key] !== 'object') {
      return false;
    }
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
}

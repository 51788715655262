import React, { useState } from 'react';

import TextInput from 'common/inputs/TextInput';
import ModernModal from 'common/modals/ModernModal';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import Label from 'common/ui/common/Label';
import { Span } from 'common/ui/Text';
import validateInput from 'common/validateInput';
import styles from 'css-module/components/subdomain/admin/AdminMicrosoftTeamsSettings/_ChannelConnectionModal.module.scss';

import type { MicrosoftTeamsWorkflow } from 'common/api/endpoints/microsoftTeamsWorkflows';

type ChannelConnectionModalProps = {
  onSave: (workflow: Pick<MicrosoftTeamsWorkflow, 'name' | 'webhookURL'>) => void;
  onCancel: () => void;
};

const ChannelConnectionModal = ({ onSave, onCancel }: ChannelConnectionModalProps) => {
  const [name, setName] = useState('');
  const [webhookURL, setWebhookURL] = useState('');
  const [dirty, setDirty] = useState(false);

  const isNameValid = validateInput.microsoftTeams.name(name);
  const isWebhookURLValid = validateInput.microsoftTeams.webhookURL(webhookURL);
  const isFormValid = isNameValid && isWebhookURLValid;
  return (
    <ModernModal
      onClose={onCancel}
      header="New Teams channel connection"
      sections={[
        <div key="form" className={styles.channelForm}>
          <Label label="Channel name" labelPlacement="top">
            <TextInput
              className={styles.channelField}
              name="name"
              placeholder="General"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Label>
          <Label label="Webhook URL" labelPlacement="top">
            <TextInput
              className={styles.channelField}
              name="webhookURL"
              placeholder="https://example.logic.azure.com/workflows/.../triggers/..."
              value={webhookURL}
              onChange={(e) => setWebhookURL(e.target.value)}
            />
          </Label>
          <Alert
            type={AlertTypes.Info}
            className={styles.alert}
            headingText="Where do I get the webhook URL?"
            subText={
              <Span>
                <a
                  className={styles.link}
                  href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=dotnet"
                  target="_blank"
                  rel="noopener">
                  Read our guide
                </a>{' '}
                to find out how to get the webhook URL for your&nbsp;channel.
              </Span>
            }
          />
          {dirty && !isFormValid && (
            <Alert
              type={AlertTypes.Danger}
              headingText="Some of your fields are not valid"
              subText="Please, make sure the channel name is between 1 and 100 characters and the webhook URL is valid."
              className={styles.alert}
            />
          )}
        </div>,
      ]}
      footer={
        <ButtonV2
          size="medium"
          onClick={() => {
            setDirty(true);
            if (isFormValid) {
              onSave({ name, webhookURL });
            }
          }}>
          Save channel connection
        </ButtonV2>
      }
    />
  );
};

export default ChannelConnectionModal;

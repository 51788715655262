import React, { Component } from 'react';

import PropTypes from 'prop-types';

import DateRangePicker from 'common/common/DateRangePicker';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import CheckboxInput from 'common/inputs/CheckboxInput';
import RadioButtonGroup from 'common/inputs/RadioButtonGroup';
import AccessModal from 'common/modals/AccessModal';
import SavedSegmentsModal from 'common/modals/SavedSegmentsModal';
import UpsellModal from 'common/modals/UpsellModal';
import AdminPageSidebar from 'common/subdomain/admin/AdminPageSidebar';
import AdminSidebarSectionItem from 'common/subdomain/admin/AdminSidebarSectionItem';
import Tappable from 'common/Tappable';
import { RelativeDateRanges } from 'common/util/dateRanges';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';

import AdminSidebarSection from './AdminSidebarSection';

import 'css/components/subdomain/admin/_AdminUsersSidebar.scss';

const Sorts = {
  lastActivity: {
    name: 'lastActivity',
    render: 'Last Activity',
  },
  topPosters: {
    name: 'topPosters',
    render: 'Top Posters',
  },
  topVoters: {
    name: 'topVoters',
    render: 'Top Voters',
  },
};

const ActivityFilters = {
  posts: {
    render: 'Posts',
  },
  votes: {
    render: 'Votes',
  },
  comments: {
    render: 'Comments',
  },
};

function parseQueryDateRange(dateRange) {
  if (!dateRange) {
    return RelativeDateRanges.allTime;
  }
  const dateRangeArr = dateRange.split('_');

  if (dateRangeArr.length === 1) {
    return dateRangeArr[0];
  }

  return dateRangeArr;
}

function outputQueryDateRange(dateRange) {
  if (!dateRange) {
    return undefined;
  }

  if (typeof dateRange === 'string') {
    if (dateRange === RelativeDateRanges.allTime) {
      return undefined;
    }
    return [dateRange];
  }

  return dateRange.join('_');
}

class AdminUsersSidebar extends Component {
  static propTypes = {
    company: PropTypes.shape({
      segments: PropTypes.array,
    }),
    location: PropTypes.object,
    openModal: PropTypes.func.isRequired,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    afterUpsell: null,
    segment: this.props.location.query.segment,
    showUpsellModal: false,
    dateRange: parseQueryDateRange(this.props.location.query.dateRange),
  };

  constructor(props, context) {
    super(props, context);

    this.activityRefs = {};
    Object.keys(ActivityFilters).forEach((activityName) => {
      this.activityRefs[activityName] = React.createRef();
    });
    this.segmentRef = React.createRef();
    this.sortRef = React.createRef();
  }

  componentDidMount() {
    this.updateQuery();
  }

  changeSegment = (segment) => {
    this.setState({ segment }, this.updateQuery);
  };

  manageSegments = () => {
    const { boards, company, openModal, viewer } = this.props;
    const viewerHasPermission = hasPermission('manageUserSegmentation', company, viewer);
    if (!viewerHasPermission) {
      openModal(AccessModal, {
        requiredPermissions: ['manageUserSegmentation'],
      });
      return;
    }

    openModal(SavedSegmentsModal, {
      boards,
      onUpdateQuery: this.updateQuery,
    });
  };

  updateQuery = () => {
    const {
      company: { segments },
      location,
      router,
    } = this.props;
    const { segment, dateRange } = this.state;
    const sort = this.sortRef.current ? this.sortRef.current.getValue().name : undefined;
    const segmentObject = segments.find(({ urlName }) => urlName === segment);

    const filters = [];
    const allFilters = Object.keys(ActivityFilters);
    allFilters.forEach((name) => {
      const ref = this.activityRefs[name].current;
      if (ref && ref.getValue()) {
        filters.push(name);
      }
    });

    router.replace({
      ...location,
      query: {
        ...location.query,
        filter: (filters.length < allFilters.length && filters.join('-')) || undefined,
        segment: (segmentObject && segment) || undefined,
        sort: sort !== Sorts.lastActivity.name ? sort : undefined,
        dateRange: outputQueryDateRange(dateRange),
      },
    });
  };

  onManageSegments = async () => {
    const { features } = this.props.company;
    const planSupports = features?.userSegmentation;
    if (!planSupports) {
      this.setState({
        afterUpsell: this.manageSegments,
        showUpsellModal: true,
      });
      return;
    }

    this.manageSegments();
  };

  onSegmentChange = async (segment) => {
    const { features } = this.props.company;
    const planSupports = features?.userSegmentation;
    if (!planSupports) {
      this.setState({
        afterUpsell: () => this.changeSegment(segment),
        showUpsellModal: true,
      });
      return;
    }

    this.changeSegment(segment);
  };

  onDateRangeSelected = (e) => {
    this.setState(
      {
        dateRange: e.relativeDate ?? e.dateRange,
      },
      this.updateQuery
    );
  };

  onSortChange = () => {
    this.updateQuery();
  };

  onActivityChange = () => {
    this.updateQuery();
  };

  onUpsell = () => {
    const { afterUpsell } = this.state;
    this.setState({
      afterUpsell: null,
      showUpsellModal: false,
    });
    afterUpsell();
  };

  onUpsellDismiss = () => {
    this.setState({
      afterUpsell: null,
      showUpsellModal: false,
    });
  };

  renderSegmentSection() {
    const {
      company: { segments },
    } = this.props;
    const segmentOptions = [
      { name: 'everyone', render: 'Everyone (default)' },
      ...segments.map(({ name, urlName }) => ({ name: urlName, render: name })),
    ];
    const segment = segments.find(({ urlName }) => urlName === this.state.segment);
    return (
      <AdminSidebarSection
        action={
          <Tappable onTap={this.onManageSegments}>
            <div className="icon icon-gear" />
          </Tappable>
        }
        className="segmentSection"
        title="User Segment">
        <ControlledDropdown
          className="segmentDropdown"
          dropdownClassName="adminBoardSidebarSegmentDropdown"
          ref={this.segmentRef}
          onChange={this.onSegmentChange}
          options={segmentOptions}
          selectedName={(segment && segment.urlName) || 'everyone'}
        />
      </AdminSidebarSection>
    );
  }

  renderSorts() {
    const buttons = [Sorts.lastActivity, Sorts.topPosters, Sorts.topVoters];

    return (
      <AdminSidebarSection title="Sort">
        <RadioButtonGroup
          buttons={buttons}
          defaultSelectedName={this.props.location.query.sort}
          onChange={this.onSortChange}
          ref={this.sortRef}
        />
      </AdminSidebarSection>
    );
  }

  renderActivityFilter() {
    const {
      location: { query },
    } = this.props;
    return (
      <AdminSidebarSection title="Activity">
        {Object.keys(ActivityFilters).map((name) => {
          const isChecked = query.filter ? query.filter.includes(name) : true;
          return (
            <AdminSidebarSectionItem key={name}>
              <CheckboxInput
                defaultChecked={isChecked}
                label={ActivityFilters[name].render}
                onChange={this.onActivityChange}
                ref={this.activityRefs[name]}
              />
            </AdminSidebarSectionItem>
          );
        })}
      </AdminSidebarSection>
    );
  }

  renderDateRangeFilter() {
    return (
      <AdminSidebarSection title="Date Range">
        <DateRangePicker
          align="start"
          date={this.state.dateRange}
          onSubmit={this.onDateRangeSelected}
        />
      </AdminSidebarSection>
    );
  }

  render() {
    return (
      <div className="adminUsersSidebar">
        <AdminPageSidebar showSidebar={true}>
          {this.renderSegmentSection()}
          {this.renderDateRangeFilter()}
          {this.renderSorts()}
          {this.renderActivityFilter()}
          <UpsellModal
            cta="Filter feedback to user groups you care&nbsp;about"
            feature="userSegmentation"
            onClose={this.onUpsellDismiss}
            onUpsell={this.onUpsell}
            show={this.state.showUpsellModal}
          />
        </AdminPageSidebar>
      </div>
    );
  }
}

export default withContexts({
  boards: BoardsContext,
  company: CompanyContext,
  location: LocationContext,
  openModal: OpenModalContext,
  router: RouterContext,
  viewer: ViewerContext,
})(AdminUsersSidebar);

import React, { useContext } from 'react';

import { X } from 'lucide-react';

import { CloseModalContext } from 'common/containers/ModalContainer';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H2 } from 'common/ui/Text';

type Props = {
  isBrowserExtension: boolean;
};

const AdminCreatePostHeader = ({ isBrowserExtension }: Props) => {
  const closeModal = useContext(CloseModalContext);

  return (
    <div className="headerSection">
      <H2 variant="headingMd">Create Post</H2>
      {!isBrowserExtension && (
        <IconButtonV2
          size="large"
          onClick={closeModal}
          icon={X}
          variant="plain"
          className="closeModalButton"
          aria-label="Close create post modal"
        />
      )}
    </div>
  );
};

export default AdminCreatePostHeader;

import React, { useContext, useEffect, useRef, useState } from 'react';

import Error from 'common/common/Error';
import { LocationContext } from 'common/containers/RouterContainer';
import Timestamp from 'common/Timestamp';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';

import ChangelogEntry from './ChangelogEntry';
import ChangelogEntryListLoadMore from './ChangelogEntryListLoadMore';
import ChangelogNoEntries from './ChangelogNoEntries';

import type {
  Changelog,
  ChangelogEntry as ChangelogEntryType,
} from 'common/api/resources/changelog';

import 'css/components/changelog/_ChangelogEntryList.scss';

type Props = {
  changelog: Changelog | null;
  // TODO: Move type to ChangelogEntryListContainer once that's moved to typescript
  entryList: {
    loading: boolean;
    error: boolean;
    notFound: boolean;
    entries: Array<ChangelogEntryType> | undefined;
  };
  logView: boolean;
  showEditLinks: boolean;
  showUnpublished: boolean;
};

const ChangelogEntryList = (props: Props) => {
  const { changelog, entryList, logView, showEditLinks, showUnpublished } = props;
  const loadMoreRef = useRef<any>(null);
  const location = useContext(LocationContext);
  const [collapsedEntryIDs, setCollapsedEntryIDs] = useState<Record<string, boolean>>({});
  const { query } = location;
  const { notFound, error, entries } = entryList;

  useEffect(() => {
    const onScroll = (e: Event) => {
      const loadMore = loadMoreRef.current && loadMoreRef.current.wrappedInstance;
      if (loadMore) {
        loadMore.onScroll(e);
      }
    };

    document.addEventListener('scroll', onScroll, false);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (error) {
    return (
      <div className="changelogEntryList">
        <div className="top">
          <Error />
        </div>
      </div>
    );
  } else if (notFound || !entries || entries.length === 0 || !changelog) {
    const hasActiveFilters = query.labels || query.status || query.type || query.search;
    return (
      <div className="changelogEntryList">
        <ChangelogNoEntries
          search={decodeQueryTextParam(query.search)}
          hasActiveFilters={!!hasActiveFilters}
          isAdminView={false}
        />
      </div>
    );
  }

  const publishedSortedEntries = entries
    .filter((entry) => showUnpublished || entry.status === 'published')
    .sort((a, b) => {
      const aPublished = a.status === 'published' && a.publishedAt;
      const bPublished = b.status === 'published' && b.publishedAt;

      if (aPublished && bPublished) {
        // safe to cast, as we are checking the dates exist first
        return (
          new Date(b.publishedAt as string).getTime() - new Date(a.publishedAt as string).getTime()
        );
      } else if (!aPublished && !bPublished) {
        return new Date(b.lastSaved).getTime() - new Date(a.lastSaved).getTime();
      } else {
        return aPublished ? 1 : -1;
      }
    });

  return (
    <div className="changelogEntryList">
      <div className="entries">
        {publishedSortedEntries.map((entry) => {
          return (
            <>
              <div className="divider" />
              <div className="changelogEntryContainer" key={entry._id}>
                <div className="changelogEntryMetadata">
                  <Timestamp
                    className="timestamp"
                    showRelative={false}
                    timestamp={entry.publishedAt}
                  />
                </div>
                <ChangelogEntry
                  changelog={changelog}
                  entry={entry}
                  collapsed={collapsedEntryIDs[entry._id]}
                  onToggleCollapse={(collapsed) => {
                    setCollapsedEntryIDs({
                      ...collapsedEntryIDs,
                      [entry._id]: collapsed,
                    });
                  }}
                  showFooter
                  showEditLink={showEditLinks}
                  isCollapsible
                  logsView={logView}
                />
              </div>
            </>
          );
        })}
        <ChangelogEntryListLoadMore ref={loadMoreRef} entryList={entryList} />
      </div>
    </div>
  );
};

export default ChangelogEntryList;

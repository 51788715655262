import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import ModalPortal from 'common/modals/ModalPortal';
import Months from 'common/Months';
import Spinner from 'common/Spinner';
import Strings from 'common/Strings';
import Tappable from 'common/Tappable';

import 'css/components/modals/_InvoicesModal.scss';

export default class InvoicesModal extends Component {
  static propTypes = {
    company: PropTypes.object,
    invoices: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          amount: PropTypes.number,
          created: PropTypes.number,
          pdf: PropTypes.string,
        })
      ),
      loading: PropTypes.bool,
    }),
  };

  state = {
    areInvoicesLoading: false,
    error: null,
    invoices: null,
  };

  componentDidMount() {
    this.fetchInvoices();
  }

  getDateString = (number) => {
    const date = new Date(number);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${Months[monthIndex]}, ${year}`;
  };

  getDollarsFromCents = (cents) => {
    return cents / 100;
  };

  fetchInvoices = async () => {
    try {
      this.setState({ areInvoicesLoading: true });
      const result = await AJAX.post('/api/billing/getInvoices', {
        companyID: this.props.company._id,
      });
      const { invoices } = JSON.parse(result);
      this.setState({ areInvoicesLoading: false, invoices });
    } catch (err) {
      this.setState({ areInvoicesLoading: false, error: Strings.miscError });
    }
  };

  renderContents() {
    const { areInvoicesLoading, error, invoices } = this.state;

    if (error) {
      return (
        <div className="errorContainer">
          <div className="error">{error}</div>
        </div>
      );
    }

    if (!invoices || areInvoicesLoading) {
      return (
        <div className="spinnerContainer">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="invoicesTable">
        <div className="invoicesHeader">
          <div className="dateHeader">invoice date</div>
          <div className="amountHeader">amount</div>
          <div className="downloadLinkHeader">download</div>
        </div>
        <div>
          {invoices.map((invoice) => (
            <div key={invoice._id} className="invoicesInfo">
              <div className="dateInfo">{this.getDateString(invoice.created)}</div>
              <div className="amountInfo">${this.getDollarsFromCents(invoice.amount)}</div>
              <div className="downloadLinkInfo">
                <a href={invoice.pdf} rel="noopener" target="_blank">
                  <span className="icon-download" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <ModalPortal
        className="invoicesModalPortal"
        closeOnClickAway={true}
        onClose={this.props.onClose}>
        <div className="invoicesModal">
          <div className="header">
            <div className="title">Invoice History</div>
            <Tappable onTap={this.props.onClose}>
              <div className="exitButton icon icon-x" />
            </Tappable>
          </div>
          {this.renderContents()}
        </div>
      </ModalPortal>
    );
  }
}

export default function createError(name) {
  return class CustomError extends Error {
    constructor(data, ...params) {
      super(...params);
      Error.captureStackTrace?.(this, CustomError);
      this.name = name;

      if (typeof data === 'string') {
        this.message = data;
        return;
      }

      this.data = data || {};
      this.message = data?.message || 'Something went wrong';
    }
  };
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import translateString from 'common/i18n/translateString';

export default class PostTitle extends Component {
  static propTypes = {
    post: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const { post } = this.props;
    return (
      <div className="postTitle">
        <span>{translateString(post, 'title')}</span>
      </div>
    );
  }
}

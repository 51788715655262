import React, { Component } from 'react';

import { Plus } from 'lucide-react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Dropdown from 'common/Dropdown';
import PostSearchDropdown from 'common/post/PostSearchDropdown';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import Tappable from 'common/Tappable';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapAddPost.scss';

class AdminRoadmapAddPost extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.object,
    createRoadmapPost: PropTypes.func,
    reloadCompany: PropTypes.func,
    roadmapPosts: PropTypes.arrayOf(
      PropTypes.shape({
        post: PropTypes.shape({
          _id: PropTypes.string,
        }),
      })
    ),
  };

  state = {
    addingPost: false,
    error: null,
    selectedBoard: this.getDefaultBoard(),
    submittingNewPost: false,
  };

  addPostToRoadmap = async (post) => {
    this.setState({ addingPost: false, submittingNewPost: false });
    await this.props.createRoadmapPost(post);
    this.setState({ addingPost: true });
  };

  getDefaultBoard() {
    const { boards, company } = this.props;
    if (!boards || boards.length === 0) {
      return {};
    }

    const lastUsedBoardID = company?.viewerPreferences?.lastUsedRoadmapCreationBoard;
    if (lastUsedBoardID) {
      const board = boards.find((board) => board._id === lastUsedBoardID);
      if (board) {
        return board;
      }
    }

    // Default to the board with the most posts.
    return boards.slice(1).reduce((prev, curr) => {
      return prev.postCount > curr.postCount ? prev : curr;
    }, boards[0]);
  }

  stopAddingPost = () => {
    this.setState({ addingPost: false });
  };

  onAddPost = () => {
    this.setState({
      addingPost: true,
      error: null,
    });
  };

  onBoardSelected = async (boardURLName) => {
    const board = this.props.boards.find((board) => board.urlName === boardURLName);
    this.setState({ selectedBoard: board });
    await AJAX.post('/api/viewer/updatePreferences', {
      preferences: {
        lastUsedRoadmapCreationBoard: board._id,
      },
    });
    await this.props.reloadCompany();
  };

  onSearchBlur = (e, searchValue) => {
    if (searchValue || (e && findDOMNode(this).contains(e.target))) {
      return;
    }
    this.stopAddingPost();
  };

  onCreateNewPost = async (postTitle) => {
    const { selectedBoard, submittingNewPost } = this.state;
    if (submittingNewPost || !postTitle.trim()) {
      return;
    }
    this.setState({
      error: null,
      submittingNewPost: true,
    });

    const responseJSON = await AJAX.post('/api/posts/create', {
      boardID: selectedBoard._id,
      details: '',
      fileURLs: JSON.stringify([]),
      imageURLs: JSON.stringify([]),
      title: postTitle,
    });

    const { error, parsedResponse } = parseAPIResponse(responseJSON, {
      isSuccessful: (parsedResponse) => parsedResponse.post,
      errors: {
        'slow down':
          'You are trying to create posts too fast. Please wait a few minutes before trying again.',
      },
    });

    if (error) {
      this.setState({
        addingPost: false,
        error: error.message,
        submittingNewPost: false,
      });
      return;
    }

    await this.addPostToRoadmap(parsedResponse.post);
  };

  renderBoardDropdown() {
    const { boards } = this.props;

    return (
      <div className="boardPicker">
        in
        <div className="boardDropdown">
          <Dropdown
            defaultSelectedName={this.state.selectedBoard.urlName}
            dropdownClassName="adminRoadmapAddPostBoardDropdownPortal"
            onChange={this.onBoardSelected}
            options={boards.map((board) => ({
              name: board.urlName,
              render: board.name.replace(/ /g, '\u00a0'),
            }))}
          />
        </div>
      </div>
    );
  }

  render() {
    const { roadmapPosts } = this.props;
    const { addingPost, error } = this.state;

    const postsToExclude = roadmapPosts.map((roadmapPost) => roadmapPost.post);
    const dropdownPosition = roadmapPosts.length > 5 ? 'top' : 'bottom';

    if (addingPost) {
      return (
        <div className="adminRoadmapAddPost" data-cell-key="add-row-cell">
          <Tappable onTap={this.stopAddingPost}>
            <div className="deletePost icon icon-x" />
          </Tappable>
          <PostSearchDropdown
            allowCreateNewPost={true}
            dropdownClassName="adminRoadmapAddPostSuggestionsPortal"
            dropdownPosition={dropdownPosition}
            excludePosts={postsToExclude}
            onClickAway={this.onSearchBlur}
            onCreatePost={this.onCreateNewPost}
            onLinkPost={this.addPostToRoadmap}
            showRecentlyCompleted={false}
            statusTypes={[PostStatusTypes.Initial, PostStatusTypes.Active]}
          />
          {this.renderBoardDropdown()}
        </div>
      );
    }

    return (
      <Tappable onTap={this.onAddPost}>
        <div className="adminRoadmapAddPost" data-cell-key="add-row-cell">
          <div className="iconContainer">
            <Plus size={14} />
          </div>
          {error && <div className="error">{error}</div>}
          {!error && <div className="label">Add new post</div>}
        </div>
      </Tappable>
    );
  }
}

export default compose(
  connect(
    null,
    (dispatch) => ({
      reloadCompany: () => dispatch(reloadCompany()),
    }),
    { withRef: true }
  ),
  withContexts({ company: CompanyContext }, { forwardRef: true })
)(AdminRoadmapAddPost);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import Tappable from 'common/Tappable';

import 'css/components/subdomain/admin/_AdminGitHubIssues.scss';

class AdminGitHubIssues extends Component {
  static propTypes = {
    post: PropTypes.object,
  };

  state = {
    removing: false,
  };

  onRemoveIssue = (issue) => {
    const { removing } = this.state;
    if (removing) {
      return;
    }

    this.setState({
      removing: true,
    });

    const { post, reloadPost } = this.props;
    AJAX.post(
      '/api/github/unlinkIssue',
      {
        issueLinkID: issue.issueLinkID,
      },
      (response) => {
        if (response === 'success') {
          reloadPost(post).then(() => {
            this.setState({
              removing: false,
            });
          });
        } else {
          this.setState({
            removing: false,
          });
        }
      }
    );
  };

  renderIssues() {
    const {
      post: { githubIssues },
    } = this.props;

    const items = [];
    githubIssues.forEach((issue) => {
      const remove = (
        <Tappable onTap={this.onRemoveIssue.bind(this, issue)}>
          <div className="icon icon-x" />
        </Tappable>
      );
      items.push(
        <div key={issue.id} className="issue">
          <a
            href={issue.url}
            rel="noopener"
            target="_blank">{`#${issue.number}: ${issue.title}`}</a>
          {remove}
        </div>
      );
    });

    return items;
  }

  render() {
    const {
      post: { githubIssues },
    } = this.props;
    if (!githubIssues || !githubIssues.length) {
      return null;
    }

    return <div className="adminGitHubIssues">{this.renderIssues()}</div>;
  }
}

export default connect(null, (dispatch) => ({
  reloadPost: (post) => {
    return Promise.all([dispatch(invalidatePostQueries()), dispatch(reloadPost(post))]);
  },
}))(AdminGitHubIssues);

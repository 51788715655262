import Data from 'common/Data';

// Action Types

export const InvalidateReactions = 'canny/user_reactions/invalidate_reactions';
export const ReactionsLoaded = 'canny/user_reactions/reactions_loaded';
export const ReactionsError = 'canny/user_reactions/reactions_error';
export const RequestReactions = 'canny/user_reactions/request_reactions';

// Actions

function requestReactions(objectID, reactionName) {
  return {
    objectID,
    reactionName,
    timestamp: Date.now(),
    type: RequestReactions,
  };
}

function reactionsLoaded(objectID, reactionName, reactions) {
  return {
    objectID,
    reactionName,
    reactions,
    timestamp: Date.now(),
    type: ReactionsLoaded,
  };
}

function reactionsError(objectID, reactionName, error) {
  return {
    objectID,
    reactionName,
    error,
    timestamp: Date.now(),
    type: ReactionsError,
  };
}

export function invalidate(objectID, reactionName) {
  return {
    objectID,
    reactionName,
    timestamp: Date.now(),
    type: InvalidateReactions,
  };
}

// Action Creators

function fetchReactions(objectID, objectType, reactionName) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        userReactions: {
          input: {
            objectID,
            objectType,
            reactionName,
          },
          query: Data.queries.userReactions,
        },
      },
      cookies,
      (error, data) => {
        const { userReactions } = getState();
        const reactions = userReactions[objectID]?.[reactionName];

        if (reactions) {
          if (error) {
            dispatch(reactionsError(objectID, reactionName, error));
          } else {
            dispatch(reactionsLoaded(objectID, reactionName, data.userReactions));
          }
        } else {
          // if reactions were invalidated while fetching we need to try again
          dispatch(requestReactions(objectID, reactionName));
          return dispatch(fetchReactions(objectID, objectType, reactionName));
        }
      }
    );
  };
}

export function invalidateReactions(objectID, reactionName) {
  return (dispatch) => {
    return dispatch(invalidate(objectID, reactionName));
  };
}

export function loadReactions(objectID, objectType, reactionName) {
  return (dispatch, getState) => {
    if (shouldFetchReactions(getState(), objectID, reactionName)) {
      dispatch(requestReactions(objectID, reactionName));
      return dispatch(fetchReactions(objectID, objectType, reactionName));
    }
  };
}

function shouldFetchReactions(state, objectID, reactionName) {
  if (!objectID) {
    return false;
  }

  const { userReactions } = state;
  return !userReactions[objectID]?.[reactionName];
}

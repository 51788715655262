import React, { Component } from 'react';

import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadPost } from 'common/actions/posts';
import { loadMorePostActivity, loadPostActivity } from 'common/actions/postsActivity';
import AJAX from 'common/AJAX';
import Comment from 'common/comment/Comment';
import LoadMore from 'common/common/LoadMore';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Dropdown from 'common/Dropdown';
import AccessModal from 'common/modals/AccessModal';
import PostMerge from 'common/post/PostMerge';
import PostStatusChange from 'common/post/PostStatusChange';
import { SummaryErrors, shouldRenderSummarizeButton } from 'common/post/Summary/utils';
import delayer from 'common/util/delayer';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import AdminHeader from './AdminHeader';
import { getFilterCount, segmentDefaultValue } from './FiltersDropdown';

import 'css/components/post/PostActivity/_index.scss';

const RequestDelay = 300;

const viewTypes = {
  admin: 'admin',
  public: 'public',
};

class PostActivity extends Component {
  static propTypes = {
    allowUnmerge: PropTypes.bool,
    board: PropTypes.object,
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    post: PropTypes.object,
    postActivity: PropTypes.shape({
      activities: PropTypes.array,
      comments: PropTypes.object,
      mergedPosts: PropTypes.object,
      statusHistories: PropTypes.object,
      options: PropTypes.shape({
        limit: PropTypes.number,
        internalComments: PropTypes.oneOf(['allowed', 'none', 'only']),
        sort: PropTypes.string,
      }),
    }).isRequired,
    viewType: PropTypes.oneOf([viewTypes.admin, viewTypes.public]),
    viewer: PropTypes.object,
  };

  static defaultProps = {
    allowUnmerge: false,
  };

  state = {
    exporting: false,
    filterLoading: false,
    limit: 10,
    onlyInternalComments: false,
    search: '',
    segment: segmentDefaultValue,
    showHeader: false,
    showOptionsMenu: false,
    sort: 'newest',
    summarizeLoading: false,
  };

  dropdownButtonRef = React.createRef();
  loadMoreRef = React.createRef();
  requestDelayer = new delayer(this.requestActivity.bind(this), RequestDelay);

  componentDidMount() {
    this.showHeader();
  }

  componentDidUpdate() {
    if (this.state.filterLoading && !this.props.postActivity.loading) {
      this.setState({ filterLoading: false });
    }

    this.showHeader();
  }

  showHeader = () => {
    const { postActivity } = this.props;
    const { onlyInternalComments, search, segment, showHeader } = this.state;

    if (showHeader) {
      return;
    }

    const hasFilters = !!getFilterCount({ onlyInternalComments, segment }) || !!search;
    if (hasFilters || postActivity?.activities?.length) {
      this.setState({ showHeader: true });
    }
  };

  onSummarize = async () => {
    const { company, openModal, viewer, post, showToast, reloadPost } = this.props;

    const permission = 'summarizeComments';
    if (!hasPermission(permission, company, viewer)) {
      openModal(AccessModal, { requiredPermissions: [permission] });
      return;
    }

    this.setState({ summarizeLoading: true });

    const response = await AJAX.post('/api/comments/summary/create', {
      postID: post._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: SummaryErrors,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      this.setState({ summarizeLoading: false });
      return;
    }

    await reloadPost(post);
    this.setState({ summarizeLoading: false });
  };

  onCommentExport = async () => {
    const { post } = this.props;

    this.setState({ exporting: true });

    const response = await AJAX.post('/api/posts/exportComments', {
      postID: post._id,
    });

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.csv,
    });

    if (error) {
      this.setState({ error: error.message, exporting: false });
      return;
    }

    const { csv } = parsedResponse;

    const currentDateString = new Date().toISOString().slice(0, 10);
    const csvName = `Canny - ${post.title} - comments - ${currentDateString}.csv`;

    saveAs(new Blob([csv]), csvName);

    this.setState({ exporting: false });
  };

  onDropdownBlur = () => {
    if (!this.state.showOptionsMenu) {
      return;
    }
    this.setState({ showOptionsMenu: false });
  };

  onLoadMore = () => {
    this.props.loadMore(this.props.post);
  };

  onScroll = (e, scrollContainer) => {
    const loadMore = this.loadMoreRef.current;
    if (!loadMore) {
      return;
    }

    loadMore.onScroll(e, scrollContainer);
  };

  onFiltersChange = ({ onlyInternalComments, search, segment, sort }) => {
    this.setState({
      filterLoading: true,
      onlyInternalComments,
      search,
      segment,
      sort,
    });

    this.requestDelayer.callAfterDelay();
  };

  requestActivity() {
    const { loadActivity, post, postActivity, viewType } = this.props;
    const { onlyInternalComments, search, segment, sort } = this.state;

    let internalCommentFilter = 'none';
    if (viewType === viewTypes.admin) {
      internalCommentFilter = onlyInternalComments ? 'only' : 'allowed';
    }

    loadActivity(post, {
      limit: postActivity.options.limit,
      internalComments: internalCommentFilter,
      text: search !== '' ? search : undefined,
      segmentURLName: segment !== segmentDefaultValue ? segment : undefined,
      sort,
    });
  }

  renderLoadMore() {
    const { postActivity } = this.props;
    const { hasMore, loading } = postActivity;
    return (
      <LoadMore
        hasMore={hasMore}
        loadingMore={loading}
        onLoadMore={this.onLoadMore}
        ref={this.loadMoreRef}
      />
    );
  }

  renderAdminHeader() {
    const { company, post, viewer } = this.props;
    const { filterLoading, onlyInternalComments, search, segment, sort, summarizeLoading } =
      this.state;

    const isSummarizable = shouldRenderSummarizeButton(post, company);

    return (
      <AdminHeader
        canExportComments={
          company.features?.exportComments && hasPermission('exportData', company, viewer)
        }
        canSearch={company.features?.commentSearch}
        canSummarize={isSummarizable}
        filterLoading={filterLoading}
        filters={{ onlyInternalComments, search, segment, sort }}
        onExportComments={this.onCommentExport}
        onFiltersChange={this.onFiltersChange.bind(this)}
        onSummarize={this.onSummarize}
        summarizeLoading={summarizeLoading}
      />
    );
  }

  renderEmptyState() {
    const { filterLoading, onlyInternalComments, search, segment } = this.state;
    const filterCount = getFilterCount({ onlyInternalComments, segment });
    if (filterLoading) {
      return null;
    }

    let emptyMessage = 'There are no comments on this post.';
    if (search !== '') {
      emptyMessage = `We couldn't find any results matching "${search}".`;
    } else if (filterCount !== 0) {
      emptyMessage = `We couldn't find any results matching the filters.`;
    }

    return <p className="emptyMessage activityList">{emptyMessage}</p>;
  }

  renderPublicHeader() {
    const { onlyInternalComments, sort } = this.state;
    return (
      <div className="publicHeader">
        <div className="left">Activity Feed</div>
        <div className="right">
          <span className="sortBy">Sort by</span>
          <Dropdown
            defaultSelectedName={sort}
            onChange={(newSort) => this.onFiltersChange({ sort: newSort, onlyInternalComments })}
            options={[
              {
                name: 'newest',
                render: 'Newest first',
              },
              {
                name: 'oldest',
                render: 'Oldest first',
              },
            ]}
          />
        </div>
      </div>
    );
  }

  renderList() {
    const { board, post, postActivity, viewType } = this.props;
    const { activities, comments, mergedPosts, statusHistories } = postActivity;

    const replyMap = Object.values(comments).reduce((prev, curr) => {
      const { internal, parentID } = curr;
      if (!parentID || (internal && viewType === viewTypes.public)) {
        return prev;
      }

      return {
        ...prev,
        [parentID]: [...(prev[parentID] || []), curr],
      };
    }, {});

    const list = [];
    activities.forEach((activity, i) => {
      const { id, type } = activity;
      if (type === 'comment') {
        const comment = comments[id];
        const replies = replyMap[id];
        const { internal } = comment;
        if (internal && viewType === viewTypes.public) {
          return;
        }
        list.push(
          <Comment key={id} board={board} comment={comment} post={post} replies={replies} />
        );
      } else if (type === 'mergedPost') {
        const mergedPost = mergedPosts[id];
        list.push(
          <PostMerge
            allowUnmerge={this.props.allowUnmerge}
            board={board}
            key={id}
            merge={mergedPost}
            post={post}
          />
        );
      } else if (type === 'statusHistory') {
        const statusChange = statusHistories[id];
        const comment = comments[statusChange.commentID];
        const replies = comment ? replyMap[comment._id] : [];
        list.push(
          <PostStatusChange
            key={id}
            board={board}
            comment={comment}
            post={post}
            statusChange={statusChange}
            replies={replies}
          />
        );
      }
    });

    return <div className="activityList">{list}</div>;
  }

  render() {
    const { postActivity, viewType } = this.props;
    let isEmpty = false;
    if (!postActivity || !postActivity.activities || !postActivity.activities.length) {
      isEmpty = true;
    }

    const { activities = [], comments } = postActivity;
    const isAdminView = viewType === viewTypes.admin;

    const visibleActivities = activities.filter((activity) => {
      if (isAdminView) {
        return true;
      } else if (activity.type !== 'comment') {
        return true;
      } else if (!comments[activity.id].internal) {
        return true;
      }
      return false;
    });

    if (!visibleActivities.length) {
      isEmpty = true;
    }

    return (
      <div className="postActivity">
        {isAdminView
          ? this.state.showHeader && this.renderAdminHeader()
          : this.state.showHeader && this.renderPublicHeader()}
        {isEmpty ? this.state.showHeader && this.renderEmptyState() : this.renderList()}
        {this.renderLoadMore()}
      </div>
    );
  }
}

export default compose(
  withContexts(
    {
      company: CompanyContext,
      viewer: ViewerContext,
      showToast: ShowToastContext,
      openModal: OpenModalContext,
    },
    { forwardRef: true }
  ),
  connect(
    null,
    (dispatch) => ({
      loadActivity: (post, options) => {
        return dispatch(loadPostActivity(post, options));
      },
      loadMore: (post) => {
        return dispatch(loadMorePostActivity(post));
      },
      reloadPost: (post) => dispatch(reloadPost(post)),
    }),
    { withRef: true }
  )
)(PostActivity);

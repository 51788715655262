import React from 'react';

import { ChevronLeft, ChevronRight } from 'lucide-react';

import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import styles from 'css-module/components/subdomain/admin/reports/_Pagination.module.scss';

type Props = {
  page: number;
  count: number;
  onPageChange: (page: number) => void;
};

const PagesToRender = 5;

const Pagination = ({ page: activePage, count, onPageChange }: Props) => {
  if (count <= 1) {
    return null;
  }

  const minPageBase = Math.max(1, activePage - Math.floor(PagesToRender / 2));
  const maxPageBase = Math.min(activePage + Math.floor(PagesToRender / 2), count);

  // adjust first and last pages
  let extraPages = PagesToRender - (maxPageBase - minPageBase + 1);
  const maxPage = Math.min(maxPageBase + extraPages, count);
  extraPages = PagesToRender - (maxPage - minPageBase + 1);
  const minPage = Math.max(1, minPageBase - extraPages);

  const pages = Array.from({ length: maxPage - minPage + 1 }, (_, idx) => minPage + idx);
  return (
    <div className={styles.pagination}>
      {activePage !== 1 && (
        <IconButtonV2
          size="medium"
          onClick={() => onPageChange(activePage - 1)}
          icon={ChevronLeft}
          variant="plain"
          aria-label="Previous page"
        />
      )}
      {pages.map((page) => (
        <ButtonV2
          aria-label={`Go to page ${page}`}
          size="medium"
          onClick={() => onPageChange(page)}
          key={page}
          variant={activePage === page ? 'outlined' : 'ghost'}>
          {page}
        </ButtonV2>
      ))}
      {activePage !== count && (
        <IconButtonV2
          size="medium"
          onClick={() => onPageChange(activePage + 1)}
          icon={ChevronRight}
          variant="plain"
          aria-label="Next page"
        />
      )}
    </div>
  );
};

export default Pagination;

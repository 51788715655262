import React, { useContext, useState } from 'react';

import { CornerDownRight } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import TextToggle from 'common/common/TextToggle';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Link from 'common/Link';
import { CannyAnnouncement } from 'common/modals/CannyAnnouncementModal';
import withAccessControl from 'common/routing/withAccessControl';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelectWithSearch from 'common/ui/SingleSelectWithSearch';
import SwitchV2 from 'common/ui/SwitchV2';
import { H1, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import AutopilotAnnouncement from 'img/autopilot.svg';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/_AdminFeedbackDiscoverySettings.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type Props = OwnProps & ConnectProps;

const AdminFeedbackDiscoverySettings = ({ reloadCompany }: Props) => {
  const boards = useContext(BoardsContext);
  const company = useContext<Company>(CompanyContext);
  const router = useContext(RouterContext);
  const showToast = useContext(ShowToastContext);

  const [enabled, setEnabled] = useState(!!company.queueAutomation?.enabled);
  const [boardID, setBoardID] = useState<string | null>(company.queueAutomation?.boardID ?? null);
  const [loading, setLoading] = useState<boolean>(false);

  // handle boards loading state
  if ('loading' in boards) {
    return null;
  }

  const options = boards.map((board) => ({
    label: board.name,
    value: board._id,
  }));

  const updatePostDeduplication = async (enabled: boolean) => {
    const response = await AJAX.post('/api/queue/togglePostDeduplication', {
      enabled,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadCompany();
  };

  const updateQueueAutomation = async () => {
    if (enabled && !boardID) {
      return;
    }

    setLoading(true);

    const response = await AJAX.post('/api/queue/toggleAutomation', {
      boardID,
      enabled,
    });

    setLoading(false);

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadCompany();
  };

  const { features } = company;
  const hasAutopilot = features.deduplication || features.supportExtraction;
  const selectedBoard = options.find((option) => option.value === boardID);
  return (
    <>
      <Helmet title={`Feedback Discovery | Autopilot Settings | Canny`} />
      <div className="adminQueueSettings">
        {hasAutopilot ? (
          <>
            <div className="feedbackDiscoveryModeSection">
              <div className="queueToggleSection">
                <div className="queueDetails">
                  <H1 variant="headingMd">Feedback Suggestions</H1>
                  <P className="queueBody">
                    Choose how you would like feedback suggestions to be handled. You can either
                    manually review the actions or automate them, with the ability to reverse them
                    as needed.
                  </P>
                </div>
                <TextToggle
                  className="enableToggle"
                  disabled={loading}
                  onChange={(value) => setEnabled(value === 'automatic')}
                  options={[
                    { value: 'manual', label: 'Manual' },
                    { value: 'automatic', label: 'Automated' },
                  ]}
                  selected={enabled ? 'automatic' : 'manual'}
                />
                <ButtonV2
                  color="primary"
                  onClick={updateQueueAutomation}
                  disabled={
                    (!enabled && !company.queueAutomation?.enabled) ||
                    (enabled && !selectedBoard) ||
                    loading
                  }
                  loading={loading}
                  size="medium">
                  Save
                </ButtonV2>
              </div>
              {enabled ? (
                <div className="boardSelectSection">
                  <P className="boardSelectLabel" variant="bodyMd">
                    <CornerDownRight height="16" width="16" />
                    Select a destination for generated feedback
                  </P>
                  <div>
                    <SingleSelectWithSearch
                      disabled={loading}
                      onChange={(option) => option && setBoardID(option.value)}
                      options={options}
                      placeholder="Board"
                      value={selectedBoard}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="queueToggleSection">
              <div className="queueDetails">
                <div className="queueSectionTitle">
                  <H1 variant="headingMd">Canny Portal</H1>
                  <Pill pillStyle={DefaultPillStyles.canny} className="pill">
                    1 credit / post
                  </Pill>
                </div>
                <P className="queueBody">
                  When new posts are created in the Canny Portal, allow Autopilot to review existing
                  posts to find&nbsp;duplicates.
                </P>
              </div>
              <SwitchV2
                size="medium"
                checked={!!company.deduplication?.enabled}
                onChange={updatePostDeduplication}
              />
            </div>
            <Alert
              type={AlertTypes.Info}
              headingText="Installing sources"
              subText={
                <>
                  You can install Autopilot sources from the{' '}
                  <Link to="/admin/settings/integrations/autopilot" className="link">
                    Integration Settings page
                  </Link>
                  .
                </>
              }
            />
          </>
        ) : (
          <Card borderStyle="solid" className="legacyAutopilotUpsell">
            <CannyAnnouncement
              body={`Never let valuable feedback slip through the cracks again with Canny Autopilot. Automatically detect and consolidate feedback from all your sources in one place.\nMove to our new Free plan to get started.`}
              cta="Get Autopilot"
              image={AutopilotAnnouncement}
              learnMore="https://help.canny.io/en/articles/8202451-beta-inbox"
              onSuccess={() => router.replace('/admin/settings/billing/subscription')}
              title="Feedback Discovery on Autopilot"
            />
          </Card>
        )}
      </div>
    </>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.inbox),
    '/admin/settings'
  )
)(AdminFeedbackDiscoverySettings) as unknown as React.FC<OwnProps>;

import React from 'react';

import TextInput from 'common/inputs/TextInput';
import { P } from 'common/ui/Text';

import AdminOnboardingHeader, { AdminOnboardingSubHeader } from './AdminOnboardingHeader';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  inviteEmails: string[];
  onChangeInviteEmails: (inviteEmails: string[]) => void;
  onSubmit: () => void;
};

const AdminOnboardingInvite = ({ inviteEmails, onChangeInviteEmails, onSubmit }: Props) => {
  const onEmailChange = (index: number, email: string) => {
    const newEmails = [...inviteEmails];
    newEmails[index] = email;
    onChangeInviteEmails(newEmails);
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div className="onboardingInviteTeam">
      <AdminOnboardingHeader>Invite your team</AdminOnboardingHeader>
      <AdminOnboardingSubHeader>
        Collaborate with your teammates to get the most out of Canny.
      </AdminOnboardingSubHeader>

      <div className="emails">
        <P variant="bodyLg">Invite with email</P>
        {[0, 1, 2].map((index) => {
          return (
            <div key={index} className="email">
              <TextInput
                onChange={(e) => onEmailChange(index, e.target.value)}
                onKeyUp={onKeyUp}
                placeholder="Email address"
                value={inviteEmails[index]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminOnboardingInvite;

import { ColorNames } from 'common/colors/constants';
import mod from 'common/mod';
import hash from 'common/util/hash';

export type RGBColor = [number, number, number];
export type HexColor = `#${string}`; // TODO: Improve type when typescript supports it. see https://medium.com/@steve.alves2/how-to-type-hex-colors-in-typescript-3c3b9a32baa7

export const luminance = (rgb: RGBColor) => {
  const a = rgb.map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrastRatio = (rgb1: RGBColor, rgb2: RGBColor) => {
  const luminanceFront = luminance(rgb1);
  const luminanceBack = luminance(rgb2);
  const ratio =
    luminanceBack > luminanceFront
      ? (luminanceFront + 0.05) / (luminanceBack + 0.05)
      : (luminanceBack + 0.05) / (luminanceFront + 0.05);

  return ratio;
};

// WCAG requirements
// 0.14285 (7.0:1) for small text in AAA-level
// 0.22222 (4.5:1) for small text in AA-level, or large text in AAA-level
// 0.33333 (3.0:1) for large text in AA-level
export const testWCAG = (colorA: RGBColor, colorB: RGBColor) => {
  const colorContrastRatio = contrastRatio(colorA, colorB);
  return {
    AAA: {
      smallText: colorContrastRatio < 0.14285,
      largeText: colorContrastRatio < 0.22222,
    },
    AA: {
      smallText: colorContrastRatio < 0.22222,
      largeText: colorContrastRatio < 0.33333,
    },
  };
};

export const isHexColor = (color: unknown): color is HexColor => {
  if (typeof color !== 'string') {
    return false;
  }

  const regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  return !!color.match(regex);
};

/**
 * Hashes a string into the name of one of the colors from the design system's color palette
 */
export const colorPaletteHash = (str: string): ColorNames => {
  const idHash = hash(str.split('').reverse().join(''));
  const colorNameList = Object.keys(ColorNames) as ColorNames[];
  const colorIndex = mod(Number(idHash), colorNameList.length);
  const colorName = colorNameList[colorIndex];
  return ColorNames[colorName];
};

import { companyLoaded } from './thirdPartyCompanies';
import Data from '../Data';

// Action Types

export const RequestQuery = 'canny/third_party_company_queries/request_query';
export const QueryLoaded = 'canny/third_party_company_queries/query_loaded';
export const QueryError = 'canny/third_party_company_queries/query_error';
export const LoadingMore = 'canny/third_party_company_queries/loading_more';
export const Invalidate = 'canny/third_party_company_queries/invalidate';

// Actions

function requestQuery(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestQuery,
  };
}

function queryLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: QueryLoaded,
  };
}

function queryError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QueryError,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LoadingMore,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return (dispatch, getState) => {
    if (!queryParams) {
      return dispatch(queryError('Missing queryParams'));
    }

    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: {
            ...getRequestData(queryParams),
          },
          query: Data.queries.thirdPartyCompanies,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, queryParams, error, data);
      }
    );
  };
}

/**
 * queryParams of type: {}
 * (until queries are supported)
 */
export function loadQuery(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(companyList) {
  return (dispatch, getState) => {
    const { queryParams } = companyList;
    dispatch(loadingMore(queryParams));

    queryParams.limit = queryParams.limit ? queryParams.limit + 10 : 20;
    return dispatch(fetchQuery(queryParams));
  };
}

export function invalidateUserQueries() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions

export function getCompanyQueryKey(queryParams) {
  const queryObject = {
    ...(queryParams.search && { search: queryParams.search }),
    ...(queryParams.limit && { limit: queryParams.limit }),
    ...(queryParams.segmentURLName && { segmentURLName: queryParams.segmentURLName }),
  };
  return JSON.stringify(queryObject);
}

function getRequestData(queryParams) {
  const requestData = {
    limit: queryParams.limit ? queryParams.limit : 10,
    ...(queryParams.search && { search: queryParams.search }),
    ...(queryParams.segmentURLName && { segmentURLName: queryParams.segmentURLName }),
  };
  return requestData;
}

function shouldFetchQuery(state, queryParams) {
  const thirdPartyCompanyQueries = state.thirdPartyCompanyQueries;
  const queryKey = getCompanyQueryKey(queryParams);
  return !thirdPartyCompanyQueries[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const thirdPartyCompanyQueries = state.thirdPartyCompanyQueries;
  const queryKey = getCompanyQueryKey(queryParams);
  return !!thirdPartyCompanyQueries[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getCompanyQueryKey(queryParams);
  return new Promise((resolve, reject) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, queryParams, error, data) {
  var resultAction;
  const promises = [];
  if (error) {
    resultAction = queryError(queryParams, error);
  } else {
    resultAction = queryLoaded(queryParams, data.result);
    data.result.companies.forEach((company) => {
      promises.push(dispatch(companyLoaded(company._id, company)));
    });
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      const queryKey = getCompanyQueryKey(queryParams);
      if (!resultCallbacks[queryKey]) {
        return;
      }

      resultCallbacks[queryKey].forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks[queryKey].length = 0;
    });
  });
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import TextInput from './TextInput';

export default class PercentageInput extends Component {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
  };

  state = {
    value: null,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({ value: defaultValue ? `${defaultValue}%` : null });
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  onBlur = (e) => {
    const newValue = e.target.value.replace(/%/g, '');
    this.setState({
      value: newValue.trim() ? `${newValue}%` : null,
    });
    this.props.onBlur?.();
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
    const value = e.target.value.replace(/%/g, '');
    this.props.onChange?.(e, value);
  };

  render() {
    const { value } = this.state;

    return (
      <TextInput
        ref={this.inputRef}
        className={this.props.className}
        disabled={this.props.disabled}
        value={value}
        maxLength={3}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={this.props.onKeyDown}
        placeholder="0%"
      />
    );
  }
}

import React, { Component } from 'react';

import { Info } from 'lucide-react';
import PropTypes from 'prop-types';

import Tooltip from 'common/common/Tooltip';
import LazyLoadedImage from 'common/LazyLoadedImage';
import PostList from 'common/post/PostList';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardPostList.scss';

import CrosshairsIcon from 'img/icons/crosshairs.svg';

export default class AdminDashboardPostList extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    noPostsMessage: PropTypes.string.isRequired,
    posts: PropTypes.array.isRequired,
    showStaleInfo: PropTypes.bool,
    tooltip: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showStaleInfo: false,
  };

  renderContents() {
    const { noPostsMessage, posts, showStaleInfo } = this.props;
    if (posts.length === 0) {
      return (
        <div className="noPosts">
          <LazyLoadedImage src={CrosshairsIcon} />
          <div className="message">{noPostsMessage}</div>
        </div>
      );
    }

    return (
      <div className="postItems">
        <PostList
          noPostsMessage=""
          postList={{
            posts: posts,
            hasNextPage: false,
          }}
          showComments={false}
          showDetails={false}
          showMenu={false}
          showPagination={false}
          showStale={showStaleInfo}
          showStatus={true}
        />
      </div>
    );
  }

  render() {
    const { header, tooltip } = this.props;
    return (
      <div className="adminDashboardPostList">
        <div className="postListHeader">
          <h2 className="listHeader">{header}</h2>
          <div className="postTooltip" tabIndex="0">
            <Tooltip position="bottom" value={tooltip}>
              <Info size={14} className="info" />
            </Tooltip>
          </div>
        </div>
        {this.renderContents()}
      </div>
    );
  }
}

import requestReducerFactory, { defaultState as _defaultState } from './requestReducerFactory';
import { ErrorType, LoadedType, RequestType } from '../actions/customPostFields';

import type { CustomPostFields } from 'common/api/endpoints/customPostFields';

const reducer = requestReducerFactory<CustomPostFields>(RequestType, LoadedType, ErrorType);
export default reducer;

export type State = ReturnType<typeof reducer>;
export const defaultState = _defaultState;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import LazyLoadedImage from 'common/LazyLoadedImage';

export default class Image extends Component {
  static propTypes = {
    imageStyle: PropTypes.object.isRequired,
    loader: PropTypes.node.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  };

  static defaultProps = {
    // alt should only be left as empty if this is a decorative image
    alt: '',
    imageStyle: {},
    loader: <div className="loading" />,
  };

  state = {
    loaded: false,
  };

  constructor(props) {
    super(props);
    this._image = null;
  }

  componentDidMount() {
    this.createImage();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.src !== this.props.src) {
      this.createImage();
    }
  }

  componentWillUnmount() {
    this.destroyLoader();
  }

  createImage = () => {
    this.destroyLoader();

    this._image = new window.Image();
    this._image.onload = this.onLoad.bind(this, this._image);
    this._image.onerror = this.onLoad.bind(this, this._image);
    this._image.src = this.props.src;
  };

  destroyLoader = () => {
    if (!this._image) {
      return;
    }

    this._image.onload = null;
    this._image.onerror = null;
    this._image = null;
  };

  onLoad = (image) => {
    if (image !== this._image) {
      return;
    }

    this.setState({
      loaded: true,
    });

    this.destroyLoader();
  };

  render() {
    if (!this.state.loaded) {
      return this.props.loader;
    }

    return (
      <LazyLoadedImage alt={this.props.alt} src={this.props.src} style={this.props.imageStyle} />
    );
  }
}

import { loadCompany } from './company';
import Data from '../Data';

// Action Types
export const LinearSettingsError = 'canny/linear_settings/linear_settings_error';
export const LinearSettingsLoaded = 'canny/linear_settings/linear_settings_loaded';
export const RequestLinearSettings = 'canny/linear_settings/request_linear_settings';

// Actions
function linearSettingsError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: LinearSettingsError,
  };
}

function linearSettingsLoaded(linearSettings) {
  return {
    linearSettings,
    timestamp: Date.now(),
    type: LinearSettingsLoaded,
  };
}

function requestLinearSettings() {
  return {
    timestamp: Date.now(),
    type: RequestLinearSettings,
  };
}

// Action Creators
function fetchLinearSettings() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    return Data.fetch(
      {
        linearSettings: {
          query: Data.queries.linearSettings,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(linearSettingsError(error));
        }
        return dispatch(linearSettingsLoaded(data.linearSettings));
      }
    );
  };
}

export function loadLinearSettings() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      if (shouldFetchSettings(getState())) {
        dispatch(requestLinearSettings());
        return dispatch(fetchLinearSettings());
      }
    });
  };
}

export function reloadLinearSettings() {
  return (dispatch, getState) => {
    return dispatch(fetchLinearSettings());
  };
}

function shouldFetchSettings(state) {
  const { linearSettings } = state;
  return linearSettings === null;
}

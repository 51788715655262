import { loadAzureDevopsSettings } from 'common/actions/azureDevopsSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

const AzureDevopsSettingsContainer = (props) => {
  const { children, azureDevopsSettings } = props;

  return cloneElementWithProps(children, {
    ...props,
    azureDevopsSettings,
  });
};

export default asyncConnect(
  [{ promise: ({ store: { dispatch } }) => dispatch(loadAzureDevopsSettings()) }],
  (state) => ({ azureDevopsSettings: state.azureDevopsSettings })
)(AzureDevopsSettingsContainer);

import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import FileRenderer from 'common/file/FileRenderer';
import { createFiles, createImageFiles } from 'common/file/utils/createFiles';
import translateString from 'common/i18n/translateString';
import Markdown from 'common/markdown/Markdown';

import 'css/components/post/_PostBody.scss';

export default class PostBody extends PureComponent {
  static propTypes = {
    hideTranslation: PropTypes.bool,
    post: PropTypes.shape({
      details: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    hideTranslation: false,
  };

  render() {
    const { post } = this.props;
    const imageFiles = post.imageURLs ? createImageFiles(post.imageURLs) : [];
    const nonImageFiles = post.files ? createFiles(post.files) : [];
    const files = [...imageFiles, ...nonImageFiles];

    return (
      <div className="postBody">
        <div className="details">
          <Markdown contents={translateString(post, 'details', this.props.hideTranslation)} />
        </div>
        <FileRenderer className="postFileRenderer" allowRemove={false} files={files} />
      </div>
    );
  }
}

import {
  type Action,
  ActionType,
  type AddPostCategory,
  type AddPostTags,
  type AddToRoadmap,
  type ApplyPostStatus,
} from './constants';

export const isActionAddPostCategory = (action: Action): action is AddPostCategory =>
  action.type === ActionType.addPostCategory;

export const isActionAddPostTags = (action: Action): action is AddPostTags =>
  action.type === ActionType.addPostTags;

export const isActionApplyPostStatus = (action: Action): action is ApplyPostStatus =>
  action.type === ActionType.applyPostStatus;

export const isActionAddToRoadmap = (action: Action): action is AddToRoadmap =>
  action.type === ActionType.addToRoadmap;

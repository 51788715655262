import React, { Component } from 'react';

import PropTypes from 'prop-types';

import urlNamify from 'common/util/urlNamify';

import TextInput from './TextInput';

export default class URLNameInput extends Component {
  static propTypes = {
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    input: PropTypes.string.isRequired,
  };

  state = {
    urlName: urlNamify(this.props.input),
  };

  constructor(props, context) {
    super(props, context);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.input !== prevProps.input) {
      this.setState({
        urlName: urlNamify(this.props.input),
      });
    }
  }

  getValue = () => {
    return this.inputRef.current.getValue();
  };

  onURLNameChange = (e) => {
    const urlNamified = urlNamify(e.target.value);

    let nextURLName = urlNamified;
    if (e.target.value === urlNamified + '-') {
      nextURLName = e.target.value;
    }

    this.setState({
      urlName: nextURLName,
    });
    this.props.onChange && this.props.onChange(nextURLName);
  };

  render() {
    var inputProps = { ...this.props };
    Object.keys(URLNameInput.propTypes).forEach((propType) => {
      delete inputProps[propType];
    });

    const prefix = this.props.company.subdomain + '.canny.io/';
    return (
      <div className="urlNameInput">
        <TextInput
          {...inputProps}
          onChange={this.onURLNameChange}
          prefix={prefix}
          ref={this.inputRef}
          value={this.state.urlName}
        />
      </div>
    );
  }
}

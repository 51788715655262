import React, { Component } from 'react';

import PropTypes from 'prop-types';

// This file is heavily based on:
// https://github.com/ReactTraining/react-router/blob/v3/modules/Redirect.js
export default class ExternalRedirect extends Component {
  static propTypes = {
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  };

  static createRouteFromReactElement = (element) => {
    const { type } = element;
    const route = Object.assign({}, type.defaultProps, element.props);
    route.childRoutes = [];
    delete route.children;

    if (route.from) {
      route.path = route.from;
    }

    route.onEnter = function (nextState, replace) {
      const location = nextState.location;
      if (typeof window !== 'undefined') {
        window.location = route.to;
        return;
      }

      replace({
        pathname: location.pathname,
        query: location.query,
        state: {
          externalURL: route.to,
        },
      });
    };

    return route;
  };

  static getRoutePattern = (routes, routeIndex) => {
    let parentPattern = '';
    for (var i = routeIndex; i >= 0; i--) {
      const route = routes[i];
      const pattern = route.path || '';
      parentPattern = pattern.replace(/\/*$/, '/') + parentPattern;
      if (pattern.indexOf('/') === 0) {
        break;
      }
    }
    return '/' + parentPattern;
  };

  render() {
    return <div />;
  }
}

import React, { useContext } from 'react';

import classnames from 'classnames';
import { LockIcon } from 'lucide-react';

import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Link, { type Props as LinkProps } from 'common/Link';
import { hasEveryPermission } from 'common/util/permissions';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

import 'css/components/subdomain/admin/_AdminSettingsSidebarLink.scss';

interface Props extends LinkProps {
  requiredPermissions?: RolePermissionName[];
}

const AdminSettingsSidebarLink = ({
  children,
  className,
  requiredPermissions,
  ...props
}: Props) => {
  const company = useContext<Company>(CompanyContext);
  const viewer = useContext<Viewer>(ViewerContext);

  const canAccessLink =
    !requiredPermissions ||
    hasEveryPermission(requiredPermissions, company, viewer).result === 'success';

  return (
    <Link
      activeClassName="active"
      disabled={!canAccessLink}
      className={classnames('adminSettingsSidebarLink', className, { disabled: !canAccessLink })}
      {...props}>
      {children}
      {!canAccessLink && (
        <Tooltip value="You do not have access to this feature. Please ask one of your teammates to give you permission.">
          <LockIcon className="lockIcon" />
        </Tooltip>
      )}
    </Link>
  );
};

export default AdminSettingsSidebarLink;

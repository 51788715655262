import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { loadMore } from 'common/actions/postQueries';
import Spinner from 'common/Spinner';

import PostListItem from './PostListItem';
import PostListLoadMore from './PostListLoadMore';
import PostListMenu from './PostListMenu';

import 'css/components/post/_PostList.scss';

export default class PostList extends Component {
  static propTypes = {
    additionalMenu: PropTypes.node,
    autoFocus: PropTypes.bool,
    board: PropTypes.object,
    ItemComponent: PropTypes.elementType,
    linkToAdmin: PropTypes.bool.isRequired,
    maxPosts: PropTypes.number,
    noPostsMessage: PropTypes.node.isRequired,
    onLoadMore: PropTypes.func,
    postList: PropTypes.shape({
      posts: PropTypes.array,
    }),
    scrollContainerRef: PropTypes.object,
    searchMode: PropTypes.bool,
    selectedPostsMap: PropTypes.object,
    showBoard: PropTypes.bool,
    showComments: PropTypes.bool.isRequired,
    showDetails: PropTypes.bool,
    showMenu: PropTypes.bool,
    showPagination: PropTypes.bool,
    showPrivateComments: PropTypes.bool,
    showStale: PropTypes.bool,
    showStatus: PropTypes.bool,
  };

  static defaultProps = {
    additionalMenu: null,
    autoFocus: true,
    ItemComponent: PostListItem,
    linkToAdmin: false,
    maxPosts: null,
    onLoadMore: loadMore,
    searchMode: false,
    selectedPostsMap: {},
    showBoard: false,
    showComments: true,
    showDetails: true,
    showMenu: true,
    showPagination: true,
    showPrivateComments: false,
    showStale: false,
    showStatus: true,
  };

  state = {
    selectedPost: null,
  };

  constructor(props, context) {
    super(props, context);

    this.loadMoreRef = React.createRef();
  }

  onScroll = (e, scrollContainer) => {
    const loadMore = this.loadMoreRef.current && this.loadMoreRef.current.getWrappedInstance();
    if (!loadMore) {
      return;
    }

    loadMore.onScroll(e, scrollContainer);
  };

  onPostSelected = (e, post) => {
    const { onPostSelected } = this.props;
    this.setState(
      {
        selectedPost: post ? { boardURLName: post.board.urlName, postURLName: post.urlName } : null,
      },
      () => onPostSelected(e, post)
    );
  };

  renderPosts() {
    const { selectedPost } = this.state;
    const {
      board,
      linkToAdmin,
      maxPosts,
      noPostsMessage,
      postList,
      scrollContainerRef,
      selectedPostsMap,
      showBoard,
      showComments,
      showDetails,
      showPrivateComments,
      showStale,
      showStatus,
    } = this.props;

    if ((postList.loading && !postList.needsPurge) || postList.postsInvalidated) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    }

    if (postList.error) {
      return (
        <div className="error">
          Something went wrong and posts couldn't be loaded, try again later.
        </div>
      );
    }

    const posts = [];
    postList.posts.forEach((post, i) => {
      if (maxPosts && i >= maxPosts) {
        return;
      }

      const { ItemComponent } = this.props;
      const isSelectModeOn = !!Object.keys(selectedPostsMap).length;
      const highlighted = !!selectedPostsMap[post._id];
      const canShowComment = !post.boardCommentsArePrivate || showPrivateComments;

      posts.push(
        <ItemComponent
          board={board}
          key={post._id}
          linkToAdmin={linkToAdmin}
          onPostSelected={this.onPostSelected}
          post={post}
          scrollContainerRef={scrollContainerRef}
          selectedPost={selectedPost}
          showBoard={showBoard}
          showComments={showComments && canShowComment}
          showDetails={showDetails}
          showStale={showStale}
          showStatus={showStatus}
          {...(isSelectModeOn && { highlighted })}
        />
      );
    });

    if (posts.length === 0) {
      return <div className="noPosts">{noPostsMessage}</div>;
    }

    return <div className="posts">{posts}</div>;
  }

  renderAdditionalMenu() {
    return this.props.additionalMenu;
  }

  renderMenu() {
    const { additionalMenu, autoFocus, board, searchMode, showMenu } = this.props;
    const menu = showMenu ? (
      <PostListMenu autoFocus={autoFocus} board={board} searchMode={searchMode} />
    ) : null;

    return (
      <div className="topContainer">
        {menu}
        {additionalMenu}
      </div>
    );
  }

  renderPagination() {
    const { onLoadMore, postList, showPagination } = this.props;
    if (!showPagination) {
      return null;
    }

    return <PostListLoadMore onLoadMore={onLoadMore} postList={postList} ref={this.loadMoreRef} />;
  }

  render() {
    return (
      <div className="postList">
        {this.renderMenu()}
        {this.renderPosts()}
        {this.renderPagination()}
      </div>
    );
  }
}

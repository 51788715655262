import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AccountModal, { FormStates } from 'common/AccountModal';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { reloadPostActivity } from 'common/actions/postsActivity';
import { invalidateUserQueries } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import { LoadStatus } from 'common/constants/files';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import FileRenderer from 'common/file/FileRenderer';
import { createFiles, createImageFiles } from 'common/file/utils/createFiles';
import getAcceptedMimeTypes from 'common/file/utils/getAcceptedMimeTypes';
import getValidFileURLs from 'common/file/utils/getValidFileURLs';
import uploadFile from 'common/file/utils/uploadFile';
import Form from 'common/Form';
import Button from 'common/inputs/Button';
import MentionsTextarea from 'common/inputs/MentionsTextarea';
import UploadFileButton from 'common/inputs/UploadFileButton';
import AdminFeatureBlock from 'common/subdomain/admin/AdminFeatureBlock';
import delayer from 'common/util/delayer';
import getAuthRedirectURL from 'common/util/getAuthRedirectURL';
import isWidget from 'common/util/isWidget';
import { RenderValues, getMentionTag } from 'common/util/mentions';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';
import { ConfigContext } from 'common/widget/WidgetContext';

import 'css/components/comment/_CommentComposer.scss';

const BlurDelay = 100;
const MeaninglessBlocklist = {
  '+1': true,
};

class CommentComposer extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    board: PropTypes.object.isRequired,
    comment: PropTypes.shape({
      _id: PropTypes.string,
      files: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }))
        .isRequired,
      imageURLs: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }).isRequired,
    config: PropTypes.object,
    location: PropTypes.object,
    onCancelEdit: PropTypes.func,
    onCommentCreated: PropTypes.func,
    onCommentEdited: PropTypes.func,
    openModal: PropTypes.func.isRequired,
    post: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
    replyTo: PropTypes.shape({
      author: PropTypes.shape({
        _id: PropTypes.string,
        aliasID: PropTypes.string.isRequired,
      }),
    }),
    tintColor: PropTypes.string,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    onCommentCreated: () => {},
  };

  state = {
    addingFile: false,
    autoFocus: false,
    fileButtonMouseDown: false,
    files: [
      ...createFiles(this.props.comment?.files || []),
      ...createImageFiles(this.props.comment?.imageURLs || []),
    ],
    focused: false,
    initialMentionedUsers: [
      this.props.replyTo?.author,
      ...(this.props.comment?.mentionedUsers ?? []),
    ].filter(Boolean),
    isInternal: this.props.comment
      ? this.props.comment.internal
      : this.props.replyTo
      ? this.props.replyTo.internal
      : false,
    submitting: false,
    value: this.props.comment && !this.props.comment.internal ? this.props.comment.value : '',
    valueInternal:
      this.props.comment && this.props.comment.internal ? this.props.comment.value : '',
  };

  constructor(props, context) {
    super(props, context);

    this._blurDelayer = new delayer(this.onBlurAfterDelay, BlurDelay);
    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    const { company, replyTo, viewer } = this.props;

    if (!replyTo) {
      return;
    }
    const userMentionsDisabled =
      company.featureAllowlist.includes('disable-mentions') && !company.viewerIsMember;
    const { author, internal } = replyTo;
    if (!author || userMentionsDisabled) {
      this.setState({ value: '', valueInternal: '' });
      return;
    }

    const authorIsMember = company.members.some((m) => m._id === author._id);
    const authorIsViewer = author._id === viewer._id;
    const authorMention = this.getMentionTag(author);
    const autofillMentionPublic = !authorIsViewer && !internal;
    const autofillMentionInternal = !authorIsViewer && authorIsMember;
    const nextValue = autofillMentionPublic ? authorMention : '';
    const nextValueInternal = autofillMentionInternal ? authorMention : '';
    this.setState({ value: nextValue, valueInternal: nextValueInternal }, () => {
      const textarea = this.textareaRef.current?.getWrappedInstance();
      if (textarea) {
        const cursor = internal ? nextValueInternal.length : nextValue.length;
        textarea.setSelectionRange?.(cursor, cursor, 'forward');
      }
    });
  }

  componentWillUnmount() {
    this._blurDelayer.cancel();
  }

  areFilesUploading = () => {
    return this.state.files.some((file) => file.uploadStatus !== LoadStatus.loaded);
  };

  getMentionTag(user) {
    const { board, company } = this.props;
    const { members, viewerIsMember } = company;

    // Except members and viewer, user IDs will be null for all users in anonymized board view
    const userIsMember = members.some((member) => member._id === user._id);

    // Admins will see normal names in Anonymized view -> mention users with full name
    // Members will be visible to everyone -> mention admins with full name
    if (viewerIsMember || userIsMember) {
      return getMentionTag(user, RenderValues.full_name);
    }

    if (board.settings.privateAuthors) {
      return getMentionTag(user, RenderValues.alias);
    }

    return getMentionTag(user, RenderValues.full_name);
  }

  isButtonHidden = () => {
    const { addingFile, fileButtonMouseDown, files, focused, isInternal, value, valueInternal } =
      this.state;
    const uploadingFiles = this.areFilesUploading();

    return (
      !addingFile &&
      !focused &&
      !uploadingFiles &&
      !fileButtonMouseDown &&
      !(!isInternal && (value || files.length)) &&
      !(isInternal && (valueInternal || files.length))
    );
  };

  isSubmitDisabled = () => {
    const { files, isInternal, submitting, value, valueInternal } = this.state;
    const buttonDisabled = !files.length && (isInternal ? !valueInternal : !value);
    const buttonLoading = submitting;
    const buttonHidden = this.isButtonHidden();
    const filesUploading = this.areFilesUploading();

    return buttonDisabled || buttonLoading || buttonHidden || filesUploading;
  };

  onBlurAfterDelay = () => {
    this.setState({
      focused: false,
    });
  };

  onChange = (value) => {
    if (this.state.isInternal) {
      this.setState({ valueInternal: value });
    } else {
      this.setState({ value });
    }
  };

  onFile = async (file) => {
    const { openModal, viewer } = this.props;
    if (!viewer || viewer.loggedOut) {
      openModal(AccountModal, {
        formState: FormStates.signup,
        onSuccess: (callback) => {
          callback();
        },
      });
      return;
    }

    await uploadFile({
      file,
      viewer,
      onFileError: this.onFileError,
      onFileUploading: this.onFileUploading,
      onFileUploaded: this.onFileUploaded,
    });
  };

  onFileButtonMouseDown = (e) => {
    this.setState({ fileButtonMouseDown: true });
  };

  onFileButtonMouseUp = (e) => {
    this.setState({ fileButtonMouseDown: false });
  };

  onFileError = (file, error) => {
    this.setState((state) => ({
      error,
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFileOpen = () => {
    this.setState({ addingFile: true });
  };

  onFileStart = () => {
    const { openModal, viewer } = this.props;
    if (viewer?._id) {
      // continue uploading file
      return true;
    }

    openModal(AccountModal, {
      formState: FormStates.signup,
      onSuccess: (callback) => {
        callback();
      },
    });

    // do not continue uploading file
    return false;
  };

  onFileUploading = (file) => {
    this.setState((state) => ({
      files: [...state.files, file],
    }));
  };

  onFileUploaded = (file) => {
    this.setState((state) => ({
      files: state.files.map((f) => (f.uniqueID === file.uniqueID ? file : f)),
    }));
  };

  onFileRemoved = (file) => {
    this.setState((state) => ({
      files: state.files.filter((f) => f.uniqueID !== file.uniqueID),
    }));
  };

  onFocus = () => {
    this._blurDelayer.cancel();
    this.setState({
      focused: true,
    });
  };

  onInternal = () => {
    this.setState({
      isInternal: true,
    });
    this.onFocus();
    this.textareaRef.current.getWrappedInstance().focus();
  };

  onPublic = () => {
    this.setState({
      isInternal: false,
    });
    this.onFocus();
    this.textareaRef.current.getWrappedInstance().focus();
  };

  onSubmit = () => {
    const { files, isInternal, value, valueInternal } = this.state;
    const { imageURLs, nonImageFileURLs } = getValidFileURLs(files);
    const commentValue = isInternal ? valueInternal : value;

    var error = null;
    if (!commentValue && !imageURLs.length && !nonImageFileURLs.length) {
      error = 'Oops! You forgot to enter a comment.';
    } else if (commentValue && MeaninglessBlocklist[commentValue]) {
      error =
        'Please upvote instead. Make sure your comments add meaningful value to the discussion.';
    }

    this.setState({ error });
    if (error) {
      return;
    }

    const { comment } = this.props;
    const commentData = comment
      ? {
          commentID: comment._id,
          fileURLs: JSON.stringify(nonImageFileURLs),
          imageURLs: JSON.stringify(imageURLs),
          value: commentValue,
        }
      : {
          fileURLs: JSON.stringify(nonImageFileURLs),
          imageURLs: JSON.stringify(imageURLs),
          internal: isInternal,
          postID: this.props.post._id,
          value: commentValue,
        };

    if (!comment && this.props.replyTo) {
      if (this.props.replyTo.parentID) {
        commentData.parentID = this.props.replyTo.parentID;
      } else {
        commentData.parentID = this.props.replyTo._id;
      }
    }

    if (this.props.viewer._id) {
      this.onSaveComment(commentData);
      return;
    }

    this.props.openModal(AccountModal, {
      formState: FormStates.login,
      onSuccess: (callback) => {
        this._refreshCallback = callback;
        this.onSaveComment(commentData);
      },
    });
  };

  onSaveComment = async (commentData) => {
    this.setState({
      error: null,
      submitting: true,
    });

    const { comment } = this.props;
    const endpointURL = comment ? '/api/comments/edit' : '/api/comments/create';
    const response = await AJAX.post(endpointURL, commentData);
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'slow down': `You are trying to ${
          comment ? 'edit' : 'create'
        } comments too fast. Please wait a few minutes before trying again.`,
        'character limit': 'Please make your comment between 1 and 2500 characters.',
        'author does not have permission':
          "You don't have permission to create public comments. Speak to a teammate to get access.",
      },
    });

    if (error) {
      this.setState({ error: error.message, submitting: false });
      return;
    }

    if (this._refreshCallback) {
      this._refreshCallback();
    }

    await this.props.invalidateData(this.props.post);

    this.setState((state) => ({
      ...state,
      ...(state.isInternal ? { valueInternal: '' } : { value: '' }),
      files: [],
      submitting: false,
    }));

    if (comment) {
      this.props.onCommentEdited();
      return;
    }

    this.props.onCommentCreated();
  };

  renderTeamNotifyMessage() {
    const {
      comment,
      company: { viewerIsMember },
      replyTo,
    } = this.props;
    if (!viewerIsMember || replyTo || comment) {
      return null;
    }

    return this.state.isInternal ? (
      <div className="teamNotifyMessage">Mentioned admins will be notified.</div>
    ) : (
      <div className="teamNotifyMessage">The post author and voters will get an&nbsp;email.</div>
    );
  }

  renderButtonBar() {
    const { company } = this.props;
    const { files, isInternal, value, valueInternal } = this.state;

    if (this.isButtonHidden()) {
      return null;
    }

    const formValuesInvalid = !files.length && (isInternal ? !valueInternal : !value);
    const disabled = this.areFilesUploading() || formValuesInvalid;

    return (
      <div className="buttonBar">
        <div onMouseDown={this.onFileButtonMouseDown} onMouseUp={this.onFileButtonMouseUp}>
          <UploadFileButton
            acceptedMimeTypes={getAcceptedMimeTypes(company)}
            defaultStyle={false}
            onFileError={this.onFileError}
            onFileOpen={this.onFileOpen}
            onFileStart={this.onFileStart}
            onFileUploading={this.onFileUploading}
            onFileUploaded={this.onFileUploaded}
          />
        </div>
        <div className="rightContainer">
          {this.renderTeamNotifyMessage()}
          {this.renderCancelButton()}
          <Button
            buttonType="blackButton"
            disabled={disabled}
            formButton={true}
            loading={this.state.submitting}
            tint={true}
            value={this.props.comment ? 'Save' : 'Submit'}
          />
        </div>
      </div>
    );
  }

  renderCancelButton() {
    const { comment } = this.props;
    if (!comment) {
      return null;
    }

    const { submitting } = this.state;

    return (
      <Button
        buttonType="ghostButton"
        className="cancelButton"
        disabled={submitting}
        onTap={this.props.onCancelEdit}
        value="Cancel"
      />
    );
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderFiles() {
    const { files } = this.state;

    if (!files.length) {
      return null;
    }

    return (
      <div className="fileRendererContainer">
        <FileRenderer
          allowRemove={true}
          files={this.state.files}
          onFileRemoved={this.onFileRemoved}
        />
      </div>
    );
  }

  render() {
    const { company, config, location, tintColor, viewer } = this.props;
    const { authRedirectEnabled, authRedirectURL, billingData, viewerIsMember } = company;
    const viewerIsLoggedIn = viewer && viewer._id;
    const planIsExpired = !billingData || !billingData.plan;
    if (viewerIsLoggedIn && viewerIsMember && planIsExpired) {
      return (
        <div className="commentComposer">
          <AdminFeatureBlock
            feature="comments"
            benefit="Interact with your users to dig into details and give updates."
          />
        </div>
      );
    } else if (!viewerIsLoggedIn && authRedirectEnabled && authRedirectURL) {
      const redirectURL = getAuthRedirectURL(company, location, config);
      const linkStyle = {
        borderBottomColor: tintColor,
        color: tintColor,
      };
      return (
        <div className="commentComposer logInPrompt">
          <div className="message">Log in to leave a comment</div>
          <a href={redirectURL} className="logInLink" style={linkStyle} target="_top">
            Log In
          </a>
        </div>
      );
    }

    const { board, comment } = this.props;
    const { initialMentionedUsers, isInternal, value, valueInternal } = this.state;

    const placeholder = isInternal ? 'Leave an internal comment' : 'Leave a comment';
    return (
      <div
        className={classnames({
          commentComposer: true,
          editing: !!comment,
          internal: isInternal,
        })}>
        {this.renderErrorMessage()}
        <Form
          acceptedFileTypes={getAcceptedMimeTypes(company)}
          addEventsToDocument={false}
          allowFileUpload={true}
          className="composerForm"
          disableSubmit={this.isSubmitDisabled()}
          onFile={this.onFile}
          onSubmit={this.onSubmit}>
          <MentionsTextarea
            ref={this.textareaRef}
            autoComplete="off"
            autoFocus={this.props.autoFocus}
            board={board}
            initialMentionedUsers={initialMentionedUsers}
            membersOnly={isInternal}
            onBlur={this._blurDelayer.callAfterDelay}
            onChange={this.onChange}
            onFocus={this.onFocus}
            placeholder={placeholder}
            value={isInternal ? valueInternal : value}
          />
          {this.renderFiles()}
          {this.renderButtonBar()}
        </Form>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateData: (post) => {
      return Promise.all([
        dispatch(invalidatePostQueries()),
        dispatch(reloadPost(post)),
        dispatch(reloadPostActivity(post)),
        isWidget() ? null : dispatch(invalidateUserQueries()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      config: ConfigContext,
      location: LocationContext,
      openModal: OpenModalContext,
      tintColor: TintColorContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(CommentComposer);

import AJAX from 'common/AJAX';

export default class OAuthGoogleRedirect {
  constructor({ onSuccess, onFailure, onContinue, shouldOpenNewTab }) {
    this._onSuccess = onSuccess;
    this._onFailure = onFailure;
  }

  connect = (result) => {
    // 1. Verify access token
    if (!result.code) {
      this._onFailure();
      return;
    }

    AJAX.post(
      '/api/oauth/getGoogleAuthTokens',
      {
        code: result.code,
      },
      (response) => {
        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          this._onFailure('invalid code');
          return;
        }

        if (!responseObject.googleIDToken || !responseObject.googleUserID) {
          this._onFailure('invalid code');
          return;
        }

        this._onSuccess({
          avatarURL: responseObject.avatarURL,
          email: responseObject.email,
          googleIDToken: responseObject.googleIDToken,
          googleUserID: responseObject.googleUserID,
          name: responseObject.name,
        });
      }
    );
  };
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadAllBoards } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import { reloadCustomPostFields } from 'common/actions/customPostFields';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { RevenueTimeframes } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import { findTypeFromName } from 'common/customPostFields/Utils';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import ConfirmModal from 'common/modals/ConfirmModal';
import {
  CreateCustomPostFieldModal,
  EditCustomPostFieldModal,
} from 'common/modals/CustomPostFieldModal';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import Tappable from 'common/Tappable';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminCustomPostFieldsSettings.scss';

const Modals = {
  createField: 'createField',
  editField: 'editField',
  deleteField: 'deleteField',
  uspell: 'upsell',
};

class AdminCustomPostFieldsSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
    }),
    customPostFields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  };

  state = {
    customPostField: null,
    error: null,
    modal: null,
  };

  onDelete = (customPostField) => {
    this.setState({ customPostField, modal: Modals.deleteField });
  };

  onDeleteModalConfirmed = async () => {
    const { reloadBoards, reloadCustomPostFields } = this.props;
    const { customPostField } = this.state;

    const response = await AJAX.post('/api/customPostFields/delete', {
      customPostFieldID: customPostField._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({
        error: error.message,
        modal: null,
      });
      return;
    }

    this.setState({ modal: null }, () => {
      reloadCustomPostFields();
      reloadBoards();
    });
  };

  onEdit = (customPostField) => {
    this.setState({ customPostField, modal: Modals.editField });
  };

  onRevenueToggle = async () => {
    const {
      reloadCompany,
      company: { revenueTimeframe },
    } = this.props;

    this.setState({ error: null });
    const response = await AJAX.post('/api/company/changeSettings', {
      revenueTimeframe:
        revenueTimeframe === RevenueTimeframes.arr ? RevenueTimeframes.mrr : RevenueTimeframes.arr,
    });

    if (response !== 'success') {
      this.setState({
        error: 'Something went wrong, please try again later.',
      });
      return;
    }

    await reloadCompany();
  };

  renderDeleteModal() {
    const { customPostField, modal } = this.state;
    if (modal !== Modals.deleteField || !customPostField) {
      return null;
    }

    return (
      <ConfirmModal
        closeModal={() => this.setState({ modal: null })}
        message={
          <div className="confirmPostFieldDeletion">
            <p>
              Are you sure you want to delete the{' '}
              <span className="fieldName">{customPostField.name}</span> field? This action will:
            </p>
            <ul>
              <li>Remove the field from your Post Fields</li>
              <li>Remove the field from the "Create Post" form</li>
              <li>Remove the data linked to this field from your posts</li>
            </ul>
          </div>
        }
        onConfirm={this.onDeleteModalConfirmed}
        submitButtonValue="Delete"
        useModalPortal={true}
      />
    );
  }

  renderFieldsTable() {
    const {
      customPostFields,
      company: { revenueTimeframe },
    } = this.props;
    const usingArr = revenueTimeframe === RevenueTimeframes.arr;

    return (
      <div className="fieldsTable">
        <table>
          <thead>
            <tr>
              <th>Field name</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="spend">
                {usingArr ? 'ARR' : 'MRR'}
                <button className="spendTimescale" onClick={this.onRevenueToggle}>
                  Use {usingArr ? 'MRR' : 'ARR'} instead
                </button>
                <Tooltip value="Show company spend as ARR instead of&nbsp;MRR">
                  <div className="icon-help" />
                </Tooltip>
              </td>
              <td className="type">Default</td>
            </tr>
            <tr>
              <td>Category</td>
              <td className="type">Default</td>
            </tr>
            <tr>
              <td>ETA</td>
              <td className="type">Default</td>
            </tr>
            {customPostFields.map((customPostField) => {
              const type = findTypeFromName(customPostField.type);
              return (
                <tr key={customPostField._id}>
                  <td>{customPostField.name}</td>
                  <td className="type">{type.label}</td>
                  <td className="options">
                    <Tappable onTap={() => this.onEdit(customPostField)}>
                      <span className="icon icon-pencil" />
                    </Tappable>
                    <Tappable onTap={() => this.onDelete(customPostField)}>
                      <span className="icon icon-x" />
                    </Tappable>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  renderCreateFieldModal() {
    const { reloadCustomPostFields } = this.props;
    const { modal } = this.state;

    if (modal !== Modals.createField) {
      return null;
    }

    return (
      <CreateCustomPostFieldModal
        onClose={() => this.setState({ modal: null })}
        onCreate={() => this.setState({ modal: null }, () => reloadCustomPostFields())}
      />
    );
  }

  renderEditFieldModal() {
    const { reloadCustomPostFields } = this.props;
    const { customPostField, modal } = this.state;

    if (modal !== Modals.editField) {
      return null;
    }

    return (
      <EditCustomPostFieldModal
        defaultCustomPostField={customPostField}
        onClose={() => this.setState({ modal: null })}
        onEdit={() => this.setState({ modal: null }, () => reloadCustomPostFields())}
      />
    );
  }

  renderUpsellModal() {
    const { modal } = this.state;

    if (modal !== Modals.upsell) {
      return null;
    }

    return (
      <UpsellModal
        cta="Add custom fields to&nbsp;posts"
        feature="customPostFields"
        onClose={() => this.setState({ modal: null })}
        onUpsell={() => this.setState({ modal: Modals.createField })}
        show={true}
      />
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { company } = this.props;
    const { features } = company;

    return (
      <div className="adminCustomPostFieldsSettings">
        <Helmet title="Post Fields | Canny" />
        <div className="content">
          <H2 variant="headingMd">Post Fields</H2>
          <P className="text">
            You can configure which fields show up on the create post form, posts, and roadmaps.
          </P>
          <div className="fieldsTableContainer">
            <Button
              buttonType="cannyButton"
              value="Create new field"
              onTap={() =>
                this.setState({
                  modal: features.customPostFields ? Modals.createField : Modals.upsell,
                })
              }
            />
            {this.renderFieldsTable()}
            {this.renderError()}
          </div>
        </div>
        {this.renderCreateFieldModal()}
        {this.renderEditFieldModal()}
        {this.renderDeleteModal()}
        {this.renderUpsellModal()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoards: () => dispatch(reloadAllBoards()),
    reloadCompany: () => dispatch(reloadCompany()),
    reloadCustomPostFields: () => dispatch(reloadCustomPostFields()),
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.postFields['post-fields']),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminCustomPostFieldsSettings);

import React from 'react';

import Helmet from 'common/helmets/Helmet';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';
import { RoutePermissions } from 'common/util/permissions';

import 'css/components/subdomain/admin/_AdminGeneralSettingsContainer.scss';

type BillingSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.billing;
};

const AdminBillingSettingsContainer = ({ children }: { children: React.ReactNode }) => {
  const sideBarLinks: BillingSidebarLink[] = [
    {
      label: 'Subscription',
      urlSuffix: 'subscription',
    },
    {
      label: 'Autopilot usage',
      urlSuffix: 'autopilot',
    },
  ];

  return (
    <div className="adminGeneralSettingsContainer">
      <Helmet title="Billing settings | Canny" />
      <AdminSettingsHeader
        title="Billing"
        subheading="Manage your subscription, payment information, and&nbsp;invoices."
      />
      <div className="settingsContainer">
        <div className="settingsSidebar">
          {sideBarLinks.map(({ label, urlSuffix }) => {
            return (
              <AdminSettingsSidebarLink
                key={urlSuffix}
                requiredPermissions={RoutePermissions.adminSettings.billing[urlSuffix]}
                to={`/admin/settings/billing/${urlSuffix as string}`}>
                {label}
              </AdminSettingsSidebarLink>
            );
          })}
        </div>
        <div className="settingsContent">{children}</div>
      </div>
    </div>
  );
};

export default AdminBillingSettingsContainer;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Portal from 'common/common/Portal';
import Tappable from 'common/Tappable';
import emphasizeMatches from 'common/util/emphasizeMatches';
import findStringMatches from 'common/util/findStringMatches';

import TextInput from './TextInput';

import 'css/components/_TextInputWithSuggestions.scss';

export default class TextInputWithSuggestions extends Component {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    hideSuggestionsOnTyping: PropTypes.bool,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      })
    ),
    placeholder: PropTypes.string,
    selectOnFocus: PropTypes.bool,
    suggestMatches: PropTypes.bool,
  };

  state = {
    dirty: false,
    focused: false,
    mouseDown: false,
    value: null,
  };

  constructor(props, context) {
    super(props, context);

    this.suggestionsRef = React.createRef();
    this.textInputContainerRef = React.createRef();
    this.textInputRef = React.createRef();
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({
      value: defaultValue ? String(defaultValue) : null,
    });

    document.addEventListener('mousedown', this.onMouseDown, false);
    document.addEventListener('mouseup', this.onMouseUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
  }

  focus = () => {
    this.setState({ focused: true }, () => {
      this.textInputRef.current.focus();
    });
  };

  selectOption = (e, option) => {
    this.setState(
      {
        dirty: false,
        value: String(option.value),
        focused: false,
      },
      () => {
        this.props.onChange?.(e, option.value);
        this.props.onBlur?.(e);
      }
    );
  };

  onInputBlur = (e) => {
    const { mouseDown } = this.state;
    if (!mouseDown) {
      this.setState({ dirty: false, focused: false }, () => {
        this.props.onBlur?.(e);
      });
    }
  };

  onInputChange = (e) => {
    const newValue = e.target.value.trim();
    this.setState({ dirty: true, value: newValue });
    this.props.onChange?.(e, newValue);
  };

  onInputFocus = (e) => {
    const { selectOnFocus } = this.props;
    this.setState({ focused: true }, () => {
      if (selectOnFocus) {
        this.textInputRef.current.select();
      }
    });
  };

  onMouseDown = (e) => {
    const { focused } = this.state;
    const clickedOnInput = findDOMNode(this)?.contains(e.target);
    const clickedOnSuggestions = findDOMNode(this.suggestionsRef.current)?.contains(e.target);
    if (!focused || (!clickedOnInput && !clickedOnSuggestions)) {
      return;
    }

    this.setState({ mouseDown: true });
  };

  onMouseUp = () => {
    this.setState({ mouseDown: false });
  };

  renderOption = (option) => {
    const { value } = this.state;
    return (
      <Tappable onTap={(e) => this.selectOption(e, option)} key={option.value}>
        <div className="option">{emphasizeMatches(option.label, value)}</div>
      </Tappable>
    );
  };

  renderOptions = () => {
    const { disabled, hideSuggestionsOnTyping, options, suggestMatches } = this.props;
    const { dirty, focused, value } = this.state;
    if (disabled || !focused || (hideSuggestionsOnTyping && dirty && value)) {
      return null;
    }

    const matchingOptions = suggestMatches
      ? findStringMatches(options, 'label', value ?? '')
      : options;

    if (matchingOptions.length < 1) {
      return null;
    }

    return (
      <Portal
        align="start"
        className="textInputWithSuggestions portal"
        position="bottom"
        relativeToRef={this.textInputContainerRef}>
        <div className="suggestions" ref={this.suggestionsRef}>
          {matchingOptions.map(this.renderOption)}
        </div>
      </Portal>
    );
  };

  render() {
    const { className, disabled, maxLength, placeholder } = this.props;
    const { value } = this.state;

    return (
      <div className="textInputWithSuggestions">
        <div className="inputContainer" ref={this.textInputContainerRef}>
          <TextInput
            className={className}
            disabled={disabled}
            value={value}
            maxLength={maxLength}
            onBlur={this.onInputBlur}
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            onKeyDown={this.props.onKeyDown}
            placeholder={placeholder}
            ref={this.textInputRef}
          />
        </div>
        {this.renderOptions()}
      </div>
    );
  }
}

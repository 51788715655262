/**
 * delayer
 *
 * Like setTimeout, but if called twice before the delay is up, it'll cancel the first call.
 * Useful utility for Typeahead searches. You don't want to call every time the user types,
 * but probably after they've stopped typing for a few hundred milliseconds.
 *
 * 1. Create the delayer
 *    this._delayer = new delayer(callback, delay);
 *
 * 2. TextInput.onChange
 *    this._delayer.callAfterDelay();
 *
 * 3. TextInput.onChange
 *    this._delayer.callAfterDelay(); // cancels first call
 *
 * Note: since we use Refs to store delayer, any values stored within our callback will persist until ref.current is updated.
 * If the callback relies on external factors that may change after instantiation of delayer (i.e. query params), use the
 * useDelayer custom hook instead of directly initializing delayer in your component.
 *
 */

export default class delayer {
  constructor(callback, delay) {
    this._callback = callback;
    this._delay = delay;
    this._timeoutID = null;
  }

  callAfterDelay = (...args) => {
    args.forEach((arg) => {
      // If the arg is an event then we need to call persist on it since we're using it
      // asynchronously. Read more: https://facebook.github.io/react/docs/events.html
      if (arg !== null && typeof arg === 'object' && typeof arg.persist === 'function') {
        arg.persist();
      }
    });
    this.cancel();
    this._timeoutID = setTimeout(this._callCallback.bind(this, ...args), this._delay);
  };

  _callCallback = (...args) => {
    this._timeoutID = null;
    this._callback.call(null, ...args);
  };

  cancel = () => {
    if (!this._timeoutID) {
      return;
    }
    clearTimeout(this._timeoutID);
    this._timeoutID = null;
  };

  isPending = () => {
    return !!this._timeoutID;
  };
}

import devURL from './devURL';
import queryString from './queryString';

export default function getAuthRedirectURL(company, location, widgetConfig) {
  const { authRedirectURL } = company;

  const containsQuery = authRedirectURL.indexOf('?') !== -1;
  const baseURL = containsQuery
    ? authRedirectURL.slice(0, authRedirectURL.indexOf('?'))
    : authRedirectURL;
  const queryParams = containsQuery
    ? queryString.parse(authRedirectURL.slice(authRedirectURL.indexOf('?')))
    : {};

  const redirectURL = removeDuplicateSlahes(getRedirectURL(company, location, widgetConfig));
  queryParams.companyID = company._id;
  queryParams.redirect = redirectURL;

  const query = queryString.stringify(queryParams);
  return baseURL + query;
}

function getRedirectURL(company, location, widgetConfig) {
  if (widgetConfig) {
    return (
      widgetConfig.origin +
      widgetConfig.basePath +
      location.pathname +
      queryString.stringify(location.query)
    );
  }

  const { subdomain } = company;
  const protocol = 'https://';
  const hostname = company.primaryDomain ? company.primaryDomain : subdomain + '.canny.io';
  const path = location.pathname;
  const query = queryString.stringify(location.query);
  return devURL(protocol + hostname + path + query);
}

function removeDuplicateSlahes(redirectURL) {
  return redirectURL.replace(/([^:])\/(\/)+/g, '$1/');
}

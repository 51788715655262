import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import getCannyOrigin from 'common/util/getCannyOrigin';
import withContexts from 'common/util/withContexts';

import Helmet from './Helmet';

class SubdomainCreatePostHelmet extends Component {
  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  render() {
    const { board, company } = this.props;
    if (!company || !company.name || !board || !board.name) {
      return null;
    }

    const url = getCannyOrigin(company) + '/' + board.urlName + '/create';
    const title = 'Create new post | ' + board.name + ' | ' + company.name;
    return <Helmet title={title} link={[{ href: url, rel: 'canonical' }]} />;
  }
}

export default withContexts({ company: CompanyContext })(SubdomainCreatePostHelmet);

import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import KeyCodes from 'common/KeyCodes';
import Tappable from 'common/Tappable';

import TextInput from './TextInput';

import 'css/components/_SearchInput.scss';

export default class SearchInput extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool.isRequired,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    showClearIcon: PropTypes.bool,
    showSearchIcon: PropTypes.bool,
    stopPropagation: PropTypes.bool,
    styling: PropTypes.string,
    inputMode: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: true,
    disabled: false,
    showClearIcon: true,
    showSearchIcon: true,
    stopPropagation: true,
  };

  state = {
    showClearIcon: !!this.props.defaultValue,
  };

  constructor(props) {
    super(props);
    this._value = '';
    this.textInputRef = React.createRef();
  }

  blur = () => {
    this.textInputRef.current.blur();
  };

  clearSearch = () => {
    this.textInputRef.current.setValue('');
    this._value = '';
    this.focus();
    setTimeout(this.processChange, 0);
  };

  focus = () => {
    this.textInputRef.current.focus();
  };

  getValue = () => {
    return this._value;
  };

  setValue = (value) => {
    this._value = value;
    this.textInputRef.current.setValue(value);
    this.setState({ showClearIcon: !!value });
  };

  setCursorPosition = (cursorPosition) => {
    this.textInputRef.current.setCursorPosition(cursorPosition);
  };

  onTextChange = (e) => {
    this._value = e.target.value;
    setTimeout(this.processChange, 0);
  };

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();
  };

  onFocus = () => {
    this.props.onFocus && this.props.onFocus(this._value);
  };

  onKeyDown = (e) => {
    if (e.which !== KeyCodes.Escape) {
      return;
    }

    this.clearSearch();
  };

  processChange = () => {
    this.setState({ showClearIcon: !!this._value });
    this.props.onChange && this.props.onChange(this._value);
  };

  renderClearIcon() {
    const { showClearIcon } = this.state;

    if (!this.props.showClearIcon || !showClearIcon) {
      return null;
    }

    return (
      <Tappable onTap={this.clearSearch}>
        <div className="icon icon-x" />
      </Tappable>
    );
  }

  renderInset() {
    if (!this.props.showSearchIcon) {
      return null;
    }

    return <div className="icon icon-search" />;
  }

  render() {
    return (
      <div
        className={classNames('searchInputContents', this.props.className, {
          v2: this.props.styling === 'v2',
        })}>
        <TextInput
          className="searchInput"
          autoFocus={this.props.autoFocus}
          defaultValue={this.props.defaultValue}
          disabled={this.props.disabled}
          inset={this.renderInset()}
          placeholder={this.props.placeholder}
          onBlur={this.onBlur}
          onChange={this.onTextChange}
          onFocus={this.onFocus}
          onKeyDown={this.onKeyDown}
          ref={this.textInputRef}
          stopPropagation={this.props.stopPropagation}
          inputMode={this.props.inputMode}
          type={this.props.type}
        />
        {this.renderClearIcon()}
      </div>
    );
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import FileRenderer from 'common/file/FileRenderer';
import { createFiles, createImageFiles } from 'common/file/utils/createFiles';
import AccessModal from 'common/modals/AccessModal';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';

import CommentBody from './CommentBody';
import CommentComposer from './CommentComposer';
import CommentMenu from './CommentMenu';
import PinnedLabel from './PinnedLabel';
import { invalidatePostQueries } from '../actions/postQueries';
import { reloadPost } from '../actions/posts';
import { reloadPostActivity } from '../actions/postsActivity';
import AJAX from '../AJAX';
import connect from '../core/connect';
import ConfirmModal from '../modals/ConfirmModal';
import Tappable from '../Tappable';
import UserLockup from '../user/UserLockup';

import 'css/components/comment/_Comment.scss';

class Comment extends Component {
  static propTypes = {
    board: PropTypes.object,
    comment: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      author: PropTypes.shape({
        _id: PropTypes.string,
        alias: PropTypes.string.isRequired,
      }).isRequired,
      files: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }))
        .isRequired,
      imageURLs: PropTypes.arrayOf(PropTypes.string).isRequired,
      postID: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      vote: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        by: PropTypes.shape({
          _id: PropTypes.string,
          aliasID: PropTypes.string.isRequired,
        }),
        score: PropTypes.number,
        user: PropTypes.shape({
          _id: PropTypes.string,
          alias: PropTypes.string.isRequired,
          aliasID: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          urlName: PropTypes.string,
        }),
      }),
    }).isRequired,
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    openModal: PropTypes.func.isRequired,
    pinned: PropTypes.bool,
    post: PropTypes.object,
    replies: PropTypes.array,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    replies: [],
  };

  state = {
    editMode: false,
    hideTranslation: false,
  };

  componentDidMount() {
    this._deleting = false;
  }

  onCancelEdit = () => {
    this.setState({
      editMode: false,
    });
  };

  onCommentEdited = () => {
    this.setState({
      editMode: false,
    });
  };

  onConfirmDeleteComment = () => {
    if (this._deleting) {
      return;
    }
    this._deleting = true;

    const { comment, invalidateData, post } = this.props;
    AJAX.post(
      '/api/comments/delete',
      {
        commentID: comment._id,
      },
      (response) => {
        invalidateData(post).then(() => {
          this._deleting = false;
        });
      }
    );
  };

  onDeleteComment = () => {
    const { comment, company, openModal, viewer } = this.props;
    const { viewerIsMember } = company;
    const viewerIsAuthor = comment.author._id === viewer._id;
    const needToCheckPermissions = !viewerIsAuthor && viewerIsMember;
    if (needToCheckPermissions) {
      if (!hasPermission('deleteComments', company, viewer)) {
        openModal(AccessModal, {
          requiredPermissions: ['deleteComments'],
        });
        return;
      }
    }

    openModal(ConfirmModal, {
      message: "Are you sure you'd like to delete this comment?",
      onConfirm: this.onConfirmDeleteComment,
    });
  };

  onEditComment = () => {
    this.setState({
      editMode: true,
    });
  };

  onMarkedNotSpam = async () => {
    const { invalidateData, post } = this.props;
    await invalidateData(post);
  };

  onMarkedSpam = () => {
    const { invalidateData, post } = this.props;
    invalidateData(post);
  };

  onPinToggle = () => {
    const { invalidateData, post } = this.props;
    invalidateData(post);
  };

  onToggleTranslation = () => {
    this.setState({
      hideTranslation: !this.state.hideTranslation,
    });
  };

  showAlias(user) {
    const {
      board,
      company: { viewerIsMember },
      viewer,
    } = this.props;

    const isUserViewer = user._id === viewer._id;
    return board.settings.privateAuthors && isUserViewer && !viewerIsMember;
  }

  renderComment() {
    const { editMode, hideTranslation } = this.state;
    const { board, comment, company, pinned, post } = this.props;
    const files = [
      ...createImageFiles(comment.imageURLs || []),
      ...createFiles(comment.files || []),
    ];

    const hasText = comment.value.length > 0;
    // files are undefined in the case of pinnedComments
    const hasFiles = comment.imageURLs.length > 0 || comment.files?.length > 0;

    const containerClass = hasFiles && !hasText ? 'bottomContainer filesOnly' : 'bottomContainer';

    if (editMode) {
      return (
        <div className="bottomContainer">
          <CommentComposer
            autoFocus={true}
            board={board}
            comment={comment}
            onCancelEdit={this.onCancelEdit}
            onCommentEdited={this.onCommentEdited}
            post={post}
          />
        </div>
      );
    }

    return (
      <div className={containerClass}>
        <CommentBody comment={comment} hideTranslation={hideTranslation} />
        <FileRenderer className="commentFileRenderer" allowRemove={false} files={files} />
        <CommentMenu
          board={board}
          comment={comment}
          company={company}
          hideTranslation={hideTranslation}
          onEditComment={this.onEditComment}
          onMarkedNotSpam={this.onMarkedNotSpam}
          onMarkedSpam={this.onMarkedSpam}
          onPinToggle={this.onPinToggle}
          onToggleTranslation={this.onToggleTranslation}
          pinned={pinned}
          post={post}
        />
      </div>
    );
  }

  renderDeleteComment() {
    const { editMode } = this.state;
    const { pinned } = this.props;
    if (editMode || pinned) {
      return;
    }

    const {
      comment,
      company: { viewerIsMember },
      viewer,
    } = this.props;
    const viewerIsAuthor = comment.author._id === viewer._id;
    const viewerIsBae = viewer.cannyBae;

    if (!viewerIsAuthor && !viewerIsMember && !viewerIsBae) {
      return null;
    }

    return (
      <Tappable onTap={this.onDeleteComment}>
        <span className="icon icon-x" />
      </Tappable>
    );
  }

  renderReplies() {
    const { board, comment, invalidateData, post, replies } = this.props;
    if (!replies || replies.length === 0) {
      return null;
    }

    const list = [];
    replies.forEach((reply) => {
      list.push(
        <CommentWithContext
          key={reply._id}
          board={board}
          comment={reply}
          invalidateData={invalidateData}
          parent={comment}
          post={post}
          replies={[]}
        />
      );
    });

    return <div className="replies">{list}</div>;
  }

  renderSpamFlag() {
    const { comment, company } = this.props;
    const { viewerIsMember } = company;

    if (!comment.spam || !viewerIsMember) {
      return null;
    }

    return (
      <Pill className="commentPill" pillStyle={DefaultPillStyles.destructive}>
        Spam
      </Pill>
    );
  }

  renderVoteSection() {
    const { board, comment } = this.props;
    const { author, vote } = comment;

    if (!vote || !vote.user) {
      return null;
    }

    // In anonymized boards mode, _id's are hidden for non-members+viewer, so we need to compare aliasIDs
    const hasVotedOnBehalf = board.settings.privateAuthors
      ? vote.user.aliasID !== author.aliasID
      : vote.user._id !== author._id;

    if (!hasVotedOnBehalf) {
      return null;
    }

    const showAlias = this.showAlias(vote.user);

    return (
      <>
        <div className="voteMessage">voted on behalf of</div>
        <UserLockup
          showAvatar={false}
          showCompanyNames={true}
          user={vote.user}
          showAlias={showAlias}
        />
      </>
    );
  }

  render() {
    const { comment, company, pinned } = this.props;
    const { viewerIsMember } = company;
    const isSpam = comment.spam && viewerIsMember;
    const showAlias = this.showAlias(comment.author);

    return (
      <div
        className={classnames({
          comment: true,
          internal: comment.internal,
          pinned,
          spamComment: isSpam,
        })}>
        <div className="topContainer">
          <div className="left">
            <>
              <UserLockup user={comment.author} showAlias={showAlias} />
              {this.renderSpamFlag()}
              {this.renderVoteSection()}
            </>
          </div>
          <div className="right">
            {pinned && <PinnedLabel />}
            {this.renderDeleteComment()}
          </div>
        </div>
        {this.renderComment()}
        {this.renderReplies()}
      </div>
    );
  }
}

// Need to do this becuase Comment renders itself
const CommentWithContext = compose(
  connect(null, (dispatch) => ({
    invalidateData: (post) => {
      return Promise.all([
        dispatch(invalidatePostQueries()),
        dispatch(reloadPostActivity(post)),
        dispatch(reloadPost(post)),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(Comment);

export default CommentWithContext;

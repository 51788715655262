import deepCompare from 'react-fast-compare';

import Data from '../Data';

// Constants

const DefaultLimit = 10;
const DefaultSort = 'newest';
const DefaultOptions = {
  limit: DefaultLimit,
  internalComments: 'allowed',
  sort: DefaultSort,
};

// Action Types

export const InvalidateAllPostActivities = 'canny/posts/invalidate_all_post_activities';
export const InvalidatePostActivities = 'canny/posts/invalidate_post_activities';
export const RequestPostActivity = 'canny/posts/request_post_activity';
export const PostActivityLoaded = 'canny/posts/post_activity_loaded';
export const PostActivityNotFound = 'canny/posts/post_activity_not_found';
export const PostActivityError = 'canny/posts/post_activity_error';

// Actions

export function invalidateAll() {
  return {
    timestamp: Date.now(),
    type: InvalidateAllPostActivities,
  };
}

export function invalidate(posts) {
  return {
    posts,
    timestamp: Date.now(),
    type: InvalidatePostActivities,
  };
}

export function requestPostActivity(post, options) {
  return {
    options,
    post,
    timestamp: Date.now(),
    type: RequestPostActivity,
  };
}

export function postActivityLoaded(post, postActivity, options) {
  return {
    options,
    post,
    postActivity,
    timestamp: Date.now(),
    type: PostActivityLoaded,
  };
}

function postActivityNotFound(post) {
  return {
    post,
    timestamp: Date.now(),
    type: PostActivityNotFound,
  };
}

function postActivityError(post, error) {
  return {
    error,
    post,
    timestamp: Date.now(),
    type: PostActivityError,
  };
}

// Action Creators

export function fetchPostActivity(post, options) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        postActivity: {
          input: {
            limit: options.limit || DefaultLimit,
            postID: post._id,
            internalComments: options.internalComments,
            text: options.text,
            segmentURLName: options.segmentURLName,
            sort: options.sort || DefaultSort,
          },
          query: Data.queries.postActivity,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, getState(), post, error, data, options);
      }
    );
  };
}

export function invalidateAllPostActivities() {
  return (dispatch) => {
    return dispatch(invalidateAll());
  };
}

export function invalidatePostActivities(posts) {
  return (dispatch) => {
    return dispatch(invalidate(posts));
  };
}

export function loadPostActivity(post, options = DefaultOptions) {
  return (dispatch, getState) => {
    if (shouldFetchPostActivity(getState(), post, options)) {
      dispatch(requestPostActivity(post, options));
      return dispatch(fetchPostActivity(post, options));
    } else if (isFetchingPostActivity(getState(), post)) {
      return waitForResult(post);
    }
  };
}

export function loadMorePostActivity(post) {
  return (dispatch, getState) => {
    const { postsActivity } = getState();
    const postActivity = postsActivity[post._id];
    const { loading, options, hasMore } = postActivity;
    const { limit, internalComments, segmentURLName, sort } = options;

    if (loading || !hasMore) {
      return;
    }

    return dispatch(
      loadPostActivity(post, {
        limit: limit + 10,
        internalComments,
        segmentURLName,
        sort,
      })
    );
  };
}

export function reloadPostActivity(post) {
  return (dispatch, getState) => {
    const options = getOptions(getState(), post);
    dispatch(requestPostActivity(post, options));
    return dispatch(fetchPostActivity(post, options));
  };
}

// Helper Functions

const getOptions = (state, post) => {
  if (!post || !post._id) {
    return DefaultOptions;
  }

  const { postsActivity } = state;
  const postActivity = postsActivity[post._id];
  if (!postActivity) {
    return DefaultOptions;
  }

  return postActivity.options;
};

function shouldFetchPostActivity(state, post, options) {
  if (!post || !post._id) {
    return false;
  }

  const { postsActivity } = state;
  const postActivity = postsActivity[post._id];
  if (!postActivity) {
    return true;
  }

  const { options: currentOptions } = postActivity;

  // If some value has changed, it should fetch
  const keys = ['limit', 'internalComments', 'text', 'segmentURLName', 'sort'];
  return keys.some((key) => options[key] !== currentOptions[key]);
}

function isFetchingPostActivity(state, post) {
  if (!post || !post._id) {
    return false;
  }

  const { postsActivity } = state;
  const postActivity = postsActivity[post._id];

  return postActivity && postActivity.loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(post) {
  return new Promise((resolve, reject) => {
    resultCallbacks[post._id] = resultCallbacks[post._id] || [];
    resultCallbacks[post._id].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, state, post, error, data, options) {
  const stateOptions = getOptions(state, post);
  if (!deepCompare(options, stateOptions)) {
    // The options are not the same, meaning that a new request has been made, discard the newly received result
    return;
  }

  var resultAction;
  if (error === 'not found') {
    resultAction = postActivityNotFound(post);
  } else if (error) {
    resultAction = postActivityError(post, error);
  } else {
    resultAction = postActivityLoaded(post, data.postActivity, options);
  }

  const promises = [dispatch(resultAction)];

  return Promise.all(promises).then(() => {
    if (!resultCallbacks[post._id]) {
      return;
    }

    resultCallbacks[post._id].forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks[post._id].length = 0;
  });
}

import type { AsanaTask } from 'common/api/endpoints/asanaTasks';
import type { StrippedUser } from 'common/api/endpoints/users';
import type { Board, Category } from 'common/api/resources/board';
import type { Translation } from 'common/api/resources/i18n';
import type { BoardField, CustomPostField } from 'common/api/resources/postFields';
import type { ObjectFile } from 'common/constants/files';

export enum PostSummaryStatus {
  pending = 'pending',
  complete = 'complete',
  failed = 'failed',
}

export interface PostSummary {
  _id: string;
  companyID: string;
  createdAt: string; // ISO 8601 date
  deleted: boolean;
  postID: string;
  status: PostSummaryStatus;
  summarizerID: string;
  summary: string;
  updatedAt: string; // ISO 8601 date
}

export interface Tag {
  boardID: string;
  created: string;
  creatorID: string;
  deleted: boolean;
  deletedDate?: string;
  deletorID?: string;
  name: string;
  postCount: number;
  urlName: string;
  _id: string;
}

// The default CustomPostField object
export interface DefaultCustomPostFields {
  _id: string;
  customPostFieldID: string;
  value: string | string[]; // Might be more than just this
}

// Some endpoints return a complete set of customPostFields, some return fields as-is
export interface Post<CPF = CustomPostField> {
  _id: string;
  asanaTasks: AsanaTask[] | null;
  author?: StrippedUser;
  authorID: string | null;
  board: Board & { boardFields: BoardField[] };
  boardID: string;
  byID: string | null;
  category?: Category;
  categoryID: string | null;
  commentCount: number;
  companyID: string;
  created?: string; // ISO string
  customPostFields: CPF[];
  deleted: boolean;
  details: string;
  duplicatePostID: string | null;
  duplicatePost?: Post & {
    board?: Board & { boardFields: BoardField[] };
    author: StrippedUser;
  };
  eta: string | null;
  etaPublic: boolean;
  files: ObjectFile[];
  fileURLs: string[];
  imageURLs: string[];
  internalCommentCount: number;
  maxScore: number;
  mergeCount: number;
  ownerAssignerID: string;
  owner?: StrippedUser;
  ownerID: string | null;
  score: number;
  spam: boolean;
  status: string;
  statusChanged: Date;
  subCategoryID: string | null;
  tags: Tag[];
  tagIDs: string[];
  title: string;
  totalMRR: number;
  totalOpportunityValue: number;
  translation?: Translation<Post>;
  trendingScore: number;
  summary?: PostSummary;
  urlName: string;
  updatedAt: string; // ISO 8601 date
}

type StrippedFields =
  | '_id'
  | 'authorID'
  | 'boardID'
  | 'commentCount'
  | 'created'
  | 'details'
  | 'internalCommentCount'
  | 'score'
  | 'status'
  | 'title'
  | 'urlName';
export type StrippedPost<A extends Partial<Post>> = Pick<Post, StrippedFields> & A;

import React, { Component } from 'react';

import classnames from 'classnames';
import { Merge } from 'lucide-react';
import PropTypes from 'prop-types';

import IconButtonV2 from 'common/ui/IconButtonV2';

import MergePostDropdown from './MergePostDropdown';

import 'css/components/post/_MergePostMenu.scss';

export default class MergePostMenu extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
      urlName: PropTypes.string,
    }),
    boards: PropTypes.array,
    company: PropTypes.shape({
      fullScreenAdminView: PropTypes.bool,
      subdomain: PropTypes.string,
    }),
    onPostMerged: PropTypes.func,
    post: PropTypes.shape({
      status: PropTypes.string,
      voters: PropTypes.array,
    }),
    showToast: PropTypes.func,
  };

  state = {
    menuOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  onDocumentClick = (e) => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return;
    }

    const confirmModal = document.querySelector('.confirmModal');
    if (confirmModal?.contains(e.target)) {
      return;
    }

    const container = this.containerRef.current;
    if (!container?.contains(e.target)) {
      // touchend happens outside of menu, close menu
      this.setState({ menuOpen: false });
      return;
    }
  };

  onPostMerged = (intoPost, toBoard) => {
    const { onPostMerged } = this.props;
    this.setState({ menuOpen: false }, () => onPostMerged(intoPost, toBoard));
  };

  renderMenu = () => {
    const { menuOpen } = this.state;
    const { board, boards, post } = this.props;

    if (!menuOpen) {
      return null;
    }

    return (
      <div className="mergeDropdownContainer">
        <MergePostDropdown
          autoFocus={true}
          board={board}
          boards={boards}
          onPostMerged={this.onPostMerged}
          post={post}
        />
      </div>
    );
  };

  toggleMenu = () => {
    this.setState(({ menuOpen }) => ({ menuOpen: !menuOpen }));
  };

  render() {
    const { menuOpen } = this.state;

    return (
      <div className="mergePostMenu" ref={this.containerRef}>
        <IconButtonV2
          className={classnames('mergeIcon', {
            menuOpen,
          })}
          size="large"
          icon={Merge}
          variant="plain"
          aria-label="Close button"
          onClick={this.toggleMenu}
        />
        {this.renderMenu()}
      </div>
    );
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadPost } from 'common/actions/posts';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Tappable from 'common/Tappable';
import withContexts from 'common/util/withContexts';

import AdminCreateGithubIssueModal from './AdminCreateGithubIssueModal';
import AdminGitHubIssueForm from './AdminGitHubIssueForm';
import AdminGitHubIssues from './AdminGitHubIssues';
import AdminSidebarSection from './AdminSidebarSection';

import 'css/components/subdomain/admin/_AdminGitHubPostSidebarSection.scss';

class AdminGitHubPostSidebarSection extends Component {
  static propTypes = {
    company: PropTypes.shape({
      github: PropTypes.shape({
        installationID: PropTypes.string,
      }),
    }),
    post: PropTypes.object,
  };

  state = {
    formOpen: false,
  };

  onCreateIssue = () => {
    const { board, openModal, post } = this.props;
    openModal(AdminCreateGithubIssueModal, {
      board,
      onIssueCreated: () => {
        const { reloadPost } = this.props;
        reloadPost(post);
      },
      post,
    });
  };

  onToggleTypeahead = () => {
    const { formOpen } = this.state;
    this.setState({
      formOpen: !formOpen,
    });
  };

  renderAction() {
    return (
      <Tappable onTap={this.onToggleTypeahead}>
        <div>Link issue</div>
      </Tappable>
    );
  }

  renderCreateButton() {
    return (
      <Tappable onTap={this.onCreateIssue}>
        <div className="createButton">Create new issue</div>
      </Tappable>
    );
  }

  renderIssueForm() {
    const { formOpen } = this.state;
    if (!formOpen) {
      return null;
    }

    const { post } = this.props;
    return <AdminGitHubIssueForm post={post} />;
  }

  render() {
    const {
      company: { github },
    } = this.props;
    if (!github || !github.installationID) {
      // should we have a link to the settings page if this hasn't been installed?
      return null;
    }

    const { post } = this.props;
    return (
      <AdminSidebarSection
        action={this.renderAction()}
        className="adminGitHubPostSidebarSection"
        title="GitHub Issues">
        {this.renderIssueForm()}
        <AdminGitHubIssues post={post} />
        {this.renderCreateButton()}
      </AdminSidebarSection>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => dispatch(reloadPost(post)),
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminGitHubPostSidebarSection);

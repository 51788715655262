import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import SearchInput from 'common/inputs/SearchInput';
import Tappable from 'common/Tappable';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import delayer from 'common/util/delayer';
import withContexts from 'common/util/withContexts';

import CategoriesDropdown from './CategoriesDropdown';
import FilterSortDropdown from './FilterSortDropdown';

import 'css/components/post/_PostListMenu.scss';

class PostListMenu extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    board: PropTypes.object,
    location: PropTypes.shape({
      query: PropTypes.shape({
        search: PropTypes.string,
        sort: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
    router: PropTypes.object,
    searchMode: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    autoFocus: true,
  };

  state = {
    searchMode: this.props.searchMode || !!this.props.location.query.search,
  };

  constructor(props, context) {
    super(props, context);

    this._searchDelayer = new delayer(this.onSearchInputChangeAfterDelay, 250);
    this.searchInputRef = React.createRef();
  }

  componentWillUnmount() {
    this._searchDelayer.cancel();
  }

  updateQuery(query) {
    const { router, location } = this.props;
    router.replace({ ...location, query });
  }

  onCloseSearch = () => {
    this.setState({
      searchMode: false,
    });
    this.searchInputRef.current.setValue('');

    this.updateQuery({
      search: undefined,
    });
  };

  onOpenSearch = () => {
    this.setState(
      {
        searchMode: true,
      },
      () => {
        this.searchInputRef.current.focus();
      }
    );
  };

  onSearchInputChange = (searchQuery) => {
    if (!searchQuery) {
      const { search, ...newQuery } = this.props.location.query;
      this.updateQuery(newQuery);
      this._searchDelayer.cancel();
      return;
    }

    this._searchDelayer.callAfterDelay(searchQuery);
  };

  onSearchInputChangeAfterDelay = (searchQuery) => {
    const {
      searchMode,
      location: { query },
    } = this.props;
    const encodedSearch = encodeURIComponent(searchQuery);
    this.updateQuery(
      searchMode
        ? {
            ...query,
            search: encodedSearch,
          }
        : {
            search: encodedSearch,
          }
    );
  };

  renderMenu() {
    const { board } = this.props;
    if (this.state.searchMode) {
      return null;
    }

    const showCategoriesDropdown = board && board.categories && board.categories.length > 0;

    return (
      <div className="menu">
        <label>
          <span className="text">Showing</span>
          <FilterSortDropdown />
        </label>
        {showCategoriesDropdown ? (
          <label>
            <span className="text">posts in</span>
            <CategoriesDropdown board={board} />
          </label>
        ) : (
          <span className="text">posts</span>
        )}
      </div>
    );
  }

  renderDismissSearch() {
    if (!this.state.searchMode) {
      return null;
    }

    return (
      <Tappable onTap={this.onCloseSearch}>
        <div key="x" className="icon icon-x" />
      </Tappable>
    );
  }

  renderSearchMode() {
    var className = 'searchContainer';
    const { searchMode } = this.state;
    if (searchMode) {
      className += ' active';
    }
    const { autoFocus, location } = this.props;
    const textSearch = decodeQueryTextParam(location.query.search);
    return (
      <div className={className}>
        <Tappable onTap={this.onOpenSearch}>
          <div className="searchBar">
            <SearchInput
              autoFocus={searchMode && autoFocus}
              defaultValue={textSearch}
              onChange={this.onSearchInputChange}
              placeholder={'Search…'}
              ref={this.searchInputRef}
              showClearIcon={false}
              stopPropagation={false}
            />
          </div>
        </Tappable>
        {this.renderDismissSearch()}
      </div>
    );
  }

  render() {
    return (
      <div className="postListMenu">
        {this.renderMenu()}
        {this.renderSearchMode()}
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  router: RouterContext,
})(PostListMenu);

import type {
  CalculationFieldTypes,
  CalculationFunctions,
} from 'common/prioritization/CalculationFactorTypes';
import type {
  CustomDescriptionFieldTypes,
  DefaultDescriptionColumnFields,
  DefaultDescriptionFieldTypes,
  DescriptionColumnTypes,
} from 'common/prioritization/DescriptionColumnTypes';
type EffortType = 'fibonacci' | 'numberToOneHundred' | 'numberToTen' | 'stars';

type Calculation = {
  field: string | null;
  fieldID: string | null;
  fieldType: CalculationFieldTypes;
  segmentID: string | null;
  function: CalculationFunctions;
};

export type Settings = {
  companyID: string;
  groupBy: string;
  userID: string;
  roadmapID: string;
  hiddenColumnIDs: string[];
  columnWidths: Record<string, number>;
  lastActiveFilters?: {
    filterQuery: string;
    viewID: string | null;
  };
};

export type DescriptionColumn = {
  _id: string;
  companyID: string;
  created: string;
  createdBy: string;
  field: DefaultDescriptionColumnFields | null;
  fieldID: string | null;
  fieldType: DescriptionColumnTypes;
  name: string;
  options?: string[];
  roadmapID: string;
  type: DefaultDescriptionFieldTypes | CustomDescriptionFieldTypes;
};

export const FactorType = {
  calculation: 'calculation',
  checkbox: 'checkbox',
  fibonacci: 'fibonacci',
  numberToOneHundred: 'numberToOneHundred',
  numberToTen: 'numberToTen',
  percentage: 'percentage',
  stars: 'stars',
};

export type Factor = {
  _id: string;
  calculation: Calculation | null;
  companyID: string;
  created: string;
  createdBy: string;
  name: string;
  options?: string[];
  roadmapID: string;
  type: keyof typeof FactorType;
  weight: number;
  effort?: boolean;
};

export type SavedView = {
  _id: string;
  hiddenColumnIDs: string[];
  filterQuery: string;
  name: string;
};

export type Invite = {
  _id: string;
  roadmapID: string;
  email: string;
  roadmapSavedSettingsViewID: string;
  name?: string;
  avatarURL?: string | null;
};

export type Roadmap = {
  _id: string;
  archived: boolean;
  columns: string[];
  companyID: string;
  created: string;
  createdBy: string;
  deleted: boolean;
  deletedBy: string | null;
  descriptionColumns: DescriptionColumn[];
  effortType: EffortType;
  factors: Factor[];
  name: string;
  roadmapPostCount: number;
  savedViews: SavedView[];
  settings: Settings;
  urlName: string;
  invites: Invite[];
  externalAccess?: boolean;
  filterQuery?: string;
};

import React from 'react';

import { Span } from 'common/ui/Text';

import 'css/components/subdomain/admin/AdminRoadmap/_GenericSuggestion.scss';

type Props<T> = {
  suggestion: T;
  onClick: () => void;
};

const GenericSuggestion = <T extends { name: string }>({ suggestion, onClick }: Props<T>) => {
  return (
    <button className="genericSuggestion" onClick={onClick}>
      <Span>{suggestion.name}</Span>
    </button>
  );
};

export default GenericSuggestion;

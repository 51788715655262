import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import UserAvatar from './UserAvatar';

import 'css/components/user/_Facepile.scss';

const Facepile = ({ className, maxFaces, users }) => {
  const facepile = [];

  users.slice(0, maxFaces).forEach((user) => {
    facepile.push(
      <div key={user._id} className="face">
        <UserAvatar showBadge={false} user={user} />
      </div>
    );
  });

  return <div className={classnames('facepile', className)}>{facepile}</div>;
};

Facepile.propTypes = {
  maxFaces: PropTypes.number,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      avatarURL: PropTypes.string,
    })
  ),
};

Facepile.defaultProps = {
  maxFaces: 5,
};

export default Facepile;

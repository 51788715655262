import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadViewer } from 'common/actions/viewer';
import AJAX from 'common/AJAX';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import * as FileUploader from 'common/FileUploader';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import FileInput from 'common/inputs/FileInput';
import TextInput from 'common/inputs/TextInput';
import Spinner from 'common/Spinner';
import Strings from 'common/Strings';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import Alert, { AlertTypes } from 'common/ui/Alert';
import UserAvatar from 'common/user/UserAvatar';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminAccountSettings.scss';

class AdminAccountSettings extends Component {
  static propTypes = {
    viewer: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  state = {
    addingImage: false,
    avatarURL: this.props.viewer.avatarURL,
    edited: false,
    editing: false,
    error: null,
    hasEdits: false,
  };

  constructor(props, context) {
    super(props, context);

    this.emailRef = React.createRef();
    this.nameRef = React.createRef();
  }

  onFormChange = () => {
    this.setState({
      edited: false,
      hasEdits: true,
    });
  };

  onNewImage = async (image) => {
    if (!image) {
      return;
    }

    this.setState({
      addingImage: true,
    });

    const { viewer } = this.props;
    let imageURL = null;
    let error = null;

    try {
      imageURL = await FileUploader.upload(image, viewer);
    } catch (err) {
      error = err;
    }

    if (error || !imageURL) {
      const message =
        error?.data?.message ?? 'Something went wrong, please try again or contact support';
      this.setState({
        addingImage: false,
        error: message,
      });
      return;
    }

    this.setState({
      addingImage: false,
      avatarURL: imageURL,
      edited: false,
      hasEdits: true,
    });
  };

  onSubmit = () => {
    const name = this.nameRef.current.getValue().trim();
    const email = this.emailRef.current.getValue().trim();

    var error = null;
    if (!validateInput.userName(name)) {
      error = 'Please enter a valid name (2-50 characters)';
    } else if (!validateInput.email(email)) {
      error = 'Please enter a valid email';
    }

    if (error) {
      this.setState({ error });
      return;
    }

    this.setState({
      editing: true,
      error: null,
    });

    AJAX.post(
      '/api/viewer/edit',
      {
        avatarURL: this.state.avatarURL,
        email,
        name,
      },
      (response) => {
        if (response === '{"error":"email taken"}') {
          this.setState({
            editing: false,
            error: 'This email is taken.',
          });
          return;
        } else if (response !== 'success') {
          this.setState({
            editing: false,
            error: Strings.miscError,
          });
          return;
        }

        this.props.reloadViewer().then(() => {
          this.setState({
            edited: true,
            editing: false,
            error: null,
            hasEdits: false,
          });
        });
      }
    );
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderEmailChangeWarning() {
    const { viewer } = this.props;
    const { hasEdits } = this.state;

    if (!hasEdits) {
      return null;
    }

    // emails are case insensitive - ignore case on the backend endpoint as well
    if (viewer.email.toLowerCase() === this.emailRef.current?.getValue()) {
      return null;
    }

    return (
      <Alert
        className="changingEmailAlert"
        type={AlertTypes.Danger}
        headingText="Warning!"
        subText="Changing your email will disconnect your account from Google, Okta, OneLogin, and/or OpenID Connect. To reconnect, you'll need to log in with your new email through those services."
      />
    );
  }

  render() {
    const { addingImage, edited, editing, hasEdits } = this.state;
    const { viewer } = this.props;

    const avatarViewer = Object.assign({}, viewer, {
      avatarURL: this.state.avatarURL,
    });
    return (
      <div className="adminAccountSettings">
        <Helmet title="Profile Settings | Canny" />
        <AdminSettingsHeader title="Profile" subheading="Manage your Canny&nbsp;profile." />
        <Form
          className="content"
          addEventsToDocument={false}
          disableSubmit={editing || !hasEdits}
          onSubmit={this.onSubmit}>
          <div className="editUserAvatar">
            <UserAvatar user={avatarViewer} />
            <FileInput
              onFile={this.onNewImage}
              value={addingImage ? <Spinner /> : <span>Upload image</span>}
            />
          </div>
          <div className="fields">
            <TextInput
              defaultValue={viewer.name}
              inset="Name"
              onChange={this.onFormChange}
              ref={this.nameRef}
            />
            <TextInput
              defaultValue={viewer.email}
              inset="Email"
              onChange={this.onFormChange}
              ref={this.emailRef}
            />
          </div>
          {this.renderEmailChangeWarning()}
          {this.renderError()}
          <Button
            disabled={!hasEdits}
            formButton={true}
            loading={editing}
            value={edited ? 'Saved' : 'Save'}
          />
        </Form>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadViewer: () => {
      return dispatch(reloadViewer());
    },
  })),
  withContexts(
    {
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminAccountSettings);

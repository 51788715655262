import React from 'react';

import classNames from 'classnames';

import 'css/components/_SwitchV2.scss';

type Size = 'small' | 'medium' | 'large';
export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'checked' | 'size' | 'onChange'> {
  checked: boolean;
  children?: React.ReactNode;
  size?: Size;
  onChange?: (checked: boolean) => void;
  'aria-label'?: string;
}

const SwitchV2 = ({
  'aria-label': ariaLabel,
  children,
  checked,
  className,
  onChange,
  size = 'medium',
  ...props
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <span className={classNames('switchV2', className, { [size]: true, checked })}>
      <input
        {...props}
        className="switchInput"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        aria-label={ariaLabel}
      />
      <span className="switchBackground" />
      <span className="switchCircle" />
    </span>
  );
};

export default SwitchV2;

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import CreateRuleForm from './CreateRuleForm';

const ErrorMessages = {
  'similar rule exists': `There's already a rule assigned to this Linear state for this list. Please, select another one and try again.`,
};

const Rules = ({ linearRules, linearStatuses, onError, onRuleCreated, onRuleDeleted }) => {
  // state
  const [ruleOperations, _setRuleOperations] = useState({
    creatingRule: false,
    deletingRuleID: null,
    savingRule: false,
  });

  // state utils
  const setRuleOperations = (data) => _setRuleOperations((state) => ({ ...state, ...data }));

  // helpers
  const deleteRule = async (rule) => {
    setRuleOperations({ deletingRuleID: rule._id });
    const response = await AJAX.post('/api/linear/deleteRule', {
      ruleID: rule._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      setRuleOperations({ deletingRuleID: null });
      onError(error.message);
      return;
    }

    await onRuleDeleted();
    setRuleOperations({ deletingRuleID: null });
  };

  const openCreateForm = () => setRuleOperations({ creatingRule: true });
  const closeCreateForm = () => setRuleOperations({ creatingRule: false });

  const saveRule = async ({ allLinkedIssues, cannyStatus, linearStatus, shouldNotifyVoters }) => {
    setRuleOperations({ savingRule: true });

    const response = await AJAX.post('/api/linear/createRule', {
      allLinkedIssues,
      cannyStatus,
      linearStatus,
      shouldNotifyVoters,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: ErrorMessages,
    });

    if (error) {
      onError(error.message);
      setRuleOperations({ savingRule: false });
      return;
    }

    await onRuleCreated();
    setRuleOperations({ creatingRule: false, savingRule: false });
  };

  // renders
  const renderRule = (rule) => {
    const isPlural = !!rule.allLinkedIssues;
    return (
      <div className="rule" key={rule._id}>
        <div className="description">
          When <p className="underlined">{rule.allLinkedIssues ? 'all' : 'any'}</p> linked Linear{' '}
          {isPlural ? 'issues are' : 'issue is'} changed to{' '}
          <div className="linearStatusLabel underlined">{rule.linearStatus}</div>, change all linked
          Canny posts to <PostStatus showOpen={true} status={rule.cannyStatus} />, and{' '}
          {rule.shouldNotifyVoters ? '' : 'do not'} notify voters.
        </div>
        <div className="deleteContainer">
          {ruleOperations.deletingRuleID === rule._id ? (
            <Spinner />
          ) : (
            <Tappable onTap={() => deleteRule(rule)}>
              <div className="icon icon-x" />
            </Tappable>
          )}
        </div>
      </div>
    );
  };

  const render = () => {
    if (!linearRules) {
      return null;
    }

    const hasRules = linearRules.length > 0;

    return (
      <div className="rules">
        <p className="text">
          Set up rules so that when a issue's state changes, linked Canny posts are updated as well.
        </p>
        {(hasRules || ruleOperations.creatingRule) && (
          <>
            <UppercaseHeader>Rules</UppercaseHeader>
            <hr />
          </>
        )}
        {hasRules && <div className="ruleList">{linearRules.map(renderRule)}</div>}
        {ruleOperations.creatingRule ? (
          <CreateRuleForm
            linearStatuses={linearStatuses}
            loading={ruleOperations.savingRule}
            onClose={closeCreateForm}
            onSubmit={saveRule}
          />
        ) : (
          <Button className="createRuleButton" onTap={openCreateForm} value="Create New Rule" />
        )}
      </div>
    );
  };

  return render();
};

Rules.propTypes = {
  linearRules: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      allLinkedIssues: PropTypes.bool,
      cannyStatus: PropTypes.string,
      linearStatus: PropTypes.string,
    })
  ),
  linearStatuses: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func,
  onRuleCreated: PropTypes.func,
  onRuleDeleted: PropTypes.func,
};

export default Rules;

enum DefaultCompanyRoleNames {
  contributor = 'Contributor',
  manager = 'Manager',
  owner = 'Owner',
}

// Higher index = more permissions
export const OrderedDefaultRoles = [
  DefaultCompanyRoleNames.contributor,
  DefaultCompanyRoleNames.manager,
  DefaultCompanyRoleNames.owner,
];

export const hasRoleName =
  <Role extends { name: string }>(roleName: DefaultCompanyRoleNames) =>
  (role: Role) =>
    role.name === roleName;

export default DefaultCompanyRoleNames;

import React, { Component } from 'react';

import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import AJAX from 'common/AJAX';
import { RevenueTimeframes } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import SearchInput from 'common/inputs/SearchInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import AdminCreatePostModal from 'common/subdomain/admin/AdminCreatePostModal';
import Tappable from 'common/Tappable';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import delayer from 'common/util/delayer';
import { getQueryFilterParams, mapQueryFilterParamsToRequestData } from 'common/util/filterPosts';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminFeedbackPostListMenu.scss';

const DefaultSavedFilterName = 'Default';

class AdminFeedbackPostListMenu extends Component {
  static propTypes = {
    activeSavedFilter: PropTypes.string,
    board: PropTypes.object,
    boards: PropTypes.array,
    company: PropTypes.shape({
      savedFilters: PropTypes.array,
    }),
    location: PropTypes.object,
    onUpdateActiveSavedFilter: PropTypes.func.isRequired,
    openModal: PropTypes.func,
    router: PropTypes.object,
  };

  state = {
    showFilterMenu: false,
    showSortMenu: false,
  };

  constructor(props, context) {
    super(props, context);

    this._searchDelayer = new delayer(this.onSearchChangeAfterDelay, 500);

    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick, false);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { query },
    } = this.props;
    const {
      location: { query: prevQuery },
    } = prevProps;
    if (!query.search && prevQuery.search) {
      this.searchInputRef.current.setValue('');
    } else if (query.search && !prevQuery.search) {
      this.searchInputRef.current.setValue(decodeQueryTextParam(query.search));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false);
    this._searchDelayer.cancel();
  }

  onCreatePostTapped = () => {
    const {
      boards,
      location: { query },
    } = this.props;
    const boardURLNames = (query.boards ?? '').split('_').filter(Boolean);
    const board = boardURLNames.length
      ? boards.find((board) => board.urlName === boardURLNames[0])
      : this.props.board;

    this.props.openModal(AdminCreatePostModal, {
      board,
      boards: this.props.boards,
      allowCreateAnother: true,
    });
  };

  onDocumentClick = (e) => {
    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    this.setState({
      showSortMenu: false,
      showFilterMenu: false,
    });
  };

  onSavedFilterChange = (filterName) => {
    const { company } = this.props;
    this.setState({ showFilterMenu: false });
    const filter = company.savedFilters.find((filter) => filter.name === filterName);
    this.props.onUpdateActiveSavedFilter(filter);
  };

  onSearchChange = (searchValue) => {
    const encodedSearch = encodeURIComponent(searchValue);

    if (encodedSearch) {
      this._searchDelayer.callAfterDelay(encodedSearch);
      return;
    }

    this._searchDelayer.cancel();
    const {
      location: { pathname, query },
      router,
    } = this.props;
    const newQuery = Object.assign({}, query);
    if (!query.search) {
      return;
    }

    delete newQuery.search;
    if (this._previousSort) {
      newQuery['sort'] = this._previousSort;
      this._previousSort = null;
    } else {
      delete newQuery['sort'];
    }

    router.push({
      pathname,
      query: newQuery,
    });
  };

  onSearchChangeAfterDelay = (searchValue) => {
    const {
      location: { pathname, query },
      router,
    } = this.props;
    const newQuery = Object.assign({}, query);
    if (!query.search && searchValue) {
      newQuery.search = searchValue;
      delete newQuery['sort'];
      this._previousSort = query['sort'];
    } else {
      newQuery.search = searchValue;
    }

    if (query.search) {
      router.replace({
        pathname,
        query: newQuery,
      });
    } else {
      router.push({
        pathname,
        query: newQuery,
      });
    }
  };

  onSortChange = (sort) => {
    this.setState({
      showSortMenu: false,
    });

    const {
      location: { pathname, query },
      router,
    } = this.props;
    const newQuery = { ...query };
    if (query.search && sort === 'relevance') {
      delete newQuery['sort'];
    } else if (!query.search && sort === 'trending') {
      delete newQuery['sort'];
    } else {
      newQuery.sort = sort;
    }

    if (query.search) {
      this._previousSort = null;
    }

    router.push({
      pathname,
      query: newQuery,
    });
  };

  onToggleFilterMenu = () => {
    this.setState({
      showFilterMenu: !this.state.showFilterMenu,
      showSortMenu: false,
    });
  };

  onToggleSortMenu = () => {
    this.setState({
      showFilterMenu: false,
      showSortMenu: !this.state.showSortMenu,
    });
  };

  onExport = async () => {
    const { board, company, location } = this.props;
    const queryData = mapQueryFilterParamsToRequestData(
      getQueryFilterParams(board, company, location, { isWidget: false }),
      company
    );
    const response = await AJAX.post('/api/boards/export', queryData);

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.csv,
    });

    if (error) {
      return;
    }

    const currentDateString = new Date().toISOString().slice(0, 10);
    const csvName = `Canny - Exported posts - ${currentDateString}.csv`;

    saveAs(new Blob([parsedResponse.csv]), csvName);
  };

  renderSavedFilters() {
    const { activeSavedFilter } = this.props;
    return (
      <div className="dropdown filterDropdown">
        <Tappable onTap={this.onToggleFilterMenu}>
          <div className="dropdownButton">
            <div className="icon icon-filter" />
            <div className="label">{activeSavedFilter}</div>
          </div>
        </Tappable>
        {this.renderSavedFiltersMenu()}
      </div>
    );
  }

  renderSavedFiltersMenu() {
    const { showFilterMenu } = this.state;
    if (!showFilterMenu) {
      return null;
    }

    const hasDefaultFilter = this.props.company.savedFilters.some((filter) => {
      return filter.isDefault || filter.name === DefaultSavedFilterName;
    });
    const filterOptions = this.props.company.savedFilters.map((filter) => filter.name);
    // Add the default filter if it doesn't exist yet
    if (!hasDefaultFilter) {
      filterOptions.unshift(DefaultSavedFilterName);
    }
    filterOptions.sort();

    return (
      <div className="dropdownMenu">
        {filterOptions.map((filterName) => (
          <Tappable key={filterName} onTap={this.onSavedFilterChange.bind(this, filterName)}>
            <div className="dropdownMenuOption">{filterName}</div>
          </Tappable>
        ))}
      </div>
    );
  }

  renderSearch() {
    const { boards } = this.props;
    return (
      <div className="searchRow">
        <SearchInput
          autoFocus={false}
          defaultValue={decodeQueryTextParam(this.props.location.query.search)}
          onChange={this.onSearchChange}
          placeholder="Search..."
          ref={this.searchInputRef}
        />
        {boards.length > 0 ? (
          <Tappable onTap={this.onCreatePostTapped}>
            <div className="createPostButton">
              <div className="icon icon-pencil" />
            </div>
          </Tappable>
        ) : null}
      </div>
    );
  }

  renderSort() {
    const {
      company: { revenueTimeframe },
      location: { query },
    } = this.props;
    const selectedSort = getSorts(revenueTimeframe).find((sortOption) => {
      if (query.sort) {
        return sortOption.name === query.sort;
      } else if (query.search) {
        return sortOption.name === 'relevance';
      } else {
        return sortOption.name === 'trending';
      }
    });
    return (
      <div className="dropdown">
        <Tappable onTap={this.onToggleSortMenu}>
          <div className="dropdownButton">
            <div className="icon icon-data-sync" />
            <div className="label">{selectedSort?.render}</div>
          </div>
        </Tappable>
        {this.renderSortMenu()}
      </div>
    );
  }

  renderSortMenu() {
    const {
      revenueTimeframe,
      stats: { opportunityCount },
    } = this.props.company;
    const { showSortMenu } = this.state;
    if (!showSortMenu) {
      return null;
    }

    const {
      location: { query },
    } = this.props;
    const sortOptions = getSorts(revenueTimeframe).filter((sortOption) => {
      if (sortOption.name === 'relevance' && !query.search) {
        return false;
      }
      if (sortOption.name === 'opportunity' && !opportunityCount) {
        return false;
      }
      return true;
    });
    return (
      <div className="dropdownMenu">
        {sortOptions.map((sortOption) => (
          <Tappable key={sortOption.name} onTap={this.onSortChange.bind(this, sortOption.name)}>
            <div className="dropdownMenuOption">{sortOption.render}</div>
          </Tappable>
        ))}
      </div>
    );
  }

  renderExport() {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('exportData', company, viewer)) {
      return null;
    }

    const onExportTap = () =>
      openModal(ConfirmModal, {
        message: 'Are you sure you want to export all posts that match the current filters?',
        onConfirm: this.onExport,
      });

    return (
      <Tappable onTap={onExportTap}>
        <div className="exportPostsButton">
          <div className="icon icon-download" />
        </div>
      </Tappable>
    );
  }

  render() {
    return (
      <div className="adminFeedbackPostListMenu">
        {this.renderSearch()}
        <div className="menuOptions">
          <div className="dropdowns">
            {this.renderSavedFilters()}
            {this.renderSort()}
          </div>
          {this.renderExport()}
        </div>
      </div>
    );
  }
}

const getSorts = (timeframe) => {
  return [
    {
      name: 'relevance',
      render: 'Relevance',
    },
    {
      name: 'trending',
      render: 'Trending',
    },
    {
      name: 'top',
      render: 'Top',
    },
    {
      name: 'mrr',
      render: timeframe === RevenueTimeframes.mrr ? 'MRR' : 'ARR',
    },
    {
      name: 'new',
      render: 'Newest',
    },
    {
      name: 'old',
      render: 'Oldest',
    },
    {
      name: 'opportunity',
      render: 'Opportunity',
    },
    {
      name: 'statusChanged',
      render: 'Status Changed',
    },
  ];
};

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  openModal: OpenModalContext,
  router: RouterContext,
  viewer: ViewerContext,
})(AdminFeedbackPostListMenu);

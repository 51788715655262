import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries, loadQuery } from 'common/actions/postQueries';
import { invalidatePosts, reloadPost, reloadPostByURLName } from 'common/actions/posts';
import { invalidatePostActivities, reloadPostActivity } from 'common/actions/postsActivity';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { invalidateRoadmap } from 'common/actions/roadmap';
import {
  reloadRoadmapPostsForRoadmap,
  removePostFromAllRoadmaps,
} from 'common/actions/roadmapPosts';
import AJAX from 'common/AJAX';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import UpsellModal from 'common/modals/UpsellModal';
import MergePostSuggestion from 'common/post/MergePostSuggestion';
import AdminFeedbackBulkEditModal from 'common/subdomain/admin/AdminFeedbackBulkEditModal';
import Tappable from 'common/Tappable';
import UserAvatar from 'common/user/UserAvatar';
import { getPostQueryKey } from 'common/util/filterPosts';
import findStringMatches from 'common/util/findStringMatches';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminFeedbackBulkEditForm.scss';

const ContainerID = 'adminFeedbackBulkEditForm';
const MinEntityLengthToShowSearch = 10;
const Pages = 3;
const ScoreFactor = 100000;

const getPostURL = (board, post) => {
  return `/admin/feedback/${board.urlName}/p/${post.urlName}`;
};

class AdminFeedbackBulkEditForm extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(PropTypes.object),
    onPostsEdited: PropTypes.func,
    post: PropTypes.object,
    selectedPostsMap: PropTypes.object,
  };

  static defaultProps = {
    selectedPostsMap: {},
  };

  state = {
    selectedOperationType: null,
    selectedSuggestion: null,
  };

  areMultipleBoardsSelected = () => {
    return this.getSelectedBoards().length > 1;
  };

  closeModal = () => {
    this.setState({ selectedOperationType: null, selectedSuggestion: null });
  };

  deletePostData = async () => {
    const { deletePostData, location, onPostsEdited, router, selectedPostsMap } = this.props;
    const boards = this.getSelectedBoards();
    const posts = Object.values(selectedPostsMap);

    await deletePostData(posts, boards);
    onPostsEdited({});

    router.replace({
      pathname: '/admin/feedback',
      query: location.query,
    });
  };

  findStringMatchesByName = (entities = [], name) => {
    if (!name) {
      return entities;
    }

    return findStringMatches(entities, 'name', name);
  };

  getBoardsQueryKey = () => {
    const { boards } = this.props;
    return boards.map((board) => board.urlName).join('_');
  };

  getConfirmationModal = () => {
    const { selectedOperationType, selectedSuggestion } = this.state;
    const { delete: deleteOperation, merge: mergeOperation } = this.getOperations();

    if (selectedOperationType === 'delete') {
      return (
        <ConfirmModal
          closeModal={this.closeModal}
          message="Are you sure you want to delete these posts?"
          onConfirm={this.onSubmit}
          portalContainerID={ContainerID}
          submitButtonValue={deleteOperation.cta}
          useModalPortal={true}
        />
      );
    } else if (selectedOperationType === 'merge' && selectedSuggestion) {
      const mergeBoard = this.props.boards.find(
        (board) => board._id === selectedSuggestion.boardID
      );
      const hasPrivateBoards = this.getSelectedBoards().some((board) => board.settings?.private);
      const isTurningPublic = hasPrivateBoards && !mergeBoard?.settings?.private;

      return (
        <ConfirmModal
          closeModal={this.closeModal}
          message={
            <div className="mergeConfirmationModal">
              <div className="part">
                Are you sure you'd like to merge selected posts into "{selectedSuggestion.title}"?
                All votes and comments will be&nbsp;moved.
              </div>
              {isTurningPublic && (
                <div className="part warning">
                  Warning: You are merging posts from private boards to a public board. All comments
                  and votes will be made public.
                </div>
              )}
            </div>
          }
          onConfirm={() => this.onSubmit({ selectedSuggestion })}
          portalContainerID={ContainerID}
          submitButtonValue={mergeOperation.cta}
          useModalPortal={true}
        />
      );
    }
  };

  getOperations = () => {
    const { company, loadQuery, roadmaps } = this.props;
    const { features } = company;
    const visibleRoadmaps = roadmaps.filter((roadmap) => !roadmap.archived);
    return {
      merge: {
        cta: 'Merge',
        disabled: false,
        getRequestData: ({ selectedSuggestion }) => ({
          mergeIntoPostID: selectedSuggestion._id,
          operationType: 'merge',
          postIDs: Object.keys(this.props.selectedPostsMap),
        }),
        getSuggestions: this.getPostMergeSuggestions,
        icon: <div className="icon icon-merge-icon" />,
        name: 'Merge Posts',
        onCompleted: this.reloadMergeData,
        onSearchValueChanged: (searchValue) =>
          loadQuery({
            boards: this.getBoardsQueryKey(),
            pages: Pages,
            scoreFactor: ScoreFactor,
            textSearch: searchValue,
          }),
        onSubmit: this.onMergeSubmit,
        permissionKey: 'mergePosts',
        planSupports: true,
        renderSuggestion: this.renderMergePostSuggestion,
        showSearch: true,
        successMessage: 'Posts successfully merged',
        type: 'merge',
        upsellFeatureName: null,
      },
      move: {
        cta: 'Move',
        disabled: false,
        getRequestData: ({ selectedSuggestion }) => ({
          boardID: selectedSuggestion._id,
          operationType: 'move',
          postIDs: Object.keys(this.props.selectedPostsMap),
        }),
        getSuggestions: this.findStringMatchesByName.bind(this, this.props.boards),
        icon: <div className="icon icon-arrow" />,
        name: 'Move to Board',
        onCompleted: this.invalidatePostsAndBoards,
        onSearchValueChanged: null,
        onSubmit: this.onSubmit,
        permissionKey: null,
        planSupports: true,
        renderSuggestion: this.renderSuggestion,
        showSearch: this.moreThanMinEntityLength(this.props.boards),
        successMessage: 'Posts successfully moved',
        type: 'move',
        upsellFeatureName: null,
      },
      addToRoadmap: {
        cta: 'Send',
        disabled: false,
        getRequestData: ({ selectedSuggestion }) => ({
          roadmapID: selectedSuggestion._id,
          operationType: 'addToRoadmap',
          postIDs: Object.keys(this.props.selectedPostsMap),
        }),
        getSuggestions: this.findStringMatchesByName.bind(this, visibleRoadmaps),
        icon: <div className="icon icon-pin" />,
        name: 'Send to Roadmap',
        onCompleted: async (roadmap) => {
          const { reloadRoadmapPostsForRoadmap } = this.props;
          return Promise.all([reloadRoadmapPostsForRoadmap(roadmap), this.invalidatePosts()]);
        },
        onSubmit: this.onSubmit,
        onSearchValueChanged: null,
        planSupports: features.prioritizationRoadmap,
        permissionKey: 'manageRoadmap',
        renderSuggestion: this.renderSuggestion,
        showSearch: this.moreThanMinEntityLength(visibleRoadmaps),
        successMessage: 'Posts successfully sent',
        type: 'addToRoadmap',
        upsellFeatureName: 'prioritizationRoadmap',
      },
      updateOwner: {
        cta: 'Update',
        disabled: false,
        getRequestData: ({ selectedSuggestion }) => ({
          ownerID: selectedSuggestion._id,
          operationType: 'updateOwner',
          postIDs: Object.keys(this.props.selectedPostsMap),
        }),
        getSuggestions: (name) => [
          ...this.findStringMatchesByName(this.props.company.members, name),
          { _id: null, name: 'Clear Owners' },
        ],
        icon: <div className="icon icon-user" />,
        name: 'Update Owner',
        onCompleted: this.invalidatePosts,
        onSearchValueChanged: null,
        onSubmit: this.onSubmit,
        permissionKey: 'changePostOwner',
        planSupports: features.postOwners,
        renderSuggestion: this.renderOwnerSuggestion.bind(this),
        showSearch: this.moreThanMinEntityLength(this.props.company.members),
        successMessage: 'Owners successfully updated',
        type: 'updateOwner',
        upsellFeatureName: 'postOwners',
      },
      addTag: {
        cta: 'Add',
        disabled: this.areMultipleBoardsSelected(),
        getSuggestions: (name) => {
          const board = this.getSelectedBoards()[0];
          const suggestions = this.findStringMatchesByName(board.tags, name);
          const hasTagPermissions = hasPermission(
            'manageTags',
            this.props.company,
            this.props.viewer
          );
          const tagExists = board.tags.find(({ name: tagName }) => tagName === name);

          if (hasTagPermissions && !tagExists && name) {
            suggestions.push({ _id: null, name: `Create new tag` });
          }

          return suggestions;
        },
        getRequestData: async ({ searchValue, selectedSuggestion }) => {
          const { _id: boardID } = this.getSelectedBoards()[0];
          let { _id: tagID } = selectedSuggestion;

          if (!tagID && searchValue) {
            const response = await AJAX.post('/api/tags/create', {
              boardID,
              name: searchValue,
            });
            const { parsedResponse } = parseAPIResponse(response, {
              isSuccessful: (tag) => tag._id,
            });
            tagID = parsedResponse._id;
          }

          return {
            operationType: 'addTag',
            postIDs: Object.keys(this.props.selectedPostsMap),
            tagID,
          };
        },
        icon: <div className="icon icon-tag" />,
        name: 'Add Tag',
        onCompleted: this.invalidateTagBoardAndPosts,
        onSearchValueChanged: null,
        onSubmit: this.onSubmit,
        permissionKey: null,
        planSupports: true,
        renderSuggestion: this.renderTagSuggestion.bind(this),
        showSearch: true,
        successMessage: 'Tags successfully added',
        type: 'addTag',
        upsellFeatureName: null,
      },
      changeCategory: {
        cta: 'Change',
        disabled: this.areMultipleBoardsSelected(),
        getSuggestions: (name) => {
          const board = this.getSelectedBoards()[0];
          return [
            ...this.findStringMatchesByName(board.categories, name),
            { _id: null, name: 'Uncategorized' },
          ];
        },
        getRequestData: ({ selectedSuggestion }) => ({
          categoryID: selectedSuggestion._id,
          operationType: 'changeCategory',
          postIDs: Object.keys(this.props.selectedPostsMap),
        }),
        icon: <div className="icon icon-folder" />,
        name: 'Change Category',
        onCompleted: this.invalidatePosts,
        onSearchValueChanged: null,
        onSubmit: this.onSubmit,
        permissionKey: null,
        planSupports: features.categories,
        renderSuggestion: this.renderSuggestion,
        showSearch: this.moreThanMinEntityLength(this.getSelectedBoards()[0]?.categories),
        successMessage: 'Categories successfully changed',
        type: 'changeCategory',
        upsellFeatureName: 'categories',
      },
      delete: {
        cta: 'Delete',
        disabled: false,
        getRequestData: () => ({
          operationType: 'delete',
          postIDs: Object.keys(this.props.selectedPostsMap),
        }),
        icon: <div className="icon icon-thick-x" />,
        name: 'Delete',
        onCompleted: this.deletePostData,
        onSearchValueChanged: null,
        onSubmit: this.onSubmit,
        permissionKey: 'deletePosts',
        planSupports: true,
        successMessage: 'Posts successfully deleted',
        type: 'delete',
        upsellFeatureName: null,
      },
    };
  };

  getSelectedBoards = (selectedBoardID = null) => {
    const { boards, selectedPostsMap } = this.props;
    const postBoardIDs = Object.values(selectedPostsMap).map((post) => post.boardID);
    const boardIDs = Array.from(new Set([selectedBoardID, ...postBoardIDs]));
    const selectedBoards = boards.filter((board) => boardIDs.includes(board._id));
    return selectedBoards;
  };

  getFilteredPosts = (queryResultPosts) => {
    const { selectedPostsMap, posts } = this.props;

    const postsInfoArray =
      queryResultPosts?.map((post) => {
        const { boardID, postURLName } = post;
        return posts[boardID][postURLName];
      }) || [];
    if (Object.values(selectedPostsMap).length) {
      return postsInfoArray.filter((post) => selectedPostsMap[post._id]);
    }
    return postsInfoArray;
  };

  getPostMergeSuggestions = (search) => {
    const { postQueries } = this.props;

    // If no search value return default posts
    if (!search) {
      const queryResult = Object.values(postQueries)[0] ?? {};
      return this.getFilteredPosts(queryResult?.posts);
    }

    const boardsQueryKey = this.getBoardsQueryKey();
    const queryKey = getPostQueryKey({
      boards: boardsQueryKey,
      pages: Pages,
      scoreFactor: ScoreFactor,
      textSearch: search,
    });
    const queryResult = postQueries[queryKey];

    return this.getFilteredPosts(queryResult?.posts);
  };

  onMergeSubmit = ({ selectedSuggestion } = {}) => {
    this.setState({ selectedSuggestion });
  };

  onSubmit = async ({ searchValue, selectedSuggestion } = {}) => {
    const operation = this.getOperations()[this.state.selectedOperationType];
    const requestData = await operation.getRequestData({ searchValue, selectedSuggestion });
    const responseJSON = await AJAX.post('/api/posts/bulkEdit', requestData);
    const { error } = parseAPIResponse(responseJSON, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      throw new Error(error);
    }

    await operation.onCompleted(selectedSuggestion);
    this.props.showToast(operation.successMessage);
  };

  onOperationSelected = (operation) => {
    this.setState({ selectedOperationType: operation.type });
  };

  invalidatePostsAndBoards = async (selectedSuggestion) => {
    const {
      invalidatePostData,
      location,
      onPostsEdited,
      post,
      reloadBoards,
      reloadPost,
      router,
      selectedPostsMap,
    } = this.props;
    const { _id: selectedBoardID } = selectedSuggestion;
    const boards = this.getSelectedBoards(selectedBoardID);
    const selectedBoard = boards.find((board) => board._id === selectedBoardID);
    const promises = [reloadBoards(boards)];
    const copiedPostMap = { ...selectedPostsMap };

    if (copiedPostMap[post._id]) {
      delete copiedPostMap[post._id];
      promises.push(reloadPost(selectedBoard, post));
    }
    promises.push(invalidatePostData(Object.values(copiedPostMap)));

    await Promise.all(promises);

    const updatedPostsMap = Object.keys(selectedPostsMap).reduce((prev, key) => {
      const selectedPost = selectedPostsMap[key];
      return {
        ...prev,
        [key]: {
          ...selectedPost,
          boardID: selectedBoardID,
        },
      };
    }, {});

    onPostsEdited(updatedPostsMap);
    this.closeModal();

    router.replace({
      pathname: getPostURL(selectedBoard, post),
      query: location.query,
    });
  };

  invalidatePosts = async () => {
    const { boards, invalidatePostData, post, reloadPost, selectedPostsMap } = this.props;
    const copiedPostMap = { ...selectedPostsMap };
    const promises = [];

    if (copiedPostMap[post._id]) {
      delete copiedPostMap[post._id];
      const postBoard = boards.find((board) => board._id === post.boardID);
      promises.push(reloadPost(postBoard, post));
    }
    promises.push(invalidatePostData(Object.values(copiedPostMap)));

    await Promise.all(promises);

    this.closeModal();
  };

  moreThanMinEntityLength = (entity) => {
    return entity.length >= MinEntityLengthToShowSearch;
  };

  invalidateTagBoardAndPosts = async () => {
    const board = this.getSelectedBoards()[0];
    await Promise.all([this.invalidatePosts(), this.props.reloadBoards([board])]);

    this.closeModal();
  };

  reloadMergeData = async (mergeIntoPost) => {
    const { boards, location, onPostsEdited, reloadMergeData, router, selectedPostsMap } =
      this.props;
    const selectedBoards = this.getSelectedBoards();
    const copiedPostMap = { ...selectedPostsMap };
    // MergeIntoPost might be selected, remove it before reloading to prevent it's removal from boards/roadmaps
    delete copiedPostMap[mergeIntoPost._id];
    const posts = Object.values(copiedPostMap);

    await reloadMergeData(selectedBoards, mergeIntoPost, posts);

    const mergeIntoPostBoard = boards.find((board) => board._id === mergeIntoPost.boardID);

    router.replace({
      pathname: getPostURL(mergeIntoPostBoard, mergeIntoPost),
      query: location.query,
    });

    onPostsEdited({});
  };

  renderMergePostSuggestion(suggestion) {
    return <MergePostSuggestion post={suggestion} />;
  }

  renderSuggestion(suggestion) {
    return <>{suggestion.name}</>;
  }

  renderOwnerSuggestion(suggestion) {
    if (!suggestion._id) {
      return this.renderSuggestion(suggestion);
    }

    return (
      <div className="ownerSuggestion">
        <UserAvatar user={suggestion} />
        <div className="suggestionName">{suggestion.name}</div>
      </div>
    );
  }

  renderTagSuggestion(suggestion) {
    if (suggestion._id) {
      return this.renderSuggestion(suggestion);
    }

    return (
      <div className="createNewTagSuggestion">
        <div className="icon icon-plus" />
        <div>{suggestion.name}</div>
      </div>
    );
  }

  renderHeader() {
    const postCount = Object.keys(this.props.selectedPostsMap).length;
    const title = `${postCount} ${postCount >= 2 ? 'posts' : 'post'} selected`;

    return (
      <div className="header">
        <div className="title">{title}</div>
      </div>
    );
  }

  renderContent() {
    const operations = this.getOperations();

    return (
      <div className="operationsList">
        {Object.values(operations).map((operation) => {
          const { disabled } = operation;
          const className = classnames('operation', { active: !disabled, disabled });
          const content = (
            <div className={className}>
              <div className="operationIcon">{operation.icon}</div>
              <div className="operationName">{operation.name}</div>
            </div>
          );

          return disabled ? (
            <Tooltip
              key={operation.name}
              position="right"
              value="This operation isn't supported when selected posts belong to different boards">
              {content}
            </Tooltip>
          ) : (
            <Tappable key={operation.name} onTap={() => this.onOperationSelected(operation)}>
              {content}
            </Tappable>
          );
        })}
      </div>
    );
  }

  renderOperationModal() {
    const { company, openModal, viewer } = this.props;
    const { selectedOperationType } = this.state;

    if (!selectedOperationType) {
      return null;
    }

    const selectedOperation = this.getOperations()[selectedOperationType];

    if (!selectedOperation.planSupports && selectedOperation.upsellFeatureName) {
      return (
        <UpsellModal
          feature={selectedOperation.upsellFeatureName}
          onClose={this.closeModal}
          show={true}
        />
      );
    }

    if (
      selectedOperation.permissionKey &&
      !hasPermission(selectedOperation.permissionKey, company, viewer)
    ) {
      openModal(AccessModal, {
        requiredPermissions: [selectedOperation.permissionKey],
      });
      return;
    }

    // handle confirmation modals
    const confirmationModal = this.getConfirmationModal();
    if (confirmationModal) {
      return confirmationModal;
    }

    return (
      <AdminFeedbackBulkEditModal
        closeModal={this.closeModal}
        onSubmit={selectedOperation.onSubmit}
        operation={selectedOperation}
        portalContainerID={ContainerID}
        selectedPostsMap={this.props.selectedPostsMap}
      />
    );
  }

  render() {
    return (
      <div className="adminFeedbackBulkEditForm">
        <div className="adminFeedbackBulkEditFormOuter" id={ContainerID}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderOperationModal()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      posts: state.posts,
      postQueries: state.postQueries,
    }),
    (dispatch) => ({
      deletePostData: (posts, boards) => {
        return Promise.all([
          dispatch(invalidateDashboardActivity()),
          dispatch(invalidatePostQueries()),
          dispatch(invalidatePosts(posts)),
          dispatch(invalidateRoadmap()),
          dispatch(invalidateSuggestions()),
          ...boards.map((board) => dispatch(reloadBoard(board.urlName))),
          ...posts.map((post) => dispatch(removePostFromAllRoadmaps(post))),
        ]);
      },
      invalidatePostData: (posts) => {
        return Promise.all([
          dispatch(invalidatePostActivities(posts)),
          dispatch(invalidatePostQueries()),
          dispatch(invalidatePosts(posts)),
        ]);
      },
      loadQuery: (queryParams) => {
        return Promise.all([dispatch(loadQuery(queryParams))]);
      },
      reloadBoards: (boards) => {
        return Promise.all(boards.map((board) => dispatch(reloadBoard(board.urlName))));
      },
      reloadMergeData: (boards, mergeIntoPost, mergePosts) => {
        return Promise.all([
          dispatch(invalidateDashboardActivity()),
          dispatch(invalidatePostQueries()),
          dispatch(invalidatePosts(mergePosts)),
          dispatch(reloadPost(mergeIntoPost)),
          dispatch(reloadPostActivity(mergeIntoPost)),
          ...boards.map((board) => dispatch(reloadBoard(board.urlName))),
          ...mergePosts.map((post) => dispatch(removePostFromAllRoadmaps(post))),
        ]);
      },
      reloadPost: (board, post) => {
        return Promise.all([
          dispatch(reloadPostActivity(post)),
          dispatch(reloadPostByURLName(board, post.urlName)),
        ]);
      },
      reloadRoadmapPostsForRoadmap: (roadmap) => {
        return Promise.all([dispatch(reloadRoadmapPostsForRoadmap(roadmap))]);
      },
    })
  ),
  withContexts(
    {
      company: CompanyContext,
      location: LocationContext,
      openModal: OpenModalContext,
      router: RouterContext,
      showToast: ShowToastContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminFeedbackBulkEditForm);

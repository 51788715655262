import React, { Component } from 'react';

import PropTypes from 'prop-types';

import subdomainify from 'common/util/subdomainify';

import TextInput from './TextInput';

export default class SubdomainInput extends Component {
  static propTypes = {
    input: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'Subdomain',
  };

  state = {
    subdomain: subdomainify(this.props.input),
  };

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.input !== prevProps.input) {
      this.setState({
        subdomain: subdomainify(this.props.input),
      });
      this.props.onChange &&
        this.props.onChange({
          currentTarget: {
            value: subdomainify(this.props.input),
          },
        });
    }
  }

  getValue = () => {
    return this.inputRef.current.getValue();
  };

  onSubdomainChange = (e) => {
    const subdomainified = subdomainify(e.target.value);
    this.props.onChange && this.props.onChange(e);
    if (e.target.value === subdomainified + '-') {
      this.setState({
        subdomain: e.target.value,
      });
    } else {
      this.setState({
        subdomain: subdomainified,
      });
    }
  };

  render() {
    var inputProps = { ...this.props };
    Object.keys(SubdomainInput.propTypes).forEach((propType) => {
      delete inputProps[propType];
    });

    return (
      <div className="subdomainInput">
        <TextInput
          {...inputProps}
          name="subdomain"
          onChange={this.onSubdomainChange}
          placeholder={this.props.placeholder}
          ref={this.inputRef}
          suffix=".canny.io"
          value={this.state.subdomain}
        />
      </div>
    );
  }
}

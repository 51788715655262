export default function mapify<T extends object>(objects: T[], key: keyof T) {
  const map: Record<string, T> = {};

  objects.forEach((object) => {
    if (!object.hasOwnProperty(key)) {
      return;
    }

    map[String(object[key])] = object;
  });
  return map;
}

import React from 'react';

import classnames from 'classnames';

import 'css/components/common/_Card.scss';

type Props = {
  className?: string;
  borderStyle: 'solid' | 'dashed' | 'dotted' | 'none';
  children: React.ReactNode;
};

const Card = ({ borderStyle, children, className }: Props) => {
  return (
    <div
      className={classnames(className, 'card', {
        [borderStyle]: true,
      })}>
      {children}
    </div>
  );
};

export default Card;

import React, { Component } from 'react';

import { Code2 } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadRules as reloadClickupRules } from 'common/actions/clickupRules';
import { reloadCompany } from 'common/actions/company';
import { reloadRules as reloadJiraRules } from 'common/actions/jiraRules';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidateAllPostActivities } from 'common/actions/postsActivity';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import { reloadRoadmap } from 'common/actions/roadmap';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import Tooltip from 'common/common/Tooltip';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Draggable from 'common/draganddrop/Draggable';
import DropArea from 'common/draganddrop/DropArea';
import generateRandomID from 'common/generateRandomID';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import PostStatusColorPicker from 'common/postStatus/components/PostStatusColorPicker';
import PostStatusColors, { hexToStatusColor } from 'common/postStatus/PostStatusColors';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import Tappable from 'common/Tappable';
import groupify from 'common/util/groupify';
import hasPermission from 'common/util/hasPermission';
import mapify from 'common/util/mapify';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminStatusSettings.scss';

const Modals = {
  DeleteStatus: 'DeleteStatus',
};

class AdminStatusSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    deletingStatus: null,
    errors: [],
    modal: null,
    newStatuses: {
      [PostStatusTypes.Active]: '',
      [PostStatusTypes.Complete]: '',
      [PostStatusTypes.Closed]: '',
    },
    saving: false,
    statuses: [],
    targetStatus: null,
  };

  componentDidMount() {
    const { company, openModal, route, router, viewer } = this.props;
    if (!hasPermission('manageStatuses', company, viewer)) {
      router.replace('/admin');
      openModal(
        AccessModal,
        {
          requiredPermissions: ['manageStatuses'],
        },
        {
          allowRouteChange: true,
        }
      );
      return;
    }

    // don't show alert on exit when running SSR tests
    if (!__SSR_TEST_RUNNER__) {
      window.addEventListener('beforeunload', this.onBeforeUnload, false);
      router.setRouteLeaveHook(route, this.onRouteLeave);
    }

    const statuses = this.getStatuses();
    const initialStatus = statuses.find((status) => status.type === PostStatusTypes.Initial);
    this.setState({ statuses, targetStatus: initialStatus });

    // add variable to cheaply check if statuses were updated
    this._originalStatuses = statuses;
  }

  componentDidUpdate(prevProps) {
    const { company } = this.props;
    if (company.statuses !== prevProps.company.statuses) {
      const statuses = this.getStatuses();
      this.setState({ statuses });

      // add variable to cheaply check if statuses were updated
      this._originalStatuses = statuses;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload, false);
  }

  updateStatus = (updatedStatus) => {
    const { statuses } = this.state;

    const updatedStatuses = statuses.reduce((updatedStatuses, status) => {
      const isUpdated = status.tempID === updatedStatus.tempID;
      return updatedStatuses.concat(isUpdated ? updatedStatus : status);
    }, []);

    this.setState({ statuses: updatedStatuses });
  };

  getStatuses = () => {
    const { company } = this.props;
    const statuses = company.statuses.map((status) => ({
      _id: status._id,
      color: status.color,
      delete: false,
      name: status.name,
      new: false,
      showInPublicRoadmap: status.showInPublicRoadmap,
      targetStatus: null,
      tempID: status._id,
      type: status.type,
    }));
    return statuses;
  };

  getStatusesPayload = () => {
    const { statuses } = this.state;
    const statusMap = mapify(statuses, 'tempID');
    const payload = statuses
      .map((status) => ({
        ...status,
        ...(status.targetStatus && { targetStatus: statusMap[status.targetStatus].name }),
        color: hexToStatusColor(status.color),
      }))
      .filter((status) => !(status.new && status.delete));
    return payload;
  };

  getErrors = () => {
    const { statuses } = this.state;
    const nonDeletedStatuses = statuses.filter((status) => !status.delete);
    const errors = [];

    const repeatedStatuses = Object.values(groupify(nonDeletedStatuses, 'name')).find(
      (list) => list.length > 1
    );
    if (repeatedStatuses) {
      const statusName = repeatedStatuses[0].name;
      errors.push(
        `The status ${statusName} is ${repeatedStatuses.length} times in the list, and should be unique.`
      );
    }

    const statusTypes = Object.keys(mapify(nonDeletedStatuses, 'type'));
    const emptyTypes = Object.keys(PostStatusTypes).filter((type) => !statusTypes.includes(type));
    const isPlural = emptyTypes.length > 1;
    if (emptyTypes.length > 0 && isPlural) {
      errors.push(`The types "${emptyTypes.join(', ')}" have no statuses on them`);
    } else if (emptyTypes.length > 0 && !isPlural) {
      errors.push(`The type "${emptyTypes[0]}" has no statuses on it`);
    }

    const isRoadmapIncorrect =
      nonDeletedStatuses.filter((status) => status.showInPublicRoadmap).length !== 3;
    if (isRoadmapIncorrect) {
      errors.push('Exactly 3 statuses need to be selected for display in the public roadmap');
    }

    return errors;
  };

  getRandomColor = () => {
    const colorList = Object.keys(PostStatusColors);
    const color = colorList[Math.floor(Math.random() * colorList.length)];
    const lightList = Object.keys(PostStatusColors[color]);
    const light = lightList[Math.floor(Math.random() * lightList.length)];

    return PostStatusColors[color][light];
  };

  onBeforeUnload = (e) => {
    const prompt = this.onRouteLeave();
    if (!prompt) {
      return;
    }

    const event = e || window.event;
    event.returnValue = prompt;
    return prompt;
  };

  onCloseModal = () => {
    this.setState({ deletingStatus: null, modal: null });
  };

  onColorSelected = (color, status) => {
    this.updateStatus({ ...status, color });
  };

  onDeleteStatusConfirmed = (deletedStatus, targetStatus) => {
    const { statuses } = this.state;

    // if there are any statuses with the deleted status as target, update their
    // target to the new target status instead. if there's no target status, default
    // to open.
    const statusesToUpdate = statuses.filter(
      (status) => status.delete && status.targetStatus === deletedStatus.tempID
    );
    const statusesToUpdateMap = mapify([...statusesToUpdate, deletedStatus], 'tempID');
    const initialStatus = statuses.find((status) => status.type === PostStatusTypes.Initial);

    const updatedStatuses = statuses.reduce((updatedStatuses, status) => {
      const isUpdated = statusesToUpdateMap[status.tempID];
      const targetStatusTempID = targetStatus?.tempID || initialStatus.tempID;
      return updatedStatuses.concat(
        isUpdated ? { ...status, delete: true, targetStatus: targetStatusTempID } : status
      );
    }, []);

    // update statuses and close modal
    this.setState({
      deletingStatus: null,
      modal: null,
      statuses: updatedStatuses,
    });
  };

  onCreateNewStatus = (statusName, postStatusType) => {
    if (!validateInput.companyPostStatus.name(statusName)) {
      this.setState({
        errors: ['Statuses must have at least 1 character, and a maximum length of 30 characters'],
      });
      return;
    }

    this.onNewStatus(statusName, postStatusType);
    this.setState(({ newStatuses }) => {
      return {
        newStatuses: {
          ...newStatuses,
          [postStatusType]: '',
        },
      };
    });
  };

  onDeleteStatus = (status) => {
    this.setState({ deletingStatus: status, modal: Modals.DeleteStatus });
  };

  onNameUpdated = (name, status) => {
    const statusName = name.toLowerCase();
    if (!validateInput.companyPostStatus.name(statusName)) {
      this.setState({
        errors: ['Statuses must have at least 1 character, and a maximum length of 30 characters'],
      });
      return;
    }

    this.updateStatus({ ...status, name: statusName });
  };

  onNewStatus = (name, type) => {
    const { statuses } = this.state;
    const publicRoadmapColumns = statuses.filter(
      (status) => !status.delete && status.showInPublicRoadmap
    ).length;

    const newStatus = {
      color: this.getRandomColor(),
      delete: false,
      name,
      new: true,
      showInPublicRoadmap: publicRoadmapColumns < 3,
      tempID: generateRandomID(),
      type,
    };

    this.setState({ statuses: statuses.concat(newStatus) });
  };

  onNewStatusChange = (e, postStatusType) => {
    const statusName = e.target.value;
    this.setState(({ newStatuses }) => {
      return {
        newStatuses: {
          ...newStatuses,
          [postStatusType]: statusName,
        },
      };
    });
  };

  onNewStatusKeydown = (e, postStatusType) => {
    const statusName = e.target.value;
    if (e.keyCode === KeyCodes.Enter) {
      this.onCreateNewStatus(statusName, postStatusType);
      return;
    }
  };

  onReorderStatus = (fromStatusTempID, intoStatusTempID, { order }) => {
    const { statuses } = this.state;
    const fromStatus = statuses.find((status) => status.tempID === fromStatusTempID);
    const intoStatus = statuses.find((status) => status.tempID === intoStatusTempID);

    // if trying to move a status to the same position, do nothing
    if (fromStatusTempID === intoStatusTempID) {
      return null;
    }

    // if trying to move the last status outside its type, do nothing
    const statusesInType = statuses.filter((status) => status.type === fromStatus.type);
    if (statusesInType.length === 1) {
      return null;
    }

    const updatedStatuses = statuses.reduce((updatedStatuses, status) => {
      if (status.tempID === intoStatusTempID) {
        const updatedStatus = { ...fromStatus, type: intoStatus.type };
        const appendableStatuses = {
          after: [intoStatus, updatedStatus],
          before: [updatedStatus, intoStatus],
        }[order];

        return updatedStatuses.concat(appendableStatuses);
      }

      return status.tempID === fromStatusTempID ? updatedStatuses : updatedStatuses.concat(status);
    }, []);
    this.setState({ statuses: updatedStatuses });
  };

  onRouteLeave = () => {
    const { statuses } = this.state;

    const hasUnsavedWork = this._originalStatuses !== statuses;
    if (hasUnsavedWork) {
      return 'Your statuses have unsaved changes. Are you sure you want to leave?';
    }
  };

  onSave = async () => {
    const { saving } = this.state;
    if (saving) {
      return;
    }

    const errors = this.getErrors();
    if (errors.length) {
      this.setState({ errors });
      return;
    }

    this.setState({
      errors: [],
      saving: true,
    });

    const response = await AJAX.post('/api/postStatuses/update', {
      statuses: this.getStatusesPayload(),
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({
        errors: [error.message],
        saving: false,
      });
      return;
    }

    await this.props.reloadAll();

    this.setState({ saving: false });
  };

  onSelectTargetStatus = (statusTempID) => {
    const { statuses } = this.state;
    const targetStatus = statuses.find((status) => status.tempID === statusTempID);
    this.setState({ targetStatus });
  };

  onToggleColumnRoadmap = (showInPublicRoadmap, status) => {
    this.updateStatus({ ...status, showInPublicRoadmap });
  };

  renderDeleteConfirmationMessage = () => {
    const { deletingStatus, statuses, targetStatus } = this.state;

    const initialStatus = statuses.find((status) => status.type === PostStatusTypes.Initial);
    const options = statuses
      .filter((status) => {
        const isDeleting = status.tempID === deletingStatus.tempID;
        const isDeleted = status.delete;
        return !isDeleting & !isDeleted;
      })
      .map((status) => {
        return {
          name: status.tempID,
          render: (
            <div className="statusName" style={{ color: status.color }}>
              {status.name}
            </div>
          ),
        };
      });
    const targetOption = options.find((option) => option.name === targetStatus.tempID);

    return (
      <div className="renderDeleteConfirmationMessage">
        <div>
          Every post with the status "{deletingStatus.name}" will be switched to the following
          status:
        </div>
        <ControlledDropdown
          className="statusesDropdown"
          dropdownClassName="statusDropdownPortal"
          onChange={this.onSelectTargetStatus}
          options={options}
          selectedName={targetOption?.name || initialStatus.tempID}
        />
        <div className="note">NOTE: Click on "Save" to confirm this change.</div>
      </div>
    );
  };

  renderDeleteModal() {
    const { deletingStatus, modal, statuses, targetStatus } = this.state;
    if (modal !== Modals.DeleteStatus) {
      return null;
    }

    // don't ask for confirmation if the status didn't exist before and no other
    // status was pointing to it.
    const shouldConfirm =
      deletingStatus._id ||
      statuses.find((status) => status.targetStatus === deletingStatus.tempID);
    if (!shouldConfirm) {
      this.onDeleteStatusConfirmed(deletingStatus);
      return;
    }

    return (
      <ConfirmModal
        closeModal={this.onCloseModal}
        message={this.renderDeleteConfirmationMessage()}
        onConfirm={() => this.onDeleteStatusConfirmed(deletingStatus, targetStatus)}
        submitButtonValue="delete status"
        useModalPortal={true}
      />
    );
  }

  renderError() {
    const { errors } = this.state;
    if (!errors.length) {
      return null;
    }

    return (
      <div className="errors">
        {errors.map((error) => (
          <div className="error" key={error}>
            {error}
          </div>
        ))}
      </div>
    );
  }

  renderChanges() {
    const { statuses } = this.state;
    if (!statuses.length) {
      return null;
    }

    const statusMap = mapify(statuses, 'tempID');
    const deleteStatusChanges = statuses
      .filter((status) => status._id && status.delete)
      .map((status) => {
        const targetStatus = statusMap[status.targetStatus];
        return (
          <li className="change" key={status.tempID}>
            All posts previously set to "{status.name}" will be set to "{targetStatus.name}".
          </li>
        );
      });

    const newStatusChanges = statuses
      .filter((status) => status.new && !status.delete)
      .map((status) => {
        return (
          <li className="change" key={status.tempID}>
            A new status "{status.name}" will be created.
          </li>
        );
      });

    const changes = [...deleteStatusChanges, ...newStatusChanges];
    if (changes.length < 1) {
      return null;
    }

    return (
      <div className="changes">
        <span className="changeTitle">changes</span>
        <ul className="changeList">{changes}</ul>
      </div>
    );
  }

  renderLockedStatus = (status) => {
    return (
      <div className="lockedStatusContainer" key={status.tempID} style={{ color: status.color }}>
        <div className="statusName">{status.name}</div>
        <div className="icon icon-lock" />
      </div>
    );
  };

  renderStatus = (status, statuses) => {
    return (
      <Draggable key={status.tempID} value={status.tempID}>
        <div className="statusContainer" style={{ color: status.color }}>
          <DropArea
            className="dropArea"
            onDrop={(statusTempID) =>
              this.onReorderStatus(statusTempID, status.tempID, { order: 'before' })
            }
          />
          <div className="left">
            <div className="statusDrag icon icon-drag" />
            <PostStatusColorPicker
              onColorSelected={(color) => this.onColorSelected(color, status)}
              selectedColor={status.color}
            />
            <TextInput
              className="statusName"
              defaultValue={status.name}
              style={{ color: status.color }}
              onChange={(e) => this.onNameUpdated(e.target.value, status)}
            />
          </div>
          <div className="right">
            <Tooltip className="statusCode" position="top" value={status.name}>
              <Code2 className="statusCodeIcon" size={18} />
            </Tooltip>
            <Toggle
              onToggle={(showInPublicRoadmap) =>
                this.onToggleColumnRoadmap(showInPublicRoadmap, status)
              }
              value={status.showInPublicRoadmap}
            />
            {statuses.length > 1 && (
              <Tappable onTap={() => this.onDeleteStatus(status)}>
                <div className="statusDelete icon icon-x" />
              </Tappable>
            )}
          </div>
        </div>
      </Draggable>
    );
  };

  renderNewStatus = (postStatusType) => {
    const { newStatuses, statuses } = this.state;
    const statusesInType = statuses.filter((status) => status.type === postStatusType);
    const lastStatus = statusesInType[statusesInType.length - 1];

    return (
      <div className="newStatusContainer">
        <DropArea
          className="dropArea"
          onDrop={(statusTempID) =>
            this.onReorderStatus(statusTempID, lastStatus.tempID, { order: 'after' })
          }
        />
        <TextInput
          className="statusName"
          onChange={(e) => this.onNewStatusChange(e, postStatusType)}
          onKeyDown={(e) => this.onNewStatusKeydown(e, postStatusType)}
          placeholder="Add new status"
          value={newStatuses[postStatusType]}
        />
        {validateInput.companyPostStatus.name(newStatuses[postStatusType]) && (
          <Button
            className="createStatusButton"
            onTap={() => this.onCreateNewStatus(newStatuses[postStatusType], postStatusType)}
            value="Create"
          />
        )}
      </div>
    );
  };

  renderInitialStatuses = () => {
    const { statuses } = this.state;
    const initialStatuses = statuses.filter(
      (status) => !status.delete && status.type === PostStatusTypes.Initial
    );

    return (
      <div className="statusSection">
        <div className="statusDescription">
          <div className="subheading">Default statuses</div>
          <div className="description">
            New posts are given the default status. This cannot be&nbsp;changed.
          </div>
        </div>
        <div className="statusList">
          {initialStatuses.map((status) => this.renderLockedStatus(status, initialStatuses))}
        </div>
      </div>
    );
  };

  renderActiveStatuses = () => {
    const { statuses } = this.state;
    const activeStatuses = statuses.filter(
      (status) => !status.delete && status.type === PostStatusTypes.Active
    );

    return (
      <div className="statusSection">
        <div className="statusDescription">
          <div className="subheading">Active statuses</div>
          <div className="description">Showing the progress of a&nbsp;post.</div>
        </div>
        <div className="statusList">
          {activeStatuses.map((status) => this.renderStatus(status, activeStatuses))}
          {this.renderNewStatus(PostStatusTypes.Active)}
        </div>
      </div>
    );
  };

  renderCompleteStatuses = () => {
    const { statuses } = this.state;
    const completeStatuses = statuses.filter(
      (status) => !status.delete && status.type === PostStatusTypes.Complete
    );

    return (
      <div className="statusSection">
        <div className="statusDescription">
          <div className="subheading">Complete statuses</div>
          <div className="description">
            To be used as a final status for a post. These posts are deprioritized when Canny
            suggests similiar&nbsp;posts.
          </div>
        </div>
        <div className="statusList">
          {completeStatuses.map((status) => this.renderStatus(status, completeStatuses))}
          {this.renderNewStatus(PostStatusTypes.Complete)}
        </div>
      </div>
    );
  };

  renderClosedStatuses = () => {
    const { statuses } = this.state;
    const closedStatuses = statuses.filter(
      (status) => !status.delete && status.type === PostStatusTypes.Closed
    );

    return (
      <div className="statusSection">
        <div className="statusDescription">
          <div className="subheading">Closed statuses</div>
          <div className="description">
            For posts that will not be completed. These posts are deprioritized when Canny suggests
            similar&nbsp;posts.
          </div>
        </div>
        <div className="statusList">
          {closedStatuses.map((status) => this.renderStatus(status, closedStatuses))}
          {this.renderNewStatus(PostStatusTypes.Closed)}
        </div>
      </div>
    );
  };

  renderPostStatuses = () => {
    return (
      <div className="section">
        <div className="statusSectionList">
          {this.renderInitialStatuses()}
          <div className="roadmapHint">
            <span className="roadmapHintTitle">Roadmap columns</span>
            <span>Select 3</span>
          </div>
          {this.renderActiveStatuses()}
          {this.renderCompleteStatuses()}
          {this.renderClosedStatuses()}
        </div>
      </div>
    );
  };

  render() {
    const { company } = this.props;
    const { saving } = this.state;

    if (!company.features.customStatuses) {
      return (
        <div className="adminStatusSettings">
          <AdminFeatureUpsell
            cta="Customize post statuses to match your team's&nbsp;workflow"
            feature="customStatuses"
          />
        </div>
      );
    }

    return (
      <div className="adminStatusSettings">
        <div className="content">
          {this.renderPostStatuses()}
          {this.renderChanges()}
          <div className="buttonContainer">
            {this.renderError()}
            <Button buttonType="cannyButton" loading={saving} onTap={this.onSave} value="save" />
          </div>
        </div>
        {this.renderDeleteModal()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadAll: () => {
      return Promise.all([
        dispatch(reloadClickupRules()),
        dispatch(reloadCompany()),
        dispatch(reloadJiraRules()),
        dispatch(reloadRoadmap()),
        dispatch(invalidateAllPostActivities()),
        dispatch(invalidatePostQueries()),
        dispatch(invalidateSuggestions()),
      ]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.roadmap.statuses),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminStatusSettings);

import React from 'react';

import { Copy, Search } from 'lucide-react';

import Spinner from 'common/Spinner';
import AdminFeedbackPostListItem from 'common/subdomain/admin/AdminFeedbackPostListItem';
import { H3, P } from 'common/ui/Text';
import { getPostQueryKey } from 'common/util/filterPosts';

import type { Board } from 'common/api/resources/board';
import type { Post } from 'common/api/resources/posts';
import type { PostQueriesState } from 'common/reducers/postQueries';

type Props = {
  postQueries: PostQueriesState;
  fetchingSuggestions: string;
  details: string;
  title: string;
  posts: Record<string, Record<string, Post>>;
  selectedPost: Post;
  selectedBoard?: Board;
  onSuggestionSelected: (event: Event, post: Post) => void;
};

const AdminCreatePostSuggestions = ({
  postQueries,
  posts,
  details,
  fetchingSuggestions,
  onSuggestionSelected,
  selectedBoard,
  selectedPost,
  title,
}: Props) => {
  const renderEmptyState = (Icon: React.ElementType, copy: string) => {
    return (
      <div className="emptySuggestionsContainer">
        <div className="emptySuggestionsIcon">
          <Icon size={24} strokeWidth={1.5} />
        </div>
        <p className="emptySuggestionsCopy">{copy}</p>
      </div>
    );
  };

  const renderContent = () => {
    if (!selectedBoard) {
      return null;
    }

    if (fetchingSuggestions) {
      return renderEmptyState(Spinner, 'Searching for similar posts');
    }

    const textSearch = ((details || '') + ' ' + (title || '')).trim();
    if (!textSearch) {
      return renderEmptyState(Copy, 'Write a title to discover similar posts');
    }

    const queryKey = getPostQueryKey({
      board: selectedBoard,
      textSearch,
    });
    const queryResult = postQueries[queryKey];
    if (!queryResult || !queryResult.posts) {
      return renderEmptyState(Copy, 'Write a title to discover similar posts');
    } else if (queryResult.posts.length === 0) {
      return renderEmptyState(Search, 'No matching posts found');
    }

    return queryResult.posts.map((postResult) => {
      const { postURLName } = postResult;
      const post = posts[selectedBoard._id][postURLName];
      return (
        <AdminFeedbackPostListItem
          board={post.board}
          key={post._id}
          onPostSelected={onSuggestionSelected}
          post={post}
          redirectOnSelect={false}
          selectedPost={selectedPost}
          showSelection={!!selectedPost}
          showDetails
          capTitleLength
        />
      );
    });
  };

  return (
    <div className="suggestionsContainer">
      <div className="similarPostsHeader">
        <H3 className="similarPostsHeading" variant="headingSm">
          <Copy size={16} strokeWidth={1.5} className="similarPostsIcon" />
          Similar posts
        </H3>
        <P className="similarPostsSubtitle">Add a vote to an existing post instead</P>
      </div>
      <div className="suggestions">{renderContent()}</div>
    </div>
  );
};

export default AdminCreatePostSuggestions;

import MarkdownStages from './MarkdownStages';
import processStage from './processStage';

export default function getImageURLs(contents) {
  const items = MarkdownStages.reduce(
    (items, stage) => {
      return processStage(items, stage);
    },
    [contents]
  );
  const reduce = (imageURLs, item) => {
    if (item.componentName === 'img') {
      return imageURLs.concat(item.componentProps.src);
    }

    if (typeof item === 'string') {
      return imageURLs;
    }

    return item.items.reduce(reduce, imageURLs);
  };
  return items.reduce(reduce, []);
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { TrackBingEventContext } from 'common/containers/BingContainer';
import { TrackCannylyticsEventContext } from 'common/containers/CannylyticsContainer';
import { TrackHubSpotEventContext } from 'common/containers/HubSpotContainer';
import { TrackIntercomEventContext } from 'common/containers/IntercomContainer';
import { TrackLinkedInEventContext } from 'common/containers/LinkedInContainer';
import { TrackMixpanelEventContext } from 'common/containers/MixpanelContainer';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import GoogleAnalytics from 'common/ga/GoogleAnalytics';
import snakeCase from 'common/util/snakeCase';
import withContexts from 'common/util/withContexts';

export const TrackEventContext = React.createContext();

const CannyEventsToRedditEvents = {
  'ViewedContent(30secs)': 'ViewContent',
  DemoRequest: 'Lead',
  RegisteredCompany: 'SignUp',
};

class EventContainer extends Component {
  static propTypes = {
    trackBingEvent: PropTypes.func,
    trackCannylyticsEvent: PropTypes.func,
    trackHubSpotEvent: PropTypes.func,
    trackIntercomEvent: PropTypes.func,
    trackLinkedInEvent: PropTypes.func,
    trackMixpanelEvent: PropTypes.func,
  };

  onTrackEvent = (name, data) => {
    if (__DEV__ || __SSR_TEST_RUNNER__) {
      return;
    }

    const {
      trackBingEvent,
      trackCannylyticsEvent,
      trackHubSpotEvent,
      trackIntercomEvent,
      trackLinkedInEvent,
      trackMixpanelEvent,
    } = this.props;

    // Bing
    trackBingEvent(name, data);

    // Cannylytics
    trackCannylyticsEvent(name);

    // Facebook
    if (typeof window.fbq !== 'undefined') {
      window.fbq('trackCustom', name, data);
    }

    // Reddit
    if (typeof window.rdt !== 'undefined') {
      window.rdt('track', 'Custom', {
        customEventName: name,
        ...data,
      });

      const strippedName = name.replace(/\s/g, '');

      // Reddit suggests we send a non-custom event for some of our custom events
      if (CannyEventsToRedditEvents[strippedName]) {
        window.rdt('track', CannyEventsToRedditEvents[strippedName]);
      }
    }
    // Google
    GoogleAnalytics.trackEvent('cannyTracker', name, {
      category: 'Canny Events',
      label: name,
    });

    const snakeCasedName = snakeCase(name);
    GoogleAnalytics.trackEvent('cannyTracker', snakeCasedName, {
      category: 'Canny Events',
      label: snakeCasedName,
    });

    // HubSpot
    trackHubSpotEvent(name, data);

    // Intercom
    trackIntercomEvent(name, data);

    // LinkedIn
    trackLinkedInEvent(name, data);

    // Mixpanel
    trackMixpanelEvent(name, data);
  };

  render() {
    const newProps = { ...this.props };
    delete newProps.trackBingEvent;
    delete newProps.trackCannylyticsEvent;
    delete newProps.trackHubSpotEvent;
    delete newProps.trackIntercomEvent;
    delete newProps.trackLinkedInEvent;
    delete newProps.trackMixpanelEvent;

    return (
      <TrackEventContext.Provider value={this.onTrackEvent}>
        {cloneElementWithProps(this.props.children, newProps)}
      </TrackEventContext.Provider>
    );
  }
}

export default withContexts(
  {
    trackBingEvent: TrackBingEventContext,
    trackCannylyticsEvent: TrackCannylyticsEventContext,
    trackHubSpotEvent: TrackHubSpotEventContext,
    trackIntercomEvent: TrackIntercomEventContext,
    trackLinkedInEvent: TrackLinkedInEventContext,
    trackMixpanelEvent: TrackMixpanelEventContext,
  },
  { forwardRef: true }
)(EventContainer);

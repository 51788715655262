const promisify = (functor, ...args) =>
  new Promise((resolve, reject) => {
    try {
      functor(...args, (error, result) => {
        if (error) {
          reject(error);
        }
        resolve(result);
      });
    } catch (error) {
      reject(error);
    }
  });

export default promisify;

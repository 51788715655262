import React from 'react';

import 'css/components/subdomain/admin/AdminSettings/_AdminSettings.scss';

type Props = {
  learnMoreLink?: string;
  subheading: React.ReactNode;
  title: string;
};

const AdminSettingsHeader = ({ learnMoreLink, subheading, title }: Props) => {
  return (
    <div className="settingsHeading">
      <div className="title">{title}</div>
      <div className="subheading">
        <div>{subheading}</div>
        {learnMoreLink ? (
          <a className="learnMore" href={learnMoreLink} target="_blank" rel="noreferrer">
            Learn&nbsp;more
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default AdminSettingsHeader;

import stringSort from 'common/util/stringSort';

import type { Option } from './SelectCommon';

export const getSelectedOptionsOnChange = ({
  selectedOption,
  value,
}: {
  selectedOption?: Option | null;
  value?: Option[];
}) => {
  if (selectedOption) {
    const isSelected = value?.find((option) => option.value === selectedOption.value);
    if (isSelected) {
      return value?.filter((option) => option.value !== selectedOption.value) || [];
    }
    if (value) {
      return [...value, selectedOption];
    }
    return [selectedOption];
  }
  return [];
};

export const sortByGroupBy = (options: Option[]) => {
  return options
    .map((option) => ({ ...option, groupBy: option.groupBy || '' }))
    .sort(stringSort('groupBy'));
};

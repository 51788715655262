import React, { type ChangeEvent, useContext, useState } from 'react';

import { Edit, Save, Trash, X } from 'lucide-react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Card from 'common/common/Card';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import TextInput from 'common/inputs/TextInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import CountBadge from 'common/ui/CountBadge';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H2, Span } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import stringSort from 'common/util/stringSort';

import type { Filter } from 'common/api/endpoints/companies';
import type { Dispatch } from 'redux';

import 'css/components/subdomain/admin/_AdminSavedFiltersSettings.scss';

const HelpArticleLink = 'http://help.canny.io/en/articles/4467904-saved-filters';

type Props = {
  reloadCompany: () => void;
};

const Nux = () => (
  <div className="nux">
    <p>Using saved filters gives you easy access to different views of your feedback.</p>
    <p>
      Check out our{' '}
      <a className="link" href={HelpArticleLink} target="_blank" rel="noreferrer">
        help article
      </a>{' '}
      to learn more.
    </p>
  </div>
);

const SavedFilterRow = ({
  deleting,
  deleteEndpoint,
  filter,
  renaming,
  renameEndpoint,
  onSaveRename,
  onCancelRename,
  onRenameFilter,
  onDeleteFilter,
  error,
  setDraftRenameValue,
}: {
  deleting: boolean;
  deleteEndpoint: string;
  filter: Filter;
  renaming: boolean;
  renameEndpoint: string;
  onSaveRename: (filter: Filter, endpoint: string) => void;
  onCancelRename: () => void;
  onRenameFilter: (filter: Filter) => void;
  onDeleteFilter: (filter: Filter, endpoint: string) => void;
  error: string;
  setDraftRenameValue: (name: string) => void;
}) => (
  <li key={filter._id}>
    <Card borderStyle="solid" className="filterCard">
      {renaming ? (
        <TextInput
          autoFocus={true}
          defaultValue={filter.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setDraftRenameValue(e.target.value)}
        />
      ) : (
        <Span>{filter.name}</Span>
      )}
      {!renaming ? (
        <>
          <IconButtonV2
            className="editButton"
            variant="plain"
            aria-label="edit name"
            onClick={() => onRenameFilter(filter)}
            size="small"
            icon={Edit}
          />
          <div className="verticalDivider" />
          <IconButtonV2
            color="error"
            variant="plain"
            aria-label="delete view"
            onClick={() => onDeleteFilter(filter, deleteEndpoint)}
            size="small"
            icon={Trash}
          />
        </>
      ) : (
        <>
          <IconButtonV2
            className="editButton"
            variant="plain"
            aria-label="save name"
            onClick={() => onSaveRename(filter, renameEndpoint)}
            size="small"
            icon={Save}
          />
          <div className="verticalDivider" />
          <IconButtonV2
            className="editButton"
            variant="plain"
            aria-label="cancel edit name"
            onClick={onCancelRename}
            size="small"
            icon={X}
          />
        </>
      )}
    </Card>
    {(renaming || deleting) && error ? <div className="error">{error}</div> : null}
  </li>
);

const AdminSavedFiltersSettings = ({ reloadCompany }: Props) => {
  const openModal = useContext(OpenModalContext);
  const company = useContext(CompanyContext);

  const [deleting, setDeleting] = useState(false);
  const [deletingID, setDeletingID] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [renaming, setRenaming] = useState(false);
  const [renamingID, setRenamingID] = useState<string | null>(null);
  const [draftRenameValue, setDraftRenameValue] = useState('');

  const onCancelRename = () => {
    setError('');
    setRenamingID(null);
  };

  const onDeleteFilter = (filter: Filter, endpoint: string) => {
    if (deleting) {
      return;
    }

    setError('');
    setRenamingID(null);

    openModal(ConfirmModal, {
      message: `Are you sure you want to delete the filter "${filter.name}"?`,
      onConfirm: async () => {
        setDeleting(true);
        setDeletingID(filter._id);

        const response = await AJAX.post(endpoint, {
          filterID: filter._id,
        });

        const { error } = parseAPIResponse(response, {
          isSuccessful: isDefaultSuccessResponse,
          errors: {
            default: 'Something went wrong, please try again later.',
          },
        });

        if (error) {
          setDeleting(false);
          setError(error.message);
          return;
        }

        await reloadCompany();
        setDeleting(false);
        setDeletingID(null);
        setError('');
      },
    });
  };

  const onRenameFilter = (filter: Filter) => {
    setDeletingID(null);
    setError('');
    setRenamingID(filter._id);
    setDraftRenameValue(filter.name);
  };

  const onSaveRename = async (filter: Filter, endpoint: string) => {
    if (renaming) {
      return;
    }

    if (!draftRenameValue) {
      setError('You must input a valid filter name.');
      return;
    }

    // If unchanged, just cancel.
    if (draftRenameValue === filter.name) {
      onCancelRename();
      return;
    }

    const existingName = company.savedFilters.find(
      (filter: Filter) => filter.name === draftRenameValue
    );

    if (existingName) {
      setError('This filter name already exists.');
      return;
    }

    setRenaming(true);

    const response = await AJAX.post(endpoint, {
      ...filter,
      filterID: filter._id,
      name: draftRenameValue,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Something went wrong, please try again later.',
      },
    });

    if (error) {
      setRenaming(false);
      setError(error.message);
      return;
    }

    await reloadCompany();
    setError('');
    setRenamingID(null);
    setRenaming(false);
  };

  return (
    <div className="adminSavedFiltersSettings">
      <Helmet title="Saved Filters Settings | Canny" />
      <AdminSettingsHeader
        title="Saved Filters"
        subheading="Create custom views to see relevant&nbsp;feedback."
        learnMoreLink="https://help.canny.io/en/articles/4467904-saved-filters"
      />
      <div className="content">
        {!company.savedFilters.length && !company.savedViews.length ? (
          <Nux />
        ) : (
          <>
            <section>
              <div className="filterSectionHeader">
                <H2 variant="headingSm">Feedback</H2>
                <CountBadge size="small" max={500} count={company.savedFilters.length} />
              </div>
              <ul className="filters">
                {[...company.savedFilters].sort(stringSort('name')).map((filter: Filter) => {
                  const renaming = renamingID === filter._id;
                  const deleting = deletingID === filter._id;

                  return (
                    <SavedFilterRow
                      deleting={deleting}
                      deleteEndpoint="/api/savedFilters/delete"
                      filter={filter}
                      key={filter._id}
                      renaming={renaming}
                      renameEndpoint="/api/savedFilters/edit"
                      onSaveRename={onSaveRename}
                      onCancelRename={onCancelRename}
                      onRenameFilter={onRenameFilter}
                      onDeleteFilter={onDeleteFilter}
                      error={error}
                      setDraftRenameValue={setDraftRenameValue}
                    />
                  );
                })}
              </ul>
            </section>
            <section className="filterSection">
              <div className="filterSectionHeader">
                <H2 variant="headingSm">Roadmap</H2>
                <CountBadge size="small" max={500} count={company.savedViews.length} />
              </div>
              <ul className="filters">
                {[...company.savedViews].sort(stringSort('name')).map((filter: Filter) => {
                  const renaming = renamingID === filter._id;
                  const deleting = deletingID === filter._id;

                  return (
                    <SavedFilterRow
                      deleting={deleting}
                      deleteEndpoint="/api/roadmaps/users/deleteRoadmapSavedSettingsView"
                      filter={filter}
                      key={filter._id}
                      renaming={renaming}
                      renameEndpoint="/api/roadmaps/users/updateRoadmapSavedSettingsView"
                      onSaveRename={onSaveRename}
                      onCancelRename={onCancelRename}
                      onRenameFilter={onRenameFilter}
                      onDeleteFilter={onDeleteFilter}
                      error={error}
                      setDraftRenameValue={setDraftRenameValue}
                    />
                  );
                })}
              </ul>
            </section>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    return dispatch(reloadCompany());
  },
}))(AdminSavedFiltersSettings);

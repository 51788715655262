import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { GongSettings } from 'common/api/endpoints/gong';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: gongSettingsRequested,
  loadedAction: gongSettingsLoaded,
  errorAction: gongSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<GongSettings>('gongSettings');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchGongSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          gongSettings: {
            query: Data.queries.gongSettings,
          },
        },
        cookies
      );
      return dispatch(gongSettingsLoaded(response.gongSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(gongSettingsError(message));
    }
  };
}

export function loadGongSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(gongSettingsRequested());
    return dispatch(fetchGongSettings());
  };
}

export function reloadGongSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchGongSettings());
  };
}

function shouldFetch(state: State) {
  return !state.gongSettings.data;
}

import { ChangelogError, ChangelogLoaded, RequestChangelog } from '../actions/changelog';

export default function changelog(state = null, action) {
  switch (action.type) {
    case RequestChangelog: {
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case ChangelogLoaded: {
      return Object.assign({}, state, {
        enableEmailSubscriptions: action.result.enableEmailSubscriptions,
        hasRSSFeed: action.result.hasRSSFeed,
        labels: action.result.labels,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case ChangelogError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    default:
      return state;
  }
}

import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';

import type { Company } from 'common/api/endpoints/companies';
import 'css/components/company/_CompanyLogo.scss';

const CompanyLogo = () => {
  const company = useContext<Company>(CompanyContext);

  if (!company?.logoURL) {
    return null;
  }

  return (
    <div className="companyLogo">
      <LazyLoadedImage alt={`${company.name} logo`} src={company.logoURL} />
    </div>
  );
};

export default CompanyLogo;

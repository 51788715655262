import React, { Component } from 'react';

import { X } from 'lucide-react';
import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import AccessModal from 'common/modals/AccessModal';
import UpsellModal from 'common/modals/UpsellModal';
import Spinner from 'common/Spinner';
import AdminDropdown from 'common/subdomain/admin/AdminDropdown';
import Tappable from 'common/Tappable';
import IconButtonV2 from 'common/ui/IconButtonV2';
import UserAvatar from 'common/user/UserAvatar';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';

import 'css/components/post/_PostOwnerInput.scss';

class PostOwnerInput extends Component {
  static propTypes = {
    company: PropTypes.object,
    openModal: PropTypes.func,
    post: PropTypes.object,
    reloadPost: PropTypes.func,
    viewer: PropTypes.object,
  };

  state = {
    dropdownOpen: false,
    saving: false,
    showUpsellModal: false,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
    this.ownerInputRef = React.createRef();
  }

  toggleDropdown = async () => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('changePostOwner', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['changePostOwner'],
      });
      return;
    }

    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  onAdminSelected = (member) => {
    const { clearing, saving } = this.state;
    if (clearing || saving) {
      return;
    }

    this.setState({
      dropdownOpen: false,
      saving: true,
    });

    const { post } = this.props;
    AJAX.post(
      '/api/posts/updateOwner',
      {
        ownerID: member._id,
        postID: post._id,
      },
      (response) => {
        const { reloadPost } = this.props;
        reloadPost(post).then(() => {
          this.setState({
            saving: false,
          });
        });
      }
    );
  };

  onClearOwner = () => {
    const { clearing, saving } = this.state;
    if (clearing || saving) {
      return;
    }

    this.setState({
      clearing: true,
      dropdownOpen: false,
    });

    const { post } = this.props;
    AJAX.post(
      '/api/posts/updateOwner',
      {
        ownerID: null,
        postID: post._id,
      },
      (response) => {
        const { reloadPost } = this.props;
        reloadPost(post).then(() => {
          this.setState({
            clearing: false,
          });
        });
      }
    );
  };

  onClickOutside = (e) => {
    this.setState({
      dropdownOpen: false,
    });
  };

  onToggleDropdown = async () => {
    const { features } = this.props.company;
    const planSupports = features?.postOwners;
    if (!planSupports) {
      this.setState({ showUpsellModal: true });
      return;
    }

    this.toggleDropdown();
  };

  onUpsell = () => {
    this.setState({ showUpsellModal: false });
    this.toggleDropdown();
  };

  onUpsellDismiss = () => {
    this.setState({ showUpsellModal: false });
  };

  renderClearButton() {
    const { company } = this.props;
    const companyMemberMap = {};
    company.members.forEach((member) => {
      companyMemberMap[member._id] = member;
    });
    const { post } = this.props;
    const postOwner = post.ownerID ? companyMemberMap[post.ownerID] : null;
    const { clearing } = this.state;
    if (!clearing && !postOwner) {
      return null;
    }

    return (
      <IconButtonV2
        loading={clearing}
        onClick={this.onClearOwner}
        icon={X}
        size="small"
        className="clearButton"
        variant="outlined"
      />
    );
  }

  renderDropdown() {
    const { dropdownOpen } = this.state;
    if (!dropdownOpen) {
      return false;
    }

    return (
      <AdminDropdown
        align="start"
        width={220}
        onUserSelected={this.onAdminSelected}
        relativeElementRef={this.ownerInputRef}
        placeholder="Search for an admin..."
        onClickOutside={this.onClickOutside}
        buttonRef={this.containerRef}
      />
    );
  }

  renderValue() {
    const { company } = this.props;
    const companyMemberMap = {};
    company.members.forEach((member) => {
      companyMemberMap[member._id] = member;
    });
    const { post } = this.props;
    const postOwner = post.ownerID ? companyMemberMap[post.ownerID] : null;

    const { saving } = this.state;
    if (saving) {
      return (
        <div className="buttonValue">
          <Spinner />
        </div>
      );
    }

    if (!postOwner) {
      return <div className="buttonValue">—</div>;
    }

    return (
      <div className="buttonValue">
        <UserAvatar user={postOwner} />
        <div className="name">{postOwner.name}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="postOwnerInput" ref={this.ownerInputRef}>
        <div className="label detailLabel">Owner</div>
        <div className="ownerContainer" ref={this.containerRef}>
          <Tappable onTap={this.onToggleDropdown}>
            <div className="toggleButton">{this.renderValue()}</div>
          </Tappable>
          {this.renderClearButton()}
          {this.renderDropdown()}
        </div>
        <UpsellModal
          feature="postOwners"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsell}
          show={this.state.showUpsellModal}
        />
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  openModal: OpenModalContext,
  viewer: ViewerContext,
})(PostOwnerInput);

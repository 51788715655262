enum ReviewPlatformNames {
  appStore = 'App Store',
  capterra = 'Capterra',
  g2 = 'G2',
  playStore = 'Play Store',
  trustpilot = 'Trustpilot',
  trustradius = 'Trustradius',
  productHunt = 'Product Hunt',
  salesforce = 'Salesforce',
  wordpress = 'Wordpress',
  shopify = 'Shopify',
}

enum ReviewPlatformLabels {
  appStore = 'App Store',
  capterra = 'Capterra',
  g2 = 'G2',
  playStore = 'Play Store',
  trustpilot = 'Trustpilot',
  trustradius = 'Trustradius',
  productHunt = 'Product Hunt',
  salesforce = 'Salesforce AppExchange',
  wordpress = 'WordPress',
  shopify = 'Shopify',
}

enum SyncIntegrationNames {
  freshdesk = 'freshdesk',
  gong = 'gong',
  helpscout = 'helpscout',
  intercom = 'intercom',
  tldv = 'tldv',
  zendesk = 'zendesk',
  zoom = 'zoom',
  api = 'api',
  zapier = 'zapier',
  // review platforms
  trustpilotReviews = 'trustpilot-reviews',
  appStoreReviews = 'appstore-reviews',
  playStoreReviews = 'playstore-reviews',
  g2Reviews = 'g2-reviews',
  capterraReviews = 'capterra-reviews',
  trustradiusReviews = 'trustradius-reviews',
  productHuntReviews = 'producthunt-reviews',
  salesforceReviews = 'salesforce-reviews',
  wordpressReviews = 'wordpress-reviews',
  shopifyReviews = 'shopify-reviews',
}

enum SyncReviewIntegrationNames {
  trustpilotReviews = SyncIntegrationNames.trustpilotReviews,
  appStoreReviews = SyncIntegrationNames.appStoreReviews,
  playStoreReviews = SyncIntegrationNames.playStoreReviews,
  g2Reviews = SyncIntegrationNames.g2Reviews,
  capterraReviews = SyncIntegrationNames.capterraReviews,
  trustradiusReviews = SyncIntegrationNames.trustradiusReviews,
  productHuntReviews = SyncIntegrationNames.productHuntReviews,
  salesforceReviews = SyncIntegrationNames.salesforceReviews,
  wordpressReviews = SyncIntegrationNames.wordpressReviews,
  shopifyReviews = SyncIntegrationNames.shopifyReviews,
}

enum ZapierTemplateNames {
  googleForms = 'google-forms',
  typeform = 'typeform',
  surveyMonkey = 'survey-monkey',
  generic = 'generic', // not a specific integration, but a catch-all for any other Zapier integrations
}

enum IntegrationItemNames {
  call = 'call',
  conversation = 'conversation',
  review = 'review',
  ticket = 'ticket',
}

const CreditsPerIntegrationItemName: Record<IntegrationItemNames, number> = {
  [IntegrationItemNames.call]: 5,
  [IntegrationItemNames.conversation]: 1,
  [IntegrationItemNames.review]: 1,
  [IntegrationItemNames.ticket]: 1,
};

type InternalIntegrations = SyncIntegrationNames.api | SyncIntegrationNames.zapier;

type ExternalIntegrationNames = Exclude<SyncIntegrationNames, InternalIntegrations>;

export type { ExternalIntegrationNames };

export {
  CreditsPerIntegrationItemName,
  ReviewPlatformNames,
  ReviewPlatformLabels,
  SyncIntegrationNames,
  SyncReviewIntegrationNames,
  ZapierTemplateNames,
  IntegrationItemNames,
};

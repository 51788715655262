import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CannyAttribution from 'common/CannyAttribution';
import { CompanyContext } from 'common/containers/CompanyContainer';
import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import Months from 'common/Months';
import PostStatus from 'common/post/PostStatus';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import BoardDetails from 'common/subdomain/public/BoardDetails';
import BoardList from 'common/subdomain/public/BoardList';
import SubdomainPostVoters from 'common/subdomain/public/SubdomainPostVoters';
import ButtonV2 from 'common/ui/ButtonV2';
import UppercaseHeader from 'common/UppercaseHeader';
import withContexts from 'common/util/withContexts';

import 'css/components/containers/_SubdomainSidebarContainer.scss';

class SubdomainSidebarContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    }),
    board: PropTypes.object,
    boards: PropTypes.arrayOf(PropTypes.object),
    post: PropTypes.object,
  };

  renderCategory() {
    const { board, post } = this.props;
    if (!post || !board || !board.categories || board.categories.length === 0) {
      return null;
    }

    const label = post.category ? translateString(post.category, 'name') : 'Uncategorized';
    return (
      <div className="category">
        <UppercaseHeader>Category</UppercaseHeader>
        <div className="label">{label}</div>
      </div>
    );
  }

  renderEntrySection() {
    const { company, post } = this.props;

    const {
      changelog: { hasPublishedEntries },
    } = company;

    // hasPublishedEntries is a check on user access to changelog
    if (!post || !post.linkedEntry || !hasPublishedEntries) {
      return null;
    }

    const entryURL = '/changelog/' + post.linkedEntry.urlName;
    const status = company.statuses.find((status) => status.name === post.status);
    const isComplete = status.type === PostStatusTypes.Complete;
    return (
      <div className="sidebarSection entrySection">
        {isComplete ? <PostStatus status={status} /> : null}
        <div className="message">Visit our changelog to read the official release notes 🎉</div>
        <Link to={entryURL}>
          <ButtonV2 size="medium">Learn more</ButtonV2>
        </Link>
      </div>
    );
  }

  renderETA() {
    const { post } = this.props;
    if (!post || !post.eta || !post.etaPublic) {
      return null;
    }

    const eta = new Date(post.eta);
    const month = Months[eta.getUTCMonth()];
    const year = eta.getUTCFullYear();

    return (
      <div className="eta">
        <UppercaseHeader>Estimated</UppercaseHeader>
        <div className="label">{month + ' ' + year}</div>
      </div>
    );
  }

  renderSidebar() {
    const { board, post } = this.props;
    if (!post) {
      return <BoardDetails board={board} />;
    }

    return (
      <div className="sidebar">
        {this.renderEntrySection()}
        <div className="sidebarSection">
          {this.renderETA()}
          {this.renderCategory()}
          <div className="voters">
            <UppercaseHeader>Voters</UppercaseHeader>
            <SubdomainPostVoters board={board} post={post} voters={post.voters} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { board, boards, post } = this.props;
    if (!board || board.error || board.notFound) {
      return this.props.children;
    }

    if (post && (post.error || post.notFound)) {
      return this.props.children;
    }

    if (board.requestedBoardID) {
      return this.props.children;
    }

    return (
      <div className="subdomainSidebarContainer">
        <div className="sidebarContainer">
          {this.renderSidebar()}
          {Array.isArray(boards) && boards.length ? (
            <BoardList boards={boards} activeBoard={board} className="boardListContainer" />
          ) : null}
          <CannyAttribution desktopOnly={true} source="feedback_subdomain" />
        </div>
        <div className="mainContainer">
          {this.props.children}
          <CannyAttribution mobileOnly={true} source="feedback_subdomain" />
        </div>
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(SubdomainSidebarContainer);

import React, { useContext, useEffect, useMemo, useState } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import Button from 'common/inputs/Button';
import PostStatus from 'common/post/PostStatus';

import type { AsanaCustomField } from 'common/api/endpoints/asanaSettings';
import type { Company } from 'common/api/endpoints/companies';

type Props = {
  asanaCustomFields: AsanaCustomField[];
  loading: boolean;
  onClose(): void;
  onSubmit(data: {
    allLinkedTasks: boolean;
    cannyStatus: string;
    customFieldID: string;
    customFieldOptionID: string;
    shouldNotifyVoters: boolean;
  }): void;
};

const AnyAllOptions = [
  {
    name: 'any',
    render: 'any',
  },
  {
    name: 'all',
    render: 'all',
  },
];
const NotifyOptions = [
  {
    name: 'notify',
    render: 'notify',
  },
  {
    name: 'do not notify',
    render: 'do not notify',
  },
];

const CreateRuleForm = ({ asanaCustomFields, loading, onClose, onSubmit }: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);

  // state
  const [allLinkedTasks, setAllLinkedTasks] = useState<boolean>(false);
  const [customFieldID, setCustomFieldID] = useState<string | null>(null);
  const [customFieldOptionID, setCustomFieldOptionID] = useState<string | null>(null);
  const [cannyStatus, setCannyStatus] = useState<string>('open');
  const [shouldNotifyVoters, setShouldNotifyVoters] = useState<boolean>(false);

  // Memos
  const cannyStatuses = useMemo(() => {
    return company.statuses.map((status) => ({
      name: status.name,
      render: <PostStatus showOpen={true} status={status.name} />,
    }));
  }, [company]);

  const customFields = useMemo(() => {
    if (!asanaCustomFields) {
      return [];
    }

    return asanaCustomFields.map((customField) => ({
      name: customField.id,
      render: <div className="asanaCustomFieldLabel">{customField.name}</div>,
    }));
  }, [asanaCustomFields]);

  const customFieldOptions = useMemo(() => {
    if (!asanaCustomFields || !customFieldID) {
      return [];
    }

    const customField = asanaCustomFields.find((asanaField) => asanaField.id === customFieldID);

    if (!customField) {
      return [];
    }

    return customField.enumOptions.map((enumOption) => ({
      name: enumOption.id,
      render: <div className="asanaCustomFieldLabel">{enumOption.name}</div>,
    }));
  }, [asanaCustomFields, customFieldID]);

  // effects
  useEffect(() => {
    if (!asanaCustomFields || customFieldID || customFieldOptionID) {
      return;
    }

    const customField = asanaCustomFields[0];

    setCustomFieldID(customField.id);
    setCustomFieldOptionID(customField.enumOptions[0].id);
  }, [asanaCustomFields, customFieldID, customFieldOptionID]);

  // helpers
  const onAnyAllChanged = (option: string) => {
    setAllLinkedTasks(option === 'all');
  };

  const onCannyStatusChange = (cannyStatus: string) => {
    setCannyStatus(cannyStatus);
  };

  const onCustomFieldChange = (customFieldID: string) => {
    const customField = asanaCustomFields.find(
      (asanaCustomField) => asanaCustomField.id === customFieldID
    );

    if (!customField) {
      return;
    }

    setCustomFieldID(customField.id);
    setCustomFieldOptionID(customField.enumOptions[0].id);
  };

  const onCustomFieldOptionChange = (customFieldOptionID: string) => {
    setCustomFieldOptionID(customFieldOptionID);
  };

  const onNotifyChange = (optionName: string) => {
    setShouldNotifyVoters(optionName === 'notify');
  };

  const onSubmitForm = () => {
    if (!customFieldID || !customFieldOptionID) {
      return;
    }

    onSubmit({
      allLinkedTasks,
      cannyStatus,
      customFieldID,
      customFieldOptionID,
      shouldNotifyVoters,
    });
  };

  const render = () => {
    const isPlural = !!allLinkedTasks;
    const selectedAllOption = allLinkedTasks ? 'all' : 'any';
    const selectedNotifyOption = shouldNotifyVoters ? NotifyOptions[0].name : NotifyOptions[1].name;

    return (
      <div className="composerContainer">
        <div className="composer">
          When
          <ControlledDropdown
            className="anyAllDropdown"
            dropdownClassName="adminAsanaSettingsDropdown"
            onChange={onAnyAllChanged}
            options={AnyAllOptions}
            selectedName={selectedAllOption}
          />
          linked Asana {isPlural ? 'tasks are' : 'task is'} changed to
          <ControlledDropdown
            className="asanaCustomFieldDropdown"
            dropdownClassName="adminAsanaSettingsDropdown"
            options={customFields}
            onChange={onCustomFieldChange}
            selectedName={customFieldID}
          />
          &nbsp;:&nbsp;
          <ControlledDropdown
            className="asanaCustomFieldOptionsDropdown"
            dropdownClassName="adminAsanaSettingsDropdown"
            options={customFieldOptions}
            onChange={onCustomFieldOptionChange}
            selectedName={customFieldOptionID}
          />
          &nbsp;, change all linked Canny posts to
          <ControlledDropdown
            className="cannyStatusDropdown"
            dropdownClassName="adminAsanaSettingsDropdown"
            options={cannyStatuses}
            onChange={onCannyStatusChange}
            selectedName={cannyStatus}
          />
          , and
          <ControlledDropdown
            className="notifyDropdown"
            dropdownClassName="adminAsanaSettingsDropdown"
            options={NotifyOptions}
            onChange={onNotifyChange}
            selectedName={selectedNotifyOption}
          />
          voters.
        </div>
        <div className="buttons">
          <Button buttonType="ghostButton" onTap={onClose} value="cancel" />
          <Button loading={loading} onTap={onSubmitForm} value="Create" />
        </div>
      </div>
    );
  };

  return render();
};

export default CreateRuleForm;

import React, { useContext } from 'react';

import { TintColorContext } from 'common/containers/TintColorContainer';

import 'css/components/comment/_PinnedLabel.scss';

const PinnedLabel = () => {
  const tintColor = useContext(TintColorContext);

  return (
    <div className="pinnedLabel" style={{ ...(tintColor && { color: tintColor }) }}>
      <span className="icon icon-pin" /> <span className="message">Pinned</span>
    </div>
  );
};

export default PinnedLabel;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_EmailVerificationForm.scss';

class EmailVerificationForm extends Component {
  static propTypes = {
    boardURLName: PropTypes.string,
    shouldHideAllowlistedDomain: PropTypes.bool,
    location: PropTypes.object,
    router: PropTypes.object,
    viewer: PropTypes.object,
    allowListedDomain: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  };

  state = {
    error: null,
    verificationSent: false,
    verifying: false,
  };

  constructor(props, context) {
    super(props, context);

    this.emailInputRef = React.createRef();
  }

  verifyEmail = async (e) => {
    e.preventDefault();
    const { boardURLName, allowListedDomain } = this.props;

    const email = this.emailInputRef.current.getValue();
    const domain = email.split('@')[1];

    if (typeof allowListedDomain === 'string' && !allowListedDomain.includes(domain)) {
      this.setState({ error: 'This email address does not have access' });
      return;
    }

    this.setState({
      error: null,
      verifying: true,
    });

    const verificationData = {
      boardURLName,
      email,
    };

    const response = await AJAX.post('/api/viewer/sendEmailVerification', verificationData);
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'invalid email domain': 'This email address does not have access',
      },
    });

    if (error) {
      this.setState({
        error: error.message,
        verifying: false,
      });
      return;
    }

    this.setState({
      verificationSent: true,
      verifying: false,
    });
  };

  componentDidMount() {
    const { location, router } = this.props;
    const { query } = location;

    if (query.verification === 'invalid') {
      this.setState({
        error:
          'That verification link is no longer valid. Please request a new verification email.',
      });

      router.replace({
        pathname: location.pathname,
        query: '',
      });
    }
  }

  renderErrorMessage() {
    if (!this.state.error) {
      return null;
    }

    return <div className="verificationError">{this.state.error}</div>;
  }

  renderConfirmation() {
    return (
      <div className="emailConfirmation">
        <div className="description">A confirmation has been sent to your email.</div>
        <div className="description">
          Please check your inbox and click the verification&nbsp;link.
        </div>
      </div>
    );
  }

  renderForm() {
    const { shouldHideAllowlistedDomain, viewer, allowListedDomain } = this.props;
    const { email } = viewer;
    const domain = email?.split('@')[1];

    let defaultValue = '';
    if (domain && allowListedDomain.includes?.(domain)) {
      defaultValue = email;
    }

    const domains = allowListedDomain
      .split?.(/[,\s@]+/gi)
      .filter((domain) => {
        return !!domain;
      })
      .map((domain) => {
        return '@' + domain;
      })
      .join(', ');

    return (
      <div className="emailVerification">
        {this.renderErrorMessage()}
        <div className="description">
          You need a verified {!shouldHideAllowlistedDomain ? domains : null} email address to
          access this page.
        </div>
        <div className="promptLabel">Enter your email address for verification:</div>
        <form onSubmit={this.verifyEmail}>
          <div className="input">
            <TextInput defaultValue={defaultValue} placeholder="Email" ref={this.emailInputRef} />
          </div>
          <div className="button">
            <Button formButton={true} loading={this.state.verifying} tint={true} value="NEXT" />
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { verificationSent } = this.state;

    return (
      <div className="emailVerificationForm">
        {verificationSent ? this.renderConfirmation() : this.renderForm()}
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
  router: RouterContext,
  viewer: ViewerContext,
})(EmailVerificationForm);

import Data from 'common/Data';
import promisify from 'common/util/promisify';

import type { Roadmap } from 'common/api/endpoints/roadmaps';
import type { RoadmapsState } from 'common/reducers/sharedRoadmap';
import type { Dispatch, GetState } from 'redux-connect';

// Action Types
export const Actions = {
  FetchSharedRoadmap: 'canny/roadmaps/shared/requestOne',
  SharedRoadmapLoaded: 'canny/roadmaps/shared/loadedOne',
  SharedRoadmapError: 'canny/roadmaps/shared/error',
};

export type SharedRoadmapAction = Action & {
  roadmap: Roadmap;
  roadmapViewID: string;
  timestamp: Date;
  sharedRoadmapError?: string;
  type: typeof Actions;
};

// Actions
function requestRoadmap(roadmapViewID: string) {
  return {
    roadmapViewID,
    timestamp: Date.now(),
    type: Actions.FetchSharedRoadmap,
  };
}

function roadmapLoaded(roadmap: Roadmap, roadmapViewID: string) {
  return {
    roadmap,
    roadmapViewID,
    timestamp: Date.now(),
    type: Actions.SharedRoadmapLoaded,
  };
}

function roadmapsError(error: string) {
  return {
    error,
    timestamp: Date.now(),
    type: Actions.SharedRoadmapError,
  };
}

// Action Creators
function fetchSharedRoadmap(roadmapViewID: string, roadmapURLName: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          roadmap: {
            input: {
              roadmapViewID,
              roadmapURLName,
            },
            query: Data.queries.sharedRoadmap,
          },
        },
        cookies
      );
      return dispatch(roadmapLoaded(response.roadmap, roadmapViewID));
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return dispatch(roadmapsError(error));
    }
  };
}

export function loadRoadmap(roadmapViewID: string, roadmapURLName: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchRoadmap(getState(), roadmapViewID)) {
      dispatch(requestRoadmap(roadmapViewID));
      return dispatch(fetchSharedRoadmap(roadmapViewID, roadmapURLName));
    }
  };
}

export function reloadRoadmap(roadmapViewID: string, roadmapURLName: string) {
  return (dispatch: Dispatch) => {
    return dispatch(fetchSharedRoadmap(roadmapViewID, roadmapURLName));
  };
}

// Helper Functions
function shouldFetchRoadmap(state: RoadmapsState, roadmapViewID: string) {
  const roadmaps = state.roadmaps;
  if (!roadmaps || !roadmaps[roadmapViewID]) {
    return true;
  }
  return false;
}

import { type Post, type PostSummary, PostSummaryStatus } from 'common/api/resources/posts';
import { dayjs } from 'common/util/dayjsUtils';

import type { Company } from 'common/api/endpoints/companies';

export const shouldRenderSummary = (
  post: Post,
  company: Company
): post is Post & { summary: PostSummary } => {
  /*
  List of cases where we should render the summary:
  1. status === failed: always render summary
  2. status === success: always render summary
  3. status === pending: always render summary
  4. summary deleted: do not render
  5. plan does not support: do not render
  */

  const isDeleted = !post.summary || post.summary.deleted;
  const doesPlanSupport = company.features.summarizeComments;
  if (isDeleted || !doesPlanSupport) {
    return false;
  }

  return true;
};

export const canSummarize = (post: Post, company: Company) => {
  /*
  List of cases where a post can be summarized:
  1. the company plan supports the feature
  2. the post has at least 10 comments + merges
  */

  const supportsFeature = company.features.summarizeComments;
  const hasEnoughComments = post.commentCount + post.mergeCount >= 10;
  const canSummarize = supportsFeature && hasEnoughComments;
  return canSummarize;
};

export const shouldRenderSummarizeButton = (post: Post, company: Company) => {
  /*
  The "summarize" button should only be rendered if the post is summarizable and the summary is not rendered
  */

  const lastSummaryIsRecent =
    post.summary && dayjs(post.summary.updatedAt).isSame(new Date(), 'day');
  const lastSummaryFailed = post.summary && post.summary.status === PostSummaryStatus.failed;

  return (
    canSummarize(post, company) &&
    !shouldRenderSummary(post, company) &&
    (lastSummaryFailed || !lastSummaryIsRecent)
  );
};

export const SummaryErrors = {
  'not authorized': 'You are not authorized to summarize comments',
  'plan does not support': 'Your plan does not support comment summarization',
  'insufficient credits': 'You are out of Autopilot credits, please purchase more',
  'invalid postID': 'The post does not exist. Refresh and try again',
  'not enough comments': 'There are not enough comments on the post to summarize',
  'too soon': 'Comments can be summarized at most once a day per post',
  'slow down': 'Current limit for comment summarizations reached. Please try again soon.',
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import mod from 'common/mod';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import delayer from 'common/util/delayer';

import 'css/components/subdomain/admin/_AdminIntegrationSearchForm.scss';

const ChangeDelay = 1000;

const AdminIntegrationSearchForm = ({
  loadSuggestions,
  onSearchValueChanged,
  onSuggestionSelected,
  placeholder,
  renderError,
  renderSuggestion,
  suggestions,
}) => {
  // state
  const [search, _setSearch] = useState({
    searching: false,
    selectedSuggestionIndex: 0,
    submitting: false,
    value: '',
    query: '',
  });

  // state utils
  const setSearch = (data) => _setSearch((state) => ({ ...state, ...data }));

  // refs
  const searchRef = useRef();

  // callbacks
  // Note: onChangeAfterDelay is used with delayer
  // We need to useCallback for this so we keep referencing the same function at every iteration
  const onChangeAfterDelay = useCallback(
    async (value) => {
      if (!value || value.length === 0) {
        return;
      }

      setSearch({ searching: true });
      await loadSuggestions(value);

      setSearch({
        searching: false,
        query: value,
        selectedSuggestionIndex: 0,
      });
    },
    [loadSuggestions]
  );

  // memoized values
  const onChangeDelayer = useMemo(
    () => new delayer(onChangeAfterDelay, ChangeDelay),
    [onChangeAfterDelay]
  );

  // effects
  useEffect(() => {
    return () => onChangeDelayer.cancel();
  }, [onChangeDelayer]);

  // helpers
  const onSelected = async (suggestion) => {
    setSearch({ submitting: true });

    await onSuggestionSelected(suggestion);

    searchRef.current.setValue('');
    setSearch({ submitting: false, value: '', query: '' });
  };

  const onSearchValueChange = (e) => {
    const { value } = e.nativeEvent.target;

    setSearch({ value });
    onSearchValueChanged?.(value);

    if (!value) {
      setSearch({
        query: null,
        searching: false,
        selectedSuggestionIndex: 0,
      });
      return;
    }

    onChangeDelayer.callAfterDelay(value);
  };

  const onKeyDown = (e) => {
    const { query, selectedSuggestionIndex } = search;
    const querySuggestions = suggestions?.[query];
    const itemLength = querySuggestions?.items?.length;

    if (!itemLength) {
      return;
    }

    const { DownArrow, UpArrow, Enter, Tab } = KeyCodes;
    if (![DownArrow, UpArrow, Enter, Tab].includes(e.keyCode)) {
      return;
    }

    e.preventDefault();

    if (e.keyCode === DownArrow) {
      setSearch({ selectedSuggestionIndex: mod(selectedSuggestionIndex + 1, itemLength) });
    } else if (e.keyCode === UpArrow) {
      setSearch({ selectedSuggestionIndex: mod(selectedSuggestionIndex - 1, itemLength) });
    } else if (e.keyCode === Enter || e.keyCode === Tab) {
      onSelected(querySuggestions.items[selectedSuggestionIndex]);
    }
  };

  // renders
  const renderErrorMessage = () => {
    const { query } = search;
    const querySuggestions = suggestions?.[query];

    if (!querySuggestions?.error) {
      return null;
    }

    const errorContent = renderError(querySuggestions.error);

    return <div className="error">{errorContent}</div>;
  };

  const renderOverlay = () => {
    const { value, query, submitting } = search;

    if (!value || !query || submitting) {
      return null;
    }

    const querySuggestions = suggestions?.[query];
    if (!querySuggestions || querySuggestions.error || !querySuggestions.items?.length) {
      return null;
    }

    return <div className="overlay" />;
  };

  const renderSearchForm = () => {
    const { query, submitting, value } = search;

    if (!query || !value || submitting) {
      return null;
    }

    return <div className="focusFields">{renderSuggestions()}</div>;
  };

  const renderSuggestions = () => {
    const { query, selectedSuggestionIndex } = search;
    const querySuggestions = suggestions?.[query];

    if (!querySuggestions || !querySuggestions.items?.length || querySuggestions.error) {
      return null;
    }

    const items = querySuggestions.items.map((suggestion, i) => {
      const suggestionName = renderSuggestion(suggestion);
      return (
        <Tappable key={suggestionName} onTap={() => onSelected(suggestion)}>
          <div className={classnames('suggestion', { selected: i === selectedSuggestionIndex })}>
            {suggestionName}
          </div>
        </Tappable>
      );
    });

    return <div className="suggestions">{items}</div>;
  };

  const render = () => {
    const { searching } = search;
    return (
      <div className="adminIntegrationSearchForm">
        {renderOverlay()}
        <div className="formFields">
          <TextInput
            autoFocus={true}
            disabled={search.submitting}
            onChange={onSearchValueChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            ref={searchRef}
            suffix={searching ? <Spinner /> : null}
          />
          {renderSearchForm()}
          {renderErrorMessage()}
        </div>
      </div>
    );
  };

  return render();
};

AdminIntegrationSearchForm.defaultProps = {
  renderError: (message) => message,
};

AdminIntegrationSearchForm.propTypes = {
  loadSuggestions: PropTypes.func.isRequired,
  onSearchValueChanged: PropTypes.func,
  onSuggestionSelected: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  renderError: PropTypes.func,
  renderSuggestion: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
};

export default AdminIntegrationSearchForm;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { MaxPages } from 'common/actions/postQueries';
import { TintColorContext } from 'common/containers/TintColorContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import isElementOnScreen from 'common/util/isElementOnScreen';
import withContexts from 'common/util/withContexts';

import 'css/components/post/_PostListLoadMore.scss';

class PostListLoadMore extends Component {
  static propTypes = {
    onLoadMore: PropTypes.func,
    postList: PropTypes.shape({
      hasNextPage: PropTypes.bool,
    }),
    tintColor: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  state = {
    hasFailed: false,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, false);
    this.onScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.postList !== this.props.postList) {
      this.setState({ hasFailed: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  hasReachedLimit = (limit) => {
    const { postList } = this.props;
    return postList?.queryParams?.pages && postList.queryParams.pages === limit;
  };

  onLoadMore = () => {
    const { postList, showToast } = this.props;
    const { hasFailed } = this.state;
    if (!hasFailed && this.hasReachedLimit(MaxPages)) {
      showToast(
        'You have reached the maximum number of pages, refine your filters and try again.',
        ToastTypes.error
      );
      this.setState({ hasFailed: true });
      return;
    }

    const { loadingMore } = postList;
    if (loadingMore) {
      return;
    }

    const { onLoadMore, paginate } = this.props;
    paginate(onLoadMore, postList);
  };

  onScroll = (e, scrollContainer) => {
    const { postList } = this.props;
    const { hasFailed } = this.state;
    const { loadingMore } = postList;
    if (loadingMore || hasFailed) {
      return;
    }

    const container = this.containerRef.current;
    if (!container) {
      return;
    }

    if (!isElementOnScreen(container, scrollContainer)) {
      return;
    }

    this.onLoadMore();
  };

  render() {
    const { postList } = this.props;
    const { hasFailed } = this.state;
    const { hasNextPage, loading } = postList;
    if (loading || !hasNextPage || hasFailed) {
      return null;
    }

    const { loadingMore } = postList;
    if (loadingMore) {
      return (
        <div className="postListLoadMore loadingMore" ref={this.containerRef}>
          <Spinner />
        </div>
      );
    }

    const { tintColor } = this.props;
    const loadMoreStyle = {
      ...(tintColor && { color: tintColor }),
    };
    return (
      <Tappable onTap={this.onLoadMore}>
        <div className="postListLoadMore" ref={this.containerRef} style={loadMoreStyle}>
          Load More
          <div className="arrow">&rarr;</div>
        </div>
      </Tappable>
    );
  }
}

export default compose(
  connect(
    null,
    (dispatch) => ({
      paginate: (onLoadMore, postList) => {
        return Promise.all([dispatch(onLoadMore(postList))]);
      },
    }),
    { withRef: true }
  ),
  withContexts(
    {
      tintColor: TintColorContext,
      showToast: ShowToastContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostListLoadMore);

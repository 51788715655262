import React, { createContext, useContext, useEffect, useState } from 'react';

import cloneElementWithProps from 'common/core/cloneElementWithProps';
import AccessModal from 'common/modals/AccessModal';

import { OpenModalContext } from './ModalContainer';

import type { RolePermissionName } from 'common/api/endpoints/companies';

type SuccessStatus = {
  result: 'success';
};

type ErrorStatus = {
  result: 'error';
  reason: string;
};

type PermissionFailureStatus = {
  result: 'failure';
  reason: 'permission';
  data: {
    missingPermissions: RolePermissionName[];
  };
};

export type AccessStatus = SuccessStatus | ErrorStatus | PermissionFailureStatus;

type Context = {
  setAccessStatus(status: AccessStatus): void;
  accessStatus: AccessStatus;
};

export const AccessContext = createContext<Context>({
  accessStatus: {
    result: 'success',
  },
  setAccessStatus: () => undefined,
});

type Props = React.PropsWithChildren<Record<string, unknown>>;

const AccessContainer = ({ children, ...props }: Props) => {
  const openModal = useContext(OpenModalContext);

  const [accessStatus, setAccessStatus] = useState<AccessStatus>({ result: 'success' });

  useEffect(() => {
    if (accessStatus.result !== 'failure') {
      // TODO: handle error status somehow, or not?
      return;
    }

    // we know accessStatus was a failure at this point
    if (accessStatus.reason === 'permission') {
      const { missingPermissions } = accessStatus.data;
      openModal(AccessModal, {
        requiredPermissions: missingPermissions,
      });
    }
  }, [accessStatus, openModal]);

  return (
    <AccessContext.Provider value={{ accessStatus, setAccessStatus }}>
      {cloneElementWithProps(children, props)}
    </AccessContext.Provider>
  );
};

export default AccessContainer;

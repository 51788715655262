import devURL from 'common/util/devURL';

export default class OAuthOIDC {
  private _companyID: string;

  constructor({ companyID }: { companyID: string }) {
    this._companyID = companyID;
  }

  connect = () => {
    const url = devURL('https://canny.io/api/oidc/initiate');

    const params = new URLSearchParams({
      companyID: this._companyID,
    });

    window.location.href = `${url}?${params.toString()}`;
  };
}

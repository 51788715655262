type Attributes = Record<string, string>;
type Callback = null | (() => void);

type Options = {
  attributes: Attributes;
  callback: Callback;
  waitForLoad: boolean;
};

function _loadScript(url: string, id: string, attributes: Attributes, callback: Callback) {
  const first = document.getElementsByTagName('script')[0];
  if (!first?.parentNode) {
    return;
  }

  const entry = document.createElement('script');
  entry.type = 'text/javascript';
  entry.async = true;
  entry.onload = callback;
  entry.src = url;
  entry.id = id;

  Object.keys(attributes).forEach((name) => {
    const value = attributes[name];
    entry.setAttribute(name, value);
  });

  first.parentNode.insertBefore(entry, first);
}

export default function loadScript(
  url: string,
  id: string,
  { attributes = {}, callback = null, waitForLoad = true }: Options = {
    attributes: {},
    callback: null,
    waitForLoad: true,
  }
) {
  if (id && document.getElementById(id)) {
    callback?.();
    return false;
  }

  if (waitForLoad && document.readyState !== 'complete') {
    window.addEventListener('load', () => {
      _loadScript(url, id, attributes, callback);
    });
  } else {
    _loadScript(url, id, attributes, callback);
  }

  return true;
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import Helmet from 'common/helmets/Helmet';
import getImageURLs from 'common/markdown/getImageURLs';
import plaintext from 'common/markdown/plaintext';
import companyOGImage from 'common/util/companyOGImage';
import generateMetaDescription from 'common/util/generateMetaDescription';
import getCannyOrigin from 'common/util/getCannyOrigin';
import withContexts from 'common/util/withContexts';

function getFirstImageURLFromEntry(entry) {
  const imageURLs = getImageURLs(entry.details);
  if (!imageURLs || imageURLs.length === 0) {
    return null;
  }

  return imageURLs[0];
}

class SubdomainChangelogEntryHelmet extends Component {
  static propTypes = {
    company: PropTypes.object,
    entry: PropTypes.object,
    tintColor: PropTypes.string,
  };

  render() {
    const { company, entry, tintColor } = this.props;
    if (!company) {
      return null;
    }

    if (!entry) {
      return null;
    } else if (entry.notFound || entry.loading || entry.error) {
      return null;
    }

    const image = getFirstImageURLFromEntry(entry) || companyOGImage(company);

    const description = generateMetaDescription(plaintext(entry.details));
    const title = entry.title + ' | ' + company.name + ' Changelog';
    const url = getCannyOrigin(company) + '/changelog/' + entry.urlName;
    return (
      <Helmet
        link={[{ href: url, rel: 'canonical' }]}
        meta={[
          { name: 'description', content: description },
          { name: 'theme-color', content: tintColor },
          { name: 'title', content: title },

          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@cannyHQ' },
          { name: 'twitter:title', content: title },
          { name: 'twitter:description', content: description },
          { name: 'twitter:image', content: image },

          { property: 'article:publisher', content: 'https://canny.io' },

          { property: 'og:description', content: description },
          { property: 'og:image', content: image },
          { property: 'og:locale', content: 'en' },
          { property: 'og:site_name', content: title },
          { property: 'og:title', content: title },
          { property: 'og:url', content: url },
          { property: 'og:type', content: 'website' },
        ]}
        title={title}
      />
    );
  }
}

export default withContexts({
  company: CompanyContext,
  tintColor: TintColorContext,
})(SubdomainChangelogEntryHelmet);

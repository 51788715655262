import React, { useContext, useState } from 'react';

import DateRangePicker from 'common/common/DateRangePicker';
import { CompanyContext } from 'common/containers/CompanyContainer';
import UpsellModal from 'common/modals/UpsellModal';
import { RelativeDateRanges } from 'common/util/dateRanges';

import type { Company } from 'common/api/endpoints/companies';

type Props = {
  dateRange: string | [string, string];
  firstColumn?: RelativeDateRanges[];
  secondColumn?: RelativeDateRanges[];
  onChange: ({
    relativeDate,
    dateRange,
  }: {
    relativeDate: string | null;
    dateRange: [string, string] | [null, null];
  }) => void;
};

const UpsellCTAMap = {
  'limits.analyticsHistory': 'More than 30 days of analytics history',
};

const DateRangeFilter = ({ dateRange, onChange, secondColumn, firstColumn }: Props) => {
  const [showUpsellModal, setShowUpsellModal] = useState<keyof typeof UpsellCTAMap | null>(null);
  const company = useContext<Company>(CompanyContext);

  const onLimitExceeded = () => {
    setShowUpsellModal('limits.analyticsHistory');
  };

  const onUpsellDismiss = () => {
    setShowUpsellModal(null);
  };

  const onUpsell = () => {
    setShowUpsellModal(null);
  };

  let limitedOptions: RelativeDateRanges[] = [];
  const analyticsHistory = company.limits?.analyticsHistory;
  if (analyticsHistory && analyticsHistory <= 30) {
    limitedOptions = [
      RelativeDateRanges.lastHalf,
      RelativeDateRanges.lastQuarter,
      RelativeDateRanges.thisHalf,
      RelativeDateRanges.thisQuarter,
      RelativeDateRanges.thisWholeHalf,
      RelativeDateRanges.thisWholeQuarter,
      RelativeDateRanges.nextHalf,
      RelativeDateRanges.nextQuarter,
    ];
  }

  return (
    <>
      <DateRangePicker
        align="end"
        date={dateRange}
        hideOptions={['today', 'this-week', 'last-week', 'next-week']}
        historyDayLimit={analyticsHistory}
        limitedOptions={limitedOptions}
        firstColumn={firstColumn}
        secondColumn={secondColumn}
        onLimitExceeded={onLimitExceeded}
        onSubmit={onChange}
      />
      <UpsellModal
        cta={showUpsellModal ? UpsellCTAMap[showUpsellModal] : ''}
        feature={showUpsellModal}
        onClose={onUpsellDismiss}
        onUpsell={onUpsell}
        show={!!showUpsellModal}
      />
    </>
  );
};

export default DateRangeFilter;

import React from 'react';

import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';

import 'css/components/subdomain/admin/_AdminPreferencesSettings.scss';

const SidebarLinks = [
  {
    label: 'Email preferences',
    urlSuffix: 'email',
  },
  {
    label: 'Language preferences',
    urlSuffix: 'language',
  },
];

const AdminPreferencesSettings = ({ children }: { children: React.ReactNode }) => (
  <div className="adminPreferencesSettings">
    <AdminSettingsHeader
      title="Preferences"
      subheading="Configure your personal preferences in Canny."
    />
    <div className="settingsContainer">
      <div className="settingsSidebar">
        {SidebarLinks.map(({ label, urlSuffix }) => (
          <AdminSettingsSidebarLink key={urlSuffix} to={`/admin/settings/preferences/${urlSuffix}`}>
            {label}
          </AdminSettingsSidebarLink>
        ))}
      </div>
      <div className="settingsContent">{children}</div>
    </div>
  </div>
);

export default AdminPreferencesSettings;

import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { FreshdeskSettings } from 'common/api/endpoints/freshdesk';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: freshdeskSettingsRequested,
  loadedAction: freshdeskSettingsLoaded,
  errorAction: freshdeskSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<FreshdeskSettings>('freshdeskSettings');

export { RequestType, LoadedType, ErrorType };

function fetchFreshdeskSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          freshdeskSettings: {
            query: Data.queries.freshdeskSettings,
          },
        },
        cookies
      );

      return dispatch(freshdeskSettingsLoaded(response.freshdeskSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(freshdeskSettingsError(message));
    }
  };
}

export function loadFreshdeskSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(freshdeskSettingsRequested());
    return dispatch(fetchFreshdeskSettings());
  };
}

export function reloadFreshdeskSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchFreshdeskSettings());
  };
}

function shouldFetch(state: State) {
  return !state.freshdeskSettings.data;
}

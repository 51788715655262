import React, { useContext } from 'react';

import { ClipboardList, DollarSign, MessageSquare, Triangle } from 'lucide-react';
import PropTypes from 'prop-types';

import Truncate from 'common/common/Truncate';
import { convertMRRToTimeframe } from 'common/company/RevenueHelpers';
import { CompanyContext } from 'common/containers/CompanyContainer';
import PostStatus from 'common/post/PostStatus';
import truncateString from 'common/truncateString';
import abbreviateNumber from 'common/util/abbreviateNumber';

import 'css/components/post/_MergePostSuggestion.scss';

import type { Board } from 'common/api/endpoints/boards';
import type { Post } from 'common/api/resources/posts';

type Props = {
  onTap?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  post: Post & { board: Board };
};

const MergePostSuggestion = ({ onTap, post }: Props) => {
  // context
  const company = useContext(CompanyContext);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // use ctrl + click to open the post in another tab to simplify comparison between
    // posts when merging
    if (e.metaKey || e.ctrlKey) {
      window.open(`/admin/feedback/${post.board.urlName}/p/${post.urlName}`, '_blank');
      return;
    }

    onTap?.(e);
  };

  return (
    <button onClick={handleClick} className="mergePostSuggestion">
      <p className="title">{post.title}</p>
      <PostStatus status={post.status} />
      <div className="details">
        <Truncate numberOfLines={2}>{post.details}</Truncate>
      </div>
      <div className="meta">
        <div className="left">
          <ClipboardList className="icon" />
          {truncateString(post.board.name, 25)}
        </div>
        <div className="right">
          <Triangle className="icon" />
          {post.score}
          <div className="dotDivider" />
          <MessageSquare className="icon" />
          {post.commentCount}
          {company.hasCollectedMonthlySpend && (
            <>
              <div className="dotDivider" />
              <DollarSign className="icon" />
              {abbreviateNumber(convertMRRToTimeframe(post.totalMRR, company.revenueTimeframe))}
            </>
          )}
        </div>
      </div>
    </button>
  );
};

MergePostSuggestion.propTypes = {
  onTap: PropTypes.func,
  post: PropTypes.object.isRequired,
};

export default MergePostSuggestion;

import { Component } from 'react';

import PropTypes from 'prop-types';

import { getUserPostsKey, loadQuery } from 'common/actions/userPosts';
import { loadUser } from 'common/actions/users';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

function getQueryParams(user, location) {
  const { filter } = location.query;
  return {
    filter: filter || 'posts-votes-comments',
    user,
  };
}

const getPostList = (user, userPosts, allPosts, location) => {
  const queryKey = getUserPostsKey(getQueryParams(user, location));
  const queryKeyResult = Object.assign({ loading: true }, userPosts[queryKey]);
  const posts = [];

  if (queryKeyResult.postIDs && queryKeyResult.postIDs.length) {
    const postsByID = {};

    Object.keys(allPosts).forEach((boardID) => {
      Object.keys(allPosts[boardID]).forEach((postKey) => {
        const post = allPosts[boardID][postKey];
        postsByID[post._id] = post;
      });
    });

    queryKeyResult.postIDs.forEach((id) => {
      posts.push({
        ...postsByID[id],
        ...(queryKeyResult.postActivity[id] || {}),
      });
    });
  }

  return {
    ...queryKeyResult,
    posts,
  };
};

const asyncFetch = {
  promise: ({ store: { dispatch }, location, params }) => {
    const userURLName = params.userURLName ?? location.query.userURLName;

    if (!userURLName) {
      return null;
    }

    return dispatch(loadUser(userURLName));
  },
};

const userPostsAsyncFetch = {
  promise: ({ store: { dispatch, getState }, location, params }) => {
    const userURLName = params.userURLName ?? location.query.userURLName;
    const state = getState();
    const user = state.users.hasOwnProperty(userURLName) ? state.users[userURLName] : undefined;
    if (!user || user.loading || user.error || user.notFound) {
      return;
    }
    return dispatch(loadQuery(getQueryParams(user, location)));
  },
};

class UserContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
    params: PropTypes.object,
    posts: PropTypes.object,
    userPosts: PropTypes.object,
    users: PropTypes.object,
  };

  render() {
    const { location } = this.props;
    const { children, params, posts, users, userPosts } = this.props;
    const userURLName = params.userURLName ?? location.query.userURLName;
    const user = users[userURLName];
    return cloneElementWithProps(children, {
      ...this.props,
      postList: getPostList(user, userPosts, posts, location),
      user,
    });
  }
}

export default asyncConnect([asyncFetch, userPostsAsyncFetch], (state) => ({
  posts: state.posts,
  userPosts: state.userPosts,
  users: state.users,
}))(UserContainer);

import React from 'react';

import classnames from 'classnames';

import Colors, { type ColorValues } from 'common/colors/constants';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/common/_Pill.module.scss';

export type PillStyle = {
  color: ColorValues;
  background: ColorValues;
};

export const DefaultPillStyles: Record<string, PillStyle> = {
  canny: {
    color: Colors.indigo90,
    background: Colors.indigo30,
  },
  destructive: {
    color: Colors.red90,
    background: Colors.red30,
  },
  info: {
    color: Colors.gray110,
    background: Colors.gray30,
  },
  success: {
    color: Colors.grass90,
    background: Colors.grass30,
  },
  warning: {
    color: Colors.yellow110,
    background: Colors.yellow30,
  },
};

type Props = {
  children: React.ReactNode;
  className?: string;
  pillStyle?: PillStyle; // receive colors instead of color names for flexibility (e.g., support for company-tinted pills)
};

const Pill = ({ children, className, pillStyle = DefaultPillStyles.canny }: Props) => {
  return (
    <div
      className={classnames(styles.pill, className)}
      style={{
        color: pillStyle.color,
        background: pillStyle.background,
      }}>
      <P variant="bodySm" fontWeight="medium">
        {children}
      </P>
    </div>
  );
};

export default Pill;

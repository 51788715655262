import { loadCompany } from './company';
import Data from '../Data';

// Action Types
export const AzureDevopsSettingsError = 'canny/azureDevops_settings/azureDevops_settings_error';
export const AzureDevopsSettingsLoaded = 'canny/azureDevops_settings/azureDevops_settings_loaded';
export const RequestAzureDevopsSettings = 'canny/azureDevops_settings/request_azureDevops_settings';

// Actions
function azureDevopsSettingsError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: AzureDevopsSettingsError,
  };
}

function azureDevopsSettingsLoaded(azureDevopsSettings) {
  return {
    azureDevopsSettings,
    timestamp: Date.now(),
    type: AzureDevopsSettingsLoaded,
  };
}

function requestAzureDevopsSettings() {
  return {
    timestamp: Date.now(),
    type: RequestAzureDevopsSettings,
  };
}

// Action Creators
function fetchAzureDevopsSettings() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    return Data.fetch(
      {
        azureDevopsSettings: {
          query: Data.queries.azureDevopsSettings,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(azureDevopsSettingsError(error));
        }
        return dispatch(azureDevopsSettingsLoaded(data.azureDevopsSettings));
      }
    );
  };
}

export function loadAzureDevopsSettings() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      if (shouldFetchSettings(getState())) {
        dispatch(requestAzureDevopsSettings());
        return dispatch(fetchAzureDevopsSettings());
      }
    });
  };
}

export function reloadAzureDevopsSettings() {
  return (dispatch, getState) => {
    return dispatch(fetchAzureDevopsSettings());
  };
}

function shouldFetchSettings(state) {
  const { azureDevopsSettings } = state;
  return azureDevopsSettings === null;
}

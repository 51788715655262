import {
  CustomFieldsError,
  CustomFieldsLoaded,
  RequestCustomFields,
} from '../actions/customFields';

export default function customFields(state = null, action) {
  switch (action.type) {
    case RequestCustomFields: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case CustomFieldsLoaded: {
      return Object.assign({}, state, {
        customFields: action.customFields,
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case CustomFieldsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import classnames from 'classnames';
import { Construction, Lightbulb } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadFilteredRoadmap, loadMore } from 'common/actions/roadmap';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import translateString from 'common/i18n/translateString';
import PostList from 'common/post/PostList';
import Spinner from 'common/Spinner';
import { H2, H3, P } from 'common/ui/Text';
import capitalizeWords from 'common/util/capitalizeWords';
import withContexts from 'common/util/withContexts';

import RoadmapFilter from './RoadmapFilter';

import 'css/components/subdomain/RoadmapView/_RoadmapView.scss';

const BaseColumns = Array.from({ length: 3 });
const PageSize = 25;
const StartLoadingOffset = 100;

class RoadmapView extends Component {
  static propTypes = {
    company: PropTypes.object,
    roadmap: PropTypes.shape({
      boardURLNames: PropTypes.arrayOf(PropTypes.string),
      filters: PropTypes.shape({
        boardURLNames: PropTypes.arrayOf(PropTypes.string),
        categoryURLNames: PropTypes.arrayOf(PropTypes.string),
      }),
      hasNextPage: PropTypes.bool,
      posts: PropTypes.array,
    }),
  };

  state = {
    scrollableColumns: BaseColumns.map(() => false),
  };

  constructor(props, context) {
    super(props, context);

    // we need to create the scroll refs based on the column index (instead of the status id)
    // because otherwise, we'd have to wait data to load, making extremely hard to update the
    // state based on the size of the DOM element.
    this.loadRefs = BaseColumns.map(() => React.createRef());
    this.scrollRefs = BaseColumns.map(() => React.createRef());
  }

  componentDidMount() {
    this.updateScrollableColumns();
    this.onScroll();
  }

  componentDidUpdate(prevProps) {
    const { roadmap } = this.props;

    if (roadmap?.posts !== prevProps.roadmap?.posts) {
      this.updateScrollableColumns();
    }
  }

  componentWillUnmount() {
    BaseColumns.forEach((_, columnIndex) => {
      const scrollContainer = this.scrollRefs[columnIndex]?.current;
      if (!scrollContainer) {
        return;
      }
      scrollContainer.removeEventListener('scroll', this.onScroll);
    });
  }

  updateScrollableColumns = () => {
    const scrollableColumns = [];
    BaseColumns.forEach((_, columnIndex) => {
      const scrollContainer = this.scrollRefs[columnIndex]?.current;
      if (!scrollContainer) {
        return;
      }

      const isScrollable = scrollContainer.scrollHeight > scrollContainer.clientHeight;
      scrollableColumns[columnIndex] = isScrollable;
      scrollContainer.addEventListener('scroll', this.onScroll, false);
    });

    this.setState({ scrollableColumns });
  };

  getColumns = () => {
    const {
      company: { statuses },
    } = this.props;
    return statuses.filter((status) => status.showInPublicRoadmap);
  };

  onLoadMore = () => {
    const { roadmap } = this.props;
    if (!roadmap || !roadmap.hasNextPage || roadmap.loadingMore) {
      return;
    }

    const { paginate } = this.props;
    paginate();
  };

  onScroll = () => {
    const { roadmap } = this.props;
    if (!roadmap || !roadmap.hasNextPage || roadmap.loadingMore) {
      return;
    }

    const columns = this.getColumns();
    const shouldLoad = columns.reduce((shouldLoad, column, columnIndex) => {
      if (shouldLoad) {
        return true;
      }

      const loadComponent = this.loadRefs[columnIndex]?.current;
      if (!loadComponent) {
        return false;
      }

      const scrollContainer = this.scrollRefs[columnIndex].current;
      const { offsetHeight, scrollTop, scrollHeight } = scrollContainer;
      const scrollBottom = scrollHeight - offsetHeight - scrollTop;
      return scrollBottom < StartLoadingOffset;
    }, false);

    if (!shouldLoad) {
      return;
    }

    this.onLoadMore();
  };

  render() {
    const { company, fetchFilteredRoadmap, roadmap } = this.props;
    if (!roadmap || !roadmap.posts) {
      return null;
    }
    const { pages, posts } = roadmap;
    const columns = this.getColumns();

    return (
      <div className="roadmapView">
        <header className="header">
          <H2 variant="headingMd">Roadmap</H2>
          <RoadmapFilter
            allowedBoardURLNames={roadmap.boardURLNames}
            filterValues={roadmap.filters}
            onFilterChange={(filters) => fetchFilteredRoadmap(filters)}
          />
        </header>
        <div className="roadmapColumns">
          {columns.map((column, columnIndex) => {
            const columnPosts = posts.filter((post) => post.status === column.name);
            const isScrollable = this.state.scrollableColumns[columnIndex];
            const renderLoadMore = roadmap.hasNextPage && columnPosts.length === pages * PageSize;

            return (
              <div className="roadmapColumn" key={column._id}>
                <div className="columnHeader">
                  <div className="dot" style={{ background: column.color }} />
                  <H3 variant="headingSm" resetStyles className="statusName">
                    {capitalizeWords(translateString(column, 'name'))}
                  </H3>
                </div>
                <div
                  className={classnames('scrollContainer', { scrollable: isScrollable })}
                  ref={this.scrollRefs[columnIndex]}>
                  {columnPosts.length !== 0 ? (
                    <>
                      <PostList
                        noPostsMessage="Come back soon!"
                        postList={{
                          posts: columnPosts,
                          hasNextPage: false,
                        }}
                        showBoard={true}
                        showComments={false}
                        showDetails={false}
                        showMenu={false}
                        showPagination={false}
                        showStatus={false}
                      />
                      {renderLoadMore ? (
                        <div className="roadmapLoadMore" ref={this.loadRefs[columnIndex]}>
                          {roadmap.loadingMore ? <Spinner /> : null}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <div className="zeroPosts">
                        <div className="bulbBox">
                          {company.viewerIsMember ? <Construction /> : <Lightbulb />}
                        </div>
                        <P>
                          {company.viewerIsMember ? (
                            <>Share your progress by changing the status on posts.</>
                          ) : (
                            <>Share your feedback and check back here for updates.</>
                          )}
                        </P>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    paginate: () => {
      return dispatch(loadMore());
    },
    fetchFilteredRoadmap: (filters) => {
      return dispatch(loadFilteredRoadmap(filters));
    },
  })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(RoadmapView);

import React, { Component } from 'react';

import classnames from 'classnames';
import { CalendarRange, ChevronDown } from 'lucide-react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Portal from 'common/common/Portal';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import Tappable from 'common/Tappable';
import { RelativeDateOptions, RelativeDateRanges } from 'common/util/dateRanges';
import { dayjs } from 'common/util/dayjsUtils';

import 'css/components/common/_DateRangePicker.scss';

export default class DateRangePicker extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['center', 'end', 'start']),
    className: PropTypes.string,
    date: PropTypes.oneOfType([
      PropTypes.array, // fixed date range tuple [from, to]
      PropTypes.string, // relative date
    ]),
    hideOptions: PropTypes.array,
    historyDayLimit: PropTypes.number,
    limitedOptions: PropTypes.array,
    onLimitExceeded: PropTypes.func,
    onSubmit: PropTypes.func,
    secondColumn: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    align: 'start',
    hideOptions: [],
    limitedOptions: [],
    firstColumn: [
      RelativeDateRanges.today,
      RelativeDateRanges.thisWeek,
      RelativeDateRanges.thisMonth,
      RelativeDateRanges.thisQuarter,
      RelativeDateRanges.thisHalf,
    ],
    secondColumn: [
      RelativeDateRanges.lastWeek,
      RelativeDateRanges.lastMonth,
      RelativeDateRanges.lastQuarter,
      RelativeDateRanges.lastHalf,
    ],
  };

  state = {
    appliedDate: 'All time',
    error: null,
    pickerOpen: false,
    selectedFixed: {
      from: null,
      to: null,
    },
    selectedRelative: RelativeDateRanges.allTime,
  };

  constructor(props, context) {
    super(props, context);
    this.dropdownButtonRef = React.createRef();
    this.fixedFromDateRef = React.createRef();
    this.fixedToDateRef = React.createRef();
    this.portalRef = React.createRef();

    // Determine initial state from props
    this.state = {
      ...this.state,
      ...this.getNextStateFromProps(props),
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.onClick);
    window.addEventListener('resize', this.onCloseDropdown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      const nextState = this.getNextStateFromProps(this.props);
      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
    window.removeEventListener('resize', this.onCloseDropdown);
  }

  getNextStateFromProps({ date }) {
    const defaultState = {
      appliedDate: 'All time',
      selectedFixed: {
        from: null,
        to: null,
      },
      selectedRelative: RelativeDateRanges.allTime,
    };

    if (!date) {
      return defaultState;
    }

    if (typeof date === 'string' && RelativeDateOptions[date]) {
      return {
        appliedDate: RelativeDateOptions[date].text,
        selectedFixed: { from: null, to: null },
        selectedRelative: date,
      };
    } else if (date.length === 2) {
      const fromDate = dayjs(date[0]);
      const toDate = dayjs(date[1]);
      if (!fromDate.isValid() || !toDate.isValid() || fromDate.isAfter(toDate)) {
        return defaultState;
      }
      const from = fromDate.format('MM/DD/YYYY');
      const to = toDate.format('MM/DD/YYYY');
      return {
        appliedDate: `${from} - ${to}`,
        selectedFixed: { from, to },
        selectedRelative: null,
      };
    }

    return defaultState;
  }

  onApply = () => {
    const { historyDayLimit, onLimitExceeded } = this.props;
    const { selectedFixed, selectedRelative } = this.state;
    let appliedDate;
    let dateRange;
    let from = null;
    let to = null;
    if (selectedRelative) {
      appliedDate = RelativeDateOptions[selectedRelative].text;
      dateRange = RelativeDateOptions[selectedRelative].toRange();
    } else {
      from = dayjs(selectedFixed.from);
      to = dayjs(selectedFixed.to);
      if (!from.isValid() || !to.isValid()) {
        this.setState({ error: 'Please enter a valid fixed date\u00A0range.' });
        return;
      } else if (from.isAfter(to)) {
        this.setState({ error: 'The end date must be after the start\u00A0date.' });
        return;
      }

      if (historyDayLimit) {
        const historyLimit = dayjs().subtract(historyDayLimit, 'day');
        const fromDate = dayjs(selectedFixed.from);
        const toDate = dayjs(selectedFixed.to);
        if (fromDate < historyLimit || toDate < historyDayLimit) {
          this.onCloseDropdown();
          onLimitExceeded();
          return;
        }
      }

      dateRange = [from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')];
      from = from.format('MM/DD/YYYY');
      to = to.format('MM/DD/YYYY');
      appliedDate = `${from} - ${to}`;
    }

    this.setState({
      appliedDate,
      pickerOpen: false,
      selectedFixed: { from, to },
      selectedRelative,
    });

    this.props.onSubmit({
      dateRange,
      relativeDate: selectedRelative,
    });
  };

  onClick = (e) => {
    if (
      !this.state.pickerOpen ||
      findDOMNode(this).contains(e.target) ||
      this.portalRef?.current?.containsNode(e.target)
    ) {
      return;
    }
    this.onCloseDropdown();
  };

  onCloseDropdown = () => {
    if (!this.state.pickerOpen) {
      return;
    }
    this.setState({ error: null, pickerOpen: false });
  };

  onFixedDateBlur = () => {
    this.setState({
      error: null,
      selectedFixed: {
        from: this.fixedFromDateRef.current.getValue(),
        to: this.fixedToDateRef.current.getValue(),
      },
    });
  };

  onFixedDateFocus = () => {
    let fromDate = this.fixedFromDateRef.current.getValue();
    let toDate = this.fixedToDateRef.current.getValue();
    if (!fromDate) {
      fromDate = dayjs().format('MM/DD/YYYY');
      this.fixedFromDateRef.current.setValue(fromDate);
    }
    if (!toDate) {
      toDate = dayjs().format('MM/DD/YYYY');
      this.fixedToDateRef.current.setValue(toDate);
    }
    this.setState({
      selectedFixed: {
        from: fromDate,
        to: toDate,
      },
      selectedRelative: null,
    });
  };

  onSelectRelativeDate = (relativeDate) => {
    const { limitedOptions, onLimitExceeded } = this.props;
    const limitedSet = new Set(limitedOptions);
    if (limitedSet.has(relativeDate)) {
      this.onCloseDropdown();
      onLimitExceeded();
      return;
    }

    this.fixedFromDateRef.current.setValue('');
    this.fixedToDateRef.current.setValue('');
    this.setState({
      error: null,
      selectedFixed: { from: null, to: null },
      selectedRelative: relativeDate,
    });
  };

  onToggleDropdown = () => {
    this.setState((state) => ({
      error: null,
      pickerOpen: !state.pickerOpen,
    }));
  };

  renderDropdownButton() {
    const { appliedDate } = this.state;
    const classNames = classnames('dropdownValue', appliedDate.includes('/') && 'fixedRange');
    return (
      <div ref={this.dropdownButtonRef}>
        <Tappable onTap={this.onToggleDropdown}>
          <div className="dropdownButton">
            <div className="left">
              <CalendarRange strokeWidth={2} size={16} className="icon" />
              <div className={classNames}>{appliedDate}</div>
            </div>
            <ChevronDown aria-label="toggle icon" size={16} className="icon-chevron-down" />
          </div>
        </Tappable>
      </div>
    );
  }

  renderPicker() {
    const { align } = this.props;
    const { error, pickerOpen, selectedFixed, selectedRelative } = this.state;
    if (!pickerOpen) {
      return null;
    }

    const disabled = !selectedRelative && (!selectedFixed.from || !selectedFixed.to);

    return (
      <Portal
        align={align}
        ref={this.portalRef}
        className="dateRangePickerPortal ignoreModalClickAway"
        relativeToRef={this.dropdownButtonRef}>
        <div className="pickerContainer">
          {this.renderRelativeDateOptions()}
          {this.renderFixedDateOptions()}
          <div className="applyButton">
            <div className="error">{error}</div>
            <Button disabled={disabled} onTap={this.onApply} value="Apply" />
          </div>
        </div>
      </Portal>
    );
  }

  renderFixedDateOptions() {
    const { selectedFixed } = this.state;
    const classNames = classnames('dateOptions', {
      selected: selectedFixed.from || selectedFixed.to,
    });
    return (
      <div className="fixedDates">
        <div className="sectionTitle">Fixed dates</div>
        <div className={classNames}>
          <TextInput
            defaultValue={selectedFixed.from}
            onBlur={this.onFixedDateBlur}
            onFocus={this.onFixedDateFocus}
            placeholder="mm/dd/yyyy"
            ref={this.fixedFromDateRef}
          />
          <div className="dateSeparator">–</div>
          <TextInput
            defaultValue={selectedFixed.to}
            onBlur={this.onFixedDateBlur}
            onFocus={this.onFixedDateFocus}
            placeholder="mm/dd/yyyy"
            ref={this.fixedToDateRef}
          />
        </div>
      </div>
    );
  }

  renderRelativeDateOptions() {
    const {
      limitedOptions,
      hideOptions,
      firstColumn: defaultFirstColumnOptions,
      secondColumn: defaultSecondColumnOptions,
    } = this.props;
    const { selectedRelative } = this.state;
    const allOptions = Object.values(RelativeDateOptions);
    const firstColumn = allOptions.filter(
      (option) =>
        !hideOptions.includes(option.value) && defaultFirstColumnOptions.includes(option.value)
    );
    const secondColumn = allOptions.filter(
      (option) =>
        !hideOptions.includes(option.value) && defaultSecondColumnOptions.includes(option.value)
    );

    // Align all-time in the correct column
    const allTime = allOptions.find((option) => option.value === RelativeDateRanges.allTime);
    if (firstColumn.length <= secondColumn.length) {
      firstColumn.push(allTime);
    } else {
      secondColumn.push(allTime);
    }

    const limitedSet = new Set(limitedOptions);
    const renderOption = ({ text, value }) => (
      <div key={value} className="option">
        <Tappable onTap={() => this.onSelectRelativeDate(value)}>
          <span
            className={classnames('optionText', {
              disabled: limitedSet.has(value),
              selected: value === selectedRelative,
            })}>
            {text}
          </span>
        </Tappable>
      </div>
    );
    return (
      <div className="relativeDates">
        <div className="sectionTitle">Relative dates</div>
        <div className="relativeOptions">
          <div className="optionColumn">{firstColumn.map(renderOption)}</div>
          <div className="optionColumn">{secondColumn.map(renderOption)}</div>
        </div>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={classnames('dateRangePicker', className)}>
        {this.renderDropdownButton()}
        {this.renderPicker()}
      </div>
    );
  }
}

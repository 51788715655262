import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { TintColorContext } from 'common/containers/TintColorContainer';
import Mentionify from 'common/Mentionify';
import Tappable from 'common/Tappable';
import mapify from 'common/util/mapify';
import withContexts from 'common/util/withContexts';

import MarkdownStages from './MarkdownStages';
import processStage from './processStage';

import 'css/components/markdown/_Markdown.scss';

const StagesNameMap = mapify(MarkdownStages, 'name');

class Markdown extends Component {
  static propTypes = {
    contents: PropTypes.string,
    mentions: PropTypes.array,
    onImageLoaded: PropTypes.func,
    onImageTapped: PropTypes.func,
    stages: PropTypes.array,
    tintColor: PropTypes.string,
  };

  static defaultProps = {
    mentions: [],
    onImageLoaded: () => {},
    stages: Object.keys(StagesNameMap),
  };

  renderItems(items, keyPrefix) {
    const { mentions, onImageTapped, tintColor } = this.props;
    const hasMentions = mentions && mentions.length > 0;

    return items.map((item, i) => {
      const key = keyPrefix + '_' + i;
      if (typeof item === 'string') {
        const lines = item.split(/[\n\r]/g);

        if (lines.length === 1) {
          if (!hasMentions) {
            return item;
          }

          return (
            <Mentionify mentions={mentions} key={key}>
              {item}
            </Mentionify>
          );
        }

        return lines.map((line, j) => (
          <div className="line" key={key + '_' + j}>
            {!hasMentions ? line : <Mentionify mentions={mentions}>{line}</Mentionify>}
          </div>
        ));
      }

      const componentName = item.componentName || 'div';
      const children = this.renderItems(item.items, key);
      const start = item?.items[0]?.start || undefined;

      const itemStyle = {
        ...item.componentProps?.style,
        ...(componentName === 'a' && {
          color: tintColor,
        }),
      };
      const component = React.createElement(
        componentName,
        {
          ...item.componentProps,
          ...(Object.keys(itemStyle).length > 0 && { style: itemStyle }),
          className: classnames(item.className, {
            tappable: item.componentName === 'img' && onImageTapped,
          }),
          ...(item.componentName === 'img' && {
            onLoad: this.props.onImageLoaded,
          }),
          key,
          start,
        },
        children.length > 0 ? children : null
      );

      if (item.componentName !== 'img' || !onImageTapped) {
        return component;
      }

      return (
        <Tappable
          key={key}
          onTap={() => {
            onImageTapped(item.componentProps.src);
          }}>
          {component}
        </Tappable>
      );
    });
  }

  getStages = () => {
    const { stages } = this.props;
    return stages.map((stageName) => StagesNameMap[stageName]);
  };

  render() {
    const { contents } = this.props;

    const stages = this.getStages();
    const items = stages.reduce(
      (items, stage) => {
        return processStage(items, stage);
      },
      [contents]
    );

    return <div className="markdown">{this.renderItems(items, '')}</div>;
  }
}

export default withContexts({
  tintColor: TintColorContext,
})(Markdown);

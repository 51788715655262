import { useContext } from 'react';

import deepCompare from 'react-fast-compare';

import {
  type PostType,
  type QueryParams,
  getQueryKey,
  loadQueueItems,
} from 'common/actions/queueItemQueries';
import { LocationContext } from 'common/containers/RouterContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { isNotNil } from 'common/util/isNil';
import mapify from 'common/util/mapify';

import { CompanyContext } from './CompanyContainer';

import type { Board } from 'common/api/endpoints/boards';
import type { State } from 'common/reducers/queueItemQueries';

export type EncodedQuery = {
  boardURLNames?: string;
  dateRange?: string;
  postTypes?: string;
  sort?: 'created' | 'source';
  sortDirection?: 'asc' | 'desc';
  source?: string;
};

export type LocationWithQuery = {
  pathname: string;
  query: EncodedQuery;
};

type Props = {
  boards: Board[];
  children: React.ReactNode;
  queueItemQueries: State;
};

export const getQueryParams = (location: LocationWithQuery): QueryParams => {
  const {
    query: { boardURLNames, dateRange, postTypes, source, sort, sortDirection },
  } = location;

  return {
    ...(boardURLNames && { boardURLNames: boardURLNames.split('_') }),
    ...(dateRange && { dateRange: [dateRange, dateRange] }),
    ...(postTypes && { postTypes: postTypes.split('_') as PostType[] }),
    ...(source && { source }),
    ...(sort && { sort }),
    ...(sortDirection && { sortDirection }),
  };
};

export const getEncodedQueryParams = (queryParams: QueryParams): EncodedQuery => {
  return {
    ...(queryParams.boardURLNames &&
      queryParams.boardURLNames.length && { boardURLNames: queryParams.boardURLNames.join('_') }),
    ...(queryParams.dateRange && { dateRange: '' }), // TODO: update when adding date range filters
    ...(queryParams.postTypes && { postTypes: queryParams.postTypes.join('_') }),
    ...(queryParams.source && { source: queryParams.source }),
    ...(queryParams.sort && { sort: queryParams.sort }),
    ...(queryParams.sortDirection && { sortDirection: queryParams.sortDirection }),
  };
};

const mapAndFilterItems = (itemIDs: string[], queueItemQueries: State, boards: Board[]) => {
  const boardMap = mapify(boards, '_id');
  return itemIDs
    .map((itemID) => {
      const item = queueItemQueries.items[itemID];
      if (!item) {
        return null;
      }

      // add boards
      return {
        ...item,
        ...(item.type === 'post' && { board: boardMap[item.boardID] }),
        ...(item.duplicatePost && {
          duplicatePost: { ...item.duplicatePost, board: boardMap[item.duplicatePost.boardID] },
        }),
      };
    })
    .filter(isNotNil);
};

export const getPreferencesFromQueryParams = (queryParams: QueryParams) => {
  return {
    ...(queryParams.boardURLNames && { boardURLNames: queryParams.boardURLNames }),
    ...(queryParams.postTypes && { postTypes: queryParams.postTypes }),
    ...(queryParams.source && { source: queryParams.source }),
  };
};

const QueueItemListContainer = (props: Props) => {
  const { boards, queueItemQueries } = props;

  const location = useContext(LocationContext);
  const company = useContext(CompanyContext);

  const getQueueItemList = () => {
    const queryParams = getQueryParams(location);
    const queueQuery = queueItemQueries.queries[getQueryKey(queryParams)];

    if (!queueQuery?.itemIDs || queueQuery.loading) {
      return Object.assign({}, queueQuery, { loading: true, items: [] });
    }

    // We only persist certain filters to viewer preferences. Therefore, to
    // avoid an infinite loading state, we drop these before deep compare
    const queryParamPreferences = getPreferencesFromQueryParams(queryParams);

    const preferencesOutOfSync =
      company?.viewerPreferences?.queueFilters &&
      !deepCompare(queryParamPreferences, company.viewerPreferences.queueFilters);

    const fetchingCannyPosts = queryParams.source === 'canny' || !queryParams.source;

    if (fetchingCannyPosts && preferencesOutOfSync) {
      return Object.assign({}, queueQuery, { loading: true, items: [] });
    }

    return {
      ...queueQuery,
      items: mapAndFilterItems(queueQuery.itemIDs, queueItemQueries, boards),
    };
  };

  const getQueueItemCounts = () => {
    return {
      totalCount: queueItemQueries.count,
      cannyCount: queueItemQueries.postsCount,
      totalSourceCount: queueItemQueries.draftsCount,
      perSourceCounts: queueItemQueries.draftsCountPerSource,
    };
  };

  return cloneElementWithProps(props.children, {
    ...props,
    queueItemList: getQueueItemList(),
    queueItemCounts: getQueueItemCounts(),
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState }, location }) => {
        const isFeedbackDiscovery = location.pathname.includes('/admin/autopilot');
        if (!isFeedbackDiscovery) {
          return;
        }
        const queryParams = getQueryParams(location);
        if (queryParams?.source === 'spam') {
          return;
        }

        const { company } = getState();
        if (company.queueAutomation?.enabled) {
          return;
        }

        return dispatch(loadQueueItems(queryParams));
      },
    },
  ],
  (state) => ({
    queueItemQueries: state.queueItemQueries,
  })
)(QueueItemListContainer);

import React from 'react';

import LazyLoadedImage from 'common/LazyLoadedImage';
import { H5, P } from 'common/ui/Text';
import Chelsea from 'img/landing/chelsea.webp';
import Ida from 'img/landing/ida.webp';
import Liya from 'img/landing/liya.webp';
import Rachel from 'img/landing/rachel.webp';
import Saravana from 'img/landing/saravana.webp';
import Zeb from 'img/landing/zeb.webp';

import Aryeo from 'img/aryeo-logo-circle.png';
import CircleCI from 'img/circleci-logo-circle.png';
import Clickup from 'img/clickup-logo-circle.png';
import Jane from 'img/jane-logo-circle.png';
import Kovai from 'img/kovai-logo-circle.png';
import Mercury from 'img/mercury-logo-circle.png';

const References = {
  zeb: {
    companyName: 'Clickup',
    companyLogo: Clickup,
    quote:
      'Canny intrinsically provides us with a way of connecting and communicating with the people that matter most to our product',
    headshot: Zeb,
    name: 'Zeb Evans, Founder and CEO',
  },
  liya: {
    companyName: 'CircleCI',
    companyLogo: CircleCI,
    quote:
      "Canny is a very valuable data source for us when we're evaluating and prioritizing what we're working on.",
    headshot: Liya,
    name: 'Liya Ai, Product Ops Manager',
  },
  chelsea: {
    companyName: 'Aryeo',
    companyLogo: Aryeo,
    quote:
      'It has reduced the number of inbounds in our support inbox by 20%, which is a big deal.',
    headshot: Chelsea,
    name: 'Chelsea Darby, Customer Success Lead',
  },
  ida: {
    companyName: 'Mercury',
    companyLogo: Mercury,
    quote:
      'Whether you use feedback for your external customers or internal teams, Canny builds a bridge for you.',
    headshot: Ida,
    name: 'Ida Ström, Product Designer',
  },
  saravana: {
    companyName: 'Kovai',
    companyLogo: Kovai,
    quote: 'We’re a data-driven company, and Canny helps us make smart decisions.',
    headshot: Saravana,
    name: 'Saravana Kumar, Founder and CEO',
  },
  rachel: {
    companyName: 'Jane',
    companyLogo: Jane,
    quote:
      'Now I can just invite anyone from the team to submit a request in Canny. They can vote on features on behalf of their partners.',
    headshot: Rachel,
    name: 'Rachel Schaaf, Senior Director of Partner Success',
  },
};

type Props = {
  referenceKey: keyof typeof References;
};

const AdminOnboardingReference = ({ referenceKey }: Props) => {
  const reference = References[referenceKey];

  return (
    <div className="reference">
      <div className="spacer"></div>
      <div className="words">
        <P className="quote" variant="bodyXl">
          {reference.quote}"
        </P>
        <div className="line" />
        <div className="company">
          <H5 className="companyName">{reference.companyName}</H5>
          <LazyLoadedImage alt={`${reference.companyName} logo`} src={reference.companyLogo} />
        </div>
        <P variant="bodyLg">{reference.name}</P>
      </div>
      <div className="headshot">
        <LazyLoadedImage src={reference.headshot} />
      </div>
    </div>
  );
};

export default AdminOnboardingReference;

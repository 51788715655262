import {
  ALL_BOARDS_ERROR,
  ALL_BOARDS_LOADED,
  BOARD_ERROR,
  BOARD_LOADED,
  BOARD_NOT_AUTHORIZED,
  BOARD_NOT_FOUND,
  REQUEST_ALL_BOARDS,
  REQUEST_BOARD,
} from '../actions/boards';

import type { Board } from 'common/api/endpoints/boards';
import type { Action } from 'redux';

type BoardAdditions = Partial<{
  error: string;
  lastUpdated: Date;
  loading: boolean;
  notAuthorized: boolean;
  notFound: boolean;
}>;

export type BoardStateItem = Board & BoardAdditions;

type BoardState = {
  allLoaded: boolean;
  allLoading: boolean;
  error: string | null;
  items: { [key: string]: BoardStateItem };
  lastUpdated: Date | null;
};

export default function boards(
  state: BoardState = {
    allLoaded: false,
    allLoading: false,
    error: null,
    items: {},
    lastUpdated: null,
  },
  action: Action & any // TODO: Import the board actions, once they are typed
) {
  switch (action.type) {
    case REQUEST_ALL_BOARDS: {
      return Object.assign({}, state, {
        allLoading: true,
        lastUpdated: action.timestamp,
      });
    }

    case ALL_BOARDS_LOADED: {
      const items: BoardState['items'] = {};
      action.boards.forEach((board: Board) => {
        items[board.urlName] = Object.assign({}, board);
      });

      return Object.assign({}, state, {
        allLoaded: true,
        allLoading: false,
        items: items,
        lastUpdated: action.timestamp,
      });
    }

    case ALL_BOARDS_ERROR: {
      return Object.assign({}, state, {
        allLoading: false,
        error: action.error,
        lastUpdated: action.timestamp,
      });
    }

    case REQUEST_BOARD: {
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.boardURLName]: {
            error: null,
            lastUpdated: action.timestamp,
            loading: true,
            notAuthorized: false,
            notFound: false,
          },
        }),
      });
    }

    case BOARD_LOADED: {
      const boardObject = Object.assign(
        {},
        state.items[action.boardURLName],
        {
          error: null,
          lastUpdated: action.timestamp,
          loading: false,
          notAuthorized: false,
          notFound: false,
        },
        action.board
      );
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.boardURLName]: boardObject,
          [action.board.urlName]: boardObject,
        }),
      });
    }

    case BOARD_NOT_FOUND: {
      const boardObject = Object.assign({}, state.items[action.boardURLName], {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        notAuthorized: false,
        notFound: true,
        requestedBoardID: action.requestedBoardID,
      });
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.boardURLName]: boardObject,
        }),
      });
    }

    case BOARD_NOT_AUTHORIZED: {
      const boardObject = Object.assign({}, action.errorReason, state.items[action.boardURLName], {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        notAuthorized: true,
        notFound: false,
        requestedBoardID: action.requestedBoardID,
      });
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.boardURLName]: boardObject,
        }),
      });
    }

    case BOARD_ERROR: {
      const boardObject = Object.assign({}, state.items[action.boardURLName], {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
        notAuthorized: false,
        notFound: false,
      });
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.boardURLName]: boardObject,
        }),
      });
    }

    default:
      return state;
  }
}

import React, { useState } from 'react';

import classNames from 'classnames';
import { ChevronDown, ChevronUp } from 'lucide-react';

import CountBadge from 'common/ui/CountBadge';
import capitalizeFirstLetter from 'common/util/capitalizeFirstLetter';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapAccordion.scss';

type Props = {
  children: React.ReactNode;
  count: number;
  groupID: string;
  name: string;
};

const AdminRoadmapAccordion = ({ children, count, groupID, name }: Props) => {
  const [truncated, setTruncated] = useState<boolean>(false);
  return (
    <li
      key={groupID}
      className={classNames('adminRoadmapAccordion', {
        adminRoadmapAccordionTruncated: truncated,
      })}>
      <button className="categoryName" onClick={() => setTruncated(!truncated)}>
        {!truncated ? (
          <div className="iconContainer">
            <ChevronUp className="accordionIcon" />
          </div>
        ) : (
          <div className="iconContainer">
            <ChevronDown className="accordionIcon" />
          </div>
        )}
        {capitalizeFirstLetter(name)}
        <CountBadge className="groupPostCount" size="small" count={count} />
      </button>
      <ul className={classNames('categoryRowBlock', { open: !truncated })}>{children}</ul>
    </li>
  );
};

export default AdminRoadmapAccordion;

import { type ExtensionTypes, Extensions } from 'common/constants/files';

import AttachmentIcon from 'img/icons/attachment-icon.svg';
import DocumentIcon from 'img/icons/document-icon.svg';
import JSONIcon from 'img/icons/json-icon.svg';
import PDFIcon from 'img/icons/pdf-icon.svg';
import PresentationIcon from 'img/icons/presentation-icon.svg';
import SpreadsheetIcon from 'img/icons/spreadsheet-icon.svg';
import TextIcon from 'img/icons/text-icon.svg';
import VideoIcon from 'img/icons/video-icon.svg';

export const FileTypeLabelMap = {
  attachment: { label: 'Attachment', icon: AttachmentIcon },
  document: { label: 'Document', icon: DocumentIcon },
  json: { label: 'Json', icon: JSONIcon },
  pdf: { label: 'PDF', icon: PDFIcon },
  presentation: { label: 'Presentation', icon: PresentationIcon },
  spreadsheet: { label: 'Spreadsheet', icon: SpreadsheetIcon },
  text: { label: 'Text', icon: TextIcon },
  video: { label: 'Video', icon: VideoIcon },
};

export const ExtensionLabelMap: Partial<Record<ExtensionTypes, { label: string; icon: string }>> = {
  [Extensions.txt]: FileTypeLabelMap.text,
  [Extensions.rtf]: FileTypeLabelMap.document,
  [Extensions.csv]: FileTypeLabelMap.spreadsheet,
  [Extensions.md]: FileTypeLabelMap.text,
  [Extensions.yaml]: FileTypeLabelMap.text,
  [Extensions.pdf]: FileTypeLabelMap.pdf,
  [Extensions.doc]: FileTypeLabelMap.document,
  [Extensions.docx]: FileTypeLabelMap.document,
  [Extensions.xls]: FileTypeLabelMap.spreadsheet,
  [Extensions.xlsx]: FileTypeLabelMap.spreadsheet,
  [Extensions.ppt]: FileTypeLabelMap.document,
  [Extensions.pptx]: FileTypeLabelMap.presentation,
  [Extensions.odt]: FileTypeLabelMap.document,
  [Extensions.ods]: FileTypeLabelMap.spreadsheet,
  [Extensions.odp]: FileTypeLabelMap.presentation,
  [Extensions.key]: FileTypeLabelMap.presentation,
  [Extensions.json]: FileTypeLabelMap.json,
  [Extensions.xml]: FileTypeLabelMap.text,
  [Extensions.mp4]: FileTypeLabelMap.video,
  [Extensions.avi]: FileTypeLabelMap.video,
  [Extensions.mov]: FileTypeLabelMap.video,
  [Extensions.wmv]: FileTypeLabelMap.video,
  [Extensions.flv]: FileTypeLabelMap.video,
};

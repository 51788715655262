import capitalizeWords from 'common/util/capitalizeWords';
import urlNamify from 'common/util/urlNamify';

import UserPlaceholderImage from 'img/user-placeholder.svg';

import type { Board } from 'common/api/endpoints/boards';
import type { Company, CompanyPostStatus, Member } from 'common/api/endpoints/companies';
import type { Option } from 'common/ui/common/select/SelectCommon';

const createBoardOptions = (boards: Board[]) => {
  return boards.map((board) => ({
    label: board.name,
    value: board.urlName,
  }));
};

const createCategoryOptions = (boards: Board[], distinctBoardIDs: string[]) => {
  return boards
    .filter((board) => distinctBoardIDs.includes(board._id))
    .map((filteredBoard) =>
      filteredBoard.categories.map((category) => ({
        label: category.name,
        value: category.urlName,
      }))
    )
    .flat();
};

const createOwnerOptions = (company: Company) => {
  return (
    company?.members?.map((member: Member) => ({
      label: member.name,
      value: member.urlName,
      avatar: member.avatarURL || UserPlaceholderImage,
    })) || []
  );
};

const createStatusOptions = (company: Company) => {
  return (
    company?.statuses?.map((status: CompanyPostStatus) => ({
      label: capitalizeWords(status.name),
      value: urlNamify(status.name),
    })) || []
  );
};

const createTagOptions = (boards: Board[], distinctBoardIDs: string[]) => {
  return boards
    .filter((board) => (distinctBoardIDs.length ? distinctBoardIDs.includes(board._id) : true))
    .map((filteredBoard) =>
      filteredBoard.tags.map((tag) => ({
        label: tag.name,
        value: tag.urlName,
      }))
    )
    .flat();
};

const getDefaultFieldSettings = (
  name: string,
  boards: Board[],
  distinctBoardIDs: string[],
  company: Company
) => {
  const settings: { [key: string]: { options: Option[]; placeholder: string } } = {
    boards: {
      options: createBoardOptions(boards),
      placeholder: 'All boards',
    },
    categories: {
      options: createCategoryOptions(boards, distinctBoardIDs),
      placeholder: 'Select a category',
    },
    owner: {
      options: createOwnerOptions(company),
      placeholder: 'All owners',
    },
    status: {
      options: createStatusOptions(company),
      placeholder: 'All statuses',
    },
    tags: {
      options: createTagOptions(boards, distinctBoardIDs),
      placeholder: 'Select a tag',
    },
  };

  return settings[name];
};

export const customFilterOperator = 'equalsOneOf';

export const boolFilterOperator = 'equalsBool';

export default getDefaultFieldSettings;

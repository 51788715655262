import { useEffect, useRef } from 'react';

/**
 * Reactive self-clearing setInterval alternative implemented in a hook.
 * Pass a `null` delay to stop the interval from running
 * @source https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return;
  }, [delay]);
}

export default useInterval;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import translateString from 'common/i18n/translateString';
import { DefaultColors as DefaultPostStatusColors } from 'common/postStatus/PostStatusColors';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import withContexts from 'common/util/withContexts';

import UppercaseHeader from '../UppercaseHeader';

import 'css/components/post/_PostStatusV2.scss';

const isStatusObject = (status) => {
  return !!status?.name;
};

class PostStatus extends Component {
  static propTypes = {
    company: PropTypes.shape({
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          color: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    hideTranslation: PropTypes.bool,
    isBadge: PropTypes.bool,
    showOpen: PropTypes.bool,
    status: PropTypes.oneOfType([
      PropTypes.string, // either the status name
      PropTypes.shape({
        // or the full status object
        _id: PropTypes.string,
        color: PropTypes.string,
        name: PropTypes.string,
      }),
    ]).isRequired,
  };

  static defaultProps = {
    hideTranslation: false,
    showOpen: false,
  };

  render() {
    const { company, isBadge, showOpen } = this.props;

    const status = isStatusObject(this.props.status)
      ? this.props.status
      : company.statuses.find((status) => status.name === this.props.status);
    if (!status) {
      const styling = isBadge
        ? { background: DefaultPostStatusColors.Open }
        : { color: DefaultPostStatusColors.Open };
      return (
        <UppercaseHeader className="postStatus" style={styling}>
          {this.props.status.replace(' ', '\u00a0')}
        </UppercaseHeader>
      );
    }

    if (status.type === PostStatusTypes.Initial && !showOpen) {
      return null;
    }

    const styling = isBadge ? { background: status.color } : { color: status.color };
    return (
      <UppercaseHeader className="postStatus" style={styling}>
        {translateString(status, 'name', this.props.hideTranslation).replace(' ', '\u00a0')}
      </UppercaseHeader>
    );
  }
}

export default withContexts({ company: CompanyContext })(PostStatus);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ImageMimeTypes } from 'common/constants/files';
import { OpenModalContext } from 'common/containers/ModalContainer';
import Form from 'common/Form';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import UploadImageButton from 'common/inputs/UploadImageButton';
import KeyCodes from 'common/KeyCodes';
import Markdown from 'common/markdown/Markdown';
import ErrorModal from 'common/modals/ErrorModal';
import PhotoViewer from 'common/PhotoViewer';
import Tappable from 'common/Tappable';
import UppercaseHeader from 'common/UppercaseHeader';
import addOrRemoveLinePrefix from 'common/util/textFormat/addOrRemoveLinePrefix';
import addOrRemoveSurroundingCharacters from 'common/util/textFormat/addOrRemoveSurroundingCharacters';
import updateRangeThenSelectRange from 'common/util/textFormat/updateRangeThenSelectRange';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminDetailsInputWithFormatBar.scss';

class AdminDetailsInputWithFormatBar extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    hideEditModeButtons: PropTypes.bool,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    openModal: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    hideEditModeButtons: false,
  };

  state = {
    detailsValue: this.props.defaultValue,
    mode: 'edit',
  };

  constructor(props, context) {
    super(props, context);

    this.detailsInputRef = React.createRef();
    this.uploadImageButtonRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onBoldTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    const range = detailsInput.getSelectionRange();
    const value = detailsInput.getValue();

    const selection = value.substring(range.start, range.end);
    const isMultiline = selection.includes('\n');

    if (isMultiline) {
      addOrRemoveSurroundingCharacters(detailsInput, '**\n', '\n**');
    } else {
      addOrRemoveSurroundingCharacters(detailsInput, '**', '**');
    }
  };

  onCodeTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    const range = detailsInput.getSelectionRange();
    const value = detailsInput.getValue();

    const selection = value.substring(range.start, range.end);
    const isMultiline = selection.includes('\n');

    if (isMultiline) {
      addOrRemoveSurroundingCharacters(detailsInput, '```\n', '\n```');
    } else {
      addOrRemoveSurroundingCharacters(detailsInput, '`', '`');
    }
  };

  onDetailsChange = (e) => {
    const detailsValue = e.target.value;
    this.setState({
      detailsValue,
    });

    this.props.onChange(detailsValue);
  };

  onEditSelected = () => {
    this.setState({
      mode: 'edit',
    });
  };

  onHeadingTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    addOrRemoveSurroundingCharacters(detailsInput, '# ', '');
  };

  onImage = (image) => {
    this.uploadImageButtonRef.current.onFile(image);
  };

  onImageError = (error) => {
    this.props.openModal(ErrorModal, {
      message: error,
    });
  };

  onImageTapped = (imageURL) => {
    this.props.openModal(PhotoViewer, {
      imageURLs: [imageURL],
      defaultIndex: 0,
    });
  };

  onImageUploaded = (imageURL, imageName) => {
    const detailsInput = this.detailsInputRef.current;
    const range = detailsInput.getSelectionRange();
    const selectionEnd = range.end;
    const value = detailsInput.getValue();

    // not start of string &&
    // not preceeded by fspace/newline
    const addNewline = selectionEnd !== 0 && !value[selectionEnd - 1].match(/(^|\s)$/);

    const before = value.substring(0, selectionEnd);
    const insert =
      (addNewline ? '\n' : '') + ('![' + imageName.split('.')[0] + '](' + imageURL + ')\n');

    updateRangeThenSelectRange(
      detailsInput,
      {
        start: selectionEnd,
        end: selectionEnd,
      },
      insert,
      {
        start: before.length + insert.length,
        end: before.length + insert.length,
      }
    );
  };

  onItalicsTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    const range = detailsInput.getSelectionRange();
    const value = detailsInput.getValue();

    const selection = value.substring(range.start, range.end);
    const isMultiline = selection.includes('\n');

    if (isMultiline) {
      addOrRemoveSurroundingCharacters(detailsInput, '*\n', '\n*');
    } else {
      addOrRemoveSurroundingCharacters(detailsInput, '*', '*');
    }
  };

  onKeyDown = (e) => {
    const { mode } = this.state;
    if (mode !== 'edit') {
      return;
    }

    // mac: command / ctrl, windows: ctrl
    const isMetaPressed = e.metaKey || e.ctrlKey;
    if (!isMetaPressed) {
      return;
    }

    if (e.keyCode === KeyCodes.B) {
      this.onBoldTapped();
      e.preventDefault();
    } else if (e.keyCode === KeyCodes.I) {
      this.onItalicsTapped();
      e.preventDefault();
    } else if (e.keyCode === KeyCodes.K) {
      this.onLinkTapped();
      e.preventDefault();
    }
  };

  onLinkTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    const range = detailsInput.getSelectionRange();
    const value = detailsInput.getValue();

    const selection = value.substring(range.start, range.end);
    const isMultiline = selection.includes('\n');

    if (isMultiline) {
      addOrRemoveSurroundingCharacters(detailsInput, '', ' [](url)');
      setTimeout(() => {
        const selectionRange = detailsInput.getSelectionRange();
        detailsInput.setSelectionRange(selectionRange.end + 4, selectionRange.end + 7);
      }, 0);
    } else {
      addOrRemoveSurroundingCharacters(detailsInput, '[', '](url)');
      setTimeout(() => {
        const selectionRange = detailsInput.getSelectionRange();
        detailsInput.setSelectionRange(selectionRange.end + 2, selectionRange.end + 5);
      }, 0);
    }
  };

  onOrderedListTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    addOrRemoveLinePrefix(detailsInput, 'i. ');
  };

  onPreviewSelected = () => {
    this.setState({
      mode: 'preview',
    });
  };

  onUnorderedListTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    addOrRemoveLinePrefix(detailsInput, '- ');
  };

  onVideoTapped = () => {
    const detailsInput = this.detailsInputRef.current;
    const range = detailsInput.getSelectionRange();
    const value = detailsInput.getValue();

    const selection = value.substring(range.start, range.end);
    const isMultiline = selection.includes('\n');

    if (isMultiline) {
      addOrRemoveSurroundingCharacters(
        detailsInput,
        '',
        ' ![](Insert YouTube/Wistia/Loom/Vimeo video URL here)'
      );
    } else {
      addOrRemoveSurroundingCharacters(
        detailsInput,
        '![',
        '](Insert YouTube/Wistia/Loom/Vimeo video URL here)'
      );
    }
  };

  renderFormattingBar() {
    const { hideEditModeButtons } = this.props;
    const { mode } = this.state;
    const leftButtons = (
      <div>
        <Tappable onTap={this.onEditSelected}>
          <UppercaseHeader className={mode === 'edit' ? 'selected' : ''}>Edit</UppercaseHeader>
        </Tappable>
        <Tappable onTap={this.onPreviewSelected}>
          <UppercaseHeader className={mode === 'preview' ? 'selected' : ''}>
            Preview
          </UppercaseHeader>
        </Tappable>
      </div>
    );

    const rightButtons =
      mode === 'edit' ? (
        <div>
          <div className="grouping">
            <Tappable onTap={this.onHeadingTapped}>
              <div className="h1">H1</div>
            </Tappable>
            <Tappable onTap={this.onBoldTapped}>
              <div className="icon icon-bold" />
            </Tappable>
            <Tappable onTap={this.onItalicsTapped}>
              <div className="icon icon-italics" />
            </Tappable>
          </div>
          <div className="grouping">
            <Tappable onTap={this.onLinkTapped}>
              <div className="icon icon-link" />
            </Tappable>
            <Tappable onTap={this.onCodeTapped}>
              <div className="icon icon-code" />
            </Tappable>
            <Tappable onTap={this.onUnorderedListTapped}>
              <div className="icon icon-list" />
            </Tappable>
            <Tappable onTap={this.onOrderedListTapped}>
              <div className="icon icon-ordered-list" />
            </Tappable>
          </div>
          <div className="grouping">
            <UploadImageButton
              ref={this.uploadImageButtonRef}
              onError={this.onImageError}
              onImageUploaded={this.onImageUploaded}
            />
            <Tappable onTap={this.onVideoTapped}>
              <div className="icon icon-video" />
            </Tappable>
          </div>
        </div>
      ) : null;

    return (
      <div className="formattingBar">
        {!hideEditModeButtons && leftButtons}
        {rightButtons}
      </div>
    );
  }

  render() {
    const { defaultValue, disabled, placeholder } = this.props;
    const { detailsValue, mode } = this.state;
    return (
      <div className="adminDetailsInputWithFormatBar">
        {this.renderFormattingBar()}
        {mode === 'edit' ? (
          <Form
            acceptedFileTypes={ImageMimeTypes}
            addEventsToDocument={false}
            allowFileUpload={true}
            onFile={this.onImage}>
            <AutoResizeTextarea
              className="detailsInput"
              defaultValue={defaultValue}
              definedHeight="100%"
              disabled={disabled}
              minRows={5}
              onChange={this.onDetailsChange}
              placeholder={placeholder}
              ref={this.detailsInputRef}
            />
          </Form>
        ) : (
          <Markdown contents={detailsValue} onImageTapped={this.onImageTapped} />
        )}
      </div>
    );
  }
}

export default withContexts({ openModal: OpenModalContext })(AdminDetailsInputWithFormatBar);

import React from 'react';

import classnames from 'classnames';

import 'css/components/_Text.scss';

enum Variants {
  headingXs = 'headingXs',
  headingSm = 'headingSm',
  headingMd = 'headingMd',
  headingLg = 'headingLg',
  headingXl = 'headingXl',
  heading2Xl = 'heading2Xl',
  heading3Xl = 'heading3Xl',
  heading4Xl = 'heading4Xl',
  displaySm = 'displaySm',
  displayMd = 'displayMd',
}

enum BodyVariants {
  bodySm = 'bodySm',
  bodyMd = 'bodyMd',
  bodyLg = 'bodyLg',
  bodyXl = 'bodyXl',
}
enum FontWeights {
  bold = 'bold',
  medium = 'medium',
  regular = 'regular',
  semibold = 'semibold',
}
type Element = keyof React.ReactHTML;

const defaultVariants = {
  h1: Variants.heading3Xl,
  h2: Variants.heading2Xl,
  h3: Variants.headingXl,
  h4: Variants.headingLg,
  h5: Variants.headingMd,
  h6: Variants.headingSm,
  p: BodyVariants.bodyMd,
  span: BodyVariants.bodyMd,
} as const;

interface DefaultProps extends React.HTMLAttributes<HTMLElement> {
  element: Element;
  className?: string;
  children: React.ReactNode;
  resetStyles?: boolean;
}
type HeadingVariantProps = {
  variant?: keyof typeof Variants;
  underline?: never;
  fontWeight?: never;
  italic?: never;
};
type BodyVariantProps = {
  variant?: keyof typeof BodyVariants;
  fontWeight?: keyof typeof FontWeights;
  underline?: boolean;
  italic?: boolean;
};
type Props = DefaultProps & (HeadingVariantProps | BodyVariantProps);

const isDefaultVariant = (
  variant: keyof React.ReactHTML
): variant is keyof typeof defaultVariants => defaultVariants.hasOwnProperty(variant);

const Text = ({
  children,
  className,
  element: Element,
  fontWeight = FontWeights.regular,
  italic,
  resetStyles,
  underline,
  variant,
  ...props
}: Props) => {
  const calculatedVariant =
    variant || (isDefaultVariant(Element) && defaultVariants[Element]) || BodyVariants.bodyMd;
  const isBodyVariant =
    calculatedVariant === BodyVariants.bodyLg ||
    calculatedVariant === BodyVariants.bodySm ||
    calculatedVariant === BodyVariants.bodyXl ||
    calculatedVariant === BodyVariants.bodyMd;
  return (
    <Element
      {...props}
      className={classnames(
        'textV2',
        className,
        {
          [calculatedVariant]: true,
          resetStyles,
        },
        isBodyVariant && { [`${fontWeight}-weight`]: true, underline, italic }
      )}>
      {children}
    </Element>
  );
};
type PropsWithoutElement = Omit<DefaultProps, 'element'> & (HeadingVariantProps | BodyVariantProps);
export const P = (props: PropsWithoutElement) => <Text {...props} element="p" />;
export const H1 = (props: PropsWithoutElement) => <Text {...props} element="h1" />;
export const H2 = (props: PropsWithoutElement) => <Text {...props} element="h2" />;
export const H3 = (props: PropsWithoutElement) => <Text {...props} element="h3" />;
export const H4 = (props: PropsWithoutElement) => <Text {...props} element="h4" />;
export const H5 = (props: PropsWithoutElement) => <Text {...props} element="h5" />;
export const H6 = (props: PropsWithoutElement) => <Text {...props} element="h6" />;
export const Span = (props: PropsWithoutElement) => <Text {...props} element="span" />;

export default Text;

import React from 'react';

import { ColorNames } from 'common/colors/constants';
import { colorPaletteHash } from 'common/colors/utils';
import Avatar, { type Props as AvatarProps } from 'common/ui/Avatar';

import type { StrippedUserWithAlias } from 'common/api/endpoints/users';

const DefaultID = '1';

interface Props extends Omit<AvatarProps, 'value' | 'variant' | 'type' | 'color'> {
  user: StrippedUserWithAlias;
  color?: AvatarProps['color'];
}

const UserAvatarV2 = ({ user, alt, ...props }: Props) => {
  if (user.avatarURL) {
    return (
      <Avatar
        {...props}
        value={user.avatarURL}
        shape="circle"
        variant="image"
        color={ColorNames.blue}
        alt={alt || user.name}
      />
    );
  }

  const _id = user._id ?? user.aliasID ?? DefaultID;

  return (
    <Avatar
      {...props}
      color={props.color ?? colorPaletteHash(_id)}
      value={user.name.charAt(0)}
      shape="circle"
      variant="initial"
    />
  );
};

export default UserAvatarV2;

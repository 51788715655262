const EffortTypes = {
  fibonacci: {
    name: 'fibonacci',
    normalize: (value) => {
      return Math.max(1, value / 2.33);
    },
    validate: (value) => {
      if (value === null) {
        return true;
      }
      if (typeof value !== 'number') {
        return false;
      } else if (Number.isNaN(value)) {
        return false;
      } else if (value < 1 || value > 233) {
        return false;
      } else if (!Number.isInteger(value)) {
        return false;
      }
      return true;
    },
  },
  numberToOneHundred: {
    name: 'numberToOneHundred',
    normalize: (value) => value,
    validate: (value) => {
      if (value === null) {
        return true;
      }
      if (typeof value !== 'number') {
        return false;
      } else if (Number.isNaN(value)) {
        return false;
      } else if (value < 0.1 || value > 100) {
        return false;
      } else if (!Number.isInteger(value * 10)) {
        return false;
      }
      return true;
    },
  },
  numberToTen: {
    name: 'numberToTen',
    normalize: (value) => {
      return value * value;
    },
    validate: (value) => {
      if (value === null) {
        return true;
      }
      if (typeof value !== 'number') {
        return false;
      } else if (Number.isNaN(value)) {
        return false;
      } else if (value < 1 || value > 10) {
        return false;
      } else if (!Number.isInteger(value)) {
        return false;
      }
      return true;
    },
  },
  stars: {
    name: 'stars',
    normalize: (value) => {
      return Math.pow(2 * value, 2);
    },
    validate: (value) => {
      if (value === null) {
        return true;
      }
      if (typeof value !== 'number') {
        return false;
      } else if (Number.isNaN(value)) {
        return false;
      } else if (value < 1 || value > 5) {
        return false;
      } else if (!Number.isInteger(value)) {
        return false;
      }
      return true;
    },
  },
};

export default EffortTypes;

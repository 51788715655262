import validateInput from 'common/validateInput';

enum AttributeOp {
  after = 'after',
  afterNDaysAgo = 'afterNDaysAgo',
  before = 'before',
  beforeNDaysAgo = 'beforeNDaysAgo',
  contains = 'contains',
  doesNotContain = 'doesNotContain',
  greaterThan = 'greaterThan',
  is = 'is',
  isFalse = 'isFalse',
  isTrue = 'isTrue',
  isNot = 'isNot',
  isNotNull = 'isNotNull',
  isNull = 'isNull',
  lessThan = 'lessThan',
  on = 'on',
  onNDaysAgo = 'onNDaysAgo',
}

const { primitives, string } = validateInput;

export const StandardFields = {
  'company.created': {
    fieldType: 'date',
    name: 'companyCreated',
    objectType: 'company',
    prettyName: 'Company Created',
  },
  'company.id': {
    fieldType: 'string',
    name: 'thirdPartyCompanyID',
    objectType: 'company',
    prettyName: 'Company ID',
  },
  'company.mrr': {
    fieldType: 'number',
    name: 'monthlySpend',
    objectType: 'company',
    prettyName: 'Monthly Recurring Revenue',
  },
  'company.name': {
    fieldType: 'string',
    name: 'name',
    objectType: 'company',
    prettyName: 'Company Name',
  },
  // user.created and user.id can only match against ThirdPartyUserModels
  'user.created': {
    fieldType: 'date',
    name: 'userCreated',
    objectType: 'user',
    prettyName: 'User Created',
  },
  'user.id': {
    fieldType: 'string',
    name: 'thirdPartyUserID',
    objectType: 'user',
    prettyName: 'User ID',
  },
  // user.name and user.email can match against ThirdPartyUserModels or UserModels
  'user.email': {
    fieldType: 'string',
    name: 'email',
    objectType: 'user',
    prettyName: 'User Email',
  },
  'user.name': {
    fieldType: 'string',
    name: 'name',
    objectType: 'user',
    prettyName: 'User Name',
  },
};

export const TypeOpValidation = {
  boolean: {
    isFalse: { label: 'is false' },
    isNotNull: { label: 'has any value' },
    isNull: { label: 'is null' },
    isTrue: { label: 'is true' },
  },
  date: {
    after: { inputType: 'date', label: 'after date', validator: primitives.isoString },
    afterNDaysAgo: {
      inputType: 'number',
      label: 'less than x days ago',
      validator: primitives.integer,
    },
    before: { inputType: 'date', label: 'before date', validator: primitives.isoString },
    beforeNDaysAgo: {
      inputType: 'number',
      label: 'more than x days ago',
      validator: primitives.integer,
    },
    isNotNull: { label: 'is not null' },
    isNull: { label: 'is null' },
    on: { inputType: 'date', label: 'on date', validator: primitives.isoString },
    onNDaysAgo: { inputType: 'number', label: 'exactly x days ago', validator: primitives.integer },
  },
  number: {
    greaterThan: { inputType: 'number', label: 'greater than', validator: primitives.number },
    is: { inputType: 'number', label: 'is', validator: primitives.number },
    isNot: { inputType: 'number', label: 'is not', validator: primitives.number },
    isNotNull: { label: 'is not null' },
    isNull: { label: 'is null' },
    lessThan: { inputType: 'number', label: 'less than', validator: primitives.number },
  },
  string: {
    is: { inputType: 'text', label: 'is', validator: string },
    isNot: { inputType: 'text', label: 'is not', validator: string },
    isNotNull: { label: 'is not null' },
    isNull: { label: 'is null' },
    contains: { inputType: 'text', label: 'contains', validator: string },
    doesNotContain: { inputType: 'text', label: 'does not contain', validator: string },
  },
};

export const validateAttribute = (attribute: {
  op: AttributeOp;
  fieldType: keyof typeof TypeOpValidation | null;
  value: unknown;
}) => {
  const { op, fieldType, value } = attribute;

  if (!fieldType || !TypeOpValidation[fieldType]) {
    throw new Error('Invalid fieldType');
  }

  if (!(op in TypeOpValidation[fieldType])) {
    throw new Error('Invalid operator');
  }

  const typeOperator: { inputType: string; label: string; validator?: (value: any) => boolean } = (
    TypeOpValidation[fieldType] as any
  )[op];

  if (typeOperator.validator) {
    const format = { field: typeOperator };
    const data = { field: value };
    const error = validateInput.validate(format, data);
    if (error) {
      throw new Error('Invalid value');
    }
  }
};

const buildAttribute = (
  attribute: {
    customFieldID?: string;
    op: AttributeOp;
    standardFieldID?: string;
    value: unknown;
  },
  customFieldMap: Record<
    string,
    {
      fieldType: 'boolean' | 'number' | 'date' | 'string' | null;
      name: string;
      objectType: 'company' | 'user';
    }
  >
) => {
  const { customFieldID, op, standardFieldID, value } = attribute;

  if (!customFieldID && !standardFieldID) {
    throw new Error('No field defined');
  }

  if (!op) {
    throw new Error('No operator defined');
  }

  const builtAttribute: {
    fieldType: keyof typeof TypeOpValidation | null;
    field: string;
    objectType: string;
    op: AttributeOp;
    value: unknown;
  } = {
    fieldType: null,
    field: '',
    objectType: '',
    op,
    value,
  };
  if (standardFieldID) {
    const standardField = StandardFields[standardFieldID as keyof typeof StandardFields];

    if (!standardField) {
      throw new Error('Invalid standardFieldID');
    }

    builtAttribute.fieldType = standardField.fieldType as keyof typeof TypeOpValidation;
    builtAttribute.field = standardField.name;
    builtAttribute.objectType = standardField.objectType;
  } else if (customFieldID) {
    const customField = customFieldMap[customFieldID.toString()];

    if (!customField) {
      throw new Error('Invalid customFieldID');
    }
    builtAttribute.fieldType = customField.fieldType;
    builtAttribute.field = 'customFields.' + customField.name;
    builtAttribute.objectType = customField.objectType;
  }

  validateAttribute(builtAttribute);
  return builtAttribute;
};

export default buildAttribute;

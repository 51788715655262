import React from 'react';

import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import truncateString from 'common/truncateString';
import UppercaseHeader from 'common/UppercaseHeader';

import type { Board } from 'common/api/endpoints/boards';

import 'css/components/common/_BoardGridItem.scss';

const MaxChars = 30;

type Props = {
  board: Board;
};

const BoardGridItem = ({ board }: Props) => {
  const { activePostCount } = board;
  const boardURL = '/' + board.urlName;
  const truncatedName = truncateString(translateString(board, 'name'), MaxChars);

  return (
    <Link to={boardURL} className="boardGridItem">
      <div className="top">
        {truncatedName ? <div className="name">{truncatedName}</div> : null}
        {activePostCount ? (
          <UppercaseHeader className="posts">{activePostCount}</UppercaseHeader>
        ) : null}
      </div>
    </Link>
  );
};
export default BoardGridItem;

import diacritics from './diacritics';
import escapeRegExp from './escapeRegExp';

export default function findStringMatches<T>(
  objects: T[],
  key: keyof T | ((object: T) => string),
  search: string,
  { returnAll = false, prefixOnly = false } = {}
) {
  const getString = (object: T): string => {
    if (typeof key === 'function') {
      return diacritics.remove(key(object));
    }
    return diacritics.remove(String(object[key]));
  };

  objects.forEach((object) => {
    if (!object) {
      throw new Error(`Unable to find matches, one object is ${object}`);
    } else if (typeof object !== 'object') {
      throw new Error(`Unable to find matches, one object is ${typeof object}`);
    } else if (typeof getString(object) !== 'string') {
      throw new Error(`Unable to find matches, one object[key] is ${typeof object}`);
    }
  });
  const trimmedSearch = search.trim();
  if (trimmedSearch === '') {
    return objects;
  }

  const transformedSearch = diacritics.remove(escapeRegExp(trimmedSearch));
  const matchSet: Record<string, boolean> = {};

  // exact matches
  const exactMatches = objects.filter((object, i) => {
    const string = getString(object);
    const isExactMatch = string.match(new RegExp('^' + transformedSearch + '$', 'i'));
    if (!isExactMatch) {
      return false;
    }

    matchSet[i] = true;
    return true;
  });

  // prefix matches
  const prefixMatches = objects.filter((object, i) => {
    if (matchSet[i]) {
      return false;
    }

    const string = getString(object);
    const isPrefixMatch = string.match(new RegExp('^' + transformedSearch, 'i'));
    if (!isPrefixMatch) {
      return false;
    }

    matchSet[i] = true;
    return true;
  });

  if (prefixOnly) {
    return exactMatches.concat(prefixMatches);
  }

  // other matches
  const otherMatches = objects.filter((object, i) => {
    if (matchSet[i]) {
      return false;
    }

    const string = getString(object);
    const isOtherMatch = string.match(new RegExp(transformedSearch, 'i'));
    if (!isOtherMatch) {
      return false;
    }

    matchSet[i] = true;
    return true;
  });

  const allMatches = exactMatches.concat(prefixMatches).concat(otherMatches);

  if (returnAll) {
    const nonMatches = objects.filter((_, i) => !matchSet[i]);
    return allMatches.concat(nonMatches);
  }

  return allMatches;
}

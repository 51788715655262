import React, { useContext, useState } from 'react';

import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { type Post, PostSummaryStatus } from 'common/api/resources/posts';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import useInterval from 'common/hooks/useInterval';
import AccessModal from 'common/modals/AccessModal';
import SummaryUI from 'common/post/Summary/SummaryUI';
import { SummaryErrors, canSummarize, shouldRenderSummary } from 'common/post/Summary/utils';
import { dayjs } from 'common/util/dayjsUtils';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { RolePermissionName } from 'common/api/endpoints/companies';
import type { Dispatch } from 'redux-connect';

const ThirtySeconds = 30 * 1000;

type SummaryProps = {
  className?: string;
  post: Post;
  reloadPost: (post: Post) => Promise<void>;
};

const Summary = ({ className, post, reloadPost }: SummaryProps) => {
  const [deleting, setDeleting] = useState(false);
  const [resummarizing, setResummarizing] = useState(false);

  const showToast = useContext(ShowToastContext);
  const company = useContext(CompanyContext);
  const viewer = useContext(ViewerContext);
  const openModal = useContext(OpenModalContext);

  // reload post every 30 seconds until the summary either succeeds or fails
  useInterval(
    () => reloadPost(post),
    post.summary?.status === PostSummaryStatus.pending ? ThirtySeconds : null
  );

  if (!shouldRenderSummary(post, company)) {
    return null;
  }

  const deleteSummary = async () => {
    const permission: RolePermissionName = 'summarizeComments';
    if (!hasPermission(permission, company, viewer)) {
      openModal(AccessModal, { requiredPermissions: [permission] });
      return;
    }

    setDeleting(true);

    const response = await AJAX.post('/api/comments/summary/delete', {
      summaryID: post.summary._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: SummaryErrors,
    });

    if (error) {
      setDeleting(false);
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadPost(post);
    setDeleting(false);
    showToast('Comment summary successfully deleted', ToastTypes.success);
  };

  const resummarize = async () => {
    const permission: RolePermissionName = 'summarizeComments';
    if (!hasPermission(permission, company, viewer)) {
      openModal(AccessModal, { requiredPermissions: [permission] });
      return;
    }

    const lastSummaryIsRecent =
      post.summary && dayjs(post.summary.updatedAt).isSame(new Date(), 'day');
    const lastSummaryFailed = post.summary && post.summary.status === PostSummaryStatus.failed;
    if (lastSummaryIsRecent && !lastSummaryFailed) {
      showToast('Comments can be summarized at most once a day per post', ToastTypes.error);
      return;
    }

    setResummarizing(true);

    const response = await AJAX.post('/api/comments/summary/create', {
      postID: post.summary.postID,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: SummaryErrors,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      setResummarizing(false);
      return;
    }

    await reloadPost(post);
    setResummarizing(false);
  };

  const canResummarize = canSummarize(post, company);
  return (
    <SummaryUI
      className={className}
      summary={post.summary}
      resummarize={canResummarize ? resummarize : undefined}
      resummarizing={resummarizing}
      deleteSummary={deleteSummary}
      deleting={deleting}
    />
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadPost: (post: Post) => dispatch(reloadPost(post)),
}))(Summary);

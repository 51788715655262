import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Toggle from 'common/common/Toggle';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import Tappable from 'common/Tappable';
import SwitchV2 from 'common/ui/SwitchV2';
import { P } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminChangelogSettingsPrivacy.scss';

class AdminChangelogSettingsPrivacy extends Component {
  static propTypes = {
    company: PropTypes.object,
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    allowIdentified: this.props.company.changelog.allowIdentified,
    domains: this.props.company.changelog.domains,
    error: null,
    enableEmailSubscriptions: this.props.company.changelog.enableEmailSubscriptions,
    needsSave: false,
    private: this.props.company.changelog.private,
    saving: false,
    modal: null,
  };

  componentDidMount() {
    const { company, openModal, router, viewer } = this.props;
    if (hasPermission('manageChangelogPrivacy', company, viewer)) {
      return;
    }

    router.replace('/admin/changelog');
    openModal(
      AccessModal,
      {
        requiredPermissions: ['manageChangelogPrivacy'],
      },
      {
        allowRouteChange: true,
      }
    );
  }

  onDomainsChange = (e) => {
    const domains = e.target.value.split(/[,@\s]+/g).filter((domain) => {
      return !!domain.trim();
    });
    this.setState({
      domains,
      needsSave: true,
    });
  };

  onToggleEmailSubscriptions = () => {
    const { company } = this.props;
    if (!company.features.changelogEmailSubscriptions && !this.state.enableEmailSubscriptions) {
      this.setState({ modal: 'upsellEmailSubscriptions' });
      return;
    }

    this.setState((state) => ({
      needsSave: true,
      enableEmailSubscriptions: !state.enableEmailSubscriptions,
    }));
  };

  onPrivateSelected = () => {
    this.setState({
      needsSave: true,
      private: true,
    });
  };

  onPublicSelected = () => {
    this.setState({
      needsSave: true,
      private: false,
      allowIdentified: false,
    });
  };

  onSave = async () => {
    const { reloadCompany } = this.props;
    const { domains, saving } = this.state;

    if (saving) {
      return;
    }

    this.setState({ saving: true });

    const response = await AJAX.post('/api/changelog/editSettings', {
      allowIdentified: this.state.allowIdentified,
      domains,
      enableEmailSubscriptions: this.state.enableEmailSubscriptions,
      private: this.state.private,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'plan does not support private changelog':
          'Your plan does not support private changelog. Please upgrade to access this feature.',
        'plan does not support  changelog subscriptions':
          'Your plan does not support email subscriptions. Please upgrade to access this feature.',
      },
    });

    if (error) {
      this.setState({ error: error.message, saving: false, needsSave: false });
      return;
    }

    await reloadCompany();

    this.setState({ saving: false, needsSave: false });
  };

  renderErrorMessage() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderPrivateOptions() {
    if (!this.state.private) {
      return null;
    }

    const {
      company: { features, changelog },
    } = this.props;
    if (!features?.privateChangelog) {
      return (
        <AdminFeatureUpsell
          cta="Limit changelog access to specific&nbsp;users"
          feature="privateChangelog"
        />
      );
    }

    return (
      <div className="privateOptions">
        <div className="optionDetail">
          <div className="toggleContainer">
            <Toggle
              onToggle={(allowIdentified) => {
                this.setState({
                  allowIdentified,
                  needsSave: true,
                });
              }}
              value={this.state.allowIdentified}
            />
          </div>
          <div className="toggleValueContainer">
            <div className="subheading">Users/customers</div>
            <div className="text">
              {'Grant access to anyone who has been identified via our '}
              <a href="https://developers.canny.io/install" rel="noopener" target="_blank">
                client&#8209;side&nbsp;SDK
              </a>{' '}
              or{' '}
              <a
                href="https://developers.canny.io/install/widget/sso"
                rel="noopener"
                target="_blank">
                single&nbsp;sign&#8209;on
              </a>
              .
            </div>
          </div>
        </div>
        <div className="optionDetail">
          <div>
            <div className="subheading">Specific email by domain</div>
            <div className="text">
              Give access to people who have @yourcompany.com emails (authenticated via an OAuth
              provider)
            </div>
            <TextInput
              defaultValue={changelog.domains.join(', ')}
              inset="Email Domains"
              onChange={this.onDomainsChange}
              placeholder="canny.io, example.com"
            />
          </div>
        </div>
      </div>
    );
  }

  renderSaveButton() {
    const { domains, needsSave, saving } = this.state;
    const domainsAreValid = domains.every((domain) => {
      return domain.match(/(([0-9a-z]+\.)+)?[0-9a-z]+\.[0-9a-z]+/gi);
    });
    const {
      company: { features },
    } = this.props;
    const planSupports = !this.state.private || features?.privateChangelog;
    const canSave = needsSave && domainsAreValid && planSupports;
    return <Button disabled={!canSave} loading={saving} onTap={this.onSave} value="Save" />;
  }

  render() {
    return (
      <div className="adminChangelogSettingsPrivacy">
        <Helmet title="Privacy Settings | Changelog | Canny" />
        <div className="privacyOptions">
          <Tappable onTap={this.onPublicSelected}>
            <div className={'option' + (this.state.private ? '' : ' selected')}>
              <div className="topContainer">
                <div className="name">Public</div>
                <div className="description">Anybody can access your&nbsp;changelog</div>
              </div>
            </div>
          </Tappable>
          <Tappable onTap={this.onPrivateSelected}>
            <div className={'option' + (this.state.private ? ' selected' : '')}>
              <div className="topContainer">
                <div className="name">Private</div>
                <div className="description">Limit changelog access to your&nbsp;team</div>
              </div>
            </div>
          </Tappable>
        </div>
        {this.renderPrivateOptions()}
        <div className="subscriptionsToggle">
          <div className="subscriptionsToggleDescription">
            <P fontWeight="semibold">Email subscriptions</P>
            <P className="subtitle">Allow users to subscribe to changelog updates</P>
          </div>
          <SwitchV2
            onChange={this.onToggleEmailSubscriptions}
            checked={this.state.enableEmailSubscriptions}
          />
        </div>
        {this.renderErrorMessage()}
        {this.renderSaveButton()}
        <UpsellModal
          feature="changelogEmailSubscriptions"
          onClose={() => this.setState({ modal: null })}
          show={this.state.modal === 'upsellEmailSubscriptions'}
        />
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.changelog.privacy),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminChangelogSettingsPrivacy);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

export const IsBrowserExtensionContext = React.createContext();

export default class IsBrowserExtensionContainer extends Component {
  static propTypes = {
    isBrowserExtension: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <IsBrowserExtensionContext.Provider value={this.props.isBrowserExtension}>
        {this.props.children}
      </IsBrowserExtensionContext.Provider>
    );
  }
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Buffer } from 'buffer';

import AJAX from 'common/AJAX';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import EngagementBreakdown, {
  Activity,
  Group,
} from 'common/subdomain/admin/AdminAnalytics/EngagementBreakdown';
import { RelativeDateOptions, RelativeDateRanges } from 'common/util/dateRanges';
import { dayjs } from 'common/util/dayjsUtils';
import mapify from 'common/util/mapify';
import parseAPIResponse from 'common/util/parseAPIResponse';
import queryString from 'common/util/queryString';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { BoardStateItem } from 'common/reducers/boards';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardEngagementBreakdown.scss';

type Filters = {
  activity: Activity;
  dateRange: null | [string, string];
  group: Group;
  boardID: null | string;
  segmentID: null | string;
};

const AdminDashboardEngagementBreakdown = () => {
  const company = useContext<Company>(CompanyContext);
  // We have a check below to ensure this assertion is correct
  let boards = useContext(BoardsContext) as BoardStateItem[];
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const trackEvent = useContext(TrackEventContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Record<string, number> | null>(null);

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!Array.isArray(boards)) {
    boards = [];
  }

  // `useMemo` is required because we use analyticsFilters as a dependency of
  // `useEffect` later.
  const engagementBreakdownParam = location.query['engagement-breakdown'];
  const analyticsFilters: Filters = useMemo(() => {
    // decode query params
    const payload = Buffer.from(engagementBreakdownParam ?? '', 'base64').toString();

    // default filters
    let filters = {
      activity: Activity.posts,
      dateRange: RelativeDateOptions[RelativeDateRanges.lastWeek].toRange() as [string, string],
      group: Group.boards,
      boardID: null,
      segmentID: null,
    };

    try {
      filters = JSON.parse(payload);
    } catch (e) {
      // keep default filters
      null;
    }

    return filters;
  }, [engagementBreakdownParam]);

  const categories = useMemo(
    () =>
      boards.reduce<Board['categories']>((accumulator, board, _) => {
        const boardCategories = board.categories.map((category) => {
          return {
            ...category,
            boardID: board._id,
          };
        });
        return [...accumulator, ...boardCategories];
      }, []),
    [boards]
  );

  const tags = useMemo(
    () =>
      boards.reduce<Board['tags']>((accumulator, board, _) => {
        const boardTags = board.tags.map((tag) => {
          return {
            ...tag,
            boardID: board._id,
          };
        });
        return [...accumulator, ...boardTags];
      }, []),
    [boards]
  );

  useEffect(() => {
    const fetchAnalytics = async () => {
      setError(null);
      setLoading(true);

      const response = await AJAX.post(
        '/api/adminDashboard/getEngagementAnalytics',
        analyticsFilters
      );

      const { error, parsedResponse } = parseAPIResponse<Record<string, number>>(response, {
        isSuccessful: (parsedResponse) => parsedResponse && typeof parsedResponse === 'object',
      });

      setLoading(false);

      if (error) {
        setError(error.message);
        return;
      } else if (!parsedResponse) {
        return;
      }

      setData(parsedResponse);
    };

    fetchAnalytics();
  }, [analyticsFilters]);

  const processedData = useMemo(() => {
    if (!data || !boards) {
      return {};
    }

    const boardMap = mapify(boards, '_id');
    if (analyticsFilters.group === Group.boards) {
      return Object.entries(data).reduce((processedData, [boardID, value]) => {
        if (!boardMap[boardID]) {
          // skip missing boards
          return processedData;
        }

        return {
          ...processedData,
          [boardMap[boardID].name]: value,
        };
      }, {});
    }

    if (!analyticsFilters.boardID) {
      return {};
    }

    const board = boardMap[analyticsFilters.boardID];
    if (analyticsFilters.group === Group.categories) {
      const categoryMap = mapify(board.categories, '_id');

      return Object.entries(data).reduce((processedData, [categoryID, value]) => {
        if (categoryID === 'uncategorized') {
          return {
            ...processedData,
            Uncategorized: value,
          };
        } else if (!categoryMap[categoryID]) {
          // skip missing category
          return processedData;
        }

        return {
          ...processedData,
          [categoryMap[categoryID].name]: value,
        };
      }, {});
    } else if (analyticsFilters.group === Group.tags) {
      const tagMap = mapify(board.tags, '_id');

      return Object.entries(data).reduce((processedData, [tagID, value]) => {
        if (tagID === 'untagged') {
          return {
            ...processedData,
            Untagged: value,
          };
        } else if (!tagMap[tagID]) {
          // skip missing tag
          return processedData;
        }

        return {
          ...processedData,
          [tagMap[tagID].name]: value,
        };
      }, {});
    }

    return {};
  }, [analyticsFilters, data, boards]);

  const updateFilters = (update: Partial<Filters>) => {
    const updates = {
      ...update,
    };

    // syncs group and boardID to generate valid filters
    if (updates.boardID) {
      updates.group =
        analyticsFilters.group === Group.boards ? Group.categories : analyticsFilters.group;
    } else if (updates.group === Group.boards) {
      updates.boardID = null;
    } else if (updates.boardID === null) {
      updates.group = Group.boards;
    }

    setLoading(true);
    setData(null);

    const filters = {
      ...analyticsFilters,
      ...updates,
    };

    trackEvent('Filtered the Engagement Breakdown analytics component', { filters });

    router.push({
      pathname: location.pathname,
      query: {
        'engagement-breakdown': Buffer.from(JSON.stringify(filters)).toString('base64'),
      },
    });
  };

  const onSelectArea = useCallback(
    (key: string, event: PointerEvent) => {
      const queryParams: Record<string, string | null> = {};
      let board: Board | null = null;

      // reset filters
      queryParams.boards = null;
      queryParams.categories = null;
      queryParams['post-created'] = null;
      queryParams.segment = null;
      queryParams.tags = null;
      queryParams.uncategorized = null;
      queryParams.untagged = null;

      // find and filter by group
      if (analyticsFilters.group === Group.boards) {
        board = boards.find((board) => board.name === key) ?? null;
      } else if (analyticsFilters.group === Group.categories) {
        board = boards.find((board) => board._id === analyticsFilters.boardID) ?? null;
        const category = categories.find((category) => category.name === key);
        if (category) {
          queryParams.categories = category.urlName;
        } else if (key === 'Uncategorized') {
          queryParams.uncategorized = 'true';
        }
      } else if (analyticsFilters.group === Group.tags) {
        board = boards.find((board) => board._id === analyticsFilters.boardID) ?? null;
        const tag = tags.find((tag) => tag.name === key);
        if (tag) {
          queryParams.tags = tag.urlName;
        } else if (key === 'Untagged') {
          queryParams.untagged = 'true';
        }
      }

      // filter by board
      if (board) {
        queryParams.boards = board.urlName;
      }

      // filter by date
      if (analyticsFilters.dateRange) {
        queryParams['post-created'] = analyticsFilters.dateRange
          .map((date) => dayjs(date).format('YYYY-MM-DD'))
          .join('_');
      }

      // filter by segment
      if (analyticsFilters.segmentID) {
        const segment = company.segments.find(
          (segment) => segment._id === analyticsFilters.segmentID
        );
        if (segment) {
          queryParams.segment = segment.urlName;
        }
      }

      trackEvent('Clicked on a group of the Engagement Breakdown analytics component', {
        filters: analyticsFilters,
        key,
      });

      if (event.metaKey || event.ctrlKey) {
        // open search view with filters applied
        window.open(`/admin/feedback/${queryString.stringify(queryParams)}`, '_blank');
      } else {
        router.push({
          pathname: `/admin/feedback/`,
          query: queryParams,
        });
      }
    },
    [analyticsFilters, boards, categories, tags, company.segments, router, trackEvent]
  );

  return (
    <div className="adminDashboardEngagementBreakdown">
      <EngagementBreakdown
        filters={analyticsFilters}
        boards={boards}
        data={processedData}
        onSelectArea={onSelectArea}
        updateFilters={updateFilters}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default AdminDashboardEngagementBreakdown;

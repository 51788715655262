import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import plaintext from 'common/markdown/plaintext';
import { encode } from 'common/util/base64';
import devURL from 'common/util/devURL';
import generateMetaDescription from 'common/util/generateMetaDescription';
import getCannyOrigin from 'common/util/getCannyOrigin';

import Helmet from './Helmet';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { Post } from 'common/api/resources/posts';

type Props = {
  board?: Board;
  post?: Post;
};

const SubdomainPostHelmet = ({ board, post }: Props) => {
  const company = useContext<Company>(CompanyContext);

  if (!post || !post.title || !board || !board.name || !company || !company.name) {
    return null;
  }

  const title = `${post.title} | ${board.name} | ${company.name}`;
  const url = `${getCannyOrigin(company)}/${board.urlName}/p/${post.urlName}`;
  const description = generateMetaDescription(plaintext(post.details)) || '';
  // we pass the updatedAt date in the URL to force invalidations on stricter third-party caches
  const image = devURL(
    `https://${company.subdomain}.canny.io/api/og/posts/${post._id}?v=${encode(post.updatedAt)}`
  );

  return (
    <Helmet
      title={title}
      link={[{ href: url, rel: 'canonical' }]}
      meta={[
        { name: 'description', content: description || title },

        // og default
        { property: 'og:description', content: description },
        { property: 'og:image', content: image },
        { property: 'og:title', content: title },

        // twitter
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: image },
        { name: 'twitter:title', content: title },
        { name: 'twitter:card', content: 'summary_large_image' },
      ]}
    />
  );
};

export default SubdomainPostHelmet;

import React from 'react';

import { ArrowRight } from 'lucide-react';

import { convertMRRToTimeframe } from 'common/company/RevenueHelpers';
import translateString from 'common/i18n/translateString';
import plaintext from 'common/markdown/plaintext';
import truncateString from 'common/truncateString';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_Suggestion.module.scss';

import AdminQueuePost from './AdminQueuePost';

import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { StrippedUser } from 'common/api/endpoints/users';
interface CommonPostSuggestionProps {
  author: StrippedUser | undefined;
  board?: Board;
  workaround?: string | null;
  created?: string;
  details: string | null;
  title: string;
}

const MinDetailsLength = 150;

interface UniquePostSuggestionProps extends CommonPostSuggestionProps {
  onClick: () => void;
}

export const UniquePostSuggestion = ({
  author,
  board,
  created,
  details,
  onClick,
  title,
  workaround,
}: UniquePostSuggestionProps) => (
  <button className={styles.queueItemWrapper} onClick={onClick}>
    <AdminQueuePost
      className={styles.queueSuggestionItem}
      title={title}
      details={details}
      author={author}
      created={created}
      board={board}
      workaround={workaround}
      truncate={false}
    />
  </button>
);

interface DuplicatePostSuggestionsProps extends CommonPostSuggestionProps {
  sourceType: 'draft' | 'post';
  company: Company;
  duplicatePost: CommonPostSuggestionProps & {
    score: number;
    commentCount: number;
    totalMRR: number;
  };
  onClickDuplicatePost: () => void;
  onClickOriginalPost: () => void;
}

export const DuplicatePostSuggestion = ({
  author,
  board,
  company,
  created,
  details,
  sourceType,
  title,
  duplicatePost,
  onClickDuplicatePost,
  onClickOriginalPost,
  workaround,
}: DuplicatePostSuggestionsProps) => {
  // we only truncate the dupe details if a) the source post has details that we can match the character count to b) the source post is a draft c) the src details are longer than MinDetailsLength
  const shouldManuallyTruncateDupeDetails =
    !!details && sourceType === 'draft' && details.length > MinDetailsLength; // MinDetailsLength prevents us truncating the dupe to 10 chars, for example

  const translatedDupeDetails = translateString(duplicatePost as { details: string }, 'details');

  const duplicateDetails = shouldManuallyTruncateDupeDetails
    ? truncateString(plaintext(translatedDupeDetails), details.length)
    : translatedDupeDetails;

  return (
    <div className={styles.duplicateQueueItem}>
      <button className={styles.queueItemWrapper} onClick={onClickDuplicatePost}>
        <AdminQueuePost
          title={title}
          details={details}
          author={author}
          created={created}
          board={board}
          truncate={sourceType === 'post'}
          workaround={workaround}
        />
      </button>
      <ArrowRight className={styles.mergeButton} strokeWidth={1} width={32} />
      <button className={styles.queueItemWrapper} onClick={onClickOriginalPost}>
        <AdminQueuePost
          title={translateString(duplicatePost, 'title')}
          details={duplicateDetails}
          author={duplicatePost.author}
          created={duplicatePost.created}
          board={board}
          meta={{
            score: duplicatePost.score,
            commentCount: duplicatePost.commentCount,
            ...(company.hasCollectedMonthlySpend && {
              monthlySpend: convertMRRToTimeframe(duplicatePost.totalMRR, company.revenueTimeframe),
            }),
          }}
          truncate={!shouldManuallyTruncateDupeDetails}
        />
      </button>
    </div>
  );
};

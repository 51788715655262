import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadMore } from 'common/actions/changelogEntryQueries';
import { TintColorContext } from 'common/containers/TintColorContainer';
import connect from 'common/core/connect';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import isElementOnScreen from 'common/util/isElementOnScreen';
import withContexts from 'common/util/withContexts';

import 'css/components/changelog/_ChangelogEntryListLoadMore.scss';

class ChangelogEntryListLoadMore extends Component {
  static propTypes = {
    entryList: PropTypes.shape({
      hasNextPage: PropTypes.bool,
    }),
    tintColor: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  onLoadMore = () => {
    const { entryList } = this.props;
    const { loadingMore } = entryList;
    if (loadingMore) {
      return;
    }

    const { paginate } = this.props;
    paginate(entryList);
  };

  onScroll = (e, scrollContainer) => {
    const { entryList } = this.props;
    const { loadingMore } = entryList;
    if (loadingMore) {
      return;
    }

    const container = this.containerRef.current;
    if (!container) {
      return;
    }

    if (!isElementOnScreen(container, scrollContainer)) {
      return;
    }

    this.onLoadMore();
  };

  render() {
    const { entryList, tintColor } = this.props;
    const { hasNextPage, loading } = entryList;
    if (loading || !hasNextPage) {
      return null;
    }

    const { loadingMore } = entryList;
    if (loadingMore) {
      return (
        <div className="changelogEntryListLoadMore loadingMore" ref={this.containerRef}>
          <Spinner />
        </div>
      );
    }

    const loadMoreStyle = {
      ...(tintColor && { color: tintColor }),
    };
    return (
      <Tappable onTap={this.onLoadMore}>
        <div className="changelogEntryListLoadMore" ref={this.containerRef} style={loadMoreStyle}>
          Load More
          <div className="arrow">&rarr;</div>
        </div>
      </Tappable>
    );
  }
}

export default compose(
  connect(
    null,
    (dispatch) => ({
      paginate: (entryList) => {
        return Promise.all([dispatch(loadMore(entryList))]);
      },
    }),
    { withRef: true }
  ),
  withContexts(
    {
      tintColor: TintColorContext,
    },
    {
      forwardRef: true,
    }
  )
)(ChangelogEntryListLoadMore);

import React from 'react';

import classnames from 'classnames';
import { Loader2 } from 'lucide-react';

import 'css/components/_SpinnerV2.scss';

type Size = 'small' | 'medium' | 'large' | 'xxxxlarge';

type Props = {
  className?: string;
  size: Size;
};
const SpinnerV2 = ({ className, size = 'medium' }: Props) => {
  return <Loader2 className={classnames(className, 'spinnerV2', { [size]: true })} />;
};

export default SpinnerV2;

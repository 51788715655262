import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import CategorySelector from 'common/categories/CategorySelector';
import Tappable from 'common/Tappable';

import 'css/components/post/_PostCategoryMenu.scss';

export default class PostCategoryMenu extends Component {
  static propTypes = {
    board: PropTypes.shape({
      name: PropTypes.string,
      urlName: PropTypes.string,
    }),
    boards: PropTypes.array,
    onCategorySelected: PropTypes.func,
  };

  state = {
    menuOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  onDocumentClick = (e) => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return;
    }

    const container = this.containerRef.current;
    if (!container?.contains(e.target)) {
      // touchend happens outside of menu, close menu
      this.setState({ menuOpen: false });
      return;
    }
  };

  onCategorySelected = (category) => {
    const { onCategorySelected } = this.props;
    this.setState({ menuOpen: false }, () => onCategorySelected(category));
  };

  renderMenu = () => {
    const { menuOpen } = this.state;
    const { board, boards = [board] } = this.props;

    if (!menuOpen) {
      return null;
    }

    return (
      <div className="categoryDropdownContainer">
        <CategorySelector
          autoFocus={true}
          board={board}
          boards={boards}
          excludeCategories={[]}
          isFloating={true}
          onCategorySelected={this.onCategorySelected}
        />
      </div>
    );
  };

  toggleMenu = () => {
    this.setState(({ menuOpen }) => ({ menuOpen: !menuOpen }));
  };

  render() {
    const { menuOpen } = this.state;
    const { children } = this.props;

    return (
      <div className="postCategoryMenu" ref={this.containerRef}>
        <Tappable onTap={this.toggleMenu}>
          <div className={classnames('toggleMenu', { open: menuOpen })}>{children}</div>
        </Tappable>
        {this.renderMenu()}
      </div>
    );
  }
}

import React, { useContext, useEffect, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import AccessModal from 'common/modals/AccessModal';
import UpsellModal from 'common/modals/UpsellModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminBillingSeatsCard from 'common/subdomain/admin/AdminMemberSettings/PeopleSettings/AdminBillingSeatsCard';
import { H2 } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import { StarterPlanID } from 'common/util/isStarter';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';

import InviteForm from './InviteForm';
import Teammates from './Teammates';

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

import 'css/components/subdomain/admin/AdminMemberSettings/_AdminMemberPeopleSettings.scss';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

const AdminPeopleSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const openModal = useContext(OpenModalContext);
  const trackEvent = useContext(TrackEventContext);
  const viewer = useContext<Viewer>(ViewerContext);
  const router = useContext(RouterContext);

  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);

  useEffect(() => {
    trackEvent('Viewed Team Settings (Admin)');

    const permission: RolePermissionName = 'manageTeammates';

    if (hasPermission(permission, company, viewer)) {
      return;
    }

    router.replace('/admin/settings');

    openModal(
      AccessModal,
      {
        requiredPermissions: [permission],
      },
      {
        allowRouteChange: true,
      }
    );
  }, [company, openModal, router, trackEvent, viewer]);

  return (
    <div className="adminMemberPeopleSettings">
      <div className="content">
        <section className="details">
          <H2 variant="headingMd">Details</H2>
          <AdminBillingSeatsCard />
        </section>
        <section className="manageAdmins">
          <H2 variant="headingMd">Manage admins</H2>
          <InviteForm company={company} viewer={viewer} onUpsell={() => setShowUpsellModal(true)} />
          <Teammates reloadCompany={reloadCompany} company={company} viewer={viewer} />
        </section>
      </div>
      <UpsellModal
        cta="Grow your feedback team"
        feature="limits.admins"
        onClose={() => setShowUpsellModal(false)}
        onUpsell={() => setShowUpsellModal(false)}
        planID={StarterPlanID}
        show={showUpsellModal}
      />
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.team.people),
    '/admin/settings'
  )
)(AdminPeopleSettings) as unknown as React.FC<OwnProps>;

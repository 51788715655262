import {
  CompanyError,
  CompanyLoaded,
  CompanyNotFound,
  Invalidate,
  RequestCompany,
} from '../actions/thirdPartyCompanies';

export default function thirdPartyCompanies(state = {}, action) {
  switch (action.type) {
    case RequestCompany: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign({}, state[action.urlName], {
          error: null,
          lastUpdated: action.timestamp,
          loading: true,
          notFound: false,
        }),
      });
    }

    case CompanyLoaded: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign(
          {},
          state[action.urlName],
          {
            lastUpdated: action.timestamp,
            loading: false,
          },
          action.company
        ),
      });
    }

    case CompanyNotFound: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign({}, state[action.urlName], {
          lastUpdated: action.timestamp,
          loading: false,
          notFound: true,
        }),
      });
    }

    case CompanyError: {
      return Object.assign({}, state, {
        [action.urlName]: Object.assign({}, state[action.urlName], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
        }),
      });
    }

    case Invalidate: {
      return {};
    }

    default:
      return state;
  }
}

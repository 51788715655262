import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import KeyCodes from 'common/KeyCodes';
import Tappable from 'common/Tappable';

import 'css/components/inputs/_StarRatingInput.scss';

export default class StarRatingInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    isZeroValid: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    starAmount: PropTypes.number,
  };

  static defaultProps = {
    isZeroValid: true,
    starAmount: 5,
  };

  state = {
    focused: false,
    value: 0,
  };

  constructor(props, context) {
    super(props, context);

    this.starsInput = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value });

    this.starsInput.current.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    this.starsInput.current.removeEventListener('keydown', this.onKeyDown);
  }

  setValue = (value) => {
    const { isZeroValid, onChange } = this.props;
    const { value: oldValue } = this.state;
    const newValue = oldValue === value ? 0 : value;

    if (newValue === 0 && !isZeroValid) {
      return;
    }

    this.setState({ value: newValue }, () => onChange?.(newValue));
  };

  onBlur = (e) => {
    this.setState({ focused: false });
  };

  onFocus = (e) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    this.setState({ focused: true });
  };

  onStarClick = (value) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    this.setValue(value);
  };

  onKeyDown = (e) => {
    const { focused, value } = this.state;
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    if (focused && e.keyCode === KeyCodes.RightArrow) {
      e.preventDefault();
      const nextValue = Math.min(value + 1, this.props.starAmount);
      if (nextValue !== value) {
        this.setValue(nextValue);
      }
    } else if (focused && e.keyCode === KeyCodes.LeftArrow) {
      e.preventDefault();
      const nextValue = Math.max(value - 1, this.props.isZeroValid ? 0 : 1);
      this.setValue(nextValue);
    }
    this.props.onKeyDown?.(e);
  };

  renderStar = (value, selected) => {
    return (
      <Tappable key={value} onTap={() => this.onStarClick(value)}>
        <div className={classnames('star icon icon-star', { selected })} />
      </Tappable>
    );
  };

  renderStars = () => {
    const { value } = this.state;
    const { starAmount } = this.props;

    const allowedValues = Array.from({ length: starAmount }).map((_, i) => i + 1);
    return allowedValues.reverse().map((allowedValue) => {
      return this.renderStar(allowedValue, allowedValue <= value);
    });
  };

  render() {
    const { focused, value } = this.state;
    const { disabled } = this.props;

    return (
      <div
        className={classnames('starRatingInput focusable', { disabled, empty: !value, focused })}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        ref={this.starsInput}
        tabIndex={0}>
        {this.renderStars()}
      </div>
    );
  }
}

import Data from 'common/Data';

// Action Types

export const RequestViewer = 'canny/viewer/request_viewer';
export const ViewerLoaded = 'canny/viewer/viewer_loaded';
export const ViewerLoggedOut = 'canny/viewer/viewer_logged_out';
export const ViewerError = 'canny/viewer/viewer_error';

// Actions

function requestViewer() {
  return {
    timestamp: Date.now(),
    type: RequestViewer,
  };
}

function viewerLoaded(viewer) {
  return {
    timestamp: Date.now(),
    type: ViewerLoaded,
    viewer: viewer,
  };
}

function viewerLoggedOut() {
  return {
    timestamp: Date.now(),
    type: ViewerLoggedOut,
  };
}

function viewerError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: ViewerError,
  };
}

// Action Creators

function fetchViewer(options = {}) {
  return (dispatch, getState) => {
    const { cookies } = getState();
    return Data.fetch(
      {
        viewer: {
          query: Data.queries.viewer,
          input: {
            includeDocsData: options.includeDocsData,
          },
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(viewerError(error));
        } else if (!data.viewer) {
          return dispatch(viewerLoggedOut());
        } else {
          return dispatch(viewerLoaded(data.viewer));
        }
      }
    );
  };
}

export function loadViewer(options) {
  return (dispatch, getState) => {
    if (shouldFetchViewer(getState())) {
      dispatch(requestViewer());
      return dispatch(fetchViewer(options));
    }
  };
}

export function reloadViewer(options) {
  return (dispatch) => {
    return dispatch(fetchViewer(options));
  };
}

// Helper Functions

function shouldFetchViewer(state) {
  const viewer = state.viewer;
  return !viewer;
}

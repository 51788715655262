import { loadCompany } from './company';
import Data from '../Data';

// Action Types

export const RequestRules = 'canny/clickup_rules/request_rules';
export const RulesLoaded = 'canny/clickup_rules/rules_loaded';
export const RulesError = 'canny/clickup_rules/rules_error';

// Actions

function requestRules() {
  return {
    timestamp: Date.now(),
    type: RequestRules,
  };
}

function rulesLoaded(rules) {
  return {
    rules,
    timestamp: Date.now(),
    type: RulesLoaded,
  };
}

function rulesError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: RulesError,
  };
}

// Action Creators

function fetchRules() {
  return (dispatch, getState) => {
    const { cookies } = getState();
    return Data.fetch(
      {
        rules: {
          query: Data.queries.clickupRules,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(rulesError(error));
        } else {
          return dispatch(rulesLoaded(data.rules));
        }
      }
    );
  };
}

export function loadRules() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      if (shouldFetchRules(getState())) {
        dispatch(requestRules());
        return dispatch(fetchRules());
      }
    });
  };
}

export function reloadRules() {
  return (dispatch, getState) => {
    return dispatch(fetchRules());
  };
}

// Helpers

function shouldFetchRules(state) {
  const { company } = state;
  if (!company?.clickup?.authorized) {
    return false;
  }

  return !state.rules;
}

import { Component } from 'react';

import PropTypes from 'prop-types';
import { createPortal, findDOMNode } from 'react-dom';

import 'css/components/common/_Portal.scss';

export const PortalContainerID = 'canny-portal-container';

export default class Portal extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['center', 'end', 'start']),
    className: PropTypes.string,
    onBlur: PropTypes.func,
    portalContainerID: PropTypes.string,
    position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    relativeToRef: PropTypes.object,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    align: 'start',
    className: '',
    portalContainerID: PortalContainerID,
    position: 'bottom',
    zIndex: 5000,
  };

  state = {
    hasEventListeners: this.props.relativeToRef ? true : false,
  };

  componentDidMount() {
    const { portalContainerID } = this.props;
    // Store all portals in a single shared container node
    const portalContainer =
      document.getElementById(portalContainerID) ?? this.createPortalContainer();

    // Create a rendering container for this specific portal
    this.portalNode = document.createElement('div');
    this.portalNode.className = this.props.className;
    Object.assign(this.portalNode.style, {
      ...(portalContainerID !== PortalContainerID && {
        height: '100%',
        width: '100%',
      }),
      position: 'absolute',
      zIndex: this.props.zIndex,
    });

    portalContainer.appendChild(this.portalNode);

    this.createEventListeners();

    // Force a rerender now that we've created our container node.
    this.forceUpdate();
    this.updateRelativePosition();
  }

  componentDidUpdate(prevProps) {
    const { align, position, relativeToRef } = this.props;
    if (relativeToRef && (prevProps.align !== align || prevProps.position !== position)) {
      this.updateRelativePosition();
    }
  }

  componentWillUnmount() {
    this.destroyEventListeners();
    // Remove the portal node
    document.getElementById(this.props.portalContainerID).removeChild(this.portalNode);
  }

  // Used to check if the portal's node contains another DOM node.
  containsNode(node) {
    return findDOMNode(this.portalNode).contains(node);
  }

  createPortalContainer() {
    const container = document.createElement('div');
    container.id = this.props.portalContainerID;
    document.body.appendChild(container);
    return container;
  }

  createEventListeners() {
    if (this.props.onBlur) {
      document.addEventListener('click', this.onBlur);
      this.portalNode.addEventListener('click', this.onIgnoreClick);
    }

    if (!this.state.hasEventListeners) {
      return;
    }
    window.addEventListener('scroll', this.updateRelativePosition, true);
    window.addEventListener('resize', this.updateRelativePosition);
  }

  destroyEventListeners() {
    if (this.props.onBlur) {
      document.removeEventListener('click', this.onBlur);
      this.portalNode.removeEventListener('click', this.onIgnoreClick);
    }

    if (!this.state.hasEventListeners) {
      return;
    }
    window.removeEventListener('scroll', this.updateRelativePosition, true);
    window.removeEventListener('resize', this.updateRelativePosition);
  }

  updateRelativePosition = () => {
    const { align, position, relativeToRef } = this.props;
    if (!relativeToRef || typeof window === 'undefined') {
      return;
    }

    // Waiting for the next animation frame keeps the position 100% in sync with the scroll
    window.requestAnimationFrame(() => {
      // Force the portal to always be relative to this component ref.
      const box = relativeToRef?.current?.getBoundingClientRect();
      const portalBox = this.portalNode.getBoundingClientRect();
      if (!box || !portalBox) {
        return;
      }
      let left;
      let top;
      let transform;

      if (position === 'bottom') {
        top = box.bottom;

        if (align === 'center') {
          const offsetX = box.width / 2 - portalBox.width / 2;
          left = box.left;
          transform = `translateX(${offsetX}px)`;
        } else if (align === 'end') {
          left = box.right;
          transform = `translateX(-100%)`;
        } else if (align === 'start') {
          left = box.left;
        }
      } else if (position === 'left') {
        left = box.left;
        transform = 'translateX(-100%)';

        if (align === 'center') {
          const offsetY = box.height / 2 - portalBox.height / 2;
          top = box.top;
          transform = `translate(-100%, ${offsetY}px)`;
        } else if (align === 'end') {
          top = box.bottom;
          transform = 'translate(-100%, -100%)';
        } else if (align === 'start') {
          top = box.top;
        }
      } else if (position === 'right') {
        left = box.right;

        if (align === 'center') {
          const offsetY = box.height / 2 - portalBox.height / 2;
          top = box.top;
          transform = `translateY(${offsetY}px)`;
        } else if (align === 'end') {
          top = box.bottom;
          transform = 'translateY(-100%)';
        } else if (align === 'start') {
          top = box.top;
        }
      } else if (position === 'top') {
        top = box.top;
        transform = 'translateY(-100%)';

        if (align === 'center') {
          const offsetX = box.width / 2 - portalBox.width / 2;
          left = box.left;
          transform = `translate(${offsetX}px, -100%)`;
        } else if (align === 'end') {
          left = box.right;
          transform = `translate(-100%, -100%)`;
        } else if (align === 'start') {
          left = box.left;
        }
      }

      this.portalNode.style.left = `${left}px`;
      this.portalNode.style.top = `${top + window.scrollY}px`;
      this.portalNode.style.transform = transform ?? '';
    });
  };

  onBlur = (e) => {
    if (this.containsNode(e.target)) {
      // Ignore clicks within the portal container.
      e.stopPropagation();
      return;
    } else if (
      this.props.relativeToRef?.current &&
      findDOMNode(this.props.relativeToRef.current).contains(e.target)
    ) {
      // Ignore clicks within the button that opened the portal. It will usually handle closing itself.
      return;
    }
    this.props.onBlur(e);
  };

  onIgnoreClick = (e) => {
    e.stopPropagation();
  };

  render() {
    this.updateRelativePosition();

    if (!this.portalNode) {
      return null;
    }
    return createPortal(this.props.children, this.portalNode);
  }
}

import React, { useEffect, useState } from 'react';

import { formatDateRangeString } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import Spinner from 'common/Spinner';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse from 'common/util/parseAPIResponse';

type Props = {
  userList: {
    queryParams?: Record<string, string>;
  };
};

const AdminUserCount = ({ userList }: Props) => {
  const [userCount, setUserCount] = useState<number>(NaN);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await AJAX.post('/api/users/count', {
        search: userList.queryParams?.search,
        segmentURLName: userList.queryParams?.segmentURLName,
        dateRange:
          userList.queryParams?.dateRange && formatDateRangeString(userList.queryParams.dateRange),
      });

      const { error, parsedResponse } = parseAPIResponse<{ result: number }>(response, {
        isSuccessful: (parsedResponse) =>
          parsedResponse && typeof parsedResponse.result === 'number',
      });

      setLoading(false);
      if (error || !parsedResponse) {
        setUserCount(NaN);
        return;
      }

      setUserCount(parsedResponse.result);
    };

    // TODO: logic for this case is not implemented on the server
    if (userList.queryParams && userList.queryParams.sort && userList.queryParams.dateRange) {
      setLoading(false);
      setUserCount(NaN);
      return;
    }

    fetch();
  }, [userList.queryParams]);

  const users = userCount === 1 ? 'User' : 'Users';
  return (
    <div className="userCount">
      {Number.isNaN(userCount) ? '—' : numberWithCommas(userCount)} {users} {loading && <Spinner />}
    </div>
  );
};

export default AdminUserCount;

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Spinner from 'common/Spinner';
import AdminClickupSearchForm from 'common/subdomain/admin/AdminClickupSearchForm';
import AdminCreateClickupTaskModal from 'common/subdomain/admin/AdminCreateClickupTaskModal';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminClickupPostSidebarSection.scss';

class AdminClickupPostSidebarSection extends Component {
  static propTypes = {
    company: PropTypes.shape({
      clickup: PropTypes.shape({
        authorized: PropTypes.bool,
      }),
    }),
    post: PropTypes.object,
  };

  state = {
    isModalOpen: false,
    isSearchFormOpen: false,
    removingTask: null,
  };

  onCreateTask = () => {
    this.setState({ isModalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  onToggleSearchForm = () => {
    this.setState((state) => ({ isSearchFormOpen: !state.isSearchFormOpen }));
  };

  onRemoveTask = async (task) => {
    const { post, reloadPost } = this.props;
    const { removingTask } = this.state;
    if (removingTask === task.linkID) {
      return;
    }

    this.setState({ removingTask: task.linkID });
    const response = await AJAX.post('/api/clickup/unlinkTask', { taskLinkID: task.linkID });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (!error) {
      await reloadPost(post);
    }

    this.setState({ removingTask: null });
  };

  renderCreateButton() {
    return (
      <Tappable onTap={this.onCreateTask}>
        <div className="createButton">Create new task</div>
      </Tappable>
    );
  }

  renderClickupTasks() {
    const {
      post: { clickupTasks },
    } = this.props;
    const { removingTask } = this.state;
    if (!clickupTasks?.length) {
      return null;
    }

    const tasks = clickupTasks.map((task) => {
      return (
        <div key={task.id} className="task">
          <a href={task.url} rel="noopener" target="_blank">
            {task.name}
          </a>
          {removingTask === task.linkID ? (
            <Spinner />
          ) : (
            <Tappable onTap={() => this.onRemoveTask(task)}>
              <div className="icon icon-x" />
            </Tappable>
          )}
        </div>
      );
    });

    return <div className="clickupTasks">{tasks}</div>;
  }

  renderSearchForm() {
    const { isSearchFormOpen } = this.state;
    if (!isSearchFormOpen) {
      return null;
    }

    const { post } = this.props;
    return <AdminClickupSearchForm post={post} />;
  }

  renderAction() {
    return (
      <Tappable onTap={this.onToggleSearchForm}>
        <div>Link task</div>
      </Tappable>
    );
  }

  render() {
    const { company } = this.props;
    const { isModalOpen } = this.state;

    const doesPlanSupportIntegration = company?.integrations?.clickup;
    const isAuthorized = company?.clickup?.authorized;
    if (!doesPlanSupportIntegration || !isAuthorized) {
      return null;
    }

    return (
      <AdminSidebarSection
        action={this.renderAction()}
        className="adminClickupPostSidebarSection"
        title="ClickUp Tasks">
        {this.renderSearchForm()}
        {this.renderClickupTasks()}
        {this.renderCreateButton()}
        {isModalOpen && (
          <AdminCreateClickupTaskModal post={this.props.post} onClose={this.onCloseModal} />
        )}
      </AdminSidebarSection>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadPost: (post) => {
      return Promise.all([dispatch(invalidatePostQueries()), dispatch(reloadPost(post))]);
    },
  })),
  withContexts({ company: CompanyContext }, { forwardRef: true })
)(AdminClickupPostSidebarSection);

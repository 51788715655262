import React, { useContext } from 'react';

import { Infinity } from 'lucide-react';

import Colors from 'common/colors/constants';
import Pill from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { P, Span } from 'common/ui/Text';
import getSlidingQuantity from 'common/util/getSlidingQuantity';
import isNil from 'common/util/isNil';
import numberWithCommas from 'common/util/numberWithCommas';
import roundCents from 'common/util/roundCents';
import styles from 'css-module/components/subdomain/admin/AdminMemberSettings/_AdminBillingSeatsCard.module.scss';

import type { Company } from 'common/api/endpoints/companies';

const MonochromePillStyle = {
  color: Colors.gray120,
  background: Colors.gray30,
};

const getAdminPrice = (company: Company) => {
  if (!company.sliding?.admins) {
    return 0;
  }

  const slidingQuantity = getSlidingQuantity(
    company.stats.adminCount,
    company.limits.admins,
    company.sliding.admins
  );

  return slidingQuantity * company.sliding.admins.price;
};

const getPlanTimeframe = (company: Company) => {
  if (!company.billingData?.plan?.timeframe) {
    return null;
  }

  return company.billingData.plan.timeframe === 'monthly' ? 'mo' : 'yr';
};

const AdminBillingSeatsCard = () => {
  const company = useContext<Company>(CompanyContext);
  const currentAdminCount = company.stats.adminCount;
  const freeAdminCount = company.limits.admins;
  const planTimeframe = getPlanTimeframe(company);
  const adminPrice = roundCents(getAdminPrice(company) / 100);
  const hasOvercharges = !isNil(freeAdminCount) && currentAdminCount > freeAdminCount;

  return (
    <div className={styles.adminBillingSeatsCard}>
      <div className={styles.billingCount}>
        <P variant="bodyMd" fontWeight="semibold">
          Billable Seats
        </P>
        <div className={styles.adminCount}>
          <P variant="headingLg">{currentAdminCount}</P>
          <Pill className={styles.pill} pillStyle={MonochromePillStyle}>
            <P variant="bodySm" fontWeight="medium" className={styles.pillContent}>
              {isNil(freeAdminCount) ? <Infinity size={14} /> : freeAdminCount} Free
            </P>
          </Pill>
        </div>
      </div>
      {hasOvercharges ? (
        <P variant="bodySm" className={styles.billingNotice}>
          You will be billed{' '}
          <Span fontWeight="semibold" variant="bodySm">
            ${numberWithCommas(adminPrice)}/{planTimeframe}
          </Span>{' '}
          prorated for{' '}
          <Span variant="bodySm" fontWeight="semibold">
            {currentAdminCount - freeAdminCount} extra seats
          </Span>
        </P>
      ) : (
        <P variant="bodySm" className={styles.billingNotice}>
          You won't be billed for any seats
        </P>
      )}
    </div>
  );
};

export default AdminBillingSeatsCard;

import { cloneElement } from 'react';

export default function cloneElementWithProps(element, props) {
  const propsClone = Object.assign({}, props);
  delete propsClone.children;
  delete propsClone.dispatch;
  delete propsClone.history;
  delete propsClone.location;
  delete propsClone.params;
  delete propsClone.route;
  delete propsClone.routeParams;
  delete propsClone.routes;
  return cloneElement(element, propsClone);
}

import React from 'react';

import ChartRenderer from 'common/charts/ChartRenderer';
import Colors from 'common/colors/constants';

const ChartBorderWidth = 1;
const ChartFillColor = Colors.gray60;
const ChartStyle = {
  // This is required to fill a gap that chartjs has at the bottom of the chart.
  // The chart's fill color does not extend fully to the bottom of the chart.
  // This inset shadow fills in that tiny gap using the same fill color.
  boxShadow: `inset 0 -${ChartBorderWidth}px 0 0 ${ChartFillColor}`,
};
const DefaultAspectRatio = 6;

type Props = {
  aspectRatio?: number;
  data: number[];
};

const SimpleLineChart = ({ aspectRatio = DefaultAspectRatio, data }: Props) => {
  const chartConfig = getConfig(data, { aspectRatio });

  return (
    <div style={ChartStyle}>
      <ChartRenderer type="line" chartConfig={chartConfig} />
    </div>
  );
};

export default React.memo(SimpleLineChart);

type Options = {
  aspectRatio: number;
};

function getConfig(data: number[], { aspectRatio }: Options) {
  return {
    data: {
      labels: Object.keys(data), // labels must be the same length as the data
      datasets: [
        {
          clip: 1,
          data,
          fill: true,
          tension: 0.35,
          borderColor: Colors.gray120,
          borderWidth: 2,
        },
      ],
    },
    options: {
      aspectRatio,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        autoPadding: false,
        padding: {
          left: 0,
          bottom: 1,
          right: 0,
          top: 2,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
          min: 0,
          max: Math.max(...data),
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
      elements: {
        point: {
          pointStyle: false,
        },
      },
    },
  };
}

export enum RevenueTimeframes {
  mrr = 'mrr',
  arr = 'arr',
}

export const RevenueTimeframesList = Object.values(RevenueTimeframes);

export const convertMRRToTimeframe = (mrr: number, revenueTimeframe: RevenueTimeframes) => {
  if (revenueTimeframe === RevenueTimeframes.arr) {
    return mrr * 12 || 0;
  } else {
    return mrr || 0;
  }
};

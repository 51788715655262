import React, { useContext, useState } from 'react';

import { Trash } from 'lucide-react';

import AJAX from 'common/AJAX';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import ConfirmModal from 'common/modals/ConfirmModal';
import UpsellModal from 'common/modals/UpsellModal';
import IconButtonV2 from 'common/ui/IconButtonV2';
import UserAvatar from 'common/user/UserAvatar';
import hasPermission from 'common/util/hasPermission';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Company, Member } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

type SharedProps = {
  company: Company;
  viewer: Viewer;
};

interface TeammatesProps extends SharedProps {
  reloadCompany: () => void;
}

const Teammates = ({ company, reloadCompany, viewer }: TeammatesProps) => {
  const { members, features, roles } = company;
  const showToast = useContext(ShowToastContext);
  const openModal = useContext(OpenModalContext);
  const [upsell, setUpsell] = useState(false);

  const viewerHasPermission = hasPermission('manageTeammates', company, viewer);

  const onRemoveTeammate = (member: Member) => {
    if (!viewerHasPermission || member._id === viewer._id) {
      return;
    }

    openModal(ConfirmModal, {
      message: "Are you sure you'd like to remove " + member.name + '?',
      onConfirm: () => {
        AJAX.post(
          '/api/company/removeTeammate',
          {
            teammateID: member._id,
          },
          () => {
            reloadCompany();
          }
        );
      },
    });
  };

  const onEditMember = async (member: Member, roleID: string) => {
    const role = company.roles.find((role) => role._id === roleID);

    // given the options come from the list of roles, this should never happen
    if (!role) {
      return;
    }

    if (!features.adminRoles) {
      setUpsell(true);
      return;
    }

    const response = await AJAX.post('/api/companyMembers/edit', {
      roleID: roleID,
      userID: member._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(
        `Role for ${member.name} could not be updated. Please try again.`,
        ToastTypes.error
      );
    } else {
      showToast(`Role for ${member.name} changed to ${role.name}`);
    }
    reloadCompany();
  };

  const renderUpsell = () => {
    if (!upsell) {
      return null;
    }

    return (
      <UpsellModal
        cta={'Manage teammate permissions'}
        feature={'adminRoles'}
        onClose={() => setUpsell(false)}
        onUpsell={() => setUpsell(false)}
        show
      />
    );
  };

  return (
    <div className="teammates">
      {members.map((member) => (
        <div key={member._id} className="teammate">
          <div className="leftContainer">
            <UserAvatar user={member} />
            <div className="textContainer">
              <div className="name">{member.name}</div>
              <div className="email">{member.email}</div>
            </div>
          </div>
          <div className="rightContainer">
            <ControlledDropdown
              className="roleDropdown"
              disabled={member._id === viewer._id}
              onChange={(value: string) => onEditMember(member, value)}
              selectedName={roles.find((role) => role._id === member.roleID)?._id}
              options={roles.map((role) => ({
                name: role._id,
                render: role.name,
              }))}
            />
            <IconButtonV2
              disabled={member._id === viewer._id || !viewerHasPermission}
              color="error"
              variant="plain"
              aria-label="remove"
              onClick={() => onRemoveTeammate(member)}
              icon={Trash}
              size={'small'}
            />
          </div>
        </div>
      ))}
      {renderUpsell()}
    </div>
  );
};

export default Teammates;

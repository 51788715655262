import AJAX from 'common/AJAX';
import Strings from 'common/Strings';
import parseAPIResponse from 'common/util/parseAPIResponse';

import type { Company } from 'common/api/endpoints/companies';
import type { StrippedUser } from 'common/api/endpoints/users';

type APIError = {
  type: string;
  message: string;
};

type LoginParams = {
  azureAccessToken?: string;
  azureUzerID?: string;
  email: string;
  fbUserID?: string;
  fbAccessToken?: string;
  githubUserID?: string;
  googleIDToken?: string;
  googleUserID?: string;
  password?: string;
  ppToken?: string;
  useTokenAuth?: boolean;
};

type SignupParams = {
  avatarURL?: string;
  azureAccessToken?: string;
  azureUserID?: string;
  captcha?: string;
  email: string;
  fbUserID?: string;
  fbAccessToken?: string;
  githubUserID?: string;
  googleIDToken?: string;
  googleUserID?: string;
  name: string;
  password?: string;
  ppToken?: string;
  useTokenAuth?: boolean;
};

type CompanyWithRedirectionURL = Company & { integrationAuthRedirectionURL: string | null };
type UserWithCompanies = StrippedUser & { companies: CompanyWithRedirectionURL[] };

type AuthResponse = {
  user: UserWithCompanies;
  redirectionURL?: string | null;
};

const LoginErrorMessages = {
  'no such email': 'There is no account associated with that email, please try again.',
  'auth failed': 'The email and password combination is incorrect. Please try again.',
  'slow down':
    'You are trying to log in too many times. Please wait a few minutes before trying again.',
  default: 'Log in failed',
};

const SignupErrorMessages = {
  'email domain is not trusted':
    'Canny does not accept disposable email addresses. Please use another email address.',
  'email exists': Strings.emailTaken,
  'invalid captcha':
    'Our servers failed to verify your identity. Please wait a few minutes before trying again.',
  'missing captcha':
    "Our servers couldn't verify your identity. Please wait a few minutes before trying again.",
  'slow down':
    'You are trying to sign up too many times. Please wait a few minutes before trying again.',
  'password is too weak': 'Please enter a stronger password.',
};

const handleReturnData = (error: APIError | null, parsedResponse: AuthResponse | null) => {
  if (error) {
    return { error, parsedResponse, redirecting: false };
  }

  if (!parsedResponse) {
    return { error: Strings.miscError, parsedResponse, redirecting: false };
  }

  // If redirectionURL is provided, immediately redirect to it to complete the auth flow
  if (parsedResponse.redirectionURL) {
    window.location.assign(parsedResponse.redirectionURL);
    return { error, parsedResponse, redirecting: true };
  }

  return { error, parsedResponse, redirecting: false };
};

export const login = async (data: LoginParams) => {
  const response = await AJAX.post('/api/viewer/login', data);
  const { error, parsedResponse } = parseAPIResponse<AuthResponse>(response, {
    isSuccessful: (parsedResponse) => !!parsedResponse.user,
    errors: LoginErrorMessages,
  });

  return handleReturnData(error, parsedResponse);
};

export const signup = async (data: SignupParams) => {
  const response = await AJAX.post('/api/viewer/signup', data);
  const { error, parsedResponse } = parseAPIResponse<AuthResponse>(response, {
    isSuccessful: (parsedResponse) => !!parsedResponse.user,
    errors: SignupErrorMessages,
  });

  return handleReturnData(error, parsedResponse);
};

import React from 'react';

import { DatabaseZap, Zap, ZapOff } from 'lucide-react';

import { useGettingStartedContext } from 'common/subdomain/admin/AdminQueue/GettingStarted/AdminAutopilotGettingStarted';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotGettingStarted.module.scss';

import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

type Props = {
  enabledSyncIntegrations: SyncIntegrationNames[];
};

const AdminAutopilotGettingStartedHeading = ({ enabledSyncIntegrations }: Props) => {
  const { erroredIntegrationName } = useGettingStartedContext();
  const hasEnabledASource = enabledSyncIntegrations.length > 0;

  if (erroredIntegrationName) {
    return (
      <>
        <ZapOff aria-hidden size={20} className={styles.headingIcon} />
        <>
          {!!hasEnabledASource && (
            <>
              {erroredIntegrationName} connection failed. Please try again, or continue with the
              connected sources.{' '}
            </>
          )}
          {!hasEnabledASource && (
            <>{erroredIntegrationName} connection failed. Please try again. </>
          )}
        </>
      </>
    );
  }

  if (hasEnabledASource) {
    return (
      <>
        <Zap size={20} aria-hidden className={styles.headingIcon} />
        {enabledSyncIntegrations.length} data source
        {enabledSyncIntegrations.length > 1 ? 's' : ''} successfully connected
      </>
    );
  }

  return (
    <>
      <DatabaseZap size={20} aria-hidden className={styles.headingIcon} />
      <>Connect a data source</>
    </>
  );
};

export default AdminAutopilotGettingStartedHeading;

import React, { Component } from 'react';

import AJAX from 'common/AJAX';
import AuthRequest from 'common/auth/AuthRequest';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

export const DisconnectAzureContext = React.createContext();
export const DisconnectGoogleContext = React.createContext();
export const OAuthContext = React.createContext();

class AuthContainer extends Component {
  disconnectAzure = () => {
    AJAX.post(
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/logout',
      {},
      (response) => {}
    );
  };

  disconnectGoogle = () => {
    if (!this._googleIDToken) {
      return;
    }

    AJAX.post(
      'https://accounts.google.com/o/oauth2/revoke',
      {
        token: this._googleIDToken,
      },
      (response) => {}
    );
  };

  oauth = (authType, additionalData, callback) => {
    const authRequest = new AuthRequest(authType, additionalData, (error, oauthData) => {
      if (error) {
        callback(error);
        return;
      }

      if (oauthData.googleIDToken) {
        this._googleIDToken = oauthData.googleIDToken;
      }

      callback(null, oauthData);
    });
    authRequest.start();
  };

  render() {
    return (
      <DisconnectAzureContext.Provider value={this.disconnectAzure}>
        <DisconnectGoogleContext.Provider value={this.disconnectGoogle}>
          <OAuthContext.Provider value={this.oauth}>
            {cloneElementWithProps(this.props.children, {
              ...this.props,
            })}
          </OAuthContext.Provider>
        </DisconnectGoogleContext.Provider>
      </DisconnectAzureContext.Provider>
    );
  }
}

export default AuthContainer;

import { loadCompany } from './company';
import Data from '../Data';

// Action Types

export const RequestGroups = 'canny/gsuiteGroups/request_groups';
export const GroupsLoaded = 'canny/gsuiteGroups/groups_loaded';
export const GroupsError = 'canny/gsuiteGroups/groups_error';

// Actions

function requestGroups() {
  return {
    timestamp: Date.now(),
    type: RequestGroups,
  };
}

function groupsLoaded(groups) {
  return {
    groups,
    timestamp: Date.now(),
    type: GroupsLoaded,
  };
}

function groupsError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: GroupsError,
  };
}

// Action Creators

function fetchGroups() {
  return (dispatch, getState) => {
    const { cookies } = getState();
    return Data.fetch(
      {
        groups: {
          query: Data.queries.gsuiteGroups,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(groupsError(error));
        } else {
          return dispatch(groupsLoaded(data.groups));
        }
      }
    );
  };
}

export function loadGroups() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      if (shouldFetchGroups(getState())) {
        dispatch(requestGroups());
        return dispatch(fetchGroups());
      }
    });
  };
}

export function reloadGroups() {
  return (dispatch, getState) => {
    if (shouldFetchGroups(getState())) {
      return dispatch(fetchGroups());
    }
  };
}

// Helpers

function shouldFetchGroups(state) {
  const { company } = state;
  if (!company || !company.gsuite || !company.gsuite.connected) {
    return false;
  }

  return !state.groups;
}

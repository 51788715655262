const KeyCodes = {
  B: 66,
  Backspace: 8,
  Delete: 46,
  DownArrow: 40,
  Eight: 56,
  Enter: 13,
  Escape: 27,
  Five: 53,
  Four: 52,
  I: 73,
  J: 74,
  K: 75,
  LeftArrow: 37,
  M: 77,
  Nine: 56,
  One: 49,
  RightArrow: 39,
  Seven: 55,
  Six: 54,
  Space: 32,
  Tab: 9,
  Three: 51,
  Two: 50,
  UpArrow: 38,
  Zero: 48,
};

export const KeyNames = {
  B: 'b',
  D: 'd',
  DownArrow: 'ArrowDown',
  End: 'End',
  Enter: 'Enter',
  Escape: 'Escape',
  M: 'm',
  N: 'n',
  Home: 'Home',
  V: 'v',
  UpArrow: 'ArrowUp',
  X: 'x',
};

// see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
export const KeyIdentifiers = {
  Space: 'Space',
  B: 'KeyB',
  D: 'KeyD',
  M: 'KeyM',
  N: 'KeyN',
  V: 'KeyV',
  X: 'KeyX',
};

export default KeyCodes;

import React, { useRef } from 'react';

import classnames from 'classnames';

import Portal from 'common/common/Portal';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import TextInput from 'common/inputs/TextInput';
import Tappable from 'common/Tappable';
import UserAvatar from 'common/user/UserAvatar';

import 'css/components/subdomain/admin/_UserPicker.scss';

type PartialUser = {
  _id: string;
  email: string | null;
  name: string;
  avatarURL: string | null;
};

type Props<User> = {
  align?: 'center' | 'end' | 'start';
  relativeElementRef: React.Ref<React.ReactNode>;
  onSearch: (value: string) => void;
  onUserSelected: (user: User) => void;
  placeholder?: string;
  showBadge?: boolean;
  showSearch?: boolean;
  users: User[];
  onClickOutside: () => void;
  width?: number;
  buttonRef: React.RefObject<HTMLElement>;
};

const UserPicker = <User extends PartialUser>({
  align = 'start',
  relativeElementRef,
  onUserSelected,
  onSearch,
  placeholder = `Search\u2026`,
  showBadge = true,
  showSearch = true,
  users,
  width = 310,
  onClickOutside = () => null,
  buttonRef,
}: Props<User>) => {
  const portalRef = useRef<Portal>(null);

  useBackgroundClick(() => {
    onClickOutside();
  }, [portalRef, buttonRef]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.currentTarget.value);
  };

  return (
    <Portal
      align={align}
      className="userPickerPortal"
      position="bottom"
      ref={portalRef}
      relativeToRef={relativeElementRef}>
      <div className={classnames('userPicker', { empty: users.length === 0 })} style={{ width }}>
        {showSearch && (
          <TextInput
            autoFocus={true}
            onChange={onSearchChange}
            placeholder={placeholder}
            prefix={<div className="icon icon-search" />}
          />
        )}
        {!!users.length && (
          <div className={classnames('suggestions', { noSearch: !showSearch })}>
            {users.map((user) => (
              <Tappable onTap={() => onUserSelected(user)} key={user._id}>
                <div className="suggestion">
                  <UserAvatar user={user} showBadge={showBadge} />
                  <div className="userData">
                    <div className="name">{user.name}</div>
                    {user.email && <div className="email">{user.email}</div>}
                  </div>
                </div>
              </Tappable>
            ))}
          </div>
        )}
      </div>
    </Portal>
  );
};

export default UserPicker;

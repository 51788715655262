import React from 'react';

import { P } from 'common/ui/Text';

type Props = {
  error?: string;
};

const FieldError = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <P variant="bodySm" role="alert" className="error">
      {error}
    </P>
  );
};

export default FieldError;

import {
  LoadingMoreNotifications,
  NoNotifications,
  NotificationsError,
  NotificationsLoaded,
  ReloadingNotifications,
  RequestNotifications,
} from '../actions/notifications';

export default function notifications(state = null, action) {
  switch (action.type) {
    case RequestNotifications:
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
        loadingMore: false,
      });

    case NotificationsLoaded: {
      const { boardID, hasNextPage, items, pages, unreadCount } = action.notifications;
      return Object.assign({}, state, {
        boardID,
        error: null,
        hasNextPage,
        items,
        lastUpdated: action.timestamp,
        loading: false,
        loadingMore: false,
        pages,
        reloading: false,
        unreadCount,
      });
    }

    case LoadingMoreNotifications:
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        loadingMore: true,
      });

    case NoNotifications:
      return Object.assign({}, state, {
        count: 0,
        error: null,
        hasNextPage: false,
        items: [],
        lastUpdate: action.timestamp,
        loading: false,
        loadingMore: false,
        pages: 1,
      });

    case NotificationsError:
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
        loadingMore: false,
      });

    case ReloadingNotifications:
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        reloading: true,
      });

    default:
      return state;
  }
}

import Data from '../Data';

// Action Types

export const REQUEST_WIDGET_DATA = 'canny/widget/request_widget_data';
export const WIDGET_DATA_LOADED = 'canny/widget/widget_data_loaded';
export const WIDGET_DATA_ERROR = 'canny/widget/widget_data_error';

// Actions

function requestWidgetData() {
  return {
    timestamp: Date.now(),
    type: REQUEST_WIDGET_DATA,
  };
}

function widgetDataLoaded(result) {
  return {
    result,
    timestamp: Date.now(),
    type: WIDGET_DATA_LOADED,
  };
}

function widgetDataError(error) {
  return {
    error,
    timestamp: Date.now(),
    type: WIDGET_DATA_ERROR,
  };
}

// Action Creators

function fetchWidgetData(boardToken, ssoToken) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Promise.all([
      Data.fetch(
        {
          result: {
            input: {
              boardToken,
              ssoToken,
            },
            query: Data.queries.widget,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, error, data);
        }
      ),
    ]);
  };
}

export function loadWidgetData(boardToken, ssoToken) {
  return (dispatch, getState) => {
    if (shouldFetchWidgetData(getState())) {
      if (!boardToken) {
        return dispatch(widgetDataError('Missing board token'));
      }

      dispatch(requestWidgetData());
      return dispatch(fetchWidgetData(boardToken, ssoToken));
    } else if (isFetchingWidgetData(getState())) {
      return waitForResult();
    }
  };
}

// Helper Functions

function shouldFetchWidgetData(state) {
  return !state.widget;
}

function isFetchingWidgetData(state) {
  return !!state.widget.loading;
}

// Callback Queue

const resultCallbacks = [];

function waitForResult() {
  return new Promise((resolve, reject) => {
    resultCallbacks.push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, error, data) {
  var resultAction;
  if (data && data.result) {
    resultAction = widgetDataLoaded(data.result);
  } else {
    resultAction = widgetDataError(error);
  }

  return Promise.all([dispatch(resultAction)]).then(() => {
    resultCallbacks.forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks.length = 0;
  });
}

// Action Types

export const PostDraftDeleted = 'canny/posts/post_draft_deleted';
export const PostDraftSaved = 'canny/posts/post_draft_saved';

// Actions
function postDraftDeleted() {
  return {
    timestamp: Date.now(),
    type: PostDraftDeleted,
  };
}

function postDraftSaved(postDraft) {
  return {
    postDraft,
    timestamp: Date.now(),
    type: PostDraftSaved,
  };
}

// Action Creators

export function deletePostDraft() {
  return (dispatch) => {
    return dispatch(postDraftDeleted());
  };
}

export function savePostDraft(postDraft) {
  return (dispatch) => {
    return dispatch(postDraftSaved(postDraft));
  };
}

import React from 'react';

import classnames from 'classnames';

import 'css/components/common/_TextToggle.scss';

type Option<T extends string> = {
  label: React.ReactNode;
  value: T;
};

type Props<T extends string> = {
  className?: string;
  disabled?: boolean;
  onChange: (selected: T) => void;
  options: Option<T>[];
  selected: string;
  variant?: 'fullBorder' | 'partialBorder';
};

const TextToggle = <T extends string>({
  className,
  disabled,
  onChange,
  options,
  selected,
  variant = 'fullBorder',
}: Props<T>) => {
  return (
    <div
      className={classnames(className, 'textToggle', {
        [variant]: true,
      })}>
      {options.map((option) => {
        return (
          <button
            key={option.value}
            type="button"
            disabled={disabled}
            className="optionButton"
            onClick={() => onChange(option.value)}>
            <div
              className={classnames({
                option: true,
                selected: option.value === selected,
              })}>
              {option.label}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default TextToggle;

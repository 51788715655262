import React from 'react';

import classnames from 'classnames';

import 'css/components/inputs/_RadioButton.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  name: string;
  onSelected: () => void;
  selected?: boolean;
};

const RadioButton = ({ label, className, name, selected = false, onSelected }: Props) => {
  return (
    <button
      aria-label={name}
      className="radioButtonInput"
      onClick={onSelected}
      role="radio"
      aria-checked={selected}>
      <div className={classnames(className, 'radioButton', { selected })}>
        <div className="selectedDot" />
      </div>
      {label && <div className="radioButtonLabel">{label}</div>}
    </button>
  );
};

export default RadioButton;

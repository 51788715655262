import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import Button from 'common/inputs/Button';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_SubdomainUnsubscribe.scss';

class SubdomainUnsubscribe extends Component {
  static propTypes = {
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    location: PropTypes.shape({
      query: PropTypes.shape({
        email: PropTypes.string.isRequired,
        unsubscribeID: PropTypes.string.isRequired,
      }),
    }),
  };

  state = {
    error: null,
    submitting: false,
    success: false,
  };

  onUnsubscribe = () => {
    const { submitting } = this.state;
    if (submitting) {
      return;
    }

    const {
      location: { query },
    } = this.props;
    const email = query.email;
    const unsubscribeID = query.unsubscribeID;

    this.setState({
      error: null,
      submitting: true,
    });

    AJAX.post(
      '/api/company/unsubscribe',
      {
        email,
        unsubscribeID,
      },
      (response) => {
        if (response === 'success') {
          this.setState({
            submitting: false,
            success: true,
          });
        } else {
          this.setState({
            error: true,
            submitting: false,
          });
        }
      }
    );
  };

  renderContents() {
    const {
      company,
      location: { query },
    } = this.props;
    const { error, submitting, success } = this.state;

    if (error || !query.email || !query.unsubscribeID) {
      return (
        <div className="contents">
          <div className="message">
            Uh-oh, something went wrong. Please{' '}
            <a href="mailto:hello@canny.io?subject=Unsubscribe%20Issues">email us</a> and we'll get
            you taken care of right away.
          </div>
        </div>
      );
    } else if (success) {
      return (
        <div className="contents">
          <div className="message">You have successfully been unsubscribed.</div>
        </div>
      );
    } else {
      return (
        <div className="contents">
          <div className="message">
            {'Click the button below to stop receiving emails from Canny on behalf of ' +
              company.name}
          </div>
          <Button
            buttonType="cannyButton"
            loading={submitting}
            onTap={this.onUnsubscribe}
            tint={true}
            value="Unsubscribe"
          />
        </div>
      );
    }
  }

  render() {
    return (
      <ContentContainer outerClassName="subdomainUnsubscribe">
        <div className="heading">Unsubscribe</div>
        {this.renderContents()}
      </ContentContainer>
    );
  }
}

export default withContexts({ company: CompanyContext })(SubdomainUnsubscribe);

const errors = {
  'hubspot not installed':
    "The HubSpot integration is not installed on Canny. If this doesn't sound correct, please, contact support for assistance.",
  'invalid installation':
    'The HubSpot integration was uninstalled from Canny. Please, contact a Canny admin to fix this issue.',
  'invalid token':
    'The authentication token has expired. Please, reload this page to access the Canny integration again.',
  'not authorized':
    'Your account does not have the permissions required to install the HubSpot integration. Please contact a teammate who is an admin to install this integration.',
  'plan does not support':
    'Your current Canny plan does not support the HubSpot integration. Please, contact a Canny admin to upgrade.',
};

export default errors;

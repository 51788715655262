import React, { Component } from 'react';

import { compose } from 'redux';

import { loadAllBoards } from 'common/actions/boards';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import areArraysEqual from 'common/util/areArraysEqual';

import type { BoardStateItem } from 'common/reducers/boards';
import type { AsyncItem } from 'redux-connect';

const asyncFetch: AsyncItem = {
  promise: ({ store: { dispatch } }) => {
    return dispatch(loadAllBoards());
  },
};

export type BoardContextType =
  | {
      loading: true;
    }
  | BoardStateItem[];

export const BoardsContext = React.createContext<BoardContextType>({ loading: true });

type Props = {
  boards: { items: { [key: string]: BoardStateItem } };
};

class BoardsContainer extends Component<Props> {
  static asyncConnect = asyncFetch;
  _boards: BoardStateItem[] | null = null;

  getBoards() {
    const boardURLNames = Object.keys(this.props.boards.items);
    const boards = boardURLNames
      .filter((boardURLName) => {
        const board = this.props.boards.items[boardURLName];
        return (
          board &&
          board._id &&
          !board.error &&
          !board.notFound &&
          !board.loading &&
          board.urlName === boardURLName
        );
      })
      .map((boardURLName) => {
        return this.props.boards.items[boardURLName];
      });

    if (this._boards) {
      if (areArraysEqual(boards, this._boards)) {
        return this._boards;
      }
    }

    this._boards = boards;
    return boards;
  }

  render() {
    const boards = this.getBoards();
    const clonedElement = cloneElementWithProps(this.props.children, {
      ...this.props,
      boards: this.getBoards(),
    });
    return (
      <BoardsContext.Provider value={boards || { loading: true }}>
        {clonedElement}
      </BoardsContext.Provider>
    );
  }
}

export default compose(asyncConnect([asyncFetch], (state) => ({ boards: state.boards })))(
  BoardsContainer
);

import type { CustomField } from 'common/api/endpoints/companies';
import type {
  IntegrationName,
  ThirdPartyCompanyDefaultField,
} from 'common/thirdPartyCompanies/constants';

export type Field = {
  name: string;
  type: CustomField['fieldType'];
  integrations: IntegrationName[];
};

export type FieldMapping = Record<ThirdPartyCompanyDefaultField, string | null>;

/**
 * Checks if a field is connected to at least one of the integrations
 * @param integrations - The integrations to check against
 * @param fieldName - The field name to check
 * @param fields - The fields to check against
 * @returns Whether the field is connected to at least one of the integrations
 */
export const fieldIsIntegration = (
  integrations: IntegrationName[],
  fieldName: string | null,
  fields: Field[]
) => {
  if (!fieldName) {
    return false;
  }

  const field = fields.find((field) => field.name === fieldName);
  const isIntegration = field?.integrations.some((integration) =>
    integrations.includes(integration)
  );
  return isIntegration;
};

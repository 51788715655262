import React from 'react';

import type { Company as _Company } from 'common/api/endpoints/companies';

export type Company = _Company;

// The company will always exist, because of asyncConnect in SubdomainApp
export const CompanyContext = React.createContext<Company>({} as Company);

type Props = {
  children: React.ReactNode;
  company: Company;
};

const CompanyContainer = ({ company, children }: Props) => {
  return <CompanyContext.Provider value={company}>{children}</CompanyContext.Provider>;
};

export default CompanyContainer;

import getCookieDomain from './util/getCookieDomain';

const TenYears = 10 * 365 * 24 * 60 * 60 * 1000;

export default function setCookie(key, value) {
  const domain = getCookieDomain(window.location.host);
  const keyValue = encodeURIComponent(key) + '=' + encodeURIComponent(value);
  const expiresPart = '; expires=' + new Date(new Date().getTime() + TenYears).toString();
  const domainPart = '; domain=' + domain;
  const pathPart = '; path=/';
  const sameSitePart = '; SameSite=Lax';
  document.cookie = keyValue + expiresPart + domainPart + pathPart + sameSitePart;
}

import { loadGongSettings } from 'common/actions/gongSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

import type { GongSettings } from 'common/api/endpoints/gong';
import type { State } from 'redux-connect';

type Props = {
  gongSettings: GongSettings;
  children: React.ReactNode;
};

const GongSettingsContainer = ({ children, ...props }: Props) =>
  cloneElementWithProps(children, props);

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadGongSettings()),
    },
  ],
  (state: State) => ({ gongSettings: state.gongSettings?.data })
)(GongSettingsContainer);

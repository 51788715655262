import React, { useContext, useState } from 'react';

import { Archive, Trash } from 'lucide-react';

import { loadRoadmaps, reloadRoadmaps } from 'common/actions/roadmaps';
import AJAX from 'common/AJAX';
import { type Roadmap } from 'common/api/endpoints/roadmaps';
import Card from 'common/common/Card';
import Tooltip from 'common/common/Tooltip';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import asyncConnect from 'common/core/asyncConnect';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

type Props = {
  disableActions: boolean;
  roadmap: Roadmap;
  reloadRoadmaps: () => void;
};

const tooltipDelay = 300;

const RoadmapSettingsUnarchivedRow = ({ disableActions, roadmap, reloadRoadmaps }: Props) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isBeingArchived, setIsBeingArchived] = useState(false);
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const showToast = useContext(ShowToastContext);

  const onDeleteModalConfirmed = async () => {
    setIsBeingDeleted(true);
    setShowDeleteModal(false);
    const response = await AJAX.post('/api/roadmaps/delete', { roadmapID: roadmap._id });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Failed to delete roadmap',
        'invalid roadmapID': 'There is a problem deleting this roadmap',
        'not authorized': "You don't have the permissions to delete roadmaps",
      },
    });

    if (error) {
      setIsBeingDeleted(false);
      showToast(error.message, ToastTypes.error);
      return;
    }
    await reloadRoadmaps();
    setIsBeingDeleted(false);
  };

  const onArchiveRoadmapConfirmed = async () => {
    setIsBeingArchived(true);
    setShowArchiveModal(false);
    const response = await AJAX.post('/api/roadmaps/update', {
      archived: true,
      columns: roadmap.columns,
      name: roadmap.name,
      roadmapID: roadmap._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Failed to archive roadmap',
        'cannot archive last roadmap': 'You cannot archive the last roadmap',
        'invalid roadmapID': 'There is a problem archiving this roadmap',
        'not authorized': "You don't have the permissions to archive roadmaps",
      },
    });

    if (error) {
      setIsBeingArchived(false);
      showToast(error.message, ToastTypes.error);
      return;
    }

    await reloadRoadmaps();
    setIsBeingArchived(false);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const onCloseArchiveModal = () => {
    setShowArchiveModal(false);
  };

  const onArchiveRoadmap = async () => {
    setShowArchiveModal(true);
  };

  const onDeleteRoadmap = () => {
    setShowDeleteModal(true);
  };

  return (
    <>
      <Card borderStyle="solid" className="roadmap" key={roadmap._id}>
        <P>
          {roadmap.name} ({roadmap.roadmapPostCount})
        </P>
        <div className="roadmapActions">
          <Tooltip
            disabled={isBeingDeleted || isBeingArchived}
            delay={tooltipDelay}
            value={disableActions ? 'You cannot archive the last roadmap' : 'Archive roadmap'}
            position="left">
            <IconButtonV2
              icon={Archive}
              onClick={() => !isBeingArchived && onArchiveRoadmap()}
              aria-label="archive roadmap"
              variant="plain"
              size="medium"
              loading={isBeingArchived}
              disabled={isBeingDeleted || disableActions}
            />
          </Tooltip>
          <span className="separator" />
          <Tooltip
            disabled={isBeingDeleted || isBeingArchived}
            delay={tooltipDelay}
            value={disableActions ? 'You cannot delete the last roadmap' : 'Delete roadmap'}
            position="left">
            <IconButtonV2
              icon={Trash}
              onClick={() => !isBeingDeleted && onDeleteRoadmap()}
              aria-label="delete roadmap"
              variant="plain"
              color="error"
              size="medium"
              loading={isBeingDeleted}
              disabled={isBeingArchived || disableActions}
            />
          </Tooltip>
        </div>
      </Card>
      {showArchiveModal ? (
        <ModernConfirmModal
          onClose={onCloseArchiveModal}
          onConfirm={onArchiveRoadmapConfirmed}
          confirmText="Archive"
          header="Archive Roadmap">
          <P>Are you sure you want to archive {roadmap.name}?</P>
        </ModernConfirmModal>
      ) : null}
      {showDeleteModal ? (
        <ModernConfirmModal
          onClose={onCloseDeleteModal}
          onConfirm={onDeleteModalConfirmed}
          confirmText="Delete"
          type="destructive"
          header="Delete Roadmap">
          <P>Are you sure you want to delete {roadmap.name}? This cannot be&nbsp;undone.</P>
        </ModernConfirmModal>
      ) : null}
    </>
  );
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => {
        return dispatch(loadRoadmaps());
      },
    },
  ],
  undefined,
  (dispatch) => ({
    reloadRoadmaps: () => dispatch(reloadRoadmaps()),
  })
)(RoadmapSettingsUnarchivedRow);

import {
  type ExtensionTypes,
  Extensions,
  LoadStatus,
  type MimeTypeValues,
  MimeTypes,
  type UploadedFile,
} from 'common/constants/files';
import generateFileID from 'common/file/utils/generateFileID';
import getFileNameWithoutExtension from 'common/file/utils/getFileNameWithoutExtension';
import getExtension from 'common/util/getExtension';

type EntityFile = {
  mimeType: MimeTypeValues | null;
  name: string;
  url: string;
};

const isSupportedExtension = (extension: unknown): extension is ExtensionTypes => {
  return typeof extension === 'string' && extension in Extensions;
};

export const createFiles = (files: EntityFile[]): UploadedFile[] => {
  return files.map(({ mimeType, name, url }) => {
    const temporaryUniqueID = generateFileID();
    const baseExtension = getExtension(url);
    const extension = baseExtension && isSupportedExtension(baseExtension) ? baseExtension : null;
    const nameWithoutExtension = getFileNameWithoutExtension(name);

    return {
      extension,
      name: nameWithoutExtension,
      // NOTE: We use unique ID as the key of file elements.
      // We need a constant value (such as url) to keep the same file element when the file is re-rendered.
      uniqueID: url || temporaryUniqueID,
      contentType: mimeType ?? null,
      uploadStatus: LoadStatus.loaded,
      url,
    };
  });
};

export const createImageFiles = (urls: string[]): UploadedFile[] => {
  const filesWithNames = urls.map((url) => {
    const extension = getExtension(url);
    const mimeType = isSupportedExtension(extension) ? MimeTypes[extension][0] : null;

    return { mimeType, name: url, url };
  });
  return createFiles(filesWithNames);
};

import React, { Component } from 'react';

import { Settings } from 'lucide-react';
import PropTypes from 'prop-types';

import CheckboxInput from 'common/inputs/CheckboxInput';
import Link from 'common/Link';
import PostSearchDropdown from 'common/post/PostSearchDropdown';
import PostStatus from 'common/post/PostStatus';
import Tag from 'common/tags/Tag';
import Tappable from 'common/Tappable';

import AdminSidebarSection from './AdminSidebarSection';

import 'css/components/subdomain/admin/_AdminChangelogCreateSidebar.scss';

export const Types = {
  new: {
    name: 'new',
    render: 'New',
  },
  improved: {
    name: 'improved',
    render: 'Improved',
  },
  fixed: {
    name: 'fixed',
    render: 'Fixed',
  },
};

export default class AdminChangelogCreateSidebar extends Component {
  static propTypes = {
    changelog: PropTypes.object,
    disabled: PropTypes.bool,
    entry: PropTypes.object,
    linkedPosts: PropTypes.array.isRequired,
    onDeleteEntry: PropTypes.func,
    onLabelSelected: PropTypes.func,
    onLinkPost: PropTypes.func,
    onShouldSaveDraft: PropTypes.func,
    onTypeChange: PropTypes.func,
    onUnlinkPost: PropTypes.func,
    selectedLabels: PropTypes.array.isRequired,
    selectedTypes: PropTypes.array.isRequired,
  };

  state = {
    showPostSearchInput: false,
  };

  onSearchTapped = () => {
    const { showPostSearchInput } = this.state;
    this.setState({ showPostSearchInput: !showPostSearchInput });
  };

  renderPostSection() {
    const { linkedPosts, onUnlinkPost } = this.props;

    const items = linkedPosts.map((post, i) => {
      const voteCount = `${post.score}`;

      return (
        <div className="postItem" key={i}>
          <div className="body">
            <div className="title">{post.title}</div>
            <div className="detailsRow">
              <div className="voteCount">
                <span className="vote" />
                {voteCount}
              </div>
              <div className="middot">&middot;</div>
              <PostStatus showOpen={true} status={post.status} />
            </div>
          </div>
          <Tappable onTap={() => onUnlinkPost(post)}>
            <div className="icon icon-x" />
          </Tappable>
        </div>
      );
    });

    return (
      <AdminSidebarSection
        action={
          <Tappable onTap={this.onSearchTapped}>
            <div className="actionLink">Search</div>
          </Tappable>
        }
        className="postSection"
        title="Linked posts">
        {this.renderPostSearchInput()}
        <div className="labelInputs">{items}</div>
      </AdminSidebarSection>
    );
  }

  renderTypeSection() {
    const { disabled, onTypeChange, selectedTypes } = this.props;

    const inputs = Object.values(Types).map((type) => {
      return (
        <CheckboxInput
          defaultChecked={selectedTypes.some((t) => t === type.name)}
          disabled={disabled}
          key={type.name}
          label={type.render}
          onChange={(checked) => onTypeChange(type, checked)}
        />
      );
    });

    return (
      <AdminSidebarSection className="typeSection" title="Type">
        {inputs}
      </AdminSidebarSection>
    );
  }

  renderLabelSection() {
    const { changelog, disabled, onLabelSelected, selectedLabels } = this.props;
    const labelInputs = [];

    changelog.labels.forEach((label) => {
      labelInputs.push(
        <Tag
          disabled={disabled}
          key={label._id}
          name={label.name}
          onTap={() => onLabelSelected(label)}
          selected={selectedLabels.includes(label._id)}
        />
      );
    });

    return (
      <AdminSidebarSection
        action={
          <Link to="/admin/settings/changelog/labels">
            <Settings size="16" className="icon-settings" />
          </Link>
        }
        className="labelSection"
        title="Labels">
        <div className="labelInputs">{labelInputs}</div>
      </AdminSidebarSection>
    );
  }

  renderDeleteSection() {
    const { entry, onDeleteEntry } = this.props;
    if (!entry) {
      return null;
    }

    return (
      <Tappable onTap={onDeleteEntry}>
        <div className="actionLink">Delete entry</div>
      </Tappable>
    );
  }

  renderPostSearchInput() {
    const { disabled } = this.props;
    const { showPostSearchInput } = this.state;
    if (disabled || !showPostSearchInput) {
      return null;
    }

    const { entry, linkedPosts, onLinkPost } = this.props;
    return <PostSearchDropdown entry={entry} excludePosts={linkedPosts} onLinkPost={onLinkPost} />;
  }

  render() {
    return (
      <div className="adminChangelogCreateSidebar">
        {this.renderPostSection()}
        {this.renderTypeSection()}
        {this.renderLabelSection()}
        {this.renderDeleteSection()}
      </div>
    );
  }
}

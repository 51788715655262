import React from 'react';

import classNames from 'classnames';

import { H3, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/GettingStarted/_AdminAutopilotInfoBox.module.scss';

type Props = {
  points: string[];
  className?: string;
};

const AdminAutopilotInfoBox = ({ points, className }: Props) => {
  return (
    <div className={classNames(styles.container, className)}>
      <H3 variant="bodyMd" fontWeight="medium" className={styles.heading}>
        What happens when you connect:
      </H3>
      <ul className={styles.list}>
        {points.map((point, index) => (
          <li className={styles.item} key={`info_box_${index}`}>
            <P>{point}</P>
          </li>
        ))}
      </ul>
      <P className={styles.learnMore}>
        Additional questions?{' '}
        <a
          href="https://help.canny.io/en/articles/8202451-autopilot#h_2db4f3ed39"
          rel="noopener"
          target="_blank">
          Check out the FAQ
        </a>
      </P>
    </div>
  );
};

export default AdminAutopilotInfoBox;

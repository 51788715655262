import React from 'react';

import Form from 'common/Form';
import Button from 'common/inputs/Button';

import AdminRoadmapSelector from './AdminRoadmapSelector';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

type Props = {
  editing: boolean;
  onCreateRoadmap: () => void;
  onNameUpdate: (name: string) => void;
  onSaveRoadmapName: () => void;
  roadmap: Roadmap | null;
  roadmapName: string;
  roadmaps: Roadmap[];
};

const AdminRoadmapHeaderName = ({
  editing,
  onCreateRoadmap,
  onNameUpdate,
  onSaveRoadmapName,
  roadmap,
  roadmapName,
  roadmaps,
}: Props) => {
  if (editing) {
    return (
      <Form addEventsToDocument={false} disableSubmit={false} onSubmit={onSaveRoadmapName}>
        <div className="nameFields">
          <input
            className="nameEditor"
            autoFocus={true}
            onBlur={onSaveRoadmapName}
            onChange={(e) => onNameUpdate(e.target.value)}
            value={roadmapName}
          />
          <Button
            buttonType="cannyButton"
            className="saveNameButton"
            formButton={true}
            value="save"
          />
        </div>
      </Form>
    );
  }

  return (
    <AdminRoadmapSelector onNewRoadmap={onCreateRoadmap} roadmap={roadmap} roadmaps={roadmaps} />
  );
};

export default AdminRoadmapHeaderName;

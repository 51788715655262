import {
  Invalidate,
  SuggestionsError,
  SuggestionsLoaded,
} from 'common/actions/azureDevopsWorkItemSuggestions';

export default function azureDevopsWorkItemSuggestions(state = {}, action) {
  switch (action.type) {
    case SuggestionsLoaded: {
      return Object.assign({}, state, {
        [action.search]: {
          error: null,
          lastUpdated: action.timestamp,
          loading: false,
          items: action.suggestions,
        },
      });
    }

    case SuggestionsError: {
      return Object.assign({}, state, {
        [action.search]: {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          items: null,
        },
      });
    }

    case Invalidate: {
      return {};
    }

    default:
      return state;
  }
}

import { Component } from 'react';

import PropTypes from 'prop-types';

export default class BackgroundColorContainer extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
  };

  componentDidMount() {
    const html = document.body.parentElement;
    this._previousValue = html.style.backgroundColor;

    const { backgroundColor } = this.props;
    html.style.backgroundColor = backgroundColor;
  }

  componentWillUnmount() {
    const html = document.body.parentElement;
    html.style.backgroundColor = this._previousValue ? this._previousValue : '';
  }

  render() {
    return this.props.children;
  }
}

import Data from '../Data';

// Action Types

export const REQUEST_COMPANY = 'canny/company/request_company';
export const COMPANY_LOADED = 'canny/company/company_loaded';
export const COMPANY_NOT_FOUND = 'canny/company/company_not_found';
export const COMPANY_ERROR = 'canny/company/company_error';

// Actions

function requestCompany() {
  return {
    timestamp: Date.now(),
    type: REQUEST_COMPANY,
  };
}

function companyLoaded(company) {
  return {
    company,
    timestamp: Date.now(),
    type: COMPANY_LOADED,
  };
}

function companyNotFound() {
  return {
    timestamp: Date.now(),
    type: COMPANY_NOT_FOUND,
  };
}

function companyError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: COMPANY_ERROR,
  };
}

// Action Creators

function fetchCompany() {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Promise.all([
      Data.fetch(
        {
          company: {
            input: {},
            query: Data.queries.company,
          },
        },
        cookies,
        (error, data) => {
          return gotResult(dispatch, error, data);
        }
      ),
    ]);
  };
}

export function loadCompany() {
  return (dispatch, getState) => {
    if (shouldFetchCompany(getState())) {
      dispatch(requestCompany());
      return dispatch(fetchCompany());
    } else if (isFetchingCompany(getState())) {
      return waitForResult();
    }
  };
}

export function reloadCompany() {
  return (dispatch) => {
    return dispatch(fetchCompany());
  };
}

// Helper Functions

function shouldFetchCompany(state) {
  return !state.company;
}

function isFetchingCompany(state) {
  return !!state.company.loading;
}

// Callback Queue

const resultCallbacks = [];

function waitForResult() {
  return new Promise((resolve, reject) => {
    resultCallbacks.push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, error, data) {
  var resultAction;
  if (error && error === 'not found') {
    resultAction = companyNotFound();
  } else if (error || !data.company) {
    resultAction = companyError(error);
  } else {
    resultAction = companyLoaded(data.company);
  }

  return Promise.all([dispatch(resultAction)]).then(() => {
    resultCallbacks.forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks.length = 0;
  });
}

import React, { Component, createContext } from 'react';

import PropTypes from 'prop-types';

import { loadCustomPostFields } from 'common/actions/customPostFields';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { type State, defaultState } from 'common/reducers/customPostFields';

import type { AsyncItem } from 'redux-connect';

const asyncFetch: AsyncItem = {
  promise: ({ store: { dispatch } }) => {
    return dispatch(loadCustomPostFields());
  },
};

const DefaultCustomPostFields: State['data'] = [];

export const CustomPostFieldsContext = createContext<State>(defaultState);

type Props = {
  customPostFields: State;
};

class CustomPostFieldsContainer extends Component<Props> {
  static asyncConnect = asyncFetch;

  static propTypes = {
    customPostFields: PropTypes.object,
  };

  render() {
    const { children, customPostFields } = this.props;
    const clonedElement = cloneElementWithProps(children, {
      ...this.props,
      customPostFields: customPostFields?.data ?? DefaultCustomPostFields,
    });

    return (
      <CustomPostFieldsContext.Provider value={customPostFields}>
        {clonedElement}
      </CustomPostFieldsContext.Provider>
    );
  }
}

export default asyncConnect([asyncFetch], (state) => ({
  customPostFields: state.customPostFields,
}))(CustomPostFieldsContainer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import Link from 'common/Link';
import Tappable from 'common/Tappable';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminFeatureBlock.scss';

class AdminFeatureBlock extends Component {
  static propTypes = {
    benefit: PropTypes.string,
    company: PropTypes.shape({
      subdomain: PropTypes.string,
    }),
    feature: PropTypes.string,
    isWidget: PropTypes.bool,
    showBillingLink: PropTypes.bool,
    showIntercom: PropTypes.func,
  };

  static defaultProps = {
    showBillingLink: true,
  };

  onContactUsTapped = () => {
    const { showIntercom } = this.props;
    showIntercom();
  };

  renderBillingLink() {
    const {
      company: { subdomain },
      isWidget,
      showBillingLink,
    } = this.props;

    if (!showBillingLink) {
      return null;
    }

    if (isWidget) {
      const billingURL = devURL('https://' + subdomain + '.canny.io/admin/settings/billing');
      return (
        <a href={billingURL} rel="noopener" target="_blank">
          Go to billing
        </a>
      );
    }

    return <Link to="/admin/settings/billing">Go to billing</Link>;
  }

  renderContactUs() {
    const { isWidget } = this.props;
    if (isWidget) {
      return null;
    }

    return (
      <Tappable onTap={this.onContactUsTapped}>
        <div className="contact">Contact us</div>
      </Tappable>
    );
  }

  render() {
    const { benefit, feature } = this.props;
    return (
      <div className="adminFeatureBlock">
        <h2 className="title">Upgrade for {feature}</h2>
        <p className="description">{benefit}</p>
        <div className="buttons">
          {this.renderBillingLink()}
          {this.renderContactUs()}
        </div>
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  isWidget: IsWidgetContext,
  showIntercom: ShowIntercomContext,
})(AdminFeatureBlock);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import cloneElementWithProps from 'common/core/cloneElementWithProps';
import Helmet from 'common/helmets/Helmet';
import loadScript from 'common/util/loadScript';

export const TrackLinkedInEventContext = React.createContext();

const LinkedInPixelID = '239507';
const LinkedInConversionIDMap = {
  'Demo Request': 13198332,
  'Registered Company': 592259,
  'Registered Qualified Company': 592267,
  'Registered VIP Company': 592275,
  'Qualified Demo Request': 13198340,
  'Viewed Jobs Site': 4434812,
  'Viewed Landing Page': 4434804,
  'Viewed Register Page': 592283,
  'VIP Demo Request': 13198356,
};

function loadLinkedInSDK(onload) {
  window._linkedin_partner_id = LinkedInPixelID;
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

  loadScript('https://snap.licdn.com/li.lms-analytics/insight.min.js', 'linkedin-script');
}

export default class LinkedInContainer extends Component {
  static propTypes = {
    shouldLoadSDK: PropTypes.bool,
  };

  static defaultProps = {
    shouldLoadSDK: false,
  };

  scriptLoaded = false;
  eventQueue = [];

  componentDidMount() {
    const { shouldLoadSDK } = this.props;
    if (!shouldLoadSDK) {
      return;
    }

    loadLinkedInSDK(this.onScriptLoaded);
  }

  onScriptLoaded = () => {
    this.scriptLoaded = true;
    this.eventQueue.forEach(({ eventName, eventData }) => {
      this.onTrackEvent(eventName, eventData);
    });
    this.eventQueue = [];
  };

  onTrackEvent = (eventName, eventData) => {
    const { shouldLoadSDK } = this.props;
    if (!shouldLoadSDK) {
      return;
    }

    const linkedInConversionID = LinkedInConversionIDMap[eventName];
    if (!linkedInConversionID) {
      return;
    }

    if (!this.scriptLoaded) {
      this.eventQueue.push({
        eventName,
        eventData,
      });
      return;
    }

    if (!window.lintrk) {
      // LinkedIn script blocked by chrome extension / browser
      return;
    }

    window.lintrk('track', { conversion_id: linkedInConversionID });
  };

  render() {
    const childProps = Object.assign({}, this.props);
    delete childProps.shouldLoadSDK;
    return (
      <TrackLinkedInEventContext.Provider value={this.onTrackEvent}>
        <Helmet
          link={[
            {
              href: 'https://snap.licdn.com',
              rel: 'preconnect',
            },
            {
              href: 'https://linkedin.com',
              rel: 'preconnect',
            },
            {
              href: 'https://px.ads.linkedin.com',
              rel: 'preconnect',
            },
            {
              href: 'https://p.adsymptotic.com',
              rel: 'preconnect',
            },
          ]}
        />
        {cloneElementWithProps(this.props.children, {
          ...childProps,
        })}
      </TrackLinkedInEventContext.Provider>
    );
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { OAuthContext } from 'common/containers/AuthContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import withContexts from 'common/util/withContexts';

import OAuthFlows from './OAuthFlows';
import Tappable from './Tappable';

import AzureLogoIcon from 'img/azure-logo-icon.png';
import FacebookLogoIcon from 'img/facebook-logo-icon.png';
import GitHubLogoIcon from 'img/github-logo-icon.png';
import GoogleLogoIcon from 'img/google-logo-icon.svg';
import OktaLogoIcon from 'img/okta-logo-icon.png';
import OIDCLogoIcon from 'img/open-id-connect-logo.png';

import 'css/components/_AuthButton.scss';

const AuthData = {
  azure: {
    image: AzureLogoIcon,
    results: ['azureAccessToken', 'azureUserID'],
  },
  facebook: {
    image: FacebookLogoIcon,
    results: ['fbUserID', 'fbAccessToken'],
  },
  github: {
    image: GitHubLogoIcon,
    results: ['githubUserID', 'ppToken'],
  },
  google: {
    image: GoogleLogoIcon,
    results: ['googleUserID', 'googleIDToken'],
  },
  gsuite: {
    image: GoogleLogoIcon,
    results: [],
  },
  oidc: {
    image: OIDCLogoIcon,
    results: [],
  },
  okta: {
    image: OktaLogoIcon,
    results: [],
  },
};

class AuthButton extends Component {
  static propTypes = {
    authType: PropTypes.oneOf(['azure', 'facebook', 'github', 'google', 'gsuite', 'oidc', 'okta'])
      .isRequired,
    className: PropTypes.string,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    oauth: PropTypes.func,
    onFailure: PropTypes.func,
    onSuccess: PropTypes.func,
    queryAllowlist: PropTypes.object,
    router: PropTypes.object,
    value: PropTypes.node.isRequired,
  };

  static defaultProps = {
    additionalData: {},
    className: '',
    queryAllowlist: {},
  };

  componentDidMount() {
    const { authType, location, queryAllowlist, router } = this.props;
    const { query } = location;

    if (!query || !query.authType || query.authType !== authType) {
      return;
    }

    if (query.failed) {
      this.onFailure(query.error);
    } else if (query.success) {
      this.onSuccess(query);
    } else {
      this.onContinue(query);
    }

    const cleanQuery = {};
    for (var key in query) {
      if (queryAllowlist[key]) {
        cleanQuery[key] = query[key];
      }
    }

    router.replace({
      pathname: location.pathname,
      query: cleanQuery,
    });
  }

  onTap = () => {
    const { additionalData, authType } = this.props;
    this.props.oauth(authType, additionalData, (error, oauthData) => {
      if (error) {
        this.onFailure(error);
      } else {
        this.onSuccess(oauthData);
      }
    });
  };

  onContinue = (result) => {
    const shouldOpenNewTab = false;
    var OAuthFlow = OAuthFlows.getFlow(this.props.authType, 2);
    var oauthFlow = new OAuthFlow({
      onContinue: this.onContinue,
      onFailure: this.onFailure,
      onSuccess: this.onSuccess,
      redirectURL: null,
      separateWindow: null,
      shouldOpenNewTab,
    });
    oauthFlow.connect(result);
  };

  onFailure = (error) => {
    this.props.onFailure(error);
  };

  onSuccess = (oauthData) => {
    var error = null;

    const { authType } = this.props;
    if (!oauthData) {
      error = new Error('OAuth: oauthData is null');
    } else {
      AuthData[authType].results.forEach((result) => {
        if (!oauthData[result]) {
          error = new Error('OAuth: missing ' + result + ' for ' + authType);
          return;
        }
      });
    }

    if (error) {
      this.onFailure(error);
      return;
    }

    this.props.onSuccess(oauthData);
  };

  render() {
    const { authType } = this.props;
    const className = ('authButton ' + this.props.className).trim();
    const image = AuthData[authType].image;
    return (
      <Tappable onTap={this.onTap}>
        <div className={className}>
          <div className="imageContainer">
            <LazyLoadedImage alt={`${authType} logo`} src={image} />
          </div>
          <div className="cta">{this.props.value}</div>
        </div>
      </Tappable>
    );
  }
}

export default compose(
  withContexts({
    location: LocationContext,
    oauth: OAuthContext,
    router: RouterContext,
  })
)(AuthButton);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CloseModalContext } from 'common/containers/ModalContainer';
import withContexts from 'common/util/withContexts';
import { OnAuthTokenContext } from 'common/widget/WidgetContext';

import EmailLoginForm from './EmailLoginForm';
import EmailSignupForm from './EmailSignupForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import Message from './message/Message';
import OAuthSignupForm from './OAuthSignupForm';
import SignupForm from './SignupForm';
import isWidget from './util/isWidget';
import queryString from './util/queryString';

import 'css/components/_AccountModal.scss';

export const FormStates = {
  emailLogin: 'emailLogin',
  emailSignup: 'emailSignup',
  forgotPassword: 'forgotPassword',
  login: 'login',
  oauthSignup: 'oauthSignup',
  signup: 'signup',
};

class AccountModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    email: PropTypes.string,
    formState: PropTypes.string.isRequired,
    initiateProvider: PropTypes.string,
    oauthProvider: PropTypes.string,
    onAuthToken: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  state = {
    formState: this.props.formState,
    oauthData: null,
    oauthProvider: this.props.oauthProvider || null,
  };

  openLogin = () => {
    this.setState({ formState: FormStates.login, oauthProvider: null });
  };

  openSignup = () => {
    this.setState({ formState: FormStates.signup, oauthProvider: null });
  };

  openEmailLogin = () => {
    this.setState({ formState: FormStates.emailLogin, oauthProvider: null });
  };

  openEmailSignup = () => {
    this.setState({ formState: FormStates.emailSignup, oauthProvider: null });
  };

  openForgotPassword = () => {
    this.setState({ formState: FormStates.forgotPassword });
  };

  openOAuthSignup = (oauthData) => {
    this.setState({
      formState: FormStates.oauthSignup,
      oauthData: oauthData,
    });
  };

  close = () => {
    this.props.closeModal();
  };

  onSuccess = async (response) => {
    if (this.props.onAuthToken && response?.ssoToken) {
      await this.props.onAuthToken(response.ssoToken);
    }

    const refresh = function (queryParams) {
      if (isWidget()) {
        Message.postMessage(window.parent, '*', 'refresh', {});
        return;
      }

      queryParams = typeof queryParams === 'object' ? queryParams : {};
      const search = queryString.stringify(queryParams);
      const href = window.location.pathname + search + window.location.hash;

      // reload page, removing query params not specified by caller
      window.location = href;
      window.location.reload();
    };

    if (!this.props.onSuccess) {
      refresh();
      return;
    }

    this.props.onSuccess(refresh);
  };

  renderContents() {
    switch (this.state.formState) {
      // login stuff
      case FormStates.login:
        return (
          <LoginForm
            hasSuccessAction={!!this.props.onSuccess}
            initiateProvider={this.props.initiateProvider}
            oauthProvider={this.state.oauthProvider}
            onEmail={this.openEmailLogin}
            onForgotPassword={this.openForgotPassword}
            onSignup={this.openSignup}
            onSuccess={this.onSuccess}
          />
        );
      case FormStates.emailLogin:
        return (
          <EmailLoginForm
            email={this.props.email}
            onBack={this.openLogin}
            onForgotPassword={this.openForgotPassword}
            onSuccess={this.onSuccess}
          />
        );

      // signup stuff
      case FormStates.signup:
        return (
          <SignupForm
            hasSuccessAction={!!this.props.onSuccess}
            oauthProvider={this.state.oauthProvider}
            onEmail={this.openEmailSignup}
            onLogin={this.openLogin}
            onOAuthSignup={this.openOAuthSignup}
            onSuccess={this.onSuccess}
          />
        );
      case FormStates.emailSignup:
        return (
          <EmailSignupForm
            email={this.props.email}
            onForgotPasswordClicked={this.openForgotPassword}
            onBackClicked={this.openSignup}
            onSuccess={this.onSuccess}
          />
        );
      case FormStates.oauthSignup:
        return (
          <OAuthSignupForm
            oauthData={this.state.oauthData}
            onCancel={this.openSignup}
            onSuccess={this.onSuccess}
          />
        );

      // forgot password
      case FormStates.forgotPassword:
        return (
          <ForgotPasswordForm onBackClicked={this.openEmailLogin} onCloseClicked={this.close} />
        );
      default:
        console.error('Invalid form state');
        return null;
    }
  }

  render() {
    return <div className="accountModal">{this.renderContents()}</div>;
  }
}

export default withContexts(
  { closeModal: CloseModalContext, onAuthToken: OnAuthTokenContext },
  { forwardRef: true }
)(AccountModal);

import { encode } from 'common/util/base64';
import devURL from 'common/util/devURL';

type PartialCompany = {
  _id: unknown;
  subdomain: string;
  updatedAt: string | Date | null;
};

const OneOffCompanies = {
  jane: '5d373e9a5cc3bb787a4a7e24',
};

const companyOGImage = (company?: PartialCompany) => {
  if (!company?._id) {
    return 'https://canny.io/images/canny-social.png';
  }

  if (company._id === OneOffCompanies.jane) {
    return 'https://canny.io/images/jane-og.png';
  }

  return devURL(
    `https://${company.subdomain}.canny.io/api/og/company?v=${encode(String(company.updatedAt))}`
  );
};

export default companyOGImage;

import React, { useMemo, useState } from 'react';

import { DollarSign, Gift, Mail, PiggyBank } from 'lucide-react';

import { reloadReferralSettings } from 'common/actions/referralSettings';
import AJAX from 'common/AJAX';
import {
  CompanyReferralStatus,
  type ReferralAnswer,
  type ReferralSettings,
} from 'common/api/endpoints/referralSettings';
import Card from 'common/common/Card';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { ReferralQuestions } from 'common/constants/referrals';
import connect from 'common/core/connect';
import DataTable, { type Column } from 'common/DataTable';
import Helmet from 'common/helmets/Helmet';
import CopyButtonV2 from 'common/inputs/CopyButtonV2';
import TextInput from 'common/inputs/TextInput';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import ButtonV2 from 'common/ui/ButtonV2';
import CheckboxV2 from 'common/ui/CheckboxV2';
import IconBadge from 'common/ui/IconBadge';
import { H1, P } from 'common/ui/Text';
import devURL from 'common/util/devURL';
import mapify from 'common/util/mapify';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Dispatch } from 'redux';

import 'css/components/subdomain/admin/_AdminReferralSettings.scss';

type Props = {
  referralSettings: ReferralSettings;
  reloadReferralSettings: () => void;
};

type Row = {
  id: string;
  name: string;
  status: string;
};

const FormErrors = {
  'not authorized': "You don't have the permissions to join the referral program",
  'invalid answers': 'Please answer all questions to join the referral program',
  'user already joined': 'You have already joined the referral program',
};

const HowItems = {
  whatIReceive: {
    title: 'What do I receive?',
    description:
      'For every new customer you refer, you’ll receive 20% of their paid subscription every month (up to $1,000 per referral).',
    icon: DollarSign,
  },
  whatReferralsReceive: {
    title: 'What do my referrals receive?',
    description:
      'Your referrals will get 20% off their monthly plan for 3 months or 5% off their first year of an annual plan.',
    icon: Gift,
  },
  trackStatus: {
    title: 'How do I track the status of my referral?',
    description:
      'You can check the status of each referral below. Once your first referral becomes a paid customer, you’ll get an email from us with instructions.',
    icon: Mail,
  },
  payment: {
    title: 'How do I get paid?',
    description: 'Rewards will be distributed via Paypal. Payouts will take up to 90 days.',
    icon: PiggyBank,
  },
};

const AdminReferralSettings = ({ referralSettings, reloadReferralSettings }: Props) => {
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState<ReferralAnswer[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);

  const areAnswersValid = useMemo(() => {
    if (!termsAgreed) {
      return false;
    }

    const answerMap = mapify(answers, 'questionID');
    return ReferralQuestions.every(({ _id }) => !!answerMap[_id] && answerMap[_id].answer);
  }, [answers, termsAgreed]);

  const getPillForStatus = (status: CompanyReferralStatus) => {
    const pillStyle =
      {
        [CompanyReferralStatus.free]: DefaultPillStyles.warning,
        [CompanyReferralStatus.paid]: DefaultPillStyles.success,
        [CompanyReferralStatus.cancelled]: DefaultPillStyles.destructive,
      }[status] || DefaultPillStyles.warning;

    return (
      <Pill pillStyle={pillStyle}>
        <P variant="bodyMd" fontWeight="medium">
          {status}
        </P>
      </Pill>
    );
  };

  const saveAnswer = (questionID: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAnswers((prevAnswers) => {
      const newAnswers = prevAnswers.filter((a) => a.questionID !== questionID);
      return [...newAnswers, { questionID, answer: value }].sort((a, b) =>
        a.questionID.localeCompare(b.questionID)
      );
    });
  };

  const joinProgram = async () => {
    if (!areAnswersValid) {
      return;
    }

    setError(null);
    setLoading(true);

    const response = await AJAX.post('/api/referrals/joinProgram', { answers });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: FormErrors,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    await reloadReferralSettings();
    setLoading(false);
  };

  const onTermsChanged = () => {
    setTermsAgreed((termsAgreed) => !termsAgreed);
  };

  const renderHowSection = (excludeItems: string[] = []) => {
    const items = Object.entries(HowItems)
      .map(([key, value]) => (!excludeItems.includes(key) ? value : null))
      .filter((item): item is (typeof HowItems)[keyof typeof HowItems] => Boolean(item));

    return (
      <div className="howSection">
        <Card className="howCard" borderStyle="solid">
          {items.map(({ title, description, icon }) => (
            <div className="cardItem" key={title}>
              <IconBadge active size="x-large" icon={icon} />
              <div className="cardItemTextContainer">
                <P className="cardItemHeader" fontWeight="medium">
                  {title}
                </P>
                <P className="cardItemDescription">{description}</P>
              </div>
            </div>
          ))}
        </Card>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="referralFormSection">
        <div className="headingSection">
          <H1 variant="headingMd">Referral Signup Form</H1>
          <P className="linkBody">Earn up to $1,000 per referral for sharing your unique link.</P>
        </div>
        {renderHowSection(['trackStatus'])}
        <div className="formSection">
          {ReferralQuestions.map(({ _id, placeholder, question }) => (
            <div key={_id} className="questionSection">
              <P variant="bodyMd" className="question">
                {question}
              </P>
              <TextInput
                className="questionInput"
                placeholder={placeholder}
                onChange={saveAnswer(_id)}
              />
            </div>
          ))}
          <div className="termsSection">
            <CheckboxV2
              aria-label="agree to terms"
              checked={termsAgreed}
              onChange={onTermsChanged}
              size="medium"
            />
            <P className="termsText" variant="bodyMd">
              I agree to the&nbsp;
              <a
                className="termsLink"
                href="https://canny.io/referral-terms"
                rel="noopener"
                target="_blank">
                Terms and Conditions
              </a>
            </P>
          </div>
          <ButtonV2
            color="primary"
            onClick={joinProgram}
            loading={loading}
            size="medium"
            disabled={!areAnswersValid}>
            Join program
          </ButtonV2>
          {error && (
            <P variant="bodyMd" className="error">
              {error}
            </P>
          )}
        </div>
      </div>
    );
  };

  const renderLinkSection = () => {
    const referralLink = devURL(
      `https://canny.io/register?referral=${referralSettings.referralCode}`
    );

    return (
      <div className="referralLinkSection">
        <div className="headingSection">
          <H1 variant="headingMd">Referral Link</H1>
          <P className="linkBody">Earn up to $1,000 per referral for sharing your unique link.</P>
        </div>
        <div className="linkInputSection">
          <P className="linkInputDescription">Share your unique referral link</P>
          <div className="inputContainer">
            <TextInput className="linkInput" value={referralLink} readOnly={true} />
            <CopyButtonV2 size="large" value={referralLink} />
          </div>
        </div>
        {renderHowSection()}
      </div>
    );
  };

  const renderCompanies = () => {
    const companies = referralSettings?.referredCompanies ?? [];

    if (!companies.length) {
      return null;
    }

    const columns = [
      {
        id: 'name',
        align: 'left',
        header: 'Company',
        cell: (name) => <P variant="bodySm">{name}</P>,
        sortable: false,
      } as Column<Row, 'name'>,
      {
        id: 'status',
        align: 'left',
        header: 'Status',
        cell: (status: CompanyReferralStatus) => getPillForStatus(status),
        sortable: false,
      } as Column<Row, 'status'>,
    ];

    const rows = companies.map(({ name, status }) => ({
      id: name,
      name,
      status,
    }));

    return (
      <div className="referralCompaniesSection">
        <div className="headingSection">
          <H1 variant="headingMd">Your Referrals</H1>
        </div>
        <div className="tableSection">
          <DataTable columns={columns} rows={rows} />
        </div>
      </div>
    );
  };

  const renderSettings = () => {
    if (!referralSettings || !referralSettings.joinedReferralProgram) {
      return <div className="referralSettings">{renderForm()}</div>;
    }

    return (
      <div className="referralSettings">
        {renderLinkSection()}
        {renderCompanies()}
      </div>
    );
  };

  return (
    <div className="adminReferralSettings">
      <Helmet title="Referrals | Canny" />
      <AdminSettingsHeader
        title="Referrals"
        subheading="Get paid for recommending Canny to your&nbsp;network."
      />
      <div className="referralContent">{renderSettings()}</div>
    </div>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadReferralSettings: () => {
    return dispatch(reloadReferralSettings());
  },
}))(AdminReferralSettings);

import { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadQuery } from 'common/actions/postQueries';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { getPostQueryKey, getQueryFilterParams } from 'common/util/filterPosts';
import isServer from 'common/util/isServer';
import withContexts from 'common/util/withContexts';

const asyncFetch = {
  promise: ({ store: { dispatch, getState }, location, params }) => {
    const state = getState();
    const board = state.widget ? state.widget.board : state.boards.items[params.boardURLName];
    if ((!board || board.requestedBoardID) && (state.widget || params.boardURLName)) {
      return;
    }

    const company = state.widget ? state.widget.company : state.company;
    if (!company) {
      return;
    }

    const waitForResult =
      !location.pathname.startsWith('/admin/feedback') && !location.pathname.startsWith('/search');
    const options = {
      waitForResult,
    };

    if (!waitForResult && isServer()) {
      return;
    }

    return dispatch(
      loadQuery(
        getQueryFilterParams(board, company, location, { isWidget: !!state.widget }),
        options
      )
    );
  },
};

class PostListContainer extends Component {
  static asyncConnect = asyncFetch;

  static propTypes = {
    board: PropTypes.object,
    company: PropTypes.shape({
      segments: PropTypes.array,
    }),
    loadQuery: PropTypes.func,
    location: PropTypes.shape({
      query: PropTypes.object,
      state: PropTypes.object,
    }),
    postQueries: PropTypes.object,
    posts: PropTypes.object,
  };

  state = {
    needsPurge: false,
  };

  componentDidMount() {
    const { location, company } = this.props;
    const didWaitForResult = !location.pathname.startsWith('/admin/feedback');
    if (didWaitForResult) {
      return;
    }

    const { board, isWidget, loadQuery } = this.props;
    loadQuery(board, company, location, { isWidget });
  }

  componentDidUpdate(prevProps, prevState) {
    const { board, company, isWidget, loadQuery, location, postQueries } = this.props;
    const { needsPurge } = this.state;

    if (prevState.needsPurge && !postQueries.needsPurge) {
      this.setState({ needsPurge: false });
      return;
    }

    // Refresh the post list if the queries were recently invalidated
    if (postQueries.needsPurge && !needsPurge) {
      const postQueryKey = getPostQueryKey(
        getQueryFilterParams(board, company, location, { isWidget })
      );
      const pages = postQueries[postQueryKey]?.queryParams?.pages ?? null;
      this.setState({ needsPurge: true }, () => {
        loadQuery(board, company, location, { isWidget, pages });
      });
    }
  }

  getPostList() {
    const { board, company, isWidget, location, posts, postQueries } = this.props;
    const postQueryKey = getPostQueryKey(
      getQueryFilterParams(board, company, location, { isWidget })
    );
    const postList = Object.assign({ loading: true }, postQueries[postQueryKey]);
    if (!postList.posts) {
      return postList;
    }
    const postCountBeforeFilter = postList.posts.length;

    postList.needsPurge = postQueries.needsPurge;

    postList.posts = postList.posts
      .filter((postKey) => posts[postKey.boardID][postKey.postURLName])
      .map((postKey) => {
        const post = posts[postKey.boardID][postKey.postURLName];
        post.filteredScore = postKey.filteredScore;
        delete post.lastUpdated;
        return post;
      });
    postList.postsInvalidated = postCountBeforeFilter !== postList.posts.length;

    return postList;
  }

  render() {
    const newProps = { ...this.props };
    const { children } = newProps;

    delete newProps.company;
    newProps.postList = this.getPostList();
    return cloneElementWithProps(children, newProps);
  }
}

export default compose(
  asyncConnect(
    [asyncFetch],
    (state) => ({
      postQueries: state.postQueries,
      posts: state.posts,
    }),
    (dispatch) => ({
      loadQuery: (board, company, location, params) => {
        return dispatch(
          loadQuery(getQueryFilterParams(board, company, location, params), {
            waitForResult: false,
          })
        );
      },
    })
  ),
  withContexts(
    {
      company: CompanyContext,
      isWidget: IsWidgetContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostListContainer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { TintColorContext } from 'common/containers/TintColorContainer';
import hexToRGB from 'common/util/hexToRGB';
import { getMentionDisplayValue, getModeFromMentionTag } from 'common/util/mentions';
import withContexts from 'common/util/withContexts';

class Mentionify extends Component {
  static propTypes = {
    mentions: PropTypes.array,
    tintColor: PropTypes.string,
  };

  parseString(string) {
    let keyCounter = 1;

    const { mentions } = this.props;
    const mentionLocations = [];
    mentions.forEach((mention) => {
      // If board is anonymized, admins/viewer are rendered with userID but other users are rendered with aliasID
      const entityID = mention._id ?? mention.aliasID;
      const search = `@{${entityID}\\|(alias|first_name|full_name)}`;
      const regex = new RegExp(search, 'gi');

      let match;
      while ((match = regex.exec(string)) !== null) {
        const [mentionString] = match;
        mentionLocations.push({
          end: match.index + mentionString.length,
          mode: getModeFromMentionTag(mentionString),
          start: match.index,
          user: mention,
        });
      }
    });

    if (mentionLocations.length === 0) {
      return string;
    }

    mentionLocations.sort((a, b) => {
      return a.start - b.start;
    });

    const parsed = [];
    var position = 0;
    mentionLocations.forEach((mentionLocation) => {
      if (position <= mentionLocation.start) {
        const previousString = string.substr(position, mentionLocation.start - position);
        if (previousString) {
          parsed.push(previousString);
        }
      }

      const { tintColor } = this.props;
      const mentionRGBColor = hexToRGB(tintColor);
      const displayValue = getMentionDisplayValue(mentionLocation.user, mentionLocation.mode);
      parsed.push(
        <span
          className="mention"
          key={keyCounter++}
          style={
            mentionRGBColor && { backgroundColor: `rgba(${mentionRGBColor.join(',')}, 0.15)` }
          }>
          {displayValue}
        </span>
      );

      position = mentionLocation.end;
    });

    if (position < string.length) {
      const followingString = string.substr(position, string.length - position);
      if (followingString) {
        parsed.push(followingString);
      }
    }

    return parsed;
  }

  parse(children) {
    if (typeof children === 'string') {
      return this.parseString(children);
    } else if (React.isValidElement(children)) {
      return React.cloneElement(children, {}, this.parse(children.props.children));
    } else if (children instanceof Array) {
      return children.map((child) => {
        return this.parse(child);
      });
    }
  }

  render() {
    const parsedChildren = this.parse(this.props.children);
    return <>{parsedChildren}</>;
  }
}

export default withContexts({ tintColor: TintColorContext })(Mentionify);

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { loadMore as loadMoreThirdPartyCompanyPosts } from 'common/actions/thirdPartyCompanyPosts';
import { loadMore as loadMoreUserPosts } from 'common/actions/userPosts';
import { LocationContext } from 'common/containers/RouterContainer';
import PostList from 'common/post/PostList';
import AdminUsersPostListItem from 'common/subdomain/admin/AdminUsersPostListItem';
import nbspLastSpace from 'common/util/nbspLastSpace';
import oxfordJoin from 'common/util/oxfordJoin';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminUsersPostList.scss';

class AdminUsersPostList extends Component {
  static propTypes = {
    isCompanySelected: PropTypes.bool,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    postList: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.postListRef = React.createRef();
    this.scrollContainerRef = React.createRef();
  }

  componentDidMount() {
    this.scrollContainerRef.current.addEventListener('scroll', this.onScroll, false);
    this.onScroll();
  }

  componentWillUnmount() {
    this.scrollContainerRef.current.removeEventListener('scroll', this.onScroll);
  }

  onLoadMore = (postList) => {
    const { isCompanySelected } = this.props;
    if (isCompanySelected) {
      return loadMoreThirdPartyCompanyPosts(postList);
    }
    return loadMoreUserPosts(postList);
  };

  onScroll = (e) => {
    const postList = this.postListRef.current;
    if (!postList) {
      return;
    }

    const scrollContainer = this.scrollContainerRef.current;
    postList.onScroll(e, scrollContainer);
  };

  render() {
    const {
      isCompanySelected,
      location: { query },
      postList,
    } = this.props;

    if (!postList || !postList.posts) {
      return <div className="adminUsersPostList" ref={this.scrollContainerRef} />;
    }

    const filter = query.filter || 'posts-comments-votes';
    const noActivity = oxfordJoin(filter.split('-'));
    const companyOrUser = isCompanySelected ? 'company' : 'user';

    return (
      <div className="adminUsersPostList" ref={this.scrollContainerRef}>
        <PostList
          noPostsMessage={nbspLastSpace(`This ${companyOrUser} has no ${noActivity}.`)}
          ItemComponent={AdminUsersPostListItem}
          postList={postList}
          onLoadMore={this.onLoadMore}
          ref={this.postListRef}
          showComments={true}
          showMenu={false}
          showPrivateComments={true}
        />
      </div>
    );
  }
}

export default withContexts({ location: LocationContext })(AdminUsersPostList);

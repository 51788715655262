import { StaticColors } from 'common/colors/constants';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

// OnboardingTasks
export const Completed = 'Completed';
export const CreateBoard = 'CreateBoard';
export const CustomizePage = 'CustomizePage';
export const EnabledAutopilot = 'EnabledAutopilot';
export const FiveBehalfVotes = 'FiveBehalfVotes';
export const FiveContributors = 'FiveContributors';
export const InstallCanny = 'InstallCanny';
export const InstalledIntegration = 'InstalledIntegration';
export const InstalledSource = 'InstalledSource';
export const InviteTeammates = 'InviteTeammates';
export const PrivateBoard = 'PrivateBoard';
export const ThreePosts = 'ThreePosts';
export const ThreeTeammates = 'ThreeTeammates';
export const TwoTeammates = 'TwoTeammates';

const OnboardingChecks = {
  [CreateBoard]: ({ boardCount }) => boardCount > 0,
  [CustomizePage]: ({ faviconURL, logoURL, tintColor }) =>
    faviconURL !== null || logoURL !== null || tintColor !== StaticColors.canny,
  [EnabledAutopilot]: ({ installedSyncIntegrations }) =>
    !!installedSyncIntegrations.find((i) => !i.disabled),
  [FiveBehalfVotes]: ({ contributorCount }) => contributorCount > 4,
  [FiveContributors]: ({ contributorCount }) => contributorCount > 4,
  [InstallCanny]: ({ stats }) => stats.hasUsedIdentify || stats.hasUsedSSO,
  [InstalledIntegration]: ({ integrationCount }) => integrationCount > 0,
  [InstalledSource]: ({ activeIntegrations }) => {
    return !!Object.values(SyncIntegrationNames).find((name) => activeIntegrations[name]);
  },
  [InviteTeammates]: ({ inviteCount, members }) => inviteCount > 0 || members.length > 1,
  [PrivateBoard]: ({ hasUnlistedBoard }) => hasUnlistedBoard,
  [ThreePosts]: ({ postCount }) => postCount > 2,
  [ThreeTeammates]: ({ members }) => members.length > 2,
  [TwoTeammates]: ({ members }) => members.length > 1,
};

export const OnboardingLabels = {
  [CreateBoard]: 'Create a board',
  [CustomizePage]: 'Customize your page',
  [EnabledAutopilot]: 'Turn a source on',
  [FiveContributors]: 'Collect feedback',
  [InstalledIntegration]: 'Connect an integration',
  [InstalledSource]: 'Connect a source',
  [ThreePosts]: 'Create 3 posts',
  [TwoTeammates]: 'Invite your team',
};

export const OnboardingDescriptions = {
  [CreateBoard]: 'Start organizing your feedback',
  [CustomizePage]: 'Make Canny match your brand',
  [FiveContributors]: 'Track customer feedback in Canny',
  [InstalledIntegration]: 'Get Canny working with your existing workflow',
  [ThreePosts]: 'Add some ideas you’ve heard recently',
  [TwoTeammates]: 'Have another teammate join your Canny team',
};

export const OnboardingLinks = {
  [CreateBoard]: '/admin/create-board',
  [CustomizePage]: '/admin/settings/company',
  [EnabledAutopilot]: '/admin/autopilot/getting-started',
  [FiveContributors]: 'https://canny.io/blog/make-your-user-feedback-tool-more-accessible/',
  [InstallCanny]: 'https://developers.canny.io/install',
  [InstalledSource]: '/admin/autopilot/getting-started',
  [InstalledIntegration]: '/admin/settings/integrations',
  [InviteTeammates]: '/admin/settings/team',
  [ThreeTeammates]: '/admin/settings/team',
  [TwoTeammates]: '/admin/settings/team',
};

const getChecklist = (company) => {
  const { useCase } = company;
  const wantsAutopilot = useCase.motivations?.includes('autopilotFeedback');

  if (wantsAutopilot) {
    return [CreateBoard, InstalledSource, EnabledAutopilot, FiveContributors, TwoTeammates];
  }

  return [CreateBoard, CustomizePage, TwoTeammates, InstalledIntegration, FiveContributors];
};

export const getOnboardingTasks = (company) => {
  const checklist = getChecklist(company);

  return checklist.map((item) => {
    const check = OnboardingChecks[item];
    const completed = check(company);
    return {
      completed,
      name: item,
    };
  });
};

export default getOnboardingTasks;

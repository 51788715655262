import {
  InvalidateRoadmaps,
  RequestRoadmap,
  RequestRoadmaps,
  RoadmapLoaded,
  RoadmapsError,
  RoadmapsLoaded,
  VisibilitySettingsUpdated,
} from '../actions/roadmaps';

import type { Roadmap } from 'common/api/endpoints/roadmaps';

// TODO: Import this once the actions are typed
type Action = any;

export type RoadmapsState = {
  error?: string;
  lastUpdated: Date;
  loading?: boolean;
  roadmaps?: Roadmap[];
  oldURLNames: Record<string, string>;
};

export default function roadmaps(
  state: RoadmapsState | null = null,
  action: Action
): RoadmapsState | null {
  switch (action.type) {
    case RequestRoadmap: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case RequestRoadmaps: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    case RoadmapLoaded: {
      const roadmaps = state?.roadmaps?.map((roadmap) => {
        if (roadmap._id === action.roadmap._id) {
          return action.roadmap;
        } else {
          return roadmap;
        }
      });

      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        oldURLNames: {
          [action.oldURLName]: action.roadmap.urlName,
        },
        roadmaps: roadmaps ?? [action.roadmap],
      });
    }

    case RoadmapsLoaded: {
      const oldURLNames = { ...state?.oldURLNames };
      if (state?.roadmaps) {
        state.roadmaps.forEach((oldRoadmap) => {
          const newRoadmap = action.roadmaps.find(
            (roadmap: Roadmap) => roadmap._id === oldRoadmap._id
          );
          if (newRoadmap && newRoadmap.urlName !== oldRoadmap.urlName) {
            oldURLNames[oldRoadmap.urlName] = newRoadmap.urlName;
          }
        });
      }

      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        oldURLNames,
        roadmaps: action.roadmaps,
      });
    }

    case RoadmapsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case InvalidateRoadmaps: {
      return null;
    }

    case VisibilitySettingsUpdated: {
      const roadmaps = state?.roadmaps?.map((roadmap) => {
        if (roadmap._id === action.roadmap._id) {
          return {
            ...action.roadmap,
            settings: {
              ...action.roadmap.settings,
              hiddenColumnIDs: action.hiddenColumnIDs,
            },
          };
        }

        return roadmap;
      }) ?? [action.roadmap];

      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        roadmaps: roadmaps,
      });
    }

    default:
      return state;
  }
}

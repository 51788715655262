import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ChangelogEntry from 'common/changelog/ChangelogEntry';
import ChangelogEntryListLoadMore from 'common/changelog/ChangelogEntryListLoadMore';
import ChangelogNoEntries from 'common/changelog/ChangelogNoEntries';
import Error from 'common/common/Error';
import { LocationContext } from 'common/containers/RouterContainer';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminChangelogList.scss';

class AdminChangelogList extends Component {
  static propTypes = {
    changelog: PropTypes.object,
    entryList: PropTypes.object,
    location: PropTypes.object,
  };

  state = {
    collapsedEntryIDs: {},
  };

  constructor(props, context) {
    super(props, context);

    this.loadMoreRef = React.createRef();
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.scrollRef.current.addEventListener('scroll', this.onScroll, false);
    this.onScroll();
  }

  componentWillUnmount() {
    this.scrollRef.current.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (e) => {
    const scrollContainer = this.scrollRef.current;
    const loadMore = this.loadMoreRef.current && this.loadMoreRef.current.wrappedInstance;
    if (loadMore) {
      loadMore.onScroll(e, scrollContainer);
    }
  };

  hasActiveQuery() {
    const {
      location: { query },
    } = this.props;
    return query.labels || query.status || query.type || query.search;
  }

  renderNoEntries() {
    const {
      location: { query },
    } = this.props;

    return (
      <ChangelogNoEntries
        search={decodeQueryTextParam(query.search)}
        link="/admin/changelog/create"
        hasActiveFilters={this.hasActiveQuery()}
        isAdminView
      />
    );
  }

  renderPagination() {
    const { entryList } = this.props;
    return <ChangelogEntryListLoadMore ref={this.loadMoreRef} entryList={entryList} />;
  }

  renderEntries() {
    const { changelog, entryList } = this.props;
    if (entryList.entries.length === 0) {
      return this.renderNoEntries();
    }

    return (
      <div className="listContainer">
        {entryList.entries.map((entry) => (
          <ChangelogEntry
            key={entry._id}
            entry={entry}
            changelog={changelog}
            collapsed={this.state.collapsedEntryIDs[entry._id]}
            onToggleCollapse={(collapsed) => {
              this.setState({
                collapsedEntryIDs: {
                  ...this.state.collapsedEntryIDs,
                  [entry._id]: collapsed,
                },
              });
            }}
            showPublishTime
            showFooter
            showEditLink
            isCollapsible
          />
        ))}
        {this.renderPagination()}
      </div>
    );
  }

  render() {
    const { entryList } = this.props;
    const anyEntryHasError = entryList.entries.some((entry) => entry.error || entry.notFound);

    return (
      <div className="adminChangelogList" ref={this.scrollRef}>
        {entryList.error || anyEntryHasError ? (
          <div className="center">
            <Error />
          </div>
        ) : (
          this.renderEntries()
        )}
      </div>
    );
  }
}

export default withContexts({
  location: LocationContext,
})(AdminChangelogList);

import React from 'react';

import styles from 'css-module/components/common/_LoadingBar.module.scss';

type Props = {
  percentage: number;
};

const LoadingBar = ({ percentage }: Props) => {
  const clampedPercentage = Math.max(Math.min(percentage, 100), 0);
  return (
    <div className={styles.container}>
      <div className={styles.bar} style={{ width: `${clampedPercentage}%` }} />
    </div>
  );
};

export default LoadingBar;

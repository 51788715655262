import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { setCookies } from 'common/actions/cookies';
import AJAX from 'common/AJAX';
import { setStore } from 'common/core/clientAsyncConnect';

const composeEnhancers =
  __DEV__ && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default function configureStore(reducers, cookies) {
  const preloadedState = typeof window !== 'undefined' ? window.__data : undefined;
  const store = createStore(
    reducers,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );

  if (cookies) {
    store.dispatch(setCookies(cookies));
  }

  if (preloadedState?.viewer?.csrfToken) {
    AJAX.injectCookies({ csrfToken: preloadedState?.viewer?.csrfToken });
  }

  setStore(store);

  return store;
}

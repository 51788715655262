import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { ReferralSettings } from 'common/api/endpoints/referralSettings';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: referralSettingsRequested,
  loadedAction: referralSettingsLoaded,
  errorAction: referralSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<ReferralSettings>('referralSettings');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchReferralSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          referralSettings: {
            query: Data.queries.referralSettings,
          },
        },
        cookies
      );
      return dispatch(referralSettingsLoaded(response.referralSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(referralSettingsError(message));
    }
  };
}

export function loadReferralSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(referralSettingsRequested());
    return dispatch(fetchReferralSettings());
  };
}

export function reloadReferralSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchReferralSettings());
  };
}

function shouldFetch(state: State) {
  return !state.referralSettings.data;
}

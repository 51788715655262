import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminSegmentSettings.scss';

const Destinations = {
  deprecatedDestination: 'canny-functions',
  newDestination: 'canny-actions',
};
const ErrorMessages = {
  'not authorized': "You don't have the permissions to install the integration",
  'plan does not support': 'Your plan does not support this integration',
  'integration limit': 'You have reached your integration limit',
  'destination already installed': 'You have already installed this integration',
};

class AdminSegmentSettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      segment: PropTypes.shape(),
    }),
  };

  state = {
    error: null,
    installing: false,
  };

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Segment integration?",
      onConfirm: () => {
        AJAX.post('/api/segment/disconnect', {}, (response) => {
          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please contact our team for support.',
            });
          } else {
            this.props.reloadCompany();
          }
        });
      },
    });
  };

  onInstall = async () => {
    this.setState({ installing: true });

    const response = await AJAX.post('/api/segment/install', {});
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: ErrorMessages,
    });

    if (error) {
      this.setState({
        installing: false,
        error: error.message,
      });
      return;
    }

    await this.props.reloadCompany();
    this.setState({ installing: false });
  };

  renderDeprecatedSettings() {
    const {
      company: {
        segment: { destinationName },
      },
    } = this.props;
    const path = destinationName.split('/');
    const workspace = path[1];
    const source = path[3];
    const destination = path[5];
    const url = `https://app.segment.com/${workspace}/destinations/${destination}/sources/${source}/configuration`;
    return (
      <>
        <a
          className="buttonContainer"
          href={url}
          rel="nofollow noreferrer noopener"
          target="_blank">
          <Button value="Configure Segment" />
        </a>
        {this.renderDisconnectButton()}
      </>
    );
  }

  renderNewSettings() {
    const {
      company: {
        segment: { sharedSecret },
      },
    } = this.props;
    return (
      <>
        <p className="text">
          Your Segment API Key is:&nbsp;
          <span className="emphasize">{sharedSecret}</span>
        </p>
        <p className="linkText">
          Add&nbsp;
          <a
            href="https://app.segment.com/goto-my-workspace/destinations/catalog/actions-canny"
            rel="noopener"
            target="_blank">
            Canny (Actions)
          </a>{' '}
          to your destinations in Segment and see our&nbsp;
          <a
            href="https://help.canny.io/en/articles/3567235-segment-integration"
            rel="noopener"
            target="_blank">
            documentation
          </a>{' '}
          to complete installation.
        </p>
        {this.renderDisconnectButton()}
      </>
    );
  }

  renderDisconnectButton() {
    return (
      <Tappable onTap={this.onDisconnect}>
        <div className="disconnect">Disconnect</div>
      </Tappable>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderInstallButton() {
    const { installing } = this.state;

    return (
      <div>
        <p className="text">Install the Segment integration to get your API key.</p>
        <Button loading={installing} onTap={this.onInstall} value="Install Segment" />
      </div>
    );
  }

  renderContents() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
      company,
    } = this.props;
    const installed = company.segment && company.segment.destinationName;
    const hasAccess = company?.integrations?.segment;
    const hasDeprecatedInstall =
      installed && company.segment.destinationName !== Destinations.newDestination;
    const reachedIntegrationLimit = integrationLimit && integrationCount >= integrationLimit;

    if (!hasAccess) {
      return (
        <div>
          {installed && this.renderDisconnectButton()}
          <AdminFeatureUpsell feature="segment" />
        </div>
      );
    }

    if (!installed && reachedIntegrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    if (!installed && !reachedIntegrationLimit) {
      return this.renderInstallButton();
    }

    return hasDeprecatedInstall ? this.renderDeprecatedSettings() : this.renderNewSettings();
  }

  render() {
    return (
      <div className="adminSegmentSettings">
        <Helmet title="Segment | Canny" />
        <div className="settingsHeading">Segment Integration</div>
        <div className="content">
          <p className="text">
            Pull in your users from Segment easily by installing our Segment integration.
          </p>
          {this.renderContents()}
          {this.renderError()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return dispatch(reloadCompany());
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.segment), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminSegmentSettings);

import AJAX from './AJAX';

export default class OAuthFacebookRedirect {
  constructor({ onSuccess, onFailure }) {
    this._onSuccess = onSuccess;
    this._onFailure = onFailure;
  }

  connect = (result) => {
    if (!result.code) {
      this._onFailure();
      return;
    }

    AJAX.post(
      '/api/oauth/getFacebookAccessToken',
      {
        code: result.code,
      },
      (response) => {
        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          this._onFailure('invalid code');
          return;
        }

        this._onSuccess({
          avatarURL: responseObject.avatarURL,
          email: responseObject.email,
          fbAccessToken: responseObject.fbAccessToken,
          fbUserID: responseObject.fbUserID,
          name: responseObject.name,
        });
      }
    );
  };
}

import React, { Component } from 'react';

import { MessageSquare } from 'lucide-react';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import translateString from 'common/i18n/translateString';
import plaintext from 'common/markdown/plaintext';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import withContexts from 'common/util/withContexts';

import PostCommentCount from './PostCommentCount';
import PostLink from './PostLink';
import PostStatusV2 from './PostStatusV2';
import PostTitle from './PostTitle';
import PostVotesV2 from './PostVotes/PostVotesV2';
import Truncate from '../common/Truncate';

import 'css/components/post/_PostListItemV2.scss';

class PostListItem extends Component {
  static propTypes = {
    board: PropTypes.object,
    linkToAdmin: PropTypes.bool.isRequired,
    post: PropTypes.shape({
      board: PropTypes.object,
      details: PropTypes.string,
      status: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    showComments: PropTypes.bool.isRequired,
    showDetails: PropTypes.bool.isRequired,
    showStatus: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    showComments: true,
    showDetails: true,
    showStatus: false,
  };

  renderCommentCount() {
    const { post, showComments } = this.props;
    if (!showComments || post.commentCount === null || Number.isNaN(post.commentCount)) {
      return null;
    }

    return <PostCommentCount post={post} />;
  }

  renderDetails() {
    const { post, showDetails } = this.props;
    if (!showDetails || !post.details) {
      return null;
    }

    return (
      <div className="postDetails">
        <Truncate numberOfLines={2}>{plaintext(translateString(post, 'details'))}</Truncate>
      </div>
    );
  }

  renderMetaInfo() {
    const {
      company,
      post: { commentCount, status },
      showStatus,
    } = this.props;

    const statusObject = status?.name
      ? status
      : company.statuses.find((companyStatus) => companyStatus.name === status);
    const isInitialStatus = statusObject.type === PostStatusTypes.Initial;

    return (
      <div className="metaInfo">
        <MessageSquare />
        <span className="count">{commentCount}</span>
        {showStatus && !isInitialStatus ? (
          <>
            <div className="middot">&middot;</div>
            <PostStatusV2 isBadge={true} status={statusObject} />
          </>
        ) : null}
      </div>
    );
  }

  render() {
    const { linkToAdmin, post } = this.props;

    return (
      <div className="postListItemV2">
        <PostLink className="postListItemBody" linkToAdmin={linkToAdmin} post={post}>
          <PostTitle post={post} />
          {this.renderDetails()}
          {this.renderMetaInfo()}
        </PostLink>
        <PostVotesV2 post={post} />
      </div>
    );
  }
}

export default withContexts({ company: CompanyContext })(PostListItem);

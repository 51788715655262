import Data from 'common/Data';
import promisify from 'common/util/promisify';

import type { TPCForFiltersState } from 'common/reducers/tpcForFilters';
import type { Dispatch, GetState } from 'redux-connect';

// Action Types
export const Actions = {
  FetchTPCForFilters: 'canny/third_party_companies/filters/requestAll',
  TPCForFiltersLoaded: 'canny/third_party_companies/filters/loadedAll',
  TPCForFiltersError: 'canny/third_party_companies/filters/error',
};

// urlName: name
export type Names = Record<string, CompanyInfo>;
export type CompanyInfo = {
  urlName: string;
  name: string;
  owner?: boolean;
  memberCount?: number;
};

export type TPCForFiltersAction = Action & {
  companyNames: Names;
  accountOwnerNames: Names;
  timestamp: Date;
  error?: string;
  query?: string;
  type: typeof Actions;
};

const DefaultBaseCompanies: string[] = [];
const DefaultBaseAccountOwner = '';

type FullReduxState = {
  tpcForFilters: TPCForFiltersState;
};

// Actions
function requestTPCForFilters(query?: string) {
  return {
    query,
    timestamp: Date.now(),
    type: Actions.FetchTPCForFilters,
  };
}

function tpcForFiltersLoaded(companyNames: Names, accountOwnerNames: Names) {
  return {
    companyNames,
    accountOwnerNames,
    timestamp: Date.now(),
    type: Actions.TPCForFiltersLoaded,
  };
}

function tpcForFiltersError(error: string) {
  return {
    error,
    timestamp: Date.now(),
    type: Actions.TPCForFiltersError,
  };
}

// Action Creators
function fetchTPCForFilters(
  search: string,
  baseCompanies: string[] = DefaultBaseCompanies,
  baseAccountOwner: string = DefaultBaseAccountOwner
) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const response = await promisify(
        Data.fetch,
        {
          result: {
            input: { search, baseCompanies, baseAccountOwner },
            query: Data.queries.thirdPartyCompaniesForFilters,
          },
        },
        cookies
      );
      return dispatch(
        tpcForFiltersLoaded(response.result.companyNames, response.result.accountOwnerNames)
      );
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return dispatch(tpcForFiltersError(error));
    }
  };
}

export function loadTPCForFilters(baseCompanies: string[], baseAccountOwner: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const query = getQueryKey('', baseCompanies, baseAccountOwner);
    if (shouldFetchTPCForFilters(getState(), query)) {
      dispatch(requestTPCForFilters(query));
      dispatch(fetchTPCForFilters('', baseCompanies, baseAccountOwner));
    }
  };
}

export function reloadTPCForFilters(search: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const query = getQueryKey(search, DefaultBaseCompanies, DefaultBaseAccountOwner);
    if (shouldFetchTPCForFilters(getState(), query)) {
      dispatch(requestTPCForFilters(query));
      return dispatch(fetchTPCForFilters(search));
    }
  };
}

// Helper Functions
function getQueryKey(search: string, baseCompanies: string[], baseAccountOwner: string) {
  return JSON.stringify({ search, baseCompanies, baseAccountOwner });
}

function shouldFetchTPCForFilters(state: FullReduxState, query: string) {
  if (!state.tpcForFilters?.queries[query]) {
    return true;
  }
  if (
    !state.tpcForFilters ||
    !state.tpcForFilters.companyNames ||
    !state.tpcForFilters.accountOwnerNames
  ) {
    return true;
  }
  if (
    [
      ...Object.values(state.tpcForFilters.companyNames),
      ...Object.values(state.tpcForFilters.accountOwnerNames),
    ].length === 0
  ) {
    return true;
  }
  return false;
}

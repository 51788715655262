import { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import withContexts from 'common/util/withContexts';

import { loadBoard } from '../actions/boards';
import asyncConnect from '../core/asyncConnect';
import cloneElementWithProps from '../core/cloneElementWithProps';

export const asyncFetch = {
  promise: ({ store: { dispatch }, params }) => {
    if (!params.boardURLName) {
      return;
    }

    return dispatch(loadBoard(params.boardURLName));
  },
};

class BoardContainer extends Component {
  static propTypes = {
    boardsState: PropTypes.object,
    params: PropTypes.object,
  };

  componentDidMount() {
    this.handleBoardRedirection();
  }

  handleBoardRedirection() {
    const {
      location: { pathname, query },
      params: { boardURLName, postURLName },
      router,
    } = this.props;
    const board = this.getBoardByBoardURLName();

    const postCouldHaveBeenMoved = postURLName && board?.requestedBoardID;

    if (
      !board ||
      ((board.notAuthorized || board.notFound || board.error) && !postCouldHaveBeenMoved)
    ) {
      return;
    }

    if (boardURLName === board.urlName) {
      return;
    }

    const urlPrefixes = ['/admin/feedback/', '/admin/settings/boards/', '/'];

    // if the post could have been moved, we'll leave it to PostContainer to redirect
    if (postCouldHaveBeenMoved) {
      return;
    }

    const updatedPathname = urlPrefixes.reduce((updatedPathname, urlPrefix) => {
      if (updatedPathname) {
        return updatedPathname;
      }

      if (!pathname.startsWith(urlPrefix)) {
        return null;
      }

      return pathname.replace(`${urlPrefix}${boardURLName}`, board.urlName);
    }, null);

    router.replace({ pathname: `/${updatedPathname}`, query });
  }

  getBoardByBoardURLName() {
    const { params, boardsState } = this.props;

    return boardsState.items.hasOwnProperty(params.boardURLName)
      ? boardsState.items[params.boardURLName]
      : undefined;
  }

  render() {
    const newProps = { ...this.props };
    const { children } = newProps;

    const board = this.getBoardByBoardURLName();

    if (board) {
      newProps.board = board;
    }

    return cloneElementWithProps(children, newProps);
  }
}

export default compose(
  asyncConnect([asyncFetch], (state) => ({ boardsState: state.boards })),
  withContexts(
    {
      location: LocationContext,
      params: ParamsContext,
      router: RouterContext,
    },
    {
      forwardRef: true,
    }
  )
)(BoardContainer);

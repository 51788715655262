import React, { useContext, useRef, useState } from 'react';

import { FilePlus2, Merge, Trash } from 'lucide-react';

import Portal from 'common/common/Portal';
import Tooltip from 'common/common/Tooltip';
import { AutopilotActionSourceType, AutopilotActionType } from 'common/constants/autopilotActions';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import MergePostDropdown from 'common/post/MergePostDropdown';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P, Span } from 'common/ui/Text';
import hasPermission from 'common/util/hasPermission';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_CommonActionsCell.module.scss';

import type { ActionRow as Row } from '../types';
import type { Board } from 'common/api/endpoints/boards';
import type { Company } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

type Props = {
  row: Row;
  boards: Board[];
};

enum Modal {
  merge = 'merge',
  delete = 'delete',
}

enum Action {
  create = 'create',
  merge = 'merge',
  delete = 'delete',
}

const ActionLabelMap = {
  [AutopilotActionType.merge]: 'Merged',
  [AutopilotActionType.create]: 'Created',
  [AutopilotActionType.dismiss]: 'Dismissed',
  default: 'Acted',
} as const;

const MissingPermissionMessage = 'You do not have permission to perform this action.';

const getActionDate = (item: Row['item']) => {
  return new Date(item.createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const fetchPermissions = (viewer: Viewer, company: Company) => {
  return {
    voteOnBehalf: hasPermission('voteOnBehalf', company, viewer),
    mergePosts: hasPermission('mergePosts', company, viewer),
    deletePosts: hasPermission('deletePosts', company, viewer),
    deleteComments: hasPermission('deleteComments', company, viewer),
  };
};

const getLabels = (row: Row) => {
  if (row.item.createdPostID) {
    return {
      deleteTooltip: 'Delete post',
      mergeTooltip: 'Merge into existing post instead',
      deleteModalCTA: 'Delete post',
      deleteModalHeader: 'Are you sure you want to delete this post?',
      deleteModalBody:
        'The post will no longer be visible to you or other users in your workspace. This action cannot be reversed.',
    };
  } else if (row.item.actionType === AutopilotActionType.dismiss) {
    return {
      createTooltip: 'Create as new post instead',
      mergeTooltip: 'Merge into existing post instead',
    };
  }

  if (row.item.source === 'canny') {
    return {
      createTooltip: 'Create as new post instead',
      deleteTooltip: 'Undo merge and delete duplicate',
      mergeTooltip: 'Merge into a different post',
      deleteModalCTA: 'Undo merge and delete',
      deleteModalHeader: 'Are you sure you want to undo this merge and delete the duplicate post?',
      deleteModalBody:
        'The posts will be unmerged, the duplicate post will be deleted and will no longer be visible. This action cannot be reversed.',
    };
  }

  return {
    createTooltip: 'Create as new post instead',
    deleteTooltip: 'Undo merge and dismiss with no action',
    mergeTooltip: 'Merge into a different post',
    deleteModalCTA: 'Undo merge and dismiss',
    deleteModalHeader: 'Are you sure you want to undo this merge and dismiss the feedback?',
    deleteModalBody:
      'The posts will be unmerged and the original feedback post will be dismissed. This action cannot be reversed.',
  };
};

const AuditLogActionsCell = ({ row }: Props) => {
  const [loadingAction, setLoadingAction] = useState<Action | null>();
  const [modal, setModal] = useState<Modal | null>(null);

  const mergeRef = useRef<HTMLButtonElement>(null);
  const portalRef = useRef<Portal>(null);

  const viewer = useContext<Viewer>(ViewerContext);
  const company = useContext<Company>(CompanyContext);
  const boards = useContext(BoardsContext);

  const userPermissions = fetchPermissions(viewer, company);

  const canPerformMerge =
    row.item.sourceType === AutopilotActionSourceType.post
      ? userPermissions.mergePosts
      : userPermissions.voteOnBehalf && userPermissions.deleteComments;

  // to perform any action the user must be able to undo the initial action
  let canUndoAction = false;
  if (row.item.createdPostID) {
    canUndoAction = userPermissions.deletePosts;
  } else if (row.item.actionType === 'dismiss') {
    canUndoAction = true;
  } else if (canPerformMerge) {
    canUndoAction = true;
  }

  const { item, actions } = row;

  useBackgroundClick(() => {
    if (modal === Modal.merge) {
      setModal(null);
    }
  }, [portalRef, mergeRef]);

  const performCreate = async () => {
    setLoadingAction(Action.create);
    await actions.create();
    setLoadingAction(null);
  };

  const performDelete = async () => {
    setLoadingAction(Action.delete);
    await actions.delete();
    setLoadingAction(null);
  };

  const openMergeModal = () => {
    setModal(Modal.merge);
  };

  const openDeleteModal = () => {
    setModal(Modal.delete);
  };

  const actionsDisabled = !!loadingAction || !canUndoAction;

  // post is used to a) stop the merge dialog recommending the current post via an _id comparison b) find similar suggestions based off title and details
  let post = undefined;
  if (item.duplicatePost) {
    post = item.duplicatePost;
  } else {
    post = { ...item.sourcePostDraft, _id: item.createdPostID };
  }

  const { mergeTooltip, createTooltip, deleteTooltip, ...modalLabels } = getLabels(row);

  return (
    <div className={styles.autopilotActionsLayout}>
      <Span className={styles.autopilotActionDate}>
        {ActionLabelMap[item.actionType] ?? ActionLabelMap.default} on {getActionDate(item)}
      </Span>
      <div className={styles.queueActions}>
        <Tooltip
          disabled={canUndoAction && canPerformMerge}
          value={MissingPermissionMessage}
          position="left">
          <Tooltip delay={250} value={mergeTooltip} position="left">
            <IconButtonV2
              aria-label={mergeTooltip}
              disabled={actionsDisabled || !canPerformMerge}
              icon={Merge}
              loading={loadingAction === Action.merge}
              onClick={openMergeModal}
              ref={mergeRef}
              size="medium"
              variant="outlined"
            />
          </Tooltip>
        </Tooltip>
        {!item.createdPostID && createTooltip && (
          <Tooltip disabled={canUndoAction} value={MissingPermissionMessage} position="left">
            <Tooltip delay={250} value={createTooltip} position="left">
              <IconButtonV2
                aria-label={createTooltip}
                disabled={actionsDisabled}
                icon={FilePlus2}
                loading={loadingAction === Action.create}
                onClick={performCreate}
                size="medium"
                variant="outlined"
              />
            </Tooltip>
          </Tooltip>
        )}
        {deleteTooltip && (
          <Tooltip disabled={canUndoAction} value={MissingPermissionMessage} position="left">
            <Tooltip delay={250} value={deleteTooltip} position="left">
              <IconButtonV2
                aria-label={deleteTooltip}
                disabled={actionsDisabled}
                icon={Trash}
                loading={loadingAction === Action.delete}
                onClick={openDeleteModal}
                size="medium"
                variant="outlined"
              />
            </Tooltip>
          </Tooltip>
        )}
      </div>
      {modal === Modal.delete && modalLabels.deleteModalCTA && (
        <ModernConfirmModal
          onClose={() => setModal(null)}
          onConfirm={performDelete}
          confirmText={modalLabels.deleteModalCTA}
          type="destructive"
          header={modalLabels.deleteModalHeader}>
          <P>{modalLabels.deleteModalBody}</P>
        </ModernConfirmModal>
      )}
      {modal === Modal.merge && (
        <Portal
          align="end"
          className="modalPostDropdownPortal"
          zIndex={100}
          ref={portalRef}
          relativeToRef={mergeRef}>
          <MergePostDropdown
            autoFocus
            boards={boards}
            placeholder="Search all posts..."
            onPostSelected={actions.merge}
            post={post}
          />
        </Portal>
      )}
    </div>
  );
};

export default AuditLogActionsCell;

import React, { useContext, useEffect } from 'react';

import { type Filter, type InBoardFilter, Resource } from 'common/automations/constants';
import { BoardsContext } from 'common/containers/BoardsContainer';
import SingleSelect from 'common/ui/SingleSelect';
import unique from 'common/util/unique';

interface Props {
  value: InBoardFilter['value'];
  onChange: (condition?: InBoardFilter['condition'], value?: InBoardFilter['value']) => void;
  required: boolean;
  filters: Filter[];
}

const PostCategory = ({ filters, required, ...props }: Props) => {
  const boards = useContext(BoardsContext);

  const boardsLoaded = Array.isArray(boards) && boards.length > 0;

  const boardFilter = filters.find((filter) => filter.resource === Resource.postBoardURLName);
  const selectedBoard =
    boardsLoaded && boardFilter
      ? boards.find((board) => board.urlName === boardFilter.value)
      : null;

  const { value, onChange } = props;
  const boardCategoryNames = boardsLoaded
    ? boards
        .filter((board) => {
          if (selectedBoard) {
            return board.urlName === selectedBoard.urlName;
          }
          return true;
        })
        .map((board) => {
          return board.categories.map((category) => {
            return category.name;
          });
        })
    : [];
  const allCategoryNames = unique(boardCategoryNames.flat()).sort();
  const selectedCategory = allCategoryNames.find((categoryName) => categoryName === value);

  // On init, select the first board
  useEffect(() => {
    if (boardsLoaded && allCategoryNames.length > 0) {
      if (!selectedCategory) {
        onChange('equals', allCategoryNames[0]);
      }
    }
  }, [boardsLoaded, selectedCategory, allCategoryNames, onChange, selectedBoard]);

  if (allCategoryNames.length === 0) {
    return (
      <div className="textCircle filterField1of1">
        No categories exist{selectedBoard ? ' for selected board' : ''}
      </div>
    );
  }

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!boardsLoaded) {
    return <div className="textCircle filterField1of1">No boards exist</div>;
  }

  return (
    <>
      <div className="textCircle filterField1of2">is</div>
      <SingleSelect
        className={required ? 'filterField2of2-required' : 'filterField2of2'}
        placeholder=""
        onChange={(option) => onChange('equals', option?.value)}
        options={allCategoryNames.map((name) => ({ label: name, value: name }))}
        value={selectedCategory ? { label: selectedCategory, value: selectedCategory } : undefined}
      />
    </>
  );
};
export default PostCategory;

import { SET_COOKIES } from '../actions/cookies';

export default function cookies(state = {}, action) {
  switch (action.type) {
    case SET_COOKIES:
      return Object.assign({}, state, {
        ...action.cookies,
      });

    default:
      return state;
  }
}

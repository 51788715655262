import React, { Component } from 'react';

import classnames from 'classnames';
import { CheckCircle2, Slash } from 'lucide-react';

import Colors from 'common/colors/constants';
import sleep from 'common/util/sleep';

import 'css/components/containers/_ToastContainer.scss';

const DefaultYOffset = 20;
const SlideAnimationDuration = 200;
const DelayBetween = 500;
const ToastDuration = 3000;

type Toast = {
  message: string;
  type: ToastTypes;
  yOffset: number;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  delaying: boolean;
  exiting: boolean;
  showing: boolean;
  toasts: Toast[];
};

export enum ToastTypes {
  success = 'success',
  error = 'error',
}

const IconMap: Record<ToastTypes, React.ReactNode> = {
  [ToastTypes.error]: <Slash color={Colors.red90} />,
  [ToastTypes.success]: <CheckCircle2 color={Colors.green100} />,
};

export type ShowToastFn = (
  message: string,
  type?: ToastTypes,
  options?: { yOffset: number }
) => Promise<void>;

export const ShowToastContext = React.createContext<ShowToastFn>(() => Promise.resolve());

const calculateToastStyle = (toast: Toast) => {
  const style: React.CSSProperties = {};

  if (toast.yOffset) {
    style.bottom = `${toast.yOffset}px`;
  }

  return style;
};

export default class ToastContainer extends Component<Props, State> {
  state = {
    delaying: false,
    exiting: false,
    showing: false,
    toasts: [] as Toast[],
  };

  showToast: ShowToastFn = async (message: string, type = ToastTypes.success, options) => {
    this.setState({
      toasts: this.state.toasts.concat({
        message,
        type,
        yOffset: options?.yOffset || DefaultYOffset,
      }),
    });

    const { delaying, showing } = this.state;
    if (delaying || showing) {
      return;
    }

    this.startToast();
  };

  startToast = async () => {
    await Promise.all([
      this.setState({
        showing: true,
      }),
      sleep(SlideAnimationDuration + ToastDuration),
    ]);

    await Promise.all([
      this.setState({
        exiting: true,
      }),
      sleep(SlideAnimationDuration),
    ]);

    await Promise.all([
      this.setState({
        delaying: this.state.toasts.length > 1,
        exiting: false,
        showing: false,
        toasts: this.state.toasts.slice(1),
      }),
      sleep(DelayBetween),
    ]);

    if (!this.state.delaying) {
      return;
    }

    setTimeout(this.startToast, 0);
  };

  renderToast() {
    const { showing } = this.state;
    if (!showing) {
      return null;
    }

    const { exiting, toasts } = this.state;
    const toast = toasts[0];

    const toastStyle = calculateToastStyle(toast);

    return (
      <div className="toastInnerContainer">
        <div className={classnames('toast', toast.type, { exiting })} style={toastStyle}>
          <div className="iconContainer">{IconMap[toast.type]}</div>
          <div className="message">{toast.message}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ShowToastContext.Provider value={this.showToast}>
        <div className="toastContainer">
          {this.props.children}
          {this.renderToast()}
        </div>
      </ShowToastContext.Provider>
    );
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadBoard } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { BoardAccess } from 'common/constants/boards';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { TrackEventContext } from 'common/containers/EventContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import AdminCreateBoardHelmet from 'common/helmets/AdminCreateBoardHelmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import URLNameInput from 'common/inputs/URLNameInput';
import LazyLoadedImage from 'common/LazyLoadedImage';
import AccessModal from 'common/modals/AccessModal';
import Strings from 'common/Strings';
import AdminBoardPrivacyForm from 'common/subdomain/admin/AdminBoardPrivacyForm';
import Tappable from 'common/Tappable';
import hasPermission from 'common/util/hasPermission';
import validateInviteEmails from 'common/util/validateInviteEmails';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import WelcomeImg from 'img/welcome-img.png';

import 'css/components/subdomain/admin/_AdminCreateBoard.scss';

class AdminCreateBoard extends Component {
  static propTypes = {
    company: PropTypes.shape({
      boardCount: PropTypes.number,
      subdomain: PropTypes.string.isRequired,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    trackEvent: PropTypes.func,
    viewer: PropTypes.object,
  };

  state = {
    access: BoardAccess.private,
    allowIdentified: false,
    domain: null,
    email: '',
    error: null,
    indexed: true,
    name: '',
    segment: null,
    showPrivacySettings: true,
    submitting: false,
    unlisted: false,
    verifiedBy: 'email',
  };

  constructor(props, context) {
    super(props, context);

    this.nameRef = React.createRef();
    this.urlNameRef = React.createRef();
  }

  componentDidMount() {
    const { company, openModal, router, trackEvent, viewer } = this.props;
    const { boardCount } = company;
    if (boardCount === 0) {
      trackEvent('Started Trial');
    }

    if (hasPermission('manageBoards', company, viewer)) {
      return;
    }

    router.replace('/admin');
    openModal(
      AccessModal,
      {
        requiredPermissions: ['manageBoards'],
      },
      {
        allowRouteChange: true,
      }
    );
  }

  onNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  onPrivacySettingsChange = (privacySettings) => {
    this.setState(privacySettings);
  };

  onTogglePrivacySettings = () => {
    if (this.state.showPrivacySettings) {
      this.setState({ showPrivacySettings: false });
      return;
    }

    this.setState({
      email: '',
      showPrivacySettings: true,
    });
  };

  canCreate = () => {
    const {
      company: { features },
    } = this.props;
    const { access } = this.state;

    if (access === BoardAccess.private) {
      return features?.privateBoards;
    }

    if (access === BoardAccess.custom) {
      return features?.customAccess;
    }

    return true;
  };

  validateEmails = () => {
    const { validEmails, invalidEmails } = validateInviteEmails(this.state.email);

    if (invalidEmails.length > 0) {
      this.setState({
        error: 'The following emails are invalid: ' + invalidEmails.join(', '),
      });
      return false;
    }

    return validEmails;
  };

  onSubmit = () => {
    var error = null;
    var name = this.nameRef.current.getValue();
    var urlName = this.urlNameRef.current.getValue();

    if (!name && !urlName) {
      name = 'Feature Requests';
      urlName = 'feature-requests';
    }

    if (!name || !validateInput.board.name(name)) {
      error = 'Please enter a valid board name.';
    } else if (!urlName || !validateInput.board.urlName(urlName)) {
      error = 'Please enter a valid board URL.';
    }

    if (error) {
      this.setState({ error });
      return;
    }

    const validEmails = this.validateEmails();
    if (!validEmails) {
      return;
    }

    this.setState({
      error: null,
      submitting: true,
    });

    const { allowIdentified, domain, indexed, segment, unlisted, verifiedBy } = this.state;

    const isPublic = this.state.access === BoardAccess.public;
    const isCustomAccess = this.state.access === BoardAccess.custom;
    AJAX.post(
      '/api/boards/create',
      {
        access: this.state.access,
        indexed,
        name,
        unlisted,
        urlName,
        ...(isPublic && {
          indexed,
          unlisted,
        }),
        ...(isCustomAccess && {
          allowIdentified,
          domain,
          emails: validEmails,
          segmentURLName: segment,
          verifiedBy,
        }),
      },
      async (response) => {
        if (response === 'success') {
          const { loadBoard, reloadCompany, router, trackEvent } = this.props;

          trackEvent('Created Board');

          await Promise.all([loadBoard(urlName), reloadCompany()]);

          router.push(`/admin/feedback?boards=${urlName}`);
          return;
        }

        this.setState({
          submitting: false,
        });

        var responseData;
        try {
          responseData = JSON.parse(response);
        } catch (e) {
          responseData = { error: 'server error' };
        }

        if (responseData.error === 'url taken') {
          this.setState({
            error: 'This URL is taken, please use another.',
          });
        } else {
          this.setState({
            error: Strings.miscError,
          });
        }
      }
    );
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderPrivacySettings() {
    if (!this.state.showPrivacySettings) {
      return null;
    }

    return (
      <div className="privacySettings">
        <AdminBoardPrivacyForm
          access={this.state.access}
          allowIdentified={this.state.allowIdentified}
          domain={this.state.domain}
          indexed={this.state.indexed}
          onChange={this.onPrivacySettingsChange}
          segment={this.state.segment}
          unlisted={this.state.unlisted}
          verifiedBy={this.state.verifiedBy}
        />
      </div>
    );
  }

  renderWelcomeImg() {
    const {
      company: { boardCount },
    } = this.props;
    if (boardCount > 0) {
      return null;
    }

    return <LazyLoadedImage className="welcomeImg" src={WelcomeImg} />;
  }

  renderExamples() {
    return (
      <div className="examplesContainer">
        <div className="examplesHeading">Here are a couple examples:</div>
        <div className="examples">
          <div className="example">
            <LazyLoadedImage src="https://canny.io/images/8cacae02fdad0b0645cb845173069bf5.png" />
            <div className="right">
              <div className="description">
                ClickUp has a board to keep track of{' '}
                <span className="emphasize">feature&nbsp;requests</span> to inform
                their&nbsp;roadmap.
              </div>
            </div>
          </div>
          <div className="example">
            <LazyLoadedImage src="https://canny.io/images/c104e91677ce72f6b937eb7b5afb1e94.png" />
            <div className="right">
              <div className="description">
                Subbly has a board to keep track of <span className="emphasize">integrations</span>{' '}
                their users want to&nbsp;use.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const toggle = this.state.showPrivacySettings ? 'up' : 'down';
    return (
      <Form
        className="card"
        addEventsToDocument={false}
        disableSubmit={this.state.submitting}
        onSubmit={this.onSubmit}>
        <div className="heading">Create a new board</div>
        <div className="explanation">
          A board is a place where people can post and vote on ideas for a specific&nbsp;topic.
        </div>
        <div className="inputs">
          <TextInput
            autoFocus={true}
            defaultValue={this.state.name}
            disabled={this.state.submitting}
            inset="Name"
            onChange={this.onNameChange}
            placeholder="Feature Requests"
            ref={this.nameRef}
            value={this.state.name}
          />
          <URLNameInput
            company={this.props.company}
            defaultValue={this.state.urlName}
            disabled={this.state.submitting}
            input={this.state.name}
            inset="url"
            placeholder="feature-requests"
            ref={this.urlNameRef}
          />
        </div>
        <Tappable onTap={this.onTogglePrivacySettings}>
          <div className="privacySettingToggle">
            Access settings (advanced)
            <div className={`icon icon-chevron-${toggle}`} />
          </div>
        </Tappable>
        {this.renderPrivacySettings()}
        {this.renderError()}
        <div className="buttonContainer">
          <Button
            buttonType="cannyButton"
            disabled={!this.canCreate()}
            loading={this.state.submitting}
            formButton={true}
            value="Create"
          />
        </div>
      </Form>
    );
  }

  render() {
    return (
      <ContentContainer outerClassName="adminCreateBoard">
        <AdminCreateBoardHelmet />
        <div className="left">{this.renderWelcomeImg()}</div>
        <div className="right">
          {this.renderForm()}
          {this.renderExamples()}
        </div>
      </ContentContainer>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadBoard: (boardURLName) => {
      return dispatch(loadBoard(boardURLName));
    },
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      trackEvent: TrackEventContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminCreateBoard);

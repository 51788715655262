import {
  InvalidatePosts,
  PostError,
  PostLoaded,
  PostNotAuthorized,
  PostNotFound,
  PostsLoaded,
  RequestPost,
} from '../actions/posts';

export default function posts(state = {}, action) {
  switch (action.type) {
    case InvalidatePosts: {
      const { posts } = action;
      const boardPosts = {};
      posts.forEach((post) => {
        if (!boardPosts[post.boardID]) {
          boardPosts[post.boardID] = Object.assign({}, state[post.boardID]);
        }
        const postBoard = boardPosts[post.boardID];
        delete postBoard[post.urlName];
      });
      return Object.assign({}, state, boardPosts);
    }

    case RequestPost: {
      const boardPosts = Object.assign({}, state[action.board._id]);
      return Object.assign({}, state, {
        [action.board._id]: Object.assign({}, boardPosts, {
          [action.postURLName]: Object.assign({}, boardPosts[action.postURLName], {
            error: null,
            lastUpdated: action.timestamp,
            loading: true,
            notFound: false,
          }),
        }),
      });
    }

    case PostLoaded: {
      const post = Object.assign(
        {},
        {
          error: null,
          lastUpdated: action.timestamp,
          loading: false,
          notFound: false,
        },
        action.post
      );
      const boardObject = Object.assign({}, state[action.board._id]);
      const postObject = Object.assign({}, boardObject[action.postURLName], post);
      return Object.assign({}, state, {
        [action.board._id]: Object.assign({}, boardObject, {
          [action.postURLName]: postObject,
          [post.urlName]: postObject,
        }),
      });
    }

    case PostsLoaded: {
      const newState = Object.assign({}, state);
      action.posts.forEach((post) => {
        const boardObject = Object.assign({}, newState[post.board._id]);
        const postObject = Object.assign({}, boardObject[post.urlName], post);
        newState[post.board._id] = Object.assign({}, boardObject, {
          [post.urlName]: postObject,
        });
      });
      return newState;
    }

    case PostNotAuthorized: {
      return Object.assign({}, state, {
        [action.board._id]: Object.assign({}, state[action.board._id], {
          [action.postURLName]: Object.assign({}, state[action.board._id][action.postURLName], {
            lastUpdated: action.timestamp,
            loading: false,
            notAuthorized: true,
          }),
        }),
      });
    }

    case PostNotFound: {
      return Object.assign({}, state, {
        [action.board._id]: Object.assign({}, state[action.board._id], {
          [action.postURLName]: Object.assign({}, state[action.board._id][action.postURLName], {
            lastUpdated: action.timestamp,
            loading: false,
            notFound: true,
          }),
        }),
      });
    }

    case PostError: {
      const postsState = state[action.board._id] ?? {};
      return Object.assign({}, state, {
        [action.board._id]: Object.assign({}, postsState, {
          [action.postURLName]: Object.assign({}, postsState[action.postURLName], {
            error: action.error,
            lastUpdated: action.timestamp,
            loading: false,
          }),
        }),
      });
    }

    default:
      return state;
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

class SubdomainJoinCompany extends Component {
  static propTypes = {
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    location: PropTypes.shape({
      query: PropTypes.shape({
        inviteID: PropTypes.string,
      }),
    }),
    router: PropTypes.object,
  };

  componentDidMount() {
    const {
      company,
      location: { query },
      router,
    } = this.props;

    // Case #1: viewer is already a member, redirect
    if (company.viewerIsMember) {
      router.replace('/admin');
      return;
    }

    if (company?.name && company?.subdomain && query?.inviteID) {
      window.location = devURL(
        `https://canny.io/accept-invite?companyName=${company.name}&inviteID=${query.inviteID}&subdomain=${company.subdomain}`
      );
    }
  }

  render() {
    const {
      company,
      location: { query },
    } = this.props;
    if (!company?.name || !company?.subdomain || !query.inviteID) {
      return <div className="error">Something went wrong, please try again later.</div>;
    }

    return null;
  }
}

export default withContexts({
  company: CompanyContext,
})(SubdomainJoinCompany);

import React, { type Ref, forwardRef, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { ChevronDown, ChevronUp, ChevronsUpDown, Info } from 'lucide-react';

import LoadMore from 'common/common/LoadMore';
import Tooltip from 'common/common/Tooltip';
import Spinner from 'common/Spinner';

import 'css/components/_DataTable.scss';

export interface Row {
  id: string;
  className?: string;
  onHover?: (row: Row) => void;
  onClick?: (row: Row) => void;
}

interface Pagination {
  hasMore: boolean;
  loading: boolean;
  onLoadMore: () => void;
}

export interface Column<R extends Row, ID extends keyof R> {
  id: ID;
  align: 'left' | 'right';
  header: React.ReactNode;
  cell: (content: R[ID], row: R) => React.ReactNode;
  getClassName?: (content: R[ID], row: R) => string;
  onClick?: (row: R) => void;
  tooltip?: string;
  fullWidth?: boolean;
  sortable: boolean;
  sticky?: boolean;
}

export type Sort = {
  columnID: string;
  order: 'asc' | 'desc';
};

export interface Props<R extends Row> {
  className?: string;
  columns: Column<R, any>[];
  hoverable?: boolean;
  rows: R[];
  error?: string | null;
  emptyState?: React.ReactNode;
  fullHeight?: boolean;
  height?: number;
  loading?: boolean;
  rounded?: boolean;
  defaultSort?: Sort;
  cellAlignment?: 'top' | 'middle';
  onSort?: (sort: Sort | null) => void;
  padding?: 'none' | 'small' | 'medium';
  pagination?: Pagination;
  showLoadingWithHeaders?: boolean;
}

export const OptionalNumericCell = ({ value }: { value?: number }) => {
  if (typeof value === 'number') {
    return <div className="value">{value}</div>;
  }

  return <div className="empty">—</div>;
};

export const useLocalSort = <R extends Row>(
  rows: R[],
  sortRows: (rows: R[], sort: Sort | null) => R[],
  defaultSort?: Sort
): [R[], (sort: Sort | null) => void, Sort | null] => {
  const [sort, setSort] = useState<Sort | null>(defaultSort || null);

  const onSort = (sort: Sort | null) => {
    if (!sort) {
      return;
    }

    setSort(sort);
  };

  if (sort) {
    const sortedRows = sortRows(rows, sort);
    return [sortedRows, onSort, sort];
  }

  return [rows, onSort, sort];
};

const SortIcons = {
  asc: <ChevronUp strokeWidth={2} size={14} />,
  desc: <ChevronDown strokeWidth={2} size={14} />,
};

function DataTable<R extends Row>(
  {
    className,
    columns,
    hoverable = true,
    pagination,
    rows,
    error,
    emptyState = null,
    fullHeight = false,
    defaultSort,
    height,
    padding = 'none',
    loading,
    showLoadingWithHeaders = false,
    rounded = true,
    onSort,
    cellAlignment = 'middle',
  }: Props<R>,
  ref: Ref<HTMLDivElement>
) {
  const [sort, setSort] = useState<Sort | null>(defaultSort ?? null);

  // LoadMore is not TS so we have to use any
  const loadMoreRef = useRef<any>(null);

  useEffect(() => {
    if (!fullHeight) {
      return;
    }

    if (!ref || !('current' in ref) || !ref.current) {
      return;
    }

    const dataTableContainer = ref.current;

    const onScroll = (e: Event) => {
      loadMoreRef.current?.onScroll?.(e, dataTableContainer);
    };

    dataTableContainer.addEventListener('scroll', onScroll);

    return () => {
      dataTableContainer.removeEventListener('scroll', onScroll);
    };
  }, [fullHeight, ref]);

  if (loading && !showLoadingWithHeaders) {
    return (
      <div
        className={classnames('dataTableContainer', className, { rounded })}
        style={{ maxHeight: height }}>
        <div className="dataTable">
          <div className="dataTableLoading">
            <Spinner />
          </div>
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div
        className={classnames('dataTableContainer', className, { rounded })}
        style={{ maxHeight: height }}>
        <div className="dataTable">
          <div role="alert" className="error">
            {error}
          </div>
        </div>
      </div>
    );
  }

  const setSortForColumn = (column: Column<R, keyof Row>) => {
    // sort goes: desc -> asc -> none
    const columnID = String(column.id);
    const nextSortMap: Record<'asc' | 'desc' | 'none', Sort | null> = {
      asc: null,
      desc: {
        columnID,
        order: 'asc',
      },
      none: {
        columnID,
        order: 'desc',
      },
    };

    const nextSort = nextSortMap[sort?.order ?? 'none'];
    setSort(nextSort);
    onSort?.(nextSort);
  };

  return (
    <div
      className={classnames('dataTableContainer', className, { hoverable, rounded, fullHeight })}
      style={{ maxHeight: height }}
      tabIndex={0}
      ref={ref}>
      <table className="dataTable">
        <thead className="dataTableHeader">
          <tr className="dataTableRow">
            {columns.map((column) => (
              <th
                className={classnames({ sticky: column.sticky })}
                key={`column_${String(column.id)}`}>
                <div
                  className={classnames('content', column.align, { [`padding-${padding}`]: true })}>
                  {column.align === 'left' && <div className="label">{column.header}</div>}
                  {column.sortable && (
                    <div className="sort">
                      <button onClick={() => setSortForColumn(column)}>
                        {sort?.columnID === String(column.id) ? (
                          SortIcons[sort.order]
                        ) : (
                          <ChevronsUpDown strokeWidth={2} size={14} />
                        )}
                      </button>
                    </div>
                  )}
                  {column.align === 'right' && <div className="label">{column.header}</div>}
                  {column.tooltip && (
                    <Tooltip value={column.tooltip}>
                      <div className="info">
                        <Info size={14} strokeWidth={2} color="black" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="dataTableBody">
          {!rows.length && !loading && emptyState}
          {loading && showLoadingWithHeaders && (
            <tr>
              <td colSpan={columns.length}>
                <div className="dataTableLoading">
                  <Spinner />
                </div>
              </td>
            </tr>
          )}
          {!(loading && showLoadingWithHeaders) &&
            rows.map((row) => (
              <tr
                onMouseOver={() => row.onHover?.(row)}
                onClick={() => row.onClick?.(row)}
                className={classnames('dataTableRow', row.className)}
                data-row={row.id}
                key={`row_${row.id}`}>
                {columns.map((column) => (
                  <td
                    onClick={() => column.onClick?.(row)}
                    className={classnames(
                      'dataTableCell',
                      column.getClassName?.(row[column.id as keyof Row], row),
                      cellAlignment,
                      'alignment',
                      {
                        fullWidth: column.fullWidth,
                        sticky: column.sticky,
                      }
                    )}
                    key={`cell_${row.id}_${String(column.id)}`}>
                    <div
                      className={classnames('content', column.align, {
                        [`padding-${padding}`]: true,
                      })}>
                      {column.cell(row[column.id as keyof Row], row)}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {!!(rows.length && pagination) && (
        <LoadMore
          ref={loadMoreRef}
          className="pagination"
          hasMore={pagination.hasMore}
          loadingMore={pagination.loading}
          onLoadMore={pagination.onLoadMore}
        />
      )}
    </div>
  );
}

export default forwardRef(DataTable) as <R extends Row>(
  p: Props<R> & { ref?: Ref<HTMLDivElement> }
) => React.ReactElement; // casting to support generic props

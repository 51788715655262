import React, { useContext } from 'react';

import { FactorType } from 'common/api/endpoints/roadmaps';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import {
  CustomDescriptionFieldTypes,
  DefaultDescriptionFieldTypes,
} from 'common/prioritization/DescriptionColumnTypes';
import { RelativeDateRanges } from 'common/util/dateRanges';
import getDateRangeFromQuery from 'common/util/getDateRangeFromQuery';
import { isNotNil } from 'common/util/isNil';

import DateRangeFilter from './DateRangeFilter';
import SelectFilter from './SelectFilter';
import TextFilter from './TextFilter';
import useCustomQueryParam from './useCustomQueryParam';

import type { Board } from 'common/api/endpoints/boards';
import type { Board as PlainBoard } from 'common/api/resources/board';

type Props = {
  boards: Board[];
  distinctBoards: PlainBoard[];
  fieldID: string | null;
  id: string;
  isCustomDescriptionColumn: boolean;
  name: string;
  options?: string[];
  type?: CustomDescriptionFieldTypes | DefaultDescriptionFieldTypes | keyof typeof FactorType;
};

const isNumericType = (type: string) => {
  return (
    type === CustomDescriptionFieldTypes.integer ||
    type === FactorType.numberToTen ||
    type === FactorType.numberToOneHundred ||
    type === FactorType.percentage ||
    type === FactorType.calculation ||
    type === FactorType.fibonacci ||
    type === FactorType.stars
  );
};

const FilterWrapper = ({
  boards,
  distinctBoards,
  fieldID,
  id,
  isCustomDescriptionColumn,
  name,
  options,
  type,
}: Props) => {
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);

  const { getCustomQueryParam, setCustomQueryParams } = useCustomQueryParam(
    isCustomDescriptionColumn ? 'description' : 'factor'
  );

  if (type === DefaultDescriptionFieldTypes.dateRange) {
    const dateValue = location.query[name]?.split('_') ?? [];
    const dateRange = getDateRangeFromQuery(dateValue);

    return (
      <li key={`${id}_filter`}>
        <DateRangeFilter
          dateRange={dateRange}
          onChange={({ relativeDate, dateRange }) => {
            const newRange = relativeDate
              ? relativeDate !== RelativeDateRanges.allTime
                ? relativeDate
                : null
              : dateRange.join('_');

            const copiedQuery = { ...location.query, [name]: newRange };

            if (!newRange) {
              delete copiedQuery[name];
            }

            router.push({
              pathname: location.pathname,
              query: copiedQuery,
            });
          }}
        />
      </li>
    );
  }

  if (
    type === DefaultDescriptionFieldTypes.multiSelect ||
    type === DefaultDescriptionFieldTypes.singleSelect ||
    type === CustomDescriptionFieldTypes.multiselect ||
    type === CustomDescriptionFieldTypes.dropdown ||
    type === FactorType.checkbox
  ) {
    return (
      <li key={`${id}_filter`}>
        <SelectFilter
          boards={boards}
          distinctBoards={distinctBoards}
          fieldID={fieldID}
          isCustomDescriptionColumn={isCustomDescriptionColumn}
          name={name}
          options={options}
          type={type}
        />
      </li>
    );
  }

  if (
    type &&
    (type === CustomDescriptionFieldTypes.text ||
      type === CustomDescriptionFieldTypes.multilineText ||
      isNumericType(type)) &&
    fieldID
  ) {
    const filter = getCustomQueryParam(fieldID) ?? {
      operator: null,
      value: null,
    };

    // unarraify value from params
    if (Array.isArray(filter.value)) {
      filter.value = filter.value[0];
    }

    return (
      <li key={`${id}_filter`}>
        <TextFilter
          filter={filter}
          inputType={isNumericType(type) ? 'number' : 'text'}
          onChange={(filter) => {
            setCustomQueryParams(fieldID, filter.operator, [filter.value].filter(isNotNil));
          }}
        />
      </li>
    );
  }

  return null;
};

export default FilterWrapper;

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import TextInput from '../inputs/TextInput';
import Months from '../Months';
import Tappable from '../Tappable';
import UppercaseHeader from '../UppercaseHeader';

import 'css/components/common/_DatePicker.scss';

function getToday() {
  return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
}

export default class DatePicker extends Component {
  static propTypes = {
    includeTime: PropTypes.bool,
    onClose: PropTypes.func,
    onDateSelect: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    includeTime: false,
  };

  state = {
    am: true,
    day: null,
    hour: '09',
    minutes: '00',
    month: null,
    year: null,
  };

  componentDidMount() {
    const date = this.props.value || new Date();
    this.setState({
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    });

    setTimeout(() => {
      document.addEventListener('click', this.onClick, false);
    }, 0);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick, false);
  }

  getTimestamp() {
    const { am, day, minutes, month, year } = this.state;
    const hour = Number(this.state.hour);
    const militaryHour = am ? (hour === 12 ? 0 : hour) : hour === 12 ? hour : hour + 12;
    return new Date(year, month, day, militaryHour, minutes);
  }

  onClick = (e) => {
    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    const { onClose } = this.props;
    onClose && onClose();
  };

  onDaySelected = (day) => {
    this.setState({
      day,
    });
    this.props.onDateSelect &&
      this.props.onDateSelect(new Date(this.state.year, this.state.month, day));
  };

  onDecrementMonth = () => {
    const { value } = this.props;
    const { month, year } = this.state;
    const currentDate = value || getToday();
    const newYear = month === 0 ? year - 1 : year;
    const newMonth = month === 0 ? 11 : month - 1;
    this.setState({
      month: newMonth,
      year: newYear,
    });

    if (currentDate.getMonth() === newMonth && currentDate.getFullYear() === newYear) {
      this.setState({ day: currentDate.getDate() });
    } else {
      this.setState({
        day: null,
      });
    }
  };

  onHoursChange = (e) => {
    const { value } = e.target;
    this.setState({
      hour: value.replace(/[^0-9]/g, '').substr(0, 2),
    });
  };

  onIncrementMonth = () => {
    const { value } = this.props;
    const { month, year } = this.state;
    const currentDate = value || getToday();
    const newYear = month === 11 ? year + 1 : year;
    const newMonth = month === 11 ? 0 : month + 1;
    this.setState({
      month: newMonth,
      year: newYear,
    });

    if (currentDate.getMonth() === newMonth && currentDate.getFullYear() === newYear) {
      this.setState({ day: currentDate.getDate() });
    } else {
      this.setState({
        day: null,
      });
    }
  };

  onMinutesChange = (e) => {
    const { value } = e.target;
    this.setState({
      minutes: value.replace(/[^0-9]/g, '').substr(0, 2),
    });
  };

  onSubmit = () => {
    const timestamp = this.getTimestamp();
    const { onSubmit } = this.props;
    onSubmit(timestamp);
  };

  onToggleAMPM = () => {
    const { am } = this.state;
    this.setState({
      am: !am,
    });
  };

  renderAMPMToggle() {
    const { am } = this.state;
    return (
      <Tappable onTap={this.onToggleAMPM}>
        <div className="ampm">{am ? 'AM' : 'PM'}</div>
      </Tappable>
    );
  }

  renderDates() {
    const { day, month, year } = this.state;

    const monthStart = new Date(year, month, '01');
    const monthStartDay = monthStart.getDay();
    const currentDay = new Date(monthStart);
    currentDay.setDate(1 - monthStartDay);
    const items = [];

    let key = 0;
    while (true) {
      if (currentDay.getFullYear() > year) {
        break;
      } else if (currentDay.getFullYear() === year) {
        if (currentDay.getMonth() > month) {
          break;
        }
      }

      if (currentDay.getMonth() !== month) {
        items.push(<div className="blank" key={key++} />);
      } else {
        const date = currentDay.getDate();
        items.push(
          <Tappable key={key++} onTap={this.onDaySelected.bind(this, date)}>
            <div
              className={classnames({
                day: true,
                selected: date === day,
              })}>
              {date}
            </div>
          </Tappable>
        );
      }

      currentDay.setDate(currentDay.getDate() + 1);
    }

    return <div className="dates">{items}</div>;
  }

  renderFooter() {
    const { hour, minutes } = this.state;
    const timeZone = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];
    return (
      <div className="footer">
        <div className="left">
          <TextInput onChange={this.onHoursChange} value={String(hour)} />
          <div className="colon">:</div>
          <TextInput onChange={this.onMinutesChange} value={String(minutes)} />
          {this.renderAMPMToggle()}
          <UppercaseHeader>{timeZone}</UppercaseHeader>
        </div>
        {this.renderSubmitButton()}
      </div>
    );
  }

  renderHeading() {
    const { month, year } = this.state;

    return (
      <div className="heading">
        <Tappable onTap={this.onDecrementMonth}>
          <div className="icon icon-chevron-left" />
        </Tappable>
        <div className="month">{Months[month] + ' ' + year}</div>
        <Tappable onTap={this.onIncrementMonth}>
          <div className="icon icon-chevron-right" />
        </Tappable>
      </div>
    );
  }

  renderSubmitButton() {
    const { day, hour, minutes } = this.state;
    if (!day || !hour || !minutes) {
      return null;
    } else if (hour < 1 || hour > 12) {
      return null;
    } else if (minutes > 59) {
      return null;
    }

    const now = new Date();
    const timestamp = this.getTimestamp();
    if (timestamp < now) {
      return (
        <Tappable onTap={this.onSubmit}>
          <div className="submitButton">Publish</div>
        </Tappable>
      );
    } else {
      return (
        <Tappable onTap={this.onSubmit}>
          <div className="submitButton">Schedule</div>
        </Tappable>
      );
    }
  }

  renderWeekdays() {
    return (
      <div className="weekdays">
        <div className="weekday">Su</div>
        <div className="weekday">Mo</div>
        <div className="weekday">Tu</div>
        <div className="weekday">We</div>
        <div className="weekday">Th</div>
        <div className="weekday">Fr</div>
        <div className="weekday">Sa</div>
      </div>
    );
  }

  render() {
    return (
      <div className="datePicker">
        {this.renderHeading()}
        {this.renderWeekdays()}
        {this.renderDates()}
        {this.props.includeTime && this.renderFooter()}
      </div>
    );
  }
}

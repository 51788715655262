import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import CheckboxInput from 'common/inputs/CheckboxInput';
import TextInput from 'common/inputs/TextInput';

import 'css/components/inputs/_CheckboxList.scss';

const CheckboxList = ({ className, fields, onChange, selectedFields }) => {
  const [search, setSearch] = useState('');

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const onFieldToggle = (field) => {
    // Const selectedFields could have a value that no longer exists in fields. Next time the user
    // toggles an input, remove any fields that are no longer in the fields array.
    const existingSelectedFields = selectedFields.filter((field) =>
      fields.some((f) => f.name === field)
    );

    const existingFieldIndex = existingSelectedFields.findIndex((el) => el === field.name);
    if (existingFieldIndex === -1) {
      // Add the element to the array
      onChange([...existingSelectedFields, field.name].sort());
    } else {
      // Remove the element from the array
      onChange([
        ...existingSelectedFields.slice(0, existingFieldIndex),
        ...existingSelectedFields.slice(existingFieldIndex + 1),
      ]);
    }
  };

  return (
    <div className={classNames('checkboxList', className)}>
      <div className="search">
        <TextInput className="searchInput" onChange={onSearchChange} placeholder="Search..." />
      </div>
      <div className="fields">
        {fields
          .filter((field) => {
            return (
              field.name.toLowerCase().includes(search.toLowerCase()) ||
              field.label.toLowerCase().includes(search.toLowerCase())
            );
          })
          .map((field) => (
            <div className="field" key={field.name}>
              <CheckboxInput
                defaultChecked={selectedFields.includes(field.name)}
                label={
                  <>
                    {field.label}
                    <span className="fieldName">({field.name})</span>
                  </>
                }
                onChange={() => onFieldToggle(field)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

CheckboxList.defaultProps = {
  selectedFields: [],
};

CheckboxList.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

export default CheckboxList;

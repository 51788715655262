import React from 'react';

import Card from 'common/common/Card';
import Link from 'common/Link';
import ButtonV2 from 'common/ui/ButtonV2';
import { H6, P } from 'common/ui/Text';
import abbreviateNumber from 'common/util/abbreviateNumber';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AutopilotUsageCard.module.scss';

import type { Company } from 'common/api/endpoints/companies';

const AutopilotUsageCard = ({
  company,
  showPercentage = true,
}: {
  company: Company;
  showPercentage?: boolean;
}) => {
  const { autopilot } = company;
  const { creditLimit, usedCredits } = autopilot;

  let progress;
  if (!creditLimit || usedCredits > creditLimit) {
    progress = 1;
  } else {
    progress = usedCredits / creditLimit;
  }

  const percentage = Math.floor(progress * 100);
  const abbrevUsed = abbreviateNumber(usedCredits);
  const abbrevLimit = abbreviateNumber(creditLimit);
  return (
    <Card borderStyle="solid" className={styles.autopilotUsageCard}>
      <H6 className={styles.autopilotUsageHeader}>Autopilot Usage</H6>
      <P className={styles.autopilotUsageText} variant="bodySm">
        {abbrevUsed} of {abbrevLimit} credits used {showPercentage ? `${percentage}%` : null}
      </P>
      <div className={styles.progressBar}>
        <div className={styles.progress} style={{ width: `${percentage}%` }} />
      </div>
      <Link to="/admin/settings/billing/autopilot">
        <ButtonV2 className={styles.autopilotUsageButton} size="medium" variant="outlined">
          Purchase Credits
        </ButtonV2>
      </Link>
    </Card>
  );
};

export default AutopilotUsageCard;

import React from 'react';

import translateString from 'common/i18n/translateString';
import capitalizeWords from 'common/util/capitalizeWords';
import getFirstName from 'common/util/getFirstName';

function getStatus(post) {
  const status = translateString(post, 'status');
  return capitalizeWords(status);
}

export default {
  'commentCreated.boardMember': ({ notification }) => (
    <span>
      {getFirstName(notification.comment.author.name)}
      {' commented on a post in '}
      {translateString(notification.comment.post.board, 'name')}
      {', '}
      {translateString(notification.comment.post, 'title')}
    </span>
  ),

  'commentCreated.otherCommenter': ({ notification }) => (
    <span>
      {getFirstName(notification.comment.author.name)}
      {' also commented on a post, '}
      {translateString(notification.comment.post, 'title')}
    </span>
  ),

  'commentCreated.parentAuthor': ({ notification }) => (
    <span>
      {getFirstName(notification.comment.author.name)}
      {' replied to your comment on '}
      {translateString(notification.comment.post, 'title')}
    </span>
  ),

  'commentCreated.postAuthor': ({ notification }) => (
    <span>
      {getFirstName(notification.comment.author.name)}
      {' commented on your post, '}
      {translateString(notification.comment.post, 'title')}
    </span>
  ),

  'postCreated.boardMember': ({ notification }) =>
    notification.post.author ? (
      <span>
        {getFirstName(notification.post.author.name)}
        {' created a post in '}
        {translateString(notification.post.board, 'name')}
        {', '}
        {translateString(notification.post, 'title')}
      </span>
    ) : (
      <span>
        {'A new post was created in '}
        {translateString(notification.post.board, 'name')}
        {', '}
        {translateString(notification.post, 'title')}
      </span>
    ),

  'postMerged.postAuthor': ({ notification }) => (
    <span>
      {'Your post has been merged into '}
      {translateString(notification.post, 'title')}
    </span>
  ),

  'postOwnerChanged.oldPostOwner': ({ notification }) => (
    <span>
      {getFirstName(notification.post.ownerAssigner.name)}
      {' changed the owner of '}
      {translateString(notification.post, 'title')}
      {' from you to '}
      {getFirstName(notification.post.owner.name)}
    </span>
  ),

  'postOwnerChanged.postOwner': ({ notification }) => (
    <span>
      {getFirstName(notification.post.ownerAssigner.name)}
      {' made you the owner of '}
      {translateString(notification.post, 'title')}
    </span>
  ),

  'postOwnerCleared.oldPostOwner': ({ notification }) => (
    <span>
      {getFirstName(notification.post.ownerAssigner.name)}
      {' removed you as the owner of '}
      {translateString(notification.post, 'title')}
    </span>
  ),

  'postStatusChanged.boardMember': ({ notification }) => (
    <span>
      {notification.post.title}
      {' in '}
      {translateString(notification.post.board, 'name')}
      {' has been marked as '}
      {getStatus(notification.post)}
    </span>
  ),

  'postStatusChanged.commenter': ({ notification }) => (
    <span>
      {'A post you commented on, '}
      {translateString(notification.post, 'title')}
      {', has been marked as '}
      {getStatus(notification.post)}
    </span>
  ),

  'postStatusChanged.postAuthor': ({ notification }) => (
    <span>
      {'Your post, '}
      {translateString(notification.post, 'title')}
      {', has been marked as '}
      {getStatus(notification.post)}
    </span>
  ),

  'postStatusChanged.voter': ({ notification }) => (
    <span>
      {'A post you voted on, '}
      {translateString(notification.post, 'title')}
      {', has been marked as '}
      {getStatus(notification.post)}
    </span>
  ),

  'userMentioned.mentionedUser': ({ notification }) => (
    <span>
      {getFirstName(notification.comment.author.name)}
      {' mentioned you in '}
      {translateString(notification.comment.post, 'title')}
    </span>
  ),

  'voteMilestone.boardMember': ({ notification }) => (
    <span>
      {notification.voteMilestone}
      {' people voted on a post in '}
      {translateString(notification.post.board, 'name')}
      {', '}
      {translateString(notification.post, 'title')}
    </span>
  ),

  'voteMilestone.postAuthor': ({ notification }) => (
    <span>
      {notification.voteMilestone}
      {' people voted on a post, '}
      {translateString(notification.post, 'title')}
    </span>
  ),
};

import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { TLDVInstallation } from 'common/api/endpoints/tldv';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: tldvSettingsRequested,
  loadedAction: tldvSettingsLoaded,
  errorAction: tldvSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<TLDVInstallation>('tldvSettings');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchTLDVSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          tldvSettings: {
            query: Data.queries.tldvSettings,
          },
        },
        cookies
      );
      return dispatch(tldvSettingsLoaded(response.tldvSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(tldvSettingsError(message));
    }
  };
}

export function loadTLDVSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(tldvSettingsRequested());
    return dispatch(fetchTLDVSettings());
  };
}

export function reloadTLDVSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchTLDVSettings());
  };
}

function shouldFetch(state: State) {
  return !state.tldvSettings.data;
}

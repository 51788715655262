import promisify from 'common/util/promisify';

import { loadCompany } from './company';
import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { AsanaSettings } from 'common/api/endpoints/asanaSettings';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: asanaSettingsRequested,
  loadedAction: asanaSettingsLoaded,
  errorAction: asanaSettingsError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<AsanaSettings>('asanaSettings');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchAsanaSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          asanaSettings: {
            query: Data.queries.asanaSettings,
          },
        },
        cookies
      );
      return dispatch(asanaSettingsLoaded(response.asanaSettings));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(asanaSettingsError(message));
    }
  };
}

export function loadAsanaSettings() {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadCompany());

    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(asanaSettingsRequested());
    return dispatch(fetchAsanaSettings());
  };
}

export function reloadAsanaSettings() {
  return (dispatch: Dispatch) => {
    return dispatch(fetchAsanaSettings());
  };
}

function shouldFetch(state: State) {
  return !state.asanaSettings.data;
}

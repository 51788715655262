import { QueueItemType } from 'common/api/endpoints/queue';

import type { Counts, InboxCannyPostItem, InboxDraftItem, QueueInboxItem } from './types';
import type { Company } from 'common/api/endpoints/companies';
import type { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';

export enum CTAs {
  resetFilters = 'resetFilters',
  enableSource = 'enableSource',
  connectSource = 'connectSource',
  enableSources = 'enableSources',
  connectAnotherSource = 'connectAnotherSource',
  redeemCreditGift = 'redeemCreditGift',
  purchaseCredits = 'purchaseCredits',
}

const calculateHasOtherItems = (source: string | undefined, counts: Counts) => {
  if (!source) {
    return counts.totalCount > 0;
  }

  if (source === 'canny') {
    return counts.cannyCount > 0;
  }

  if (counts.perSourceCounts[source as SyncIntegrationNames]) {
    return true;
  }

  return false;
};

export const isPostDraft = (item: QueueInboxItem): item is InboxDraftItem =>
  item.type === QueueItemType.draft;

export const isCannyPost = (item: QueueInboxItem): item is InboxCannyPostItem =>
  item.type === QueueItemType.post;

export const getCTA = (company: Company, counts: Counts, source: string | undefined) => {
  const { autopilot, installedSyncIntegrations, stats } = company;

  const hasOtherItems = calculateHasOtherItems(source, counts);
  if (hasOtherItems) {
    return CTAs.resetFilters;
  }

  const notCanny = source && source !== 'canny';
  const sourceDisabled = installedSyncIntegrations.find(
    ({ integrationName }) => integrationName === source
  )?.disabled;
  if (notCanny && sourceDisabled) {
    return CTAs.enableSource;
  }

  const noSourcesInstalled = installedSyncIntegrations.length === 0;
  if (noSourcesInstalled) {
    return CTAs.connectSource;
  }

  const enabledSources = installedSyncIntegrations.filter((integration) => !integration.disabled);
  if (enabledSources.length === 0) {
    return CTAs.enableSources;
  }

  const hasCredits = autopilot?.usedCredits < autopilot?.creditLimit;
  if (hasCredits) {
    return CTAs.connectAnotherSource;
  }

  const noPostDrafts = !stats?.firstPostDraftID;
  if (!autopilot?.creditGiftRedeemed && noPostDrafts) {
    return CTAs.redeemCreditGift;
  }

  return CTAs.purchaseCredits;
};

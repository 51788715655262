import {
  AzureDevopsSettingsError,
  AzureDevopsSettingsLoaded,
  RequestAzureDevopsSettings,
} from 'common/actions/azureDevopsSettings';

export default function azureDevopsSettings(state = null, action) {
  switch (action.type) {
    case AzureDevopsSettingsError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    case AzureDevopsSettingsLoaded: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: false,
        ...action.azureDevopsSettings,
      });
    }

    case RequestAzureDevopsSettings: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
      });
    }

    default:
      return state;
  }
}

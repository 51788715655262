import type { Translation } from 'common/api/resources/i18n';
import type { StringFieldKeys } from 'common/types/util';

export type TranslationObject<P extends object> = P & {
  translation?: Translation<P>;
};

export function hasTranslation<P extends object>(
  object: TranslationObject<P>,
  stringKeys: StringFieldKeys<P>[]
) {
  return !!stringKeys.find((stringKey) => {
    const translatedString = object?.translation?.strings?.[stringKey];
    return typeof translatedString === 'string';
  });
}

export default function translateString<P extends object>(
  object: TranslationObject<P>,
  stringKey: StringFieldKeys<P>,
  hideTranslation?: boolean
) {
  const originalString = object[stringKey];
  const translatedString = object?.translation?.strings?.[stringKey];

  if (hideTranslation) {
    return originalString;
  }
  return translatedString ?? originalString;
}

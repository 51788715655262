import React, { useContext } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import AdminIntegrationLinkCard from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationCard/AdminIntegrationLinkCard';
import AdminIntegrationToggleCard from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationCard/AdminIntegrationToggleCard';
import { getEnabledSyncIntegrationMap } from 'common/subdomain/admin/AdminIntegrationSettings/utils';
import {
  type Integration,
  SourceType,
} from 'common/subdomain/admin/AdminQueue/GettingStarted/constants';
import { H3, P } from 'common/ui/Text';
import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationSettingsSections.module.scss';

import type { Company } from 'common/api/endpoints/companies';
import type { Upsell } from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import type { IntegrationLinkCard } from 'common/subdomain/admin/AdminIntegrationSettings/constants';

export type IntegrationSection = {
  sectionTitle?: React.ReactNode;
  sectionDescription?: string;
  integrations: (IntegrationLinkCard | Integration)[];
};

type Props = {
  sections: IntegrationSection[];
  currentIntegration: Integration | null;
  loading: boolean;
  startModalFlow: (integration: Integration) => void;
  openUpsell: (upsell: Upsell) => void;
};

const AdminIntegrationSettingsSections = ({
  sections,
  currentIntegration,
  loading,
  startModalFlow,
  openUpsell,
}: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const enabledSyncIntegrations = getEnabledSyncIntegrationMap(company);

  // render
  return (
    <>
      {sections.map(({ sectionTitle, sectionDescription, integrations }, index) => {
        // Sort-in-place isn't ideal, but isn't broken
        integrations.sort((a, b) => a.label.localeCompare(b.label));
        return (
          <section key={`section-${sectionTitle || index}`}>
            {!!sectionTitle && (
              <H3 variant="headingSm" className={styles.sectionTitle}>
                {sectionTitle}
              </H3>
            )}
            {!!sectionDescription && (
              <P className={styles.sectionDescription}>{nbspLastSpace(sectionDescription)}</P>
            )}
            <ul className={styles.list}>
              {integrations.map((integration) => {
                const { label, type } = integration;
                if (type) {
                  const { name, type } = integration;
                  // Data Sync Sources with a settings page
                  if (type === SourceType.integration) {
                    return (
                      <li className={styles.item} key={`integration-${label}`}>
                        <AdminIntegrationLinkCard
                          integration={{
                            url: integration.url,
                            label,
                            icon: integration.icon,
                            credits: integration.credits,
                            external: integration.url.startsWith('https://'),
                          }}
                        />
                      </li>
                    );
                  }

                  if (type === SourceType.zapier) {
                    return (
                      <li className={styles.item} key={`integration-${label}`}>
                        <AdminIntegrationLinkCard
                          integration={{
                            url: integration.url,
                            label,
                            icon: integration.icon,
                            credits: integration.credits,
                            external: true,
                            zapier: true,
                          }}
                        />
                      </li>
                    );
                  }
                  // Review Data Sync Sources
                  const enabled = enabledSyncIntegrations[name];
                  return (
                    <li className={styles.item} key={`integration-${label}`}>
                      <AdminIntegrationToggleCard
                        openIntegrationModal={() => startModalFlow(integration)}
                        integration={integration}
                        enabled={enabled}
                        loading={currentIntegration === integration && loading}
                      />
                    </li>
                  );
                }
                // Non-Data Sync Sources
                const upsell = integration.upsell ? integration.upsell(company) : null;
                return (
                  <li className={styles.item} key={`integration-${label}`}>
                    <AdminIntegrationLinkCard
                      integration={{
                        ...integration,
                        ...(upsell
                          ? {
                              onClick: () => {
                                openUpsell(upsell);
                              },
                            }
                          : {}),
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </section>
        );
      })}
    </>
  );
};

export default AdminIntegrationSettingsSections;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AJAX from './AJAX';
import Button from './inputs/Button';
import TextInput from './inputs/TextInput';
import Tappable from './Tappable';
import validateInput from './validateInput';

import 'css/components/_ForgotPasswordForm.scss';

export default class ForgotPasswordForm extends Component {
  static propTypes = {
    onBackClicked: PropTypes.func.isRequired,
    onCloseClicked: PropTypes.func.isRequired,
  };

  state = {
    error: null,
    submitting: false,
    success: false,
  };

  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
  }

  onCloseClicked = (e) => {
    e.preventDefault();

    this.props.onCloseClicked();
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.submitting) {
      return;
    }

    const email = this.emailRef.current.getValue();

    if (!email || !validateInput.email(email)) {
      this.setState({
        error: 'Please enter a valid email',
      });
      return;
    }

    AJAX.post(
      '/api/viewer/forgotPassword',
      {
        email: email,
      },
      this.onForgotPasswordResponse
    );

    this.setState({
      error: null,
      submitting: true,
    });
  };

  onForgotPasswordResponse = (response) => {
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'no such email': 'There is no account associated with that email, try again',
        'slow down':
          'You are trying to reset your password too much. Please wait a few minutes before trying again.',
      },
    });

    if (!error) {
      this.setState({
        submitting: false,
        success: true,
      });
      return;
    }

    this.setState({
      submitting: false,
      error: error.message,
    });
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    if (this.state.success) {
      return (
        <div className="forgotPassword">
          <h1>Recover your account</h1>
          <div className="bodyText">
            Success! You should receive an email with instructions on how to reset your password.
          </div>
          <form onSubmit={this.onCloseClicked}>
            <Button formButton={true} loading={this.state.submitting} value="OK" />
          </form>
        </div>
      );
    }

    return (
      <div className="forgotPassword">
        <h1>Recover your account</h1>
        <div className="bodyText">
          Enter the email associated with your account. We will send you an email to reset your
          password.
        </div>
        <form onSubmit={this.onSubmit}>
          <TextInput ref={this.emailRef} placeholder="Email" />
          {this.renderError()}
          <div className="buttons">
            <Tappable onTap={this.props.onBackClicked}>
              <span className="greyLink">Cancel</span>
            </Tappable>
            <Button
              formButton={true}
              loading={this.state.submitting}
              tint={true}
              value="Reset Password"
            />
          </div>
        </form>
      </div>
    );
  }
}

import React, { useContext, useEffect, useRef, useState } from 'react';

import { ChevronDown, Filter, Rss, X } from 'lucide-react';

import ChangelogEntryList from 'common/changelog/ChangelogEntryList';
import { Types, isTypeKey } from 'common/changelog/constants';
import Accordion from 'common/common/Accordion';
import Popover from 'common/common/Popover';
import Portal from 'common/common/Portal';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import translateString from 'common/i18n/translateString';
import SearchInput from 'common/inputs/SearchInput';
import SubdomainChangelogHelmet from 'common/subdomain/public/SubdomainChangelogHelmet';
import SubdomainChangelogSubscriptionButton from 'common/subdomain/public/SubdomainChangelogSubscriptionButton';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { H1, P, Span } from 'common/ui/Text';
import decodeQueryTextParam from 'common/util/decodeQueryTextParam';
import queryString from 'common/util/queryString';
import useDelayer from 'common/util/useDelayer';

import type { Changelog, ChangelogEntry } from 'common/api/resources/changelog';
import type { Option } from 'common/ui/common/select/SelectCommon';

import 'css/components/subdomain/public/_SubdomainChangelog.scss';

const decodeLabelURLNames = (labelsParam?: string) => {
  return labelsParam ? labelsParam.split('_').filter(Boolean) : [];
};

const encodeLabelURLNames = (labelURLNames: string[]) => {
  return labelURLNames.length > 0 ? labelURLNames.join('_') : undefined;
};

type Props = {
  changelog: Changelog | null;
  // TODO: Move type to ChangelogEntryListContainer once that's moved to typescript
  changelogEntryList: {
    loading: boolean;
    error: boolean;
    notFound: boolean;
    entries: ChangelogEntry[] | undefined;
  };
};

const SubdomainChangelog = (props: Props) => {
  const { changelog, changelogEntryList } = props;
  const [open, setFiltersMenu] = useState<boolean>(false);

  const searchInputRef = useRef<SearchInput>(null);
  const portalRef = useRef(null);
  const buttonRef = useRef(null);

  const company = useContext(CompanyContext);
  const location = useContext(LocationContext);
  const router = useContext(RouterContext);

  const { query } = location;
  const filteredLabelURLNames = new Set(decodeLabelURLNames(query.labels));
  const filteredType = query.type;

  useBackgroundClick(() => setFiltersMenu(false), [portalRef, buttonRef]);

  const onSearchInputChangeAfterDelay = (search: string) => {
    router.replace({
      ...location,
      query: {
        ...location.query,
        search: search ? encodeURIComponent(search) : undefined,
      },
    });
  };

  const searchDelayer = useDelayer(onSearchInputChangeAfterDelay, 250);

  useEffect(() => {
    if (!searchInputRef.current) {
      return;
    }

    searchInputRef.current.setValue(decodeQueryTextParam(location.query.search));
  }, [location.query.search]);

  if (!changelog) {
    return null;
  }

  const typeOptions = Object.values(Types).map((type) => ({
    label: type.render,
    value: type.name,
    flair: type.color,
    selected: filteredType ? filteredType === type.name : type.name === 'all',
  }));

  const labelOptions = changelog.labels.map((label) => ({
    label: translateString(label, 'name'),
    value: label.urlName,
    selected: filteredLabelURLNames.has(label.urlName),
  }));

  const onTypeSelected = (typeOption?: Option) => {
    if (!typeOption) {
      return router.replace({
        ...location,
        query: {
          ...location.query,
          type: undefined,
        },
      });
    }

    router.replace({
      ...location,
      query: {
        ...location.query,
        type: typeOption.value === 'all' ? undefined : typeOption.value,
      },
    });
  };

  const onToggleLabel = (labelOption: Option) => {
    if (filteredLabelURLNames.has(labelOption.value)) {
      filteredLabelURLNames.delete(labelOption.value);
    } else {
      filteredLabelURLNames.add(labelOption.value);
    }

    router.replace({
      ...location,
      query: {
        ...query,
        labels: encodeLabelURLNames(Array.from(filteredLabelURLNames)),
      },
    });
  };

  const getRSSLink = () => {
    const params = {
      ...(filteredLabelURLNames.size && { labels: Array.from(filteredLabelURLNames).join(',') }),
      ...(isTypeKey(filteredType) && filteredType !== Types.all.name && { types: filteredType }),
    };

    const link = `/api/changelog/feed.rss${queryString.stringify(params)}`;
    return link;
  };

  const numberOfFiltersApplied = [...typeOptions, ...labelOptions].filter(
    (option) => option.selected && option.value !== 'all'
  ).length;

  const areSubscriptionsEnabled = changelog.enableEmailSubscriptions;
  const areSubscriptionsSupported = company.features.changelogEmailSubscriptions;
  return (
    <ContentContainer innerClassName="subdomainChangelog">
      <SubdomainChangelogHelmet entryList={changelogEntryList} />
      <header className="subdomainChangelogHeader">
        <div className="headerTitle">
          <H1>Changelog</H1>
          <div className="headerSubtitle">
            <P variant="bodyMd">Follow up on the latest improvements and&nbsp;updates.</P>
            <div className="verticalDivider" />
            <a href={getRSSLink()} className="RSS">
              <Rss size={16} />
              <P>RSS</P>
            </a>
            {areSubscriptionsEnabled && areSubscriptionsSupported && (
              <>
                <div className="verticalDivider" />
                <SubdomainChangelogSubscriptionButton />
              </>
            )}
          </div>
        </div>
        <div className="headerFilters">
          <SearchInput
            stopPropagation={false}
            placeholder="Search Entries..."
            onChange={(searchQuery: string) => searchDelayer(searchQuery)}
            defaultValue={decodeQueryTextParam(location.query.search)}
            showClearIcon={false}
            autoFocus={false}
            ref={searchInputRef}
            styling="v2"
          />
          <ButtonV2
            ref={buttonRef}
            startIcon={Filter}
            variant="outlined"
            size="medium"
            onClick={() => setFiltersMenu(!open)}
            endIcon={ChevronDown}>
            Filters&nbsp;
            {numberOfFiltersApplied ? (
              <Span className="filterCount">({numberOfFiltersApplied})</Span>
            ) : (
              ''
            )}
          </ButtonV2>
        </div>
        {open && (
          <Portal
            align="end"
            ref={portalRef}
            className="filtersPopoverContainer"
            relativeToRef={buttonRef}>
            <Popover
              title="Filters"
              cta={
                <IconButtonV2
                  size="medium"
                  variant="plain"
                  aria-label="Close popover"
                  icon={X}
                  onClick={() => setFiltersMenu(false)}
                />
              }>
              <div className="accordions">
                <Accordion className="filterAccordion" title="Type">
                  <div className="typeGroup">
                    {typeOptions.map((typeOption) => (
                      <ButtonV2
                        key={typeOption.value}
                        onClick={() => onTypeSelected(typeOption)}
                        size="small"
                        variant={typeOption.selected ? 'toggleOn' : 'toggleOff'}>
                        {typeOption.label}
                      </ButtonV2>
                    ))}
                  </div>
                </Accordion>
                {labelOptions.length ? (
                  <Accordion className="filterAccordion" title="Labels">
                    <div className="labelGroup">
                      {labelOptions.map((labelOption) => (
                        <ButtonV2
                          key={labelOption.value}
                          onClick={() => onToggleLabel(labelOption)}
                          size="small"
                          variant={labelOption.selected ? 'toggleOn' : 'toggleOff'}>
                          {labelOption.label}
                        </ButtonV2>
                      ))}
                    </div>
                  </Accordion>
                ) : null}
              </div>
            </Popover>
          </Portal>
        )}
      </header>
      <div className="body">
        <ChangelogEntryList
          changelog={changelog}
          entryList={changelogEntryList}
          logView={true}
          showEditLinks={false}
          showUnpublished={false}
        />
      </div>
    </ContentContainer>
  );
};

export default SubdomainChangelog;

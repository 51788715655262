import React, { useContext, useState } from 'react';

import SentrySDK from 'common/3rd/SentrySDK';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import { ChurnReasons } from 'common/growth/ChurnModal';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import ModernModal from 'common/modals/ModernModal';
import Timestamp from 'common/Timestamp';
import ButtonV2 from 'common/ui/ButtonV2';
import SingleSelect from 'common/ui/SingleSelect';
import { P } from 'common/ui/Text';
import nbspLastSpace from 'common/util/nbspLastSpace';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import styles from 'css-module/components/subdomain/admin/billing/_CancelSubscriptionModal.module.scss';

import type { Option } from 'common/ui/common/select/SelectCommon';
import type { Dispatch } from 'redux';

type ConnectProps = {
  reloadCompany: () => void;
};

type CancelSubscriptionModalProps = ConnectProps & {
  type: 'downgrade' | 'cancel';
  onClose: (event: { completed: boolean }) => void;
};

const AdminChurnModal = ({
  onClose,
  reloadCompany,
  type = 'cancel',
}: CancelSubscriptionModalProps) => {
  const [cancelError, setCancelError] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [reason, setReason] = useState<Option>();
  const [subReason, setSubReason] = useState<Option>();

  const onCancel = () => {
    onClose({ completed: false });
  };

  const onChurn = async () => {
    setIsCancelling(true);
    const response = await AJAX.post('/api/billing/churn', {
      cancelSubscription: type === 'cancel',
      notes,
      reason: reason?.value || 'noReason',
      subReason: subReason?.value,
    });
    const { error } = parseAPIResponse(response, {
      errors: { 'free customer': 'free customer' },
      isSuccessful: isDefaultSuccessResponse,
    });
    await reloadCompany();
    setIsCancelling(false);
    if (error && error.message !== 'free customer') {
      SentrySDK.captureException(error);
      setCancelError(true);
    } else {
      onClose({ completed: true });
    }
  };

  const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
  };
  const onReasonChange = (reason?: Option) => {
    setReason(reason);
    setSubReason(undefined);
  };
  const onSubReasonChange = (subReason?: Option) => {
    setSubReason(subReason);
  };

  const cancelSubscription = type === 'cancel';
  const action = cancelSubscription ? 'Cancel Subscription' : 'Downgrade Plan';
  const footer = (
    <div className={styles.footer}>
      <ButtonV2 size="medium" onClick={onCancel} variant="outlined">
        Cancel
      </ButtonV2>
      <ButtonV2 color="error" size="medium" loading={isCancelling} onClick={onChurn}>
        {action}
      </ButtonV2>
    </div>
  );

  const options = ChurnReasons.filter((churnReason) => {
    return churnReason.name !== 'noReason';
  }).map((churnReason) => {
    return {
      label: churnReason.label,
      value: churnReason.name,
    };
  });
  const selectedReason = reason?.value
    ? ChurnReasons.find((option) => option.name === reason.value)
    : undefined;
  const subReasonOptions = selectedReason?.subReasons?.map((subReason) => {
    return {
      label: subReason.label,
      value: subReason.name,
    };
  });

  const company = useContext(CompanyContext);
  const timestamp = company.billingData?.activeUntil ? (
    <>
      , on{' '}
      <Timestamp showRelative={false} inline={true} timestamp={company.billingData.activeUntil} />
    </>
  ) : null;
  const body = (
    <div className={styles.body}>
      {cancelSubscription ? (
        <P>
          We're sorry to see you go. If you'd like to book a call with Canny's Head of Operations so
          that we can try and make things right, feel free to{' '}
          <a
            className={styles.link}
            href="https://cozycal.com/canny/chat-with-clare/9037/30"
            rel="noopener">
            book that here
          </a>
          .
        </P>
      ) : (
        <P>
          We're sorry to hear your plan isn't working for you. If you'd like to book a call with
          Canny's Head of Operations so that we can try and make things right, feel free to{' '}
          <a
            className={styles.link}
            href="https://cozycal.com/canny/chat-with-clare/9037/30"
            rel="noopener">
            book that here
          </a>
          .
        </P>
      )}
      <SingleSelect
        onChange={onReasonChange}
        options={options}
        placeholder="Select reason"
        value={reason}
      />
      {subReasonOptions && (
        <SingleSelect
          onChange={onSubReasonChange}
          options={subReasonOptions}
          placeholder="Select subreason"
          value={subReason}
        />
      )}
      <AutoResizeTextarea
        minRows={5}
        onChange={onNotesChange}
        placeholder={nbspLastSpace(
          `Please provide any additional details you wish to share about your decision to ${
            cancelSubscription ? 'cancel' : 'downgrade'
          }.`
        )}
      />
      {cancelSubscription && (
        <P>
          Submitting this form will set your subscription not to renew. You will still have access
          through the end of your billing cycle{timestamp}.
        </P>
      )}
      {cancelError && (
        <P className={styles.error} variant="bodySm">
          Something went wrong, please contact our support team to update your subscription.
        </P>
      )}
    </div>
  );
  return (
    <ModernModal
      header={action}
      modalClassName={styles.modal}
      sections={[body]}
      footer={footer}
      onClose={onCancel}></ModernModal>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => dispatch(reloadCompany()),
}))(AdminChurnModal);

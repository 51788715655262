import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadChangelog } from 'common/actions/changelog';
import { invalidateEntries } from 'common/actions/changelogEntries';
import { invalidateEntryQueries } from 'common/actions/changelogEntryQueries';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import AccessModal from 'common/modals/AccessModal';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Tappable from 'common/Tappable';
import hasPermission from 'common/util/hasPermission';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/subdomain/admin/_AdminChangelogSettingsLabels.scss';

class AdminChangelogSettingsLabels extends Component {
  static propTypes = {
    changelog: PropTypes.shape({
      labels: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          entryCount: PropTypes.number,
          name: PropTypes.string,
          urlName: PropTypes.string,
        })
      ),
    }),
    company: PropTypes.object,
    openModal: PropTypes.func,
    reloadChangelog: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    error: null,
    creating: false,
    deleting: false,
    renaming: false,
    renamingID: null,
  };

  constructor(props, context) {
    super(props, context);

    this.createInputRef = React.createRef();
    this.renameInputRef = React.createRef();
  }

  componentDidMount() {
    const { company, openModal, router, viewer } = this.props;
    if (hasPermission('manageChangelog', company, viewer)) {
      return;
    }

    router.replace('/admin/changelog');
    openModal(
      AccessModal,
      {
        requiredPermissions: ['manageChangelog'],
      },
      {
        allowRouteChange: true,
      }
    );
  }

  onCancelRenaming = () => {
    this.setState({
      renamingID: null,
    });
  };

  onCreateInputChange = (e) => {
    const { value } = e.target;
    this.setState({
      error: null,
      showCreateButton: value !== '',
    });
  };

  onCreateLabel = () => {
    const { creating } = this.state;
    if (creating) {
      return;
    }

    const { changelog } = this.props;
    const labelName = this.createInputRef.current.getValue();

    if (!validateInput.changelog.label.name(labelName)) {
      this.setState({
        error: 'Please enter a valid label name. (1-30 characters)',
      });
      return;
    } else if (
      changelog.labels.find((label) => {
        return label.name === labelName;
      })
    ) {
      this.setState({
        error: 'This label already exists.',
      });
      return;
    }

    this.setState({
      creating: true,
      error: null,
    });

    AJAX.post(
      '/api/changelog/labels/create',
      {
        name: labelName,
      },
      (response) => {
        this.setState({
          creating: false,
        });

        if (response === 'success') {
          const { reloadChangelog } = this.props;
          reloadChangelog();
          this.createInputRef.current.setValue('');
          return;
        } else {
          this.setState({
            error: 'Something went wrong, please try again later.',
          });
        }
      }
    );
  };

  onDeleteLabel = (label) => {
    const { deleting } = this.state;
    if (deleting) {
      return;
    }

    const suffix = label.entryCount === 1 ? ' entry' : ' entries';
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message:
        "Are you sure you'd like to delete this label? It will be removed from " +
        label.entryCount +
        suffix +
        '.',
      onConfirm: () => {
        this.setState({
          deleting: true,
        });

        AJAX.post(
          '/api/changelog/labels/delete',
          {
            labelID: label._id,
          },
          (response) => {
            this.setState({
              deleting: false,
            });

            if (response === 'success') {
              const { invalidateChangelog } = this.props;
              invalidateChangelog();
              return;
            } else {
              this.setState({
                error: 'Something went wrong, please try again later.',
              });
            }
          }
        );
      },
    });
  };

  onRenameLabel = (label) => {
    this.setState({
      renamingID: label._id,
    });
  };

  onSaveRename = (label) => {
    const { renaming } = this.state;
    if (renaming) {
      return;
    }

    const { changelog } = this.props;
    const labelName = this.renameInputRef.current.getValue();
    if (!validateInput.changelog.label.name(labelName)) {
      this.setState({
        error: 'Please enter a valid label name. (1-30 characters)',
      });
      return;
    } else if (
      changelog.labels.find((label) => {
        return label.name === labelName;
      }) &&
      labelName !== label.name
    ) {
      this.setState({
        error: 'This label already exists.',
      });
      return;
    }

    this.setState({
      renaming: true,
      error: null,
    });

    AJAX.post(
      '/api/changelog/labels/edit',
      {
        labelID: label._id,
        name: labelName,
      },
      (response) => {
        this.setState({
          renaming: false,
          renamingID: null,
        });

        if (response === 'success') {
          const { reloadChangelog } = this.props;
          reloadChangelog();
        } else {
          this.setState({
            error: 'Something went wrong, please try again later.',
          });
        }
      }
    );
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderLabels() {
    const {
      changelog: { labels },
    } = this.props;
    const items = [];
    labels.forEach((label) => {
      const { renamingID } = this.state;
      if (label._id === renamingID) {
        items.push(
          <div className="labelItem renaming" key={label._id}>
            <TextInput autoFocus={true} defaultValue={label.name} ref={this.renameInputRef} />
            <div className="rightContainer">
              <Tappable onTap={this.onCancelRenaming}>
                <div className="cancelLink">Cancel</div>
              </Tappable>
              <Tappable onTap={this.onSaveRename.bind(this, label)}>
                <div className="saveLink">Save</div>
              </Tappable>
            </div>
          </div>
        );
      } else {
        const suffix = label.entryCount === 1 ? ' entry' : ' entries';
        items.push(
          <div className="labelItem" key={label._id}>
            <div className="leftContainer">
              <div className="labelName">{label.name + ' (' + label.entryCount + suffix + ')'}</div>
              <div className="labelID">ID: {label._id}</div>
            </div>
            <div className="rightContainer">
              <Tappable onTap={this.onRenameLabel.bind(this, label)}>
                <div className="renameLink">Rename</div>
              </Tappable>
              <Tappable onTap={this.onDeleteLabel.bind(this, label)}>
                <div className="deleteLink">Delete</div>
              </Tappable>
            </div>
          </div>
        );
      }
    });

    return <div className="labels">{items}</div>;
  }

  renderCreateButton() {
    const { creating, showCreateButton } = this.state;
    if (!showCreateButton) {
      return null;
    }

    return <Button formButton={true} loading={creating} value="Create" />;
  }

  renderCreateLabelForm() {
    return (
      <Form
        className="createLabelForm"
        addEventsToDocument={false}
        disableSubmit={this.state.creating}
        onSubmit={this.onCreateLabel}>
        <TextInput
          onChange={this.onCreateInputChange}
          placeholder="Create new label…"
          ref={this.createInputRef}
        />
        {this.renderCreateButton()}
      </Form>
    );
  }

  renderDescription() {
    return (
      <div className="description">
        <div>
          Labels look like this:&nbsp;<div className="label">Label Name</div>
        </div>
        <div>
          You can add labels to changelog entries to specify which part of your product is being
          changed. Labels are public-facing, and can be used to filter down the list&nbsp;view.
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="adminChangelogSettingsLabels">
        <Helmet title="Label Settings | Changelog | Canny" />
        {this.renderDescription()}
        {this.renderLabels()}
        {this.renderCreateLabelForm()}
        {this.renderError()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateChangelog: () => {
      return Promise.all([
        dispatch(reloadChangelog()),
        dispatch(invalidateEntries()),
        dispatch(invalidateEntryQueries()),
      ]);
    },
    reloadChangelog: () => {
      return Promise.all([dispatch(reloadChangelog())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.changelog.labels),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    { forwardRef: true }
  )
)(AdminChangelogSettingsLabels);

import { type User } from 'common/api/endpoints/users';
import { type MimeTypeValues } from 'common/constants/files';

export enum Status {
  pending = 'pending',
  processing = 'processing',
  processed = 'processed',
  failed = 'failed',
}

export interface KnowledgeHubFile {
  _id: string;
  createdAt: string; // ISO 8601
  markdownURL: string;
  mimeType?: MimeTypeValues | null;
  status: Status;
  title: string;
  url: string;
  uploader: User | null;
}

import { ImageMimeTypes, type LocalFile } from 'common/constants/files';

const getValidFileURLs = (files: LocalFile[]) => {
  const imageURLs = files
    .filter((file) => (file.contentType ? ImageMimeTypes.includes(file.contentType) : false))
    .map((file) => file.url)
    .filter(Boolean);
  const nonImageFileURLs = files
    .filter((file) => (file.contentType ? !ImageMimeTypes.includes(file.contentType) : false))
    .map((file) => file.url)
    .filter(Boolean);

  return { imageURLs, nonImageFileURLs };
};

export default getValidFileURLs;

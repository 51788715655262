import React from 'react';

import { Sigma } from 'lucide-react';

import { type DescriptionColumn, type Factor, FactorType } from 'common/api/endpoints/roadmaps';
import Toggle from 'common/common/Toggle';
import {
  CustomDescriptionFieldTypes,
  DefaultDescriptionFieldTypes,
} from 'common/prioritization/DescriptionColumnTypes';

import FilterWrapper from './FilterWrapper';

import type { Column } from './AdminRoadmapViewSettingsModal';
import type { Board } from 'common/api/endpoints/boards';
import type { Board as PlainBoard } from 'common/api/resources/board';

type Props = {
  boards: Board[];
  column: DescriptionColumn | Factor;
  clearSettingFromPath: (name: string) => void;
  distinctBoards: PlainBoard[];
  hiddenFactors: string[];
  hiddenDescriptionColumns: string[];
  updateColumns: (columns: Column[]) => void;
};

export const isDescriptionColumn = (
  column: DescriptionColumn | Factor
): column is DescriptionColumn =>
  column.type in DefaultDescriptionFieldTypes || column.type in CustomDescriptionFieldTypes;

// Map fields that have names in the database that differ from what we want to see in the UI
const mapToCopy = (name: string) =>
  ({
    board: 'Boards',
    category: 'Categories',
    eta: 'ETA',
  }[name] ?? name);

const ColumnSetting = ({
  boards,
  column,
  clearSettingFromPath,
  distinctBoards,
  hiddenFactors,
  hiddenDescriptionColumns,
  updateColumns,
}: Props) => {
  const isHidden =
    hiddenFactors.includes(column._id) || hiddenDescriptionColumns.includes(column._id);
  const isFactor = column.type in FactorType;

  const name = mapToCopy(column.name);

  let settingKey = name.toLowerCase();

  // Custom fields will have the ID in the URL since that's what's used to identify them in a post
  if (isDescriptionColumn(column) && column.fieldID) {
    settingKey = column.fieldID;
  }

  if (isFactor && column._id) {
    settingKey = column._id;
  }

  return (
    <>
      <li className="factorVisibilityItem">
        <span className="factorVisibilityName">
          {name}
          {isFactor ? <Sigma className="sigmaIcon" size={14} /> : null}
        </span>
        <Toggle
          small
          value={!isHidden}
          onToggle={(isColumnVisible: boolean) => {
            updateColumns([{ columnID: column._id, hidden: !isColumnVisible }]);
            clearSettingFromPath(settingKey);
          }}
        />
      </li>
      {!isHidden && (
        <FilterWrapper
          boards={boards}
          fieldID={isDescriptionColumn(column) ? column.fieldID : column._id}
          distinctBoards={distinctBoards}
          id={column._id}
          name={name.toLowerCase()}
          options={column.options || []}
          type={column.type}
          isCustomDescriptionColumn={isDescriptionColumn(column)}
        />
      )}
    </>
  );
};

export default ColumnSetting;

import { loadCompany } from './company';
import Data from '../Data';

// Action Types
export const DiscordSettingsError = 'canny/discord_settings/discord_settings_error';
export const DiscordSettingsLoaded = 'canny/discord_settings/discord_settings_loaded';
export const RequestDiscordSettings = 'canny/discord_settings/request_discord_settings';

// Actions
function discordSettingsError(error) {
  return {
    error: error,
    timestamp: Date.now(),
    type: DiscordSettingsError,
  };
}

function discordSettingsLoaded(discordSettings) {
  return {
    discordSettings,
    timestamp: Date.now(),
    type: DiscordSettingsLoaded,
  };
}

function requestDiscordSettings() {
  return {
    timestamp: Date.now(),
    type: RequestDiscordSettings,
  };
}

// Action Creators
function fetchDiscordSettings() {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    return Data.fetch(
      {
        discordSettings: {
          query: Data.queries.discordSettings,
        },
      },
      cookies,
      (error, data) => {
        if (error) {
          return dispatch(discordSettingsError(error));
        }
        return dispatch(discordSettingsLoaded(data.discordSettings));
      }
    );
  };
}

export function loadDiscordSettings() {
  return (dispatch, getState) => {
    return Promise.all([dispatch(loadCompany())]).then(() => {
      if (shouldFetchSettings(getState())) {
        dispatch(requestDiscordSettings());
        return dispatch(fetchDiscordSettings());
      }
    });
  };
}

export function reloadDiscordSettings() {
  return (dispatch, getState) => {
    return dispatch(fetchDiscordSettings());
  };
}

function shouldFetchSettings(state) {
  const { discordSettings } = state;
  return discordSettings === null;
}

import React, { useContext, useEffect } from 'react';

import { AccessContext } from 'common/containers/AccessContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import { type RoutePermissionsMap, findMatchingRoute } from 'common/util/permissions';

type Props = {
  routePermissions: RoutePermissionsMap;
  urlPrefix: ((params: Record<string, string>) => string) | string;
  fallbackURL?: string;
};

const AdminSettingsRedirect = ({
  fallbackURL = '/admin/settings',
  routePermissions,
  urlPrefix,
}: Props) => {
  const company = useContext(CompanyContext);
  const router = useContext(RouterContext);
  const viewer = useContext(ViewerContext);
  const params = useContext(ParamsContext);
  const { setAccessStatus } = useContext(AccessContext);

  const matchingRoute = findMatchingRoute(routePermissions, company, viewer);

  let prefix = '';
  if (typeof urlPrefix === 'function') {
    prefix = urlPrefix(params);
  } else {
    prefix = urlPrefix;
  }

  useEffect(() => {
    if (matchingRoute) {
      router.replace(`/admin/settings/${prefix}/${matchingRoute}`);
    } else {
      // show access denied modal
      setAccessStatus({
        result: 'failure',
        reason: 'permission',
        data: {
          missingPermissions: Object.values(routePermissions).flat(),
        },
      });
      // redirect to fallback URL
      router.replace(fallbackURL);
    }
  }, [fallbackURL, matchingRoute, prefix, router, setAccessStatus, routePermissions]);

  return <div />;
};

export default AdminSettingsRedirect;

import {
  INVALIDATE_AND_RELOAD,
  type InboxAction,
  LOADING_MORE,
  QUERY_ERROR,
  QUERY_LOADED,
  REQUEST_QUERY,
  getQueryKey,
} from '../actions/inboxItemQueries';

import type { InboxItem } from 'common/api/resources/inbox';

type State = Record<
  string,
  {
    items: InboxItem[];
    lastCreated: Date;
    lastUpdated: Date;
    loading: boolean;
    loadingMore: boolean;
    queryParams: string;
  }
>;

export default function inboxItemQueries(state: State = {}, action: InboxAction) {
  switch (action.type) {
    case REQUEST_QUERY: {
      const queryKey = getQueryKey(action.queryParams);
      const queryState = state[queryKey];
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, queryState, {
          lastUpdated: action.timestamp,
          loading: true,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case QUERY_LOADED: {
      const queryKey = getQueryKey(action.queryParams);
      const existingItems = action.reload ? [] : state[queryKey]?.items ?? [];
      const allItems = [...existingItems, ...action.result.items];
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          items: allItems,
          lastCreated: action.result.lastCreated,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          totalItemCount: action.result.totalItemCount,
          queryParams: action.queryParams,
          reloading: false,
        }),
      });
    }

    case QUERY_ERROR: {
      const queryKey = getQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LOADING_MORE: {
      const queryKey = getQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case INVALIDATE_AND_RELOAD: {
      const queryKey = getQueryKey(action.queryParams);
      const existingStateForQuery = state[queryKey];
      return Object.assign(
        {},
        {
          [queryKey]: {
            ...existingStateForQuery,
            reloading: true,
          },
        }
      );
    }

    default:
      return state;
  }
}

// update the textarea using document.execCommand so that browser undo/redo works
export default function updateRangeThenSelectRange(
  autoResizeTextarea,
  updateRange,
  updateValue,
  selectRange
) {
  autoResizeTextarea.focus();
  autoResizeTextarea.setSelectionRange(updateRange.start, updateRange.end);
  autoResizeTextarea.insertText(updateValue);

  setTimeout(() => {
    autoResizeTextarea.focus();
    autoResizeTextarea.setSelectionRange(selectRange.start, selectRange.end);
  }, 0);
}

import React, { Component } from 'react';

import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminBoardSettingsDataExport.scss';

class AdminBoardSettingsDataExport extends Component {
  static propTypes = {
    board: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    openModal: PropTypes.func,
    router: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    exporting: false,
    error: null,
  };

  onExport = () => {
    const { board, company } = this.props;

    this.setState({
      exporting: true,
      error: null,
    });

    const statuses = company.statuses.map((status) => status.name);
    AJAX.post(
      '/api/boards/export',
      {
        boardURLNames: [board.urlName],
        status: { $in: statuses },
      },
      (response) => {
        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (!responseObject.csv) {
          this.setState({
            error: 'Something went wrong. Please contact support.',
            exporting: false,
          });
          return;
        }

        const currentDateString = new Date().toISOString().slice(0, 10);
        const csvName = `Canny - ${board.name} - ${currentDateString}.csv`;

        saveAs(new Blob([responseObject.csv]), csvName);

        this.setState({ exporting: false });
      }
    );
  };

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  render() {
    const { board } = this.props;
    return (
      <div className="adminBoardSettingsDataExport">
        <Helmet title={'Export Data | ' + board.name + ' | Canny'} />
        <div className="message">Export a list of this board's posts in CSV&nbsp;format.</div>
        <div className="buttonContainer">
          {this.renderError()}
          <Button
            buttonType="cannyButton"
            loading={this.state.exporting}
            value="Export"
            onTap={this.onExport}
          />
        </div>
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  openModal: OpenModalContext,
  viewer: ViewerContext,
})(AdminBoardSettingsDataExport);

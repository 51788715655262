import * as linkify from 'linkifyjs';

import queryString from 'common/util/queryString';
import shouldNoFollow from 'common/util/shouldNoFollow';

const MarkdownStages = [
  {
    className: 'multiLineCodeBlock',
    final: true,
    name: 'multiLineCodeBlock',
    regex: /(?:^|[\r\n]+)```[\r\n]+([\s\S]*?)[\r\n]+```(?=[\r\n]+|$)/g,
  },
  {
    className: 'bold italics',
    name: 'multilineBoldItalicsBlock',
    regex: /(?:^|[\r\n]+)\*\*\*[\r\n]+([\s\S]*?)[\r\n]+\*\*\*(?=[\r\n]+|$)/g,
  },
  {
    className: 'bold',
    final: true,
    name: 'multiLineBoldBlock',
    regex: /(?:^|[\r\n]+)\*\*[\r\n]+([\s\S]*?)[\r\n]+\*\*(?=[\r\n]+|$)/g,
  },
  {
    className: 'italics',
    name: 'multilineItalicsBlock',
    regex: /(?:^|[\r\n]+)\*[\r\n]+([\s\S]*?)[\r\n]+\*(?=[\r\n]+|$)/g,
  },
  {
    componentName: 'ul',
    name: 'unorderedList',
    regex: /(?:^|[\r\n]+)((-( |\u00a0).+([\r\n]+|$)?)+)([\r\n]+|$)/g,
    skip: ['orderedList', 'heading', 'line'],
    transform: (item) => {
      const contents = item.items[0].trim();
      const transformedItems = contents
        .replace(/^-( |\u00a0)/gm, '')
        .split(/[\r\n]+(?=.)/g)
        .map((itemContents) => {
          return {
            componentName: 'li',
            items: [itemContents],
          };
        });
      return {
        ...item,
        items: transformedItems,
      };
    },
  },
  {
    componentName: 'ol',
    name: 'orderedList',
    skip: ['heading', 'line'],
    regex: /(?:^|[\r\n]+)(([0-9]+\.( |\u00a0).+([\r\n]+|$)?)+)([\r\n]+|$)/g,
    transform: (item) => {
      const contents = item.items[0].trim();

      const transformedItems = contents.split(/[\r\n]+/).map((line) => {
        const match = line.match(/^([0-9]+)\.( |\u00a0)(.+)/);
        const number = Number.parseInt(match[1], 10); // This captures the number
        const text = match[3];

        return {
          componentName: 'li',
          items: [text],
          start: number,
        };
      });

      return {
        ...item,
        items: transformedItems,
      };
    },
  },
  {
    className: 'line',
    name: 'line',
    regex: /(?:[\r\s\n]+)?([^\n]+)([\r\s\n]+)?/g,
  },
  {
    className: 'heading',
    name: 'heading',
    regex: /^#[ |\u00a0](.+)/g,
  },
  {
    className: 'inlineCodeBlock',
    final: true,
    name: 'inlineCodeBlock',
    regex: /`([^`]+)`/g,
  },
  {
    componentName: 'div',
    final: true,
    name: 'loom',
    regex: /!\[(([^\]]+)?\]\(https:\/\/(www\.)?(use)?loom.com\/(share|embed)\/([0-9a-z]+).*)\)/g,
    transform: (item, match) => {
      const videoID = match[match.length - 1];
      return {
        ...item,
        className: 'videoContainer',
        items: [
          {
            componentName: 'iframe',
            componentProps: {
              allow:
                'accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture',
              allowFullScreen: true,
              frameBorder: '0',
              src: 'https://www.loom.com/embed/' + videoID,
            },
            final: true,
            items: [],
          },
        ],
      };
    },
  },
  {
    componentName: 'div',
    final: true,
    name: 'vimeo',
    regex: /!\[(([^\]]+)?\]\(https:\/\/(www\.|player\.)?vimeo.com\/(video\/)?([0-9]+).*)\)/g,
    transform: (item, match) => {
      const videoID = match[match.length - 1];
      return {
        ...item,
        className: 'videoContainer',
        items: [
          {
            componentName: 'iframe',
            componentProps: {
              allow:
                'accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture',
              allowFullScreen: true,
              frameBorder: '0',
              src: 'https://player.vimeo.com/video/' + videoID,
            },
            final: true,
            items: [],
          },
        ],
      };
    },
  },
  {
    componentName: 'div',
    final: true,
    name: 'wistia',
    regex:
      /!\[(([^\]]+)?\]\(https:\/\/(.+\.)?wistia.(com|net)\/(medias\/|embed\/iframe\/)?([a-z0-9]+).*)\)/g,
    transform: (item, match) => {
      const videoID = match[match.length - 1];
      return {
        ...item,
        className: 'videoContainer',
        items: [
          {
            componentName: 'iframe',
            componentProps: {
              allow:
                'accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture',
              allowFullScreen: true,
              frameBorder: '0',
              src: 'https://fast.wistia.net/embed/iframe/' + videoID + '?fullscreenButton=true',
            },
            final: true,
            items: [],
          },
        ],
      };
    },
  },
  {
    componentName: 'div',
    final: true,
    name: 'youtube',
    regex:
      /!\[(([^\]]+)?\]\(https:\/\/(www\.)?youtu(\.be|be\.com)\/(embed\/|watch\?v=)?([a-zA-Z0-9-_]+)(.*))\)/g,
    transform: (item, match) => {
      const videoID = match[match.length - 2];
      const parsedParams = queryString.parse(match[match.length - 1]);
      const { end, loop, start, t } = parsedParams;
      const startTime = t ?? start;
      const appendParams = queryString.stringify({
        ...(startTime && { start: Number.parseInt(startTime) }),
        ...(end && { end: Number.parseInt(end) }),
        ...(loop && { loop: Number.parseInt(loop) }),
      });
      return {
        ...item,
        className: 'videoContainer',
        items: [
          {
            componentName: 'iframe',
            componentProps: {
              allow:
                'accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture',
              allowFullScreen: true,
              frameBorder: '0',
              src: `https://www.youtube.com/embed/${videoID}${appendParams}`,
            },
            final: true,
            items: [],
          },
        ],
      };
    },
  },
  {
    componentName: 'img',
    final: true,
    name: 'image',
    regex: /!\[(([^\]]+)?\]\(([^\)]+)?)\)/g,
    transform: (item) => {
      const contents = item.items[0];
      const parts = contents.split('](');
      const alt = parts[0];
      const src = parts[1];
      return {
        ...item,
        componentProps: {
          alt,
          loading: 'lazy',
          src,
        },
        items: [],
      };
    },
  },
  {
    name: 'link',
    regex: /\[(([^\]]+)?\]\((?!javascript:.+)([^\)]+)?)\)/gi,
    skip: ['url'],
    transform: (item) => {
      const contents = item.items[0];
      const parts = contents.split('](');
      const value = parts[0];
      const href = parts[1];

      // Reject faking one link with another
      const textContainsLink = linkify.find(value).length > 0;
      if (textContainsLink && value !== href) {
        return {
          items: ['[', value, '](', href, ')'],
        };
      }

      const rel = ['ugc'];
      if (shouldNoFollow(href)) {
        rel.push('nofollow');
        rel.push('noopener');
      }

      return {
        ...item,
        componentName: 'a',
        componentProps: {
          rel: rel.join(' '),
          href: href,
          target: '_blank',
        },
        items: [value],
      };
    },
  },
  {
    className: 'bold italics',
    name: 'boldItalics',
    regex: /\*\*\*([^\*]+)\*\*\*/g,
  },
  {
    className: 'bold',
    name: 'bold',
    regex: /\*\*([^\*]+)\*\*/g,
  },
  {
    className: 'italics',
    name: 'italics',
    regex: /\*([^\*]+)\*/g,
  },
  {
    name: 'url',
    regex: /(.+)/g,
    transform: (item) => {
      const contents = item.items[0];
      const results = linkify.find(contents);
      if (results.length === 0) {
        return contents;
      }

      const items = [];
      let position = 0;

      results.forEach((result) => {
        const linkStart = contents.indexOf(result.value, position);

        const before = contents.substring(position, linkStart);
        if (before.length > 0) {
          items.push(before);
        }

        const rel = ['ugc'];
        if (shouldNoFollow(result.href)) {
          rel.push('nofollow');
          rel.push('noopener');
        }

        items.push({
          componentName: 'a',
          componentProps: {
            rel: rel.join(' '),
            href: result.href,
            target: '_blank',
          },
          final: true,
          items: [result.value],
        });

        position = linkStart + result.value.length;
      });

      const after = contents.substring(position, contents.length);
      if (after.length > 0) {
        items.push(after);
      }

      return items;
    },
  },
];

export const MarkdownStagesNameMap = MarkdownStages.reduce((nameMap, stage) => {
  return {
    ...nameMap,
    [stage.name]: stage.name,
  };
}, {});

export default MarkdownStages;

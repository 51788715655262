import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import { Check } from 'lucide-react';
import { type Dispatch } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Instructions from 'common/subdomain/admin/AdminDashboard/onboarding/Instructions';
import ButtonV2 from 'common/ui/ButtonV2';
import { H4, P } from 'common/ui/Text';
import getOnboardingTasks, { OnboardingLabels } from 'common/util/getOnboardingTasks';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/AdminDashboard/_AdminDashboardOnboarding.scss';
import 'css/components/subdomain/admin/_AdminOnboarding.scss';

type Props = {
  reloadCompany: () => void;
};

const AdminDashboardOnboarding = ({ reloadCompany }: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  // state
  const tasks = getOnboardingTasks(company);
  const nextTask = tasks.find((task) => !task.completed) || tasks[0];
  const [selected, setSelected] = useState<string>(nextTask.name);
  const [skipping, setSkipping] = useState<boolean>(false);

  // helpers
  const onSkipOnboarding = async () => {
    setSkipping(true);

    const response = await AJAX.post('/api/billing/skipOnboarding');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setSkipping(false);
      showToast('Skip failed, please contact support.', ToastTypes.error);
      return;
    }

    await reloadCompany();
    setSkipping(false);
  };

  const onboardingExtensions = company.billingData?.onboardingExtensions;
  const canSkip = onboardingExtensions && onboardingExtensions > 0;
  const selectedTaskStatus = tasks.find(({ name }) => name === selected);
  return (
    <div className="adminDashboardOnboarding">
      <div className="taskbar">
        <div className="header">
          <H4 className="title">Getting started</H4>
          <P className="description">
            Here are some actions that will set you up for success with&nbsp;Canny.
          </P>
        </div>
        <div className="tasklist">
          {tasks.map((task, i) => {
            const taskName = task.name as keyof typeof OnboardingLabels;
            const label = OnboardingLabels[taskName];
            return (
              <div
                className={classnames({
                  task: true,
                  completed: task.completed,
                  selected: selected === task.name,
                })}
                key={task.name}
                onClick={() => setSelected(task.name)}>
                <div className="index">{i + 1}</div>
                <div className="check">
                  <Check size={14} />
                </div>
                <div className="label">{label}</div>
                <div className="popper">🎉</div>
              </div>
            );
          })}
        </div>
        <div className="spacer" />
        {!!canSkip && (
          <ButtonV2
            className="skipOnboardingButton"
            size="medium"
            variant="plain"
            loading={skipping}
            onClick={onSkipOnboarding}>
            {skipping ? 'Skipping' : 'Skip onboarding steps'}
          </ButtonV2>
        )}
      </div>
      <Instructions completed={selectedTaskStatus?.completed} taskName={selected} />
    </div>
  );
};

export default connect(null, (dispatch: Dispatch<any>) => ({
  reloadCompany: () => {
    return dispatch(reloadCompany());
  },
}))(AdminDashboardOnboarding) as unknown as React.FC<Props>;

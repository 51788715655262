import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { loadMoreNotifications, reloadNotifications } from 'common/actions/notifications';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import NotificationList from 'common/notifications/NotificationList';
import AdminNotificationsSidebar from 'common/subdomain/admin/AdminNotificationsSidebar';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminNotifications.scss';

class AdminNotifications extends Component {
  static propTypes = {
    boards: PropTypes.arrayOf(PropTypes.object),
    company: PropTypes.object,
    notifications: PropTypes.shape({
      hasNextPage: PropTypes.bool,
      items: PropTypes.array,
      loading: PropTypes.bool,
    }),
    loadMoreNotifications: PropTypes.func,
    reloadNotifications: PropTypes.func,
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }),
  };

  constructor(props, context) {
    super(props, context);

    this.notificationListRef = React.createRef();
    this.scrollContainerRef = React.createRef();
  }

  componentDidMount() {
    this.scrollContainerRef.current.addEventListener('scroll', this.onScroll);
    this.onScroll();
    this.handleMarkAllSeen();
  }

  componentWillUnmount() {
    this.scrollContainerRef.current.removeEventListener('scroll', this.onScroll);
  }

  handleMarkAllSeen = () => {
    const { viewer } = this.props;
    if (!viewer?._id) {
      return;
    }

    return AJAX.post('/api/notifications/markAllSeen');
  };

  onLoadMoreTapped = () => {
    this.props.loadMoreNotifications();
  };

  markAllAsRead = async () => {
    const response = await AJAX.post('/api/notifications/markAllRead');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    this.props.reloadNotifications();
  };

  onScroll = (e) => {
    const notificationList = this.notificationListRef.current;
    if (!notificationList) {
      return;
    }

    const scrollContainer = this.scrollContainerRef.current;
    this.notificationListRef.current.onScroll(e, scrollContainer);
  };

  renderMarkAllLink() {
    return (
      <Tappable onTap={this.markAllAsRead}>
        <div className="markAll">Mark all as read</div>
      </Tappable>
    );
  }

  renderContents() {
    const { viewer } = this.props;
    if (!viewer?._id) {
      return <div className="loggedOut">You are not logged in.</div>;
    }

    return (
      <>
        <div className="notificationHeader">
          <div className="heading">Notifications</div>
          {this.renderMarkAllLink()}
        </div>
        <NotificationList
          notifications={this.props.notifications}
          onLoadMoreTapped={this.onLoadMoreTapped}
          ref={this.notificationListRef}
        />
      </>
    );
  }

  render() {
    return (
      <div className="adminNotifications">
        <Helmet title={'Notifications | Canny'} />
        <AdminNotificationsSidebar boards={this.props.boards} company={this.props.company} />
        <div className="adminNotificationsContents" ref={this.scrollContainerRef}>
          {this.renderContents()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    loadMoreNotifications: () => dispatch(loadMoreNotifications()),
    reloadNotifications: () => dispatch(reloadNotifications()),
  })),
  withContexts(
    {
      company: CompanyContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminNotifications);

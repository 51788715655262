enum ReferralStatuses {
  cancelled = 'Cancelled',
  free = 'Free subscription',
  paid = 'Paid subscription',
}

const ReferralQuestions = [
  {
    _id: '1',
    question: 'Who will you be referring?',
    placeholder: '(e.g. friends, family, colleagues, etc.)',
  },
  {
    _id: '2',
    question: 'What channels will you use?',
    placeholder: '(e.g. LinkedIn, X, blog posts, etc.)',
  },
];

export { ReferralQuestions, ReferralStatuses };

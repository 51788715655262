function getHostname(urlString: string): string {
  if (urlString.indexOf('://') === -1) {
    urlString = `http://${urlString}`;
  }

  try {
    const url = new URL(urlString);
    return url.hostname;
  } catch (error) {
    return '';
  }
}

export default getHostname;

// Find the difference between two arrays
type Comparator<T> = (a: T, b: T) => boolean;
const basicEquals = <T,>(a: T, b: T) => a === b;

const difference = <T,>(arrayA: T[], arrayB: T[], equals: Comparator<T> = basicEquals) =>
  arrayA.filter((itemA) => {
    return arrayB.every((itemB) => !equals(itemA, itemB));
  });

export default difference;

import isServer from 'common/util/isServer';

import SentrySDK from './3rd/SentrySDK';

const CSRFTokenHeaderName = 'Canny-CSRF-Token';

type Callback = (result: string) => void;
type Cookies = Record<string, string>;
export type URLParameters = Record<string, string | number | boolean>;
export type BodyParameters = unknown;
type FetchOverride = (url: string, options: RequestInit) => Promise<FetchResponse>;
type Host = string;
export type FetchResponse = {
  status: number;
  headers: Headers;
  text: () => string;
};
type URL = string;

const noop: Callback = () => null;

let injectedCookies: Cookies = {};
let injectedHost: Host;
let injectedFetch: FetchOverride;

function fixURL(url: URL) {
  if (!injectedHost) {
    return url;
  }
  return injectedHost + url;
}

function send(
  url: URL,
  callback: Callback = noop,
  method: 'GET' | 'POST',
  body?: BodyInit | null,
  headers?: HeadersInit
): Promise<string> {
  url = fixURL(url);
  return (injectedFetch || fetch)(url, {
    body,
    credentials: 'same-origin',
    headers,
    method,
  })
    .then((response: FetchResponse) => {
      if (response.status >= 200 && response.status < 500) {
        const csrfTokenFromResponse = response.headers.get(CSRFTokenHeaderName);
        const csrfTokenIsUnset = !injectedCookies['csrfToken'];

        if (!isServer() && csrfTokenIsUnset && csrfTokenFromResponse) {
          AJAX.injectCookies({
            csrfToken: csrfTokenFromResponse,
          });
        }

        return response.text();
      } else if (response.status >= 100) {
        return response.text();
      } else {
        return 'server error';
      }
    })
    .catch(() => {
      callback('server error');
      return 'server error';
    })
    .then((body) => {
      callback(body);
      return body;
    })
    .catch((error) => {
      SentrySDK.captureException(error);
      return 'server error';
    });
}

const AJAX = {
  get(url: URL, data: URLParameters, callback?: Callback) {
    data = Object.assign({}, injectedCookies, data);
    const query = [];
    for (const key in data) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
    return send(url + '?' + query.join('&'), callback, 'GET', null, {});
  },

  injectCookies(cookies: Cookies) {
    if (isServer()) {
      throw new Error('AJAX.injectCookies should never be called on the server');
    }
    injectedCookies = Object.assign(injectedCookies, cookies);
  },

  injectFetch(fetchOverride: FetchOverride) {
    if (!isServer()) {
      throw new Error('AJAX.injectFetch should only be called on the server');
    }
    injectedFetch = fetchOverride;
  },

  injectHost(host: Host) {
    if (isServer()) {
      throw new Error('AJAX.injectHost should never be called on the server');
    }
    injectedHost = host;
  },

  post(url: URL, data?: BodyParameters, callback: Callback = noop) {
    data = Object.assign({}, injectedCookies, data);
    const body = JSON.stringify(data);
    return send(url, callback, 'POST', body, { 'Content-Type': 'application/json' });
  },

  postFile(url: URL, files: Record<string, File>, data: URLParameters, callback: Callback = noop) {
    const formData = new FormData();
    for (const key in files) {
      formData.append(encodeURIComponent(key), files[key], files[key].name);
    }

    data = Object.assign({}, injectedCookies, data);
    for (const key in data) {
      formData.append(encodeURIComponent(key), encodeURIComponent(data[key]));
    }

    return send(url, callback, 'POST', formData, {});
  },
};

export default AJAX;

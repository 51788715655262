// Action Types

export const SET_COOKIES = 'canny/cookies/set_cookies';

// Action Creator

export function setCookies(cookies) {
  return (dispatch) => {
    return dispatch({
      cookies,
      type: SET_COOKIES,
    });
  };
}

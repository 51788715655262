import React, { useState } from 'react';

import SearchInput from 'common/inputs/SearchInput';
import { type Option } from 'common/ui/common/select/SelectCommon';
import SingleSelect from 'common/ui/SingleSelect';
import useDelayer from 'common/util/useDelayer';

import {
  type Filter,
  customFieldAlphanumericOptions,
  customFieldNumericOptions,
  postFieldAlphanumericOptions,
  postFieldNumericOptions,
  textFilterOptionsSettings,
} from './FilterConstants/TextFilterConstants';

import type { Nullable } from 'common/types/util';

import 'css/components/subdomain/admin/AdminRoadmap/_TextFilter.scss';

type inputTypes = 'number' | 'text' | 'customFieldNumber' | 'customFieldText';

type Props = {
  inputType: inputTypes;
  filter: Nullable<Filter>;
  onChange: (filter: Nullable<Filter>) => void;
};

const determineOptions = (inputType: inputTypes) => {
  switch (inputType) {
    case 'customFieldNumber':
      return customFieldNumericOptions;
    case 'customFieldText':
      return customFieldAlphanumericOptions;
    case 'number':
      return postFieldNumericOptions;
    case 'text':
      return postFieldAlphanumericOptions;
    default:
      return postFieldAlphanumericOptions;
  }
};

const TextFilter = ({ inputType, onChange, filter }: Props) => {
  const [options] = useState(determineOptions(inputType));

  const onTextChangeAfterDelay = (operator: Filter['operator'], value: string) => {
    onChange({ operator, value });
  };

  const searchDelayer = useDelayer(onTextChangeAfterDelay, 500);

  const changeOperator = (option?: Option) => {
    const operator = option && textFilterOptionsSettings[option.value] ? option.value : null;

    // Reset search value to empty every time the option changes
    onChange({
      operator,
      value: null,
    });
  };

  const changeValue = (value: string) => {
    if (value) {
      searchDelayer(filter.operator, value);
      return;
    }
  };

  const selectedOption = options.find((option) => option.value === filter.operator);
  return (
    <div className="textFilter">
      <SingleSelect
        allowClear={true}
        labelPlacement="top"
        onChange={changeOperator}
        options={options}
        optionsPlacement="bottom"
        value={selectedOption}
        placeholder="All"
        withBorder={true}
      />
      {filter.operator && textFilterOptionsSettings[filter.operator]?.requiresAdditionalValue && (
        <SearchInput
          className="additionalValueInput"
          autoFocus={false}
          showClearIcon={false}
          showSearchIcon={false}
          defaultValue={filter.value}
          onChange={changeValue}
          placeholder={['number', 'customFieldNumber'].includes(inputType) ? 'Value' : 'Keywords'}
          styling="v2"
          inputMode={inputType}
          type={inputType}
        />
      )}
    </div>
  );
};

export default TextFilter;

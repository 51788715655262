import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import Helmet from 'common/helmets/Helmet';
import companyOGImage from 'common/util/companyOGImage';
import getCannyOrigin from 'common/util/getCannyOrigin';
import withContexts from 'common/util/withContexts';

class SubdomainChangelogHelmet extends Component {
  static propTypes = {
    company: PropTypes.object,
    entryList: PropTypes.object,
    tintColor: PropTypes.string,
  };

  render() {
    const { company, entryList, tintColor } = this.props;
    if (!company) {
      return null;
    }

    if (!entryList || !entryList.entries) {
      return null;
    }

    const { entries } = entryList;

    const descriptionStart = 'See all of the latest product updates from ' + company.name + '.';
    const descriptionEnd =
      entries.length > 0 ? ' Most recent update: ' + entries[0].title + '.' : '';
    const description = descriptionStart + descriptionEnd;
    const title = 'Changelog | ' + company.name;
    const url = getCannyOrigin(company) + '/changelog';
    const feedURL = `${getCannyOrigin(company)}/api/changelog/feed.rss`;
    const image = companyOGImage(company);
    return (
      <Helmet
        link={[
          { href: url, rel: 'canonical' },
          { rel: 'alternate', type: 'application/rss+xml', title: 'RSS 2.0', href: feedURL },
        ]}
        meta={[
          { name: 'description', content: description },
          { name: 'theme-color', content: tintColor },
          { name: 'title', content: title },

          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@cannyHQ' },
          { name: 'twitter:title', content: title },
          { name: 'twitter:description', content: description },
          { name: 'twitter:image', content: image },

          { property: 'article:publisher', content: 'https://canny.io' },

          { property: 'og:description', content: description },
          { property: 'og:image', content: image },
          { property: 'og:locale', content: 'en' },
          { property: 'og:site_name', content: title },
          { property: 'og:title', content: title },
          { property: 'og:url', content: url },
          { property: 'og:type', content: 'website' },
        ]}
        title={title}
      />
    );
  }
}

export default withContexts({
  company: CompanyContext,
  tintColor: TintColorContext,
})(SubdomainChangelogHelmet);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Errors as CustomFieldErrors, CustomFieldTypes } from 'common/customPostFields/Constants';
import { getFieldsErrors } from 'common/customPostFields/Utils';
import Dropdown from 'common/Dropdown';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import TextInput from 'common/inputs/TextInput';

import 'css/components/post/_CustomPostFieldInput.scss';

export const getErrorMessage = (field, errorType) => {
  const label = field.label ?? field.name;
  const validationMessage = {
    [CustomFieldTypes.integer.name]: `The field "${label}" should be an integer number`,
    [CustomFieldTypes.multilineText.name]: `The field "${label}" should be text`,
    [CustomFieldTypes.text.name]: `The field "${label}" should be text`,
  };

  const message =
    {
      [CustomFieldErrors.incompatibleTypes]:
        validationMessage[field.type] || `The field "${label}" is invalid`,
      [CustomFieldErrors.missingFields]: `The required field "${label}" is empty`,
    }[errorType] || `There is an error on the field "${label}"`;

  return message;
};

export const getErrorMessages = (customFieldValuesMap, fields) => {
  const errors = getFieldsErrors(customFieldValuesMap, fields);
  if (errors.length) {
    const errorMessages = errors.map((error) => getErrorMessage(error.field, error.type));
    const erroredFields = errors.map((error) => error.field);
    return { erroredFields, errorMessages, errors };
  }

  return { erroredFields: [], errorMessages: [], errors: [] };
};

export default class CustomPostFieldInput extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errored: PropTypes.bool,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    postField: PropTypes.oneOfType([
      PropTypes.shape({
        _id: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        type: PropTypes.string,
      }),
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
    truncate: PropTypes.bool,
  };

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    truncate: false,
  };

  inputRef = React.createRef();

  setValue = (value) => {
    const { postField } = this.props;
    if (
      postField.type === CustomFieldTypes.dropdown.name ||
      postField.type === CustomFieldTypes.multiselect.name
    ) {
      return;
    }

    if (this.inputRef.current) {
      this.inputRef.current.setValue(value);
    }
  };

  render() {
    const { autoFocus, className, disabled, errored, id, onChange, postField, truncate } =
      this.props;

    if (!postField) {
      return null;
    }

    if (postField.type === CustomFieldTypes.text.name) {
      return (
        <TextInput
          id={id}
          autoFocus={autoFocus}
          disabled={disabled}
          className={classnames(className, { errored })}
          defaultValue={postField.value}
          onChange={(e) => onChange(e.currentTarget.value, postField)}
          placeholder={postField.placeholder}
          ref={this.inputRef}
        />
      );
    } else if (postField.type === CustomFieldTypes.integer.name) {
      return (
        <TextInput
          id={id}
          autoFocus={autoFocus}
          className={classnames(className, { errored })}
          disabled={disabled}
          defaultValue={postField.value}
          inputMode="numeric"
          onChange={(e) => onChange(e.currentTarget.value, postField)}
          placeholder={postField.placeholder}
          type="text"
          ref={this.inputRef}
        />
      );
    } else if (postField.type === CustomFieldTypes.multilineText.name) {
      return (
        <AutoResizeTextarea
          id={id}
          autoFocus={autoFocus}
          className={classnames(className, { errored })}
          defaultValue={postField.value}
          disabled={disabled}
          maxRows={10}
          minRows={1}
          onChange={(e) => onChange(e.currentTarget.value, postField)}
          placeholder={postField.placeholder}
          ref={this.inputRef}
        />
      );
    } else if (postField.type === CustomFieldTypes.dropdown.name) {
      const defaultSelectedName =
        postField.value && postField.options?.includes(postField.value) ? postField.value : null;
      return (
        <Dropdown
          id={id}
          aria-label={postField.label || postField.name}
          className={classnames(className, { errored })}
          defaultSelectedName={defaultSelectedName}
          dropdownClassName="customPostFieldDropdownInput"
          disabled={disabled}
          onChange={(option) => onChange(option, postField)}
          options={postField.options?.map((option) => {
            return {
              name: option,
              render: option,
            };
          })}
          placeholder={!defaultSelectedName ? postField.placeholder : null}
          truncate={truncate}
        />
      );
    } else if (postField.type === CustomFieldTypes.multiselect.name) {
      const defaultSelectedNames = postField.value?.filter((option) =>
        postField.options?.includes(option)
      );
      return (
        <Dropdown
          id={id}
          className={classnames(className, { errored })}
          defaultSelectedNames={defaultSelectedNames}
          disabled={disabled}
          dropdownClassName="customPostFieldDropdownInput"
          multiselect={true}
          onChange={(selectedOptions) => {
            const options = selectedOptions.filter((selectedOption) =>
              postField.options?.includes(selectedOption)
            );
            onChange(options, postField);
          }}
          options={postField.options?.map((option) => {
            return {
              name: option,
              render: option,
            };
          })}
          placeholder={!defaultSelectedNames?.length ? postField.placeholder : null}
          truncate={truncate}
        />
      );
    }

    return null;
  }
}

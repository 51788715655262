import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import Card from 'common/common/Card';
import { LoadStatus, type LocalFile } from 'common/constants/files';
import LazyLoadedImage from 'common/LazyLoadedImage';
import SpinnerV2 from 'common/SpinnerV2';

import 'css/components/image/_FormImagesItem.scss';

type Props = {
  allowRemove: boolean;
  image: LocalFile;
  onImageClicked: (image: LocalFile) => void;
  onImageRemoved?: (image: LocalFile) => void;
};

const FormImagesItem = ({ allowRemove, image, onImageClicked, onImageRemoved }: Props) => {
  const [downloadStatus, setDownloadStatus] = useState(LoadStatus.loading);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const displayImage = downloadStatus === LoadStatus.loaded;
  const displayLoader =
    downloadStatus === LoadStatus.loading || image.uploadStatus === LoadStatus.loading;

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onImageClicked(image);
  };

  const onLoad = useCallback(() => {
    setDownloadStatus(LoadStatus.loaded);
  }, []);

  const onRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!allowRemove || !onImageRemoved) {
      return;
    }

    onImageRemoved(image);
  };

  useLayoutEffect(() => {
    if (!imageRef.current?.complete) {
      return;
    }

    onLoad();
  }, [onLoad]);

  return (
    <div
      className="formImagesItem"
      style={{ display: displayImage || displayLoader ? 'block' : 'none' }}>
      <button className="formImageButton" onClick={onClick} disabled={!displayImage}>
        {image.url && (
          <LazyLoadedImage
            ref={imageRef}
            style={{ display: displayImage ? 'block' : 'none' }}
            alt={image.url}
            src={image.url}
            onLoad={onLoad}
          />
        )}
        {displayLoader && (
          <Card className="formImagesCard" borderStyle="solid">
            <SpinnerV2 size="medium" />
          </Card>
        )}
      </button>
      {displayImage && allowRemove && (
        <button className="removeImageButton" onClick={onRemove}>
          <span className="icon icon-x" />
        </button>
      )}
    </div>
  );
};

export default FormImagesItem;

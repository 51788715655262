import React, { Component } from 'react';

import classnames from 'classnames';
import { Book, HelpCircle, LogOut, MessagesSquare, Plus, Settings } from 'lucide-react';
import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import Portal from 'common/common/Portal';
import Tooltip from 'common/common/Tooltip';
import { ViewerContext } from 'common/containers/ViewerContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import Tappable from 'common/Tappable';
import { P } from 'common/ui/Text';
import UserAvatar from 'common/user/UserAvatar';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminNavAccountMenu.scss';

import CannyIcon from 'img/canny-icon.svg';

class AdminNavAccountMenu extends Component {
  static propTypes = {
    viewer: PropTypes.shape({
      companies: PropTypes.array,
    }),
  };

  state = {
    menuOpen: false,
  };

  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
  }

  onDropdownBlur = (e) => {
    const { menuOpen } = this.state;
    if (!menuOpen) {
      return;
    }

    this.setState({ menuOpen: false });
  };

  onLogoutTapped = async () => {
    const response = await AJAX.post('/api/viewer/logout', {});
    if (response !== 'success') {
      return;
    }

    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    document.location.href = devURL('https://canny.io');
  };

  onToggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  renderUserProfile() {
    const { viewer } = this.props;
    if (!viewer || !viewer._id) {
      return null;
    }

    return (
      <button onClick={this.onToggleMenu} className="menuButton">
        <div className="userProfile">
          <UserAvatar user={viewer} size="medium" className="avatar" />
        </div>
      </button>
    );
  }

  renderCompanies() {
    const {
      viewer: { companies },
    } = this.props;

    const items = [];
    if (companies.length > 1) {
      companies.forEach((company) => {
        const companyLogo = company.logoURL;
        const companyURL =
          company.integrationAuthRedirectionURL ??
          devURL('https://' + company.subdomain + '.canny.io/admin');
        items.push(
          <a key={company._id} className="company menuItem" href={companyURL}>
            <LazyLoadedImage
              className="companyLogo"
              src={companyLogo ?? CannyIcon}
              alt=""></LazyLoadedImage>
            <P className="itemName">{company.name}</P>
          </a>
        );
      });
    }

    const newCompanyURL = devURL('https://canny.io/register');
    return (
      <div className="companies section">
        <div className="sectionTitleWrapper">
          <p className="sectionTitle">Workspaces</p>
          <Tooltip
            className="newCompanyLinkTooltip"
            delay={300}
            position="left"
            value={'Create a new workspace'}>
            <a href={newCompanyURL} className="newCompanyLink" aria-label="Create a new workspace">
              <Plus size={14} />
            </a>
          </Tooltip>
        </div>
        {items}
      </div>
    );
  }

  renderAccountMenu() {
    const {
      viewer: { companies },
    } = this.props;
    return (
      <>
        <div
          className={classnames('menuItems adminMenu', {
            shrink: companies.length <= 1,
          })}>
          {this.renderCompanies()}
          <div className="section spacedOut">
            <a
              className="menuItem"
              href={devURL('https://developers.canny.io/install')}
              rel="noopener"
              target="_blank">
              <Book size={16} className="lucide-icon" />
              <P className="itemName">Docs</P>
            </a>
            <a className="menuItem" href="https://help.canny.io/" rel="noopener" target="_blank">
              <HelpCircle size={16} className="lucide-icon" />
              <P className="itemName">Help center</P>
            </a>
            <a
              className="menuItem"
              href={devURL('https://feedback.canny.io')}
              rel="noopener"
              target="_blank">
              <MessagesSquare size={16} className="lucide-icon" />
              <P className="itemName">Canny feedback</P>
            </a>
          </div>
          <div className="section spacedOut">
            <Link className="menuItem" onTap={this.onToggleMenu} to="/admin/settings/account">
              <Settings size={16} className="lucide-icon" />
              <P className="itemName">Settings</P>
            </Link>
            <Tappable onTap={this.onLogoutTapped}>
              <div className="menuItem">
                <LogOut size={16} className="lucide-icon" />
                <P className="itemName">Logout</P>
              </div>
            </Tappable>
          </div>
        </div>
      </>
    );
  }

  renderPortal() {
    if (!this.state.menuOpen) {
      return null;
    }

    return (
      <Portal
        align="end"
        className={classnames('dropdownPortal', 'adminNavAccountMenuPortal')}
        onBlur={this.onDropdownBlur}
        position="bottom"
        relativeToRef={this.containerRef}>
        {this.renderAccountMenu()}
      </Portal>
    );
  }

  render() {
    return (
      <div className="adminNavAccountMenu" ref={this.containerRef}>
        {this.renderUserProfile()}
        {this.renderPortal()}
      </div>
    );
  }
}

export default withContexts({ viewer: ViewerContext })(AdminNavAccountMenu);

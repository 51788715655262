import React from 'react';

import { loadZoomSettings } from 'common/actions/zoomSettings';
import asyncConnect from 'common/core/asyncConnect';

import type { ZoomSettings } from 'common/api/endpoints/zoom';
import type { State } from 'redux-connect';

type Props = {
  zoomSettings: ZoomSettings;
  children: React.ReactNode;
};

export const ZoomSettingsContext = React.createContext<ZoomSettings>({
  data: { installation: { companyID: '', installed: false, lostAccess: false, userEmail: null } },
  error: null,
  lastUpdated: 0,
  loading: false,
});

const ZoomSettingsContainer = ({ children, zoomSettings }: Props) => (
  <ZoomSettingsContext.Provider value={zoomSettings}>{children}</ZoomSettingsContext.Provider>
);

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadZoomSettings()),
    },
  ],
  (state: State) => ({ zoomSettings: state.zoomSettings })
)(ZoomSettingsContainer);

import React, { useState } from 'react';

import { Check, Trash, X } from 'lucide-react';

import Tooltip from 'common/common/Tooltip';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import ButtonV2 from 'common/ui/ButtonV2';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_CommonActionsCell.module.scss';

import type { SpamRow as Row } from '../types';

type ActionsCellProps = {
  row: Row;
};

enum Modal {
  reject = 'reject',
}

enum Action {
  approve = 'approve',
  reject = 'reject',
  delete = 'delete',
}

const SpamActionsCell = ({ row }: ActionsCellProps) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const [loadingAction, setLoadingAction] = useState<Action | null>();

  const openRejectModal = () => {
    setModal(Modal.reject);
  };

  const performApprove = async () => {
    setLoadingAction(Action.approve);
    await row.actions.approve();
    setLoadingAction(null);
  };

  const performReject = async () => {
    setLoadingAction(Action.reject);
    setModal(null);
    await row.actions.reject();
    setLoadingAction(null);
  };

  const performDelete = async () => {
    setLoadingAction(Action.delete);
    await row.actions.delete();
    setLoadingAction(null);
  };

  return (
    <div className={styles.queueActions}>
      <ButtonV2
        aria-label="mark as spam"
        data-action="mark-as-spam"
        disabled={row.loading}
        loading={loadingAction === Action.reject}
        onClick={openRejectModal}
        size="medium"
        variant="outlined"
        startIcon={Check}>
        Mark as spam
      </ButtonV2>
      <ButtonV2
        aria-label="mark as not spam"
        disabled={row.loading}
        loading={loadingAction === Action.approve}
        onClick={performApprove}
        size="medium"
        variant="outlined"
        startIcon={X}>
        Not spam
      </ButtonV2>
      <Tooltip delay={1000} value={`Delete ${row.item.itemType}`} position="left">
        <IconButtonV2
          icon={Trash}
          aria-label="delete"
          disabled={row.loading}
          size="medium"
          variant="outlined"
          onClick={performDelete}
        />
      </Tooltip>
      {modal === Modal.reject && (
        <ModernConfirmModal
          onClose={() => setModal(null)}
          onConfirm={performReject}
          confirmText="Ban and delete user"
          type="destructive"
          header="Are you sure you want to ban and delete this user?">
          <P>The following action will:</P>
          <ul>
            <li>Delete all of their posts, comments, and votes.</li>
            <li>Ban them from voting, along with creating future posts and comments.</li>
          </ul>
          <P>This action cannot be&nbsp;reversed.</P>
        </ModernConfirmModal>
      )}
    </div>
  );
};

export default SpamActionsCell;

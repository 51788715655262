import Data from '../Data';

// Action Types

export const Invalidate = 'canny/third_party_companies/invalidate';
export const RequestCompany = 'canny/third_party_companies/request_company';
export const CompanyLoaded = 'canny/third_party_companies/company_loaded';
export const CompanyNotFound = 'canny/third_party_companies/company_not_found';
export const CompanyError = 'canny/third_party_companies/company_error';

// Actions

function requestCompany(urlName) {
  return {
    timestamp: Date.now(),
    type: RequestCompany,
    urlName,
  };
}

export function companyLoaded(urlName, company) {
  return {
    company,
    timestamp: Date.now(),
    type: CompanyLoaded,
    urlName,
  };
}

function companyNotFound(urlName) {
  return {
    timestamp: Date.now(),
    type: CompanyNotFound,
    urlName,
  };
}

function companyError(urlName, error) {
  return {
    error,
    timestamp: Date.now(),
    type: CompanyError,
    urlName,
  };
}

function invalidate() {
  return {
    timestamp: Date.now(),
    type: Invalidate,
  };
}

// Action Creators

function fetchThirdPartyCompany(urlName) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        thirdPartyCompany: {
          input: {
            urlName,
          },
          query: Data.queries.thirdPartyCompany,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, getState(), urlName, error, data);
      }
    );
  };
}

export function loadThirdPartyCompany(urlName) {
  return (dispatch, getState) => {
    if (shouldFetchCompany(getState(), urlName)) {
      dispatch(requestCompany(urlName));
      return dispatch(fetchThirdPartyCompany(urlName));
    } else if (isFetchingCompany(getState(), urlName)) {
      return waitForResult(urlName);
    }
  };
}

export function invalidateCompanies() {
  return (dispatch) => {
    return dispatch(invalidate());
  };
}

// Helper Functions

function shouldFetchCompany(state, urlName) {
  const { thirdPartyCompanies } = state;
  const thirdPartyCompany = thirdPartyCompanies[urlName];
  return !thirdPartyCompany;
}

function isFetchingCompany(state, urlName) {
  if (!urlName) {
    return false;
  }

  return !!state.thirdPartyCompanies[urlName].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(urlName) {
  return new Promise((resolve, reject) => {
    resultCallbacks[urlName] = resultCallbacks[urlName] || [];
    resultCallbacks[urlName].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, state, urlName, error, data) {
  var resultAction;
  if (error === 'not found') {
    resultAction = companyNotFound(urlName);
  } else if (error) {
    resultAction = companyError(urlName, error);
  } else {
    resultAction = companyLoaded(urlName, data.thirdPartyCompany);
  }

  return Promise.all([dispatch(resultAction)]).then(() => {
    if (!resultCallbacks[urlName]) {
      return;
    }

    resultCallbacks[urlName].forEach((resultCallback) => {
      resultCallback();
    });
    resultCallbacks[urlName].length = 0;
  });
}

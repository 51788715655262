import React, { useContext, useEffect } from 'react';

import { RoadmapsContext } from 'common/containers/RoadmapsContainer';
import SingleSelect from 'common/ui/SingleSelect';

import type { AddToRoadmap as AddToRoadmapType, Filter } from 'common/automations/constants';
import type { Option } from 'common/ui/common/select/SelectCommon';

interface Props {
  filters: Filter[];
  properties: AddToRoadmapType['properties'];
  onChange: (properties?: AddToRoadmapType['properties']) => void;
}

const AddToRoadmap = ({ filters, ...props }: Props) => {
  const { roadmaps } = useContext(RoadmapsContext);

  const { properties, onChange } = props;
  const roadmapsAreValid = roadmaps && roadmaps.length > 0;

  const roadmapOptions = roadmapsAreValid
    ? roadmaps
        .filter((roadmap) => !roadmap.archived && !roadmap.deleted)
        .map((roadmap) => ({ label: roadmap.name, value: roadmap._id }))
    : null;
  const selectedOption = roadmapOptions?.find((option) => option.value === properties.roadmapID);

  // Set the roadmap to the first one
  useEffect(() => {
    if (roadmapOptions && !selectedOption) {
      onChange({ roadmapID: roadmapOptions[0].value });
    }
  }, [roadmapOptions, onChange, selectedOption]);

  // Should probably never happen, companies must have at least one roadmap
  if (!roadmapOptions || !selectedOption) {
    return null;
  }

  return (
    <SingleSelect
      className="grid2"
      placeholder="Select a roadmap"
      onChange={(option) => onChange({ roadmapID: (option as Option).value })}
      options={roadmapOptions}
      value={selectedOption}
      optionsMaxWidth="300px"
    />
  );
};

export default AddToRoadmap;

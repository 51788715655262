import React, { Component } from 'react';

import BoardsContainer from 'common/containers/BoardsContainer';

import AdminBoardList from './AdminBoardList';

import 'css/components/subdomain/admin/_AdminBoardSettingsReorderModal.scss';

export default class AdminBoardSettingsReorderModal extends Component {
  render() {
    return (
      <div className="adminBoardSettingsReorderModal">
        <div className="reorderHeader">Drag to reorder your boards</div>
        <BoardsContainer>
          <AdminBoardList
            alwaysShowDrag={true}
            linkToBoards={false}
            noBoardsMessage="You don't have any boards yet."
            showHeader={false}
            showLinks={false}
            showPostCount={false}
          />
        </BoardsContainer>
      </div>
    );
  }
}

import React from 'react';

import { ArrowLeft, PanelRightClose } from 'lucide-react';

import TextInput from 'common/inputs/TextInput';
import Button from 'common/ui/ButtonV2';
import IconButton from 'common/ui/IconButtonV2';
import { Span } from 'common/ui/Text';

type Props = {
  onClose: () => void;
  updateViewName: (name: string) => void;
  setCreatingView: (creating: boolean) => void;
  viewName: string;
};

const SettingsModalCreateViewHeader = ({
  onClose,
  setCreatingView,
  updateViewName,
  viewName,
}: Props) => {
  return (
    <>
      <div className="modalHeaderTitle">
        <Button
          size="medium"
          color="primary"
          startIcon={ArrowLeft}
          variant="plain"
          onClick={() => setCreatingView(false)}>
          Back to all filters
        </Button>
        <IconButton
          onClick={onClose}
          icon={PanelRightClose}
          aria-label="Close filter panel"
          variant="plain"
          size="medium"
        />
      </div>
      <div className="modalHeaderText">
        <label htmlFor="titleInput">
          Title <Span className="requiredField">*</Span>
        </label>
        <TextInput
          id="titleInput"
          onChange={(e) => updateViewName(e.target.value)}
          placeholder="e.g. Critical Feedback"
          value={viewName}
        />
      </div>
    </>
  );
};

export default SettingsModalCreateViewHeader;

import {
  type AsyncItem,
  type MapDispatchToProps,
  type MapStateToProps,
  type Options,
  asyncConnect,
} from 'redux-connect';

export default function cannyAsyncConnect<Props>(
  asyncItems: AsyncItem[],
  mapStateToProps?: MapStateToProps,
  mapDispatchToProps?: MapDispatchToProps,
  options?: Options
) {
  return asyncConnect<Props>(asyncItems, mapStateToProps, mapDispatchToProps, null, options);
}

export default {
  about: true,
  aboutus: true,
  abuse: true,
  acme: true,
  ad: true,
  ads: true,
  adsense: true,
  admin: true,
  affiliate: true,
  analytics: true,
  andrew: true,
  android: true,
  api: true,
  api2: true,
  app: true,
  asdf: true,
  assets: true,
  auth: true,
  bae: true,
  beta: true,
  blog: true,
  blog2: true,
  blog3: true,
  buy: true,
  calendar: true,
  canny: true,
  cdn: true,
  changelog: true,
  'changelog-widget': true,
  chat: true,
  checkout: true,
  client: true,
  cname: true,
  code: true,
  community: true,
  content: true,
  css: true,
  dashboard: true,
  data: true,
  dev: true,
  developer: true,
  developers: true,
  docs: true,
  email: true,
  emailtracking: true,
  error: true,
  experiments: true,
  featurerequests: true,
  features: true,
  ftp: true,
  fuck: true,
  'give-feedback': true,
  go: true,
  help: true,
  holla: true,
  http: true,
  https: true,
  'hubspot-integration': true,
  image: true,
  images: true,
  imap: true,
  investor: true,
  ios: true,
  ipad: true,
  iphone: true,
  job: true,
  jobs: true,
  js: true,
  legal: true,
  local: true,
  locale: true,
  log: true,
  loopify: true,
  mail: true,
  manage: true,
  metabase: true,
  metrics: true,
  mis: true,
  mobile: true,
  my: true,
  new: true,
  ns: true,
  ns1: true,
  ns2: true,
  ns3: true,
  ns4: true,
  ns5: true,
  oauth: true,
  porn: true,
  partner: true,
  popd3: true,
  pricing: true,
  private: true,
  prod: true,
  production: true,
  'product-seen': true,
  ramatestingcompany: true,
  register: true,
  roadmap: true,
  rss: true,
  sarah: true,
  sdk: true,
  search: true,
  server: true,
  'sgmnt-api': true,
  'sgmnt-cdn': true,
  shop: true,
  sl: true,
  slut: true,
  smtp: true,
  soap: true,
  ssl: true,
  static: true,
  status: true,
  'storybook-demo': true,
  success: true,
  test: true,
  testing: true,
  try: true,
  video: true,
  videos: true,
  vids: true,
  webview: true,
  whois: true,
  widget: true,
  wiki: true,
  w: true,
  ww: true,
  www: true,
  xxx: true,
} as Record<string, true>;

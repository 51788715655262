import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { invalidateSuggestions, loadSuggestions } from 'common/actions/githubIssueSuggestions';
import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import AdminIntegrationSearchForm from 'common/subdomain/admin/AdminIntegrationSearchForm';
import parseAPIResponse from 'common/util/parseAPIResponse';

class AdminGitHubIssueForm extends Component {
  static propTypes = {
    post: PropTypes.object,
    issueSuggestions: PropTypes.object,
  };

  state = {
    error: null,
  };

  onAddIssue = async (issue) => {
    const { post, reloadPost } = this.props;

    this.setState({ error: null });

    const response = await AJAX.post('/api/github/linkIssue', {
      issueID: issue.node_id,
      postID: post._id,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse?.issue,
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    await reloadPost(post);
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  renderSuggestion(suggestion) {
    return `#${suggestion?.number}: ${suggestion?.title}`;
  }

  render() {
    const { issueSuggestions, loadSuggestions } = this.props;

    return (
      <div className="adminGitHubIssueForm">
        <AdminIntegrationSearchForm
          loadSuggestions={loadSuggestions}
          onSuggestionSelected={this.onAddIssue}
          placeholder={'Search for an issue...'}
          renderSuggestion={this.renderSuggestion}
          suggestions={issueSuggestions}
        />
        {this.renderError()}
      </div>
    );
  }
}

export default connect(
  (state) => ({ issueSuggestions: state.githubIssueSuggestions }),
  (dispatch) => ({
    loadSuggestions: (issueSearch) => dispatch(loadSuggestions(issueSearch)),
    reloadPost: (post) => {
      return Promise.all([dispatch(invalidateSuggestions()), dispatch(reloadPost(post))]);
    },
  })
)(AdminGitHubIssueForm);

import {
  Invalidate,
  LoadingMore,
  RequestUserPosts,
  UserPostsError,
  UserPostsLoaded,
  getUserPostsKey,
} from '../actions/userPosts';

export default function userPosts(state = {}, action) {
  switch (action.type) {
    case RequestUserPosts: {
      const queryKey = getUserPostsKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case Invalidate: {
      const queryKey = getUserPostsKey(action.queryParams);
      return {
        ...state,
        [queryKey]: null,
      };
    }

    case UserPostsLoaded: {
      const { postList, commentedPostIDs, userVoteMap } = action.result;
      const queryKey = getUserPostsKey(action.queryParams);
      const postIDs = postList.posts.map((post) => post._id);

      const postActivity = postList.posts.reduce((map, post) => {
        map[post._id] = {
          hasCommentByUser: commentedPostIDs.includes(post._id),
          hasVoteByUser: !!userVoteMap[post._id],
          wasAuthoredByUser: post.authorID === action.queryParams.user._id,
          vote: userVoteMap[post._id],
        };
        return map;
      }, {});

      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          postIDs,
          postActivity,
          hasNextPage: postList.hasNextPage,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case UserPostsError: {
      const queryKey = getUserPostsKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LoadingMore: {
      const queryKey = getUserPostsKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    default:
      return state;
  }
}

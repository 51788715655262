import React from 'react';

import Helmet from 'common/helmets/Helmet';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import AdminSettingsSidebarLink from 'common/subdomain/admin/AdminSettingsSidebarLink';
import { RoutePermissions } from 'common/util/permissions';

import 'css/components/subdomain/admin/_AdminGeneralSettingsContainer.scss';

type GeneralSidebarLink = {
  label: string;
  urlSuffix: keyof typeof RoutePermissions.adminSettings.security;
};

const SidebarLinks: GeneralSidebarLink[] = [
  {
    label: 'Email Authentication',
    urlSuffix: 'email-auth',
  },
  {
    label: 'Secure Identify',
    urlSuffix: 'secure-identify',
  },
  {
    label: 'SSO Redirect',
    urlSuffix: 'sso-redirect',
  },
];

const AdminSecuritySettingsContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="adminGeneralSettingsContainer">
      <Helmet title="General settings | Canny" />
      <AdminSettingsHeader title="General" subheading="Manage your workspace's&nbsp;settings." />
      <div className="settingsContainer">
        <div className="settingsSidebar">
          {SidebarLinks.map(({ label, urlSuffix }) => {
            return (
              <AdminSettingsSidebarLink
                key={urlSuffix}
                requiredPermissions={RoutePermissions.adminSettings.security[urlSuffix]}
                to={`/admin/settings/security/${urlSuffix}`}>
                {label}
              </AdminSettingsSidebarLink>
            );
          })}
        </div>
        <div className="settingsContent">{children}</div>
      </div>
    </div>
  );
};

export default AdminSecuritySettingsContainer;

import React, { useContext } from 'react';

import classnames from 'classnames';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import Truncate from 'common/common/Truncate';
import { CompanyContext } from 'common/containers/CompanyContainer';
import Link from 'common/Link';
import plaintext from 'common/markdown/plaintext';
import PostStatus from 'common/post/PostStatus';
import PostTitle from 'common/post/PostTitle';
import VoteWeightPill from 'common/post/VoteWeightPill';

import type { UserPost } from 'common/api/endpoints/userPosts';

import 'css/components/subdomain/admin/_AdminUsersPostListItem.scss';

type Props = {
  post: UserPost;
};

const AdminUsersPostListItem = ({ post }: Props) => {
  const company = useContext(CompanyContext);
  const isCompanySelected =
    !post.wasAuthoredByUser && !post.hasVoteByUser && !post.hasCommentByUser;

  return (
    <Link to={`/admin/feedback/${post.board.urlName}/p/${post.urlName}`}>
      <div className="adminUsersPostListItem">
        <div className={classnames('activityType', { hidden: isCompanySelected })}>
          {post.wasAuthoredByUser && (
            <Tooltip delay={300} value="Authored this&nbsp;post" position="left">
              <div className="icon icon-pencil" />
            </Tooltip>
          )}
          {post.hasVoteByUser && (
            <Tooltip delay={300} value="Voted on this&nbsp;post" position="left">
              <div className="upvote" />
            </Tooltip>
          )}
          {post.hasCommentByUser && (
            <Tooltip delay={300} value="Commented on this&nbsp;post" position="left">
              <div className="icon icon-comment-solid" />
            </Tooltip>
          )}
        </div>
        <div className="postContent">
          <div className="postTitleWithPill">
            <PostTitle post={post} />
            {post.spam ? (
              <Pill className="postTitlePill" pillStyle={DefaultPillStyles.destructive}>
                Spam
              </Pill>
            ) : null}
          </div>

          {post.details && (
            <div className="postDetails">
              <Truncate numberOfLines={2}>{plaintext(post.details)}</Truncate>
            </div>
          )}
          <div className="postInfo">
            <div className="counts">
              <div className="votes">
                <div className="upvote" />
                <div className="score">{post.score}</div>
              </div>
              <div className="commentCount">
                <div className="icon icon-comment-solid" />
                <div className="count">{post.commentCount}</div>
              </div>
              {post.hasVoteByUser && company?.features?.voteWeights && (
                <VoteWeightPill weight={post.vote.weight} />
              )}
            </div>
            <PostStatus status={post.status} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AdminUsersPostListItem;

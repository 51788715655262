import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Tappable from '../Tappable';

import 'css/components/_TextInput.scss';

// Omit the 'prefix' property from HTMLAttribute since we want to override it here
type OmitProperties = 'prefix';

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, OmitProperties> {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  inset?: React.ReactNode;

  // These are redundant, but just want to explicitly outline that we handle these differently
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;

  prefix?: React.ReactNode;
  readonly?: boolean;
  stopPropagation?: boolean;
  suffix?: React.ReactNode;
  type?: string;
  value?: string;
}

type State = {
  focused: boolean;
  value: string;
};

export default class TextInput extends Component<Props, State> {
  static propTypes = {
    autoFocus: PropTypes.any,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    inset: PropTypes.node,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    prefix: PropTypes.node,
    readonly: PropTypes.bool,
    stopPropagation: PropTypes.bool,
    suffix: PropTypes.node,
    type: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    stopPropagation: true,
    type: 'text',
  };

  state = {
    focused: !!this.props.autoFocus,
    value: this.props.defaultValue || this.props.value || '',
  };

  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    // workaround to avoid autofocus scrolling the page
    if (this.props.autoFocus && this.inputRef.current) {
      this.inputRef.current.focus({
        preventScroll: true,
      });
    }
  }

  getValue = () => {
    return this.inputRef.current?.value;
  };

  setValue = (value: string) => {
    this.setState({
      value,
    });
  };

  setCursorPosition = (cursorPosition: number) => {
    this.inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
  };

  focus = () => {
    this.inputRef.current?.focus();
  };

  blur = () => {
    this.inputRef.current?.blur();
  };

  select = () => {
    this.inputRef.current?.select();
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onBlur && this.props.onBlur(e);
    this.setState({
      focused: false,
    });
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(e);
    this.setState({
      value: e.target.value,
    });
  };

  onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onFocus && this.props.onFocus(e);
    this.setState({
      focused: true,
    });
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    this.props.onKeyDown && this.props.onKeyDown(e);
  };

  renderInset() {
    const { inset } = this.props;
    if (!inset) {
      return null;
    }

    return <div className="inset">{inset}</div>;
  }

  renderPrefix() {
    const { prefix } = this.props;
    if (!prefix) {
      return null;
    }

    return <div className="prefix">{prefix}</div>;
  }

  renderSuffix() {
    const { suffix } = this.props;
    if (!suffix) {
      return null;
    }

    return <div className="suffix">{suffix}</div>;
  }

  render() {
    let className = 'textInput';
    if (this.props.className) {
      className += ' ' + this.props.className;
    }
    if (this.state.focused) {
      className += ' focused';
    }

    if (this.props.disabled) {
      className += ' disabled';
    }

    // Deleting keys doesn't work nicely, shoehorn this type for now
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputProps = { ...this.props } as any;
    Object.keys(TextInput.propTypes).forEach((propType) => {
      delete inputProps[propType];
    });

    const value = typeof this.props.value === 'string' ? this.props.value : this.state.value;
    return (
      <Tappable onTap={this.focus} stopPropagation={this.props.stopPropagation}>
        <div className={className}>
          {this.renderInset()}
          <div className="inputContainer">
            {this.renderPrefix()}
            <input
              {...inputProps}
              type={this.props.type}
              disabled={this.props.disabled}
              key="input"
              ref={this.inputRef}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onKeyDown={this.onKeyDown}
              value={value}
            />
            {this.renderSuffix()}
          </div>
        </div>
      </Tappable>
    );
  }
}

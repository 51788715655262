import { RelativeDateOptions, RelativeDateRanges } from 'common/util/dateRanges';
import validateInput from 'common/validateInput';

const getDateRangeFromQuery = (
  dateValue: [RelativeDateRanges] | [string, string]
): string | [string, string] => {
  if (dateValue.length === 1) {
    const relativeDate = RelativeDateOptions[dateValue[0]];
    return relativeDate?.value;
  }
  if (dateValue.length === 2) {
    const filteredDateValue = dateValue.filter((date: string) =>
      validateInput.primitives.isoString(date)
    );
    const [from, to] = filteredDateValue;

    const areDatesValid = new Date(from) <= new Date(to);
    return areDatesValid ? [from, to] : RelativeDateRanges.allTime;
  }
  return RelativeDateRanges.allTime;
};

export default getDateRangeFromQuery;

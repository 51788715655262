import React, { useContext, useState } from 'react';

import { Info, Sparkles, ToyBrick } from 'lucide-react';

import Colors from 'common/colors/constants';
import Card from 'common/common/Card';
import Tooltip from 'common/common/Tooltip';
import { SyncIntegrationNames } from 'common/constants/autopilotIntegrations';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import UpsellModal from 'common/modals/UpsellModal';
import ButtonV2 from 'common/ui/ButtonV2';
import LoadingBar from 'common/ui/LoadingBar';
import { P } from 'common/ui/Text';
import getPlanNameForTrial from 'common/util/getPlanNameForTrial';
import { GrowthPlanID } from 'common/util/isGrowth';
import isStarter, { StarterPlanID } from 'common/util/isStarter';
import nbspLastSpace from 'common/util/nbspLastSpace';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationPlanCard.module.scss';

import type { Company } from 'common/api/endpoints/companies';

const IntegrationCount = ({
  integrationCount,
  integrationLimit,
}: {
  integrationCount: number;
  integrationLimit: number | null;
}) => {
  if (integrationLimit) {
    return (
      <div className={styles.integrationCount}>
        <P fontWeight="medium" className={styles.integrationCountText}>
          <ToyBrick
            aria-hidden
            className={styles.icon}
            size={14}
            strokeWidth={2}
            color={Colors.gray120}
          />
          {integrationCount} / {integrationLimit}
          <span className={styles.countSubtext}>Integrations</span>
        </P>
        <LoadingBar percentage={(integrationCount / integrationLimit) * 100} />
      </div>
    );
  }

  return (
    <div className={styles.integrationCount}>
      <P fontWeight="medium" className={styles.integrationCountText}>
        <ToyBrick
          aria-hidden
          className={styles.icon}
          size={14}
          strokeWidth={2}
          color={Colors.gray120}
        />
        {integrationCount} / ∞ <span className={styles.countSubtext}>Integrations</span>
      </P>
    </div>
  );
};

enum Modal {
  upsell = 'upsell',
}

const AdminIntegrationPlanCard = () => {
  const { integrationCount, integrationLimit } = useContext(ActiveIntegrationContext);
  const company = useContext<Company>(CompanyContext);

  const enabledNonAPIDataSyncs = company.installedSyncIntegrations.filter(
    ({ integrationName, disabled }) => integrationName !== SyncIntegrationNames.api && !disabled
  );

  const planName = company.trialingPlan
    ? `${getPlanNameForTrial(company.trialingPlan)} (trial)`
    : company.billingData?.plan?.name;

  const [modal, setModal] = useState<Modal | null>(null);

  const planID = integrationLimit === 1 ? StarterPlanID : GrowthPlanID;
  const amount = isStarter(planID) ? 'More' : 'Unlimited';

  const planToolTip = `The ${planName} plan gets ${integrationLimit ?? 'unlimited'} integration${
    integrationLimit !== 1 ? 's' : ''
  }, and unlimited sources`;

  return (
    <Card borderStyle="solid" className={styles.card}>
      <div className={styles.planId}>
        <P fontWeight="medium">{planName}</P>
        <Tooltip containerClassName={styles.tooltip} value={planToolTip}>
          <Info size={14} strokeWidth={2} color={Colors.gray90} />
        </Tooltip>
      </div>
      <ul>
        <li>
          <IntegrationCount
            integrationCount={integrationCount}
            integrationLimit={integrationLimit}
          />
        </li>
        <li>
          <P fontWeight="medium" className={styles.dataSyncCount}>
            <Sparkles
              aria-hidden
              className={styles.icon}
              size={14}
              strokeWidth={2}
              color={Colors.gray120}
            />
            {enabledNonAPIDataSyncs.length} / ∞ <span className={styles.countSubtext}>Sources</span>
          </P>
        </li>
      </ul>
      {integrationLimit && (
        <ButtonV2
          size="medium"
          variant="outlined"
          className={styles.upgradeButton}
          onClick={() => setModal(Modal.upsell)}>
          Upgrade Plan
        </ButtonV2>
      )}
      {modal && (
        <UpsellModal
          cta={nbspLastSpace(`${amount} integrations to suit your workflow`)}
          feature={'limits.integrations'}
          onClose={() => setModal(null)}
          planID={planID}
          show
        />
      )}
    </Card>
  );
};

export default AdminIntegrationPlanCard;

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from 'common/common/ErrorBoundary';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import withContexts from 'common/util/withContexts';

import CustomPostFields from './CustomPostFields';
import PostActivity from './PostActivity';
import PostBody from './PostBody';
import PostHeader from './PostHeader';
import PostMenu from './PostMenu';
import PostStatusChange from './PostStatusChange';
import Summary from './Summary';
import Comment from '../comment/Comment';
import CommentComposer from '../comment/CommentComposer';
import UserLockup from '../user/UserLockup';

import 'css/components/post/_Post.scss';

class Post extends Component {
  static propTypes = {
    allowUnmerge: PropTypes.bool,
    board: PropTypes.object.isRequired,
    company: PropTypes.shape({
      viewerIsMember: PropTypes.bool,
    }),
    onEdit: PropTypes.func,
    onMarkedSpam: PropTypes.func,
    onPostDeleted: PropTypes.func,
    onToggleTranslation: PropTypes.func,
    post: PropTypes.shape({
      title: PropTypes.string,
      details: PropTypes.string,
    }),
    postActivity: PropTypes.shape({
      activities: PropTypes.array,
      commentReplies: PropTypes.array,
      comments: PropTypes.object,
      mergedPosts: PropTypes.object,
      statusHistories: PropTypes.object,
    }),
    showCustomPostFields: PropTypes.bool,
    showHeader: PropTypes.bool,
    showStatus: PropTypes.bool,
    showSummary: PropTypes.bool,
    viewType: PropTypes.oneOf(['public', 'admin']),
    viewer: PropTypes.shape({
      _id: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    additionalMenu: null,
    allowUnmerge: false,
    showCommentComposer: true,
    showHeader: true,
    showStatus: true,
    showSummary: false,
  };

  state = {
    hideTranslation: false,
  };

  constructor(props, context) {
    super(props, context);

    this.postActivityRef = React.createRef();
  }

  onScroll = (e, scrollContainer) => {
    const postActivity =
      this.postActivityRef.current && this.postActivityRef.current.getWrappedInstance();
    if (!postActivity) {
      return;
    }

    postActivity.onScroll(e, scrollContainer);
  };

  onToggleTranslation = () => {
    const newValue = !this.state.hideTranslation;
    if (this.props.onToggleTranslation) {
      this.props.onToggleTranslation(newValue);
    }
    this.setState({
      hideTranslation: newValue,
    });
  };

  renderAuthor() {
    const {
      company: { viewerIsMember },
      board: { settings: boardSettings },
      post,
      viewer,
      viewType,
    } = this.props;
    if (!post.author) {
      return null;
    }

    const isAuthorViewer = post.author._id === viewer._id;
    const isPublic = viewType === 'public';
    const showAlias =
      boardSettings?.privateAuthors && isPublic && isAuthorViewer && !viewerIsMember;

    return (
      <div className="postAuthor">
        <UserLockup user={post.author} showAlias={showAlias} />
      </div>
    );
  }

  renderHeader() {
    const { post, showHeader, showStatus } = this.props;
    if (!showHeader) {
      return null;
    }

    const { hideTranslation } = this.state;
    return (
      <div className="headerContainer">
        {this.props.additionalMenu}
        <PostHeader hideTranslation={hideTranslation} post={post} showStatus={showStatus} />
      </div>
    );
  }

  renderPinnedComment() {
    const { board, post, postActivity } = this.props;

    if (!post.pinnedComment) {
      return null;
    }

    if (postActivity?.statusHistories && post.pinnedComment.statusChangeID) {
      const statusChange = postActivity.statusHistories[post.pinnedComment.statusChangeID];
      return (
        <PostStatusChange
          board={board}
          comment={post.pinnedComment}
          pinned={true}
          post={post}
          statusChange={statusChange}
        />
      );
    }

    return <Comment board={board} comment={post.pinnedComment} pinned={true} post={post} />;
  }

  render() {
    const {
      allowUnmerge,
      board,
      onEdit,
      onMarkedSpam,
      onPostDeleted,
      post,
      postActivity,
      showCommentComposer,
      showCustomPostFields,
      showSummary,
      viewType,
    } = this.props;

    if (post.error) {
      return (
        <div className="post postError">
          Something went wrong and this post couldn't be loaded, try again in a few minutes.
        </div>
      );
    }

    const { hideTranslation } = this.state;
    return (
      <div className="post">
        <ErrorBoundary>
          <div className="mainContent">
            {this.renderHeader()}
            <div className="postContent">
              {this.renderAuthor()}
              <PostBody hideTranslation={hideTranslation} post={post} />
              {showCustomPostFields && (
                <CustomPostFields
                  board={board}
                  post={post}
                  showCustomPostConfiguration={viewType === 'admin'}
                />
              )}
              <PostMenu
                board={board}
                hideTranslation={hideTranslation}
                onEdit={onEdit}
                onMarkedSpam={onMarkedSpam}
                onPostDeleted={onPostDeleted}
                onToggleTranslation={this.onToggleTranslation}
                post={post}
              />
            </div>
            {showCommentComposer && <CommentComposer board={board} post={post} />}
            {this.renderPinnedComment()}
            {showSummary && <Summary className="summary" post={post} />}
          </div>
          <PostActivity
            allowUnmerge={allowUnmerge}
            board={board}
            post={post}
            postActivity={postActivity}
            ref={this.postActivityRef}
            viewType={viewType}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

export default withContexts(
  {
    company: CompanyContext,
    viewer: ViewerContext,
  },
  {
    forwardRef: true,
  }
)(Post);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { LocationContext, ParamsContext, RouterContext } from 'common/containers/RouterContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import ControlledCheckboxInput from 'common/inputs/ControlledCheckboxInput';
import Tappable from 'common/Tappable';
import UserAvatar from 'common/user/UserAvatar';
import { isElementCompletelyOnScreen } from 'common/util/isElementOnScreen';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminUsersListItem.scss';

class AdminUsersListItem extends Component {
  static propTypes = {
    company: PropTypes.shape({
      contributorCount: PropTypes.number,
      userCount: PropTypes.number,
      members: PropTypes.array,
    }),
    deleting: PropTypes.bool.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
    params: PropTypes.object,
    router: PropTypes.object,
    scrollContainerRef: PropTypes.object,
    selected: PropTypes.bool.isRequired,
    tintColor: PropTypes.string,
    user: PropTypes.object.isRequired,
  };

  state = {
    isSelected: this.props.params?.userURLName === this.props.user?.urlName,
  };

  componentDidMount() {
    this.itemRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { params, user } = this.props;

    if (prevProps.params !== params) {
      const isSelected = params.userURLName === user.urlName;
      this.setState({ isSelected });
    } else if (!prevState.isSelected && this.state.isSelected) {
      this.scrollIntoView();
    }
  }

  onUserSelected = (e) => {
    const {
      location,
      router,
      user,
      selected,
      company: { members, deleting },
    } = this.props;
    const isMetaPressed = e.metaKey || e.ctrlKey;

    if (!isMetaPressed) {
      router.replace({
        pathname: `/admin/users/${user.urlName}`,
        query: location.query,
      });
      return;
    }
    const userIsMember = members.some((member) => member._id === user._id);

    if (!userIsMember && !deleting) {
      this.props.onCheckboxChange(user, !selected);
    }
  };

  scrollIntoView() {
    const parent = this.props.scrollContainerRef?.current;
    if (!parent) {
      return;
    }
    const element = findDOMNode(this.itemRef.current);

    const { isVisible, above } = isElementCompletelyOnScreen(element, parent);

    if (!isVisible) {
      element.scrollIntoView(above);
    }
  }

  renderName() {
    const { company, tintColor, user } = this.props;
    if (!company || !company.members) {
      return null;
    }

    const isMember = company.members.some((member) => member._id === user._id);
    if (!isMember) {
      return <div className="name">{user.name}</div>;
    }

    const nameStyle = {
      ...(tintColor && { color: tintColor }),
    };
    return (
      <div className="name" style={nameStyle}>
        {user.name}
      </div>
    );
  }

  renderCompany() {
    const {
      user: { companies },
    } = this.props;
    if (!companies || !companies.length) {
      return null;
    }

    if (companies.length === 1) {
      return <div className="companyNames">{companies[0].name}</div>;
    }

    return (
      <div className="companyNames">{`${companies[0].name} and ${companies.length - 1} more`}</div>
    );
  }

  renderCheckbox() {
    const {
      company: { members },
      deleting,
      onCheckboxChange,
      selected,
      user,
    } = this.props;
    const userIsMember = members.some((member) => member._id === user._id);
    if (userIsMember) {
      return <div className="userCheckbox"></div>;
    }

    return (
      <div className="userCheckbox">
        <ControlledCheckboxInput
          className={selected ? 'selected' : null}
          disabled={userIsMember || deleting}
          onChange={() => onCheckboxChange(user, !selected)}
          checked={selected}
        />
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const { isSelected } = this.state;
    const className = classnames('adminUsersListItem', { selected: isSelected });

    return (
      <Tappable onTap={this.onUserSelected}>
        <div className={className} ref={this.itemRef}>
          {this.renderCheckbox()}
          <UserAvatar showBadge={true} user={user} />
          <div className="nameAndCompany">
            {this.renderName()}
            {this.renderCompany()}
          </div>
        </div>
      </Tappable>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  location: LocationContext,
  params: ParamsContext,
  router: RouterContext,
  tintColor: TintColorContext,
})(AdminUsersListItem);

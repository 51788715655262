import React from 'react';

import PostLink from '../post/PostLink';

import type { Notification } from 'common/api/endpoints/notifications';

type Props = {
  notification: Notification;
  onTap?: () => void;
  children: React.ReactNode;
};

const NotificationLink = ({ notification, onTap, children }: Props) => {
  const post = notification.post || notification.comment?.post;

  return (
    <PostLink post={post} onTap={onTap}>
      {children}
    </PostLink>
  );
};

export default NotificationLink;

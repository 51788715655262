import type { RolePermissionName } from 'common/api/endpoints/companies';

export const Sections = [
  'Boards',
  'Posts',
  'Votes',
  'Comments',
  'Users',
  'Roadmap',
  'Changelog',
  'Developer',
  'Settings',
] as const;

// Group the permissions into sections to display in the modal
export const PermissionSections: Record<Section, RolePermissionName[]> = {
  Boards: [
    'customizeBoards',
    'exportData',
    'importPosts',
    'manageCustomPostFields',
    'manageBoards',
    'manageBoardPrivacy',
  ],
  Posts: [
    'deletePosts',
    'manageCategories',
    'manageTags',
    'changePostTags',
    'changePostETA',
    'changePostStatus',
    'changePostOwner',
    'mergePosts',
    'managePostAutomation',
    'manageStatuses',
  ],
  Votes: ['voteOnBehalf'],
  Comments: ['deleteComments', 'summarizeComments', 'pinComments', 'createPublicComment'],
  Users: ['deleteUsers', 'manageUserSegmentation', 'viewUserDetails'],
  Roadmap: ['manageRoadmap'],
  Changelog: ['manageChangelog', 'manageChangelogPrivacy'],
  Developer: ['manageAPIKey', 'manageWebhooks', 'manageSSOKey'],
  Settings: [
    'manageCustomDomains',
    'manageEmailSettings',
    'manageCompanyProfile',
    'manageTeammates',
    'manageIntegrations',
    'manageBilling',
    'manageAuthSettings',
    'manageQueue',
  ],
};

export type Section = (typeof Sections)[number];

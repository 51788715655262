import React from 'react';

import ContentContainer from 'common/containers/ContentContainer';
import NavLogo from 'common/NavLogo';

import 'css/components/_FocusNav.scss';

const FocusNav = () => {
  return (
    <ContentContainer outerClassName="focusNav" innerClassName="focusNavContents">
      <div className="leftContainer">
        <NavLogo />
      </div>
    </ContentContainer>
  );
};

export default FocusNav;

import { Actions, type Names, type TPCForFiltersAction } from 'common/actions/tpcForFilters';

export type TPCForFiltersState = {
  error?: string;
  queries: Record<string, boolean>;
  lastUpdated: Date | null;
  loading?: boolean;
  companyNames?: Names;
  accountOwnerNames?: Names;
};

export const DefaultState: TPCForFiltersState = {
  companyNames: {},
  accountOwnerNames: {},
  queries: {},
  lastUpdated: null,
};

export default function tpcForFilters(
  state: TPCForFiltersState = DefaultState,
  action: TPCForFiltersAction
): TPCForFiltersState {
  switch (action.type) {
    case Actions.FetchTPCForFilters: {
      return Object.assign({}, state, {
        error: null,
        lastUpdated: action.timestamp,
        loading: true,
        queries: {
          ...state.queries,
          ...(action.query ? { [action.query]: true } : {}),
        },
      });
    }

    case Actions.TPCForFiltersLoaded: {
      return Object.assign({}, state, {
        lastUpdated: action.timestamp,
        loading: false,
        companyNames: {
          ...state.companyNames,
          ...action.companyNames,
        },
        accountOwnerNames: {
          ...state.accountOwnerNames,
          ...action.accountOwnerNames,
        },
      });
    }

    case Actions.TPCForFiltersError: {
      return Object.assign({}, state, {
        error: action.error,
        lastUpdated: action.timestamp,
        loading: false,
      });
    }

    default:
      return state;
  }
}

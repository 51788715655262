import React, { useContext } from 'react';

import {
  type AddPostTags as AddPostTagsType,
  type Filter,
  Resource,
} from 'common/automations/constants';
import { BoardsContext } from 'common/containers/BoardsContainer';
import MultipleSelect from 'common/ui/MultipleSelect';

import type { Option } from 'common/ui/common/select/SelectCommon';

interface Props {
  filters: Filter[];
  properties: AddPostTagsType['properties'];
  onChange: (properties?: AddPostTagsType['properties']) => void;
}

const AddPostTags = ({ filters, ...props }: Props) => {
  const boards = useContext(BoardsContext);

  const { properties, onChange } = props;

  if (!Array.isArray(boards)) {
    return null;
  }

  const boardFilter = filters.find((filter) => filter.resource === Resource.postBoardURLName);

  if (!boardFilter) {
    return null;
  }

  const selectedBoard = boards.find((board) => board.urlName === boardFilter.value);

  if (!selectedBoard) {
    return null;
  }

  const tagOptions: Option[] = [];
  const selectedTags: Option[] = [];

  selectedBoard.tags.forEach((tag) => {
    const tagOption = { label: tag.name, value: tag._id };
    tagOptions.push(tagOption);
    if (properties.tagIDs.includes(tag._id)) {
      selectedTags.push(tagOption);
    }
  });

  return (
    <MultipleSelect
      className="grid2"
      placeholder="Select a tag"
      onChange={(options: Option[]) => onChange({ tagIDs: options.map((option) => option.value) })}
      options={tagOptions}
      value={selectedTags}
      optionsMaxWidth="300px"
    />
  );
};

export default AddPostTags;

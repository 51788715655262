import React, { forwardRef } from 'react';

import classnames from 'classnames';

import Portal from 'common/common/Portal';

import 'css/components/subdomain/admin/_ActionMenu.scss';

type ActionOption = {
  destructive?: boolean;
  label: string;
  action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type Props = {
  align?: 'start' | 'center' | 'end';
  className?: string;
  options: ActionOption[];
  relativeToRef: React.Ref<React.ReactNode>;
};

const ActionMenu = forwardRef<Portal, Props>(
  ({ className, align = 'start', options, relativeToRef }, ref) => {
    return (
      <Portal
        align={align}
        ref={ref}
        className={classnames('actionMenuPortal', className)}
        relativeToRef={relativeToRef}>
        <div className="actionMenu">
          {options.map((option) => (
            <button
              className={classnames('menuButton', { destructive: option.destructive })}
              key={option.label}
              onClick={option.action}>
              {option.label}
            </button>
          ))}
        </div>
      </Portal>
    );
  }
);

export default ActionMenu;

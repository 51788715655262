import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ContentContainer from 'common/containers/ContentContainer';
import SubdomainPostVotersListHelmet from 'common/helmets/SubdomainPostVotersListHelmet';
import FourOhFour from 'common/routeComponents/general/FourOhFour';
import Spinner from 'common/Spinner';

import SubdomainPostVoters from './SubdomainPostVoters';

import 'css/components/subdomain/public/_SubdomainPostVotersList.scss';

export default class SubdomainPostVotersList extends Component {
  static propTypes = {
    board: PropTypes.object,
    post: PropTypes.object,
    voters: PropTypes.array,
    router: PropTypes.object,
  };

  componentDidMount() {
    this.fixURL();
  }

  fixURL() {
    const { board, params, post, router } = this.props;

    // permissions are handled on board level
    if (board?.requestedBoardID && (post.notAuthorized || post.notFound)) {
      router.replace('/' + params.boardURLName);
    }
  }

  renderContents() {
    const { board, post, voters } = this.props;
    if (post.notFound) {
      return <div className="notFound">There is no such post.</div>;
    } else if (post.error || !voters) {
      return <div className="notFound">Something went wrong, please try again later.</div>;
    } else if (post.loading || voters.loading) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    }

    return <SubdomainPostVoters board={board} linkMore={false} post={post} voters={voters} />;
  }

  render() {
    const { board, post } = this.props;

    // when the board is either not found or not authorized, we don't return settings - hence the optional chaining
    if (!board.settings?.showVoterNames || board.settings?.privateVotes) {
      return <FourOhFour />;
    }
    return (
      <ContentContainer
        outerClassName="subdomainPostVotersList"
        innerClassName="subdomainPostVotersContents card">
        <SubdomainPostVotersListHelmet board={board} post={post} />
        {this.renderContents()}
      </ContentContainer>
    );
  }
}

export default function areArraysSimilar(arr1: Array<string>, arr2: Array<string>): boolean;
export default function areArraysSimilar(arr1: Array<number>, arr2: Array<number>): boolean;
export default function areArraysSimilar(
  arr1: Array<string | number>,
  arr2: Array<string | number>
) {
  if (arr1 === arr2) {
    return true;
  }
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }

  const keyMap: Record<string, number> = {};
  for (let i = 0; i < arr1.length; i++) {
    const key = arr1[i];

    if (!keyMap[key]) {
      keyMap[key] = 0;
    }

    keyMap[key] += 1;
  }

  for (let i = 0; i < arr2.length; i++) {
    const key = arr2[i];

    if (!keyMap[key]) {
      return false;
    }

    if (keyMap[key] === 1) {
      delete keyMap[key];
    } else {
      keyMap[key] -= 1;
    }
  }

  return Object.keys(keyMap).length === 0;
}

import React, { useEffect, useState } from 'react';

import { reloadPost } from 'common/actions/posts';
import AJAX from 'common/AJAX';
import { Errors } from 'common/asana/Constants';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ModalPortal from 'common/modals/ModalPortal';
import Spinner from 'common/Spinner';
import UppercaseHeader from 'common/UppercaseHeader';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import type { Post } from 'common/api/resources/posts';
import type { Dispatch } from 'redux-connect';

import 'css/components/subdomain/admin/_AdminCreateAsanaTaskModal.scss';

type OwnProps = {
  onClose?(): void;
  onTaskCreated?(): void;
  post: Post;
};

type ConnectProps = {
  reloadPost(post: Post): void;
};

type Props = OwnProps & ConnectProps;

type Project = {
  id: string;
  name: string;
};

type Task = {
  name: string;
  notes: string | null;
  projectID: string | null;
};

const mapToOptions = (projects: Project[]) => {
  return projects.map((project) => ({
    name: project.id,
    render: project.name,
  }));
};

const AdminCreateAsanaTaskModal = ({ onClose, onTaskCreated, post, reloadPost }: Props) => {
  // state
  const [creating, setCreating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [task, _setTask] = useState<Task>({
    name: post.title,
    notes: post.details,
    projectID: null,
  });
  const [projects, setProjects] = useState<Project[] | null>(null);

  // state utils
  const setTask = (data: Partial<Task>) => _setTask((state) => ({ ...state, ...data }));
  const getProject = (projectID: string) =>
    projects ? projects.find((project) => project.id === projectID) : null;

  // effects
  useEffect(() => {
    const fetchAndSetProjects = async () => {
      const response = await AJAX.post('/api/asana/getProjects', {});
      const { error, parsedResponse } = parseAPIResponse<{ projects: Project[] }>(response, {
        errors: Errors,
        isSuccessful: (parsedResponse) => !!parsedResponse.projects,
      });

      if (error) {
        setError(error.message);
        return;
      }

      if (!parsedResponse) {
        return;
      }

      const { projects } = parsedResponse;
      const [defaultProject] = projects;

      setTask({ projectID: defaultProject?.id });
      setProjects(projects);
    };

    fetchAndSetProjects();
  }, []);

  const createTask = async () => {
    const { name, notes, projectID } = task;

    setCreating(true);

    const response = await AJAX.post('/api/asana/createAndLinkTask', {
      name,
      notes,
      postID: post._id,
      projectID,
    });

    const { error } = parseAPIResponse(response, {
      errors: Errors,
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      setCreating(false);
      setError(error.message);
      return;
    }

    await reloadPost(post);

    onTaskCreated?.();
    onClose?.();
  };

  // renders
  const renderContents = () => {
    if (error) {
      return <div className="error">{error}</div>;
    } else if (!projects) {
      return <Spinner />;
    }

    const selectedProject = getProject(task.projectID ?? '');
    const projectOptions = mapToOptions(projects ?? []);

    if (!projectOptions.length || !selectedProject) {
      return (
        <div className="error">
          It looks like there are no available Asana Projects. Please add a Project on Asana before
          creating a&nbsp;task.
        </div>
      );
    }

    return (
      <div className="createTaskForm">
        <div className="dropdownOuterContainer">
          <UppercaseHeader>Project</UppercaseHeader>
          <ControlledDropdown
            options={projectOptions}
            onChange={(projectID: string) => setTask({ projectID })}
            selectedName={selectedProject.id}
          />
        </div>
        <TextInput
          defaultValue={task.name}
          inset="Name"
          onChange={(e) => setTask({ name: e.target.value })}
        />
        <AutoResizeTextarea
          defaultValue={task.notes}
          inset="Notes"
          maxRows={8}
          minRows={3}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setTask({ notes: e.target.value })
          }
        />
        <Button
          buttonType="cannyButton"
          loading={creating}
          onTap={createTask}
          value="Create & Link Task"
        />
      </div>
    );
  };

  const render = () => {
    return (
      <ModalPortal className="adminCreateAsanaTaskModal" closeOnClickAway={true} onClose={onClose}>
        <div className="container">
          <div className="heading">Create a new Asana task</div>
          {renderContents()}
        </div>
      </ModalPortal>
    );
  };

  return render();
};

// TODO: remove cast once `connect` is typed
export default connect(null, (dispatch: Dispatch) => ({
  reloadPost: (post: Post) => dispatch(reloadPost(post)),
}))(AdminCreateAsanaTaskModal) as unknown as React.FC<OwnProps>;

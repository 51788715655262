import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import CheckboxInput from 'common/inputs/CheckboxInput';
import Link from 'common/Link';
import UpsellModal from 'common/modals/UpsellModal';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import AdminSidebarSectionItem from 'common/subdomain/admin/AdminSidebarSectionItem';
import Tappable from 'common/Tappable';
import CountBadge from 'common/ui/CountBadge';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminSidebarBoardSection.scss';

class AdminSidebarBoardSection extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.object,
    onBoardsChange: PropTypes.func,
    selectedBoards: PropTypes.arrayOf(PropTypes.object),
    showPostCount: PropTypes.bool,
  };

  state = {
    showUpsellModal: false,
  };

  constructor(props, context) {
    super(props, context);

    this.boardRefs = {};
    props.boards.forEach((board) => {
      this.boardRefs[board.urlName] = React.createRef();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedBoards !== prevProps.selectedBoards) {
      const { boards } = this.props;
      const selectedBoardIDs = this.props.selectedBoards.map((board) => board._id);
      boards.forEach((board) =>
        this.boardRefs[board.urlName].current.setValue(selectedBoardIDs.includes(board._id))
      );
    }
  }

  onBoardChange = (board, checked) => {
    const boardMap = this.getBoardMapFromRefs();
    boardMap[board.urlName] = checked;
    this.props.onBoardsChange(boardMap);
  };

  onSelectAllBoards = () => {
    const { boards } = this.props;
    const boardMap = this.getBoardMapFromRefs();

    boards.forEach((board) => {
      this.boardRefs[board.urlName].current.setValue(true);
      boardMap[board.urlName] = true;
    });

    this.props.onBoardsChange(boardMap);
  };

  onSelectBoard = (boardToSelect) => {
    const { boards } = this.props;
    const boardMap = this.getBoardMapFromRefs();

    boards.forEach((board) => {
      const value = board === boardToSelect;
      this.boardRefs[board.urlName].current.setValue(value);
      boardMap[board.urlName] = value;
    });

    this.props.onBoardsChange(boardMap);
  };

  onUpsell = () => {
    this.setState({ showUpsellModal: true });
  };

  onUpsellDismiss = () => {
    this.setState({ showUpsellModal: false });
  };

  getBoardMapFromRefs() {
    return Object.keys(this.boardRefs).reduce((map, next) => {
      map[next] = this.boardRefs[next].current.getValue();
      return map;
    }, {});
  }

  renderCreateBoard() {
    const boardCount = this.props.company.boardCount;
    const boardLimit = this.props.company.limits.boards;
    if (boardLimit && boardLimit <= boardCount) {
      return (
        <Tappable onTap={this.onUpsell}>
          <div className="createBoardLink">
            <div className="icon icon-plus" />
            Create board
          </div>
        </Tappable>
      );
    }

    return (
      <Link className="createBoardLink" to="/admin/create-board">
        <div className="icon icon-plus" />
        Create board
      </Link>
    );
  }

  render() {
    const { boards, selectedBoards } = this.props;
    const selectedBoardURLNames = selectedBoards.reduce((acc, board) => {
      acc[board.urlName] = true;
      return acc;
    }, {});

    return (
      <AdminSidebarSection
        action={
          <Tappable onTap={this.onSelectAllBoards}>
            <div className="all">Select All</div>
          </Tappable>
        }
        className="adminSidebarBoardSection"
        title="Boards">
        <div className="boards">
          {boards.map((board) => {
            const isChecked = selectedBoardURLNames[board.urlName];
            const settingsURL = `/admin/settings/boards/${board.urlName}`;
            return (
              <AdminSidebarSectionItem key={board.urlName}>
                <CheckboxInput
                  defaultChecked={isChecked}
                  label={board.name}
                  onChange={this.onBoardChange.bind(this, board)}
                  ref={this.boardRefs[board.urlName]}
                />
                {this.props.showPostCount && (
                  <div className="boardCount">
                    <CountBadge size="small" count={board.activePostCount} />
                  </div>
                )}
                <div className="hoverMenu">
                  <Tappable onTap={this.onSelectBoard.bind(this, board)}>
                    <div className="only">1</div>
                  </Tappable>
                  <Link to={settingsURL}>
                    <div className="icon icon-gear" />
                  </Link>
                </div>
              </AdminSidebarSectionItem>
            );
          })}
          {this.renderCreateBoard()}
        </div>
        <UpsellModal
          cta="Get unlimited boards!"
          feature="limits.boards"
          onClose={() => this.onUpsellDismiss()}
          onUpsell={() => this.onUpsellDismiss()}
          show={!!this.state.showUpsellModal}
        />
      </AdminSidebarSection>
    );
  }
}

export default withContexts(
  { company: CompanyContext },
  { forwardRef: true }
)(AdminSidebarBoardSection);

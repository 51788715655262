import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import Strings from 'common/Strings';
import { H2, P } from 'common/ui/Text';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminEmailSettings.scss';

class AdminEmailSettings extends Component {
  static propTypes = {
    company: PropTypes.object,
  };

  state = {
    edited: false,
    editing: false,
    error: null,
    frequency: this.props.company.viewerReportFrequency,
    hasEdits: false,
  };

  onChange = (e) => {
    this.setState({
      edited: false,
      frequency: e.target.value,
      hasEdits: true,
    });
  };

  onSubmit = () => {
    this.setState({
      editing: true,
    });

    const { frequency } = this.state;
    AJAX.post(
      '/api/company/updateEmailSettings',
      {
        reportFrequency: frequency,
      },
      (response) => {
        if (response !== 'success') {
          this.setState({
            editing: false,
            error: Strings.miscError,
          });
          return;
        }

        this.props.reloadCompany().then(() => {
          this.setState({
            edited: true,
            editing: false,
            hasEdits: false,
          });
        });
      }
    );
  };

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    const {
      company: { viewerReportFrequency },
    } = this.props;
    const { edited, editing, frequency, hasEdits } = this.state;
    return (
      <div className="adminEmailSettings">
        <Helmet title="Email Preferences | Canny" />
        <H2 className="heading" variant="bodyMd" fontWeight="bold">
          Email preferences
        </H2>
        <P>
          Set how often you would like to receive email reports from&nbsp;Canny.{' '}
          <a
            className="learnMore"
            href="https://help.canny.io/en/articles/1279840-admin-reports"
            target="_blank"
            rel="noreferrer">
            Learn more
          </a>
        </P>
        <div className="content">
          <div className="message">Receive admin reports:</div>
          <div className="option">
            <input
              checked={frequency === 'daily'}
              id="dailyFrequency"
              name="frequency"
              onChange={this.onChange}
              type="radio"
              value="daily"
            />
            <label htmlFor="dailyFrequency">Daily</label>
          </div>
          <div className="option">
            <input
              checked={frequency === 'weekly'}
              id="weeklyFrequency"
              name="frequency"
              onChange={this.onChange}
              type="radio"
              value="weekly"
            />
            <label htmlFor="weeklyFrequency">Weekly</label>
          </div>
          <div className="option">
            <input
              checked={frequency === 'never'}
              id="neverFrequency"
              name="frequency"
              onChange={this.onChange}
              type="radio"
              value="never"
            />
            <label htmlFor="neverFrequency">Never</label>
          </div>
          {this.renderError()}
          <Button
            disabled={!hasEdits || frequency === viewerReportFrequency}
            loading={editing}
            onTap={this.onSubmit}
            value={edited ? 'Saved' : 'Save'}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminEmailSettings);

import React, { useContext, useState } from 'react';

import classnames from 'classnames';

import SentrySDK from 'common/3rd/SentrySDK';
import Tooltip from 'common/common/Tooltip';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import SearchInput from 'common/inputs/SearchInput';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import ModernModal from 'common/modals/ModernModal';
import SpinnerV2 from 'common/SpinnerV2';
import ButtonV2 from 'common/ui/ButtonV2';
import { P, Span } from 'common/ui/Text';
import createCustomError from 'common/util/createCustomError';

import 'css/components/subdomain/admin/AdminRoadmap/_GenericActionModal.scss';
import { ModalToastYOffset } from './constants';

type SuggestionComponentProps<T> = {
  onClick: () => void;
  suggestion: T;
};

type ConfirmationModalSettings = {
  header: string;
  description: string;
  confirmCTA: string;
};

type Props<T> = {
  confirmationModalSettings?: ConfirmationModalSettings;
  cta: string;
  title: string;
  onClose: (completed?: boolean) => void;
  onSearchValueChanged: (searchValue: string) => void;
  onSubmit: (selectedSuggestion: T) => Promise<void>;
  searchValue: string;
  showSearch: boolean;
  successMessage: string;
  SuggestionComponent: React.FC<SuggestionComponentProps<T>>;
  suggestions: T[];
  suggestionsLoading: boolean;
};

export const GenericActionError = createCustomError('GenericActionError');

const GenericActionModal = <T extends { _id: string }>({
  confirmationModalSettings,
  cta,
  title,
  onClose,
  onSearchValueChanged,
  onSubmit,
  searchValue,
  showSearch,
  successMessage,
  SuggestionComponent,
  suggestions,
  suggestionsLoading,
}: Props<T>) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState<T | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const showToast = useContext(ShowToastContext);

  const onSubmitClick = () => {
    if (confirmationModalSettings) {
      setShowConfirmationModal(true);
      return;
    }
    submit();
  };

  const submit = async () => {
    if (selectedSuggestion) {
      try {
        setIsSubmitting(true);
        await onSubmit(selectedSuggestion);
        // assuming onSubmit must throw if it fails, reaching here means the op has succeeded
        showToast(successMessage, ToastTypes.success, { yOffset: ModalToastYOffset });
        setIsSubmitting(false);
        onClose(true);
      } catch (e: unknown) {
        setIsSubmitting(false);
        showToast('Something went wrong, please try again.', ToastTypes.error, {
          yOffset: ModalToastYOffset,
        });

        if (e instanceof GenericActionError) {
          // the error is from our API request, so we should log it to the console
          console.error(e);
        } else {
          // the error is not from our API request, so we should log it to Sentry
          SentrySDK.captureException(e);
        }

        // if an error occurs after the confirmation modal is shown, we need to hide it
        if (showConfirmationModal) {
          setShowConfirmationModal(false);
        }
      }
    }
  };

  if (showConfirmationModal && confirmationModalSettings) {
    const { confirmCTA, description, header } = confirmationModalSettings;

    return (
      <ModernConfirmModal
        onClose={onClose}
        onConfirm={submit}
        confirmText={confirmCTA}
        header={header}
        loading={isSubmitting}>
        <P>{description}</P>
      </ModernConfirmModal>
    );
  }

  return (
    <ModernModal
      overlayClassName="genericActionModalPortal"
      onClose={onClose}
      header={title}
      sections={[
        <>
          <div className="modalContents">
            {showSearch && (
              <div className="modalSearch">
                <SearchInput
                  autoFocus={false}
                  placeholder="Search..."
                  defaultValue={searchValue}
                  onChange={onSearchValueChanged}
                  showClearIcon={false}
                  styling="v2"
                />
              </div>
            )}
            <div className="modalSuggestions">
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion._id}
                  className={classnames('modalSuggestion', {
                    selected: suggestion._id === selectedSuggestion?._id,
                  })}>
                  <SuggestionComponent
                    suggestion={suggestion}
                    onClick={() => {
                      setSelectedSuggestion(suggestion);
                    }}
                  />
                </div>
              ))}
              <div className="suggestionsContentCentered">
                {!suggestionsLoading && !suggestions.length && (
                  <Span className="noResultsMessage">No results found</Span>
                )}
                {suggestionsLoading && <SpinnerV2 className="suggestionsSpinner" size="large" />}
              </div>
            </div>
          </div>
        </>,
      ]}
      footer={
        <Tooltip
          disabled={!!selectedSuggestion}
          position="left"
          value="You need to select an item first.">
          <ButtonV2
            disabled={suggestionsLoading || !selectedSuggestion}
            loading={isSubmitting}
            onClick={onSubmitClick}
            size="medium">
            {cta}
          </ButtonV2>
        </Tooltip>
      }
    />
  );
};

export default GenericActionModal;

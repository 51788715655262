import React, { Component } from 'react';

import PropTypes from 'prop-types';

import UserLockup from 'common/user/UserLockup';

import Modal from './Modal';

import 'css/components/reaction/_ReactorsModal.scss';

export default class ReactorsModal extends Component {
  static propTypes = {
    reactors: PropTypes.array.isRequired,
  };

  render() {
    const { reactors } = this.props;

    return (
      <Modal className="reactorsModal">
        <div className="reactors">
          {reactors.map((user) => {
            return (
              <div className="reactor" key={user._id}>
                <UserLockup
                  showBadge={true}
                  showCompanyNames={true}
                  showProfile={true}
                  user={user}
                />
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}

import React from 'react';

import classNames from 'classnames';

import type { LucideProps } from 'lucide-react';

import 'css/components/_IconBadge.scss';

export type Props = {
  disabled?: boolean;
  size: Size;
  icon: React.ComponentType<LucideProps>;
  active?: boolean;
};

type Size = 'small' | 'medium' | 'large' | 'x-large';

const IconBadge = ({ size = 'medium', icon: Icon, active, disabled }: Props) => {
  return (
    <div
      className={classNames('iconBadge', {
        disabled,
        [size]: true,
        active,
      })}>
      <Icon className="badgeIcon" />
    </div>
  );
};

export default IconBadge;

import type { Company, RolePermissionName } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

export default function hasPermission(
  permissionKey: RolePermissionName,
  company: Company,
  viewer: Viewer
): boolean {
  if (!company.members) {
    return false;
  }

  const companyMember = company.members.find((member) => {
    return member._id === viewer._id;
  });

  if (!companyMember) {
    // could not find viewer in list of company members
    return false;
  }

  const { roleID } = companyMember;
  const role = company.roles.find((role) => {
    return role._id === roleID;
  });

  if (!role) {
    // could not find role for viewer
    return false;
  } else if (!role.permissions.hasOwnProperty(permissionKey)) {
    throw new Error('Could not find permission with key: ' + permissionKey);
  }

  return role.permissions[permissionKey];
}

export default {
  admin: true,
  api: true,
  changelog: true,
  join: true,
  notifications: true,
  register: true,
  settings: true,
  unsubscribe: true,
  'unsubscribe-report': true,
};

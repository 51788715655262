import React, { type FC, useContext, useState } from 'react';

import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import withAccessControl from 'common/routing/withAccessControl';
import ButtonV2 from 'common/ui/ButtonV2';
import SwitchV2 from 'common/ui/SwitchV2';
import { H2, P } from 'common/ui/Text';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/AdminGeneralSettings/_AdminSecuritySettings.module.scss';

import type { Company } from 'common/api/endpoints/companies';

type OwnProps = Record<string, never>;

type ConnectProps = {
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

const AdminEmailAuthSettings: FC<Props> = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showToast = useContext(ShowToastContext);

  const [enableEmailAuth, setEnableEmailAuth] = useState<boolean>(company.enableEmailAuth);
  const [loading, setLoading] = useState<boolean>(false);

  const savePreferences = async () => {
    setLoading(true);

    const response = await AJAX.post('/api/company/changeSettings', {
      enableEmailAuth,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error) {
      showToast(error.message, ToastTypes.error);
      setLoading(false);
      return;
    }

    await reloadCompany();
    setLoading(false);
  };

  return (
    <div className={styles.adminSecuritySettings}>
      <Helmet title="Security | General settings | Canny" />
      <H2 variant="bodyMd" fontWeight="bold" className={styles.heading}>
        Pre-authenticated Email Links
      </H2>
      <P className={styles.content}>
        Your users can automatically authenticate into Canny through links we send them, such as
        status change or comment notifications. This provides a seamless login experience, making it
        easier for them to submit feedback with minimal friction.
      </P>
      <P className={styles.content}>
        <a
          className={styles.linkOut}
          href="https://help.canny.io/en/articles/10064276-pre-authenticated-email-links"
          target="_blank"
          rel="nofollow noopener">
          Learn more about the security of pre-authenticated links in emails
        </a>
      </P>
      <div className={styles.toggleContainer}>
        <P>Enable pre-authenticated email links</P>
        <SwitchV2
          onChange={() => setEnableEmailAuth((enableEmailAuth) => !enableEmailAuth)}
          checked={enableEmailAuth}
        />
      </div>
      <ButtonV2
        color="primary"
        disabled={enableEmailAuth === company.enableEmailAuth}
        onClick={savePreferences}
        className="saveButton"
        loading={loading}
        size="medium">
        Save
      </ButtonV2>
    </div>
  );
};

export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.security['email-auth']),
    '/admin/settings'
  )
)(AdminEmailAuthSettings) as unknown as React.FC<OwnProps>;

import React from 'react';

import styles from 'css-module/components/subdomain/admin/AdminSideBar/_FilterToggle.module.scss';

type Option = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  toggleOptions: Option[];
  selection: string;
  onToggle: (value: string) => void;
};

const FilterToggle = ({ name, toggleOptions, selection, onToggle }: Props) => {
  return (
    <div className={styles.wrapper}>
      {toggleOptions.map((option, index) => {
        return (
          <React.Fragment key={`filter-toggle-${name}-option-${index}`}>
            <input
              className={styles.input}
              name={name}
              id={`filter-toggle-${name}-${index}`}
              type="radio"
              checked={option.value === selection}
              onChange={() => onToggle(option.value)}
            />
            <label className={styles.label} htmlFor={`filter-toggle-${name}-${index}`}>
              {option.label}
            </label>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FilterToggle;

import React, { useContext } from 'react';

import { Plus } from 'lucide-react';
import { compose } from 'redux';

import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import withAccessControl from 'common/routing/withAccessControl';
import AdminIntegrationSettings from 'common/subdomain/admin/AdminIntegrationSettings/AdminIntegrationSettings';
import {
  getEnabledAutopilotIntegrations,
  getEnabledIntegrationCards,
} from 'common/subdomain/admin/AdminIntegrationSettings/utils';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationSettingsSections.module.scss';

import type { Company } from 'common/api/endpoints/companies';

const EmptySection = [
  {
    integrations: [
      {
        icon: <Plus className={styles.emptyIcon} size={32} />,
        label: 'Browse integrations',
        url: '/admin/settings/integrations/all',
      },
    ],
    sectionTitle: "You don't have anything set up yet!",
  },
];

const AdminIntegrationSettingsActive = () => {
  const company = useContext<Company>(CompanyContext);
  const { activeIntegrations } = useContext(ActiveIntegrationContext);

  const autopilotIntegrationsSection = getEnabledAutopilotIntegrations(company);
  const baseIntegrationsSection = getEnabledIntegrationCards(activeIntegrations);

  const installedSections = [
    ...(baseIntegrationsSection.length > 0
      ? [
          {
            integrations: baseIntegrationsSection,
          },
        ]
      : []),
    ...(autopilotIntegrationsSection.length > 0
      ? [
          {
            sectionTitle: 'Autopilot',
            integrations: autopilotIntegrationsSection,
          },
        ]
      : []),
  ];

  const sections = installedSections.length > 0 ? installedSections : EmptySection;

  return (
    <AdminIntegrationSettings
      settingsTitle="Active Integrations"
      settingsDescription="The active integrations in your Canny workspace"
      sections={sections}
    />
  );
};

export default compose(
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.integrations.active),
    '/admin/settings'
  )
)(AdminIntegrationSettingsActive) as unknown as React.FC;

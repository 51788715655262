import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Portal from 'common/common/Portal';
import Truncate from 'common/common/Truncate';
import generateRandomID from 'common/generateRandomID';
import DropdownButton from 'common/inputs/DropdownButton';
import { KeyNames } from 'common/KeyCodes';
import areArraysEqual from 'common/util/areArraysEqual';

import Link from './Link';
import Tappable from './Tappable';

import 'css/components/_Dropdown.scss';

export default class Dropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    defaultSelectedName: PropTypes.string,
    defaultSelectedNames: PropTypes.arrayOf(PropTypes.string),
    dropdownClassName: PropTypes.string,
    disabled: PropTypes.bool,
    multiselect: PropTypes.bool,
    onChange: PropTypes.func,
    onTap: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        render: PropTypes.node.isRequired, // renderable
      })
    ).isRequired,
    placeholder: PropTypes.string,
    selectionStyle: PropTypes.object,
    splitStyle: PropTypes.bool,
    truncate: PropTypes.bool,
  };

  static defaultProps = {
    defaultSelectedName: null,
    defaultSelectedNames: [],
    disabled: false,
    options: [],
    multiselect: false,
    placeholder: null,
    selectionStyle: {},
    splitStyle: false,
  };

  state = {
    open: false,
    focusedName:
      (this.props.multiselect
        ? this.props.defaultSelectedNames[0]
        : this.props.defaultSelectedName) ?? null,
    selectedNames: this.props.multiselect
      ? this.props.defaultSelectedNames
      : this.props.defaultSelectedName
      ? [this.props.defaultSelectedName]
      : [],
  };

  constructor(props) {
    super(props);
    this.dropdownListRef = React.createRef();
    this.selectionRef = React.createRef();
    this.focusedOptionRef = React.createRef();
    this.dropdownID = generateRandomID();
  }

  componentDidMount() {
    const { defaultSelectedName, defaultSelectedNames, multiselect } = this.props;
    if (multiselect && defaultSelectedName) {
      throw new Error(
        'Mutliselect dropdowns should receive "defaultSelectedNames" instead of "defaultSelectedName"'
      );
    } else if (!multiselect && defaultSelectedNames.length) {
      throw new Error(
        'Single-select dropdowns should receive "defaultSelectedName" instead of "defaultSelectedNames"'
      );
    }

    document.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    const { focusedName, open } = this.state;
    const { defaultSelectedName, defaultSelectedNames, options, multiselect } = this.props;

    if (multiselect && defaultSelectedName) {
      throw new Error(
        'Mutliselect dropdowns should receive "defaultSelectedNames" instead of "defaultSelectedName"'
      );
    } else if (!multiselect && defaultSelectedNames.length) {
      throw new Error(
        'Single-select dropdowns should receive "defaultSelectedName" instead of "defaultSelectedNames"'
      );
    }

    if (open && options?.length && !prevState.open) {
      // focus first option once the dropdown list is open
      this.setState({ focusedName: options[0].name });
    } else if (open && focusedName !== prevState.focusedName) {
      // focus DOM element when changing focused option
      this.focusedOptionRef.current?.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  getValue = () => {
    const { multiselect } = this.props;
    const { selectedNames } = this.state;

    return multiselect ? selectedNames : selectedNames[0] ?? null;
  };

  onDropdownBlur = () => {
    if (!this.state.open) {
      return;
    }
    this.setState({ open: false }, () => this.selectionRef.current?.focus());
  };

  onChoose = (option) => {
    const { multiselect } = this.props;

    if (option === null) {
      this.setState(
        {
          open: false,
          selectedNames: [],
        },
        () => this.selectionRef.current?.focus()
      );
      return;
    }

    this.setState(
      (state) => {
        if (!multiselect) {
          return {
            open: false,
            selectedNames: [option.name],
          };
        }

        if (state.selectedNames.includes(option.name)) {
          return {
            open: true,
            selectedNames: state.selectedNames.filter((name) => name !== option.name),
          };
        }

        return {
          open: true,
          selectedNames: state.selectedNames.concat(option.name),
        };
      },
      () => {
        if (!multiselect) {
          this.selectionRef.current?.focus();
          this.props.onChange?.(this.state.selectedNames[0]);
          return;
        }

        this.props.onChange?.(this.state.selectedNames);
      }
    );
  };

  getNextOption = (key, focusedOptionIdx) => {
    const { options } = this.props;

    if (key === KeyNames.DownArrow) {
      const nextOption =
        options[focusedOptionIdx === options.length - 1 ? 0 : focusedOptionIdx + 1];
      return nextOption;
    } else if (key === KeyNames.UpArrow) {
      const nextOption =
        options[focusedOptionIdx === 0 ? options.length - 1 : focusedOptionIdx - 1];
      return nextOption;
    } else if (key === KeyNames.End) {
      const nextOption = options[options.length - 1];
      return nextOption;
    } else if (key === KeyNames.Home) {
      const nextOption = options[0];
      return nextOption;
    }

    return null;
  };

  onKeyDown = (e) => {
    const { options } = this.props;
    const { focusedName, open } = this.state;
    if (!open) {
      return;
    }

    if (e.key === KeyNames.Escape) {
      this.onDropdownBlur();
      return;
    }

    const focusedOptionIdx = options.findIndex((option) => option.name === focusedName);
    const nextOption = this.getNextOption(e.key, focusedOptionIdx);
    if (nextOption) {
      e.preventDefault();
      this.setState({ focusedName: nextOption.name });
      return;
    }
  };

  onToggleDropdown = () => {
    const { disabled, onTap } = this.props;
    if (disabled) {
      return;
    }

    onTap?.();
    this.setState((state) => ({ open: !state.open }));
  };

  renderDropdown() {
    const { focusedName, open, selectedNames } = this.state;
    const { dropdownClassName, multiselect, options } = this.props;
    if (options.length === 0) {
      return null;
    }

    const mapOptionToItem = (option) => {
      if (option.link) {
        return (
          <li
            aria-selected={selectedNames.includes(option.name)}
            key={option.name}
            onMouseEnter={() => this.setState({ focusedName: option.name })}>
            <Link
              className={classnames('option', {
                focused: focusedName === option.name,
                selected: selectedNames.includes(option.name),
              })}
              forwardRef={focusedName === option.name ? this.focusedOptionRef : null}
              onTap={() => this.onChoose(option)}
              to={option.link}>
              {option.render}
            </Link>
          </li>
        );
      } else {
        return (
          <Tappable
            key={option.name}
            triggerOnSpace={true}
            triggerOnEnter={true}
            onTap={() => this.onChoose(option)}
            stopPropagation={true}>
            <li
              id={`${this.dropdownID}_${option.name}`}
              aria-selected={selectedNames.includes(option.name)}
              className={classnames('option', {
                focused: focusedName === option.name,
                selected: selectedNames.includes(option.name),
                indent: option.indent,
              })}
              name={`${this.dropdownID}_${option.name}`}
              onMouseEnter={() => this.setState({ focusedName: option.name })}
              ref={focusedName === option.name ? this.focusedOptionRef : null}
              role="option"
              tabIndex={0}>
              {option.render}
            </li>
          </Tappable>
        );
      }
    };

    const items = options.filter((option) => !option.sticky).map(mapOptionToItem);
    const stickyItems = options.filter((option) => option.sticky).map(mapOptionToItem);

    const selectionWidth = `${this.selectionRef.current?.offsetWidth}px`;
    return (
      <Portal
        align="start"
        className={classnames('dropdownPortal', dropdownClassName)}
        onBlur={this.onDropdownBlur}
        position="bottom"
        relativeToRef={this.selectionRef}>
        <div
          className={classnames('dropdown', { hidden: !open, multiselect })}
          style={{ minWidth: selectionWidth }}>
          <ul
            aria-activedescendant={`${this.dropdownID}_${focusedName}`}
            className="dropdownList"
            ref={this.dropdownListRef}
            role="listbox"
            tabIndex={-1}>
            {items}
          </ul>
          <ul>{stickyItems}</ul>
        </div>
      </Portal>
    );
  }

  renderSelection() {
    const {
      defaultSelectedName,
      defaultSelectedNames,
      multiselect,
      options,
      placeholder,
      selectionStyle,
      splitStyle,
    } = this.props;
    const { open, selectedNames } = this.state;

    const selectedOptions = options.filter((option) => selectedNames.includes(option.name));
    const isDefaultOptionSelected = multiselect
      ? areArraysEqual(selectedNames, defaultSelectedNames)
      : selectedNames[0] === defaultSelectedName;
    const shouldShowPlaceholder =
      (placeholder && isDefaultOptionSelected) || !selectedOptions || selectedOptions.length === 0;
    const label = shouldShowPlaceholder
      ? placeholder
      : multiselect
      ? selectedOptions.map((option) => option.render).join(', ')
      : selectedOptions[0]?.render;

    // pass through props
    const dropdownProps = { ...this.props };
    Object.keys(Dropdown.propTypes).forEach((propType) => {
      delete dropdownProps[propType];
    });

    if (!multiselect && splitStyle) {
      return (
        <div
          className={classnames('selection', 'split', {
            placeholder: shouldShowPlaceholder,
          })}
          ref={this.selectionRef}>
          <Link
            {...dropdownProps}
            className="option"
            style={selectionStyle}
            to={selectedOptions[0].link}>
            {label}
          </Link>
          <Tappable onTap={this.onToggleDropdown}>
            <div className="icon-chevron-down" />
          </Tappable>
        </div>
      );
    }

    return (
      <Tappable triggerOnSpace={true} onTap={this.onToggleDropdown}>
        <DropdownButton
          aria-placeholder={label}
          className={classnames('selection')}
          open={open}
          label={this.props.truncate ? <Truncate numberOfLines={1}>{label}</Truncate> : label}
          placeholderActive={shouldShowPlaceholder}
          ref={this.selectionRef}
          ariaType="listbox"
        />
      </Tappable>
    );
  }

  render() {
    const className = classnames('dropdownContainer', this.props.className, {
      disabled: this.props.disabled,
      open: this.state.open,
    });
    return (
      <div className={className}>
        {this.renderSelection()}
        {this.renderDropdown()}
      </div>
    );
  }
}

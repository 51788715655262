export const AzureLogIn = 'AzureLogIn';
export const CannyLogIn = 'CannyLogIn';
export const CannySignUp = 'CannySignUp';
export const CompanyLogIn = 'CompanyLogIn';
export const EmailVerification = 'EmailVerification';
export const GSuiteLogIn = 'GSuiteLogIn';
export const NoLogIn = 'NoLogIn';
export const OIDCLogIn = 'OIDCLogIn';
export const OktaLogIn = 'OktaLogIn';

type LoginParams = {
  viewerLoggedIn: boolean;
  allowAzure: boolean;
  allowIdentified: boolean;
  allowInvited: boolean;
  allowGSuite: boolean;
  allowOIDC: boolean;
  allowOkta: boolean;
  allowSegment: boolean;
  allowVerifiedEmail: boolean;
};

export const getPrimaryLogIn = ({
  viewerLoggedIn,
  allowAzure,
  allowIdentified,
  allowInvited,
  allowGSuite,
  allowOIDC,
  allowOkta,
  allowSegment,
  allowVerifiedEmail,
}: LoginParams) => {
  if (viewerLoggedIn && allowVerifiedEmail) {
    return EmailVerification;
  }

  if (allowIdentified) {
    return CompanyLogIn;
  }

  if (allowGSuite) {
    return GSuiteLogIn;
  } else if (allowAzure) {
    return AzureLogIn;
  } else if (allowOIDC) {
    return OIDCLogIn;
  } else if (allowOkta) {
    return OktaLogIn;
  } else if (!viewerLoggedIn && allowVerifiedEmail) {
    return CannySignUp;
  }

  if (!viewerLoggedIn && (allowInvited || allowSegment)) {
    return CannyLogIn;
  }

  return NoLogIn;
};

export const getSecondaryLogIn = ({
  viewerLoggedIn,
  allowAzure,
  allowIdentified,
  allowInvited,
  allowGSuite,
  allowOIDC,
  allowOkta,
  allowVerifiedEmail,
}: LoginParams) => {
  if (allowIdentified) {
    if (viewerLoggedIn) {
      if (allowGSuite) {
        return GSuiteLogIn;
      } else if (allowAzure) {
        return AzureLogIn;
      } else if (allowOIDC) {
        return OIDCLogIn;
      } else if (allowOkta) {
        return OktaLogIn;
      }
    } else {
      if (allowInvited) {
        return CannyLogIn;
      }

      if (allowGSuite) {
        return GSuiteLogIn;
      } else if (allowAzure) {
        return AzureLogIn;
      } else if (allowOIDC) {
        return OIDCLogIn;
      } else if (allowOkta) {
        return OktaLogIn;
      } else if (allowVerifiedEmail) {
        return CannySignUp;
      }
    }
  } else {
    if (!viewerLoggedIn && allowInvited && allowGSuite) {
      return CannyLogIn;
    }
  }

  return null;
};

export const getTertiaryLogIn = ({
  viewerLoggedIn,
  allowAzure,
  allowIdentified,
  allowInvited,
  allowGSuite,
  allowOIDC,
  allowOkta,
}: LoginParams) => {
  if (!viewerLoggedIn && allowIdentified && allowInvited) {
    if (allowGSuite) {
      return GSuiteLogIn;
    } else if (allowAzure) {
      return AzureLogIn;
    } else if (allowOIDC) {
      return OIDCLogIn;
    } else if (allowOkta) {
      return OktaLogIn;
    }
  }

  return null;
};

import React, { useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { type CompanyInfo } from 'common/actions/tpcForFilters';
import { LocationContext, RouterContext } from 'common/containers/RouterContainer';
import { TPCForFiltersContext } from 'common/containers/TPCForFiltersContainer';
import AdminSidebarSection from 'common/subdomain/admin/AdminSidebarSection';
import styles from 'css-module/components/subdomain/admin/AdminSideBar/_CompanyFilterSection.module.scss';

import AccountOwnerFilterInput from './AccountOwnerFilterInput';
import CompanyFilterInput from './CompanyFilterInput';
import CompanyFilterSelections from './CompanyFilterSelections';
import FilterToggle from './FilterToggle';

// these equal the url parameter names
export enum ToggleModes {
  companies = 'companies',
  accountOwner = 'accountOwner',
}

const generateCompaniesParam = (companyNames: string[]): string | undefined => {
  if (companyNames.length === 0) {
    return undefined;
  }
  return companyNames.join('_');
};

const CompanyFilterSection = () => {
  const filterDetails = useContext(TPCForFiltersContext);
  const router = useContext(RouterContext);
  const location = useContext(LocationContext);
  const [toggleMode, setToggleMode] = useState<ToggleModes>(ToggleModes.companies);

  const companiesQuery: string = location.query?.companies ?? null;
  const accountOwnerQuery: string = location.query?.accountOwner ?? null;

  // Memo to avoid triggering useEffect on every render
  const selectedCompanies = useMemo(
    () => (companiesQuery ? companiesQuery.split('_') : []),
    [companiesQuery]
  );
  const selectedAccountOwner = accountOwnerQuery ? accountOwnerQuery : undefined;

  // on load / location change
  useEffect(() => {
    const { query } = location;
    if (query?.[ToggleModes.companies] && toggleMode !== ToggleModes.companies) {
      setToggleMode(ToggleModes.companies);
    } else if (query?.[ToggleModes.accountOwner] && toggleMode !== ToggleModes.accountOwner) {
      setToggleMode(ToggleModes.accountOwner);
    }
  }, [location, toggleMode]);

  // If the company or account owner exists in the URL, but does not exist on the server, clear it from the URL
  useEffect(() => {
    const { accountOwnerNames, companyNames, loading } = filterDetails;
    const { query, pathname } = location;

    // loading can be undefined, in which case it's probably loading
    if (loading === false && companyNames && accountOwnerNames) {
      const foundCompanies = selectedCompanies.filter(
        (companyURLName) => companyNames[companyURLName]
      );
      const accountOwnerExists = selectedAccountOwner
        ? accountOwnerNames[selectedAccountOwner]
        : null;

      // If a company in the URL does not exist in the DB, OR
      // An account owner is selected, but they do not exist
      // Remove them from the query params
      if (
        foundCompanies.length !== selectedCompanies.length ||
        (selectedAccountOwner && !accountOwnerExists)
      ) {
        const newQuery = query;
        query.companies = generateCompaniesParam(foundCompanies);
        query.accountOwner = accountOwnerExists ? selectedAccountOwner : undefined;
        router.push({
          pathname,
          query: newQuery,
        });
      }
    }
  }, [filterDetails, router, location, selectedAccountOwner, selectedCompanies]);

  const onChangeMode = (newMode: string) => {
    if (newMode in ToggleModes) {
      const mode = newMode as ToggleModes;
      if (mode !== toggleMode) {
        clearParam(toggleMode);
        setToggleMode(ToggleModes[mode]);
      }
    }
  };

  const setAccountOwner = (accountOwnerURLName: string | undefined) => {
    const { query, pathname } = location;
    const existingValue = query?.accountOwner;
    const newQuery = query;
    if (existingValue === accountOwnerURLName) {
      return;
    }

    newQuery.accountOwner = accountOwnerURLName ?? undefined;
    router.push({
      pathname,
      query: newQuery,
    });
  };

  const toggleCompany = (companyURLName: string) => {
    const { query, pathname } = location;
    const newQuery = query;

    const companyExists = selectedCompanies.includes(companyURLName);
    if (companyExists) {
      newQuery.companies = generateCompaniesParam(
        selectedCompanies.filter((urlName: string) => urlName !== companyURLName)
      );
    } else {
      newQuery.companies = [...selectedCompanies, companyURLName].join('_');
    }

    router.push({
      pathname,
      query: newQuery,
    });
  };

  const clearParam = (paramName: ToggleModes) => {
    const { query } = location;
    const newQuery = Object.assign({}, query, {
      [paramName]: undefined,
    });
    router.push({
      pathname: location.pathname,
      query: newQuery,
    });
  };

  const onCompanySelected = (company: CompanyInfo) => {
    toggleCompany(company.urlName);
  };

  const onAccountOwnerSelected = (accountOwner: CompanyInfo | undefined) => {
    setAccountOwner(accountOwner?.urlName);
  };

  const toggleOptions = [
    {
      label: 'By name',
      value: ToggleModes.companies,
    },
    {
      label: 'By account owner',
      value: ToggleModes.accountOwner,
    },
  ];

  return (
    <AdminSidebarSection className={classNames(styles.companyFilterSection)} title="Companies">
      <FilterToggle
        name="feedback-companies"
        toggleOptions={toggleOptions}
        selection={toggleMode}
        onToggle={onChangeMode}
      />
      {toggleMode === ToggleModes.companies && (
        <>
          <CompanyFilterInput
            companyNames={filterDetails.companyNames}
            loading={filterDetails.loading ?? true}
            selections={selectedCompanies}
            onCompanySelected={onCompanySelected}
          />
          <CompanyFilterSelections
            companyNames={filterDetails.companyNames}
            selections={selectedCompanies}
            onCompanySelected={onCompanySelected}
          />
        </>
      )}
      {toggleMode === ToggleModes.accountOwner && (
        <AccountOwnerFilterInput
          accountOwnerNames={filterDetails.accountOwnerNames}
          accountOwnerSelected={onAccountOwnerSelected}
          loading={filterDetails.loading ?? true}
          selection={selectedAccountOwner}
        />
      )}
    </AdminSidebarSection>
  );
};

export default CompanyFilterSection;

import getSubdomain from './getSubdomain';

export default function isWidget() {
  if (typeof window.__WIDGET__ !== 'undefined') {
    return window.__WIDGET__;
  }

  const subdomain = getSubdomain(window.location.host);
  return subdomain === 'widget';
}

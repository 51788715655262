import React, { Component } from 'react';

import PropTypes from 'prop-types';

export const SSOTokenContext = React.createContext();

export default class SSOTokenContainer extends Component {
  static propTypes = {
    ssoToken: PropTypes.string,
  };

  render() {
    return (
      <SSOTokenContext.Provider value={this.props.ssoToken}>
        {this.props.children}
      </SSOTokenContext.Provider>
    );
  }
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';

import translateString from 'common/i18n/translateString';

import PostStatus from './PostStatus';
import PostVotes from './PostVotes';
import PostVotesV2 from './PostVotes/PostVotesV2';

import 'css/components/post/_PostHeader.scss';

export default class PostHeader extends Component {
  static propTypes = {
    hideTranslation: PropTypes.bool,
    post: PropTypes.object.isRequired,
    showStatus: PropTypes.bool.isRequired,
    v1: PropTypes.bool,
  };

  static defaultProps = {
    hideTranslation: false,
    showStatus: true,
  };

  renderStatus() {
    const {
      post: { status },
      showStatus,
    } = this.props;
    if (!showStatus) {
      return null;
    }

    return <PostStatus hideTranslation={this.props.hideTranslation} status={status} />;
  }

  render() {
    const { hideTranslation, post, v1 } = this.props;
    return (
      <div className="postHeader">
        {v1 ? <PostVotes post={post} /> : <PostVotesV2 post={post} />}
        <div className="statusTitle">
          <div className="postTitle">{translateString(post, 'title', hideTranslation)}</div>
          {this.renderStatus()}
        </div>
      </div>
    );
  }
}

import React, { useState } from 'react';

import { Inbox, MessageCircleDashed, PauseCircle, Plus, Search } from 'lucide-react';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import connect from 'common/core/connect';
import Link from 'common/Link';
import ButtonV2 from 'common/ui/ButtonV2';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';

import AdminQueueNUX from './AdminQueueNUX';
import useFilterControls from './useFilterControls';
import { CTAs, getCTA } from './utils';

import type { Counts } from './types';
import type { Company } from 'common/api/endpoints/companies';
import type { State } from 'redux-connect';

type OwnProps = {
  company: Company;
  counts: Counts;
};

type ConnectProps = {
  queryState: Record<string, string> | null;
  reloadCompany: () => void;
};

const ResetFilters = ({ onClick }: { onClick: () => void }) => {
  return (
    <AdminQueueNUX
      cta={
        <ButtonV2 onClick={onClick} size="medium">
          Reset filters
        </ButtonV2>
      }
      icon={Search}
      info={[
        'We couldn’t find any results that match your current filters. Try adjusting your filters or check back later.',
      ]}
      title="No results found"
    />
  );
};

const EnableSource = () => {
  return (
    <AdminQueueNUX
      cta={
        <Link to="/admin/settings/autopilot/feedback-discovery">
          <ButtonV2 size="medium">Enable source</ButtonV2>
        </Link>
      }
      icon={PauseCircle}
      info={[
        'The source you selected is currently disabled. Enable it to resume feedback extraction and processing.',
      ]}
      title="Enable your source"
    />
  );
};

const ConnectSource = () => {
  return (
    <AdminQueueNUX
      cta={
        <Link to="/admin/settings/autopilot/feedback-discovery">
          <ButtonV2 startIcon={Plus} size="medium">
            Connect source
          </ButtonV2>
        </Link>
      }
      icon={Inbox}
      info={[
        'Your inbox is currently empty. Connect a source to automatically import relevant feedback into your Canny workspace.',
      ]}
      title="Connect a source"
    />
  );
};

const EnableSources = () => {
  return (
    <AdminQueueNUX
      cta={
        <Link to="/admin/settings/autopilot/feedback-discovery">
          <ButtonV2 size="medium">Enable sources</ButtonV2>
        </Link>
      }
      icon={PauseCircle}
      info={[
        'All of your connected sources are currently disabled. Enable them to resume feedback extraction and processing',
      ]}
      title="Enable your sources"
    />
  );
};

const ConnectAnotherSource = () => {
  return (
    <AdminQueueNUX
      cta={
        <Link to="/admin/settings/autopilot/feedback-discovery">
          <ButtonV2 startIcon={Plus} size="medium">
            Connect source
          </ButtonV2>
        </Link>
      }
      icon={MessageCircleDashed}
      info={[
        ' Autopilot hasn’t found any feedback yet. Connect another source of wait for more feedback to see insights.',
      ]}
      title="Waiting for feedback..."
    />
  );
};

type RedeemProps = {
  reloadCompany: () => void;
};

const RedeemCreditGift = ({ reloadCompany }: RedeemProps) => {
  const [error, setError] = useState('');
  const [redeeming, setRedeeming] = useState(false);

  const redeemCredits = async () => {
    setError('');
    setRedeeming(true);

    const response = await AJAX.post('/api/billing/redeemCreditGift');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'credit gift already redeemed': 'You have already redeemed your credit gift.',
      },
    });

    if (error) {
      setError(error.message);
      setRedeeming(false);
      return;
    }

    await reloadCompany();

    setRedeeming(false);
  };

  return (
    <AdminQueueNUX
      cta={
        <>
          <ButtonV2 loading={redeeming} onClick={redeemCredits} size="medium">
            Get 100 free credits
          </ButtonV2>
          {error && <div className="error">{error}</div>}
        </>
      }
      icon={PauseCircle}
      info={[
        'No feedback found. Claim your one-time gift of 100 credits to try your search again or use it for another source.',
      ]}
      title="Autopilot paused - out of credits"
    />
  );
};

const PurchaseCredits = () => {
  return (
    <AdminQueueNUX
      cta={
        <Link to="/admin/settings/billing/autopilot">
          <ButtonV2 size="medium">Purchase credits</ButtonV2>
        </Link>
      }
      icon={PauseCircle}
      info={[
        'To continue extracting and analyzing feedback, purchase more credits and pick up where you left off.',
      ]}
      title="Autopilot paused - out of credits"
    />
  );
};

const AutopilotNUX = ({ company, counts, queryState, reloadCompany }: ConnectProps & OwnProps) => {
  const { clearFilters, queryParams } = useFilterControls({
    queryState,
    reloadCompany,
  });
  const { source } = queryParams;

  const cta = getCTA(company, counts, source);

  switch (cta) {
    case CTAs.resetFilters:
      return <ResetFilters onClick={clearFilters} />;

    case CTAs.enableSource:
      return <EnableSource />;

    case CTAs.connectSource:
      return <ConnectSource />;

    case CTAs.enableSources:
      return <EnableSources />;

    case CTAs.connectAnotherSource:
      return <ConnectAnotherSource />;

    case CTAs.redeemCreditGift:
      return <RedeemCreditGift reloadCompany={reloadCompany} />;

    case CTAs.purchaseCredits:
      return <PurchaseCredits />;

    default:
      return <ConnectAnotherSource />;
  }
};

export default connect(
  (state: State) => ({
    queryState: state.routing.locationBeforeTransitions.query,
  }),
  (dispatch: Dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })
)(AutopilotNUX) as unknown as React.FC<OwnProps>;

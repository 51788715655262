import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';

import Helmet from './Helmet';

const BoardHomeHelmet = ({ location }) => {
  const company = useContext(CompanyContext);
  const searchValue = location?.query?.search;

  if (!company.name) {
    return null;
  }

  return <Helmet title={`"${searchValue}" search results | ${company.name}`} />;
};

BoardHomeHelmet.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
  }),
  location: PropTypes.shape({
    query: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

export default BoardHomeHelmet;

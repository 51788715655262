import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import InformationBox from 'common/common/InformationBox';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import Form from 'common/Form';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminSettingsHeader from 'common/subdomain/admin/AdminSettings/AdminSettingsHeader';
import Tappable from 'common/Tappable';
import { StarterPlanID } from 'common/util/isStarter';
import parseJSON from 'common/util/parseJSON';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import promisify from 'common/util/promisify';
import withContexts from 'common/util/withContexts';

import AdminFeatureUpsell from './AdminFeatureUpsell';

import 'css/components/subdomain/admin/_AdminCNAMESettings.scss';

const TestErrorMessages = {
  ['certificate has expired']: `SSL certificate of this domain has expired. Please renew it and try again.`,
  ['cloudflare proxy detected']: `Custom domains aren\'t supported via Cloudflare\'s proxy. Please disable the\u00a0proxy.`,
  ['domain misconfigured']: "This domain isn't set up. Please follow the DNS instructions.",
  default: 'Something went wrong, please try again later.',
};

function processDomain(domain) {
  const transformed = domain
    .replace('http://', '')
    .replace('https://', '')
    .replace(/:[0-9]+/g, '')
    .replace(/\/.*$/gi, '')
    .trim();

  const isValidRegex = /^[^:/?#]+\.[^:/?#]+$/gi;
  if (transformed.match(isValidRegex)) {
    return transformed;
  } else {
    return null;
  }
}

class AdminCNAMESettings extends Component {
  static propTypes = {
    company: PropTypes.shape({
      _id: PropTypes.string,
      domains: PropTypes.arrayOf(PropTypes.string),
      primaryDomain: PropTypes.string,
    }),
    router: PropTypes.object,
    showIntercom: PropTypes.func,
    openModal: PropTypes.func,
  };

  state = {
    adding: false,
    domainStatuses: {},
    error: null,
    hasContents: false,
    makingPrimary: null,
    removing: false,
    showTestIcons: false,
  };

  constructor(props, context) {
    super(props, context);

    this.domainRef = React.createRef();
  }

  componentDidMount() {
    this.setDefaultDomainStatuses();
  }

  componentDidUpdate() {
    this.setDefaultDomainStatuses();
  }

  setDefaultDomainStatuses() {
    const { domainStatuses } = this.state;
    const {
      company: { domains },
    } = this.props;

    if (Object.keys(domainStatuses).length === domains.length) {
      return;
    }

    const newDomainStatuses = domains.reduce((domainStatuses, domain) => {
      domainStatuses[domain] = domainStatuses?.[domain] ?? { error: null };
      return domainStatuses;
    }, {});

    this.setState({ domainStatuses: newDomainStatuses });
  }

  updateDomainStatus = (domain, fields = {}) => {
    this.setState((state) => ({
      domainStatuses: {
        ...state.domainStatuses,
        [domain]: { ...state.domainStatuses[domain], ...fields },
      },
    }));
  };

  onAddDomain = () => {
    const { adding } = this.state;
    if (adding) {
      return;
    }

    const domain = this.domainRef.current.getValue();
    if (!domain) {
      this.setState({
        error: 'Please enter a domain.',
      });
      return;
    }

    const processed = processDomain(domain);
    if (!processed) {
      this.setState({
        error: 'Please enter a valid domain (eg. feedback.website.com)',
      });
      return;
    }

    this.domainRef.current.setValue(processed);

    this.setState({
      adding: true,
      error: null,
    });

    AJAX.post(
      '/api/domains/register',
      {
        domain: processed,
      },
      (response) => {
        if (response === 'success') {
          this.props.reloadCompany().then(() => {
            this.setState({
              adding: false,
              hasContents: false,
            });
            this.domainRef.current.setValue('');
          });
          return;
        }

        // if the response was not successful, parse error
        try {
          const { error } = JSON.parse(response);
          if (error === 'domain taken') {
            this.setState({
              adding: false,
              error: (
                <span>
                  This domain has already been registered by another user. Please{' '}
                  <Tappable onTap={this.props.showIntercom}>
                    <span className="link">contact us</span>
                  </Tappable>{' '}
                  for help.
                </span>
              ),
            });
          } else {
            this.setState({
              adding: false,
              error: 'Something went wrong, please try again later.',
            });
          }
        } catch (e) {
          this.setState({
            adding: false,
            error: 'Something went wrong, please try again later.',
          });
        }
      }
    );
  };

  onDomainChange = (e) => {
    const { value } = e.target;
    this.setState({
      error: null,
      hasContents: !!value.trim(),
    });
  };

  onMakePrimary = (domain) => {
    const { makingPrimary } = this.state;
    if (makingPrimary) {
      return;
    }

    this.setState({
      error: null,
      makingPrimary: domain,
    });

    AJAX.post(
      '/api/domains/makePrimary',
      {
        domain,
      },
      async (response) => {
        if (response === 'success') {
          this.props.reloadCompany().then(() => {
            this.setState({
              makingPrimary: null,
            });
          });
          return;
        }

        let responseObject;
        try {
          responseObject = await promisify(parseJSON, response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        this.handleTestDomainError(domain, responseObject?.error);
      }
    );
  };

  onRemoveDomain = (domain) => {
    const { removing } = this.state;
    if (removing) {
      return;
    }

    this.setState({
      error: null,
    });

    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to remove " + domain + '?',
      onConfirm: () => {
        this.setState({
          removing: true,
        });
        AJAX.post(
          '/api/domains/unregister',
          {
            domain: domain,
          },
          (response) => {
            if (response !== 'success') {
              this.setState({
                error: 'Something went wrong, please try again later.',
                removing: false,
              });
            } else {
              this.props.reloadCompany().then(() => {
                this.setState({
                  removing: false,
                });
              });
            }
          }
        );
      },
    });
  };

  onTestDomains = () => {
    const {
      company: { domains },
    } = this.props;
    this.setState({ error: null, showTestIcons: true });

    domains.forEach(async (domain) => {
      this.updateDomainStatus(domain, { error: null, isTesting: true });

      const response = await AJAX.post('/api/domains/testDomainConfiguration', { domain });
      if (response === 'success') {
        this.updateDomainStatus(domain, { isSetUp: true, isTesting: false });
        return;
      }

      let responseObject;
      try {
        responseObject = await promisify(parseJSON, response);
      } catch (e) {
        responseObject = { error: 'server error' };
      }

      this.handleTestDomainError(domain, responseObject?.error);
    });
  };

  handleTestDomainError = (domain, error) => {
    this.setState({ makingPrimary: null });
    const errorMessage = TestErrorMessages[error] || TestErrorMessages.default;
    this.updateDomainStatus(domain, { error: errorMessage, isSetUp: false, isTesting: false });
  };

  renderDomains() {
    const {
      company: { domains },
    } = this.props;
    if (domains.length === 0) {
      return (
        <div className="domains">
          <div className="noDomains">You have not set up any custom domains</div>
        </div>
      );
    }

    const items = [];
    domains.forEach((domain, i) => {
      items.push(
        <div className="domainContainer" key={i}>
          <div className="left">
            {this.renderTestDomainIcon(domain)}
            <div className="domain">{domain}</div>
            {this.renderDomainState(domain)}
          </div>
          <Tappable onTap={this.onRemoveDomain.bind(this, domain)}>
            <div className="icon icon-x" />
          </Tappable>
        </div>
      );
    });

    return <div className="domains">{items}</div>;
  }

  renderDomainState(domain) {
    const {
      company: { primaryDomain },
    } = this.props;
    const { domainStatuses, makingPrimary } = this.state;
    const domainStatus = domainStatuses[domain];
    const isMakingPrimary = domain === makingPrimary;
    const isPrimary = domain === primaryDomain;

    if (domainStatus?.error) {
      return <div className="error">{domainStatus.error}</div>;
    }

    if (isPrimary) {
      return <div className="primary">{'(primary)'}</div>;
    }

    if (isMakingPrimary) {
      return <div className="verifying">Verifying domain...</div>;
    }

    return (
      <button className="makePrimary" onClick={this.onMakePrimary.bind(this, domain)}>
        Make primary
      </button>
    );
  }

  renderTestDomainIcon(domain) {
    const { domainStatuses, showTestIcons } = this.state;
    const domainStatus = domainStatuses[domain];

    if (!showTestIcons) {
      return null;
    }

    if (domainStatus?.isTesting) {
      return (
        <div className="iconContents">
          <Spinner />
        </div>
      );
    }

    if (domainStatus?.isSetUp === false) {
      return (
        <div className="iconContents">
          <div className="icon-exclamation">!</div>
        </div>
      );
    }

    if (domainStatus?.isSetUp) {
      return (
        <div className="iconContents">
          <div className="icon-check" />
        </div>
      );
    }

    return null;
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderAddDomain() {
    const { adding, hasContents } = this.state;
    return (
      <Form
        className="addDomain"
        addEventsToDocument={false}
        disableSubmit={!hasContents || adding}
        onSubmit={this.onAddDomain}>
        <TextInput
          autoFocus={true}
          onChange={this.onDomainChange}
          placeholder="feedback.yoursite.com"
          ref={this.domainRef}
        />
        {hasContents ? <Button formButton={true} loading={adding} value="Add Domain" /> : null}
      </Form>
    );
  }

  renderTestDomains() {
    const { domainStatuses } = this.state;
    const isTesting = Object.values(domainStatuses).some((domainStatus) => domainStatus.isTesting);

    return (
      <div className="testDomains">
        <Button loading={isTesting} onTap={this.onTestDomains} value="Test Domains" />
      </div>
    );
  }

  renderUpsell() {
    return (
      <AdminFeatureUpsell
        cta="Host Canny from your own&nbsp;domain"
        feature="customDomain"
        planID={StarterPlanID}
      />
    );
  }

  renderSettings() {
    return (
      <>
        <div className="description">
          Set your primary domain to update the links we use in our&nbsp;product/emails.
        </div>
        {this.renderDomains()}
        {this.renderAddDomain()}
        {this.renderError()}
        <InformationBox icon="!" className="tip">
          Make sure you point to <span>cname.canny.io</span> in your DNS&nbsp;settings
        </InformationBox>
        {this.renderTestDomains()}
      </>
    );
  }

  render() {
    const {
      company: { subdomain, features },
    } = this.props;

    const hasAccess = features?.customDomain;
    return (
      <div className="adminCNAMESettings">
        <Helmet title="Custom Domain Settings | Canny" />
        <AdminSettingsHeader
          title="Custom Domains"
          subheading="Set up redirects to your preferred&nbsp;domains."
          learnMoreLink="https://help.canny.io/en/articles/1355038-setting-up-your-custom-domain"
        />
        <div className="content">
          <div className="description">
            We've created {subdomain}.canny.io for you. With custom domains, you can use your own
            website (feedback.yoursite.com) instead.{' '}
            {hasAccess ? (
              <>
                Just add the domain below then follow{' '}
                <a
                  href="https://help.canny.io/en/articles/1355038-setting-up-your-custom-domain"
                  rel="noopener"
                  target="_blank">
                  these&nbsp;instructions
                </a>
              </>
            ) : null}
          </div>
          {hasAccess ? this.renderSettings() : this.renderUpsell()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(
    testEveryPermission(RoutePermissions.adminSettings.customDomain),
    '/admin/settings',
    { forwardRef: true }
  ),
  withContexts(
    {
      company: CompanyContext,
      showIntercom: ShowIntercomContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminCNAMESettings);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadAllBoards } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import Spinner from 'common/Spinner';
import UppercaseHeader from 'common/UppercaseHeader';
import withContexts from 'common/util/withContexts';

import AdminBoardListItem from './AdminBoardListItem';

import 'css/components/subdomain/admin/_AdminBoardList.scss';

const ListItemHeight = 50;

class AdminBoardList extends Component {
  static propTypes = {
    alwaysShowDrag: PropTypes.bool,
    boards: PropTypes.array,
    company: PropTypes.object,
    linkToBoards: PropTypes.bool,
    noBoardsMessage: PropTypes.node.isRequired,
    showHeader: PropTypes.bool,
    showLinks: PropTypes.bool,
    showPostCount: PropTypes.bool,
  };

  static defaultProps = {
    alwaysShowDrag: false,
    linkToBoards: true,
    showHeader: true,
    showLinks: true,
    showPostCount: true,
  };

  state = {
    dividerIndex: null,
    dragging: false,
    reordering: false,
  };

  onDragStart = (boardIndex) => {
    this.setState({
      dragging: true,
    });
  };

  onDragMove = (boardIndex, offset) => {
    const { boards } = this.props;
    const minIndex = 0;
    const maxIndex = boards.length;

    const indexOffset = Math.floor((offset + ListItemHeight) / ListItemHeight);
    const newIndex = Math.min(Math.max(boardIndex + indexOffset, minIndex), maxIndex);
    if (newIndex === boardIndex || newIndex === boardIndex + 1) {
      this.setState({
        dividerIndex: null,
      });
    } else {
      this.setState({
        dividerIndex: newIndex,
      });
    }
  };

  onDragEnd = (boardIndex) => {
    this.setState({
      dragging: false,
    });

    const { dividerIndex } = this.state;
    if (dividerIndex === null) {
      return;
    }

    const { boards } = this.props;

    const boardIDs = [];
    boards.forEach((board, i) => {
      if (i === dividerIndex) {
        boardIDs.push(boards[boardIndex]._id);
      }
      if (i === boardIndex) {
        return;
      }
      boardIDs.push(boards[i]._id);
    });
    if (dividerIndex === boards.length) {
      boardIDs.push(boards[boardIndex]._id);
    }

    this.setState({
      dividerIndex: null,
      reordering: true,
    });

    AJAX.post(
      '/api/boards/reorder',
      {
        boardIDs,
      },
      (response) => {
        const { reloadBoards } = this.props;
        reloadBoards().then(() => {
          this.setState({
            reordering: false,
          });
        });
      }
    );
  };

  renderBoards() {
    const { reordering } = this.state;
    if (reordering) {
      return (
        <div className="reordering">
          <Spinner />
        </div>
      );
    }

    const { boards } = this.props;
    const items = [];
    boards.forEach((board, i) => {
      items.push(
        <AdminBoardListItem
          alwaysShowDrag={this.props.alwaysShowDrag}
          key={board._id}
          board={board}
          linkToBoard={this.props.linkToBoards}
          onDragStart={this.onDragStart.bind(this, i)}
          onDragMove={this.onDragMove.bind(this, i)}
          onDragEnd={this.onDragEnd.bind(this, i)}
          showLinks={this.props.showLinks}
          showPostCount={this.props.showPostCount}
        />
      );
    });

    const { dividerIndex } = this.state;
    if (dividerIndex !== null) {
      items.splice(dividerIndex, 0, <div className="divider" key="divider" />);
    }

    if (items.length === 0) {
      return <div className="noBoards">{this.props.noBoardsMessage}</div>;
    }

    return items;
  }

  renderColumnLabels() {
    const { boards, showHeader } = this.props;
    if (boards.length === 0) {
      return null;
    }

    if (!showHeader) {
      return false;
    }

    const { showLinks, showPostCount } = this.props;
    const columns = [
      <UppercaseHeader className="name" key="name">
        Name
      </UppercaseHeader>,
    ];

    if (showPostCount) {
      columns.push(
        <UppercaseHeader className="posts" key="posts">
          Posts
        </UppercaseHeader>
      );
    }

    if (showLinks) {
      columns.push(<UppercaseHeader className="actions" key="actions"></UppercaseHeader>);
    }

    return <div className="columnLabels">{columns}</div>;
  }

  render() {
    const { linkToBoards } = this.props;
    const { dragging } = this.state;
    const classNames = classnames({
      adminBoardList: true,
      card: true,
      dragging,
      linkToBoards,
    });
    return (
      <div className={classNames}>
        {this.renderColumnLabels()}
        {this.renderBoards()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoards: () => {
      return Promise.all([dispatch(reloadAllBoards())]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBoardList);

import {
  type Filter,
  type InBoardFilter,
  type PostCategoryFilter,
  type PostContainsFilter,
  type PostCustomFieldFilter,
  Resource,
} from './constants';

export const isFilterCustomField = (filter: Filter): filter is PostCustomFieldFilter =>
  filter.resource === Resource.postCustomField;

export const isFilterBoardURLName = (filter: Filter): filter is InBoardFilter =>
  filter.resource === Resource.postBoardURLName;

export const isFilterPostCategory = (filter: Filter): filter is PostCategoryFilter =>
  filter.resource === Resource.postCategory;

export const isFilterPostContent = (filter: Filter): filter is PostContainsFilter =>
  filter.resource === Resource.postContent;

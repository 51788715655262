import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { reloadRoadmapPostsForRoadmapsWithPost } from 'common/actions/roadmapPosts';

import { invalidatePostQueries } from '../actions/postQueries';
import { reloadPost } from '../actions/posts';
import AJAX from '../AJAX';
import connect from '../core/connect';
import Dropdown from '../Dropdown';

import 'css/components/post/_PostCategoryDropdown.scss';

class PostCategoryDropdown extends Component {
  static propTypes = {
    board: PropTypes.shape({
      categories: PropTypes.array,
    }),
    post: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      category: PropTypes.object,
    }),
  };

  onCategoryChange = (categoryID) => {
    const { board } = this.props;
    const category =
      board.categories.find((category) => {
        return category._id === categoryID;
      }) || null;

    const { post, reloadPost } = this.props;
    AJAX.post(
      '/api/posts/changeCategory',
      {
        categoryID: category ? category._id : null,
        postID: post._id,
      },
      (response) => {
        reloadPost(post);
      }
    );
  };

  render() {
    const { board, post } = this.props;
    const { categories } = board;
    const parentCategories = categories.filter((category) => !category.parentID);
    const options = [];

    parentCategories.forEach((parent) => {
      const pushCategories = [
        parent,
        ...categories.filter((category) => category.parentID === parent._id),
      ];
      options.push(
        ...pushCategories.map((category) => ({
          indent: !!category.parentID,
          name: category._id,
          render: category.name,
        }))
      );
    });

    options.push({
      name: 'uncategorized',
      render: 'Uncategorized',
    });

    return (
      <div className="postCategoryDropdown">
        <Dropdown
          defaultSelectedName={post.category ? post.category._id : 'uncategorized'}
          dropdownClassName="postCategoryDropdownSelector"
          onChange={this.onCategoryChange}
          options={options}
        />
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  reloadPost: (post) => {
    return Promise.all([
      dispatch(reloadPost(post)),
      dispatch(reloadRoadmapPostsForRoadmapsWithPost(post)),
      dispatch(invalidatePostQueries()),
    ]);
  },
}))(PostCategoryDropdown);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import { invalidateDashboardActivity } from 'common/actions/dashboardActivity';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { reloadPost } from 'common/actions/posts';
import { invalidateUserQueries } from 'common/actions/userQueries';
import AJAX from 'common/AJAX';
import CompanyUserSearch from 'common/company/CompanyUserSearch';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Button from 'common/inputs/Button';
import delayer from 'common/util/delayer';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/post/_PostVoteAsForm.scss';

const ChangeDelay = 300;

class PostVoteAsForm extends Component {
  static propTypes = {
    company: PropTypes.object,
    invalidateUsers: PropTypes.func,
    post: PropTypes.object,
    showToast: PropTypes.func,
  };

  state = {
    creatingNewVoter: false,
    error: null,
    submitting: false,
  };

  constructor(props, context) {
    super(props, context);

    this._onChangeDelayer = new delayer(this.onChangeAfterDelay, ChangeDelay);
    this.searchRef = React.createRef();
  }

  componentWillUnmount() {
    this._onChangeDelayer.cancel();
  }

  createNewVoter = () => {
    const { invalidateUsers } = this.props;
    const { email, name } = this.searchRef.current?.wrappedInstance?.getFormValues() ?? {};

    if (!name || !validateInput.userName(name)) {
      this.setState({
        error: 'Please enter a valid name (2-50)',
      });
      return;
    }

    if (email && !validateInput.email(email)) {
      this.setState({
        error: 'Please enter a valid email address',
      });
      return;
    }

    this.setState({
      creatingNewVoter: false,
      error: null,
      submitting: true,
    });

    AJAX.post(
      '/api/company/createUser',
      {
        name,
        ...(email && { email }),
      },
      (response) => {
        var responseObject;
        try {
          responseObject = JSON.parse(response);
        } catch (e) {
          responseObject = { error: 'server error' };
        }

        if (responseObject.userID) {
          this.onAddVoter({
            _id: responseObject.userID,
            name,
          });
          invalidateUsers();
        } else {
          this.setState({
            error: 'Something went wrong, please try again later.',
            submitting: false,
          });
        }
      }
    );
  };

  onNewVoterSelected = () => {
    this.setState({ creatingNewVoter: true });
  };

  onAddVoter = (voter) => {
    this.setState({
      error: null,
      submitting: true,
    });

    const { post, showToast } = this.props;
    AJAX.post(
      '/api/posts/voteAs',
      {
        postID: post._id,
        score: 1,
        userID: voter._id,
      },
      (response) => {
        if (response === 'success') {
          this.props.reloadPost(post).then(() => {
            this.setState({ submitting: false });
            this.searchRef.current?.wrappedInstance?.resetFormValues();
            this.searchRef.current?.wrappedInstance?.refocusForm();
            showToast('Vote has been successfully added');
          });
        } else {
          this.setState({
            error: 'Something went wrong, please try again later.',
            submitting: false,
          });
        }
      }
    );
  };

  renderButtonContainer() {
    if (!this.state.creatingNewVoter) {
      return null;
    }
    return (
      <div className="buttonContainer">
        <div className="emailNote">
          {'Voters with email addresses will get status change email updates.'}
        </div>
        <Button
          buttonType="cannyButton"
          loading={this.state.submitting}
          onTap={this.createNewVoter}
          value="save"
        />
      </div>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div className="error">{this.state.error}</div>;
  }

  render() {
    return (
      <div className="postVoteAsForm">
        {this.renderError()}
        <CompanyUserSearch
          autoFocus={true}
          disabled={this.state.submitting}
          onNewUserSelected={this.onNewVoterSelected}
          onUserSelected={this.onAddVoter}
          ref={this.searchRef}
        />
        {this.renderButtonContainer()}
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidateUsers: () => {
      return dispatch(invalidateUserQueries());
    },
    reloadPost: (post) => {
      return Promise.all([
        dispatch(invalidateDashboardActivity()),
        dispatch(invalidatePostQueries()),
        dispatch(reloadPost(post)),
        dispatch(reloadCompany()),
      ]);
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      showToast: ShowToastContext,
    },
    {
      forwardRef: true,
    }
  )
)(PostVoteAsForm);

import React from 'react';

import { H2, Span } from 'common/ui/Text';

import Card from './Card';

import type { LucideProps } from 'lucide-react';

import 'css/components/common/_EmptyStatePlaceholder.scss';

type Props = {
  title: string;
  description: string;
  icon: React.ComponentType<LucideProps>;
  children: React.ReactNode;
};

const EmptyStatePlaceholder = ({ title, description, icon: Icon, children }: Props) => {
  return (
    <Card className="emptyStatePlaceholder" borderStyle="dashed">
      <Icon className="emptyStateIcon" />
      <header className="emptyStateHeader">
        <H2 variant="headingSm">{title}</H2>
        <Span className="emptyStateDescription">{description}</Span>
      </header>
      {children}
    </Card>
  );
};
export default EmptyStatePlaceholder;

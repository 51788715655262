import { Component, cloneElement } from 'react';

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

function getTouch(touch) {
  if (!touch) {
    return null;
  }
  return {
    clientX: touch.clientX,
    clientY: touch.clientY,
    pageX: touch.pageX,
    pageY: touch.pageY,
  };
}

export default class Draggable extends Component {
  static propTypes = {
    onDragEnd: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragStart: PropTypes.func,
  };

  componentDidMount() {
    this._mouseDown = false;
    this._initialTouch = null;

    if (typeof document === 'undefined') {
      return;
    }

    document.addEventListener('mouseup', this.documentMouseUp, false);
    document.addEventListener('touchend', this.documentTouchEnd, false);

    document.addEventListener('mousemove', this.documentMouseMove, false);
    document.addEventListener('touchmove', this.documentTouchMove, false);
  }

  componentWillUnmount() {
    if (typeof document === 'undefined') {
      return;
    }

    document.removeEventListener('mouseup', this.documentMouseUp, false);
    document.removeEventListener('touchend', this.documentTouchEnd, false);

    document.removeEventListener('mousemove', this.documentMouseMove, false);
    document.removeEventListener('touchmove', this.documentTouchMove, false);
  }

  onMouseDown = (e) => {
    if (window._blockMouseEvents) {
      return;
    }

    this.props.onDragStart && this.props.onDragStart(e);

    this._mouseDown = true;
  };

  onMouseUp = (e) => {
    if (window._blockMouseEvents) {
      return;
    }

    if (!this._mouseDown) {
      return;
    }

    this._mouseDown = false;
    this.props.onDragEnd && this.props.onDragEnd(e);
  };

  documentMouseMove = (e) => {
    if (window._blockMouseEvents) {
      return;
    }

    if (!this._mouseDown) {
      return;
    }

    this.props.onDragMove && this.props.onDragMove(e);
  };

  documentMouseUp = (e) => {
    if (window._blockMouseEvents) {
      return;
    }

    if (!this._mouseDown) {
      return;
    }

    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    this.props.onDragEnd && this.props.onDragEnd(e);

    this._mouseDown = false;
  };

  documentTouchEnd = (e) => {
    if (!this._initialTouch) {
      return;
    }

    if (findDOMNode(this).contains(e.target)) {
      return;
    }

    this.props.onDragEnd && this.props.onDragEnd(e);
    this._initialTouch = null;
  };

  documentTouchMove = (e) => {
    if (!this._initialTouch) {
      return;
    }

    this.props.onDragMove && this.props.onDragMove(e);
  };

  onTouchStart = (e) => {
    window._blockMouseEvents = true;

    // don't care about >1 touches
    if (this._initialTouch || e.touches.length > 1) {
      this._initialTouch = null;
      return;
    }

    this.props.onDragStart && this.props.onDragStart(e);

    this._initialTouch = getTouch(e.touches[0]);
  };

  onTouchEnd = (e) => {
    if (!this._initialTouch) {
      return;
    }

    this._initialTouch = null;
    this.props.onDragEnd && this.props.onDragEnd(e);
  };

  render() {
    return cloneElement(this.props.children, {
      onMouseDown: this.onMouseDown,
      onMouseUp: this.onMouseUp,
      onTouchEnd: this.onTouchEnd,
      onTouchStart: this.onTouchStart,
    });
  }
}

import { useContext, useEffect } from 'react';

import { CompanyContext } from 'common/containers/CompanyContainer';

import type { Company } from 'common/api/endpoints/companies';

type Props = {
  router: {
    replace: (path: string) => void;
  };
};

const AdminOnboardingRedirect = ({ router }: Props) => {
  // context
  const company = useContext<Company>(CompanyContext);

  // effects
  useEffect(() => {
    router.replace('/admin/onboarding/what');
  }, [company, router]);

  return null;
};

export default AdminOnboardingRedirect;

import React, { useContext } from 'react';

import classNames from 'classnames';
import { Search } from 'lucide-react';

import { CompanyContext } from 'common/containers/CompanyContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';

import ChangelogNoEntryImage from 'img/changelog-no-entry.svg';
import ChangelogPoint from 'img/changelog-point.png';

import type { Company } from 'common/api/endpoints/companies';
import 'css/components/changelog/_ChangelogNoEntries.scss';

type Props = {
  search?: string;
  link?: string;
  hasActiveFilters: boolean;
  isAdminView: boolean;
};

const ChangelogNoEntries = ({ search, link, hasActiveFilters, isAdminView }: Props) => {
  const company = useContext<Company>(CompanyContext);

  if (hasActiveFilters) {
    const subText = search ? 'Try searching for other keywords' : 'Try changing the filters';
    return (
      <div
        className={classNames('changelogNoEntries', {
          center: isAdminView,
          top: !isAdminView,
        })}>
        <div className="hasFilterWrapper">
          <Search size={80} className="search-icon" />
          <div className="noEntries hasFilter">
            <p className="filterText">
              {search ? (
                <>
                  We couldn’t find any changelog entries matching{' '}
                  <span className="searchText">"{search}".</span>
                </>
              ) : (
                'We couldn’t find any changelog entries matching those filters.'
              )}
            </p>
            <p className="filterText">
              {link ? (
                <>
                  {subText} or{' '}
                  <Link className="createChangelogLink" to="/admin/changelog/create">
                    create a new entry instead
                  </Link>
                  .
                </>
              ) : (
                `${subText}.`
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (isAdminView) {
    return (
      <div className="changelogNoEntries">
        <div className="noEntries">
          <div className="nullState">
            <LazyLoadedImage alt="no entries" className="noEntriesImg" src={ChangelogPoint} />
            <div className="text">
              <h1 className="title">Welcome to your Canny changelog!</h1>
              <p className="subtitle">
                Keep your users engaged with official product announcements. Go ahead and create
                your first entry.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="changelogNoEntries top">
      <LazyLoadedImage alt="changelog entry" src={ChangelogNoEntryImage} />
      <div className="noEntries">{`${company.name} hasn’t written any changelog entries yet.`}</div>
    </div>
  );
};

export default ChangelogNoEntries;

import React from 'react';

import classNames from 'classnames';

import Colors from 'common/colors/constants';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import {
  type IntegrationLinkCard,
  Tag,
} from 'common/subdomain/admin/AdminIntegrationSettings/constants';
import { P, Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/settings/AdminIntegrationSettings/_AdminIntegrationCard.module.scss';

import ZapierPlatformLogo from 'img/zapier-platform-logo-small.svg';

type IntegrationLinkProps = {
  label: string;
  children: React.ReactNode;
  connected: boolean;
  destination: string;
  external?: boolean;
  onClick?: () => void;
};

const IntegrationLink = ({
  label,
  children,
  connected,
  destination,
  external,
  onClick,
}: IntegrationLinkProps) => {
  const className = classNames(styles.card, { active: connected });
  const linkHint = `Configure ${label} settings.`;

  // onClick handles upsell, so it has to  be before the `external` check
  if (onClick) {
    return (
      <button aria-label={linkHint} className={className} onClick={onClick}>
        {children}
      </button>
    );
  }

  if (external) {
    const externalLinkHint = `Configure ${label} in a new tab.`;
    return (
      <a
        href={destination}
        target="_blank"
        rel="noopener"
        aria-label={externalLinkHint}
        className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link aria-label={linkHint} to={destination} className={className}>
      {children}
    </Link>
  );
};

type RightHandInfoProps = { tag?: Tag; zapier?: boolean };
const RightHandInfo = ({ tag, zapier }: RightHandInfoProps) => {
  if (tag) {
    return (
      <div className={styles.rightHandInfo}>
        <Pill
          pillStyle={tag === Tag.beta ? betaPill : DefaultPillStyles.canny}
          className={styles.tagPill}>
          {tag}
        </Pill>
      </div>
    );
  }

  if (zapier) {
    return (
      <div className={styles.rightHandInfo}>
        <Span className={styles.zapierAttribution} variant="bodySm">
          via <LazyLoadedImage className={styles.zapierPlatformLogo} src={ZapierPlatformLogo} />
        </Span>
      </div>
    );
  }

  return null;
};

const betaPill = {
  color: Colors.purple110,
  background: Colors.purple30,
};

type Props = {
  integration: IntegrationLinkCard;
};

const AdminIntegrationLinkCard = ({ integration }: Props) => {
  const { tag, icon: Icon } = integration;

  return (
    <IntegrationLink
      label={integration.label}
      connected={false}
      destination={integration.url}
      external={integration.external}
      onClick={integration.onClick}>
      <div className={styles.label}>
        {typeof Icon === 'string' ? (
          <LazyLoadedImage src={Icon} className={styles.icon} alt="" />
        ) : (
          Icon
        )}
        <div className={styles.labelPillContainer}>
          <P className={styles.labelCopy}>{integration.label}</P>
        </div>
      </div>
      <RightHandInfo tag={tag} zapier={integration.zapier} />
    </IntegrationLink>
  );
};

export default AdminIntegrationLinkCard;

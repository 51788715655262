import { loadLinearSettings } from 'common/actions/linearSettings';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';

const LinearSettingsContainer = (props) => {
  // props
  const { children, linearSettings } = props;

  return cloneElementWithProps(children, {
    ...props,
    linearSettings,
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch } }) => dispatch(loadLinearSettings()),
    },
  ],
  (state) => ({ linearSettings: state.linearSettings })
)(LinearSettingsContainer);

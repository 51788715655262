import React, { useRef, useState } from 'react';

import { MoreHorizontal, ThumbsDown, ThumbsUp } from 'lucide-react';

import Portal from 'common/common/Portal';
import Tooltip from 'common/common/Tooltip';
import useBackgroundClick from 'common/hooks/useBackgroundClick';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import MergePostDropdown from 'common/post/MergePostDropdown';
import ActionMenu from 'common/subdomain/admin/ActionMenu';
import IconButtonV2 from 'common/ui/IconButtonV2';
import { P, Span } from 'common/ui/Text';
import { isNotNil } from 'common/util/isNil';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_CommonActionsCell.module.scss';

import type { QueueRow as Row } from '../types';
import type { Board } from 'common/api/endpoints/boards';
import type { Post } from 'common/api/resources/posts';

enum Modal {
  merge = 'merge',
  delete = 'delete',
}

enum Action {
  approve = 'approve',
  merge = 'merge',
  delete = 'delete',
}

type Props = {
  row: Row;
  boards: Board[];
};

const getLabels = (row: Row) => {
  const isDuplicatePost = row.item.duplicatePost;
  const isCannyPost = row.item.source === 'canny';

  if (isDuplicatePost && isCannyPost) {
    return {
      action: 'Merge post?',
      positiveTooltip: 'Merge post',
      negativeTooltip: 'Do not merge',
      deleteModalCTA: 'Dismiss merge suggestion',
      deleteModalHeader: <>Are you sure you want to dismiss this merge&nbsp;suggestion?</>,
      deleteModalBody:
        'The suggestion will no longer be visible to you or other users in your workspace. This action cannot be reversed.',
    };
  }

  if (isDuplicatePost && !isCannyPost) {
    return {
      action: 'Merge post?',
      positiveTooltip: 'Merge as vote',
      negativeTooltip: 'Dismiss with no action',
      deleteModalCTA: 'Dismiss merge suggestion',
      deleteModalHeader: <>Are you sure you want to dismiss this merge&nbsp;suggestion?</>,
      deleteModalBody:
        'The suggestion will no longer be visible to you or other users in your workspace. This action cannot be reversed.',
    };
  }

  // no duplicate post
  if (isCannyPost) {
    return {
      action: 'Keep post?',
      positiveTooltip: 'Keep post',
      negativeTooltip: 'Delete post',
      deleteModalCTA: 'Delete post',
      deleteModalHeader: 'Are you sure you want to delete this post?',
      deleteModalBody:
        'The post will be deleted from the board and will no longer be visible. This action cannot be reversed.',
    };
  }

  return {
    action: 'Create post?',
    positiveTooltip: 'Create post',
    negativeTooltip: 'Dismiss with no action',
    deleteModalCTA: 'Dismiss feedback',
    deleteModalHeader: 'Are you sure you want to dismiss this feedback?',
    deleteModalBody:
      'The suggested feedback will no longer be visible to you or other users in your workspace. This action cannot be reversed.',
  };
};

const QueueActionsCell = ({ row, boards }: Props) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const [loadingAction, setLoadingAction] = useState<Action | null>();
  const mergeRef = useRef<HTMLButtonElement>(null);
  const menuPortalRef = useRef<Portal>(null);
  const portalRef = useRef<Portal>(null);

  useBackgroundClick(() => {
    if (modal === Modal.merge) {
      setModal(null);
      setMenuOpen(false);
    }
  }, [portalRef, mergeRef]);

  useBackgroundClick(() => {
    if (isMenuOpen) {
      setMenuOpen(false);
      setModal(null);
    }
  }, [menuPortalRef, mergeRef]);

  const toggleMenu = () => {
    setModal(null);
    setMenuOpen(!isMenuOpen);
  };

  const openMergeModal = () => {
    setMenuOpen(false);
    setModal(Modal.merge);
  };

  const openDeleteModal = () => {
    setMenuOpen(false);
    setModal(Modal.delete);
  };

  const performDelete = async () => {
    setLoadingAction(Action.delete);
    await row.actions.delete();
    setLoadingAction(null);
  };

  const performApprove = async () => {
    setMenuOpen(false);
    setLoadingAction(Action.approve);
    await row.actions.approve();
    setLoadingAction(null);
  };

  const performMerge = async (post: Post) => {
    setLoadingAction(Action.merge);
    await row.actions.merge(post);
    setLoadingAction(null);
  };

  const { action, positiveTooltip, negativeTooltip, ...deleteModalLabels } = getLabels(row);

  return (
    <div className={styles.queueActionsWrapper}>
      <Span className={styles.actionExplainerText}>{action}</Span>
      <div className={styles.queueActions}>
        <Tooltip delay={250} value={positiveTooltip} position="left">
          {row.item.duplicatePost ? (
            <IconButtonV2
              aria-label={positiveTooltip}
              className="merge"
              disabled={loadingAction === Action.merge}
              icon={ThumbsUp}
              loading={loadingAction === Action.merge}
              onClick={() => row.item.duplicatePost && performMerge(row.item.duplicatePost)}
              size="medium"
            />
          ) : (
            <IconButtonV2
              aria-label={positiveTooltip}
              className="approve"
              disabled={loadingAction === Action.approve}
              icon={ThumbsUp}
              loading={loadingAction === Action.approve}
              onClick={performApprove}
              size="medium"
            />
          )}
        </Tooltip>
        <Tooltip delay={250} value={negativeTooltip} position="left">
          <IconButtonV2
            aria-label={negativeTooltip}
            className="delete"
            disabled={loadingAction === Action.delete}
            icon={ThumbsDown}
            onClick={openDeleteModal}
            loading={loadingAction === Action.delete}
            size="medium"
          />
        </Tooltip>
        <Tooltip delay={250} value="More actions" position="left">
          <IconButtonV2
            icon={MoreHorizontal}
            ref={mergeRef}
            aria-label="More actions"
            size="medium"
            variant="outlined"
            onClick={toggleMenu}
          />
        </Tooltip>
      </div>
      {isMenuOpen && (
        <ActionMenu
          align="end"
          className="moreActionsPopover"
          ref={menuPortalRef}
          relativeToRef={mergeRef}
          options={[
            {
              action: openMergeModal,
              label: 'Add vote to other post',
            },
            row.item.source !== 'canny' && row.item.duplicatePost
              ? {
                  action: performApprove,
                  label: 'Create a new post',
                }
              : null,
          ].filter(isNotNil)}
        />
      )}
      {modal === Modal.delete && (
        <ModernConfirmModal
          confirmText={deleteModalLabels.deleteModalCTA}
          header={deleteModalLabels.deleteModalHeader}
          onClose={() => setModal(null)}
          onConfirm={performDelete}
          type="destructive">
          <P>{deleteModalLabels.deleteModalBody}</P>
        </ModernConfirmModal>
      )}
      {modal === Modal.merge && (
        <Portal
          align="end"
          className="modalPostDropdownPortal"
          zIndex={100}
          ref={portalRef}
          relativeToRef={mergeRef}>
          <MergePostDropdown
            autoFocus={true}
            board={row.item.board}
            boards={boards}
            placeholder="Search all posts..."
            permissionKey="voteOnBehalf"
            onPostSelected={row.actions.merge}
            post={row.item}
          />
        </Portal>
      )}
    </div>
  );
};

export default QueueActionsCell;

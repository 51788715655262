import React, { Component } from 'react';

import PropTypes from 'prop-types';

import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import Button from 'common/inputs/Button';
import CheckboxInput from 'common/inputs/CheckboxInput';
import TextInput from 'common/inputs/TextInput';
import KeyCodes from 'common/KeyCodes';
import AccessModal from 'common/modals/AccessModal';
import UpsellModal from 'common/modals/UpsellModal';
import Months from 'common/Months';
import Tappable from 'common/Tappable';
import hasPermission from 'common/util/hasPermission';
import withContexts from 'common/util/withContexts';
import validateInput from 'common/validateInput';

import 'css/components/post/_PostETAInput.scss';

class PostETAInput extends Component {
  static propTypes = {
    company: PropTypes.object,
    openModal: PropTypes.func,
    post: PropTypes.object,
    viewer: PropTypes.object,
  };

  state = {
    clearing: false,
    justClickedButtons: false,
    focused: false,
    saving: false,
    showUpsellModal: false,
    value: '',
  };

  constructor(props, context) {
    super(props, context);

    this.clickablesRef = React.createRef();
    this.etaInputRef = React.createRef();
    this.etaPublicInputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onMouseDown, false);
    document.addEventListener('mouseup', this.onMouseUp, false);

    const { post } = this.props;
    if (!post.eta) {
      return;
    }

    const eta = new Date(post.eta);
    const monthNumber = eta.getUTCMonth() + 1;
    const monthString = ('0' + monthNumber).slice(-2);
    const year = eta.getUTCFullYear();

    this.setState({
      value: monthString + '/' + year,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMouseDown, false);
    document.removeEventListener('mouseup', this.onMouseUp, false);
  }

  focusPostETA = async () => {
    const { company, openModal, viewer } = this.props;
    if (!hasPermission('changePostETA', company, viewer)) {
      openModal(AccessModal, {
        requiredPermissions: ['changePostETA'],
      });
      return;
    }

    this.setState({
      focused: true,
    });
  };

  onBlur = (e) => {
    this.setState({
      focused: this.state.justClickedButtons,
    });

    if (this.state.justClickedButtons) {
      this.etaInputRef.current.focus();
    }
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  onClear = () => {
    const { clearing } = this.state;
    if (clearing) {
      return;
    }

    this.setState({
      clearing: true,
    });

    const { post } = this.props;
    AJAX.post(
      '/api/posts/updateETA',
      {
        eta: null,
        etaPublic: true,
        postID: post._id,
      },
      (response) => {
        const { reloadPost } = this.props;
        reloadPost(post).then(() => {
          this.setState({
            clearing: false,
            focused: false,
            value: '',
          });
        });
      }
    );
  };

  onFocus = async () => {
    const { focused } = this.state;
    if (focused) {
      return;
    }

    const { features } = this.props.company;
    const planSupports = features?.postETAs;
    if (!planSupports) {
      this.setState({ showUpsellModal: true });
      return;
    }

    this.focusPostETA();
  };

  onMouseDown = (e) => {
    if (this.clickablesRef.current) {
      this.setState({
        justClickedButtons: this.clickablesRef.current.contains(e.target),
      });
    }
  };

  onMouseUp = (e) => {
    this.setState({
      justClickedButtons: false,
    });
  };

  onSave = () => {
    const { saving, value } = this.state;
    if (saving) {
      return;
    }

    this.setState({
      saving: true,
    });

    const { post } = this.props;
    const etaPublic = this.etaPublicInputRef.current.getValue();
    AJAX.post(
      '/api/posts/updateETA',
      {
        eta: value,
        etaPublic,
        postID: post._id,
      },
      (response) => {
        const { reloadPost } = this.props;
        reloadPost(post).then(() => {
          this.setState({
            focused: false,
            saving: false,
          });
        });
      }
    );
  };

  onUpsell = () => {
    this.setState({ showUpsellModal: false });
    this.focusPostETA();
  };

  onUpsellDismiss = () => {
    this.setState({ showUpsellModal: false });
  };

  renderClearButton() {
    const { clearing, saving } = this.state;
    const { post } = this.props;
    if (!post.eta) {
      return null;
    }

    return (
      <Button
        buttonType="greyButton"
        disabled={clearing || saving}
        loading={clearing}
        onTap={this.onClear}
        value="Clear"
      />
    );
  }

  renderSaveButton() {
    const { clearing, saving } = this.state;
    return (
      <Button disabled={clearing || saving} loading={saving} onTap={this.onSave} value="Save" />
    );
  }

  renderInput() {
    const { clearing, focused, saving, value } = this.state;
    if (!focused && !saving && !clearing) {
      return null;
    }

    return (
      <TextInput
        autoFocus={true}
        disabled={clearing || saving}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        placeholder="mm/yyyy"
        ref={this.etaInputRef}
        value={value}
        onKeyDown={this.onKeyDown}
      />
    );
  }

  onKeyDown = (e) => {
    const { value } = this.state;
    const isValid = validateInput.post.eta(value);

    if (!isValid) {
      return null;
    }

    if (e.keyCode === KeyCodes.Enter) {
      this.onSave();
    }
  };

  renderLabel() {
    const { clearing, focused, saving } = this.state;
    if (clearing || focused || saving) {
      return null;
    }

    const { post } = this.props;
    const className = 'label' + (post.eta ? ' set' : ' unset');

    return (
      <Tappable onTap={this.onFocus}>
        <div className={className}>{this.renderLabelValue()}</div>
      </Tappable>
    );
  }

  renderLabelValue() {
    const { company, post } = this.props;
    const { features } = company;
    if (!post.eta || !features?.postETAs) {
      return '—';
    }

    const eta = new Date(post.eta);
    return Months[eta.getUTCMonth()].substr(0, 3) + ' ' + eta.getUTCFullYear();
  }

  renderPublicCheckbox() {
    return (
      <div className="publicCheckbox">
        <CheckboxInput
          defaultChecked={this.props.post.etaPublic}
          label="Show Publicly"
          ref={this.etaPublicInputRef}
        />
      </div>
    );
  }

  renderClickables() {
    const { clearing, focused, saving, value } = this.state;
    const isValid = validateInput.post.eta(value);
    if ((!focused && !clearing && !saving) || !isValid) {
      return null;
    }

    return (
      <div className="clickables" ref={this.clickablesRef}>
        {this.renderPublicCheckbox()}
        <div className="buttons">
          {this.renderSaveButton()}
          {this.renderClearButton()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="postETA">
        <div className="input">
          <div className="label">Estimated</div>
          <div className="value">
            {this.renderLabel()}
            {this.renderInput()}
          </div>
        </div>
        {this.renderClickables()}
        <UpsellModal
          feature="postETA"
          onClose={this.onUpsellDismiss}
          onUpsell={this.onUpsell}
          show={this.state.showUpsellModal}
        />
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  openModal: OpenModalContext,
  viewer: ViewerContext,
})(PostETAInput);

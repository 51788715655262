import { postsLoaded } from './posts';
import Data from '../Data';

// Action Types

export const REQUEST_QUERY = 'canny/vote_queries/request_query';
export const QUERY_LOADED = 'canny/vote_queries/query_loaded';
export const QUERY_ERROR = 'canny/vote_queries/query_error';
export const LOADING_MORE = 'canny/vote_queries/loading_more';

// Actions

function requestQuery(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: REQUEST_QUERY,
  };
}

export function queryLoaded(queryParams, result) {
  return {
    queryParams,
    result,
    timestamp: Date.now(),
    type: QUERY_LOADED,
  };
}

function queryError(queryParams, error) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QUERY_ERROR,
  };
}

function loadingMore(queryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: LOADING_MORE,
  };
}

// Action Creators

function fetchQuery(queryParams) {
  return (dispatch, getState) => {
    const cookies = getState().cookies;
    return Data.fetch(
      {
        result: {
          input: getRequestData(queryParams),
          query: Data.queries.votes,
        },
      },
      cookies,
      (error, data) => {
        return gotResult(dispatch, queryParams, error, data);
      }
    );
  };
}

export function loadQuery(queryParams) {
  return (dispatch, getState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function loadMore(voteList) {
  return (dispatch, getState) => {
    const queryParams = voteList.queryParams;
    dispatch(loadingMore(queryParams));

    queryParams.pages = queryParams.pages ? queryParams.pages + 1 : 2;
    return dispatch(fetchQuery(queryParams));
  };
}

// Helper Functions

export function getVoteQueryKey(queryParams) {
  return JSON.stringify({
    ...(queryParams.filter && { filter: queryParams.filter }),
    ...(queryParams.user && { userID: queryParams.user._id }),
  });
}

function getRequestData(queryParams) {
  const pages = queryParams.pages ? queryParams.pages : 1;
  return {
    limit: pages * 10,
    userID: queryParams.user._id,
    ...(queryParams.filter && { filter: queryParams.filter }),
  };
}

function shouldFetchQuery(state, queryParams) {
  const voteQueries = state.voteQueries;
  const queryKey = getVoteQueryKey(queryParams);
  return !voteQueries[queryKey];
}

function isFetchingQuery(state, queryParams) {
  const voteQueries = state.voteQueries;
  const queryKey = getVoteQueryKey(queryParams);
  return !!voteQueries[queryKey].loading;
}

// Callback Queue

const resultCallbacks = {};

function waitForResult(queryParams) {
  const queryKey = getVoteQueryKey(queryParams);
  return new Promise((resolve, reject) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => {
      resolve();
    });
  });
}

function gotResult(dispatch, queryParams, error, data) {
  var resultAction;
  const promises = [];
  if (error) {
    resultAction = queryError(queryParams, error);
  } else {
    resultAction = queryLoaded(queryParams, data.result);
    promises.push(dispatch(postsLoaded(data.result.posts)));
  }

  return Promise.all(promises).then(() => {
    return Promise.all([dispatch(resultAction)]).then(() => {
      if (!resultCallbacks[queryParams]) {
        return;
      }

      resultCallbacks[queryParams].forEach((resultCallback) => {
        resultCallback();
      });
      resultCallbacks[queryParams].length = 0;
    });
  });
}

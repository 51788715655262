import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CloseModalContext } from 'common/containers/ModalContainer';
import Form from 'common/Form';
import withContexts from 'common/util/withContexts';

import Modal from './Modal';
import Button from '../inputs/Button';

import 'css/components/modals/_MarkSpamModal.scss';

class MarkSpamModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  onConfirm = () => {
    this.props.closeModal();
    this.props.onConfirm();
  };

  render() {
    return (
      <Modal>
        <Form
          className="markSpamModal"
          addEventsToDocument={true}
          disableSubmit={false}
          onSubmit={this.onConfirm}>
          <div className="message">
            <p>Are you sure you'd like to mark this as spam?</p>
            <div className="spamConsequences">
              <p>This will:</p>
              <ul>
                <li>Delete posts, comments, and votes from this user</li>
                <li>Ban them from creating future posts, comments, and votes</li>
              </ul>
            </div>
          </div>
          <div className="buttons">
            <Button buttonType="ghostButton" onTap={this.props.closeModal} value="Cancel" />
            <Button buttonType="cannyButton" formButton={true} tint={true} value="Yes" />
          </div>
        </Form>
      </Modal>
    );
  }
}

export default withContexts({ closeModal: CloseModalContext }, { forwardRef: true })(MarkSpamModal);

import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { IsWidgetContext } from 'common/containers/IsWidgetContainer';
import { TintColorContext } from 'common/containers/TintColorContainer';
import truncateString from 'common/truncateString';
import withContexts from 'common/util/withContexts';

import UserAvatar from './UserAvatar';
import Link from '../Link';

import 'css/components/user/_UserLockup.scss';

class UserLockup extends Component {
  static propTypes = {
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    company: PropTypes.shape({
      members: PropTypes.array,
      viewerIsMember: PropTypes.bool,
    }),
    isWidget: PropTypes.bool,
    showAlias: PropTypes.bool,
    showAvatar: PropTypes.bool,
    showBadge: PropTypes.bool,
    showCompanyNames: PropTypes.bool,
    showProfile: PropTypes.bool,
    showTint: PropTypes.bool,
    targetURL: PropTypes.string,
    tintColor: PropTypes.string,
    truncate: PropTypes.bool,
    user: PropTypes.shape({
      _id: PropTypes.string,
      aliasID: PropTypes.string,
      alias: PropTypes.string,
      avatarURL: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    showAvatar: true,
    showBadge: true,
    showCompanyNames: false,
    showProfile: true,
    showTint: true,
    targetURL: null,
    truncate: false,
  };

  renderCompanyNames() {
    const { showCompanyNames, user } = this.props;

    if (!showCompanyNames || !user.companies || !user.companies.length) {
      return null;
    }

    if (user.companies.length === 1) {
      return <div className="companyNames">{'\u00a0at ' + user.companies[0].name}</div>;
    }

    return (
      <p className="companyNames">
        {'\u00a0at ' + user.companies[0].name + ' and ' + (user.companies.length - 1) + ' more'}
      </p>
    );
  }

  renderName() {
    const { company, showAlias, showTint, tintColor, truncate, user } = this.props;
    if (!company || !company.members) {
      return null;
    }

    const isUserMember = company.members.some((member) => member._id === user._id);
    const truncatedName = truncateString(user.name, 20);
    const truncatedNameWithAlias = user.alias
      ? `${truncateString(user.name, 20)} (${user.alias})`
      : truncatedName;
    const nameWithAlias = user.alias ? `${user.name} (${user.alias})` : user.name;

    const names = {
      truncated: {
        showAlias: truncatedNameWithAlias,
        hideAlias: truncatedName,
      },
      full: {
        showAlias: nameWithAlias,
        hideAlias: user.name,
      },
    };
    const nameGroup = truncate ? names.truncated : names.full;
    const name = !isUserMember && showAlias ? nameGroup.showAlias : nameGroup.hideAlias;

    if (!isUserMember) {
      return <span className="name">{name}</span>;
    }

    const nameStyle = {
      ...(showTint && tintColor && { color: tintColor }),
    };
    const className = classnames('name', {
      admin: showTint,
    });
    return (
      <span className={className} style={nameStyle}>
        {name}
      </span>
    );
  }

  renderContents() {
    const {
      company: { viewerIsMember },
      isWidget,
      showAvatar,
      showBadge,
      showProfile,
      targetURL,
      user,
    } = this.props;
    if (!viewerIsMember || !showProfile || isWidget || !user.urlName || user.serviceAccount) {
      return (
        <div className="userLockup">
          {showAvatar && (
            <div className="userAvatarContainer">
              <UserAvatar showBadge={showBadge} user={user} />
            </div>
          )}
          <div className="userInfo">{this.renderName()}</div>
          {this.renderCompanyNames()}
        </div>
      );
    }

    const userProfileURL =
      targetURL ?? `/admin/users/${user.urlName}?search=${encodeURIComponent(user.name)}`;
    return (
      <Link className="userLockup tappable" to={userProfileURL}>
        {showAvatar && (
          <div className="userAvatarContainer">
            <UserAvatar showBadge={showBadge} user={user} />
          </div>
        )}
        <div className="userInfo">{this.renderName()}</div>
        {this.renderCompanyNames()}
      </Link>
    );
  }

  render() {
    const { containerClassName } = this.props;
    return (
      <div className={classnames('userLockupContainer', containerClassName)}>
        {this.renderContents()}
      </div>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  isWidget: IsWidgetContext,
  tintColor: TintColorContext,
})(UserLockup);

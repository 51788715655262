import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import Portal, { PortalContainerID } from 'common/common/Portal';
import KeyCodes from 'common/KeyCodes';

import 'css/components/modals/_ModalPortal.scss';

export default class ModalPortal extends Component {
  static propTypes = {
    allowBodyScroll: PropTypes.bool,
    allowEscape: PropTypes.bool,
    className: PropTypes.string, // Deprecated, use overlayClassName instead
    overlayClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    closeOnClickAway: PropTypes.bool,
    onClose: PropTypes.func,
    portalContainerID: PropTypes.string,
  };

  static defaultProps = {
    allowBodyScroll: false,
    allowEscape: true,
    closeOnClickAway: true,
    portalContainerID: PortalContainerID,
  };

  constructor(props, context) {
    super(props, context);
    this.backgroundRef = React.createRef();
    this.contentsRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.closeOnClickAway) {
      // Timeout to prevent the click event that opens this modal from also closing it.
      setTimeout(() => {
        document.addEventListener('pointerdown', this.onMouseDown);
        document.addEventListener('pointerup', this.onMouseUp);
      }, 0);
    }

    if (this.props.allowEscape) {
      document.addEventListener('keydown', this.onKeyDown);
    }

    if (!this.props.allowBodyScroll) {
      document.body.classList.add('modalPortalBodyLock');
    }
  }

  componentWillUnmount() {
    if (this.props.closeOnClickAway) {
      document.removeEventListener('pointerdown', this.onMouseDown);
      document.removeEventListener('pointerup', this.onMouseUp);
    }

    if (this.props.allowEscape) {
      document.removeEventListener('keydown', this.onKeyDown);
    }

    if (!this.props.allowBodyScroll) {
      document.body.classList.remove('modalPortalBodyLock');
    }
  }

  onKeyDown = (e) => {
    if (e.keyCode === KeyCodes.Escape) {
      this.props.onClose?.(e);
    }

    if (document.activeElement !== document.body) {
      return;
    }
  };

  onMouseDown = (e) => {
    if (this.contentsRef?.current && findDOMNode(this.contentsRef.current).contains(e.target)) {
      // Ignore clicks from within the contents.
      this._skipClose = true;
      return;
    }

    if (e.target.closest('.ignoreModalClickAway')) {
      // Ignore clicks on elements with the `ignoreModalClickAway` class.
      this._skipClose = true;
      return;
    }

    const portalContainer = document.getElementById(this.props.portalContainerID);
    const modalBackground = findDOMNode(this.backgroundRef.current);
    if (portalContainer && portalContainer.contains(e.target) && e.target !== modalBackground) {
      // Ignore clicks from within the portal container that is not the modal background
      this._skipClose = true;
      return;
    }
  };

  onMouseUp = (e) => {
    if (this._skipClose) {
      this._skipClose = false;
      return;
    }

    if (this.contentsRef?.current && findDOMNode(this.contentsRef.current).contains(e.target)) {
      // Ignore clicks from within the contents.
      return;
    }

    const portalContainer = document.getElementById(this.props.portalContainerID);
    const modalBackground = findDOMNode(this.backgroundRef.current);
    if (portalContainer && portalContainer.contains(e.target) && e.target !== modalBackground) {
      // Ignore clicks from within the portal container that is not the modal background
      return;
    }

    this.props.onClose?.(e);
  };

  render() {
    const portalClasses = classnames(
      'modalPortal',
      this.props.className,
      this.props.overlayClassName
    );
    const modalClasses = classnames('modalPortalContents', this.props.modalClassName);
    // Decreased zIndex to ensure our old modals appear on top of this modal.
    return (
      <Portal portalContainerID={this.props.portalContainerID} zIndex={2999}>
        <div className={portalClasses} ref={this.backgroundRef}>
          <div className={modalClasses} ref={this.contentsRef} role="dialog">
            {this.props.children}
          </div>
        </div>
      </Portal>
    );
  }
}

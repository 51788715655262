import {
  type ExternalIntegrationNames,
  type IntegrationItemNames,
  ReviewPlatformLabels,
  ReviewPlatformNames,
  SyncIntegrationNames,
  ZapierTemplateNames,
} from 'common/constants/autopilotIntegrations';
import AdminIntercomSettingsTeamInboxes from 'common/subdomain/admin/AdminIntercomSettingsTeamInboxes';

import AppStoreLogo from 'img/app-store-logo-small.svg';
import CapterraLogo from 'img/capterra-logo-small.svg';
import FreshdeskLogo from 'img/freshdesk-logo-small.svg';
import G2Logo from 'img/g2-logo-small.svg';
import GongLogo from 'img/gong-logo.png';
import GoogleFormsLogo from 'img/google-forms-logo-small.png';
import GooglePlayLogo from 'img/google-play-logo-small.svg';
import HelpscoutLogo from 'img/helpscout-logo.png';
import IntercomLogo from 'img/landing/intercom-logo.svg';
import ZendeskLogo from 'img/landing/zendesk-logo.svg';
import ProductHuntLogo from 'img/product-hunt-logo-small.svg';
import SalesforceLogo from 'img/salesforce-text-logo-small.svg';
import ShopifyLogo from 'img/shopify-logo-small.svg';
import SurveyMonkeyLogo from 'img/survey-monkey-logo-small.svg';
import TLDVLogo from 'img/tldv-logo-small.svg';
import TrustpilotLogo from 'img/trustpilot-logo-small.svg';
import TrustRadiusLogo from 'img/trustradius-logo-small.svg';
import TypeformLogo from 'img/typeform-logo-small.png';
import WordpressLogo from 'img/wordpress-logo-small.svg';
import ZapierLogo from 'img/zapier-logo-square-medium.png';
import ZoomLogo from 'img/zoom-logo-small.png';

import type { LucideIcon } from 'lucide-react';

export type SourceFilter = {
  description: string;
  component: React.ComponentType;
};

export enum SourceType {
  integration = 'integration',
  review = 'review',
  zapier = 'zapier',
}

type BaseIntegration = {
  label: string;
  name: SyncIntegrationNames;
  icon: string;
  sourceFilters?: SourceFilter[];
};

type ReviewIntegration = {
  type: SourceType.review;
  platform: ReviewPlatformNames;
  needsCountry?: boolean;
  credits: number;
};

type ConversationIntegration = {
  type: SourceType.integration;
  url: string;
  platform?: never;
  needsCountry?: never;
  credits: number;
};

type ZapierIntegration = {
  type: SourceType.zapier;
  credits: number | { min: number; max: number };
  url: string;
  platform?: never;
  needsCountry?: never;
};

export type Integration = BaseIntegration &
  (ReviewIntegration | ConversationIntegration | ZapierIntegration);

type InfoPoints = {
  [key in SyncIntegrationNames]?: string[];
} & {
  conversationDefault: string[];
  reviewDefault: string[];
};

const ticketDefaultInfoPoints = [
  'Pulls feedback from tickets closed in the past 30 days.',
  'Continuously collects feedback from newly closed tickets.',
  'Uses your current credit balance—no extra charges unless specified.',
];

export const InfoPoints: InfoPoints = {
  conversationDefault: [
    'Pulls feedback from conversations closed in the past 30 days.',
    'Continuously collects feedback from newly closed conversations.',
    'Uses your current credit balance—no extra charges unless specified.',
  ],
  reviewDefault: [
    'Pulls feedback from reviews left in the last 100 days',
    'Continuously gathers new feedback from new reviews',
    'Uses 1 credit per review, up until your allotted credit limit',
  ],
  [SyncIntegrationNames.freshdesk]: ticketDefaultInfoPoints,
  [SyncIntegrationNames.zendesk]: ticketDefaultInfoPoints,
};

export type IntegrationItem = {
  credits: number;
  label: string;
  logo: string | LucideIcon;
  name: ExternalIntegrationNames;
  needsCountry?: boolean;
  platform?: ReviewPlatformNames;
  type: IntegrationItemNames;
};

export type ZapierIntegrationItem = Omit<IntegrationItem, 'name' | 'credits' | 'type'> & {
  credits: number | { max: number; min: number };
  logo: string;
  name: ZapierTemplateNames;
  url: string;
};

export const ZapierIntegrations: ZapierIntegrationItem[] = [
  {
    credits: 1,
    label: 'Google Forms',
    logo: GoogleFormsLogo,
    name: ZapierTemplateNames.googleForms,
    url: 'https://zapier.com/app/editor/template/255575445',
  },
  {
    credits: 1,
    label: 'Survey Monkey',
    logo: SurveyMonkeyLogo,
    name: ZapierTemplateNames.surveyMonkey,
    url: 'https://zapier.com/app/editor/template/255575449',
  },
  {
    credits: 1,
    label: 'Typeform',
    logo: TypeformLogo,
    name: ZapierTemplateNames.typeform,
    url: 'https://zapier.com/app/editor/template/255575446',
  },
];

export const ZapierGenericIntegration: ZapierIntegrationItem = {
  credits: { min: 1, max: 5 },
  label: 'Build your own source',
  logo: ZapierLogo,
  name: ZapierTemplateNames.generic,
  url: 'https://zapier.com/app/editor/template/255575517',
};

const conversationSources = [
  {
    label: 'Intercom',
    name: SyncIntegrationNames.intercom,
    icon: IntercomLogo,
    sourceFilters: [
      {
        description: 'Use filters to select conversations you want to process',
        component: AdminIntercomSettingsTeamInboxes,
      },
    ],
    url: '/admin/settings/intercom',
  },
  {
    label: 'Freshdesk',
    name: SyncIntegrationNames.freshdesk,
    icon: FreshdeskLogo,
    url: '/admin/settings/freshdesk',
  },
  {
    label: 'Zendesk',
    name: SyncIntegrationNames.zendesk,
    icon: ZendeskLogo,
    url: '/admin/settings/zendesk',
  },
  // TODO: in follow-up PR
  // {
  //   label: 'Gong',
  //   name: SyncIntegrationNames.gong,
  //   icon: GongLogo,
  //   url: '/admin/settings/gong',
  //   credits: 5,
  // },
  {
    label: 'Help Scout',
    name: SyncIntegrationNames.helpscout,
    icon: HelpscoutLogo,
    url: '/admin/settings/helpscout',
  },
  {
    label: 'TLDV',
    name: SyncIntegrationNames.tldv,
    icon: TLDVLogo,
    url: '/admin/settings/tldv',
  },
  {
    label: 'Zapier',
    name: SyncIntegrationNames.zapier,
    icon: ZapierLogo,
    url: 'https://zapier.com/app/editor/template/255575517',
  },
  {
    label: 'Zoom',
    name: SyncIntegrationNames.zoom,
    icon: ZoomLogo,
    url: '/admin/settings/zoom',
  },
];

// TODO: bring into conversationSources above once getting-started can handle Gong
export const GongSource: Integration = {
  credits: 5,
  icon: GongLogo,
  label: 'Gong',
  name: SyncIntegrationNames.gong,
  url: '/admin/settings/gong',
  type: SourceType.integration,
};

// TODO: bring into conversationSources above once getting-started can handle Zapier
export const ZapierSources: Integration[] = ZapierIntegrations.map((zapTemplate) => ({
  label: zapTemplate.label,
  icon: zapTemplate.logo,
  type: SourceType.zapier,
  name: SyncIntegrationNames.zapier,
  url: zapTemplate.url,
  credits: zapTemplate.credits,
}));

// TODO: make sure to update mapping to use "credits" once Gong/Zoom is added into conversationSources
export const mappedConversationSources: Record<string, Integration> = conversationSources.reduce(
  (map, { label, name, icon, url }) => ({
    ...map,
    [name]: {
      label,
      name,
      icon,
      type: SourceType.integration,
      url,
      credits: 1,
    },
  }),
  {}
);

const reviewSources = [
  {
    name: SyncIntegrationNames.salesforceReviews,
    label: ReviewPlatformLabels.salesforce,
    icon: SalesforceLogo,
    platform: ReviewPlatformNames.salesforce,
  },
  {
    name: SyncIntegrationNames.appStoreReviews,
    icon: AppStoreLogo,
    platform: ReviewPlatformNames.appStore,
    needsCountry: true,
  },
  {
    name: SyncIntegrationNames.playStoreReviews,
    icon: GooglePlayLogo,
    platform: ReviewPlatformNames.playStore,
    needsCountry: true,
  },
  {
    name: SyncIntegrationNames.g2Reviews,
    icon: G2Logo,
    platform: ReviewPlatformNames.g2,
  },
  {
    name: SyncIntegrationNames.capterraReviews,
    icon: CapterraLogo,
    platform: ReviewPlatformNames.capterra,
  },
  {
    name: SyncIntegrationNames.trustpilotReviews,
    icon: TrustpilotLogo,
    platform: ReviewPlatformNames.trustpilot,
  },
  {
    name: SyncIntegrationNames.trustradiusReviews,
    icon: TrustRadiusLogo,
    platform: ReviewPlatformNames.trustradius,
  },
  {
    name: SyncIntegrationNames.productHuntReviews,
    icon: ProductHuntLogo,
    platform: ReviewPlatformNames.productHunt,
  },
  {
    name: SyncIntegrationNames.wordpressReviews,
    icon: WordpressLogo,
    platform: ReviewPlatformNames.wordpress,
  },
  {
    name: SyncIntegrationNames.shopifyReviews,
    icon: ShopifyLogo,
    platform: ReviewPlatformNames.shopify,
  },
];

const mappedReviewSources = reviewSources.reduce(
  (map, { name, icon, platform, needsCountry, label }) => ({
    ...map,
    [name]: {
      label: label ?? platform,
      name,
      icon,
      platform,
      needsCountry,
      type: SourceType.review,
      credits: 1,
    },
  }),
  {}
);

export const IntegrationMap: Record<string, Integration> = {
  ...mappedConversationSources,
  ...mappedReviewSources,
};

export const Integrations = Object.values(IntegrationMap);

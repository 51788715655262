export default function processStage(items, stage) {
  const result = [];
  items.forEach((item, i) => {
    if (typeof item === 'object') {
      if (!item.final && !(item.skip || []).includes(stage.name)) {
        item.items = processStage(item.items, stage);
      }
      result.push(item);
      return;
    }

    let position = 0;
    while (true) {
      const match = stage.regex.exec(item);
      if (!match) {
        const after = item.substring(position, item.length);
        if (after.length > 0) {
          result.push(after);
        }
        break;
      }

      const before = item.substring(position, match.index);
      if (before.length > 0) {
        result.push(before);
      }

      const contents = match[1];
      if (contents.length > 0) {
        const item = {
          className: stage.className,
          componentName: stage.componentName,
          final: stage.final,
          items: [contents],
          skip: stage.skip,
        };

        if (!stage.transform) {
          result.push(item);
        } else {
          const transformed = stage.transform(item, match);
          if (Array.isArray(transformed)) {
            result.push(...transformed);
          } else {
            result.push(transformed);
          }
        }
      }

      position = match.index + match[0].length;
    }
  });

  return result;
}

import {
  Invalidate,
  LoadingMore,
  QueryError,
  QueryLoaded,
  RequestQuery,
  getEntryQueryKey,
} from '../actions/changelogEntryQueries';

export default function changelogEntryQueries(state = {}, action) {
  switch (action.type) {
    case RequestQuery: {
      const queryKey = getEntryQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loading: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case QueryLoaded: {
      const entries = [];
      action.result.entries.forEach((entry) => {
        entries.push({
          entryURLName: entry.urlName,
        });
      });
      const queryKey = getEntryQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          entries,
          hasNextPage: action.result.hasNextPage,
          lastUpdated: action.timestamp,
          loading: false,
          loadingMore: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case QueryError: {
      const queryKey = getEntryQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          error: action.error,
          lastUpdated: action.timestamp,
          loading: false,
          queryParams: action.queryParams,
        }),
      });
    }

    case LoadingMore: {
      const queryKey = getEntryQueryKey(action.queryParams);
      return Object.assign({}, state, {
        [queryKey]: Object.assign({}, state[queryKey], {
          lastUpdated: action.timestamp,
          loadingMore: true,
          queryParams: action.queryParams,
        }),
      });
    }

    case Invalidate: {
      return {};
    }

    default:
      return state;
  }
}

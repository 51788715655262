import React from 'react';

import ContentContainer from 'common/containers/ContentContainer';
import SubdomainSearchHelmet from 'common/helmets/SubdomainSearchHelmet';
import PostList from 'common/post/PostList';

import 'css/components/subdomain/public/_SubdomainSearch.scss';

const SubdomainSearch = ({ boards, location, postList }) => {
  return (
    <ContentContainer key="search" innerClassName="subdomainSearch">
      <SubdomainSearchHelmet location={location} />
      <h1>Search Results</h1>
      <PostList
        autoFocus={false}
        boards={boards}
        postList={postList}
        noPostsMessage="We couldn't find anything. Try a new search or create a new post!"
        showBoard={true}
        showComments={true}
        showMenu={false}
        showPrivateComments={false}
      />
    </ContentContainer>
  );
};

export default SubdomainSearch;

import React, { Component } from 'react';

import { VenetianMask } from 'lucide-react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import { invalidatePostQueries } from 'common/actions/postQueries';
import { invalidateSuggestions } from 'common/actions/postSuggestions';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import ContentContainer from 'common/containers/ContentContainer';
import { GetExperimentVariationContext } from 'common/containers/ExperimentContainer';
import { GetPostLinkContext } from 'common/containers/PostLinkContainer';
import SubdomainSidebarContainer from 'common/containers/SubdomainSidebarContainer';
import { ShowToastContext } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import BoardHomeHelmet from 'common/helmets/BoardHomeHelmet';
import translateString from 'common/i18n/translateString';
import Link from 'common/Link';
import Markdown from 'common/markdown/Markdown';
import CreatePostFormV2 from 'common/post/CreatePostFormV2';
import PostList from 'common/post/PostList';
import PostListItemV2 from 'common/post/PostListItemV2';
import { H1, P } from 'common/ui/Text';
import delayer from 'common/util/delayer';
import parseAPIResponse from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/public/_BoardHome.scss';

class BoardHome extends Component {
  static propTypes = {
    board: PropTypes.object,
    location: PropTypes.shape({
      query: PropTypes.object,
      state: PropTypes.object,
    }),
    postList: PropTypes.object,
    showToast: PropTypes.func,
  };

  state = {
    searchQuery: this.props.location.query.search,
    isCreatingPost: false,
  };

  constructor(props, context) {
    super(props, context);

    // we need this to be in a constructor because it references the object's method
    this._formDelayer = new delayer(this.onCreatePostFormUpdate, 500);
  }

  componentDidMount() {
    const {
      location: {
        query: { postDeleted },
      },
      showToast,
    } = this.props;
    if (!postDeleted) {
      return;
    }

    setTimeout(() => {
      showToast('The post has been deleted');
    }, 500);
  }

  componentWillUnmount() {
    this._formDelayer.cancel();
  }

  onCreatePostFormUpdate = (form) => {
    const { location, router, board } = this.props;
    const category = form.categoryID
      ? board.categories.find((category) => category._id === form.categoryID)
      : null;

    router.replace({
      pathname: location.pathname,
      query: { ...location.query, selectedCategory: category?.urlName },
      state: Object.assign({ createForm: form }),
    });
  };

  getDefaultCreatePostForm = () => {
    const { location, board } = this.props;
    const form = location.state?.createForm ?? {};
    const { selectedCategory } = location.query;

    if (!selectedCategory) {
      return form;
    }

    const category = board.categories.find((category) => category.urlName === selectedCategory);
    if (!category) {
      return form;
    }

    return {
      ...form,
      categoryID: category._id,
    };
  };

  resetForm = async () => {
    const { router, location } = this.props;
    router.replace({
      pathname: location.pathname,
      query: location.query,
      state: Object.assign({ createForm: null }),
    });

    this._formDelayer.cancel();
  };

  createPost = async (form) => {
    const {
      board,
      company,
      getPostLink,
      reloadBoard,
      reloadCompany,
      invalidatePostQueries,
      router,
    } = this.props;

    this.setState({ isCreatingPost: true });

    const response = await AJAX.post('/api/posts/create', form);

    const { error, parsedResponse } = parseAPIResponse(response, {
      isSuccessful: (parsedResponse) => parsedResponse.post,
      errors: {
        spam: `Our system identifies parts of this post as spam. Please, try with different content.`,
        'slow down':
          'You are trying to create posts too fast. Please wait a few minutes before trying again.',
        'invalid boardID': 'The board does not exist. Refresh and try again.',
        'invalid categoryID': 'The category you chose does not exist. Refresh and try again.',
        'not authorized': 'You are not authorized to create a post. Refresh and try again.',
        'invalid author id': 'You are not authorized to create a post. Refresh and try again.',
        'too many fields': 'Something went wrong, please refresh and try again.',
        'invalid field types': 'There are invalid fields on the form. Refresh and try again',
        'missing required fields':
          'There are missing required fields on the form. Refresh and try again.',
      },
    });

    if (error || !parsedResponse) {
      this.setState({ isCreatingPost: false });
      throw new Error(error.message);
    }

    this.resetForm();

    invalidatePostQueries();
    reloadBoard(board.urlName);
    if (company.viewerIsMember && company.postCount < 3) {
      reloadCompany();
    }

    router.push(getPostLink(parsedResponse.post));
  };

  renderCreateNewCTA() {
    const createHref = '/' + this.props.board.urlName + '/create';
    const state = { createTitle: this.props.location.query.search };
    return (
      <div className="newPostCTA">
        {"Don't see your post? "}
        <Link to={createHref} state={state}>
          Create New
        </Link>
      </div>
    );
  }

  renderNoPostsMessage() {
    if (this.props.location.query.search) {
      return "We couldn't find anything. Try a new search or create a new post!";
    } else {
      const { state } = this.props.location;
      if (state && state.createForm && (state.createForm.title || state.createForm.details)) {
        return 'There are no suggested posts.';
      } else {
        return 'There are no posts.';
      }
    }
  }

  renderSuggestedPosts() {
    const { location, postList } = this.props;
    const {
      state: { createForm },
    } = location;
    const isPendingSearch = createForm.pendingSearch;
    const isLoadingTextSearch = (createForm.details || createForm.title) && postList.loading;

    if (isPendingSearch || isLoadingTextSearch) {
      return <div className="searching">Looking for similar posts...</div>;
    }

    return (
      <div className="suggestedPosts">
        <div className="heading">Suggested posts</div>
        <PostList
          ItemComponent={PostListItemV2}
          noPostsMessage={this.renderNoPostsMessage()}
          postList={postList}
          showBoard={true}
          showMenu={false}
          showComments={true}
          showPrivateComments={false}
        />
      </div>
    );
  }

  renderPostList() {
    const { board, location, postList } = this.props;
    const { query, state } = location;
    const createForm = state && state.createForm;
    if (createForm && (createForm.pendingSearch || createForm.title || createForm.details)) {
      return this.renderSuggestedPosts();
    }

    return (
      <PostList
        autoFocus={false}
        board={board}
        ItemComponent={PostListItemV2}
        postList={postList}
        noPostsMessage={this.renderNoPostsMessage()}
        // Show the board if the user is searching
        showBoard={!!query?.search}
        showComments={true}
        showPrivateComments={false}
      />
    );
  }

  render() {
    const { board, boards } = this.props;
    if (!board) {
      return null;
    }

    return (
      <ContentContainer key={board._id} outerClassName="boardHome">
        <BoardHomeHelmet board={board} />
        <SubdomainSidebarContainer boards={boards} board={board}>
          <div className="boardContent">
            <header className="boardHeader">
              <div className="nameSection">
                <H1 variant="headingMd" fontWeight="semibold">
                  {translateString(board, 'name')}
                </H1>
                {board.settings.privateAuthors && (
                  <Pill className="anonymousPill" pillStyle={DefaultPillStyles.info}>
                    <P variant="bodySm" className="pillContainer">
                      <VenetianMask size={14} />
                      Anonymous
                    </P>
                  </Pill>
                )}
              </div>
              <div className="boardDescription">
                <Markdown contents={translateString(board.strings, 'description')} />
              </div>
            </header>
            {board.settings.showCreateForm && (
              <CreatePostFormV2
                className="createPostFormV2"
                board={board}
                defaultVisibility="open"
                loading={this.state.isCreatingPost}
                defaultForm={this.getDefaultCreatePostForm()}
                onCreate={this.createPost}
                onUpdate={this._formDelayer.callAfterDelay}
                onCancel={this.resetForm}
              />
            )}
            <div className="postListContainer">{this.renderPostList()}</div>
          </div>
        </SubdomainSidebarContainer>
      </ContentContainer>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    invalidatePostQueries: () => {
      return Promise.all([dispatch(invalidatePostQueries()), dispatch(invalidateSuggestions())]);
    },
    reloadBoard: (boardURLName) => {
      return dispatch(reloadBoard(boardURLName));
    },
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      showToast: ShowToastContext,
      company: CompanyContext,
      getPostLink: GetPostLinkContext,
      getExperimentVariation: GetExperimentVariationContext,
    },
    { forwardRef: true }
  )
)(BoardHome);

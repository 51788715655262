import React, { useEffect, useState } from 'react';

import AJAX from 'common/AJAX';
import ControlledDropdown from 'common/ControlledDropdown';
import Form from 'common/Form';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import Button from 'common/inputs/Button';
import ModernConfirmModal from 'common/modals/ModernConfirmModal';
import UpsellModal from 'common/modals/UpsellModal';
import DefaultCompanyRoleNames from 'common/roles/CompanyRoleNames';
import { companyRoleHasBillablePermissions } from 'common/roles/utils';
import { P, Span } from 'common/ui/Text';
import getSlidingQuantity from 'common/util/getSlidingQuantity';
import mapify from 'common/util/mapify';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import roundCents from 'common/util/roundCents';
import validateInput from 'common/validateInput';

import type { Company, Role } from 'common/api/endpoints/companies';
import type { Viewer } from 'common/api/endpoints/viewer';

type Props = {
  company: Company;
  viewer: Viewer;
  onUpsell: () => void;
};

enum Modal {
  invite = 'invite',
}

type State = {
  error: React.ReactNode;
  emailString: string;
  hasMultipleEmails: boolean;
  invitesSent: boolean;
  inviteEmails: string[];
  isSendingInvites: boolean;
  role: Role | null;
  upsell: boolean;
  modal: Modal | null;
};

const validateEmails = (
  emails: string[]
): { valid: true; error: null } | { valid: false; error: string } => {
  const invalidEmails = emails.reduce<string[]>((acc, email) => {
    if (!validateInput.email(email)) {
      return acc.concat(email);
    }
    return acc;
  }, []);

  if (invalidEmails.length > 1) {
    return {
      valid: false,
      error: `"${invalidEmails.join('", "')}" are not valid email addresses.`,
    };
  } else if (invalidEmails.length > 0) {
    return { valid: false, error: `"${invalidEmails[0]}" is not a valid email address.` };
  }
  return { valid: true, error: null };
};

const InviteForm = ({ company, viewer, onUpsell }: Props) => {
  const [state, setState] = useState<State>({
    error: null,
    emailString: '',
    hasMultipleEmails: false,
    invitesSent: false,
    inviteEmails: [],
    isSendingInvites: false,
    role: null,
    upsell: false,
    modal: null,
  });

  useEffect(() => {
    if (!company.features.adminRoles) {
      setState((prevState) => ({
        ...prevState,
        role: company.roles.find((role) => role.name === DefaultCompanyRoleNames.owner) ?? null,
      }));
    }
  }, [company.features.adminRoles, company.roles]);

  const findAlreadyMemberEmails = (splitEmails: string[]) => {
    const { members } = company;
    const membersMap = mapify(members, 'email');

    const alreadyMemberEmails: string[] = [];
    splitEmails.forEach((email) => {
      if (membersMap[email]) {
        alreadyMemberEmails.push(email);
      }
    });
    return alreadyMemberEmails;
  };

  const onUpsellClose = () => {
    setState((prevState) => ({
      ...prevState,
      upsell: false,
    }));
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const splitEmails = value.trim().split(/[, ]+/);
    setState((prevState) => ({
      ...prevState,
      emailString: value,
      error: null,
      hasMultipleEmails: splitEmails.length > 1,
      inviteEmails: splitEmails,
      invitesSent: false,
    }));
  };

  const onSubmitInvite = async () => {
    const { role, emailString } = state;

    const splitEmails = emailString.trim().split(/[, ]+/);
    const { valid, error: validationError } = validateEmails(splitEmails);

    if (!valid || !role) {
      setState((prevState) => ({
        ...prevState,
        error: validationError,
      }));
      return;
    }

    const alreadyMemberEmails = findAlreadyMemberEmails(splitEmails);
    if (alreadyMemberEmails.length) {
      setState((prevState) => ({
        ...prevState,
        error: (
          <>
            <P>
              Some of the emails belong to members, you can change a role from the list. Please
              check the emails you entered.
            </P>
            <P>{alreadyMemberEmails.join(', ')}</P>
          </>
        ),
      }));
      return;
    }

    const adminCount = company.stats.adminCount;
    const adminLimit = company.limits.admins;
    const adminSliding = company.sliding.admins;
    const hasLimit = !!adminLimit;

    if (
      hasLimit &&
      adminCount >= adminLimit &&
      !adminSliding &&
      companyRoleHasBillablePermissions(company, role)
    ) {
      onUpsell();
      return;
    }

    // show confirmation modal
    setState((prevState) => ({
      ...prevState,
      modal: Modal.invite,
    }));
  };

  const sendInvites = async () => {
    const { inviteEmails, role } = state;
    if (!role) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isSendingInvites: true,
      modal: null,
    }));

    const response = await AJAX.post('/api/company/inviteAdmins', {
      emails: inviteEmails,
      roleID: role._id,
      viewer,
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'too many emails': `You've entered too many emails at once. Please enter less than 50 at a time.`,
        'slow down': `You've invited the maximum amount of users allowed per day. Please try again tomorrow or contact support.`,
      },
    });

    if (error) {
      setState((prevState) => ({
        ...prevState,
        error: error.message,
        isSendingInvites: false,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      emailString: '',
      invitesSent: true,
      inviteEmails: [],
      hasMultipleEmails: false,
      isSendingInvites: false,
      validEmails: false,
    }));
  };

  const onRoleChange = (roleID: string) => {
    if (!company.features.adminRoles) {
      setState((prevState) => ({
        ...prevState,
        upsell: true,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      role: company.roles.find((role) => role._id === roleID) ?? null,
    }));
  };

  const getPriceIncrease = (adminCountIncrease: number) => {
    if (!company.sliding?.admins) {
      return 0;
    }

    const currentSlidingQuantity = getSlidingQuantity(
      company.stats.adminCount,
      company.limits.admins,
      company.sliding.admins
    );

    const nextSlidingQuantity = getSlidingQuantity(
      company.stats.adminCount + adminCountIncrease,
      company.limits.admins,
      company.sliding.admins
    );

    return (nextSlidingQuantity - currentSlidingQuantity) * company.sliding.admins.price;
  };

  const getPlanTimeframe = () => {
    if (!company.billingData?.plan?.timeframe) {
      return null;
    }

    return company.billingData.plan.timeframe === 'monthly' ? 'mo' : 'yr';
  };

  const {
    isSendingInvites,
    invitesSent,
    inviteEmails,
    hasMultipleEmails,
    role,
    emailString,
    upsell,
  } = state;

  const isDataMissing = !role || emailString.trim().length === 0;
  const priceIncrease = roundCents(getPriceIncrease(inviteEmails.length) / 100);

  return (
    <Form
      addEventsToDocument={false}
      className="inviteForm"
      disableSubmit={isDataMissing || isSendingInvites}
      onSubmit={onSubmitInvite}>
      <span className="explain">Invite admins via email:</span>
      <AutoResizeTextarea
        onChange={onEmailChange}
        placeholder="Email addresses, separated by commas or spaces"
        value={state.emailString}
      />
      <div className="submitSection">
        <ControlledDropdown
          className="roleDropdown"
          onChange={onRoleChange}
          placeholder="Select role..."
          selectedName={role?._id}
          options={company.roles.map((role) => {
            return {
              name: role._id,
              render: role.name,
            };
          })}
        />
        <Button
          buttonType="cannyButton"
          disabled={isDataMissing}
          formButton={true}
          loading={isSendingInvites}
          value={invitesSent ? 'Invites Sent!' : hasMultipleEmails ? 'Send Invites' : 'Send Invite'}
        />
      </div>
      {state.modal === 'invite' && (
        <ModernConfirmModal
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              modal: null,
            }))
          }
          onConfirm={sendInvites}
          confirmText={hasMultipleEmails ? 'Send invites' : 'Send invite'}
          header={`Invite ${hasMultipleEmails ? 'admins' : 'admin'} to your workspace`}>
          <P>
            You are inviting {inviteEmails.length}{' '}
            {hasMultipleEmails ? 'individuals' : 'individual'} to join your workspace.{' '}
            {priceIncrease > 0 && role && companyRoleHasBillablePermissions(company, role) ? (
              <P>
                This action will result in a{' '}
                <Span fontWeight="semibold">
                  ${numberWithCommas(priceIncrease)}/{getPlanTimeframe()}
                </Span>{' '}
                prorated increase to your bill.
              </P>
            ) : (
              `This action will not incur any additional charges.`
            )}
          </P>
        </ModernConfirmModal>
      )}
      <UpsellModal
        cta="Manage teammate permissions"
        feature="adminRoles"
        onClose={onUpsellClose}
        onUpsell={onUpsellClose}
        show={upsell}
      />
      {state.error ? <span className="error">{state.error}</span> : null}
    </Form>
  );
};

export default InviteForm;

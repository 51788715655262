import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import PercentageInput from 'common/inputs/PercentageInput';
import TextInput from 'common/inputs/TextInput';
import TextInputWithSuggestions from 'common/inputs/TextInputWithSuggestions';
import Tappable from 'common/Tappable';
import { P } from 'common/ui/Text';

import 'css/components/subdomain/admin/AdminRoadmap/_AdminRoadmapTextCell.scss';

export default class AdminRoadmapTextCell extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    percentage: PropTypes.bool,
    placeholder: PropTypes.string,
    suggestions: PropTypes.array,
    value: PropTypes.string,
  };

  state = {
    editedValue: null,
    focused: false,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate() {
    const { editedValue } = this.state;
    // After this rerenders with the updated value, then we no longer use the editedValue.
    if (editedValue !== null && Number(editedValue) === Number(this.props.value)) {
      this.setState({ editedValue: null });
    }
  }

  onBlur = () => {
    this.setState({ focused: false });
  };

  onChange = (e, value) => {
    this.setState({
      editedValue: `${value ?? e.target.value}`,
    });
    this.props.onChange?.(e, value);
  };

  onFocus = (e) => {
    e.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this.setState({ focused: true }, () => {
      this.inputRef.current.focus();
    });
  };

  renderValue() {
    const { className, disabled, percentage, placeholder, value } = this.props;
    const { editedValue, focused } = this.state;
    // Render using the editedValue if the saved value hasn't been stored yet.
    const liveValue = editedValue !== null ? editedValue : value;

    if (!focused) {
      const showPlaceholder = typeof liveValue !== 'string' || liveValue === '';
      const text = showPlaceholder ? placeholder : liveValue;
      const classes = classnames(className, { placeholder: showPlaceholder });
      return (
        <Tappable onTapStart={this.onFocus} stopPropagation>
          <P variant="bodyMd" className={classes} tabIndex="0" onFocus={this.onFocus}>
            {text}
            {percentage ? '%' : ''}
          </P>
        </Tappable>
      );
    }

    if (this.props.percentage) {
      return (
        <PercentageInput
          ref={this.inputRef}
          className={className}
          defaultValue={liveValue}
          disabled={disabled}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyDown={this.props.onKeyDown}
        />
      );
    } else if (this.props.suggestions?.length > 0) {
      return (
        <TextInputWithSuggestions
          ref={this.inputRef}
          className={className}
          defaultValue={liveValue}
          disabled={disabled}
          hideSuggestionsOnTyping={true}
          maxLength={this.props.maxLength}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyDown={this.props.onKeyDown}
          options={this.props.suggestions}
          placeholder={placeholder}
          selectOnFocus={true}
        />
      );
    }

    return (
      <TextInput
        ref={this.inputRef}
        className={className}
        disabled={disabled}
        defaultValue={liveValue}
        maxLength={this.props.maxLength}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={this.props.onKeyDown}
        placeholder={placeholder}
      />
    );
  }

  render() {
    const { disabled } = this.props;
    // when disabled, support keydown events by attaching them to the container.
    // this allows to be able to navigate disable cells.
    return (
      <div
        tabIndex={disabled ? 0 : -1}
        className={classnames('adminRoadmapTextCell', { disabled })}
        onKeyDown={disabled ? (e) => this.props.onKeyDown(e) : null}>
        {this.renderValue()}
      </div>
    );
  }
}

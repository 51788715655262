import React from 'react';

import withAccessControl from 'common/routing/withAccessControl';
import { H2, P } from 'common/ui/Text';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import styles from 'css-module/components/subdomain/admin/_AdminCompanyFieldsSettings.module.scss';

import FieldMappingTable from './FieldMappingTable';
import RevenuePreferencesSettings from './RevenuePreferencesSettings';

const AdminCompanyFieldsSettings = () => {
  return (
    <section className={styles.adminCompanyFieldsSettings}>
      <header className={styles.header}>
        <H2 variant="headingMd">Company Fields</H2>
        <P className={styles.subheading}>
          Map data passed in from the Canny SDK or integrations to Canny fields for powerful
          analytics. Only fields matching data types will be&nbsp;displayed.
        </P>
      </header>
      <FieldMappingTable />
      <RevenuePreferencesSettings />
    </section>
  );
};

export default withAccessControl(
  testEveryPermission(RoutePermissions.adminSettings.postFields['company-fields']),
  '/admin/settings',
  { forwardRef: true }
)(AdminCompanyFieldsSettings) as unknown as React.FC<unknown>;

import { decode, encode } from 'common/util/base64';
import promisify from 'common/util/promisify';

import Data from '../Data';

import type {
  CompanyWithFeatureRequests,
  Filters,
  Sort,
} from 'common/api/endpoints/thirdPartyCompanyFeatureRequests';
import type { State } from 'common/reducers/thirdPartyCompanyFeatureRequestsQueries';
import type { Dispatch, GetState } from 'redux-connect';
// Action Types
export const RequestQuery = 'canny/third_party_company_feature_requests/request_query';
export const QueryLoaded = 'canny/third_party_company_feature_requests/query_loaded';
export const QueryError = 'canny/third_party_company_feature_requests/query_error';
export const InvalidateAll = 'canny/third_party_company_feature_requests/invalidate_all';
export const InvalidateAndReload =
  'canny/third_party_company_feature_requests/invalidate_and_reload';

type FullReduxState = {
  thirdPartyCompanyFeatureRequestsQueries: State;
};

export type ThirdPartyCompanyFeatureWithRequestsState = State;

export type QueryParams = {
  sort: Sort | null;
  filter: Filters | null;
};

type Result = CompanyWithFeatureRequests[];

export type ThirdPartyCompanyFeatureRequestsAction = Action & {
  queryParams: QueryParams;
  result: Result;
};

// Actions

function requestQuery(queryParams: QueryParams) {
  return {
    queryParams,
    timestamp: Date.now(),
    type: RequestQuery,
  };
}

function queryLoaded(queryParams: QueryParams, result: Result, reload = false) {
  return {
    queryParams,
    reload,
    result,
    timestamp: Date.now(),
    type: QueryLoaded,
  };
}

function queryError(queryParams: QueryParams, error: string) {
  return {
    error,
    queryParams,
    timestamp: Date.now(),
    type: QueryError,
  };
}

function invalidatingAndReloading(queryParams: QueryParams) {
  return {
    timestamp: Date.now(),
    queryParams,
    type: InvalidateAndReload,
  };
}

function invalidateAll() {
  return {
    timestamp: Date.now(),
    type: InvalidateAll,
  };
}

// Action Creators
function fetchQuery(queryParams: QueryParams, reload = false) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const cookies = getState().cookies;
    try {
      const data = await promisify(
        Data.fetch,
        {
          result: {
            input: queryParams,
            query: Data.queries.thirdPartyCompanyFeatureRequests,
          },
        },
        cookies
      );
      return gotResult(dispatch, queryParams, '', data.result, reload);
    } catch (e) {
      const error = typeof e === 'string' ? e : 'server error';
      return gotResult(dispatch, queryParams, error);
    }
  };
}

export function loadThirdPartyCompaniesWithRequests(queryParams: QueryParams) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetchQuery(getState(), queryParams)) {
      dispatch(requestQuery(queryParams));
      return dispatch(fetchQuery(queryParams, false));
    } else if (isFetchingQuery(getState(), queryParams)) {
      return waitForResult(queryParams);
    }
  };
}

export function invalidateQueriesAndReload(queryParams: QueryParams) {
  return (dispatch: Dispatch) => {
    dispatch(invalidatingAndReloading(queryParams));
    return dispatch(fetchQuery(queryParams, true));
  };
}

export function invalidateAllQueries() {
  return (dispatch: Dispatch) => {
    dispatch(invalidateAll());
  };
}

// Helper Functions
export function getQueryKey({ sort, filter }: QueryParams) {
  return encode(JSON.stringify({ sort, filter }));
}

export function getQueryParams(key: string) {
  try {
    return JSON.parse(decode(key));
  } catch {
    return null;
  }
}

function shouldFetchQuery(state: FullReduxState, queryParams: QueryParams) {
  const { thirdPartyCompanyFeatureRequestsQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !thirdPartyCompanyFeatureRequestsQueries.queries[queryKey];
}

function isFetchingQuery(state: FullReduxState, queryParams: QueryParams) {
  const { thirdPartyCompanyFeatureRequestsQueries } = state;
  const queryKey = getQueryKey(queryParams);
  return !!thirdPartyCompanyFeatureRequestsQueries.queries[queryKey].loading;
}

// Callback Queue
const resultCallbacks: Record<string, (() => void)[]> = {};

async function waitForResult(queryParams: QueryParams) {
  const queryKey = getQueryKey(queryParams);
  return new Promise((resolve) => {
    resultCallbacks[queryKey] = resultCallbacks[queryKey] || [];
    resultCallbacks[queryKey].push(() => resolve(true));
  });
}

async function gotResult(
  dispatch: Dispatch,
  queryParams: QueryParams,
  error: string,
  result?: Result,
  reload = false
) {
  const copiedParams = { ...queryParams };
  const resultAction = result
    ? queryLoaded(copiedParams, result, reload)
    : queryError(copiedParams, error);

  await dispatch(resultAction);

  const queryKey = getQueryKey(copiedParams);
  const callbacks = resultCallbacks[queryKey];
  if (!callbacks) {
    return;
  }

  callbacks.forEach((cb) => cb());
  resultCallbacks[queryKey].length = 0;
}

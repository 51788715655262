import { useContext } from 'react';

import { type QueryParams, getQueryKey, loadActionItems } from 'common/actions/actionItemQueries';
import { LocationContext } from 'common/containers/RouterContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import { isNotNil } from 'common/util/isNil';
import mapify from 'common/util/mapify';

import type { Board } from 'common/api/endpoints/boards';
import type { State } from 'common/reducers/actionItemQueries';

export type EncodedQuery = {
  source?: string;
};

export type LocationWithQuery = {
  pathname: string;
  query: EncodedQuery;
};

type Props = {
  boards: Board[];
  children: React.ReactNode;
  actionItemQueries: State;
};

export const getQueryParams = (location: LocationWithQuery): QueryParams => {
  const {
    query: { source },
  } = location;

  return {
    ...(source && { source }),
  };
};

export const getEncodedQueryParams = (queryParams: QueryParams): EncodedQuery => {
  return {
    ...(queryParams.source && { source: queryParams.source }),
  };
};

const mapAndFilterItems = (itemIDs: string[], boards: Board[], actionItemQueries: State) => {
  const boardMap = mapify(boards, '_id');

  return itemIDs
    .map((itemID) => {
      const item = actionItemQueries.items[itemID];

      if (!item) {
        return null;
      }

      return {
        ...item,
        board: boardMap[item.boardID],
        ...(item.duplicatePost && {
          duplicatePost: {
            ...item.duplicatePost,
            board: boardMap[item.duplicatePost.boardID],
          },
        }),
      };
    })
    .filter(isNotNil);
};

const AutopilotActionItemListContainer = (props: Props) => {
  const { actionItemQueries, boards } = props;

  const location = useContext(LocationContext);

  const getActionItemList = () => {
    const queryParams = getQueryParams(location);
    const query = actionItemQueries.queries[getQueryKey(queryParams)];

    if (!query?.itemIDs || query.loading) {
      return Object.assign({}, query, { loading: true, items: [] });
    }

    return {
      ...query,
      items: mapAndFilterItems(query.itemIDs, boards, actionItemQueries),
    };
  };

  const getActionItemCounts = () => {
    return {
      totalCount: actionItemQueries.count,
      cannyCount: actionItemQueries.postActionsCount,
      totalSourceCount: actionItemQueries.draftActionsCount,
      perSourceCounts: actionItemQueries.draftActionsCountPerSource,
    };
  };

  return cloneElementWithProps(props.children, {
    ...props,
    actionItemList: getActionItemList(),
    actionItemCounts: getActionItemCounts(),
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch, getState }, location }) => {
        const isFeedbackDiscovery = location.pathname.includes('/admin/autopilot');

        if (!isFeedbackDiscovery) {
          return;
        }

        const queryParams = getQueryParams(location);
        if (queryParams?.source === 'spam') {
          return;
        }

        const { company } = getState();
        if (!company.queueAutomation?.enabled) {
          return;
        }

        return dispatch(loadActionItems(queryParams));
      },
    },
  ],
  (state) => ({
    actionItemQueries: state.actionItemQueries,
  })
)(AutopilotActionItemListContainer);
